import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Paper,
    Grid,
    Container,
    CssBaseline,
    FormControlLabel,
    Switch as MuiSwitch,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation, useNavigate} from 'react-router-dom';
import { ArrowBack, StarBorderPurple500Rounded } from '@mui/icons-material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import axios from 'axios';
import parseHtml from 'html-react-parser';
import Checkbox from '@mui/material/Checkbox';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import { CONFIG } from '../config';
import { Box } from '@material-ui/core';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DoneIcon from '@mui/icons-material/Done';
import Header from '../Components/Header';
import BlogComponentClientMoreInfor from '../Components/BlogComponentClientMoreInfor';

const packageBorderStyle = {
    border: '1px solid #ccc', 
    borderRadius: '5px',      
    padding: '10px', 
};

const BlogPageForSuperAdminMoreInfor = ({articleId}) => {

    const [PageName, setPageName] = useState('Blog');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    useEffect(() => {

    }, []);

    const search = useLocation().search;
    const rtnId = new URLSearchParams(search).get('id');
    const rtnTypeId = new URLSearchParams(search).get('type');




    const navigate = useNavigate();

    return (
        <>
        <Header />
        <Container>
            <CssBaseline />
            <div className='pageTopRC'>
                <Grid container spacing={2}>
                    <Grid container spacing={2} className='pageBodyStyleRC'>
                        <Grid item xs={12}>
                            <div role="presentation" onClick={handleClick}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" to="/">
                                        <span className='breadcrumbFonts'>Home</span>
                                    </Link>
                                    <Typography color="text.primary"><span className='breadcrumbFonts'>{PageName}</span></Typography>
                                </Breadcrumbs>
                            </div>
                            
                        </Grid>
                        <Grid item xs={12}>
                            <a className='goBack' onClick={() => navigate(-1)}><ArrowBack /> Go Back</a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div>
                            <BlogComponentClientMoreInfor
                                articleId={rtnId}
                                articleType={rtnTypeId}
                                
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>

        </Container>
        </>
    );
}

export default BlogPageForSuperAdminMoreInfor;
