import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import axios from 'axios'
import { CONFIG } from '../config'
import { Grid, makeStyles } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Draggable1 from 'react-draggable';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ClockLoader } from 'react-spinners';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import useStyle from '../Css/MainCss';
import { Person } from '@mui/icons-material';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info } from '@mui/icons-material';

const tableStyle = {
    border: '1px solid #ccc',
    backgroundColor: 'white',
};

const cellStyle = {
    border: '1px solid #ccc',
    padding: '8px',
};

const menuBorderStyle = {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '10px',
};


const useStyles = makeStyles((theme) => ({
    right: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    pageTop: {
        marginTop: '115px',
    },
    pageBodyStyle: {

        paddingTop: '12px',
    },
    stdCardShowAll: {
        height: '450px !important',
        border: '1px solid black'
    },
    scrollableDivToStandardShowAll: {
        height: '375px',
        overflow: 'auto',
        width: '100%',
      },


}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const MainMenuSetup = () => {
    const classesss = useStyle();
    const classes = useStyles();

    function PaperComponentObject(props) {
        return (
            <Draggable1
                handle="#draggable-grid-objective"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable1>
        );
    }

    React.useEffect(() => {
        getAllCentresFromServiceSystem()
    }, [])

    const [allCentres, setAllCentres] = React.useState([]);

    function getAllCentresFromServiceSystem() {
        var data = '{getAllCentreList{centreId,centreName}}';
        axios(CONFIG.MAIN_URL + '/graphql', {
            method: 'post',
            headers: {
                'Content-Type': 'text/plain',
            },
            data: data,
        })
            .then(function (response) {
                var centreList = response.data.data.getAllCentreList;
                console.log("All Centre List");
                console.log(centreList);
                setAllCentres(centreList);
            })
            .catch(function (error) {
                console.log("Entity Loding error" + error);
            });
    }

    const [centreId, setCentreId] = useState('');
    const [allEntityMenu, setAllEntityMenu] = useState([]);
    const [gridDivider, setGridDivider] = useState(12);
    const [selectedMainMenu, setSelectedMainMenu] = useState({});
    const [selectedMainMenuId, setSelectedMainMenuId] = useState('');

    var xMainArr = []
    const handleChangeCentre = (event) => {
        clearFuction();
        setCentreId(event.target.value);
        loadMenuData(event.target.value)
    }
    function loadMenuData(selCentreId) {
        axios(CONFIG.MAIN_URL + `/mainMenuSetup/filter?centreId=` + selCentreId, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("Main Menu Retuens for the selected sentre Id");
                console.log(response.data);
                var mainMenuObj = {};
                var mainMenuFound = false;
                response.data.forEach((itemA, indexA) => {
                    if (indexA === 0) {
                        mainMenuFound = true;
                        mainMenuObj = itemA;
                    }
                })
                setSelectedMainMenu(mainMenuObj);
                var data = '{allEntityList(centreId:\"' + selCentreId + '\"){ entityDataList{entityName,entityId,isActive,createdBy,lastModifiedBy,centreId,basicEntity{basicEntityId,entityName,iamRole,forceIamRole}}}}';
                axios(CONFIG.MAIN_URL + '/graphql', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                    data: data,
                })
                    .then(function (res1) {
                        var entityList = res1.data.data.allEntityList.entityDataList;
                        var tmpEntityData = [];
                        entityList.forEach((item) => {
                            if (item.isActive && item.centreId === selCentreId) {
                                var be = item.basicEntity
                                var arrayData = {}
                                arrayData["entityId"] = item.entityId;
                                arrayData["entityName"] = item.entityName;
                                arrayData["isActive"] = item.isActive;
                                arrayData["createdBy"] = item.createdBy;
                                arrayData["centreId"] = item.centreId;
                                arrayData["basicEntityId"] = item.basicEntity.basicEntityId;
                                arrayData["basicEntityName"] = item.basicEntity.entityName;
                                arrayData["basicIamRole"] = item.basicEntity.iamRole;
                                arrayData["basicForceIamRole"] = item.basicEntity.forceIamRole;
                                tmpEntityData.push(arrayData);
                            }
                        })


                        tmpEntityData.forEach((item) => {
                            var data = '{allMenuRoleList(centreId:"' + selCentreId + '",iamRole:"' + item.basicIamRole + '"){menuRoleId,centerMenuName,centerMenuOrderNum,centreId,createdDate,updatedDate,isActive,menu{menuUri,menuHtmlId},subMenuList{subMenuRoleId,centerMenuName,centerMenuOrderNum,centreId,createdDate,updatedDate,isActive,menu{menuUri,menuHtmlId}}}}';
                            axios(CONFIG.MAIN_URL + `/graphql`, {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'text/plain',
                                },
                                data: data

                            })
                                .then(function (res2) {
                                    var arr = res2.data.data.allMenuRoleList;
                                    var mainArr = [];
                                    var entityId = "";
                                    var entityName = "";
                                    var iamRole = "";
                                    arr.forEach((dataT1) => {
                                        var arr1 = dataT1.subMenuList;
                                        arr1.forEach((itemT1) => {
                                            if (item.entityId != "") {
                                                entityId = item.entityId;
                                                entityName = item.entityName;
                                                iamRole = item.basicIamRole
                                                var objT = {};
                                                objT["entityData"] = item;
                                                //mainMenuFound = false;
                                                if (mainMenuFound) {
                                                    setSelectedMainMenuId(mainMenuObj.id);
                                                    var foundFlag = false;
                                                    mainMenuObj.entityMenus.forEach((xD1) => {
                                                        if (entityId === xD1.entityId && iamRole === xD1.iamRole) {
                                                            foundFlag = true;
                                                            console.log("+++++++++++++++itemT1+++++++++++++++");
                                                            console.log(itemT1);
                                                            //itemT1.forEach((xD2) => {
                                                            var foundFlag1 = false;
                                                            xD1.menus.forEach((xD3) => {
                                                                if (itemT1.menu.menuHtmlId === xD3.menuId) {
                                                                    itemT1["selected"] = "yes";
                                                                    itemT1["orderNo"] = xD3.orderNo;
                                                                    itemT1["editedMenuName"] = xD3.menuName;
                                                                    objT["menuData"] = itemT1;
                                                                    foundFlag1 = true;
                                                                }
                                                            })
                                                            if (!foundFlag1) {
                                                                itemT1["selected"] = "no";
                                                                itemT1["orderNo"] = 100;
                                                                itemT1["editedMenuName"] = itemT1.centerMenuName;
                                                                objT["menuData"] = itemT1;
                                                            }
                                                            //})
                                                        }
                                                    })
                                                    if (!foundFlag) {
                                                        itemT1["selected"] = "no";
                                                        itemT1["orderNo"] = 100;
                                                        itemT1["editedMenuName"] = itemT1.centerMenuName;
                                                        objT["menuData"] = itemT1;
                                                    }


                                                } else {
                                                    itemT1["selected"] = "no";
                                                    itemT1["orderNo"] = 100;
                                                    itemT1["editedMenuName"] = itemT1.centerMenuName;
                                                    objT["menuData"] = itemT1;
                                                }


                                                mainArr.push(objT);
                                            }
                                        })
                                    })
                                    console.log("++++++++++++++++++MenuData-mainArr+++++++++++++++++++");
                                    console.log(mainArr);
                                    if (entityId != "") {
                                        var ttObj = {}
                                        ttObj["entityId"] = entityId
                                        ttObj["entityName"] = entityName
                                        ttObj["iamRole"] = iamRole;
                                        ttObj["menuData"] = mainArr
                                        xMainArr.push(ttObj);
                                        setAllEntityMenu(xMainArr);
                                        if (xMainArr.length === 1) {
                                            setGridDivider(12);
                                        }
                                        if (xMainArr.length === 2) {
                                            setGridDivider(6);
                                        }
                                        if (xMainArr.length === 3) {
                                            setGridDivider(4);
                                        }
                                        if (xMainArr.length === 4 && xMainArr.length > 4) {
                                            setGridDivider(3);
                                        }
                                        console.log("++++++++++++++++++XXMenuDataXX-xMainArr+++++++++++++++++++");
                                        console.log(xMainArr);

                                        
                                    }
                                })
                                .catch(function (error) {
                                    console.log("++++++++++++++++++Error+++++++++++++++++++");
                                    console.log(error);
                                });
                        })
                    })
                    .catch(function (error) {
                        console.log("Entity Loding error" + error);
                    });
            })
            .catch(function (error) {
                console.log("Main Menu Loding error" + error);
            });





    }

    function clearFuction() {
        setCentreId('');
        xMainArr = [];
        setGridDivider(12);
        setAllEntityMenu([]);
        setSelectedMainMenuId('');
        setSelectedMainMenu({});

    }




    function Package({ entityId, entityName, iamRole, menuData }) {
        return (
            <Paper style={menuBorderStyle}>
                <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
                    <span style={{ color: "blue" }}><Person /></span>&nbsp;
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Entity &nbsp;-&nbsp;{entityName}</span>
                </Typography>
                <div style={{ marginTop: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <TableContainer style={tableStyle}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ ...cellStyle, width: '10%' }}>
                                        <span style={{ marginLeft: '0px' }}  >All</span>
                                        <br></br>
                                        <input
                                            type="checkbox"
                                            style={{ cursor: 'pointer', accentColor: 'blue' }}
                                            onClick={() => {
                                                getCheckStatusAll(entityId);
                                            }}
                                            id={
                                                "chkMenu_" + "all" + "_" + entityId
                                            }
                                            name={
                                                "chkMenu_" + "all" + "_" + entityId
                                            }
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell style={{ ...cellStyle, width: '45%' }}>Menu Name</StyledTableCell>
                                    <StyledTableCell style={{ ...cellStyle, width: '45%' }}>Centre Menu Name</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {menuData.map((data) => (
                                    <TableRow>
                                        <TableCell style={cellStyle}>
                                            {data.menuData.selected === "yes" ?
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={true}
                                                    style={{ cursor: 'pointer', accentColor: 'blue' }}
                                                    onClick={() => {
                                                        getCheckStatus(
                                                            "chkMenu_" + data.entityData.entityId + "_" + data.menuData.menu.menuHtmlId
                                                        );
                                                    }}
                                                    id={
                                                        "chkMenu_" + data.entityData.entityId + "_" + data.menuData.menu.menuHtmlId
                                                    }
                                                    name={
                                                        "chkMenu_" + data.entityData.entityId + "_" + data.menuData.menu.menuHtmlId
                                                    }
                                                />
                                                :
                                                <input
                                                    type="checkbox"
                                                    style={{ cursor: 'pointer', accentColor: 'blue' }}
                                                    onClick={() => {
                                                        getCheckStatus(
                                                            "chkMenu_" + data.entityData.entityId + "_" + data.menuData.menu.menuHtmlId
                                                        );
                                                    }}
                                                    id={
                                                        "chkMenu_" + data.entityData.entityId + "_" + data.menuData.menu.menuHtmlId
                                                    }
                                                    name={
                                                        "chkMenu_" + data.entityData.entityId + "_" + data.menuData.menu.menuHtmlId
                                                    }
                                                />

                                            }

                                        </TableCell>
                                        <TableCell style={cellStyle}>
                                            {data.menuData.centerMenuName}
                                        </TableCell>
                                        <TableCell style={cellStyle}>
                                            <TextField
                                                variant="outlined"
                                                id={"txtMenu_" + data.entityData.entityId + "_" + data.menuData.menu.menuHtmlId}
                                                fullWidth
                                                defaultValue={data.menuData.editedMenuName}
                                                onChange={() => {
                                                    changeTxtValue(
                                                        "txtMenu_" + data.entityData.entityId + "_" + data.menuData.menu.menuHtmlId
                                                    );
                                                }}
                                            />
                                            <input
                                                type="hidden"
                                                id={
                                                    "hidden_" + data.entityData.entityId + "_" + data.menuData.menu.menuHtmlId
                                                }
                                                value={data.menuData.orderNo}
                                            />
                                            &nbsp;
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>


                </div>
                <br></br>
                <div style={{ marginTop: '10px', gap: '5px', display: 'flex' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: '25%' }}
                        onClick={() => {
                            updateMenu(entityId, entityName, iamRole);
                        }}

                    >
                        Update
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: '75%' }}
                        onClick={() => {
                            orderMenu(entityId, entityName, iamRole);
                        }}
                    >

                        Change the display position
                    </Button>

                </div>

            </Paper>
        );
    }

    function getCheckStatus(regId) {
        /*
        if (document.getElementById(regId).checked){
            document.getElementById(regId).checked = false;
        }else{
            document.getElementById(regId).checked = true;
        }
        */
    }

    function getCheckStatusAll(entityId) {
        if (document.getElementById("chkMenu_" + "all" + "_" + entityId).checked) {
            allEntityMenu.forEach((data) => {
                if (data.entityId === entityId) {
                    data.menuData.forEach((item) => {
                        document.getElementById("chkMenu_" + entityId + "_" + item.menuData.menu.menuHtmlId).checked = true;
                    })
                }

            })
        } else {
            allEntityMenu.forEach((data) => {
                if (data.entityId === entityId) {
                    data.menuData.forEach((item) => {
                        document.getElementById("chkMenu_" + entityId + "_" + item.menuData.menu.menuHtmlId).checked = false;
                    })
                }

            })
        }
    }

    function changeTxtValue(id) {
        //alert(document.getElementById(id).value)
    }

    function compare(a, b) {
        if (a.orderNo < b.orderNo) {
            return -1;
        }
        if (a.orderNo > b.orderNo) {
            return 1;
        }
        return 0;
    }

    function updateMenu(entityId, entityName, iamRole) {
        var selectedArr = [];
        allEntityMenu.forEach((data) => {
            if (data.entityId === entityId) {
                data.menuData.forEach((item) => {
                    if (document.getElementById("chkMenu_" + entityId + "_" + item.menuData.menu.menuHtmlId).checked) {
                        var obj = {};
                        obj["menuId"] = item.menuData.menu.menuHtmlId
                        obj["menuName"] = document.getElementById("txtMenu_" + entityId + "_" + item.menuData.menu.menuHtmlId).value;
                        var oNo = 100;
                        if (document.getElementById("hidden_" + entityId + "_" + item.menuData.menu.menuHtmlId).value != "") {
                            oNo = parseInt(document.getElementById("hidden_" + entityId + "_" + item.menuData.menu.menuHtmlId).value);
                        }
                        obj["orderNo"] = oNo;
                        selectedArr.push(obj);
                    }

                })
            }
        })
        /*
        if (selectedArr.length === 0) {
            alert("Please select at least one menu to update menus")
        } else {
         */   
            selectedArr.sort(compare);
            var obj = {};
            if (selectedMainMenuId != "") {
                obj = {};
                obj["id"] = selectedMainMenuId;
                obj["centreId"] = selectedMainMenu.centreId;
                var entityArr = [];
                var tFoundEntity = false;
                selectedMainMenu.entityMenus.forEach((data) => {
                    //alert(data.entityId)
                    //alert(entityId)
                    if (data.entityId === entityId) {
                        tFoundEntity = true;
                        var obj1 = {};
                        obj1["entityId"] = entityId;
                        obj1["entityName"] = entityName;
                        obj1["iamRole"] = iamRole;
                        var tArr = [];
                        selectedArr.forEach((item, index) => {
                            var obj2 = {};
                            obj2["menuId"] = item.menuId;
                            obj2["menuName"] = item.menuName;
                            if (item.orderNo === 100) {
                                item["orderNo"] = (index + 1)
                            }
                            obj2["orderNo"] = item.orderNo;
                            tArr.push(obj2);
                        })
                        obj1["menus"] = tArr;
                        entityArr.push(obj1)
                    } else {
                        entityArr.push(data);
                    }
                })
                if (!tFoundEntity){
                    var obj1 = {};
                    obj1["entityId"] = entityId;
                    obj1["entityName"] = entityName;
                    obj1["iamRole"] = iamRole;
                    var tArr = [];
                    selectedArr.forEach((item, index) => {
                        var obj2 = {};
                        obj2["menuId"] = item.menuId;
                        obj2["menuName"] = item.menuName;
                        if (item.orderNo === 100) {
                            item["orderNo"] = (index + 1)
                        }
                        obj2["orderNo"] = item.orderNo;
                        tArr.push(obj2);
                    })
                    obj1["menus"] = tArr;
                    entityArr.push(obj1)
                } 




                obj["entityMenus"] = entityArr;
                console.log(obj);

            } else {
                obj = {};
                obj["centreId"] = centreId;
                var entityArr = [];
                var obj1 = {};
                obj1["entityId"] = entityId;
                obj1["entityName"] = entityName;
                obj1["iamRole"] = iamRole;
                var tArr = [];
                selectedArr.forEach((item, index) => {
                    var obj2 = {};
                    obj2["menuId"] = item.menuId;
                    obj2["menuName"] = item.menuName;
                    if (item.orderNo === 100) {
                        item["orderNo"] = (index + 1)
                    }
                    obj2["orderNo"] = item.orderNo;
                    tArr.push(obj2);
                })
                obj1["menus"] = tArr;
                entityArr.push(obj1)
                obj["entityMenus"] = entityArr;
                console.log(obj);
            }
            var sendJson = JSON.stringify(obj);
            console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
            console.log(sendJson);
            var endPoint = "";
            if (selectedMainMenuId != '') {
                endPoint = '/mainMenuSetup/update';
            } else {
                endPoint = '/mainMenuSetup/create';
            }
            axios(CONFIG.MAIN_URL + endPoint, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: sendJson
            })
                .then(res => {
                    if (selectedMainMenuId != '') {
                        alert('Successfully Updated the menus');
                    } else {
                        alert('Successfully Saved the menus');
                    }
                    clearFuction();
                    setCentreId(centreId);
                    loadMenuData(centreId);
                })
                .catch(function (error) {
                    alert('Error Updating the Pricing');
                    console.log(error);
                });

        /*}*/
    }

    const [open, setOpen] = React.useState(false);
    const [openScroll, setOpenScroll] = React.useState('paper');

    const handleClose = () => {
        setOpen(false);
    };

    const [selectedEntityName, setSelectedEntityName] = React.useState('');
    const [selectedEntityMenus, setSelectedEntityMenus] = React.useState([]);
    const [selectedEntityId, setSelectedEntityId] = React.useState('');

    function orderMenu(entityId, entityName, iamRole) {
        setSelectedEntityName(entityName);
        setSelectedEntityId(entityId);
        var foundData = false;
        selectedMainMenu.entityMenus.forEach((data) => {
            if (data.entityId === entityId) {
                setTimeout(()=> {
                    setSelectedEntityMenus(data.menus);
                }, 100);
                foundData = true;
            }
        })

        if (!foundData) {
            alert("Please select and update the menus before changing the menu order")
        } else {
            setOpen(true);
            setOpenScroll('paper');
        }
    }

    function saveOrderMenus() {
        var obj = {};
        obj = {};
        obj["id"] = selectedMainMenuId;
        obj["centreId"] = selectedMainMenu.centreId;
        var entityArr = [];
        selectedMainMenu.entityMenus.forEach((data) => {
            if (data.entityId === selectedEntityId) {
                var obj1 = {};
                obj1["entityId"] = data.entityId;
                obj1["entityName"] = data.entityName;
                obj1["iamRole"] = data.iamRole;
                var tArr = [];
                selectedEntityMenus.forEach((item, index) => {
                    var obj2 = {};
                    obj2["menuId"] = item.menuId;
                    obj2["menuName"] = item.menuName;
                    obj2["orderNo"] = (index + 1)
                    tArr.push(obj2);
                })
                obj1["menus"] = tArr;
                entityArr.push(obj1)
            } else {
                entityArr.push(data);
            }
        })
        obj["entityMenus"] = entityArr;
        console.log(obj);
        var sendJson = JSON.stringify(obj);
        console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
        console.log(sendJson);
        var endPoint = '/mainMenuSetup/update';
        axios(CONFIG.MAIN_URL + endPoint, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: sendJson
        })
            .then(res => {
                alert('Successfully Updated the menu order numbers');
                clearFuction();
                setCentreId(centreId);
                loadMenuData(centreId);
                setOpen(false);

            })
            .catch(function (error) {
                alert('Error in Updating order numbers');
                console.log(error);
            });
    }


    const onDragEndRows = (result) => {
        if (!result.destination) {
            return;
        }

        const newRows = [...selectedEntityMenus];
        console.log(newRows)
        const [removed] = newRows.splice(result.source.index, 1);
        newRows.splice(result.destination.index, 0, removed);

        setSelectedEntityMenus(newRows);
    };


    const entityMenuData = (
        <DragDropContext onDragEnd={onDragEndRows}>
            <Droppable droppableId="table">
                {(provided) => (
                    <table ref={provided.innerRef} style={{ width: '96%' }}>
                        <thead>
                            <tr>
                                <th style={{ width: '80%', fontSize: '10px' }}>Menu Name </th>
                                <th style={{ width: '20%', fontSize: '10px' }}>Order No </th>
                            </tr>
                        </thead>
                        <tbody {...provided.droppableProps}>
                            {selectedEntityMenus.map((row, index) => {
                                if (row.orderNo > 0) {
                                    return (
                                        <Draggable key={row.orderNo} draggableId={`${row.orderNo}`} index={index}>
                                            {(provided) => (
                                                <tr
                                                    className={index % 2 === 0 ? classes.trBackColor1 : classes.trBackColor2}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}


                                                >
                                                    <td>{row.menuName}</td>
                                                    {/* <td style={{height:'40px'}}>{index}</td> */}
                                                    <td style={{ float: 'right' }} >{row.orderNo}</td>
                                                </tr>
                                            )}
                                        </Draggable>
                                    )
                                }
                            })}
                            {provided.placeholder}
                        </tbody>
                    </table>
                )}
            </Droppable>
        </DragDropContext>

    )



    return (

        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { mt: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    > <FormControl fullWidth>
                            <TextField
                                label="Select a Centre"
                                sx={{ width: '100%' }}
                                select
                                value={centreId}
                                onChange={handleChangeCentre}
                                style={{ backgroundColor: "white" }}
                            >
                                {allCentres.map((item) => (
                                    <MenuItem
                                        value={item.centreId}
                                    >
                                        {item.centreName}{" ("}{item.centreId}{")"}
                                    </MenuItem>
                                ))}

                            </TextField>
                        </FormControl>

                    </Box>
                </Grid>

                <Grid item xs={8} style={{ marginTop: '10px', gap: '5px', display: 'flex' }}>
                    <Box>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            onClick={clearFuction}
                            style={{ padding: '10px 15px' }}
                        >
                            clear
                        </Button>
                    </Box>

                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {allEntityMenu.map((menuItem, index) => (
                            <Grid item xs={gridDivider}>
                                <Package {...menuItem} />
                            </Grid>

                        ))}

                    </Grid>

                </Grid>





            </Grid>





            <Dialog
                open={open}
                onClose={false}
                scroll={openScroll}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}

            >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} PaperComponent={PaperComponentObject} id="draggable-grid-objective" style={{ cursor: 'move' }}>
                            <Card className={classes.stdCardShowAll}>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                            <Info />
                                        </Avatar>
                                    }
                                    className={classesss.commonMainTableHead}
                                    title={selectedEntityName}
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <div className={classes.scrollableDivToStandardShowAll}>
                                                {entityMenuData}
                                            </div>
                                        </Grid>

                                    </Grid>
                                </CardContent>


                            </Card>

                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => saveOrderMenus()}
                                style={{ float: 'right' }}
                                variant="outlined"
                            >
                                confirm changes
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>





        </>
    );

}

export default MainMenuSetup;
