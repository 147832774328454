import { Tab, Tabs } from '@material-ui/core'
import React, { useState } from 'react'
import WeightingQuestion from '../Components/WeightingQuestion'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import WeightingRisk from '../Components/WeightingRisk';
import WeightingAnswers from '../Components/WeightingAnswers';

const useStyles = makeStyles((theme) => ({ 
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageBtn:{

    height: '60px',
    width: '170px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '12px !important',
  },
  headerLable:{

    color: '#827d7d',
  },
  pageTop:{
    marginTop: '115px',
    marginLeft:'20px'
  },
  pageBodyStyle: {
      paddingTop: '12px',

   
  },
  root: {
    position: 'relative',
  },
  posRelative: {
    position: 'relative',
  },
  topImagePos: {
    position: 'absolute', 
    top: '33%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  topHeaderText1: {
    color: '#9155FD',
    fontSize:'25px', 
    paddingBottom:'10px',
  },
  topHeaderText2: {
    color: '#3A3541',
    fontSize:'12px', 
    textAlign:'center',
    fontFamily: 'Inter',
  },
  thStyle: {
    fontSize: '12px !important',
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important'
  },
  thStyleImage: {
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important',
    width: '130px !important',
  },
  btnColorPurple:{
    fontSize: '12px !important',
    backgroundColor: '#9155FD !important',
    height:'40px',
    textAlign:'center'
  },
  riskImageSize: {
    width: '120px',
    height: '100px !important',
  },
  tableStyle: {
    width: '55% !important',
    minWidth:'720px !important'
  },
  btnBorderGreen: {
    border: '1px solid #0e640e !important',
    color: '#0e640e !important',
    height:'40px'
  },
  btnclear:{
    height:'40px'
  },
  btnBorderAsh: {
    border: '1px solid #8a8181 !important',
    color: '#8a8181 !important',
  },
  btnBorderRed: {
    border: '1px solid #ff0000 !important',
    color: '#ff0000 !important',
  },
  qusCard: {
    width: '100% !important',
  },
  qusCardHeader: {
    backgroundColor: '#ece9fa !important',
  },
  subText: {
    fontSize: '12px !important',
  },
  btnPadding: {
    paddingLeft:'2px !important',
    paddingRight:'2px !important',
  },
  cardBodyH6: {
    fontSize: '14px !important',
  },
  cardBodyH6Ans: {
    fontSize: '13px',
    paddingLeft: '12px',
  },
  addBodyPlus: {
    fontSize: '146px !important',
    fontWeight: '1000 !important',
    textAlign: 'center !important',
    color: '#3A3541 !important',
  },
  posRelativeAndDivBorder: {
    position: 'relative',
    border: '1px solid #9155FD', 
    width: '120px',
    height: '100px !important',
  },
  hightrachytitle:{
    textAlign:'left',
    color:'#3A3541',
    
  },
  btnBorderpurple:{
    border: '1px solid #9155FD !important',
    color: '#9155FD !important',
    padding:'5px 10px !important',
    height: '40px !important',
  },
  hightrachytitlestyle:{
    fontSize:'14px !important',
    fontWeight:500
  },
  modalbox:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 14,
    padding: 10,
  },
  addriskbtn:{
    color:'#9155FD !important',
    border:'1px solid #9155FD !important',
    marginTop:'20px !important'
  },
  risktytext:{
    width:'350px',

  },
  canclbtn:{
    alignItem:'left !important' ,
    justifyContent: 'end'
  },
  cardRiskManage:{
      width:'95%',
      border:'1px solid black'
  },
  scrollableDiv: {
    height: '150px',
    overflow: 'auto',
    width: '100%',
  },
  tableHeadStyle: {
    backgroundColor: '#d5d5e1',
    color: '#ffffff',
  },
  tableThStyle: {
    lineHeight: '0.25rem !important'
  },
  tableTrStyle: {
    //lineHeight: '0.20rem !important',
    height: '19px !important'
  },
  mousePointer: {
    cursor: 'pointer'
  },
  scrollableDiv: {
    height: '250px',
    overflow: 'auto',
    width: '100%',
  },
  stdMainCardHeader:{
    backgroundColor:'#acacbe !important',
    fontSize:'13px !important',
    height:'50px',
    padding:'0px',
    marginTop: '-7px',
  },
  addrisktypebtn:{
    marginLeft:'10px !important',
    border: '1px solid #0e640e !important',
    color: '#0e640e !important',
    padding:'5px 10px !important',
    height: '40px !important',
  },

  btnBorderpurple:{
    border: '1px solid #9155FD !important',
    color: '#9155FD !important',
    padding:'5px 10px !important',
    height: '40px !important',
  },
  stdCard:{
    height:'100%',
    border:'1px solid black'
  },
  scrollableDivToStandard: {
    height: '350px',
    overflow: 'auto',
    width: '100%',
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}


function TabPanel(props){

  const {children, value, index} = props;

  return(
    <div>
      {
        value === index && (
          <h1>{children}</h1>
        )
      }
    </div>
  )
}

const SliderMethodsComponent = () => {



  const [value, setTabValur] = useState(0)
  const handleTabs = (e,val)=> {
    console.log(val)
    setTabValur(val)
  }

  const [PageName, setPageName] = useState('Create and Edit Slider Methods')

  const classes = useStyles();
  return (
    <>
    
          <div style={{backgroundColor:"white"}}>
              <Grid container spacing={2}>
              <Grid container spacing={2} className={classes.pageBodyStyle}>
  
                {/* <Grid item xs={12}>
                  <div role="presentation" onClick={handleClick}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link underline="hover" color="inherit" to="/login">
                        Home
                      </Link>
                      
                      <Typography color="text.primary">Create and Edit Slider Mthods </Typography>
                    </Breadcrumbs>
                  </div>
                  </Grid> */}

    <>

    <Tabs value={value} onChange={handleTabs} style={{paddingLeft:'20px'}}>
      <Tab label="Risk Weighting"/>
      <Tab label="Qustion Weighting"/>
      <Tab label="Answer Weighting"/>
    </Tabs>

    <TabPanel value={value} index={0} style={{paddingLeft:'20px'}}>
    <WeightingRisk/>
    
    </TabPanel>
    <TabPanel value={value} index={1} style={{paddingLeft:'20px'}}>
    <WeightingQuestion/>
    </TabPanel>
    <TabPanel value={value} index={2} style={{paddingLeft:'20px'}}>
      <WeightingAnswers/>
    </TabPanel>



    </>


    </Grid>
                  </Grid></div>
    </>
  )
}

export default SliderMethodsComponent