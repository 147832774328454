export const collectionData = {
   collectionId:1,
   title:"human resources",
   imageUrl:"http://192.168.5.9:8180/app/image/R01/dmg2.png1657029580808",
   status:"Draft",
   description:"Question to all personel",
   centreId:"R01",
   collectionUniqueName:"S01_questionnaire_1",
   documentCreator:"user/expert",
   version:"0.0.7",
   createdDate:"ISODate(2022-07-05T13:59:40.984Z)",
   updatedDate:"ISODate(2022-07-05T13:59:40.984Z)",
   risks:[
      {
         collectionRiskId:4,
         name:"Human resources-risk heirachy collection",
         riskTypes:[
            {
               collectionRiskTypeId:30,
               name:"Absentism",
               riskLevel:"2",
               riskAppetite:"7",
               subRisks:[
                  {
                     collectionRiskTypeId:31,
                     name:"Attendance register",
                     riskLevel:"2",
                     riskAppetite:"2",
                     subRisks:[
                        {
                           collectionRiskTypeId:32,
                           name:"Location of Attendance register",
                           riskLevel:"2",
                           riskAppetite:"7",
                           subRisks:[
                              
                           ],
                           questions:{
                              closeEndedQuestions:[
                                 {
                                    questionId:66,
                                    question:"Is the attendance register located in a convenient place",
                                    questionScore:2,
                                    version:"0.0.1",
                                    component:{
                                       id:13,
                                       name:"Internal Environment -2013",
                                       standardId:1,
                                       standardName:"coso2013"
                                    },
                                    objective:{
                                       id:8,
                                       name:"Reporting -2013",
                                       standardId:1,
                                       standardName:"coso2013"
                                    },
                                    principle:{
                                       id:1,
                                       name:"Control environment - commit to integrity and ethical values",
                                       standardId:1,
                                       standardName:"coso2013"
                                    },
                                    entity:{
                                       id:13,
                                       name:"ADMIN assistant -2013",
                                       standardId:1,
                                       standardName:"coso2013"
                                    },
                                    questionType:"Closeended",
                                    questionTypeName:"Ordinal",
                                    metaInfo:{
                                       status:"complete",
                                       createdDate:"ISODate(2022-07-05T13:59:40.984Z)",
                                       UpdatedDate:"ISODate(2022-07-05T13:59:40.984Z)"
                                    },
                                    answer:{
                                       answersId:57,
                                       answerMethod:{
                                          name:"radioButtonType",
                                          minValue:0,
                                          maxValue:0
                                       },
                                       version:"0.0.1",
                                       updatedDate:"ISODate(2022-07-05T13:59:40.984Z)",
                                       createdDate:"ISODate(2022-07-05T13:59:40.984Z)",
                                       answerScaleType:{
                                          name:"semanticdifferential"
                                       },
                                       answerList:[
                                          {
                                             answerName:"yes",
                                             answerType:"radioButtonType",
                                             riskLevel:7,
                                             expectedAnswerName:"Yes"
                                          },
                                          {
                                             answerName:"no",
                                             answerType:"radioButtonType",
                                             riskLevel:3,
                                             expectedAnswerName:""
                                          }
                                       ],
                                    },
                                 }
                              ]
                           }
                        }
                     ],
                     questions:{
                        
                     }
                  }
               ],
               questions:{
                  openEdndedQuestions:[
                     {
                        questionId:63,
                        question:"please share your views re Absenteism and its importance as a risk",
                        questionScore:10,
                        component:{
                           id:13,
                           name:"Internal Environment -2013",
                           standardId:1,
                           standardName:"coso2013"
                        },
                        objective:{
                           id:9,
                           name:"Operations -2013",
                           standardId:1,
                           standardName:"coso2013"
                        },
                        principle:{
                           id:4,
                           name:"Control -Env Management Establishes structure and Appropriate responsibility and reporting lines and authority",
                           standardId:1,
                           standardName:"coso2013"
                        },
                        entity:{
                           id:6,
                           name:"Main Administrator global 2013",
                           standardId:1,
                           standardName:"coso2013"
                        },
                        questionType:"Openended",
                        questionTypeName:"",
                        metaInfo:{
                           status:"complete",
                           createdDate:"ISODate(2022-07-05T13:59:40.984Z)",
                           UpdatedDate:"ISODate(2022-07-05T13:59:40.984Z)"
                        },
                        answer:{
                           answersId:54,
                           answerMethod:{
                              name:"freeTextType",
                              minValue:0,
                              maxValue:0
                           },
                           answer:"Eg. I think absentism poses a great risk to our company's production and output",
                           version:"0.0.1",
                           UpdatedDate:"ISODate(2022-07-05T13:59:40.984Z)",
                           createdDate:"ISODate(2022-07-05T13:59:40.984Z)",
                           answerList:[
                              
                           ],
                        },
                     },
                     {
                        questionId:67,
                        question:"Is there any other views you wish to share",
                        questionScore:2,
                        version:"0.0.1",
                        component:{
                           id:15,
                           name:"Internal Environment -2017",
                           standardId:3,
                           standardName:"Coso2017"
                        },
                        objective:{
                           id:7,
                           name:"operations -2017",
                           standardId:3,
                           standardName:"Coso2017"
                        },
                        principle:{
                           id:9,
                           name:" Control Activities - through policies of the organisation",
                           standardId:3,
                           standardName:"Coso2017"
                        },
                        entity:{
                           id:6,
                           name:"Management",
                           standardId:3,
                           standardName:"Coso2017"
                        },
                        questionType:"Openended",
                        questionTypeName:"",
                        metaInfo:{
                           status:"complete",
                           createdDate:"ISODate(2022-07-05T13:59:40.984Z)",
                           UpdatedDate:"ISODate(2022-07-05T13:59:40.984Z)"
                        },
                        answer:{
                           answersId:58,
                           answerMethod:{
                              name:"freeTextType",
                              minValue:0,
                              maxValue:0
                           },
                           answer:"Not really",
                           version:"0.0.1",
                           UpdatedDate:"ISODate(2022-07-05T13:59:40.984Z)",
                           createdDate:"ISODate(2022-07-05T13:59:40.984Z)",
                           answerList:[
                              
                           ],
                        },
                     }
                  ],
                  closeEndedQuestions:[
                     {
                        questionId:64,
                        question:"Abentism in my department is a problem (rate between 1-10; 1 being not a big poblem and 10 being a big problem)",
                        questionScore:10,
                        version:"0.0.1",
                        component:{
                           id:13,
                           name:"Internal Environment -2013",
                           standardId:1,
                           standardName:"coso2013"
                        },
                        objective:{
                           id:9,
                           name:"Operations -2013",
                           standardId:1,
                           standardName:"coso2013"
                        },
                        principle:{
                           id:4,
                           name:"Control -Env Management Establishes structure and Appropriate responsibility and reporting lines and authority",
                           standardId:1,
                           standardName:"coso2013"
                        },
                        entity:{
                           id:6,
                           name:"Main Administrator global 2013",
                           standardId:1,
                           standardName:"coso2013"
                        },
                        questionType:"Closeended",
                        questionTypeName:"Ordinal",
                        metaInfo:{
                           status:"complete",
                           createdDate:"ISODate(2022-07-05T13:59:40.984Z)",
                           UpdatedDate:"ISODate(2022-07-05T13:59:40.984Z)"
                        },
                        answer:{
                           answersId:55,
                           answerMethod:{
                              name:"checkBoxType",
                              minValue:0,
                              maxValue:0
                           },
                           version:"0.0.1",
                           UpdatedDate:"ISODate(2022-07-05T13:59:40.984Z)",
                           createdDate:"ISODate(2022-07-05T13:59:40.984Z)",
                           answerScaleType:{
                              name:"semanticdifferential"
                           },
                           answerList:[
                              {
                                 answerName:"is problematic",
                                 answerType:"checkBoxType",
                                 riskLevel:2,
                                 expectedAnswerName:""
                              },
                              {
                                 answerName:"not a problem",
                                 answerType:"checkBoxType",
                                 riskLevel:0,
                                 expectedAnswerName:""
                              },
                              {
                                 answerName:"is a big problem",
                                 answerType:"checkBoxType",
                                 riskLevel:8,
                                 expectedAnswerName:"checked"
                              }
                           ],
                        },
                     }
                  ]
               }
            },
            {
               collectionRiskTypeId:35,
               name:"Job Satisfaction",
               riskLevel:"2",
               riskAppetite:"8",
               subRisks:[
                  {
                     collectionRiskTypeId:36,
                     name:"Job Description",
                     riskLevel:"2",
                     riskAppetite:"2",
                     subRisks:[
                        
                     ],
                     questions:{
                        
                     }
                  },
                  {
                     collectionRiskTypeId:37,
                     name:"work environment",
                     riskLevel:"2",
                     riskAppetite:"2",
                     subRisks:[
                        
                     ],
                     questions:{
                        
                     }
                  },
                  {
                     collectionRiskTypeId:38,
                     name:"Complaints procedure",
                     riskLevel:"2",
                     riskAppetite:"2",
                     subRisks:[
                        
                     ],
                     questions:{
                        
                     }
                  }
               ],
               questions:{
                  
               }
            }
         ]
      }
   ],
};