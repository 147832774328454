import React, { useEffect, useState, useRef } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import CardContent from '@mui/material/CardContent';
import { CONFIG } from '../config'
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info, KeyboardReturnOutlined, Label, TrainRounded, TrendingUpRounded } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import '../Css/Slider.css';
import Draggable from 'react-draggable';
import useStyle from '../Css/MainCss';
import Delete from '@mui/icons-material/Delete';
import { Editor } from '@tinymce/tinymce-react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";


function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));



const StyledTableRow = styled(TableRow)(({ theme }) => ({


    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const useStyles = makeStyles((theme) => ({
    right: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    root: {
        position: 'relative',
    },
    cardRiskManage: {
        width: '100%',
        border: '1px solid black'
    },
    tableTrStyle: {
        height: '19px !important'
    },
    mousePointer: {
        cursor: 'pointer'
    },
    stdMainCardHeader: {
        backgroundColor: '#4C7EE0 !important',
        fontSize: '13px !important',
        height: '50px',
        padding: '0px',
        marginTop: '-7px',
        color: 'white'
    },
    addrisktypebtn: {
        marginLeft: '0px !important',
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },

    btnBorderpurple: {
        border: '1px solid #9155FD !important',
        color: '#9155FD !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },
    stdCard: {
        height: '100%',
        border: '1px solid black'
    },
    scrollableDivToStandard: {
        height: '350px',
        overflow: 'auto',
        width: '100%',
    },
    btnColorPurple: {
        fontSize: '12px !important',
        backgroundColor: '#BACDF2 !important',
        height: '40px',
        textAlign: 'center',
        color: '#4C7EE0 !important',
        border: '1px solid #4C7EE0'
    },
    thStyle: {
        fontSize: '12px !important',
        lineHeight: '0.5rem !important',
        borderBottom: '0px !important'
    },
    riskImageSize: {
        width: '120px',
        height: '100px !important',
    },
    posRelativeAndDivBorder: {
        position: 'relative',
        border: '1px solid #4C7EE0',
        width: '120px',
        height: '100px !important',
    },
    root: {
        position: 'relative',
    },
    thStyleImage: {
        lineHeight: '0.5rem !important',
        borderBottom: '0px !important',
        width: '130px !important',
    },
    tableStyle: {
        // width: '55% !important',
        //minWidth: '720px !important'
    },
    btnBorderGreen: {
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        height: '40px'
    },

}));


const SuperAdminHeaderSettings = () => {



    const classe = useStyle();
    const classes = useStyles();
    const Input = styled('input')({
        display: 'none',
    });

    const editorRef = useRef(null);
    const [golobalCentreId, setGolobalCentreId] = React.useState('');
    const [selectedFile, setSelectedFile] = useState(null)
    const [preview, setPreview] = React.useState()

    // create a preview as a side effect, whenever selected file is changed
    React.useEffect(() => {
        getData();
        getTemplates()
        getAllCentresFromServiceSystem()
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])


    const [tinyMCETemplateList, setTinyMCETemplateList] = React.useState()

    var templateArr = [];
    function getTemplateHtml(fileName) {
        axios('https://www.daytaem.co.uk/readFile.php?q=' + fileName, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(function (res) {
                var tmpA = fileName.split(".")
                var templateObj = {};
                templateObj["title"] = tmpA[0].replace("RST_", "");
                templateObj["description"] = "Risk System - Template ";
                templateObj["content"] = res.data;
                templateArr.push(templateObj);
                setTinyMCETemplateList(templateArr);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    function getTemplates() {
        var filesArr = "";
        axios('https://www.daytaem.co.uk/getFile.php?q=aaaaaaa', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(function (response) {
                console.log(response);
                var resData = response.data;
                var arrTmp = resData.split("@@");
                var filesStr = arrTmp[1];

                filesArr = filesStr.split("]");
                var k = 1;

                var templateHtmlArr = [];
                for (var i = 1; i < filesArr.length; i++) {
                    if (filesArr[i] != "") {
                        if (filesArr[i].slice(0, 3) === "RST") {
                            var fileName = filesArr[i];
                            getTemplateHtml(fileName)
                        }
                    }

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function getData() {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        setGolobalCentreId(data.centreId);
        getAllLandingPagesData()
    }

    const [centreDesignData, setCentreDesignData] = useState([])


    function getAllLandingPagesData() {
        axios(CONFIG.MAIN_URL + `/pageHeaderContent/all`, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("All Collections Returns");
                console.log(response.data);
                setCentreDesignData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // console.log(selectedFile)
    const [saveBtnName, setSaveBtnName] = useState("Save");
    const [createheaderInfoMessage, setCreateHeaderInfoMessage] = useState("Create Edit Header Page Content");
    const [centreName, setCentreName] = useState('')

    const [collectionDescription, setCollectionDescription] = useState('')
    const [editCentreId, setEditCentreId] = useState('')
    const [showCollectionTable, setShowCollectionTable] = React.useState(true);


    const [chkLoginPageFlag, setChkLoginPageFlag] = React.useState(false);

    function getChkLoginPageHandler() {
      setChkLoginPageFlag(!chkLoginPageFlag);
      if (document.getElementById("chkLoginPage").checked) {
        clearFuction();
        setChkLoginPageFlag(true);
        centreDesignData.forEach((data) => {
            if (data.centreId === 'superadmin') {
                console.log(data)
                setEditCentreId(data.id);
                setEditCentreId('superadmin');
                setCentreName('');
                setSystemName(data.systemName)
                setBackgroundColor(data.backgroundColor)
                setPreview(data.logoUrl)
                setSaveBtnName("Update");
                setCreateHeaderInfoMessage("Edit Header Design");
            }
        })

      }
    }
  


    const [editGroup, setEditGroup] = useState(false);
    const [imgLocation, setImgLocation] = useState();

    const [backgroundColor, setBackgroundColor] = useState('#c9c9c5');
    const handleColor = (event) => {
        setBackgroundColor(event.target.value);
    };

    const [systemName, setSystemName] = useState('');
    const systemNameChange = (event) => {
        setSystemName(event.target.value);
    };




    const [allCentres, setAllCentres] = React.useState([]);
    function getAllCentresFromServiceSystem() {
        var data = '{getAllCentreList{centreId,centreName}}';
        axios(CONFIG.MAIN_URL + '/graphql', {
            method: 'post',
            headers: {
                'Content-Type': 'text/plain',
            },
            data: data,
        })
            .then(function (response) {
                var centreList = response.data.data.getAllCentreList;
                console.log("All Centre List");
                console.log(centreList);
                setAllCentres(centreList);
            })
            .catch(function (error) {
                console.log("Entity Loding error" + error);
            });
    }

    const handleChangeCentre = (event) => {
        clearFuction();
        setCentreName(event.target.value)
        centreDesignData.forEach((data) => {
            if (data.centreId === event.target.value) {
                setEditCentreId(data.id);
                setCentreName(data.centreId);
                setSystemName(data.systemName)
                setBackgroundColor(data.backgroundColor)
                setPreview(data.logoUrl)
                setSaveBtnName("Update");
                setCreateHeaderInfoMessage("Edit Header Design");
                setChkLoginPageFlag(false);
            }
        })

    };


    function editCentreData(id) {

        setEditCentreId(id);
        centreDesignData.forEach((data) => {
            if (data.id === id) {
                console.log(data)
                setEditCentreId(data.id);
                setCentreName(data.centreId);
                setBackgroundColor(data.backgroundColor)
                setPreview(data.logoUrl)
                setSaveBtnName("Update");
                setCreateHeaderInfoMessage("Edit Header Design");
                if (data.centreId === 'superadmin'){
                    setChkLoginPageFlag(true);
                    setCentreName('');
                }else{
                    setChkLoginPageFlag(false);
                }
            }
        })
    }

    function deleteCollectionData(id) {
        centreDesignData.forEach((item) => {
            if (item.id === id) {
                console.log(item)
                var sendJson = JSON.stringify(item);
                console.log("+++++++++++++++++++sendJson To Delete++++++++++++++++++++");
                console.log(sendJson);
                var endPoint = '/pageHeaderContent/delete';
                axios(CONFIG.MAIN_URL + endPoint, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: sendJson
                })
                    .then(res => {

                        alert("Deleted Successfully")
                        clearFuction();
                        getAllLandingPagesData()
                    })
                    .catch(function (error) {
                        alert('Error Deleting ');
                        console.log(error);
                    });
            }
        })

    }

    const [alreadyCreatedDesign, setAlreadyCreatedDesign] = useState(false)


    function saveHeaderFunction(location) {
        var obj = {};
        if (editCentreId != '') {
            obj["id"] = editCentreId;
        }
        if (document.getElementById("chkLoginPage").checked) {
            obj["centreId"] = 'superadmin';
        }else{
            obj["centreId"] = centreName;
        }
        obj["logoUrl"] = location;
        obj["systemName"] = systemName;
        obj["backgroundColor"] = backgroundColor;

        console.log(obj)

        var sendJson = JSON.stringify(obj);
        console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
        console.log(sendJson);
        var endPoint = '/pageHeaderContent/save';
        if (editCentreId != '') {
            endPoint = '/pageHeaderContent/update';
        }

        axios(CONFIG.MAIN_URL + endPoint, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: sendJson
        })
            .then(res => {

                if (editCentreId != '') {
                    alert('Successfully Updated ');
                } else {
                    alert('Successfully Saved ');
                }
                clearFuction();
                getAllLandingPagesData()
            })
            .catch(function (error) {
                alert('Error Updating the collection');
                console.log(error);
            });


    }

    function onFormSubmit(e) {

        let formdata = new FormData();
        formdata.append('file', selectedFile);
        formdata.append('centreId', golobalCentreId);
        if (selectedFile === null && editGroup) {
            saveHeaderFunction(imgLocation);
        } else {
            if (selectedFile === null) {
                saveHeaderFunction("");

            } else {

                axios(CONFIG.MAIN_URL + '/image/upload', {
                    method: 'post',
                    data: formdata,
                })
                    .then(function (response) {
                        console.log(response.data.location);
                        if (response.data.location === null || response.data.location === "") {
                            alert(response.data.message + " and please use a different image name");
                            return;
                        } else {
                            setImgLocation(response.data.location);
                            saveHeaderFunction(response.data.location)

                        }
                    })
                    .catch(function (error) {
                        alert(error);
                        console.log(error);
                    })

            }
        }
    }



    function clearFuction() {
        setFrameSize(8);
        setShowCollectionTable(true);
        setEditCentreId('')
        setCentreName('')
        setSystemName('')
        setBackgroundColor('#c9c9c5')
        setPreview()
        setSaveBtnName("Save");
        setCreateHeaderInfoMessage("Header Settings");
        setChkLoginPageFlag(false);
    }

    const previousCollectionTableRowData = (
        <TableBody>
            {centreDesignData.map((item) => {


                return (
                    <StyledTableRow key={item.id} className={classes.tableTrStyle}>
                        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                            <div style={{ whiteSpace: 'normal' }}>{item.centreId}</div>
                        </StyledTableCell>
                        <StyledTableCell
                            align="right"
                            className={classes.tableTrStyle}
                        >
                            <EditIcon
                                className={classes.mousePointer}
                                onClick={() => { editCentreData(item.id) }}
                            />
                        </StyledTableCell>
                        <StyledTableCell
                            align="right"
                            className={classes.tableTrStyle}
                        >
                            <Delete
                                className={classes.mousePointer}
                                onClick={() => { deleteCollectionData(item.id) }}
                            />
                        </StyledTableCell>
                    </StyledTableRow>
                )
            })}
        </TableBody>
    )


    const [frameSize, setFrameSize] = useState(8)




    return (
        <>
            <div>
                <Grid >
                    <Grid container spacing={2}>
                        <>
                            <Grid item xs={frameSize} >
                                <Card className={classes.cardRiskManage} >
                                    <CardContent>
                                        <Card >
                                            <CardHeader
                                                avatar={
                                                    <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                                        <Info />
                                                    </Avatar>
                                                }
                                                className={classe.commonMainTableHead}
                                                title={createheaderInfoMessage}
                                            />
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={5}>
                                                        <Box
                                                            component="form"
                                                            sx={{
                                                                '& .MuiTextField-root': { mt: 1, width: '100%' },
                                                            }}
                                                            noValidate
                                                            autoComplete="off"
                                                        > <FormControl fullWidth>
                                                                <TextField
                                                                    label="Select a Centre"
                                                                    sx={{ width: '100%' }}
                                                                    select
                                                                    value={centreName}
                                                                    onChange={handleChangeCentre}
                                                                >
                                                                    {allCentres.map((item) => (
                                                                        <MenuItem
                                                                            value={item.centreId}
                                                                        >
                                                                            {item.centreName}
                                                                        </MenuItem>
                                                                    ))}

                                                                </TextField>
                                                            </FormControl>

                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={1} style={{marginTop: '10px'}}><span style={{fontSize: '14px', fontWeight: 'bold', color: 'red'}}>OR</span></Grid>
                                                    <Grid item xs={6} style={{marginTop: '10px'}}>
                                                    <FormControlLabel
                                control={
                                  <Checkbox
                                    id={"chkLoginPage"}
                                    checked={chkLoginPageFlag}
                                    onClick={() => {
                                        getChkLoginPageHandler();
                                    }}

                                  />
                                }
                                label={
                                  <Typography
                                    sx={{ fontSize: "1rem !important" }}
                                  >
                                    Create Header on the Login Page
                                  </Typography>
                                }
                                className={"formControlLabelStyle"}
                                labelPlacement="start"
                                style={{ marginLeft: "5px" }}
                              />


                                                    </Grid>
                                                    {/* <Grid item xs={12}>You Can add only logo to your header or you can add logo and system Name both to your header</Grid> */}


                                                    <Grid item xs={12}></Grid>
                                                    <Grid item xs={6}>


                                                        <labe>System Name :</labe>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <TextField
                                                            id="title"
                                                            //label="System Name"
                                                            multiline
                                                            maxRows={2}
                                                            name='title'
                                                            value={systemName}
                                                            onChange={systemNameChange}
                                                            sx={{ minWidth: 300 }}
                                                        />




                                                    </Grid>
                                                    <Grid item xs={6}>

                                                        <label>Pick Header Color : </label>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;

                                                        <input
                                                            type='color'
                                                            style={{ width: '50%' }}
                                                            id="headerColor"
                                                            name="headerColor"
                                                            value={backgroundColor}
                                                            onChange={handleColor}
                                                        />

                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TableContainer>
                                                            <Table aria-label="spanning table" className={classes.tableStyle}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="center" rowSpan={2} className={classes.thStyleImage}>
                                                                            <Box sx={{ minWidth: 120 }} className={classes.riskImageSize}>
                                                                                <Card className={classes.root}>
                                                                                    <div className={classes.posRelativeAndDivBorder}>
                                                                                        {preview != null && preview != "" && <img src={preview} className={classes.riskImageSize} />}

                                                                                    </div>
                                                                                </Card>
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell align="right" className={classes.thStyle}>
                                                                            <Stack direction="row" spacing={2}>
                                                                                <label htmlFor="contained-button-file-1">
                                                                                    <Input accept="image/*" id="contained-button-file-1" type="file" name="file"
                                                                                        onChange={(event) => {

                                                                                            console.log(event.target.files[0]);
                                                                                            setSelectedFile(event.target.files[0]);
                                                                                            //setPreview(event.target.files[0])
                                                                                        }} />

                                                                                    <Button variant="contained" component="span" className={classes.btnColorPurple} >
                                                                                        Upload logo
                                                                                    </Button>
                                                                                </label>

                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            </Table>
                                                        </TableContainer>


                                                    </Grid>
                                                    <Grid item xs={6}>


                                                    </Grid>
                                                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            style={{ marginTop: '10px', height: '46px' }}
                                                            onClick={() => {
                                                                onFormSubmit();
                                                            }}
                                                        >
                                                            {saveBtnName}
                                                        </Button>&nbsp;&nbsp;
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            style={{ marginTop: '10px', height: '46px' }}
                                                            onClick={() => {
                                                                clearFuction()
                                                            }}
                                                        >
                                                            Clear
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </CardContent>
                                </Card>
                            </Grid>
                            {
                                showCollectionTable ?
                                    <Grid item xs={4}>
                                        <Card className={classes.stdCard}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                                        <Info />
                                                    </Avatar>
                                                }
                                                className={classes.stdMainCardHeader}
                                                title="Header Content Created"
                                            />
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <div className={classes.scrollableDivToStandard}>
                                                            <TableContainer>
                                                                <Table aria-label="customized table">
                                                                    <TableHead className={classe.commonSecondaryTableHead}>
                                                                        <TableRow>
                                                                            <StyledTableCell className={classe.commonTableThStyle} style={{ width: '70%' }}> Centre Name</StyledTableCell>
                                                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '15%' }}>Edit</StyledTableCell>
                                                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '15%' }}>Delete</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    {previousCollectionTableRowData}
                                                                </Table>
                                                            </TableContainer>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    : null
                            }
                        </>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default SuperAdminHeaderSettings


