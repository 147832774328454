import React, { useEffect, useState } from 'react'
import { FormGroup, Grid, makeStyles, TableFooter } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { CONFIG} from '../config'
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import userCentreEntitiesPageStyle from '../Css/UserCentreEntitiesPageCss'
import CircularProgress from '@mui/material/CircularProgress';
import RightBarDataForUserPages from '../Components/RightBarDataForUserPages';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import useStyle from '../Css/MainCss';
import { ArrowBack } from '@mui/icons-material';
import '../Css/CentreEntities.css'



function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const UserCentreEntitiesPage = () => {

  const navigate = useNavigate();
  const classe = useStyle();
  
  const [waitingOpen, setWaitingOpen] = React.useState(false);
  
  function compare( a, b ) {
    var nameA = a.createdDate; // ignore upper and lowercase
    var nameB = b.createdDate; // ignore upper and lowercase
  
    if ( nameA < nameB ){
        return -1;
    }
    if ( nameA > nameB ){
        return 1;
    }
    // names must be equal
    return 0;
  }
    
  
  const classes = userCentreEntitiesPageStyle();
    const Input = styled('input')({
      display: 'none',
    });



  React.useEffect(() => {
    getData();
  }, []);

  const [globalCentreId, setGlobalCentreId] = React.useState('');
  const [globalUserId, setGlobalUserId] = React.useState('');
  function getData () {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGlobalCentreId(data.centreId); 
    setGlobalUserId(data.userId);
    getBasicEntities();
    getCentreData(data.centreId);
    getCentreEntityData(data.centreId);
    console.log(data)
  }

  /** For Centre Entities */
  const [basicEntityData, setBasicEntityData] = React.useState([]);
  const [approvedBasicEntityData, setApprovedBasicEntityData] = React.useState([]);
  function getBasicEntities(){
    var data = '{allBasicEntityList{ basicEntityList{basicEntityId,entityUserKey,entityPropertyKey,entityName,tableName,iamRole,isActive,isMainRole,forceIamRole}}}';
    axios(CONFIG.MAIN_URL+'/graphql',{
    method: 'post',
    headers: { 
        'Content-Type': 'text/plain',
      },
    data : data,  
    })
    .then(function (response) {
        console.log("++++++++++++++++Return All Basic Entities++++++++++++++++");
        var basicEntityList = response.data.data.allBasicEntityList.basicEntityList;
        console.log(basicEntityList);
        setBasicEntityData(basicEntityList);
        var arr = [];
        basicEntityList.forEach((data, index)=>{
          if (data.iamRole != "superadmin" && data.iamRole != "ADMIN" && data.iamRole != "supplier" && data.iamRole != "serviceprovider" && data.iamRole != "volunteer"){
            arr.push(data);
          }
        })
        setApprovedBasicEntityData(arr);

    })
    .catch(function (error) {
        console.log("Basic Entity Loding error" + error);
    });
  }
  const [centreName, setCentreName] = React.useState('');
  function getCentreData(centreId){
    var data = '{getCentreData(centreId:\"' + centreId + '\"){centreId,centreName}}';
    axios(CONFIG.MAIN_URL+'/graphql',{
    method: 'post',
    headers: { 
        'Content-Type': 'text/plain',
      },
    data : data,  
    })
    .then(function (response) {
        console.log("++++++++++++++++Return centreData++++++++++++++++");
        var centreData = response.data.data.getCentreData;
        console.log(centreData);
        setCentreName(centreData.centreName);

    })
    .catch(function (error) {
        console.log("Centre Data Loding error" + error);
    });
  }
  const [previousCentreEntityData, setPreviousCentreEntityData] = React.useState([]);  
  function getCentreEntityData(centerId){
    var data = '{allEntityList{ entityDataList{entityName,entityId,isActive,createdBy,createdDate,lastModifiedBy,centreId,basicEntity{basicEntityId,entityUserKey,entityPropertyKey,entityName,tableName,iamRole,isActive,isMainRole,forceIamRole}}}}';
    axios(CONFIG.MAIN_URL+'/graphql',{
    method: 'post',
    headers: { 
        'Content-Type': 'text/plain',
      },
    data : data,  
    })
    .then(function (response) {
        var entityList = response.data.data.allEntityList.entityDataList;
        console.log("++++++++++++++++Return All centre entity List ++++++++++++++++++++");
        console.log(entityList);
        var tmpEntityData = [];
        var count = 1;
        entityList.forEach((item, index)=>{
            if (item.isActive && item.centreId === centerId) {  
                var be = item.basicEntity
                var arrayData = {}
                arrayData["id"] = count;
                arrayData["entityId"] = item.entityId;
                arrayData["entityName"] = item.entityName;
                arrayData["basicEntityId"] = item.basicEntity.basicEntityId;
                arrayData["basicEntityName"] = item.basicEntity.entityName;
                arrayData["basicForceIamRole"] = item.basicEntity.forceIamRole;
                arrayData["basicIamRole"] = item.basicEntity.iamRole;
                arrayData["createdBy"] = item.createdBy;
                arrayData["createdDate"] = item.createdDate;
                arrayData["lastModifiedBy"] = item.lastModifiedBy;
                tmpEntityData.push(arrayData);
                count ++;
            }
        })
        tmpEntityData = tmpEntityData.sort (compare);
        console.log(tmpEntityData);
        setPreviousCentreEntityData(tmpEntityData);
    })
    .catch(function (error) {
        console.log("Entity Loding error" + error);
    });
  }

  const renderText1 = (params) => {
    return (
        <Tooltip title={params.row.id}>
        <span className={classes.fontZise10}>{params.row.id}</span>        
        </Tooltip>
    )
  }

  const renderText2 = (params) => {
    return (
        <Tooltip title={params.row.entityId}>
        <span className={classes.fontZise10}>{params.row.entityId}</span>        
        </Tooltip>
    )
  }

  const renderText3 = (params) => {
    return (
        <Tooltip title={params.row.entityName}>
        <span className={classes.fontZise10}>{params.row.entityName}</span>        
        </Tooltip>
    )
  }

  const renderText4 = (params) => {
    return (
        <Tooltip title={params.row.basicEntityId}>
        <span className={classes.fontZise10}>{params.row.basicEntityId}</span>        
        </Tooltip>
    )
  }
  const renderText5 = (params) => {
    return (
        <Tooltip title={params.row.basicEntityName}>
        <span className={classes.fontZise10}>{params.row.basicEntityName}</span>        
        </Tooltip>
    )
  }
  
  const [saveBtnName, setSaveBtnName] = useState("Save");
  const [previousCentreEntityId, setPreviousCentreEntityId] = React.useState('');  
  const [previousCentreEntityCreatedBy, setPreviousCentreEntityCreatedBy] = React.useState('');  
  const [previousCentreEntityLastModifiedBy, setPreviousCentreEntityLastModifiedBy] = React.useState('');  
  function editEntity(no, entityId, entityName, basicEntityId, basicEntityName, createdBy, lastModifiedBy){
    setPreviousCentreEntityId(entityId);
    setSaveBtnName('Update');
    setSelectedBasicEntityId(basicEntityId);
    setSelectedBasicEntityName(basicEntityName);
    setCentreEntityName(entityName);
    setPreviousCentreEntityCreatedBy(createdBy);
    setPreviousCentreEntityLastModifiedBy(lastModifiedBy);
  }

  function clearFuction(){
    setPreviousCentreEntityId('');
    setSaveBtnName('Save');
    setSelectedBasicEntityId('');
    setSelectedBasicEntityName('');
    setCentreEntityName('')
    setPreviousCentreEntityCreatedBy('');
    setPreviousCentreEntityLastModifiedBy('');
  }

  const renderEditButton = (params) => {
    var bEntityName = params.row.basicEntityName;
    
    return (
        <>
        {bEntityName === "Admin" || bEntityName === "ADMIN" || bEntityName === "Volunteer" || bEntityName === "VOLUNTEER"?
         <strong>
            &nbsp;
        </strong>
        :
          <strong>
            <Button
                id={'btn'+params.row.id}
                variant="contained"
                color="primary"
                size="small"
                //className={classes.fontZise9AndBlue}
                onClick={() => {
                    editEntity(params.row.id, params.row.entityId, params.row.entityName, params.row.basicEntityId, params.row.basicEntityName, params.row.createdBy, params.row.lastModifiedBy)
                }}
            >
                Edit
            </Button>
          </strong>
        }

        </>
    )
}


  const columns = [
    { field: 'id', headerName: 'No', renderCell: renderText1, resizable: true, width: 10 },
    { field: 'entityId', headerName: 'Entity Id', renderCell: renderText2, resizable: true, cellClassName: 'super-app-theme--cell', width: 120, hide: true },
    { field: 'createdBy', headerName: 'createdBy', renderCell: renderText2, resizable: true, cellClassName: 'super-app-theme--cell', width: 120, hide: true },
    { field: 'lastModifiedBy', headerName: 'lastModifiedBy', renderCell: renderText2, resizable: true, cellClassName: 'super-app-theme--cell', width: 120, hide: true },
    { field: 'entityName', headerName: 'Centre Entity Name', renderCell: renderText3, width: 200, resizable: true, cellClassName: 'super-app-theme--cell'},
    { field: 'basicEntityId', headerName: 'Basic Id', renderCell: renderText4, resizable: true, cellClassName: 'super-app-theme--cell', width: 100, hide: true },
    { field: 'basicEntityName', headerName: 'Basic Entity Name', renderCell: renderText5, resizable: true, width: 200 },
    {
      field: 'action',
      headerName: 'Edit',
      sortable: false,
      renderCell: renderEditButton,
      disableClickEventBubbling: true,
      width: 150,
    }
  ];
  const [selectedBasicEntityId, setSelectedBasicEntityId] = React.useState('');
  const [selectedBasicEntityName, setSelectedBasicEntityName] = React.useState('');
  const handleChangeBasicEntity = (event) => {
    const bId = event.target.value;
    setSelectedBasicEntityId(bId);
    basicEntityData.forEach((data,index)=>{
        if (data.basicEntityId === bId) {  
            setSelectedBasicEntityName(data.entityName);  
        }
    })
};
const [centreEntityName, setCentreEntityName] = React.useState('');
const handleCentreEntityName = (event) => {
    setCentreEntityName(event.target.value);
};

function saveEntity(){
  var centerId = globalCentreId;
  var userId = globalUserId;
  if (selectedBasicEntityId === ''){
    alert("Please Select Basic Entity");
    return;
  }
  if (centreEntityName === ''){
    alert("Please enter Centre Entity Name");
    return;
  }
  var sendStr = '';
  if (previousCentreEntityId != ''){
    sendStr += "mutation{updateEntityData(entityId:" + previousCentreEntityId + ",entityName:\"" + centreEntityName + "\",isActive:true,createdBy:\"" + previousCentreEntityCreatedBy + "\",lastModifiedBy:\"" + userId + "\",centreId:\"" + centerId + "\",basicEntity:" + selectedBasicEntityId + "){entityId,entityName,centreId,createdBy}}";
  }else{
    sendStr += "mutation{createEntityData(entityName:\"" + centreEntityName + "\",isActive:true,createdBy:\"" + userId + "\",lastModifiedBy:\"" + userId + "\",centreId:\"" + centerId + "\",basicEntity:" + selectedBasicEntityId + "){entityId,entityName,centreId,createdBy}}"; 
    var canTake = true;
    previousCentreEntityData.forEach((data,index)=>{
      if (data.basicEntityId === selectedBasicEntityId) {  
        canTake = false;
      }
    })
    if (!canTake){
      alert("Sorry!. You have already selected this basic entity.");
      return;
    }

  }
  
  setWaitingOpen(true);
  axios(CONFIG.MAIN_URL+'/graphql',{
    method: 'post',
    headers: { 
      'Content-Type': 'text/plain'
      },
    data : sendStr  
  })
  .then(function (response) {
    console.log(JSON.stringify(response.data));
    let rtnData = response.data;
    console.log("++++++++++ Entity Data Data save in PG");
    console.log(rtnData);
    setWaitingOpen(false);
    if (previousCentreEntityId != ''){
      alert("Centre Entity updated successfully.");
    }else{
      alert("Centre Entity saved successfully.");
    }
    clearFuction();  
    getCentreEntityData(centerId);
  })
  .catch(function (error) {
    console.log(error);
    setWaitingOpen(false);
    alert("Error saving Collection Data to Database Server -:" + error);
  });

}




  /** End of For Centre Entities */








const [headerInfoMessage, setheaderInfoMessage] = useState("Assign your Centre Entities based on Basic Entities provided by the System Administrator");
const [headerInfoMessage1, setheaderInfoMessage1] = useState("Previous Centre Entities");
const [PageName, setPageName] = useState('Assign Centre Entities');
  return (
    <>
        <RightBarDataForUserPages PageName={PageName} CentreName={centreName}/>
        <div className={classes.pageTop}>
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.pageBodyStyle}>
                    <Grid item xs={12}>
                        <div role="presentation" onClick={handleClick}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" to="/login">
                              <span className='breadcrumbsStyle'>Home</span>
                            </Link>
                            <Typography color="text.primary"><span className='breadcrumbsStyle'>Main menu - Assign Centre Entities</span></Typography>
                        </Breadcrumbs>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                      <a style={{float: 'right',marginTop: '-36px',color: '#0056b3', cursor:'pointer'}} onClick={() => navigate(-1)}><ArrowBack/> <span className='breadcrumbsStyle'>Go Back</span></a>
                    </Grid>
                    
                    <Grid item className='maintableleft'>
                        <Card id='focusCardId' style={{"width": "100% !important", border:"1px solid black"}}>
                            <CardHeader 
                                avatar={
                                    <Avatar sx={{ bgcolor: '#9155FD', width:'25px', height:'25px' }}>
                                        <Info/>
                                    </Avatar>
                                    } 
                                    className={CONFIG.EXPERT_LOGIN? classe.commonMainTableHead :classes.stdMainCardHeader} 
                                    title={headerInfoMessage}
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box sx={{ minWidth: 120 }}>    
                                            <FormControl fullWidth>
                                                <TextField
                                                    label='Select a Basic Entities provided By the System'
                                                    sx={{ width: '100%' }}
                                                    select
                                                    value={selectedBasicEntityId}
                                                    onChange={handleChangeBasicEntity}
                                                    >
                                                    {approvedBasicEntityData.map((item)=>(
                                                        <MenuItem 
                                                            value={item.basicEntityId}
                                                            >
                                                            {item.entityName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                      <Grid item xs={12}>
                                        <Box component="form" sx={{'& .MuiTextField-root': { m: 0, width: '100%' },}} noValidate autoComplete="off">
                                          <div>
                                            <TextField
                                                id="centreEntityName"
                                                label="Type Centre entity name based on the above selected basic entity"
                                                multiline
                                                maxRows={2}
                                                value={centreEntityName}
                                                onChange={handleCentreEntityName}
                                            />
                                          </div>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Stack direction="row" spacing={2} style={{float: 'right'}}>
                                          <Button variant="contained" type='submit' 
                                              onClick={e => saveEntity()}
                                              >
                                              {saveBtnName}
                                            </Button>
                                          <Button variant="contained" 
                                             onClick={clearFuction} 
                                             >
                                             Clear</Button>
                                        </Stack>

                                      </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item className='maintableright'>
                        <Card id='focusCardId' style={{"width": "100% !important", border:"1px solid black"}}>
                            <CardHeader 
                                avatar={
                                    <Avatar sx={{ bgcolor: '#9155FD', width:'25px', height:'25px' }}>
                                        <Info/>
                                    </Avatar>
                                    } 
                                    className={CONFIG.EXPERT_LOGIN? classe.commonMainTableHead :classes.stdMainCardHeader} 
                                    title={headerInfoMessage1}
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                    <Grid container spacing={2}>

<Box    
sx={{
height: 300,
width: '100%',
'& .super-app-theme--cell': {
whiteSpace: 'normal !important',
lineHeight: '1',
},
'& .super-app-theme--Open': {
fontSize: '10px !important',
}, 

}}
>





    <div style={{ height: 325, width: '100%',backgroundColor: '#ffffff', fontSize: '9px !important' }}>
    <DataGrid
        rows={previousCentreEntityData}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        getRowClassName={`super-app-theme--Open`}
        disableSelectionOnClick
    />
    </div>

    </Box>
</Grid>



                                    </Grid>
                                   </Grid> 
                                </CardContent>
                            </Card>

                        </Grid>    



                </Grid>
            </Grid>  
        </div>

        


        
















  {/** Dialog Waiting */}
  <Dialog
    maxWidth={'md'}
    open={waitingOpen}
    PaperProps={{style:{backgroundColor: 'transparent', boxShadow: 'none'}}} 
    >
    <CircularProgress disableShrink />
  </Dialog>










    </>
  )
}

export default UserCentreEntitiesPage