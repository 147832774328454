import React, { useEffect, useState, useRef } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import CardContent from '@mui/material/CardContent';
import { CONFIG } from '../config'
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info, KeyboardReturnOutlined } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import '../Css/Slider.css';
import "../Css/DmgRiskGpt.css";

import Draggable from 'react-draggable';
import useStyle from '../Css/MainCss';
import Delete from '@mui/icons-material/Delete';
import { Editor } from '@tinymce/tinymce-react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Close } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import CircularWithValueLabel from './ProgressTimer';
import parseHtml from 'html-react-parser';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  root: {
    position: 'relative',
  },
  cardRiskManage: {
    width: '100%',
    border: '1px solid black'
  },
  tableTrStyle: {
    height: '19px !important'
  },
  mousePointer: {
    cursor: 'pointer'
  },
  stdMainCardHeader: {
    backgroundColor: '#4C7EE0 !important',
    fontSize: '13px !important',
    height: '50px',
    padding: '0px',
    marginTop: '-7px',
    color: 'white'
  },
  addrisktypebtn: {
    marginLeft: '0px !important',
    border: '1px solid #4C7EE0 !important',
    color: '#4C7EE0 !important',
    padding: '5px 10px !important',
    height: '40px !important',
  },

  btnBorderpurple: {
    border: '1px solid #9155FD !important',
    color: '#9155FD !important',
    padding: '5px 10px !important',
    height: '40px !important',
  },
  stdCard: {
    height: '100%',
    border: '1px solid black',
    marginTop: '10px'
  },
  scrollableDivToStandard: {
    height: '200px',
    overflow: 'auto',
    width: '100%',
  },

}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '13px'
  },
}));


const SchemaMaintainers = () => {

  const classe = useStyle();
  const classes = useStyles();

  React.useEffect(() => {
    getAllSchemas()
  }, [])


  const [frameSize, setFrameSize] = useState(8)
  const [createheaderInfoMessage, setCreateHeaderInfoMessage] = useState("Create A New Schema");
  const [schemaName, setSchemaName] = useState('')
  const [validateSchema, setValidateSchema] = useState('')
  const [validateJson, setValidateJson] = useState('')
  const [validateSchemaId, setValidateSchemaId] = useState('')
  const [saveSchema, setSaveSchema] = useState('save schema')



  const schemaNameChange = (event) => {
    setSchemaName(event.target.value);
  }

  const handleChangeJsonForSchema = (event) => {
    setValidateSchema(event.target.value);

  }

  const handleChangeValidateJson = (event) => {
    setValidateJson(event.target.value);

  }

  const [getAllShemas, setGetAllSchema] = useState([])

  function getAllSchemas() {
    axios(CONFIG.MAIN_URL + '/schemaformat/all', {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("+++++++++++++++++All schemas++++++++++++++++++");
        console.log(response.data);
        setGetAllSchema(response.data)


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const previousSchemaTableRowData = (
    <TableBody>
      {getAllShemas.map((item) => {
        return (
          <StyledTableRow key={item.id} className={classes.tableTrStyle}>
            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
              <div style={{ whiteSpace: 'normal' }}>{item.name}</div>
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={classes.tableTrStyle}
            >
              <EditIcon
                className={classes.mousePointer}
                onClick={() => { editSchemaData(item.id) }}
              />
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={classes.tableTrStyle}
            >
              <Delete
                className={classes.mousePointer}
                onClick={() => { deleteSchemaData(item.id) }}
              />
            </StyledTableCell>
          </StyledTableRow>
        )
      })}
    </TableBody>
  )

  /** GPT Schema create */
  const wordstyle = 'wordStyle'
  const [openRiskGpt, setOpenRiskGpt] = React.useState(false);
  const [openRiskGptScroll, setOpenRiskGptScroll] = React.useState('paper');

  const [gptText, setGptText] = React.useState('');
  const handleChangeGptText = (e) => {
    setGptText(e.target.value);
  };


  function createSchemaUsingRiskGpt() {
    if (schemaName === '') {
      alert("Please insert the Schema name")
      return;
    }
    setGptTextArray([])
    setOpenRiskGpt(true)
    setOpenRiskGptScroll('paper')


  }
  const [loading, setLoading] = React.useState(false);
  const [gptTextArray, setGptTextArray] = React.useState([]);

  function sentToChatGtp() {
    var obj = {}
    obj["userId"] = "SUP"
    obj["createdBy"] = "superadmin"

    var objArr = [];

    var obj1 = {}
    obj1["messageId"] = "001";
    obj1["message"] = gptText;
    objArr.push(obj1);
    obj["chats"] = objArr
    var sendJson = JSON.stringify(obj)
    setLoading(true)

    axios(CONFIG.MAIN_URL + '/openai/advancechat', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: sendJson,
    })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        var obj = {};
        obj["question"] = gptText;
        var ans = response.data.answer.replaceAll('\n', '<br>')
        obj["answer"] = response.data.answer;
        setGptTextArray([...gptTextArray, obj]);
        setGptText('')
        setLoading(false)

      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)
        alert("Error saving Question -:" + error);
      });


  }

  function finishToChatGtp() {
    gptTextArray.forEach((item, index) => {
      if (index === (gptTextArray.length - 1)) {
        console.log(item)

        try {
          var ans = item.answer.replaceAll('\n', '');
          ans = ans.replaceAll("```", "@SP@");
          ans = ans.replaceAll("`", "'");
          ans = ans.replaceAll('"', "'");
          console.log(ans)
          const myArray = ans.split("@SP@");
          var validateSchemaStr = myArray[1].substring(4).replace(/'/g, '"');
          validateSchemaStr = JSON.stringify(JSON.parse(validateSchemaStr), null, "\t");
          console.log(validateSchemaStr);
          setValidateSchema(validateSchemaStr);
          setOpenRiskGpt(false);
        } catch (error) {
          alert("Invalid Json Schema. Please check your JSON String : " + error)
          return
        }


      }
    })
  }

  function resetGtp() {
    setGptTextArray([])
    setGptText('')
  }



  /** End GPT Schema create */


  /** Edit Schema */
  function editSchemaData(id) {
    setValidateSchemaId(id)
    getAllShemas.forEach((data) => {
      if (data.id === id) {
        setSchemaName(data.name)
        if (data.schemaFormat != undefined && data.schemaFormat != null && data.schemaFormat != '') {
          var schemaStr = data.schemaFormat;
          var validateSchemaStr = schemaStr.replace(/'/g, '"');
          validateSchemaStr = JSON.stringify(JSON.parse(validateSchemaStr), null, "\t");
          console.log(validateSchemaStr)
          setValidateSchema(validateSchemaStr)
        } else {
          setValidateSchema('')
        }

        if (data.sampleJson != undefined && data.sampleJson != null && data.sampleJson != '') {
          var jsonStr = data.sampleJson.replace(/'/g, '"');
          var validateJsonStr = JSON.stringify(JSON.parse(jsonStr), null, "\t");
          setValidateJson(validateJsonStr)
        } else {
          setValidateJson('')
        }
        setSaveSchema("update schema")

      }
    })

  }

  function deleteSchemaData(id) {
    var arr = [];
    getAllShemas.forEach((item) => {
      if (item.id === id) {
        var schemaName = item.name;
        var sendJson = JSON.stringify(item);
        console.log("+++++++++++++++++++sendJson To Delete++++++++++++++++++++");
        console.log(sendJson);
        var endPoint = '/schemaformat/delete';
        axios(CONFIG.MAIN_URL + endPoint, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          data: sendJson
        })
          .then(res => {

            alert('Successfully Deleted the Schema \" ' + schemaName + '\"');
            getAllSchemas()

            clearFunction()
          })
          .catch(function (error) {
            alert('Error Deleting the Schema \" ' + schemaName + '\"');
            console.log(error);
          });
      }
    })
  }

  function validateSchemaaFunction() {
    try {
      JSON.parse(validateSchema);
      alert("Your schema is a valid JSON schema.");
    } catch (error) {
      alert(error);
    }
  }

  function validateJsonFunction() {
    try {
      JSON.parse(validateJson);
      alert("Your schema is a valid JSON schema.");
    } catch (error) {
      alert(error);
    }
  }


  function validateFormat1Fuction() {
    const Ajv = require('ajv');
    const ajv = new Ajv({ allErrors: true });
    try {
      var jObj = JSON.parse(validateJson);
      var jObj11 = JSON.parse(validateSchema);

      var validate = ajv.compile(jObj11);
      const valid = validate(jObj);
      if (!valid) {
        var errorStr = "Error validating JSON data With JSON-Schema\n\n";
        var count = 1
        validate.errors.forEach((data) => {
          errorStr = errorStr + count + ")\n";
          errorStr = errorStr + "    Error : " + data.message + "\n";
          errorStr = errorStr + "    Error Path : " + data.instancePath + "\n";
          count++;
        })
        console.log(validate.errors);
        alert(errorStr)
        return;
      }
      else {
        alert("valid string with json schems")
      }
    } catch (error) {
      alert("Invalid Json in the Text Area. Please correct it and Press validate button again")
      return;
    }

  }

  function clearFunction() {
    setValidateJson('')
    setValidateSchema('')
    setSchemaName('')
    setValidateSchemaId('')
    setSaveSchema("save schema")

  }


  function saveSaveAndUpdateFunction() {
    if (schemaName === '') {
      alert("Please Enter JSON Schema");
      return;
    }
    if (validateSchema === '') {
      alert("Please Enter JSON Schema");
      return;
    }


    if (validateJson != '') {
      const Ajv = require('ajv');
      const ajv = new Ajv({ allErrors: true });
      try {
        var jObj = JSON.parse(validateJson);
        var jObj11 = JSON.parse(validateSchema);
        var validate = ajv.compile(jObj11);
        const valid = validate(jObj);
        if (!valid) {
          var errorStr = "Error validating JSON data With JSON-Schema\n\n";
          var count = 1
          validate.errors.forEach((data) => {
            errorStr = errorStr + count + ")\n";
            errorStr = errorStr + "    Error : " + data.message + "\n";
            errorStr = errorStr + "    Error Path : " + data.instancePath + "\n";
            count++;
          })
          console.log(validate.errors);
          alert(errorStr)
          return;
        }
      } catch (error) {
        alert("Invalid Json in the Text Area. Please go back and correct it")
        return;
      }
    }

    try {
      var jString = JSON.parse(validateSchema);
    } catch (error) {
      alert("Invalid Json Schema. Please check your JSON schema : " + error)
      return
    }

    var validateJsonStr = "";

    if (validateJson != '') {
      try {
        var jString = JSON.parse(validateJson);
        validateJsonStr = validateJson.replace(/"/g, "'");
        validateJsonStr = validateJsonStr.replace(/[\r\n]/gm, '');
      } catch (error) {
        alert("Invalid Json String. Please check your JSON String : " + error)
        return
      }
    }




    var validateSchemaStr = validateSchema.replace(/"/g, "'");
    validateSchemaStr = validateSchemaStr.replace(/[\r\n]/gm, '');

    var obj = {};
    if (validateSchemaId != '') {
      obj["id"] = validateSchemaId;

    }
    obj["name"] = schemaName;
    obj["schemaFormat"] = validateSchemaStr;
    obj["sampleJson"] = validateJsonStr;

    var data = JSON.stringify(obj);
    console.log(data);



    if (validateSchemaId != '') {
      var url = '/schemaformat/update'
    } else {
      url = '/schemaformat/save'
    }
    axios(CONFIG.MAIN_URL + url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(function (response) {
        if (validateSchemaId != '') {
          alert("Schema Updated successfully");
        }
        else {
          alert("Schema saved successfully");
        }

        getAllSchemas()
        console.log(response.data);
        clearFunction()
        setSaveSchema("save schema")

      })
      .catch(function (error) {
        console.log(error);
        alert("Error saving Risk Type -:" + error);
      });
  }


  return (
    <div>
      <Grid>
        <Grid container spacing={2}>
          <>
            <Grid item xs={12} >
              <Card className={classes.cardRiskManage} >
                <CardContent>
                  <Card >
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                          <Info />
                        </Avatar>
                      }
                      className={classe.commonMainTableHead}
                      title={createheaderInfoMessage}
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box
                            component="form"
                            sx={{
                              '& .MuiTextField-root': { mt: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <div>
                              <TextField
                                id="title"
                                label="Schema Name"
                                multiline
                                maxRows={2}
                                name='title'
                                required
                                value={schemaName}
                                onChange={schemaNameChange}
                              />
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            size="small"
                            style={{ height: '35px', width: '300px', marginTop: '10px' }}
                            onClick={() => createSchemaUsingRiskGpt()}
                          >
                            Create / Amend the JSON schema using RISKGPT
                          </Button>&nbsp;&nbsp;&nbsp; OR
                        </Grid>

                        <Grid item xs={5}>
                          <p>Write your Schema here*</p>
                        </Grid>
                        <Grid item xs={7}>
                          <p>If you need to validate and update as a sample json string with the left side schema, Write your Json String here:</p>
                        </Grid>

                        <Grid item xs={5}>

                          <textarea
                            name="postContent"
                            rows={12}
                            cols={62}
                            value={validateSchema}
                            onChange={handleChangeJsonForSchema}
                            style={{ marginTop: '-21px' }}
                          />
                          <Button
                            variant="outlined"
                            size="small"
                            style={{ height: '35px', width: '150px', marginTop: '10px' }}
                            onClick={() => validateSchemaaFunction()} >
                            validate Schema
                          </Button>
                        </Grid>

                        <Grid item xs={5}>

                          <textarea
                            name="postContent"
                            rows={12}
                            cols={62}
                            value={validateJson}
                            onChange={handleChangeValidateJson}
                            style={{ marginTop: '-21px' }}
                          />

                          <Button
                            variant="outlined"
                            size="small"
                            style={{ height: '35px', width: '150px', marginTop: '10px' }}
                            onClick={() => validateJsonFunction()}
                          >
                            validate json
                          </Button>
                        </Grid>

                        <Grid item xs={2} style={{ marginTop: '-21px' }}>

                          <Button
                            variant="outlined"
                            size="small"
                            style={{ height: '40px', width: '180px' }}
                            onClick={() => validateFormat1Fuction()}
                          >

                            validate json with schema
                          </Button>




                        </Grid>

                        <Grid item xs={12}>
                          <br></br>
                          <Button variant="contained" onClick={() => saveSaveAndUpdateFunction()}>{saveSchema}</Button>&nbsp;&nbsp;&nbsp;
                          <Button variant="contained" onClick={() => clearFunction()}>CLEAR</Button>
                        </Grid>

                        <Grid item xs={12}>
                          <Card className={classes.stdCard}>
                            <CardHeader
                              avatar={
                                <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                  <Info />
                                </Avatar>
                              }
                              className={classes.stdMainCardHeader}
                              title="Schemas Created"
                            />
                            <CardContent>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <div className={classes.scrollableDivToStandard}>
                                    <TableContainer>
                                      <Table aria-label="customized table">
                                        <TableHead className={classe.commonSecondaryTableHead}>
                                          <TableRow>
                                            <StyledTableCell className={classe.commonTableThStyle} style={{ width: '70%' }}>Schema Name</StyledTableCell>
                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '15%' }}>Edit</StyledTableCell>
                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '15%' }}>Delete</StyledTableCell>
                                          </TableRow>
                                        </TableHead>
                                        {previousSchemaTableRowData}
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>

                      </Grid>





                    </CardContent>
                  </Card>
                </CardContent>
              </Card>
            </Grid>

          </>

        </Grid>
      </Grid>




      <Dialog
        open={openRiskGpt}
        onClose={false}
        scroll={openRiskGptScroll}
        aria-labelledby="collection-data-dialog-title"
        aria-describedby="collection-data-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>Create / Edit Schema for - "{schemaName}" - Using RiskGpt
          </Typography>
          <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
            <Tooltip title="Close"><Close onClick={() => setOpenRiskGpt(false)} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={openRiskGptScroll === 'paper'}>
          <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} noValidate autoComplete="off">
                  <textarea
                    variant="outlined"
                    value={gptText}
                    id='gptText'
                    rows={3}
                    style={{ width: "100%" }}
                    onChange={handleChangeGptText}
                  />
                </Box>

              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  onClick={() => {
                    sentToChatGtp()
                  }}
                  style={{ height: '50px', width: '100%' }}>
                  Send To Gpt
                </Button>
              </Grid>

              <Grid item xs={12} style={{ height: '400px', overflowY: 'scroll' }}>
                <Grid container spacing={1}>
                  {gptTextArray.slice().reverse().map((item) => (
                    <>
                      <Grid item xs={1}>
                        <BootstrapTooltip title='Risk GPT'>
                          <Avatar sx={{ bgcolor: green[500], height: '25px', width: '25px', fontSize: '1.0rem !important' }} variant="square">
                            <span className={wordstyle}>Q</span>
                          </Avatar>
                        </BootstrapTooltip>
                      </Grid>
                      <Grid item xs={11}>
                        {item.question}

                      </Grid>
                      <Grid item xs={1}>
                        <BootstrapTooltip title='Risk GPT'>
                          <Avatar sx={{ bgcolor: red[500], height: '25px', width: '25px', fontSize: '1.0rem !important' }} variant="square">
                            <span className={wordstyle}>A</span>
                          </Avatar>
                        </BootstrapTooltip>
                      </Grid>
                      <Grid item xs={11}>
                        {parseHtml(item.answer.replaceAll('\n', '<br>'))}

                      </Grid>
                    </>
                  ))}



                </Grid>


              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => finishToChatGtp()}>Apply Json to Schema Area</Button>
          <Button onClick={() => resetGtp()}>Reset</Button>
          <Button onClick={() => setOpenRiskGpt(false)}>Close</Button>


        </DialogActions>
      </Dialog>

      <Dialog
        open={loading}
        onClose={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          <span style={{ textAlign: 'center' }}><br></br>
            Please Wait .......</span>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <CircularWithValueLabel
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>




    </div>
  )
}

export default SchemaMaintainers