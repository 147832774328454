import React, { useEffect, useState, useRef } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import CardContent from '@mui/material/CardContent';
import { CONFIG } from '../config'
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info, KeyboardReturnOutlined } from '@mui/icons-material';
import '../Css/Slider.css';
import useStyle from '../Css/MainCss';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';




function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const SuperAdminLoginCentreLimitation = () => {
  const classes = useStyle();
  const Input = styled('input')({
    display: 'none',
  });

  const [golobalCentreId, setGolobalCentreId] = React.useState('');

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    getData();
  }, [])


  function getData() {
    //let data = sessionStorage.getItem('sessionstorage');
    //data = JSON.parse(data);
    //setGolobalCentreId(data.centreId);
    getAllUpdatedCentres();
    getAllCentresFromServiceSystem();
  }

  const [allCentres, setAllCentres] = React.useState([]);  
  function getAllCentresFromServiceSystem(){
      var data = '{getAllCentreList{centreId,centreName}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          var centreList = response.data.data.getAllCentreList;
          console.log("All Centre List");
          console.log(centreList);
          setAllCentres(centreList);
      })
      .catch(function (error) {
          console.log("Entity Loding error" + error);
      });
  }

  /** Get All Updated Centers */
  const [allUpdatedCentres, setAllUpdatedCentres] = React.useState([]);
  function getAllUpdatedCentres(){
    axios(CONFIG.MAIN_URL+'/centreLimitation/all',{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get All Updated Centers");
      console.log((response.data));
      setAllUpdatedCentres(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  }



  const [centreId, setCentreId] = React.useState('');
  const [selectedCentreCollectionId, setSelectedCentreCollectionId] = React.useState('');
  const handleChangeCentre = (event) => {
    var id = event.target.value;
    setCentreId(id);
    var found = false;
    allUpdatedCentres.forEach((item)=>{
      if (item.centreId === id ){
        found = true;
        setSelectedCentreCollectionId(item.id);
        setMaximumNumber(item.maximumRiskCollection);
        setMaximumNumberTrasfered(item.maximumCollectionTransfered);
        setSaveBtnName('Update');
      }
    });
    if (!found){
      setSelectedCentreCollectionId('');
      setMaximumNumber('');
      setMaximumNumberTrasfered('');
      setSaveBtnName('Save');
    }

  };

  const [maximumNumber, setMaximumNumber] = React.useState('');
  const handleMaximumNumber = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setMaximumNumber(value);
  };

  const [maximumNumberTrasfered, setMaximumNumberTrasfered] = React.useState('');
  const handleMaximumNumberTrasfered = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setMaximumNumberTrasfered(value);
  };

  const [saveBtnName, setSaveBtnName] = React.useState('Save');

    /** Clear Maximum Questionnaire Collection */
    function clearFuction() {
        setSaveBtnName('Save');
        setMaximumNumber('');
        setMaximumNumberTrasfered('');
        setCentreId('');
        setSelectedCentreCollectionId('');
      }
      /** Save/ Update Maximum Questionnaire Collection */
      function onFormSubmit() {
        var canSave = true;
        if (centreId === ''){
          canSave = false;
        }
        if (maximumNumber === ''){
          canSave = false;
        }
        if (maximumNumberTrasfered === ''){
          canSave = false;
        }
        if (canSave) {
          var obj = {};
          if (selectedCentreCollectionId != ''){
            obj["id"] = selectedCentreCollectionId;
          }
          obj["centreId"] = centreId;
          obj["maximumRiskCollection"] = maximumNumber;
          obj["maximumCollectionTransfered"] = maximumNumberTrasfered;
          var sendJson = JSON.stringify(obj);
          console.log("+++++++++++++++++++sendJson++++++++++++++++++++");
          console.log(sendJson);
          var endPoint = '/centreLimitation/create';
          if (selectedCentreCollectionId != ''){
            endPoint = '/centreLimitation/update';
          }
          axios(CONFIG.MAIN_URL+endPoint,{
            method: 'post',
            headers: { 
              'Content-Type': 'application/json',   
            },  
            data:sendJson
          })
          .then(res =>  {
              console.log("++++++++++++++Update Center Limitation+++++++++++++++++");
              console.log(res.data);
              getAllUpdatedCentres();
              if (selectedCentreCollectionId != ''){
                alert('Successfully Updated the Maximum Number of Questionnaire Collection');
              }else{
                alert('Successfully Save the Maximum Number of Questionnaire Collection');
              }  
              clearFuction();
          })
          .catch(function (error) {
              console.log(error);
          });
    
        }else{
          if (centreId === ''){
            alert("Please select a centre");
          }else{
            if (maximumNumber === ''){
              alert("Please Enter Maximum Questionnaire Collection Allowed");
            }else{
              if (maximumNumberTrasfered === ''){
                alert("Please Enter Maximum Number of transfers Questionnaire Collection Allowed");
              }
            }
          }
        }
    
    
      }
    



  

  
  


  


  const [headerInfoMessage, setHeaderInfoMessage] = useState('Maintenance Centre Questionnaire Collection Limitation')


  return (
    <>
        <div>
            <Grid >
                <Grid container spacing={2}>
                <Grid item xs={12}>
              <Card id='focusCardId' style={{"width": "100% !important", border:"1px solid black"}}>
                <CardHeader 
                    avatar={
                        <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                            <Info/>
                        </Avatar>
                    } 
                    className={classes.commonMainTableHead} 
                    title={headerInfoMessage}
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box sx={{ minWidth: 120}}>    
                        <FormControl fullWidth>
                            <TextField
                                label="Select a Centre"
                                sx={{ width: '100%' }}
                                select
                                value={centreId}
                                onChange={handleChangeCentre}
                                >
                                {allCentres.map((item)=>(
                                    <MenuItem 
                                        value={item.centreId}
                                        >
                                        {item.centreName}{' ('}{item.centreId}{')'}
                                    </MenuItem>
                                ))}

                            </TextField>
                        </FormControl>
                      </Box>
                      </Grid>
                      <Grid item xs={4} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="maximunNumber"
                              label="Type Maximum Questionnaire Collection Allowed"
                              multiline
                              maxRows={1}
                              value={maximumNumber}
                              onChange={handleMaximumNumber}
                            />
                          </div>
                        </Box> 
                      </Grid>
                      <Grid item xs={4} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="maximunNumber"
                              label="Type Maximum number of transfers Allowed"
                              multiline
                              maxRows={1}
                              value={maximumNumberTrasfered}
                              onChange={handleMaximumNumberTrasfered}
                            />
                          </div>
                        </Box> 
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2} style={{float: 'right'}}>
                          <Button variant="contained" type='submit' onClick={e => onFormSubmit(e)}>{saveBtnName}</Button>
                          <Button variant="contained" onClick={clearFuction} >Clear</Button>
                        </Stack>
                      </Grid>
                    </Grid>  
                </CardContent>  
              </Card>  
            </Grid>




                </Grid>
            </Grid>
      </div>
    </>
  )
}

export default SuperAdminLoginCentreLimitation
