import React, {useEffect, useState} from 'react'
import { FormControlLabel, Grid, makeStyles, Tooltip } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow'; 
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Links from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { textAlign } from '@mui/system';
import {CONFIG} from '../config';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import RiskTypeQustionDisplay from '../Components/RiskTypeQustionDisplay';
import { Close, Info } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import '../Css/Slider.css';
import RightBarDataForUserPages from '../Components/RightBarDataForUserPages';
import Draggable from 'react-draggable';
import useStyle from '../Css/MainCss';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import RiskLevelSlider from './RiskLevelSlider';
import RiskAppetiteSlider from './RiskAppetiteSlider';


function PaperComponentMainRisk(props) {
  return (
    <Draggable
      handle="#main-risk-scroll-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function PaperComponentSubRisk(props) {
  return (
    <Draggable
      handle="#sub-risk-scroll-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}





function PaperComponentRiskEdit(props) {
  return (
    <Draggable
      handle="#edit-risk-scroll-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  
}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const useStyles = makeStyles((theme) => ({ 
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageBtn:{

    height: '60px',
    width: '170px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '12px !important',
  },
  headerLable:{

    color: '#827d7d',
  },
  pageTop:{

    marginTop: '115px',
  },
  pageBodyStyle: {
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '12px',
    /*backgroundColor: '#f7f7f7',*/
    marginLeft: '25px',
  },
  root: {
    position: 'relative',
  },
  posRelative: {
    position: 'relative',
  },
  topImagePos: {
    position: 'absolute', 
    top: '33%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  topHeaderText1: {
    color: '#9155FD',
    fontSize:'25px', 
    paddingBottom:'10px',
  },
  topHeaderText2: {
    color: '#3A3541',
    fontSize:'12px', 
    textAlign:'center',
    fontFamily: 'Inter',
  },
  thStyle: {
    fontSize: '12px !important',
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important'
  },
  thStyleImage: {
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important',
    width: '130px !important',
  },
  btnColorPurple:{
    fontSize: '12px !important',
    backgroundColor: '#E5EAF4 !important',
    height:'40px',
    textAlign:'center',
    color:'#4C7EE0 !important',
    border:'1px solid #4C7EE0'
  },
  riskImageSize: {
    width: '120px',
    height: '100px !important',
  },
  tableStyle: {
    width: '55% !important',
    minWidth:'720px !important'
  },
  btnBorderGreen: {
    border: '1px solid #4C7EE0 !important',
    color: '#4C7EE0 !important',
    height:'40px'
  },
  btnclear:{
    height:'40px'
  },
  btnBorderAsh: {
    border: '1px solid #8a8181 !important',
    color: '#8a8181 !important',
  },
  btnBorderRed: {
    border: '1px solid #ff0000 !important',
    color: '#ff0000 !important',
  },
  qusCard: {
    width: '100% !important',
  },
  qusCardHeader: {
    backgroundColor: '#ece9fa !important',
  },
  subText: {
    fontSize: '12px !important',
  },
  btnPadding: {
    paddingLeft:'2px !important',
    paddingRight:'2px !important',
  },
  cardBodyH6: {
    fontSize: '14px !important',
  },
  cardBodyH6Ans: {
    fontSize: '13px',
    paddingLeft: '12px',
  },
  addBodyPlus: {
    fontSize: '146px !important',
    fontWeight: '1000 !important',
    textAlign: 'center !important',
    color: '#3A3541 !important',
  },
  posRelativeAndDivBorder: {
    position: 'relative',
    border: '1px solid #4C7EE0', 
    width: '120px',
    height: '100px !important',
  },
  hightrachytitle:{
    textAlign:'left',
    color:'#3A3541',
    
  },
  btnBorderpurple:{
    border: '1px solid #0e640e !important',
    color: '#0e640e !important',
    padding:'5px 10px !important',
    height: '40px !important',
  },
  hightrachytitlestyle:{
    fontSize:'14px !important',
    fontWeight:500
  },
  modalbox:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 14,
    padding: 10,
  },
  addriskbtn:{
    color:'#9155FD !important',
    border:'1px solid #9155FD !important',
    marginTop:'20px !important'
  },
  risktytext:{
    width:'350px',

  },
  canclbtn:{
    alignItem:'left !important' ,
    justifyContent: 'end'
  },
  cardRiskManage:{
      width:'98%',
      marginLeft: '-25px !important',
      boxShadow: '2px 2px 2px 2px !important',
  },
  cardRiskManageInside:{
    width:'99%',
    marginLeft: '10px !important',
    boxShadow: '1px 1px 1px 1px !important',
    borderRadius: '2px !important'
  },

  stdButtonsStyle:{
    fontSize:'0.70rem !important', 
    height: '50px !important', 
  },
  tableThStyle: {
    backgroundColor: '#7B9DE0',
    color: '#ffffff',
    lineHeight: '0.25rem !important'
  },
  tableTrStyle: {
    lineHeight: '0.25rem !important',
    fontSize: '10px !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },
  addrisktypebtn:{
    marginLeft:'10px !important',
    border: '1px solid #0e640e !important',
    color: '#0e640e !important',
    padding:'5px 10px !important',
    height: '40px !important',
  },
  // commonMainTableHead:{
  //   backgroundColor:'#acacbe !important',
  //   fontSize:'13px !important',
  //   height:'50px',
  //   padding:'0px',
  //   marginTop: '-7px',
  // },
  stdCard:{
    height:'100%',
    border:'1px solid black'

  },
  scrollableDivToStandard: {
    height: '250px',
    overflow: 'auto',
    width: '100%',
  },
  tableHeadStyle: {
    backgroundColor: '#d5d5e1',
    color: '#ffffff',
  },
  mousePointer: {
    cursor: 'pointer'
  },
}));


const CollectionManagement = ({openCreateNewCollectionWithColId}) => {
  
  const classe = useStyle();
  const classes = useStyles();
  const Input = styled('input')({
     display: 'none',
  });

  const [golobalCentreId, setGolobalCentreId] = React.useState('');

  const [questionerTitlevalue, setQuestionerTitlevalue] = React.useState('');
    const [questionerCreatedDatevalue, setQuestionerCreatedDatevalue] = React.useState(null);
  const [questionerDescvalue, setQuestionerDescvalue] = React.useState('');

  const [selectedFile, setSelectedFile] = useState(null)
  const [preview, setPreview] = React.useState()

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
      getData();
      getColors()
      getAllRisksMethods()
      if (!selectedFile) {
          setPreview(undefined)
          return
      }
      const objectUrl = URL.createObjectURL(selectedFile)
      setPreview(objectUrl)
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  function getData () {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId);
    console.log(data)
    getGlobalRiskForCenter(data.centreId);
    getCentreData(data.centreId);
    getMaximumQuestionerCollection(data.centreId);
  }
  
  function reloadAll(){
    getGlobalRiskForCenter(golobalCentreId);
  }


  const [centreName, setCentreName] = React.useState('');
    function getCentreData(centreId){
      var data = '{getCentreData(centreId:\"' + centreId + '\"){centreId,centreName}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          console.log("++++++++++++++++Return centreData++++++++++++++++");
          var centreData = response.data.data.getCentreData;
          console.log(centreData);
          setCentreName(centreData.centreName);
  
      })
      .catch(function (error) {
          console.log("Centre Data Loding error" + error);
      });
    }


  /** Get All All Maximum Collection Centers*/
  const [allowSaveCollection, setAllowSaveCollection] = React.useState(true);
  const [allCentreCollections, setAllCentreCollections] = React.useState([]);
  function getMaximumQuestionerCollection(centreId){
    /** get All Collections */
    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all',{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get All Available Collection");
      console.log((response.data));
      var arr = [];

      
      var centerCollections = 0;

      response.data.forEach((item)=>{
        if (item.centreId === centreId ){
          centerCollections ++;
          arr.push(item);
        }
      });
      setAllCentreCollections(arr);
      axios(CONFIG.MAIN_URL+'/centreLimitation/all',{
        method: 'get',
      })
      .then(function (response) {
        console.log("Get All Maximum Collection Centers");
        console.log((response.data));
        response.data.forEach((item)=>{
          if (item.centreId === centreId ){
            if (centerCollections === item.maximumRiskCollection || centerCollections > item.maximumRiskCollection){
              setAllowSaveCollection(false);
            }
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const previousCollectionTableRowData = (
    <TableBody>
    {allCentreCollections.map((item)=>{
        return (
          <StyledTableRow key={item.standardId}  className={classes.tableTrStyle}>
            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
              <div style= {{ whiteSpace: 'normal' }}>{item.title}</div>
            </StyledTableCell>
            <StyledTableCell 
              align="right"
              className={classes.tableTrStyle}
              >
              {/*<Link id={item.id} to={`/editcollection/${item.collectionUniqueName}`}>  */}
              <EditIcon 
                className={classes.mousePointer}
                onClick ={ ()=>{editCollectionGroup(item.collectionUniqueName)}}
              />
              {/*</Link>*/}
            </StyledTableCell>
          </StyledTableRow>
        )
    })}
  </TableBody>
  
  )

  /** Get Global Risks */
  const [globalRiskArray, setGlobalRiskArray] = useState([]);
  function getGlobalRiskForCenter(centerId){
    setGlobalRiskArray([]);
    axios(CONFIG.MAIN_URL+`/risk/all?centreId=`+ centerId,{
      method: 'get',
    })
    .then(function (response) {
      console.log("Risk All Return Response");
      console.log(response.data);
      setGlobalRiskArray(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const onSelectFile = e => {
      if (!e.target.files || e.target.files.length === 0) {
          setSelectedFile(undefined)
          return
      }
      // I've kept this example simple by using the first image instead of multiple
      setSelectedFile(e.target.files[0])
      
  }
 // console.log(selectedFile)
  const [saveBtnName, setSaveBtnName] = useState("Save");
  const [saveBtnNameForRisk, setSaveBtnNameForRisk] = useState("Save Risk");
  const [pageBannerName, setPageBannerName] = useState("New Questionnaire Group");
  const [editGroup, setEditGroup] = useState(false);
  const [showquesbtn, setShowquesbtn] = useState();
  
  function onTextFieldChange(e) {
    if (e.target.id === "title"){
      setQuestionerTitlevalue(e.target.value);
    }
    if (e.target.id === "description"){
      setQuestionerDescvalue(e.target.value);
    }
    setSaveCollec({
     ...saveCollec,
     [e.target.name]: e.target.value
    })
  }

  const [imgLocation, setImgLocation] = useState();
  const [saveCollec, setSaveCollec] = useState({
    title: "",
    description: "",
    imageUrl:imgLocation,
    centreId:golobalCentreId,
    status:'Draft'
    
  });

























      /** malindus section */

      const [riskCategoryMethodData, setRiskCategoryMethodData] = React.useState([])
      const [riskCategoryId, setRiskCategoryId] = React.useState('')

      const [riskCategoryMethodDataAppetite, setRiskCategoryMethodDataAppetite] = React.useState([])
      const [riskCategoryIdAppetite, setRiskCategoryIdAppetite] = React.useState('')

      const [showSelectBar, setShowSelectBar] = React.useState(true);
      const [sliderType, setSliderType] = React.useState();
      const [sliderValue, setSliderValue] = React.useState();
      const [sliderValueStyle, setSliderValueStyle] = React.useState('');
      const [sliderValueName, setSliderValueName] = React.useState('');
      const [appetitesShowSelectBar, setAppetiteShowSelectBar] = React.useState(true);
      const [appetiteSliderType, setAppetiteSliderType] = React.useState('');
      const [appetitesSliderValue, setAppetiteSliderValue] = React.useState();
      const [appetitesSliderValueStyle, setAppetiteSliderValueStyle] = React.useState('');
      const [appetitesSliderValueName, setAppetiteSliderValueName] = React.useState('');
  
      const [appetiteColorForSaveToDb, setAppetiteColorForSaveToDb] = useState('')

      function getColorCode(number) {
        var cCode = "#ffffff";
        databClr.forEach((data)=> {
          if (parseInt(number) === parseInt(data.scoreNumber)){
            cCode = data.color;
          }
        })
        return cCode;
      }
      function getColorCodeAppetite(number) {
        var cCode = "#ffffff";
        databClr.forEach((data) => {
          if (selectedColorForAppetiteSlider === 1) {
            if (parseInt(number) === parseInt(data.scoreNumber)) {
              cCode = data.color;
              setAppetiteColorForSaveToDb(cCode)
            }
          }else{
            if (parseInt(number) === parseInt(data.scoreNumber)) {
              cCode = data.secondaryColor;
              setAppetiteColorForSaveToDb(cCode)
            }
          }
        })
        return cCode;
      }
    
      
      function setSliderValueFunction(val, min, max, type){
        var posNumber = val - min;
        var totalNumber = max - min;
        var percentageVal = (posNumber/totalNumber) * 100
        var cCode = getColorCode(val);
        var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
        if (type === 1) {
          setSliderValue(val);
          setSliderValueStyle(cssStr);
        }
        if (type === 2) {
          setAppetiteSliderValue(val);
          cCode = getColorCodeAppetite(val);
          cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
          setAppetiteSliderValueStyle(cssStr);
        }
      }
    



  function getAllRisksMethods(){
    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_RISK+"/all",{
      method: 'get',
      headers: { 
          'Content-Type': 'text/plain', 
        },     
      })
      .then(function (response) {
       
          console.log("All Risk Returns");
          console.log(response.data[0]);
          setRiskCategoryMethodData(response.data[0].methods);
          setRiskCategoryId(response.data[0].id);

          setRiskCategoryMethodDataAppetite(response.data[0].methods);
          setRiskCategoryIdAppetite(response.data[0].id);

          console.log(response.data[0].methods);
  
      })
      .catch(function (error) {
      console.log(error);
      });
  }

  const handleChange = (event) => {
    
    setSliderType(event.target.value);
    setShowSelectBar(false);
    if (appetiteSliderType === undefined || appetiteSliderType === ''){
      setAppetiteSliderType(event.target.value);
      setAppetiteShowSelectBar(false);
    }
    
  };

  function nameChange(sVal){
    var fondType = false;
    riskCategoryMethodData.forEach((data)=> {
      if(sliderType === data.id){
        data.riskTypes.forEach((data1)=> {
          if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
            setSliderValueName(data1.riskTypesName)
            fondType = true;
          }
        })
      }
    })
    if (!fondType) {
      setSliderValueName('');
      setSliderValue();
    }
    
  
  }

  function riskClearFunction(){
    setShowSelectBar(true);
    setSliderType('');
    setSliderValue();
    setSliderValueName('');


  }

  const appetiteHandleChange = (event) => {
    setAppetiteSliderType(event.target.value);
    setAppetiteShowSelectBar(false);
  };

  function appetiteNameChange(sVal){
    var fondType = false;
    riskCategoryMethodDataAppetite.forEach((data)=> {
      if(appetiteSliderType === data.id){
        data.riskTypes.forEach((data1)=> {
          if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
            setAppetiteSliderValueName(data1.riskTypesName)
            fondType = true;
          }
        })
      }
    })
    if (!fondType) {
      setAppetiteSliderValueName('');
      setAppetiteSliderValue();
    }
  }

  function appetiteClearFunction(){

    setAppetiteShowSelectBar(true);
    setAppetiteSliderValue();
    setAppetiteSliderType(0);
    setAppetiteSliderValueName('')
    setAppetiteSliderValueStyle('')
    
  }




  /**end of the malindus section */



























  
  /** Save Image and Collections */
  function onFormSubmit(e) {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append('file',selectedFile);
    formdata.append('centreId',golobalCentreId);
    if (selectedFile === null && editGroup) {
      if (questionerTitlevalue === "" || questionerDescvalue === "") {
        if (questionerTitlevalue === "") {
          alert("Please enter Questionnaire Group Title.");
        }else{
          alert("Please enter Questionnaire Group Description.");
        }
      }else{
        saveData(imgLocation);
      }  
    }else{
      if (selectedFile === null) {
        if (questionerTitlevalue === "" || questionerDescvalue === "") {
          if (questionerTitlevalue === "") {
            alert("Please enter Questionnaire Group Title.");
          }else{
            alert("Please enter Questionnaire Group Description.");
          }
        }else{
          saveData("");
        }  
      }else{
        if (questionerTitlevalue === "" || questionerDescvalue === "") {
          if (questionerTitlevalue === "") {
            alert("Please enter Questionnaire Group Title..");
          }else{
            alert("Please enter Questionnaire Group Description.");
          }
        }else{
          axios(CONFIG.MAIN_URL+'/image/upload',{
            method: 'post',
            data: formdata,
          })
          .then(function (response) {
            console.log(response.data.location);
            if (response.data.location === null || response.data.location === ""){
              alert(response.data.message + " and please use a different image name");
              return;
            }else {
              setImgLocation(response.data.location);
              saveData(response.data.location);
              
            }  
          })
          .catch(function (error) {
            alert(error);
            console.log(error);
          })
        }
      }
    }
  }

  /** Save Collection */
  const [rtnCollectedId, setRtnCollectedId] = useState('');
  const [rtnCollectionUniqueName, setRtnCollectionUniqueName] = useState('');
  const [rtnDocumentCreator, setRtnDocumentCreator] = useState('');

  function saveData(testData) {

    var title = questionerTitlevalue;
    var centreId = golobalCentreId;
    var status = saveCollec.status;
    if (rtnCollectedId === ''){
      if (allowSaveCollection){
        var sendStr = "mutation{createCollectionData(collectionName:\"" + title + "\",status:\"" + status + "\",centreId:\"" + centreId + "\"){collectionId,collectionName,status,centreId }}"; 
        axios(CONFIG.MAIN_URL+'/graphql',{
          method: 'post',
          headers: { 
            'Content-Type': 'text/plain'
            },
          data : sendStr  
        })
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          let rtnData = response.data;
          console.log("++++++++++ Collection Data save in PG");
          console.log(rtnData);
          console.log("++++++++++ Collection Data save in PG");
          let obj = {};
          obj["title"]= questionerTitlevalue;
          obj["description"]= questionerDescvalue;
          obj["imageUrl"]= testData;
          obj["centreId"]= golobalCentreId;
          obj["status"]= saveCollec.status;
          obj["collectionUniqueName"]= golobalCentreId + "_questionnaire_"+ rtnData.data.createCollectionData.collectionId;
          obj["documentCreator"]= "expert";
    
          console.log(obj);
          console.log(JSON.stringify(obj));
          let sendJson = JSON.stringify(obj);
            
          axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/save',{
            method: 'post',
            headers: { 
                'Content-Type': 'application/json',
              },
            data : sendJson,  
          })
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            let rtnData = response.data;
            alert("Questionnaire Group saved successfully.");

            setRtnCollectedId(rtnData.id);
            setRtnCollectionUniqueName(rtnData.collectionUniqueName);
            setRtnDocumentCreator(rtnData.documentCreator);
            setQuestionerCreatedDatevalue(rtnData.createdDate);
            console.log(rtnData.id);
            setSaveBtnName("Update");
            setPageBannerName("Edit Questionnaire Group")
            setEditGroup(true);
          })
          .catch(function (error) {
            console.log(error);
            alert("Error saving Questionnaire Group 2-:" + error);
          });
        })
        .catch(function (error) {
          console.log(error);
          alert("Error saving Collection Data to Database Server -:" + error);
        });
      }else{
        alert("Sorry!. You have exceeded maximum number of collection granted by the System Administrator. Please contact System Adminstrator to increase the collections.");
      }  





  
    }else{

      let obj = {};
      
      obj["id"]= rtnCollectedId;  
      obj["title"]= questionerTitlevalue;
      obj["description"]= questionerDescvalue;
      obj["imageUrl"]= testData;
      obj["centreId"]= golobalCentreId;
      obj["status"]= saveCollec.status;
      obj["collectionUniqueName"]= rtnCollectionUniqueName;
      obj["documentCreator"]= rtnDocumentCreator;
      obj["documentCreator"]= rtnDocumentCreator;
      if (questionerCreatedDatevalue != null) {
        obj["createdDate"]= questionerCreatedDatevalue;
      }  

      if (collectionRiskDataById != undefined && collectionRiskDataById != null && collectionRiskDataById != ''){
        obj["risks"]= collectionRiskDataById;
      }
      console.log(obj);
      console.log(JSON.stringify(obj));
      let sendJson = JSON.stringify(obj);
      console.log(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/save');
        
      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/save',{
        method: 'post',
        headers: { 
            'Content-Type': 'application/json',
          },
        data : sendJson,  
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        let rtnData = response.data;
        alert("Questionnaire Group Updated successfully");
        
        setRtnCollectedId(rtnData.id);
        setRtnCollectionUniqueName(rtnData.collectionUniqueName);
        setRtnDocumentCreator(rtnData.documentCreator);
        setQuestionerCreatedDatevalue(rtnData.createdDate);
        console.log(rtnData.id);
        setSaveBtnName("Update");
        setPageBannerName("Edit Questionnaire Group")
        setEditGroup(true);
      })
      .catch(function (error) {
        console.log(error);
        alert("Error saving Questionnaire Group 1 -:" + error);
      });
    }

  }

  //*********************************************** */
  const [displaySaveEditRisk, setDisplaySaveEditRisk] = React.useState(false);
  const [collectionRiskDataById, setCollectionRiskDataById] = React.useState({});
  
  const [golobalRiskHierarchyId, setGolobalRiskHierarchyId] = React.useState('');
    const handleChangeGolobalRiskHierarchy = (event) => {
    setGolobalRiskHierarchyId(event.target.value);
  };

  function setGlobalRiskToCollection(){
    setCreateNewRisk(false);
    if (golobalRiskHierarchyId === "") {
      alert("Please select a global risk");
    }else{
      setDisplaySaveEditRisk(true);
      setRiskHierarchyNameValue('');
      globalRiskArray.forEach((item)=>{
        if(item.riskId === golobalRiskHierarchyId){
          setRiskHierarchyNameValue(item.name);
          setShowSaveBtn(true);
          setRiskTypeData(item);      
        }                   
      });
    }
  }
  
  const [createNewRisk, setCreateNewRisk] = React.useState(false); // To identify creating a new Risk
  function createNewRiskHierarchy(){
    setRiskTypeData({});
    setRiskHierarchyNameValue('');
    setCreateNewRisk(true);
    setDisplaySaveEditRisk(true);
    setShowSaveBtn(false);
  }

  const [riskHierarchyNameValue, setRiskHierarchyNameValue] = React.useState('');
  const handleChangeRiskHierarchyName = (event) => {
    setRiskHierarchyNameValue(event.target.value);
  };


  function clearFuction() {
    setQuestionerTitlevalue("");
    setQuestionerDescvalue("");
    setPreview("")
    setImgLocation("");
    
    setSaveBtnName("Save");
    setEditGroup(false);
    setSaveRiskFlag(false);
    
    setRiskTypeData({});
    setRtnCollectedId('');
    setRtnCollectionUniqueName('');
    setRtnDocumentCreator('');
    setQuestionerCreatedDatevalue(null);
  }

  const [showSaveBtn, setShowSaveBtn] = React.useState(false)
  const [riskTypeData, setRiskTypeData] = React.useState({});

    /**
     * Create Main Risk
     */

    const [selectedColorForAppetiteSlider, setSelectedColorForAppetiteSlider] = useState(1)

    const radioHandler = (status) => {
      setSelectedColorForAppetiteSlider(status);
      if (selectedColorForAppetiteSlider != status){
        setAppetiteSliderValueStyle('')
        setAppetiteSliderValueName('')
        setAppetiteSliderValue('')
      }
    };


     const [mainRiskName, setMainRiskName] = React.useState('');
     const [mainRiskLevel, setMainRiskLevel] = React.useState('');
     const [mainRiskAppetite, setMainRiskAppetite] = React.useState('');
     const [lastRiskTmpId, setLastRiskTmpId] = React.useState(0);
     const [mainRiskOpen, setMainRiskOpen] = React.useState(false);
     const [mainRiskScroll, setMainRiskScroll] = React.useState('paper');
     const mainRiskHandleClickClose = () => {
         setMainRiskOpen(false);
         // maindu section
         setShowSelectBar(true);
         setSliderType('');
         setSliderValue('');
         setSliderValueName("");
         setAppetiteShowSelectBar(true);
         setAppetiteSliderValue();
         setAppetiteSliderType();
         setAppetiteSliderValueName();
         setMainRiskName('');
         appetiteClearFunction()
          riskClearFunction()
     };
     const handleChangeMainRisk = (event) => {
        setMainRiskName(event.target.value);
     };
     const handleChangeMainRiskLevel = (event) => {
        const value = event.target.value.replace(/\D/g, "");
        if ((value != 0 && value < 11) || value === "" ){
          setMainRiskLevel(value);
        }  
     };
     const handleChangeMainRiskAppetite = (event) => {
        const value = event.target.value.replace(/\D/g, "");
        if ((value != 0 && value < 11) || value === ""){
          setMainRiskAppetite(value);
        }
     };

     function setMainRiskFunction(){

         if ( mainRiskName === ""){
             alert("Please enter a Main Risk");
             return
         }
         if (sliderValue === undefined){
          alert("Please enter a Risk level");
          return
         }
          if (appetitesSliderValue === undefined){
            alert("Please enter a Appetite Level ");
            return
        }


         setMainRiskName('');
         setMainRiskOpen(false);
         if (Object.keys(riskTypeData).length == 0){
            var arryObj = [];
            var tmpArray = {};
            tmpArray["riskTypeTmpId"] = lastRiskTmpId + 1;
            tmpArray["name"] = mainRiskName;
            tmpArray["riskLevel"] = sliderValue;
            var tmpArray1 = {}

            tmpArray1["riskLevel"] = sliderValue
            tmpArray1["riskCategoryId"] = riskCategoryId
            tmpArray1["riskMethodId"] = sliderType

            tmpArray["riskLevelData"] = tmpArray1;

            tmpArray["riskAppetite"] = appetitesSliderValue;
            var tmpArrayApp = {}

            tmpArrayApp["riskAppetite"] = appetitesSliderValue
            tmpArrayApp["riskCategoryId"] = riskCategoryIdAppetite
            tmpArrayApp["riskMethodId"] = appetiteSliderType

            if (selectedColorForAppetiteSlider === 1) {
              tmpArrayApp["color"] = appetiteColorForSaveToDb
              tmpArrayApp["secondaryColor"] = ""
            }
            else{
              tmpArrayApp["color"] = ""
              tmpArrayApp["secondaryColor"] = appetiteColorForSaveToDb
            }

            tmpArray["riskAppetiteData"] = tmpArrayApp;

            arryObj.push(tmpArray);
            
            tmpArray = {};

            tmpArray["name"] = riskHierarchyNameValue;
            tmpArray["centreId"] = golobalCentreId;
            tmpArray["riskTypes"] = arryObj;

            setRiskTypeData(tmpArray);
            setLastRiskTmpId(lastRiskTmpId + 1);

            setShowSaveBtn(true);
         }else{
            var tmpArray = {};
            tmpArray["riskTypeTmpId"] = lastRiskTmpId + 1;
            tmpArray["name"] = mainRiskName;
            tmpArray["riskLevel"] = sliderValue;
            var tmpArray1 = {}

            tmpArray1["riskLevel"] = sliderValue
            tmpArray1["riskCategoryId"] = riskCategoryId
            tmpArray1["riskMethodId"] = sliderType
            tmpArray["riskLevelData"] = tmpArray1;


            tmpArray["riskAppetite"] = appetitesSliderValue;
            var tmpArrayApp = {}

            tmpArrayApp["riskAppetite"] = appetitesSliderValue
            tmpArrayApp["riskCategoryId"] = riskCategoryIdAppetite
            tmpArrayApp["riskMethodId"] = appetiteSliderType
            if (selectedColorForAppetiteSlider === 1) {
              tmpArrayApp["color"] = appetiteColorForSaveToDb
              tmpArrayApp["secondaryColor"] = ""
            }
            else{
              tmpArrayApp["color"] = ""
              tmpArrayApp["secondaryColor"] = appetiteColorForSaveToDb
            }
            tmpArray["riskAppetiteData"] = tmpArrayApp;
            riskTypeData.riskTypes.push(tmpArray);
            setLastRiskTmpId(lastRiskTmpId + 1);
            console.log(riskTypeData);
         }


         appetiteClearFunction()
        riskClearFunction()
     }
     const mainRiskHandleClickOpen = (scrollType) => () => {
        if (riskHierarchyNameValue === ""){
          alert("Please enter Risk Hierarchy Name before add Risk Types")
        }else{
          setMainRiskOpen(true);
          setMainRiskScroll(scrollType);
          setMainRiskLevel('');
          setMainRiskAppetite('');
        }
      };
     /** End of Create mainRisk */

     /** Save Risk */

    const [saveRiskFlag, setSaveRiskFlag] = React.useState(false);
    
    function saveRisk(){
        var data = JSON.stringify(riskTypeData);
        console.log("++++++++++++++++++++Risk Save JSON");
        console.log(data);
        console.log("++++++++++++++++++++End of Risk Save JSON");
        if (createNewRisk){
          setDisplaySaveEditRisk(false);
          setShowSaveBtn(false);
          setRiskTypeData(riskTypeData);
          saveRiskToCollection(riskTypeData);

          /*
          axios(CONFIG.MAIN_URL+'/risk/save',{
              method: 'post',
              headers: { 
                  'Content-Type': 'application/json',
                },
              data : data,  
          })
          .then(function (response) {
                
              console.log("+++++++++++++++++++++Test Test++++++++++++++++++");
              console.log(JSON.stringify(response.data));
              console.log(JSON.stringify(riskTypeData));
              console.log("+++++++++++++++++++++Test Test++++++++++++++++++");

              setRiskTypeData({});


              getGlobalRiskForCenter(golobalCentreId);
              setDisplaySaveEditRisk(false);
              setShowSaveBtn(false);
              setRiskTypeData(response.data);
              saveRiskToCollection(response.data);
          })
          .catch(function (error) {
              console.log(error);
              alert("Error saving Risk Type -:" + error);
          });
          */
        }else{
          saveRiskToCollection(null);
        }
    }


    function saveRiskToCollection(amendedArray){
      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ rtnCollectionUniqueName,{
        method: 'get',
      })
      .then(function (response) {
        console.log("Collection Retun Response for Saving using rtnCollectionUniqueName");
        console.log(response.data);
        var tmpRiskArr = [];
        if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
          tmpRiskArr = response.data[0].risks; 
        }
        if (amendedArray === null){
          riskTypeData["name"] = riskHierarchyNameValue;
          tmpRiskArr.push(riskTypeData);

          if (updateRiskItem){
            tmpRiskArr = [];
            collectionRiskDataById.forEach((item)=>{
              if (item.sequenceNo === riskTypeData.sequenceNo){
                tmpRiskArr.push(riskTypeData);
              }else{
                tmpRiskArr.push(item);
              }
            });
          }
        }else{
          tmpRiskArr.push(amendedArray);
        }  


        var collectionId = response.data[0].id;
        var title = response.data[0].title;
        var description = response.data[0].description;
        var imageUrl = response.data[0].imageUrl
        var collectionUniqueName = response.data[0].collectionUniqueName;
        var documentCreator = response.data[0].documentCreator;
        var createdDate = response.data[0].createdDate;

        

        let obj = {}
        obj["id"]= collectionId;  
        obj["title"]= title;
        obj["description"]= description;
        obj["imageUrl"]= imageUrl;
        obj["centreId"]= golobalCentreId;
        obj["status"]= saveCollec.status;
        obj["collectionUniqueName"]= collectionUniqueName;
        obj["documentCreator"]= documentCreator;
        if (createdDate != null && createdDate != undefined){
          obj["createdDate"]= createdDate;
        }

        obj["risks"]= tmpRiskArr;
        console.log(obj);
        console.log("JSON String to Update Collection Risk");
        console.log(JSON.stringify(obj));
        let sendJson = JSON.stringify(obj);
        
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/save',{
          method: 'post',
          headers: { 
              'Content-Type': 'application/json',
            },
          data : sendJson,  
        })
        .then(function (response) {
          console.log("After Update Collection Risk");
          console.log(JSON.stringify(response.data));
          let rtnData = response.data;
          alert("Questionnaire Group saved successfully with Risk Hierarchy");
          
          setRtnCollectedId(rtnData.id);
          setRtnCollectionUniqueName(rtnData.collectionUniqueName);
          setRtnDocumentCreator(rtnData.documentCreator);
          setQuestionerCreatedDatevalue(rtnData.createdDate);

          console.log(rtnData.id);
          setRiskTypeData({}); 
          setSaveBtnName("Update");
          setPageBannerName("Edit Questionnaire Group")
   
          setCreateNewRisk(false);
          setDisplaySaveEditRisk(false);
          setShowSaveBtn(false);
          setSaveBtnNameForRisk("Save Risk");
          setUpdateRiskItem(false)
          getCollectionById();

          setSaveRiskFlag(true);

        })
        .catch(function (error) {
          console.log(error);
          alert("Error saving Questionnaire Group 3 -:" + error);
        });
        
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    function getCollectionById(){
      
      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ rtnCollectionUniqueName,{
        method: 'get',
      })
      .then(function (response) {
        console.log("Collection Retun Response from getCollectionById");
        console.log(response.data);
        var tmpArr = []
        var count = 1;
        if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
          response.data[0].risks.forEach((item)=>{
            item["sequenceNo"] = count;
            tmpArr.push(item);
            count ++;
          });
        }
        setCollectionRiskDataById(tmpArr);
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    
    const [updateRiskItem, setUpdateRiskItem] = React.useState(false);
    const [selectedCollectionRiskId, setSelectedCollectionRiskId] = React.useState('');
    function editRiskHierarchy(no){
      collectionRiskDataById.forEach((item)=>{
        if (item.sequenceNo === no){

          console.log("item");
          console.log(item);
          setDisplaySaveEditRisk(true);
          setRiskHierarchyNameValue(item.name);
          setSelectedCollectionRiskId(item.collectionRiskId);
          setShowSaveBtn(true);
          setSaveBtnNameForRisk("Update Risk");
          setRiskTypeData(item);  
          setUpdateRiskItem(true)
        }
      });

    }


    /** Load previous collection for editing*/
    const location = useLocation();
    const collectnId = '';//location.pathname.split("/")[2];
    const [stopRecurence, setStopRecurence] = React.useState(true);
    //const [rsType, setRsType] = useState([])

    if (collectnId != null && collectnId != "" && stopRecurence) {
        //axios(CONFIG.MAIN_URL+'/risksubrisk/riskType?collectionId='+ collectnId,{
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectnId,{
                method: 'get',
            })
            .then(function (response) {
                console.log("Collection Retun Response");
                console.log(response.data);
                setStopRecurence(false);
                if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
                  setSaveRiskFlag(true); 
                }
                setRtnCollectedId(response.data[0].id);
                setRtnCollectionUniqueName(response.data[0].collectionUniqueName);
                setRtnDocumentCreator(response.data[0].documentCreator);
                setQuestionerTitlevalue(response.data[0].title);
                setQuestionerCreatedDatevalue(response.data[0].createdDate);
                setQuestionerDescvalue(response.data[0].description);
                setPreview(response.data[0].imageUrl)
                setImgLocation(response.data[0].imageUrl);
                setPageBannerName("Edit Questionnaire Group")
                setEditGroup(true);
                setSaveBtnName("Update");


                var tmpArr = []
                var count = 1;
                if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
                  response.data[0].risks.forEach((item)=>{
                    item["sequenceNo"] = count;
                    tmpArr.push(item);
                    count ++;
                  });
                }
                setCollectionRiskDataById(tmpArr);

                
                
                /*
                response.data.forEach((item,index)=>{
                    if (index === 0) {  
                        setSaveRiskFlag(true); 
                        setRtnCollectedId(item.collectionId);
                        setQuestionerTitlevalue(item.title);
                        setQuestionerDescvalue(item.description);
                        setPreview(item.imageUrl)
                        setImgLocation(item.imageUrl);
                        // 30-06-2022
                        //setRiskTypeData(item);
                        //setShowSaveBtn(true);
                        //setSaveBtnName("Update");
                        //setSaveBtnNameForRisk("Update Risk");
                        setPageBannerName("Edit Questionnaire Group")
                        setEditGroup(true);
                    }
                })
                */
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    /** End of previous collection for editing */

    /** Edit from Div page */

    function editCollectionGroup(colId){
      
      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ colId,{
        method: 'get',
      })
      .then(function (response) {
        console.log("Collection Retun Response");
        console.log(response.data);
        setStopRecurence(false);
        if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
          setSaveRiskFlag(true); 
        }
        setRtnCollectedId(response.data[0].id);
        setRtnCollectionUniqueName(response.data[0].collectionUniqueName);
        setRtnDocumentCreator(response.data[0].documentCreator);
        setQuestionerTitlevalue(response.data[0].title);
        setQuestionerCreatedDatevalue(response.data[0].createdDate);
        setQuestionerDescvalue(response.data[0].description);
        setPreview(response.data[0].imageUrl)
        setImgLocation(response.data[0].imageUrl);
        setPageBannerName("Edit Questionnaire Group")
        setEditGroup(true);
        setSaveBtnName("Update");


        var tmpArr = []
        var count = 1;
        if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
          response.data[0].risks.forEach((item)=>{
            item["sequenceNo"] = count;
            tmpArr.push(item);
            count ++;
          });
        }
        setCollectionRiskDataById(tmpArr);

        
        
         })
      .catch(function (error) {
        console.log(error);
      });

    }

    /** Direct for Parent Page */

    if (openCreateNewCollectionWithColId != null && openCreateNewCollectionWithColId != ''){
      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ openCreateNewCollectionWithColId,{
        method: 'get',
      })
      .then(function (response) {
        console.log("Collection Retun Response");
        console.log(response.data);
        setStopRecurence(false);
        if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
          setSaveRiskFlag(true); 
        }
        setRtnCollectedId(response.data[0].id);
        setRtnCollectionUniqueName(response.data[0].collectionUniqueName);
        setRtnDocumentCreator(response.data[0].documentCreator);
        setQuestionerTitlevalue(response.data[0].title);
        setQuestionerCreatedDatevalue(response.data[0].createdDate);
        setQuestionerDescvalue(response.data[0].description);
        setPreview(response.data[0].imageUrl)
        setImgLocation(response.data[0].imageUrl);
        setPageBannerName("Edit Questionnaire Group")
        setEditGroup(true);
        setSaveBtnName("Update");


        var tmpArr = []
        var count = 1;
        if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
          response.data[0].risks.forEach((item)=>{
            item["sequenceNo"] = count;
            tmpArr.push(item);
            count ++;
          });
        }
        setCollectionRiskDataById(tmpArr);

        
        
         })
      .catch(function (error) {
        console.log(error);
      });
    }




/**Comment section for Risk Tree*/

function getQuestionIds(comment,type){
  var count = 0;
  var ids = [];

  if (comment.questions != undefined && comment.questions!= null){
      if (type === 1){
          if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
              comment.questions.forEach((quesItem)=>{
                  if (quesItem.questionType === "Closeended"){
                      ids.push(quesItem);
                  }
              })  
          }
      }
      if (type === 2){
          if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
              comment.questions.forEach((quesItem)=>{
                  if (quesItem.questionType === "Openended"){
                      ids.push(quesItem);
                  }
              })  
          }
      }
  }else{
      ids = [];
  }
  return ids;
}

function getQuestionIdsWithSubRisks(comment,type){
  var count = 0;
  var riskIds = "";
  var ids = [];
  if (type === 1){
      if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
          comment.questions.forEach((quesItem)=>{
              if (quesItem.questionType === "Closeended"){
                  ids.push(quesItem);
              }
          })  
      }
  }
  if (type === 2){
      if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
          comment.questions.forEach((quesItem)=>{
              if (quesItem.questionType === "Openended"){
                  ids.push(quesItem);
              }
          })  
      }
  }
  if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
      return getQuestionSubIdsWithSubRisks(comment.subRisks,type,ids);
  }else{
      return ids;
  }
}
function getQuestionSubIdsWithSubRisks(comment,type,ids ){
  comment.forEach((data,index)=>{
      if (type === 1){
          if (data.questions != undefined && data.questions != null && data.questions.length > 0){
              data.questions.forEach((quesItem)=>{
                  if (quesItem.questionType === "Closeended"){
                      ids.push(quesItem);
                  }
              })  
          }  
      }
      if (type === 2){
          if (data.questions != undefined && data.questions != null && data.questions.length > 0){
              data.questions.forEach((quesItem)=>{
                  if (quesItem.questionType === "Openended"){
                      ids.push(quesItem);
                  }
              })  
          }  
      }
      if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0){
          getQuestionSubIdsWithSubRisks(data.subRisks,type,ids);
      }
  });
  return ids;
}    

const [databClr, setDatabClr] = useState([])
const [colorPatterns, setColorPatterns] = useState([])
  
function getColors(){
  axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
    method: 'get',
    headers: { 
        'Content-Type': 'text/plain', 
      },     
    })
    .then(function (response) {
      console.log("All colors");
      console.log(response.data);

      //setDatabClr(response.data)
      setColorPatterns(response.data);
      response.data.forEach((data,index)=> {
        if(index === 0){
          setDatabClr(data.colors)
        }
      })

     
    })
    .catch(function (error) {
    console.log(error);
    });
}


function Comment({ comment, lastRiskTmpId, saveBtnNameForRisk, rtnCollectionUniqueName, selectedCollectionRiskId }) {
  const [subLastRiskTmpId, setSubLastRiskTmpId] = React.useState(lastRiskTmpId);
  const [show, setShow] = useState(false)

  var closeEndedQuestionIds = [];
  var openEndedQuestionIds = [];
  var closeEndedQuestionIdsWithSub = [];
  var openEndedQuestionIdsWithSub = [];
  var sendRiskAppetiteStr = "";
  if (saveBtnNameForRisk === "Update Risk"){
    closeEndedQuestionIds = getQuestionIds(comment,1);
    openEndedQuestionIds = getQuestionIds(comment,2);
    closeEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,1);
    openEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,2);
  }

  const nestedComments = (comment.subRisks || []).map(comment => {
      return <Comment key={comment.riskTypeTmpId} comment={comment} lastRiskTmpId={subLastRiskTmpId} saveBtnNameForRisk={saveBtnNameForRisk} rtnCollectionUniqueName={rtnCollectionUniqueName} selectedCollectionRiskId={selectedCollectionRiskId} type="child" />
  })

  /**
  * Create Sub Risk
  */

  const [appetiteColorForSaveToDbEdit, setAppetiteColorForSaveToDbEdit] = useState("")
  const [appetiteColorForSaveToDbSubRisk, setAppetiteColorForSaveToDbSubRisk] = useState("")

  const [subRiskName, setSubRiskName] = React.useState('');
  const [subRiskLevel, setSubRiskLevel] = React.useState('');
  //const [subRiskAppetite, setSubRiskAppetite] = React.useState('');
  const [subRiskId, setSubRiskId] = React.useState(0);
  const [subRiskOpen, setSubRiskOpen] = React.useState(false);
  const [subRiskScroll, setSubRiskScroll] = React.useState('paper');
  const subRiskHandleClickClose = () => {
      setSubRiskOpen(false);
      //main section
      subAppeClearFunction()
      subRiskClearFunction()
      setSubRiskName('')

  };
  const handleChangeSubRisk = (event) => {
    setSubRiskName(event.target.value);
    setSubRiskId(event.target.id);
  };
  // const handleChangeSubRiskLevel = (event) => {
  //   const value = event.target.value.replace(/\D/g, "");
  //   if ((value != 0 && value < 11) || value === "" ){
  //     setSubRiskLevel(value);
  //   }  
  // };
  // const handleChangeSubRiskAppetite = (event) => {
  //   const value = event.target.value.replace(/\D/g, "");
  //   if ((value != 0 && value < 11) || value === ""){
  //     setSubRiskAppetite(value);
  //   }
  // };

    //sub risk - malindu section

    const [subRiskLvlShow, setSubRiskLvlShow] = React.useState(true);
    const [subRiskLevelType, setSubRiskLevelType] = React.useState();
    const [subRiskLevelValue, setSubRiskLevelValue] = React.useState();
    const [subRiskLevelValueStyle, setSubRiskLevelValueStyle] = React.useState('');
    const [subRiskSliderValueName, setSubRiskSliderValueName] = React.useState('');
    const [subAppetitesShowSelectBar, setSubAppetitesShowSelectBar] = useState(true)
    const [subAppetiteSliderType, setSubAppetiteSliderType] = useState()
    const [subRiskAppetite, setSubRiskAppetite] = React.useState();
    const [subRiskAppetiteStyle, setSubRiskAppetiteStyle] = React.useState();
    const [subAppetitesSliderValueName, setSubAppetitesSliderValueName] = React.useState('');
  
    const [selectedColorForAppetiteSliderSubRisk, setSelectedColorForAppetiteSliderSubRisk] = useState(1)
  
    const radioHandlerAppetiteSubEdit = (status) => {
      if (selectedColorForAppetiteSliderSubRisk != status){
        setSubRiskAppetite('')
        setSubAppetitesSliderValueName('')
        setSubRiskAppetiteStyle('');
        setSelectedColorForAppetiteSliderSubRisk(status);
      }
    };



    function getSubColorCode(number) {
      var cCode = "#ffffff";
      databClr.forEach((data)=> {
        if (parseInt(number) === parseInt(data.scoreNumber)){
          cCode = data.color;
        }
      })
      return cCode;
    }

    function getSubColorCodeAppetite(number,colorScheme) {
      var cCode = "#ffffff";
      databClr.forEach((data) => {
        if(colorScheme === 1){
          if (parseInt(number) === parseInt(data.scoreNumber)) {
            cCode = data.color;
            setAppetiteColorForSaveToDbEdit(cCode)
          }
        }else{
          if (parseInt(number) === parseInt(data.scoreNumber)) {
            cCode = data.secondaryColor;
            setAppetiteColorForSaveToDbEdit(cCode)
          }
        }
      })
      return cCode;
    }

    function getSubColorCodeAppetiteSubRisk(number,colorScheme) {
      var cCode = "#ffffff";
      databClr.forEach((data) => {
        if(colorScheme === 1){
          if (parseInt(number) === parseInt(data.scoreNumber)) {
            cCode = data.color;
            setAppetiteColorForSaveToDbSubRisk(cCode)
          }
        }else{
          if (parseInt(number) === parseInt(data.scoreNumber)) {
            cCode = data.secondaryColor;
            setAppetiteColorForSaveToDbSubRisk(cCode)
          }
        }
      })
      return cCode;
    }
    
    function setRiskLvlEditFunction(val, min, max, type){
      var posNumber = val - min;
      var totalNumber = max - min;
      var percentageVal = (posNumber/totalNumber) * 100
      var cCode = getSubColorCode(val);
      var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
      if (type === 1) {
        setRiskLvlEdit(val);
        setRiskLvlEditStyle(cssStr);
      }
      if (type === 2) {
        setappetiteEdit(val);
        cCode = getSubColorCodeAppetite(val, selectedColorForAppetiteSliderEdit);
        cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
        setappetiteEditStyle(cssStr);
      }
    }
  
    function setSubRiskLevelValueFunction(val, min, max, type){
      var posNumber = val - min;
      var totalNumber = max - min;
      var percentageVal = (posNumber/totalNumber) * 100
      var cCode = getSubColorCode(val);
      var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
      if (type === 1) {
        setSubRiskLevelValue(val);
        setSubRiskLevelValueStyle(cssStr);
      }
      if (type === 2) {
        setSubRiskAppetite(val);
        cCode = getSubColorCodeAppetiteSubRisk(val, selectedColorForAppetiteSliderSubRisk);
        cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';

        setSubRiskAppetiteStyle(cssStr);
      }
    }
  







    function subRiskNameChange(sVal){
      var fondType = false;
      riskCategoryMethodData.forEach((data)=> {
      if(subRiskLevelType === data.id){
        data.riskTypes.forEach((data1)=> {
          if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
            setSubRiskSliderValueName(data1.riskTypesName)
            fondType = true;
          }
        })
      }
    })
    if (!fondType) {
      setSubRiskSliderValueName('');
      setSubRiskLevelValue();
    }
  }
  
  function subRiskClearFunction () {
    setSubRiskLvlShow(true)
    setSubRiskLevelType()
    setSubRiskLevelValue()
    setSubRiskSliderValueName('')
    setSubRiskLevelValueStyle('');
    
  }
  
  const subAppetiteHandleChange = (event) => {  
    console.log(event.target.value) 
    setSubAppetiteSliderType(event.target.value);
    setSubAppetitesShowSelectBar(false)
  
  };
  
  function subAppetiteNameChange(sVal){
    var fondType = false;
    riskCategoryMethodDataAppetite.forEach((data)=> {
    if(subAppetiteSliderType === data.id){
      data.riskTypes.forEach((data1)=> {
        if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
          setSubAppetitesSliderValueName(data1.riskTypesName)
          fondType = true;
        }
      })
    }
  })
  if (!fondType) {
    setSubAppetitesSliderValueName('');
    setSubRiskAppetite();
  }

  }
  
  function subAppeClearFunction(){
    //setSubAppetitesShowSelectBar(true)
    //setSubAppetiteSliderType()
    //setSubRiskAppetite()

    setSubAppetitesShowSelectBar(true)
    setSubAppetiteSliderType()
    setSubRiskAppetite()
    setSubRiskAppetiteStyle('');
    setSelectedColorForAppetiteSliderSubRisk(1);
    setSubAppetitesSliderValueName('');

  
  }
  
  const handleChangeSubRiskLevel = (event) => {  
    setSubRiskLevelType(event.target.value);
    setSubRiskLvlShow(false)

    riskCategoryMethodData.forEach((data)=> {
      if (data.id === event.target.value){
        var colorPatId = data.colorPatternId;
        if (data.colorPatternId != undefined && data.colorPatternId != null){
          colorPatterns.forEach((item)=> {
            if (item.id === data.colorPatternId){
                setDatabClr(item.colors);
            }
          })
        }
      }
    })



    if (subAppetiteSliderType === undefined || subAppetiteSliderType === ''){
      setSubAppetiteSliderType(event.target.value);
      setSubAppetitesShowSelectBar(false);
    }
  }

  //end of the sub risk - malindu section

  


  function setSubRiskFunction(event){
    subAppeClearFunction()
    subRiskClearFunction()
    if ( subRiskName === ""){
        alert("Please enter a Main Risk");
        return
    }
    if (subRiskLevelValue === undefined){
      alert("Please enter a Risk level");
      return
  }
  if (subRiskAppetite === undefined){
    alert("Please enter a Appetite Level ");
    return
}

    setSubRiskName('');
    setSubRiskOpen(false);
    console.log(comment.subRisks);
    console.log(subLastRiskTmpId);
    if (comment.subRisks === undefined || comment.subRisks === null){
        var arryObj = [];
        var tmpArray = {};
        tmpArray["riskTypeTmpId"] = subLastRiskTmpId + 1;
        tmpArray["name"] = subRiskName;

        tmpArray["riskLevel"] = subRiskLevelValue;
        var tmpArray1 = {}

        tmpArray1["riskLevel"] = subRiskLevelValue
        tmpArray1["riskCategoryId"] = riskCategoryId
        tmpArray1["riskMethodId"] = subRiskLevelType
        tmpArray["riskLevelData"] = tmpArray1;

        tmpArray["riskAppetite"] = subRiskAppetite;

        var tmpArrayApp = {}

          tmpArrayApp["riskAppetite"] = subRiskAppetite
          tmpArrayApp["riskCategoryId"] = riskCategoryIdAppetite
          tmpArrayApp["riskMethodId"] = subAppetiteSliderType
          if (selectedColorForAppetiteSliderSubRisk === 1) {
            tmpArrayApp["color"] = appetiteColorForSaveToDbSubRisk
            tmpArrayApp["secondaryColor"] = ""
          }else{
            tmpArrayApp["color"] = ""
            tmpArrayApp["secondaryColor"] = appetiteColorForSaveToDbSubRisk
          }
          tmpArray["riskAppetiteData"] = tmpArrayApp;

        arryObj.push(tmpArray);
        comment["subRisks"] = arryObj;
        setSubLastRiskTmpId(subLastRiskTmpId + 1);
        setShow(true);
    }else{
        var tmpArray = {};
        tmpArray["riskTypeTmpId"] = subLastRiskTmpId + 1;
        tmpArray["name"] = subRiskName;
        tmpArray["riskLevel"] = subRiskLevelValue;

        var tmpArray1 = {}

        tmpArray1["riskLevel"] = subRiskLevelValue
        tmpArray1["riskCategoryId"] = riskCategoryId
        tmpArray1["riskMethodId"] = subRiskLevelType
        tmpArray["riskLevelData"] = tmpArray1;


        tmpArray["riskAppetite"] = subRiskAppetite;

        var tmpArrayApp = {}

        tmpArrayApp["riskAppetite"] = subRiskAppetite
        tmpArrayApp["riskCategoryId"] = riskCategoryIdAppetite
        tmpArrayApp["riskMethodId"] = subAppetiteSliderType
        if (selectedColorForAppetiteSliderSubRisk === 1) {
          tmpArrayApp["color"] = appetiteColorForSaveToDbSubRisk
          tmpArrayApp["secondaryColor"] = ""
        }else{
          tmpArrayApp["color"] = ""
          tmpArrayApp["secondaryColor"] = appetiteColorForSaveToDbSubRisk
        }
        tmpArray["riskAppetiteData"] = tmpArrayApp;

        comment.subRisks.push(tmpArray);
        setSubLastRiskTmpId(subLastRiskTmpId + 1);
    }
}


  const subRiskHandleClickOpen = (scrollType) => () => {
  setSubRiskOpen(true);
  setSubRiskScroll(scrollType);
  };
  /** End of Create subRisk */

  /**
  * Edit Risk
  */
   const [editRiskName, setEditRiskName] = React.useState('');
   const [editRiskLevel, setEditRiskLevel] = React.useState('');
   const [editRiskAppetite, setEditRiskAppetite] = React.useState('');

   const [editRiskId, setEditRiskId] = React.useState(0);
   const [editRiskOpen, setEditRiskOpen] = React.useState(false);
   const [editRiskScroll, setEditRiskScroll] = React.useState('paper');
   const [editedRisk, setEditedRisk] = React.useState(false);

   const editRiskHandleClickClose = () => {
       setEditRiskOpen(false);
   };

   const handleChangeEditRisk = (event) => {
      setEditRiskName(event.target.value);
      setEditRiskId(event.target.id);
   };
   const handleChangeEditRiskLevel = (event) => {
      const value = event.target.value.replace(/\D/g, "");
      if ((value != 0 && value < 11) || value === "" ){
        setEditRiskLevel(value);
      }  
   };
   const handleChangeEditRiskAppetite = (event) => {
      const value = event.target.value.replace(/\D/g, "");
      if ((value != 0 && value < 11) || value === "" ){
        setEditRiskAppetite(value);
      } 
   };

   function setEditRiskFunction(event){
       if ( editRiskName === ""){
           alert("Please Edit the Risk ");
           return
       }
       if (riskLvlEdit === undefined) {
        alert("Please Edit the Risk level");
        return
      }
      if (appetiteEdit === undefined) {
        alert("Please Edit the Risk Appetite");
        return
      }
       console.log("comment");
       console.log(comment);
       if (comment.name != editRiskName || comment.riskLevel != editRiskLevel || comment.riskAppetite != editRiskAppetite){
        setEditedRisk(true);
       }
       setEditRiskName('');
      // setEditRiskLevel();
      // setEditRiskAppetite();
       setEditRiskOpen(false);
       setRiskLvlEdit()
       setappetiteEdit()
       //setSliderTypeEdit()
       setEditSliderMthodType()
       setEditAppetiteSliderMthod()
       console.log(comment.subRisks);
       comment.name = editRiskName;
       comment.riskLevel = riskLvlEdit;
       comment.riskAppetite = appetiteEdit;
       comment.riskLevelData.riskMethodId = editSliderMthodType;
       comment.riskAppetiteData.riskMethodId = editSliderMthodType
       if (selectedColorForAppetiteSliderEdit === 1) {
        comment.riskAppetiteData.color = appetiteColorForSaveToDbEdit;
        comment.riskAppetiteData.secondaryColor = "";
        }
        else{
          comment.riskAppetiteData.color = "";
          comment.riskAppetiteData.secondaryColor = appetiteColorForSaveToDbEdit;
        }
       setShow(true);
   }

   const [selectedColorForAppetiteSliderEdit, setSelectedColorForAppetiteSliderEdit] = useState("")

   const radioHandlerAppetiteEdit = (status) => {
    if (selectedColorForAppetiteSliderEdit != status){
      setappetiteEdit('')
      setAppetiteSliderValueNameEdit('')
      setappetiteEditStyle('');
      setSelectedColorForAppetiteSliderEdit(status);
    }
  };

   
   
   const editRiskHandleClickOpen = (scrollType) => () => {
      setEditRiskOpen(true);
      // setEditRiskScroll(scrollType);
      setEditRiskName(comment.name);
      // setEditRiskLevel(comment.riskLevel);
      // setEditRiskAppetite(comment.riskAppetite);
      setRiskLvlEdit(comment.riskLevel);
      setappetiteEdit(comment.riskAppetite)
      // setSliderTypeEdit(comment.riskLevelData.riskMethodId)
      setEditSliderMthodType(comment.riskLevelData.riskMethodId)
      setEditAppetiteSliderMthod(comment.riskAppetiteData.riskMethodId)

      var colorScheme = 1;
      if (comment.riskAppetiteData.color != null && comment.riskAppetiteData.color != undefined && comment.riskAppetiteData.color != "" ) {
        setSelectedColorForAppetiteSliderEdit(1);
      }else if(comment.riskAppetiteData.secondaryColor != null && comment.riskAppetiteData.secondaryColor != undefined && comment.riskAppetiteData.secondaryColor != ""){
        setSelectedColorForAppetiteSliderEdit(2)
        colorScheme = 2;
      }else{
        setSelectedColorForAppetiteSliderEdit(1);
      }

              /* To set slider Type name to risklevel when Editing (setRiskLvlSliderValueNameEdit)*/
      var min = 0;
      var max = 0;        
      riskCategoryMethodData.forEach((data)=> {
        if(comment.riskLevelData.riskMethodId === data.id){
          data.riskTypes.forEach((data1)=> {
            if (data1.startingNumber <= comment.riskLevel && comment.riskLevel <= data1.endingNumber) {
              setRiskLvlSliderValueNameEdit(data1.riskTypesName)
              min=data.minimumWeightingNumber;
              max=data.maximumWeightingNumber;
            }
          })
        }
      })

      var posNumber = comment.riskLevel - min;
      var totalNumber = max - min;
      var percentageVal = (posNumber/totalNumber) * 100
      var cCode = getSubColorCode(comment.riskLevel);
      var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
      //alert(cssStr);
      setRiskLvlEditStyle(cssStr);


      /* To set slider Type name to riskAppetite when Editing (setSubAppetitesSliderValueName)*/
      riskCategoryMethodDataAppetite.forEach((data)=> {
        if(comment.riskAppetiteData.riskMethodId === data.id){
          data.riskTypes.forEach((data1)=> {
            if (data1.startingNumber <= comment.riskAppetite && comment.riskAppetite <= data1.endingNumber) {
              setAppetiteSliderValueNameEdit(data1.riskTypesName)
              min=data.minimumWeightingNumber;
              max=data.maximumWeightingNumber;
            }
          })
        }
      })
      var posNumber1 = comment.riskAppetite - min;
      var totalNumber1 = max - min;
      var percentageVal1 = (posNumber1/totalNumber1) * 100

      var cCode1 = getSubColorCodeAppetite(comment.riskAppetite,colorScheme);
      var cssStr1 = 'linear-gradient(90deg, ' + cCode1 + ' ' + percentageVal1 + '%, #d3d3d3 0%)';

      //var cCode1 = getSubColorCode(comment.riskAppetite);
      //var cssStr1 = 'linear-gradient(90deg, ' + cCode1 + ' ' + percentageVal1 + '%, #d3d3d3 0%)';

      setappetiteEditStyle(cssStr1);




   };


   // edit section malindu


   const [showRiskDropDown, setShowRiskDropDown] = useState(false)
   const [editSliderMthodType, setEditSliderMthodType] = useState('')
   const [ riskLvlEdit, setRiskLvlEdit] = useState();
   const [ riskLvlEditStyle, setRiskLvlEditStyle] = useState('');
   const [riskLvlSliderValueNameEdit, setRiskLvlSliderValueNameEdit] = React.useState('');
   const [showAppetiteDropDown, setShowAppetiteDropDown] = useState(false)
   const [editAppetiteSliderMthod, setEditAppetiteSliderMthod] = useState('')
   const [ appetiteEdit, setappetiteEdit] = useState()
   const [ appetiteEditStyle, setappetiteEditStyle] = useState('')
   const [appetitesSliderValueNameEdit, setAppetiteSliderValueNameEdit] = React.useState('');

   function handleChangeRiskEdit(event){
    // console.log(event.target.value)
    //setSliderTypeEdit(event.target.value);
    riskCategoryMethodData.forEach((data)=> {
      if (data.id === event.target.value){
        var colorPatId = data.colorPatternId;
        if (colorPatId != undefined && colorPatId != null){
          colorPatterns.forEach((item)=> {
            if (item.id === colorPatId){
              setDatabClr(item.colors);
            }
          })
        }
      }
    })
    
    setEditSliderMthodType(event.target.value);
    setShowRiskDropDown(false)
 
    
  };

  function nameChangeRiskLvlEdit(sVal){
    var fondType = false;
    riskCategoryMethodData.forEach((data)=> {
    if(editSliderMthodType === data.id){
      data.riskTypes.forEach((data1)=> {
        if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
          setRiskLvlSliderValueNameEdit(data1.riskTypesName)
          fondType = true;
        }
      })
    }
  })
  if (!fondType) {
    setRiskLvlSliderValueNameEdit('');
    setRiskLvlEdit();
  }
}

function riskClearFunctionEdit(){
  //setSliderTypeEdit();

  setRiskLvlEdit();
  setEditSliderMthodType('');
  setRiskLvlSliderValueNameEdit()
  setShowRiskDropDown(true)
  setRiskLvlEditStyle('');
  

}

function handleChangeAppetiteEdit(event){
  // console.log(event.target.value)
  //setSliderTypeEdit(event.target.value);
  setEditAppetiteSliderMthod(event.target.value);
  setShowAppetiteDropDown(false)

  
};

function appetiteNameChangeEdit(sVal){
  var fondType = false;
  riskCategoryMethodDataAppetite.forEach((data)=> {
    if(editAppetiteSliderMthod === data.id){
      data.riskTypes.forEach((data1)=> {
        if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
          setAppetiteSliderValueNameEdit(data1.riskTypesName)
          fondType = true;
        }
      })
    }
  })
  if (!fondType) {
    setAppetiteSliderValueNameEdit('');
    setappetiteEdit();
  }


}

function appetiteClearFunctionEdit(){

  setappetiteEdit()
  setAppetiteSliderValueNameEdit()
  setEditAppetiteSliderMthod('');
  setShowAppetiteDropDown(true)
  setappetiteEditStyle('');
  setSelectedColorForAppetiteSliderEdit(1);

  

}

   /** End of Edit Risk */    

   const [riskQuestion, setRiskQuestion] = useState(false);
   const [RiskD, setRiskD] = useState([])

   function riskTypeQues(closeEndedQuesIds,openEndedQuesIds) {
      var quesDataArray = [];
      closeEndedQuesIds.forEach((item)=>{
        quesDataArray.push(item);
      });
      openEndedQuesIds.forEach((item)=>{
        quesDataArray.push(item);
      });

      console.log("xxxxxxxxxxxxxx")
      console.log(quesDataArray)
      setRiskD(quesDataArray);
      setRiskQuestion(!riskQuestion);

   }






   function getRiskRiskTypeName(Comment){
    var xx = ''
    console.log(Comment)
    riskCategoryMethodData.forEach((data)=> {
      if(data.id === Comment.riskLevelData.riskMethodId){
        data.riskTypes.forEach((data1)=> {
          if(data1.startingNumber <= Comment.riskLevel && Comment.riskLevel <= data1.endingNumber){
            xx = data1.riskTypesName
          }
        })
      }
      
    })
         
  return xx

     
  }

  function getAppetiteRiskTypeName(Comment){
    var xx = ''
    console.log(Comment)
    console.log(riskCategoryMethodDataAppetite)
    riskCategoryMethodDataAppetite.forEach((data)=> {
      if(data.id === Comment.riskAppetiteData.riskMethodId){
        data.riskTypes.forEach((data1)=> {
          if(data1.startingNumber <= Comment.riskAppetite && Comment.riskAppetite <= data1.endingNumber){
            xx = data1.riskTypesName
          }
        })
      }
      
    })
         
  return xx

     
  }



  function getRiskRiskColor(Comment){
    console.log(Comment)
    console.log(Comment.riskLevel)

    var xx = ''

          databClr.forEach((data2)=>{         
            if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
              console.log(data2.color)
              console.log(Comment.riskLevel)
              xx = data2.color
            }
          })
      
  return xx
  }


  function getAppetiteColor(Comment){
    var xx = ''

          databClr.forEach((data2)=>{         
            if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
              if (Comment.riskAppetiteData != null && Comment.riskAppetiteData != undefined){
                if (Comment.riskAppetiteData.color != null && Comment.riskAppetiteData.color != undefined && Comment.riskAppetiteData.color != ''){
                  xx = Comment.riskAppetiteData.color;
                }
                if (Comment.riskAppetiteData.secondaryColor != null && Comment.riskAppetiteData.secondaryColor != undefined && Comment.riskAppetiteData.secondaryColor != ''){
                  xx = Comment.riskAppetiteData.secondaryColor;
                }
              }
              if (xx === ''){
                xx = data2.color
              }
    
            }
          })
      

         
  return xx
  }


   function XXXXXX({ comment }) {
    var riskName = getRiskRiskTypeName(comment)
    var appetiteName = getAppetiteRiskTypeName(comment)
    var riskColor = getRiskRiskColor(comment)
    var appetiteColor = getAppetiteColor(comment)

    console.log(riskColor)

    return(
      <>
        
            {comment.name}  
          { (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' (' )   + 
           (closeEndedQuestionIdsWithSub.length > 0 ?' C : ' + closeEndedQuestionIdsWithSub.length:'') + 
           (openEndedQuestionIdsWithSub.length > 0 ? closeEndedQuestionIdsWithSub.length > 0 ?', O : ' + openEndedQuestionIdsWithSub.length:' O : ' + openEndedQuestionIdsWithSub.length:'') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' )' )}
           { " - (Risk : " }  { comment.riskLevel } {' '} { <span style={{color:`${riskColor}`}}> <b>-{riskName}</b> </span> }{" , Appetite : "} { comment.riskAppetite } {' '} { <span style={{color:`${appetiteColor}`}}><b>-{appetiteName}</b></span>}{")" }
        

       </>
    )
  }


  function YYYYY({ comment }) {
    var riskName = getRiskRiskTypeName(comment)
    var appetiteName = getAppetiteRiskTypeName(comment)
    var riskColor = getRiskRiskColor(comment)
    var appetiteColor = getAppetiteColor(comment)

    console.log(riskColor)

    return(
      <>
        
         { comment.name } { " - (Risk : " } {comment.riskLevel} {' '}  {<span style={{color:`${riskColor}`}}> <b>-{riskName}</b> </span>} {" , Appetite : " } {comment.riskAppetite } {' '} { <span style={{color:`${appetiteColor}`}}><b>-{appetiteName}</b></span>}  {")"}
        

     
       </>
    )
  }


  function ZZZZZ({ comment }) {
    var riskName = getRiskRiskTypeName(comment)
    var appetiteName = getAppetiteRiskTypeName(comment)
    var riskColor = getRiskRiskColor(comment)
    var appetiteColor = getAppetiteColor(comment)

    console.log(riskColor)

    return(
      <>
        
         { comment.name }
         { (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'':' (' ) + 
          (closeEndedQuestionIds.length > 0 ?' C : ' + closeEndedQuestionIds.length:'') + 
          (openEndedQuestionIds.length > 0? closeEndedQuestionIds.length > 0 ?', O : ' + openEndedQuestionIds.length:' O : ' + openEndedQuestionIds.length:'') + 
          (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' )' )}
          { " - (Risk : " } { comment.riskLevel } {' '}  {<span style={{color:`${riskColor}`}}> <b>-{riskName}</b> </span>} {" , Appetite : "} { comment.riskAppetite }{' '} { <span style={{color:`${appetiteColor}`}}><b>-{appetiteName}</b></span>} { " )" }

       </>
    )
  }

  function RiskNameUpdate({ comment }) {
    var riskName = getRiskRiskTypeName(comment)
    var appetiteName = getAppetiteRiskTypeName(comment)
    var riskColor = getRiskRiskColor(comment)
    var appetiteColor = getAppetiteColor(comment)

    console.log(riskName)

    return(
      <>

          {comment.name }+ 
          {(closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'':' (' ) + 
          (closeEndedQuestionIds.length > 0 ?' C : ' + closeEndedQuestionIds.length:'') + 
          (openEndedQuestionIds.length > 0? closeEndedQuestionIds.length > 0 ?', O : ' + openEndedQuestionIds.length:' O : ' + openEndedQuestionIds.length:'') + 
          (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' )' )}
          { " - (Risk : "} {comment.riskLevel } {' '}  {<span style={{color:`${riskColor}`}}> <b>-{riskName}</b> </span>}{" )"}
        
    
       </>
    )
  }

  function RiskNameConFalse({ comment }) {
    var riskName = getRiskRiskTypeName(comment)
    var appetiteName = getAppetiteRiskTypeName(comment)
    var riskColor = getRiskRiskColor(comment)
    var appetiteColor = getAppetiteColor(comment)

    console.log(riskName)

    return(
      <>
        

         {comment.name }+ 
         { (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' (' )   + 
          (closeEndedQuestionIdsWithSub.length > 0 ?' C : ' + closeEndedQuestionIdsWithSub.length:'') + 
          (openEndedQuestionIdsWithSub.length > 0 ? closeEndedQuestionIdsWithSub.length > 0 ?', O : ' + openEndedQuestionIdsWithSub.length:' O : ' + openEndedQuestionIdsWithSub.length:'') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' )' )}
          { " - (Risk : "}{ comment.riskLevel} { ' '}  {<span style={{color:`${riskColor}`}}> <b>-{riskName}</b> </span>}{" )"}
        
    
       </>
    )
  }

  function RiskNameConTrue({ comment }) {
    var riskName = getRiskRiskTypeName(comment)

    var riskColor = getRiskRiskColor(comment)


    console.log(riskName)

    return(
      <>
        
           {comment.name}  {" - (Risk : "} {comment.riskLevel}   {' '}  {<span style={{color:`${riskColor}`}}> <b>-{riskName}</b> </span>}{" )"}
        
    
       </>
    )
  }


  function RiskAppetiteNameUpdate({ comment }) {
    var riskName = getRiskRiskTypeName(comment)
    var appetiteName = getAppetiteRiskTypeName(comment)
    var riskColor = getRiskRiskColor(comment)
    var appetiteColor = getAppetiteColor(comment)

    console.log(riskName)

    return(
      <>

        
           {comment.name}  
            {(closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'':' (' ) + 
            (closeEndedQuestionIds.length > 0 ?' C : ' + closeEndedQuestionIds.length:'') + 
            (openEndedQuestionIds.length > 0? closeEndedQuestionIds.length > 0 ?', O : ' + openEndedQuestionIds.length:' O : ' + openEndedQuestionIds.length:'') + 
            (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' )' )}
            {" - (Appetite : "} {comment.riskAppetite} {' '} { <span style={{color:`${appetiteColor}`}}><b>-{appetiteName}</b></span>} {" )"}
        
    
       </>
    )
  }

  function RiskAppetiteNameConFalse({ comment }) {
    var riskName = getRiskRiskTypeName(comment)
    var appetiteName = getAppetiteRiskTypeName(comment)
    var riskColor = getRiskRiskColor(comment)
    var appetiteColor = getAppetiteColor(comment)

    console.log(riskName)

    return(
      <>

        
                    {comment.name}  
                    {(closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' (' )   + 
                    (closeEndedQuestionIdsWithSub.length > 0 ?' C : ' + closeEndedQuestionIdsWithSub.length:'') + 
                    (openEndedQuestionIdsWithSub.length > 0 ? closeEndedQuestionIdsWithSub.length > 0 ?', O : ' + openEndedQuestionIdsWithSub.length:' O : ' + openEndedQuestionIdsWithSub.length:'') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' )' )}
                   {" - (Appetite : "} {comment.riskAppetite} {' '} { <span style={{color:`${appetiteColor}`}}><b>-{appetiteName}</b></span>}  {" )"}
        
    
       </>
    )
  }

  function RiskAppetiteNameConTrue({ comment }) {
    var riskName = getRiskRiskTypeName(comment)
    var appetiteName = getAppetiteRiskTypeName(comment)
    var riskColor = getRiskRiskColor(comment)
    var appetiteColor = getAppetiteColor(comment)

    console.log(riskName)

    return(
      <>
                
                    {comment.name}  {" - (Appetite : "} {comment.riskAppetite}  {' '} { <span style={{color:`${appetiteColor}`}}><b>-{appetiteName}</b></span>}{" )"}
                
       </>
    )
  }



   const riskDisplayStr = (
     <>
     {comment.riskLevel != undefined && comment.riskLevel != null && comment.riskLevel != "" && comment.riskAppetite != undefined && comment.riskAppetite != null && comment.riskAppetite != "" && (
      saveBtnNameForRisk  === "Update Risk"
      ?
        show
        ?
            <ZZZZZ 
      
            comment={comment} 
            />

        :
   
            <XXXXXX 
      
            comment={comment} 
            />


      :
       <YYYYY 
            
            comment={comment} 
      />
      
      
      
     )}
     {comment.riskLevel != undefined && comment.riskLevel != null && comment.riskLevel != "" && (comment.riskAppetite === undefined || comment.riskAppetite === null || comment.riskAppetite === "") && (
      saveBtnNameForRisk  === "Update Risk"
      ?
        show
        ?


        <RiskNameUpdate 
            
        comment={comment} 
  />
        
        :
       
        <RiskNameConFalse 
            
        comment={comment} 
  />

         
      :

      <RiskNameConTrue 
            
      comment={comment} 
/>
     
      
      
     
     )}
     {(comment.riskLevel === undefined || comment.riskLevel === null || comment.riskLevel === "") && comment.riskAppetite != undefined && comment.riskAppetite != null && comment.riskAppetite != "" && (
      saveBtnNameForRisk  === "Update Risk"
      ?
        show
        ?

        

        <RiskAppetiteNameUpdate 
            
        comment={comment} 
  />
        
        :

        

        <RiskAppetiteNameConFalse 
            
        comment={comment} 
  />

      :
      
      <RiskAppetiteNameConTrue 
            
      comment={comment} 
/>
      
    
     )}
     {(comment.riskLevel === undefined || comment.riskLevel === null || comment.riskLevel === "") && (comment.riskAppetite === undefined || comment.riskAppetite === null || comment.riskAppetite === "") && (
      saveBtnNameForRisk  === "Update Risk"
      ?
        show
        ?
          comment.name + 
            (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'':' (' ) + 
            (closeEndedQuestionIds.length > 0 ?' C : ' + closeEndedQuestionIds.length:'') + 
            (openEndedQuestionIds.length > 0? closeEndedQuestionIds.length > 0 ?', O : ' + openEndedQuestionIds.length:' O : ' + openEndedQuestionIds.length:'') + 
            (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' )' )
        :
          comment.name + 
            (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' (' )   + 
            (closeEndedQuestionIdsWithSub.length > 0 ?' C : ' + closeEndedQuestionIdsWithSub.length:'') + 
            (openEndedQuestionIdsWithSub.length > 0 ? closeEndedQuestionIdsWithSub.length > 0 ?', O : ' + openEndedQuestionIdsWithSub.length:' O : ' + openEndedQuestionIdsWithSub.length:'') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' )' )
      :comment.name

     )}
     </>

   );

  return (
      <div style={{"marginLeft": "25px", "marginTop": "10px"}}>
          <div style={{display:'flex',textAlign:'center'}}> 
              <Button 
                  style={{width:'0px', height:'0px'}} 
                  onClick={()=>setShow(!show)} 
                  startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  >
              </Button>
              {riskDisplayStr}
              &nbsp;&nbsp;<Button 
                  variant="outlined" 
                  size='small' 
                  style={{"border": "1px solid #000000 !important",  "color": "#8a8181 !important",  "padding":"5px 10px !important",  "height": "35px !important"}}
                  onClick={subRiskHandleClickOpen('paper')}
                  > 
                  + Add Sub Risk
              </Button>&nbsp;&nbsp;
              <Button 
                  variant="outlined" 
                  size='small' 
                  style={{"border": "1px solid #000000 !important",  "color": "#8a8181 !important",  "padding":"5px 10px !important",  "height": "35px !important"}}
                  onClick={editRiskHandleClickOpen('paper')}
                  > 
                  Edit
              </Button>
              {saveBtnNameForRisk  === "Update Risk" && !editedRisk && (
                <>
                    &nbsp;&nbsp;
                    <Link to={`/questioPage/${rtnCollectionUniqueName}?collectionRiskId=${selectedCollectionRiskId}&collectionRiskTypeId=${comment.collectionRiskTypeId}&riskTypeName=${comment.name}&riskLevel=${comment.riskLevel}&riskLevelCategoryId=${comment.riskLevelData.riskCategoryId}&riskLevelMethodId=${comment.riskLevelData.riskMethodId}&riskAppetite=${comment.riskAppetite}&riskAppetiteCategoryId=${comment.riskAppetiteData.riskCategoryId}&riskAppetiteMethodId=${comment.riskAppetiteData.riskMethodId}`}>
                      <Button 
                      variant="outlined" 
                      size='small' 
                      style={{"border": "1px solid #000000 !important",  "color": "#8a8181 !important",  "padding":"5px 10px !important",  "height": "35px !important"}}
                      > 
                      Add Question
                    </Button>
                  </Link>
                </>
              )}
              {saveBtnNameForRisk  === "Update Risk" && 
              show?closeEndedQuestionIds.length > 0 || openEndedQuestionIds.length > 0 ? 
                    <>      
                    &nbsp;&nbsp;<Button 
                      onClick={()=>riskTypeQues(closeEndedQuestionIds,openEndedQuestionIds,!show)}
                      variant="outlined" 
                      size='small' 
                      style={{"border": "1px solid #000000 !important",  "color": "#8a8181 !important",  "padding":"5px 10px !important",  "height": "35px !important"}}
                      > 
                      Show Questions
                    </Button> </>: ''
                    :closeEndedQuestionIdsWithSub.length > 0 || openEndedQuestionIdsWithSub.length > 0 ? 
                    <>
                    &nbsp;&nbsp;<Button 
                      onClick={()=>riskTypeQues(closeEndedQuestionIdsWithSub,openEndedQuestionIdsWithSub,!show)}
                      variant="outlined" 
                      size='small' 
                      style={{"border": "1px solid #000000 !important",  "color": "#8a8181 !important",  "padding":"5px 10px !important",  "height": "35px !important"}}
                      > 
                      Show Questions
                    </Button> </>: ''   
              }

          </div>
          {
              show?
                  <>
                      {nestedComments}
                  </>
              :null
          }


          {riskQuestion
            ?
              <Draggable>
                  <div style={{zIndex:'1000'}}>
                  <Grid item xs={6} style={{marginLeft:'20%',backgroundColor:'rgb(246 245 252)', border:'1px solid black'}}>
                          <Typography variant="h6" style={{float: 'right', marginTop:'-5px'}}><Tooltip title="Close"><Close style={{cursor: 'pointer'}} onClick={()=>setRiskQuestion(!riskQuestion)}/></Tooltip></Typography>            
                          <Grid container spacing={2}>
                              <RiskTypeQustionDisplay
                                  quesData={RiskD}
                                  collectnId={collectnId}
                                  setRiskQuestion={setRiskQuestion}
                                  riskQuestion={riskQuestion}
                              />
                          </Grid>    
                      </Grid>
                  </div>
              </Draggable>
            :
              null
          }



      {/** Add Sub Risk Dialog */}
      <Dialog
                  open={subRiskOpen}
                  onClose={false}
                  scroll={subRiskScroll}
                  aria-labelledby="sub-risk-scroll-dialog-title"
                  aria-describedby="sub-risk-scroll-dialog-description"
                  fullWidth={true}
                  maxWidth={'md'}
                  PaperComponent={PaperComponentSubRisk}
                  >
                  <DialogTitle style={{ cursor: 'move' }} id="sub-risk-scroll-dialog-title">Create a Sub Risk for {comment.name}</DialogTitle>
                  <DialogContent dividers={subRiskScroll === 'paper'}>
                      <DialogContentText
                          id="sub-risk-scroll-dialog-description"
                          tabIndex={-1}
                          >
                          <Grid container spacing={2}>
                              <Grid item xs={10}>
                                  <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                                      <div>
                                          <TextField
                                              error
                                              id={comment.riskTypeTmpId}
                                              label="Sub Risk"
                                              multiline
                                              maxRows={2}
                                              value={subRiskName}
                                              onChange={handleChangeSubRisk}
                                          />
                                      </div>
                                  </Box> 
                              </Grid>
                         
                              <Grid item xs={5}>
                                                 <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                                                   <div>

                                                   <RiskLevelSlider
                                                          MethodDropdown={'Risk Level Slider'}
                                                          SliderName={'Risk level'}
                                                          showSelectBar={subRiskLvlShow}
                                                          handleChange={handleChangeSubRiskLevel}
                                                          sliderType={subRiskLevelType}
                                                          riskCategoryMethodData={riskCategoryMethodData}
                                                          nameChange={subRiskNameChange}
                                                          sliderValue={subRiskLevelValue}
                                                          sliderValueStyle={subRiskLevelValueStyle}
                                                          sliderValueName={subRiskSliderValueName}
                                                          riskClearFunction={subRiskClearFunction}
                                                          setSliderValueFunction={setSubRiskLevelValueFunction}
                                                     />
                                                    {/* <>
    
                                                  {
                                                      subRiskLvlShow ? 
                                                      
                                                      <div>

                                                      <Box >
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={10}>
                                                      <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Risk Level Slider</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          value={subRiskLevelType}
                                                          label="Age"
                                                          onChange={handleChangeSubRiskLevel}
                                                        >

                                                            {riskCategoryMethodData.map((data,index)=> (
                                                            
                                                            <MenuItem value={data.id}>{data.methodName}</MenuItem>

                                                           
                                                          ))}
                                                  
                                                        </Select>
                                                      </FormControl>
                                                      </Grid>
                                                      </Grid>
                                                    </Box>
                                                
                                                    </div>
                                                      
                                                      
                                                      :null
                                                  }
                                                    
                                                  {
                                                    riskCategoryMethodData.map((data)=>(
                                                     
                                                      <>
                                                      {
                                                        subRiskLevelType === data.id ? 
                                                        <>
                                                        
                                                         <p style={{color:'black'}}>Risk Level</p>
                                                              <Grid container spacing={2}>
                                                              
                                                                <Grid item xs={7}>
                                                                  <div>
                                                                 
                                                                    <input
                                                                    
                                                                      type="range"
                                                                      min={data.minimumWeightingNumber}
                                                                      
                                                                      max={data.maximumWeightingNumber}
                                                                      value={subRiskLevelValue}
                                                                      //onChange={({ target: { value } }) => setSubRiskLevelValue(value)}
                                                                      onChange={({ target: { value } }) => {setSubRiskLevelValueFunction(value,data.minimumWeightingNumber,data.maximumWeightingNumber,1);subRiskNameChange(value)}}
                                                                      className={`slider`}
                                                                      // className={btnEntityClass()}
                                                                      style={{background: `${subRiskLevelValueStyle}`}}
                                                                      //onClick={subRiskNameChange} 
                                                                      />
                                                                  </div>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                  <div >
                                                                  <div >
                                                                    <p style={{fontSize:'10px'}}>{subRiskSliderValueName}</p>
                                                                  </div>
                                                                 
                                                                  </div>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                <div>
                                                                  <Button variant="outlined"  onClick={subRiskClearFunction}>clear</Button>
                                                                  </div>
                                                                </Grid>
                                                                
                                                                
                                                              </Grid>
                                                            <p>
                                                              <b>{subRiskLevelValue}&nbsp;-&nbsp;{subRiskSliderValueName}</b>
                                                              </p>
                                                        </> 
                                                        
                                                        
                                                        
                                                        :null
                                                      }
                                                      </>
                                                    ))
                                                  }

                                                      
                                                    
                                                        </> */}
                                                                </div>
                                                            </Box> 
                                                        </Grid>



                                                        <Grid item xs={1} spacing={2}></Grid>

                                                        <Grid item xs={5}>
                                                            <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                                                                <div>
                                                                <RiskAppetiteSlider
                                                                  SliderName={'Risk Appetites'}
                                                                  MethodDropdown ={'Risk Appetites Slider'}
                                                                  appetitesShowSelectBar={subAppetitesShowSelectBar}
                                                                  appetiteSliderType={subAppetiteSliderType}
                                                                  appetiteHandleChange={subAppetiteHandleChange}
                                                                  riskCategoryMethodDataAppetite={riskCategoryMethodDataAppetite}
                                                                  selectedColorForAppetiteSlider={selectedColorForAppetiteSliderSubRisk}
                                                                  radioHandler={radioHandlerAppetiteSubEdit}
                                                                  setSliderValueFunction={setSubRiskLevelValueFunction}
                                                                  appetitesSliderValueStyle={subRiskAppetiteStyle}
                                                                  appetiteClearFunction={subAppeClearFunction}
                                                                  appetitesSliderValueName={subAppetitesSliderValueName}
                                                                  appetitesSliderValue={subRiskAppetite}
                                                                  appetiteNameChange={subAppetiteNameChange}
                                                          />
                                                                </div>
                                                            </Box> 
                                                        </Grid>



                          </Grid>
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Button  onClick={() => setSubRiskFunction(this)}>add</Button>
                      <Button onClick={subRiskHandleClickClose}>Cancel</Button>
                  </DialogActions>
              </Dialog>
    {/** End of Add Sub Risk Dialog */}

    {/** Edit Sub Risk Dialog */}
    <Dialog
                  open={editRiskOpen}
                  onClose={editRiskHandleClickClose}
                  scroll={editRiskScroll}
                  aria-labelledby="edit-risk-scroll-dialog-title"
                  aria-describedby="edit-risk-scroll-dialog-description"
                  fullWidth={true}
                  maxWidth={'md'}
                  PaperComponent={PaperComponentRiskEdit}
                  >
                  <DialogTitle  style={{ cursor: 'move' }} id="edit-risk-scroll-dialog-title">Edit Risk - {comment.name}</DialogTitle>
                  <DialogContent dividers={editRiskScroll === 'paper'}>
                      <DialogContentText
                          id="edit-risk-scroll-dialog-description"
                          tabIndex={-1}
                          >
                          <Grid container spacing={2}>
                              <Grid item xs={10}>
                                  <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                                      <div>
                                          <TextField
                                              id={comment.riskTypeTmpId}
                                              label="Edit Risk"
                                              multiline
                                              maxRows={2}
                                              value={editRiskName}
                                              onChange={handleChangeEditRisk}
                                          />
                                      </div>
                                  </Box> 
                              </Grid>

                              <Grid item xs={5}>
                                  <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                                      <div>
                                      <>
    
                                            <RiskLevelSlider
                                                MethodDropdown={'Risk Level Slider'}
                                                SliderName={'Risk level'}
                                                showSelectBar={showRiskDropDown}
                                                handleChange={handleChangeRiskEdit}
                                                sliderType={editSliderMthodType}
                                                riskCategoryMethodData={riskCategoryMethodData}
                                                nameChange={nameChangeRiskLvlEdit}
                                                sliderValue={riskLvlEdit}
                                                sliderValueStyle={riskLvlEditStyle}
                                                sliderValueName={riskLvlSliderValueNameEdit}
                                                riskClearFunction={riskClearFunctionEdit}
                                                setSliderValueFunction={setRiskLvlEditFunction}
                                        />





                                          
                                          

                                            </>
                                      </div>
                                  </Box> 
                              </Grid>



                              <Grid item xs={1} spacing={2}></Grid>

                              <Grid item xs={5}>
                              <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                                                                <div>
                                                                <RiskAppetiteSlider
                                                                    SliderName={'Risk Appetites'}
                                                                    MethodDropdown ={'Risk Appetites Slider'}
                                                                    appetitesShowSelectBar={showAppetiteDropDown}
                                                                    appetiteSliderType={editAppetiteSliderMthod}
                                                                    appetiteHandleChange={handleChangeAppetiteEdit}
                                                                    riskCategoryMethodDataAppetite={riskCategoryMethodDataAppetite}
                                                                    selectedColorForAppetiteSlider={selectedColorForAppetiteSliderEdit}
                                                                    radioHandler={radioHandlerAppetiteEdit}
                                                                    setSliderValueFunction={setRiskLvlEditFunction}
                                                                    appetitesSliderValueStyle={appetiteEditStyle}
                                                                    appetiteClearFunction={appetiteClearFunctionEdit}
                                                                    appetitesSliderValueName={appetitesSliderValueNameEdit}
                                                                    appetitesSliderValue={appetiteEdit}
                                                                    appetiteNameChange={appetiteNameChangeEdit}
                      />
                                                                </div>
                                                            </Box> 
                              </Grid>
                                  



                          </Grid>
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button  onClick={() => setEditRiskFunction(this)}>Update</Button>
                    <Button onClick={editRiskHandleClickClose}>Cancel</Button>
                  </DialogActions>
              </Dialog>


    {/** End of Edit Sub Risk Dialog */}






    </div>
  )
}


/** End of Comment section */





  return (
    <>
       {/* {CONFIG.EXPERT_LOGIN?
        <RightBarDataForOtherPages PageName={pageBannerName}/> 
       :
       <RightBarDataForUserPages PageName={pageBannerName} CentreName={centreName}/> 
       }  */}
       
       
        {/* <div className={classes.pageTop}> */}
        <div>
            <Grid>
            <form>
                <Grid container spacing={2} className={classes.pageBodyStyle}>
                   

                    {/* <Grid item xs={12}>
                    <div role="presentation" onClick={handleClick}>
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/login">
                          Home
                        </Link>
                        
                        <Typography color="text.primary">{pageBannerName}</Typography>
                      </Breadcrumbs>
                    </div>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Button 
                        variant="contained" 
                        onClick={e => reloadAll(e)}  
                        style={{float:'right', marginRight: '49px', marginTop: '-29px'}}
                        >Reload latest Updates
                      </Button>
                    </Grid>
                    {(collectnId != null && collectnId != '') || rtnCollectedId != ''?
                    
                     <> 
                     <Grid item xs={12}>
                     <Card className={classes.cardRiskManage}>
                     
                      <CardContent>                   
                    <Grid item xs={10}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                            >
                            <div>
                                <TextField
                                    error
                                    id="title"
                                    size='small'
                                    label="Questionnaire Group Title"
                                    multiline
                                    maxRows={2}
                                    name='title'
                                    required 
                                    value={questionerTitlevalue}
                                    onChange={e => onTextFieldChange(e)}
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={10}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                            >
                            <div>
                                <TextField
                                    error
                                    id="description"
                                    size='small'
                                    label="Questionnaire Group Description"
                                    multiline
                                    maxRows={3}
                                    name='description'
                                    required
                                    value={questionerDescvalue}
                                    onChange={e => onTextFieldChange(e)}
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table sx={{ minWidth: 720 }} aria-label="spanning table" className={classes.tableStyle}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" rowSpan={2} className={classes.thStyleImage}>
                                <Box sx={{ minWidth: 120 }} className={classes.riskImageSize}>
                                  <Card className={classes.root}>
                                      <div className={classes.posRelativeAndDivBorder}>   
                                        {preview != null && preview != "" &&  <img src={preview} className={classes.riskImageSize}/> }   
                                        
                                      </div>
                                  </Card>        
                                </Box>
                              </TableCell>
                              <TableCell align="right" className={classes.thStyle}>
                                <Stack direction="row" spacing={2}>
                                <label htmlFor="contained-button-file">
                                    <Input accept="image/*" id="contained-button-file" type="file" name="file"
                                     onChange={(event) => {

                                      console.log(event.target.files[0]);
                                      setSelectedFile(event.target.files[0]);
                                    }}/>
                                      <Button variant="contained" component="span" className={classes.btnColorPurple} >
                                        Upload new photo
                                      </Button>
                                    </label>
                                    <Button 
                                      type='submit' 
                                      onClick={e => onFormSubmit(e)} 
                                      variant="outlined"
                                      className={classes.btnBorderGreen}> 
                                      {saveBtnName}
                                    </Button>
                                    <Button onClick={clearFuction}  variant="outlined" className={classes.btnclear} color="error"> 
                                      clear
                                    </Button>


                                    {saveRiskFlag && rtnCollectionUniqueName != '' && (
                                      <>
                                      <Link to={`/questioPage/${rtnCollectionUniqueName}`}>
                                        <Button variant="outlined" size='small' className={classes.btnBorderpurple}> 
                                          Add A New Question
                                        </Button>
                                      </Link>
                                      <Link to={`/questionerManagement/${rtnCollectionUniqueName}`}>
                                        <Button variant="outlined" size='small' className={classes.btnBorderpurple}> 
                                          Show / Edit Available Questions
                                        </Button>
                                      </Link>
                                      </>
                                    )}


                                </Stack>  
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.thStyle}><span className={classes.topHeaderText2}>Allowed JPG, PNG or GIF. Max size of 800k</span></TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </Grid> 
                    </CardContent>
                    </Card>
                    </Grid>
                    </>
                    :

                    <>                    
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Card id='focusCardId' style={{"width": "100% !important", border:"1px solid black"}}>
                          <CardHeader 
                              avatar={
                                  <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                      <Info/>
                                  </Avatar>
                              } 
                              className={classe.commonMainTableHead} 
                              title={pageBannerName}
                          />
                          <CardContent>
                                    <Grid container spacing={2}>
                                    <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                            >
                            <div>
                                <TextField
                                    error
                                    id="title"
                                    size='small'
                                    label="Questionnaire Group Title"
                                    multiline
                                    maxRows={2}
                                    name='title'
                                    required 
                                    value={questionerTitlevalue}
                                    onChange={e => onTextFieldChange(e)}
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                            >
                            <div>
                                <TextField
                                    error
                                    id="description"
                                    size='small'
                                    label="Questionnaire Group Description"
                                    multiline
                                    maxRows={3}
                                    name='description'
                                    required
                                    value={questionerDescvalue}
                                    onChange={e => onTextFieldChange(e)}
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table sx={{ minWidth: 720 }} aria-label="spanning table" className={classes.tableStyle}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" rowSpan={2} className={classes.thStyleImage}>
                                <Box sx={{ minWidth: 120 }} className={classes.riskImageSize}>
                                  <Card className={classes.root}>
                                      <div className={classes.posRelativeAndDivBorder}>   
                                        {preview != null && preview != "" &&  <img src={preview} className={classes.riskImageSize}/> }   
                                        
                                      </div>
                                  </Card>        
                                </Box>
                              </TableCell>
                              <TableCell align="right" className={classes.thStyle}>
                                <Stack direction="row" spacing={2}>
                                <label htmlFor="contained-button-file">
                                    <Input accept="image/*" id="contained-button-file" type="file" name="file"
                                     onChange={(event) => {

                                      console.log(event.target.files[0]);
                                      setSelectedFile(event.target.files[0]);
                                    }}/>
                                      <Button variant="contained" component="span" className={classes.btnColorPurple} >
                                        Upload new photo
                                      </Button>
                                    </label>
                                    <Button 
                                      type='submit' 
                                      onClick={e => onFormSubmit(e)} 
                                      variant="outlined"
                                      className={classes.btnBorderGreen}> 
                                      {saveBtnName}
                                    </Button>
                                    <Button onClick={clearFuction}  variant="outlined" className={classes.btnclear} color="error"> 
                                      clear
                                    </Button>


                                    {saveRiskFlag && rtnCollectionUniqueName != '' && (
                                      <>
                                      <Link to={`/questioPage/${rtnCollectionUniqueName}`}>
                                        <Button variant="outlined" size='small' className={classes.btnBorderpurple}> 
                                          Add A New Question
                                        </Button>
                                      </Link>
                                      <Link to={`/questionerManagement/${rtnCollectionUniqueName}`}>
                                        <Button variant="outlined" size='small' className={classes.btnBorderpurple}> 
                                          Show / Edit Available Questions
                                        </Button>
                                      </Link>
                                      </>
                                    )}


                                </Stack>  
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.thStyle}><span className={classes.topHeaderText2}>Allowed JPG, PNG or GIF. Max size of 800k</span></TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </Grid> 









                                    </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={3} spacing={2}>
                      <Card className={classes.stdCard}>
                        <CardHeader 
                          avatar={
                            <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                              <Info/>
                            </Avatar>
                            } 
                          className={classe.commonMainTableHead} 
                          title="Questionnaire Groups Created"
                          />
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div className={classes.scrollableDivToStandard}>
                                  <TableContainer 
                                    component={Paper}
                                    //sx={{ maxHeight: 440 }}
                                    >
                                    <Table  aria-label="customized table">
                                      <TableHead className={classe.commonSecondaryTableHead}>
                                        <TableRow>
                                          <StyledTableCell className={classe.commonTableThStyle} style={{width: '90%'}}>Questionnaire Groups Title</StyledTableCell>
                                          <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Edit</StyledTableCell>
                                        </TableRow>
                                      </TableHead>
                                      {previousCollectionTableRowData}
                                      
                                    </Table>
                                  </TableContainer>    
                                </div>
                              </Grid>  

                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>    
                    </Grid>
                    
                    </>












                    }                    














                    {rtnCollectedId != '' && (
                      <>
                      <Grid item xs={12} spacing={2}>
                        
                        <Card className={classes.cardRiskManage} >
                              <CardContent>
                                  <Grid container spacing={2}>
                                      <Grid item xs={12}>
                                        <h3>Manage Risk Hierarchy, Risk Level and Risk Appetite</h3>
                                      </Grid>
                                      <Grid item xs={8}>
                                        <Box sx={{ minWidth: 120}}>    
                                              <FormControl fullWidth>
                                                  <TextField
                                                      label="Select a Global Risk Hierarchy"
                                                      sx={{ width: '100%' }}
                                                      select
                                                      value={golobalRiskHierarchyId}
                                                      onChange={handleChangeGolobalRiskHierarchy}
                                                      >
                                                      {globalRiskArray.map((item, index)=>(
                                                          <MenuItem 
                                                              value={item.riskId}
                                                              >
                                                          {item.name === null? "name" + index:item.name}
                                                          </MenuItem>
                                                      ))}
                                                  </TextField>
                                              </FormControl>
                                          </Box>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Button variant="outlined" onClick={() => setGlobalRiskToCollection()} className={classes.stdButtonsStyle}>
                                            Add To Questionnaire Collection
                                        </Button>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Button variant="outlined" onClick={() => createNewRiskHierarchy()} className={classes.stdButtonsStyle}>
                                            Create a New RisK Hierarchy
                                        </Button>
                                      </Grid>
                                      {displaySaveEditRisk && (                
                                      <>
<Card className={classes.cardRiskManageInside} >
                              <CardContent>
                                  <Grid container spacing={2}>





                                        <Grid item xs={12}>
                                          <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                                              <div>
                                                  <TextField
                                                      error
                                                      id="riskHierarchyName"
                                                      size='small'
                                                      label="Risk Hierarchy Name"
                                                      multiline
                                                      maxRows={3}
                                                      name='riskHierarchyName'
                                                      required
                                                      value={riskHierarchyNameValue}
                                                      onChange={handleChangeRiskHierarchyName}
                                                  />
                                              </div>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                              <Button 
                                                  variant="outlined" 
                                                  size='small' 
                                                  //style={{"border": "1px solid #9155FD !important",  "color": "#9155FD !important",  "padding":"5px 10px !important",  "height": "40px !important"}}
                                                  onClick={mainRiskHandleClickOpen('paper')}
                                                  className={classe.commonButton}
                                                  > 
                                                  + Add Risk Type
                                              </Button>
                                              {/** Main Risk add popup is bottom of the Page */}
                                              
                                        </Grid>
                                        <Grid item xs={12}>
                                            {Object.keys(riskTypeData).length >0 &&
                                              <>
                                                  {
                                                      riskTypeData.riskTypes.map((riskType) => {
                                                        return (
                                                          <Comment 
                                                              key={riskType.riskTypeTmpId} 
                                                              comment={riskType} 
                                                              lastRiskTmpId={lastRiskTmpId}
                                                              saveBtnNameForRisk={saveBtnNameForRisk}
                                                              rtnCollectionUniqueName={rtnCollectionUniqueName}                                                              
                                                              selectedCollectionRiskId={selectedCollectionRiskId}
                                                              />
                                                        )
                                                      })
                                                  }
                                              </>  
                                            }  
                                          </Grid>  
                                          {
                                              showSaveBtn?
                                                  <Grid item xs={10} spacing={2}>

                                                      <Button 
                                                          variant="contained" 
                                                          size='small' 
                                                          style={{"border": "1px solid #9155FD !important",  "color": "#9155FD !important",  "padding":"5px 10px !important",  "height": "40px !important", "float": "right"}}
                                                          onClick={saveRisk}
                                                      > 
                                                  {saveBtnNameForRisk}
                                              </Button>
                                                  </Grid>
                                              :null
                                          }
</Grid>
</CardContent>
</Card>




                                        </>

                                        )}
                                        {collectionRiskDataById != undefined && collectionRiskDataById != null && collectionRiskDataById.length> 0 && ( 
                                        <>
                                        <Grid item xs={12}>
                                          <Divider>
                                            <Chip label="Previous RisK Hierarchy" />
                                          </Divider>
                                        </Grid>
                                        <Grid item xs={12}>
                                       
                                          <TableContainer component={Paper}>
                                            <Table aria-label="customized table">
                                              <TableHead>
                                                <TableRow>
                                                  <StyledTableCell className={classes.tableThStyle} style={{width: '90%'}}>Risk Hierarchy Name</StyledTableCell>
                                                  <StyledTableCell className={classes.tableThStyle} style={{width: '10%'}}>Edit</StyledTableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                              {collectionRiskDataById.map((item, index)=>(
                                                <StyledTableRow>
                                                  <StyledTableCell className={classes.tableTrStyle}>
                                                    {item.name}
                                                  </StyledTableCell>
                                                  <StyledTableCell className={classes.tableTrStyle}>
                                                        <Tooltip title="Edit This Hierarchy">
                                                            <EditIcon 
                                                              onClick={() => editRiskHierarchy(item.sequenceNo)}
                                                              style={{cursor:'pointer'}}
                                                            />
                                                        </Tooltip>
                                                  </StyledTableCell>
                                                </StyledTableRow>
                                              ))}  
                                              </TableBody>
                                            </Table> 
                                          </TableContainer>          
                                      </Grid>
                                      </>
                                    )}      



                                  </Grid> 
                              </CardContent>
                          </Card>
                        </Grid>



                      </>
                      )
                    }  
                </Grid>
                </form>
            </Grid>  
        </div>

      {/** Main Risk add popup */}              
        <Dialog
          open={mainRiskOpen}
          onClose={mainRiskHandleClickClose}
          scroll={mainRiskScroll}
          aria-labelledby="main-risk-scroll-dialog-title"
          aria-describedby="main-risk-scroll-dialog-description"
          fullWidth={true}
          maxWidth={'md'}
          PaperComponent={PaperComponentMainRisk}
          >
          <DialogTitle style={{ cursor: 'move' }} id="main-risk-scroll-dialog-title">Create a Main Risk</DialogTitle>
          <DialogContent dividers={mainRiskScroll === 'paper'}>
          <DialogContentText
              id="main-risk-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                  <Grid item xs={10}>
                      <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                              <TextField
                                error
                                  id="mainRiskType"
                                  label="Main Risk"
                                  multiline
                                  maxRows={2}
                                  value={mainRiskName}
                                  onChange={handleChangeMainRisk}
                              />
                          </div>
                      </Box> 
                  </Grid>
                    

                  <Grid item xs={5}>
                                                            <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                                                                <div>
                                                                <RiskLevelSlider
                                                                  MethodDropdown={'Risk Level Slider'}
                                                                  SliderName={'Risk level'}
                                                                  showSelectBar={showSelectBar}
                                                                  handleChange={handleChange}
                                                                  sliderType={sliderType}
                                                                  riskCategoryMethodData={riskCategoryMethodData}
                                                                  nameChange={nameChange}
                                                                  sliderValue={sliderValue}
                                                                  sliderValueStyle={sliderValueStyle}
                                                                  sliderValueName={sliderValueName}
                                                                  riskClearFunction={riskClearFunction}
                                                                  setSliderValueFunction={setSliderValueFunction}
                                          
                                          />
                                                                </div>
                                                            </Box> 
                                                        </Grid>
                                                        <Grid item xs={1} spacing={2}></Grid>


                                                        <Grid item xs={5}>
                                                            <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                                                                <div>
                                                                <RiskAppetiteSlider
                                                                    SliderName={'Risk Appetites'}
                                                                    MethodDropdown ={'Risk Appetites Slider'}
                                                                    appetitesShowSelectBar={appetitesShowSelectBar}
                                                                    appetiteSliderType={appetiteSliderType}
                                                                    appetiteHandleChange={appetiteHandleChange}
                                                                    riskCategoryMethodDataAppetite={riskCategoryMethodDataAppetite}
                                                                    selectedColorForAppetiteSlider={selectedColorForAppetiteSlider}
                                                                    radioHandler={radioHandler}
                                                                    setSliderValueFunction={setSliderValueFunction}
                                                                    appetitesSliderValueStyle={appetitesSliderValueStyle}
                                                                    appetiteClearFunction={appetiteClearFunction}
                                                                    appetitesSliderValueName={appetitesSliderValueName}
                                                                    appetitesSliderValue={appetitesSliderValue}
                                                                    appetiteNameChange={appetiteNameChange}
                                              />
                                                                </div>
                                                            </Box> 
                                                        </Grid>







              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button  onClick={() => setMainRiskFunction()}>add</Button>
            <Button onClick={mainRiskHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** Main Risk add popup */}                      
      




    </>
  )
}

export default CollectionManagement

