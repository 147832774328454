import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, RadioGroup } from "@material-ui/core";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import titleImage from '../assets/risktitle.jpg';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { red, green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useLocation } from 'react-router-dom';
import Links from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip'
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Close, Save, SaveAltSharp } from '@mui/icons-material';
import axios from 'axios';
import {CONFIG} from '../config';
import Draggable from 'react-draggable'; 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Divider from '@mui/material/Divider';
import { ArrowBackSharp, ArrowBackTwoTone, ArrowCircleLeft, ArrowCircleLeftSharp, ArrowCircleRightSharp, ArrowRightRounded } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({ 
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageBtn:{
    height: '80px',
    width: '180px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '12px !important',
  },
  globalBtn:{
    height: '80px',
    width: '180px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '12px !important',
  },
  globalBtnAfter:{
    height: '80px',
    width: '180px',
    backgroundColor: '#d6c3fc !important',
    color: 'black !important',
    borderRadius: '12px !important',
  },
  pageBtnRisk:{
    height: '30px',
    width: '200px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '5px !important',
  },
  cardBodyH6: {
    fontSize: '11px !important',
  },
  headerLable:{
    color: '#827d7d',
    marginLeft: '25px',
  },
  pageTop:{
    marginTop: '0px',
  },
  pageBodyStyle: {
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '12px',
    backgroundColor: '#f7f7f7',
    marginLeft: '25px',
  },
  mainbtn:{
      justifyContent:'center'
  },
  qusMainCardHeader:{
    backgroundColor:'#d6c3fc !important',
    fontSize:'13px !important',
    height:'44px',
    padding:'0px',
    marginTop: '-7px',
  },
  qusCardHeader:{
    backgroundColor: '#ece9fa !important',
    /*
    fontSize:'13px !important',
    height:'44px',
    padding:'0px',
    marginTop: '-7px',
    */
  },
  popupdiv:{
        //marginLeft:'25%',
        //marginTop:'-50%',
        border:'2px solid black',
       // width:'600px',
        backgroundColor:'#f6f5fc !important',
        boxShadowhadow: '5px 10px #888888',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        
        
  },
  cardcencel:{
     // alignItems:'right !important',
    //  marginLeft:'100px !important'
        float:'right !important'
  },
  qusCard:{
    marginLeft:'15%',
    //marginTop:'-50%',
    border:'2px solid black',
    width:'700px',
    backgroundColor:'#f6f5fc !important',
    boxShadowhadow: '5px 10px #888888',
    resize: 'horizontal',
    overflow: 'auto',
    height: '100%',
  },
  tableThStyle: {
    backgroundColor: '#d4d0ea',
    color: '#ffffff',
    lineHeight: '0.25rem !important'
  },
  tableRadioTrStyle: {
    lineHeight: '0.25rem !important',
    fontSize: '10px !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },


  
}));

const RiskTypeQustionDisplay = ({quesData,collectnId,conSize, setRiskQuestion,riskQuestion,formDashBord}) => {

  //console.log(quesData1)
  //var quesData = [];
  const [quesDataTrue, setQuesDataTrue] = useState([])
  /*  
  var a = [quesData1.questions.closeEndedQuestions]
  var b = [quesData1.questions.openEdndedQuestions]


    a.forEach((data1, index)=>{
        var tmpA = [];
        data1.forEach((data2, index)=>{
            quesData.push(data2);

        })
    });
    b.forEach((data1, index)=>{
        var tmpA = [];
        data1.forEach((data2, index)=>{
            quesData.push(data2);

        })
    });
 */   

    // output is [{fname : 'foo'},{lname : 'bar'}]

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const menuHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuHandleClose = () => {
    setAnchorEl(null);
  };

  const subHandleClickOpen = (scrollType) => () => {
    setSubOpen(true);
    setSubScroll(scrollType);
  };

  const subHandleClose = () => {
    setSubOpen(false);
  };

  const [subOpen, setSubOpen] = React.useState(false);
  const [subScroll, setSubScroll] = React.useState('paper');

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    
    if (subOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [subOpen]);


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  function handleChangeSortBy(){

  }
  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }
  
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    
  }));  
  console.log("collectnId : " + collectnId);
  console.log(quesData);
  

  /** Risk and entity for searck */

  
  function getUnique(arr, index) {
    const unique = arr
         .map(e => e[index])
         // store the keys of the unique objects
         .map((e, i, final) => final.indexOf(e) === i && i)
         // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);      
     return unique;
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

  useEffect(() => {
    setIsLoaded(true);
    //getRiskType()
  }, []);

  React.useEffect(() => {
    if (isLoaded) {
        setIsPageLoaded(true);
    }
  }, [isLoaded]);


/**
   * get risktypes
   */

const [serverRiskType, setServerRiskType] = useState([]);
const [recursiveEvent, setRecursiveEvent] = useState(true);

const [rsType, setRsTtpe] = useState([])

/*
function getRiskType(){
axios(CONFIG.MAIN_URL+`/risksubrisk/riskType?collectionId=${collectnId}`,{
   method: 'get',
   headers: { 
       'Content-Type': 'text/plain', 
     },
   })
   .then(function (response) {
    console.log("Risk Data");
     console.log(response.data);
     setServerRiskType(response.data)
     //getRiskArray(response.data);
     response.data.forEach((item,index)=>{
        if (index === 0) {  
            //setRiskTypeData(item);
            setRsTtpe(item.riskTypes);
        }
     })

   })
   .catch(function (error) {
   console.log(error);
   });
}
*/

 /** select box*/
 const [riskTypeName, setRiskTypeName] = React.useState('');
 const riskTypehandleChange = (event) => {
    setRiskTypeName(event.target.value);         
 };

 const [entityName, setEntityName] = React.useState('');
 const entityHandleChange = (event) => {
    setEntityName(event.target.value);
 };



if (isPageLoaded) {
    setTimeout(loadAfterTime, 5000)
}  

function loadAfterTime(){
    quesData.forEach((data)=>{
        if (data.answers != undefined && data.answers.answerList != undefined && data.answers.answerList.length>0){
            data.answers.answerList.forEach((item,index)=>{
                if (data.answers.answerMethod.name === "radioButtonType") {
                    var id = "radio" + data.questionId + index;
                    if (item.expectedAnswerName === "Yes"){
                        var getId = document.getElementById(id);
                        if (getId != undefined && getId != null) {
                            document.getElementById(id).checked = true;
                        } 
                    }
                }
                if (data.answers.answerMethod.name === "checkBoxType") {
                    var id = "chk" + data.questionId + index;
                    if (item.expectedAnswerName === "checked"){
                        var getId = document.getElementById(id);
                        if (getId != undefined && getId != null) {
                            document.getElementById(id).checked = true;
                        } 
                    }
                }

            })  
        }
        

    })


}
function checkedRadio(qId, id){
    quesData.forEach((data)=>{
        if (data.questionId === qId){
            data.answers.answerList.forEach((item,index)=>{
                var idGet = "radio" + data.questionId + index;
                if (id === idGet){
                    document.getElementById(idGet).checked = true;
                }else{
                    document.getElementById(idGet).checked = false;
                }
            })    
        }
    })  
}
function saveChanges(quesId){
    var jsonStr = "";
    quesData.forEach((data)=>{
        if (data.questionId === quesId){
            jsonStr += "{\"collectionId\":" + collectnId + ",";
            jsonStr += "\"questionId\":" + quesId + ",";
            if (data.parentQuestionId != undefined && data.parentQuestionId != null){
                jsonStr += "\"parentQuestionId\":" + data.parentQuestionId + ",";
            }
            jsonStr += "\"question\":" + "\"" + data.question + "\",";
            jsonStr += "\"questionType\":" + "\"" + data.questionType + "\",";
            jsonStr += "\"questionScore\":" + data.questionScore + ",";
            if (data.questionType === "Openended"){
                jsonStr += "\"questionTypeName\":" + "\"" + "" + "\",";
            }
            if (data.questionType === "Closeended"){
                jsonStr += "\"questionTypeName\":" + "\"" + data.questionTypeName + "\",";    
            }
            jsonStr += "\"metaInfo\":{\"status\":" + "\"" + data.metaInfo.status + "\"},";
            jsonStr += "\"riskType\":{\"riskTypeId\":" + "" + data.riskType.riskTypeId + ", \"name\":"+ "\"" + data.riskType.name + "\"},";
            jsonStr += "\"objective\":{\"standardId\":" + "\"" + data.objective.standardId + "\",\"standardName\":" + "\"" + data.objective.standardName + "\",\"id\":" + "\"" + data.objective.id + "\", \"name\":"+ "\"" + data.objective.name + "\"},";
            jsonStr += "\"component\":{\"standardId\":" + "\"" + data.component.standardId + "\",\"standardName\":" + "\"" + data.component.standardName + "\",\"id\":" + "\"" + data.component.id + "\", \"name\":"+ "\"" + data.component.name + "\"},";
            jsonStr += "\"principle\":{\"standardId\":" + "\"" + data.principle.standardId + "\",\"standardName\":" + "\"" + data.principle.standardName + "\",\"id\":" + "\"" + data.principle.id + "\", \"name\":"+ "\"" + data.principle.name + "\"},";
            jsonStr += "\"entity\":{\"standardId\":" + "\"" + data.entity.standardId + "\",\"standardName\":" + "\"" + data.entity.standardName + "\",\"id\":" + "\"" + data.entity.id + "\", \"name\":"+ "\"" + data.entity.name + "\"},";
            jsonStr += "\"answers\":{";
            jsonStr += "\"answerMethod\":{\"name\":" + "\"" + data.answers.answerMethod.name + "\", \"minValue\":"+ "\"" + data.answers.answerMethod.minValue + "\", \"maxValue\":"+ "\"" + data.answers.answerMethod.maxValue + "\"},";
            if (data.questionType === "Openended"){
                var ansStr = document.getElementById("txt" + data.questionId).value
                jsonStr += "\"answer\":" + "\"" + ansStr + "\"";
                jsonStr += "}";
            }else{
                jsonStr += "\"answerScaleType\":{\"name\":" + "\"" + data.answers.answerScaleType.name + "\"},";
                jsonStr += "\"answerList\":[";
                data.answers.answerList.forEach((item,index)=>{
                    var expctedAns = "";
                    if (data.answers.answerMethod.name === "radioButtonType"){
                        if ( document.getElementById("radio" + data.questionId + index).checked) {
                            expctedAns = "Yes";
                        }
                    }
                    if (data.answers.answerMethod.name === "checkBoxType"){
                        if ( document.getElementById("chk" + data.questionId + index).checked) {
                            expctedAns = "checked";
                        }
                    }
                    if (data.answers.answerMethod.name === "orderNumberType"){
                        expctedAns =  document.getElementById("select" + data.questionId + index).value;
                    }
                    if (data.answers.answerMethod.name === "answerTypeType"){
                        expctedAns =  document.getElementById("txt" + data.questionId + index).value;
                    }
                    if (expctedAns === undefined || expctedAns === null || expctedAns === "null"){
                        expctedAns = "";
                    }
                    if (data.answers.answerList.length -1 === index) {
                        jsonStr += "{\"answerName\":" + "\"" + item.answerName + "\", \"answerType\":"+ "\"" + item.answerType + "\", \"riskLevel\":" + item.riskLevel + ", \"expectedAnswerName\":"+ "\"" + expctedAns + "\"}";
                    }else{
                        jsonStr += "{\"answerName\":" + "\"" + item.answerName + "\", \"answerType\":"+ "\"" + item.answerType + "\", \"riskLevel\":" + item.riskLevel + ", \"expectedAnswerName\":"+ "\"" + expctedAns + "\"},";
                    }
                })
                jsonStr += "]";
                jsonStr += "}";
            }

            jsonStr += "}";
            console.log(jsonStr);
        }
    })
    if (jsonStr != ""){
        
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_QUESTION+'/save',{
            method: 'post',
            headers: { 
                'Content-Type': 'application/json',
              },
            data : jsonStr,  
        })
        .then(function (response) {
            console.log(JSON.stringify(response.data));
            alert("Expert Answer Updated successfully");
        })
        .catch(function (error) {
            console.log(error);
            alert("Error saving Question -:" + error);
        });
        
    }
}


const [openRisk, setOpenRisk] = useState(false);
const [riskBtnTop, setRiskBtnTop] = useState(0);
const [selectedRiskName, setSelectedRiskName] = React.useState('');
const [selectedRiskId, setSelectedRiskId] = React.useState(0);

function setOpenRiskFuction(riskFlag) {
    var btnId = document.getElementById("riskBtnId");
    var lastGridId = document.getElementById("lastGridId");
    var top = (btnId.offsetTop-lastGridId.offsetTop);
    setRiskBtnTop(top);
    setOpenRisk(riskFlag);
}
const [mainRiskMenu, setMainRiskMenu] = useState([]);

const riskHandleChange = (event) => {
    let idAndVal=event.target.value;
    let idAndValArr=idAndVal.split("]");
    setSelectedRiskId(idAndValArr[0]);
    setSelectedRiskName(idAndValArr[1]);

    setRiskTypeName(idAndValArr[0]);
    var arryObj = [];
    var tmpArray = {};
    tmpArray["id"] = idAndValArr[0];
    tmpArray["name"] = idAndValArr[1];
    arryObj.push(tmpArray);
    setMainRiskMenu(arryObj);
    setOpenRisk(false);
};


function Comment({ comment }) {
    const [show, setShow] = React.useState(false)
    const nestedComments = (comment.subRisks || []).map(comment => {
        return <Comment key={comment.riskTypeId} comment={comment} type="child" />
    })

    return (
        <div style={{"marginLeft": "15px", "marginTop": "10px"}}>
            <div style={{display:'flex',textAlign:'center'}}> 
                <Button 
                    style={{width:'0px', height:'0px', marginTop:'14px'}} 
                    onClick={()=>setShow(!show)} 
                    startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    >
                </Button>
                
                <span style={{marginTop:'-7px !important'}} >
                <Radio
                    checked={selectedRiskId === `${comment.riskTypeId}`}
                    onChange={riskHandleChange}
                    value={`${comment.riskTypeId}]${comment.name}]${comment.riskLevel}]${comment.riskAppetite}` }
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}
                    style={{marginLeft:'-1px !important'}} 
                    />
                    
                    {comment.name + (comment.questions.closeEndedQuestions.length === 0 && comment.questions.openEdndedQuestions.length === 0 ?'' : ' (' )  + (comment.questions.closeEndedQuestions.length > 0 ?'Closeended : '
                     + comment.questions.closeEndedQuestions.length:'') + (comment.questions.openEdndedQuestions.length > 0 
                     ?' openEnded : ' + comment.questions.openEdndedQuestions.length:'') + (comment.questions.closeEndedQuestions.length === 0 && comment.questions.openEdndedQuestions.length === 0 ?'' : ')' )  } 
                    
                    
             </span>    
            </div>
            {
                show?
                    <>
                        {nestedComments}
                    </>
                :null
            }
    </div>
  )
}

const [nextNumberCount, setNextNumberCount] = React.useState(0);
    
function preNumber(){
  if (nextNumberCount > 0){
    setNextNumberCount(nextNumberCount - 1);
  }else{
    setNextNumberCount(0);
  }
}
function nextNumber(){
  if (nextNumberCount === (quesData.length -1)){
    setNextNumberCount(nextNumberCount);
  }else{
    setNextNumberCount(nextNumberCount + 1);
  }
}
function numberClick(no){
  setNextNumberCount(no-1);
}


/** Sort Assending/Decending question */
function compareQa( a, b ) {
    var nameA = a.question.toUpperCase(); // ignore upper and lowercase
    var nameB = b.question.toUpperCase(); // ignore upper and lowercase
    if ( nameA < nameB ){ return -1;}
    if ( nameA > nameB ){ return 1;}
    return 0;
}

/** Sort Assending/Decending Entity */
function compareEa( a, b ) {
    var nameA = a.entity.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.entity.name.toUpperCase(); // ignore upper and lowercase
    if ( nameA < nameB ){ return -1;}
    if ( nameA > nameB ){ return 1;}
    return 0;
}

 /** Sort Assending/Decending Objective */
 function compareOa( a, b ) {
    var nameA = a.objective.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.objective.name.toUpperCase(); // ignore upper and lowercase
    if ( nameA < nameB ){ return -1;}
    if ( nameA > nameB ){ return 1;}
    return 0;
}

/** Sort Assending/Decending component */
function compareCa( a, b ) {
    var nameA = a.component.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.component.name.toUpperCase(); // ignore upper and lowercase
    if ( nameA < nameB ){ return -1;}
    if ( nameA > nameB ){ return 1;}
    return 0;
}

/** Sort Assending/Decending principle */
 function comparePa( a, b ) {
    var nameA = a.principle.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.principle.name.toUpperCase(); // ignore upper and lowercase
    if ( nameA < nameB ){ return -1;}
    if ( nameA > nameB ){ return 1;}
    return 0;
}

/** Sort Assending/Decending Risk */
function compareRa( a, b ) {
    var nameA = a.riskType.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.riskType.name.toUpperCase(); // ignore upper and lowercase
    if ( nameA < nameB ){ return -1;}
    if ( nameA > nameB ){ return 1;}
    return 0;
}



const [sortFlag, setSortFlag] = useState(false);

const handleChangeSortByEvent = (event) => {
    var type=event.target.value;
    

    console.log(type)
    
    if (type === 1){
        quesData = quesData.sort (compareEa);
        setQuesDataTrue(quesData)
        setSortFlag(true);
    }
    if (type === 2){
        quesData = quesData.sort (compareRa);
        setQuesDataTrue(quesData)
        setSortFlag(true);
    }
    if (type === 3){
        quesData = quesData.sort (compareQa);
        setQuesDataTrue(quesData)
        setSortFlag(true);
    }
    if (type === 4){
        quesData = quesData.sort (compareOa);
        setQuesDataTrue(quesData)
        setSortFlag(true);
    }
    if (type === 5){
        quesData = quesData.sort (compareCa);
        setQuesDataTrue(quesData)
        setSortFlag(true);
    }
    if (type === 6){
        quesData = quesData.sort (comparePa);
        setQuesDataTrue(quesData)
        setSortFlag(true);
    }
   
};


    function editQuestion(questionId, collectionUniqueName, formDashBord){
        if (formDashBord != undefined && formDashBord != null && formDashBord){
            //Should be treated as the multivalued frame
            let a= document.createElement('a');
            a.target= '_self';
            a.href= '/questioPage/' + collectionUniqueName + "?questionid=" + questionId;
            a.click();
        }else{
            let a= document.createElement('a');
            a.target= '_self';
            a.href= '/questioPage/' + collectionUniqueName + "?questionid=" + questionId;
            a.click();
        }
    }
  return (


        <>
            <div className={classes.pageTop}>
                
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                          <ArrowCircleLeftSharp
                            onClick={()=>preNumber()} 
                            style={{cursor:'pointer', verticalAlign: 'center', float:'right', marginTop:'35%'}}
                          />
                   </Grid>   
                   <Grid item xs={10}>
                    {!sortFlag && quesData.map((item,index)=>{
                        console.log(item)
                        if (index === nextNumberCount){
                              return(
                                <div>
                                    <Grid spacing={2}>
                                        <Card className={classes.popupdiv} style={{height: '300px'}}>
                                            <CardHeader className={classes.qusCardHeader} 
                                                avatar={
                                                    <Avatar sx={{ bgcolor: '#9155FD' }} aria-label="recipe">
                                                        {item.question.charAt(0)}
                                                    </Avatar>
                                                }


                                                action={
                                                    <IconButton aria-label="settings" 
                                                    onClick={() => {
                                                        editQuestion(item.questionId,item.collectionUniqueName,formDashBord)
                                                    
                                                    }}
                                                    title="Edit"
                                                    >
                                                      <EditIcon />
                                                    </IconButton>
                                                  }
                                                title={"To: " + item.entity.name}
                                                subheader={item.question}

                                                
                                            />
                                            <CardContent style={{overflowY: 'scroll', height:'250px'}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" className={classes.cardBodyH6}>Risk Type</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                            {item.riskTypes.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" className={classes.cardBodyH6}>Objective</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                            {item.objective.name + (item.objective.standardName != ""?"(" + item.objective.standardName + ")":"") }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" className={classes.cardBodyH6}>Component</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                            {item.component.name + (item.component.standardName != ""?"(" + item.component.standardName + ")":"")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" className={classes.cardBodyH6}>Principle</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                            {item.principle.name + (item.principle.standardName != ""?"(" + item.principle.standardName + ")":"")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" className={classes.cardBodyH6}>Question Type</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}>
                                                            {item.questionType == "Openended"? "Open Ended Question":"Close ended Question"}
                                                        </Typography>
                                                    </Grid>
                                                    {item.questionType === "Closeended" && (
                                                      <Grid item xs={6}>
                                                          <Typography variant="h6" className={classes.cardBodyH6}>Measurement scale</Typography>
                                                          <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}>
                                                            {item.questionTypeName}
                                                        </Typography>
                                                      </Grid>
                                                    )}
                                                </Grid> 
                                                {item.questionType === "Closeended" && (
                                                    <>
                                                        <Typography variant="h6" className={classes.cardBodyH6}>Answers</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}> 
                                                          {item.answers.answerList.length > 0 && (
                                                              <TableContainer component={Paper}>
                                                              <Table aria-label="customized table">
                                                                  <TableHead className={classes.tableHeadStyle}>
                                                                      <TableRow>
                                                                          <StyledTableCell className={classes.tableThStyle} style={{width: '45%'}}>Answer</StyledTableCell>
                                                                          <StyledTableCell className={classes.tableThStyle} style={{width: '30%'}}>Answer Method</StyledTableCell>
                                                                          <StyledTableCell className={classes.tableThStyle} style={{width: '25%'}}>Risk Level</StyledTableCell>
                                                                      </TableRow>
                                                                  </TableHead>
                                                                  <TableBody>
                                                                      {item.answers.answerMethod.name === "radioButtonType" &&  item.answers.answerList.map((row,index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>
                                                                                  
                                                                                  <input 
                                                                                      type="radio" 
                                                                                      name={"radio" + item.questionId}
                                                                                      id={"radio" + item.questionId + index}
                                                                                      onClick={(e) => checkedRadio(item.questionId, "radio" + item.questionId + index)}  
                                                                                      >
                                                                                  </input>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                      {item.answers.answerMethod.name === "checkBoxType" &&  item.answers.answerList.map((row,index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>
                                                                                  <input 
                                                                                      type="checkbox" 
                                                                                      id={"chk" + item.questionId + index} 
                                                                                      name={"chk" + item.questionId + index}
                                                                                      />
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                      {item.answers.answerMethod.name === "orderNumberType" &&  item.answers.answerList.map((row, index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>
                                                                                  <FormControl sx={{ m: 0.5, minWidth: 100 }} size="small">
                                                                                      <InputLabel id="select-select-label">Select</InputLabel>
                                                                                      <Select
                                                                                          labelId="select-select-label"
                                                                                          id={"select" + item.questionId + index}
                                                                                          label="Select"
                                                                                          defaultValue={row.expectedAnswerName}
                                                                                          >
                                                                                          <MenuItem value={1}>{1}</MenuItem>
                                                                                          <MenuItem value={2}>{2}</MenuItem>
                                                                                          <MenuItem value={3}>{3}</MenuItem>
                                                                                          <MenuItem value={4}>{4}</MenuItem>
                                                                                          <MenuItem value={5}>{5}</MenuItem>
                                                                                          <MenuItem value={6}>{6}</MenuItem>
                                                                                          <MenuItem value={7}>{7}</MenuItem>
                                                                                          <MenuItem value={8}>{8}</MenuItem>
                                                                                          <MenuItem value={9}>{9}</MenuItem>
                                                                                          <MenuItem value={10}>{10}</MenuItem>
                                                                                      </Select>
                                                                                      </FormControl>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                      {item.answers.answerMethod.name === "answerTypeType" &&  item.answers.answerList.map((row,index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>
                                                                                  <Box component="form" sx={{'& .MuiTextField-root': { width: '10ch' },}} autoComplete="off">
                                                                                      <div>
                                                                                          <TextField
                                                                                              id={"txt" + item.questionId + index}
                                                                                              //label="Answer"
                                                                                              size="small"
                                                                                              defaultValue={row.expectedAnswerName}
                                                                                          />
                                                                                      </div>
                                                                                  </Box> 
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                  </TableBody>
                                                              </Table>
                                                          </TableContainer>  
                          
                                                          )}    
                                                        </Typography>     
                                                    </>
                                                )}
                                                {item.questionType === "Openended" && (
                                                    <>
                                                    <Typography variant="h6" className={classes.cardBodyH6}>Answers</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}> 
                                                          <Grid container spacing={1}>
                                                              <Grid item xs={12}>
                                                              <Box
                                                                  component="form"
                                                                  sx={{
                                                                      '& .MuiTextField-root': { m: 1, width: '100%' },
                                                                  }}
                                                                  noValidate
                                                                  autoComplete="off"
                                                                  >
                                                                  <div>
                                                                      <TextField
                                                                          id={"txt" + item.questionId}
                                                                          label="Answer (about 30 charactors)"
                                                                          multiline
                                                                          maxRows={3}
                                                                          defaultValue={item.answers.answer}
                                                                          //onChange={handleOpenEndedAnswerChange}
                                                                      />
                                                                  </div>
                                                              </Box>
                          
                                                              </Grid>
                                                          </Grid>        
                                                        </Typography>
                                                    </>
                          
                                                )}  
                          
                                                {item.subQuestions != undefined && item.subQuestions.length > 0 && (
                                                    <div>
                                                        <Button variant="outlined" className={classes.subQuestionBtn} onClick={subHandleClickOpen('paper')}>
                                                            Sub Questioners Available
                                                        </Button>
                                                        <Dialog
                                                            open={subOpen}
                                                            onClose={subHandleClose}
                                                            scroll={subScroll}
                                                            aria-labelledby="sub-scroll-dialog-title"
                                                            aria-describedby="sub-scroll-dialog-description"
                                                            >
                                                            <DialogTitle id="sub-scroll-dialog-title">Sub Questioners</DialogTitle>
                                                            <DialogContent dividers={subScroll === 'paper'}>
                                                            <DialogContentText
                                                                id="sub-scroll-dialog-description"
                                                                ref={descriptionElementRef}
                                                                tabIndex={-1}
                                                                >
                                                                {item.subQuestions.map((itemSub)=>(  
                                                                    <Grid item xs={12} spacing={2}>
                                                                        <Card className={classes.qusCard}>
                                                                            <CardHeader className={classes.qusCardHeader} 
                                                                                avatar={
                                                                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                                                        {itemSub.subQuestion.charAt(0)}
                                                                                    </Avatar>
                                                                                }
                                                                                action={
                                                                                    <div>
                                                                                        <IconButton 
                                                                                            aria-label="settings"
                                                                                            onClick={menuHandleClick}
                                                                                            >
                                                                                            <MoreVertIcon />
                                                                                        </IconButton>
                                                                                        <Menu
                                                                                            id="long-menu"
                                                                                            MenuListProps={{
                                                                                                'aria-labelledby': 'long-button',
                                                                                            }}
                                                                                            anchorEl={anchorEl}
                                                                                            open={menuOpen}
                                                                                            onClose={menuHandleClose}
                                                                                            PaperProps={{
                                                                                                style: {
                                                                                                    maxHeight: 120,
                                                                                                    width: '20ch',
                                                                                                },
                                                                                            }}
                                                                                            >
                                                                                            <MenuItem onClick={menuHandleClose} disableRipple>
                                                                                                <EditIcon />
                                                                                                Edit
                                                                                            </MenuItem>
                                                                                            <MenuItem onClick={menuHandleClose} disableRipple>
                                                                                                <ListItemIcon>
                                                                                                    <PersonAdd fontSize="small" />
                                                                                                </ListItemIcon>
                                                                                                Add Sub Question
                                                                                            </MenuItem>
                                                                                        </Menu>
                                                                                    </div>
                                                                                }
                                                                                title={"To : " + itemSub.entity.name}
                                                                                subheader={itemSub.subQuestion}
                                                                            />
                                                                      <CardContent>
                                                                        <Grid container spacing={2}>
                                                                          <Grid item xs={6}>
                                                                              <Typography variant="h6" className={classes.cardBodyH6}>Risk Type</Typography>
                                                                              <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                                                  {itemSub.riskType.name}
                                                                              </Typography>
                                                                          </Grid>
                                                                          <Grid item xs={6}>
                                                                              <Typography variant="h6" className={classes.cardBodyH6}>Objective</Typography>
                                                                              <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                                                  {itemSub.objective.name + (itemSub.objective.standardName != ""?"(" + itemSub.objective.standardName + ")":"")}
                                                                              </Typography>
                                                                          </Grid>
                                                                          <Grid item xs={6}>
                                                                              <Typography variant="h6" className={classes.cardBodyH6}>Component</Typography>
                                                                              <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                                                  {itemSub.component.name + (itemSub.component.standardName != ""?"(" + itemSub.component.standardName + ")":"")}
                                                                              </Typography>
                                                                          </Grid>
                                                                          <Grid item xs={6}>
                                                                              <Typography variant="h6" className={classes.cardBodyH6}>Principle</Typography>
                                                                              <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                                                  {itemSub.principle.name + (itemSub.principle.standardName != ""?"(" + itemSub.principle.standardName + ")":"")}
                                                                              </Typography>
                                                                          </Grid>
                                                                          <Grid item xs={6}>
                                                                              <Typography variant="h6" className={classes.cardBodyH6}>Question Type</Typography>
                                                                              <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}>
                                                                                  {itemSub.questionType == "Openended"? "Open Ended Question":"Close ended Question"}
                                                                              </Typography>
                                                                          </Grid>
                                                                          {itemSub.questionType == "Closeended" && (
                                                                              <Grid item xs={6}>
                                                                                  <Typography variant="h6" className={classes.cardBodyH6}>Measurement scale</Typography>
                                                                                  <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}>
                                                                                  {itemSub.questionTypeName}
                                                                              </Typography>
                                                                              </Grid>
                                                                          )}
                                                                        </Grid> 
                                                                        {itemSub.questionType == "Closeended" && (
                                                                          <>
                                                                              <Typography variant="h6" className={classes.cardBodyH6}>Answers</Typography>
                                                                              <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}> 
                                                                                  {itemSub.answers.answerList.length > 0 && (
                                                                                      <TableContainer component={Paper}>
                                                                                      <Table aria-label="customized table">
                                                                                          <TableHead className={classes.tableHeadStyle}>
                                                                                              <TableRow>
                                                                                                  <StyledTableCell className={classes.tableThStyle} style={{width: '50%'}}>Answer</StyledTableCell>
                                                                                                  <StyledTableCell className={classes.tableThStyle} style={{width: '30%'}}>Answer Method</StyledTableCell>
                                                                                                  <StyledTableCell className={classes.tableThStyle} style={{width: '20%'}}>Risk Level</StyledTableCell>
                                                                                              </TableRow>
                                                                                          </TableHead>
                                                                                          <TableBody>
                                                                                          {itemSub.answers.answerMethod.name === "radioButtonType" &&  itemSub.answers.answerList.map((row,index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>
                                                                                  
                                                                                  <input 
                                                                                      type="radio" 
                                                                                      name={"radio" + itemSub.questionId}
                                                                                      id={"radio" + itemSub.questionId + index}
                                                                                      onClick={(e) => checkedRadio(itemSub.questionId, "radio" + itemSub.questionId + index)}  
                                                                                      >
                                                                                  </input>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                      {itemSub.answers.answerMethod.name === "checkBoxType" &&  itemSub.answers.answerList.map((row,index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>
                                                                                  <input 
                                                                                      type="checkbox" 
                                                                                      id={"chk" + itemSub.questionId + index} 
                                                                                      name={"chk" + itemSub.questionId + index}
                                                                                      />
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                      {itemSub.answers.answerMethod.name === "orderNumberType" &&  itemSub.answers.answerList.map((row, index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>
                                                                                  <FormControl sx={{ m: 0.5, minWidth: 100 }} size="small">
                                                                                      <InputLabel id="select-select-label">Select</InputLabel>
                                                                                      <Select
                                                                                          labelId="select-select-label"
                                                                                          id={"select" + itemSub.questionId + index}
                                                                                          label="Select"
                                                                                          defaultValue={row.expectedAnswerName}
                                                                                          >
                                                                                          <MenuItem value={1}>{1}</MenuItem>
                                                                                          <MenuItem value={2}>{2}</MenuItem>
                                                                                          <MenuItem value={3}>{3}</MenuItem>
                                                                                          <MenuItem value={4}>{4}</MenuItem>
                                                                                          <MenuItem value={5}>{5}</MenuItem>
                                                                                          <MenuItem value={6}>{6}</MenuItem>
                                                                                          <MenuItem value={7}>{7}</MenuItem>
                                                                                          <MenuItem value={8}>{8}</MenuItem>
                                                                                          <MenuItem value={9}>{9}</MenuItem>
                                                                                          <MenuItem value={10}>{10}</MenuItem>
                                                                                      </Select>
                                                                                      </FormControl>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                      {itemSub.answers.answerMethod.name === "answerTypeType" &&  itemSub.answers.answerList.map((row,index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>
                                                                                  <Box component="form" sx={{'& .MuiTextField-root': { width: '10ch' },}} autoComplete="off">
                                                                                      <div>
                                                                                          <TextField
                                                                                              id={"txt" + itemSub.questionId + index}
                                                                                              //label="Answer"
                                                                                              size="small"
                                                                                              defaultValue={row.expectedAnswerName}
                                                                                          />
                                                                                      </div>
                                                                                  </Box> 
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                          
                                                                                          </TableBody>
                                                                                      </Table>
                                                                                  </TableContainer>  
                                                  
                                                                                  )}    
                                                                              </Typography>     
                                                                          </>
                                                  
                                                                        )}
                          
                                                                        
                                                                      
                                                                      </CardContent>
                                                                    </Card>
                                                  
                                                                  </Grid> 
                                                                    
                                                                ))}    
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={subHandleClose}>Cancel</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                    </div>
                                                )}    
                                            </CardContent>
                                         
                                                                    </Card>
                                    </Grid>   
                                    
                                 
                              </div>
                              )
                            }  
                          })}


                        {sortFlag && quesDataTrue.map((item,index)=>{
                        if (index === nextNumberCount){
                              return(
                                <div>
                                    <Grid spacing={2}>
                                        <Card className={classes.popupdiv} style={{height: '300px'}}>
                                            <CardHeader className={classes.qusCardHeader} 
                                                avatar={
                                                    <Avatar sx={{ bgcolor: '#9155FD' }} aria-label="recipe">
                                                        {item.question.charAt(0)}
                                                    </Avatar>
                                                }
                                                title={"To : " + item.entity.name}
                                                subheader={item.question}
                                            />
                                            <CardContent style={{overflowY: 'scroll', height:'250px'}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" className={classes.cardBodyH6}>Risk Type</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                            {item.riskType.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" className={classes.cardBodyH6}>Objective</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                            {item.objective.name + (item.objective.standardName != ""?"(" + item.objective.standardName + ")":"") }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" className={classes.cardBodyH6}>Component</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                            {item.component.name + (item.component.standardName != ""?"(" + item.component.standardName + ")":"")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" className={classes.cardBodyH6}>Principle</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                            {item.principle.name + (item.principle.standardName != ""?"(" + item.principle.standardName + ")":"")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" className={classes.cardBodyH6}>Question Type</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}>
                                                            {item.questionType == "Openended"? "Open Ended Question":"Close ended Question"}
                                                        </Typography>
                                                    </Grid>
                                                    {item.questionType === "Closeended" && (
                                                      <Grid item xs={6}>
                                                          <Typography variant="h6" className={classes.cardBodyH6}>Measurement scale</Typography>
                                                          <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}>
                                                            {item.questionTypeName}
                                                        </Typography>
                                                      </Grid>
                                                    )}
                                                </Grid> 
                                                {item.questionType === "Closeended" && (
                                                    <>
                                                        <Typography variant="h6" className={classes.cardBodyH6}>Answers</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}> 
                                                          {item.answers.answerList.length > 0 && (
                                                              <TableContainer component={Paper}>
                                                              <Table aria-label="customized table">
                                                                  <TableHead className={classes.tableHeadStyle}>
                                                                      <TableRow>
                                                                          <StyledTableCell className={classes.tableThStyle} style={{width: '45%'}}>Answer</StyledTableCell>
                                                                          <StyledTableCell className={classes.tableThStyle} style={{width: '30%'}}>Answer Method</StyledTableCell>
                                                                          <StyledTableCell className={classes.tableThStyle} style={{width: '25%'}}>Risk Level</StyledTableCell>
                                                                      </TableRow>
                                                                  </TableHead>
                                                                  <TableBody>
                                                                      {item.answers.answerMethod.name === "radioButtonType" &&  item.answers.answerList.map((row,index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>
                                                                                  
                                                                                  <input 
                                                                                      type="radio" 
                                                                                      name={"radio" + item.questionId}
                                                                                      id={"radio" + item.questionId + index}
                                                                                      onClick={(e) => checkedRadio(item.questionId, "radio" + item.questionId + index)}  
                                                                                      >
                                                                                  </input>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                      {item.answers.answerMethod.name === "checkBoxType" &&  item.answers.answerList.map((row,index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>
                                                                                  <input 
                                                                                      type="checkbox" 
                                                                                      id={"chk" + item.questionId + index} 
                                                                                      name={"chk" + item.questionId + index}
                                                                                      />
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                      {item.answers.answerMethod.name === "orderNumberType" &&  item.answers.answerList.map((row, index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>
                                                                                  <FormControl sx={{ m: 0.5, minWidth: 100 }} size="small">
                                                                                      <InputLabel id="select-select-label">Select</InputLabel>
                                                                                      <Select
                                                                                          labelId="select-select-label"
                                                                                          id={"select" + item.questionId + index}
                                                                                          label="Select"
                                                                                          defaultValue={row.expectedAnswerName}
                                                                                          >
                                                                                          <MenuItem value={1}>{1}</MenuItem>
                                                                                          <MenuItem value={2}>{2}</MenuItem>
                                                                                          <MenuItem value={3}>{3}</MenuItem>
                                                                                          <MenuItem value={4}>{4}</MenuItem>
                                                                                          <MenuItem value={5}>{5}</MenuItem>
                                                                                          <MenuItem value={6}>{6}</MenuItem>
                                                                                          <MenuItem value={7}>{7}</MenuItem>
                                                                                          <MenuItem value={8}>{8}</MenuItem>
                                                                                          <MenuItem value={9}>{9}</MenuItem>
                                                                                          <MenuItem value={10}>{10}</MenuItem>
                                                                                      </Select>
                                                                                      </FormControl>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                      {item.answers.answerMethod.name === "answerTypeType" &&  item.answers.answerList.map((row,index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>
                                                                                  <Box component="form" sx={{'& .MuiTextField-root': { width: '10ch' },}} autoComplete="off">
                                                                                      <div>
                                                                                          <TextField
                                                                                              id={"txt" + item.questionId + index}
                                                                                              //label="Answer"
                                                                                              size="small"
                                                                                              defaultValue={row.expectedAnswerName}
                                                                                          />
                                                                                      </div>
                                                                                  </Box> 
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                  </TableBody>
                                                              </Table>
                                                          </TableContainer>  
                          
                                                          )}    
                                                        </Typography>     
                                                    </>
                                                )}
                                                {item.questionType === "Openended" && (
                                                    <>
                                                    <Typography variant="h6" className={classes.cardBodyH6}>Answers</Typography>
                                                        <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}> 
                                                          <Grid container spacing={1}>
                                                              <Grid item xs={12}>
                                                              <Box
                                                                  component="form"
                                                                  sx={{
                                                                      '& .MuiTextField-root': { m: 1, width: '100%' },
                                                                  }}
                                                                  noValidate
                                                                  autoComplete="off"
                                                                  >
                                                                  <div>
                                                                      <TextField
                                                                          id={"txt" + item.questionId}
                                                                          label="Answer (about 30 charactors)"
                                                                          multiline
                                                                          maxRows={3}
                                                                          defaultValue={item.answers.answer}
                                                                          //onChange={handleOpenEndedAnswerChange}
                                                                      />
                                                                  </div>
                                                              </Box>
                          
                                                              </Grid>
                                                          </Grid>        
                                                        </Typography>
                                                    </>
                          
                                                )}  
                          
                                                {item.subQuestions.length > 0 && (
                                                    <div>
                                                        <Button variant="outlined" className={classes.subQuestionBtn} onClick={subHandleClickOpen('paper')}>
                                                            Sub Questioners Available
                                                        </Button>
                                                        <Dialog
                                                            open={subOpen}
                                                            onClose={subHandleClose}
                                                            scroll={subScroll}
                                                            aria-labelledby="sub-scroll-dialog-title"
                                                            aria-describedby="sub-scroll-dialog-description"
                                                            >
                                                            <DialogTitle id="sub-scroll-dialog-title">Sub Questioners</DialogTitle>
                                                            <DialogContent dividers={subScroll === 'paper'}>
                                                            <DialogContentText
                                                                id="sub-scroll-dialog-description"
                                                                ref={descriptionElementRef}
                                                                tabIndex={-1}
                                                                >
                                                                {item.subQuestions.map((itemSub)=>(  
                                                                    <Grid item xs={12} spacing={2}>
                                                                        <Card className={classes.qusCard}>
                                                                            <CardHeader className={classes.qusCardHeader} 
                                                                                avatar={
                                                                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                                                        {itemSub.subQuestion.charAt(0)}
                                                                                    </Avatar>
                                                                                }
                                                                                action={
                                                                                    <div>
                                                                                        <IconButton 
                                                                                            aria-label="settings"
                                                                                            onClick={menuHandleClick}
                                                                                            >
                                                                                            <MoreVertIcon />
                                                                                        </IconButton>
                                                                                        <Menu
                                                                                            id="long-menu"
                                                                                            MenuListProps={{
                                                                                                'aria-labelledby': 'long-button',
                                                                                            }}
                                                                                            anchorEl={anchorEl}
                                                                                            open={menuOpen}
                                                                                            onClose={menuHandleClose}
                                                                                            PaperProps={{
                                                                                                style: {
                                                                                                    maxHeight: 120,
                                                                                                    width: '20ch',
                                                                                                },
                                                                                            }}
                                                                                            >
                                                                                            <MenuItem onClick={menuHandleClose} disableRipple>
                                                                                                <EditIcon />
                                                                                                Edit
                                                                                            </MenuItem>
                                                                                            <MenuItem onClick={menuHandleClose} disableRipple>
                                                                                                <ListItemIcon>
                                                                                                    <PersonAdd fontSize="small" />
                                                                                                </ListItemIcon>
                                                                                                Add Sub Question
                                                                                            </MenuItem>
                                                                                        </Menu>
                                                                                    </div>
                                                                                }
                                                                                title={"To : " + itemSub.entity.name}
                                                                                subheader={itemSub.subQuestion}
                                                                            />
                                                                      <CardContent>
                                                                        <Grid container spacing={2}>
                                                                          <Grid item xs={6}>
                                                                              <Typography variant="h6" className={classes.cardBodyH6}>Risk Type</Typography>
                                                                              <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                                                  {itemSub.riskType.name}
                                                                              </Typography>
                                                                          </Grid>
                                                                          <Grid item xs={6}>
                                                                              <Typography variant="h6" className={classes.cardBodyH6}>Objective</Typography>
                                                                              <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                                                  {itemSub.objective.name + (itemSub.objective.standardName != ""?"(" + itemSub.objective.standardName + ")":"")}
                                                                              </Typography>
                                                                          </Grid>
                                                                          <Grid item xs={6}>
                                                                              <Typography variant="h6" className={classes.cardBodyH6}>Component</Typography>
                                                                              <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                                                  {itemSub.component.name + (itemSub.component.standardName != ""?"(" + itemSub.component.standardName + ")":"")}
                                                                              </Typography>
                                                                          </Grid>
                                                                          <Grid item xs={6}>
                                                                              <Typography variant="h6" className={classes.cardBodyH6}>Principle</Typography>
                                                                              <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans} style={{textAlign: 'justify'}}>
                                                                                  {itemSub.principle.name + (itemSub.principle.standardName != ""?"(" + itemSub.principle.standardName + ")":"")}
                                                                              </Typography>
                                                                          </Grid>
                                                                          <Grid item xs={6}>
                                                                              <Typography variant="h6" className={classes.cardBodyH6}>Question Type</Typography>
                                                                              <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}>
                                                                                  {itemSub.questionType == "Openended"? "Open Ended Question":"Close ended Question"}
                                                                              </Typography>
                                                                          </Grid>
                                                                          {itemSub.questionType == "Closeended" && (
                                                                              <Grid item xs={6}>
                                                                                  <Typography variant="h6" className={classes.cardBodyH6}>Measurement scale</Typography>
                                                                                  <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}>
                                                                                  {itemSub.questionTypeName}
                                                                              </Typography>
                                                                              </Grid>
                                                                          )}
                                                                        </Grid> 
                                                                        {itemSub.questionType == "Closeended" && (
                                                                          <>
                                                                              <Typography variant="h6" className={classes.cardBodyH6}>Answers</Typography>
                                                                              <Typography variant="body2" color="text.secondary" className={classes.cardBodyH6Ans}> 
                                                                                  {itemSub.answers.answerList.length > 0 && (
                                                                                      <TableContainer component={Paper}>
                                                                                      <Table aria-label="customized table">
                                                                                          <TableHead className={classes.tableHeadStyle}>
                                                                                              <TableRow>
                                                                                                  <StyledTableCell className={classes.tableThStyle} style={{width: '50%'}}>Answer</StyledTableCell>
                                                                                                  <StyledTableCell className={classes.tableThStyle} style={{width: '30%'}}>Answer Method</StyledTableCell>
                                                                                                  <StyledTableCell className={classes.tableThStyle} style={{width: '20%'}}>Risk Level</StyledTableCell>
                                                                                              </TableRow>
                                                                                          </TableHead>
                                                                                          <TableBody>
                                                                                          {itemSub.answers.answerMethod.name === "radioButtonType" &&  itemSub.answers.answerList.map((row,index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>
                                                                                  
                                                                                  <input 
                                                                                      type="radio" 
                                                                                      name={"radio" + itemSub.questionId}
                                                                                      id={"radio" + itemSub.questionId + index}
                                                                                      onClick={(e) => checkedRadio(itemSub.questionId, "radio" + itemSub.questionId + index)}  
                                                                                      >
                                                                                  </input>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                      {itemSub.answers.answerMethod.name === "checkBoxType" &&  itemSub.answers.answerList.map((row,index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>
                                                                                  <input 
                                                                                      type="checkbox" 
                                                                                      id={"chk" + itemSub.questionId + index} 
                                                                                      name={"chk" + itemSub.questionId + index}
                                                                                      />
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableRadioTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                      {itemSub.answers.answerMethod.name === "orderNumberType" &&  itemSub.answers.answerList.map((row, index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>
                                                                                  <FormControl sx={{ m: 0.5, minWidth: 100 }} size="small">
                                                                                      <InputLabel id="select-select-label">Select</InputLabel>
                                                                                      <Select
                                                                                          labelId="select-select-label"
                                                                                          id={"select" + itemSub.questionId + index}
                                                                                          label="Select"
                                                                                          defaultValue={row.expectedAnswerName}
                                                                                          >
                                                                                          <MenuItem value={1}>{1}</MenuItem>
                                                                                          <MenuItem value={2}>{2}</MenuItem>
                                                                                          <MenuItem value={3}>{3}</MenuItem>
                                                                                          <MenuItem value={4}>{4}</MenuItem>
                                                                                          <MenuItem value={5}>{5}</MenuItem>
                                                                                          <MenuItem value={6}>{6}</MenuItem>
                                                                                          <MenuItem value={7}>{7}</MenuItem>
                                                                                          <MenuItem value={8}>{8}</MenuItem>
                                                                                          <MenuItem value={9}>{9}</MenuItem>
                                                                                          <MenuItem value={10}>{10}</MenuItem>
                                                                                      </Select>
                                                                                      </FormControl>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                                                                      {itemSub.answers.answerMethod.name === "answerTypeType" &&  itemSub.answers.answerList.map((row,index) => (
                                                                          <StyledTableRow key={row.answerName}>
                                                                              <StyledTableCell className={classes.tableTrStyle}> 
                                                                                  {row.answerName}
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>
                                                                                  <Box component="form" sx={{'& .MuiTextField-root': { width: '10ch' },}} autoComplete="off">
                                                                                      <div>
                                                                                          <TextField
                                                                                              id={"txt" + itemSub.questionId + index}
                                                                                              //label="Answer"
                                                                                              size="small"
                                                                                              defaultValue={row.expectedAnswerName}
                                                                                          />
                                                                                      </div>
                                                                                  </Box> 
                                                                              </StyledTableCell>
                                                                              <StyledTableCell className={classes.tableTrStyle}>{row.riskLevel}</StyledTableCell>
                                                                          </StyledTableRow>
                                                                      ))}
                          
                                                                                          </TableBody>
                                                                                      </Table>
                                                                                  </TableContainer>  
                                                  
                                                                                  )}    
                                                                              </Typography>     
                                                                          </>
                                                  
                                                                        )}
                          
                                                                        
                                                                      
                                                                      </CardContent>
                                                                    </Card>
                                                  
                                                                  </Grid> 
                                                                    
                                                                ))}    
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={subHandleClose}>Cancel</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                    </div>
                                                )}    
                                            </CardContent>
                                         
                                                                    </Card>
                                    </Grid>   
                                    
                                 
                              </div>
                              )
                            }  
                          })}
                        
                    </Grid>
                    <Grid item xs={1}>
                          <ArrowCircleRightSharp
                          onClick={()=>nextNumber()} 
                          style={{cursor:'pointer', float:'left', marginTop:'35%'}}
                          /> 
                   </Grid>  
                   <Grid item xs={1}>
                          &nbsp; 
                   </Grid>  
    
                    
                   <Grid item xs={10}>
                    <p style={{textAlign:'center'}}>
                    {quesData.map((item,index)=>{
                      if (index === nextNumberCount){
                        return(

                          <>
                            <span style={{color:'red'}}>
                              {index + 1}
                            </span>
                            {'  '}
                          </>
                        )
                      }
                      if (index != nextNumberCount){
                        return(
                          <>
                            <span onClick={()=>numberClick(index + 1)} style={{cursor:'pointer'}}>
                              {index + 1}
                            </span>
                            {'  '}
                          </>
                        )
                      } 
                    })}
                        </p>
                    </Grid>
                    <Grid item xs={1}>
                          &nbsp; 
                   </Grid>
                    
                    
                </Grid>    
            </div>
    
    
        </>
    
    
  )
}

export default RiskTypeQustionDisplay