import React, { useEffect, useState } from 'react'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import useStyle from '../Css/MainCss';
import { ArrowBack, CheckBox, ClearAllOutlined, Details, SendOutlined } from '@mui/icons-material';
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info } from '@mui/icons-material';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { CONFIG } from '../config'

import Tooltip from '@mui/material/Tooltip';
import { Close } from '@mui/icons-material';
import RiskHierarchyNew from '../Components/RiskHierarchyNew';
import Draggable from 'react-draggable';
import { event } from 'jquery';
import { CircularProgress } from "@material-ui/core";
import RiskRegisterComponent from '../Components/RiskRegisterComponent';
import '../Css/UserRights.css'
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularWithValueLabel from '../Components/ProgressTimer';
import { ClockLoader } from 'react-spinners';


function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



const UserRights = () => {
    const classe = useStyle();
    const [PageName, setPageName] = React.useState('Assign User Rights');
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [loading1, setLoading1] = React.useState(false);


    React.useEffect(() => {
        getData();
    }, [])

    const [globalCentreId, setGlobalCentreId] = React.useState('');
    const [globalUserId, setGlobalUserId] = React.useState('');
    const [globalUserRoleId, setGlobalUserRoleId] = React.useState('');
    function getData() {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        setGlobalCentreId(data.centreId);
        setGlobalUserId(data.userId);
        setGlobalUserRoleId(data.userRoleId);
        getAllRegister(data.centreId,data.userId,data.userRoleId);
        getAllUsers(data.centreId, data.userId)
    }



    const [allUsers, setAllUsers] = React.useState([]);
    function compareUserDesc(a, b) {
        var nameA = a.createdDate;
        var nameB = b.createdDate;
        if (nameA > nameB) { return -1; }
        if (nameA < nameB) { return 1; }
        return 0;
    }
    function getAllUsers(centerId, loggedUserId) {
        var data = '{getAllUsers(centreId:\"' + centerId + '\",roleId:\"' + 'volunteer' + '\"){userId,name,middleName,lastName,poBox,addressLineOne,addressLineTwo,addressLineThree,city,state,postalCode,country,email,phoneNumber,mobileNumber,createdDate,isActive,lastLoginDate,centreId,firstLogin,department{departmentId,departmentName,isActive},userRole{roleId,isActive,entity{entityId,entityName}}}}';
        axios(CONFIG.MAIN_URL + '/graphql', {
            method: 'post',
            headers: {
                'Content-Type': 'text/plain',
            },
            data: data,
        })
            .then(function (response) {
                console.log("+++++++++++++++++All User List++++++++++++++++++");
                console.log(response);
                var userList = response.data.data.getAllUsers;
                console.log(userList);
                userList = userList.sort(compareUserDesc);
                var tmpArr = [];

                userList.forEach((item) => {
                    var obj = {};
                    obj["assignedId"] = "";
                    obj["userId"] = item.userId;
                    obj["centreId"] = item.centreId;
                    obj["name"] = item.name;
                    obj["lastName"] = item.lastName;
                    var tmpArr1 = [];
                    item.userRole.forEach((role) => {
                        if (role.isActive) {
                            var obj1 = {};
                            obj1["roleId"] = role.roleId;
                            tmpArr1.push(obj1);
                        }
                    })
                    obj["userRole"] = tmpArr1;
                    obj["assignedRignts"] = false;
                    if (loggedUserId != item.userId ){
                        tmpArr.push(obj)
                    }
                })
                console.log("+++++++++++++tmpArr++++++++++++++++++++");
                console.log(tmpArr);
                axios(CONFIG.MAIN_URL + `/userRights/filter?centreId=` + centerId, {
                    method: 'get',
                })
                    .then(function (res) {
                        var arr = []
                        console.log("Return All Rights Assigned Users");
                        console.log(res.data);
                        var rtnData = res.data
                        tmpArr.forEach((item) => {
                            rtnData.forEach((dataT) => {
                                if (dataT.userCode === item.userId) {
                                    item["assignedId"] = dataT.id;
                                    item["assignedRignts"] = true;
                                }
                            })
                        })
                        console.log("+++++++++++++1111tmpArr111++++++++++++++++++++");
                        console.log(tmpArr);
                        setAllUsers(tmpArr);
                    })
                    .catch(function (error) {
                        setAllUsers(tmpArr);
                        console.log(error);
                    });
            })
            .catch(function (error) {
                console.log("Error Loding User List" + error);
            });

    }

    const [allRegister, setAllRegister] = React.useState([]);
    function compareRegister(a, b) {
        var nameA = new Date(a.createdDate); // ignore upper and lowercase
        var nameB = new Date(b.createdDate); // ignore upper and lowercase
        if (nameA > nameB) {
            return -1;
        }
        if (nameA < nameB) {
            return 1;
        }
        // names must be equal
        return 0;
    }

    function getAllRegister(centrId, userId, userRoleId) {
        axios(CONFIG.MAIN_URL + `/register/all`, {
            method: 'get',
        })
            .then(function (response) {
                var arr = []
                console.log("Return All Register");
                console.log(response.data);
                response.data.forEach((item) => {
                    if (item.centreId === centrId) {
                        if (userRoleId === "staff"){
                            item["showHide"] = "show";
                            arr.push(item)
                        }else{
                            if (userId === item.createdUserId){
                                item["showHide"] = "show";
                                arr.push(item)
                            }
                        }
                    }
                })
                arr = arr.sort(compareRegister);
                setAllRegister(arr);
            })
            .catch(function (error) {
                console.log(error);
            });


    }
    const [saveBtnName, setSaveBtnName] = React.useState("Save");

    function clearAll() {
        setChkCreateNewRegisterState(false);
        setChkAmendRegistersState(false);
        setChkFinaliseRegisterState(false);
        setChkCreateCollectionState(false);
        setChkDeleteRegisterState(false);
        setChkSelectAllRegistersState(false)
        setHideShowMessage("Hide Unticked");
        allRegister.forEach((item) => {
            // (item.status === "Draft") {
                if (document.getElementById("chkRegister" + item.id) != null){
                    document.getElementById("chkRegister" + item.id).checked = false;
                }
                if (document.getElementById("chkRegisterFinalise" + item.id) != null){
                    document.getElementById("chkRegisterFinalise" + item.id).checked = false;
                }
                if (document.getElementById("chkRegisterDelete" + item.id) != null){
                    document.getElementById("chkRegisterDelete" + item.id).checked = false;
                }
                if (document.getElementById("chkRegisterCollection" + item.id) != null){
                    document.getElementById("chkRegisterCollection" + item.id).checked = false;
                }
            //}
        });
        var arr = [];
        allRegister.forEach((item) => {
            item["showHide"] = "show";
            arr.push(item)
        })
        setAllRegister(arr);

        setAmendUserName('');
        setAmendUserId('');
        setSelectedUserCode('');
        setSaveBtnName('Save');
    }

    const [amendUserId, setAmendUserId] = React.useState("");
    const [selectedUserCode, setSelectedUserCode] = React.useState("");
    function submitRightsFuction() {
        var chkCreateNewRegisterStr = "No";
        if (chkCreateNewRegisterState) {
            chkCreateNewRegisterStr = "Yes";
        }
        if (selectedUserCode === "") {
            alert("Please select a user to Assign Rights");
            return;
        }

        var obj = {};
        if (amendUserId != "") {
            obj["id"] = amendUserId;
        }
        obj["centreId"] = globalCentreId;
        obj["userCode"] = selectedUserCode;
        obj["allowAssignUserRights"] = "No";
        obj["createNewRegister"] = chkCreateNewRegisterStr;
        var tArr = [];
        var foundAlLeastOne = false;
        allRegister.forEach((item) => {
            //if (item.status === "Draft") {
                if (document.getElementById("chkRegister" + item.id) != null){
                    var chkRegister = document.getElementById("chkRegister" + item.id).checked;
                    if (chkRegister) {
                        foundAlLeastOne = true;
                        var obj1 = {};
                        obj1["regsiterId"] = item.id;
                        obj1["registerUniqueName"] = item.registerUniqueName;
                        var tmp1 = document.getElementById("chkRegisterFinalise" + item.id).checked;
                        if (tmp1) {
                            obj1["allowFinaliseRegisters"] = "Yes";
                        }else{
                            obj1["allowFinaliseRegisters"] = "No";
                        }
                        var tmp2 = document.getElementById("chkRegisterDelete" + item.id).checked;
                        if (tmp2) {
                            obj1["allowDeleteRegisters"] = "Yes";
                        }else{
                            obj1["allowDeleteRegisters"] = "No";
                        }
                        var tmp3 = document.getElementById("chkRegisterCollection" + item.id).checked;
                        if (tmp3) {
                            obj1["allowCreateCollections"] = "Yes";
                        }else{
                            obj1["allowCreateCollections"] = "No";
                        }
                        tArr.push(obj1);
                    }
                }
            //}
        });
        if (!foundAlLeastOne) {
            alert("Please select atleast one register to amend");
            return;
        }
        obj["registersAllowedToAmend"] = tArr;
        let sendJson = JSON.stringify(obj);
        console.log(sendJson);
        var urlEndPoint = '/userRights/create';
        if (amendUserId != "") {
            urlEndPoint = '/userRights/update';
        }
        setLoading(true);
        axios(CONFIG.MAIN_URL + urlEndPoint, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: sendJson,
        })
            .then(function (resp) {
                setLoading(false);
                console.log("++++++++++++++++++User Rights Create / Update Response+++++++++++++++++++");
                console.log(resp.data);
                if (amendUserId != "") {
                    alert("User Rights Updated successfully.");
                } else {
                    alert("User Rights Created successfully.");
                }
                getAllUsers(globalCentreId, globalUserId);
                clearAll();
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
                alert("Error updating User Rights -:" + error);
            });
    }

    function getCheckStatus(regId) {
        if (selectedUserCode === "") {
            alert("Please select a user to Assign Rights");
            document.getElementById("chkRegister" + regId).checked = false;
            return;
        }
        var chkRegister = document.getElementById("chkRegister" + regId).checked;
        if (!chkRegister) {
            if (chkSelectAllRegistersState){
                setChkSelectAllRegistersState(false);
            }
            document.getElementById("chkRegisterFinalise" + regId).checked = false;
            document.getElementById("chkRegisterCollection" + regId).checked = false;
            document.getElementById("chkRegisterDelete" + regId).checked = false;
        }
        var selectAll = true;
        allRegister.forEach((item) => {
            //if (item.status === "Draft") {
                if (document.getElementById("chkRegister" + item.id) != null){
                    var tmp = document.getElementById("chkRegister" + item.id).checked;
                    if (!tmp) {
                        selectAll = false;
                    }
                }else{
                    selectAll = false;
                }
            //}
        })
        if (selectAll) {
            setChkSelectAllRegistersState(true);
        }
    }

    function getCheckFinaliseStatus(regId) {
        if (selectedUserCode === "") {
            alert("Please select a user to Assign Rights");
            document.getElementById("chkRegisterFinalise" + regId).checked = false;
            return;
        }
        var chkRegister = document.getElementById("chkRegisterFinalise" + regId).checked;
        if (!chkRegister) {
            if (chkFinaliseRegisterState){
                setChkFinaliseRegisterState(false);
            }
        }else{
            document.getElementById("chkRegister" + regId).checked = true;
        }
        var selectAll = true;
        allRegister.forEach((item) => {
            //if (item.status === "Draft") {
                if (document.getElementById("chkRegister" + item.id) != null){
                    var tmp = document.getElementById("chkRegisterFinalise" + item.id).checked;
                    if (!tmp) {
                        selectAll = false;
                    }
                }else{
                    selectAll = false;
                }
            //}
        })
        if (selectAll) {
            setChkFinaliseRegisterState(true);
        }

    }

    function getCheckCollectionStatus(regId) {
        if (selectedUserCode === "") {
            alert("Please select a user to Assign Rights");
            document.getElementById("chkRegisterCollection" + regId).checked = false;
            return;
        }
        var chkRegister = document.getElementById("chkRegisterCollection" + regId).checked;
        if (!chkRegister) {
            if (chkCreateCollectionState){
                setChkCreateCollectionState(false);
            }
        }else{
            document.getElementById("chkRegister" + regId).checked = true;
        }
        var selectAll = true;
        allRegister.forEach((item) => {
            //if (item.status === "Draft") {
                if (document.getElementById("chkRegister" + item.id) != null){
                    var tmp = document.getElementById("chkRegisterCollection" + item.id).checked;
                    if (!tmp) {
                        selectAll = false;
                    }
                }else{
                    selectAll = false;
                }    
            //}
        })
        if (selectAll) {
            setChkCreateCollectionState(true);
        }

    }
    function getCheckDeleteStatus(regId) {
        if (selectedUserCode === "") {
            alert("Please select a user to Assign Rights");
            document.getElementById("chkRegisterDelete" + regId).checked = false;
            return;
        }
        var chkRegister = document.getElementById("chkRegisterDelete" + regId).checked;
        if (!chkRegister) {
            if (chkDeleteRegisterState){
                setChkDeleteRegisterState(false);
            }
        }else{
            document.getElementById("chkRegister" + regId).checked = true;
        }
        var selectAll = true;
        allRegister.forEach((item) => {
            //if (item.status === "Draft") {
                if (document.getElementById("chkRegister" + item.id) != null){
                    var tmp = document.getElementById("chkRegisterDelete" + item.id).checked;
                    if (!tmp) {
                        selectAll = false;
                    }
                }else{
                    selectAll = false;
                }
            //}
        })
        if (selectAll) {
            setChkDeleteRegisterState(true);
        }

    }

    const [chkCreateNewRegisterState, setChkCreateNewRegisterState] = React.useState(false);
    function handleClickChkCreateNewRegister() {
        if (selectedUserCode === "") {
            alert("Please select a user to Assign Rights");
            return;
        }
        if (chkCreateNewRegisterState) {
            setChkCreateNewRegisterState(false);
        } else {
            setChkCreateNewRegisterState(true);
        }
    }

    const [chkAmendRegistersState, setChkAmendRegistersState] = React.useState(false);
    function handleClickChkAmendRegisters() {
        if (chkAmendRegistersState) {
            setChkAmendRegistersState(false);
        } else {
            setChkAmendRegistersState(true);
        }
    }

    const [chkSelectAllRegistersState, setChkSelectAllRegistersState] = React.useState(false);
    function handleClickChkSelectAllRegisters() {
        if (selectedUserCode === "") {
            alert("Please select a user to Assign Rights");
            return;
        }
        if (chkSelectAllRegistersState) {
            var selectAll = true;
            allRegister.forEach((item) => {
                //if (item.status === "Draft") {
                    if (document.getElementById("chkRegister" + item.id) != null){
                        var tmp = document.getElementById("chkRegister" + item.id).checked;
                        if (!tmp) {
                            selectAll = false;
                        }
                    }else{
                        selectAll = false;
                    }
                //}
            })
            if (selectAll){
                setChkSelectAllRegistersState(true);
            }else{
                setChkSelectAllRegistersState(false);
            }

        } else {
            setChkSelectAllRegistersState(true);
            allRegister.forEach((item) => {
                //if (item.status === "Draft") {
                    if (document.getElementById("chkRegister" + item.id) != null){
                        var tmp = document.getElementById("chkRegister" + item.id).checked;
                        if (!tmp) {
                            document.getElementById("chkRegister" + item.id).checked = true;
                        }
                    }
                //}
            })
        }
    }


    const [chkFinaliseRegisterState, setChkFinaliseRegisterState] = React.useState(false);
    function handleClickChkFinaliseRegister() {
        if (selectedUserCode === "") {
            alert("Please select a user to Assign Rights");
            return;
        }
        if (chkFinaliseRegisterState) {
            var selectAll = true;
            allRegister.forEach((item) => {
                //if (item.status === "Draft") {
                    if (document.getElementById("chkRegister" + item.id) != null){
                        var tmp = document.getElementById("chkRegisterFinalise" + item.id).checked;
                        if (!tmp) {
                            selectAll = false;
                        }
                    }else{
                        selectAll = false;
                    }
                //}
            })
            if (selectAll){
                setChkFinaliseRegisterState(true);
            }else{
                setChkFinaliseRegisterState(false);
            }

        } else {
            setChkFinaliseRegisterState(true);
            allRegister.forEach((item) => {
                //if (item.status === "Draft") {
                    if (document.getElementById("chkRegister" + item.id) != null){
                        if (document.getElementById("chkRegister" + item.id).checked){
                            var tmp = document.getElementById("chkRegisterFinalise" + item.id).checked;
                            if (!tmp) {
                                document.getElementById("chkRegisterFinalise" + item.id).checked = true;
                            }
                        }
                    }
                //}
            })
        }
    }

    const [chkCreateCollectionState, setChkCreateCollectionState] = React.useState(false);
    function handleClickChkCreateCollection() {
        if (selectedUserCode === "") {
            alert("Please select a user to Assign Rights");
            return;
        }
        if (chkCreateCollectionState) {
            var selectAll = true;
            allRegister.forEach((item) => {
                //if (item.status === "Draft") {
                    if (document.getElementById("chkRegister" + item.id) != null){
                        var tmp = document.getElementById("chkRegisterCollection" + item.id).checked;
                        if (!tmp) {
                            selectAll = false;
                        }
                    }else{
                        selectAll = false;
                    }
                //}
            })
            if (selectAll){
                setChkCreateCollectionState(true);
            }else{
                setChkCreateCollectionState(false);
            }
        } else {
            setChkCreateCollectionState(true);
            allRegister.forEach((item) => {
                //if (item.status === "Draft") {
                    if (document.getElementById("chkRegister" + item.id) != null){
                        if (document.getElementById("chkRegister" + item.id).checked){
                            var tmp = document.getElementById("chkRegisterCollection" + item.id).checked;
                            if (!tmp) {
                                document.getElementById("chkRegisterCollection" + item.id).checked = true;
                            }
                        }
                    }
                //}
            })
        }
    }

    const [chkDeleteRegisterState, setChkDeleteRegisterState] = React.useState(false);
    function handleClickChkDeleteRegister() {
        if (selectedUserCode === "") {
            alert("Please select a user to Assign Rights");
            return;
        }
        if (chkDeleteRegisterState) {
            var selectAll = true;
            allRegister.forEach((item) => {
                //if (item.status === "Draft") {
                    if (document.getElementById("chkRegister" + item.id) != null){
                        var tmp = document.getElementById("chkRegisterDelete" + item.id).checked;
                        if (!tmp) {
                            selectAll = false;
                        }
                    }else{
                        selectAll = false;
                    }
                //}
            })
            if (selectAll){
                setChkDeleteRegisterState(true);
            }else{
                setChkDeleteRegisterState(false);
            }
        } else {
            setChkDeleteRegisterState(true);
            allRegister.forEach((item) => {
                //if (item.status === "Draft") {
                    if (document.getElementById("chkRegister" + item.id) != null){
                        if (document.getElementById("chkRegister" + item.id).checked){
                            var tmp = document.getElementById("chkRegisterDelete" + item.id).checked;
                            if (!tmp) {
                                document.getElementById("chkRegisterDelete" + item.id).checked = true;
                            }
                        }
                    }
                //}
            })
        }
    }

    const [amendUserName, setAmendUserName] = React.useState('');
    function assignRigntsFuction(item) {
        clearAll();
        setAmendUserName(' - ' + item.name + ' ' + item.lastName);
        setSelectedUserCode(item.userId);
    }
    function editRigntsFuction(item) {
        clearAll();
        setAmendUserName(' - ' + item.name + ' ' + item.lastName);
        setSelectedUserCode(item.userId);
        setAmendUserId(item.assignedId);
        setSaveBtnName('Update');
        setLoading1(true);
        axios(CONFIG.MAIN_URL + `/userRights/filter?centreId=` + globalCentreId, {
            method: 'get',
        })
            .then(function (res) {
                setLoading1(false);
                var arr = []
                console.log("Return All Rights Assigned Users");
                console.log(res.data);
                var rtnData = res.data
                rtnData.forEach((tData) => {
                    if (tData.id === item.assignedId) {
                        if (tData.createNewRegister === "Yes") {
                            setChkCreateNewRegisterState(true);
                        } else {
                            setChkCreateNewRegisterState(false);
                        }
                        var chechAllClick = true;
                        allRegister.forEach((item1) => {
                            //if (item1.status === "Draft") {
                                var chechSubClick = false;
                                tData.registersAllowedToAmend.forEach((item2) => {
                                    if (item2.regsiterId === item1.id) {
                                        document.getElementById("chkRegister" + item1.id).checked = true;
                                        chechSubClick = true;
                                        if (item2.allowFinaliseRegisters === "Yes"){
                                            document.getElementById("chkRegisterFinalise" + item1.id).checked = true;
                                        }
                                        if (item2.allowCreateCollections === "Yes"){
                                            document.getElementById("chkRegisterCollection" + item1.id).checked = true;
                                        }
                                        if (item2.allowDeleteRegisters === "Yes"){
                                            document.getElementById("chkRegisterDelete" + item1.id).checked = true;
                                        }
                                    }
                                })
                                if (!chechSubClick) {
                                    chechAllClick = false;
                                }
                            //}
                        });
                        if (chechAllClick) {
                            setChkSelectAllRegistersState(true);
                        }

                        var chechFinaliseClick = true;
                        var chechDeleteClick = true;
                        var chechCollectionClick = true;
                        var len1 = 0;
                        var len2 = 0; 
                        allRegister.forEach((item1) => {
                            //if (item1.status === "Draft") {
                                len1 ++;
                                var len3 = 0;
                                tData.registersAllowedToAmend.forEach((item2) => {
                                    len3 ++;
                                    if (item2.regsiterId === item1.id) {
                                        if (item2.allowFinaliseRegisters != "Yes"){
                                            chechFinaliseClick = false;
                                        }
                                        if (item2.allowCreateCollections != "Yes"){
                                            chechCollectionClick = false;
                                        }
                                        if (item2.allowDeleteRegisters != "Yes"){
                                            chechDeleteClick = false;
                                        }
                                    }
                                })
                                len2 = len3;
                            //}
                        });

                        if (len1 === len2){
                            if (chechFinaliseClick) {
                                setChkFinaliseRegisterState(true);
                            }
                            if (chechCollectionClick) {
                                setChkCreateCollectionState(true);
                            }
                            if (chechDeleteClick) {
                                setChkDeleteRegisterState(true);
                            }
                        }






                    }
                })
            })
            .catch(function (error) {
                setLoading1(false);
                console.log(error);
            });

    }

    const [hideShowMessage, setHideShowMessage] = React.useState('Hide Unticked');
    const [hideShowData, setHideShowData] = React.useState([]);
    function hideShowUntick(){
        if (selectedUserCode === "") {
            alert("Please select a user to Assign Rights");
            return;
        }
        var arr = [];
        if (hideShowMessage === "Hide Unticked"){
            setHideShowMessage("Show Unticked")
            
            var showHideSelectedArr = []
            allRegister.forEach((item) => {
                var obj = {}
                obj["id"] = item.id
                var tmp = document.getElementById("chkRegister" + item.id).checked;
                if (!tmp){
                    item["showHide"] = "hide";
                    obj["edit"] = "No"
                }else{
                    obj["edit"] = "Yes"
                }
                tmp = document.getElementById("chkRegisterFinalise" + item.id).checked;
                if (!tmp){
                    obj["finalise"] = "No"
                }else{
                    obj["finalise"] = "Yes"
                }
                tmp = document.getElementById("chkRegisterCollection" + item.id).checked;
                if (!tmp){
                    obj["collection"] = "No"
                }else{
                    obj["collection"] = "Yes"
                }
                tmp = document.getElementById("chkRegisterDelete" + item.id).checked;
                if (!tmp){
                    obj["delete"] = "No"
                }else{
                    obj["delete"] = "Yes"
                }
                showHideSelectedArr.push(obj);
                arr.push(item);
            })
            setHideShowData(showHideSelectedArr);
            setAllRegister(arr);
            setTimeout(function () {
                
                allRegister.forEach((item) => {
                    showHideSelectedArr.forEach((data) => {
                        if (item.id === data.id){
                            if (data.edit === "Yes"){
                                document.getElementById("chkRegister" + data.id).checked = true;
                            }
                            if (data.finalise === "Yes"){
                                document.getElementById("chkRegisterFinalise" + data.id).checked = true;
                            }
                            if (data.collection === "Yes"){
                                document.getElementById("chkRegisterCollection" + data.id).checked = true;
                            }
                            if (data.delete === "Yes"){
                                document.getElementById("chkRegisterDelete" + data.id).checked = true;
                            }
                        }

                    })
                })

            }, 100);
        }else{
            setHideShowMessage("Hide Unticked")
            allRegister.forEach((item) => {
                item["showHide"] = "show";
                arr.push(item);
            })
            setAllRegister(arr);
            setTimeout(function () {
                allRegister.forEach((data) => {
                    if (document.getElementById("chkRegister" + data.id) != null){
                        document.getElementById("chkRegister" + data.id).checked = false;
                        document.getElementById("chkRegisterFinalise" + data.id).checked = false;
                        document.getElementById("chkRegisterCollection" + data.id).checked = false;
                        document.getElementById("chkRegisterDelete" + data.id).checked = false;
                    }
                })
                allRegister.forEach((item) => {
                    hideShowData.forEach((data) => {
                        if (item.id === data.id){
                            if (data.edit === "Yes"){
                                document.getElementById("chkRegister" + data.id).checked = true;
                            }
                            if (data.finalise === "Yes"){
                                document.getElementById("chkRegisterFinalise" + data.id).checked = true;
                            }
                            if (data.collection === "Yes"){
                                document.getElementById("chkRegisterCollection" + data.id).checked = true;
                            }
                            if (data.delete === "Yes"){
                                document.getElementById("chkRegisterDelete" + data.id).checked = true;
                            }
                        }

                    })
                })

            }, 100);
        }
        
    }


    return (
        <>
            <RightBarDataForOtherPages PageName={PageName} />
            <div className={classe.pageTop} >
                <Grid container spacing={2}>
                    <Grid container spacing={2} className={classe.pageBodyStyleRiskReg}>
                        <Grid item xs={12}>
                            <div role="presentation" onClick={handleClick}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" to="/login">
                                        Home
                                    </Link>
                                    <Typography color="text.primary">Main menu - {PageName}</Typography>
                                </Breadcrumbs>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <a style={{ float: 'right', marginTop: '-36px', color: '#0056b3', cursor: 'pointer' }} onClick={() => navigate(-1)}><ArrowBack /> Go Back</a>
                        </Grid>
                        <Grid item xs={8}>
                            <Card className='stdCard'>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                            <Info />
                                        </Avatar>
                                    }
                                    className={classe.commonMainTableHead}
                                    title={"Assign User Rights "}
                                />
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} style={{marginLeft: '145px', marginTop: '-56px'}}>
                                            <span style={{fontSize: '1.6rem', fontWeight: 500, color: '#fff'}}>{amendUserName}</span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Checkbox id={"chkCreateNewRegister"} checked={chkCreateNewRegisterState} onClick={() => { handleClickChkCreateNewRegister() }} />} label={<Typography sx={{ fontSize: '1.1rem !important' }}>{"Allow Create a New Register"}</Typography>} className={'formControlLabelStyle'} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Card className='stdCard'>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar sx={{ bgcolor: '#6966e', width: '25px', height: '25px' }}>
                                                            <Info />
                                                        </Avatar>
                                                    }
                                                    className={classe.commonMainTableHead}
                                                    sx={{ bgcolor: '#7B9DE0 !important' }}
                                                    title="Select Registers To Amend"
                                                />
                                                <CardContent>
                                                    <Grid container spacing={1}>
                                                        {/** 
                                                        <Grid item xs={1}>
                                                            <input
                                                                type='checkbox'
                                                                onClick={() => {
                                                                    checkAllRegistersStatus('chkSelectAllRegisters')
                                                                }}
                                                                id={"chkSelectAllRegisters"}
                                                                name={"chkSelectAllRegisters"}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography sx={{ fontSize: '1.1rem !important', marginLeft: '-30px' }}>{"Select All Registers"}</Typography>
                                                        </Grid>
                                                        */}
                                                        <Grid item xs={4}>
                                                            <FormControlLabel
                                                                control={<Checkbox id={"chkSelectAllRegisters"} checked={chkSelectAllRegistersState} onClick={() => { handleClickChkSelectAllRegisters() }} />} label={<Typography sx={{ fontSize: '1.1rem !important' }}>{"Select All Registers"}</Typography>} className={'formControlLabelStyle'} />
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={6}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox id={"chkFinaliseRegister"} checked={chkFinaliseRegisterState} onClick={() => { handleClickChkFinaliseRegister() }} />} label={<Typography sx={{ fontSize: '1.1rem !important' }}>{"Allow Finalise Registers"}</Typography>} className={'formControlLabelStyle'} />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox id={"chkDeleteRegister"} checked={chkDeleteRegisterState} onClick={() => { handleClickChkDeleteRegister() }} />} label={<Typography sx={{ fontSize: '1.1rem !important' }}>{"Allow Delete Registers"}</Typography>} className={'formControlLabelStyle'} />
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox id={"chkCreateCollection"} checked={chkCreateCollectionState} onClick={() => { handleClickChkCreateCollection() }} />} label={<Typography sx={{ fontSize: '1.1rem !important' }}>{"Allow Create Questioneair Collection"}</Typography>} className={'formControlLabelStyle'} />
                                                                </Grid>
                                                                <Grid item xs={4} style={{float: 'right'}}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        size="small"
                                                                        style={{float: 'right'}}
                                                                        onClick={() => { hideShowUntick() }}
                                                                    >
                                                                        {hideShowMessage}
                                                                    </Button>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>

                                                        {allRegister.filter(reg => reg.showHide != 'hide' ).map((row, index) => {
                                                            return (
                                                                <Grid item xs={6}>
                                                                    <Card className='stdCardInside'>
                                                                        <CardContent>
                                                                            <Grid container spacing={1}>
                                                                                <Grid item xs={1}>
                                                                                    <input
                                                                                        type='checkbox'
                                                                                        onClick={() => {
                                                                                            getCheckStatus(row.id)
                                                                                        }}
                                                                                        id={"chkRegister" + row.id}
                                                                                        name={"chkRegister" + row.id}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={11}>
                                                                                    <Typography sx={{ fontSize: '1.00rem !important', marginLeft: '0px' }}>{row.name}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    &nbsp;
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <input
                                                                                        type='checkbox'
                                                                                        onClick={() => {
                                                                                            getCheckFinaliseStatus(row.id)
                                                                                        }}
                                                                                        id={"chkRegisterFinalise" + row.id}
                                                                                        name={"chkRegisterFinalise" + row.id}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={3}>
                                                                                    <Typography sx={{ fontSize: '0.75rem !important', marginLeft: '0px' }}>{'Allow Finalise'}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <input
                                                                                        
                                                                                        type='checkbox'
                                                                                        onClick={() => {
                                                                                            getCheckCollectionStatus(row.id)
                                                                                        }}
                                                                                        id={"chkRegisterCollection" + row.id}
                                                                                        name={"chkRegisterCollection" + row.id}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={3}>
                                                                                    <Typography sx={{ fontSize: '0.75rem !important', marginLeft: '0px' }}>{'Allow Create Collection'}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <input
                                                                                        type='checkbox'
                                                                                        onClick={() => {
                                                                                            getCheckDeleteStatus(row.id)
                                                                                        }}
                                                                                        id={"chkRegisterDelete" + row.id}
                                                                                        name={"chkRegisterDelete" + row.id}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <Typography sx={{ fontSize: '0.75rem !important', marginLeft: '0px' }}>{'Allow Delete'}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </CardContent>
                                                                    </Card>


                                                                </Grid>
                                                            )
                                                        })}
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>


                                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                                            <Button
                                                variant="contained"
                                                id="riskEvaluateBtn"
                                                endIcon={<SendOutlined />}
                                                onClick={() => submitRightsFuction()}
                                            >
                                                {saveBtnName}
                                            </Button>&nbsp;&nbsp;&nbsp;
                                            <Button
                                                variant="contained"
                                                id="riskEvaluateBtn"
                                                endIcon={<ClearAllOutlined />}
                                                onClick={() => clearAll()}
                                            >
                                                Clear
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>

                        </Grid>
                        <Grid item xs={4}>
                            <Card className='stdCard'>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                            <Info />
                                        </Avatar>
                                    }
                                    className={classe.commonMainTableHead}
                                    title="Users Registered"
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <div className={'scrollableDivToStandard'}>
                                                <TableContainer component={Paper}>
                                                    <Table aria-label="customized table">
                                                        <TableHead className={classe.commonSecondaryTableHead}>
                                                            <TableRow>
                                                                <StyledTableCell className={classe.commonTableThStyle} style={{ width: '70%' }}>User Name(Code)</StyledTableCell>
                                                                <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '20%' }}>Assign</StyledTableCell>
                                                                <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '10%' }}>Edit</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {allUsers.map((row, index) => {
                                                                return (
                                                                    <StyledTableRow key={index} className={classe.tableTrStyle}>
                                                                        <StyledTableCell component="th" scope="row" className={classe.tableTrStyle} >
                                                                            <div style={{ whiteSpace: 'normal' }}>{row.name + ' ' + row.lastName + '(' + row.userId + ')'}</div>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="center"
                                                                            className={classe.tableTrStyle}
                                                                        >
                                                                            {!row.assignedRignts ?
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    size="small"
                                                                                    onClick={() => { assignRigntsFuction(row) }}
                                                                                >
                                                                                    Assign
                                                                                </Button>

                                                                                :
                                                                                <>
                                                                                    &nbsp;
                                                                                </>

                                                                            }
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="right"
                                                                            className={classe.tableTrStyle}
                                                                        >
                                                                            {!row.assignedRignts ?
                                                                                <>
                                                                                    &nbsp;
                                                                                </>
                                                                                :
                                                                                <EditIcon
                                                                                    className={'mousePointer'}
                                                                                    onClick={() => { editRigntsFuction(row) }}
                                                                                />
                                                                            }
                                                                        </StyledTableCell>


                                                                    </StyledTableRow>

                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                        </Grid>



                    </Grid>
                </Grid>
            </div>

            <Dialog
                open={loading}
                onClose={false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
                    <span style={{ textAlign: 'center' }}>Processing !.<br></br>
                        Please Wait .......</span>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <CircularWithValueLabel
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog
                open={loading1}
                onClose={false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
                    <span style={{ textAlign: 'center' }}>Please Wait ...</span>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ClockLoader
                                size={100}
                                color={'red'}
                                //className={classe.loaderStyle}
                                loading={loading1}
                            />

                        </Grid>
                    </Grid>


                </DialogContent>
            </Dialog>



        </>
    )
}

export default UserRights
