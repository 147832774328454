import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Paper,
    Grid,
    Container,
    CssBaseline,
    FormControlLabel,
    Switch as MuiSwitch,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowBack, StarBorderPurple500Rounded } from '@mui/icons-material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import axios from 'axios';
import parseHtml from 'html-react-parser';
import Checkbox from '@mui/material/Checkbox';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import { CONFIG } from '../config';
import { Box } from '@material-ui/core';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DoneIcon from '@mui/icons-material/Done';
import Header from '../Components/Header';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Close } from '@mui/icons-material';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';


const packageBorderStyle = {
    border: '1px solid #ccc', 
    borderRadius: '5px',      
    padding: '10px', 
    //height:'450px',       
};

function PackageFeatues({ id, commonFeatureId, fData }) {
    var found = false;
    var checkBoxTrue = false;
    var textFieldVal = "";
    fData.forEach((item) => {
        if (item.id === commonFeatureId) {
            found = true;
            if (item.type === "checkBox") {
                if (item.value === "yes") {
                    checkBoxTrue = true;
                }
            } else {
                textFieldVal = item.value;
            }
        }
    });
    if (found) {
        return (
            <Paper style={packageBorderStyle}>
                {checkBoxTrue ?
                    <Typography variant="h6" gutterBottom style={{ marginLeft: '25px' }}>
                        <FormControlLabel control={<Checkbox checked />} label="" />
                    </Typography>
                    :
                    <>
                        {textFieldVal !== '' ?
                            <Typography variant="h6" gutterBottom style={{ fontSize: '1rem' }}>
                                {textFieldVal}
                            </Typography>
                            :
                            <Typography variant="h6" gutterBottom style={{ fontSize: '1rem' }}>
                                -
                            </Typography>
                        }
                    </>
                }
            </Paper>
        )
    } else {
        return (
            <Paper style={packageBorderStyle}>
                <Typography variant="h6" gutterBottom style={{ fontSize: '1rem' }}>
                    -
                </Typography>
            </Paper>
        )
    }
}

function Package({ id, method, pricingDetails, anualFee, monthlyFee, currency,pricingFeatures,orderNumber,commonPricingFeatures }) {

    console.log(pricingDetails)
    console.log("++++++++++++++++++++++++++++++++++++++++++++")
    console.log(id)
    console.log("++++++++++++++++++++++++++++++++++++++++++++++")
    const [isAnnual, setIsAnnual] = useState(false);

    const handleToggle = () => {
        setIsAnnual(!isAnnual);
    };

    const [openPackagesDialog, setOpenPackagesDialog] = React.useState(false);
    const [openPackageseDialogScroll, setOpenPackagesDialogScroll] = React.useState('paper');
  
    function openPackagesDialogClose() {
        setOpenPackagesDialog(false)
    }

    function openPackagesDialogOpen(){
        setOpenPackagesDialog(true);
    }

    const [openImage, setOpenImage] = React.useState(false);
    const [imageHtmlData, setImageHtmlData] = React.useState('');
  
    function handleCloseClick() {
      setOpenImage(false)
    }
  
    setTimeout(() => {
  
      var imgTags = document.getElementsByTagName('img');
      for (var i = 0; i < imgTags.length; i++) {
        imgTags[i].onclick = function () {
          var str = "<img src=\"" + this.src + "\" />";
          setImageHtmlData(str)
          setOpenImage(true);
        };
        imgTags[i].onmouseover = function () {
          this.style.cursor = 'pointer';
        };
      }
    }, 1000);



    return (
        <>
        <Paper style={packageBorderStyle}> 
            <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>
                <span style={{ color: "blue" }}><ElectricBoltIcon /></span>&nbsp;
                <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{method}</span>
            </Typography>
            <div style={{ marginTop: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {isAnnual ? <span>Annual</span> : <span>Monthly</span>} &nbsp;&nbsp;&nbsp;&nbsp;
                <FormControlLabel
                    control={
                        <MuiSwitch
                            checked={isAnnual}
                            onChange={handleToggle}
                            name={`pricing-switch-${method}`}
                        />
                    }
                />
            </div>
            <br></br>
            <div style={{ marginTop: 'auto' }}>
                    <div style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
                        <Typography variant="h6" gutterBottom>
                            Price
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            {isAnnual ? `${currency}${anualFee}/year` : `${currency}${monthlyFee}/month`}
                        </Typography>
                        <Link to={`/purchase?id=${id}&method=${method}&price=${isAnnual ? anualFee : monthlyFee}&duration=${isAnnual ? "Annual" : "Monthly"}`}>
                            <Button variant="contained" color="primary" style={{ width: '100%' }}>
                                Buy Now
                            </Button>
                        </Link>
                        <Box>
                        <div style={{textAlign:'center',marginTop:'15px', fontWeight:'bold'}}>
                            Features
                        </div>
                        
                            {pricingFeatures.map((data) => (
                                
                                <Box key={data.id} style={{display:'flex',marginTop:'15px'}}>

                                    <Grid container spacing={2}>
                                    <Grid item xs={1}><NavigateNextIcon/></Grid>
                                         
                                            
                                                <Grid item xs={6}>
                                                {data.name}
                                                </Grid>
                                           
                                        
                                        
                                        {/* <Grid item xs={1}></Grid> */}
                                        <Grid item xs={4}>
                                        {"-"}&nbsp;&nbsp;&nbsp;
                                            {
                                                data.value === 'yes' ?
                                                <>
                                                <DoneIcon/>
                                                </>
                                                :
                                                <>
                                                {data.value}
                                                </>
                                            }
                                            
                                            </Grid>
                                         </Grid>
                                </Box>
                               
                            ))}

<br></br>
                            <div style={{ textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginTop: '10px', height: '36px', width: '50%' }}
                                    onClick={() => {
                                        openPackagesDialogOpen();
                                    }}
                                >
                                    Display More Details
                                </Button>

                            </div>
                        </Box>
                    </div>
                </div>
        </Paper>
        <Dialog
                open={openPackagesDialog}
                onClose={false}
                scroll={openPackageseDialogScroll}
                aria-labelledby="register-data-dialog-title"
                aria-describedby="register-data-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
                    <Typography variant="h6" style={{ fontSize: '25px !important' }}> Pricing Package Details for {method} </Typography>
                    <Typography variant="h6" style={{ marginRight: '-22px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                        <Tooltip title="Close"><Close onClick={openPackagesDialogClose} style={{ cursor: 'pointer' }} /></Tooltip>
                    </Typography>
                </DialogTitle>

                <DialogContent >
                    <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                        <Card >
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
{parseHtml(pricingDetails)}
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                    </DialogContentText>
                </DialogContent>
            </Dialog>


            <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>
            
        </>
    );
}

const PricingPage3 = () => {
    const [PageName, setPageName] = useState('Pricing');
    const [unChangedpricingData, setUnChangedpricingData] = useState([]);
    const [headerContent, setHeaderContent] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    useEffect(() => {
        getData();
    }, []);
    const [globalCentreId, setGlobalCentreId] = React.useState('');
    function getData() {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        console.log("++++++++++++++++++++++++++data.centreId+++++++++++++++++++++++++++")
        console.log(data.centreId)
        setGlobalCentreId(data.centreId);
        getAllPricingData(data.centreId);
      }

    const [pricingData, setPricingData] = useState([]);
    const [commonPricingFeatures, setCommonPricingFeatures] = useState([]);
    const [minimumWidth, setMinimumWidth] = useState(800);

    function getAllPricingData(centreId) {
        axios(CONFIG.MAIN_URL + `/pricingMethods/all`, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("All package Returns");
                console.log(response.data);
                response.data.forEach((item) => {
                    if (item.centreId === centreId) {
                        setPricingData(item.pricingMethods.slice().sort((a, b) => a.orderNumber - b.orderNumber));
                        if (item.commonPricingFeatures !== undefined && item.commonPricingFeatures !== null) {
                            setCommonPricingFeatures(item.commonPricingFeatures);
                        }
                        if (item.pricingMethods !== undefined && item.pricingMethods !== null) {
                            setUnChangedpricingData(item.pricingMethods.slice().sort((a, b) => a.orderNumber - b.orderNumber));
                            if (item.pricingMethods.length > 0) {
                                if (item.pricingMethods.length === 4) {
                                    setMinimumWidth(1100);
                                }
                                if (item.pricingMethods.length === 5) {
                                    setMinimumWidth(1300);
                                }
                                if (item.pricingMethods.length === 6) {
                                    setMinimumWidth(1500);
                                }
                                if (item.pricingMethods.length > 6) {
                                    setMinimumWidth(1600);
                                }
                            }
                        }
                        setHeaderContent(item.pricingPageHeader)
        
              
                    }
                  })  


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const navigate = useNavigate();

    const sortedPricingData = unChangedpricingData.slice().sort((a, b) => a.orderNumber - b.orderNumber);

    const [openImage, setOpenImage] = React.useState(false);
    const [imageHtmlData, setImageHtmlData] = React.useState('');
  
    function handleCloseClick() {
      setOpenImage(false)
    }
  
    setTimeout(() => {
  
      var imgTags = document.getElementsByTagName('img');
      for (var i = 0; i < imgTags.length; i++) {
        imgTags[i].onclick = function () {
          var str = "<img src=\"" + this.src + "\" />";
          setImageHtmlData(str)
          setOpenImage(true);
        };
        imgTags[i].onmouseover = function () {
          this.style.cursor = 'pointer';
        };
      }
    }, 1000);

    return (
        <>
        <RightBarDataForOtherPages PageName={PageName} />
        <Container>
            <CssBaseline />
            <div className='pageTopRC'>
                <Grid container spacing={2}>
                    <Grid container spacing={2} className='pageBodyStyleRC'>
                        <Grid item xs={12}>
                            <div role="presentation" onClick={handleClick}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" to="/login">
                                        <span className='breadcrumbFonts'>Home</span>
                                    </Link>
                                    <Typography color="text.primary"><span className='breadcrumbFonts'>Main menu - {PageName}</span></Typography>
                                </Breadcrumbs>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <a className='goBack' onClick={() => navigate(-1)}><ArrowBack /> Go Back</a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div>
                            {parseHtml(headerContent)}
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Grid container spacing={2}>
                {unChangedpricingData.map((packageItem) => (
                    <Grid item xs={12} sm={6} md={4} key={packageItem.id}>
                        <Package {...packageItem} commonPricingFeatures={commonPricingFeatures} />
                    </Grid>
                ))}
            </Grid>
        </Container>

        <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>
        </>
    );
}

export default PricingPage3;
