import React, { useEffect, useState, useRef } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import CardContent from '@mui/material/CardContent';
import { CONFIG } from '../config'
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info, KeyboardReturnOutlined } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import '../Css/Slider.css';
import Draggable from 'react-draggable';
import useStyle from '../Css/MainCss';
import Delete from '@mui/icons-material/Delete';
import { Editor } from '@tinymce/tinymce-react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import parseHtml from 'html-react-parser';


const useStyles = makeStyles((theme) => ({

    cardRiskManage: {
        width: '100%',
        border: '1px solid black'
    },


}));

const SuperAdminFooterDesign = () => {

    const classe = useStyle();
    const classes = useStyles();
    const editorRef = useRef(null);

    const [footerDesign, setFooterDesignDesign] = useState('')

    const [footerDesignId, setFooterDesignId] = useState('')

    React.useEffect(() => {
        getTemplates()
        getHtmlData()
    }, [])



    const [tinyMCETemplateList, setTinyMCETemplateList] = React.useState()

    var templateArr = [];
    function getTemplateHtml(fileName) {
        axios('https://www.daytaem.co.uk/readFile.php?q=' + fileName, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(function (res) {
                var tmpA = fileName.split(".")
                var templateObj = {};
                templateObj["title"] = tmpA[0].replace("RST_", "");
                templateObj["description"] = "Risk System - Template ";
                templateObj["content"] = res.data;
                templateArr.push(templateObj);
                setTinyMCETemplateList(templateArr);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    function getTemplates() {
        var filesArr = "";
        axios('https://www.daytaem.co.uk/getFile.php?q=aaaaaaa', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(function (response) {
                console.log(response);
                var resData = response.data;
                var arrTmp = resData.split("@@");
                var filesStr = arrTmp[1];

                filesArr = filesStr.split("]");
                var k = 1;

                var templateHtmlArr = [];
                for (var i = 1; i < filesArr.length; i++) {
                    if (filesArr[i] != "") {
                        if (filesArr[i].slice(0, 3) === "RST") {
                            var fileName = filesArr[i];
                            getTemplateHtml(fileName)
                        }
                    }

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function getHtmlData() {
        axios(CONFIG.MAIN_URL + `/loginPageFooter/all`, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("All Collections Returns");
                console.log(response.data);
                if (response.data.length != 0) {
                    setFooterDesignId(response.data[0].id)
                    setFooterDesignDesign(response.data[0].pageContent);
                    editorRef.current.setContent(response.data[0].pageContent);
                } else {
                    setFooterDesignId('')
                    setFooterDesignDesign('');
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function saveFunction() {
        var obj = {}
        if (footerDesignId === '') {

        } else {
            obj["id"] = footerDesignId
        }
        obj["pageContent"] = editorRef.current.getContent();

        var sendJson = JSON.stringify(obj);

        console.log(sendJson)

        axios(CONFIG.MAIN_URL + '/loginPageFooter/save', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: sendJson
        })
            .then(res => {
                getHtmlData()
                if (footerDesignId === '') {
                    alert(" Saved Successfully")
                } else {
                    alert("Updated Successfully")
                }

            })
            .catch(function (error) {
                alert('Error Updating');
                console.log(error);
            });

    }

    function deleteFunction() {
        var obj = {}
        obj["id"] = footerDesignId
        obj["pageContent"] = editorRef.current.getContent();

        var sendJson = JSON.stringify(obj);

        console.log(sendJson)

        axios(CONFIG.MAIN_URL + '/loginPageFooter/delete', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: sendJson
        })
            .then(res => {
                getHtmlData()
                alert("Deleted Successfully")
                console.log(res.data);


            })
            .catch(function (error) {
                alert('Error Updating the collection');
                console.log(error);
            });
    }



    const [openImage, setOpenImage] = React.useState(false);
    const [imageHtmlData, setImageHtmlData] = React.useState('');
  
    function handleCloseClick() {
      setOpenImage(false)
    }
  
    function imagePreviw(url) {
          var str = "<img src=\"" + url + "\" />";
          setImageHtmlData(str)
          setOpenImage(true);
        
    }   


    return (
        <>
            <Card className={classes.cardRiskManage} >
                <CardContent>
                    <Card >
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                    <Info />
                                </Avatar>
                            }
                            className={classe.commonMainTableHead}
                            title={'Footer Design'}
                        />
                        <CardContent>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Editor
                                        apiKey='ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj'
                                        tinymceScriptSrc={'https://cdn.tiny.cloud/1/ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj/tinymce/6/tinymce.min.js'}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue={footerDesign}
                                        init={{
                                            referrer_policy: 'origin',
                                            height: 300,
                                            menubar: true,
                                            placeholder: 'Footer Design',
                                            plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media mediaembed template codesample table advtable charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help charmap quickbars emoticons casechange formatpainter pageembed hr fullpage advcode export permanentpen powerpaste',
                                            imagetools_cors_hosts: ['picsum.photos'],
                                            menubar: 'file edit view insert format tools table help',
                                            toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | casechange forecolor backcolor formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview print | pageembed insertfile image media template tokens link anchor codesample | ltr rtl hr fullpage export permanentpen configurepermanentpen',
                                            //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                                            export_image_proxy: 'proxy.php',
                                            toolbar_sticky: true,
                                            autosave_ask_before_unload: true,
                                            autosave_interval: '30s',
                                            autosave_prefix: '{path}{query}-{id}-',
                                            autosave_restore_when_empty: false,
                                            autosave_retention: '2m',
                                            setup: (editor) => {
                                                editor.on('init', function (e) {
                                                    var content = unescape(footerDesign);
                                                    editor.setContent(content);
                                                    editor.dom.bind(editor.getBody(), 'click', function (e) {
                                                        var target = e.target;
                                                
                                                        // Check if the clicked element is an image
                                                        if (target.nodeName.toLowerCase() === 'img') {
                                                          // Your onclick function logic here
                                                          imagePreviw(target.src);  // Replace this with your actual onclick function
                                                        }
                                                      });
                                                });

                                            },


                                            image_title: true,
                                            /* enable automatic uploads of images represented by blob or data URIs*/
                                            automatic_uploads: true,
                                            /*Here we add custom filepicker only to Image dialog*/
                                            file_picker_types: 'image',

                                            /* and here's our custom image picker*/
                                            file_picker_callback: function (cb, value, meta) {
                                                var input = document.createElement('input');
                                                input.setAttribute('type', 'file');
                                                input.setAttribute('accept', 'image/*');

                                                input.onchange = function () {
                                                    var file = this.files[0];
                                                    var reader = new FileReader();

                                                    reader.onload = function () {
                                                        /*
                                                        Note: Now we need to register the blob in TinyMCEs image blob
                                                        registry. In the next release this part hopefully won't be
                                                        necessary, as we are looking to handle it internally.
                                                        */
                                                        var id = 'blobid' + (new Date()).getTime();

                                                        var blobCache = editorRef.current.editorUpload.blobCache;
                                                        //var blobCache = editorRef.current.activeEditor.editorUpload.blobCache;
                                                        var base64 = reader.result.split(',')[1];

                                                        var blobInfo = blobCache.create(id, file, base64);
                                                        blobCache.add(blobInfo);

                                                        /* call the callback and populate the Title field with the file name */
                                                        cb(blobInfo.blobUri(), { title: file.name });
                                                    };

                                                    reader.readAsDataURL(file);
                                                };

                                                input.click();
                                            },

                                            images_upload_handler: function (blobInfo, success, failure) {
                                                /*  
                                                var xhr, formData;
                                      
                                                  xhr = new XMLHttpRequest();
                                                  xhr.withCredentials = false;
                                                  xhr.open('POST', 'actionContent/getImage');
                                                  xhr.onerror = function() {
                                                      //$('#wait').hide();
                                                  };
                                                  xhr.onload = function () {
                                                      var json;
                                      
                                                      if (xhr.status != 200) {
                                                          failure('HTTP Error: ' + xhr.status);
                                                          return;
                                                      }
                                                      console.log()
                                                      json = xhr.responseText;
                                                      console.log(json);
                                                      success(json);
                                                      //$('#wait').hide();
                                                  };
                                      
                                                  formData = new FormData();
                                                  formData.append('file', blobInfo.blob(), blobInfo.filename());
                                                  //$('#wait').show();
                                                  xhr.send(formData);
                                                  */
                                            },

                                            templates: tinyMCETemplateList,
                                            /*
                                            templates : [
                                              {
                                                title: 'Date modified example',
                                                description: 'Adds a timestamp indicating the last time the document modified.',
                                                content: '<p>Last Modified: This will be replaced with the date modified.</time></p>'
                                              },
                                              {
                                                title: 'Replace values example',
                                                description: 'These values will be replaced when the template is inserted into the editor content.',
                                                content: '<p>Name: StaffID: </p>'
                                              }
                                            ],
                                            */
                                            referrer_policy: 'origin',

                                            template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                                            template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                                            image_caption: true,
                                            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                            noneditable_noneditable_class: 'mceNonEditable',
                                            toolbar_mode: 'sliding',
                                            contextmenu: 'link image imagetools table',
                                            //content_css: useDarkMode ? 'dark' : 'default',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'





                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <div style={{ float: 'right', gap: '5px' }}>
                                        {
                                            footerDesignId === '' ?
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => saveFunction()}>
                                                        save</Button>

                                                </>

                                                :
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => saveFunction()}>
                                                        update</Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => deleteFunction()}>
                                                        delete</Button>
                                                </>

                                        }

                                    </div>

                                </Grid>


                            </Grid>
                        </CardContent>
                    </Card>
                </CardContent>
            </Card>

            <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>
        </>
    )
}

export default SuperAdminFooterDesign
