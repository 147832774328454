import React, { useEffect, useState, useRef } from 'react'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import { Grid, Input, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { ArrowBack, Delete, DetailsSharp, Javascript, Label } from '@mui/icons-material';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import useStyle from '../Css/MainCss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import { CONFIG } from '../config'
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ClockLoader } from 'react-spinners';
import EvaluateComponent1 from '../Components/EvaluateComponent1';
import EvaluateComponent from '../Components/EvaluateComponent';
import SavePageAs from '../Components/SavePageAs';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Close } from '@mui/icons-material';
import Draggable from 'react-draggable';
import RiskRegisterComponent from '../Components/RiskRegisterComponent';
import { green, red } from '@mui/material/colors';
import RiskHierarchyNew from '../Components/RiskHierarchyNew';
import AddQuestionComponent from '../Components/AddQuestionComponent';
import { DataGrid } from '@mui/x-data-grid';
import DMGRiskGptComponent from '../Components/DMGRiskGptComponentd2';
import "../Css/DmgRiskGpt.css";
import { Chip, Divider } from '@mui/material';
import parseHtml from 'html-react-parser';
import FinaliseRegisterComponent from '../Components/FinaliseRegisterComponent';
import Slide from '@mui/material/Slide';
import CircularWithValueLabel from '../Components/ProgressTimer';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


const useStyles = makeStyles((theme) => ({
    right: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    pageTop: {
        marginTop: '115px',
    },
    pageBodyStyle: {

        paddingTop: '12px',
    },

}));

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: '13px'
    },
}));


function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const DmgRiskGPTTest = () => {

    const classes = useStyles();
    const classe = useStyle();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);



    const [text1, setText1] = React.useState('The OWASP Top 10 is a standard awareness document for developers and web application security. It represents a broad consensus about the most critical security risks to web applications.');
    const [text1Value, setText1Value] = React.useState('');
    const handleChangeText1 = (e) => {
        setText1(e.target.value);
    };
    const handleChangeText1Value = (e) => {
        setText1Value(e.target.value);
    };

    const [text2, setText2] = React.useState('Can you analyse the main risks in each document and list the mitigation processes');
    const [text2Value, setText2Value] = React.useState('');
    const handleChangeText2 = (e) => {
        setText2(e.target.value);
    };
    const handleChangeText2Value = (e) => {
        setText2Value(e.target.value);
    };

    const [text3, setText3] = React.useState('In The light of this analysis can you amend the following json schema to incorporate new sections : RiskDescription; ImpactDescription; Mitigation');
    const [text3Value, setText3Value] = React.useState('');
    const handleChangeText3 = (e) => {
        setText3(e.target.value);
    };
    const handleChangeText3Value = (e) => {
        setText3Value(e.target.value);
    };



    function sentToChatGtp(value, no) {
        var obj = {}
        obj["userId"] = "R01"
        obj["createdBy"] = "R01admin"

        var objArr = [];

        var obj1 = {}
        obj1["messageId"] = "001";
        obj1["message"] = value;
        objArr.push(obj1);
        obj["chats"] = objArr
        var sendJson = JSON.stringify(obj)
        setLoading(true)

        axios(CONFIG.MAIN_URL + '/openai/advancechat', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: sendJson,
        })
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                if (no === 1) {
                    setText1Value(response.data.answer)
                }
                if (no === 2) {
                    setText2Value(response.data.answer)
                }
                if (no === 3) {
                    setText3Value(response.data.answer)
                }
                setLoading(false)

            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
                alert("Error saving Question -:" + error);
            });

    }




    return (
        <>
            <div >
                <Grid container spacing={2}>
                    <Grid container spacing={2} className={classes.pageBodyStyle}>

                        <Grid item xs={12}>
                            <div role="presentation" onClick={handleClick}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" to="/login">
                                        <span className='breadcrumbsStyle'>Home</span>
                                    </Link>
                                </Breadcrumbs>
                            </div>
                        </Grid>

                        <Grid item xs={10}>
                            <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    value={text1}
                                    id='txtText1'
                                    onChange={handleChangeText1}
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    sentToChatGtp(text1, 1)
                                }}
                                style={{ height: '50px', }}>
                                Send
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                component="form"
                                sx={{ "& .MuiTextField-root": { width: "90%" } }}
                                validate
                                autoComplete="off"
                            >
                                <div>
                                    <textarea
                                        name="description"
                                        value={text1Value}
                                        onChange={handleChangeText1Value}
                                        rows={6}
                                        style={{ width: "90%" }}
                                    />
                                </div>
                            </Box>
                        </Grid>





                        <Grid item xs={10}>
                            <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    value={text2}
                                    id='txtText2'
                                    onChange={handleChangeText2}
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    sentToChatGtp(text2, 2)
                                }}
                                style={{ height: '50px', }}>
                                Send
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                component="form"
                                sx={{ "& .MuiTextField-root": { width: "90%" } }}
                                validate
                                autoComplete="off"
                            >
                                <div>
                                    <textarea
                                        name="description"
                                        value={text2Value}
                                        onChange={handleChangeText2Value}
                                        rows={6}
                                        style={{ width: "90%" }}
                                    />
                                </div>
                            </Box>
                        </Grid>


                        <Grid item xs={10}>
                            <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    value={text3}
                                    id='txtText3'
                                    onChange={handleChangeText3}
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    sentToChatGtp(text3, 3)
                                }}
                                style={{ height: '50px', }}>
                                Send
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                component="form"
                                sx={{ "& .MuiTextField-root": { width: "90%" } }}
                                validate
                                autoComplete="off"
                            >
                                <div>
                                    <textarea
                                        name="description"
                                        value={text3Value}
                                        onChange={handleChangeText3Value}
                                        rows={6}
                                        style={{ width: "90%" }}
                                    />
                                </div>
                            </Box>
                        </Grid>







                    </Grid>
                </Grid>
            </div>




            <Dialog
                open={loading}
                onClose={false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
                    <span style={{ textAlign: 'center' }}><br></br>
                        Please Wait .......</span>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <CircularWithValueLabel
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>




        </>
    )
}

export default DmgRiskGPTTest