import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import CardHeader from '@mui/material/CardHeader';
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import axios from 'axios'
import { CONFIG } from '../config';
import { More } from '@mui/icons-material';

import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import { styled } from '@mui/material/styles';
import parseHtml from 'html-react-parser';
import "../Css/blog.css";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const BlogComponentClient = () => {

  const classes = useStyle();

  useEffect(() => {
    getMainArticle();
    getArticles();
  }, []);


  function dateString(dayStr){
    var dateRtn = "";
    var tdArr = dayStr.split(".")
    dateRtn = tdArr[0].replaceAll("T", " " ) 
    return dateRtn;
  }


  const [mainArticle, setMainArticle] = React.useState({});
  const [mainArticleBy, setMainArticleBy] = React.useState('');
  const [mainArticleDate, setMainArticleDate] = React.useState('');
  
  function getMainArticle() {
    axios(CONFIG.EXPERT_SERVER_URL + '/blogMainArticle/allCurrent', {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("++++Get Mail Article +++++++++++++++++");
        console.log(response.data);
        response.data.forEach((item, index) => {
          if (item.domainName === window.location.hostname || ('www.' + item.domainName) === window.location.hostname) {
            setMainArticle(item);
            setMainArticleBy(item.createdUserName);
            setMainArticleDate(dateString(item.updatedDate + ""));
          }
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const [subArticles, setSubArticles] = React.useState([]);

  function compare(a, b) {
    if (a.orderNumber < b.orderNumber) {
      return -1;
    }
    if (a.orderNumber > b.orderNumber) {
      return 1;
    }
    return 0;
  }

  function getArticles() {
    axios(CONFIG.EXPERT_SERVER_URL + `/blogArticle/allCurrent`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("++++Sub Atricles  +++++++++++++++++");
        console.log(response.data);
        var arr = [];
        response.data.forEach((item, index) => {
          if (item.domainName === window.location.hostname || ('www.' + item.domainName) === window.location.hostname) {
            arr.push(item)
          }
        })
        if (arr.length > 0){
          var tArr = arr.sort(compare);
          setSubArticles(tArr);
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  setTimeout(() => {

    var imgTags = document.getElementsByTagName('img');
    for (var i = 0; i < imgTags.length; i++) {
      imgTags[i].onclick = function () {
        var str = "<img src=\"" + this.src + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      };
      imgTags[i].onmouseover = function () {
        this.style.cursor = 'pointer';
      };
    }
  }, 1000);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <span style={{ fontFamily: 'times', lineHeight: '75px', color: '#466fa5', fontSize: '45px' }}>{mainArticle.title}</span>
        </Grid>
        <Grid item class="mainGrid2000">
          <img src={mainArticle.imageUrl} style={{ width: '100%', height: '380px' }} />
        </Grid>
        <Grid item class="mainGrid2000">
          <div style={{ textAlign: 'left', width: '98%', marginTop: '0px', color: '#1d215f', fontFamily: 'Sans-serif', height: '30px' }}>
            {'By '} {mainArticleBy} {' On'} {mainArticleDate}
          </div>
          <div style={{ textAlign: 'center', width: '98%', marginTop: '0px', color: '#fff', fontFamily: 'Sans-serif', height: '380px', overflow: 'scroll' }}>
          {parseHtml(" " + mainArticle.content)}
            {/** 
            <span style={{ fontSize: '20px', paddingBottom: '10px', fontWeight: '700', color: '#1d215f', fontFamily: 'Helvetica' }}>In uncertainty lies opportunity; in a well-crafted risk management system lies the map to navigate it</span>
            <hr style={{ border: '0.5px solid #bbb2b2', width: '300px' }}></hr>
            <span style={{ fontSize: '14px', fontWeight: '400', color: '#324f74', lineHeight: '23px', marginTop: '30px' }}>
              A Records Management (RM) system is a digital platform that organizes and controls an
              organization's documents and information throughout their lifecycle. It ensures compliance with
              regulations, facilitates efficient retrieval, and enhances data security. By automating
              record-keeping processes, it improves operational efficiency, reduces the risk of errors, and
              supports better decision-making. The RM system is crucial for maintaining a well-organized and
              compliant information management framework within an organization.
            </span>
            **/}
            <br></br>
            <span style={{ width: '100%' }}>
              <Link to={`/blogmoreinfor?id=${mainArticle.id}&type=main`}>
              <Button
                variant="outlined"
                startIcon={<More />}
              >
                More info
              </Button>
              </Link>
            </span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ textAlign: 'center', padding: '50px 0 20px 0' }}>
            <span style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '20px', color: '#333', fontWeight: '700', textTransform: 'uppercase' }}>Latest blog posts</span>
            <hr style={{ width: '200px', alignItems: 'center', border: '1px solid #324f74' }} />
          </div>
        </Grid>

        
        {subArticles.map((item) => {
          var commentCount = 0;
          if (item.articleComments != null && item.articleComments.length > 0) {
            commentCount = item.articleComments.length;
          }
          var content = item.content;
          content = content.replaceAll("\\n", ''); 
            return (
        <Grid item class='subGrid2000'>
          <Card sx={{ maxWidth: '100%', height: '477px' }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  {item.createdUserName.substring(0, 1)}
                </Avatar>
              }
              title={item.title}
              subheader={`${dateString(item.updatedDate)} By ${item.createdUserName} `}
            />
            <CardMedia
              component="img"
              height='150'
              width='100%'
              image={item.imageUrl}
              alt={item.createdUserName}
            />
            <CardContent style={{height: '209px', overflow: 'scroll'}}>
              <Typography variant="body2" color="text.secondary">
              {parseHtml(" " + content)}
              </Typography>
            </CardContent>
            <CardActions>
            <Grid container spacing={2}>
        <Grid item xs={6}>
          <Link to={`/blogmoreinfor?id=${item.id}&type=sub`}>
            <Button
                variant="outlined"
                startIcon={<More />}
              >
                More info
              </Button>
          </Link>    
         </Grid>
         <Grid item xs={6}>
         <span style={{ float: 'right', color: '#333' }}>Comments ({commentCount})</span>
         </Grid>     
         </Grid>
      </CardActions>
          </Card>




        </Grid>
        )
      })}

      </Grid>


      <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>

    </>
  )
}

export default BlogComponentClient