import  React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import NavBar from './NavBar';
import axios from 'axios';
import HomeOutlined from '@mui/icons-material/HomeOutlined';  
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import useStyle from '../Css/MainCss';
import {CONFIG} from '../config'
import { Link } from 'react-router-dom';
import SubMenu from './SubMenu'
import styled from 'styled-components';

const drawerWidth = 260;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: white;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  margin-top:70px;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '-100' : '0%')};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const SideBarUpload = ({centreId,userRoleId}) => {

  console.log(centreId)
  const classes = useStyle();

  const [menuItem, setMenuItem] = useState([]);

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    getData()
    
    }, [])

    function getData () {
      let data = sessionStorage.getItem('sessionstorage');
      data = JSON.parse(data);
      getSideBarData(data.userRoleId,data.centreId)
      console.log(data.userRoleId)
  } 

  function getSideBarData(rId,cId){
    console.log(userRoleId)
    if(rId === 'staff'){
        rId = 'ADMIN'
      console.log(rId)     
    }
   
    var data = '{allMenuRoleList(centreId:"'+cId+'",iamRole:"'+rId+'"){menuRoleId,centerMenuName,centerMenuOrderNum,centreId,createdDate,updatedDate,isActive,menu{menuUri,menuHtmlId},subMenuList{subMenuRoleId,centerMenuName,centerMenuOrderNum,centreId,createdDate,updatedDate,isActive,menu{menuUri,menuHtmlId}}}}';
    axios(CONFIG.MAIN_URL+`/graphql`,{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain', 
        },
        data:data
        
      })     
      .then(function (response) {
     setMenuItem(response.data.data.allMenuRoleList)
  
      })
      .catch(function (error) {
      console.log(error);
      });
    console.log()
    
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      
      <NavBar/>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />       
           <SidebarNav sidebar={sidebar}>
              <SidebarWrap>
              
                {menuItem.map((item, index) => {
                  return <SubMenu item={item} key={index} />;
                })}
              </SidebarWrap>
            </SidebarNav>       
      </Drawer>

    </Box>
  )
}

export default SideBarUpload
