import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { CONFIG} from '../config'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import QuestionerMgtPageItemOthersExpert from '../Components/QuestionerMgtPageItemOthersExpert';
import RightBarDataForUserPages from '../Components/RightBarDataForUserPages';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const useStyles = makeStyles((theme) => ({ 
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageTop:{
    marginTop: '105px',
  },
  pageBodyStyle: {
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '12px',
    marginLeft: '25px',
  },
  root: {
    position: 'relative',
  },
  fontZise10:{
    fontSize: '10px !important',
  },

}));

const QuestionerManagementPageExpert = () => {
  const classes = useStyles();
    const Input = styled('input')({
      display: 'none',
    });

/** Sort abject sort array */
function compare( a, b ) {
  var nameA = a.entity.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.entity.name.toUpperCase(); // ignore upper and lowercase

  if ( nameA < nameB ){
      return -1;
  }
  if ( nameA > nameB ){
      return 1;
  }
  // names must be equal
  return 0;
}


  React.useEffect(() => {
    getData();
  }, []);

  const [golobalCentreId, setGolobalCentreId] = React.useState();
  function getData () {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId); 
    getCentreData(data.centreId);
    getColors();
    console.log(data)
  }

  const [databClr, setDatabClr] = useState([])
    function getColors(){
      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
        method: 'get',
        headers: { 
            'Content-Type': 'text/plain', 
          },     
        })
        .then(function (response) {
          console.log("All colors");
          console.log(response.data);  
          setDatabClr(response.data);         
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    

  const [centreName, setCentreName] = React.useState('');
  function getCentreData(centreId){
    var data = '{getCentreData(centreId:\"' + centreId + '\"){centreId,centreName}}';
    axios(CONFIG.MAIN_URL+'/graphql',{
    method: 'post',
    headers: { 
        'Content-Type': 'text/plain',
      },
    data : data,  
    })
    .then(function (response) {
        console.log("++++++++++++++++Return centreData++++++++++++++++");
        var centreData = response.data.data.getCentreData;
        console.log(centreData);
        setCentreName(centreData.centreName);

    })
    .catch(function (error) {
        console.log("Centre Data Loding error" + error);
    });
  }

  const [preview, setPreview] = React.useState();

  const location = useLocation();
  const collectionUniqueName = location.pathname.split("/")[2];

  const [collectnId, setCollectnId] = useState('');
  const [riskTypeData, setRiskTypeData] = useState([])

  const [quesData, setQuesData] = useState([]);
  const [quesTitle, setQuesTttle] = useState("");
  const [quesDesc, setQuesDesc] = useState("");
  const [documentCreator, setDocumentCreator] = useState('');

  useEffect(() => {
    axios(CONFIG.MAIN_URL+'/questionnaire-expert/all?collectionUniqueName='+ collectionUniqueName,{
      method: 'get',
    })
    .then(function (response) {
      console.log("Return Collection Data fromcollectionUniqueName");
      console.log(response.data);

      setCollectnId(response.data[0].id);
      setDocumentCreator(response.data[0].documentCreator);
      setQuesTttle(response.data[0].title);
      setQuesDesc(response.data[0].description);
      setPreview(response.data[0].imageUrl);
      var tmpRiskArr = [];
      if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
        tmpRiskArr = response.data[0].risks; 
      }
      setRiskTypeData(tmpRiskArr);
      getAllQuestions(tmpRiskArr);
    })
    .catch(function (error) {
      console.log(error);
    });
  },[])

  /** Get all question from the collection and put in to attay */
  var allQuesArray = [];
  function getAllQuestions(riskArray){
    allQuesArray = [];
    riskArray.forEach((item)=>{
      if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
        item.riskTypes.forEach((item1)=>{
          if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
            item1.questions.forEach((item2)=>{
              allQuesArray.push(item2);
            })  
          }
          if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
            getAllQuestionsInSubRisks(item1.subRisks);
          }
        });
      }
    });
    if (allQuesArray.length > 0){
      var quesArray = allQuesArray.sort (compare);
      setQuesData(allQuesArray);
    }
  }

  function getAllQuestionsInSubRisks(riskArray){
    riskArray.forEach((item)=>{
      if (item.questions != undefined && item.questions != null && item.questions.length > 0){
        item.questions.forEach((item1)=>{
          allQuesArray.push(item1);
        })  
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
        getAllQuestionsInSubRisks(item.subRisks);
      }
    });  
  }



const [PageName, setPageName] = useState('View Expert Questionnaire Collection')
  return (
    <>
        {CONFIG.EXPERT_LOGIN?
          <RightBarDataForOtherPages PageName={PageName}/> 
        :
          <RightBarDataForUserPages PageName={PageName} CentreName={centreName}/> 
        }
        <div className={classes.pageTop}>
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.pageBodyStyle}>

                    <Grid item xs={12}>
                    <div role="presentation" onClick={handleClick}>
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/login">
                          Home
                        </Link>
                        <Typography color="text.primary">Expert Questionnaire Collection</Typography>
                      </Breadcrumbs>
                    </div>
                    </Grid>
                    {quesData.length > 0 && 
                      <>
                         <QuestionerMgtPageItemOthersExpert 
                            riskTypeData={riskTypeData}
                            quesData={quesData}
                            classes={classes} 
                            collectnId={collectnId}
                            collectionUniqueName={collectionUniqueName}
                            databClr={databClr}

                          /> 
                      </>          

                    }
                </Grid>
            </Grid>  
        </div>
    </>
  )
}

export default QuestionerManagementPageExpert