import React, { Suspense, useEffect, useState } from 'react'
import { Collapse, CssBaseline, makeStyles } from '@material-ui/core';
//import Footer from '../Components/Footer';
import Button from '@mui/material/Button';
import useStyle from '../Css/MainCss';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { CONFIG } from '../config';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { sendAuthorizationRequest } from "../actions/sign-in";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import "../Css/Slider.css"
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import parseHtml from 'html-react-parser';


// Import Swiper styles

import 'swiper/swiper-bundle.css';
import Draggable from 'react-draggable';
import axios from 'axios'
import Typography from '@mui/material/Typography';
import '../Css/MainPge.css'
import { CircularProgress } from '@mui/material';



const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px'
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const MainPage = () => {
  const classes = useStyle();
  const [checked, setChecked] = useState(false);

  const [loggedDomainName, setLoggedDomainName] = useState('');

  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    setLoggedDomainName(window.location.hostname);
    //setSessionDataForLogoutUrl();
    setChecked(true);
    getAllCollectionData();
    getLoginPageDetails();

    const timer = setTimeout(() => {
      setShowPreloader(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const handleRegisterBtnClick = () => {
    let authorizeRequest = `${CONFIG.ADMIN_REGISTRATION_URL}`;
    window.location.href = authorizeRequest;
  };

  

  const handleLoginBtnClick = (volunterFlag) => {

    if (trialCentreId != '' && trialCentreName != '') {
      sendAuthorizationRequest(volunterFlag, trialCentreId, trialCentreName.replaceAll(" ", "_"));
    } else {
      sendAuthorizationRequest(volunterFlag);
    }
  };


  const alertFunction = () => {
    alert("Please register as an Administrator")
  };

  const [open, setOpen] = React.useState(false);

  const [dialogHtml, setDialogHtml] = React.useState('');

  function handleClickOpen(des) {

    setDialogHtml(des)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const dialogHtmlData = (
    // <div dangerouslySetInnerHTML={{ dialogHtml}} />
    <div dangerouslySetInnerHTML={{ __html: dialogHtml }} />

  )


  const [collectionNames, setCollectionNames] = React.useState([]);

  function getAllCollectionData() {
    axios(CONFIG.EXPERT_SERVER_URL + `/availableCollections/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("All Collections Returns");
        console.log(response.data);
        setCollectionNames(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [pageTopHtml, setPageTopHtml] = React.useState('');
  const [trialCentreId, setTrialCentreId] = React.useState('');
  const [trialCentreName, setTrialCentreName] = React.useState('');
  const [displayPageContent, setDisplayPageContent] = React.useState(false);
  const [pageBackgroundUrl, setPageBackgroundUrl] = React.useState('');
  const [pageBackgroundColor, setPageBackgroundColor] = React.useState('');

  const [needToDisplayInLoginPage, setNeedToDisplayInLoginPage] = React.useState(false);
  const [typeOfDisplay, setTypeOfDisplay] = React.useState('carousel');
  const [whereToDisplay, setWhereToDisplay] = React.useState('top');

  const [alignContent, setAlignContent] = React.useState('left');
  const [backgroundColorSub, setBackgroundColorSub] = React.useState('#9155FD');
  const [buttonBackground, setButtonBackground] = React.useState('outlined');
  const [fontColor, setFontColor] = React.useState('#9155FD');

  const [subPagesArray, setSubPagesArray] = React.useState([]);

  function compare(a, b) {
    if (a.orderNumber < b.orderNumber) {
      return -1;
    }
    if (a.orderNumber > b.orderNumber) {
      return 1;
    }
    return 0;
  }

  function getLoginPageDetails() {
    axios(CONFIG.EXPERT_SERVER_URL + `/loginPageContent/allCurrent`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("++++Login Page Details +++++++++++++++++");
        console.log(response.data);
        response.data.forEach((item, index) => {
          if (item.domainName === window.location.hostname || ('www.' + item.domainName) === window.location.hostname) {
            if (item.pageContent != undefined && item.pageContent != null) {
              //alert(item.pageContent)
              var tArr = item.pageContent.split("<body>");
              if (tArr.length > 1) {
                var tArr1 = tArr[1].split("</body>");
                //alert(tArr1[0])
                setPageTopHtml(tArr1[0].replaceAll("\\n", ''));
              } else {
                if (item.pageContent != "") {
                  setPageTopHtml(item.pageContent.replaceAll("\\n", ''));
                }
              }

            }
            var baseVersionId = "";

            if (item.baseVersionId != undefined && item.baseVersionId != null) {
              baseVersionId = item.baseVersionId;
            }

            if (item.backgroundUrl != undefined && item.backgroundUrl != null) {
              setPageBackgroundUrl(item.backgroundUrl);
            }
            if (item.backgroundColor != undefined && item.backgroundColor != null) {
              setPageBackgroundColor(item.backgroundColor);
            }
            if (item.assignedCentreId != undefined && item.assignedCentreId != null) {
              setTrialCentreId(item.assignedCentreId);
            }
            if (item.assignedCentreName != undefined && item.assignedCentreName != null) {
              setTrialCentreName(item.assignedCentreName);
            }
            if (item.subPageFeatures != undefined && item.subPageFeatures != null) {
              if (item.subPageFeatures.needToDisplayInLoginPage != undefined && item.subPageFeatures.needToDisplayInLoginPage != null && item.subPageFeatures.needToDisplayInLoginPage === "yes") {
                var tmpNeedToDisplayInLoginPage = true;
                var tmpTypeOfDisplay = "carousel";
                var tmpWhereToDisplay = "top";

                var tmpAlignContent = "left";
                var tmpBackgroundColorSub = "#9155FD";
                var tmpButtonBackground = "outlined";
                var tmpFontColor = "#9155FD";

                if (item.subPageFeatures.typeOfDisplay != undefined && item.subPageFeatures.typeOfDisplay != null && item.subPageFeatures.typeOfDisplay != "") {
                  tmpTypeOfDisplay = item.subPageFeatures.typeOfDisplay;
                }
                if (item.subPageFeatures.whereToDisplay != undefined && item.subPageFeatures.whereToDisplay != null && item.subPageFeatures.whereToDisplay != "") {
                  tmpWhereToDisplay = item.subPageFeatures.whereToDisplay;
                }
                if (item.subPageFeatures.alignContent != undefined && item.subPageFeatures.alignContent != null && item.subPageFeatures.alignContent != "") {
                  tmpAlignContent = item.subPageFeatures.alignContent;
                }
                if (item.subPageFeatures.backgroundColor != undefined && item.subPageFeatures.backgroundColor != null && item.subPageFeatures.backgroundColor != "") {
                  tmpBackgroundColorSub = item.subPageFeatures.backgroundColor;
                }
                if (item.subPageFeatures.buttonBackground != undefined && item.subPageFeatures.buttonBackground != null && item.subPageFeatures.buttonBackground != "") {
                  tmpButtonBackground = item.subPageFeatures.buttonBackground;
                }
                if (item.subPageFeatures.fontColor != undefined && item.subPageFeatures.fontColor != null && item.subPageFeatures.fontColor != "") {
                  tmpFontColor = item.subPageFeatures.fontColor;
                }

                var hostName = window.location.hostname;
                var hostNameArr = hostName.split('www.')
                if (hostNameArr.length > 1) {
                  hostName = hostNameArr[1]  
                }else{
                  hostName = hostNameArr[0] 
                }
                axios(CONFIG.EXPERT_SERVER_URL + `/loginPageSubPage/filter?parentDomainName=` + hostName, {
                  method: 'get',
                  headers: {
                    'Content-Type': 'text/plain',
                  },
                })
                  .then(function (res) {
                    console.log("++++Login Page Sub Pages Details +++++++++++++++++");
                    console.log(res.data);
                    var tmpSubPagesArray = [];
                    res.data.forEach((itemX, indexX) => {

                      if (baseVersionId === itemX.parentId) {
                        itemX.pages.forEach((data, indexY) => {
                          var canTake = false;
                          if (data.isHide != undefined && data.isHide != null) {
                            if (data.isHide === 'no'){
                              canTake = true;
                            }
                          }else{
                            canTake = true;
                          }
                          if (canTake){
                            var tObj = {};
                            tObj["keyCode"] = data.keyCode;
                            tObj["pageName"] = data.pageName;
                            if (data.orderNumber != undefined && data.orderNumber != null && data.orderNumber > 0) {
                              tObj["orderNumber"] = data.orderNumber;
                            } else {
                              tObj["orderNumber"] = indexY + 1;
                            }
                            tmpSubPagesArray.push(tObj);
                          }
                        })
                        if (tmpSubPagesArray.length > 0) {
                          setNeedToDisplayInLoginPage(tmpNeedToDisplayInLoginPage);
                          setTypeOfDisplay(tmpTypeOfDisplay);
                          setWhereToDisplay(tmpWhereToDisplay);
                          setAlignContent(tmpAlignContent);
                          setBackgroundColorSub(tmpBackgroundColorSub);
                          setButtonBackground(tmpButtonBackground);
                          setFontColor(tmpFontColor);
                          tmpSubPagesArray.sort(compare);
                          setSubPagesArray(tmpSubPagesArray);
                        }

                      }
                    })

                  })
                  .catch(function (error) {
                    console.log(error);
                  });


              }
            }


          }
        })
        setDisplayPageContent(true);
      })
      .catch(function (error) {
        setDisplayPageContent(true);
        console.log(error);
      });
  }

  const slides = Array.from({ length: 1000 }).map(
    (el, index) => `Slide ${index + 1}`
  );



  const text = "This is some text with \n line breaks.";

  const formattedText = text.replace(/\n/g, '<br>');

  console.log(formattedText)


  // const shortDescri = (

  //   <React.Fragment>
  //       {}
  //     </React.Fragment>
  // )

  function shortDescri(ab) {

    const ll = ab.replace(/\n/g, '<br />')
    return (
      <React.Fragment>
        <div dangerouslySetInnerHTML={{ __html: ll }}></div>
      </React.Fragment>
    )
  }

  const subPagesDisplay = (
    <>
      {needToDisplayInLoginPage && whereToDisplay === 'top' && subPagesArray.length > 0 ?
        <Grid item xs={12} style={{ paddingTop: '0px', display: 'flex', justifyContent: `${alignContent}`  }}>
          {typeOfDisplay === 'carousel' ?
            <>
              <div style={{ marginTop: '5px', backgroundColor: '#f4f1f9ad', padding: '3px', borderRadius: '6px', width: '100%' }}>
                <div style={{ border: '1px solid' + `${backgroundColorSub}` + '', padding: '3px', borderRadius: '6px' }}>
                  <div >
                    <h5 style={{ color: "white", fontSize: '18px', fontWeight: '400', textAlign: 'center', letterSpacing: '2px', marginTop: '', color: `${fontColor}` }} >Available Pages</h5>
                  </div>
                  {
                    subPagesArray.length === 1 ?
                      <Grid container spacing={2}>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6} >
                          <div style={{ marginTop: "-5px", color: 'white' }}>
                            <Swiper
                              modules={[Navigation, Pagination, Scrollbar, A11y]}

                              slidesPerView={1}
                              navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                              }}

                              onSwiper={(swiper) => console.log(swiper)}
                              onSlideChange={() => console.log('slide change')}
                              style={{ textAlign: 'center', spacing: '10px' }}

                              pagination={{
                                clickable: true,
                                style: { marginTop: '50% !important' }

                              }}
                            >



                              <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                              </div>
                              <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                              </div>
                              {subPagesArray.map((slideContent, index) => (
                                <SwiperSlide key={slideContent.keyCode} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                  <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: `${backgroundColorSub}`, boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '5%', marginBottom: '3%' }}>
                                    <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                      <BootstrapTooltip title={shortDescri(slideContent.pageName)}>
                                        <div style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><a href='/domainendpoint?keyCode={slideContent.keyCode}' target='_blank'><span style={{color: `${fontColor}` }}>{slideContent.pageName}</span></a>

                                        </div>
                                      </BootstrapTooltip>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>

                          </div>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid> :

                      subPagesArray.length <= 2 ?
                        <Grid container spacing={2}>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={6} >

                            <div style={{ marginTop: "-5px", color: 'white' }}>
                              <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}

                                slidesPerView={2}
                                navigation={{
                                  prevEl: '.swiper-button-prev',
                                  nextEl: '.swiper-button-next',
                                }}

                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}
                                style={{ textAlign: 'center', spacing: '10px' }}

                                pagination={{
                                  clickable: true,
                                  style: { marginTop: '50% !important' }

                                }}
                              >



                                <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                  <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                </div>
                                <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                  <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                </div>
                                {subPagesArray.map((slideContent, index) => (
                                  <SwiperSlide key={slideContent.keyCode} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: `${backgroundColorSub}`, boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '6%', marginBottom: '3%' }}>
                                      <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        <BootstrapTooltip title={shortDescri(slideContent.pageName)}>


                                          <div style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><a href='/domainendpoint?keyCode={slideContent.keyCode}' target='_blank'><span style={{color: `${fontColor}` }}>{slideContent.pageName}</span></a>

                                          </div>
                                        </BootstrapTooltip>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>

                            </div>
                          </Grid>
                          <Grid item xs={2}></Grid>
                        </Grid> :

                        subPagesArray.length <= 3 ?

                          <Grid container spacing={2}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>

                              <div style={{ marginTop: "-5px", color: 'white' }}>
                                <Swiper
                                  modules={[Navigation, Pagination, Scrollbar, A11y]}

                                  slidesPerView={3}
                                  navigation={{
                                    prevEl: '.swiper-button-prev',
                                    nextEl: '.swiper-button-next',
                                  }}

                                  onSwiper={(swiper) => console.log(swiper)}
                                  onSlideChange={() => console.log('slide change')}
                                  style={{ textAlign: 'center', spacing: '10px' }}

                                  pagination={{
                                    clickable: true,
                                    style: { marginTop: '50% !important' }

                                  }}
                                // scrollbar={{ draggable: true }}

                                >



                                  <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                    <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                  </div>
                                  <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                    <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                  </div>
                                  {subPagesArray.map((slideContent, index) => (
                                    <SwiperSlide key={slideContent.keyCode} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px' }}>
                                      <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: `${backgroundColorSub}`, boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '-3%' }}>
                                        <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                          <BootstrapTooltip title={shortDescri(slideContent.pageName)}>
                                            <div style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><a href='/domainendpoint?keyCode={slideContent.keyCode}' target='_blank'><span style={{color: `${fontColor}` }}>{slideContent.pageName}</span></a>

                                            </div>
                                          </BootstrapTooltip>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  ))}
                                </Swiper>

                              </div>
                            </Grid>
                            <Grid item xs={2}></Grid>
                          </Grid>
                          :

                          <Grid container spacing={2}>
                            <Grid item xs={0.5}>

                            </Grid>
                            <Grid item xs={11}>
                              <div style={{ marginTop: "-5px", color: 'white' }}>
                                <Swiper
                                  modules={[Navigation, Pagination, Scrollbar, A11y]}

                                  slidesPerView={4}
                                  navigation={{
                                    prevEl: '.swiper-button-prev',
                                    nextEl: '.swiper-button-next',
                                  }}

                                  onSwiper={(swiper) => console.log(swiper)}
                                  onSlideChange={() => console.log('slide change')}
                                  style={{ textAlign: 'center', spacing: '10px' }}

                                  pagination={{
                                    clickable: true,
                                    style: { marginTop: '50% !important' }

                                  }}
                                // scrollbar={{ draggable: true }}

                                >



                                  <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                    <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                  </div>
                                  <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                    <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                  </div>
                                  {subPagesArray.map((slideContent, index) => (
                                    <SwiperSlide key={slideContent.keyCode} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px' }}>
                                      <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: `${backgroundColorSub}`, boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '-3%' }}>
                                        <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                      {/** 
                                      <div className='collectionBoxDiv'>
                                        <div className='collectionBox'>
                                          */}
                                          <BootstrapTooltip title={shortDescri(slideContent.pageName)}>
                                            <div style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><a href={`/domainendpoint?keyCode=${slideContent.keyCode}`} target='_blank'><span style={{color: `${fontColor}` }}>{slideContent.pageName}</span></a>

                                            </div>
                                          </BootstrapTooltip>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  ))}
                                </Swiper>

                              </div>
                            </Grid>
                            <Grid item xs={0.5}></Grid>
                          </Grid>
                  }

                </div>
              </div>










            </>
            :
            ''
          }


          {typeOfDisplay === 'textOnly' ?
            <>
              <div className='topDiv'>
                {subPagesArray.map((slideContent, index) => (
                  <>
                    <div style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><a href={`/domainendpoint?keyCode=${slideContent.keyCode}`} target='_blank'><span style={{color: `${fontColor}`}}>{slideContent.pageName}</span></a>

                    </div>
                  </>
                ))}

              </div>
            </>
            :
            ''
          }
          {typeOfDisplay === 'buttonFormat' ?
            <>
              <div className='topDiv'>
                {subPagesArray.map((slideContent, index) => (
                  <>
                    <a href={`/domainendpoint?keyCode=${slideContent.keyCode}`} target='_blank'>
                      {buttonBackground === 'outlined'? 
                      <Button
                        variant={buttonBackground}
                        style={{border: '1px solid' + `${backgroundColorSub}` + '!important', color: `${fontColor}`,textTransform:'none',fontSize:'11px'}}
                        className='topbtn'
                      //onClick={handleRegisterBtnClick}
                      >
                        {slideContent.pageName}
                      </Button>
                      :
                      <Button
                        variant={buttonBackground}
                        style={{backgroundColor: `${backgroundColorSub}`, color: `${fontColor}`,textTransform:'none',fontSize:'11px'}}
                        className='topbtn'
                      //onClick={handleRegisterBtnClick}
                      >
                        {slideContent.pageName}
                      </Button>

                      }

                    </a>

                  </>
                ))}

              </div>
            </>
            :
            ''
          }

        </Grid>
        :
        ''
      }

    </>

  );
  
  const subPagesDisplayBottom = (
    <>
    {needToDisplayInLoginPage && whereToDisplay === 'bottom' && subPagesArray.length > 0 ?
      <>
      <Grid item xs={12} style={{ paddingTop: '10px', display: 'flex', justifyContent: `${alignContent}` }}>
        {typeOfDisplay === 'carousel' ?
            <>
            <div style={{ marginTop: '5px', backgroundColor: '#f4f1f9ad', padding: '3px', borderRadius: '6px', width: '100%' }}>
              <div style={{ border: '1px solid' + `${backgroundColorSub}` + '', padding: '3px', borderRadius: '6px' }}>
                <div >
                  <h5 style={{ color: "white", fontSize: '18px', fontWeight: '400', textAlign: 'center', letterSpacing: '2px', marginTop: '', color: `${fontColor}` }} >Available Pages</h5>
                </div>
                {
                  subPagesArray.length === 1 ?
                    <Grid container spacing={2}>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={6} >
                        <div style={{ marginTop: "-5px", color: 'white' }}>
                          <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}

                            slidesPerView={1}
                            navigation={{
                              prevEl: '.swiper-button-prev',
                              nextEl: '.swiper-button-next',
                            }}

                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                            style={{ textAlign: 'center', spacing: '10px' }}

                            pagination={{
                              clickable: true,
                              style: { marginTop: '50% !important' }

                            }}
                          >



                            <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                              <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                            </div>
                            <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                              <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                            </div>
                            {subPagesArray.map((slideContent, index) => (
                              <SwiperSlide key={slideContent.keyCode} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: `${backgroundColorSub}`, boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '5%', marginBottom: '3%' }}>
                                  <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <BootstrapTooltip title={shortDescri(slideContent.pageName)}>
                                      <div style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><a href='/domainendpoint?keyCode={slideContent.keyCode}' target='_blank'><span style={{color: `${fontColor}` }}>{slideContent.pageName}</span></a>

                                      </div>
                                    </BootstrapTooltip>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>

                        </div>
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Grid> :

                    subPagesArray.length <= 2 ?
                      <Grid container spacing={2}>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6} >

                          <div style={{ marginTop: "-5px", color: 'white' }}>
                            <Swiper
                              modules={[Navigation, Pagination, Scrollbar, A11y]}

                              slidesPerView={2}
                              navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                              }}

                              onSwiper={(swiper) => console.log(swiper)}
                              onSlideChange={() => console.log('slide change')}
                              style={{ textAlign: 'center', spacing: '10px' }}

                              pagination={{
                                clickable: true,
                                style: { marginTop: '50% !important' }

                              }}
                            >



                              <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                              </div>
                              <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                              </div>
                              {subPagesArray.map((slideContent, index) => (
                                <SwiperSlide key={slideContent.keyCode} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                  <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: `${backgroundColorSub}`, boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '6%', marginBottom: '3%' }}>
                                    <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                      <BootstrapTooltip title={shortDescri(slideContent.pageName)}>


                                        <div style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><a href='/domainendpoint?keyCode={slideContent.keyCode}' target='_blank'><span style={{color: `${fontColor}` }}>{slideContent.pageName}</span></a>

                                        </div>
                                      </BootstrapTooltip>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>

                          </div>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid> :

                      subPagesArray.length <= 3 ?

                        <Grid container spacing={2}>
                          <Grid item xs={2}></Grid>
                          <Grid item xs={8}>

                            <div style={{ marginTop: "-5px", color: 'white' }}>
                              <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}

                                slidesPerView={3}
                                navigation={{
                                  prevEl: '.swiper-button-prev',
                                  nextEl: '.swiper-button-next',
                                }}

                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}
                                style={{ textAlign: 'center', spacing: '10px' }}

                                pagination={{
                                  clickable: true,
                                  style: { marginTop: '50% !important' }

                                }}
                              // scrollbar={{ draggable: true }}

                              >



                                <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                  <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                </div>
                                <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                  <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                </div>
                                {subPagesArray.map((slideContent, index) => (
                                  <SwiperSlide key={slideContent.keyCode} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px' }}>
                                    <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: `${backgroundColorSub}`, boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '-3%' }}>
                                      <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        <BootstrapTooltip title={shortDescri(slideContent.pageName)}>
                                          <div style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><a href='/domainendpoint?keyCode={slideContent.keyCode}' target='_blank'><span style={{color: `${fontColor}` }}>{slideContent.pageName}</span></a>

                                          </div>
                                        </BootstrapTooltip>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>

                            </div>
                          </Grid>
                          <Grid item xs={2}></Grid>
                        </Grid>
                        :

                        <Grid container spacing={2}>
                          <Grid item xs={0.5}>

                          </Grid>
                          <Grid item xs={11}>
                            <div style={{ marginTop: "-5px", color: 'white' }}>
                              <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}

                                slidesPerView={4}
                                navigation={{
                                  prevEl: '.swiper-button-prev',
                                  nextEl: '.swiper-button-next',
                                }}

                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}
                                style={{ textAlign: 'center', spacing: '10px' }}

                                pagination={{
                                  clickable: true,
                                  style: { marginTop: '50% !important' }

                                }}
                              // scrollbar={{ draggable: true }}

                              >



                                <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                  <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                </div>
                                <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                  <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                </div>
                                {subPagesArray.map((slideContent, index) => (
                                  <SwiperSlide key={slideContent.keyCode} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px' }}>
                                    <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: `${backgroundColorSub}`, boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '-3%' }}>
                                      <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    {/** 
                                    <div className='collectionBoxDiv'>
                                      <div className='collectionBox'>
                                        */}
                                        <BootstrapTooltip title={shortDescri(slideContent.pageName)}>
                                          <div style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><a href={`/domainendpoint?keyCode=${slideContent.keyCode}`} target='_blank'><span style={{color: `${fontColor}` }}>{slideContent.pageName}</span></a>

                                          </div>
                                        </BootstrapTooltip>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>

                            </div>
                          </Grid>
                          <Grid item xs={0.5}></Grid>
                        </Grid>
                }

              </div>
            </div>

          </>

          :
          ''
        }
        {typeOfDisplay === 'textOnly' ?
          <>
            <div className='topDiv'>
              {subPagesArray.map((slideContent, index) => (
                <>
                  <div style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><a href={`/domainendpoint?keyCode=${slideContent.keyCode}`} target='_blank'><span style={{color: `${fontColor}`}}>{slideContent.pageName}</span></a>

                  </div>
                </>
              ))}

            </div>
          </>
          :
          ''
        }
        {typeOfDisplay === 'buttonFormat' ?
            <>
              <div className='topDiv'>
                {subPagesArray.map((slideContent, index) => (
                  <>
                    <a href={`/domainendpoint?keyCode=${slideContent.keyCode}`} target='_blank'>
                      {buttonBackground === 'outlined'? 
                      <Button
                        variant={buttonBackground}
                        style={{border: '1px solid' + `${backgroundColorSub}` + '!important', color: `${fontColor}`,textTransform:'none',fontSize:'11px'}}
                        className='topbtn'
                      //onClick={handleRegisterBtnClick}
                      >
                        {slideContent.pageName}
                      </Button>
                      :
                      <Button
                        variant={buttonBackground}
                        style={{backgroundColor: `${backgroundColorSub}`, color: `${fontColor}`,textTransform:'none',fontSize:'11px'}}
                        className='topbtn'
                      //onClick={handleRegisterBtnClick}
                      >
                        {slideContent.pageName}
                      </Button>

                      }

                    </a>

                  </>
                ))}

              </div>
            </>
            :
            ''
          }

      </Grid>
      </>
      :
      ''
    }

  </>
  )


  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  setTimeout(() => {

    var imgTags = document.getElementsByTagName('img');
    for (var i = 0; i < imgTags.length; i++) {
      imgTags[i].onclick = function () {
        var str = "<img src=\"" + this.src + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      };
      imgTags[i].onmouseover = function () {
        this.style.cursor = 'pointer';
      };
    }
  }, 1000);

  return (
    <>
      <Header />

      {showPreloader && (
        <div className="preloader-container">
          <div className="preloader-content">
            <p>Please wait.....</p>
            <div className="circular-progress-container">
              <CircularProgress />
            </div>
            {/* You can add additional styling or elements here */}
          </div>
        </div>
      )}
      <main >
        {pageBackgroundUrl != '' ?

          <div style={{
            height: '98vh',
            backgroundSize: 'cover',
            backgroundImage: `linear-gradient( 0deg , rgb(63 54 80), rgb(240 232 239 / 0%)),url(${pageBackgroundUrl})`,
            backgroundRepeat: 'no-repeat'
          }}
          >
            <div class="scrollable-container-main">
              <div class="scrollable-content">
                <div className={classes.pageLayout} >
                    {displayPageContent ?
                      <>
                        {pageTopHtml != '' ?
                          <>
                            <Grid container spacing={2}>
                              {subPagesDisplay}
                              <Grid item xs={12}>
                                <div>
                                  {parseHtml(pageTopHtml)}
                                </div>
                              </Grid>
                            </Grid>
                          </>
                          :
                          <>
                            <Grid container spacing={2}>
                              <Grid item xs={3}></Grid>
                              <Grid item xs={6}>
                                <div className='mainHeadercss'>
                                  {/*  */}
                                  <h5 className='title'>
                                    Welcome to&nbsp;
                                    <span className='colorText'>DMG, </span>

                                  </h5>
                                  <h5 className={classes.secondtitle} style={{ letterSpacing: '2px' }} color="primary">Risk Management System</h5>
                                </div>
                              </Grid>
                              <Grid item xs={3}></Grid>

                            </Grid>

                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div>
                                  <div style={{ marginTop: '' }}>
                                    <div>
                                      <div style={{ color: 'white', marginTop: '10px', fontSize: '12px' }}>
                                        <p className='maintext'>
                                          Data Management Group’s (DMG) Risk Assessment system provides a user-friendly approach to risk management.
                                          It allows individuals responsible for managing risks, such as senior management, risk and compliance teams, finance teams, IT teams,
                                          and the board of directors, to easily assess and mitigate risks.
                                        </p>
                                        <p className='maintext'>
                                          The system allows users to either <span style={{ fontSize: '10px', color: '#9155FD', cursor: 'pointer' }} onClick={alertFunction}>ask questions</span>  about a specific risk or define a <span style={{ fontSize: '10px', color: '#9155FD', cursor: 'pointer' }} onClick={alertFunction}>risk and its hierarchy,</span>  providing a comprehensive and flexible
                                          solution for organizations of all sizes.
                                        </p>
                                        <p className='maintext'>
                                          With the DMG system, entities within an organization can fulfill their responsibilities in assessing and managing risks effectively and efficiently.
                                        </p>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </>
                        }
                      </>
                      :
                      ''
                    }

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div>
                          <div style={{ marginTop: '' }}>

                            {
                              collectionNames.length === 0 ? "" :

                                <div style={{ marginTop: '5px', backgroundColor: '#f4f1f9ad', padding: '3px', borderRadius: '6px' }}>
                                  <div style={{ border: '1px solid rgb(111 46 229)', padding: '3px', borderRadius: '6px' }}>
                                    <div >
                                      <h5 style={{ color: "white", fontSize: '18px', fontWeight: '400', textAlign: 'center', letterSpacing: '2px', marginTop: '', color: 'rgb(111 46 229)' }} >Collections Available</h5>
                                    </div>
                                    {
                                      collectionNames.length === 1 ?
                                        <Grid container spacing={2}>
                                          <Grid item xs={3}></Grid>
                                          <Grid item xs={6} >

                                            <div style={{ marginTop: "-5px", color: 'white' }}>
                                              <Swiper
                                                modules={[Navigation, Pagination, Scrollbar, A11y]}

                                                slidesPerView={1}
                                                navigation={{
                                                  prevEl: '.swiper-button-prev',
                                                  nextEl: '.swiper-button-next',
                                                }}

                                                onSwiper={(swiper) => console.log(swiper)}
                                                onSlideChange={() => console.log('slide change')}
                                                style={{ textAlign: 'center', spacing: '10px' }}

                                                pagination={{
                                                  clickable: true,
                                                  style: { marginTop: '50% !important' }

                                                }}
                                              //scrollbar={{ draggable: true }}

                                              >



                                                <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                  <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                                </div>
                                                <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                  <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                                </div>
                                                {collectionNames.sort((a, b) => a.orderNumber - b.orderNumber).map((slideContent, index) => (
                                                  <SwiperSlide key={slideContent._id} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                    <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: 'rgb(144 120 213 / 42%)', boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '5%', marginBottom: '3%' }}>
                                                      <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                        <BootstrapTooltip title={shortDescri(slideContent.shortDescription)}>
                                                          <div onClick={() => handleClickOpen(slideContent.description)} style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{slideContent.collectionName}

                                                          </div>
                                                        </BootstrapTooltip>
                                                      </div>
                                                    </div>
                                                  </SwiperSlide>
                                                ))}
                                              </Swiper>

                                            </div>
                                          </Grid>
                                          <Grid item xs={2}></Grid>
                                        </Grid> :

                                        collectionNames.length <= 2 ?
                                          <Grid container spacing={2}>
                                            <Grid item xs={3}></Grid>
                                            <Grid item xs={6} >

                                              <div style={{ marginTop: "-5px", color: 'white' }}>
                                                <Swiper
                                                  modules={[Navigation, Pagination, Scrollbar, A11y]}

                                                  slidesPerView={2}
                                                  navigation={{
                                                    prevEl: '.swiper-button-prev',
                                                    nextEl: '.swiper-button-next',
                                                  }}

                                                  onSwiper={(swiper) => console.log(swiper)}
                                                  onSlideChange={() => console.log('slide change')}
                                                  style={{ textAlign: 'center', spacing: '10px' }}

                                                  pagination={{
                                                    clickable: true,
                                                    style: { marginTop: '50% !important' }

                                                  }}
                                                //scrollbar={{ draggable: true }}

                                                >



                                                  <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                    <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                                  </div>
                                                  <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                    <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                                  </div>
                                                  {collectionNames.sort((a, b) => a.orderNumber - b.orderNumber).map((slideContent, index) => (
                                                    <SwiperSlide key={slideContent._id} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                      <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: 'rgb(144 120 213 / 42%)', boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '6%', marginBottom: '3%' }}>
                                                        <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                          <BootstrapTooltip title={shortDescri(slideContent.shortDescription)}>


                                                            <div onClick={() => handleClickOpen(slideContent.description)} style={{ cursor: 'pointer', fontSize: '12px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{slideContent.collectionName}

                                                            </div>
                                                          </BootstrapTooltip>
                                                        </div>
                                                      </div>
                                                    </SwiperSlide>
                                                  ))}
                                                </Swiper>

                                              </div>
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                          </Grid> :

                                          collectionNames.length <= 3 ?

                                            <Grid container spacing={2}>
                                              <Grid item xs={2}></Grid>
                                              <Grid item xs={8}>

                                                <div style={{ marginTop: "-5px", color: 'white' }}>
                                                  <Swiper
                                                    modules={[Navigation, Pagination, Scrollbar, A11y]}

                                                    slidesPerView={3}
                                                    navigation={{
                                                      prevEl: '.swiper-button-prev',
                                                      nextEl: '.swiper-button-next',
                                                    }}

                                                    onSwiper={(swiper) => console.log(swiper)}
                                                    onSlideChange={() => console.log('slide change')}
                                                    style={{ textAlign: 'center', spacing: '10px' }}

                                                    pagination={{
                                                      clickable: true,
                                                      style: { marginTop: '50% !important' }

                                                    }}
                                                  // scrollbar={{ draggable: true }}

                                                  >



                                                    <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                      <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                                    </div>
                                                    <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                      <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                                    </div>
                                                    {collectionNames.sort((a, b) => a.orderNumber - b.orderNumber).map((slideContent, index) => (
                                                      <SwiperSlide key={slideContent._id} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px' }}>
                                                        <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: 'rgb(144 120 213 / 42%)', boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '-3%' }}>
                                                          <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                            <BootstrapTooltip title={shortDescri(slideContent.shortDescription)}>
                                                              <div onClick={() => handleClickOpen(slideContent.description)} style={{ cursor: 'pointer', fontSize: '12px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{slideContent.collectionName}

                                                              </div>
                                                            </BootstrapTooltip>
                                                          </div>
                                                        </div>
                                                      </SwiperSlide>
                                                    ))}
                                                  </Swiper>

                                                </div>
                                              </Grid>
                                              <Grid item xs={2}></Grid>
                                            </Grid>
                                            :

                                            <Grid container spacing={2}>
                                              <Grid item xs={0.5}>

                                              </Grid>
                                              <Grid item xs={11}>
                                                <div style={{ marginTop: "-5px", color: 'white' }}>
                                                  <Swiper
                                                    modules={[Navigation, Pagination, Scrollbar, A11y]}

                                                    slidesPerView={4}
                                                    navigation={{
                                                      prevEl: '.swiper-button-prev',
                                                      nextEl: '.swiper-button-next',
                                                    }}

                                                    onSwiper={(swiper) => console.log(swiper)}
                                                    onSlideChange={() => console.log('slide change')}
                                                    style={{ textAlign: 'center', spacing: '10px' }}

                                                    pagination={{
                                                      clickable: true,
                                                      style: { marginTop: '50% !important' }

                                                    }}
                                                  // scrollbar={{ draggable: true }}

                                                  >



                                                    <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                      <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                                    </div>
                                                    <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                      <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                                    </div>
                                                    {collectionNames.sort((a, b) => a.orderNumber - b.orderNumber).map((slideContent, index) => (
                                                      <SwiperSlide key={slideContent._id} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px' }}>
                                                        <div className='collectionBoxDiv'>
                                                          <div className='collectionBox'>
                                                            <BootstrapTooltip title={shortDescri(slideContent.shortDescription)}>
                                                              <div onClick={() => handleClickOpen(slideContent.description)} className='collectionBoxText'>{slideContent.collectionName}

                                                              </div>
                                                            </BootstrapTooltip>
                                                          </div>
                                                        </div>
                                                      </SwiperSlide>
                                                    ))}
                                                  </Swiper>

                                                </div>
                                              </Grid>
                                              <Grid item xs={0.5}></Grid>
                                            </Grid>
                                    }

                                  </div>
                                </div>
                            }

                            {CONFIG.EXPERT_LOGIN ?

                              <div style={{ marginTop: '12px' }}>
                                <div className='lgbtn'>NEW TO DMG ?</div>
                                <div class="containerbtn">
                                  <div className='buttonSection'>
                                    <Button
                                      variant="outlined"
                                      className='regbtn'
                                      onClick={handleRegisterBtnClick}
                                    >
                                      Register as an administrator
                                    </Button>
                                    &nbsp; &nbsp; &nbsp;<span className='orTest'>OR</span> &nbsp; &nbsp; &nbsp;
                                    <Button
                                      variant="outlined"
                                      id='btnTrial'
                                      className='regbtn'
                                      onClick={() => handleLoginBtnClick(false)}
                                    >
                                      LOGIN / register to Trial The System Free
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              : ''
                            }



                          </div>
                        </div>
                      </Grid>
                      {subPagesDisplayBottom}
                    </Grid>
                   </div>
                  </div>
                  <div style={{ marginTop: '200px' }}>
                    <Footer />
                  </div>
            </div>
          </div>

          :
          <>
            {pageBackgroundColor != '' ?
              <div style={{
                backgroundSize: 'cover',
                //backgroundImage: `linear-gradient( 0deg , rgb(63 54 80), rgb(240 232 239 / 0%)),url(https://assets.kpmg/is/image/kpmg/pointing-digital-screen:cq5dam.web.512.341)`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: `${pageBackgroundColor}`
              }}
              >
                  <div class="scrollable-container-main">
                    <div class="scrollable-content">
                      <div className={classes.pageLayout}>
                        {displayPageContent ?
                          <>
                            {pageTopHtml != '' ?
                              <>
                                <Grid container spacing={2}>
                                  {subPagesDisplay}
                                  <Grid item xs={12} style={{ paddingTop: '40px' }}>
                                    <div>
                                      {parseHtml(pageTopHtml)}
                                    </div>
                                  </Grid>
                                </Grid>
                              </>
                              :
                              <>
                                <Grid container spacing={2}>
                                  <Grid item xs={3}></Grid>
                                  <Grid item xs={6}>
                                    <div className='mainHeadercss'>
                                      {/*  */}
                                      <h5 className='title'>
                                        Welcome to&nbsp;
                                        <span className='colorText'>DMG, </span>

                                      </h5>
                                      <h5 className={classes.secondtitle} style={{ letterSpacing: '2px' }} color="primary">Risk Management System</h5>
                                    </div>
                                  </Grid>
                                  <Grid item xs={3}></Grid>

                                </Grid>

                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <div>
                                      <div style={{ marginTop: '' }}>
                                        <div>
                                          <div style={{ color: 'white', marginTop: '10px', fontSize: '12px' }}>
                                            <p className='maintext'>
                                              Data Management Group’s (DMG) Risk Assessment system provides a user-friendly approach to risk management.
                                              It allows individuals responsible for managing risks, such as senior management, risk and compliance teams, finance teams, IT teams,
                                              and the board of directors, to easily assess and mitigate risks.
                                            </p>
                                            <p className='maintext'>
                                              The system allows users to either <span style={{ fontSize: '10px', color: '#9155FD', cursor: 'pointer' }} onClick={alertFunction}>ask questions</span>  about a specific risk or define a <span style={{ fontSize: '10px', color: '#9155FD', cursor: 'pointer' }} onClick={alertFunction}>risk and its hierarchy,</span>  providing a comprehensive and flexible
                                              solution for organizations of all sizes.
                                            </p>
                                            <p className='maintext'>
                                              With the DMG system, entities within an organization can fulfill their responsibilities in assessing and managing risks effectively and efficiently.
                                            </p>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </>
                            }
                          </>
                          :
                          ''
                        }

                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <div>
                              <div style={{ marginTop: '' }}>

                                {
                                  collectionNames.length === 0 ? "" :

                                    <div style={{ marginTop: '5px', backgroundColor: '#f4f1f9ad', padding: '3px', borderRadius: '6px' }}>
                                      <div style={{ border: '1px solid rgb(111 46 229)', padding: '3px', borderRadius: '6px' }}>
                                        <div >
                                          <h5 style={{ color: "white", fontSize: '18px', fontWeight: '400', textAlign: 'center', letterSpacing: '2px', marginTop: '', color: 'rgb(111 46 229)' }} >Collections Available</h5>
                                        </div>
                                        {
                                          collectionNames.length === 1 ?
                                            <Grid container spacing={2}>
                                              <Grid item xs={3}></Grid>
                                              <Grid item xs={6} >

                                                <div style={{ marginTop: "-5px", color: 'white' }}>
                                                  <Swiper
                                                    modules={[Navigation, Pagination, Scrollbar, A11y]}

                                                    slidesPerView={1}
                                                    navigation={{
                                                      prevEl: '.swiper-button-prev',
                                                      nextEl: '.swiper-button-next',
                                                    }}

                                                    onSwiper={(swiper) => console.log(swiper)}
                                                    onSlideChange={() => console.log('slide change')}
                                                    style={{ textAlign: 'center', spacing: '10px' }}

                                                    pagination={{
                                                      clickable: true,
                                                      style: { marginTop: '50% !important' }

                                                    }}
                                                  //scrollbar={{ draggable: true }}

                                                  >



                                                    <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                      <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                                    </div>
                                                    <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                      <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                                    </div>
                                                    {collectionNames.sort((a, b) => a.orderNumber - b.orderNumber).map((slideContent, index) => (
                                                      <SwiperSlide key={slideContent._id} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                        <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: 'rgb(144 120 213 / 42%)', boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '5%', marginBottom: '3%' }}>
                                                          <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                            <BootstrapTooltip title={shortDescri(slideContent.shortDescription)}>
                                                              <div onClick={() => handleClickOpen(slideContent.description)} style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{slideContent.collectionName}

                                                              </div>
                                                            </BootstrapTooltip>
                                                          </div>
                                                        </div>
                                                      </SwiperSlide>
                                                    ))}
                                                  </Swiper>

                                                </div>
                                              </Grid>
                                              <Grid item xs={2}></Grid>
                                            </Grid> :

                                            collectionNames.length <= 2 ?
                                              <Grid container spacing={2}>
                                                <Grid item xs={3}></Grid>
                                                <Grid item xs={6} >

                                                  <div style={{ marginTop: "-5px", color: 'white' }}>
                                                    <Swiper
                                                      modules={[Navigation, Pagination, Scrollbar, A11y]}

                                                      slidesPerView={2}
                                                      navigation={{
                                                        prevEl: '.swiper-button-prev',
                                                        nextEl: '.swiper-button-next',
                                                      }}

                                                      onSwiper={(swiper) => console.log(swiper)}
                                                      onSlideChange={() => console.log('slide change')}
                                                      style={{ textAlign: 'center', spacing: '10px' }}

                                                      pagination={{
                                                        clickable: true,
                                                        style: { marginTop: '50% !important' }

                                                      }}
                                                    //scrollbar={{ draggable: true }}

                                                    >



                                                      <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                        <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                                      </div>
                                                      <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                        <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                                      </div>
                                                      {collectionNames.sort((a, b) => a.orderNumber - b.orderNumber).map((slideContent, index) => (
                                                        <SwiperSlide key={slideContent._id} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                          <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: 'rgb(144 120 213 / 42%)', boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '6%', marginBottom: '3%' }}>
                                                            <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                              <BootstrapTooltip title={shortDescri(slideContent.shortDescription)}>


                                                                <div onClick={() => handleClickOpen(slideContent.description)} style={{ cursor: 'pointer', fontSize: '12px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{slideContent.collectionName}

                                                                </div>
                                                              </BootstrapTooltip>
                                                            </div>
                                                          </div>
                                                        </SwiperSlide>
                                                      ))}
                                                    </Swiper>

                                                  </div>
                                                </Grid>
                                                <Grid item xs={2}></Grid>
                                              </Grid> :

                                              collectionNames.length <= 3 ?

                                                <Grid container spacing={2}>
                                                  <Grid item xs={2}></Grid>
                                                  <Grid item xs={8}>

                                                    <div style={{ marginTop: "-5px", color: 'white' }}>
                                                      <Swiper
                                                        modules={[Navigation, Pagination, Scrollbar, A11y]}

                                                        slidesPerView={3}
                                                        navigation={{
                                                          prevEl: '.swiper-button-prev',
                                                          nextEl: '.swiper-button-next',
                                                        }}

                                                        onSwiper={(swiper) => console.log(swiper)}
                                                        onSlideChange={() => console.log('slide change')}
                                                        style={{ textAlign: 'center', spacing: '10px' }}

                                                        pagination={{
                                                          clickable: true,
                                                          style: { marginTop: '50% !important' }

                                                        }}
                                                      // scrollbar={{ draggable: true }}

                                                      >



                                                        <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                          <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                                        </div>
                                                        <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                          <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                                        </div>
                                                        {collectionNames.sort((a, b) => a.orderNumber - b.orderNumber).map((slideContent, index) => (
                                                          <SwiperSlide key={slideContent._id} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px' }}>
                                                            <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: 'rgb(144 120 213 / 42%)', boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '-3%' }}>
                                                              <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                                <BootstrapTooltip title={shortDescri(slideContent.shortDescription)}>
                                                                  <div onClick={() => handleClickOpen(slideContent.description)} style={{ cursor: 'pointer', fontSize: '12px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{slideContent.collectionName}

                                                                  </div>
                                                                </BootstrapTooltip>
                                                              </div>
                                                            </div>
                                                          </SwiperSlide>
                                                        ))}
                                                      </Swiper>

                                                    </div>
                                                  </Grid>
                                                  <Grid item xs={2}></Grid>
                                                </Grid>
                                                :

                                                <Grid container spacing={2}>
                                                  <Grid item xs={0.5}>

                                                  </Grid>
                                                  <Grid item xs={11}>
                                                    <div style={{ marginTop: "-5px", color: 'white' }}>
                                                      <Swiper
                                                        modules={[Navigation, Pagination, Scrollbar, A11y]}

                                                        slidesPerView={4}
                                                        navigation={{
                                                          prevEl: '.swiper-button-prev',
                                                          nextEl: '.swiper-button-next',
                                                        }}

                                                        onSwiper={(swiper) => console.log(swiper)}
                                                        onSlideChange={() => console.log('slide change')}
                                                        style={{ textAlign: 'center', spacing: '10px' }}

                                                        pagination={{
                                                          clickable: true,
                                                          style: { marginTop: '50% !important' }

                                                        }}
                                                      // scrollbar={{ draggable: true }}

                                                      >



                                                        <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                          <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                                        </div>
                                                        <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                          <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                                        </div>
                                                        {collectionNames.sort((a, b) => a.orderNumber - b.orderNumber).map((slideContent, index) => (
                                                          <SwiperSlide key={slideContent._id} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px' }}>
                                                            <div className='collectionBoxDiv'>
                                                              <div className='collectionBox'>
                                                                <BootstrapTooltip title={shortDescri(slideContent.shortDescription)}>
                                                                  <div onClick={() => handleClickOpen(slideContent.description)} className='collectionBoxText'>{slideContent.collectionName}

                                                                  </div>
                                                                </BootstrapTooltip>
                                                              </div>
                                                            </div>
                                                          </SwiperSlide>
                                                        ))}
                                                      </Swiper>

                                                    </div>
                                                  </Grid>
                                                  <Grid item xs={0.5}></Grid>
                                                </Grid>
                                        }

                                      </div>
                                    </div>
                                }

                                {CONFIG.EXPERT_LOGIN ?
                                  <div style={{ marginTop: '12px' }}>

                                    <div className='lgbtn'>NEW TO DMG ?</div><br></br>
                                    <div class="containerbtn">
                                      <div className='buttonSection'>
                                        <Button
                                          variant="outlined"

                                          className='regbtn'
                                          onClick={handleRegisterBtnClick}
                                        >
                                          Register as an administrator
                                        </Button>
                                        &nbsp; &nbsp; &nbsp;<span className='orTest'>OR</span> &nbsp; &nbsp; &nbsp;
                                        <Button
                                          variant="outlined"
                                          id='btnTrial'
                                          className='regbtn'
                                          onClick={() => handleLoginBtnClick(false)}
                                        //onClick={handleLoginBtnClick}
                                        >
                                          LOGIN / register to Trial The System Free
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                  : ''
                                }





                              </div>
                            </div>
                          </Grid>
                          {subPagesDisplayBottom}
                        </Grid>
                      </div>
                    </div>
                    <div style={{ marginTop: '200px' }}>
                      <Footer />
                    </div>
                  </div>
              </div>

              :
              <>
              {!showPreloader && (
              <div style={{
                height: '100vh',
                backgroundSize: 'cover',
                backgroundImage: `linear-gradient( 0deg , rgb(63 54 80), rgb(240 232 239 / 0%)),url(https://assets.kpmg/is/image/kpmg/pointing-digital-screen:cq5dam.web.512.341)`,
                backgroundRepeat: 'no-repeat'
              }}
              >
                <div class="scrollable-container-main">
                  <div class="scrollable-content">
                    <div className={classes.pageLayout}>
                      {displayPageContent ?
                        <>
                          {pageTopHtml != '' ?
                            <>
                              <Grid container spacing={2}>
                                {subPagesDisplay}
                                <Grid item xs={12}>
                                  <div>
                                    {parseHtml(pageTopHtml)}
                                  </div>
                                </Grid>
                              </Grid>
                            </>
                            :
                            <>
                              <Grid container spacing={2}>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={6}>
                                  <div className='mainHeadercss'>
                                    {/*  */}
                                    <h5 className='title'>
                                      Welcome to&nbsp;
                                      <span className='colorText'>DMG, </span>

                                    </h5>
                                    <h5 className={classes.secondtitle} style={{ letterSpacing: '2px' }} color="primary">Risk Management System</h5>
                                  </div>
                                </Grid>
                                <Grid item xs={3}></Grid>

                              </Grid>

                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <div>
                                    <div style={{ marginTop: '' }}>
                                      <div>
                                        <div style={{ color: 'white', marginTop: '10px', fontSize: '12px' }}>
                                          <p className='maintext'>
                                            Data Management Group’s (DMG) Risk Assessment system provides a user-friendly approach to risk management.
                                            It allows individuals responsible for managing risks, such as senior management, risk and compliance teams, finance teams, IT teams,
                                            and the board of directors, to easily assess and mitigate risks.
                                          </p>
                                          <p className='maintext'>
                                            The system allows users to either <span style={{ fontSize: '10px', color: '#9155FD', cursor: 'pointer' }} onClick={alertFunction}>ask questions</span>  about a specific risk or define a <span style={{ fontSize: '10px', color: '#9155FD', cursor: 'pointer' }} onClick={alertFunction}>risk and its hierarchy,</span>  providing a comprehensive and flexible
                                            solution for organizations of all sizes.
                                          </p>
                                          <p className='maintext'>
                                            With the DMG system, entities within an organization can fulfill their responsibilities in assessing and managing risks effectively and efficiently.
                                          </p>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </>
                          }
                        </>
                        :
                        ''
                      }

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div>
                            <div style={{ marginTop: '' }}>

                              {
                                collectionNames.length === 0 ? "" :

                                  <div style={{ marginTop: '5px', backgroundColor: '#f4f1f9ad', padding: '3px', borderRadius: '6px' }}>
                                    <div style={{ border: '1px solid rgb(111 46 229)', padding: '3px', borderRadius: '6px' }}>
                                      <div >
                                        <h5 style={{ color: "white", fontSize: '18px', fontWeight: '400', textAlign: 'center', letterSpacing: '2px', marginTop: '', color: 'rgb(111 46 229)' }} >Collections Available</h5>
                                      </div>
                                      {
                                        collectionNames.length === 1 ?
                                          <Grid container spacing={2}>
                                            <Grid item xs={3}></Grid>
                                            <Grid item xs={6} >

                                              <div style={{ marginTop: "-5px", color: 'white' }}>
                                                <Swiper
                                                  modules={[Navigation, Pagination, Scrollbar, A11y]}

                                                  slidesPerView={1}
                                                  navigation={{
                                                    prevEl: '.swiper-button-prev',
                                                    nextEl: '.swiper-button-next',
                                                  }}

                                                  onSwiper={(swiper) => console.log(swiper)}
                                                  onSlideChange={() => console.log('slide change')}
                                                  style={{ textAlign: 'center', spacing: '10px' }}

                                                  pagination={{
                                                    clickable: true,
                                                    style: { marginTop: '50% !important' }

                                                  }}
                                                //scrollbar={{ draggable: true }}

                                                >



                                                  <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                    <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                                  </div>
                                                  <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                    <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                                  </div>
                                                  {collectionNames.sort((a, b) => a.orderNumber - b.orderNumber).map((slideContent, index) => (
                                                    <SwiperSlide key={slideContent._id} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                      <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: 'rgb(144 120 213 / 42%)', boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '5%', marginBottom: '3%' }}>
                                                        <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                          <BootstrapTooltip title={shortDescri(slideContent.shortDescription)}>
                                                            <div onClick={() => handleClickOpen(slideContent.description)} style={{ cursor: 'pointer', fontSize: '8px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{slideContent.collectionName}

                                                            </div>
                                                          </BootstrapTooltip>
                                                        </div>
                                                      </div>
                                                    </SwiperSlide>
                                                  ))}
                                                </Swiper>

                                              </div>
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                          </Grid> :

                                          collectionNames.length <= 2 ?
                                            <Grid container spacing={2}>
                                              <Grid item xs={3}></Grid>
                                              <Grid item xs={6} >

                                                <div style={{ marginTop: "-5px", color: 'white' }}>
                                                  <Swiper
                                                    modules={[Navigation, Pagination, Scrollbar, A11y]}

                                                    slidesPerView={2}
                                                    navigation={{
                                                      prevEl: '.swiper-button-prev',
                                                      nextEl: '.swiper-button-next',
                                                    }}

                                                    onSwiper={(swiper) => console.log(swiper)}
                                                    onSlideChange={() => console.log('slide change')}
                                                    style={{ textAlign: 'center', spacing: '10px' }}

                                                    pagination={{
                                                      clickable: true,
                                                      style: { marginTop: '50% !important' }

                                                    }}
                                                  //scrollbar={{ draggable: true }}

                                                  >



                                                    <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                      <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                                    </div>
                                                    <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                      <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                                    </div>
                                                    {collectionNames.sort((a, b) => a.orderNumber - b.orderNumber).map((slideContent, index) => (
                                                      <SwiperSlide key={slideContent._id} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                        <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: 'rgb(144 120 213 / 42%)', boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '6%', marginBottom: '3%' }}>
                                                          <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                            <BootstrapTooltip title={shortDescri(slideContent.shortDescription)}>


                                                              <div onClick={() => handleClickOpen(slideContent.description)} style={{ cursor: 'pointer', fontSize: '12px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{slideContent.collectionName}

                                                              </div>
                                                            </BootstrapTooltip>
                                                          </div>
                                                        </div>
                                                      </SwiperSlide>
                                                    ))}
                                                  </Swiper>

                                                </div>
                                              </Grid>
                                              <Grid item xs={2}></Grid>
                                            </Grid> :

                                            collectionNames.length <= 3 ?

                                              <Grid container spacing={2}>
                                                <Grid item xs={2}></Grid>
                                                <Grid item xs={8}>

                                                  <div style={{ marginTop: "-5px", color: 'white' }}>
                                                    <Swiper
                                                      modules={[Navigation, Pagination, Scrollbar, A11y]}

                                                      slidesPerView={3}
                                                      navigation={{
                                                        prevEl: '.swiper-button-prev',
                                                        nextEl: '.swiper-button-next',
                                                      }}

                                                      onSwiper={(swiper) => console.log(swiper)}
                                                      onSlideChange={() => console.log('slide change')}
                                                      style={{ textAlign: 'center', spacing: '10px' }}

                                                      pagination={{
                                                        clickable: true,
                                                        style: { marginTop: '50% !important' }

                                                      }}
                                                    // scrollbar={{ draggable: true }}

                                                    >



                                                      <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                        <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                                      </div>
                                                      <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                        <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                                      </div>
                                                      {collectionNames.sort((a, b) => a.orderNumber - b.orderNumber).map((slideContent, index) => (
                                                        <SwiperSlide key={slideContent._id} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px' }}>
                                                          <div style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid rgb(111 46 229)', display: 'flex', borderRadius: '7px', backgroundColor: 'rgb(144 120 213 / 42%)', boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.25)', width: '120px', marginTop: '-3%' }}>
                                                            <div style={{ height: '40px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                              <BootstrapTooltip title={shortDescri(slideContent.shortDescription)}>
                                                                <div onClick={() => handleClickOpen(slideContent.description)} style={{ cursor: 'pointer', fontSize: '12px', textAlign: 'center', color: '#5d40cd', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{slideContent.collectionName}

                                                                </div>
                                                              </BootstrapTooltip>
                                                            </div>
                                                          </div>
                                                        </SwiperSlide>
                                                      ))}
                                                    </Swiper>

                                                  </div>
                                                </Grid>
                                                <Grid item xs={2}></Grid>
                                              </Grid>
                                              :

                                              <Grid container spacing={2}>
                                                <Grid item xs={0.5}>

                                                </Grid>
                                                <Grid item xs={11}>
                                                  <div style={{ marginTop: "-5px", color: 'white' }}>
                                                    <Swiper
                                                      modules={[Navigation, Pagination, Scrollbar, A11y]}

                                                      slidesPerView={4}
                                                      navigation={{
                                                        prevEl: '.swiper-button-prev',
                                                        nextEl: '.swiper-button-next',
                                                      }}

                                                      onSwiper={(swiper) => console.log(swiper)}
                                                      onSlideChange={() => console.log('slide change')}
                                                      style={{ textAlign: 'center', spacing: '10px' }}

                                                      pagination={{
                                                        clickable: true,
                                                        style: { marginTop: '50% !important' }

                                                      }}
                                                    // scrollbar={{ draggable: true }}

                                                    >



                                                      <div className="swiper-button-prev" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                        <span className="swiper-button-prev-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>{"<"}</span>
                                                      </div>
                                                      <div className="swiper-button-next" style={{ color: 'transparent', cursor: 'pointer' }}>
                                                        <span className="swiper-button-next-icon" style={{ fontSize: '22px', fontWeight: 'bold', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"}</span>
                                                      </div>
                                                      {collectionNames.sort((a, b) => a.orderNumber - b.orderNumber).map((slideContent, index) => (
                                                        <SwiperSlide key={slideContent._id} virtualIndex={index} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px' }}>
                                                          <div className='collectionBoxDiv'>
                                                            <div className='collectionBox'>
                                                              <BootstrapTooltip title={shortDescri(slideContent.shortDescription)}>
                                                                <div onClick={() => handleClickOpen(slideContent.description)} className='collectionBoxText'>{slideContent.collectionName}

                                                                </div>
                                                              </BootstrapTooltip>
                                                            </div>
                                                          </div>
                                                        </SwiperSlide>
                                                      ))}
                                                    </Swiper>

                                                  </div>
                                                </Grid>
                                                <Grid item xs={0.5}></Grid>
                                              </Grid>
                                      }

                                    </div>
                                  </div>
                              }

                              {CONFIG.EXPERT_LOGIN ?
                                <div style={{ marginTop: '12px' }}>

                                  <div className='lgbtn'>NEW TO DMG ?</div><br></br>
                                  <div class="containerbtn">
                                    <div className='buttonSection'>
                                      <Button
                                        variant="outlined"

                                        className='regbtn'
                                        onClick={handleRegisterBtnClick}
                                      >
                                        Register as an administrator
                                      </Button>
                                      &nbsp; &nbsp; &nbsp;<span className='orTest'>OR</span> &nbsp; &nbsp; &nbsp;
                                      <Button
                                        variant="outlined"
                                        id='btnTrial'
                                        className='regbtn'
                                        onClick={() => handleLoginBtnClick(false)}
                                      //onClick={handleLoginBtnClick}
                                      >
                                        LOGIN / register to Trial The System Free
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                : ''
                              }



                            </div>
                          </div>
                        </Grid>
                        {subPagesDisplayBottom}
                      </Grid>
                    </div>
                  </div>
                  <div style={{ marginTop: '200px' }}>
                    <Footer />
                  </div>
                </div>
              </div>
              )}
              </>
            }
          </>
        }
      </main>

      <div>
        <Dialog
          open={open}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {dialogHtmlData}
          </DialogContent>
          <DialogActions>
            <div className='buttonSection'>
              <Button
                variant="outlined"
                className='regbtn1'
                onClick={handleRegisterBtnClick}
              >
                Register as an administrator
              </Button>
              &nbsp; &nbsp; &nbsp;
              <Button
                variant="outlined"
                className='regbtn1'
                id='btnTrial'
                onClick={() => handleLoginBtnClick(false)}
              //onClick={handleLoginBtnClick}
              >
                LOGIN / register to Trial The System Free
              </Button>
            </div>
            <Button onClick={handleClose}>cancel</Button>

          </DialogActions>
        </Dialog>
      </div>


      <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>


    </>
  )
}

export default MainPage