import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import CardHeader from '@mui/material/CardHeader';
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ReactPlayer from 'react-player'
import axios from 'axios'
import { CONFIG } from '../config';
import parseHtml from 'html-react-parser';
import "../Css/Slider.css";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';



export const OurServices = () => {
  const classes = useStyle();


  useEffect(() => {
    getLoginPageDetails()
    getOurServicePageDetails()
  }, []);


  const [pageBackgroundUrl, setPageBackgroundUrl] = React.useState('');
  const [pageBackgroundColor, setPageBackgroundColor] = React.useState('');

  function getLoginPageDetails() {
    axios(CONFIG.EXPERT_SERVER_URL + `/loginPageContent/allCurrent`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("++++Login Page Details +++++++++++++++++");
        console.log(response.data);
        response.data.forEach((item, index) => {
          if (item.domainName === window.location.hostname || ('www.' + item.domainName) === window.location.hostname) {
            if (item.backgroundUrl != undefined && item.backgroundUrl != null) {
              setPageBackgroundUrl(item.backgroundUrl);
            }
            if (item.backgroundColor != undefined && item.backgroundColor != null) {
              setPageBackgroundColor(item.backgroundColor);
            }
          }
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const [pageTopHtml, setPageTopHtml] = React.useState('');

  function getOurServicePageDetails() {
    axios(CONFIG.EXPERT_SERVER_URL + `/ourServicesPageContent/filter?versionNumber=current`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("++++Login Page Details +++++++++++++++++");
        console.log(response.data);
        response.data.forEach((item, index) => {
          if (item.domainName === window.location.hostname || ('www.' + item.domainName) === window.location.hostname) {
            if (item.pageContent != undefined && item.pageContent != null) {
              //alert(item.pageContent)
              var tArr = item.pageContent.split("<body>");
              if (tArr.length > 1) {
                var tArr1 = tArr[1].split("</body>");
                //alert(tArr1[0])
                console.log(tArr1[0].replaceAll("\\n", ''))
                setPageTopHtml(tArr1[0].replaceAll("\\n", ''));
              } else {
                if (item.pageContent != "") {
                  setPageTopHtml(item.pageContent.replaceAll("\\n", ''));
                }
              }

            }
          }
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  setTimeout(() => {

    var imgTags = document.getElementsByTagName('img');
    for (var i = 0; i < imgTags.length; i++) {
      imgTags[i].onclick = function () {
        var str = "<img src=\"" + this.src + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      };
      imgTags[i].onmouseover = function () {
        this.style.cursor = 'pointer';
      };
    }
  }, 1000);


  return (
    <>
      <Header />
      <main >

        {pageBackgroundUrl != '' ?
          <div style={{
            height: '98vh',
            backgroundSize: 'cover',
            backgroundImage: `linear-gradient( 0deg , rgb(63 54 80), rgb(240 232 239 / 0%)),url(${pageBackgroundUrl})`,
            backgroundRepeat: 'no-repeat'
          }}
          >
           <div class="scrollable-container-main">
              <div class="scrollable-content">
                <div className={classes.pageLayout} >
                    {pageTopHtml != '' ?
                      <>

                        <Grid container spacing={2}>
                          <Grid item xs={12} >
                            <div>
                              {parseHtml(pageTopHtml)}
                            </div>
                          </Grid>
                        </Grid>

                      </>

                      :
                      <>
                      {/** 
                      <Grid container spacing={2}>
                        <Grid container spacing={2} className={classes.pageBodyStyleabout}>
                          <Grid item xs={12}>
                            <Box sx={{ minWidth: 120 }}>
                              <Card className={classes.root}>
                                <div className={classes.posRelative}>
                                  <CardMedia component="img" image={titleImage} title="Risk Group" alt="Ris Group" />
                                  <div className={classes.topImagePosabout}>
                                    <p className={classes.topHeaderTextabout}>
                                      OUR SERVICES
                                    </p>

                                  </div>
                                </div>
                              </Card>
                            </Box>
                          </Grid>
                          <div className={classes.margin}>
                            <Grid container spacing={2} className={classes.storytop}>
                              <Grid item xs={7} >
                                <div className={classes.phaMain}>
                                  <h3 style={{ marginBottom: '20px' }}>Frame Works</h3>
                                  <p style={{ fontSize: '15px' }}>

                                    Building a frame work is our speciality..
                                    A frame work is a conceptual structure of a particular industry.
                                    A frame work, once built, can do the heavy lifting of a complete system for that industry.
                                    myriads of facilities now available in technology, are useless unless they are applied to a definite and structured framework.
                                  </p>
                                </div>
                              </Grid>
                              <Grid item xs={5}>
                                <div >
                                  <img src='https://datam.co.uk/images/large-2033983.jpg' alt='image' style={{ width: '400px', height: '200px' }}></img>
                                </div>
                              </Grid>

                            </Grid>

                            <Grid container spacing={2} className={classes.storytop}>
                              <Grid item xs={5}>
                                <div>
                                  <img src='https://datam.co.uk/images/large-686316.jpg' alt='image' style={{ width: '400px', height: '200px' }}></img>
                                </div>
                              </Grid>
                              <Grid item xs={7}>
                                <div className={classes.phaMain}>
                                  <h3 style={{ marginBottom: '20px' }}>Structures for a Framework</h3>
                                  <p style={{ fontSize: '15px' }}>

                                    To build a structure for a framework, an "activity sector" is required.
                                    Activity sectors usually are a section in a country's economic infrastructure.
                                    At the highest level the sectors in western democracies are Public and commercial.
                                    The commercial sector then divides into Manufacturing and Service industries
                                  </p>
                                </div>

                              </Grid>

                            </Grid>

                            <Grid container spacing={2} className={classes.storytop}>
                              <Grid item xs={7} >
                                <div className={classes.phaMain}>
                                  <h3 style={{ marginBottom: '20px' }}>SERVICES Sector</h3>
                                  <p style={{ fontSize: '15px' }}>

                                    The 1970s was a disastrous period for the UK .
                                    Manufacturing Industry was rife with industrial disputes between workers and management.
                                    Inflation was rampant.
                                    Factories and equipment were outdated and required modernisation.
                                    The services sectors were growing at a fair pace.
                                    We saw an opportunity to create system frameworks for service industies
                                  </p>
                                </div>
                              </Grid>
                              <Grid item xs={5}>
                                <div>
                                  <img src='https://datam.co.uk/images/large-3846597.png' alt='image' style={{ width: '400px', height: '200px' }}></img>
                                </div>
                              </Grid>

                            </Grid>

                            <Grid container spacing={2} className={classes.storytop}>
                              <Grid item xs={8}>
                                <div>
                                  <h3 style={{ textAlign: 'center' }}>What we Do</h3>
                                  <p style={{ fontSize: '15px' }}>
                                    &nbsp;&nbsp;&nbsp;
                                    when we started it was all about developing systems
                                    that would reduce costs associated with manual effort.
                                    Today, the demand is to build
                                    Apps that will
                                    Generate Revenue
                                    Take  to market quickly and
                                    integrate   with any other  systems in operation including  social media.
                                    Being technology independent,
                                    we build systems and utilities using all or any technology that is required or available.
                                    Acceptable Cost is the main criteria.<br></br>
                                    &nbsp;&nbsp;&nbsp;
                                    A potential customer comes to us mainly because they have a business opportunity
                                    that they know requires  technology; they want some advise or help,
                                    perhaps because their current software provider cannot or  will not support their requirement<br></br>
                                    &nbsp;&nbsp;&nbsp;
                                    To a lesser extent, they come to us because their current  legacy system
                                    does not do enough and requires a great deal
                                    of manual effort to use, and does not yield any substantial benefits.<br></br>
                                    &nbsp;&nbsp;&nbsp;
                                    Here's where our experience and expertise kicks in to identify the problem and offer a swift and cost-effective solution.
                                    Oh NO !!!... No!
                                    We are not that type of business consultants who will charge a hefty fee, take months to deliver only a mere list of recommendations.

                                    We look at the problem, and if a solution is readily available, we divulge the information free of charge immediately.
                                    Because of our long experience, this type of action is possible in most cases, and
                                    If we are selected as a preferred partner to perform the tasks necessary, we quote for the work.
                                    In delivery we strive to fullfill the urgency of "Take To Market" time lines.<br></br>

                                    &nbsp;&nbsp;&nbsp;
                                    when we started it was all about developing systems
                                    that would reduce costs associated with manual effort.
                                    Today, the demand is to build
                                    Apps that will
                                    Generate Revenue
                                    Take  to market quickly and
                                    integrate   with any other  systems in operation including  social media.
                                    Being technology independent,
                                    we build systems and utilities using all or any technology that is required or available.
                                    Acceptable Cost is the main criteria.<br></br>
                                    &nbsp;&nbsp;&nbsp;
                                    A potential customer comes to us mainly because they have a business opportunity
                                    that they know requires  technology; they want some advise or help,
                                    perhaps because their current software provider cannot or  will not support their requirement<br></br>

                                    To a lesser extent, they come to us because their current  legacy system
                                    does not do enough and requires a great deal
                                    of manual effort to use, and does not yield any substantial benefits.<br></br>
                                    &nbsp;&nbsp;&nbsp;
                                    Here's where our experience and expertise kicks in to identify the problem and offer a swift and cost-effective solution.
                                    Oh NO !!!... No!
                                    We are not that type of business consultants who will charge a hefty fee, take months to deliver only a mere list of recommendations.<br></br>

                                    We look at the problem, and if a solution is readily available, we divulge the information free of charge immediately.
                                    Because of our long experience, this type of action is possible in most cases, and
                                    If we are selected as a preferred partner to perform the tasks necessary, we quote for the work.
                                    In delivery we strive to fullfill the urgency of "Take To Market" time lines.<br></br>&nbsp;&nbsp;&nbsp;
                                  </p>
                                </div>
                              </Grid>
                              <Grid item xs={4}>
                                <div className={classes.phaMain}>
                                  <h3 style={{ marginBottom: '20px', textAlign: 'center' }}>The Talisman Travel system Built from the travel and leisure Frame workk</h3>
                                  <div>
                                    <ReactPlayer controls width='450px' height='300px' url='https://youtu.be/psuDtjGKcdc' />
                                  </div>
                                  <h3 style={{ textAlign: 'center', marginTop: '30px' }}>We've come a long way since this video.</h3>
                                  <p style={{ fontSize: '15px' }}>
                                    &nbsp;&nbsp;&nbsp; his video shows  Talisman in operation in the mid 1980's.
                                    The travel Industry was booming then,  but work methods were still very manual  and archaic.
                                    The same travel and leisure  framework  has come a long way from the 1980's.
                                    An Enterprise Service Bus now  stands between the Frame Work and many micro services used by  OTAs,  Tour operators, hoteliers, Channel Managers around the world.
                                  </p>
                                </div>

                              </Grid>

                            </Grid>


                          </div>
                        </Grid>
                      </Grid>
                      */}
                      </>
                    }
                 </div>
                  </div>
                  <div style={{ marginTop: '200px' }}>
                    <Footer />
                  </div>
            </div>
          </div>
          :
          <>
            {pageBackgroundColor != '' ?
              <div style={{

                backgroundSize: 'cover',
                //backgroundImage: `linear-gradient( 0deg , rgb(63 54 80), rgb(240 232 239 / 0%)),url(${pageBackgroundUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: `${pageBackgroundColor}`
              }}
              >
                <div class="scrollable-container-main">
                    <div class="scrollable-content">
                      <div className={classes.pageLayout}>

                        {pageTopHtml != '' ?
                          <>

                            <Grid container spacing={2}>
                              <Grid item xs={12} >
                                <div>
                                  {parseHtml(pageTopHtml)}
                                </div>
                              </Grid>
                            </Grid>

                          </>

                          :
                          <>
                          {/** 
                          <Grid container spacing={2}>
                            <Grid container spacing={2} className={classes.pageBodyStyleabout}>
                              <Grid item xs={12}>
                                <Box sx={{ minWidth: 120 }}>
                                  <Card className={classes.root}>
                                    <div className={classes.posRelative}>
                                      <CardMedia component="img" image={titleImage} title="Risk Group" alt="Ris Group" />
                                      <div className={classes.topImagePosabout}>
                                        <p className={classes.topHeaderTextabout}>
                                          OUR SERVICES
                                        </p>

                                      </div>
                                    </div>
                                  </Card>
                                </Box>
                              </Grid>
                              <div className={classes.margin}>
                                <Grid container spacing={2} className={classes.storytop}>
                                  <Grid item xs={7} >
                                    <div className={classes.phaMain}>
                                      <h3 style={{ marginBottom: '20px' }}>Frame Works</h3>
                                      <p style={{ fontSize: '15px' }}>

                                        Building a frame work is our speciality..
                                        A frame work is a conceptual structure of a particular industry.
                                        A frame work, once built, can do the heavy lifting of a complete system for that industry.
                                        myriads of facilities now available in technology, are useless unless they are applied to a definite and structured framework.
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <div >
                                      <img src='https://datam.co.uk/images/large-2033983.jpg' alt='image' style={{ width: '400px', height: '200px' }}></img>
                                    </div>
                                  </Grid>

                                </Grid>

                                <Grid container spacing={2} className={classes.storytop}>
                                  <Grid item xs={5}>
                                    <div>
                                      <img src='https://datam.co.uk/images/large-686316.jpg' alt='image' style={{ width: '400px', height: '200px' }}></img>
                                    </div>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <div className={classes.phaMain}>
                                      <h3 style={{ marginBottom: '20px' }}>Structures for a Framework</h3>
                                      <p style={{ fontSize: '15px' }}>

                                        To build a structure for a framework, an "activity sector" is required.
                                        Activity sectors usually are a section in a country's economic infrastructure.
                                        At the highest level the sectors in western democracies are Public and commercial.
                                        The commercial sector then divides into Manufacturing and Service industries
                                      </p>
                                    </div>

                                  </Grid>

                                </Grid>

                                <Grid container spacing={2} className={classes.storytop}>
                                  <Grid item xs={7} >
                                    <div className={classes.phaMain}>
                                      <h3 style={{ marginBottom: '20px' }}>SERVICES Sector</h3>
                                      <p style={{ fontSize: '15px' }}>

                                        The 1970s was a disastrous period for the UK .
                                        Manufacturing Industry was rife with industrial disputes between workers and management.
                                        Inflation was rampant.
                                        Factories and equipment were outdated and required modernisation.
                                        The services sectors were growing at a fair pace.
                                        We saw an opportunity to create system frameworks for service industies
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <div>
                                      <img src='https://datam.co.uk/images/large-3846597.png' alt='image' style={{ width: '400px', height: '200px' }}></img>
                                    </div>
                                  </Grid>

                                </Grid>

                                <Grid container spacing={2} className={classes.storytop}>
                                  <Grid item xs={8}>
                                    <div>
                                      <h3 style={{ textAlign: 'center' }}>What we Do</h3>
                                      <p style={{ fontSize: '15px' }}>
                                        &nbsp;&nbsp;&nbsp;
                                        when we started it was all about developing systems
                                        that would reduce costs associated with manual effort.
                                        Today, the demand is to build
                                        Apps that will
                                        Generate Revenue
                                        Take  to market quickly and
                                        integrate   with any other  systems in operation including  social media.
                                        Being technology independent,
                                        we build systems and utilities using all or any technology that is required or available.
                                        Acceptable Cost is the main criteria.<br></br>
                                        &nbsp;&nbsp;&nbsp;
                                        A potential customer comes to us mainly because they have a business opportunity
                                        that they know requires  technology; they want some advise or help,
                                        perhaps because their current software provider cannot or  will not support their requirement<br></br>
                                        &nbsp;&nbsp;&nbsp;
                                        To a lesser extent, they come to us because their current  legacy system
                                        does not do enough and requires a great deal
                                        of manual effort to use, and does not yield any substantial benefits.<br></br>
                                        &nbsp;&nbsp;&nbsp;
                                        Here's where our experience and expertise kicks in to identify the problem and offer a swift and cost-effective solution.
                                        Oh NO !!!... No!
                                        We are not that type of business consultants who will charge a hefty fee, take months to deliver only a mere list of recommendations.

                                        We look at the problem, and if a solution is readily available, we divulge the information free of charge immediately.
                                        Because of our long experience, this type of action is possible in most cases, and
                                        If we are selected as a preferred partner to perform the tasks necessary, we quote for the work.
                                        In delivery we strive to fullfill the urgency of "Take To Market" time lines.<br></br>

                                        &nbsp;&nbsp;&nbsp;
                                        when we started it was all about developing systems
                                        that would reduce costs associated with manual effort.
                                        Today, the demand is to build
                                        Apps that will
                                        Generate Revenue
                                        Take  to market quickly and
                                        integrate   with any other  systems in operation including  social media.
                                        Being technology independent,
                                        we build systems and utilities using all or any technology that is required or available.
                                        Acceptable Cost is the main criteria.<br></br>
                                        &nbsp;&nbsp;&nbsp;
                                        A potential customer comes to us mainly because they have a business opportunity
                                        that they know requires  technology; they want some advise or help,
                                        perhaps because their current software provider cannot or  will not support their requirement<br></br>

                                        To a lesser extent, they come to us because their current  legacy system
                                        does not do enough and requires a great deal
                                        of manual effort to use, and does not yield any substantial benefits.<br></br>
                                        &nbsp;&nbsp;&nbsp;
                                        Here's where our experience and expertise kicks in to identify the problem and offer a swift and cost-effective solution.
                                        Oh NO !!!... No!
                                        We are not that type of business consultants who will charge a hefty fee, take months to deliver only a mere list of recommendations.<br></br>

                                        We look at the problem, and if a solution is readily available, we divulge the information free of charge immediately.
                                        Because of our long experience, this type of action is possible in most cases, and
                                        If we are selected as a preferred partner to perform the tasks necessary, we quote for the work.
                                        In delivery we strive to fullfill the urgency of "Take To Market" time lines.<br></br>&nbsp;&nbsp;&nbsp;
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div className={classes.phaMain}>
                                      <h3 style={{ marginBottom: '20px', textAlign: 'center' }}>The Talisman Travel system Built from the travel and leisure Frame workk</h3>
                                      <div>
                                        <ReactPlayer controls width='450px' height='300px' url='https://youtu.be/psuDtjGKcdc' />
                                      </div>
                                      <h3 style={{ textAlign: 'center', marginTop: '30px' }}>We've come a long way since this video.</h3>
                                      <p style={{ fontSize: '15px' }}>
                                        &nbsp;&nbsp;&nbsp; his video shows  Talisman in operation in the mid 1980's.
                                        The travel Industry was booming then,  but work methods were still very manual  and archaic.
                                        The same travel and leisure  framework  has come a long way from the 1980's.
                                        An Enterprise Service Bus now  stands between the Frame Work and many micro services used by  OTAs,  Tour operators, hoteliers, Channel Managers around the world.
                                      </p>
                                    </div>

                                  </Grid>

                                </Grid>


                              </div>
                            </Grid>
                          </Grid>
                          */}
                          </>
                        }
                      </div>
                    </div>
                    <div style={{ marginTop: '200px' }}>
                      <Footer />
                    </div>
                  </div>
              </div>
              :
              <>
                <div style={{
                height: '100vh',
                backgroundSize: 'cover',
                backgroundImage: `linear-gradient( 0deg , rgb(63 54 80), rgb(240 232 239 / 0%)),url(https://assets.kpmg/is/image/kpmg/pointing-digital-screen:cq5dam.web.512.341)`,
                backgroundRepeat: 'no-repeat'
              }}
                >
                  <div class="scrollable-container-main">
                  <div class="scrollable-content">
                    <div className={classes.pageLayout}>
                          {pageTopHtml != '' ?
                            <>

                              <Grid container spacing={2}>
                                <Grid item xs={12} >
                                  <div>
                                    {parseHtml(pageTopHtml)}
                                  </div>
                                </Grid>
                              </Grid>

                            </>

                            :
                            <>
                            {/** 
                            <Grid container spacing={2}>
                              <Grid container spacing={2} className={classes.pageBodyStyleabout}>
                                <Grid item xs={12}>
                                  <Box sx={{ minWidth: 120 }}>
                                    <Card className={classes.root}>
                                      <div className={classes.posRelative}>
                                        <CardMedia component="img" image={titleImage} title="Risk Group" alt="Ris Group" />
                                        <div className={classes.topImagePosabout}>
                                          <p className={classes.topHeaderTextabout}>
                                            OUR SERVICES
                                          </p>

                                        </div>
                                      </div>
                                    </Card>
                                  </Box>
                                </Grid>
                                <div className={classes.margin}>
                                  <Grid container spacing={2} className={classes.storytop}>
                                    <Grid item xs={7} >
                                      <div className={classes.phaMain}>
                                        <h3 style={{ marginBottom: '20px' }}>Frame Works</h3>
                                        <p style={{ fontSize: '15px' }}>

                                          Building a frame work is our speciality..
                                          A frame work is a conceptual structure of a particular industry.
                                          A frame work, once built, can do the heavy lifting of a complete system for that industry.
                                          myriads of facilities now available in technology, are useless unless they are applied to a definite and structured framework.
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <div >
                                        <img src='https://datam.co.uk/images/large-2033983.jpg' alt='image' style={{ width: '400px', height: '200px' }}></img>
                                      </div>
                                    </Grid>

                                  </Grid>

                                  <Grid container spacing={2} className={classes.storytop}>
                                    <Grid item xs={5}>
                                      <div>
                                        <img src='https://datam.co.uk/images/large-686316.jpg' alt='image' style={{ width: '400px', height: '200px' }}></img>
                                      </div>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <div className={classes.phaMain}>
                                        <h3 style={{ marginBottom: '20px' }}>Structures for a Framework</h3>
                                        <p style={{ fontSize: '15px' }}>

                                          To build a structure for a framework, an "activity sector" is required.
                                          Activity sectors usually are a section in a country's economic infrastructure.
                                          At the highest level the sectors in western democracies are Public and commercial.
                                          The commercial sector then divides into Manufacturing and Service industries
                                        </p>
                                      </div>

                                    </Grid>

                                  </Grid>

                                  <Grid container spacing={2} className={classes.storytop}>
                                    <Grid item xs={7} >
                                      <div className={classes.phaMain}>
                                        <h3 style={{ marginBottom: '20px' }}>SERVICES Sector</h3>
                                        <p style={{ fontSize: '15px' }}>

                                          The 1970s was a disastrous period for the UK .
                                          Manufacturing Industry was rife with industrial disputes between workers and management.
                                          Inflation was rampant.
                                          Factories and equipment were outdated and required modernisation.
                                          The services sectors were growing at a fair pace.
                                          We saw an opportunity to create system frameworks for service industies
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <div>
                                        <img src='https://datam.co.uk/images/large-3846597.png' alt='image' style={{ width: '400px', height: '200px' }}></img>
                                      </div>
                                    </Grid>

                                  </Grid>

                                  <Grid container spacing={2} className={classes.storytop}>
                                    <Grid item xs={8}>
                                      <div>
                                        <h3 style={{ textAlign: 'center' }}>What we Do</h3>
                                        <p style={{ fontSize: '15px' }}>
                                          &nbsp;&nbsp;&nbsp;
                                          when we started it was all about developing systems
                                          that would reduce costs associated with manual effort.
                                          Today, the demand is to build
                                          Apps that will
                                          Generate Revenue
                                          Take  to market quickly and
                                          integrate   with any other  systems in operation including  social media.
                                          Being technology independent,
                                          we build systems and utilities using all or any technology that is required or available.
                                          Acceptable Cost is the main criteria.<br></br>
                                          &nbsp;&nbsp;&nbsp;
                                          A potential customer comes to us mainly because they have a business opportunity
                                          that they know requires  technology; they want some advise or help,
                                          perhaps because their current software provider cannot or  will not support their requirement<br></br>
                                          &nbsp;&nbsp;&nbsp;
                                          To a lesser extent, they come to us because their current  legacy system
                                          does not do enough and requires a great deal
                                          of manual effort to use, and does not yield any substantial benefits.<br></br>
                                          &nbsp;&nbsp;&nbsp;
                                          Here's where our experience and expertise kicks in to identify the problem and offer a swift and cost-effective solution.
                                          Oh NO !!!... No!
                                          We are not that type of business consultants who will charge a hefty fee, take months to deliver only a mere list of recommendations.

                                          We look at the problem, and if a solution is readily available, we divulge the information free of charge immediately.
                                          Because of our long experience, this type of action is possible in most cases, and
                                          If we are selected as a preferred partner to perform the tasks necessary, we quote for the work.
                                          In delivery we strive to fullfill the urgency of "Take To Market" time lines.<br></br>

                                          &nbsp;&nbsp;&nbsp;
                                          when we started it was all about developing systems
                                          that would reduce costs associated with manual effort.
                                          Today, the demand is to build
                                          Apps that will
                                          Generate Revenue
                                          Take  to market quickly and
                                          integrate   with any other  systems in operation including  social media.
                                          Being technology independent,
                                          we build systems and utilities using all or any technology that is required or available.
                                          Acceptable Cost is the main criteria.<br></br>
                                          &nbsp;&nbsp;&nbsp;
                                          A potential customer comes to us mainly because they have a business opportunity
                                          that they know requires  technology; they want some advise or help,
                                          perhaps because their current software provider cannot or  will not support their requirement<br></br>

                                          To a lesser extent, they come to us because their current  legacy system
                                          does not do enough and requires a great deal
                                          of manual effort to use, and does not yield any substantial benefits.<br></br>
                                          &nbsp;&nbsp;&nbsp;
                                          Here's where our experience and expertise kicks in to identify the problem and offer a swift and cost-effective solution.
                                          Oh NO !!!... No!
                                          We are not that type of business consultants who will charge a hefty fee, take months to deliver only a mere list of recommendations.<br></br>

                                          We look at the problem, and if a solution is readily available, we divulge the information free of charge immediately.
                                          Because of our long experience, this type of action is possible in most cases, and
                                          If we are selected as a preferred partner to perform the tasks necessary, we quote for the work.
                                          In delivery we strive to fullfill the urgency of "Take To Market" time lines.<br></br>&nbsp;&nbsp;&nbsp;
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <div className={classes.phaMain}>
                                        <h3 style={{ marginBottom: '20px', textAlign: 'center' }}>The Talisman Travel system Built from the travel and leisure Frame workk</h3>
                                        <div>
                                          <ReactPlayer controls width='450px' height='300px' url='https://youtu.be/psuDtjGKcdc' />
                                        </div>
                                        <h3 style={{ textAlign: 'center', marginTop: '30px' }}>We've come a long way since this video.</h3>
                                        <p style={{ fontSize: '15px' }}>
                                          &nbsp;&nbsp;&nbsp; his video shows  Talisman in operation in the mid 1980's.
                                          The travel Industry was booming then,  but work methods were still very manual  and archaic.
                                          The same travel and leisure  framework  has come a long way from the 1980's.
                                          An Enterprise Service Bus now  stands between the Frame Work and many micro services used by  OTAs,  Tour operators, hoteliers, Channel Managers around the world.
                                        </p>
                                      </div>

                                    </Grid>

                                  </Grid>


                                </div>
                              </Grid>
                            </Grid>
                            */}
                            </>

                          }
                        
</div>
                  </div>
                  <div style={{ marginTop: '200px' }}>
                    <Footer />
                  </div>
                </div>
              </div>


              </>
            }








          </>
        }
      </main>
      <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>


    </>
  )
}

export default OurServices
