const surveryQuestionScale = {
    scales:[
        {
            name:"likert",
            examples:[
                {
                    id:"frequency",
                    name:"Frequency",
                    types:[
                        {
                            name:"Never",
                        },
                        {
                            name:"Rarely",
                        },
                        {
                            name:"Sometimes",
                        },
                        {
                            name:"Often",
                        },
                        {
                            name:"Always",
                        }
                    ]
                },
                {
                    id:"quality",
                    name:"Quality",
                    types:[
                        {
                            name:"Very poor",
                        },
                        {
                            name:"Poor",
                        },
                        {
                            name:"Fair",
                        },
                        {
                            name:"Good",
                        },
                        {
                            name:"Excellent",
                        }
                    ]
                },
                {
                    id:"intensity",
                    name:"Intensity",
                    types:[
                        {
                            name:"None",
                        },
                        {
                            name:"Very mild",
                        },
                        {
                            name:"Mild",
                        },
                        {
                            name:"Moderate",
                        },
                        {
                            name:"Sever",
                        }
                    ]
                },
                {
                    id:"agreement",
                    name:"Agreement",
                    types:[
                        {
                            name:"Strongly disagree",
                        },
                        {
                            name:"Disagree",
                        },
                        {
                            name:"Neither agree nor disagree",
                        },
                        {
                            name:"Agree",
                        },
                        {
                            name:"Strongly agree",
                        }
                    ]
                },
                {
                    id:"approval",
                    name:"Approval",
                    types:[
                        {
                            name:"Strongly disapprove",
                        },
                        {
                            name:"Disapprove",
                        },
                        {
                            name:"Neutral",
                        },
                        {
                            name:"Approve",
                        },
                        {
                            name:"Strongly approve",
                        }
                    ]
                },
                {
                    id:"awareness",
                    name:"Awareness",
                    types:[
                        {
                            name:"Not at all aware",
                        },
                        {
                            name:"Slightly aware",
                        },
                        {
                            name:"Modarately aware",
                        },
                        {
                            name:"Very aware",
                        },
                        {
                            name:"Extremely aware",
                        }
                    ]
                },
                {
                    id:"importance",
                    name:"Importance",
                    types:[
                        {
                            name:"Not at all important",
                        },
                        {
                            name:"Slightly important",
                        },
                        {
                            name:"Moderately important",
                        },
                        {
                            name:"Very important",
                        },
                        {
                            name:"Extremely important",
                        }
                    ]
                },
                {
                    id:"familiarity",
                    name:"Familiarity",
                    types:[
                        {
                            name:"Not at all familiar",
                        },
                        {
                            name:"Slightly familiar",
                        },
                        {
                            name:"Moderately familiar",
                        },
                        {
                            name:"Very familiar",
                        },
                        {
                            name:"Extremely familiar",
                        }
                    ]
                },
                {
                    id:"satisfaction",
                    name:"Satisfaction",
                    types:[
                        {
                            name:"Not at all satisfied",
                        },
                        {
                            name:"Slightly satisfied",
                        },
                        {
                            name:"Moderately satisfied",
                        },
                        {
                            name:"Very satisfied",
                        },
                        {
                            name:"Completely satisfied",
                        }
                    ]
                },
                {
                    id:"performance",
                    name:"Performance",
                    types:[
                        {
                            name:"Far below standards",
                        },
                        {
                            name:"Below standards",
                        },
                        {
                            name:"Meets standards",
                        },
                        {
                            name:"Above standards",
                        },
                        {
                            name:"Far above standards",
                        }
                    ]
                }        
            ]
        },
        {
            name:"semanticdifferential",
            examples:[
                {
                    id:"evaluation",
                    name:"Evaluation",
                    types:[
                        {
                            name:"Good to bad",
                        },
                        {
                            name:"Safe to dangerous",
                        },
                        {
                            name:"Excellent to poor",
                        }
                    ]
                },
                {
                    id:"potency",
                    name:"Potency",
                    types:[
                        {
                            name:"High to low",
                        },
                        {
                            name:"Strong to weak",
                        }
                    ]
                },
                {
                    id:"activity",
                    name:"Activity",
                    types:[
                        {
                            name:"Fast to slow",
                        },
                        {
                            name:"Active to passive",
                        }
                    ]
                }
            ]
        },
        {
            name:"dichotomous",
            examples:[
                {
                    id:"yesNo",
                    name:"Yes/No Type",
                    types:[
                        {
                            name:"Yes",
                        },
                        {
                            name:"No",
                        }
                    ]
                },
                {
                    id:"trueFalse",
                    name:"True/False Type",
                    types:[
                        {
                            name:"True",
                        },
                        {
                            name:"False",
                        }
                    ]
                },
                {
                    id:"fairUnfair",
                    name:"Fair/Unfair",
                    types:[
                        {
                            name:"Fair",
                        },
                        {
                            name:"Unfair",
                        }
                    ]
                },
                {
                    id:"agreeDisagree",
                    name:"Agree/Disagree",
                    types:[
                        {
                            name:"Agree",
                        },
                        {
                            name:"Disagree",
                        }
                    ]
                },
                {
                    id:"likeDislike",
                    name:"Like/Dislike",
                    types:[
                        {
                            name:"Like",
                        },
                        {
                            name:"Dislike",
                        }
                    ]
                }
            ]
        }                
    ]
 };
 export default surveryQuestionScale;