import React, { useEffect, useState, useRef } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import CardContent from '@mui/material/CardContent';
import { CONFIG } from '../config'
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info, KeyboardReturnOutlined } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import '../Css/Slider.css';
import Draggable from 'react-draggable';
import useStyle from '../Css/MainCss';
import Delete from '@mui/icons-material/Delete';
import { Editor } from '@tinymce/tinymce-react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import parseHtml from 'html-react-parser';



function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  root: {
    position: 'relative',
  },
  cardRiskManage: {
    width: '100%',
    border: '1px solid black'
  },
  tableTrStyle: {
    height: '19px !important'
  },
  mousePointer: {
    cursor: 'pointer'
  },
  stdMainCardHeader: {
    backgroundColor: '#4C7EE0 !important',
    fontSize: '13px !important',
    height: '50px',
    padding: '0px',
    marginTop: '-7px',
    color:'white'
  },
  addrisktypebtn: {
    marginLeft: '0px !important',
    border: '1px solid #4C7EE0 !important',
    color: '#4C7EE0 !important',
    padding: '5px 10px !important',
    height: '40px !important',
  },

  btnBorderpurple: {
    border: '1px solid #9155FD !important',
    color: '#9155FD !important',
    padding: '5px 10px !important',
    height: '40px !important',
  },
  stdCard: {
    height: '100%',
    border: '1px solid black'
  },
  scrollableDivToStandard: {
    height: '350px',
    overflow: 'auto',
    width: '100%',
  },

}));


const SuperAdminLoginAddCollection = ({tinyMCETemplateList}) => {
  const classe = useStyle();
  const classes = useStyles();
  const Input = styled('input')({
    display: 'none',
  });

  const editorRef = useRef(null);
  const [golobalCentreId, setGolobalCentreId] = React.useState('');

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    getData();
  }, [])


  function getData() {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId);
    getAllCollectionData();
  }

  const [collectionData, setCollectionData] = useState([])

  
  
  //var preNames = [];

    


  function getAllCollectionData() {
    axios(CONFIG.MAIN_URL + `/availableCollections/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
    .then(function (response) {
        console.log("All Collections Returns");
        console.log(response.data);
        setCollectionData(response.data);
    })
    .catch(function (error) {
        console.log(error);
    });
  }




  // console.log(selectedFile)
  const [saveBtnName, setSaveBtnName] = useState("Save");
  const [createheaderInfoMessage, setCreateHeaderInfoMessage] = useState("Create A New Collection Name");
  const [collectionName, setCollectionName] = useState('')
  const [collectionShortDescription, setCollectionShortDescription] = useState('')
  const [collectionDescription, setCollectionDescription] = useState('')
  const [editCollectionId, setEditCollectionId] = useState('')
  const [showCollectionTable, setShowCollectionTable] = React.useState(true);
  const [orderNumber, setOrderNumber] = useState()

  const orderNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setOrderNumber(value);
  }

  const collectionNameChange = (event) => {
    setCollectionName(event.target.value);
  }

  const collectionShortDescriptionChange = (event) => {
    setCollectionShortDescription(event.target.value);
  }
  
  function editCollectionData(id) {
    setShowCollectionTable(false);
    setFrameSize(12)
    setEditCollectionId(id);
    collectionData.forEach((data) => {
      if (data.id === id) {
        setEditCollectionId(data.id);
        setCollectionName(data.collectionName);
        setCollectionShortDescription(data.shortDescription);
        setCollectionDescription(data.description);
        editorRef.current.setContent(data.description);
        setOrderNumber(data.orderNumber);
        setSaveBtnName("Update");
        setCreateHeaderInfoMessage("Edit Collection");
      }
    })
  }

  function deleteCollectionData(id){
    var arr = [];
    collectionData.forEach((item) => {
      if (item.id === id) {
        var collectionName = item.collectionName;
        var sendJson = JSON.stringify(item);
        console.log("+++++++++++++++++++sendJson To Delete++++++++++++++++++++");
        console.log(sendJson);
        var endPoint = '/availableCollections/delete';
        axios(CONFIG.MAIN_URL+endPoint,{
          method: 'post',
          headers: { 
            'Content-Type': 'application/json',   
          },  
          data:sendJson
        })
        .then(res =>  {
          
          alert('Successfully Deleted the collection \" ' + collectionName + '\"' );
          clearFuction();
          getAllCollectionData();
        })
        .catch(function (error) {
          alert('Error Deleting the collection \" ' + collectionName + '\"' );
          console.log(error);
        });
      }
    })

  }

  function saveCollectionFunction(){
    if (collectionName === ''){
      alert("Please enter Collection Name");
      return;
    }
    if (collectionShortDescription === ''){
      alert("Please enter Collection Short Description");
      return;
    }

    var desc = '';
    if (editorRef.current) {
      desc = editorRef.current.getContent();
    }
    if (desc === ''){
      alert("Please enter Collection Description");
      return;
    }
    if (orderNumber === null || orderNumber === 0 || orderNumber === ''){
      alert("Please enter Collection Order Number");
      return;
    }

    var obj= {};
    if (editCollectionId != ''){
      obj["id"] = editCollectionId;
    }
    obj["collectionName"] = collectionName;
    obj["shortDescription"] = collectionShortDescription;
    obj["description"] = desc;
    obj["linkUrl"] = '';
    obj["orderNumber"] = orderNumber;

    var sendJson = JSON.stringify(obj);
    console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
    console.log(sendJson);
    var endPoint = '/availableCollections/save';
    if (editCollectionId != ''){
      endPoint = '/availableCollections/update';
    }
    
    axios(CONFIG.MAIN_URL+endPoint,{
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',   
      },  
      data:sendJson
    })
    .then(res =>  {
      
      if (editCollectionId != ''){
        alert('Successfully Updated the Collection details');
      }else{
        alert('Successfully Save the Collection detailss');
      }  
      clearFuction();
      getAllCollectionData();
    })
    .catch(function (error) {
      alert('Error Updating the collection' );
      console.log(error);
    });
  }

  function clearFuction(){
    setFrameSize(8);
    setShowCollectionTable(true);
    setEditCollectionId('');
    setCollectionName('');
    setCollectionDescription('');
    setCollectionShortDescription('');
    editorRef.current.setContent('');
    setOrderNumber('');
    setSaveBtnName("Save");
    setCreateHeaderInfoMessage("Create A New Collection Name");
  }



  const previousCollectionTableRowData = (
    <TableBody>
      {collectionData.map((item) => {
        return (
          <StyledTableRow key={item.id} className={classes.tableTrStyle}>
            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
              <div style={{ whiteSpace: 'normal' }}>{item.collectionName}</div>
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={classes.tableTrStyle}
            >
              <EditIcon
                className={classes.mousePointer}
                onClick={() => { editCollectionData(item.id) }}
              />
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={classes.tableTrStyle}
            >
              <Delete
                className={classes.mousePointer}
                onClick={() => { deleteCollectionData(item.id) }}
              />
            </StyledTableCell>
          </StyledTableRow>
        )
      })}
    </TableBody>
  )


  const [frameSize, setFrameSize] = useState(8)

  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  function imagePreviw(url) {
        var str = "<img src=\"" + url + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      
  }   
  


  return (
    <>
        <div>
            <Grid >
                <Grid container spacing={2}>
                <>
                <Grid item xs={frameSize} >
                    <Card className={classes.cardRiskManage} >
                        <CardContent>
                            <Card >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                        <Info />
                                        </Avatar>
                                    }
                                    className={classe.commonMainTableHead}
                                    title={createheaderInfoMessage}
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { mt: 1, width: '100%' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                                >
                                                <div>
                                                    <TextField
                                                        id="title"
                                                        label="Collection Name"
                                                        multiline
                                                        maxRows={2}
                                                        name='title'
                                                        required
                                                        value={collectionName}
                                                        onChange={collectionNameChange}
                                                    />
                                                </div>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { mt: 1, width: '100%' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                                >
                                                <div>
                                                    <TextField
                                                        id="title"
                                                        label="Collection Short Description"
                                                        multiline
                                                        maxRows={4}
                                                        name='title'
                                                        required
                                                        value={collectionShortDescription}
                                                        onChange={collectionShortDescriptionChange}
                                                    />
                                                </div>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Editor
                                                apiKey='ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj'
                                                tinymceScriptSrc={'https://cdn.tiny.cloud/1/ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj/tinymce/6/tinymce.min.js'}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                initialValue={collectionDescription}
                                                init={{
                                                  referrer_policy: 'origin',  
                                                  height: 300,
                                                    menubar: true,
                                                    placeholder: 'Collection Description',
                                                    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media mediaembed template codesample table advtable charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help charmap quickbars emoticons casechange formatpainter pageembed hr fullpage advcode export permanentpen powerpaste',
                                                    imagetools_cors_hosts: ['picsum.photos'],
                                                    menubar: 'file edit view insert format tools table help',
                                                    toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | casechange forecolor backcolor formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview print | pageembed insertfile image media template tokens link anchor codesample | ltr rtl hr fullpage export permanentpen configurepermanentpen',
                                                    //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                                                    export_image_proxy: 'proxy.php',
                                                    toolbar_sticky: true,
                                                    autosave_ask_before_unload: true,
                                                    autosave_interval: '30s',
                                                    autosave_prefix: '{path}{query}-{id}-',
                                                    autosave_restore_when_empty: false,
                                                    autosave_retention: '2m',
                                                    setup: (editor) => {
                                                      editor.on('init', function (e) {
                                                                 var content = unescape(collectionDescription);
                                                                 editor.setContent(content);
                                                                 editor.dom.bind(editor.getBody(), 'click', function (e) {
                                                                  var target = e.target;
                                                          
                                                                  // Check if the clicked element is an image
                                                                  if (target.nodeName.toLowerCase() === 'img') {
                                                                    // Your onclick function logic here
                                                                    imagePreviw(target.src);  // Replace this with your actual onclick function
                                                                  }
                                                                });

                                                      });
                                                  },
                                          
                                          
                                                  image_title: true,
                                                  /* enable automatic uploads of images represented by blob or data URIs*/
                                                  automatic_uploads: true,
                                                  /*Here we add custom filepicker only to Image dialog*/
                                                  file_picker_types: 'image',
                                          
                                                  /* and here's our custom image picker*/
                                                  file_picker_callback: function (cb, value, meta) {
                                                      var input = document.createElement('input');
                                                      input.setAttribute('type', 'file');
                                                      input.setAttribute('accept', 'image/*');
                                          
                                                      input.onchange = function () {
                                                          var file = this.files[0];
                                                          var reader = new FileReader();
                                          
                                                          reader.onload = function () {
                                                              /*
                                                              Note: Now we need to register the blob in TinyMCEs image blob
                                                              registry. In the next release this part hopefully won't be
                                                              necessary, as we are looking to handle it internally.
                                                              */
                                                              var id = 'blobid' + (new Date()).getTime();
                                                              
                                                              var blobCache = editorRef.current.editorUpload.blobCache;
                                                              //var blobCache = editorRef.current.activeEditor.editorUpload.blobCache;
                                                              var base64 = reader.result.split(',')[1];
                                          
                                                              var blobInfo = blobCache.create(id, file, base64);
                                                              blobCache.add(blobInfo);
                                          
                                                              /* call the callback and populate the Title field with the file name */
                                                              cb(blobInfo.blobUri(), {title: file.name});
                                                          };
                                          
                                                          reader.readAsDataURL(file);
                                                      };
                                          
                                                      input.click();
                                                  },
                                          
                                                  //images_upload_handler: function (blobInfo, success, failure) {
                                                  //},
                                          
                                                  templates : tinyMCETemplateList,
                                                  /*
                                                  templates : [
                                                    {
                                                      title: 'Date modified example',
                                                      description: 'Adds a timestamp indicating the last time the document modified.',
                                                      content: '<p>Last Modified: This will be replaced with the date modified.</time></p>'
                                                    },
                                                    {
                                                      title: 'Replace values example',
                                                      description: 'These values will be replaced when the template is inserted into the editor content.',
                                                      content: '<p>Name: StaffID: </p>'
                                                    }
                                                  ],
                                                  */
                                                  referrer_policy: 'origin',
                                          
                                                    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                                                    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                                                    image_caption: true,
                                                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                                    noneditable_noneditable_class: 'mceNonEditable',
                                                    toolbar_mode: 'sliding',
                                                    contextmenu: 'link image imagetools table',
                                                    //content_css: useDarkMode ? 'dark' : 'default',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                             



                                                
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                        <Box
                                                component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { mt: 1, width: '100%' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                                >
                                                <div>
                                                    <TextField
                                                        id="title"
                                                        label="Order Number"
                                                        name='title'
                                                        required
                                                        value={orderNumber}
                                                        onChange={orderNumberChange}
                                                    />
                                                </div>
                                            </Box>        
                                        </Grid>
                                        <Grid item xs={6} style={{textAlign: 'right'}}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ marginTop: '10px', height: '46px' }}
                                                onClick={() => {
                                                    saveCollectionFunction();
                                                }}
                                                >
                                                {saveBtnName}
                                            </Button>&nbsp;&nbsp;
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ marginTop: '10px', height: '46px' }}
                                                onClick={() => {
                                                    clearFuction()
                                                }}
                                                >
                                                Clear
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardContent>
                    </Card>
                </Grid>
                {
                  showCollectionTable ?
                    <Grid item xs={4}>
                        <Card className={classes.stdCard}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                    <Info />
                                    </Avatar>
                                }
                                className={classes.stdMainCardHeader}
                                title="Collection Created"
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className={classes.scrollableDivToStandard}>
                                            <TableContainer>
                                                <Table aria-label="customized table">
                                                    <TableHead className={classe.commonSecondaryTableHead}>
                                                        <TableRow>
                                                            <StyledTableCell className={classe.commonTableThStyle} style={{ width: '70%' }}>Collection Name</StyledTableCell>
                                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '15%' }}>Edit</StyledTableCell>
                                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '15%' }}>Delete</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {previousCollectionTableRowData}
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    : null
                }
            </>
          </Grid>
        </Grid>
      </div>

      <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>
    </>
  )
}

export default SuperAdminLoginAddCollection
