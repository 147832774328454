import * as React from 'react';
import Cube from 'react-3d-cube';
//import '../Css/cube.css'
import { makeStyles, Tooltip } from "@material-ui/core";
import { RotateLeftSharp, RotateRight } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({ 
  centerDiv: {
    margin: 'auto',
    border: '1px solid white',
    padding: '10px',
  },
  rotateXYZ: {
    transform: 'translate3d(0px, 0px, -150px) rotateX(0deg) rotateY(20deg) rotateZ(0deg) !important'
  },
  box1:{
    transform: 'translateZ(100px)'
  },
  
  box2: {
    transform: 'rotateY(90deg) translateX(100px)',
    transformOrigin: 'right',
  },
  
  box3: {
    transform: 'rotateY(180deg) translateZ(100px)',
  },
  
  box4: {
    transform: 'rotateY(-90deg) translateX(-100px)',
    transformOrigin: 'left',
  },
  
  box5: {
    transform: 'rotateX(-90deg) translateY(-100px)',
    transformOrigin: 'top',
  },
  box6: {
    transform: 'rotateX(90deg) translateY(100px)',
    transformOrigin: 'bottom',
  },

}));

const LogoCube = ({objectiveList,componentList,principalList,entityList}) => {

  function getBgColor(index){
    var colorStr =  '#ffcc00';
    if (index === 1 ){
      colorStr =  '#ff6600';
    }
    if (index === 2 ){
      colorStr =  '#ff0000';
    }
    if (index === 3 ){
      colorStr =  '#00cc00';
    }
    if (index === 4 ){
      colorStr =  '#0099ff';
    }
    if (index === 5 ){
      colorStr =  '#996600';
    }
    if (index === 6 ){
      colorStr =  '#009999';
    }
    if (index === 7 ){
      colorStr =  '#ff00ff';
    }
    if (index === 8 ){
      //colorStr =  '#666699';
      colorStr =  '#ff99cc';
      
    }
    if (index === 9 ){
      //colorStr =  '#666600';
      colorStr =  '#ffff00';
    }
    if (index === 10 ){
      colorStr =  '#00ffff';
    }
    if (index === 11 ){
      //colorStr =  '#ff0066';
      colorStr =  '#cccc00';
    }
    if (index === 12 ){
      colorStr =  '#339966';
    }
    if (index === 13 ){
      colorStr =  '#6666ff';
    }
    if (index === 14 ){
      colorStr =  '#ff9966';
    }
    return colorStr;
  }
  const classes = useStyles();  


    return (
      <div>
        <center>
            <h1>&nbsp;</h1>
            <div
            style={{
              width: 300,
              height: 300
            }}
            >
              
            <Cube size={300} index="front" style={{transform:'translateZ(-100px)'}}>
                <div style={{backgroundColor: 'red', height: '100%', width: '100%',color: 'white',overflowY: 'scroll'}}>
                  <div 
                      className={classes.centerDiv} 
                      style={{backgroundColor: (getBgColor(0)),color: 'black',height: '10%', fontSize: '14px !important', fontWeight: 'bold'}}
                      >
                      Entities
                      <span style={{marginTop: '-9px', float: 'right',marginRight: '-8px'}}><Tooltip title="Drag to view Objectives, Components and Principles"><RotateRight/></Tooltip></span>
                  </div>
                  {entityList.map((row,index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
                    <div 
                      className={classes.centerDiv} 
                      style={{backgroundColor: getBgColor(index+1),color: 'black',height: (90/entityList.length) + '%'}}>
                      {row.entityName}
                    </div>  
                  )
                }
              })}
                    
                </div>
                <div style={{backgroundColor: 'green', height: '100%', width: '100%',color: 'white'}}>&nbsp;</div>
                <div style={{backgroundColor: 'blue', height: '100%', width: '100%',color: 'white'}}>&nbsp;</div>
                <div style={{backgroundColor: 'yellow', height: '100%', width: '100%',color: 'white',overflowY: 'scroll'}}>
                    <div 
                      className={classes.centerDiv} 
                      style={{backgroundColor: (getBgColor(0)),color: 'black',height: '10%', fontSize: '14px !important', fontWeight: 'bold'}}
                      >
                      Components
                    </div>
                  {componentList.map((row,index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
                    <div 
                      className={classes.centerDiv} 
                      style={{backgroundColor: (getBgColor(index+1)),color: 'black'}}
                      >
                      {row.name}
                    </div>  
                  )
                }
              })}
                </div>
                <div style={{backgroundColor: 'black', height: '100%', width: '100%',color: 'white',overflowY: 'scroll'}}>
                  <div 
                      className={classes.centerDiv} 
                      style={{backgroundColor: (getBgColor(0)),color: 'black',height: '10%', fontSize: '14px !important', fontWeight: 'bold'}}
                      >
                      Objectives
                    </div>
                  {objectiveList.map((row,index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
                    <div 
                      className={classes.centerDiv} 
                      style={{backgroundColor: getBgColor(index+1),color: 'black'}}>
                      {row.name}
                    </div>  
                  )
                }
              })}
                </div>
                <div style={{backgroundColor: '#ff6600', height: '100%', width: '100%',color: 'white',overflowY: 'scroll'}}>
                  <div 
                      className={classes.centerDiv} 
                      style={{backgroundColor: (getBgColor(0)),color: 'black', fontSize: '14px !important', fontWeight: 'bold'}}
                      >
                      Principles
                  </div>
                  {principalList.map((row,index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
                    <div 
                      className={classes.centerDiv} 
                      style={{backgroundColor: getBgColor(index+1),color: 'black'}}>
                      <span style={{fontWeight: 'bold'}}>{row.shortName}</span> - {row.name}
                    </div>  
                  )
                }
              })}

                </div>
            </Cube>
            </div>
        </center>
      </div>
    );
}
export default LogoCube
