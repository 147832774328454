import React, { useEffect, ReactDOM, useState } from 'react'
import { Grid, makeStyles, Tooltip } from "@material-ui/core";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import { entityData, standards } from '../data';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Links from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import { useSearchParams } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import {CONFIG} from '../config'
import { standardDataJs } from '../dataB'; 
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Close, Info } from '@mui/icons-material';
import { Paper } from '@mui/material';
import Draggable from 'react-draggable'; 
import { width } from '@mui/system';
import Divider from '@mui/material/Divider';
import {CardActionArea, CardActions } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import RiskTypeQustionDisplay from '../Components/RiskTypeQustionDisplay';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import CircularProgress from '@mui/material/CircularProgress';
import RightBarDataForUserPages from '../Components/RightBarDataForUserPages';
import useStyle from '../Css/MainCss';
import AddQustionCloseEnded from '../Components/AddQustionCloseEnded';
import AddQustionOpenEnded from '../Components/AddQustionOpenEnded';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  
  const useStyles = makeStyles((theme) => ({ 
    right: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    pageBtn:{
      height: '80px',
      width: '180px',
      backgroundColor: '#f9f9f9 !important',
      color: '#9481df !important',
      borderRadius: '12px !important',
    },
    globalBtn:{
      height: '80px',
      width: '180px',
      backgroundColor: '#f9f9f9 !important',
      color: '#9481df !important',
      borderRadius: '12px !important',
    },
    globalBtnAfter:{
      height: '80px',
      width: '180px',
      backgroundColor: '#d6c3fc !important',
      color: 'black !important',
      borderRadius: '12px !important',
    },
    pageBtnRisk:{
      height: '30px',
      width: '210px',
      backgroundColor: '#f9f9f9 !important',
      color: '#9481df !important',
      borderRadius: '5px !important',
    },
    cardBodyH6: {
      fontSize: '14px !important',
    },
    headerLable:{
      color: '#827d7d',
      marginLeft: '25px',
    },
    pageTop:{
      marginTop: '115px',
    },
    pageBodyStyle: {
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '12px',
      backgroundColor: '#f7f7f7',
      marginLeft: '25px',
    },
    mainbtn:{
        justifyContent:'center'
    },
    qusMainCardHeader:{
      backgroundColor:'#d6c3fc !important',
      fontSize:'13px !important',
      height:'44px',
      padding:'0px',
      marginTop: '-7px',
    },
    qusCardHeader:{
      backgroundColor:'#7B9DE0 !important',
      fontSize:'13px !important',
      height:'44px',
      padding:'0px',
      marginTop: '-7px',
    },
    popupdiv:{
          marginLeft:'15%',
          //marginTop:'-50%',
          border:'2px solid black',
          width:'700px',
          backgroundColor:'#f6f5fc !important',
          boxShadowhadow: '5px 10px #888888',
          resize: 'horizontal',
          overflow: 'auto',
          height: '100%',
          
          
    },
    cardcencel:{
       // alignItems:'right !important',
      //  marginLeft:'100px !important'
          float:'right !important'
    },
      stdButtonsStyle:{
          fontSize:'0.70rem !important', 
          height: '50px !important', 
          //paddingRight: '7px !important',
          //paddingLeft: '5px !important',
      },
      cardClose: {
          float: 'right !important'
      },
      topHeaderText1Move: {
        color: '#ffffff',
        fontSize:'20px', 
        paddingBottom:'10px',
        textAlign:'center'
      },
      topHeaderText2Move: {
        color: '#ffffff',
        fontSize:'12px', 
        paddingBottom:'10px',
        textAlign:'center',
        cursor: 'pointer'
      },
      cardDivMove:{
        border:'2px solid #7a769b',
        backgroundColor:'#696873 !important',
        boxShadowhadow: '5px 10px #69676e',
        borderRadius: '5px !important',
        BorderStyle: 'rigid'
      },
      cardTitleMove: {
        fontSize:'15px !important', 
        textAlign:'center',
        fontWeight: '500',
        paddingBottom:'10px',
        color: 'white'
       },
       leftPageNumberStyle: {
        marginTop: '-91px !important',
        marginLeft: '-17px !important',
        padding: '0px !important',
        backgroundColor: 'black !important',
        fontSize: '15px !important',
        maxWidth: '26px !important', 
        maxHeight: '26px !important', 
        minWidth: '26px !important', 
        minHeight: '26px !important'
      },
      rightPageNumberStyle: {
        float: 'right !important',
        marginTop: '-49px !important',
        marginRight: '-16px !important',
        padding: '0px !important',
        backgroundColor: 'black !important',
        fontSize: '15px !important',
        maxWidth: '26px !important', 
        maxHeight: '26px !important', 
        minWidth: '26px !important', 
        minHeight: '26px !important'
      },
      mutimenuPageHeadingStyle: {
        fontSize:'14px !important', 
        fontWeight: 'normal !important', 
        marginTop:'-68px !important', 
        color:'#b60707', 
        marginLeft: '12px !important'
      },
      stdCard: {
        height: '100%',
        border: '1px solid black'
      },
      stdMainCardHeader: {
        backgroundColor: '#acacbe !important',
        fontSize: '13px !important',
        height: '50px',
        padding: '0px',
        marginTop: '-7px',
      },
      scrollableDivToStandard: {
        height: '250px',
        overflow: 'auto',
        width: '100%',
      },
      tableHeadStyle: {
        backgroundColor: '#d5d5e1',
        color: '#ffffff',
      },
      tableThStyle: {
        lineHeight: '0.25rem !important'
      },
      tableTrStyle: {
        //lineHeight: '0.20rem !important',
        height: '19px !important'
      },
      mousePointer: {
        cursor: 'pointer'
      },
  
  
  }));
  
  
  function PaperComponent(props) {
      return (
        <Draggable
          handle="#draggable-dialog-title"
          cancel={'[class*="MuiDialogContent-root"]'}
        >
          <Paper {...props} />
        </Draggable>
      );
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

const AddQuestionPage = () => {

    const navigate = useNavigate();
    const classe = useStyle();

    const classess = useStyle();

    const [waitingOpen, setWaitingOpen] = React.useState(false);



    useEffect (() => {
        //getRiskTypeData();
        getData();

       // getStandards();
       
        
        window.scrollTo(0, 0);

        
    
    },[])

    //malindu useEffect

    const [questionSliderMethods, setQuestionSliderMethods] = useState([])



    useEffect(()=> {
            axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION+"/all",{
              method: 'get',
              headers: { 
                  'Content-Type': 'text/plain', 
                },     
              })
              .then(function (response) {
                  console.log("All Risk Returns for Questyions");
                  console.log(response.data[0]);
                  setQuestionSliderMethods(response.data[0].methods)
                  setQuestionCategoryId(response.data[0].id)
                  console.log(response.data[0].methods);
              })
              .catch(function (error) {
              console.log(error);
              });

              allRiskMethodData();
              getColors();
              
          
    },[])


    function getSliderMethods(){
      
      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION+"/all",{
        method: 'get',
        headers: { 
            'Content-Type': 'text/plain', 
          },     
        })
        .then(function (response) {
         
            console.log("All Risk Returns");
            console.log(response.data[0]);
            setQuestionSliderMethods(response.data[0].methods)
            setQuestionCategoryId(response.data[0].id)
            console.log(response.data[0].methods);
    
        })
        .catch(function (error) {
        console.log(error);
        });

    }






    const [golobalCentreId, setGolobalCentreId] = React.useState();

    function getData () {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        setGolobalCentreId(data.centreId);
        getStandards(data.centreId)
        getEntityData(data.centreId);
        getCentreData(data.centreId);
        getOwnStandared(data.centreId);
        getScaleData(data.centreId)
        getPrevQuestionData(data.centreId)
        console.log(data.centreId)
    }


    const [scaleData, setScaleData] = React.useState([]);

    /** Get Scale data for selected Centre */
function getScaleData(centreId) {
  axios(CONFIG.MAIN_URL+`/scale/get?centreId=`+ centreId, {
    method: 'get',
  })
  .then(function (response) {
    console.log("++++++++++++++++Return scale Data +++++++++++++++++++");
    console.log(response.data);
    setScaleData(response.data);
  })
  .catch(function (error) {
    console.log(error);
  });
}

    function reloadAll(){

      setWaitingOpen(true);
      setTimeout(()=> {
        getStandards(golobalCentreId);
        getOwnStandared(golobalCentreId);
        getSliderMethods();
        allRiskMethodData();
        getColors();
        setWaitingOpen(false);
      }, 4000);

    }
    


    const [centreOwnObjectives, setCentreOwnObjectives] = React.useState([]);
    const [centreOwnComponents, setCentreOwnComponents] = React.useState([]);
    const [centreOwnPrinciples, setCentreOwnPrinciples] = React.useState([]);
    function getOwnStandared(centreId){
        axios(CONFIG.MAIN_URL+'/custom-standard-type-client/filter?centreId='+centreId,{
            method: 'get',
          })
          .then(function (response) {
            console.log("Get All Own standared");
            var rtnArray = response.data;
            console.log(rtnArray);
            var objArr = [];
            var compArr = [];
            var priArr = [];
            rtnArray.forEach((item)=>{
                if (item.type === "objective"){
                    objArr.push(item);
                }
                if (item.type === "component"){
                    compArr.push(item);
                }
                if (item.type === "principle"){
                    priArr.push(item);
                }
            })
            setCentreOwnObjectives(objArr);
            setCentreOwnComponents(compArr);
            setCentreOwnPrinciples(priArr);
          })
          .catch(function (error) {
            console.log(error);
          });
    
    }    
    
    const [centreName, setCentreName] = React.useState('');
    function getCentreData(centreId){
      var data = '{getCentreData(centreId:\"' + centreId + '\"){centreId,centreName}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          console.log("++++++++++++++++Return centreData++++++++++++++++");
          var centreData = response.data.data.getCentreData;
          console.log(centreData);
          setCentreName(centreData.centreName);
  
      })
      .catch(function (error) {
          console.log("Centre Data Loding error" + error);
      });
    }


    const [headerName, setHeaderName] = React.useState('Create And Edit Question');
    const [saveBtnName, setSaveBtnName] = React.useState('Save');
    const [saveDraftBtnName, setSaveDraftBtnName] = React.useState('Save Draft');
    const [selectYourRiskType, setSelectYourRiskType] = React.useState('Select Your Risk Type');
    

    const [objectivePlaceHolderName, setObjectivePlaceHolderName] = React.useState('Select Objective');
    const [componentPlaceHolderName, setComponentPlaceHolderName] = React.useState('Select Component');
    const [principlePlaceHolderName, setPrinciplePlaceHolderName] = React.useState('Select Principle');
    const [entityPlaceHolderName, setEntityPlaceHolderName] = React.useState('Select Entity');    

    /**
     * Create Own Objective
     */
     const [ownObjectiveFlag, setOwnObjectiveFlag] = React.useState(false);
     const [ownObjectiveName, setOwnObjectiveName] = React.useState('');
     const [ownObjectiveOpen, setOwnObjectiveOpen] = React.useState(false);
     const [ownObjectiveScroll, setOwnObjectiveScroll] = React.useState('paper');

     const ownObjectiveHandleClickOpen = (scrollType) => () => {
        setOwnObjectiveOpen(true);
        setOwnObjectiveScroll(scrollType);
      };

     const ownObjectiveHandleClickClose = () => {
         setOwnObjectiveOpen(false);
     };
     const handleChangeOwnObjective = (event) => {
        setOwnObjectiveName(event.target.value);
     };
     const [previousOwnObjectiveId, setPreviousOwnObjectiveId] = React.useState('');
     const handleChangePreviousOwnObjective = (event) => {
        var id = event.target.value;
        setPreviousOwnObjectiveId(id);
        centreOwnObjectives.forEach((data)=>{
            if (data.id === id){
                setOwnObjectiveName(data.name);
            }
        });    
        
     };
     function setOwnObjectiveFunction(){
         if ( ownObjectiveName === ""){
             alert("Please enter Your Own Objective");
             return;
         }
         setObjectivePlaceHolderName("Type Your Objective");
         setOwnObjectiveOpen(false);
         setObjectivedData([]);
         var arryObj = [];
         var tmpArray = {};
         tmpArray["objectiveId"] = "OBJCUS";
         tmpArray["name"] = ownObjectiveName;
         arryObj.push(tmpArray);
         setObjectivedData(arryObj);
         setObjectiveId("OBJCUS");
         setOwnObjectiveFlag(true);
     }
     /** End of Create Own Objective */

    /**
     * Create Own Component
     */
     const [ownComponentFlag, setOwnComponentFlag] = React.useState(false);
     const [ownComponentName, setOwnComponentName] = React.useState('');
     const [ownComponentOpen, setOwnComponentOpen] = React.useState(false);
     const [ownComponentScroll, setOwnComponentScroll] = React.useState('paper');
     const ownComponentHandleClickOpen = (scrollType) => () => {
        setOwnComponentOpen(true);
        setOwnComponentScroll(scrollType);
     };
     const ownComponentHandleClickClose = () => {
         setOwnComponentOpen(false);
     };
     const handleChangeOwnComponent = (event) => {
        setOwnComponentName(event.target.value);
     };
     
     const [previousOwnComponentId, setPreviousOwnComponentId] = React.useState('');
     const handleChangePreviousOwnComponent = (event) => {
        var id = event.target.value;
        setPreviousOwnComponentId(id);
        centreOwnComponents.forEach((data)=>{
            if (data.id === id){
                setOwnComponentName(data.name);
            }
        });    
     };
     
     function setOwnComponentFunction(){
         if ( ownComponentName === ""){
             alert("Please enter Your Own Component");
             return;
         }
         setComponentPlaceHolderName("Type Your Component");
         setOwnComponentOpen(false);
         setComponentsData([]);
         var arryObj = [];
         var tmpArray = {};
         tmpArray["componentId"] = "COMCUS";
         tmpArray["name"] = ownComponentName;
         arryObj.push(tmpArray);
         setComponentsData(arryObj);
         setComponentId("COMCUS");
         setOwnComponentFlag(true);
     }
     /** End of Create Own Component */


    /**
     * Create own Principal
     */
     const [ownPrincipalFlag, setOwnPrincipalFlag] = React.useState(false);
     const [ownPrincipalName, setOwnPrincipalName] = React.useState('');
     const [ownPrincipalOpen, setOwnPrincipalOpen] = React.useState(false);
     const [ownPrincipalScroll, setOwnPrincipalScroll] = React.useState('paper');
     const ownPrincipalHandleClickOpen = (scrollType) => () => {
        setOwnPrincipalOpen(true);
        setOwnPrincipalScroll(scrollType);
     };
     const ownPrincipalHandleClickClose = () => {
         setOwnPrincipalOpen(false);
     };
     const handleChangeOwnPrincipal = (event) => {
        setOwnPrincipalName(event.target.value);
     };
     
     const [previousOwnPrincipleId, setPreviousOwnPrincipleId] = React.useState('');
     const handleChangePreviousOwnPrinciple = (event) => {
        var id = event.target.value;
        setPreviousOwnPrincipleId(id);
        centreOwnPrinciples.forEach((data)=>{
            if (data.id === id){
                setOwnPrincipalName(data.name);
            }
        });    
        
     };
     
     function setOwnPrincipalFunction(){
         if ( ownPrincipalName === ""){
             alert("Please enter Your Own Principle");
             return;
         }
         setOwnPrincipalOpen(false);
         setPrinciplePlaceHolderName("Type Your Principle");
         setPrincipalsData([]);
         var arryObj = [];
         var tmpArray = {};
         tmpArray["principalId"] = "PRICUS";
         tmpArray["name"] = ownPrincipalName;
         arryObj.push(tmpArray);
         setPrincipalsData(arryObj);
         setPrincipleId("PRICUS");
         setOwnPrincipalFlag(true);
     }
     /** End of Create Own principle */

    const [standardData, setStandardData] = React.useState([]);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setObjectivedData([]);
        setComponentsData([]);
        setPrincipalsData([]);
        setEntityData([]);
        setSelectedStandardName('');

        document.getElementById('customBtn').className = classes.globalBtnAfter; 
        standardData.forEach((data1,index)=>{
            if (data1.centreId === "globle"){
              var idValue = document.getElementById('btn' + index);
              if (idValue != undefined) {
                idValue.className = classes.globalBtn; 
              }  
            }
        })

    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [customId, setCustomId] = React.useState('');

    function cusData(id){
        standardData.forEach((item)=>{
            if(item.standardId === id){
                getObjectives(item.objectives,item.components,item.principles,item.entities,item.standardName);
                    handleClose(false) 
            }                   
        })
    }

    const handleChangeCustom = (event) => {
        setCustomId(event.target.value);
        cusData(event.target.value)
    };
    /** Sort abject array */
    function compare( a, b ) {
        if ( a.orderNumber < b.orderNumber ){
            return -1;
        }
        if ( a.orderNumber > b.orderNumber ){
            return 1;
        }
        return 0;
    }

    function compareShortName( a, b ) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if ( nameA < nameB ){
            return -1;
        }
        if ( nameA > nameB ){
            return 1;
        }
        // names must be equal
        return 0;
    }
  

    function range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }
      

    function getStandards(cntre) {
        axios(CONFIG.MAIN_URL+`/standard/all??centreId=`+cntre,{
          method: 'get',
        })
        .then(function (response) {
          console.log(response.data);
          console.log(JSON.stringify(response.data));
          //setStandardData(response.data.standards)
          setStandardData(response.data)
          //setStandardData(standardDataJs)
        })
        .catch(function (error) {
          console.log(error);
        });
      };

      const [stdName, setStdName] = useState()
      const [objName, setObjName] = useState()

      function objValurs(a,b){
            console.log(a)
            console.log(b)
            setStdName(a)
            setObjName(b)
      }

    
    const [entityData, setEntityData] = React.useState([]);
    const [entity, setEntity] = React.useState('');
    const [entityName, setEntityName] = React.useState('');
    const handleChangeEntity = (event) => {
        var entityId=event.target.value;
        setEntity(entityId);
        entityData.forEach((data,index)=>{
            if (data.entityId === entityId) {   
                setEntityName(data.entityName); 
            }
        })
    };
    const [allEntityData, setAllEntityData] = React.useState([]);  
    function getEntityData(centreId){
        var data = '{allEntityList{ entityDataList{entityName,entityId,isActive,createdBy,lastModifiedBy,centreId,basicEntity{basicEntityId,entityName,iamRole,forceIamRole}}}}';
        axios(CONFIG.MAIN_URL+'/graphql',{
        method: 'post',
        headers: { 
            'Content-Type': 'text/plain',
          },
        data : data,  
        })
        .then(function (response) {
            console.log("++++++++++++++++++++++++entityList+++++++++++++++++++++");
            var entityList = response.data.data.allEntityList.entityDataList;
            console.log(entityList);

            var tmpEntityData = [];
            entityList.forEach((item, index)=>{
                if (item.isActive && item.centreId === centreId) {  
                    var be = item.basicEntity
                    var forceIamRole = item.basicEntity.forceIamRole;
                    var iamRole = item.basicEntity.iamRole;  
                    var canTake = true;
                    if ((iamRole === "ADMIN" && forceIamRole === "ADMIN") || (iamRole === "supplier" && forceIamRole === "supplier") || (iamRole === "serviceprovider" && forceIamRole === "serviceprovider") || (iamRole === "volunteer" && forceIamRole === "volunteer")){
                        canTake = false;
                    }
                    if (canTake){
                        var arrayData = {};
                        arrayData["entityId"] = item.entityId;
                        arrayData["entityName"] = item.entityName;
                        tmpEntityData.push(arrayData);
                    }
                }
            })
            console.log("++++++++++++++++++++++++tmpEntityData+++++++++++++++++++++");
            console.log(tmpEntityData);
            setAllEntityData(tmpEntityData);
        
        })
        .catch(function (error) {
            console.log("Entity Loding error" + error);
        });
    }

    const classes = useStyles();


    /** These variables are used for closeEnded componebts */
    const [ansMethod, setAnsMethod] = React.useState("");
    const [minNumber, setMinNumber] = React.useState(1);
    const [maxNumber, setMaxNumber] = React.useState(5);
    const [optArray, setOptArray] = React.useState([1,2,3,4,5]);
    const [scalingMethod, setScalingMethod] = React.useState("");
    const [scallingMgs, setScallingMsg] = React.useState("");
    const [closeEndedTypeName, setCloseEndedTypeName] = React.useState("");
    const [closeEndedTypeStatus, setCETypeStatus] = React.useState(0);
    const [ansArrayTmp, setAnsArrayTmp] = React.useState([]);
    const [answerOrderNumber, setAnswerOrderNumber] = React.useState(1);
    const [selectedSuggestedValue, setSelectedSuggestedValue] = React.useState(0);
    const [closeEndedTypeMgs, setCloseEndedTypeMsg] = React.useState("");
    const [questionLableNameOpenEnded, setQuestionLableNameOpenEnded] = React.useState("Open Ended Question");
    const [questionLableNameCloseEnded, setQuestionLableNameCloseEnded] = React.useState("Close Ended Question");


    console.log(ansArrayTmp)



    const [openEndedvalue, setOEQValue] = React.useState('');
    const [closeEndedvalue, setCEQValue] = React.useState('');
    const handleOpenEndedChange = (event) => {
        setOEQValue(event.target.value);
        setCEQValue('');
        console.log(openEndedvalue);
        console.log(closeEndedvalue);
    };
    const handleCloseEndedChange = (event) => {
        setCEQValue(event.target.value);
        setOEQValue('');
        console.log(openEndedvalue);
        console.log(closeEndedvalue);
    };
    /** Scrore of the Open ended Question */
    const [openEndedScoreValue, setOpenEndedScoreValue] = React.useState('');
    const handleOpenEndedScoreChange = (event) => {
        const value = event.target.value.replace(/\D/g, "");
        setOpenEndedScoreValue(value);
    };

    /** Answer of the Open ended Question */
    const [openEndedAnswerValue, setOpenEndedAnswerValue] = React.useState('');
    const handleOpenEndedAnswerChange = (event) => {
        setOpenEndedAnswerValue(event.target.value);
    };

    /** Scrore of the Close ended Question */
    const [closeEndedScoreValue, setCloseEndedScoreValue] = React.useState('');
    const handleCloseEndedScoreChange = (event) => {
        const value = event.target.value.replace(/\D/g, "");
        setCloseEndedScoreValue(value);
    };

    const [openCloseEndedStatus, setOCStatus] = React.useState(1);
    const radioHandler = (status) => {
        setOCStatus(status);
        console.log(openCloseEndedStatus);
    };

    const [selectCustomBtn, setSelectCustomBtn] = React.useState(false);
    const handleCustomStandard = (event) => {
        setSelectCustomBtn(true);
        var arryObj = [];
        var tmpArray = {};
        tmpArray["objectiveId"] = "OBJCUS";
        tmpArray["name"] = "Custom (Select this for assinging Custom Objevtive)";
        arryObj.push(tmpArray);
        setObjectivedData(arryObj);

        arryObj = [];
        tmpArray = {};
        tmpArray["componentId"] = "COMCUS";
        tmpArray["name"] = "Custom (Select this for assinging Custom Component)";
        arryObj.push(tmpArray);
        setComponentsData(arryObj);

        arryObj = [];
        tmpArray = {};
        tmpArray["principalId"] = "PRICUS";
        tmpArray["name"] = "Custom (Select this for assinging Custom principle)";
        arryObj.push(tmpArray);
        setPrincipalsData(arryObj);

        setSelectedStandardName("CUSTOM");
        console.log(selectCustomBtn);
    };

    const [selectedStandardName, setSelectedStandardName] = React.useState('');
    const [objectiveData, setObjectivedData] = React.useState([]);
    const [componentsData, setComponentsData] = React.useState([]);
    const [principalsData, setPrincipalsData] = React.useState([]);
    const [objectiveId, setObjectiveId] = React.useState('');
    const [objectiveName, setObjectiveName] = React.useState('');
    const [componentId, setComponentId] = React.useState('');
    const [componentName, setComponentName] = React.useState('');
    const [principleId, setPrincipleId] = React.useState('');
    const [principleName, setPrincipleName] = React.useState('');
    function getObjectives(standerdItemObjective, standerdItemComponent, standerdItemPrincipal, standerdItemEntity,stdName,indexNo){
        setObjectiveId('');
        setComponentId('');
        setPrincipleId('');
        setObjectivedData([]);
        if (standerdItemObjective != null) {
            setObjectivedData(standerdItemObjective);
        }
        if (standerdItemComponent != null) {
            setComponentsData(standerdItemComponent);
        }
        if (standerdItemPrincipal != null) {
            setPrincipalsData(standerdItemPrincipal);
        }
        if (standerdItemEntity != null) {
            setEntityData(standerdItemEntity);
        }
        setSelectedStandardName(stdName);
        setSelectCustomBtn(false);
        standardData.forEach((data1,index)=>{
            if (data1.centreId === "globle"){
              var idValue = document.getElementById('btn' + index);
              if (idValue != undefined) {
                if (indexNo === index) {  
                  idValue.className = classes.globalBtnAfter; 
                }else{
                  idValue.className = classes.globalBtn; 
                }
              }  
            }
        })
        document.getElementById('customBtn').className = classes.globalBtn; 
    }
    
    const handleChangeObjectives = (event) => {
        if (ownObjectiveFlag){
            setOwnObjectiveName(ownObjectiveName);
            setOwnObjectiveOpen(true);
            setOwnObjectiveScroll('paper');
        }else{
            console.log("ownObjectiveFlag : " + ownObjectiveFlag);
            const oId = event.target.value;
            console.log("oId : " + oId);
            //setObjectiveId('');
            //setComponentId('');
            //setPrincipleId('');
            setObjectiveId(oId);
            //setComponentsData([]);
            //setPrincipalsData([]);
            objectiveData.forEach((data,index)=>{
                if (data.objectiveId === oId) {   
                    setObjectiveName(data.name); 
                    //setComponentsData(data.component);
                }
            })
        }
    };
    
    const handleChangeComponents = (event) => {
        if (ownComponentFlag){
            setOwnComponentName(ownComponentName);
            setOwnComponentOpen(true);
            setOwnComponentScroll('paper');
        }else{
            const cId = event.target.value;
            //setComponentId('');
            //setPrincipleId('');

            setComponentId(cId);
            //setPrincipalsData([]);
            componentsData.forEach((data,index)=>{
                if (data.componentId === cId) {  
                    setComponentName(data.name);  
                    //setPrincipalsData(data.principal);
                }
            })
        }
    };
    
    const handleChangePrinciples = (event) => {
        if (ownPrincipalFlag){
            setOwnPrincipalName(ownPrincipalName);
            setOwnPrincipalOpen(true);
            setOwnPrincipalScroll('paper');
        }else{
            setPrincipleId('');
            const pId = event.target.value;
            setPrincipleId(pId);
            principalsData.forEach((data,index)=>{
                if (data.principalId === pId) {  
                    setPrincipleName(data.name);  
                }
            })
        }
    };

    /** Scrore of the Principle */
    const [principleScoreValue, setPrincipleScoreValue] = React.useState(1);
    const handlePrincipleScoreChange = (event) => {
        const value = event.target.value.replace(/[^0-9.]/g, "");
        setPrincipleScoreValue(value); 
        //    const value = event.target.value.replace(/\D/g, "");
        //    setPrincipleScoreValue(value);
        
    };



    const [maxWidth, setMaxWidth] = React.useState('md');
    /** Global Objective Dialog */
    const [globalObjOpen, setGlobalObjOpen] = React.useState(false);
    const [globalObjScroll, setGlobalObjScroll] = React.useState('paper');
    
    const globalObjHandleClickOpen = (scrollType) => () => {
      setGlobalObjOpen(true);
      setGlobalObjScroll(scrollType);
    };
    const globalObjHandleClickClose = () => {
      setGlobalObjOpen(false);
    };
    /** End of Global Objective Dialog */
    /** Custom Objective Dialog */
    const [customObjOpen, setCustomObjOpen] = React.useState(false);
    const [customObjScroll, setCustomObjScroll] = React.useState('paper');
    
    const customObjHandleClickOpen = (scrollType) => () => {
      setCustomObjOpen(true);
      setCustomObjScroll(scrollType);
    };
    const customObjHandleClickClose = () => {
      setCustomObjOpen(false);
    };
    /** End of Global Objective Dialog */
    /** Global Component Dialog */
    const [globalCompOpen, setGlobalCompOpen] = React.useState(false);
    const [globalCompScroll, setGlobalCompScroll] = React.useState('paper');
    
    const globalCompHandleClickOpen = (scrollType) => () => {
      setGlobalCompOpen(true);
      setGlobalCompScroll(scrollType);
    };
    const globalCompHandleClickClose = () => {
      setGlobalCompOpen(false);
    };
    /** End of Global Component Dialog */
    /** Custom Component Dialog */
    const [customCompOpen, setCustomCompOpen] = React.useState(false);
    const [customCompScroll, setCustomCompScroll] = React.useState('paper');
    
    const customCompHandleClickOpen = (scrollType) => () => {
      setCustomCompOpen(true);
      setCustomCompScroll(scrollType);
    };
    const customCompHandleClickClose = () => {
      setCustomCompOpen(false);
    };
    /** End of Custom Component Dialog */
    /** Global Principles Dialog */
    const [globalPriOpen, setGlobalPriOpen] = React.useState(false);
    const [globalPriScroll, setGlobalPriScroll] = React.useState('paper');
   
    const globalPriHandleClickOpen = (scrollType) => () => {
      setGlobalPriOpen(true);
      setGlobalPriScroll(scrollType);
    };
    const globalPriHandleClickClose = () => {
      setGlobalPriOpen(false);
    };
    /** End of Global Principles Dialog */
    /** Custom Principles Dialog */
    const [customPriOpen, setCustomPriOpen] = React.useState(false);
    const [customPriScroll, setCustomPriScroll] = React.useState('paper');
   
    const customPriHandleClickOpen = (scrollType) => () => {
      setCustomPriOpen(true);
      setCustomPriScroll(scrollType);
    };
    const customPriHandleClickClose = () => {
      setCustomPriOpen(false);
    };
    /** End of Custom Principles Dialog */
    /** Global Entities Dialog */
    const [globalEntOpen, setGlobalEntOpen] = React.useState(false);
    const [globalEntScroll, setGlobalEntScroll] = React.useState('paper');
   
    const globalEntHandleClickOpen = (scrollType) => () => {
      setGlobalEntOpen(true);
      setGlobalEntScroll(scrollType);
    };
    const globalEntHandleClickClose = () => {
      setGlobalEntOpen(false);
    };
    /** End of Global Entities Dialog */
    /** Custom Principles Dialog */
    const [customEntOpen, setCustomEntOpen] = React.useState(false);
    const [customEntScroll, setCustomEntScroll] = React.useState('paper');
   
    const customEntHandleClickOpen = (scrollType) => () => {
      setCustomEntOpen(true);
      setCustomEntScroll(scrollType);
    };
    const customEntHandleClickClose = () => {
      setCustomEntOpen(false);
    };
    /** End of Custom Entities Dialog */

   
    

    const [selectedObjectiveStandardName, setSelectedObjectiveStandardName] = React.useState('');
    const [selectedObjectiveStandardId, setSelectedObjectiveStandardId] = React.useState('');
    const [selectedComponentStandardName, setSelectedComponentStandardName] = React.useState('');
    const [selectedComponentStandardId, setSelectedComponentStandardId] = React.useState('');
    const [selectedPrincipleStandardName, setSelectedPrincipleStandardName] = React.useState('');
    const [selectedPrincipleStandardId, setSelectedPrincipleStandardId] = React.useState('');
    const [selectedEntityStandardName, setSelectedEntityStandardName] = React.useState('');
    const [selectedEntityStandardId, setSelectedEntityStandardId] = React.useState('');


    function getStandardObjectives(standerdItemObjective,stdName,stdId){
        setObjectivedData([]);
        if (standerdItemObjective != null) {
            standerdItemObjective.sort(compare);
            setObjectivedData(standerdItemObjective);
            setObjectivePlaceHolderName("Select Objective from " + stdName);
        }
        standardData.forEach((item)=>{
            if(item.standardId === stdId){
                if ((!ownComponentFlag && ownComponentName === "") && componentId === "" ){
                    if (item.components != undefined && item.components != null && item.components.length > 0){
                        setComponentsData(item.components);
                        setComponentPlaceHolderName("Select Component from " + stdName);
                        setSelectedComponentStandardName(stdName);
                        setSelectedComponentStandardId(stdId);
                        setGlobalCompOpen(false);
                        setOwnComponentFlag(false);
                        setOwnComponentName('');
                    }
                }
                if ((!ownPrincipalFlag && ownPrincipalName === "") && principleId === "" ){
                    if (item.principles != undefined && item.principles != null && item.principles.length > 0){
                        var arr = [];
                        item.principles.forEach((data)=>{
                            if (data.shortName === undefined || data.shortName === null){
                            data["shortName"] = "null"; 
                            }else{
                            var tArr = data.shortName.split(" (Custom)");
                            var dataSName = tArr[0];
                            data["name"] = dataSName + " - " + data.name; 
                            }
                            arr.push(data);
                        })
                        arr = arr.sort(compareShortName);
                        setPrincipalsData(arr);
                        
                        //setPrincipalsData(item.principles);
                        setPrinciplePlaceHolderName("Select Principle from " + stdName);
                        setSelectedPrincipleStandardName(stdName);
                        setSelectedPrincipleStandardId(stdId);
                        setGlobalPriOpen(false);
                        setOwnPrincipalFlag(false);
                        setOwnPrincipalName('');
                    }
                }
                if ( entity === "" && entityName === ""){
                    if (item.entities != undefined && item.entities != null && item.entities.length > 0){
                        setEntityData(item.entities);
                        setEntityPlaceHolderName("Select Entity from " + stdName);
                        setSelectedEntityStandardName(stdName);
                        setSelectedEntityStandardId(stdId);
                        setGlobalEntOpen(false);
                    }
                }
            }
        });        
        

        setSelectedObjectiveStandardName(stdName);
        setSelectedObjectiveStandardId(stdId);
        setGlobalObjOpen(false);

        setOwnObjectiveFlag(false);
        setOwnObjectiveName('');
    }

    function cusStdObjData(id){
        standardData.forEach((item)=>{
            if(item.standardId === id){
                getStandardObjectives(item.objectives,item.standardName,item.standardId);
                setCustomObjOpen(false); 
            }                   
        })
    }
    const [customObjectiveStanardId, setCustomObjectiveStanardId] = React.useState('');
    const handleChangeCustomObjectiveStanard = (event) => {
        cusStdObjData(event.target.value)
    };

    function getStandardComponents(standerdItemComponent,stdName,stdId){
        setComponentsData([]);
        if (standerdItemComponent != null) {
            standerdItemComponent.sort(compare);
            setComponentsData(standerdItemComponent);
            setComponentPlaceHolderName("Select Component from " + stdName);
        }
        standardData.forEach((item)=>{
            if(item.standardId === stdId){
                if ((!ownPrincipalFlag && ownPrincipalName === "") && principleId === "" ){
                    if (item.principles != undefined && item.principles != null && item.principles.length > 0){
                        var arr = [];
                        item.principles.forEach((data)=>{
                            if (data.shortName === undefined || data.shortName === null){
                                data["shortName"] = "null"; 
                            }else{
                                var tArr = data.shortName.split(" (Custom)");
                                var dataSName = tArr[0];
                                data["name"] = dataSName + " - " + data.name; 
                            }
                            arr.push(data);
                        })
                        arr = arr.sort(compareShortName);
                        setPrincipalsData(arr);
                        
                        //setPrincipalsData(item.principles);
                        setPrinciplePlaceHolderName("Select Principle from " + stdName);
                        setSelectedPrincipleStandardName(stdName);
                        setSelectedPrincipleStandardId(stdId);
                        setGlobalPriOpen(false);
                        setOwnPrincipalFlag(false);
                        setOwnPrincipalName('');
                    }
                }
                if ( entity === "" && entityName === ""){
                    if (item.entities != undefined && item.entities != null && item.entities.length > 0){
                        setEntityData(item.entities);
                        setEntityPlaceHolderName("Select Entity from " + stdName);
                        setSelectedEntityStandardName(stdName);
                        setSelectedEntityStandardId(stdId);
                        setGlobalEntOpen(false);
                    }
                }
            }
        });


        setSelectedComponentStandardName(stdName);
        setSelectedComponentStandardId(stdId);
        setGlobalCompOpen(false);

        setOwnComponentFlag(false);
        setOwnComponentName('');
    }

    function cusStdCompData(id){
        standardData.forEach((item)=>{
            if(item.standardId === id){
                getStandardComponents(item.components,item.standardName,item.standardId);
                setCustomCompOpen(false); 
            }                   
        })
    }
    const [customComponentStanardId, setCustomComponentStanardId] = React.useState('');
    const handleChangeCustomComponentStanard = (event) => {
        cusStdCompData(event.target.value)
    };

    function getStandardPrinciples(standerdItemPrinciple,stdName,stdId){
        setPrincipalsData([]);
        if (standerdItemPrinciple != null) {
            var arr = [];
            standerdItemPrinciple.forEach((data)=>{
              if (data.shortName === undefined || data.shortName === null){
                data["shortName"] = "null"; 
              }else{
                var tArr = data.shortName.split(" (Custom)");
                var dataSName = tArr[0];
                data["name"] = dataSName + " - " + data.name; 
              }
              arr.push(data);
            })
            arr = arr.sort(compareShortName);
            setPrincipalsData(arr);
            //standerdItemPrinciple.sort(compare);
            //setPrincipalsData(standerdItemPrinciple);
            setPrinciplePlaceHolderName("Select Principle from " + stdName);
        }
        standardData.forEach((item)=>{
            if(item.standardId === stdId){
                if ( entity === "" && entityName === ""){
                    if (item.entities != undefined && item.entities != null && item.entities.length > 0){
                        setEntityData(item.entities);
                        setEntityPlaceHolderName("Select Entity from " + stdName);
                        setSelectedEntityStandardName(stdName);
                        setSelectedEntityStandardId(stdId);
                        setGlobalEntOpen(false);
                    }
                }
            }
        });
        setSelectedPrincipleStandardName(stdName);
        setSelectedPrincipleStandardId(stdId);
        setGlobalPriOpen(false);

        setOwnPrincipalFlag(false);
        setOwnPrincipalName('');
    }


    function cusStdPriData(id){
        standardData.forEach((item)=>{
            if(item.standardId === id){
                getStandardPrinciples(item.principles,item.standardName,item.standardId);
                setCustomPriOpen(false); 
            }                   
        })
    }
    const [customPrincipleStanardId, setCustomPrincipleStanardId] = React.useState('');
    const handleChangeCustomPrincipleStanard = (event) => {
        cusStdPriData(event.target.value)
    };

    function getStandardEntities(standerdItemEntity,stdName,stdId){
        setEntityData([]);
        if (standerdItemEntity != null) {
            standerdItemEntity.sort(compare);
            setEntityData(standerdItemEntity);
            setEntityPlaceHolderName("Select Entity from " + stdName);
        }
        setSelectedEntityStandardName(stdName);
        setSelectedEntityStandardId(stdId);
        setGlobalEntOpen(false);
    }

    function cusStdEntData(id){
        standardData.forEach((item)=>{
            if(item.standardId === id){
                getStandardEntities(item.entities,item.standardName,item.standardId);
                setCustomEntOpen(false); 
            }                   
        })
    }
    const [customEntityStanardId, setCustomEntityStanardId] = React.useState('');
    const handleChangeCustomEntityStanard = (event) => {
        cusStdEntData(event.target.value)
    };

    const setAllEntities = (event) => {
        setEntityData(allEntityData);
        setEntityPlaceHolderName("Select an Entity from all available entities");
        setSelectedEntityStandardName('');
        setSelectedEntityStandardId('');
    };

    const location = useLocation();
    const collectionUniqueName = location.pathname.split("/")[2];
    const [collectnId, setCollectnId] = React.useState('');

    const [riskTypeData, setRiskTypeData] = React.useState([]);
    const [rsType, setRsTtpe] = useState([])

    /** To get the Risk Hitachy array from the Collection */
    useEffect(() => {
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
            method: 'get',
        })
        .then(function (response) {
            console.log("Collection response ");
            console.log(response.data);

            setCollectnId(response.data[0].id);

            var tmpRiskArr = [];
            if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
              tmpRiskArr = response.data[0].risks; 
            }
            setRiskTypeData(tmpRiskArr);
        })
        .catch(function (error) {
          console.log(error);
        });
    },[])

    /** To get collections with Risk after saving the question*/
    function reloadCollectionWithRiks(){
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
            method: 'get',
        })
        .then(function (response) {
              console.log("Collection response ");
              console.log(response.data);
              var tmpRiskArr = [];
              if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
                tmpRiskArr = response.data[0].risks; 
              }
              setRiskTypeData(tmpRiskArr);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    /** To get All questions from the collection THIS SHOULD GET FROM THE GRAPHQL*/
    const [quesData, setQuesData] = useState([]);   

    /** Get all question from the collection and put in to attay */
    var allQuesArray = [];
    function getAllQuestions(riskArray){
        allQuesArray = [];
        riskArray.forEach((item)=>{
        if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
            item.riskTypes.forEach((item1)=>{
            if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
                item1.questions.forEach((item2)=>{
                allQuesArray.push(item2);
                })  
            }
            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
                getAllQuestionsInSubRisks(item1.subRisks);
            }
            });
        }
        });
        if (allQuesArray.length > 0){
        var quesArray = allQuesArray.sort (compare);
        setQuesData(allQuesArray);
        }
    }
    function getAllQuestionsInSubRisks(riskArray){
        riskArray.forEach((item)=>{
        if (item.questions != undefined && item.questions != null && item.questions.length > 0){
            item.questions.forEach((item1)=>{
            allQuesArray.push(item1);
            })  
        }
        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
            getAllQuestionsInSubRisks(item.subRisks);
        }
        });  
    }

    useEffect(() => {
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
            method: 'get',
        })
        .then(function (response) {
            console.log("Get Collection And Put In to Question array");
            console.log(response.data);
            /*
            var quesArray = response.data.questions.sort (compare);
            setQuesData(quesArray);
            */
            var tmpRiskArr = [];
            if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
              tmpRiskArr = response.data[0].risks; 
            }
            getAllQuestions(tmpRiskArr);
        })
        .catch(function (error) {
            console.log(error);
        });
    },[])

    /** To get All questions from the collection after saving the question THIS SHOULD GET FROM THE GRAPHQL*/
    function getAllQuestionsForCollection() {
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
            method: 'get',
        })
        .then(function (response) {
            console.log("Get Collection and put Question Array after Question Savesaving");
            console.log(response.data);
            var tmpRiskArr = [];
            if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
              tmpRiskArr = response.data[0].risks; 
            }
            getAllQuestions(tmpRiskArr);
            /*
            var quesArray = response.data.questions.sort (compare);
            setQuesData(quesArray);
            */
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    
    const [selectedCollectionRiskId, setSelectedCollectionRiskId] = React.useState(0);
    const [previousSelectedCollectionRiskId, setPreviousSelectedCollectionRiskId] = React.useState(0);
    const handleChangeHierarchy = (event) => {
        var hierarchyId = event.target.value;
        setSelectedCollectionRiskId(hierarchyId)
        riskTypeData.forEach((item,index)=>{
            if (item.collectionRiskId === hierarchyId) {  
                setRsTtpe(item.riskTypes);
            }
        })
    };




    console.log(rsType);
    const search = useLocation().search;
    const questId = new URLSearchParams(search).get('questionid');
    const subQuestion = new URLSearchParams(search).get('subquestion');
    const [paramsAvails, setParamsAvails] = React.useState(true);
    const [selectedQuestionId, setSelectedQuestionId] = React.useState('');
    console.log("++++++++++++++++++++questId++++++++++++++++++++");
    console.log(questId);
    console.log("++++++++++++++++++++questId++++++++++++++++++++");

    

    function getSelectedQuestions(riskArray, qId){
        var qData = null;
        riskArray.forEach((item)=>{
          if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
            item.riskTypes.forEach((item1)=>{
              if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
                item1.questions.forEach((item2)=>{
                    if (item2.questionId === qId){
                        var tmpObj1 = item2.riskTypes;
                        tmpObj1["riskLevelData"] = item1.riskLevelData;
                        tmpObj1["riskAppetiteData"] = item1.riskAppetiteData;
                        item2["riskTypes"] = tmpObj1;

                        qData = item2;
                    }
                })  
              }
              if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
                var qData1  = getSelectedQuestionsInSubRisks(item1.subRisks, qId, false);
                if (qData1 != undefined && qData1 != null){
                    
                    qData = qData1;
                }
              }
            });
          }
        });
        if (qData != undefined && qData != null){
            return qData;
        }
      }
    
      var qSubData = null;
      function getSelectedQuestionsInSubRisks(riskArray, qId, sameRoutine){
        if (!sameRoutine){
            qSubData = null;
        }
        riskArray.forEach((item)=>{
          if (item.questions != undefined && item.questions != null && item.questions.length > 0){
            item.questions.forEach((item1)=>{
                if (item1.questionId === qId){
                    var tmpObj1 = item1.riskTypes;
                    tmpObj1["riskLevelData"] = item.riskLevelData;
                    tmpObj1["riskAppetiteData"] = item.riskAppetiteData;
                    item1["riskTypes"] = tmpObj1;


                    qSubData = item1;
                }
            })  
          }
          if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
            getSelectedQuestionsInSubRisks(item.subRisks, qId, true);
          }
        });  
        return qSubData;
      }

      
      /** Find the  Risk Type details and Std details*/
      function setSelectedRiskTypeAndStds(riskArray, rId){
        riskArray.forEach((item)=>{
          if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
            item.riskTypes.forEach((item1)=>{
              var riskFound =  false;
              if (item1.collectionRiskTypeId === rId){
                riskFound =  true;
                if (item1.riskStandardData != undefined && item1.riskStandardData != null){
                  if (item1.riskStandardData.objectiveData != undefined && item1.riskStandardData.objectiveData != null){
                    setObjectivedData([]);
                    var arryObj = [];
                     var tmpArray = {};
                    tmpArray["objectiveId"] = item1.riskStandardData.objectiveData.objectiveId;
                    tmpArray["name"] = item1.riskStandardData.objectiveData.objectiveName;
                    arryObj.push(tmpArray);
                    setObjectivedData(arryObj);
                    setObjectiveId(item1.riskStandardData.objectiveData.objectiveId);
                    setObjectiveName(item1.riskStandardData.objectiveData.objectiveName);
                    setOwnObjectiveFlag(false);
                    setSelectedObjectiveStandardName(item1.riskStandardData.objectiveData.standardName);
                    setSelectedObjectiveStandardId(item1.riskStandardData.objectiveData.standardId);
                  }
                  if (item1.riskStandardData.componentData != undefined && item1.riskStandardData.componentData != null){
                    setComponentsData([]);
                    var arryObj = [];
                     var tmpArray = {};
                    tmpArray["componentId"] = item1.riskStandardData.componentData.componentId;
                    tmpArray["name"] = item1.riskStandardData.componentData.componentName;
                    arryObj.push(tmpArray);
                    setComponentsData(arryObj);
                    setComponentId(item1.riskStandardData.componentData.componentId);
                    setComponentName(item1.riskStandardData.componentData.componentName);
                    setOwnComponentFlag(false);
                    setSelectedComponentStandardName(item1.riskStandardData.componentData.standardName);
                    setSelectedComponentStandardId(item1.riskStandardData.componentData.standardId);
                  }
                  if (item1.riskStandardData.principleData != undefined && item1.riskStandardData.principleData != null){
                    setPrincipalsData([]);
                    var arryObj = [];
                     var tmpArray = {};
                    tmpArray["principalId"] = item1.riskStandardData.principleData.principleId;
                    tmpArray["name"] = item1.riskStandardData.principleData.principleName;
                    arryObj.push(tmpArray);
                    setPrincipalsData(arryObj);
                    setPrincipleId(item1.riskStandardData.principleData.principleId);
                    setPrincipleName(item1.riskStandardData.principleData.principleName);
                    setOwnPrincipalFlag(false);
                    setSelectedPrincipleStandardName(item1.riskStandardData.principleData.standardName);
                    setSelectedPrincipleStandardId(item1.riskStandardData.principleData.standardId);
                  }
                }
              }  
              if (!riskFound){
                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
                  setSelectedRiskTypeAndStdsForSubRisks(item1.subRisks, rId);
                }
              }
            });
          }
        });
      }
      function setSelectedRiskTypeAndStdsForSubRisks(riskArray, rId){
        riskArray.forEach((item1)=>{
          var riskFound =  false;
          if (item1.collectionRiskTypeId === rId){
            riskFound =  true;
            if (item1.riskStandardData != undefined && item1.riskStandardData != null){
              if (item1.riskStandardData.objectiveData != undefined && item1.riskStandardData.objectiveData != null){
                setObjectivedData([]);
                var arryObj = [];
                  var tmpArray = {};
                tmpArray["objectiveId"] = item1.riskStandardData.objectiveData.objectiveId;
                tmpArray["name"] = item1.riskStandardData.objectiveData.objectiveName;
                arryObj.push(tmpArray);
                setObjectivedData(arryObj);
                setObjectiveId(item1.riskStandardData.objectiveData.objectiveId);
                setObjectiveName(item1.riskStandardData.objectiveData.objectiveName);
                setOwnObjectiveFlag(false);
                setSelectedObjectiveStandardName(item1.riskStandardData.objectiveData.standardName);
                setSelectedObjectiveStandardId(item1.riskStandardData.objectiveData.standardId);
              }
              if (item1.riskStandardData.componentData != undefined && item1.riskStandardData.componentData != null){
                setComponentsData([]);
                var arryObj = [];
                 var tmpArray = {};
                tmpArray["componentId"] = item1.riskStandardData.componentData.componentId;
                tmpArray["name"] = item1.riskStandardData.componentData.componentName;
                arryObj.push(tmpArray);
                setComponentsData(arryObj);
                setComponentId(item1.riskStandardData.componentData.componentId);
                setComponentName(item1.riskStandardData.componentData.componentName);
                setOwnComponentFlag(false);
                setSelectedComponentStandardName(item1.riskStandardData.componentData.standardName);
                setSelectedComponentStandardId(item1.riskStandardData.componentData.standardId);
              }
              if (item1.riskStandardData.principleData != undefined && item1.riskStandardData.principleData != null){
                setPrincipalsData([]);
                var arryObj = [];
                 var tmpArray = {};
                tmpArray["principalId"] = item1.riskStandardData.principleData.principleId;
                tmpArray["name"] = item1.riskStandardData.principleData.principleName;
                arryObj.push(tmpArray);
                setPrincipalsData(arryObj);
                setPrincipleId(item1.riskStandardData.principleData.principleId);
                setPrincipleName(item1.riskStandardData.principleData.principleName);
                setOwnPrincipalFlag(false);
                setSelectedPrincipleStandardName(item1.riskStandardData.principleData.standardName);
                setSelectedPrincipleStandardId(item1.riskStandardData.principleData.standardId);
              }
            }
          }
          if (!riskFound){
            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
              setSelectedRiskTypeAndStdsForSubRisks(item1.subRisks, rId);
            }
          }
        });  
      }

      /** When trigger entring question from undividual risk */
      const rtnCollectionRiskId = new URLSearchParams(search).get('collectionRiskId');
      const rtnCollectionRiskTypeId = new URLSearchParams(search).get('collectionRiskTypeId');
      const rtnRiskTypeName = new URLSearchParams(search).get('riskTypeName');
      const rtnRiskLevel = new URLSearchParams(search).get('riskLevel');
      const rtnRiskLevelCategoryId = new URLSearchParams(search).get('riskLevelCategoryId');
      const rtnRiskLevelMethodId = new URLSearchParams(search).get('riskLevelMethodId');
      const rtnRiskAppetite = new URLSearchParams(search).get('riskAppetite');
      const rtnRiskAppetiteCategoryId = new URLSearchParams(search).get('riskAppetiteCategoryId');
      const rtnRiskAppetiteMethodId = new URLSearchParams(search).get('riskAppetiteMethodId');
      useEffect(() => {
        if (rtnCollectionRiskId != null && rtnCollectionRiskId != "" && rtnCollectionRiskTypeId != null && rtnCollectionRiskTypeId != "" && rtnRiskTypeName != null && rtnRiskTypeName != "") {

            if (paramsAvails){
                setWaitingOpen(true);    
                axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
                    method: 'get',
                })

                .then(function (response) {
                    console.log("response 3333333333 : " + response);
                    console.log(response);
                    console.log(rtnRiskTypeName);
                    setParamsAvails(false);

                    var tmpRiskArr = [];
                    if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
                      tmpRiskArr = response.data[0].risks; 
                      setSelectedRiskTypeAndStds(tmpRiskArr, rtnCollectionRiskTypeId);
                    }

                    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_RISK+"/all",{
                        method: 'get',
                        headers: { 
                            'Content-Type': 'text/plain', 
                          },     
                    })
                    .then(function (res) {
                        setWaitingOpen(false);
                        console.log("All Risk Returns");
                        console.log(res.data[0]);
                        var methodData = res.data[0].methods;
                        var methodId = res.data[0].id;
                        var riskTypeNameStr  = '';
                        methodData.forEach((data)=> {
                            if(rtnRiskLevelMethodId === data.id){
                                data.riskTypes.forEach((data1)=> {
                                if (data1.startingNumber <= rtnRiskLevel && rtnRiskLevel <= data1.endingNumber) {
                                    riskTypeNameStr = data1.riskTypesName;
                                }
                                })
                            }
                        })
                        var riskTypeAppNameStr  = '';
                        methodData.forEach((data)=> {
                            if(rtnRiskAppetiteMethodId === data.id){
                                data.riskTypes.forEach((data1)=> {
                                if (data1.startingNumber <= rtnRiskAppetite && rtnRiskAppetite <= data1.endingNumber) {
                                    riskTypeAppNameStr = data1.riskTypesName;
                                }
                                })
                            }
                        })

                        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                            method: 'get',
                            headers: { 
                                'Content-Type': 'text/plain', 
                              },     
                        })
                        .then(function (res) {
                            console.log("All colors");
                            console.log(res.data);
                            var colorDatas =  []
                            colorDatas = res.data
                            var risklvlColorData = getRisklvlColorData(rtnRiskLevel,colorDatas)
                            var riskAppetitelvlColorData = getRiskAppetitelvlColorData(rtnRiskAppetite,colorDatas)

                            var riskLevel = "";
                            var riskAppetite = "";
                            var riskLevelStr = "";
                            var riskAppetiteStr = "";
                            if (rtnRiskLevel != undefined && rtnRiskLevel != null && rtnRiskLevel != ""){
                                riskLevel = rtnRiskLevel;
                                riskLevelStr = " (RisK : " + riskLevel + "-" + riskTypeNameStr; 
                                setSelectedRiskLevel(riskLevel);
                            }
                            if (rtnRiskAppetite != undefined && rtnRiskAppetite != null && rtnRiskAppetite != ""){
                                riskAppetite = rtnRiskAppetite;
                                if (riskLevelStr != ""){
                                    riskAppetiteStr += ", Appetite : " + riskAppetite + "-" + riskTypeAppNameStr + ")";
                                }else{
                                    riskAppetiteStr = " (Appetite : " + riskAppetite + "-" + riskTypeAppNameStr + ")";
                                }
                                setSelectedRiskAppetite(riskAppetite);
                            }
                            //setSelectedRiskNameStr(rtnRiskTypeName + riskLevelStr + riskAppetiteStr);
                            setSelectedRiskNameStr(<div>{rtnRiskTypeName}<span style={{color:`${risklvlColorData}`}}> {riskLevelStr}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>);

                            var lbl = "Open Ended Question for "
                            setQuestionLableNameOpenEnded(<div>{lbl}{rtnRiskTypeName}<span style={{color:`${risklvlColorData}`}}> {riskLevelStr}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>)
                            var lbl1 = "Close Ended Question for "
                            setQuestionLableNameCloseEnded(<div>{lbl1}{rtnRiskTypeName}<span style={{color:`${risklvlColorData}`}}> {riskLevelStr}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>)



                            setSelectedCollectionRiskTypeId(rtnCollectionRiskTypeId);
                            setSelectedCollectionRiskId(rtnCollectionRiskId);
                            setSelectedRiskName(rtnRiskTypeName);
                        })    

                          


                  
                    
                    })
                    .catch(function (error) {
                        setWaitingOpen(false);
                        console.log(error);
                    });
                  


                })
                .catch(function (error) {
                    setWaitingOpen(false);
                    console.log(error);
                });
            }
        }

    });


    const [scalingMethodExamples, setScalingMethodExamples] = React.useState([]);
    const [scalingMethodExampleId, setScalingMethodExampleId] = React.useState('');
    const [scalingMethodExampleName, setScalingMethodExampleName] = React.useState('');


    useEffect(() => {

        
        if (questId != null && questId != "" && subQuestion != "ok") {
            //
            if (paramsAvails){
                /*
                axios(CONFIG.MAIN_URL+'/question/questionResponse?questionId='+ questId,{
                    method: 'get',
                })
                */
                setWaitingOpen(true);
                axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
                    method: 'get',
                })
    
                .then(function (response) {
                    console.log(response.data)
                    console.log("response : " + response);

                    var CntreId = response.data[0].centreId;
                    setParamsAvails(false);
    
                    var tmpRiskArr = [];
                    if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
                      tmpRiskArr = response.data[0].risks; 
                    }
                    console.log("+++++++++++++++++++Start to Build quetionData+++++++++++++++++");
                    console.log(tmpRiskArr)
                    var quetionData = getSelectedQuestions(tmpRiskArr,questId);
                    console.log(quetionData);
                    console.log("+++++++++++++++++++End to Build quetionData+++++++++++++++++");
                    //var quetionData = response.data.questions[0];
                    
                    setSelectedQuestionId(quetionData.questionId);
                    
                    setHeaderName('Edit Questionnaire');
                    setSaveBtnName('Update');
                    setSaveDraftBtnName('Update Draft');
                    setSelectYourRiskType('Display Other Risk Types');
    
    
    
                    
                    if (quetionData.riskTypes != null){
    
                        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_RISK+"/all",{
                            method: 'get',
                            headers: { 
                                'Content-Type': 'text/plain', 
                              },     
                        })
                        .then(function (res) {
                             
                            console.log("All Risk Returns");
                            console.log(res.data[0]);
                            setQuestionSliderMethods(res.data[0].methods)
                            setQuestionCategoryId(res.data[0].id)
                            console.log(res.data[0].methods);
    
                            var SliderMethods = []
                            SliderMethods = res.data[0].methods
    
    
                                
    
                            const riskTypeId = quetionData.riskTypes.riskTypeId === undefined || quetionData.riskTypes.riskTypeId === null?"":quetionData.riskTypes.riskTypeId;
                        
                            const collectionRiskId = quetionData.riskTypes.collectionRiskId;
                            const collectionRiskTypeId = quetionData.riskTypes.collectionRiskTypeId;
        
                            const riskTypeName = quetionData.riskTypes.name;
                            var riskLevel = "";
                            var riskAppetite = "";
                            var riskAppetiteStr = "";
    
      
                            axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                method: 'get',
                                headers: { 
                                    'Content-Type': 'text/plain', 
                                  },     
                                })
                                .then(function (res) {
                                  console.log("All colors");
                                  console.log(res.data);
                          
                                  var colorDatas =  []
                                  colorDatas = res.data
                                 // setDatabClr(res.data)
                          
                                 
                                
                            if (quetionData.riskTypes.riskLevel != undefined && quetionData.riskTypes.riskLevel != null && quetionData.riskTypes.riskLevel != ""){
                                
                                riskLevel = quetionData.riskTypes.riskLevel;
                                
                                 var risklvl = getRisklvlNme(riskLevel,quetionData,SliderMethods)
                                 var risklvlColorData = getRisklvlColorData(riskLevel,colorDatas)
    
    
                                console.log(risklvlColorData)
    
        
                                var RisklvlData = " (RisK : " + riskLevel + "-" +risklvl; 
                                setSelectedRiskLevel(riskLevel);
                            }
                            if (quetionData.riskTypes.riskAppetite != undefined && quetionData.riskTypes.riskAppetite != null && quetionData.riskTypes.riskAppetite != ""){
                                
                                riskAppetite = quetionData.riskTypes.riskAppetite;
    
                                var riskAppetitelvl = getRiskAppetitelvlNme(riskAppetite,quetionData,SliderMethods)
    
                                var riskAppetitelvlColorData = getRiskAppetitelvlColorData(riskAppetite,colorDatas)
    
                                if (RisklvlData != ""){
                                    riskAppetiteStr += ", Appetite : " + riskAppetite + '-' + riskAppetitelvl;
                                }else{
                                    riskAppetiteStr = " (Appetite : " + riskAppetite +'-' + riskAppetitelvl ;
                                }
                                setSelectedRiskAppetite(riskAppetite);
                            }
                            if (riskAppetiteStr != ""){
                                riskAppetiteStr += ")";
                            }
    
                            
                            setSelectedRiskNameStr(<div>{riskTypeName}<span style={{color:`${risklvlColorData}`}}> {RisklvlData}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>);
    
                            var lbl = "Open Ended Question for "
                            setQuestionLableNameOpenEnded(<div>{lbl}{riskTypeName}<span style={{color:`${risklvlColorData}`}}> {RisklvlData}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>)
                            var lbl1 = "Close Ended Question for "
                            setQuestionLableNameCloseEnded(<div>{lbl1}{riskTypeName}<span style={{color:`${risklvlColorData}`}}> {RisklvlData}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>)
                            
                            setSelectedRiskId(riskTypeId);
        
                            console.log("++++++++++++++++++riskTypeIdriskTypeIdriskTypeId++++++++++++++++++");
                            console.log(riskTypeId);
                            
                            setSelectedCollectionRiskTypeId(collectionRiskTypeId);
                            setSelectedCollectionRiskId(collectionRiskId);
        
                            setPreviousSelectedCollectionRiskTypeId(collectionRiskTypeId);
                            setPreviousSelectedCollectionRiskId(collectionRiskId);
                            
                            setSelectedRiskName(riskTypeName);
    
    
                        })
                        
                         })
                        .catch(function (error) {
                        console.log(error);
                        });
    
    
    
                    }
    
                    if (quetionData.objective != null && quetionData.objective != ""){
                        if (quetionData.objective.id != undefined && quetionData.objective.id != null && quetionData.objective.id != "") {
                            setObjectivedData([]);
                            var arryObj = [];
                             var tmpArray = {};
                            tmpArray["objectiveId"] = quetionData.objective.id;
                            tmpArray["name"] = quetionData.objective.name;
                            arryObj.push(tmpArray);
                            setObjectivedData(arryObj);
                            setObjectiveId(quetionData.objective.id);
                            setObjectiveName(quetionData.objective.name);
                            setOwnObjectiveFlag(false);
                            setSelectedObjectiveStandardName(quetionData.objective.standardName);
                            setSelectedObjectiveStandardId(quetionData.objective.standardId);
                        }else{
                            setOwnObjectiveName(quetionData.objective.name);
                            setObjectivedData([]);
                            var arryObj = [];
                             var tmpArray = {};
                            tmpArray["objectiveId"] = "OBJCUS";
                            tmpArray["name"] = quetionData.objective.name;
                            arryObj.push(tmpArray);
                            setObjectivedData(arryObj);
                            setObjectiveId("OBJCUS");
                            setObjectiveName(quetionData.objective.name);
                            setOwnObjectiveFlag(true);
                        }
    
                    }
                    if (quetionData.component != null && quetionData.component != ""){
                        if (quetionData.component.id != undefined && quetionData.component.id != null && quetionData.component.id != "") {
                            setComponentsData([]);
                            var arryObj = [];
                             var tmpArray = {};
                            tmpArray["componentId"] = quetionData.component.id;
                            tmpArray["name"] = quetionData.component.name;
                            arryObj.push(tmpArray);
                            setComponentsData(arryObj);
                            setComponentId(quetionData.component.id);
                            setComponentName(quetionData.component.name);
                            setOwnComponentFlag(false);
                            setSelectedComponentStandardName(quetionData.component.standardName);
                            setSelectedComponentStandardId(quetionData.component.standardId);
                        }else{
                            setOwnComponentName(quetionData.component.name);
                            setComponentsData([]);
                            var arryObj = [];
                             var tmpArray = {};
                            tmpArray["componentId"] = "COMCUS";
                            tmpArray["name"] = quetionData.component.name;
                            arryObj.push(tmpArray);
                            setComponentsData(arryObj);
                            setComponentId("COMCUS");
                            setComponentName(quetionData.component.name);
                            setOwnComponentFlag(true);
                        }
                    }
                    if (quetionData.principle != null && quetionData.principle != ""){
                        if (quetionData.principle.id != undefined && quetionData.principle.id != null && quetionData.principle.id != "") {
                            setPrincipalsData([]);
                            var arryObj = [];
                             var tmpArray = {};
                            tmpArray["principalId"] = quetionData.principle.id;
                            tmpArray["name"] = quetionData.principle.name;
                            arryObj.push(tmpArray);
                            setPrincipalsData(arryObj);
                            setPrincipleId(quetionData.principle.id);
                            setPrincipleName(quetionData.principle.name);
                            setOwnPrincipalFlag(false);
                            setSelectedPrincipleStandardName(quetionData.principle.standardName);
                            setSelectedPrincipleStandardId(quetionData.principle.standardId);
                        }else{
                            setOwnPrincipalName(quetionData.principle.name);
                            setPrincipalsData([]);
                            var arryObj = [];
                             var tmpArray = {};
                            tmpArray["principalId"] = "PRICUS";
                            tmpArray["name"] = quetionData.principle.name;
                            arryObj.push(tmpArray);
                            setPrincipalsData(arryObj);
                            setPrincipleId("PRICUS");
                            setPrincipleName(quetionData.principle.name);
                            setOwnPrincipalFlag(true);
                        }
                    }
                    if (quetionData.entity != null && quetionData.entity != ""){
                        if (quetionData.entity.standardId != undefined && quetionData.entity.standardId != null && quetionData.entity.standardId != ""){
                            setSelectedEntityStandardName(quetionData.entity.standardName);
                            setSelectedEntityStandardId(quetionData.entity.standardId);
                        }
                        var tmpEntityData = [];
                        var arrayData = {}
                        arrayData["entityId"] = quetionData.entity.entityId;
                        arrayData["entityName"] = quetionData.entity.name;
                        tmpEntityData.push(arrayData);
                        setEntityData(tmpEntityData);
                        setEntity(quetionData.entity.entityId);
                        setEntityName(quetionData.entity.name);
                    }
    
    
                    if (quetionData.questionType != null && quetionData.questionType != ""){
                        if (quetionData.questionType === "Closeended"){
                            setOCStatus(2);
                            if (quetionData.questionTypeName != null && quetionData.questionTypeName != ""){
                                setCloseEndedTypeName(quetionData.questionTypeName);
    
                                if (quetionData.questionTypeName === "Nominal"){
                                    setCETypeStatus(1);
                                    setCloseEndedTypeMsg("Nominal scale is a naming scale, where Answers are simply \“named\” or labeled, with no specific order");
                                }
                                if (quetionData.questionTypeName === "Ordinal"){
                                    setCETypeStatus(2);
                                    setCloseEndedTypeMsg("Ordinal scale has all its Answers in a specific order, beyond just naming them");
                                }
                                if (quetionData.questionTypeName === "Interval"){
                                    setCETypeStatus(3);
                                    setCloseEndedTypeMsg("Interval scale offers labels, order, as well as, a specific interval between each of its variable options");
                                }
                                if (quetionData.questionTypeName === "Ratio"){
                                    setCETypeStatus(4);
                                    setCloseEndedTypeMsg("Ratio scale bears all the characteristics of an interval scale, in addition to that, it can also accommodate the value of “zero” on any of its variables");
                                }
                            } 
    
                            if (quetionData.question != null && quetionData.question != ""){
                                setCEQValue(quetionData.question);
                            } 
                            if (quetionData.questionScore != undefined && quetionData.questionScore != null && quetionData.questionScore != ""){
                                
                                axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION+"/all",{
                                    method: 'get',
                                    headers: { 
                                        'Content-Type': 'text/plain', 
                                      },     
                                })
                                .then(function (res) {
                                    console.log("+++++++++++++++All Question Score Returns+++++++++++++++");
                                    console.log(res.data[0]);
                                    setQuestionSliderMethods(res.data[0].methods)
                                    setQuestionCategoryId(res.data[0].id)
                                    console.log(res.data[0].methods);
            
                                    var SliderMethods = []
                                    SliderMethods = res.data[0].methods
            
                                    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                        method: 'get',
                                        headers: { 
                                            'Content-Type': 'text/plain', 
                                          },     
                                    })
                                    .then(function (res1) {
                                          console.log("++++++++++++++All colors+++++++++++++++");
                                          console.log(res1.data);
                                  
                                          var colorDatas =  []
                                          colorDatas = res1.data
                                    })
    
    
    
    
    
                                    var min = 0;
                                    var max = 0
                                    setShowSelectBarCloseEnded(false);
                                    setSliderTypeCloseEnded(quetionData.questionScoreData.riskMethodId)
                                    setSliderValueCloseEnded(quetionData.questionScore);
                                    res.data[0].methods.forEach((data)=> {
                                        if(quetionData.questionScoreData.riskMethodId === data.id){
                                          data.riskTypes.forEach((data1)=> {
                                            if (data1.startingNumber <= quetionData.questionScore && quetionData.questionScore <= data1.endingNumber) {
                                              setSliderValueNameCloseEnded(data1.riskTypesName)
                                              min=data.minimumWeightingNumber;
                                              max=data.maximumWeightingNumber;
                                            }
                                          })
                                        }
                                      })
    
    
                                      var posNumber1 = parseInt(quetionData.questionScore) - min;
                                      var totalNumber1 = max - min;
                                      var percentageVal1 = (posNumber1/totalNumber1) * 100
                                      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                        method: 'get',
                                        headers: { 
                                            'Content-Type': 'text/plain', 
                                          },     
                                        })
                                        .then(function (respo) {
                                          console.log("All colors");
                                          console.log(respo.data);
                                  
                                  
                                          respo.data.forEach((data)=> {
                                            if (parseInt(quetionData.questionScore) === parseInt(data.scoreNumber)){
                                              var cCode = data.color;
        
                                              var cssStr1 = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal1 + '%, #d3d3d3 0%)';
                                              //alert(cssStr1);
                                        
                                              setSliderValueCloseEndedStyle(cssStr1);
                                            }
                                          })
                                  
                                         
                                        })
                                        .catch(function (error) {
                                        console.log(error);
                                        });
    
         
                                })
    
                                
    
    
    
                                .catch(function (error) {
                                    console.log(error);
                                });
                                
                                
                                
                                
                                
                                
                                
                                
                                
                                
                                
                                
                                
                                
                                
                                
                                
                                
                                
                            }
    
                            if (quetionData.answers != null) {
                                if(quetionData.answers.answerMethod != null){
                                    var minVal = 0;
                                    var maxVal = 0;
                                    if(quetionData.answers.answerMethod.name != null){
                                        setAnsMethod(quetionData.answers.answerMethod.name);
                                    }    
                                    if(quetionData.answers.answerMethod.minValue != null){
                                        setMinNumber(quetionData.answers.answerMethod.minValue);
                                        minVal = parseInt(quetionData.answers.answerMethod.minValue);
                                    }    
                                    if(quetionData.answers.answerMethod.maxValue != null){
                                        setMaxNumber(quetionData.answers.answerMethod.maxValue);
                                        maxVal = parseInt(quetionData.answers.answerMethod.maxValue);
                                    }    
                                    if (minVal > 0 && maxVal > 0){
                                        setOptArray(range(minVal,maxVal)) ;
                                    }
                                }
                                if(quetionData.answers.answerScaleType != null){
                                    if(quetionData.answers.answerScaleType.name != null){
                                        axios(CONFIG.MAIN_URL+`/scale/get?centreId=`+ CntreId, {
                                            method: 'get',
                                          })
                                          .then(function (response) {
                                            console.log("++++++++++++++++Return scale Data +++++++++++++++++++");
                                            console.log(response.data);
                                            //setScaleData(response.data);
                                            response.data.forEach((data)=> {
                                              console.log(quetionData.answers)
                                              if (quetionData.answers.answerScaleType.name === data.id){
                                               // alert(data.id)
                                                setScallingMsg(data.description);
                                                setScalingMethodExamples(data.examples)
                                                // data.examples.forEach((data1)=> {
                                                //   setScalingMethodExampleId(data1.exampleId)
                                                // })
                                                

                                            }
                                            })
                                            
                                          })
                                          .catch(function (error) {
                                            console.log(error);
                                          });
    
                                    } 
                                }    
                                if (quetionData.answers.answerList != null && quetionData.answers.answerList.length > 0) {
                                    var tmpArry = [];
                                    var count = 1;
                                    quetionData.answers.answerList.forEach((ansData,index)=>{
                                        var arrayData = {}
                                        arrayData["answer"] = ansData.answerName;
                                        console.log(ansData)
                                        
                                        arrayData["risk"] = ansData.riskLevel;
                                        arrayData["riskLevelData"] = ansData.riskLevelData;
    
                                        if (ansData.expectedAnswerName != undefined && ansData.expectedAnswerName != null && ansData.expectedAnswerName != "null" && ansData.expectedAnswerName != ""){
                                            arrayData["expectedAnswerName"] = ansData.expectedAnswerName;
                                            if (quetionData.answers.answerMethod.name === "radioButtonType") {
                                                setSelectedSuggestedValue("" + index);
                                            }
                                        }else{
                                            arrayData["expectedAnswerName"] = "";
                                        }    
                                        arrayData["orderNumber"] = count;
                                       
                                        tmpArry.push(arrayData);
                                        count ++;
                                    }); 
                                    tmpArry.sort(compare);
                                    setAnsArray(tmpArry);
                                    setAnsArrayTmp(tmpArry);
                                    setAnswerOrderNumber(count);
                                }
    
                            }    
                            
                            
                        }else{
                            setOCStatus(1);
                            if (quetionData.question != null && quetionData.question != ""){
                                setOEQValue(quetionData.question);
                            }
                            if (quetionData.questionScore != undefined && quetionData.questionScore != null && quetionData.questionScore != ""){
                                
                                axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION+"/all",{
                                    method: 'get',
                                    headers: { 
                                        'Content-Type': 'text/plain', 
                                      },     
                                })
                                .then(function (res) {
                                    console.log("All Question Score Returns");
                                    console.log(res.data[0]);
                                    setQuestionSliderMethods(res.data[0].methods)
                                    setQuestionCategoryId(res.data[0].id)
                                    console.log(res.data[0].methods);
            
                                    var SliderMethods = []
                                    SliderMethods = res.data[0].methods
            
                                    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                        method: 'get',
                                        headers: { 
                                            'Content-Type': 'text/plain', 
                                          },     
                                    })
                                    .then(function (res1) {
                                          console.log("All colors");
                                          console.log(res1.data);
                                  
                                          var colorDatas =  []
                                          colorDatas = res1.data
                                    })
                                    setShowSelectBar(false);
                                    setSliderType(quetionData.questionScoreData.riskMethodId)
                                    setSliderValue(quetionData.questionScore);
    
                                    var min = 0;
                                    var max = 0;
                                    res.data[0].methods.forEach((data)=> {
                                        if(quetionData.questionScoreData.riskMethodId === data.id){
                                          data.riskTypes.forEach((data1)=> {
                                            if (data1.startingNumber <= quetionData.questionScore && quetionData.questionScore <= data1.endingNumber) {
                                              setSliderValueName(data1.riskTypesName)
                                              min=data.minimumWeightingNumber;
                                              max=data.maximumWeightingNumber;
                                            }
                                          })
                                        }
                                      })
    
    
                                      
    
    
                                      var posNumber1 = parseInt(quetionData.questionScore) - min;
                                      var totalNumber1 = max - min;
                                      var percentageVal1 = (posNumber1/totalNumber1) * 100
                                      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                        method: 'get',
                                        headers: { 
                                            'Content-Type': 'text/plain', 
                                          },     
                                        })
                                        .then(function (response) {
                                          console.log("All colors");
                                          console.log(response.data);
                                  
                                  
                                          response.data.forEach((data)=> {
                                            if (parseInt(quetionData.questionScore) === parseInt(data.scoreNumber)){
                                              var cCode = data.color;
        
                                              var cssStr1 = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal1 + '%, #d3d3d3 0%)';
                                        
                                              setSliderValueStyle(cssStr1);
                                            }
                                          })
                                  
                                         
                                        })
                                        .catch(function (error) {
                                        console.log(error);
                                        });
    
                                     
    
    
        
                                
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
    
    
    
                                












                                
                                
                                
                            }
                            if (quetionData.answers != undefined && quetionData.answers != null) {
                                if (quetionData.answers.answer != undefined && quetionData.answers.answer != null && quetionData.answers.answer != "") {
                                    setOpenEndedAnswerValue(quetionData.answers.answer);
                                }
                                if(quetionData.answers.answerMethod != null){
                                    if(quetionData.answers.answerMethod.name != null){
                                        setAnsMethod(quetionData.answers.answerMethod.name);
                                    }    
                                }
                            }
                        }
                    }
                    setWaitingOpen(false);
    
                })
                .catch(function (error) {
                    setWaitingOpen(false);
                    console.log(error);
                });
    
            }
            
            }
        },[])
    
    
     
        const [ansArray, setAnsArray] = React.useState([]);


        const [idForEdit, setIdForEdit] = React.useState('');
    
        function clearFuction(){
            let a= document.createElement('a');
            a.target= '_self';
            a.href= 'createQuestion';
            a.click();
        }
        
        function saveFunction(opt){
            var obj = {};
            if (idForEdit != null && idForEdit != '') {
                var data = "{\"id\":\"" + idForEdit + "\",";
                data += "\"collectionUniqueName\":\"" + collectionUniqueName + "\",";
                obj["id"] = idForEdit;
                obj["collectionUniqueName"] = collectionUniqueName;
            }else{
                var data = "{\"collectionUniqueName\":\"" + collectionUniqueName + "\",";
                obj["collectionUniqueName"] = collectionUniqueName;
            }
                
            data += "\"centreId\":\"" + golobalCentreId + "\",";
            obj["centreId"] = golobalCentreId;
            
            if (selectedQuestionId != '' && subQuestion != "ok" ) {
                data += "\"questionId\":\"" + selectedQuestionId + "\",";
                obj["questionId"] = selectedQuestionId;
            }else{
                if (selectedQuestionId === 0 && subQuestion === "ok" ) {
                    data += "\"mainQuestionId\":" + questId + ",";
                    obj["mainQuestionId"] = questId;
                }
            }
            if (openCloseEndedStatus == 1){
                if (openEndedvalue === ""){
                    // alert("Please enter Open ended Questionnaire");
                    // return;
                }else{
                    data += "\"question\":" + "\"" + openEndedvalue + "\",";
                    data += "\"questionType\":" + "\"" + "Openended" + "\",";
                    obj["question"] = openEndedvalue;
                    obj["questionType"] = "Openended";
                    if (sliderValue === ""){
                        // alert("Please enter Questionnaire Score");
                        // return;
                    }else {
                        if (sliderValue != undefined) {
                            data += "\"questionScore\":" + sliderValue + ",";
                            data += "\"questionScoreData\":{\"questionScore\":"  + sliderValue + ",\"riskCategoryId\":" + "\"" + questionCategoryId + "\",\"riskMethodId\":" + "\"" + sliderType + "\"},";

                            obj["questionScore"] = sliderValue;
                            var objSub={};
                            objSub["questionScore"] = sliderValue;
                            objSub["riskCategoryId"] = questionCategoryId;
                            objSub["riskMethodId"] = sliderType;
                            obj["questionScoreData"] = objSub;
                        }
                    }    
    
                    data += "\"questionTypeName\":" + "\"" + "" + "\",";
                    obj["questionTypeName"] = "";
                }
                
            }else{
                if (closeEndedvalue === ""){
                    // alert("Please enter Close ended Questionnaire");
                    // return;
                }else{
    
                    //questionId    
                    data += "\"question\":" + "\"" + closeEndedvalue + "\",";
                    data += "\"questionType\":" + "\"" + "Closeended" + "\",";
                    obj["question"] = closeEndedvalue;
                    obj["questionType"] = "Closeended";
                    if (sliderValueCloseEnded === ""){
                        // alert("Please enter Questionnaire Score");
                        // return;
                    }else {
                        if (sliderValueCloseEnded != undefined) {
                            data += "\"questionScore\":" + sliderValueCloseEnded + ",";
                            data += "\"questionScoreData\":{\"questionScore\":"  + sliderValueCloseEnded + ",\"riskCategoryId\":" + "\"" + questionCategoryId + "\",\"riskMethodId\":" + "\"" + sliderTypeCloseEnded + "\"},";

                            obj["questionScore"] = sliderValueCloseEnded;
                            var objSub={};
                            objSub["questionScore"] = sliderValueCloseEnded;
                            objSub["riskCategoryId"] = questionCategoryId;
                            objSub["riskMethodId"] = sliderTypeCloseEnded;
                            obj["questionScoreData"] = objSub;
                        }
                        
                    }
                    data += "\"questionTypeName\":" + "\"" + closeEndedTypeName + "\",";
                    obj["questionTypeName"] = closeEndedTypeName;
                }
            }
            //data += "\"standardName\":" + "\"" + selectedStandardName + "\",";
            if (opt === 1){
                data += "\"metaInfo\":{\"status\":" + "\"" + "complete" + "\"},";
                var objSub={};
                objSub["status"] = "complete";
                obj["metaInfo"] = objSub;
            }else{
                data += "\"metaInfo\":{\"status\":" + "\"" + "draft" + "\"},";
                var objSub={};
                objSub["status"] = "draft";
                obj["metaInfo"] = objSub;
            }
    
            if(selectedRiskName === ''){
                // alert('please Select a risk type');
                // return;
            }else{    
                //data += "\"riskType\":{\"collectionRiskTypeId\":" + selectedCollectionRiskTypeId + ", \"collectionRiskId\":" + selectedCollectionRiskId + ", \"riskTypeId\":" + selectedRiskId + ", \"name\":"+ "\"" + selectedRiskName + "\"},";
                //selectedCollectionRiskId
                //if (previousSelectedCollectionRiskId != 0 && previousSelectedCollectionRiskTypeId != 0 ){
                //    data += "\"riskType\":{\"previousCollectionRiskId\":" + previousSelectedCollectionRiskId + ", \"previousCollectionRiskTypeId\":" + previousSelectedCollectionRiskTypeId + ", \"collectionRiskTypeId\":" + selectedCollectionRiskTypeId + ", \"collectionRiskId\":" + selectedCollectionRiskId + ", \"riskTypeId\":" + selectedRiskId + ", \"name\":"+ "\"" + selectedRiskName + "\", \"riskLevel\":"+ "\"" + selectedRiskLevel + "\", \"riskAppetite\":"+ "\"" + selectedRiskAppetite + "\"},";
                //}else{
                    data += "\"riskTypes\":{\"collectionRiskTypeId\":\"" + selectedCollectionRiskTypeId + "\", \"collectionRiskId\":\"" + selectedCollectionRiskId +  "\", \"name\":"+ "\"" + selectedRiskName + "\", \"riskLevel\":"+ "\"" + selectedRiskLevel + "\", \"riskAppetite\":"+ "\"" + selectedRiskAppetite + "\"},";
                    var objSub={};
                    objSub["collectionRiskTypeId"] = selectedCollectionRiskTypeId;
                    objSub["collectionRiskId"] = selectedCollectionRiskId;
                    objSub["name"] = selectedRiskName;
                    objSub["riskLevel"] = selectedRiskLevel;
                    objSub["riskAppetite"] = selectedRiskAppetite;
                    obj["riskTypes"] = objSub;
                //}
            }    
    
            if (ownObjectiveFlag){
                if (ownObjectiveName != null && ownObjectiveName != "") {
                    data += "\"objective\":{\"id\":" + "\"" + "" + "\", \"name\":"+ "\"" + ownObjectiveName + "\"},";

                    var objSub={};
                    objSub["id"] = "";
                    objSub["name"] = ownObjectiveName;
                    obj["objective"] = objSub;
                }else{
                    // alert("please Select an Objective");
                    // return;
                }
            }else{
                if (objectiveId === ''){
                    // alert("please Select an Objective");
                    // return;
                }else {    
                    data += "\"objective\":{\"standardId\":" + "\"" + selectedObjectiveStandardId + "\",\"standardName\":" + "\"" + selectedObjectiveStandardName + "\",\"id\":" + "\"" + objectiveId + "\", \"name\":"+ "\"" + objectiveName + "\"},";

                    var objSub={};
                    objSub["standardId"] = selectedObjectiveStandardId;
                    objSub["standardName"] = selectedObjectiveStandardName;
                    objSub["id"] = objectiveId;
                    objSub["name"] = objectiveName;
                    obj["objective"] = objSub;
                }
            }
            if (ownComponentFlag){
                if (ownComponentName != null && ownComponentName != "") {
                    data += "\"component\":{\"id\":" + "\"" + "" + "\", \"name\":"+ "\"" + ownComponentName + "\"},";

                    var objSub={};
                    objSub["id"] = "";
                    objSub["name"] = ownComponentName;
                    obj["component"] = objSub;
                }else{
                    // alert("please Select a Component");
                    // return;
                }
            }else{
                if (componentId === ''){
                    // alert("please Select a Component");
                    // return;
                }else {      
                    data += "\"component\":{\"standardId\":" + "\"" + selectedComponentStandardId + "\",\"standardName\":" + "\"" + selectedComponentStandardName + "\",\"id\":" + "\"" + componentId + "\", \"name\":"+ "\"" + componentName + "\"},";

                    var objSub={};
                    objSub["standardId"] = selectedComponentStandardId;
                    objSub["standardName"] = selectedComponentStandardName;
                    objSub["id"] = componentId;
                    objSub["name"] = componentName;
                    obj["component"] = objSub;
                }
            }
            if (ownPrincipalFlag){
                if (ownPrincipalName != null && ownPrincipalName != "") {
                    //if (principleScoreValue === ''){
                    //    alert("please enter Principle Score");
                    //    return;
                    //}else{
                        data += "\"principle\":{\"id\":" + "\"" + "" + "\", \"name\":"+ "\"" + ownPrincipalName + "\",\"principleScore\":" + principleScoreValue + "},";

                        var objSub={};
                        objSub["id"] = "";
                        objSub["name"] = ownPrincipalName;
                        objSub["principleScore"] = principleScoreValue;
                        obj["principle"] = objSub;
                    //}    
                }else{
                    // alert("please Select a Principle");
                    // return;
                }
            }else{
                if (principleId === ''){
                    // alert("please Select a Principle");
                    // return;
                }else {
                     
                       
                    //if (principleScoreValue === ''){
                    //    alert("please enter Principle Score");
                    //    return;
                    //}else{  
                        data += "\"principle\":{\"standardId\":" + "\"" + selectedPrincipleStandardId + "\",\"standardName\":" + "\"" + selectedPrincipleStandardName + "\",\"id\":" + "\"" + principleId + "\", \"name\":"+ "\"" + principleName + "\",\"principleScore\":" + principleScoreValue + "},";

                        var objSub={};
                        objSub["standardId"] = selectedPrincipleStandardId;
                        objSub["standardName"] = selectedPrincipleStandardName;
                        objSub["id"] = principleId;
                        objSub["name"] = principleName;
                        objSub["principleScore"] = principleScoreValue;
                        obj["principle"] = objSub;
                    //}    
                }    
            }
            
            
            if (entityName === ''){
                // alert('please Select an Entity');
                // return;
            }else{
                if (selectedEntityStandardId != ""){
                    data += "\"entity\":{\"standardId\":" + "\"" + selectedEntityStandardId + "\",\"standardName\":" + "\"" + selectedEntityStandardName + "\",\"entityId\":" + "" + entity + ", \"name\":"+ "\"" + entityName + "\"},";

                    var objSub={};
                    objSub["standardId"] = selectedEntityStandardId;
                    objSub["standardName"] = selectedEntityStandardName;
                    objSub["entityId"] = entity;
                    objSub["name"] = entityName;
                    obj["entity"] = objSub;
                }else{
                    data += "\"entity\":{\"entityId\":" + "" + entity + ", \"name\":"+ "\"" + entityName + "\"},";
                    var objSub={};
                    objSub["entityId"] = entity;
                    objSub["name"] = entityName;
                    obj["entity"] = objSub;
                }    
            }    
    
            if (openCloseEndedStatus == 1){
                //data += "\"answers\":{}";
                data += "\"answers\":{";
                data += "\"answerMethod\":{\"name\":" + "\"" + "freeTextType" + "\", \"minValue\":"+ "\"" + "0" + "\", \"maxValue\":"+ "\"" + "0" + "\"},";
                data += "\"answer\":" + "\"" + openEndedAnswerValue + "\"";
                data += "}";

                var objSub={};
                var objSubSub={};
                
                objSubSub["name"] = "freeTextType";
                objSubSub["minValue"] = "0";
                objSubSub["maxValue"] = "0";
                
                objSub["answerMethod"] = objSubSub;
                objSub["answer"] = openEndedAnswerValue;
                obj["answers"] = objSub;
            }else{

                data += "\"answers\":{";
                var objSub={};
                

                if (ansMethod === "orderNumberType"){
                    data += "\"answerMethod\":{\"name\":" + "\"" + ansMethod + "\", \"minValue\":"+ "\"" + minNumber + "\", \"maxValue\":"+ "\"" + maxNumber + "\"},";
                    
                    var objSubSub={};
                    objSubSub["name"] = ansMethod;
                    objSubSub["minValue"] = minNumber;
                    objSubSub["maxValue"] = maxNumber;
                    objSub["answerMethod"] = objSubSub;
                }else{
                    data += "\"answerMethod\":{\"name\":" + "\"" + ansMethod + "\", \"minValue\":"+ "\"" + "0" + "\", \"maxValue\":"+ "\"" + "0" + "\"},";

                    var objSubSub={};
                    objSubSub["name"] = ansMethod;
                    objSubSub["minValue"] = "0";
                    objSubSub["maxValue"] = "0";
                    objSub["answerMethod"] = objSubSub;
                }
                
                data += "\"answerScaleType\":{\"name\":" + "\"" + scalingMethod + "\"},";

                var objSubSub={};
                objSubSub["name"] = scalingMethod;
                objSub["answerScaleType"] = objSubSub;

                data += "\"answerList\":[";

                var arryTmp = [];
                ansArray.forEach((item,index)=>{
                    var expctedAns = item.expectedAnswerName;
                    if (expctedAns === undefined || expctedAns === null || expctedAns === "null"){
                        expctedAns = "";
                    }
                    var levelData = item.riskLevelData;
                    
                    var levelDataStr = "{\"riskLevel\":" + levelData.riskLevel + ", \"riskCategoryId\":\"" + levelData.riskCategoryId + "\", \"riskMethodId\":\"" + levelData.riskMethodId + "\"}"
                    
                    var tmpObjX0 = {}
                    var tmpObjX1 = {}
                    tmpObjX1["riskLevel"]=levelData.riskLevel;
                    tmpObjX1["riskCategoryId"]=levelData.riskCategoryId;
                    tmpObjX1["riskMethodId"]=levelData.riskMethodId;

                    if (ansArray.length -1 === index) {
                        data += "{\"answerName\":" + "\"" + item.answer + "\", \"answerType\":"+ "\"" + ansMethod + "\", \"riskLevel\":" + item.risk + ", \"expectedAnswerName\":"+ "\"" + expctedAns + "\", \"riskLevelData\":"+ levelDataStr + "}";
                        
                        tmpObjX0["answerName"]=item.answer;
                        tmpObjX0["answerType"]=ansMethod;
                        tmpObjX0["riskLevel"]=item.risk;
                        tmpObjX0["expectedAnswerName"]=expctedAns;
                        tmpObjX0["riskLevelData"]=tmpObjX1;
                    }else{
                        data += "{\"answerName\":" + "\"" + item.answer + "\", \"answerType\":"+ "\"" + ansMethod + "\", \"riskLevel\":" + item.risk + ", \"expectedAnswerName\":"+ "\"" + expctedAns + "\", \"riskLevelData\":"+ levelDataStr + "},";
                        
                        tmpObjX0["answerName"]=item.answer;
                        tmpObjX0["answerType"]=ansMethod;
                        tmpObjX0["riskLevel"]=item.risk;
                        tmpObjX0["expectedAnswerName"]=expctedAns;
                        tmpObjX0["riskLevelData"]=tmpObjX1;                    
                    }

                    arryTmp.push(tmpObjX0);

                })
                data += "]";
                data += "}";

                objSub["answerList"] = arryTmp;
                obj["answers"] = objSub;

            }
            /*
            if (openCloseEndedStatus == 1){
                data += "\"answers\":[]";
            }else{
                data += "\"answers\":[";
                ansArray.forEach((item,index)=>{
                    if (ansArray.length -1 === index) {
                        data += "{\"answerName\":" + "\"" + item.answer + "\", \"answerType\":"+ "\"" + ansMethod + "\", \"riskLevel\":" + item.risk + "}";
                    }else{
                        data += "{\"answerName\":" + "\"" + item.answer + "\", \"answerType\":"+ "\"" + ansMethod + "\", \"riskLevel\":" + item.risk + "},";
                    }
                })
                data += "]";
            }
            */
            data += "}";
            console.log(data);
            console.log("+++++++++++++Same data in OBJ++++++++++++++++");
            console.log(obj);
            console.log("+++++++++++++Same data in OBJ to JSON++++++++++++++++");
            var myJSON = JSON.stringify(obj);
            console.log(myJSON); 
            
    
            var saveUrl = CONFIG.MAIN_URL+'/question/savequestiononly';
            if (idForEdit != null && idForEdit != '' ) {
                saveUrl = CONFIG.MAIN_URL+'/question/updatequestiononly';
            }

            //alert('https://192.168.5.9:9001/question/updatequestiononly')
            setWaitingOpen(true);
            axios(saveUrl,{
            method: 'post',
            headers: { 
                'Content-Type': 'application/json',
              },
            data : myJSON,  
            })
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setWaitingOpen(false);
                if(idForEdit != null && idForEdit != '' ){
                    alert("Question Update successfully");
                }else{
                    alert("Question saved successfully");
                }                
                setRefresh(true)
                setOEQValue("");
                setCEQValue("");
                setAnsArray([]);
                setAnsArrayTmp([]);
                setAnsMethod("");
                setMinNumber(1);
                setMaxNumber(5)
                setScalingMethod("");
                setScallingMsg("");
                setCloseEndedTypeName("")
                setCETypeStatus(0);
                setOpenEndedScoreValue("");
                setCloseEndedScoreValue("");
                setOpenEndedAnswerValue("");
                setSelectedQuestionId('');
                setHeaderName('Add New Questionnaire');
                setSaveBtnName('Save');
                setSaveDraftBtnName('Save Draft');
                setSelectYourRiskType('Select Your Risk Type');
                
                var stdTypeObj = '';
                var stdNameObj = '';
                var stdTypeComp = '';
                var stdNameComp = '';
                var stdTypePri = '';
                var stdNamePri = '';
                if (ownObjectiveFlag){
                    if (ownObjectiveName != null && ownObjectiveName != "") {
                        
                        stdTypeObj = 'objective';
                        stdNameObj = ownObjectiveName;
                    }
                }
                if (ownComponentFlag){
                    if (ownComponentName != null && ownComponentName != "") {
                        stdTypeComp = 'component';
                        stdNameComp = ownComponentName;
                    }
                }
                if (ownPrincipalFlag){
                    if (ownPrincipalName != null && ownPrincipalName != "") {
                        stdTypePri = 'principle';
                        stdNamePri = ownPrincipalName;
                    }
                }
                if (stdTypeObj != '' || stdTypeComp != '' || stdNamePri != ''){
                    //CONFIG.MAIN_URL/custom-standard-type-client/save
                    var arrStd = [];
                    if (stdTypeObj != ''){
                        var objStd = {};
                        objStd["name"] = stdNameObj;
                        objStd["type"] = stdTypeObj;
                        objStd["centreId"] = golobalCentreId;
                        var canTake = true;
                        centreOwnObjectives.forEach((item)=>{
                            if (item.name === stdNameObj){
                                canTake = false;
                            }
                            arrStd.push(item);    
                        });    
                        if (canTake){
                            arrStd.push(objStd);
                        }
                    }
                    if (stdTypeComp != ''){
                        var objStd = {};
                        objStd["name"] = stdNameComp;
                        objStd["type"] = stdTypeComp;
                        objStd["centreId"] = golobalCentreId;
                        var canTake = true;
                        centreOwnComponents.forEach((item)=>{
                            if (item.name === stdNameComp){
                                canTake = false;
                            }
                            arrStd.push(item);    
                        });    
                        if (canTake){
                            arrStd.push(objStd);
                        }
                    }
                    if (stdNamePri != ''){
                        var objStd = {};
                        objStd["name"] = stdNamePri;
                        objStd["type"] = stdTypePri;
                        objStd["centreId"] = golobalCentreId;
                        var canTake = true;
                        centreOwnPrinciples.forEach((item)=>{
                            if (item.name === stdNamePri){
                                canTake = false;
                            }
                            arrStd.push(item);    
                        });    
                        if (canTake){
                            arrStd.push(objStd);
                        }
                    }
                    let sendJson = JSON.stringify(arrStd);
                    console.log("Before Saving Own Standard ");
                    console.log(sendJson);
    
                    axios(CONFIG.MAIN_URL+'/custom-standard-type-client/save',{
                      method: 'post',
                      headers: { 
                          'Content-Type': 'application/json',
                        },
                      data : sendJson,  
                    })
                    .then(function (response) {
                      console.log("Saving Own Standard Retuen");
                      let rtnData = response.data;
                      console.log(rtnData);
                      getOwnStandared(golobalCentreId);
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                }
        
                
                getAllQuestionsForCollection();
                reloadCollectionWithRiks();
                riskClearFunction()
                riskClearFunctionCloseEnded()
    
    
    
                //window.location.reload(false);
            })
            .catch(function (error) {
                console.log(error);
                setWaitingOpen(false);
                alert("Error saving Question -:" + error);
            });
    
            
            
        }

   /**
     * QUSTION DIV
     */

    const [riskQuestion, setRiskQuestion] = useState(false);
    const [RiskD, setRiskD] = useState([])

    function riskTypeQues(closeEndedQuesIds,openEndedQuesIds) {
        var quesIds = [];
        var quesDataArray = [];
        closeEndedQuesIds.forEach((item)=>{
            quesIds.push(item);
        });
        openEndedQuesIds.forEach((item)=>{
            quesIds.push(item);
        });

        quesIds.forEach((item)=>{
            quesData.forEach((data)=>{
                if (data.questionId === item){
                    quesDataArray.push(data);
                    return true;
                }
            });
        });
        console.log("xxxxxxxxxxxxxx")
        console.log(quesDataArray)
        setRiskD(quesDataArray);
        setRiskQuestion(!riskQuestion);
    }




    /**
     * RESIK DIV
     */


    const [openRisk, setOpenRisk] = useState(false);
    const [riskBtnTop, setRiskBtnTop] = useState(0);
    const [riskBtnLeft, setRiskBtnLeft] = useState(0);

    function setOpenRiskFuction(riskFlag) {
        var btnId = document.getElementById("riskBtnId");
        var lastGridId = document.getElementById("lastGridId");
        var top = (btnId.offsetTop-lastGridId.offsetTop);
        setRiskBtnTop(top);
        setRiskBtnLeft(btnId.offsetLeft);
        //setRiskBtnTop(lastGridId.offsetTop);
        if (selectedCollectionRiskId != undefined && selectedCollectionRiskId != null && selectedCollectionRiskId != 0){
            riskTypeData.forEach((item,index)=>{
                if (item.collectionRiskId === selectedCollectionRiskId) {  
                    setRsTtpe(item.riskTypes);
                }
            })
        }
        setOpenRisk(riskFlag);
        
        
        
        
        //document.getElementById("riskDiv").style.marginTop = "218px"
    }



    //malindu section 

    const [riskSliderMethods, setRiskSliderMethods] = useState([])
    const [databClr, setDatabClr] = useState([])
    const [databClrAns, setDatabClrAns] = useState([])
    const [colorPatterns, setColorPatterns] = useState([])


   

    function allRiskMethodData() {
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_RISK+"/all",{
            method: 'get',
            headers: { 
                'Content-Type': 'text/plain', 
              },     
        })
        .then(function (res) {
             
            console.log("All Risk Returns");
            console.log(res.data[0]);
            setRiskSliderMethods(res.data[0].methods)
           // setQuestionCategoryId(res.data[0].id)
            console.log(res.data[0].methods);

        })
        .catch(function (error) {
            console.log(error);
        });

    }



    function getColors(){
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
          method: 'get',
          headers: { 
              'Content-Type': 'text/plain', 
            },     
          })
          .then(function (response) {
            console.log("All colors");
            console.log(response.data);
    
            //setDatabClr(response.data)
            setColorPatterns(response.data);
            setDatabClr([]);
            setDatabClrAns([]);
          })
          .catch(function (error) {
          console.log(error);
          });
      }



    function getRiskLvlName(lvlData,method){
        console.log(method)
        console.log(riskSliderMethods)
       
        var xx =''             
        riskSliderMethods.forEach((data)=>{
 
               if(data.id === method){
                    data.riskTypes.forEach((data1)=> {
                        if(data1.startingNumber <= lvlData && lvlData <= data1.endingNumber){
                          xx = data1.riskTypesName
                          console.log(data1.riskTypesName)
                        }
                      })
                }
            })


       return xx
    }


    function getAppetiteLvlName(lvlData,method){
        var xx =''             
        riskSliderMethods.forEach((data)=>{
 
               if(data.id === method){
                    data.riskTypes.forEach((data1)=> {
                        if(data1.startingNumber <= lvlData && lvlData <= data1.endingNumber){
                          xx = data1.riskTypesName
                          console.log(data1.riskTypesName)
                        }
                      })
                }
            })



       return xx
    }


    function getRiskLvlColor(lvlData){
        var xx = ''

        databClr.forEach((data2)=>{         
          if (parseInt(data2.scoreNumber) === parseInt(lvlData)) {
            xx = data2.color
          }
        })
    

       
return xx
    }

    function getAppetiteLvlColor(lvlData){
        var xx = ''

        databClr.forEach((data2)=>{         
          if (parseInt(data2.scoreNumber) === parseInt(lvlData)) {
            xx = data2.color
          }
        })
    
       
return xx
    }

    function getRisklvlColorData(lvl,coloArray){
        console.log(databClr)
        console.log(lvl)
        var xx = ''

        coloArray.forEach((data2)=>{         
          if (parseInt(data2.scoreNumber) === parseInt(lvl)) {
            xx = data2.color
          }
        })
    
       
        return xx
    }

    function getRiskAppetitelvlColorData(lvl,coloArray){
        var xx = ''

        coloArray.forEach((data2)=>{         
          if (parseInt(data2.scoreNumber) === parseInt(lvl)) {
            xx = data2.color
          }
        })
    
       
return xx
    }


    const [flag, setFlag] = React.useState(true);
     /**
     * Select Risk
     */
         const [selectedRiskName, setSelectedRiskName] = React.useState('');
         const [selectedRiskLevel, setSelectedRiskLevel] = React.useState('');
         const [selectedRiskAppetite, setSelectedRiskAppetite] = React.useState('');
         const [selectedRiskNameStr, setSelectedRiskNameStr] = React.useState('');
         const [selectedRiskId, setSelectedRiskId] = React.useState(0);
         const [selectedCollectionRiskTypeId, setSelectedCollectionRiskTypeId] = React.useState(0);
         const [previousSelectedCollectionRiskTypeId, setPreviousSelectedCollectionRiskTypeId] = React.useState(0);
         const [riskDialogOpen, setRiskDialogOpen] = React.useState(false);
         const [riskDialogScroll, setRiskDialogScroll] = React.useState('paper');
         const riskDialogHandleClickClose = () => {
            setRiskDialogOpen(false);
         };
         const riskDialogHandleClickOpen = (scrollType) => () => {
            setRiskDialogOpen(true);
            setRiskDialogScroll(scrollType);
          };

         
          function setStandardWhenSelect(item1){
            if (item1.riskStandardData != undefined && item1.riskStandardData != null){
              if (item1.riskStandardData.objectiveData != undefined && item1.riskStandardData.objectiveData != null){
                setObjectivedData([]);
                var arryObj = [];
                 var tmpArray = {};
                tmpArray["objectiveId"] = item1.riskStandardData.objectiveData.objectiveId;
                tmpArray["name"] = item1.riskStandardData.objectiveData.objectiveName;
                arryObj.push(tmpArray);
                setObjectivedData(arryObj);
                setObjectiveId(item1.riskStandardData.objectiveData.objectiveId);
                setObjectiveName(item1.riskStandardData.objectiveData.objectiveName);
                setOwnObjectiveFlag(false);
                setSelectedObjectiveStandardName(item1.riskStandardData.objectiveData.standardName);
                setSelectedObjectiveStandardId(item1.riskStandardData.objectiveData.standardId);
              }else{
                setObjectivedData([]);
                setObjectiveId('');
                setObjectiveName('');
                setOwnObjectiveFlag(false);
                setSelectedObjectiveStandardName('');
                setSelectedObjectiveStandardId('');
              }
              if (item1.riskStandardData.componentData != undefined && item1.riskStandardData.componentData != null){
                setComponentsData([]);
                var arryObj = [];
                 var tmpArray = {};
                tmpArray["componentId"] = item1.riskStandardData.componentData.componentId;
                tmpArray["name"] = item1.riskStandardData.componentData.componentName;
                arryObj.push(tmpArray);
                setComponentsData(arryObj);
                setComponentId(item1.riskStandardData.componentData.componentId);
                setComponentName(item1.riskStandardData.componentData.componentName);
                setOwnComponentFlag(false);
                setSelectedComponentStandardName(item1.riskStandardData.componentData.standardName);
                setSelectedComponentStandardId(item1.riskStandardData.componentData.standardId);
              }else{
                setComponentsData([]);
                setComponentId('');
                setComponentName('');
                setOwnComponentFlag(false);
                setSelectedComponentStandardName('');
                setSelectedComponentStandardId('');
              }
              if (item1.riskStandardData.principleData != undefined && item1.riskStandardData.principleData != null){
                setPrincipalsData([]);
                var arryObj = [];
                 var tmpArray = {};
                tmpArray["principalId"] = item1.riskStandardData.principleData.principleId;
                tmpArray["name"] = item1.riskStandardData.principleData.principleName;
                arryObj.push(tmpArray);
                setPrincipalsData(arryObj);
                setPrincipleId(item1.riskStandardData.principleData.principleId);
                setPrincipleName(item1.riskStandardData.principleData.principleName);
                setOwnPrincipalFlag(false);
                setSelectedPrincipleStandardName(item1.riskStandardData.principleData.standardName);
                setSelectedPrincipleStandardId(item1.riskStandardData.principleData.standardId);
              }else{
                setPrincipalsData([]);
                setPrincipleId('');
                setPrincipleName('');
                setOwnPrincipalFlag(false);
                setSelectedPrincipleStandardName('');
                setSelectedPrincipleStandardId('');
              }
            }else{
              setObjectivedData([]);
              setObjectiveId('');
              setObjectiveName('');
              setOwnObjectiveFlag(false);
              setSelectedObjectiveStandardName('');
              setSelectedObjectiveStandardId('');

              setComponentsData([]);
              setComponentId('');
              setComponentName('');
              setOwnComponentFlag(false);
              setSelectedComponentStandardName('');
              setSelectedComponentStandardId('');

              setPrincipalsData([]);
              setPrincipleId('');
              setPrincipleName('');
              setOwnPrincipalFlag(false);
              setSelectedPrincipleStandardName('');
              setSelectedPrincipleStandardId('');

            }

          }
         
         
          const riskHandleChange = (event,comment) => {
            let idAndVal=event.target.value;
            let idAndValArr=idAndVal.split("]");

            
            //setShow(true);
            if (idAndValArr[4] != undefined && idAndValArr[4] != null && idAndValArr[4] != "null" && idAndValArr[4] != "") {
                setSelectedCollectionRiskTypeId(idAndValArr[4]);
                if (idAndValArr[4] === comment.collectionRiskTypeId){
                  setStandardWhenSelect(comment)
                }
            }

            var riskLevelStr = "";
            var riskAppetiteStr = "";

            if (idAndValArr[2] != undefined && idAndValArr[2] != null && idAndValArr[2] != "null" && idAndValArr[2] != "") {
                setSelectedRiskLevel(idAndValArr[2]);

                var riskLvlName = getRiskLvlName(idAndValArr[2],idAndValArr[5]) 

                var riskLvlColor = getRiskLvlColor(idAndValArr[2])

                console.log(riskLvlColor)


                riskLevelStr = " (RisK : " + idAndValArr[2] + "-" + riskLvlName ; 
            }
            if (idAndValArr[3] != undefined && idAndValArr[3] != null && idAndValArr[3] != "null" && idAndValArr[3] != "") {
                setSelectedRiskAppetite(idAndValArr[3]);

                var riskAppetiteName = getAppetiteLvlName(idAndValArr[3],idAndValArr[6])
                
                var riskAppetiteColor = getAppetiteLvlColor(idAndValArr[3])

                if (riskLevelStr != "" ){
                    riskAppetiteStr += ", Appetite : " + idAndValArr[3] + "-" + riskAppetiteName;
                }else{
                    riskAppetiteStr = " (Appetite : " + idAndValArr[3] + "-" + riskAppetiteName;
                }
            }
            if (riskAppetiteStr != ""){
                riskAppetiteStr += ")";
            }
            setSelectedRiskId(idAndValArr[0]);
            setSelectedRiskName(idAndValArr[1]);
            setSelectedRiskNameStr(<div>{idAndValArr[1]}<span style={{color:`${riskLvlColor}`}}>{riskLevelStr}</span><span style={{color:`${riskAppetiteColor}`}}>{riskAppetiteStr}</span></div>);  
            var lbl = "Open Ended Question for "
            setQuestionLableNameOpenEnded(<div>{lbl}{idAndValArr[1]}<span style={{color:`${riskLvlColor}`}}>{riskLevelStr}</span><span style={{color:`${riskAppetiteColor}`}}>{riskAppetiteStr}</span></div>)
            var lbl1 = "Close Ended Question for "
            setQuestionLableNameCloseEnded(<div>{lbl1}{idAndValArr[1]}<span style={{color:`${riskLvlColor}`}}>{riskLevelStr}</span><span style={{color:`${riskAppetiteColor}`}}>{riskAppetiteStr}</span></div>)

            setRiskDialogOpen(false);
        };
         /** End of Select Risk */

        function getQuestionIds(comment,type){
            var count = 0;
            var ids = [];

            if (comment.questions != undefined && comment.questions!= null){
                if (type === 1){
                    if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                        comment.questions.forEach((quesItem)=>{
                            if (quesItem.questionType === "Closeended"){
                                ids.push(quesItem.questionId);
                            }
                        })  
                    }
                    /*
                    if (comment.questions.closeEndedQuestionIds != undefined && comment.questions.closeEndedQuestionIds != null && comment.questions.closeEndedQuestionIds.length > 0){
                        comment.questions.closeEndedQuestionIds.forEach((item,index)=>{
                            ids.push(item.questionId);
                        });
                    }else{
                        ids = [];
                    }
                    */
                }
                if (type === 2){
                    if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                        comment.questions.forEach((quesItem)=>{
                            if (quesItem.questionType === "Openended"){
                                ids.push(quesItem.questionId);
                            }
                        })  
                    }
                    /*
                    if (comment.questions.openEdndedQuestionIds != undefined && comment.questions.openEdndedQuestionIds != null && comment.questions.openEdndedQuestionIds.length > 0){
                        comment.questions.openEdndedQuestionIds.forEach((item,index)=>{
                            ids.push(item.questionId);
                        });
                    }else{
                        ids = [];
                    }
                    */
                }
            }else{
                ids = [];
            }
            return ids;
        }

        function getQuestionIdsWithSubRisks(comment,type){
            var count = 0;
            var riskIds = "";
            var ids = [];
            if (type === 1){
                if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                    comment.questions.forEach((quesItem)=>{
                        if (quesItem.questionType === "Closeended"){
                            ids.push(quesItem.questionId);
                        }
                    })  
                }
                /*
                if (comment.questions != undefined && comment.questions!= null){
                    if (comment.questions.closeEndedQuestionIds != undefined && comment.questions.closeEndedQuestionIds != null && comment.questions.closeEndedQuestionIds.length > 0){
                        comment.questions.closeEndedQuestionIds.forEach((item,index)=>{
                            ids.push(item.questionId);
                        });
                    }else{
                        ids = [];
                    }
                }else{
                    ids = [];
                }
                */
            }
            if (type === 2){
                if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                    comment.questions.forEach((quesItem)=>{
                        if (quesItem.questionType === "Openended"){
                            ids.push(quesItem.questionId);
                        }
                    })  
                }
                /*
                if (comment.questions != undefined && comment.questions!= null){
                    if (comment.questions.openEdndedQuestionIds != undefined && comment.questions.openEdndedQuestionIds != null && comment.questions.openEdndedQuestionIds.length > 0){
                        comment.questions.openEdndedQuestionIds.forEach((item,index)=>{
                            ids.push(item.questionId);
                        });
                    }else{
                        ids = [];
                    }
                }else{
                    ids = [];
                }
                */
            }
            if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
                return getQuestionSubIdsWithSubRisks(comment.subRisks,type,ids);
            }else{
                return ids;
            }
        }
        function getQuestionSubIdsWithSubRisks(comment,type,ids ){
            comment.forEach((data,index)=>{
                if (type === 1){
                    if (data.questions != undefined && data.questions != null && data.questions.length > 0){
                        data.questions.forEach((quesItem)=>{
                            if (quesItem.questionType === "Closeended"){
                                ids.push(quesItem.questionId);
                            }
                        })  
                    }  
                    /* 
                    if (data.questions != undefined && data.questions!= null){
                        if (data.questions.closeEndedQuestionIds != undefined && data.questions.closeEndedQuestionIds != null && data.questions.closeEndedQuestionIds.length > 0){
                            data.questions.closeEndedQuestionIds.forEach((item,index)=>{
                                ids.push(item.questionId);
                            });
                        }
                    }
                    */
                }
                if (type === 2){
                    if (data.questions != undefined && data.questions != null && data.questions.length > 0){
                        data.questions.forEach((quesItem)=>{
                            if (quesItem.questionType === "Openended"){
                                ids.push(quesItem.questionId);
                            }
                        })  
                    }  
                    /*
                    if (data.questions != undefined && data.questions!= null){
                        if (data.questions.openEdndedQuestionIds != undefined && data.questions.openEdndedQuestionIds != null && data.questions.openEdndedQuestionIds.length > 0){
                            data.questions.openEdndedQuestionIds.forEach((item,index)=>{
                                ids.push(item.questionId);
                            });
                        }
                    }
                    */
                }
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0){
                    getQuestionSubIdsWithSubRisks(data.subRisks,type,ids);
                }
            });
            return ids;
            
        }    

        console.log(ansArrayTmp);
        
        function Comment({ comment }) {
            console.log("**************** comment ****************");
            console.log(comment);

            var closeEndedQuestionIds = getQuestionIds(comment,1);
            var openEndedQuestionIds = getQuestionIds(comment,2);
            var closeEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,1);
            var openEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,2);


            const [show, setShow] = React.useState(true);

            const nestedComments = (comment.subRisks || []).map(comment => {
                return <Comment key={comment.riskTypeId} comment={comment} type="child" />
            })
        
            return (
                <div style={{"marginLeft": "15px", "marginTop": "10px"}}>
                    <div style={{display:'flex',textAlign:'center'}}> 
                        <Button 
                            style={{width:'0px', height:'0px', marginTop:'14px'}} 
                            onClick={()=>setShow(!show)} 
                            startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            >
                        </Button>
                        
                        <span style={{marginTop:'-7px !important'}} >
                        <Radio
                            checked={selectedCollectionRiskTypeId === `${comment.collectionRiskTypeId}`}
                            //onChange={riskHandleChange}
                            onChange={(e)=>riskHandleChange(e,comment)} 
                            value={`${comment.riskTypeId}]${comment.name}]${comment.riskLevel}]${comment.riskAppetite}]${comment.collectionRiskTypeId}]${comment.riskLevelData.riskMethodId}]${comment.riskAppetiteData.riskMethodId}`}
                            name="radio-buttons-risks"
                            inputProps={{ 'aria-label': 'A' }}
                            disabled={questId != null?subQuestion != "ok"?true:false:false}
                            style={{marginLeft:'-1px !important'}} 
                            />
                            {show?comment.name + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' (' )   + (closeEndedQuestionIds.length > 0 ?' C : '
                             + closeEndedQuestionIds.length:'')+ (openEndedQuestionIds.length > 0 
                                ?closeEndedQuestionIds.length > 0 ?', O : ' + openEndedQuestionIds.length:' O : ' + openEndedQuestionIds.length:'') + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' )' ) 
                             :comment.name + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' (' )   + (closeEndedQuestionIdsWithSub.length > 0 ?' C : '
                             + closeEndedQuestionIdsWithSub.length:'')+ (openEndedQuestionIdsWithSub.length > 0 
                                ?closeEndedQuestionIdsWithSub.length > 0 ?', O : ' + openEndedQuestionIdsWithSub.length:' O : ' + openEndedQuestionIdsWithSub.length:'') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' )' )    
                             } 

                            {show?closeEndedQuestionIds.length > 0 || openEndedQuestionIds.length > 0 ? 
                              <Button onClick={()=>riskTypeQues(closeEndedQuestionIds,openEndedQuestionIds,!show)}
                            > <DoubleArrowIcon/></Button> : ''
                             :closeEndedQuestionIdsWithSub.length > 0 || openEndedQuestionIdsWithSub.length > 0 ? 
                             <Button onClick={()=>riskTypeQues(closeEndedQuestionIdsWithSub,openEndedQuestionIdsWithSub,!show)}
                           > <DoubleArrowIcon/></Button> : ''   
                            }


                            {/*comment.name + (comment.questions.closeEndedQuestions.length === 0 && comment.questions.openEdndedQuestions.length === 0 ?'' : ' (' )   + (comment.questions.closeEndedQuestions.length > 0 ?' C : '
                             + comment.questions.closeEndedQuestions.length:'')+ (comment.questions.openEdndedQuestions.length > 0 
                                ?' O : ' + comment.questions.openEdndedQuestions.length:'') + (comment.questions.closeEndedQuestions.length === 0 && comment.questions.openEdndedQuestions.length === 0 ?'' : ' )' ) 
                                
                             */} 

                              {/*comment.questions.closeEndedQuestions.length > 0 || comment.questions.openEdndedQuestions.length > 0 ? 
                              <Button onClick={()=>riskTypeQues(comment)}
                            > <DoubleArrowIcon/></Button> : ''*/}


                            
                            
                            
                     </span>    
                    </div>
                    {
                        show?
                            <>
                                {nestedComments}
                            </>
                        :null
                    }
            </div>
          )
        }



        // slider malindu section



        
        const [questionCategoryId, setQuestionCategoryId] = useState()
        
        const [sliderType, setSliderType] = React.useState();

        const [showSelectBar, setShowSelectBar] = React.useState(true);

        const [sliderValue, setSliderValue] = React.useState();

        const [sliderValueStyle, setSliderValueStyle] = React.useState('');

        const [sliderValueName, setSliderValueName] = React.useState('');



        const handleChange = (event) => {  
            setSliderType(event.target.value);

            setShowSelectBar(false);

            questionSliderMethods.forEach((data)=> {
                if (data.id === event.target.value){
                  var colorPatId = data.colorPatternId;
                  if (colorPatId != undefined && colorPatId != null){
                    colorPatterns.forEach((item)=> {
                      if (item.id === colorPatId){
                        setDatabClr(item.colors);
                      }
                    })
                  }
                }
            })
            
          };
        
        
          function riskClearFunction(){
            setShowSelectBar(true);
            setSliderType('');
            setSliderValue();
            setSliderValueName('');

          }

          function nameChange(){
            questionSliderMethods.forEach((data)=> {
              if(sliderType === data.id){
                data.riskTypes.forEach((data1)=> {
                  if (data1.startingNumber <= sliderValue && sliderValue <= data1.endingNumber) {
                    setSliderValueName(data1.riskTypesName)
                    
                  }
                })
              }
            })
            
          
          }


          //close ended

          
          const [sliderTypeCloseEnded, setSliderTypeCloseEnded] = React.useState();
  
          const [showSelectBarCloseEnded, setShowSelectBarCloseEnded] = React.useState(true);
  
          const [sliderValueCloseEnded, setSliderValueCloseEnded] = React.useState();

          const [sliderValueCloseEndedStyle, setSliderValueCloseEndedStyle] = React.useState('');
  
          const [sliderValueNameCloseEnded, setSliderValueNameCloseEnded] = React.useState('');
  

  
  
          const handleChangeCloseEnded = (event) => {  
              setSliderTypeCloseEnded(event.target.value);
              setShowSelectBarCloseEnded(false);
              questionSliderMethods.forEach((data)=> {
                if (data.id === event.target.value){
                  var colorPatId = data.colorPatternId;
                  if (colorPatId != undefined && colorPatId != null){
                    colorPatterns.forEach((item)=> {
                      if (item.id === colorPatId){
                        setDatabClr(item.colors);
                      }
                    })
                  }
                }
            })
              
            };
          
          
            function riskClearFunctionCloseEnded(){
              setShowSelectBarCloseEnded(true);
              setSliderTypeCloseEnded('');
              setSliderValueCloseEnded();
              setSliderValueNameCloseEnded('');
  
            }
  
            function nameChangeCloseEnded(){
              questionSliderMethods.forEach((data)=> {
                if(sliderTypeCloseEnded === data.id){
                  data.riskTypes.forEach((data1)=> {
                    if (data1.startingNumber <= sliderValueCloseEnded && sliderValueCloseEnded <= data1.endingNumber) {
                      setSliderValueNameCloseEnded(data1.riskTypesName)
                      
                    }
                  })
                }
              })
              
            
            }

            function getRisklvlNme(lvl,quesDta,SliderMethods){
                var xx =''             
                SliderMethods.forEach((data)=>{
                    if (quesDta.riskTypes.riskLevelData != undefined && quesDta.riskTypes.riskLevelData != null){
                        if(data.id === quesDta.riskTypes.riskLevelData.riskMethodId){
                            data.riskTypes.forEach((data1)=> {
                                if(data1.startingNumber <= lvl && lvl <= data1.endingNumber){
                                xx = data1.riskTypesName
                                console.log(data1.riskTypesName)
                                }
                            })
            
                        }
                    }
                })
                     
               return xx
            }

            function getRiskAppetitelvlNme(lvl,quesDta,SliderMethods){
                console.log(quesDta)
                var xx =''             
                SliderMethods.forEach((data)=>{
                    if (quesDta.riskTypes.riskAppetiteData != undefined && quesDta.riskTypes.riskAppetiteData != null){
                        if(data.id === quesDta.riskTypes.riskAppetiteData.riskMethodId){
                            data.riskTypes.forEach((data1)=> {
                                if(data1.startingNumber <= lvl && lvl <= data1.endingNumber){
                                xx = data1.riskTypesName
                                console.log(data1.riskTypesName)
                                }
                            })
            
                        }
                    }
                })
                     
               return xx
            }




               //adding colors to slider

               function getSubColorCode(number) {

                var cCode = "#ffffff";
                databClr.forEach((data)=> {
                  if (parseInt(number) === parseInt(data.scoreNumber)){
                    cCode = data.color;
                  }
                })
                return cCode;
              }
              
              function setRiskLvlEditFunction(val, min, max, type){
                var posNumber = val - min;
                var totalNumber = max - min;
                var percentageVal = (posNumber/totalNumber) * 100
                var cCode = getSubColorCode(val);
                var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
                
                setSliderValue(val);
                setSliderValueStyle(cssStr);
                
              }  


              function getSubColorCodeCloseEnded(number) {
                var cCode = "#ffffff";
                databClr.forEach((data)=> {
                  if (parseInt(number) === parseInt(data.scoreNumber)){
                    cCode = data.color;
                  }
                })
                return cCode;
              }
              
              function setRiskLvlEditFunctionCloseEnded(val, min, max, type){
                var posNumber = val - min;
                var totalNumber = max - min;
                var percentageVal = (posNumber/totalNumber) * 100
                var cCode = getSubColorCodeCloseEnded(val);
                var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
                
                setSliderValueCloseEnded(val);
                setSliderValueCloseEndedStyle(cssStr);
                
              }  


              // new section checkbox and tavle

              const [showStandards, setShowStandards] = useState(false);

              const [prevAddedQuestions, setPrevAddedQuestions] = useState([])

              const [refresh, setRefresh] = useState(false)

              const [chkQuestionEditChecked, setChkQuestionEditChecked] = useState(false);

              if(refresh){
               return (<AddQuestionPage/>)
            }




              function activeStandardGrid(flag) {
                setChkQuestionEditChecked(flag)
                if (document.getElementById('chkStandard').checked) {
                    setShowStandards(true);
                }
                else{
                    setShowStandards(false);
                }
            }

            function getPrevQuestionData(centreId){

                axios(CONFIG.MAIN_URL+"/question/getquestiononly?centreId="+ centreId,{
                    
                    method: 'get',
                    // headers: { 
                    //     'Content-Type': 'text/plain', 
                    //   },     
                    })
                    .then(function (response) {
                      console.log("All question");
                      console.log(response.data);
                      setPrevAddedQuestions(response.data)
              
                     
                    })
                    .catch(function (error) {
                    console.log(error);

                    });
            }



            //USEeFFECT

            function editQuestionData(id){
                prevAddedQuestions.forEach((quetionData)=> {
                    if(quetionData.id === id){
                        
                        setIdForEdit(quetionData.id);
                        if (quetionData.objective != null || quetionData.principle != null || quetionData.entity !=null || quetionData.component != null) {
                            setChkQuestionEditChecked(true) 
                            setShowStandards(true)         
                        }
                        


                        if (quetionData.riskTypes != null){

                            axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_RISK+"/all",{
                                method: 'get',
                                headers: { 
                                    'Content-Type': 'text/plain', 
                                  },     
                            })
                            .then(function (res) {
                                 
                                console.log("All Risk Returns");
                                console.log(res.data[0]);
                                setQuestionSliderMethods(res.data[0].methods)
                                setQuestionCategoryId(res.data[0].id)
                                console.log(res.data[0].methods);
        
                                var SliderMethods = []
                                SliderMethods = res.data[0].methods
                                
        
        
                                    
        
                                const riskTypeId = quetionData.riskTypes.riskTypeId === undefined || quetionData.riskTypes.riskTypeId === null?"":quetionData.riskTypes.riskTypeId;
                            
                                const collectionRiskId = quetionData.riskTypes.collectionRiskId;
                                const collectionRiskTypeId = quetionData.riskTypes.collectionRiskTypeId;
            
                                const riskTypeName = quetionData.riskTypes.name;
                                var riskLevel = "";
                                var riskAppetite = "";
                                var riskAppetiteStr = "";
        
          
                                axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                    method: 'get',
                                    headers: { 
                                        'Content-Type': 'text/plain', 
                                      },     
                                    })
                                    .then(function (res) {
                                      console.log("All colors");
                                      console.log(res.data);
                              
                                      var colorDatas =  []
                                      colorDatas = res.data
                                     // setDatabClr(res.data)
                              
                                     
                                    
                                if (quetionData.riskTypes.riskLevel != undefined && quetionData.riskTypes.riskLevel != null && quetionData.riskTypes.riskLevel != ""){
                                    
                                    riskLevel = quetionData.riskTypes.riskLevel;
                                    
                                     var risklvl = getRisklvlNme(riskLevel,quetionData,SliderMethods)
                                     var risklvlColorData = getRisklvlColorData(riskLevel,colorDatas)
        
        
                                    console.log(risklvlColorData)
        
            
                                    var RisklvlData = " (RisK : " + riskLevel + "-" +risklvl; 
                                    setSelectedRiskLevel(riskLevel);
                                }
                                if (quetionData.riskTypes.riskAppetite != undefined && quetionData.riskTypes.riskAppetite != null && quetionData.riskTypes.riskAppetite != ""){
                                    
                                    riskAppetite = quetionData.riskTypes.riskAppetite;
        
                                    var riskAppetitelvl = getRiskAppetitelvlNme(riskAppetite,quetionData,SliderMethods)
        
                                    var riskAppetitelvlColorData = getRiskAppetitelvlColorData(riskAppetite,colorDatas)
        
                                    if (RisklvlData != ""){
                                        riskAppetiteStr += ", Appetite : " + riskAppetite + '-' + riskAppetitelvl;
                                    }else{
                                        riskAppetiteStr = " (Appetite : " + riskAppetite +'-' + riskAppetitelvl ;
                                    }
                                    setSelectedRiskAppetite(riskAppetite);
                                }
                                if (riskAppetiteStr != ""){
                                    riskAppetiteStr += ")";
                                }
        
                                
                                setSelectedRiskNameStr(<div>{riskTypeName}<span style={{color:`${risklvlColorData}`}}> {RisklvlData}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>);
        
                                var lbl = "Open Ended Question for "
                                setQuestionLableNameOpenEnded(<div>{lbl}{riskTypeName}<span style={{color:`${risklvlColorData}`}}> {RisklvlData}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>)
                                var lbl1 = "Close Ended Question for "
                                setQuestionLableNameCloseEnded(<div>{lbl1}{riskTypeName}<span style={{color:`${risklvlColorData}`}}> {RisklvlData}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>)
                                
                                setSelectedRiskId(riskTypeId);
            
                                console.log("++++++++++++++++++riskTypeIdriskTypeIdriskTypeId++++++++++++++++++");
                                console.log(riskTypeId);
                                
                                setSelectedCollectionRiskTypeId(collectionRiskTypeId);
                                setSelectedCollectionRiskId(collectionRiskId);
            
                                setPreviousSelectedCollectionRiskTypeId(collectionRiskTypeId);
                                setPreviousSelectedCollectionRiskId(collectionRiskId);
                                
                                setSelectedRiskName(riskTypeName);
        
        
                            })
                            
                             })
                            .catch(function (error) {
                            console.log(error);
                            });
        
        
        
                        }
        
                        if (quetionData.objective != null && quetionData.objective != ""){
                            if (quetionData.objective.id != undefined && quetionData.objective.id != null && quetionData.objective.id != "") {
                                setObjectivedData([]);
                                var arryObj = [];
                                 var tmpArray = {};
                                tmpArray["objectiveId"] = quetionData.objective.id;
                                tmpArray["name"] = quetionData.objective.name;
                                arryObj.push(tmpArray);
                                setObjectivedData(arryObj);
                                setObjectiveId(quetionData.objective.id);
                                setObjectiveName(quetionData.objective.name);
                                setOwnObjectiveFlag(false);
                                setSelectedObjectiveStandardName(quetionData.objective.standardName);
                                setSelectedObjectiveStandardId(quetionData.objective.standardId);
                            }else{
                                setOwnObjectiveName(quetionData.objective.name);
                                setObjectivedData([]);
                                var arryObj = [];
                                 var tmpArray = {};
                                tmpArray["objectiveId"] = "OBJCUS";
                                tmpArray["name"] = quetionData.objective.name;
                                arryObj.push(tmpArray);
                                setObjectivedData(arryObj);
                                setObjectiveId("OBJCUS");
                                setObjectiveName(quetionData.objective.name);
                                setOwnObjectiveFlag(true);
                            }
        
                        }
                        if (quetionData.component != null && quetionData.component != ""){
                            if (quetionData.component.id != undefined && quetionData.component.id != null && quetionData.component.id != "") {
                                setComponentsData([]);
                                var arryObj = [];
                                 var tmpArray = {};
                                tmpArray["componentId"] = quetionData.component.id;
                                tmpArray["name"] = quetionData.component.name;
                                arryObj.push(tmpArray);
                                setComponentsData(arryObj);
                                setComponentId(quetionData.component.id);
                                setComponentName(quetionData.component.name);
                                setOwnComponentFlag(false);
                                setSelectedComponentStandardName(quetionData.component.standardName);
                                setSelectedComponentStandardId(quetionData.component.standardId);
                            }else{
                                setOwnComponentName(quetionData.component.name);
                                setComponentsData([]);
                                var arryObj = [];
                                 var tmpArray = {};
                                tmpArray["componentId"] = "COMCUS";
                                tmpArray["name"] = quetionData.component.name;
                                arryObj.push(tmpArray);
                                setComponentsData(arryObj);
                                setComponentId("COMCUS");
                                setComponentName(quetionData.component.name);
                                setOwnComponentFlag(true);
                            }
                        }
                        if (quetionData.principle != null && quetionData.principle != ""){
                            if (quetionData.principle.id != undefined && quetionData.principle.id != null && quetionData.principle.id != "") {
                                setPrincipalsData([]);
                                var arryObj = [];
                                 var tmpArray = {};
                                tmpArray["principalId"] = quetionData.principle.id;
                                tmpArray["name"] = quetionData.principle.name;
                                arryObj.push(tmpArray);
                                setPrincipalsData(arryObj);
                                setPrincipleId(quetionData.principle.id);
                                setPrincipleName(quetionData.principle.name);
                                setOwnPrincipalFlag(false);
                                setSelectedPrincipleStandardName(quetionData.principle.standardName);
                                setSelectedPrincipleStandardId(quetionData.principle.standardId);
                            }else{
                                setOwnPrincipalName(quetionData.principle.name);
                                setPrincipalsData([]);
                                var arryObj = [];
                                 var tmpArray = {};
                                tmpArray["principalId"] = "PRICUS";
                                tmpArray["name"] = quetionData.principle.name;
                                arryObj.push(tmpArray);
                                setPrincipalsData(arryObj);
                                setPrincipleId("PRICUS");
                                setPrincipleName(quetionData.principle.name);
                                setOwnPrincipalFlag(true);
                            }
                        }
                        if (quetionData.entity != null && quetionData.entity != ""){
                            if (quetionData.entity.standardId != undefined && quetionData.entity.standardId != null && quetionData.entity.standardId != ""){
                                setSelectedEntityStandardName(quetionData.entity.standardName);
                                setSelectedEntityStandardId(quetionData.entity.standardId);
                            }
                            var tmpEntityData = [];
                            var arrayData = {}
                            arrayData["entityId"] = quetionData.entity.entityId;
                            arrayData["entityName"] = quetionData.entity.name;
                            tmpEntityData.push(arrayData);
                            setEntityData(tmpEntityData);
                            setEntity(quetionData.entity.entityId);
                            setEntityName(quetionData.entity.name);
                        }
        
        
                        if (quetionData.questionType != null && quetionData.questionType != ""){
                            if (quetionData.questionType === "Closeended"){
                                setOCStatus(2);
                                if (quetionData.questionTypeName != null && quetionData.questionTypeName != ""){
                                    setCloseEndedTypeName(quetionData.questionTypeName);
        
                                    if (quetionData.questionTypeName === "Nominal"){
                                        setCETypeStatus(1);
                                        setCloseEndedTypeMsg("Nominal scale is a naming scale, where Answers are simply \“named\” or labeled, with no specific order");
                                    }
                                    if (quetionData.questionTypeName === "Ordinal"){
                                        setCETypeStatus(2);
                                        setCloseEndedTypeMsg("Ordinal scale has all its Answers in a specific order, beyond just naming them");
                                    }
                                    if (quetionData.questionTypeName === "Interval"){
                                        setCETypeStatus(3);
                                        setCloseEndedTypeMsg("Interval scale offers labels, order, as well as, a specific interval between each of its variable options");
                                    }
                                    if (quetionData.questionTypeName === "Ratio"){
                                        setCETypeStatus(4);
                                        setCloseEndedTypeMsg("Ratio scale bears all the characteristics of an interval scale, in addition to that, it can also accommodate the value of “zero” on any of its variables");
                                    }
                                } 
        
                                if (quetionData.question != null && quetionData.question != ""){
                                    setCEQValue(quetionData.question);
                                } 
                                if (quetionData.questionScore != undefined && quetionData.questionScore != null && quetionData.questionScore != ""){
                                    
                                    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION+"/all",{
                                        method: 'get',
                                        headers: { 
                                            'Content-Type': 'text/plain', 
                                          },     
                                    })
                                    .then(function (res) {
                                        console.log("+++++++++++++++All Question Score Returns+++++++++++++++");
                                        console.log(res.data[0]);
                                        setQuestionSliderMethods(res.data[0].methods)
                                        setQuestionCategoryId(res.data[0].id)
                                        console.log(res.data[0].methods);
                
                                        var SliderMethods = []
                                        SliderMethods = res.data[0].methods
                
                                        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                            method: 'get',
                                            headers: { 
                                                'Content-Type': 'text/plain', 
                                              },     
                                        })
                                        .then(function (res1) {
                                              console.log("++++++++++++++All colors+++++++++++++++");
                                              console.log(res1.data);
                                      
                                              var colorDatas =  []
                                              colorDatas = res1.data
                                        })
        
        
        
        
        
                                        var min = 0;
                                        var max = 0
                                        setShowSelectBarCloseEnded(false);
                                        setSliderTypeCloseEnded(quetionData.questionScoreData.riskMethodId)
                                        setSliderValueCloseEnded(quetionData.questionScore);
                                        res.data[0].methods.forEach((data)=> {
                                            if(quetionData.questionScoreData.riskMethodId === data.id){
                                              data.riskTypes.forEach((data1)=> {
                                                if (data1.startingNumber <= quetionData.questionScore && quetionData.questionScore <= data1.endingNumber) {
                                                  setSliderValueNameCloseEnded(data1.riskTypesName)
                                                  min=data.minimumWeightingNumber;
                                                  max=data.maximumWeightingNumber;
                                                }
                                              })
                                            }
                                          })
        
        
                                          var posNumber1 = parseInt(quetionData.questionScore) - min;
                                          var totalNumber1 = max - min;
                                          var percentageVal1 = (posNumber1/totalNumber1) * 100
                                          axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                            method: 'get',
                                            headers: { 
                                                'Content-Type': 'text/plain', 
                                              },     
                                            })
                                            .then(function (respo) {
                                              console.log("All colors");
                                              console.log(respo.data);

                                              var cols = [];  
                                              res.data[0].methods.forEach((data)=> {
                                                if(quetionData.questionScoreData.riskMethodId === data.id){
                                                   var tmpColorPatId = data.colorPatternId;
                                                   respo.data.forEach((colorItem)=>{
                                                      if (colorItem.id === tmpColorPatId){
                                                        setDatabClr(colorItem.colors);
                                                        cols = colorItem.colors;
                                                      }
                                                    })
                                                }
                                              })
            
    
                                      
                                      
                                              cols.forEach((data)=> {
                                                if (parseInt(quetionData.questionScore) === parseInt(data.scoreNumber)){
                                                  var cCode = data.color;
            
                                                  var cssStr1 = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal1 + '%, #d3d3d3 0%)';
                                                  //alert(cssStr1);
                                            
                                                  setSliderValueCloseEndedStyle(cssStr1);
                                                }
                                              })
                                      
                                             
                                            })
                                            .catch(function (error) {
                                            console.log(error);
                                            });
        
             
                                    })
        
                                    
        
        
        
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                }
        
                                if (quetionData.answers != null) {
                                    if(quetionData.answers.answerMethod != null){
                                        var minVal = 0;
                                        var maxVal = 0;
                                        if(quetionData.answers.answerMethod.name != null){
                                            setAnsMethod(quetionData.answers.answerMethod.name);
                                        }    
                                        if(quetionData.answers.answerMethod.minValue != null){
                                            setMinNumber(quetionData.answers.answerMethod.minValue);
                                            minVal = parseInt(quetionData.answers.answerMethod.minValue);
                                        }    
                                        if(quetionData.answers.answerMethod.maxValue != null){
                                            setMaxNumber(quetionData.answers.answerMethod.maxValue);
                                            maxVal = parseInt(quetionData.answers.answerMethod.maxValue);
                                        }    
                                        if (minVal > 0 && maxVal > 0){
                                            setOptArray(range(minVal,maxVal)) ;
                                        }
                                    }
                                    if(quetionData.answers.answerScaleType != null){
                                        if(quetionData.answers.answerScaleType.name != null){
                                            setScalingMethod(quetionData.answers.answerScaleType.name);
                                            if (quetionData.answers.answerScaleType.name === "likert"){
                                                setScallingMsg("It is a psychometric scale questions. Typically a five, seven, or nine-point agreement scale are used");
                                            }
                                            if (quetionData.answers.answerScaleType.name === "semanticdifferential"){
                                                setScallingMsg("Answering options are grammatically on opposite adjectives at each end like love-hate, satisfied-unsatisfied, and likely to return-unlikely to return with intermediate options in between");
                                            }
                                            if (quetionData.answers.answerScaleType.name === "dichotomous"){
                                                setScallingMsg("It can have two possible answers like Yes/No, True/False, Fair/Unfair or Agree/Disagree answers");
                                            }
        
                                        } 
                                    }    
                                    if (quetionData.answers.answerList != null && quetionData.answers.answerList.length > 0) {
                                        var tmpArry = [];
                                        var count = 1;
                                        quetionData.answers.answerList.forEach((ansData,index)=>{
                                            var arrayData = {}
                                            arrayData["answer"] = ansData.answerName;
                                            console.log(ansData)
                                            
                                            arrayData["risk"] = ansData.riskLevel;
                                            arrayData["riskLevelData"] = ansData.riskLevelData;
        
                                            if (ansData.expectedAnswerName != undefined && ansData.expectedAnswerName != null && ansData.expectedAnswerName != "null" && ansData.expectedAnswerName != ""){
                                                arrayData["expectedAnswerName"] = ansData.expectedAnswerName;
                                                if (quetionData.answers.answerMethod.name === "radioButtonType") {
                                                    setSelectedSuggestedValue("" + index);
                                                }
                                            }else{
                                                arrayData["expectedAnswerName"] = "";
                                            }    
                                            arrayData["orderNumber"] = count;
                                           
                                            tmpArry.push(arrayData);
                                            count ++;
                                        }); 
                                        tmpArry.sort(compare);
                                        setAnsArray(tmpArry);
                                        setAnsArrayTmp(tmpArry);
                                        setAnswerOrderNumber(count);
                                    }
        
                                }    
                                
                                
                            }else{
                                setOCStatus(1);
                                if (quetionData.question != null && quetionData.question != ""){
                                    setOEQValue(quetionData.question);
                                }
                                if (quetionData.questionScore != undefined && quetionData.questionScore != null && quetionData.questionScore != ""){
                                    
                                    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION+"/all",{
                                        method: 'get',
                                        headers: { 
                                            'Content-Type': 'text/plain', 
                                          },     
                                    })
                                    .then(function (res) {
                                        console.log("All Question Score Returns");
                                        console.log(res.data[0]);
                                        setQuestionSliderMethods(res.data[0].methods)
                                        setQuestionCategoryId(res.data[0].id)
                                        console.log(res.data[0].methods);
                
                                        var SliderMethods = []
                                        SliderMethods = res.data[0].methods
                
                                        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                            method: 'get',
                                            headers: { 
                                                'Content-Type': 'text/plain', 
                                              },     
                                        })
                                        .then(function (res1) {
                                              console.log("All colors");
                                              console.log(res1.data);
                                      
                                              var colorDatas =  []
                                              colorDatas = res1.data
                                        })
                                        setShowSelectBar(false);
                                        setSliderType(quetionData.questionScoreData.riskMethodId)
                                        setSliderValue(quetionData.questionScore);
        
                                        var min = 0;
                                        var max = 0;
                                        res.data[0].methods.forEach((data)=> {
                                            if(quetionData.questionScoreData.riskMethodId === data.id){
                                              data.riskTypes.forEach((data1)=> {
                                                if (data1.startingNumber <= quetionData.questionScore && quetionData.questionScore <= data1.endingNumber) {
                                                  setSliderValueName(data1.riskTypesName)
                                                  min=data.minimumWeightingNumber;
                                                  max=data.maximumWeightingNumber;
                                                }
                                              })
                                            }
                                          })
        
        
                                          
        
        
                                          var posNumber1 = parseInt(quetionData.questionScore) - min;
                                          var totalNumber1 = max - min;
                                          var percentageVal1 = (posNumber1/totalNumber1) * 100
                                          axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                            method: 'get',
                                            headers: { 
                                                'Content-Type': 'text/plain', 
                                              },     
                                            })
                                            .then(function (response) {
                                              console.log("All colors");
                                              console.log(response.data);
                                      
                                      
                                              response.data.forEach((data)=> {
                                                if (parseInt(quetionData.questionScore) === parseInt(data.scoreNumber)){
                                                  var cCode = data.color;
            
                                                  var cssStr1 = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal1 + '%, #d3d3d3 0%)';
                                            
                                                  setSliderValueStyle(cssStr1);
                                                }
                                              })
                                      
                                             
                                            })
                                            .catch(function (error) {
                                            console.log(error);
                                            });
        
                                         
        
        
            
                                    
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
        
        
        
                                    
                                    
                                    
                                    
                                }
                                if (quetionData.answers != undefined && quetionData.answers != null) {
                                    if (quetionData.answers.answer != undefined && quetionData.answers.answer != null && quetionData.answers.answer != "") {
                                        setOpenEndedAnswerValue(quetionData.answers.answer);
                                    }
                                    if(quetionData.answers.answerMethod != null){
                                        if(quetionData.answers.answerMethod.name != null){
                                            setAnsMethod(quetionData.answers.answerMethod.name);
                                        }    
                                    }
                                }
                            }
                        }
                        setWaitingOpen(false);
                        setSaveBtnName('UPDATE');
                        

                    }
               // }
                })

            }


            const previousRiskTableRowData = (
                <TableBody>
                  {prevAddedQuestions.map((item) => {
            
                    // if (item.centreId != "globle" && item.centreId === globalCentreId){
                    return (
                      <StyledTableRow key={item.riskId} className={classes.tableTrStyle}>
                        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                          <div style={{ whiteSpace: 'normal' }}>{item.question}</div>
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          className={classes.tableTrStyle}
                        >
                          <EditIcon
                            className={classes.mousePointer}
                            onClick={() => { editQuestionData(item.id) }}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                    //  }
                  })}
                </TableBody>
            
              )


        // end of the slider malindu section 



  return (
    <>

        {CONFIG.EXPERT_LOGIN?
        <RightBarDataForOtherPages PageName={headerName}/> 
        :
        <RightBarDataForUserPages PageName={headerName} CentreName={centreName}/> 
        }
        <div style={{marginTop:'120px',padding:'5px'}}>
            <Grid container spacing={2}>
                <Grid item xs={8} className={classes.pageBodyStyle}>
                    <div role="presentation" onClick={handleClick}>
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/login" id='focusHomeLink'>
                          Home
                        </Link>
                        
                        <Typography color="text.primary">Main menu - {headerName}</Typography>
                      </Breadcrumbs>
                    </div>
                </Grid>
                <Grid item xs={12}>
                      <a style={{float: 'right',marginTop: '-36px',color: '#0056b3', cursor:'pointer'}} onClick={() => navigate(-1)}><ArrowBack/> Go Back</a>
                    </Grid>
                {/* <Grid item xs={8} className={classes.pageBodyStyle}></Grid>
                <Grid item xs={4} className={classes.pageBodyStyle}>
                      <Button 
                        variant="contained" 
                        size='small'
                        style={{float: 'right'}}
                        onClick ={ ()=>{reloadAmendements()}}
                        > 
                          Activate New Updates
                      </Button>
                </Grid> */}



                

        
                <Grid container spacing={2} className={classes.pageBodyStyle}>















                    <Grid item xs={12}>
                              <FormLabel id="radio-buttons-group-label">Select Your Question Type</FormLabel>
                              <RadioGroup
                                  row
                                  aria-labelledby="radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  >
                                  <FormControlLabel 
                                      value="openEnded" 
                                      control={<Radio />} 
                                      label="Open-Ended Question" 
                                      checked={openCloseEndedStatus === 1}
                                      onClick={(e) => radioHandler(1)}    
                                  />
                                  <FormControlLabel 
                                      value="closeEnded" 
                                      control={<Radio />} 
                                      label="Close-Ended Question" 
                                      checked={openCloseEndedStatus === 2}
                                      onClick={(e) => radioHandler(2)}
                                  />
                              </RadioGroup>
                      </Grid>
                      {
                          openCloseEndedStatus === 1 && (
                              <AddQustionOpenEnded 
                                  handleOpenEndedChange={handleOpenEndedChange} 
                                  openEndedvalue={openEndedvalue} 
                                  openEndedScoreValue={openEndedScoreValue} 
                                  handleOpenEndedScoreChange={handleOpenEndedScoreChange} 
                                  openEndedAnswerValue={openEndedAnswerValue}
                                  handleOpenEndedAnswerChange={handleOpenEndedAnswerChange}
                                  questionSliderMethods={questionSliderMethods}
                                  sliderType={sliderType}
                                  handleChange={handleChange}
                                  riskClearFunction={riskClearFunction}
                                  showSelectBar={showSelectBar}
                                  sliderValue={sliderValue}
                                  sliderValueName={sliderValueName}
                                  sliderValueStyle={sliderValueStyle}
                                  setSliderValueStyle={setSliderValueStyle}
                                  setSliderValue={setSliderValue}
                                  nameChange={nameChange}
                                  questionLableNameOpenEnded={questionLableNameOpenEnded}
                                  setRiskLvlEditFunction={setRiskLvlEditFunction}
                                  
                                  
                                  
                              />
                          )
                      }
                      
                      {
                          openCloseEndedStatus === 2 && (
                              
                              <AddQustionCloseEnded 
                                  closeEndedvalue={closeEndedvalue}
                                  handleCloseEndedChange={handleCloseEndedChange}
                                  ansArray={ansArray}     
                                  setAnsArray={setAnsArray}
                                  ansMethod={ansMethod}  
                                  setAnsMethod={setAnsMethod}   
                                  minNumber={minNumber}  
                                  setMinNumber={setMinNumber}
                                  maxNumber={maxNumber}   
                                  setMaxNumber={setMaxNumber}   
                                  scalingMethod={scalingMethod}   
                                  setScalingMethod={setScalingMethod} 
                                  scallingMgs={scallingMgs}   
                                  setScallingMsg={setScallingMsg}
                                  closeEndedTypeName={closeEndedTypeName}
                                  setCloseEndedTypeName={setCloseEndedTypeName}
                                  closeEndedTypeStatus={closeEndedTypeStatus}
                                  setCETypeStatus={setCETypeStatus}
                                  ansArrayTmp={ansArrayTmp}
                                  setAnsArrayTmp={setAnsArrayTmp}
                                  closeEndedScoreValue={closeEndedScoreValue} 
                                  handleCloseEndedScoreChange={handleCloseEndedScoreChange} 
                                  answerOrderNumber={answerOrderNumber}
                                  setAnswerOrderNumber={setAnswerOrderNumber}
                                  selectedRiskName={selectedRiskName}
                                  selectedSuggestedValue={selectedSuggestedValue}
                                  setSelectedSuggestedValue={setSelectedSuggestedValue}
                                  optArray={optArray}
                                  setOptArray={setOptArray}
                                  closeEndedTypeMgs={closeEndedTypeMgs}
                                  setCloseEndedTypeMsg={setCloseEndedTypeMsg}

                                  questionSliderMethods={questionSliderMethods}
                                  sliderTypeCloseEnded={sliderTypeCloseEnded}
                                  handleChangeCloseEnded={handleChangeCloseEnded}
                                  riskClearFunctionCloseEnded={riskClearFunctionCloseEnded}
                                  showSelectBarCloseEnded={showSelectBarCloseEnded}
                                  sliderValueCloseEnded={sliderValueCloseEnded}
                                  sliderValueNameCloseEnded={sliderValueNameCloseEnded}
                                  setSliderValueCloseEnded={setSliderValueCloseEnded}
                                  nameChangeCloseEnded={nameChangeCloseEnded}
                                  questionLableNameCloseEnded={questionLableNameCloseEnded}
                                  sliderValueCloseEndedStyle={sliderValueCloseEndedStyle}
                                  setRiskLvlEditFunctionCloseEnded={setRiskLvlEditFunctionCloseEnded}
                                  databClr={databClr}
                                  colorPatterns={colorPatterns}
                                  scaleData={scaleData}


                                  scalingMethodExamples={scalingMethodExamples}
                                  setScalingMethodExamples={setScalingMethodExamples}
                                  scalingMethodExampleId={scalingMethodExampleId}
                                  setScalingMethodExampleId={setScalingMethodExampleId}
                                  scalingMethodExampleName={scalingMethodExampleName}
                                  setScalingMethodExampleName={setScalingMethodExampleName}

                                  databClrAns={databClrAns}
                                  
                              />
                          )
                      }

                                  <Grid item xs={12}>
                                      <FormControlLabel
                                        control={<Checkbox 
                                                  id={"chkStandard"} 
                                                  checked={chkQuestionEditChecked}
                                                  onClick={() => activeStandardGrid(!chkQuestionEditChecked)} 
                                                  />} 
                                        label={"Add Objective, Component, Principle and Entity from Standards OR create your own items if needed"} 
                                      />
                                      </Grid>

                        {showStandards ?
                        
                        

                        <Grid item xs={12}>
                          <Card style={{"width": "100% !important"}}>
                              <CardHeader 
                                  avatar={
                                      <Avatar sx={{ bgcolor: '#4C7EE0', width:'25px', height:'25px' }}>
                                          <Info/>
                                      </Avatar>
                                  } 
                                  className={classes.qusCardHeader} 
                                  title="Pick your Objective, Component, Principle and Entity from Standards OR create your own items"
                              />
                              <CardContent>
                                  <Grid container spacing={2}>
                                      <Grid item xs={6}>
                                          <Box sx={{ minWidth: 120}}>    
                                              <FormControl fullWidth>
                                                  <TextField
                                                      label={objectivePlaceHolderName}
                                                      sx={{ width: '100%' }}
                                                      select
                                                      value={objectiveId}
                                                      onChange={handleChangeObjectives}
                                                      >
                                                      {objectiveData.map((item)=>(
                                                          <MenuItem 
                                                              value={item.objectiveId}
                                                              >
                                                          {item.name}
                                                          </MenuItem>
                                                      ))}

                                                  </TextField>
                                              </FormControl>
                                          </Box>
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={globalObjHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Pick From Global Standards
                                          </Button>
                                                        
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={customObjHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Pick From Custom Standards
                                          </Button>
                                                        
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={ownObjectiveHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Create Your Own Objective
                                          </Button>
                                                        
                                      </Grid>


                                      <Grid item xs={6}>
                                          <Box sx={{ minWidth: 120 }}>    
                                              <FormControl fullWidth>
                                                  <TextField
                                                      label={componentPlaceHolderName}
                                                      sx={{ width: '100%' }}
                                                      select
                                                      value={componentId}
                                                      onChange={handleChangeComponents}
                                                      >
                                                      {componentsData.map((item)=>(
                                                          <MenuItem 
                                                              value={item.componentId}
                                                              >
                                                              {item.name}
                                                          </MenuItem>
                                                      ))}
                                                  </TextField>
                                              </FormControl>
                                          </Box>
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={globalCompHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Pick From Global Standards
                                          </Button>
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={customCompHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Pick From Custom Standards
                                          </Button>
                                                        
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={ownComponentHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                          Create Your Own Component
                                          </Button>
                                      </Grid>                  
                    
                    <Grid item xs={6}>
                          <Box sx={{ minWidth: 120 }}>    
                              <FormControl fullWidth>
                                  <TextField
                                      label={principlePlaceHolderName}
                                      sx={{ width: '100%' }}
                                      select
                                      value={principleId}
                                      style={{onMouseOver: principleName}}
                                      onChange={handleChangePrinciples}
                                      >
                                      {principalsData.map((item)=>(
                                          <MenuItem 
                                              value={item.principalId}
                                              >
                                              {item.name}
                                          </MenuItem>
                                      ))}
                                  </TextField>
                              </FormControl>
                          </Box> 
                    </Grid>
                    {/* 
                    <Grid item xs={1}>
                          <Box
                              component="form"
                              sx={{
                                  '& .MuiTextField-root': { width: '100%' },
                              }}
                              noValidate
                              autoComplete="off"
                              >
                              <div>
                                  <TextField
                                      id="principleScore"
                                      label="Score"
                                      value={principleScoreValue}
                                      onChange={handlePrincipleScoreChange}
                                  />
                              </div>
                          </Box>
                      </Grid>
  */}





                    
  <Grid item xs={2}>
                                          <Button variant="outlined" onClick={globalPriHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Pick From Global Standards
                                          </Button>
                                                        
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={customPriHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Pick From Custom Standards
                                          </Button>
                                                        
                                      </Grid>  
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={ownPrincipalHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                          Create Your Own Principle
                                          </Button>
                                                        
                                      </Grid>                  
                    
                    
                    
                    <Grid item xs={6}>
                          <Box sx={{ minWidth: 120 }}>    
                              <FormControl fullWidth>
                                  <TextField
                                      label={entityPlaceHolderName}
                                      sx={{ width: '100%' }}
                                      select
                                      value={entity}
                                      onChange={handleChangeEntity}
                                      >
                                      {entityData.map((item)=>(
                                          <MenuItem value={item.entityId}>{item.entityName}</MenuItem>
                                      ))}
                                  </TextField>
                              </FormControl>
                          </Box>    
                      </Grid>            
                          <Grid item xs={2}>
                                          <Button variant="outlined" onClick={globalEntHandleClickOpen('paper')} style={{fontSize:'0.7rem', height: '50px'}}>
                                              Pick From Global Standards
                                          </Button>
                                                        
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={customEntHandleClickOpen('paper')} style={{fontSize:'0.7rem', height: '50px'}}>
                                              Pick From Custom Standards
                                          </Button>
                                                        
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={setAllEntities} style={{fontSize:'0.7rem', height: '50px'}}>
                                              Set All Available Entities
                                          </Button>
                                                        
                                      </Grid>



                                  </Grid>
                              </CardContent>
                          </Card>    

                      </Grid>

                                    :''  }

                    {/* <Grid item xs={12}>
                            <FormLabel id="radio-buttons-group-label">Question Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="radio-buttons-group-label"
                                name="radio-buttons-group"
                                >
                                <FormControlLabel 
                                    value="openEnded" 
                                    control={<Radio />} 
                                    label="Open-Ended Question" 
                                    checked={openCloseEndedStatus === 1}
                                    onClick={(e) => radioHandler(1)}    
                                />
                                <FormControlLabel 
                                    value="closeEnded" 
                                    control={<Radio />} 
                                    label="Close-Ended Question" 
                                    checked={openCloseEndedStatus === 2}
                                    onClick={(e) => radioHandler(2)}
                                />
                            </RadioGroup>
                    </Grid> */}
                    
                    {/* {
                        openCloseEndedStatus === 1 && (
                            <QuestionerPageOpenEnded 
                                handleOpenEndedChange={handleOpenEndedChange} 
                                openEndedvalue={openEndedvalue} 
                                openEndedScoreValue={openEndedScoreValue} 
                                handleOpenEndedScoreChange={handleOpenEndedScoreChange} 
                                openEndedAnswerValue={openEndedAnswerValue}
                                handleOpenEndedAnswerChange={handleOpenEndedAnswerChange}
                                questionSliderMethods={questionSliderMethods}
                                sliderType={sliderType}
                                handleChange={handleChange}
                                riskClearFunction={riskClearFunction}
                                showSelectBar={showSelectBar}
                                sliderValue={sliderValue}
                                sliderValueName={sliderValueName}
                                setSliderValue={setSliderValue}
                                nameChange={nameChange}
                                questionLableNameOpenEnded={questionLableNameOpenEnded}
                                
                                
                                
                            />
                        )
                    } */}
                    
                    {/* {
                        openCloseEndedStatus === 2 && (
                            
                            <QuestionerPageCloseEnded 
                                closeEndedvalue={closeEndedvalue}
                                handleCloseEndedChange={handleCloseEndedChange}
                                ansArray={ansArray}     
                                setAnsArray={setAnsArray}
                                ansMethod={ansMethod}  
                                setAnsMethod={setAnsMethod}   
                                minNumber={minNumber}  
                                setMinNumber={setMinNumber}
                                maxNumber={maxNumber}   
                                setMaxNumber={setMaxNumber}   
                                scalingMethod={scalingMethod}   
                                setScalingMethod={setScalingMethod} 
                                scallingMgs={scallingMgs}   
                                setScallingMsg={setScallingMsg}
                                closeEndedTypeName={closeEndedTypeName}
                                setCloseEndedTypeName={setCloseEndedTypeName}
                                closeEndedTypeStatus={closeEndedTypeStatus}
                                setCETypeStatus={setCETypeStatus}
                                ansArrayTmp={ansArrayTmp}
                                setAnsArrayTmp={setAnsArrayTmp}
                                closeEndedScoreValue={closeEndedScoreValue} 
                                handleCloseEndedScoreChange={handleCloseEndedScoreChange} 
                                answerOrderNumber={answerOrderNumber}
                                setAnswerOrderNumber={setAnswerOrderNumber}
                                selectedRiskName={selectedRiskName}
                                selectedSuggestedValue={selectedSuggestedValue}
                                setSelectedSuggestedValue={setSelectedSuggestedValue}
                                optArray={optArray}
                                setOptArray={setOptArray}
                                closeEndedTypeMgs={closeEndedTypeMgs}
                                setCloseEndedTypeMsg={setCloseEndedTypeMsg}

                                questionSliderMethods={questionSliderMethods}
                                sliderTypeCloseEnded={sliderTypeCloseEnded}
                                handleChangeCloseEnded={handleChangeCloseEnded}
                                riskClearFunctionCloseEnded={riskClearFunctionCloseEnded}
                                showSelectBarCloseEnded={showSelectBarCloseEnded}
                                sliderValueCloseEnded={sliderValueCloseEnded}
                                sliderValueNameCloseEnded={sliderValueNameCloseEnded}
                                setSliderValueCloseEnded={setSliderValueCloseEnded}
                                nameChangeCloseEnded={nameChangeCloseEnded}
                                questionLableNameCloseEnded={questionLableNameCloseEnded}
                            />
                        )
                    } */}






                      <Grid item xs={12}>
                          <Stack direction="row" spacing={2}>
                              <Button variant="contained" onClick={() => saveFunction(1)}>{saveBtnName}</Button>
                              <Button variant="contained" onClick={() => clearFuction()}>Clear</Button>

                              {/*<Button variant="contained" onClick={() => saveFunction(2)}>{saveDraftBtnName}</Button>*/}
                          </Stack>


                    <Grid item xs={12} style={{marginTop:'20PX'}}>


                          <Grid item xs={12}>
                      <Card className={classes.stdCard}>
                        <CardHeader
                          avatar={
                            <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                              <Info />
                            </Avatar>
                          }
                          className={classess.commonMainTableHead}
                          title="previous added questions"
                        />
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <div className={classes.scrollableDivToStandard}>
                                <TableContainer
                                // component={Paper}
                                // sx={{ maxHeight: 540 }}
                                >
                                  <Table aria-label="customized table">
                                    <TableHead className={classess.commonSecondaryTableHead}>
                                      <TableRow>
                                        <StyledTableCell className={classess.commonTableThStyle} style={{ width: '65%' }}>Questions</StyledTableCell>
                                        <StyledTableCell align="right" className={classess.commonTableThStyle} style={{ width: '15%' }}>Edit</StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    {previousRiskTableRowData}
                                  </Table>

                                </TableContainer>
                              </div>
                            </Grid>

                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>






















                              </Grid>

                         
                      </Grid>

                      <Grid item xs={12} id="lastGridId">
                          &nbsp;
                      </Grid>


                     

                </Grid>

            </Grid> 

            <div>
     
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"select custom standard"}
        </DialogTitle>
        <DialogContent>
         
        <Box sx={{ minWidth: 120 ,mt:1}}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">standards</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={customId}
          label="standards"
          onChange={handleChangeCustom}
        >
        {standardData.map((stData)=>{
            if(stData.centreId === golobalCentreId){
                return(
                    <MenuItem value={stData.standardId}>{stData.standardName}</MenuItem>
                )
            }
            
        })}
               
        </Select>
      </FormControl>
    </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
         
        </DialogActions>
      </Dialog>
            </div> 
        </div>

    {/** Starting Dialog Box */}
    {/** Risk Pikup dialog box */}






    
    {
           openRisk?
           <Draggable>
           <div id="riskDiv" style={{zIndex:'1000'}}>
              
           <Grid  spacing={2}>
           <Card className={classes.popupdiv} style={{marginTop: + `${riskBtnTop}`+ 'px'}}>
           <Typography variant="h6" style={{float: 'right', marginTop:'-5px'}}><Tooltip title="Close"><Close style={{cursor: 'pointer'}} onClick={()=>setOpenRisk(!openRisk)}/></Tooltip></Typography>        
           <Typography variant="h6" style={{textAlign:'center', marginTop:'0px'}}>{selectYourRiskType}</Typography>
           <Divider light />
               <CardContent>
                   <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ minWidth: 120}}>    
                                <FormControl fullWidth>
                                    <TextField
                                        label="Select a Risk Hierarchy"
                                        sx={{ width: '100%' }}
                                        select
                                        value={selectedCollectionRiskId}
                                        onChange={handleChangeHierarchy}
                                        >
                                        {riskTypeData.map((item)=>(
                                            <MenuItem 
                                                value={item.collectionRiskId}
                                                >
                                                {item.name}
                                            </MenuItem>
                                        ))}

                                    </TextField>
                                </FormControl>
                            </Box>




                        </Grid> 
                        <Grid item xs={12} spacing={2} style={{overflowY: 'scroll', height:'200px'}}>
                            {
                                rsType.map((risktypp) => {
                                    return (
                                        <Comment 
                                            key={risktypp.riskTypeId} 
                                            comment={risktypp} 
                                        />
                                    )
                                })
                            }
                        </Grid>  
                   </Grid> 
               </CardContent>
               <Divider light />
               <CardActions style={{float:'right'}}>
                    <Button  color="primary" onClick={()=>setOpenRisk(!openRisk)} className={classes.cardClose}>
                    Close
                    </Button>
                </CardActions>
           </Card>
       </Grid>
       </div>
       </Draggable>
       :null

        }



{riskQuestion?
            <Draggable>
                <div style={{zIndex:'1000'}}>
                <Grid item xs={6} style={{marginLeft:'20%',backgroundColor:'rgb(246 245 252)', border:'1px solid black'}}>
                        <Typography variant="h6" style={{float: 'right', marginTop:'-5px'}}><Tooltip title="Close"><Close style={{cursor: 'pointer'}} onClick={()=>setRiskQuestion(!riskQuestion)}/></Tooltip></Typography>            
                        <Grid container spacing={2}>
                            <RiskTypeQustionDisplay
                                quesData={RiskD}
                                collectnId={collectnId}
                                setRiskQuestion={setRiskQuestion}
                                riskQuestion={riskQuestion}
                            />
                        </Grid>    
                    </Grid>
                </div>
       </Draggable>
    :null
    }
  

    
  <Dialog
             PaperComponent={PaperComponent}
            open={riskDialogOpen}
            onClose={riskDialogHandleClickClose}
            scroll={riskDialogScroll}
            // aria-labelledby="risk-dialog-scroll-dialog-title"
            aria-describedby="risk-dialog-scroll-dialog-description"
            aria-labelledby="draggable-dialog-title"
            >
            <DialogTitle id="draggable-dialog-title">Select a Risk Type</DialogTitle>
            <DialogContent dividers={riskDialogScroll === 'paper'}>
            <DialogContentText
                id="risk-dialog-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid item xs={12} spacing={2}>
                    <Card style={{"width": "100% !important"}}>
                        <CardContent>
                            <Grid container spacing={2}>
                                    <Grid item xs={12} spacing={2}>
                                                    {
                                                        rsType.map((risktypp) => {
                                                            return (
                                                                <Comment 
                                                                    key={risktypp.riskTypeId} 
                                                                    comment={risktypp} 
                                                                />
                                                            )
                                                        })
                                                    }
                                            
                                    </Grid>  
                            </Grid> 
                        </CardContent>
                    </Card>
                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={riskDialogHandleClickClose}>Cancel</Button>
        </DialogActions>
    </Dialog> 
    {/** Risk Pikup dialog box */}
    {/** Global Objective Dialog Box */}
    <Dialog
            maxWidth={maxWidth}
            open={globalObjOpen}
            onClose={globalObjHandleClickClose}
            scroll={globalObjScroll}
            aria-labelledby="global-obj-scroll-dialog-title"
            aria-describedby="global-obj-scroll-dialog-description"
            >
            <DialogTitle id="global-obj-scroll-dialog-title">Select your Objectives from Global Standards</DialogTitle>
            <DialogContent dividers={globalObjScroll === 'paper'}>
              <DialogContentText
                id="global-obj-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel id="radio-buttons-group-label">Pick your Standard</FormLabel>
                  </Grid>       
                  <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                    <Stack spacing={10} direction="row" className={classes.mainbtn}>
                      {standardData.map((item,index)=>{
                        if (item.centreId === "globle" && item.objectives != null && item.objectives.length > 0 ){
                          return(
                          <Button 
                              variant="contained" 
                              id={"btn" + index}
                              className={classes.globalBtn}
                              onClick={() => getStandardObjectives(item.objectives,item.standardName,item.standardId)}
                              sx={{ boxShadow: 6 }}
                              >
                              {item.standardName}
                          </Button>
                          )
                        }  
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={globalObjHandleClickClose}>Cancel</Button>
            </DialogActions>
        </Dialog>

    {/** End of Global Objective Dialog */}
    {/** Custom Objective Dialog Box */}
    <Dialog
            maxWidth={maxWidth}
            open={customObjOpen}
            onClose={customObjHandleClickClose}
            scroll={customObjScroll}
            aria-labelledby="custom-obj-scroll-dialog-title"
            aria-describedby="custom-obj-scroll-dialog-description"
            >
            <DialogTitle id="custom-obj-scroll-dialog-title">Select your Objectives from Custom Standards</DialogTitle>
            <DialogContent dividers={customObjScroll === 'paper'}>
              <DialogContentText
                id="global-obj-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel id="radio-buttons-group-label">Select your Standard</FormLabel>
                  </Grid>       
                  <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Standard</InputLabel>
                      

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={customObjectiveStanardId}
                        label="standards"
                        onChange={handleChangeCustomObjectiveStanard}
                        >
                        {standardData.map((item)=>{
                            if(item.centreId === golobalCentreId && item.objectives != null && item.objectives.length > 0 ){
                                return(
                                    <MenuItem value={item.standardId}>{item.standardName}</MenuItem>
                                )
                            }
            
                        })}
                     </Select>
                     </FormControl>   
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={customObjHandleClickClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    {/** End of Custom Objective Dialog */}

    {/** Global Component Dialog Box */}
    <Dialog
          maxWidth={maxWidth}
          open={globalCompOpen}
          onClose={globalCompHandleClickClose}
          scroll={globalCompScroll}
          aria-labelledby="global-comp-scroll-dialog-title"
          aria-describedby="global-comp-scroll-dialog-description"
          >
          <DialogTitle id="global-comp-scroll-dialog-title">Select your Components from Global Standards</DialogTitle>
          <DialogContent dividers={globalCompScroll === 'paper'}>
            <DialogContentText
              id="global-comp-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel id="radio-buttons-group-label">Pick your Standard</FormLabel>
                </Grid>       
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <Stack spacing={10} direction="row" className={classes.mainbtn}>
                  {standardData.map((item,index)=>{
                        if (item.centreId === "globle" && item.components != null && item.components.length > 0){
                          return(
                          <Button 
                              variant="contained" 
                              id={"btn" + index}
                              className={classes.globalBtn}
                              onClick={() => getStandardComponents(item.components,item.standardName,item.standardId)}
                              sx={{ boxShadow: 6 }}
                              >
                              {item.standardName}
                          </Button>
                          )
                        }  
                      })}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={globalCompHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Global Component Dialog Box */}
      {/** Custom Component Dialog Box */}
      <Dialog
            maxWidth={maxWidth}
            open={customCompOpen}
            onClose={customCompHandleClickClose}
            scroll={customCompScroll}
            aria-labelledby="custom-Comp-scroll-dialog-title"
            aria-describedby="custom-Comp-scroll-dialog-description"
            >
            <DialogTitle id="custom-Comp-scroll-dialog-title">Select your Components from Custom Standards</DialogTitle>
            <DialogContent dividers={customCompScroll === 'paper'}>
              <DialogContentText
                id="global-obj-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel id="radio-buttons-group-label">Select your Standard</FormLabel>
                  </Grid>       
                  <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Standard</InputLabel>
                      

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={customComponentStanardId}
                        label="standards"
                        onChange={handleChangeCustomComponentStanard}
                        >
                        {standardData.map((item)=>{
                            if(item.centreId === golobalCentreId && item.components != null && item.components.length > 0 ){
                                return(
                                    <MenuItem value={item.standardId}>{item.standardName}</MenuItem>
                                )
                            }
            
                        })}
                     </Select>
                     </FormControl>   
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={customCompHandleClickClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    {/** End of Custom Components Dialog */}


      {/** Global Principal Dialog Box */}
      <Dialog
          maxWidth={maxWidth}
          open={globalPriOpen}
          onClose={globalPriHandleClickClose}
          scroll={globalPriScroll}
          aria-labelledby="global-pri-scroll-dialog-title"
          aria-describedby="global-pri-scroll-dialog-description"
          >
          <DialogTitle id="global-pri-scroll-dialog-title">Select your Principles from Global Standards</DialogTitle>
          <DialogContent dividers={globalPriScroll === 'paper'}>
            <DialogContentText
              id="global-pri-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel id="radio-buttons-group-label">Pick your Standard</FormLabel>
                </Grid>       
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <Stack spacing={10} direction="row" className={classes.mainbtn}>
                  {standardData.map((item,index)=>{
                        if (item.centreId === "globle" && item.principles != null && item.principles.length > 0){
                          return(
                          <Button 
                              variant="contained" 
                              id={"btn" + index}
                              className={classes.globalBtn}
                              onClick={() => getStandardPrinciples(item.principles,item.standardName,item.standardId)}
                              sx={{ boxShadow: 6 }}
                              >
                              {item.standardName}
                          </Button>
                          )
                        }  
                      })}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={globalPriHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Global Principal Dialog Box */}  

      {/** Custom Objective Dialog Box */}
      <Dialog
            maxWidth={maxWidth}
            open={customPriOpen}
            onClose={customPriHandleClickClose}
            scroll={customPriScroll}
            aria-labelledby="custom-Pri-scroll-dialog-title"
            aria-describedby="custom-Pri-scroll-dialog-description"
            >
            <DialogTitle id="custom-Pri-scroll-dialog-title">Select your Principles from Custom Standards</DialogTitle>
            <DialogContent dividers={customPriScroll === 'paper'}>
              <DialogContentText
                id="global-obj-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel id="radio-buttons-group-label">Select your Standard</FormLabel>
                  </Grid>       
                  <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Standard</InputLabel>
                      

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={customPrincipleStanardId}
                        label="standards"
                        onChange={handleChangeCustomPrincipleStanard}
                        >
                        {standardData.map((item)=>{
                            if(item.centreId === golobalCentreId && item.principles != null && item.principles.length > 0 ){
                                return(
                                    <MenuItem value={item.standardId}>{item.standardName}</MenuItem>
                                )
                            }
            
                        })}
                     </Select>
                     </FormControl>   
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={customPriHandleClickClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    {/** End of Custom Principle Dialog */}
    {/** Global Principal Dialog Box */}
    <Dialog
          maxWidth={maxWidth}
          open={globalEntOpen}
          onClose={globalEntHandleClickClose}
          scroll={globalEntScroll}
          aria-labelledby="global-ent-scroll-dialog-title"
          aria-describedby="global-ent-scroll-dialog-description"
          >
          <DialogTitle id="global-ent-scroll-dialog-title">Select your Entities from Global Standards</DialogTitle>
          <DialogContent dividers={globalEntScroll === 'paper'}>
            <DialogContentText
              id="global-ent-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel id="radio-buttons-group-label">Pick your Standard</FormLabel>
                </Grid>       
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <Stack spacing={10} direction="row" className={classes.mainbtn}>
                  {standardData.map((item,index)=>{
                        if (item.centreId === "globle" && item.entities != null && item.entities.length > 0){
                          return(
                          <Button 
                              variant="contained" 
                              id={"btn" + index}
                              className={classes.globalBtn}
                              onClick={() => getStandardEntities(item.entities,item.standardName,item.standardId)}
                              sx={{ boxShadow: 6 }}
                              >
                              {item.standardName}
                          </Button>
                          )
                        }  
                      })}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={globalEntHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Global Entity Dialog Box */}  
      {/** Custom Entity Dialog Box */}
      <Dialog
            maxWidth={maxWidth}
            open={customEntOpen}
            onClose={customEntHandleClickClose}
            scroll={customEntScroll}
            aria-labelledby="custom-Ent-scroll-dialog-title"
            aria-describedby="custom-Ent-scroll-dialog-description"
            >
            <DialogTitle id="custom-Pri-scroll-dialog-title">Select your Entities from Custom Standards</DialogTitle>
            <DialogContent dividers={customEntScroll === 'paper'}>
              <DialogContentText
                id="global-obj-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel id="radio-buttons-group-label">Select your Standard</FormLabel>
                  </Grid>       
                  <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Standard</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={customEntityStanardId}
                        label="standards"
                        onChange={handleChangeCustomEntityStanard}
                        >
                        {standardData.map((item)=>{
                            if(item.centreId === golobalCentreId && item.entities != null && item.entities.length > 0 ){
                                return(
                                    <MenuItem value={item.standardId}>{item.standardName}</MenuItem>
                                )
                            }
            
                        })}
                     </Select>
                     </FormControl>   
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={customEntHandleClickClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    {/** End of Custom Principle Dialog */}
    {/** Create Your Own Objective Dialog */}
    <Dialog
        open={ownObjectiveOpen}
        onClose={ownObjectiveHandleClickClose}
        scroll={ownObjectiveScroll}
        aria-labelledby="own-objective-scroll-dialog-title"
        aria-describedby="own-objective-scroll-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
        >
        <DialogTitle id="own-objective-scroll-dialog-title">Create Your Own Objective</DialogTitle>
        <DialogContent dividers={ownObjectiveScroll === 'paper'}>
            <DialogContentText
                id="custom-objective-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                        <Box sx={{ minWidth: 120}}>    
                            <FormControl fullWidth>
                                <TextField
                                    label='Select Your own objective from the previous Own objectives'
                                    sx={{ width: '100%' }}
                                    select
                                    value={previousOwnObjectiveId}
                                    onChange={handleChangePreviousOwnObjective}
                                    >
                                    {centreOwnObjectives.map((item)=>(
                                        <MenuItem 
                                            value={item.id}
                                            >
                                        {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Box>
                    </Grid>    
                
                    <Grid item xs={10} spacing={2}>
                        <Box
                            component="form"
                            sx={{
                            '& .MuiTextField-root': { width: '100%' },
                            }}
                            validate
                            autoComplete="off"
                            >
                            <div>
                                <TextField
                                    id="ownObjectiveType"
                                    label="Own Objective"
                                    multiline
                                    maxRows={2}
                                    value={ownObjectiveName}
                                    onChange={handleChangeOwnObjective}
                                />
                            </div>
                        </Box> 
                    </Grid>
                    <Grid item xs={2} spacing={2}>
                        <Button 
                            variant="outlined" 
                            startIcon={<AddIcon />} 
                            onClick={() => setOwnObjectiveFunction()}
                            style={{fontSize:'0.7rem', height: '50px'}}
                            >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={ownObjectiveHandleClickClose}>Cancel</Button>
        </DialogActions>
    </Dialog>
    {/** End of Create Your Own Objective Dialog */}
    {/** Create Your Own Component Dialog */}
    <Dialog
        open={ownComponentOpen}
        onClose={ownComponentHandleClickClose}
        scroll={ownComponentScroll}
        aria-labelledby="own-component-scroll-dialog-title"
        aria-describedby="own-component-scroll-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
        >
        <DialogTitle id="own-component-scroll-dialog-title">Create Your Own Component</DialogTitle>
        <DialogContent dividers={ownComponentScroll === 'paper'}>
            <DialogContentText
                id="custom-component-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                   <Grid item xs={12}>
                        <Box sx={{ minWidth: 120}}>    
                            <FormControl fullWidth>
                                <TextField
                                    label='Select Your own component from the previous Own Components'
                                    sx={{ width: '100%' }}
                                    select
                                    value={previousOwnComponentId}
                                    onChange={handleChangePreviousOwnComponent}
                                    >
                                    {centreOwnComponents.map((item)=>(
                                        <MenuItem 
                                            value={item.id}
                                            >
                                        {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={10} spacing={2}>
                        <Box
                            component="form"
                            sx={{
                            '& .MuiTextField-root': { width: '100%' },
                            }}
                            validate
                            autoComplete="off"
                            >
                            <div>
                                <TextField
                                    id="ownComponentType"
                                    label="Your Own Component"
                                    multiline
                                    maxRows={2}
                                    value={ownComponentName}
                                    onChange={handleChangeOwnComponent}
                                />
                            </div>
                        </Box> 
                    </Grid>
                    <Grid item xs={2} spacing={2}>
                        <Button 
                            variant="outlined" 
                            startIcon={<AddIcon />} 
                            onClick={() => setOwnComponentFunction()}
                            style={{fontSize:'0.7rem', height: '50px'}}
                            >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={ownComponentHandleClickClose}>Cancel</Button>
        </DialogActions>
    </Dialog>
    {/** End of Create Your Own Component Dialog */}
    {/** Your Own Principal Dialog */}
    <Dialog
        open={ownPrincipalOpen}
        onClose={ownPrincipalHandleClickClose}
        scroll={ownPrincipalScroll}
        aria-labelledby="own-principal-scroll-dialog-title"
        aria-describedby="own-principal-scroll-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
        >
        <DialogTitle id="custom-principal-scroll-dialog-title">Create Your Own Principle</DialogTitle>
        <DialogContent dividers={ownPrincipalScroll === 'paper'}>
            <DialogContentText
                id="own-principal-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <Box sx={{ minWidth: 120}}>    
                            <FormControl fullWidth>
                                <TextField
                                    label='Select Your own Principle from the previous Own Principle'
                                    sx={{ width: '100%' }}
                                    select
                                    value={previousOwnPrincipleId}
                                    onChange={handleChangePreviousOwnPrinciple}
                                    >
                                    {centreOwnPrinciples.map((item)=>(
                                        <MenuItem 
                                            value={item.id}
                                            >
                                        {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Box>
                    </Grid>
                
                    <Grid item xs={10} spacing={2}>
                        <Box
                            component="form"
                            sx={{
                            '& .MuiTextField-root': { width: '100%' },
                            }}
                            validate
                            autoComplete="off"
                            >
                            <div>
                                <TextField
                                    id="ownPrincipalType"
                                    label="Your Own Principle"
                                    multiline
                                    maxRows={2}
                                    value={ownPrincipalName}
                                    onChange={handleChangeOwnPrincipal}
                                />
                            </div>
                        </Box> 
                    </Grid>
                    <Grid item xs={2} spacing={2}>
                        <Button 
                            variant="outlined" 
                            startIcon={<AddIcon />} 
                            onClick={() => setOwnPrincipalFunction()}
                            style={{fontSize:'0.7rem', height: '50px'}}
                            >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={ownPrincipalHandleClickClose}>Cancel</Button>
        </DialogActions>
    </Dialog>

{/** Dialog Waiting */}
<Dialog
    maxWidth={'md'}
    open={waitingOpen}
    PaperProps={{style:{backgroundColor: 'transparent', boxShadow: 'none'}}} 
    >
    <CircularProgress disableShrink />
  </Dialog>

    </>
    



  )
}

export default AddQuestionPage