import React, {useState, useEffect} from 'react'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages'
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { ArrowBack } from '@mui/icons-material';
import {CONFIG} from '../config';
import axios from 'axios';
import Card from "@mui/material/Card";

import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable'; 
import Paper from '@mui/material/Paper';
import FormLabel from '@mui/material/FormLabel';
import { FormGroup } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useStyle from '../Css/MainCss';
import Tooltip from '@mui/material/Tooltip';
import { Add, AddToPhotosSharp, Close, Redo, Refresh, RestartAlt, Restore, RestorePage } from '@mui/icons-material';
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import EvaluateRiskQuestionerComponent from '../Components/EvaluateRiskQuestionerComponent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info } from '@mui/icons-material';
import EvaluateRiskTemplateQuestionComponent from '../Components/EvaluateRiskTemplateQuestionComponent';
import EvaluateRiskRiskComponent from '../Components/EvaluateRiskRiskComponent';
import { ClockLoader } from 'react-spinners';
import RegisterEvaluateComponent from '../Components/RegisterEvaluateComponent';
import RegisterEvaluateComponent2 from '../Components/RegisterEvaluateComponent2';
import '../Css/EvaluateRisk.css'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));



  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

const EvaluateRisk = ({fromMultiTaskin, onlyRiskRegsiters}) => {

    const [waitingOpen, setWaitingOpen] = React.useState(false);

    const classes = useStyle();
    const navigate = useNavigate();
    const [PageName, setPageName] = useState('Risk Evaluation')
    const [golobalCentreId, setGolobalCentreId] = React.useState('');
    const [userName, setUserName] = React.useState('');
    const [loading, setLoading] = React.useState(false);
  

    function compare( a, b ) {
        var nameA = a.title.toUpperCase(); // ignore upper and lowercase
        var nameB = b.title.toUpperCase(); // ignore upper and lowercase
        if ( nameA < nameB ){
            return -1;
        }
        if ( nameA > nameB ){
            return 1;
        }
        return 0;
    }
    
    

    useEffect(() => {
        getData();
        setLoading(true);
        
        setTimeout(()=> {
            setLoading(false);
          }, 4000); 
          
    if(fromMultiTaskin === true){
        setWaitingOpen(true)
        setTimeout(()=> {
            setWaitingOpen(false);
          }, 4000); 

    }    
    }, [])
    
    function getData () {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        setGolobalCentreId(data.centreId);
        getCollection(data.centreId)
        getQuestionData(data.centreId)
        getAllRisks(data.centreId)
        setUserName(data.name)
        getAllRegister(data.centreId)
    }

    const [allCollectionData, setAllCollectionData] = React.useState([]);

    function getCollection(centre){
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?centreId='+centre,{
            method: 'get',
        })
        .then(function (response) {
            console.log(("+++++++++++++++++++++All Collection in A Center +++++++++++++++++++++"));
            console.log((response.data));
            console.log(("+++++++++++++++++++++All Collection in A Center +++++++++++++++++++++"));
            var sortArr = [];
            response.data.forEach((item)=>{
            if (item.centreId === centre ){
                sortArr.push(item);
            }
            });
            var sortArr = sortArr.sort( compare );
            setAllCollectionData([]);
            findCollectionWithQuestions(sortArr);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    function findCollectionWithQuestions(collections){
        collections.forEach((item)=>{
            var collectionData = {};
            collectionData["id"] = item.id;
            collectionData["documentCreator"] = item.documentCreator;
            collectionData["collectionUniqueName"] = item.collectionUniqueName;
            collectionData["title"] = item.title;
            collectionData["description"] = item.description;
            collectionData["imageUrl"] = item.imageUrl;
            collectionData["status"] = item.status;
            
            var tmpRiskArr = [];
            if (item.risks != undefined && item.risks != null && item.risks.length > 0){
              collectionData["risks"] = item.risks;
              tmpRiskArr = item.risks; 
            }
            getAllQuestions(tmpRiskArr, collectionData);
        });
    
    }
    
    var allQuesArray = [];
    function getAllQuestions(riskArray, collectionData){
        allQuesArray = [];
        riskArray.forEach((item)=>{
            if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
                item.riskTypes.forEach((item1)=>{
                    if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
                        item1.questions.forEach((item2)=>{
                            allQuesArray.push(item2);
                        })  
                    }
                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
                        getAllQuestionsInSubRisks(item1.subRisks);
                    }
                });
            }
        });
        if (allQuesArray.length > 0){
            collectionData["questions"] = allQuesArray;
        }else{
            collectionData["questions"] = [];
        }
        allCollectionData.push(collectionData);
        var tmpCollectionData = allCollectionData; 
        setAllCollectionData([]);
        var arr = [];
        var arrId = [];
        tmpCollectionData.forEach((itemData)=>{
            var canTake = true;
            arrId.forEach((itemId,indexItem)=>{
                if (itemId === itemData.id){
                    arrId.splice(indexItem, 1);
                    arr.splice(indexItem, 1);    
                }
            })  
            if (canTake){
                arrId.push(itemData.id);
                arr.push(itemData);
            }
        })
        setAllCollectionData(arr);
          console.log("+++++++++++++++++++++++++allCollectionData++++++++++++++++");
          console.log(allCollectionData);
          console.log("+++++++++++++++++++++++++allCollectionData++++++++++++++++");
          
    }

    function getAllQuestionsInSubRisks(riskArray){
        riskArray.forEach((item)=>{
            if (item.questions != undefined && item.questions != null && item.questions.length > 0){
                item.questions.forEach((item1)=>{
                    allQuesArray.push(item1);
                })  
            }
            if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
                getAllQuestionsInSubRisks(item.subRisks);
            }
        });  
    }

    /** Template Question Section */
    const [prevAddedQuestions, setPrevAddedQuestions] = useState([])
    function getQuestionData(centreId){
        axios(CONFIG.MAIN_URL+"/question/getquestiononly?centreId="+ centreId,{
            method: 'get',
        })
        .then(function (response) {
            console.log("All question");
            console.log(response.data);
            setPrevAddedQuestions(response.data)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    /** Evaluate Risk */

    const [riskTypeName, setRiskTypeName] = useState([])
    function getAllRisks(centId) {
        axios(CONFIG.MAIN_URL + `/risk/all`, {
          method: 'get',
          headers: {
            'Content-Type': 'text/plain',
          },
        })
        .then(function (response) {
            console.log("All Risk Returns");
            console.log(response.data);
            var arr = [];
            response.data.forEach((item) => {
              if (centId === item.centreId) {
                arr.push(item);
              }
            })
            setRiskTypeName(arr);
    
        })
          .catch(function (error) {
            console.log(error);
        });
    }


    const [allRegister, setAllRegister] = useState([])

    function getAllRegister(centrId) {
        axios(CONFIG.MAIN_URL + `/register/all`, {
          method: 'get',
        })
          .then(function (response) {
            var arr = []
            console.log("all register");
            console.log(response.data);
            response.data.forEach((item) => {
              if (item.centreId === centrId)
                arr.push(item)
                console.log(arr)
    
            })
            setAllRegister(arr);
    
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    
    
        

    return (
        <>
            {loading ?
                <ClockLoader
                    size={120}
                    color={'red'}
                    className={classes.loaderStyle}
                    loading={loading}
                />

            :
            <>
                           {
                fromMultiTaskin ? ''

                : 
                <RightBarDataForOtherPages PageName={PageName} />
            }
                <div className={fromMultiTaskin ? classes.evapageleft : classes.pageTop}>
                    <Grid container spacing={1}>
                        <Grid container spacing={1} className={classes.pageBodyStyle}>
                        {
                                fromMultiTaskin ? ''
                                
                                : 

                                <>
                            <Grid item xs={12}>
                                <div role="presentation" onClick={handleClick}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" to="/login">
                                            <span className='breadcrumbFonts'>Home</span>
                                        </Link>
                                        <Typography color="text.primary"><span className='breadcrumbFonts'>Main menu - Risk Collection (Completed) </span></Typography>
                                    </Breadcrumbs>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <a className='goBack' onClick={() => navigate(-1)}><ArrowBack /> Go Back</a>
                            </Grid>
                            </>
                            }
                        </Grid>
                        {onlyRiskRegsiters != undefined && onlyRiskRegsiters?
                        <Grid item xs={12} style={{alignContent: 'center'}}>
                        <Card style={{"width": "100% !important", border:"1px solid black",height: "480px"}}>
                            <CardHeader 
                                avatar={
                                    <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                        <Info/>
                                    </Avatar>
                                } 
                                className={classes.commonMainTableHeadEval} 
                                title={'Evaluate Registers'}
                                sx={{ fontSize:'25px !important' }}
                            />
                            <CardContent>
                            <RegisterEvaluateComponent2
                                centreId={golobalCentreId}
                                allRegister={allRegister}
                            />
                            </CardContent>
                        </Card>   
                            </Grid>

                        :
                        <>
                        <Grid item className='questionnaireGrid'>
                            <Card className='mainCardStyle'>
                                <CardHeader 
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                            <Info/>
                                        </Avatar>
                                    } 
                                    className={classes.commonMainTableHeadEval} 
                                    title={'Evaluate Questionnaires'}
                                    sx={{ fontSize:'25px !important' }}
                                />
                                <CardContent>
                                    <EvaluateRiskQuestionerComponent
                                        centreId={golobalCentreId}
                                        allCollectionData={allCollectionData}
                                    />
                                </CardContent>
                            </Card>        
                        </Grid>
                        <Grid item className='questionsGrid'>
                            <Card className='mainCardStyle'>
                                <CardHeader 
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                            <Info/>
                                        </Avatar>
                                    } 
                                    className={classes.commonMainTableHeadEval} 
                                    title={'Evaluate Template Questions'}
                                    sx={{ fontSize:'25px !important' }}
                                />
                                <CardContent>
                                    <EvaluateRiskTemplateQuestionComponent
                                        centreId={golobalCentreId}
                                        prevAddedQuestions={prevAddedQuestions}
                                    />
                                </CardContent>
                            </Card>        
                        </Grid>
                        <Grid item className='hierarchyGrid'>
                            <Card className='mainCardStyle'>
                                <CardHeader 
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                            <Info/>
                                        </Avatar>
                                    } 
                                    className={classes.commonMainTableHeadEval} 
                                    title={'Evaluate Risk Hierachies'}
                                    sx={{ fontSize:'25px !important' }}
                                />
                                <CardContent>
                                <EvaluateRiskRiskComponent
                                    centreId={golobalCentreId}
                                    riskTypeName={riskTypeName}
                                />
                                </CardContent>
                            </Card>   
                        </Grid>
                        <Grid item className='registerGrid'>
                            <Card className='mainCardStyle'>
                                <CardHeader 
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                            <Info/>
                                        </Avatar>
                                    } 
                                    className={classes.commonMainTableHeadEval} 
                                    title={'Evaluate Registers'}
                                    sx={{ fontSize:'25px !important' }}
                                />
                                <CardContent>
                                <RegisterEvaluateComponent2
                                    centreId={golobalCentreId}
                                    allRegister={allRegister}
                                />
                                </CardContent>
                            </Card>   
                        </Grid>
                        </>
                        }
                    </Grid>


                </div>
            </>
            }



      </>
  )
}

export default EvaluateRisk