import React, {useEffect, useState} from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CardContent from '@mui/material/CardContent';
import {CONFIG} from '../config'
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { ArrowBack, Info } from '@mui/icons-material';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import { ColorPicker, toColor, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import useStyle from '../Css/MainCss';
import '../Css/AddingColorToSliderPage.css'




function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({ 
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageBtn:{

    height: '60px',
    width: '170px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '12px !important',
  },
  headerLable:{

    color: '#827d7d',
  },
  pageTop:{
    marginTop: '115px',
    marginLeft:'20px'
  },
  pageBodyStyle: {
      paddingTop: '12px',

   
  },
  root: {
    position: 'relative',
  },
  posRelative: {
    position: 'relative',
  },
  topImagePos: {
    position: 'absolute', 
    top: '33%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  topHeaderText1: {
    color: '#9155FD',
    fontSize:'25px', 
    paddingBottom:'10px',
  },
  topHeaderText2: {
    color: '#3A3541',
    fontSize:'12px', 
    textAlign:'center',
    fontFamily: 'Inter',
  },
  thStyle: {
    fontSize: '12px !important',
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important'
  },
  thStyleImage: {
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important',
    width: '130px !important',
  },
  btnColorPurple:{
    fontSize: '12px !important',
    backgroundColor: '#9155FD !important',
    height:'40px',
    textAlign:'center'
  },
  riskImageSize: {
    width: '120px',
    height: '100px !important',
  },
  tableStyle: {
    width: '55% !important',
    minWidth:'720px !important'
  },
  btnBorderGreen: {
    border: '1px solid #0e640e !important',
    color: '#0e640e !important',
    height:'40px'
  },
  btnclear:{
    height:'40px'
  },
  btnBorderAsh: {
    border: '1px solid #8a8181 !important',
    color: '#8a8181 !important',
  },
  btnBorderRed: {
    border: '1px solid #ff0000 !important',
    color: '#ff0000 !important',
  },
  qusCard: {
    width: '100% !important',
  },
  qusCardHeader: {
    backgroundColor: '#ece9fa !important',
  },
  subText: {
    fontSize: '12px !important',
  },
  btnPadding: {
    paddingLeft:'2px !important',
    paddingRight:'2px !important',
  },
  cardBodyH6: {
    fontSize: '14px !important',
  },
  cardBodyH6Ans: {
    fontSize: '13px',
    paddingLeft: '12px',
  },
  addBodyPlus: {
    fontSize: '146px !important',
    fontWeight: '1000 !important',
    textAlign: 'center !important',
    color: '#3A3541 !important',
  },
  posRelativeAndDivBorder: {
    position: 'relative',
    border: '1px solid #9155FD', 
    width: '120px',
    height: '100px !important',
  },
  hightrachytitle:{
    textAlign:'left',
    color:'#3A3541',
    
  },
  btnBorderpurple:{
    border: '1px solid #9155FD !important',
    color: '#9155FD !important',
    padding:'5px 10px !important',
    height: '40px !important',
  },
  hightrachytitlestyle:{
    fontSize:'14px !important',
    fontWeight:500
  },
  modalbox:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 14,
    padding: 10,
  },
  addriskbtn:{
    color:'#9155FD !important',
    border:'1px solid #9155FD !important',
    marginTop:'20px !important'
  },
  risktytext:{
    width:'350px',

  },
  canclbtn:{
    alignItem:'left !important' ,
    justifyContent: 'end'
  },
  cardRiskManage:{
      width:'98%',
      border:'1px solid black'
  },
  scrollableDiv: {
    height: '150px',
    overflow: 'auto',
    width: '100%',
  },
  tableHeadStyle: {
    backgroundColor: '#d5d5e1',
    color: '#ffffff',
  },
  tableThStyle: {
    lineHeight: '0.25rem !important'
  },
  tableTrStyle: {
    //lineHeight: '0.20rem !important',
    height: '19px !important'
  },
  mousePointer: {
    cursor: 'pointer'
  },
  scrollableDiv: {
    height: '250px',
    overflow: 'auto',
    width: '100%',
  },
  stdMainCardHeader:{
    backgroundColor:'#acacbe !important',
    fontSize:'13px !important',
    height:'50px',
    padding:'0px',
    marginTop: '-7px',
  },
  addrisktypebtn:{
    marginLeft:'0px !important',
    border: '1px solid #4C7EE0 !important',
    color: '#4C7EE0 !important',
    padding:'3px 7px !important',
    height: '30px !important',
  },

  btnBorderpurple:{
    border: '1px solid #9155FD !important',
    color: '#9155FD !important',
    padding:'5px 10px !important',
    height: '40px !important',
  },
  stdCard:{
    height:'60%',
    border:'1px solid black'
  },
  scrollableDivToStandard: {
    height: '400px',
    overflow: 'auto',
    width: '100%',
  },
}));

const AddingColorToSliderPage = () => {
  const navigate = useNavigate();
  const classe = useStyle();
    const classes = useStyles();
    const Input = styled('input')({
       display: 'none',
    });

    function compareScoreNumber( a, b ) {
      var nameA = parseInt(a.scoreNumber); // ignore upper and lowercase
      var nameB = parseInt(b.scoreNumber); // ignore upper and lowercase
    
      if ( nameA < nameB ){
          return -1;
      }
      if ( nameA > nameB ){
          return 1;
      }
      // names must be equal
      return 0;
    }
    
  
    const [golobalCentreId, setGolobalCentreId] = React.useState('');
    const [color, setColor] = useColor("hex", "#121212");
    const [colorSecondary, setColorSecondary] = useColor("hex", "#121212");
    
  
    React.useEffect(() => {
        getData();
    }, [])


    const [pickerWidth, setPickerWidth] = React.useState(225);

    const [pickerHeight, setPickerHeight] = React.useState(200);
  
  
    useEffect (() => {
      console.log("++++++++++++window. screen. width+++++++++++++");
      if (window. screen. width <= 480){
           setPickerWidth(150)
           setPickerHeight(160)
      }else{
        if (window. screen. width <= 810 && window. screen. width >= 481){
          setPickerWidth(190)
          setPickerHeight(160)
        }
      }
  
      
      console.log(window. screen. width);
    },[])
  
    function getData () {
      let data = sessionStorage.getItem('sessionstorage');
      data = JSON.parse(data);
      setGolobalCentreId(data.centreId);
      getAllColors();
      console.log(data)
    }
  
   // console.log(selectedFile)
    const [saveBtnName, setSaveBtnName] = useState("+ Add Color");
    const [scoreNumber, setScoreNumber] = useState('')
    const [sliderColor, setSliderColor] = useState('');
    const [sliderSecondaryColor, setSliderSecondaryColor] = useState('');

    const [colorIdvalue, setColorIdvalue] = React.useState('');
    const [sliderColorvalue, setSliderColorvalue] = React.useState(0);
    
    const onTextFieldChange = (event) => {
      const value = event.target.value;
      return (Number.isInteger(+value) || value === '-') ? setScoreNumber(value) : value.slice(0,-1);
    }

    const onTextFieldChangeForSliderColor = (event) => {
      setSliderColor(event.target.value);
    }

    const onTextFieldChangeForSliderSecondaryColor = (event) => {
      setSliderSecondaryColor(event.target.value);
    }
    
  
    function clearFuction() {
      setScoreNumber("");
      setSliderColor("")
      setSliderSecondaryColor("")
      setSaveBtnName("+ Add Color");
      setColorIdvalue('');
      setSliderColorvalue(0);
      setColor(toColor("hex", "#121212"));
      setColorSecondary(toColor("hex", "#121212"));
      setCreateHeaderInfoMessage("Add and Edit Slider Colors");
      setPrimaryDescriptionForEachColor('')
      setSecondaryDescriptionForEachColor('')
    }
  
    const [createheaderInfoMessage, setCreateHeaderInfoMessage] = useState("Add and Edit Slider Colors");

    const [allColorsForEachPattern, setAllColorsForEachPattern] = useState([])

    console.log(allColorsForEachPattern)
  
  
      function saveColor(){

        if ( scoreNumber === ""){
          alert("Please enter score number ");
          return
        }
        if ( sliderColor === ""){
          alert("Please enter color ");
          return
        }
        if (colorIdvalue === ''){
          var taken = true;
          allColorsForEachPattern.forEach((data)=>{
              if (parseInt(data.scoreNumber) === parseInt(scoreNumber)) {
                taken=false;
              } 
 
          })
          if (!taken){
            alert("The score number \"" + scoreNumber + "\" is already in the list");
            return
          }

        }  


          if (colorIdvalue != ''){
            allColorsForEachPattern.forEach((data)=>{
 
                if(colorIdvalue === data.id){

                    data["id"] = data.id;
                    data["color"] = sliderColor;
                    data["primaryColorName"] = primaryDescriptionForEachColor;
                    if (sliderSecondaryColor != ''){
                    data["secondaryColor"] = sliderSecondaryColor;
                    data["secondaryColorName"] = secondaryDescriptionForEachColor;
                    }
                  }

              })
            }
            else{
                var tmpArray = {};
                // if (colorIdvalue != ''){
                //   tmpArray["id"] = colorIdvalue;
                // }
                tmpArray["scoreNumber"] = parseInt(scoreNumber);
                tmpArray["color"] = sliderColor;
                tmpArray["primaryColorName"] = primaryDescriptionForEachColor;
                if (sliderSecondaryColor != ''){
                  tmpArray["secondaryColor"] = sliderSecondaryColor;
                  tmpArray["secondaryColorName"] = secondaryDescriptionForEachColor;
                }
                allColorsForEachPattern.push(tmpArray)


            }
          
              //allColorsForEachPattern.push(tmpArray)
              clearFuction()

        
  
      }


      function savecolorPlatter(e){

        if ( colorPlatterName === ""){
          alert("Please enter Color Platter Name ");
          return
        }
        if ( primaryDescription === ""){
          alert("Please enter primary Color Description ");
          return
        }

        if (allColorsForEachPattern.length === 0) {
          alert("Please enter atleast one color to slider");
          return
          
        }
        var dataSend = ''

        if (colorPlatterId != ''){
          previousColors.forEach((data)=>{
              if(colorPlatterId === data.id){
                data["id"] = colorPlatterId;

                data["name"] = colorPlatterName;
                data["primaryColorName"] = primaryDescription;
                data["secondaryColorName"] = secondaryDescription;
                data["colors"] = allColorsForEachPattern;

                dataSend = JSON.stringify(data);
                  }
                })
          }
          else{
            var tmpArray1 = {};
            tmpArray1["name"] = colorPlatterName;
            tmpArray1["primaryColorName"] = primaryDescription;
            tmpArray1["secondaryColorName"] = secondaryDescription;
            tmpArray1["colors"] = allColorsForEachPattern;

            dataSend = JSON.stringify(tmpArray1);
          }

          if (colorPlatterId != ''){
            var url = '/color/update'
          }
          else{
            var url = '/color/create'
          }

        axios(CONFIG.MAIN_URL+ url,{
              method: 'post',
              headers: { 
                  'Content-Type': 'application/json',
                },
              data : dataSend,  
          })
          .then(function (response) {

            setColorPlatterName('')
            setPrimaryDescription('')
            setSecondaryDescription('')
            setAllColorsForEachPattern([])
            setAllDataSave('save')
            clearFuction()
            setColorPlatterId('')
            dataSend = ''
             getAllColors()
              if (colorPlatterId != ''){
                alert("color Updated successfully");
              }else{
                alert("Color saved successfully");
              } 

          })
          .catch(function (error) {
              console.log(error);
              alert("Error saving Risk Type -:" + error);
        });

      }
     
  
  const [previousColors, setPreviousColors] = useState([])
  
  function getAllColors(){
    axios(CONFIG.MAIN_URL+'/color/all',{
      method: 'get',
      headers: { 
          'Content-Type': 'text/plain', 
        },     
      })
      .then(function (response) {
        console.log("All Colors Returns");
        console.log(response.data);
        var arr = response.data;
        arr = arr.sort (compareScoreNumber);
        setPreviousColors(arr)
        
      })
      .catch(function (error) {
      console.log(error);
      });
  }





    const previousColorTableRowData = (
     <TableBody>
     {allColorsForEachPattern.map((item)=>{
  
      // if (item.centreId != "globle" && item.centreId === globalCentreId){
        return (
      <StyledTableRow key={item.id}  className={classes.tableTrStyle}>     
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal' }}>{item.scoreNumber}</div>
          
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal', color: item.color }}>{item.color}</div>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal', color: (item.secondaryColor != null && item.secondaryColor != undefined? item.secondaryColor:'')}}>{item.secondaryColor != null && item.secondaryColor != undefined? item.secondaryColor:''}</div>
        </StyledTableCell>
        <StyledTableCell 
           align="right"
          className={classes.tableTrStyle}
          >
          <EditIcon 
            className={classes.mousePointer}
            onClick ={ ()=>{editColorData(item.id)}}
          />
        </StyledTableCell>
      </StyledTableRow>
        )
      //  }
    })}
   </TableBody>
  
   )


   const previousColorPlatterTableRowData = (
    <TableBody>
    {previousColors.map((item,index)=>{
 
     // if (item.centreId != "globle" && item.centreId === globalCentreId){
       return (
     <StyledTableRow key={item.id}  className={classes.tableTrStyle}>     
       <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
         <div style= {{ whiteSpace: 'normal' }}>{index+1}</div>
         
       </StyledTableCell>
       <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
         <div style= {{ whiteSpace: 'normal', color: item.color }}>{item.name}</div>
       </StyledTableCell>
    
       <StyledTableCell 
          align="right"
         className={classes.tableTrStyle}
         >
         <EditIcon 
           className={classes.mousePointer}
           onClick ={ ()=>{editColorPttern(item.id)}}
         />
       </StyledTableCell>
     </StyledTableRow>
       )
     //  }
   })}
  </TableBody>
 
  )
  
   function editColorData(id) {
    allColorsForEachPattern.forEach((data)=>{
      if (data.id === id) {
        setColorIdvalue(data.id)
        setScoreNumber(data.scoreNumber);
        setSliderColor(data.color);
        setPrimaryDescriptionForEachColor(data.primaryColorName)
        setSecondaryDescriptionForEachColor(data.secondaryColorName)

        setColor(toColor("hex", data.color));
        if (data.secondaryColor != null && data.secondaryColor != undefined){
          setSliderSecondaryColor(data.secondaryColor);
          setColorSecondary(toColor("hex", data.secondaryColor));
        }else{
          setSliderSecondaryColor("")
          setColorSecondary(toColor("hex", "#121212"));
        }
        setSaveBtnName("Update Color");
        setCreateHeaderInfoMessage("Edit Slider Colors")
      }  
    })
  }



  function editColorPttern(id) {
    previousColors.forEach((data)=>{
      if (data.id === id) {
        setColorPlatterId(data.id)
        setColorPlatterName(data.name)
        setPrimaryDescription(data.primaryColorName)
        setSecondaryDescription(data.secondaryColorName)
        setAllColorsForEachPattern(data.colors)
        setAllDataSave('update')

        // setScoreNumber(data.scoreNumber);
        // setSliderColor(data.color);
        // setColor(toColor("hex", data.color));
        // if (data.secondaryColor != null && data.secondaryColor != undefined){
        //   setSliderSecondaryColor(data.secondaryColor);
        //   setColorSecondary(toColor("hex", data.secondaryColor));
        // }else{
        //   setSliderSecondaryColor("")
        //   setColorSecondary(toColor("hex", "#121212"));
        // }
        // setSaveBtnName("Update Color");
        // setCreateHeaderInfoMessage("Edit Slider Colors")
      }  
    })

    
  }
  /** End of Comment section */

  function setFromColorPalate(col){
    setSliderColor(col);
  }
  function setFromSecondaryColorPalate(col){
    setSliderSecondaryColor(col);
  }

  const [colorPlatterId, setColorPlatterId] = useState('')

  const [colorPlatterName, setColorPlatterName] = useState('')

  const [primaryDescription, setPrimaryDescription] = useState('')

  const [secondaryDescription, setSecondaryDescription] = useState('')



  const [primaryDescriptionForEachColor, setPrimaryDescriptionForEachColor] = useState('')

  const [secondaryDescriptionForEachColor, setSecondaryDescriptionForEachColor] = useState('')

  const onTextFieldChangeForColorPlatterName = (event) => {
    setColorPlatterName(event.target.value);
  }

  const onTextFieldChangeForPrimaryDescription = (event) => {
    setPrimaryDescription(event.target.value);
  }

  const onTextFieldChangeForSecondaryDescription = (event) => {
    setSecondaryDescription(event.target.value);
  }

  const onTextFieldChangeForPrimaryDescriptionForEachColor = (event) => {
    setPrimaryDescriptionForEachColor(event.target.value);
  }

  const onTextFieldChangeForSecondaryDescriptionForEachColor = (event) => {
    setSecondaryDescriptionForEachColor(event.target.value);
  }


  function allDataClearFunction(e){
    clearFuction()
    setAllColorsForEachPattern([])
    setColorPlatterName('')
    setPrimaryDescription('')
    setSecondaryDescription('')
    setColorPlatterId('')
    setAllDataSave('save')

  }

  const [allDataSave, setAllDataSave] = useState("save")
  
  const [PageName, setPageName] = useState('Create and Edit Slider Colors')
    return (
      <>
      <RightBarDataForOtherPages PageName={PageName}/> 
          <div className='pageTop'>
              <Grid container spacing={2}>
              <Grid container spacing={2} className={classes.pageBodyStyle}>
                <Grid item xs={12} >
                  <div role="presentation" onClick={handleClick} >
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link underline="hover" color="inherit" to="/login">
                        <span className='BreadcrumbsText'>Home</span>
                      </Link>
                      <Typography color="text.primary"><span className='BreadcrumbsText'>Main menu - Create and Edit Slider Colors</span> </Typography>
                    </Breadcrumbs>
                  </div>
                  </Grid>
                  <Grid item xs={12}>
                    <a className='BreadcrumbsText' style={{float: 'right',marginTop: '-36px',color: '#0056b3', cursor:'pointer'}} onClick={() => navigate(-1)}><ArrowBack/> Go Back</a>
                  </Grid>
                  <>
                  <Grid container spacing={1}>
                    <Grid className='mainGrid' >
                      <Card className='cardRiskManage' >
                        <CardHeader 
                            avatar={
                              <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                <Info/>
                              </Avatar>
                              } 
                            className={classe.commonMainTableHead} 
                            title={createheaderInfoMessage}
                            />
                           <CardContent>


                           <Grid container spacing={1} style={{marginBottom:'20px'}}>
                           <Grid item xs={4}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    >
                                    <TextField
                                              id="title"
                                              size='small'
                                              label="Color Platter Name"
                                              multiline
                                              maxRows={2}
                                              name='title'
                                              required 
                                             value={colorPlatterName}
                                              onChange={onTextFieldChangeForColorPlatterName}
                                    />
                                    </Box>

                                </Grid>

                                <Grid item xs={4}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    >
                                    <TextField
                                              id="title"
                                              size='small'
                                              label="Primary Color Description"
                                              multiline
                                              maxRows={2}
                                              name='title'
                                              required 
                                             value={primaryDescription}
                                              onChange={onTextFieldChangeForPrimaryDescription}
                                    />
                                    </Box>

                                </Grid>

                                <Grid item xs={4}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    >
                                    <TextField
                                              id="title"
                                              size='small'
                                              label="Secondary Color Description"
                                              multiline
                                              maxRows={2}
                                              name='title'
                                              required 
                                              value={secondaryDescription}
                                              onChange={onTextFieldChangeForSecondaryDescription}
                                    />
                                    </Box>

                                </Grid>
                                
                                      </Grid>

                                      <hr style={{height:'3px'}}></hr>

                            <Grid container spacing={1}>   

                              <Grid item className='addnEditLeft'>                           

                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    >
                                    <TextField
                                      id="title"
                                      size='small'
                                      label="Score Number"
                                      multiline
                                      maxRows={2}
                                      name='title'
                                      required 
                                      value={scoreNumber}
                                      onChange={onTextFieldChange}
                                    />
                                  </Box>
                                </Grid>
                                </Grid>
                                <Grid container spacing={1} style={{marginTop:'5px'}}>
                                <Grid item xs={6}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    >
                                    <TextField
                                              id="title"
                                              size='small'
                                              label="Primary Color for risk"
                                              multiline
                                              maxRows={2}
                                              name='title'
                                              required 
                                              value={sliderColor}
                                              onChange={onTextFieldChangeForSliderColor}
                                    />
                                    </Box>

                                </Grid>
                                <Grid item xs={6}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    >
                                    <TextField
                                              id="title"
                                              size='small'
                                              label="Secondary Color for preferences"
                                              multiline
                                              maxRows={2}
                                              name='title'
                                              value={sliderSecondaryColor}
                                              onChange={onTextFieldChangeForSliderSecondaryColor}
                                    />
                                    </Box>

                                </Grid>
                                </Grid>
                                
                                <Grid container spacing={1} style={{marginTop:'5px'}}>
                                <Grid item xs={6}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    >
                                    <TextField
                                              id="title"
                                              size='small'
                                              label="Primary Color Description"
                                              multiline
                                              maxRows={2}
                                              name='title'
                                              required 
                                              value={primaryDescriptionForEachColor}
                                              onChange={onTextFieldChangeForPrimaryDescriptionForEachColor}
                                    />
                                    </Box>

                                </Grid>
                                <Grid item xs={6}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    >
                                    <TextField
                                              id="title"
                                              size='small'
                                              label="Secondary Color Description"
                                              multiline
                                              maxRows={2}
                                              name='title'
                                              value={secondaryDescriptionForEachColor}
                                              onChange={onTextFieldChangeForSecondaryDescriptionForEachColor}
                                    />
                                    </Box>

                                </Grid>
                                </Grid>
                                <Grid container spacing={1} style={{marginTop:'5px'}}>
                                <Grid item xs={12}>
                                <Button 
                                      variant="outlined" 
                                      size='small' 
                                      onClick={saveColor}
                                      className={classes.addrisktypebtn}
                                     // style={{float:'right'}}
                                      > 
                                      {saveBtnName}
                                  </Button>&nbsp;&nbsp;&nbsp;
                                  <Button 
                                      variant="outlined" 
                                      size='small' 
                                      onClick={clearFuction}
                                      className={classes.addrisktypebtn}
                                      //style={{float:'right'}}
                                      > 
                                      Clear
                                  </Button>


                                </Grid>
                                </Grid >
                                <Grid container spacing={1}>
                                <Grid item xs={6} spacing={2}>

                                        <ColorPicker 
                                          width={pickerWidth} 
                                          height={pickerHeight} 
                                          color={color} 
                                          onChange={setColor}
                                          onChangeComplete={(color) => {
                                            setFromColorPalate(color.hex);
                                          }}
                                          hideHSV dark 
                                          hideRGB alpha
                                          />
                                 </Grid>
                                 <Grid item xs={6} spacing={2}>

                                        <ColorPicker 
                                          width={pickerWidth} 
                                          height={pickerHeight} 
                                        
                                          // className='pickerStyle'
                                          color={colorSecondary} 
                                          onChange={setColorSecondary}
                                          onChangeComplete={(colorSecondary) => {
                                            setFromSecondaryColorPalate(colorSecondary.hex);
                                          }}
                                          hideHSV dark 
                                          hideRGB alpha
                                          />
                                 </Grid>
                                 </Grid>

                                 </Grid>
                                 <Grid item className='addnEditRight'>
                                 <Grid container spacing={2}>
                                 <Grid item xs={12}>
                                   <div style={{float:'right'}}>
                                       <Button variant="contained"  onClick={savecolorPlatter}>{allDataSave}</Button> &nbsp;&nbsp;
                                       <Button variant="contained"  onClick={allDataClearFunction}>clear</Button> 
                                       </div>
                                    </Grid>
                                    </Grid>
                                    <Grid item xs={12}>   
                                 <Card className='stdCard' style={{marginTop:'10px'}}>
                                 <CardHeader 
                                  avatar={
                                    <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                      <Info/>
                                    </Avatar>
                                    } 
                                  className={classe.commonMainTableHead} 
                                  title="Previous Added Slider Colos"
                                  />
                                  <CardContent>
                                  <Grid container spacing={2}>
                                   
                                   <Grid item xs={12}>
                                  <div className='scrollableDivToStandard'>
                                    <TableContainer 
                                      >
                                      <Table  aria-label="customized table">
                                        <TableHead className={classe.commonSecondaryTableHead}>
                                          <TableRow>
                                            <StyledTableCell className={classe.commonTableThStyle} style={{width: '20%'}}><span className='tableHead'>Score</span></StyledTableCell>
                                            <StyledTableCell className={classe.commonTableThStyle} style={{width: '35%'}}><span className='tableHead'>Pr. Color</span></StyledTableCell>
                                            <StyledTableCell className={classe.commonTableThStyle} style={{width: '35%'}}><span className='tableHead'>Se. Color</span></StyledTableCell>
                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}><span className='tableHead'>Edit</span></StyledTableCell>
                                          </TableRow>
                                        </TableHead>
                                        {previousColorTableRowData} 
                                      </Table>
                                      
                                    </TableContainer>    
                                  </div>
                                  </Grid>  
  
                              </Grid>
                            </CardContent>
                                  </Card>
                                  </Grid>
                                </Grid>


                                 </Grid>

                                 

                             </CardContent>
                            </Card>
                          </Grid>
  
                                <Grid item className='secondaryGrid'>
                                 <Card className='stdCard'>
                                 <CardHeader 
                                  avatar={
                                    <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                      <Info/>
                                    </Avatar>
                                    } 
                                  className={classe.commonMainTableHead} 
                                  title="Previous Added Colos Platters"
                                  />
                                  <CardContent>
                                  <Grid container spacing={2}>
                                   <Grid item xs={12}>
                                  <div className={classes.scrollableDivToStandard}>
                                    <TableContainer 
                                      >
                                      <Table  aria-label="customized table">
                                        <TableHead className={classe.commonSecondaryTableHead}>
                                          <TableRow>
                                            <StyledTableCell className={classe.commonTableThStyle} style={{width: '5%'}}>No</StyledTableCell>
                                            <StyledTableCell className={classe.commonTableThStyle} style={{width: '85%'}}>Platter Name</StyledTableCell>
                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Edit</StyledTableCell>
                                          </TableRow>
                                        </TableHead>
                                        {previousColorPlatterTableRowData} 
                                      </Table>
                                      
                                    </TableContainer>    
                                  </div>
                                  </Grid>  
  
                              </Grid>
                            </CardContent>
                                  </Card>
                                </Grid> 
                          </Grid>
  
  
  
                        </>
                        
                  </Grid>
                  
              </Grid>  
          </div>
      </>
    )
  
}

export default AddingColorToSliderPage

