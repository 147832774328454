import React, { useEffect, useState } from 'react'
import { FormGroup, Grid, makeStyles } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { CONFIG} from '../config'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Draggable from 'react-draggable'; 
import { ClearAll, ClearAllOutlined, Close, SendOutlined } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import userEmailManagementPageStyle from '../Css/UserEmailManagementPageCss'
import RightBarShopItems from '../Components/RightBarShopItems';
import NativeSelect from '@mui/material/NativeSelect';
import { ClimbingBoxLoader, ClockLoader } from 'react-spinners';
import RightBarUserQuestions from '../Components/RightBarUserQuestions';



function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));  


const UserQuestionManagementPage = () => {

  const classes = userEmailManagementPageStyle();

  const location = useLocation();
  const userEmailId = location.pathname.split("/")[2];

  const [loading, setLoading] = React.useState(false);


  useEffect(() => {
    getData();
    setLoading(true);
    setTimeout(()=> {
      setLoading(false);
    }, 4000);    
  }, [])

  function getData () {
    getEmailData();
  }

  /** Get All Available Collection */
  const [allQuestionForSelectedUser, setAllQuestionForSelectedUser] = React.useState([]);
  const [selectedEmailData, setSelectedEmailData] = React.useState();
  const [fromName, setFromName] = React.useState('');
  const [toName, setToName] = React.useState('');
  const [collectionTitle, setCollectionTitle] = React.useState('');
  const [collectionId, setCollectionId] = React.useState('');
  const [centreName, setCentreName] = React.useState('');
  const [alreadySubmitted, setAlreadySubmitted] = React.useState(false);
  const [firstTimeSubmitted, setFirstTimeSubmitted] = React.useState(false);

  function getEmailData(){
    setAllQuestionForSelectedUser([]);
    axios(CONFIG.MAIN_URL+'/user-email-data/filter?id='+userEmailId,{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get Email Data");
      //btoa("ath")
      console.log((response.data[0]));
      console.log((response.data[0].transactionContentValue.questionList));
      var selectedEmailData = response.data[0];
      var collectionUniqueName = response.data[0].collectionUniqueName;
      var fromName = response.data[0].createdBy.userName;
      var toName = response.data[0].receivedBy.userName;
      var allQuestionForSelectedUser = response.data[0].transactionContentValue.questionList;

      //alert(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME)

      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
        method: 'get',
      })
      .then(function (res) {
        console.log("Return Collection Data from collectionUniqueName");
        console.log(res.data);
        var collectionTitle = res.data[0].title;
        var collectionId = res.data[0].id;
        var centreId = res.data[0].centreId

        var data = '{getCentreData(centreId:\"' + centreId + '\"){centreId,centreName}}';
        axios(CONFIG.MAIN_URL+'/graphql',{
          method: 'post',
          headers: { 
              'Content-Type': 'text/plain',
            },
          data : data,  
          })
          .then(function (resp) {
              var centreData = resp.data.data.getCentreData;
              console.log("+++++++++++++++++centreData++++++++++++++++++");
              console.log(centreData);
              axios(CONFIG.MAIN_URL+'/user-question-response/filter?userEmailDataId='+userEmailId,{
                method: 'get',
              })
              .then(function (respo) {
                console.log("++++++++++++++++Get user-question-response Data+++++++++++++++");
                console.log((respo));
                if (respo.data.length === 0){
                  setAlreadySubmitted(false);
                }else{
                  setAlreadySubmitted(true);
                  setFirstTimeSubmitted(false);
                }
                console.log((respo.data[0]));
                setSelectedEmailData(selectedEmailData);
                setFromName(fromName);
                setToName(toName);
                setAllQuestionForSelectedUser(allQuestionForSelectedUser);
  
                setCollectionTitle(collectionTitle);
                setCollectionId(collectionId);
                setCentreName(centreData.centreName);
  




              })
              .catch(function (error) {
                console.log(error);
              });
                      













          })
          .catch(function (error) {
              console.log("Entity Loding error" + error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  function submitAnswerFuction(){
    var errorString = "";
    var canSubmit = true;
    var submitQuestionArray = [];
    allQuestionForSelectedUser.forEach((item,index)=>{
      if (item.answers.answerMethod.name === "freeTextType"){
        var ans = document.getElementById("txt"+ item.questionId).value;
        if (ans != ''){
          item.answers.answer = ans;
          submitQuestionArray.push(item);
        }else{
          canSubmit = false;
          errorString += "Question #" + (index +1) + "\n";
        }
      }else{
        if (item.answers.answerMethod.name === "radioButtonType"){
          var selectedRadio = false;
          item.answers.answerList.forEach((data,index1)=>{
            var ans = document.getElementById("radio"+ index1 + item.questionId).checked;
            if (ans){
              data.expectedAnswerName = "Yes";
              selectedRadio = true;
            }else{
              data.expectedAnswerName = "";
            }
          })
          if (!selectedRadio){
            canSubmit = false;
            errorString += "Question #" + (index +1) + "\n";
          }else{
            submitQuestionArray.push(item);
          }
        }else{
          if (item.answers.answerMethod.name === "checkBoxType"){
            var selectedCheck = false;
            item.answers.answerList.forEach((data,index1)=>{
              var ans = document.getElementById("chk"+ index1 + item.questionId).checked;
              if (ans){
                data.expectedAnswerName = "checked";
                selectedCheck = true;
              }else{
                data.expectedAnswerName = "";
              }
            })
            if (!selectedCheck){
              canSubmit = false;
              errorString += "Question #" + (index +1) + "\n";
            }else{
              submitQuestionArray.push(item);
            }
          }else{
            if (item.answers.answerMethod.name === "orderNumberType"){
              var selectedSelect = false;
              item.answers.answerList.forEach((data,index1)=>{
                var ans = document.getElementById("select"+ index1 + item.questionId).value;
                if (ans != ''){
                  data.expectedAnswerName = ans;
                  selectedSelect = true;
                }else{
                  data.expectedAnswerName = "";
                }
              })
              if (!selectedSelect){
                canSubmit = false;
                errorString += "Question #" + (index +1) + "\n";
              }else{
                submitQuestionArray.push(item);
              }
            }
          }
        }
      }
    })
    if (!canSubmit) {
      alert("You have not answered the following question \n\n" + errorString + '\nPlease answer all questions and submit again.')
    }else{
      if (alreadySubmitted) {
        alert("Sorry!, You have already submitted the Answers and You cannot submit this again.")
      }else{
        var objMain = {};
        var objSub1 = {};
        objSub1["userEmailDataId"] = selectedEmailData.id;
        objSub1["collectionUniqueName"] = selectedEmailData.collectionUniqueName;
        objSub1["composeId"] = selectedEmailData.composeId;
        objSub1["collectionId"] = selectedEmailData.collectionId;
        objSub1["createdBy"] = selectedEmailData.receivedBy;
        objSub1["status"] = "Received";
        objMain["userQuestionsResponse"] = objSub1;
        objMain["questionList"] = submitQuestionArray;

        var sendJson = JSON.stringify(objMain);
        console.log(objMain);
        console.log(sendJson);
        
        console.log(CONFIG.MAIN_URL+'/user-question-response/create');
          
        axios(CONFIG.MAIN_URL+'/user-question-response/create',{
            method: 'post',
            headers: { 
              'Content-Type': 'application/json',    
            },  
            data:sendJson
        })
        .then(res =>  {
            console.log("++++++++++++++Return Updated from Srevre after submit user answers ++++++++++++++++++++");
            console.log(res.data);
            alert('Successfully Submited Your Answers' );
            setAlreadySubmitted(true);
            setFirstTimeSubmitted(true);
        })
        .catch(function (error) {
            console.log(error);
            alert(error );
        });
      }
      //console.log(submitQuestionArray);
    }
  }

  function clearAll(){
    window.location.reload();

    /*
    allQuestionForSelectedUser.forEach((item,index)=>{
      if (item.answers.answerMethod.name === "freeTextType"){
        var ans = document.getElementById("txt"+ item.questionId).value;
        if (ans != ''){
          document.getElementById("txt"+ item.questionId).value = null;
        }
      }else{
        if (item.answers.answerMethod.name === "radioButtonType"){
          var selectedRadio = false;
          item.answers.answerList.forEach((data,index1)=>{
            var ans = document.getElementById("radio"+ index1 + item.questionId).checked;
            if (ans){
              document.getElementById("radio"+ index1 + item.questionId).checked = false;
            }
          })
        }else{
          if (item.answers.answerMethod.name === "checkBoxType"){
            var selectedCheck = false;
            item.answers.answerList.forEach((data,index1)=>{
              var ans = document.getElementById("chk"+ index1 + item.questionId).checked;
              if (ans){
                document.getElementById("chk"+ index1 + item.questionId).checked = false;
              }
            })
          }else{
            if (item.answers.answerMethod.name === "orderNumberType"){
              var selectedSelect = false;
              item.answers.answerList.forEach((data,index1)=>{
                var ans = document.getElementById("select"+ index1 + item.questionId).value;
                if (ans != ''){
                  document.getElementById("select"+ index1 + item.questionId).value = ''
                }
              })
            }
          }
        }
      }
    })
    */

    
  }

  const [PageName, setPageName] = useState('Questionnaire Collection')
  return (
    <>
        {loading ?
          <ClockLoader
          size={120}
          color={'red'}
          className={classes.loaderStyle}
          loading={loading}

          />

        :
        
        <>
        <RightBarUserQuestions PageName={collectionTitle} fromName={fromName} toName={toName} centreName={centreName}/>
        <div className={classes.pageTopUserQuestion} >
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.pageBodyStyle}>
                    {alreadySubmitted && !firstTimeSubmitted?
                      <Typography variant="h6" style={{textAlign:'center', marginTop:'10px',color:'red'}}>You have already submitted the Answers and You cannot submit this again</Typography>                    
                    :
                    ''
                    }
                    <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 695 }} aria-label="customized table">
                                <TableBody>
                                {
                                    allQuestionForSelectedUser.map((quesmainn,index) => {
                                        return (
                                        <>
                                            <StyledTableRow key={1}>
                                                <StyledTableCell colSpan={3} component="th" scope="row" className={classes.tableAnsConfirmTrStyleEmpty}>
                                                    &nbsp;
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key={2}>
                                                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle} style={{width:'5%'}}>
                                                    {(index+1) + ')'}
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={2} component="th" scope="row" className={classes.tableAnsConfirmTrStyle}>
                                                    {quesmainn.question} 
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            {quesmainn.answers.answerMethod.name === "freeTextType"?
                                                <StyledTableRow key={index} >
                                                    <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                                                        &nbsp;
                                                    </StyledTableCell>
                                                    <StyledTableCell colSpan={2} component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                                                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} autoComplete="off">
                                                            <div>
                                                                <TextField
                                                                    id={"txt"+ quesmainn.questionId} 
                                                                    label="Type Your Answer"
                                                                    multiline
                                                                    maxRows={3}
                                                                />
                                                            </div>
                                                        </Box> 
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            :
                                              <>
                                              {quesmainn.answers.answerMethod.name === "radioButtonType" ?
                                                <StyledTableRow>
                                                  <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                                                      &nbsp;
                                                  </StyledTableCell>
                                                  <StyledTableCell colSpan={2} component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                                                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                                    {quesmainn.answers.answerList.map((row,index1) => (
                                                        <FormControlLabel  control={<Radio value={"radio"+ index1 + quesmainn.questionId} name={"radio" + index1 + index} id={"radio"+ index1 + quesmainn.questionId}/>} label={<Typography sx={{ fontSize: '1.1rem !important' }}>{row.answerName}</Typography>} className={classes.formControlLabelStyle}/>                          
                                                    ))}
                                                    </RadioGroup>
                                                  </StyledTableCell>
                                                </StyledTableRow>
                                              :
                                              <>
                                              {quesmainn.answers.answerMethod.name === "checkBoxType" ?
                                                <StyledTableRow >
                                                    <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                                                        &nbsp;
                                                    </StyledTableCell>
                                                    <StyledTableCell copSpan={2} component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                                                    <FormGroup>
                                                    {quesmainn.answers.answerList.map((row,index1) => (
                                                        <FormControlLabel 
                                                            control={<Checkbox id={"chk"+ index1 + quesmainn.questionId}/>} label={<Typography sx={{ fontSize: '1.1rem !important' }}>{row.answerName}</Typography>} className={classes.formControlLabelStyle}/>
                                                    ))} 
                                                    </FormGroup>       
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                              :
                                               '' 
                                              }
                                              </>
                                            }
                                            {quesmainn.answers.answerMethod.name === "orderNumberType" && quesmainn.answers.answerList.map((row,index1) => (
                                              <StyledTableRow key={index1} >
                                                  <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                                                      &nbsp;
                                                  </StyledTableCell>
                                                  <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'40%' }}>
                                                      {row.answerName}              
                                                  </StyledTableCell>
                                                  <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                                                      <FormControl sx={{ m: 0.5, minWidth: 100 }} size="small">
                                                          <InputLabel id="select-select-label">Select</InputLabel>
                                                          <NativeSelect
                                                            id={"select"+ index1 + quesmainn.questionId}
                                                            >
                                                            <option value={''}></option>
                                                            <option value={1}>1</option>
                                                            <option value={2}>2</option>
                                                            <option value={3}>3</option>
                                                            <option value={4}>4</option>
                                                            <option value={5}>5</option>
                                                          </NativeSelect>
                                                          
                                                      </FormControl>
                                                  </StyledTableCell>
                                              </StyledTableRow>
                                            ))}
                                            </>
  
                                        }








                                    
                                    </>
                                  )
                                })
                              } 
                              <StyledTableRow >
                                  <StyledTableCell colSpan={3} component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '10px !important', paddingBottom: '10px !important', width:'100%', height: '15px' }}>
                                      &nbsp;
                                  </StyledTableCell>
                              </StyledTableRow>  
                              <StyledTableRow >
                                  <StyledTableCell colSpan={3} component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '10px !important', paddingBottom: '10px !important', textAlign:'right' }}>
                                    <Button 
                                      variant="contained"
                                      id="riskEvaluateBtn" 
                                      endIcon={<SendOutlined />}
                                      onClick={()=>submitAnswerFuction()}
                                      >
                                      Submit
                                    </Button>&nbsp;&nbsp;&nbsp;
                                    <Button 
                                        variant="contained"
                                        id="riskEvaluateBtn" 
                                        endIcon={<ClearAllOutlined />}
                                        onClick={()=>clearAll()}
                                        >
                                        Clear
                                    </Button>
                                  </StyledTableCell>
                                </StyledTableRow>  
                                <StyledTableRow >
                                  <StyledTableCell colSpan={3} component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '10px !important', paddingBottom: '10px !important', height: '20px' }}>
                                      &nbsp;
                                  </StyledTableCell>
                                </StyledTableRow>  

                              </TableBody>
                              
                          </Table>
                      </TableContainer>
                  </Grid>
                  <Grid item xs={12}>
                  
                  </Grid>
  
                </Grid>
            </Grid>  
        </div>

        </>

      }


    </>

  )
}

export default UserQuestionManagementPage