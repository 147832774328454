import React, { useEffect, useState, useRef } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import axios from "axios";
import CardContent from "@mui/material/CardContent";
import { CONFIG } from "../config";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { Info, KeyboardReturnOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import "../Css/Slider.css";
import Draggable from "react-draggable";
import useStyle from "../Css/MainCss";
import Delete from "@mui/icons-material/Delete";
import { Editor } from "@tinymce/tinymce-react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { Alert } from "bootstrap";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddSubContentComponent from "./AddSubContentComponent";
import { Select, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MainArticleComponent from "./MainArticleComponent";
import SubArticleComponent from "./SubArticleComponent";
import ArticlePagesComponent from "./ArticlePagesComponent";
import parseHtml from 'html-react-parser';


const useStyles = makeStyles((theme) => ({
  cardRiskManage: {
    width: "100%",
    border: "1px solid black",
  },
  riskImageSize: {
    width: "120px",
    height: "100px !important",
  },
  tableStyle: {
    // width: '55% !important',
    //minWidth: '720px !important'
  },
  root: {
    position: "relative",
  },
  thStyleImage: {
    lineHeight: "0.5rem !important",
    borderBottom: "0px !important",
    width: "130px !important",
  },
  posRelativeAndDivBorder: {
    position: "relative",
    border: "1px solid #4C7EE0",
    width: "120px",
    height: "100px !important",
  },
  thStyle: {
    fontSize: "12px !important",
    lineHeight: "0.5rem !important",
    borderBottom: "0px !important",
  },
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  cardRiskManage: {
    width: "100%",
    border: "1px solid black",
  },
  tableTrStyle: {
    height: "19px !important",
  },
  mousePointer: {
    cursor: "pointer",
  },
  stdMainCardHeader: {
    backgroundColor: "#4C7EE0 !important",
    fontSize: "13px !important",
    height: "50px",
    padding: "0px",
    marginTop: "-7px",
    color: "white",
  },
  addrisktypebtn: {
    marginLeft: "0px !important",
    border: "1px solid #4C7EE0 !important",
    color: "#4C7EE0 !important",
    padding: "5px 10px !important",
    height: "40px !important",
  },
  btnBorderpurple: {
    border: "1px solid #9155FD !important",
    color: "#9155FD !important",
    padding: "5px 10px !important",
    height: "40px !important",
  },
  stdCard: {
    height: "100%",
    border: "1px solid black",
  },
  scrollableDivToStandard: {
    height: "350px",
    overflow: "auto",
    width: "100%",
  },
  btnColorPurple: {
    fontSize: "12px !important",
    backgroundColor: "#BACDF2 !important",
    height: "40px",
    textAlign: "center",
    color: "#4C7EE0 !important",
    border: "1px solid #4C7EE0",
  },
  btnBorderGreen: {
    border: "1px solid #4C7EE0 !important",
    color: "#4C7EE0 !important",
    height: "40px",
  },
  superAdmintabBtnDBClickedBlog: {
    width: "153px !important",
    color: "#F5F5F5 !important",
    backgroundColor: "#0D4CC8 !important",
    border: "1px solid #69676e !important",
    height: "50px",
    textDecoration: "none",
  },
}));

const BlogComponent = ({tokens}) => {
  const classe = useStyle();
  const classes = useStyles();
  const Input = styled("input")({
    display: "none",
  });

  const editorRef = useRef(null);
  const [openButton1, setOpenButton1] = React.useState(true);
  const [openButton2, setOpenButton2] = React.useState(false);
  const [openButton3, setOpenButton3] = React.useState(false);
  const [openButton4, setOpenButton4] = React.useState(false);
  const [selectedDomainName, setSelectedDomainName] = useState("");
  const [allLoginPages, setAllLoginPages] = useState([]);
  const [editBtnName, setEditBtnName] = useState("Edit");
  const [showSaveUpdateBtn, setShowSaveUpdateBtn] = useState("none");
  const [addUpdateButton, setAddUpdateButton] = useState("SAVE");
  const [showAddNewBtn, setShowAddNewBtn] = useState("none");
  const [showEditButton, setShowEditButton] = useState(true);
  const [showEditBtn, setShowEditBtn] = useState("none");
  const [showDeleteBtn, setShowDeleteBtn] = useState("none");
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = React.useState();
  const [imgLocation, setImgLocation] = useState("");
  const [logInPageDesign, setLogInPageDesign] = useState("");
  const [logInPageDesignId, setLogInPageDesignId] = useState("");
  const [tinyMCETemplateList, setTinyMCETemplateList] = React.useState();

  React.useEffect(() => {
    getBlogData();
    //getData();
    //getTemplates();

    if (!selectedFile) {
      //getHtmlData();
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  function clearImageFuction() {
    setPreview();
    setSelectedFile(null);
    setImgLocation("");
  }

  function compareDomain(a, b) {
    var nameA = a.domainName.toUpperCase(); // ignore upper and lowercase
    var nameB = b.domainName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  function getBlogData() {
    axios(CONFIG.MAIN_URL + `/loginPageContent/all`, {
      method: "get",
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then(function (response) {
        console.log("++++++++++++++++All loginPage Returns+++++++++++++");
        console.log(response.data);
        var allData = response.data;
        allData = allData.sort(compareDomain);
        setAllLoginPages(allData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function setOpenButton1Function(flag) {
    setOpenButton1(true);
    setOpenButton2(false);
    setOpenButton3(false);
    setOpenButton4(false);
  }
  function setOpenButton2Function(flag) {
    setOpenButton2(true);
    setOpenButton1(false);
    setOpenButton3(false);
    setOpenButton4(false);
  }

  function setOpenButton3Function(flag) {
    setOpenButton2(false);
    setOpenButton1(false);
    setOpenButton3(true);
    setOpenButton4(false);
  }

  function setOpenButton4Function(flag) {
    setOpenButton2(false);
    setOpenButton1(false);
    setOpenButton3(false);
    setOpenButton4(true);
  }

  const domainSelecter = (event, newValue) => {
    // clearFuctionDomains();
    // if (newValue != null) {
    //     setSelectedDomainName(newValue.label);
    //     setSelectedDomainId(newValue.id)
    //     setSelectedDomainVersion(newValue.versionNumber)
    //     setEditBtnName('Edit ' + newValue.versionNumber + ' version')
    // }
    // setShowEditBtn('block');
    // setShowDeleteBtn('block');
    // setShowAddNewBtn('none');
  };
  const domainSelecter1 = (event) => {
    // clearFuctionDomains();
    // setSelectedDomainName(event.target.value);
    // var found = false;
    // allLoginPages.map((data) => {
    //     if (data.domainName === event.target.value) {
    //         found = true;
    //         setEditBtnName('Edit ' + data.versionNumber + ' version')
    //         setShowEditBtn('block');
    //         setShowDeleteBtn('block');
    //         setShowAddNewBtn('none');
    //     }
    // })
    // if (!found) {
    //     setSelectedDomainId('')
    //     setEditBtnName('')
    //     setShowEditBtn('none');
    //     setShowDeleteBtn('none');
    //     if (event.target.value != '') {
    //         setShowAddNewBtn('block');
    //     } else {
    //         setShowAddNewBtn('none');
    //     }
    // }
  };

  const [cretedUserName, setCretedUserName] = React.useState("");
  const onTextFieldChangeCretedUserName = (event) => {
    setCretedUserName(event.target.value);
  };

  //main article

  return (
    <Card className={classes.cardRiskManage}>
      <CardContent>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            id="btnOpenRiskCollection"
            variant="outlined"
            size="medium"
            className={
              openButton1
                ? classes.superAdmintabBtnDBClickedBlog
                : classe.tabBtnDB
            }
            onClick={() => setOpenButton1Function(true)}
          >
            main article
          </Button>
          <Button
            id="btnOpenRiskCollection"
            variant="outlined"
            size="medium"
            className={
              openButton2
                ? classes.superAdmintabBtnDBClickedBlog
                : classe.tabBtnDB
            }
            onClick={() => setOpenButton2Function(true)}
          >
            sub articles
          </Button>

          <Button
            id="btnOpenRiskCollection"
            variant="outlined"
            size="medium"
            className={
              openButton3
                ? classes.superAdmintabBtnDBClickedBlog
                : classe.tabBtnDB
            }
            onClick={() => setOpenButton3Function(true)}
          >
            Article Pages
          </Button>

        </div>
        <br></br>

        {openButton1 ? 
        <>
        <MainArticleComponent
          tokens={tokens}
        />
        </>
        :
        ""
        }
 
        {openButton2 ? 
        <>
        <SubArticleComponent
          tokens={tokens}
        />
        </>
        
        : ""}

        {openButton3 ? 
        <>
        <ArticlePagesComponent/>
        </>
        
        : ""}


      </CardContent>
    </Card>
  );
};

export default BlogComponent;
