import React, {useEffect,useState} from 'react'
import { styled, useTheme } from '@mui/material/styles';
import { Tooltip } from "@material-ui/core";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {CONFIG} from '../config';
import axios from 'axios';
import classNames from 'classnames';
import useStyle from '../Css/MainCss';
import {dispatchLogout} from "../actions/sign-out";
import { Link, useLocation } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import '../Css/RightBarDataForOtherPages.css'
import SavePageAs from './SavePageAs'



const RightBarDataForOtherPages = ({PageName}) => {

  const [drawerWidth, setDrawerWidth] = React.useState(240);

  const [drawerWidthText, setDrawerWidthText] = React.useState(12);


  useEffect (() => {
    console.log("++++++++++++window. screen. width+++++++++++++");
    if (window. screen. width < 480){
        setDrawerWidth(180);
        setDrawerWidthText(9)
    }else{
      if (window. screen. width < 810 && window. screen. width > 481){
        setDrawerWidth(210);
        setDrawerWidthText(10)
      }
    }

    
    console.log(window. screen. width);
  },[])


  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
    }),
  );
  
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  function compareMenu( a, b ) {
    var nameA = a.no; // ignore upper and lowercase
    var nameB = b.no; // ignore upper and lowercase
  
    if ( nameA < nameB ){
        return -1;
    }
    if ( nameA > nameB ){
        return 1;
    }
    // names must be equal
    return 0;
  }

    const classes = useStyle();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

      const [menuItem, setMenuItem] = useState([]);

      const [userName, setUserName] = useState()
      const [userId, setUserId] = useState()

      useEffect(() => {
        
        getData()
        
        }, [])



  
    function getData () {
      let data = sessionStorage.getItem('sessionstorage');
      data = JSON.parse(data);
      console.log("++++++++++++++++++++++++++++++++++data++++++++++++++++++++++++++++++");
      console.log(data);
      getHeaderData(data.centreId);
      getSideBarData(data.userRoleId,data.centreId, data.userId)
      getAllCollectionToTransfer(data.centreId);
      getCentreData(data.centreId);
      setUserName(data.name)
      setUserId(data.userId)
      console.log(data.name)
  } 

  const [headerLogoUrl, setHeaderLogoUrl] = useState('');
  const [headerSystemName, setHeaderSystemName] = useState('');
  const [headerBackGroundColor, setHeaderBackGroundColor] = useState('white');
  function getHeaderData(centreId){
    axios(CONFIG.EXPERT_SERVER_URL + `/pageHeaderContent/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
    .then(function (response) {
      console.log("++++++++++++++++++++++++Header Details+++++++++++++++++++");
      console.log(response.data);
      response.data.forEach((item,index) => {
        if (item.centreId === centreId) {
          if (item.logoUrl != undefined && item.logoUrl != null) {
            setHeaderLogoUrl(item.logoUrl);
          }  
          if (item.systemName != undefined && item.systemName != null) {
            setHeaderSystemName(item.systemName);
          }  
          if (item.backgroundColor != undefined && item.backgroundColor != null) {
            setHeaderBackGroundColor(item.backgroundColor);
          }  
        }
      })
    })
    .catch(function (error) {
      console.log(error);
    });
  }


  const [userCentreName, setUserCentreName] = React.useState('Data Management Group');
      function getCentreData(cId){
        var data = '{getCentreData(centreId:"'+cId+'"){centreName}}';
        axios(CONFIG.MAIN_URL+`/graphql`,{
          method: 'post',
          headers: { 
            'Content-Type': 'text/plain', 
          },
          data:data
        })
        .then(function (response) {
          console.log("++++++++++++++++++1111111response11111111+++++++++++++++++++");
          console.log(response);
          //console.log(response.data.data.getCentreData.centreName);
          if (response.data.data.getCentreData.centreName != undefined && response.data.data.getCentreData.centreName != null){
            setUserCentreName(response.data.data.getCentreData.centreName);
          }
          

        })
        .catch(function (error) {
          console.log("++++++++++++++++++Error+++++++++++++++++++");
          console.log(error);
        });
      }

      function compareEndDate(a, b) {
        var nameA = a.endDate; // ignore upper and lowercase
        var nameB = b.endDate; // ignore upper and lowercase
    
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      }
    
      
      function getSideBarData(userRoleId,centreId,userId){
        if(userRoleId === 'staff'){
          userRoleId = 'ADMIN'
            console.log(userRoleId)     
        }
    
        if(userRoleId != 'superadmin'){
          axios(CONFIG.MAIN_URL + `/mainMenuSetup/filter?centreId=` + centreId, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
          })
          .then(function (response) {
            var mainMenuObj = {};
            var mainMenuFound = false;
            response.data.forEach((itemA, indexA) => {
                if (indexA === 0) {
                    mainMenuFound = true;
                    mainMenuObj = itemA;
                }
            })
            if (mainMenuFound){
              mainMenuObj.entityMenus.forEach((itemX) => {
                if (userRoleId === itemX.iamRole) {
                  var arr = itemX.menus;
                  if (userRoleId != 'ADMIN') {
                    axios(CONFIG.MAIN_URL + `/trialCentreSetup/filter?centreId=` + centreId, {
                      method: 'get',
                    })
                      .then(function (response) {
                        console.log("++++Trial Centre setup Details +++++++++++++++++");
                        console.log(response.data);
                        var trialMenuArr = [];
                        var trialDays = 0;
          
                        response.data.forEach((item, index) => {
                          if (index === 0) {
                            trialDays = item.noOfTrialDays
                            if (item.menusToDisplay != undefined && item.menusToDisplay != null && item.menusToDisplay.length > 0) {
                              item.menusToDisplay.forEach((item1) => {
                                var obj = {};
                                obj["htmlMenuId"] = item1.htmlMenuId;
                                trialMenuArr.push(obj);
                              })
                            }
                          }
                        })
          
                        if (trialDays > 0) {
                          axios(CONFIG.MAIN_URL + `/trialUsers/filter?userCode=` + userId, {
                            method: 'get',
                          })
                            .then(function (response) {
                              console.log("++++Trial User setup Details +++++++++++++++++");
                              console.log(response.data);
                              var canHaveMenu = false;
                              response.data.forEach((item, index) => {
                                if (index === 0) {
                                  console.log(item);
                                  if (item.trialDays != undefined && item.trialDays != null && item.trialDays.length > 0) {
                                    var trilDayArr = item.trialDays;
                                    trilDayArr = trilDayArr.sort(compareEndDate);
                                    console.log("++++XXXXXXXXXXXX trilDayArr XXXXXXXXXXXXXXXXXXXXXXXXXXXXX +++++++++++++++++");
                                    console.log(trilDayArr);
                                    var tObject = {}
                                    trilDayArr.forEach((itemDay) => {
                                      tObject = itemDay;
                                    });
                                    if (tObject.endDate != undefined && tObject.endDate != null) {
                                      var endDate = new Date(tObject.endDate);
                                      var currentDate = new Date();
                                      var DifferenceInTime = endDate.getTime() - currentDate.getTime();
                                      var DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
                                      if (DifferenceInDays > 0) {
                                        canHaveMenu = true;
                                      }
                                    }
                                  }
                                }
                              })
                              if (canHaveMenu) {
                                console.log(trialMenuArr);
                                
                                var selectedMenuArr = [];
                                arr.forEach((item) => {
                                  trialMenuArr.forEach((data) => {
                                    if (data.htmlMenuId === item.menuId) {
                                      selectedMenuArr.push(item);
                                    }
                                  })
                                })
                                setMenuItem(selectedMenuArr)
                              }
                            })
                            .catch(function (error) {
                              console.log(error);
                            });
          
                        }
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  } else {
                    setMenuItem(arr)
                  }
                }
              })
            }
      
      
          })
          .catch(function (error) {
            console.log("++++++++++++++++++Error+++++++++++++++++++");
            console.log(error);
          });

        }
    
    
      }





  function getSideBarData1(rId,cId){
    if(rId === 'staff'){
        rId = 'ADMIN'
        console.log(rId)     
    }
   
    if(rId != 'superadmin'){
      var data = '{allMenuRoleList(centreId:"'+cId+'",iamRole:"'+rId+'"){menuRoleId,centerMenuName,centerMenuOrderNum,centreId,createdDate,updatedDate,isActive,menu{menuUri,menuHtmlId},subMenuList{subMenuRoleId,centerMenuName,centerMenuOrderNum,centreId,createdDate,updatedDate,isActive,menu{menuUri,menuHtmlId}}}}';
        axios(CONFIG.MAIN_URL+`/graphql`,{
          method: 'post',
          headers: { 
              'Content-Type': 'text/plain', 
            },
            data:data
            
          })      
          .then(function (response) {
            console.log(response.data.data.allMenuRoleList);
            //setMenuItem(response.data.data.allMenuRoleList)
            var arr = response.data.data.allMenuRoleList;

            arr.forEach((data)=>{
              var arr1 = data.subMenuList;
              var count = 11;
              arr1.forEach((item)=>{
                if (item.menu.menuUri === "riskCollection"){
                  item["no"] = 1;
                }else{
                  if (item.menu.menuUri === "newcollection"){
                    item["no"] = 2;
                  }else{
                    if (item.menu.menuUri === "globalStandard"){
                      item["no"] = 3;
                    }else{
                      if (item.menu.menuUri === "customStandard"){
                        item["no"] = 4;
                      }else{
                        if (item.menu.menuUri === "createRisk"){
                          item["no"] = 5;
                        }else{
                          if (item.menu.menuUri === "centreMaintenance"){
                            item["no"] = 6;
                          }else{
                            if (item.menu.menuUri === "sliderMethods"){
                              item["no"] = 7;
                            }else{
                              if (item.menu.menuUri === "addColor"){
                                item["no"] = 8;
                              }else{
                                if (item.menu.menuUri === "transferCollection"){
                                  item["no"] = 9;
                                }else{
                                  if (item.menu.menuUri === "createQuestion"){
                                    item["no"] = 10;
                                  }else{
                                    item["no"] = count;
                                    count ++;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              })
              arr1 = arr1.sort (compareMenu);
              data["subMenuList"] = arr1;
            })
            
            console.log("+++++++++++++++++++++++++++++++++Menu arr+++++++++++++++++++++++");
            console.log(arr);
            if (rId != 'ADMIN'){
              axios(CONFIG.MAIN_URL + `/trialCentreSetup/filter?centreId=` + cId, {
                method: 'get',
              })
              .then(function (response) {
                console.log("++++Trial Centre setup Details +++++++++++++++++");
                console.log(response.data);
                var trialMenuArr = [];
                response.data.forEach((item,index) => {
                  if (index === 0) {
                    if (item.menusToDisplay != undefined && item.menusToDisplay != null && item.menusToDisplay.length> 0){
                      item.menusToDisplay.forEach((item1) => {
                        var obj = {};
                        obj["htmlMenuId"] = item1.htmlMenuId;
                        trialMenuArr.push(obj);
                      })  
                    }
                  }
                })
                console.log(trialMenuArr);
                arr.forEach((item) => {
                  var subArr=[];
                  if (item.subMenuList != undefined && item.subMenuList != null && item.subMenuList.length> 0){
                    var tmmSubMenuArr = [];
                    item.subMenuList.forEach((item1) => {
                      trialMenuArr.forEach((data) => {
                        if (data.htmlMenuId === item1.menu.menuHtmlId){
                          tmmSubMenuArr.push(item1);
                        }
                      })
                    })
                    item["subMenuList"] = tmmSubMenuArr 
                  }
                })
                setMenuItem(arr)
              })
              .catch(function (error) {
                console.log(error);
              });
            }else{
              setMenuItem(arr)
            }

            //setMenuItem(arr)

     
      
          })
          .catch(function (error) {
          console.log(error);
          });
       
      }
    }

    const [preparingCount, setPreparingCount] = React.useState(0);
    const [pendingCount, setPendingCount] = React.useState(0);

    function getAllCollectionToTransfer(centreId){
      axios(CONFIG.MAIN_URL+'/transactiondata/all',{
        method: 'get',
      })
      .then(function (response) {
        console.log("Get All Transaction Collection");
        console.log((response.data));
        var arr = response.data;
        var tmpPreparingCount = 0;
        var tmpPendingCount = 0;
        arr.forEach((item)=>{
          if (item.expertCentreId === centreId) {
            if (item.transferStatus === "Preparing"){
              tmpPreparingCount ++;
            }
            if (item.transferStatus === "Pending"){
              tmpPendingCount ++;
            }
          }  
        })
        setPreparingCount(tmpPreparingCount);
        setPendingCount(tmpPendingCount);
      })
      .catch(function (error) {
        console.log(error);
      });
    }





      function handleLogoutBtnClick() {
        dispatchLogout();
      };
      function handleLogoutBtnlCickAbout(){
        let a= document.createElement('a');
        a.target= '_blank';
        a.href= '/aboutus';
        a.click();
      };
      function handleLogoutBtnlCickServices(){
        let a= document.createElement('a');
        a.target= '_blank';
        a.href= '/ourservices';
        a.click();
      }  
      function handleOtherServiceClick(url){
        let multiOpen = sessionStorage.getItem('sessionstoragemultiopen');
        if (multiOpen != null && multiOpen === 'YES'){
          let text = "Exit multitasking menu?";
          if (window.confirm(text) == true) {
            sessionStorage.setItem('sessionstoragemultiopen', 'NO');  
            let a= document.createElement('a');
            a.target= '_self';
            a.href= url;
            a.click();
          }

        }else{
          let a= document.createElement('a');
          a.target= '_self';
          a.href= url;
          a.click();
        }
        
      }



  return (
    <div>
        
        <Box sx={{ display: 'flex'}}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{backgroundColor:`${headerBackGroundColor}`, height:'100px' }}>
        <Toolbar style={{display:'flex',justifyContent:'space-between', color:'black'}}>
        <div>
              {headerLogoUrl != '' && headerSystemName != ''?
                <>
                <img src={headerLogoUrl} style={{width: '100px', height: '70px'}} /><br></br>
                <span className='riskManagementtest1'>
                  {headerSystemName}
                </span>  
                </>
              :
               headerLogoUrl != ''?
                <>
                  <img src={headerLogoUrl} style={{width: '100px', height: '80px'}} /><br></br>
                </>
               :
               headerSystemName != ''?
                <span className='riskManagementtest'>
                  {headerSystemName}
                </span>
               : 
                <span className='riskManagementtest'>
                  Risk Management System
                </span>
              }
            </div>         
          <div style={{textAlign:'center',marginTop:'10px'}}>
            <span className='dataManagementGroupText'  >
              {userCentreName}
            </span>
            <br></br>
            <span className='pagenameText'   style={{fontFamily:'cursive'}} >
              {PageName}
            </span>
            <br></br>
            <SavePageAs/>
            
            {/*PageName.length > 30?
              <Typography variant="h6"  component="div" style={{fontSize: '11px', textAlign: 'right', marginTop: '-17px', marginLeft: '579px'}} >
                <span onClick={handleLogoutBtnlCickAbout} style={{cursor:'pointer'}}>About Us</span> | <span onClick={handleLogoutBtnlCickServices} style={{cursor:'pointer'}}>Our Services</span> | <span onClick={handleLogoutBtnClick} style={{cursor:'pointer'}}>Logout</span>
              </Typography>
             :
              PageName.length > 20 && PageName.length < 31?
                <Typography variant="h6"  component="div" style={{fontSize: '11px', textAlign: 'right', marginTop: '-17px', marginLeft: '500px'}} >
                  <span onClick={handleLogoutBtnlCickAbout} style={{cursor:'pointer'}}>About Us</span> | <span onClick={handleLogoutBtnlCickServices} style={{cursor:'pointer'}}>Our Services</span> | <span onClick={handleLogoutBtnClick} style={{cursor:'pointer'}}>Logout</span>
                </Typography>
              :
              PageName.length < 21 && PageName.length > 17?
              <Typography variant="h6"  component="div" style={{fontSize: '11px', textAlign: 'right', marginTop: '-17px', marginLeft: '422px'}} >
                <span onClick={handleLogoutBtnlCickAbout} style={{cursor:'pointer'}}>About Us</span> | <span onClick={handleLogoutBtnlCickServices} style={{cursor:'pointer'}}>Our Services</span> | <span onClick={handleLogoutBtnClick} style={{cursor:'pointer'}}>Logout</span>
              </Typography>
              :
              PageName.length < 18 && PageName.length > 14?
              <Typography variant="h6"  component="div" style={{fontSize: '11px', textAlign: 'right', marginTop: '-17px', marginLeft: '370px'}} >
                <span onClick={handleLogoutBtnlCickAbout} style={{cursor:'pointer'}}>About Us</span> | <span onClick={handleLogoutBtnlCickServices} style={{cursor:'pointer'}}>Our Services</span> | <span onClick={handleLogoutBtnClick} style={{cursor:'pointer'}}>Logout</span>
              </Typography>
              :
              <Typography variant="h6"  component="div" style={{fontSize: '11px', textAlign: 'right', marginTop: '-17px', marginLeft: '350px'}} >
                <span onClick={handleLogoutBtnlCickAbout} style={{cursor:'pointer'}}>About Us</span> | <span onClick={handleLogoutBtnlCickServices} style={{cursor:'pointer'}}>Our Services</span> | <span onClick={handleLogoutBtnClick} style={{cursor:'pointer'}}>Logout</span>
              </Typography>
              */} 
          </div>
          <div style={{textAlign:'center',marginTop:'10px'}}>
            <div>
              <span className='welcomeText' >
                Welcome {userName}
              </span>
              </div>
              <div className='menuIcon'  >
              <Tooltip title="Main Menu">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  sx={{ ...(open && { display: 'none' }) }}
                  className='menuIcon'
                  >
                  <MenuIcon className='menuIcon'/>
                </IconButton >
              </Tooltip>  
              </div>
              <div className='aboutusLines'>
                <span onClick={handleLogoutBtnlCickAbout} className='aboutusText' style={{cursor:'pointer'}}>About Us</span> | <span onClick={handleLogoutBtnlCickServices} className='aboutusText' style={{cursor:'pointer'}}>Our Services</span> | <span onClick={handleLogoutBtnClick} className='aboutusText' style={{cursor:'pointer'}}>Logout</span>
              </div>
            </div>




          
          
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        {menuItem.map((text, index) => (
            //text.subMenuList.map((text1, index) => (
            <ListItem key={text.menuName} disablePadding>
              {text.menuId === "transferCollection"?
              <ListItemButton onClick={()=>handleOtherServiceClick(`../${text.menuId}`)}>
                {preparingCount > 0 && pendingCount > 0 ?
                <>
                  <Button variant="contained" className={classes.rightBarBadgeBtnRed}>{'preparing-'+ preparingCount}</Button>
                  <Button variant="contained" className={classes.rightBarBadgeBtnGreenWithRed}>{'pending-'+ pendingCount}</Button>
                  <ListItemText><span style={{fontSize:drawerWidthText}} className='rightBarTextTableLeftBoth'>{text.menuName}</span></ListItemText>
                </>
                :
                <>
                {preparingCount > 0 ?
                 <>
                  <Button variant="contained" className={classes.rightBarBadgeBtnRed}>{'preparing-'+ preparingCount}</Button>
                  <ListItemText><span style={{fontSize:drawerWidthText}} className='rightBarTextTableLeft'>{text.menuName}</span></ListItemText>
                  </>
                :
                <>
                {pendingCount > 0 ?
                <>
                  <Button variant="contained" className={classes.rightBarBadgeBtnGreen}>{'pending-'+ pendingCount}</Button>
                  <ListItemText><span style={{fontSize:drawerWidthText}} className='rightBarTextTableLeft'>{text.menuName}</span></ListItemText>
                </>
                :
                <ListItemText><span style={{fontSize:drawerWidthText}}>{text.menuName}</span></ListItemText>
                }
                </>
                }
                  </>
                }
              </ListItemButton>
              :
              <ListItemButton onClick={()=>handleOtherServiceClick(`../${text.menuId}`)}>
                <ListItemText  ><span style={{fontSize:drawerWidthText}}>{text.menuName}</span></ListItemText>
              </ListItemButton>
              }
            </ListItem>
            //))
          ))}
            {/* 
            <ListItem  disablePadding >
              <ListItemButton onClick={handleLogoutBtnlCickAbout}>
                <ListItemText >
                    About Us
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem  disablePadding >
              <ListItemButton onClick={handleLogoutBtnlCickServices}>
                <ListItemText >
                  Our Services
                </ListItemText>
              </ListItemButton>
            </ListItem>

           <ListItem  disablePadding >
              <ListItemButton onClick={handleLogoutBtnClick}>
                <ListItemText >
                    Logout
                </ListItemText>
              </ListItemButton>
              
            </ListItem>
            */}

        </List>
        
      </Drawer>
    </Box>
    </div>
  )
}

export default RightBarDataForOtherPages