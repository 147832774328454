import React from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import '../Css/Slider.css'
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import RiskLevelSlider from './RiskLevelSlider';

const QuestionerPageOpenEnded = ({handleOpenEndedChange, openEndedvalue, openEndedScoreValue, handleOpenEndedScoreChange, openEndedAnswerValue, handleOpenEndedAnswerChange,
     questionSliderMethods,sliderType,handleChange,riskClearFunction,showSelectBar,sliderValue,setSliderValue,
     nameChange,sliderValueName,questionLableNameOpenEnded,setSliderValueStyle, sliderValueStyle,setRiskLvlEditFunction}) => {
    //console.log(questionSliderMethods)


  return (
    <>
    <Grid item xs={7}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
            >
            <div>
                <TextField
                    id="openEndedQuest"
                    label={questionLableNameOpenEnded}
                    multiline
                    maxRows={5}
                    value={openEndedvalue}
                    onChange={handleOpenEndedChange}
                />
            </div>
        </Box>
    </Grid>
    <Grid item xs={5}>
 
    <Box component="form" sx={{ '& .MuiTextField-root': {width: '100%' },}} validate autoComplete="off">
                                                                <div>
                                                                <RiskLevelSlider
                                                                    MethodDropdown={'Select Score method'}
                                                                    SliderName={'Question Score'}
                                                                    showSelectBar={showSelectBar}
                                                                    handleChange={handleChange}
                                                                    sliderType={sliderType}
                                                                    riskCategoryMethodData={questionSliderMethods}
                                                                    nameChange={nameChange}
                                                                    sliderValue={sliderValue}
                                                                    sliderValueStyle={sliderValueStyle}
                                                                    sliderValueName={sliderValueName}
                                                                    riskClearFunction={riskClearFunction}
                                                                    setSliderValueFunction={setRiskLvlEditFunction}
                                          
                                          />
                                                                </div>
                                                            </Box> 





    </Grid>
    <Grid item xs={12}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { mt: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
            >
            <div>
                <TextField
                    id="openEndedAnswer"
                    label="Answer (about 30 charactors)"
                    multiline
                    maxRows={3}
                    value={openEndedAnswerValue}
                    onChange={handleOpenEndedAnswerChange}
                />
            </div>
        </Box>
    </Grid>


    </>
  )
}

export default QuestionerPageOpenEnded