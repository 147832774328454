import React, { useEffect, useState } from 'react'
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip'
import Draggable from 'react-draggable'; 
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const QuestionerMgtPageItemOthersExpert = ({riskTypeData, quesData, classes, collectnId, collectionUniqueName,databClr}) => {

      function PaperComponent(props) {
        return (
          <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper {...props} />
          </Draggable>
        );
      }

      function getRiskLvlColor(lvlData){
        var colorCode = ''
        databClr.forEach((data2)=>{         
          if (parseInt(data2.scoreNumber) === parseInt(lvlData)) {
            colorCode = data2.color;
          }
        })
        return colorCode;
      }

      
      function compareRiskNo( a, b ) {
        var nameA = a.no; // ignore upper and lowercase
        var nameB = b.no; // ignore upper and lowercase
      
        if ( nameA < nameB ){
            return -1;
        }
        if ( nameA > nameB ){
            return 1;
        }
        // names must be equal
        return 0;
      }
    
      
    console.log(quesData);

    const [rows, setRows] = useState([]);

    useEffect(() => {
        rowSettings();
    }, []);

    function rowSettings(){
        var count = 1;
        var tmpArray = [];
        setRows([]);
        quesData.forEach((item)=>{
            var tmpObj = {};
            tmpObj["questionId"] = item.questionId;
            tmpObj["collectionId"] = collectnId;
            tmpObj["collectionUniqueName"] = collectionUniqueName;
            tmpObj["id"] = count;
            var riskScrumbStr = RiskScrumbTable( item.riskTypes.name, item.riskTypes.collectionRiskId, riskTypeData )

            tmpObj["riskName"] = riskScrumbStr;

            tmpObj["objectiveName"] = item.objective.name;
            tmpObj["componentName"] = item.component.name;
            tmpObj["principalName"] = item.principle.name;
            tmpObj["entityName"] = item.entity.name;
            tmpObj["question"] = item.question;
            tmpObj["questionType"] = item.questionType;
            tmpObj["questionScore"] = item.questionScore;
            tmpObj["questionData"] = item;
            tmpArray.push(tmpObj);
            setRows(tmpArray);
            count ++;
        })

    }

    const [selectedQuesData, setSelectedQuesData] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [quesDataCount, setQuesDataCount] = useState(0);
    const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
    const [questionDetailScroll, setQuestionDetailScroll] = React.useState('paper');
  
      function parseId(no,questionId, collectionId, question){
        var tmpArray = [];
        setQuesDataCount(no);
        setSelectedQuestion(question);
        quesData.forEach((item)=>{
            if (item.questionId === questionId) {
            tmpArray.push(item);
            setSelectedQuesData(tmpArray);
            }
        });  
  
        setQuestionDetailOpen(true);
        setQuestionDetailScroll('paper');
  
      }
      
      const questionDetailHandleClickClose = () => {
        setQuestionDetailOpen(false);
      };
  

    function RiskScrumbTable( riskName, collectionRiskId, riskTypeData ) {
        var rsTypeArr = [];
        riskTypeData.forEach((data)=>{
            if (data.collectionRiskId === collectionRiskId){
                rsTypeArr = data.riskTypes;
            }
        });  
        var riskScrumbPath  = "";
        rsTypeArr.forEach((item)=>{
            var pathStr = "";
            pathStr = getRiskScrumbTable(item,riskName);
            if (pathStr != undefined && pathStr != ""){
                riskScrumbPath = pathStr;
                return true;
            }
        });  
        return riskScrumbPath;
      }
  
      // *Risk Scrumb****
  function getRiskScrumbTable(comment,riskName){
    var riskScrumbPath = "";
    if (riskName === comment.name){
        riskScrumbPath =  riskName;
    }else{
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
            var riskPath = comment.name;
            var subRiskPath =  getSubRiskScrumbTable(comment.subRisks,riskName);
            if (subRiskPath != ""){
                riskScrumbPath = riskPath + subRiskPath;
            }
        }
    }
    return riskScrumbPath;
  }
  function getSubRiskScrumbTable(subData,riskName){
    var tmpSubPath = "";
    subData.forEach((item,index)=>{
        if (item.name === riskName){
            tmpSubPath += " --> " + item.name;
            return true; 
        }else{
            if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
                var tmpSubPath1 = " --> " + item.name;
                var tmpSubSubPath1 = getSubRiskScrumbTable(item.subRisks,riskName);
                if (tmpSubSubPath1 != ""){
                    tmpSubPath += tmpSubPath1 + tmpSubSubPath1;
                }
            }    
        }
    });
    return tmpSubPath;
  }    
  

  const renderDetailsButton = (params) => {
      return (
          <strong>
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                      parseId(params.row.id,params.row.questionId,params.row.collectionId,params.row.question )
                  }}
              >
                  More Info
              </Button>
          </strong>
      )
  }

  const renderTextRiskName = (params) => {
    return (
        <Tooltip title={params.row.riskName}>
        <span className={classes.fontZise10}>{params.row.riskName}</span>        
        </Tooltip>
    )
  }
  const renderTextId = (params) => {
    return (
        <span className={classes.fontZise10}>{params.row.id}</span>        
    )
  }
  const renderTextObjectiveName = (params) => {
    return (
        <Tooltip title={params.row.objectiveName}>
        <span className={classes.fontZise10}>{params.row.objectiveName}</span>        
        </Tooltip>
    )
  }
  const renderTextComponentName = (params) => {
    return (
        <Tooltip title={params.row.componentName}>
        <span className={classes.fontZise10}>{params.row.componentName}</span>        
        </Tooltip>
    )
  }
  const renderTextPrincipalName = (params) => {
    return (
        <Tooltip title={params.row.principalName}>
        <span className={classes.fontZise10}>{params.row.principalName}</span>        
        </Tooltip>
    )
  }
  const renderTextQuestion = (params) => {
    return (
        <Tooltip title={params.row.question}>
        <span className={classes.fontZise10}>{params.row.question}</span>        
        </Tooltip>
    )
  }
  const renderTextEntity = (params) => {
    return (
        <Tooltip title={params.row.entityName}>
        <span className={classes.fontZise10}>{params.row.entityName}</span>        
        </Tooltip>
    )
  }
  const renderTextQuestionType = (params) => {
    return (
        <span className={classes.fontZise10}>{params.row.questionType}</span>        
    )
  }
  const renderTextQuestionScore = (params) => {
    var riskLvlColor = getRiskLvlColor(params.row.questionScore)
    return (
        <span className={classes.fontZise10} style={{color: riskLvlColor}}>{params.row.questionScore}</span>        
    )
  }



    const columns = [
      { field: 'id', headerName: 'No', renderCell: renderTextId, resizable: true, width: 15 },
      { field: 'questionId', headerName: 'No', width: 15, hide: true },
      { field: 'collectionId', headerName: 'No', width: 15, hide: true },
      { field: 'collectionUniqueName', headerName: 'No', width: 15, hide: true },      
      { field: 'riskName', headerName: 'Risk', renderCell: renderTextRiskName, resizable: true, cellClassName: 'super-app-theme--cell', width: 250 },
      { field: 'objectiveName', headerName: 'Objective', renderCell: renderTextObjectiveName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
      { field: 'componentName', headerName: 'Component', renderCell: renderTextComponentName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
      { field: 'principalName', headerName: 'Principal', renderCell: renderTextPrincipalName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
      { field: 'entityName', headerName: 'Entity', renderCell: renderTextEntity, cellClassName: 'super-app-theme--cell', resizable: true, width: 90 },
      { field: 'question', headerName: 'Question', renderCell: renderTextQuestion, cellClassName: 'super-app-theme--cell', resizable: true, width: 200 },
      { field: 'questionType', headerName: 'Type', renderCell: renderTextQuestionType, resizable: true, width: 90 },
      { field: 'questionScore', headerName: 'Score', renderCell: renderTextQuestionScore, resizable: true, width: 50 },
      {
        field: 'action',
        headerName: 'More Info',
        sortable: false,
        renderCell: renderDetailsButton,
        disableClickEventBubbling: true,
      
      }
    
    ];

  /** Evaluate All Questions */
  const [evaluateAllOpen, setEvaluateAllOpen] = React.useState(false);
  const [evaluateAllScroll, setEvaluateAllScroll] = React.useState('paper');
  const [closeEndedData, setCloseEndedData] = React.useState([]);
  const [openEndedData, setOpenEndedData] = React.useState([]);
  const [closeEndedTotal, setCloseEndedTotal] = React.useState({});
  
  const evaluateAllHandleClickClose = () => {
    setEvaluateAllOpen(false);
  };
  
  
  function evaluateAll(){
    console.log(rows);
    var countCloseEnded = 1;
    var countOpenEnded = 1;
    var closeEndedArray = [];
    var openEndedArray = [];
    var tmpCOTotalQuestionWeight = 0;
    var tmpCOTotalUsers = 0;
    var tmpCOTotalAnswer1 = 0;
    var tmpCOTotalAnswer2 = 0;
    var tmpCOTotalAnswer3 = 0;
    var tmpCOTotalAnswer4 = 0;
    var tmpCOTotalAnswer5 = 0;
    rows.forEach((data, index)=>{
      var tmpArray = [];
      var objAllCe = {};  
      var objAllOe = {};  
      var tmpDataArray = [];
      var questionData = data.questionData;
      if (data.questionType === "Closeended") {
          objAllCe["id"] = countCloseEnded;
          objAllCe["questionId"] = questionData.questionId;
          objAllCe["question"] = questionData.question;
          objAllCe["questionType"] = questionData.questionType;
          objAllCe["questionWeigth"] = questionData.questionScore;
          objAllCe["noOfRespondedUsers"] = 1; 
          tmpCOTotalUsers += 1;
          objAllCe["totalQuestionWeigth"] = (1 * parseInt(questionData.questionScore));
          tmpCOTotalQuestionWeight += (1 * parseInt(questionData.questionScore));
          objAllCe["questionData"] = questionData;
          countCloseEnded ++;
          
          if (questionData.answers.answerMethod.name != "" && questionData.answers.answerList.length > 0 ) {
              var tmpAnsName = '';
              var tmpRiskLevel = '';
              var tmpTotalUserValue = 0;
              var count1 = 1;
              questionData.answers.answerList.forEach((ansData,index2)=>{
                  var tmpRiskLevel = ansData.riskLevel;
                  tmpAnsName = ansData.answerName;
                  tmpTotalUserValue = 0;
                  if (ansData.expectedAnswerName === "Yes" || ansData.expectedAnswerName === "checked"){
                    tmpTotalUserValue = 1;
                  }else{
                    if (ansData.expectedAnswerName != ''){
                      tmpTotalUserValue =1;
                    }
                  }
                  var objData = {};
                  objData["answerName"] =  tmpAnsName;
                  objData["riskLevel"] =  tmpRiskLevel;
                  objData["userResponseCount"] =  tmpTotalUserValue;
                  tmpDataArray.push(objData);
              })  
          }
              
      }else{
          objAllOe["id"] = countOpenEnded;
          objAllOe["questionId"] = questionData.questionId;
          objAllOe["question"] = questionData.question;
          objAllOe["questionType"] = questionData.questionType;
          objAllOe["questionWeigth"] = questionData.questionScore;
          objAllOe["noOfRespondedUsers"] = 1; 
          objAllOe["totalQuestionWeigth"] = (1 * parseInt(questionData.questionScore));
          objAllOe["questionData"] = questionData;
          //objAllOe["responseUserData"] = tmpArray; 
          countOpenEnded ++;
          var objData = {};
          objData["answer"] =  questionData.answers.answer;
          tmpDataArray.push(objData);
  
      }
      if (Object.keys(objAllCe).length != 0){
          var answerLevelArr = [];
          var level1 = false;
          var level2 = false;
          var level3 = false;
          var level4 = false;
          var level5 = false;
          tmpDataArray.forEach((itemDataData)=>{
            var riskLevelVal= parseInt(itemDataData.riskLevel);
            if (riskLevelVal === -5 || riskLevelVal === -3 || (riskLevelVal > -5 && riskLevelVal < -3)){
              level1 = true;
              var obj = {}
              obj["no"] = 1;
              obj["riskName"] = 'Heighly Negative';
              obj["riskLevel"] = '-5';
              obj["userResponseCount"] = itemDataData.userResponseCount;
              obj["totalRiskLevel"] = -5 * parseInt(itemDataData.userResponseCount);
              tmpCOTotalAnswer1 += -5 * parseInt(itemDataData.userResponseCount);
              answerLevelArr.push(obj);
            }
            if (riskLevelVal === -2 || riskLevelVal === 0 || (riskLevelVal > -2 && riskLevelVal < 0)){
              level2 = true;
              var obj = {}
              obj["no"] = 2;
              obj["riskName"] = 'Negative';
              obj["riskLevel"] = '-2';
              obj["userResponseCount"] = itemDataData.userResponseCount;
              obj["totalRiskLevel"] = -2 * parseInt(itemDataData.userResponseCount);
              tmpCOTotalAnswer2 += -2 * parseInt(itemDataData.userResponseCount);
              answerLevelArr.push(obj);
            }
            if (riskLevelVal === 1 || riskLevelVal === 3 || (riskLevelVal > 1 && riskLevelVal < 3)){
              level3 = true;
              var obj = {}
              obj["no"] = 3;
              obj["riskName"] = 'Somewhat Positive';
              obj["riskLevel"] = '1';
              obj["userResponseCount"] = itemDataData.userResponseCount;
              obj["totalRiskLevel"] = 1 * parseInt(itemDataData.userResponseCount);
              tmpCOTotalAnswer3 += 1 * parseInt(itemDataData.userResponseCount);
              answerLevelArr.push(obj);
            }
            if (riskLevelVal === 4 || riskLevelVal === 7 || (riskLevelVal > 4 && riskLevelVal < 7)){
              level4 = true;
              var obj = {}
              obj["no"] = 4;
              obj["riskName"] = 'Positive';
              obj["riskLevel"] = '4';
              obj["userResponseCount"] = itemDataData.userResponseCount;
              obj["totalRiskLevel"] = 4 * parseInt(itemDataData.userResponseCount);
              tmpCOTotalAnswer4 += 4 * parseInt(itemDataData.userResponseCount);
              answerLevelArr.push(obj);          }
            if (riskLevelVal === 8 || riskLevelVal === 10 || (riskLevelVal > 8 && riskLevelVal < 10)){
              level5 = true;
              var obj = {}
              obj["no"] = 5;
              obj["riskName"] = 'Heightly Positive';
              obj["riskLevel"] = '8';
              obj["userResponseCount"] = itemDataData.userResponseCount;
              obj["totalRiskLevel"] = 8 * parseInt(itemDataData.userResponseCount);
              tmpCOTotalAnswer5 += 8 * parseInt(itemDataData.userResponseCount);
              answerLevelArr.push(obj);          
            }
          })
          if (!level1){
            var obj = {}
            obj["no"] = 1;
            obj["riskName"] = 'Heighly Negative';
            obj["riskLevel"] = '-5';
            obj["userResponseCount"] = 0;
            obj["totalRiskLevel"] = 0;
            answerLevelArr.push(obj);
          }
          if (!level2){
            var obj = {}
            obj["no"] = 2;
            obj["riskName"] = 'Negative';
            obj["riskLevel"] = '-2';
            obj["userResponseCount"] = 0;
            obj["totalRiskLevel"] = 0;
            answerLevelArr.push(obj);
          }
          if (!level3){
            var obj = {}
            obj["no"] = 3;
            obj["riskName"] = 'Somewhat Positive';
            obj["riskLevel"] = '1';
            obj["userResponseCount"] = 0;
            obj["totalRiskLevel"] = 0;
            answerLevelArr.push(obj);
          }
          if (!level4){
            var obj = {}
            obj["no"] = 4;
            obj["riskName"] = 'Positive';
            obj["riskLevel"] = '4';
            obj["userResponseCount"] = 0;
            obj["totalRiskLevel"] = 0;
            answerLevelArr.push(obj);          }
          if (!level5){
            var obj = {}
            obj["no"] = 5;
            obj["riskName"] = 'Heightly Positive';
            obj["riskLevel"] = '8';
            obj["userResponseCount"] = 0;
            obj["totalRiskLevel"] = 0;
            answerLevelArr.push(obj);          
          }
          answerLevelArr = answerLevelArr.sort (compareRiskNo);
          var no = 0;
          var totalRiskLevel = 0;
          var riskName = '';
          var riskLevel = '';
          var userResponseCount =  0;
          var tmpAnswerLevelArr = [];
          //console.log(answerLevelArr);
          answerLevelArr.forEach((itemDataData, index2)=>{
            if (index2 === 0){
              no = itemDataData.no;
              totalRiskLevel = itemDataData.totalRiskLevel;
              riskName = itemDataData.riskName;
              riskLevel = itemDataData.riskLevel;
              userResponseCount = itemDataData.userResponseCount;
              if ((answerLevelArr.length -1) ===  index2){
                var obj = {};
                obj["no"] = no;
                obj["riskName"] = riskName;
                obj["riskLevel"] = riskLevel;
                obj["userResponseCount"] = userResponseCount;
                obj["totalRiskLevel"] = totalRiskLevel;
                tmpAnswerLevelArr.push(obj);
              }
            }else{
              if (no === itemDataData.no){
                totalRiskLevel += itemDataData.totalRiskLevel;
                riskName = itemDataData.riskName;
                riskLevel = itemDataData.riskLevel;
                userResponseCount += itemDataData.userResponseCount;
                if ((answerLevelArr.length -1) ===  index2){
                  var obj = {};
                  obj["no"] = no;
                  obj["riskName"] = riskName;
                  obj["riskLevel"] = riskLevel;
                  obj["userResponseCount"] = userResponseCount;
                  obj["totalRiskLevel"] = totalRiskLevel;
                  tmpAnswerLevelArr.push(obj);
                }
              }else{
                var obj = {};
                obj["no"] = no;
                obj["riskName"] = riskName;
                obj["riskLevel"] = riskLevel;
                obj["userResponseCount"] = userResponseCount;
                obj["totalRiskLevel"] = totalRiskLevel;
                tmpAnswerLevelArr.push(obj);
                no = itemDataData.no;
                totalRiskLevel = itemDataData.totalRiskLevel;
                riskName = itemDataData.riskName;
                riskLevel = itemDataData.riskLevel;
                userResponseCount = itemDataData.userResponseCount;
                if ((answerLevelArr.length -1) ===  index2){
                  var obj = {};
                  obj["no"] = no;
                  obj["riskName"] = riskName;
                  obj["riskLevel"] = riskLevel;
                  obj["userResponseCount"] = userResponseCount;
                  obj["totalRiskLevel"] = totalRiskLevel;
                  tmpAnswerLevelArr.push(obj);
                }
              }
            }
          })
  
          //console.log(tmpAnswerLevelArr);
          tmpAnswerLevelArr = tmpAnswerLevelArr.sort (compareRiskNo);
          objAllCe["answerRiskLevelList"] = tmpAnswerLevelArr;
        }else{
          objAllOe["answers"] = tmpDataArray;
        }
        if (Object.keys(objAllCe).length === 0){
          openEndedArray.push(objAllOe);  
        }
        if (Object.keys(objAllOe).length === 0){
          closeEndedArray.push(objAllCe);  
        }
    });
    var obj = {}
    obj["totalQuestionWeight"] = tmpCOTotalQuestionWeight;
    obj["totalUsers"] = tmpCOTotalUsers;
    obj["totalAnswer1"] = tmpCOTotalAnswer1;
    obj["totalAnswer2"] = tmpCOTotalAnswer2;
    obj["totalAnswer3"] = tmpCOTotalAnswer3;
    obj["totalAnswer4"] = tmpCOTotalAnswer4;
    obj["totalAnswer5"] = tmpCOTotalAnswer5;
  
   
    console.log(closeEndedArray);
    console.log(obj);
    setCloseEndedData(closeEndedArray);
    setOpenEndedData(openEndedArray);
    setCloseEndedTotal(obj);
  
    setEvaluateAllOpen(true);
    setEvaluateAllScroll('paper');
  
  }
  
  


  

    return (
        <>
            <Grid item xs={12}>
            <Button
                variant="contained"
                color="primary"
                size="small"
                style={{marginTop: '-10px', float: 'right'}}
                onClick={() => {
                    evaluateAll()
                }}
                >
                Evaluate All Questions
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>

                <Box    
                    sx={{
                        height: 300,
                        width: '100%',
                        '& .super-app-theme--cell': {
                        whiteSpace: 'normal !important',
                        lineHeight: '1',
                        },
                        '& .super-app-theme--Open': {
                            fontSize: '10px !important',
                        }, 
                    }}
                    >

                    <div style={{ height: 400, width: '100%',backgroundColor: '#ffffff', fontSize: '9px !important' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        getRowClassName={`super-app-theme--Open`}
                        disableSelectionOnClick
                    />
                    </div>

                </Box>
            </Grid>
        </Grid>  

        <Grid item xs={12} id="lastGridId">
            &nbsp;                                    
        </Grid>



    {/** Question Details Show Dialog */}
    <Dialog
          maxWidth='xs'
          open={questionDetailOpen}
          onClose={questionDetailHandleClickClose}
          scroll={questionDetailScroll}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Typography variant="h6" gutterBottom component="div">
                    Question No - {quesDataCount}
                </Typography>
                <Typography component="th" scope="row" style={{width: '100%', fontSize: '10px !important'}}>
                    <span style={{fontWeight: '550'}}>Question - </span>{selectedQuestion}
                </Typography>
          </DialogTitle>
          <DialogContent dividers={questionDetailScroll === 'paper'}>
            <DialogContentText
              id="risk-eval-type-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                {selectedQuesData.map((data) => {
                  var scoreColor = getRiskLvlColor(data.questionScore)
                  return (
                    <Grid item xs={12}>
                      <Box >
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Detail Weightings
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                        <Typography variant="h6" component="div">
                                            Score
                                        </Typography>
                                    </TableCell>
                                </TableRow>    
                            </TableBody>
                        </Table>
                        <Table size="small" aria-label="purchases">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                        Questionnaire weighting
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                    <span style={{color:scoreColor}}>{data.questionScore}</span>
                                    </TableCell>
                                </TableRow>    
                            </TableBody>
                        </Table>
                        {data.questionType === "Closeended" && (
                            <>
                            <Table size="small" aria-label="purchases">
                                <TableBody>        
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                Answers
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                        &nbsp;
                                        </TableCell>
                                    </TableRow>  
                                </TableBody>
                            </Table>        
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                        var riskLvlColor = getRiskLvlColor(item.riskLevel)
                                        return (
                                            <>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                                        <span >{item.answerName}</span>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                    <span style={{color:riskLvlColor }}>{item.riskLevel}</span>
                                                    </TableCell>
                                                </TableRow>    
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            </> 
                        )}
                        <Table size="small" aria-label="purchases">
                            <TableBody>        
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Model Answer(s)
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                    &nbsp;
                                    </TableCell>
                                </TableRow>  
                            </TableBody>
                        </Table>
                        {data.questionType === "Closeended" && (
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                        if (item.expectedAnswerName != ""){
                                          var riskLvlColor = getRiskLvlColor(item.riskLevel)
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                                            <span>
                                                                {item.answerName}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                        <span style={{color:riskLvlColor }}>{item.expectedAnswerName === "Yes" || item.expectedAnswerName === "checked"? item.riskLevel:item.expectedAnswerName}</span>
                                                        </TableCell>
                                                    </TableRow>    
                                                </>
                                            )
                                        }    
                                    })}
                                </TableBody>
                            </Table>
                        )}
                        {data.questionType === "Openended" && (
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                            <span>
                                                {data.answers.answer}
                                            </span>
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                            &nbsp;
                                        </TableCell>
                                    </TableRow>    
                                </TableBody>
                            </Table>
                        )}      
                      </Box>
                    </Grid>     
                  )
                })} 
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={questionDetailHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Question Details Show Dialog*/}



  {/************************ */}

<Dialog
  maxWidth='xl'
  fullWidth={true}
  open={evaluateAllOpen}
  onClose={evaluateAllHandleClickClose}
  scroll={evaluateAllScroll}
  PaperComponent={PaperComponent}
  aria-labelledby="evaluateAll-dialog-title"
  >
  <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
    <Typography variant="h6" style={{textAlign:'right', marginTop:'-22px', marginRight: '-24px'}}><Tooltip title="Close"><Close onClick={()=>setEvaluateAllOpen(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
    <Grid container spacing={1}>                    
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom component="div">
            Evaluation Results
        </Typography>
      </Grid>                  
    </Grid>    
  </DialogTitle>
  <DialogContent dividers={questionDetailScroll === 'paper'}>
    <DialogContentText
      id="risk-eval-type-scroll-dialog-description"
      tabIndex={-1}
      >
      <Grid container spacing={1}>
        <Grid item xs={12}>
        <Paper sx={{ overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 370 }}>
            <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={5} style={{textAlign: 'center', fontSize: '14px', borderBottomColor: 'transparent'}}>Questions</StyledTableCell>
                  <StyledTableCell colSpan={5} style={{textAlign: 'center', fontSize: '14px'}}>Answers</StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell style={{verticalAlign: 'top', width: '4%'}}>No</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '40%'}}>Question</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Weight</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>No of Responses</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Total Weight</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Heighly Negative <br></br>(-5)</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Negative <br></br><br></br>(-2)</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Somewhat Positive <br></br>(1)</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Positive <br></br><br></br>(4)</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Heighly Positive <br></br>(8)</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {closeEndedData.map((data,index) => {
                  return (
                    <>
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row" style={{fontSize: '12px'}}>
                        {data.id}
                      </StyledTableCell>
                      <StyledTableCell style={{fontSize: '12px'}}>{data.question}</StyledTableCell>
                      <StyledTableCell style={{fontSize: '12px'}}>{data.questionWeigth}</StyledTableCell>
                      <StyledTableCell style={{fontSize: '12px'}}>{data.noOfRespondedUsers}</StyledTableCell>
                      <StyledTableCell style={{fontSize: '12px'}}>{data.totalQuestionWeigth}</StyledTableCell>
                      {data.answerRiskLevelList.map((item,index1) => {
                        return (
                          <>
                            {index1 === 0?
                              <StyledTableCell style={{fontSize: '12px'}}>{item.totalRiskLevel === 0?'--':item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel)/(-5 * data.noOfRespondedUsers)) + '%)' }</StyledTableCell>
                            :
                              <>
                              {index1 === 1?
                              <StyledTableCell style={{fontSize: '12px'}}>{item.totalRiskLevel === 0?'--':item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel)/(-2 * data.noOfRespondedUsers)) + '%)' }</StyledTableCell>
                              :
                              <>
                                  {index1 === 2?
                                  <StyledTableCell style={{fontSize: '12px'}}>{item.totalRiskLevel === 0?'--':item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel)/(1 * data.noOfRespondedUsers)) + '%)' }</StyledTableCell>
                                  :
                                  <>
                                    {index1 === 3?
                                      <StyledTableCell style={{fontSize: '12px'}}>{item.totalRiskLevel === 0?'--':item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel)/(4 * data.noOfRespondedUsers)) + '%)' }</StyledTableCell>
                                    :
                                    <>
                                      {index1 === 4?
                                        <StyledTableCell style={{fontSize: '12px'}}>{item.totalRiskLevel === 0?'--':item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel)/(8 * data.noOfRespondedUsers)) + '%)' }</StyledTableCell>
                                      :
                                      ''
                                      }  
                                    </>
                                    }  
                                  </>
                                  }  
                              </>
                              
                              }
                              </>
                            }

                          </>
                        )  
                      })}  


                    </StyledTableRow>

                    
                    </>
                  )
              })}      
              </TableBody>
            </Table>  
          </TableContainer>
          </Paper>
          <TableContainer>
            <Table>
              <TableBody>
                  <StyledTableRow key={1} style={{backgroundColor:'black', color: 'white'}}>
                    <StyledTableCell align='right' colSpan={3} component="th" scope="row" style={{color: 'white', fontWeight: '550', width: '51%'}}>
                      {'TOTAL'}
                    </StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalUsers}</StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalQuestionWeight}</StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalAnswer1 === 0? '--':closeEndedTotal.totalAnswer1 + '(' + Math.round((100 * closeEndedTotal.totalAnswer1)/(-5 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalAnswer2 === 0? '--':closeEndedTotal.totalAnswer2 + '(' + Math.round((100 * closeEndedTotal.totalAnswer2)/(-2 * closeEndedTotal.totalUsers)) + '%)' }</StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalAnswer3 === 0? '--':closeEndedTotal.totalAnswer3 + '(' + Math.round((100 * closeEndedTotal.totalAnswer3)/(1 * closeEndedTotal.totalUsers)) + '%)' }</StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalAnswer4 === 0? '--':closeEndedTotal.totalAnswer4 + '(' + Math.round((100 * closeEndedTotal.totalAnswer4)/(4 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalAnswer5 === 0? '--':closeEndedTotal.totalAnswer5 + '(' + Math.round((100 * closeEndedTotal.totalAnswer5)/(8 * closeEndedTotal.totalUsers)) + '%)' }</StyledTableCell>
                  </StyledTableRow>
              </TableBody>
            </Table>  
          </TableContainer>





        </Grid>
      </Grid>                   
            
    </DialogContentText>
  </DialogContent>
  <DialogActions>
      <Button onClick={evaluateAllHandleClickClose}>Close</Button>
  </DialogActions>
</Dialog>
      {/** End of Question Details Show Dialog*/}


{/************************ */}




      

        </>

      )
    }
  
  export default QuestionerMgtPageItemOthersExpert