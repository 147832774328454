import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Paper,
    Grid,
    Container,
    CssBaseline,
    FormControlLabel,
    Switch as MuiSwitch,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowBack, StarBorderPurple500Rounded } from '@mui/icons-material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import axios from 'axios';
import parseHtml from 'html-react-parser';
import Checkbox from '@mui/material/Checkbox';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import { CONFIG } from '../config';
import { Box } from '@material-ui/core';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DoneIcon from '@mui/icons-material/Done';
import useStyle from '../Css/MainCss';

import SuperAdminLoginAddCollection from '../Components/SuperAdminLoginAddCollection';
import SuperAdminLoginCentreLimitation from '../Components/SuperAdminLoginCenterLimitation';
import SuperAdminLoginCentreMaintenance from '../Components/SuperAdminLoginCenterMaintenance';
import SchemaMaintainers from '../Components/SchemaMaintainers';
import { Tabs, Tab } from '@mui/material';
import SuperAdminLoginContactUsPageDesign from '../Components/SuperAdminLoginContactUsPageDesign';
import SuperAdminLoginLandingPageDesign from '../Components/SuperAdminLoginLandingPageDesign';
import SuperAdminLoginAboutUsPageDesign from '../Components/SuperAdminLoginAboutUsPageDesign';
import SuperAdminLoginPageDesign from '../Components/SuperAdminLoginPageDesign';
import SuperAdminFooterDesign from '../Components/SuperAdminFooterDesign';
import SuperAdminHeaderSettings from '../Components/SuperAdminHeaderSettings';
import Setupupcentrefortrailusers from '../Components/Setupupcentrefortrailusers';
import MultitaskingMenuSetup from '../Components/MultitaskingMenuSetup';
import Pricing2 from '../Components/Pricing2';
import MainMenuSetup from '../Components/MainMenuSetup';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';




function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}
const packageBorderStyle = {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '10px',
    //height:'450px',       
};

function SystemSettings() {
    const [PageName, setPageName] = React.useState('System Settings');
    const navigate = useNavigate();
    const classes = useStyle();

    React.useEffect(() => {
        getData();
        getTemplates();
        getAllKeyCodes();
    }, []);

    const [tinyMCETokens, setTinyMCETokens] = React.useState([]);
  function getAllKeyCodes() {
    axios(CONFIG.EXPERT_SERVER_URL + `/loginPageSubPage/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
    .then(function (response) {
        console.log("++++All Domains Sub Pages Details +++++++++++++++++");
        console.log(response.data);
        var tokenArr = [];
        response.data.forEach((item, index) => {
            item.pages.forEach((data) => {
                var canTake = false;
                if (data.isHide != undefined && data.isHide != null) {
                    if (data.isHide === 'no'){
                        canTake = true;
                    }
                }else{
                    canTake = true;
                }
                if (canTake){
                    var tObj = {}
                    var domName = item.parentDomainName;
                    var keyCode = data.keyCode;
                    tObj["text"] = domName + " - " + keyCode;
                    tObj["value"] = "<a href='" + "/domainendpoint?keyCode=" + keyCode + "' target='_blank'>Click Here</a>";
                    tokenArr.push(tObj)
                }
            })
        })
        setTinyMCETokens(tokenArr);

    })
    .catch(function (error) {
        console.log(error);
    });
}

  const [tinyMCETemplateList, setTinyMCETemplateList] = React.useState()

  var templateArr = [];
  function getTemplateHtml(fileName){
    axios('https://www.daytaem.co.uk/readFile.php?q=' + fileName, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
    })
    .then(function (res) {
        var tmpA = fileName.split(".")
        var templateObj = {};
        templateObj["title"] = tmpA[0].replace("RST_","");
        templateObj["description"] = "Risk System - Template ";
        templateObj["content"] = res.data;
        templateArr.push(templateObj);
        setTinyMCETemplateList(templateArr);
    })
    .catch(function (error) {
        console.log(error);
    });

  }

  function getTemplates(){
      var filesArr = "";
      axios('https://www.daytaem.co.uk/getFile.php?q=aaaaaaa', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
        var resData = response.data;
        var arrTmp= resData.split("@@");
        var filesStr=arrTmp[1];
        
        filesArr = filesStr.split("]");
        var k = 1;
        
        var templateHtmlArr = [];
        for (var i=1; i < filesArr.length; i++) {
            if (filesArr[i] != "") {
                if (filesArr[i].slice(0, 3) === "RST"){
                    var fileName = filesArr[i];
                    getTemplateHtml(fileName)
                }
            }

        }
      })
      .catch(function (error) {
        console.log(error);
      });  
  }

    const [globalCentreId, setGlobalCentreId] = React.useState('');
    function getData() {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        setGlobalCentreId(data.centreId);
    }

    const [openCentreLimitation,setOpenCentreLimitation] = React.useState(false);
  const [openCentreMaintenance,setOpenCentreMaintenance] = React.useState(false);
  const [openAddCollections,setOpenAddCollections] = React.useState(true);
  const [openCreateSchema,setOpenCreateSchema] = React.useState(false);
  const [opensetOpensetupTrailUsers,setOpensetOpensetupTrailUsers] = React.useState(false);
  const [multitaskingMenuSetup,setMultitaskingMenuSetup] = React.useState(false);
  const [pricing,setPricing] = React.useState(false);
  const [mainMenuSetup,setMainMenuSetup] = React.useState(false);

  function setOpenCentreLimitationFuction(flag){
    setOpenCentreLimitation(true);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(false)
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(false);
    setMainMenuSetup(false);
    setPricing(false);
  }
  function setOpenCentreMaintenanceFuction(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(true);
    setOpenAddCollections(false);
    setOpenCreateSchema(false);
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(false);
    setMainMenuSetup(false);
    setPricing(false);
  }
  function setOpenAddCollectionFuction(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(true);
    setOpenCreateSchema(false)
    setOpensetOpensetupTrailUsers(false);
    setMainMenuSetup(false);
    setMultitaskingMenuSetup(false);
    setPricing(false);
  }

  function setOpenCreateSchemaFuction(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(true);
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(false);
    setMainMenuSetup(false);
    setPricing(false);
  }
  

  function setOpensetupTrailUsersFuction(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(false)
    setOpensetOpensetupTrailUsers(true);
    setMultitaskingMenuSetup(false);
    setMainMenuSetup(false);
    setPricing(false);
  }

  function setOpenMultitaskingMenuSetup(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(false)
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(true);
    setMainMenuSetup(false);
    setPricing(false);
  }
  
  function setOpenMainMenuSetup(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(false);
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(false);
    setPricing(false);
    setMainMenuSetup(true);
  }

  function setOpenPricing(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(false);
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(false);
    setMainMenuSetup(false);
    setPricing(true);
  }


  const [activeTab, setActiveTab] = React.useState(0); // Set the default active tab to the first tab

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };



    return (
        <>
            <RightBarDataForOtherPages PageName={PageName} />
            <Container style={{maxWidth: '100%', paddingLeft: '30px', paddingRight: '0px'}}>
                <CssBaseline />
                <div className='pageTopRC'>
                    <Grid container spacing={2}>
                        <Grid container spacing={2} className='pageBodyStyleRC'>
                            <Grid item xs={12}>
                                <div role="presentation" onClick={handleClick}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" to="/login">
                                            <span className='breadcrumbFonts'>Home</span>
                                        </Link>
                                        <Typography color="text.primary"><span className='breadcrumbFonts'>Main menu - {PageName}</span></Typography>
                                    </Breadcrumbs>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <a className='goBack' onClick={() => navigate(-1)}><ArrowBack /> Go Back</a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{marginLeft: '-28px'}}>
                        <Grid item xs={12} style={{paddingLeft: '11px', paddingRight: '0px'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div style={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                           
                                            <Button
                                                id='btnOpenRiskCollection'
                                                variant="outlined"
                                                size="medium"
                                                className={(openAddCollections ? classes.superAdmintabBtnDBClicked : classes.tabBtnDB)}
                                                onClick={() => setOpenAddCollectionFuction(true)}
                                            >
                                                Display Settings
                                            </Button>&nbsp;&nbsp;
                                            
                                            <Button
                                                id='btnOpenRiskCollection'
                                                variant="outlined"
                                                size="medium"
                                                className={(multitaskingMenuSetup ? classes.superAdmintabBtnDBClicked : classes.tabBtnDB)}
                                                onClick={() => setOpenMultitaskingMenuSetup(true)}
                                            >
                                                multitasking menusetup
                                            </Button>&nbsp;&nbsp;
                                            <Button
                                                id='btnOpenRiskCollection'
                                                variant="outlined"
                                                size="medium"
                                                className={(mainMenuSetup ? classes.superAdmintabBtnDBClicked : classes.tabBtnDB)}
                                                onClick={() => setOpenMainMenuSetup(true)}
                                            >
                                                Main menu setup
                                            </Button>&nbsp;&nbsp;
                                            
                                        </div>
                                    </div>
                                </Grid>
                                {openCentreLimitation ?
                                    <Grid item xs={12}>
                                        <Card
                                            className={classes.cardDivMove}
                                            style={{ marginRadius: '10px', width: '100%', height: '245px', overflowY: 'scroll' }}
                                        >
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SuperAdminLoginCentreLimitation />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    : ''
                                }
                                {openCentreMaintenance ?
                                    <Grid item xs={12}>
                                        <Card
                                            className={classes.cardDivMove}
                                            style={{ marginRadius: '10px', width: '100%', height: '750px', overflowY: 'scroll' }}
                                        >
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SuperAdminLoginCentreMaintenance />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    : ''
                                }
                                {openAddCollections ?
                                    <Grid item xs={12}>
                                        <Card
                                            className={classes.cardDivMove}
                                            style={{ marginRadius: '10px', width: '100%', height: '750px', overflowY: 'scroll' }}
                                        >
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>

                                                        <Tabs
                                                            value={activeTab}
                                                            onChange={handleTabChange}
                                                            textColor="primary"
                                                            indicatorColor="primary"
                                                            // centered // Centers the tabs horizontally
                                                            sx={{ bgcolor: '#f5f5f5', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}
                                                        >
                                                            <Tab
                                                                label="Log In Page Design"
                                                                sx={{
                                                                    textTransform: 'none', // Disable uppercase text
                                                                    fontSize: '1rem',
                                                                    fontWeight: '500',
                                                                }}
                                                            />
                                                            <Tab
                                                                label="About Us Page Design"
                                                                sx={{
                                                                    textTransform: 'none',
                                                                    fontSize: '1rem',
                                                                    fontWeight: '500',
                                                                }}
                                                            />
                                                            <Tab
                                                                label="Our Services Page Design"
                                                                sx={{
                                                                    textTransform: 'none',
                                                                    fontSize: '1rem',
                                                                    fontWeight: '500',
                                                                }}
                                                            />
                                                            <Tab
                                                                label="Landing Page Design"
                                                                sx={{
                                                                    textTransform: 'none',
                                                                    fontSize: '1rem',
                                                                    fontWeight: '500',
                                                                }}
                                                            />
                                                            <Tab
                                                                label="Footer Design"
                                                                sx={{
                                                                    textTransform: 'none',
                                                                    fontSize: '1rem',
                                                                    fontWeight: '500',
                                                                }}
                                                            />
                                                            <Tab
                                                                label="Header Settings"
                                                                sx={{
                                                                    textTransform: 'none',
                                                                    fontSize: '1rem',
                                                                    fontWeight: '500',
                                                                }}
                                                            />
                                                            <Tab
                                                                label="Collections Shown in Login Page"
                                                                sx={{
                                                                    textTransform: 'none',
                                                                    fontSize: '1rem',
                                                                    fontWeight: '500',
                                                                }}
                                                            />
                                                        </Tabs>

                                                        {activeTab === 0 ?
                                                            <>
                                                                <br></br>
                                                                <SuperAdminLoginPageDesign
                                                                    tokens={tinyMCETokens}
                                                                />
                                                            </>
                                                            : activeTab === 1 ?
                                                                <>
                                                                    <br></br>
                                                                    <SuperAdminLoginAboutUsPageDesign />
                                                                </>
                                                                : activeTab === 2 ?
                                                                    <>
                                                                        <br></br>
                                                                        <SuperAdminLoginContactUsPageDesign />
                                                                    </> :
                                                                    activeTab === 3 ?
                                                                        <>
                                                                            <br></br>
                                                                            <SuperAdminLoginLandingPageDesign />
                                                                        </> :
                                                                        activeTab === 4 ?
                                                                            <>
                                                                                <br></br>
                                                                                <SuperAdminFooterDesign />
                                                                            </> :
                                                                            activeTab === 5 ?
                                                                                <>
                                                                                    <br></br>
                                                                                    <SuperAdminHeaderSettings />
                                                                                </>
                                                                                :
                                                                                activeTab === 6 ?
                                                                                    <>
                                                                                        <br></br>
                                                                                        <SuperAdminLoginAddCollection
                                                                                            tinyMCETemplateList={tinyMCETemplateList}
                                                                                        />

                                                                                    </>
                                                                                    : ''
                                                        }


                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    : ''
                                }
                                {openCreateSchema ?
                                    <Grid item xs={12}>
                                        <Card
                                            className={classes.cardDivMove}
                                            style={{ marginRadius: '10px', width: '100%', height: '750px', overflowY: 'scroll' }}
                                        >
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SchemaMaintainers />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    : ''
                                }

                                {opensetOpensetupTrailUsers ?
                                    <Grid item xs={12}>
                                        <Card
                                            className={classes.cardDivMove}
                                            style={{ marginRadius: '10px', width: '100%', height: '750px', overflowY: 'scroll' }}
                                        >
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Setupupcentrefortrailusers />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    : ''
                                }

                                {multitaskingMenuSetup ?
                                    <Grid item xs={12}>
                                        <Card
                                            className={classes.cardDivMove}
                                            style={{ marginRadius: '10px', width: '100%', height: '750px', overflowY: 'scroll' }}
                                        >
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <MultitaskingMenuSetup />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    : ''
                                }
                                {mainMenuSetup ?
                                    <Grid item xs={12}>
                                        <Card
                                            className={classes.cardDivMove}
                                            style={{ marginRadius: '10px', width: '100%', height: '750px', overflowY: 'scroll' }}
                                        >
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <MainMenuSetup />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    : ''
                                }

                                {pricing ?
                                    <Grid item xs={12}>
                                        <Card
                                            className={classes.cardDivMove}
                                            style={{ marginRadius: '10px', width: '100%', height: '750px', overflowY: 'scroll' }}
                                        >
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Pricing2 />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    : ''
                                }





                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </>
    );
}

export default SystemSettings;
