import React, { useState } from 'react'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import useStyle from '../Css/MainCss';
import { Grid, makeStyles, Tooltip } from "@material-ui/core";
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info, KeyboardReturnOutlined } from '@mui/icons-material';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios'
import { CONFIG } from '../config'
import TextField from '@mui/material/TextField';
import { Alert } from 'bootstrap';
import Draggable from 'react-draggable';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Delete from '@mui/icons-material/Delete';
import { ArrowBack } from '@mui/icons-material';
import  "../Css/AddScales.css";


function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  stdCard: {
    height: '100%',
    border: '1px solid black',
    marginTop:"0px"
  },
  stdMainCardHeader: {
    backgroundColor: '#acacbe !important',
    fontSize: '13px !important',
    height: '50px',
    padding: '0px',
    //marginTop: '-7px',
  },
  scrollableDivToStandard: {
    height: '350px',
    overflow: 'auto',
    width: '100%',
  },
  tableHeadStyle: {
    backgroundColor: '#d5d5e1',
    color: '#ffffff',
  },
  tableThStyle: {
    lineHeight: '0.25rem !important'
  },
  tableTrStyle: {
    //lineHeight: '0.20rem !important',
    height: '15px !important'
  },
  mousePointer: {
    cursor: 'pointer'
  },
  pageTop: {
    marginTop: '115px',
    //marginLeft: '20px'
  },
  pageBodyStyle: {
    paddingTop: '12px',


  },
}))

const AddScales = () => {
  
  const navigate = useNavigate();

  const classes = useStyles();

  const classe = useStyle();

  React.useEffect(() => {
    getData()
  },[])

  const [globalCentreId, setGlobalCentreId] = React.useState();

  function getData() {
    let data = sessionStorage.getItem('sessionstorage');
    console.log(data);
    data = JSON.parse(data);
    getScaleData(data.centreId)
    setGlobalCentreId(data.centreId);
  }

  const [headerName, setHeaderName] = React.useState('Create and Edit Answer Scales');


  const [selectedScaleNameId, setSelectedScaleNameId] = React.useState('');
  const [selectedScaleName, setSelectedScaleName] = React.useState('');
  const [selectedScaleDescription, setSelectedScaleDescription] = React.useState('');
  const [selectedScaleExamples, setSelectedScaleExamples] = React.useState([]);

  const [scaleData, setScaleData] = React.useState([]);


  function clearScaleFunction(){
    setSelectedScaleNameId('');
    setSelectedScaleName('');
    setSelectedScaleDescription('');
    setSelectedScaleExamples([]);

  }

  const handleChangeScaleName = (event) => {
    setSelectedScaleNameId(event.target.value);
    scaleData.forEach((item)=>{
      if (item.id === event.target.value) {
        setSelectedScaleName(item.name);
        setSelectedScaleDescription(item.description);
        setSelectedScaleExamples(item.examples);
      }  
    })
  };

  /** Get Scale data for selected Centre */
  function getScaleData(centreId) {
    axios(CONFIG.MAIN_URL+`/scale/get?centreId=`+ centreId, {
      method: 'get',
    })
    .then(function (response) {
      console.log("++++++++++++++++Return scale Data +++++++++++++++++++");
      console.log(response.data);
      setScaleData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  }






  /**** */
  const [scaleExampleName, setScaleExampleName] = React.useState(''); 
  const onTextFieldChangeScaleExampleName = (event) => {
    setScaleExampleName(event.target.value);
  }

  function addScaleExampleFunction(){
    scaleData.forEach((item)=>{
      if (item.id === selectedScaleNameId) {
        var exampleArr = item.examples;
        var canTake = true;
        exampleArr.forEach((data)=>{
          if (data.name === scaleExampleName){
            canTake = false;
          }
        })  
        if (canTake) {
          var obj = {};
					obj["id"] = selectedScaleNameId;
					obj["centreId"] = globalCentreId;
					obj["name"] = selectedScaleName;
          obj["description"] = selectedScaleDescription;
					var examples = item.examples;
          var obj1 = {};
					obj1["name"] = scaleExampleName;
          examples.push(obj1);
          obj["examples"] = examples;
					var sendJson = JSON.stringify(obj);
					axios(CONFIG.MAIN_URL+'/scale/update',{
				  		method: 'post',
				  		headers: { 
				      			'Content-Type': 'application/json',
				    		},
				  		data : sendJson,  
					})
					.then(function (res) {
				  		console.log("Updating Scale Retuen");
				  		let rtnData = res.data;
              //getScaleData(globalCentreId); 

              axios(CONFIG.MAIN_URL+`/scale/get?centreId=`+ globalCentreId, {
                method: 'get',
              })
              .then(function (response) {
                console.log("++++++++++++++++Return scale Data +++++++++++++++++++");
                setScaleData(response.data);
                response.data.forEach((item)=>{
                  if (item.id === selectedScaleNameId) {
                    setSelectedScaleName(item.name);
                    setSelectedScaleDescription(item.description);
                    setSelectedScaleExamples(item.examples);
                  }  
                })  
                alert("Save Scaling Example Success");
                setSelectedScaleNameId(selectedScaleNameId);
                setScaleExampleName('');
  
              })
              .catch(function (error) {
                console.log(error);
              });

					})
					.catch(function (error) {
				  		console.log(error);
					});
        }else{
          alert("Example Name is already in the database");
        }
      }  
    })

  }

  function editExampleDataName(exampleId){

  }



  
  /** Create New Scale */
  function PaperComponentOpenCreateNewScale(props) {
    return (
      <Draggable
        handle="#create-new-scale-draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  const [openCreateNewScale, setOpenCreateNewScale] = React.useState(false);
  const [openCreateNewScaleScroll, setOpenCreateNewScaleScroll] = React.useState('paper');
  
  function openCreateNewScaleFunction(){
    setOpenCreateNewScale(true);
    setOpenCreateNewScaleScroll('paper');
  }
  const openCreateNewScaleHandleClickClose = () => {
    setOpenCreateNewScale(false);
  };

  const [newScaleName, setNewScaleName] = React.useState('');
  const handleChangeNewScaleName = (event) => {
    setNewScaleName(event.target.value)
  };

  const [newScaleDescription, setNewScaleDescription] = React.useState('');
  const handleChangeNewScaleDescription = (event) => {
    setNewScaleDescription(event.target.value)
  };

  function saveScaleFuction(){
    if (newScaleName != ''){
      var canTake = true;
      scaleData.forEach((item)=>{
        if (item.name === newScaleName) {  
          canTake = false;
        }
      })
      if (canTake){
        var obj = {};
			  obj["centreId"] = globalCentreId;
			  obj["name"] = newScaleName;
        obj["description"] = newScaleDescription;
			  var sendJson = JSON.stringify(obj);
			  axios(CONFIG.MAIN_URL+'/scale/save',{
          method: 'post',
            headers: { 
                  'Content-Type': 'application/json',
              },
            data : sendJson,  
        })
        .then(function (res) {
            console.log("Saving Scale Retuen");
            let rtnData = res.data;
            console.log(rtnData);
            alert("Save Scale data Success");
            getScaleData(globalCentreId);
            setOpenCreateNewScale(false);
        })
        .catch(function (error) {
            console.log(error);
        });

      }else{
        alert("The Scale name is already in the database. Please user different Scale name");  
      }

    }else{
      alert("Please type the Scale Name");
    }

  }

    /** Edit Scale */
  
    const [openEditScale, setOpenEditScale] = React.useState(false);
    const [openEditScaleScroll, setOpenEditScaleScroll] = React.useState('paper');
    
    function openEditScaleFunction(){
      if (selectedScaleNameId === ''){
        alert('Please select a Scale to edit');
      }else{
        scaleData.forEach((item)=>{
          if (item.id === selectedScaleNameId) {
            setEditScaleName(item.name);
            setEditScaleDescription(item.description);
          }  
        })
        setOpenEditScale(true);
        setOpenEditScaleScroll('paper');
      }
    }
    const openEditScaleHandleClickClose = () => {
      setOpenEditScale(false);
    };
  
    const [editScaleName, setEditScaleName] = React.useState('');
    const handleChangeEditScaleName = (event) => {
      setEditScaleName(event.target.value)
    };
  
    const [editScaleDescription, setEditScaleDescription] = React.useState('');
    const handleChangeEditScaleDescription = (event) => {
      setEditScaleDescription(event.target.value)
    };
  
    function updateScaleFuction(){
      if (editScaleName != ''){
        var canTake = true;
        var exampleArr = [];
        scaleData.forEach((item)=>{
          if (item.id != selectedScaleNameId) {
            if (item.name === editScaleName) {  
              canTake = false;
            }
          }else{
            exampleArr = item.examples;
          }  
        })
        if (canTake){
          var obj = {};
          obj["id"] = selectedScaleNameId;
          obj["centreId"] = globalCentreId;
          obj["name"] = editScaleName;
          obj["description"] = editScaleDescription;
          obj["examples"] = exampleArr;

          var sendJson = JSON.stringify(obj);
          axios(CONFIG.MAIN_URL+'/scale/update',{
            method: 'post',
              headers: { 
                    'Content-Type': 'application/json',
                },
              data : sendJson,  
          })
          .then(function (res) {
              console.log("Updating Scale Retuen");
              let rtnData = res.data;
              console.log(rtnData);
              getScaleData(globalCentreId);
              setSelectedScaleExamples(exampleArr);
              setSelectedScaleDescription(editScaleDescription);
              alert("Update Scale data Success");
              setOpenEditScale(false);
          })
          .catch(function (error) {
              console.log(error);
          });
  
        }else{
          alert("The Scale name is already in the database. Please user different Scale name");  
        }
  
      }else{
        alert("Please type the Scale Name");
      }
  
    }


      /** Edit Example Name */

      const [openEditExample, setOpenEditExample] = React.useState(false);
      const [openEditExampleScroll, setOpenEditExampleScroll] = React.useState('paper');
      
      const [selectedExampleId, setSelectedExampleId] = React.useState('');
      const [selectedExampleName, setSelectedExampleName] = React.useState('');
      const [selectedExampleNameOriginal, setSelectedExampleNameOriginal] = React.useState('');
      function openEditExampleFunction(exampleId){
        scaleData.forEach((item)=>{
          if (item.id === selectedScaleNameId) {
            var exampleArr = item.examples;
            exampleArr.forEach((data)=>{
              if (data.exampleId === exampleId){
                setSelectedExampleId(exampleId);
                setSelectedExampleName(data.name);
                setSelectedExampleNameOriginal(data.name);
              }
            })
          }  
        })
        setOpenEditExample(true);
        setOpenEditExampleScroll('paper');
      }
      const openEditExampleHandleClickClose = () => {
        setOpenEditExample(false);
      };
    
      const handleChangeEditExampleName = (event) => {
        setSelectedExampleName(event.target.value)
      };
    
      function updateExampleFuction(){
        if (selectedExampleName != ''){
          var canTake = true;
          var exampleArr = [];
          scaleData.forEach((item)=>{
            if (item.id === selectedScaleNameId) {
              exampleArr = item.examples;
              exampleArr.forEach((data)=>{
                if (data.exampleId != selectedExampleId){
                  if (data.name === selectedExampleName) {  
                    canTake = false;
                  }
                }
              })
            }  
          })
          if (canTake){
            var obj = {};
            obj["id"] = selectedScaleNameId;
            obj["centreId"] = globalCentreId;
            obj["name"] = selectedScaleName;
            obj["description"] = selectedScaleDescription;
            var exampleTmpArr = [];
            exampleArr.forEach((data)=>{
              if (data.exampleId === selectedExampleId){
                var obj1 = {};
                obj1["exampleId"] = selectedExampleId;
                obj1["name"] = selectedExampleName;
                obj1["types"] = data.types;
                exampleTmpArr.push(obj1);
              }else{
                exampleTmpArr.push(data);
              }
            })
            obj["examples"] = exampleTmpArr;
  
            var sendJson = JSON.stringify(obj);
            axios(CONFIG.MAIN_URL+'/scale/update',{
              method: 'post',
                headers: { 
                      'Content-Type': 'application/json',
                  },
                data : sendJson,  
            })
            .then(function (res) {
                console.log("Updating Example Name Retuen");
                let rtnData = res.data;
                console.log(rtnData);
                getScaleData(globalCentreId);
                setSelectedScaleExamples(exampleTmpArr);
                alert("Update Example Name Success");
                setOpenEditExample(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    
          }else{
            alert("The Example name is already in the database. Please user different Example name");  
          }
        }else{
          alert("Please type the Example Name");
        }
    
      }
  
      /** Add, Edit, Delete Example Types */
      const [openExampleType, setOpenExampleType] = React.useState(false);
      const [openExampleTypeScroll, setOpenExampleTypeScroll] = React.useState('paper');
      const [typeAddBtnName, setTypeAddBtnName] = React.useState('Add');

      const [examplesTypesArray, setExamplesTypesArray] = React.useState([]);
      
      function addExampleTypesFunction(exampleId){
        setSelectedExampleId(exampleId);
        scaleData.forEach((item)=>{
          if (item.id === selectedScaleNameId) {
            var exampleArr = item.examples;
            exampleArr.forEach((data)=>{
              if (data.exampleId === exampleId){
                setSelectedExampleName(data.name);
                setExamplesTypesArray(data.types);
              }
            })
          }  
        })
        setOpenExampleType(true);
        setOpenExampleTypeScroll('paper');
      }
      const openExampleTypeHandleClickClose = () => {
        setOpenExampleType(false);
      };
    
      const [exampleTypeName, setExampleTypeName] = React.useState('');
      const handleChangeExampleTypeName = (event) => {
        setExampleTypeName(event.target.value)
      };
    
      function addExampleNameFuction(){
        if (exampleTypeName != ''){
          var tmpArr = examplesTypesArray;
          if (typeAddBtnName === 'Update'){
            var canTake = true;
            tmpArr.forEach((data)=>{
              if (data.name != exampleTypeNameEdited){
                if (data.name === exampleTypeName){
                  canTake = false;
                }
              }  
            })
            if (canTake){
              var arrType = [];
              tmpArr.forEach((data)=>{
                if (data.name === exampleTypeNameEdited){
                  var obj = {};
                  obj["name"] = exampleTypeName;
                  arrType.push(obj);
                }else{
                  arrType.push(data);
                }  
              })
              setExamplesTypesArray(arrType);
              console.log(tmpArr);
              alert("Example type \"" + exampleTypeNameEdited + "\" is changed to  \"" + exampleTypeName + "\" in the List");
              setExampleTypeName('');
              setTypeAddBtnName('Add');
            }else{
              alert("Example type already in the List");  
            }
          }else{
            var canTake = true;
            tmpArr.forEach((data)=>{
              if (data.name === exampleTypeName){
                canTake = false;
              }
            })
            if (canTake){
              var obj = {};
              obj["name"] = exampleTypeName;
              tmpArr.push(obj);
              setExamplesTypesArray(tmpArr);
              console.log(tmpArr);
              alert("Example type \"" + exampleTypeName + "\" is adding to the List");
              setExampleTypeName('');
              setTypeAddBtnName('Add');
            }else{
              alert("Example type already in the List");  
            }
          }  

        }else{
          alert("Please type the Example type");
        }
      }

      const [exampleTypeNameEdited, setExampleTypeNameEdited] = React.useState('');
      function editExampleTypeFunction(typeName){
        setExampleTypeName(typeName);
        setExampleTypeNameEdited(typeName);
        setTypeAddBtnName('Update');
      }

      function deleteExampleFunction(typeName){
        var tmpArr = examplesTypesArray;
        var arrType = [];
        setExampleTypeName(typeName);
        tmpArr.forEach((data)=>{
          if (data.name != typeName){
            arrType.push(data);
          }  
        })
        setExamplesTypesArray(arrType);
        alert("The Example type \"" + typeName + "\" is deleting in the List");
        setExampleTypeName('');
        setTypeAddBtnName('Add');
      }

      function saveExampleTypeFuction(){
        var canTake = false;
        var tmpArr = examplesTypesArray;
        tmpArr.forEach((data)=>{
          canTake = true;
        })
        if (canTake){
          var exampleArr = [];
          scaleData.forEach((item)=>{
            if (item.id === selectedScaleNameId) {
              exampleArr = item.examples;
            }  
          })
          var obj = {};
          obj["id"] = selectedScaleNameId;
          obj["centreId"] = globalCentreId;
          obj["name"] = selectedScaleName;
          obj["description"] = selectedScaleDescription;
          var exampleTmpArr = [];
          exampleArr.forEach((data)=>{
            if (data.exampleId === selectedExampleId){
              var obj1 = {};
              obj1["exampleId"] = selectedExampleId;
              obj1["name"] = selectedExampleName;
              obj1["types"] = examplesTypesArray;
              exampleTmpArr.push(obj1);
            }else{
              exampleTmpArr.push(data);
            }
          })
          obj["examples"] = exampleTmpArr;

          var sendJson = JSON.stringify(obj);
          axios(CONFIG.MAIN_URL+'/scale/update',{
            method: 'post',
              headers: { 
                    'Content-Type': 'application/json',
                },
              data : sendJson,  
          })
          .then(function (res) {
              console.log("Updating Example Type Retuen");
              let rtnData = res.data;
              console.log(rtnData);
              getScaleData(globalCentreId);
              setSelectedScaleExamples(exampleTmpArr);
              alert("Update Example Types Success");
              setOpenExampleType(false);
          })
          .catch(function (error) {
              console.log(error);
          });

        }else{
          alert("No answer types in the List");
        }
      }

      /** Show Example Types */
      const [openExampleTypeShow, setOpenExampleTypeShow] = React.useState(false);
      const [openExampleTypeScrollShow, setOpenExampleTypeScrollShow] = React.useState('paper');

      const [examplesTypesArrayShow, setExamplesTypesArrayShow] = React.useState([]);
      
      function showExampleTypesFunction(exampleId){
        scaleData.forEach((item)=>{
          if (item.id === selectedScaleNameId) {
            var exampleArr = item.examples;
            exampleArr.forEach((data)=>{
              if (data.exampleId === exampleId){
                setSelectedExampleName(data.name);
                setExamplesTypesArrayShow(data.types);
              }
            })
          }  
        })
        setOpenExampleTypeShow(true);
        setOpenExampleTypeScrollShow('paper');
      }
      const openExampleTypeShowHandleClickClose = () => {
        setOpenExampleTypeShow(false);
      };
    


  return (
    <>
      <RightBarDataForOtherPages PageName={headerName}/> 
      <div className={classes.pageTop} >
          <Grid container spacing={2} >
              <Grid item xs={12} className={classes.pageBodyStyle}>
                <Grid item xs={12}>
                  <div role="presentation" onClick={handleClick}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link underline="hover" color="inherit" to="/login" id='focusHomeLink'>
                        <span className='breadcrumbFontsAS'>Home</span>
                      </Link>
                      <Typography color="text.primary"><span className='breadcrumbFontsAS'>Main menu - {headerName}</span></Typography>
                    </Breadcrumbs>
                  </div>
                </Grid>  
                <Grid item xs={12}>
                      <a className='goBackAS' onClick={() => navigate(-1)}><ArrowBack/> Go Back</a>
                </Grid>
              </Grid>
              <Grid item className='grid1AS' >
                <Box sx={{ minWidth: 120}}>    
                  <FormControl fullWidth>
                      <TextField
                          label={'Select a Scale'}
                          sx={{ width: '100%' }}
                          select
                          value={selectedScaleNameId}
                          onChange={handleChangeScaleName}
                          >
                          {scaleData.map((item)=>(
                              <MenuItem 
                                  value={item.id}
                                  >
                              {item.name}
                              </MenuItem>
                          ))}

                      </TextField>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item className='grid2AS' >
                <div style={{textAlign: 'right'}}>
                  <Button 
                    variant="contained" 
                    onClick={e => openCreateNewScaleFunction()}  
                    //style={{height: '50px'}}
                    className='btnAS'
                    >Create a New Scale
                  </Button>&nbsp;
                  <Button 
                  variant="contained" 
                  onClick={e => openEditScaleFunction()}  
                  className='btnAS'
                  //style={{height: '50px'}}
                  >Edit A Scale Name
                </Button>&nbsp;
                <Button 
                  variant="contained" 
                  onClick={e => clearScaleFunction()}  
                  className='btnAS'
                  //style={{height: '50px'}}
                  >Clear
                </Button>
                </div>

              </Grid>
              {
                selectedScaleNameId != ''?
                <>
                  <Grid item xs={12}>
                    <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                      <div>
                        <TextField
                            id="scaleDescription"
                            label="Answer Scale Description"
                            multiline
                            maxRows={3}
                            //disabled={true}
                            value={selectedScaleDescription}
                        />
                      </div>
                    </Box> 
                  </Grid>
                  <Grid item xs={11}>
                    <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                      <div>
                        <TextField
                            id="scaleDescription"
                            label={"Type Answer Scale Example for " + selectedScaleName}
                            multiline
                            maxRows={2}
                            value={scaleExampleName}
                            onChange={onTextFieldChangeScaleExampleName}
                        />
                      </div>
                    </Box> 
                  </Grid>
                  <Grid item xs={1}>
                    <Button 
                      variant="contained" 
                      onClick={e => addScaleExampleFunction()}  
                      style={{float:'right', height: '50px'}}
                      >Add
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Card className={classes.stdCard}>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                            <Info />
                          </Avatar>
                        }
                        className={classe.commonMainTableHead}
                        title={"Created Examples for " + selectedScaleName}
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                              <div className={classes.scrollableDivToStandard}>
                                <TableContainer>
                                  <Table aria-label="customized table" sx={{ minWidth: 360 }}>
                                    <TableHead className='commonSecondaryTableHead'>
                                      <TableRow>
                                        <StyledTableCell className='commonTableThStikyStyle' style={{ width: '35%' }}>{selectedScaleName} Examples</StyledTableCell>
                                        <StyledTableCell className='commonTableThStikyStyle' style={{ width: '25%' }}></StyledTableCell>
                                        <StyledTableCell className='commonTableThStikyStyle' style={{ width: '25%' }}></StyledTableCell>
                                        <StyledTableCell align="right" className='commonTableThStikyStyle' style={{ width: '15%' }}>Edit</StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {selectedScaleExamples.map((item) => {
                                        return (
                                          <StyledTableRow key={item.exampleId} className='tableTrStyle'>
                                            <StyledTableCell component="th" scope="row" className='tableTrStyle' >
                                              <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{item.name}</div>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row" className='tableTrStyle' >
                                              <Button 
                                                variant='outlined'
                                                onClick={() => { addExampleTypesFunction(item.exampleId) }}
                                                >
                                                Add Example Types
                                              </Button>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row" className='tableTrStyle' >
                                              <Button 
                                                variant='outlined'
                                                onClick={() => { showExampleTypesFunction(item.exampleId) }}
                                                >
                                                View Example Types
                                              </Button>
                                            </StyledTableCell>
                                            <StyledTableCell
                                              align="right"
                                              className='tableTrStyle'
                                            >
                                              <EditIcon
                                                className='tableTrStyle'
                                                onClick={() => { openEditExampleFunction(item.exampleId) }}
                                              />
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        )
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                  </Grid>
              </>
                :
                null
              }
           </Grid>
      </div>

      {/** Create New Scale */}
      <Dialog
        maxWidth='md'
        open={openCreateNewScale}
        onClose={false}
        scroll={openCreateNewScaleScroll}
        //PaperComponent={PaperComponentOpenCreateNewScale}
        aria-labelledby="create-new-scale-draggable-dialog-title"
        >
        <DialogTitle style={{ cursor: 'move' }} id="create-new-scale-draggable-dialog-title">Create a New Scale</DialogTitle>
        <DialogContent dividers={openCreateNewScaleScroll === 'paper'}>
          <DialogContentText id="create-new-scale-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                  <div>
                    <TextField
                        id="newScaleNameId"
                        label="Type the Answer Scale Name"
                        multiline
                        maxRows={2}
                        value={newScaleName}
                        onChange={handleChangeNewScaleName}
                    />
                  </div>
                </Box> 
              </Grid>
              <Grid item xs={12}>
                <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                  <div>
                    <TextField
                        id="newScaleDescription"
                        label="Type the Answer Scale Description"
                        multiline
                        maxRows={3}
                        value={newScaleDescription}
                        onChange={handleChangeNewScaleDescription}
                    />
                  </div>
                </Box> 
              </Grid>
              <Grid item xs={12}>
                  &nbsp;
              </Grid>
              <Grid item xs={12}>
                <Button 
                    variant="outlined"
                    id="newScaleNameBtn" 
                    //className={classes.btnBorderRedEval}
                    style={{float: 'right'}}
                    onClick={()=>saveScaleFuction()}
                    >
                    Add
                </Button>     
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={openCreateNewScaleHandleClickClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/** End of Create New Scale*/}

      {/** Edit Scale Name */}
      <Dialog
        maxWidth='md'
        open={openEditScale}
        onClose={false}
        scroll={openEditScaleScroll}
        //PaperComponent={PaperComponentOpenCreateNewScale}
        aria-labelledby="edit-scale-draggable-dialog-title"
        >
        <DialogTitle style={{ cursor: 'move' }} id="edit-scale-draggable-dialog-title">Edit Scale -  {editScaleName}</DialogTitle>
        <DialogContent dividers={openEditScaleScroll === 'paper'}>
          <DialogContentText id="edit-scale-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                  <div>
                    <TextField
                        id="editScaleNameId"
                        label="Edit the Scale Name"
                        multiline
                        maxRows={2}
                        value={editScaleName}
                        onChange={handleChangeEditScaleName}
                    />
                  </div>
                </Box> 
              </Grid>
              <Grid item xs={12}>
                <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                  <div>
                    <TextField
                        id="editScaleDescription"
                        label="Edit the Answer Scale Description"
                        multiline
                        maxRows={3}
                        value={editScaleDescription}
                        onChange={handleChangeEditScaleDescription}
                    />
                  </div>
                </Box> 
              </Grid>
              <Grid item xs={12}>
                  &nbsp;
              </Grid>
              <Grid item xs={12}>
                <Button 
                    variant="outlined"
                    id="newScaleNameBtn" 
                    //className={classes.btnBorderRedEval}
                    style={{float: 'right'}}
                    onClick={()=>updateScaleFuction()}
                    >
                    Update
                </Button>     
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={openEditScaleHandleClickClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/** End of Edit Scale*/}

      {/** Edit Scale Example Name */}
      <Dialog
        maxWidth='md'
        open={openEditExample}
        onClose={false}
        scroll={openEditExampleScroll}
        //PaperComponent={PaperComponentOpenCreateNewScale}
        aria-labelledby="edit-example-draggable-dialog-title"
        >
        <DialogTitle style={{ cursor: 'move' }} id="edit-example-draggable-dialog-title">Edit Example -  {selectedExampleNameOriginal}</DialogTitle>
        <DialogContent dividers={openEditExampleScroll === 'paper'}>
          <DialogContentText id="edit-scale-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                  <div>
                    <TextField
                        id="editExampleNameId"
                        label="Edit the Example Name"
                        multiline
                        maxRows={2}
                        value={selectedExampleName}
                        onChange={handleChangeEditExampleName}
                    />
                  </div>
                </Box> 
              </Grid>
              <Grid item xs={12}>
                <Button 
                    variant="outlined"
                    id="editExampleNameBtn" 
                    //className={classes.btnBorderRedEval}
                    style={{float: 'right'}}
                    onClick={()=>updateExampleFuction()}
                    >
                    Update
                </Button>     
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={openEditExampleHandleClickClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/** End of Edit Example Name*/}

      {/** Add / Edit Example Types */}
      <Dialog
        maxWidth='md'
        open={openExampleType}
        onClose={false}
        scroll={openExampleTypeScroll}
        //PaperComponent={PaperComponentOpenCreateNewScale}
        aria-labelledby="create-new-scale-draggable-dialog-title"
        >
        <DialogTitle style={{ cursor: 'move' }} id="create-new-scale-draggable-dialog-title">Create and Edit Example types for {selectedExampleName}</DialogTitle>
        <DialogContent dividers={openExampleTypeScroll === 'paper'}>
          <DialogContentText id="create-new-scale-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item className='grid3AS'>
                <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                  <div>
                    <TextField
                        id="exampleTypeName"
                        label="Type the Example Answer Type"
                        multiline
                        maxRows={2}
                        value={exampleTypeName}
                        onChange={handleChangeExampleTypeName}
                    />
                  </div>
                </Box> 
              </Grid>
              <Grid item className='grid4AS'>
                <Button 
                    variant="outlined"
                    id="addExampleTypeBtn" 
                    //className={classes.btnBorderRedEval}
                    style={{float: 'right', height: '50px'}}
                    onClick={()=>addExampleNameFuction()}
                    >
                    {typeAddBtnName}
                </Button>     
              </Grid>
              <Grid item xs={12}>
                <div className={classes.scrollableDivToStandard}>
                  <TableContainer>
                    <Table aria-label="customized table">
                      <TableHead className='commonSecondaryTableHead'>
                        <TableRow>
                          <StyledTableCell className='commonTableThStikyStyle' style={{ width: '70%' }}>{selectedExampleName} Answer Types</StyledTableCell>
                          <StyledTableCell align="right" className='commonTableThStikyStyle' style={{ width: '15%' }}>Edit</StyledTableCell>
                          <StyledTableCell align="right" className='commonTableThStikyStyle' style={{ width: '15%' }}>Delete</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {examplesTypesArray.map((item) => {
                          return (
                            <StyledTableRow key={item.exampleId} className='tableTrStyle'>
                              <StyledTableCell component="th" scope="row" className='tableTrStyle' >
                                <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{item.name}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                align="right"
                                className='tableTrStyle'
                                >
                                <EditIcon
                                  className={classes.mousePointer}
                                  onClick={() => { editExampleTypeFunction(item.name) }}
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                align="right"
                                className='tableTrStyle'
                                >
                                <Delete
                                  className={classes.mousePointer}
                                  onClick={() => { deleteExampleFunction(item.name) }}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button 
                    variant="outlined"
                    id="newScaleNameBtn" 
                    //className={classes.btnBorderRedEval}
                    style={{float: 'right'}}
                    onClick={()=>saveExampleTypeFuction()}
                    >
                    Save
                </Button>     
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={openExampleTypeHandleClickClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/** End of Create New Scale*/}

      {/** Show Example Types */}
      <Dialog
        maxWidth='md'
        open={openExampleTypeShow}
        onClose={false}
        scroll={openExampleTypeScrollShow}
        //PaperComponent={PaperComponentOpenCreateNewScale}
        aria-labelledby="create-new-scale-draggable-dialog-title"
        >
        <DialogTitle style={{ cursor: 'move' }} id="create-new-scale-draggable-dialog-title">Example types for {selectedExampleName}</DialogTitle>
        <DialogContent dividers={openExampleTypeScrollShow === 'paper'}>
          <DialogContentText id="create-new-scale-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={classes.scrollableDivToStandard}>
                  <TableContainer>
                    <Table aria-label="customized table">
                      <TableHead className={classe.commonSecondaryTableHead}>
                        <TableRow>
                          <StyledTableCell className={classe.commonTableThStyle} style={{ width: '70%' }}>{selectedExampleName} Answer Types</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {examplesTypesArrayShow.map((item) => {
                          return (
                            <StyledTableRow key={item.exampleId} className={classes.tableTrStyle}>
                              <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                                <div style={{ whiteSpace: 'normal' }}>{item.name}</div>
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={openExampleTypeShowHandleClickClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/** End of Create New Scale*/}


    </>
  )
}

export default AddScales