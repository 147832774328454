import React, {useEffect,useState} from 'react'
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from "@mui/material/Typography";
import Avatar from '@mui/material/Avatar';
import { CONFIG } from '../config'
import CollectionImg from '../assets/collection.jpg'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import TextField from '@mui/material/TextField';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowBack, Delete, DetailsSharp, Javascript, Label } from '@mui/icons-material';
import "../Css/CenterCollectionManagementPage.css";
import { ClockLoader } from 'react-spinners';


function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));  


const CenterCollectionManagementPage = () => {

  const navigate = useNavigate();  
  //dialog
    const [open, setOpen] = React.useState(false);
    const [waitingOpen, setWaitingOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };



    const [maxWidth, setMaxWidth] = React.useState('md');
    const [userDerailsOpen, setUserDerailsOpen] = React.useState(false);
    const [userDerailsScroll, setUserDerailsScroll] = React.useState('paper');

    const [entityMapArr, setEntityMapArr] = React.useState([]);
    const [entityMapCount, setEntityMapCount] = React.useState(0);
  
    
    const entityMapHandleClickOpen = (scrollType) => () => {
      setUserDerailsOpen(true);
    };
    const userDetailsHandleClickClose = () => {
      setUserDerailsOpen(false);
      setEntityMapCount(0);
      setEntityMapArr([]);
    };


  
  const classes = useStyle();
  const [golobalCentreId, setGolobalCentreId] = React.useState();
  const [golobalUserId, setGolobalUserId] = React.useState('');
  const [quesData, setQuesData] = useState([]); // All Questions In a collectionId 
  const [quesTitle, setQuesTttle]= useState()
  const [canBuycollection, setCanBuyCollection] = useState([]); // Get All Collections  

  /** Sort abject sort array */
function compare( a, b ) {
  var nameA = a.entity.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.entity.name.toUpperCase(); // ignore upper and lowercase

  if ( nameA < nameB ){
      return -1;
  }
  if ( nameA > nameB ){
      return 1;
  }
  // names must be equal
  return 0;
}


  useEffect(() => {
    getData();
  }, [])

  function getData () {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    console.log("+++++++++++++++++++++++++++++DATA+++++++++++++++++++");
    console.log(data);
    setGolobalCentreId(data.centreId);
    getAllCollection(data.centreId);
    setGolobalUserId(data.userId);
    console.log(data.name);
  }

  const [loading1, setLoading1] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  /** Get All Available Collection */
  function getAllCollection(centerId){
    setLoading1(true);
    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all',{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get All Available Collection");
      console.log((response.data));

      var tArr = [];
      response.data.forEach((item)=>{
        if (item.centreId === centerId){
          tArr.push(item)
        }
      })
      setLoading1(false);
      setCanBuyCollection(tArr);
      console.log((tArr));
    })
    .catch(function (error) {
      setLoading1(false);
      console.log(error);
    });
  }




  /** Get Questions In a Collection Id*/
  const [buyQuestionShow, setBuyQuestionShow] = useState(true) // Get All Collections in a Center
  function getQuestionsInCollection(collectionUniqueName, no){
    setBuyQuestionShow(true);
    console.log(collectionUniqueName)
    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get All Questions Using collectionUniqueName")
      //setQuesData(response.data.questions)
      setQuesTttle(response.data[0].title)
      var tmpRiskArr = [];
      if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
        tmpRiskArr = response.data[0].risks; 
      }
      getAllQuestions(tmpRiskArr);
    })
    .catch(function (error) {
      console.log(error);
    });
  }


  /** Get all question from the collection and put in to attay */
  var allQuesArray = [];
  function getAllQuestions(riskArray){
    allQuesArray = [];
    riskArray.forEach((item)=>{
      if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
        item.riskTypes.forEach((item1)=>{
          if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
            item1.questions.forEach((item2)=>{
              allQuesArray.push(item2);
            })  
          }
          if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
            getAllQuestionsInSubRisks(item1.subRisks);
          }
        });
      }
    });
    if (allQuesArray.length > 0){
      var quesArray = allQuesArray.sort (compare);
      setQuesData(allQuesArray);
    }
  }
  function getAllQuestionsInSubRisks(riskArray){
    riskArray.forEach((item)=>{
      if (item.questions != undefined && item.questions != null && item.questions.length > 0){
        item.questions.forEach((item1)=>{
          allQuesArray.push(item1);
        })  
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
        getAllQuestionsInSubRisks(item.subRisks);
      }
    });  
  }


  
  

      /** Credit Card Details */

      const [creditCardDerailsOpen, setCreditCardDerailsOpen] = React.useState(false);
      const [creditCardDerailsScroll, setCreditCardDerailsScroll] = React.useState('paper');
      const [boughtCollectionObject, setBoughtCollectionObject] = React.useState(null);
     
      const creditCardDetailsHandleClickClose = () => {
        setCreditCardDerailsOpen(false);
      };
      
      function buyCollection2(collection,e){
        setBoughtCollectionObject(collection);
        setCreditCardDerailsOpen(true);
      }

      function confirmCreditCardDetailsFunction() {

        let obj = {};
        obj["userId"]= golobalUserId;
        obj["transferStatus"]= "Preparing";
        obj["title"]= boughtCollectionObject.title;
        obj["description"]= boughtCollectionObject.description;
        obj["expertCentreId"]= boughtCollectionObject.centreId;
        obj["transferStatus"]= "Preparing";
        obj["parentCollectionUniqueName"]= boughtCollectionObject.collectionUniqueName;
        var sendJson = JSON.stringify(obj);
        console.log("+++++++++++++++++++++++++++sendJson+++++++++++++++++++++");
        console.log(sendJson);
        setLoading(true);
        axios(CONFIG.MAIN_URL+'/transactiondata/save',{
          method: 'post',
          headers: { 
            'Content-Type': 'application/json',        
          },  
          data:sendJson
        })
        .then(res =>  {
          setLoading(false);
          console.log("++++++++++++++Update Transaction Data+++++++++++++++++");
          console.log(res.data);
          alert('Successfully purchased the Questionnaire Collection. Your Questionnaire Collection will be transfered to your server soon.')
          setCreditCardDerailsOpen(false);
        })  
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      }
    



      /** End of Credit Card Details */
  
  

  const [PageName, setPageName] = useState('Buy Questionnaire Collection')
  return (
    <>
        <RightBarDataForOtherPages PageName={PageName}/>
        <div className={classes.pageTop}>
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.pageBodyStyle}>
                    <Grid item xs={12}>
                        <div role="presentation" onClick={handleClick}>
                          <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" to="/login" id='focusHomeLink'>
                              Home
                            </Link>
                            <Typography color="text.primary">Buy Questionnaire Collections</Typography>
                          </Breadcrumbs>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                      <a style={{ float: 'right', marginTop: '-36px', color: '#0056b3', cursor: 'pointer' }} onClick={() => navigate(-1)}><ArrowBack /> <span className='breadcrumbsStyle'>Go Back</span></a>
                    </Grid>
                    {
                      canBuycollection.map((quesmainn) => {
                        //if (quesmainn.centreId != golobalCentreId && !centerCollection.includes(n => n.collectionId)) {
                          return(
                            <Grid item xs={4} >
                              <Card variant="outlined" className={classes.cardoutcenterBuy}>
                                <CardHeader className={classes.qusCardHeadermapdata} 
                                  avatar={
                                    <Avatar sx={{ bgcolor: '#9155FD' }} aria-label="recipe">
                                        {quesmainn.title.charAt(0)}
                                    </Avatar>
                                  }
                                  action={
                                    <>
                                      <p quesmainn={classes.pMarginBottomZero}> 
                                      </p> 
                                    </>
                                  }
                                  title={quesmainn.title}
                                />
                                <div>
                                <CardContent>
                                  <div className={classes.quesimggg}>
                                    <Grid container spacing={1}>
                                    {quesmainn.imageUrl != undefined && quesmainn.imageUrl != null && quesmainn.imageUrl != ''?
                                     <>
                                      <Grid item xs={3} sx={{m:1}}>
                                        <div>
                                          {/* 
                                          <img src={quesmain.imageUrl} alt={quesmain.title} height={100} width={100} ></img>
                                          */}
                                          
                                          <img src={quesmainn.imageUrl} alt={quesmainn.title} height={80} width={80} ></img>
                                          
                                        </div>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <div className={classes.descriptionStyle}>
                                          <p>{quesmainn.description}</p>
                                        </div> 
                                      </Grid>
                                      </>
                                      :
                                      <>
                                      <Grid item xs={12} style={{height: '80px'}}>
                                        <div className={classes.descriptionStyle}>
                                          <p>{quesmainn.description}</p>
                                        </div> 
                                      </Grid>
                                      </>
                                        }

                                      <Grid item xs={12}>
                                        <Stack direction="row" spacing={1} className={classes.rowbtn}>
                                          <Button variant="outlined"  className={classes.btnwidth} 
                                            onClick={() =>{ getQuestionsInCollection(quesmainn.collectionUniqueName,2);handleClickOpen()}}
                                            >
                                            see some questions
                                          </Button>
                                          <Button variant="contained" endIcon={<ShoppingCartOutlinedIcon />} className={classes.btnwidthcolorbtn}
                                            onClick={()=>buyCollection2(quesmainn)}
                                            >
                                            buy now
                                          </Button>
                                        </Stack>
                                      </Grid>  
                                    </Grid>
                                  </div>  
                                </CardContent> 
                              </div>
                          </Card>
                        </Grid>
                          )
                        //}
                        
                      })
                    }
                    {canBuycollection.length === 0  && !loading1?
                      <>
                        <Grid item xs={12} >
                          <Card variant="outlined" className={classes.cardoutcenterBuy}>
                            <div>
                              <CardContent>
                                <div className={classes.quesimggg}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} sx={{m:1}}>
                                      <div className={classes.descriptionStyleRed}>
                                        {'No Questionnaire Collection available to buy'}
                                      </div> 
                                    </Grid>
                                  </Grid>
                                </div>  
                              </CardContent> 
                            </div>
                          </Card>
                        </Grid>
                      </>
                      :
                      ''
                    
                    }
  
                </Grid>
            </Grid>  
        </div>

{/** Start Dialog Boxes */}   
{/** Question display for each Collection */}                
<div>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'sm'}
      >
      <DialogTitle id="alert-dialog-title" style={{textAlign:'center'}}>
        {quesTitle}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          id="alert-dialog-description">
            {buyQuestionShow && 
              quesData.map((quData,index)=>{
                if (index < 5) {
                    return(
                      <Grid container spacing={1}>
                        <Grid item xs={1}>
                          {index+1} )
                        </Grid>
                        <Grid item xs={11}>
                          {quData.question}
                        </Grid>
                      </Grid>
                    )
                }
              })
            }
            {!buyQuestionShow && 
              quesData.map((quData,index)=>{
                    return(
                      <Grid container spacing={1}>
                        <Grid item xs={1}>
                          {index+1} )
                        </Grid>
                        <Grid item xs={11}>
                          {quData.question}
                        </Grid>
                      </Grid>
                    )
              })
            }
        </DialogContentText>
      </DialogContent>
        <DialogActions dividers>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
    </Dialog>
  </div>

  {/** Dialog for Credit Card Details */}

  <Dialog
    maxWidth={maxWidth}
    open={creditCardDerailsOpen}
    onClose={creditCardDetailsHandleClickClose}
    scroll={creditCardDerailsScroll}
    aria-labelledby="creditCardDerails-scroll-dialog-title"
    aria-describedby="creditCardDerails-scroll-dialog-description"
    >
    <DialogTitle id="draggable-dialog-title">
        Please Enter Your Credit Card details and confirm. (This credit card section need to be implemented)  
    </DialogTitle>
    <DialogContent dividers={creditCardDerailsScroll === 'paper'}>
      <DialogContentText
        id="userDerails-scroll-dialog-description"
        tabIndex={-1}
        >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button 
                variant="outlined" 
                size='small'
                className={classes.btnBorderGreenEntityMap}
                onClick={() => confirmCreditCardDetailsFunction()} 
                style={{float:'right'}}>
                    CONFIRM
            </Button>
          </Grid>
       </Grid>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={creditCardDetailsHandleClickClose}>Cancel</Button>
    </DialogActions>
  </Dialog>





  {/** Dialog Waiting */}
  <Dialog
    maxWidth={'md'}
    open={waitingOpen}
    PaperProps={{style:{backgroundColor: 'transparent', boxShadow: 'none'}}} 
    >
    <CircularProgress disableShrink />
  </Dialog>

  <Dialog
        open={loading1}
        onClose={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          <span style={{ textAlign: 'center' }}>Please Wait ...</span>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ClockLoader
                size={100}
                color={'red'}
                className={classes.loaderStyle1}
                loading={loading1}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={loading}
        onClose={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
        <span style={{ textAlign: 'center' }}>Processing !.<br></br>
            Please Wait .......</span>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ClockLoader
                size={100}
                color={'red'}
                className={classes.loaderStyle1}
                loading={loading}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>











    </>







  )
}

export default CenterCollectionManagementPage