import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import useStyle from '../Css/MainCss';
import Paper from '@mui/material/Paper';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import Draggable from 'react-draggable';
import Tooltip1 from '@mui/material/Tooltip';
import { FullscreenExitRounded, FullscreenRounded, Info, KeyboardReturnOutlined, MaximizeRounded, Minimize, TurnedInOutlined } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import axios from 'axios'
import { CONFIG } from '../config'
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TransferToExcelRegister from './TransferToExcelRegister';
import { BarChart, Bar, XAxis, YAxis, Cell, Tooltip, LabelList, CartesianGrid, Legend } from 'recharts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TransferToExcel from './TransferToExcel';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import EvaluateComponent from './EvaluateComponent';
import EvaluateComponent1 from './EvaluateComponent1';
import SavePageAs from './SavePageAs'
import '../Css/EvaluateRisk.css'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const useStyles = makeStyles((theme) => ({
    right: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    pageBtn: {

        height: '60px',
        width: '170px',
        backgroundColor: '#f9f9f9 !important',
        color: '#9481df !important',
        borderRadius: '12px !important',
    },
    headerLable: {

        color: '#827d7d',
    },
    pageTop: {
        marginTop: '115px',
        marginLeft: '20px'
    },
    pageBodyStyle: {
        paddingTop: '0px',
    },
    root: {
        position: 'relative',
    },
    posRelative: {
        position: 'relative',
    },
    topImagePos: {
        position: 'absolute',
        top: '33%',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    topHeaderText1: {
        color: '#9155FD',
        fontSize: '25px',
        paddingBottom: '10px',
    },
    topHeaderText2: {
        color: '#3A3541',
        fontSize: '12px',
        textAlign: 'center',
        fontFamily: 'Inter',
    },
    thStyle: {
        fontSize: '12px !important',
        lineHeight: '0.5rem !important',
        borderBottom: '0px !important'
    },
    thStyleImage: {
        lineHeight: '0.5rem !important',
        borderBottom: '0px !important',
        width: '130px !important',
    },
    btnColorPurple: {
        fontSize: '12px !important',
        backgroundColor: '#9155FD !important',
        height: '40px',
        textAlign: 'center'
    },
    riskImageSize: {
        width: '120px',
        height: '100px !important',
    },
    tableStyle: {
        width: '55% !important',
        minWidth: '720px !important'
    },
    btnBorderGreen: {
        border: '1px solid #0e640e !important',
        color: '#0e640e !important',
        height: '40px'
    },
    btnclear: {
        height: '40px'
    },
    btnBorderAsh: {
        border: '1px solid #8a8181 !important',
        color: '#8a8181 !important',
    },
    btnBorderRed: {
        border: '1px solid #ff0000 !important',
        color: '#ff0000 !important',
    },
    qusCard: {
        width: '100% !important',
    },
    qusCardHeader: {
        backgroundColor: '#ece9fa !important',
    },
    subText: {
        fontSize: '12px !important',
    },
    btnPadding: {
        paddingLeft: '2px !important',
        paddingRight: '2px !important',
    },
    cardBodyH6: {
        fontSize: '14px !important',
    },
    cardBodyH6Ans: {
        fontSize: '13px',
        paddingLeft: '12px',
    },
    addBodyPlus: {
        fontSize: '146px !important',
        fontWeight: '1000 !important',
        textAlign: 'center !important',
        color: '#3A3541 !important',
    },
    posRelativeAndDivBorder: {
        position: 'relative',
        border: '1px solid #9155FD',
        width: '120px',
        height: '100px !important',
    },
    hightrachytitle: {
        textAlign: 'left',
        color: '#3A3541',

    },
    btnBorderpurple: {
        border: '1px solid #9155FD !important',
        color: '#9155FD !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },
    hightrachytitlestyle: {
        fontSize: '14px !important',
        fontWeight: 500
    },
    modalbox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: 14,
        padding: 10,
    },
    addriskbtn: {
        color: '#9155FD !important',
        border: '1px solid #9155FD !important',
        marginTop: '20px !important'
    },
    risktytext: {
        width: '350px',

    },
    canclbtn: {
        alignItem: 'left !important',
        justifyContent: 'end'
    },
    cardRiskManage: {
        width: '95%',
        border: '1px solid black'
    },
    scrollableDiv: {
        height: '150px',
        overflow: 'auto',
        width: '100%',
    },
    tableHeadStyle: {
        backgroundColor: '#7B9DE0',
        color: '#ffffff',
    },
    tableThStyle: {
        lineHeight: '0.25rem !important',
        color: 'white !important'
    },
    tableTrStyle: {
        height: '19px !important'
    },
    mousePointer: {
        cursor: 'pointer'
    },
    scrollableDiv: {
        height: '250px',
        overflow: 'auto',
        width: '100%',
    },
    stdMainCardHeader: {
        backgroundColor: '#4C7EE0 !important',
        fontSize: '13px !important',
        height: '50px',
        padding: '0px',
        marginTop: '-7px',
        color: 'white'
    },
    addrisktypebtn: {
        marginLeft: '0px !important',
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },

    btnBorderpurple: {
        border: '1px solid #9155FD !important',
        color: '#9155FD !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },
    stdCard: {
        height: '100%',
        border: '1px solid black'
    },
    scrollableDivToEval: {
        height: '400px',
        overflow: 'auto',
        width: '100%',
    },
    noclickbackdrop: {
        pointerEevents: "none",
    },
    slider1: {
        background: 'linear-gradient(90deg, rgb(70, 163, 56) 10%,#d3d3d3 0%)',
    },
    cardDivDB: {
        border: '2px solid 69676e',
        width: '100% !important',
        backgroundColor: '#f6f5fc !important',
        boxShadowhadow: '5px 10px #69676e',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        borderRadius: '5px !important'
    },
    stdButtonsStyle: {
        fontSize: '0.70rem !important',
        height: '50px !important',
    },
    collectionCardDBEval: {
        height: '145px',
        borderRadius: '15px !important',
        backgroundColor: '#dce3f2 !important',
    },
    collectionCardTitleDBEval: {
        fontSize: '13px !important',
        textAlign: 'center',
        fontWeight: '500',
    },
    commonMainTableHeadEval: {
        backgroundColor: '#4C7EE0 !important',
        fontSize: '16px !important',
        height: '50px',
        padding: '0px',
        marginTop: '-7px',
        color: 'white'
    },
    alignCenterDBEval: {
        textAlign: 'center !important',
        alignItems: 'center  !important',
        justifyContent: 'center  !important'
    },

    btnActive: {
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        backgroundColor: '#BACDF2 !important',
        padding: '5px 10px !important',
        height: '40px !important',
        width: '160px !important'
    },

    btnNormal: {
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        padding: '5px 10px !important',
        height: '40px !important',
        width: '160px !important'
    },

    btnSepateWindow: {
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        padding: '5px 5px !important',
        height: '50px !important',
        width: '200px !important',
        float: 'right'
    },
}));

const RegisterEvaluateComponent2 = ({ centreId, allRegister }) => {


    const [maxWidthTable, setMaxWidthTable] = React.useState(400);


    useEffect(() => {
        console.log("++++++++++++window. screen. width+++++++++++++");
        if (window.screen.width <= 480) {
            setMaxWidthTable(150)
        } else {
            if (window.screen.width <= 810 && window.screen.width >= 481) {
                setMaxWidthTable(200)
            }
        }
        console.log(window.screen.width);
    }, [])

    const classes = useStyles();

    React.useEffect(() => {
        getColors();
        getAllRisks(centreId);
        getAllRisksMethods()
    }, [])


    const [allAvailableRisk, setAllAvailableRisk] = React.useState([]);
    function getAllRisks(centId) {
        axios(CONFIG.MAIN_URL + `/risk/all`, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("All Risk Returns");
                console.log(response.data);
                var arr = [];
                response.data.forEach((item) => {
                    if (centId === item.centreId) {
                        arr.push(item);
                    }
                })
                setAllAvailableRisk(arr);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [riskCategoryMethodData, setRiskCategoryMethodData] = useState([])
    const [riskCategoryId, setRiskCategoryId] = useState('')

    const [riskCategoryMethodDataAppetite, setRiskCategoryMethodDataAppetite] = useState([])
    const [riskCategoryIdAppetite, setRiskCategoryIdAppetite] = useState('')

    const [lastRiskTmpId, setLastRiskTmpId] = React.useState(0);

    function getAllRisksMethods() {
        axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_RISK + "/all", {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {

                console.log("+++++++++++++++++++All Risk Returns++++++++++++++++++++++++++++");
                console.log(response);
                console.log(response.data[0]);
                setRiskCategoryMethodData(response.data[0].methods)
                setRiskCategoryId(response.data[0].id)

                setRiskCategoryMethodDataAppetite(response.data[0].methods)
                setRiskCategoryIdAppetite(response.data[0].id)

                console.log(response.data[0].methods);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const classe = useStyle();

    function PaperComponentCollectionData(props) {
        return (
            <Draggable
                handle="#collection-data-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    const [btnHirachivalClass, setBtnHirachivalClass] = useState(classes.btnNormal);
    const [btnGradientClass, setBtnGradientClass] = useState(classes.btnNormal);
    const [btnBarChartClass, setBtnBarChartClass] = useState(classes.btnNormal);

    const [openRegisterDataForEval, setOpenRegisterDataForEval] = useState(false);
    const [openRegisterDataForEvalScroll, setOpenRegisterDataForEvalScroll] = useState('paper');
    const [maxWidthState, setMaxWidthState] = useState('lg');
    const [fullScreenState, setFullScreenState] = useState(false);
    const [selectedRegisterEvaluateTitle, setSelectedRegisterEvaluateTitle] = useState('');
    const [fullScreenStateMsg, setFullScreenStateMsg] = useState('Full Screen');
    const [colorPatternId, setColorPatternId] = useState('')
    const [colorPatterns, setColorPatterns] = useState([])
    const [tdPresentage, setTdPresentage] = useState()
    const [colorPatternName, setColorPatternName] = useState('')
    const [colorPatternPrimaryColorName, setColorPatternPrimaryColorName] = useState('')
    const [colorPatternSecondaryColorName, setColorPatternSecondaryColorName] = useState('')
    const [colorSelectDropDownRiskLevelTmp, setColorSelectDropDownRiskLevelTmp] = React.useState();
    const [colorSelectDropDownRiskAppetiteTmp, setColorSelectDropDownRiskAppetiteTmp] = React.useState();
    const [colorSelectDropDownRiskLevel, setColorSelectDropDownRiskLevel] = React.useState();
    const [colorSelectDropDownRiskAppetite, setColorSelectDropDownRiskAppetite] = React.useState();
    const [showEvalData, setShowEvalData] = useState(false)
    const [applyFlag, setApplyFlag] = useState(false)
    const [showTableType, setShowTableType] = useState()
    const [showTranferExlButton, setShowTranferExlButton] = useState(false)
    const [commitBarData, setCommitBarData] = React.useState([]);
    const [barChartHeight, setBarChartHeight] = React.useState(600);
    const [barData, setBarData] = React.useState([]);
    const [arrRiskColorState, setArrRiskColorState] = React.useState([]);
    const [arrColorAppState, setArrColorAppState] = React.useState([]);
    const [editRiskHirachy, setEditRiskHirachy] = React.useState(false);
    const [waitingOpen, setWaitingOpen] = React.useState(false);
    const [riskTypeDataForEval, setRiskTypeDataForEval] = React.useState({});
    const [registerId, setRegisterId] = React.useState('');

    function getSubAvarageRisk(comment) {
        var subTotal = 0
        var avarage = 0;
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            var len = 0;
            var subTotal = 0;
            comment.subRisks.forEach((item) => {
                subTotal = subTotal + parseInt(item.riskLevel);
                len = len + 1;
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    item.subRisks.forEach((item1) => {
                        subTotal = subTotal + parseInt(item1.riskLevel);
                        len = len + 1;
                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                            item1.subRisks.forEach((item2) => {
                                subTotal = subTotal + parseInt(item2.riskLevel);
                                len = len + 1;
                                if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                    item2.subRisks.forEach((item3) => {
                                        subTotal = subTotal + parseInt(item3.riskLevel);
                                        len = len + 1;
                                        if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                            item3.subRisks.forEach((item4) => {
                                                subTotal = subTotal + parseInt(item4.riskLevel);
                                                len = len + 1;
                                                if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                                    item4.subRisks.forEach((item5) => {
                                                        subTotal = subTotal + parseInt(item5.riskLevel);
                                                        len = len + 1;
                                                        if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                            item5.subRisks.forEach((item6) => {
                                                                subTotal = subTotal + parseInt(item6.riskLevel);
                                                                len = len + 1;
                                                                if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {

                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }

            })
            avarage = (subTotal / (len));
            avarage = Math.round(avarage);
        }
        return avarage;

    }

    function getSubAvarageAppetite(comment) {
        var subTotal = 0
        var avarage = 0;
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            var len = 0;
            var subTotal = 0;
            comment.subRisks.forEach((item) => {
                subTotal = subTotal + parseInt(item.riskAppetite);
                len = len + 1;
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    item.subRisks.forEach((item1) => {
                        subTotal = subTotal + parseInt(item1.riskAppetite);
                        len = len + 1;
                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                            item1.subRisks.forEach((item2) => {
                                subTotal = subTotal + parseInt(item2.riskAppetite);
                                len = len + 1;
                                if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                    item2.subRisks.forEach((item3) => {
                                        subTotal = subTotal + parseInt(item3.riskAppetite);
                                        len = len + 1;
                                        if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                            item3.subRisks.forEach((item4) => {
                                                subTotal = subTotal + parseInt(item4.riskAppetite);
                                                len = len + 1;
                                                if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                                    item4.subRisks.forEach((item5) => {
                                                        subTotal = subTotal + parseInt(item5.riskAppetite);
                                                        len = len + 1;
                                                        if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                            item5.subRisks.forEach((item6) => {
                                                                subTotal = subTotal + parseInt(item6.riskAppetite);
                                                                len = len + 1;
                                                                if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {

                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }

            })
            avarage = (subTotal / (len));
            avarage = Math.round(avarage);
        }
        return avarage;

    }


    function getSubNo(comment, riskId) {
        var subNo = "0";
        riskTypeDataForEval.riskTypes.forEach((data) => {
            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                data.subRisks.forEach((item, index) => {
                    if (item.riskTypeId === riskId) {
                        subNo = (index + 1) + "";
                    }

                })
            }
        })
        if (subNo === "0") {
            riskTypeDataForEval.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((item, index) => {
                        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                            var tmpN0 = getSubRiskSubNo(item.subRisks, riskId, '');
                            if (tmpN0 != "0") {
                                subNo = (index + 1) + '.' + tmpN0;
                            } else {
                                riskTypeDataForEval.riskTypes.forEach((data1) => {
                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                        data1.subRisks.forEach((item1, index1) => {
                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                item1.subRisks.forEach((item2, index2) => {
                                                    item2.subRisks.forEach((item3, index3) => {
                                                        if (item3.riskTypeId === riskId) {
                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1);
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    }
                                })
                                if (tmpN0 === "0") {
                                    riskTypeDataForEval.riskTypes.forEach((data1) => {
                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                            data1.subRisks.forEach((item1, index1) => {
                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                    item1.subRisks.forEach((item2, index2) => {
                                                        item2.subRisks.forEach((item3, index3) => {
                                                            item3.subRisks.forEach((item4, index4) => {
                                                                if (item4.riskTypeId === riskId) {
                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1);
                                                                }
                                                            })
                                                        })
                                                    })
                                                }
                                            })
                                        }
                                    })
                                    if (tmpN0 === "0") {
                                        riskTypeDataForEval.riskTypes.forEach((data1) => {
                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                data1.subRisks.forEach((item1, index1) => {
                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                        item1.subRisks.forEach((item2, index2) => {
                                                            item2.subRisks.forEach((item3, index3) => {
                                                                item3.subRisks.forEach((item4, index4) => {
                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                        if (item5.riskTypeId === riskId) {
                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1);
                                                                        }
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                        if (tmpN0 === "0") {
                                            riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                    data1.subRisks.forEach((item1, index1) => {
                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                            item1.subRisks.forEach((item2, index2) => {
                                                                item2.subRisks.forEach((item3, index3) => {
                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                if (item6.riskTypeId === riskId) {
                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1);
                                                                                }
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                            if (tmpN0 === "0") {
                                                riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                        data1.subRisks.forEach((item1, index1) => {
                                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                item1.subRisks.forEach((item2, index2) => {
                                                                    item2.subRisks.forEach((item3, index3) => {
                                                                        item3.subRisks.forEach((item4, index4) => {
                                                                            item4.subRisks.forEach((item5, index5) => {
                                                                                item5.subRisks.forEach((item6, index6) => {
                                                                                    item6.subRisks.forEach((item7, index7) => {
                                                                                        if (item7.riskTypeId === riskId) {
                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1);
                                                                                        }
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            }
                                                        })
                                                    }
                                                })
                                                if (tmpN0 === "0") {
                                                    riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                            data1.subRisks.forEach((item1, index1) => {
                                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                    item1.subRisks.forEach((item2, index2) => {
                                                                        item2.subRisks.forEach((item3, index3) => {
                                                                            item3.subRisks.forEach((item4, index4) => {
                                                                                item4.subRisks.forEach((item5, index5) => {
                                                                                    item5.subRisks.forEach((item6, index6) => {
                                                                                        item6.subRisks.forEach((item7, index7) => {
                                                                                            item7.subRisks.forEach((item8, index8) => {
                                                                                                if (item8.riskTypeId === riskId) {
                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1);
                                                                                                }
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                    if (tmpN0 === "0") {
                                                        riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                data1.subRisks.forEach((item1, index1) => {
                                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                        item1.subRisks.forEach((item2, index2) => {
                                                                            item2.subRisks.forEach((item3, index3) => {
                                                                                item3.subRisks.forEach((item4, index4) => {
                                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                                        item5.subRisks.forEach((item6, index6) => {
                                                                                            item6.subRisks.forEach((item7, index7) => {
                                                                                                item7.subRisks.forEach((item8, index8) => {
                                                                                                    item8.subRisks.forEach((item9, index9) => {
                                                                                                        if (item9.riskTypeId === riskId) {
                                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1);
                                                                                                        }
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        })
                                                        if (tmpN0 === "0") {
                                                            riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                    data1.subRisks.forEach((item1, index1) => {
                                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                            item1.subRisks.forEach((item2, index2) => {
                                                                                item2.subRisks.forEach((item3, index3) => {
                                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                                item6.subRisks.forEach((item7, index7) => {
                                                                                                    item7.subRisks.forEach((item8, index8) => {
                                                                                                        item8.subRisks.forEach((item9, index9) => {
                                                                                                            item9.subRisks.forEach((item10, index10) => {
                                                                                                                if (item10.riskTypeId === riskId) {
                                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1) + "." + (index10 + 1);
                                                                                                                }
                                                                                                            })
                                                                                                        })
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            })

                                                        }

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }


                            }

                        }

                    })
                }
            })

        }
        return subNo;
    }

    function getSubRiskSubNo(comment, riskId, subNo) {
        var no = "0"
        comment.forEach((data, index) => {
            if (data.riskTypeId === riskId) {
                no = subNo + (index + 1) + '';
            }
        });

        if (no === "0") {
            comment.forEach((item, index) => {
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    getSubRiskSubNo(item.subRisks, riskId, (index + 1));
                }

            })
        }

        return no;
    }




    function Comment({ comment, lastRiskTmpId, riskNo, fromMainMenu, fromSubMenu, editRiskHirachyData }) {

        const [subLastRiskTmpId, setSubLastRiskTmpId] = React.useState(lastRiskTmpId);
        const [subEditRiskHirachyData, setSubEditRiskHirachyData] = React.useState(editRiskHirachyData);
        const [subRiskNo, setSubRiskNo] = React.useState(riskNo);
        const [show, setShow] = useState(true)

        var noDisplay = '';
        var noDisplaySpacesCount = 0;


        function onTxtNameChageEvent(event) {
            comment["name"] = event.target.value
        }
        function onBlurChageEvent(event) {
            comment["name"] = event.target.value
        }


        const onTextFieldChangeLikelihood = (event) => {
            if (event != null && event.target.value != null) {
                var riskLevelVal = 1;
                var riskImpactVal = 1;
                if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                    event.target.value = '';
                    if (comment.riskImpact != undefined && comment.riskImpact != null && comment.riskImpact != '') {
                        riskImpactVal = parseInt(comment.riskImpact);
                    }
                } else {
                    if (comment.riskImpact != undefined && comment.riskImpact != null && comment.riskImpact != '') {
                        riskImpactVal = parseInt(comment.riskImpact);
                    }
                    riskLevelVal = parseInt(event.target.value)
                }
                comment["riskLikelihood"] = event.target.value;
                var rLevel = riskImpactVal * riskLevelVal;
                comment["riskLevel"] = rLevel.toString();
                var btn = document.getElementById("btn" + comment.riskTypeTmpId);
                btn.click();
                setTimeout(() => {
                    btn.click();
                }, 10);

            }
        }

        const onTextFieldChangeImpact = (event) => {
            if (event != null && event.target.value != null) {
                var riskLevelVal = 1;
                var riskImpactVal = 1;
                if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                    event.target.value = ''
                    if (comment.riskLikelihood != undefined && comment.riskLikelihood != null && comment.riskLikelihood != '') {
                        riskLevelVal = parseInt(comment.riskLikelihood);
                    }
                } else {
                    if (comment.riskLikelihood != undefined && comment.riskLikelihood != null && comment.riskLikelihood != '') {
                        riskLevelVal = parseInt(comment.riskLikelihood);
                    }
                    riskImpactVal = parseInt(event.target.value)
                }
                comment["riskImpact"] = event.target.value;
                var rLevel = riskImpactVal * riskLevelVal;
                comment["riskLevel"] = rLevel.toString();
                var btn = document.getElementById("btn" + comment.riskTypeTmpId);
                btn.click();
                setTimeout(() => {
                    btn.click();
                }, 10);
            }
        }

        const onTextFieldChangeAppetite = (event) => {
            if (event != null && event.target.value != null) {
                if (event.target.value <= 0 || event.target.value > 10 || !Number.isInteger(parseInt(event.target.value))) {
                    event.target.value = ''
                }
                comment["riskAppetite"] = event.target.value;
                var btn = document.getElementById("btn" + comment.riskTypeTmpId);
                btn.click();
                setTimeout(() => {
                    btn.click();
                }, 10);
            }
        }

        var subAvarageRisk = 0;
        var subAvarageAppetite = 0;
        if (fromMainMenu) {
            fromMainMenu = false;
            noDisplay = riskNo;
            subAvarageRisk = getSubAvarageRisk(comment);
            subAvarageAppetite = getSubAvarageAppetite(comment);
        }
        if (fromSubMenu) {
            fromSubMenu = false;
            var subNo = getSubNo(comment, comment.riskTypeId)
            noDisplay = riskNo + '.' + subNo;
            var tmpArr = noDisplay.split(".");
            if (tmpArr.length === 2) {
                noDisplaySpacesCount = 1;
            }
            if (tmpArr.length === 3) {
                noDisplaySpacesCount = 2;
            }
            if (tmpArr.length === 4) {
                noDisplaySpacesCount = 3;
            }
            if (tmpArr.length === 5) {
                noDisplaySpacesCount = 4;
            }
            if (tmpArr.length === 6) {
                noDisplaySpacesCount = 5;
            }
            if (tmpArr.length === 7) {
                noDisplaySpacesCount = 6;
            }
            if (tmpArr.length === 8) {
                noDisplaySpacesCount = 7;
            }
            if (tmpArr.length === 9) {
                noDisplaySpacesCount = 8;
            }
            if (tmpArr.length === 10) {
                noDisplaySpacesCount = 9;
            }
            if (tmpArr.length === 11) {
                noDisplaySpacesCount = 10;
            }


            subAvarageRisk = getSubAvarageRisk(comment);
            subAvarageAppetite = getSubAvarageAppetite(comment);
        }
        const nestedComments = (comment.subRisks || []).map(comment => {
            return <Comment key={comment.riskTypeTmpId} comment={comment} lastRiskTmpId={subLastRiskTmpId} riskNo={subRiskNo} fromSubMenu={true} editRiskHirachyData={subEditRiskHirachyData} type="child" />
        })

        /** Need */
        function getRiskRiskTypeName(Comment) {
            var rName = ''
            riskCategoryMethodData.forEach((data) => {
                if (data.id === Comment.riskLevelData.riskMethodId) {
                    data.riskTypes.forEach((data1) => {
                        if (data1.startingNumber <= Comment.riskLevel && Comment.riskLevel <= data1.endingNumber) {
                            rName = data1.riskTypesName
                        }
                    })
                }

            })
            return rName;
        }

        /** Need */
        function getAppetiteRiskTypeName(Comment) {
            var aName = ''
            riskCategoryMethodDataAppetite.forEach((data) => {
                if (data.id === Comment.riskAppetiteData.riskMethodId) {
                    data.riskTypes.forEach((data1) => {
                        if (data1.startingNumber <= Comment.riskAppetite && Comment.riskAppetite <= data1.endingNumber) {
                            aName = data1.riskTypesName
                        }
                    })
                }

            })
            return aName;
        }


        /** Need */
        function getRiskRiskColor(Comment) {
            var rColor = ''
            var lastNumber = ''
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rColor = data2.color
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rColor = data2.secondaryColor
                        }
                    }
                }
            })
            if (rColor === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.color
                            }
                        } else if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.secondaryColor
                            }
                        }
                    }
                })
            }
            return rColor;
        }


        function getRiskRiskAction(Comment) {
            var rAction = ''
            var lastNumber = ''
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rAction = data2.primaryColorName
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rAction = data2.secondaryColorName
                        }
                    }
                }
            })
            if (rAction === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rAction = data2.primaryColorName
                            }
                        } else if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rAction = data2.secondaryColorName
                            }
                        }
                    }
                })
            }
            return rAction;
        }


        function getRiskRiskColorAve(avarageRisk) {
            var rColor = ''
            var lastNumber = '';
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageRisk)) {
                            rColor = data2.secondaryColor;
                        }
                    } else if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageRisk)) {
                            rColor = data2.color;
                        }
                    }
                }
            })
            if (rColor === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.secondaryColor;
                            }
                        } else if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.color;
                            }
                        }
                    }
                })
            }
            return rColor;
        }


        /** Need */
        function getAppetiteColor(Comment) {
            var aColor = ''
            var colorFg1 = ''

            databClr.forEach((data2) => {
                if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
                    if (Comment.riskAppetite != null && Comment.riskAppetite != undefined) {

                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (aColor === '') {
                                    aColor = data2.color
                                }
                            }
                            if (colorSelectDropDownRiskAppetite === 2) {
                                if (aColor === '') {
                                    aColor = data2.secondaryColor
                                }
                            }
                        }
                    }
                }
            })
            return aColor;
        }


        function getRiskAppetiteAction(Comment) {
            var aColor = ''
            var colorFg1 = ''

            databClr.forEach((data2) => {
                if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {

                    if (Comment.riskAppetite != null && Comment.riskAppetite != undefined) {
                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (aColor === '') {
                                    aColor = data2.primaryColorName
                                }
                            }
                            if (colorSelectDropDownRiskAppetite === 2) {
                                if (aColor === '') {
                                    aColor = data2.secondaryColorName
                                }
                            }
                        }
                    }
                }
            })
            return aColor;
        }

        function getAppetiteColorAve(avarageAppetite) {
            var aColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskAppetite === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageAppetite)) {
                            aColor = data2.secondaryColor;
                        }
                    } else if (colorSelectDropDownRiskAppetite === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageAppetite)) {
                            aColor = data2.color;
                        }
                    }
                }

            })
            return aColor;

        }


        /** Need */
        function getRiskLikeliColor(Comment) {

            var rColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLikelihood)) {
                            rColor = data2.color
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLikelihood)) {
                            rColor = data2.secondaryColor
                        }
                    }
                }

            })
            return rColor;
        }

        /** Need */
        function getRiskImpactColor(Comment) {

            var rColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskImpact)) {
                            rColor = data2.color
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskImpact)) {
                            rColor = data2.secondaryColor
                        }
                    }
                }

            })
            return rColor;
        }

        var riskLikelihood = getRiskLikeliColor(comment)
        var appetiteColor = getAppetiteColor(comment);
        var appetiteAction = getRiskAppetiteAction(comment);
        var riskColor = getRiskRiskColor(comment);
        var riskAction = getRiskRiskAction(comment);
        var riskImpact = getRiskImpactColor(comment)
        var riskColorAverage = getRiskRiskColorAve(subAvarageRisk);
        var appetiteColorAverage = getAppetiteColorAve(subAvarageAppetite)

        /** Need */
        function DisplayStandared({ comment }) {
            console.log("+++++++++++++Athula+++++++comment++++++++++++++++++++++")
            console.log(comment)
            var objectiveId = '';
            var objectiveName = '';
            var componentId = '';
            var componentName = '';
            var principleId = '';
            var principleName = '';
            var standardId = '';
            var standardName = '';
            if (comment.riskStandardData != undefined && comment.riskStandardData != null) {
                if (comment.riskStandardData.objectiveData != undefined && comment.riskStandardData.objectiveData != null) {
                    objectiveId = comment.riskStandardData.objectiveData.objectiveId;
                    objectiveName = comment.riskStandardData.objectiveData.objectiveName;
                    standardId = comment.riskStandardData.objectiveData.standardId;
                    standardName = comment.riskStandardData.objectiveData.standardName;
                }
                if (comment.riskStandardData.componentData != undefined && comment.riskStandardData.componentData != null) {
                    componentId = comment.riskStandardData.componentData.componentId;
                    componentName = comment.riskStandardData.componentData.componentName;
                    if (standardId === '') {
                        standardId = comment.riskStandardData.componentData.standardId;
                    }
                    if (standardName === '') {
                        standardName = comment.riskStandardData.componentData.standardName;
                    }
                }
                if (comment.riskStandardData.principleData != undefined && comment.riskStandardData.principleData != null) {
                    principleId = comment.riskStandardData.principleData.principleId;
                    principleName = comment.riskStandardData.principleData.principleName;
                    if (standardId === '') {
                        standardId = comment.riskStandardData.principleData.standardId;
                    }
                    if (standardName === '') {
                        standardName = comment.riskStandardData.principleData.standardName;
                    }
                }
            }
            var altString = 'Standard Name : ' + standardName;
            if (objectiveName != '') {
                altString += '\nObjective Name : ' + objectiveName;
            }
            if (componentName != '') {
                altString += '\nComponent Name : ' + componentName;
            }
            if (principleName != '') {
                altString += '\nPrinciple Name : ' + principleName;
            }
            if (standardName.length > 10) {
                standardName = standardName.substring(0, 10) + '...';
            }

            console.log("++++++++++++++++++++++++++++altString+++++++++++++++++++++++");
            console.log(altString);
            console.log(standardName);

            return (
                <>

                    {
                        <span title={`${altString}`} style={{ cursor: 'pointer' }}>&nbsp;{standardName}</span>
                    }

                </>
            )
        }
        comment["riskColor"] = riskColor;
        comment["appetiteColor"] = appetiteColor;

        var createArr = pushToArray(comment);





        return (
            <>
                <Grid item xs={6}>
                    {noDisplaySpacesCount === 0 ?
                        <></>
                        : noDisplaySpacesCount === 1 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;</>
                            : noDisplaySpacesCount === 2 ?
                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                : noDisplaySpacesCount === 3 ?
                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                    : noDisplaySpacesCount === 4 ?
                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                        : noDisplaySpacesCount === 5 ?
                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                            : noDisplaySpacesCount === 6 ?
                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                : noDisplaySpacesCount === 7 ?
                                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                    : noDisplaySpacesCount === 8 ?
                                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                        : noDisplaySpacesCount === 9 ?
                                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                            : noDisplaySpacesCount === 10 ?
                                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                                : ''
                    }
                    <Button
                        style={{ width: '0px', height: '0px' }}
                        id={`btn` + comment.riskTypeTmpId}
                        onClick={() => setShow(!show)}
                        startIcon={show ? <ExpandMoreIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} /> : <ChevronRightIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} />}
                    >
                    </Button>
                    {noDisplay}{') '}

                    {comment.name}

                </Grid>
                <Grid item xs={1}>
                    {!editRiskHirachyData ?
                        <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLikelihood}</p></div></span>
                        :
                        <TextField
                            size="small"
                            id={`txtRiskLikelihood` + comment.riskTypeTmpId}
                            label="Likelihood"
                            defaultValue={comment.riskLikelihood}
                            title={comment.riskLikelihood}
                            onChange={onTextFieldChangeLikelihood}
                            //onBlur={onTextFieldChangeLikelihood}
                            style={{ height: '10px !important' }}
                        />
                    }

                </Grid>
                <Grid item xs={1}>
                    {!editRiskHirachyData ?
                        <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskImpact}</p></div></span>
                        :
                        <TextField
                            size="small"
                            id={`txtRiskImpact` + comment.riskTypeTmpId}
                            label="Impact"
                            defaultValue={comment.riskImpact}
                            title={comment.riskImpact}
                            onChange={onTextFieldChangeImpact}
                            style={{ height: '10px !important' }}
                        />
                    }
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLevel}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px' }}><p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '9px' }}>{riskAction}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    {!editRiskHirachyData ?
                        <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${appetiteColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskAppetite}</p></div></span>
                        :
                        <TextField
                            size="small"
                            id={`txtRiskAppetite` + comment.riskTypeTmpId}
                            label="Appetite"
                            defaultValue={comment.riskAppetite}
                            title={comment.riskAppetite}
                            onChange={onTextFieldChangeAppetite}
                            style={{ height: '10px !important', backgroundColor: `${appetiteColor}`, width: '66px' }}
                        />
                    }
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px' }}><p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '9px' }}>{appetiteAction}</p></div></span>
                </Grid>

                {
                    show ?
                        <>
                            {nestedComments}
                        </>
                        : null
                }
            </>

        )
    }

    const [arrayForDisplayDataInTable, setArrayForDisplayDataInTable] = useState([])

    var commitArray = []
    function pushToArray(item) {
        commitArray.push(item);
        console.log("+++++++++++++++++++++commitArray+++++++++++++++");
        console.log(commitArray);
    }

    const [commitArrayData, setCommitArrayData] = useState([])

    function allRegisterArr() {
        var arr = []
        allRegister.forEach((data) => {
            if (data.id === registerId) {
                data.riskRegister.forEach((data1) => {
                    arr.push(data1)
                    data1.risks.forEach((risk) => {
                        var obj = {}
                        obj["section"] = risk.name
                        obj["riskLevel"] = risk.riskLevel
                        obj["riskAppetite"] = risk.riskAppetite

                        arr.push(obj)
                    })
                })
            }
        })
        console.log(arr)

    }


    function createJsonObject(obj) {
        console.log("+++++++++++++++++++obj+++++++++++++++++++++");
        console.log(obj);
        var regTmpId = 100000;
        obj.riskRegister.forEach((data) => {
            data["riskTypeId"] = regTmpId
            regTmpId++;
            if(data.risks != undefined && data.risks != null){
            data.risks.forEach((risk) => {
                risk["riskTypeId"] = regTmpId
                regTmpId++;
                if (risk.riskHierarchy != undefined && risk.riskHierarchy != null) {
                    if (risk.riskHierarchy.riskHierarchyData != undefined && risk.riskHierarchy.riskHierarchyData != null) {
                        var riskHierarchyObj = risk.riskHierarchy.riskHierarchyData;
                        if (riskHierarchyObj.riskTypes != undefined && riskHierarchyObj.riskTypes != null && riskHierarchyObj.riskTypes.length > 0) {
                            risk["subRisks"] = riskHierarchyObj.riskTypes;
                        }
                    }

                }

            })
        }
        })

        var dataStr = JSON.stringify(obj);
        dataStr = dataStr.replaceAll("\"riskRegister\"", "\"riskTypes\"");
        dataStr = dataStr.replaceAll("\"section\"", "\"name\"");
        dataStr = dataStr.replaceAll("\"risks\"", "\"subRisks\"");
        dataStr = dataStr.replaceAll("\"likelihood\"", "\"riskLikelihood\"");
        dataStr = dataStr.replaceAll("\"impact\"", "\"riskImpact\"");
        var jsonObj = JSON.parse(dataStr)

        return jsonObj;

    }
    const [selectedRiskEvaluateTitle, setSelectedRiskEvaluateTitle] = useState('');

    function assignExtraAttributes(data, tmpIdCount) {
        data.forEach((item) => {
            item["riskTypeTmpId"] = globalIdCount
            console.log(globalIdCount)
            globalIdCount++;
            if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                assignExtraAttributes(item.subRisks, tmpIdCount);
            }
        })
        return tmpIdCount;
    }

    const [orginalRiskTypeDataForEval, setOrginalRiskTypeDataForEval] = useState({})

    const [multipleRoles, setMultipleRoles] = useState(false)

    var globalIdCount = 1;


    const [statusState, setStatusState] = useState(false)
    const [idState, setIdState] = useState('')

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };


    const [allRegister1, setAllRegister1] = useState([])

    function setOpenRegisterDataForEvalfunction(status, id) {

        axios(CONFIG.MAIN_URL + `/register/all`, {
            method: 'get',
          })
            .then(function (response) {
              var arr = []
              console.log("all register");
              console.log(response.data);
              response.data.forEach((item) => {
                if (item.centreId === centreId)
                  arr.push(item)
                  console.log(arr)
      
              })
              
              setAllRegister1(arr);
              setStatusState(status);
              setIdState(id);
              setOpenConfirm(true);
      
            })
            .catch(function (error) {
              console.log(error);
            });

    }

    function byRoleFuction(){
        var status = statusState;
        var id = idState;
        setOpenRegisterDataForEvalfunctionEntityWise(status, id, 'Role')
    }
    function byRiskNameFuction(){
        var status = statusState;
        var id = idState;
        setOpenRegisterDataForEvalfunctionEntityWise(status, id, 'RiskName')
    }



    function setOpenRegisterDataForEvalfunctionEntityWise(status, id, type) {
        var tmpIdCount = 1;
        var tmpIdCount1 = 1;
        setWaitingOpen(true)
        setOpenConfirm(false);




        allRegister1.forEach((data) => {
            if (data.id === id) {
                var jsonObj = createJsonObject(data);
                setSelectedRiskEvaluateTitle(data.name)
                globalIdCount = 1;
                jsonObj.riskTypes.forEach((item, index) => {
                    item["riskTypeTmpId"] = globalIdCount;
                    console.log(globalIdCount)
                    globalIdCount++
                    if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                        tmpIdCount = assignExtraAttributes(item.subRisks, tmpIdCount);
                        tmpIdCount++;
                    }

                })


                setRiskTypeDataForEval(jsonObj);


                setMultipleRoles(false)

                var mainArr = []
                jsonObj.riskTypes.forEach((data) => {
                    var secName = data.role;
                    var obj = {};
                    var arr = [];
                    var canTake = true;

                    mainArr.forEach((arrData) => {
                        if (arrData.role === secName) {
                            canTake = false;
                        }
                    })

                    if (canTake) {
                        jsonObj.riskTypes.forEach((item) => {
                            if (secName === item.role) {
                                arr.push(item);
                            }
                        })

                        obj["role"] = data.role

                        mainArr.push(obj);
                    }
                })

                if (mainArr.length > 1) {
                    setMultipleRoles(true)
                }

                if (type === "RiskName"){
                    setMultipleRoles(false)
                }

                console.log("++++++++++++++++++++++jsonObj++++++++++++++++++");
                console.log(jsonObj);
                console.log(JSON.stringify(jsonObj));

                setFullScreenState(false);
                setFullScreenStateMsg("Full Screen");


            }

        })


        setWaitingOpen(false)
        colorPatterns.forEach((data, index) => {
            if (index === 0) {
                setColorPatternId(data.id);
                setDatabClr(data.colors)
                setTdPresentage(100 / (data.colors.length + 1))

                setColorPatternName(data.name);
                setColorPatternPrimaryColorName(data.primaryColorName);
                setColorPatternSecondaryColorName(data.secondaryColorName);
            }
        })
        setColorSelectDropDownRiskLevelTmp(1)
        setColorSelectDropDownRiskAppetiteTmp(2)
        setBtnHirachivalClass(classes.btnNormal)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnNormal)

        setOpenRegisterDataForEval(status)
        setRegisterId(id)
    }

    function handleDownload() {

        var arr = [];
        if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
            arr = commitArray
        } else {
            if (commitBarData != undefined && commitBarData != null && commitBarData.length > 0) {
                arr = commitBarData;
            } else {
                arr = commitArrayData;
            }
        }


        var arrX = [];
        var arrY = []
        var objx = {}
        var arrZ = []

        arrZ.push("Risk Name");
        arrZ.push("Risk Impact");
        arrZ.push("Risk Likelihood");

        arrZ.push("Risk level");
        arrZ.push("RiskAppetite");


        arrX.push(arrZ)
        // arrX.push(arrY)

        riskTypeDataForEval.riskTypes.forEach((data, index) => {

            arrY = []
            arrZ = []
            arrZ.push("");
            arrZ.push("");
            arrZ.push("");
            arrZ.push("");
            arrZ.push("");
            arrX.push(arrZ)


            arrY = []
            arrZ = []
            arrZ.push((index + 1) + ") " + data.name);
            arrZ.push(parseInt(data.riskImpact));
            arrZ.push(parseInt(data.riskLikelihood));
            arrZ.push(parseInt(data.riskLevel));
            arrZ.push(parseInt(data.riskAppetite));
            arrX.push(arrZ)
            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                data.subRisks.forEach((item, index1) => {
                    arrY = []
                    arrZ = []
                    arrZ.push((index + 1) + "." + (index1 + 1) + ") " + item.name);
                    arrZ.push(parseInt(item.riskImpact));
                    arrZ.push(parseInt(item.riskLikelihood));
                    arrZ.push(parseInt(item.riskLevel));
                    arrZ.push(parseInt(item.riskAppetite));
                    arrX.push(arrZ)
                    if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                        item.subRisks.forEach((item1, index2) => {
                            arrY = []
                            arrZ = []
                            arrZ.push((index + 1) + "." + (index1 + 1) + "." + (index2 + 1) + ") " + item1.name);
                            arrZ.push(parseInt(item1.riskImpact));
                            arrZ.push(parseInt(item1.riskLikelihood));
                            arrZ.push(parseInt(item1.riskLevel));
                            arrZ.push(parseInt(item1.riskAppetite));
                            arrX.push(arrZ)
                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                item1.subRisks.forEach((item2, index3) => {
                                    arrY = []
                                    arrZ = []
                                    arrZ.push((index + 1) + "." + (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + ") " + item2.name);
                                    arrZ.push(parseInt(item2.riskImpact));
                                    arrZ.push(parseInt(item2.riskLikelihood));
                                    arrZ.push(parseInt(item2.riskLevel));
                                    arrZ.push(parseInt(item2.riskAppetite));
                                    arrX.push(arrZ)

                                    if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                        item2.subRisks.forEach((item3, index4) => {
                                            arrY = []
                                            arrZ = []
                                            arrZ.push((index + 1) + "." + (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + ") " + item3.name);
                                            arrZ.push(parseInt(item3.riskImpact));
                                            arrZ.push(parseInt(item3.riskLikelihood));
                                            arrZ.push(parseInt(item3.riskLevel));
                                            arrZ.push(parseInt(item3.riskAppetite));
                                            arrX.push(arrZ)
                                            if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                                item3.subRisks.forEach((item4, index5) => {
                                                    arrY = []
                                                    arrZ = []
                                                    arrZ.push((index + 1) + "." + (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + ") " + item4.name);
                                                    arrZ.push(parseInt(item4.riskImpact));
                                                    arrZ.push(parseInt(item4.riskLikelihood));
                                                    arrZ.push(parseInt(item4.riskLevel));
                                                    arrZ.push(parseInt(item4.riskAppetite));
                                                    arrX.push(arrZ)

                                                })

                                            }

                                        })


                                    }

                                })


                            }

                        })
                    }

                })
            }


        })



        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(arrX);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
        saveAs(new Blob([buffer]), 'data.xlsx');
    };

    function RegisterDataForEvalfunctionClose() {
        if (editRiskHirachy) {
            var answer = window.confirm("changes made will not be saved. save changes in Excel spread sheet ?");
            if (answer) {
                handleDownload()
                setEditRiskHirachy(false)
                setOpenRegisterDataForEval(false)
                setDatabClr([]);
                setBarData([]);
                setCommitBarData([])
                setCommitArrayData([]);
                setColorPatternPrimaryColorName('');
                setColorPatternSecondaryColorName('');
                setColorSelectDropDownRiskLevel()
                setColorSelectDropDownRiskAppetite()
                setApplyFlag(false)
                setShowEvalData(false)
                setShowTranferExlButton(false)
                return;
            }
            else {
            }
        }
        setOpenRegisterDataForEval(false)
        setDatabClr([]);
        setBarData([]);
        setCommitBarData([])
        setCommitArrayData([]);
        setColorPatternPrimaryColorName('');
        setColorPatternSecondaryColorName('');
        setColorSelectDropDownRiskLevel()
        setColorSelectDropDownRiskAppetite()
        setApplyFlag(false)
        setShowEvalData(false)
        setShowTranferExlButton(false)
        setEditRiskHirachy(false)
    }

    const [databClr, setDatabClr] = useState([]);


    function getColors() {
        axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR + "/all", {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("+++++++++++++++++All colors++++++++++++++++++");
                console.log(response.data);
                setColorPatterns(response.data);

            })
            .catch(function (error) {
                console.log(error);
            });
    }



    function saveRisk() {
        setWaitingOpen(true);
        var data = JSON.stringify(riskTypeDataForEval);
        console.log("++++++++++++++++Saving data++++++++++++++++++++++");
        console.log(data);

        axios(CONFIG.MAIN_URL + '/risk/save', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        })
            .then(function (response) {
                console.log("++++++++++++++Updatd Return+++++++++++");
                console.log(response.data);
                setWaitingOpen(false);
                alert("Risk Updated successfully");
                setEditRiskHirachy(false)
                //setOpenRiskDataEditfunction();
            })
            .catch(function (error) {
                setWaitingOpen(false);
                console.log(error);
                alert("Error saving Risk Type -:" + error);
            });

    }

    const registerTableRowData = (
        <TableBody>
            {allRegister.map((register, index) => {

                return (
                    <StyledTableRow key={index} >
                        <StyledTableCell component="th" scope="row" style={{ width: '90%' }}>
                            <div style={{ whiteSpace: 'normal' }} className='celltxt'>{register.name}</div>
                        </StyledTableCell>

                        <StyledTableCell
                            align="right">
                            <AssessmentOutlinedIcon
                                style={{ cursor: 'pointer' }}
                                onClick={e => setOpenRegisterDataForEvalfunction(true, register.id)}
                            />

                        </StyledTableCell>
                    </StyledTableRow>
                )

            })}
        </TableBody>
    )
    return (
        <>
            <div>
                <div >
                    <Grid item xs={12}>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>

                                <TableContainer
                                    component={Paper}
                                    style={{ maxHeight: maxWidthTable }}

                                >
                                    <TableHead className={classe.commonSecondaryTableHead}>
                                        <TableRow>
                                            <StyledTableCell className='celltxthead' style={{ width: '90%' }}>Registers</StyledTableCell>
                                            <StyledTableCell align="right" className='celltxthead' style={{ width: '10%' }}>Evaluate</StyledTableCell>

                                        </TableRow>
                                    </TableHead>
                                    {registerTableRowData}
                                </TableContainer>

                            </Grid>

                        </Grid>
                    </Grid>
                </div>


                <Dialog
                    open={openRegisterDataForEval}
                    onClose={false}
                    scroll={openRegisterDataForEvalScroll}
                    aria-labelledby="register-data-dialog-title"
                    aria-describedby="register-data-dialog-description"
                    fullWidth={true}
                    maxWidth={'lg'}
                    fullScreen={false}
                >
                    <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
                        <Typography variant="h6" style={{ fontSize: '25px !important' }}>Evaluate Risk Register "{selectedRiskEvaluateTitle}" - <SavePageAs /></Typography>
                        <Typography variant="h6" style={{ marginRight: '-22px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                            <Tooltip1 title="Close"><Close onClick={RegisterDataForEvalfunctionClose} style={{ cursor: 'pointer' }} /></Tooltip1>
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers={openRegisterDataForEvalScroll === 'paper'}>
                        <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {
                                        multipleRoles ?

                                            <EvaluateComponent1
                                                colorPatterns={colorPatterns}
                                                registerTypeDataForEval={riskTypeDataForEval}
                                                openRegisterDataForEvalScroll={openRegisterDataForEvalScroll}
                                                registerEvaluate={true}
                                            />
                                            :
                                            <EvaluateComponent
                                                colorPatterns={colorPatterns}
                                                registerTypeDataForEval={riskTypeDataForEval}
                                                openRegisterDataForEvalScroll={openRegisterDataForEvalScroll}
                                                registerEvaluate={true}
                                            />
                                    }
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={RegisterDataForEvalfunctionClose}>Close</Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={openConfirm}
                    onClose={handleCloseConfirm}>
                    <DialogTitle>
                        Select the Display Method

                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            How do you want to display the Register
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>&nbsp;</Grid>
                            <Grid item xs={5}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    onClick={() => byRoleFuction()}
                                    className='btnFonts'
                                    style={{float: 'right'}}
                                    >
                                    By Role
                                </Button>
                            </Grid>
                            <Grid item xs={7}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    onClick={() => byRiskNameFuction()}
                                    className='btnFonts'
                                    >
                                    By Risk Name
                                </Button>
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseConfirm}>Close</Button>
                    </DialogActions>
                </Dialog>

            </div>
        </>
    )
}

export default RegisterEvaluateComponent2
