import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import axios from 'axios'
import { CONFIG } from '../config'
import { Grid, makeStyles } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ClockLoader } from 'react-spinners';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import useStyle from '../Css/MainCss';

const tableStyle = {
  border: '1px solid #ccc',
  backgroundColor: 'white',
};

const cellStyle = {
  border: '1px solid #ccc',
  padding: '8px',
};


const useStyles = makeStyles((theme) => ({
    right: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    pageTop: {
      marginTop: '115px',
    },
    pageBodyStyle: {
  
      paddingTop: '12px',
    },
  
  }));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const MultitaskingMenuSetup = () => {
  const classesss = useStyle();
    const classes = useStyles();
  const [hintMessage, setHintMessage] = useState('');

  const handleHintMessageChange = (event) => {
    setHintMessage(event.target.value);
  };
  const [centreName, setCentreName] = useState('')

const [selectedCentreMultiData, setSelectedCentreMultiData] = useState({})

  const [mutitaskingData, setMutitaskingData] = React.useState([])

  const [mutitaskingDataId, setMutitaskingDataId] = React.useState('')


  const [radiusTopLeftmain_page, setRadiusTopLeftmain_page] = useState('');
  const [radiusTopRightmain_page, setRadiusTopRightmain_page] = useState('');
  const [radiusBottomLeftmain_page, setRadiusBottomLeftmain_page] = useState('');
  const [rradiusBottomRightmain_page, setRradiusBottomRightmain_page] = useState('');
  const [backgroundclrmain_page, setbackgroundclrmain_page] = useState('');
  const [borderclrmain_page, setborderclrmain_page] = useState('');
  const [headingFClrmain_page, setheadingFClrmain_page] = useState('');
  const [helpTextmain_page, sethelpTextmain_page] = useState('');

  const [radiusTopLeftrisk_collection, setRadiusTopLeftrisk_collection] = useState('');
  const [radiusTopRightrisk_collection, setRadiusTopRightrisk_collection] = useState('');
  const [radiusBottomLeftrisk_collection, setRadiusBottomLeftrisk_collection] = useState('');
  const [rradiusBottomRightrisk_collection, setRradiusBottomRightrisk_collection] = useState('');
  const [backgroundclrrisk_collection, setbackgroundclrrisk_collection] = useState('');
  const [borderclrrisk_collection, setborderclrrisk_collection] = useState('');
  const [headingFClrrisk_collection, setheadingFClrrisk_collection] = useState('');
  const [helpTextrisk_collection, sethelpTextrisk_collection] = useState('');

  const [radiusTopLeftquestionnaire_collections, setRadiusTopLeftquestionnaire_collections] = useState('');
  const [radiusTopRightquestionnaire_collections, setRadiusTopRightquestionnaire_collections] = useState('');
  const [radiusBottomLeftquestionnaire_collections, setRadiusBottomLeftquestionnaire_collections] = useState('');
  const [rradiusBottomRightquestionnaire_collections, setRradiusBottomRightquestionnaire_collections] = useState('');
  const [backgroundclrquestionnaire_collections, setbackgroundclrquestionnaire_collections] = useState('');
  const [borderclrquestionnaire_collections, setborderclrquestionnaire_collections] = useState('');
  const [headingFClrquestionnaire_collections, setheadingFClrquestionnaire_collections] = useState('');
  const [helpTextquestionnaire_collections, sethelpTextquestionnaire_collections] = useState('');

  const [radiusTopLeftglobal_standards, setRadiusTopLeftglobal_standards] = useState('');
  const [radiusTopRightglobal_standards, setRadiusTopRightglobal_standards] = useState('');
  const [radiusBottomLeftglobal_standards, setRadiusBottomLeftglobal_standards] = useState('');
  const [rradiusBottomRightglobal_standards, setRradiusBottomRightglobal_standards] = useState('');
  const [backgroundclglobal_standards, setbackgroundclrglobal_standards] = useState('');
  const [borderclrglobal_standards, setborderclrglobal_standards] = useState('');
  const [headingFClrglobal_standards, setheadingFClrglobal_standards] = useState('');
  const [helpTextglobal_standards, sethelpTextglobal_standards] = useState('');




  const [radiusTopLeftcustom_standards, setRadiusTopLeftcustom_standards] = useState('');
  const [radiusTopRightcustom_standards, setRadiusTopRightcustom_standards] = useState('');
  const [radiusBottomLeftcustom_standards, setRadiusBottomLeftcustom_standards] = useState('');
  const [rradiusBottomRightcustom_standards, setRradiusBottomRightcustom_standards] = useState('');
  const [backgroundclrcustom_standards, setbackgroundclrcustom_standards] = useState('');
  const [borderclrcustom_standards, setborderclrcustom_standards] = useState('');
  const [headingFClrcustom_standards, setheadingFClrcustom_standards] = useState('');
  const [helpTextcustom_standards, sethelpTextcustom_standards] = useState('');

  const [radiusTopLeftrisk_hierarchy, setRadiusTopLeftrisk_hierarchy] = useState('');
  const [radiusTopRightrisk_hierarchy, setRadiusTopRightrisk_hierarchy] = useState('');
  const [radiusBottomLeftrisk_hierarchy, setRadiusBottomLeftrisk_hierarchy] = useState('');
  const [rradiusBottomRightrisk_hierarchy, setRradiusBottomRightrisk_hierarchy] = useState('');
  const [backgroundclrrisk_hierarchy, setbackgroundclrrisk_hierarchy] = useState('');
  const [borderclrrisk_chierarchy ,setborderclrrisk_hierarchy] = useState('');
  const [headingFClrrisk_hierarchy, setheadingFClrrisk_hierarchy] = useState('');
  const [helpTextrisk_hierarchy, sethelpTextrisk_hierarchy] = useState('');

  const [radiusTopLeftcentre_maintenance, setRadiusTopLeftcentre_maintenance] = useState('');
  const [radiusTopRightcentre_maintenance, setRadiusTopRightcentre_maintenance] = useState('');
  const [radiusBottomLeftcentre_maintenance, setRadiusBottomLeftcentre_maintenance] = useState('');
  const [rradiusBottomRightcentre_maintenance, setRradiusBottomRightcentre_maintenance] = useState('');
  const [backgroundclrcentre_maintenance, setbackgroundclrcentre_maintenance] = useState('');
  const [borderclrcentre_maintenance, setborderclrcentre_maintenance] = useState('');
  const [headingFClrcentre_maintenance, setheadingFClrcentre_maintenance] = useState('');
  const [helpTextcentre_maintenance, sethelpTextcentre_maintenance] = useState('');

  const [radiusTopLeftrisk_slider_methods, setRadiusTopLeftrisk_slider_methods] = useState('');
  const [radiusTopRightrisk_slider_methods, setRadiusTopRightrisk_slider_methods] = useState('');
  const [radiusBottomLeftrisk_slider_methods, setRadiusBottomLeftrisk_slider_methods] = useState('');
  const [rradiusBottomRightrisk_slider_methods, setRradiusBottomRightrisk_slider_methods] = useState('');
  const [backgroundclrisk_slider_methods, setbackgroundclrrisk_slider_methods] = useState('');
  const [borderclrrisk_slider_methods, setborderclrrisk_slider_methods] = useState('');
  const [headingFClrrisk_slider_methods, setheadingFClrgrisk_slider_methods] = useState('');
  const [helpTextrisk_slider_methods, sethelpTextrisk_slider_methods] = useState('');



  ///

  const [radiusTopLeftRSC, setRadiusTopLeftRSC] = useState('');
  const [radiusTopRightRSC, setRadiusTopRightRSC] = useState('');
  const [radiusBottomLeftRSC, setRadiusBottomLeftRSC] = useState('');
  const [rradiusBottomRightRSC, setRradiusBottomRightRSC] = useState('');
  const [backgroundclRSC, setbackgroundclrRSC] = useState('');
  const [borderclrRSC, setborderclrRSC] = useState('');
  const [headingFClrRSC, setheadingFClrgRSC] = useState('');
  const [helpTextRSC, sethelpTextRSC] = useState('');

  const [radiusTopLeftTQC, setRadiusTopLeftTQC] = useState('');
  const [radiusTopRightTQC, setRadiusTopRightTQC] = useState('');
  const [radiusBottomLeftTQC, setRadiusBottomLeftTQC] = useState('');
  const [rradiusBottomRightTQC, setRradiusBottomRightTQC] = useState('');
  const [backgroundclTQC, setbackgroundclrTQC] = useState('');
  const [borderclrTQC, setborderclrTQC] = useState('');
  const [headingFClrTQC, setheadingFClrgTQC] = useState('');
  const [helpTextTQC, sethelpTextTQC] = useState('');

  const [radiusTopLeftCEQ, setRadiusTopLeftCEQ] = useState('');
  const [radiusTopRightCEQ, setRadiusTopRightCEQ] = useState('');
  const [radiusBottomLeftCEQ, setRadiusBottomLeftCEQ] = useState('');
  const [rradiusBottomRightCEQ, setRradiusBottomRightCEQ] = useState('');
  const [backgroundclCEQ, setbackgroundclrCEQ] = useState('');
  const [borderclrCEQ, setborderclrCEQ] = useState('');
  const [headingFClrCEQ, setheadingFClrgCEQ] = useState('');
  const [helpTextCEQ, sethelpTextCEQ] = useState('');

  const [radiusTopLeftCEAS, setRadiusTopLeftCEAS] = useState('');
  const [radiusTopRightCEAS, setRadiusTopRightCEAS] = useState('');
  const [radiusBottomLeftCEAS, setRadiusBottomLeftCEAS] = useState('');
  const [rradiusBottomRightCEAS, setRradiusBottomRightCEAS] = useState('');
  const [backgroundclCEAS, setbackgroundclrCEAS] = useState('');
  const [borderclrCEAS, setborderclrCEAS] = useState('');
  const [headingFClrCEAS, setheadingFClrgCEAS] = useState('');
  const [helpTextCEAS, sethelpTextCEAS] = useState('');

  const [radiusTopLeftER, setRadiusTopLeftER] = useState('');
  const [radiusTopRightER, setRadiusTopRightER] = useState('');
  const [radiusBottomLeftER, setRadiusBottomLeftER] = useState('');
  const [rradiusBottomRightER, setRradiusBottomRightER] = useState('');
  const [backgroundclER, setbackgroundclrER] = useState('');
  const [borderclrER, setborderclrER] = useState('');
  const [headingFClrER, setheadingFClrgER] = useState('');
  const [helpTextER, sethelpTextER] = useState('');


  const [loading1, setLoading1] = React.useState(false);




  React.useEffect(() => {
    getAllCentresFromServiceSystem()
    getmultitaskingMenuData()

}, [])

const [chkSelectAllRadiusTopLeft ,setchkSelectAllRadiusTopLeft] = React.useState(false);

function getchkSelectAllRadiusTopLeft() {
  setchkSelectAllRadiusTopLeft(!chkSelectAllRadiusTopLeft);
}

const [chkSelectAllRadiusTopRight ,setchkSelectAllRadiusTopRight] = React.useState(false);

function getchkSelectAllRadiusTopRight() {
  setchkSelectAllRadiusTopRight(!chkSelectAllRadiusTopRight);
}

const [chkSelectAllRadiusBottomLeft ,setchkSelectAllRadiusBottomLeft] = React.useState(false);

function getchkSelectAllRadiusBottomLeft() {
  setchkSelectAllRadiusBottomLeft(!chkSelectAllRadiusBottomLeft);
}

const [chkSelectAllRadiusBottomRight ,setchkSelectAllRadiusBottomRight] = React.useState(false);

function getchkSelectAllRadiusBottomRight() {
  setchkSelectAllRadiusBottomRight(!chkSelectAllRadiusBottomRight);
}

const [chkSelectAllBgColor ,setchkSelectAllBgColor] = React.useState(false);

function getchkSelectAllBgColor() {
  setchkSelectAllBgColor(!chkSelectAllBgColor);
}

const [chkSelectAllBorderColor ,setchkSelectAllBorderColor] = React.useState(false);

function getchkSelectAllBorderColor() {
  setchkSelectAllBorderColor(!chkSelectAllBorderColor);
}

const [chkSelectAllHeadingColor ,setchkSelectAllHeadingColor] = React.useState(false);

function getchkSelectAllHeadingColor() {
  setchkSelectAllHeadingColor(!chkSelectAllHeadingColor);
}


function getmultitaskingMenuData(){

axios(CONFIG.MAIN_URL + `/multitaskPageSetup/all`, {
    method: 'get',
    headers: {
      'Content-Type': 'text/plain',
    },
  })
    .then(function (response) {
      console.log("+++++++++++++++++All colors++++++++++++++++++");
      console.log(response.data);
      setMutitaskingData(response.data);


    })
    .catch(function (error) {
      console.log(error);
    });


}

const handleChangeCentre = (event) => {
  clearFuction();
    setCentreName(event.target.value);
  
    mutitaskingData.map((data)=> {
        if (data.centreId === event.target.value) {

            console.log(data)
            setMutitaskingDataId(data.id)

            setSelectedCentreMultiData(data);
            setsavebutton('update')

            var rL = true;
            const bef = data.multitaskTables[0].radiusTopLeft;
      
            for (let i = 1; i < data.multitaskTables.length; i++) {
              if (data.multitaskTables[i].radiusTopLeft !== bef) {
                rL = false;
                break;
              }
            }
      
            if (rL) {
              setchkSelectAllRadiusTopLeft(true);
            }

            var rL1 = true;
            const bef1 = data.multitaskTables[0].radiusTopRight; 
              for (let i = 1; i < data.multitaskTables.length; i++) {
                if (data.multitaskTables[i].radiusTopRight != bef1) {
                  rL1 = false;
                  break; 
                }
              }

              if (rL1) {
                setchkSelectAllRadiusTopRight(true);
              }

              var rL2 = true;
              const bef2 = data.multitaskTables[0].radiusBottomLeft; 
              for (let i = 1; i < data.multitaskTables.length; i++) {
                if (data.multitaskTables[i].radiusBottomLeft != bef2) {
                  rL2 = false;
                  break; 
                }
              }
              if (rL2) {
                setchkSelectAllRadiusBottomLeft(true);
              }

              var rL3 = true;
              const bef3 = data.multitaskTables[0].radiusBottomRight; 
              for (let i = 1; i < data.multitaskTables.length; i++) {
                if (data.multitaskTables[i].radiusBottomRight != bef3) {
                  rL3 = false;

                  break; 
                }
              }
              if (rL3) {
                setchkSelectAllRadiusBottomRight(true)
              }


              var rL4 = true;
              const bef4 = data.multitaskTables[0].backgroundColor; 
              for (let i = 1; i < data.multitaskTables.length; i++) {
                if (data.multitaskTables[i].backgroundColor != bef4) {
                  rL4 = false;

                  break; 
                }
              }

              if (rL4) {
                setchkSelectAllBgColor(true)
              }

              var rL5 = true;
              const bef5 = data.multitaskTables[0].borderColor; 
              for (let i = 1; i < data.multitaskTables.length; i++) {
                if (data.multitaskTables[i].borderColor != bef5) {
                  rL5 = false;

                  break; 
                }
              }
              if (rL5) {
                setchkSelectAllBorderColor(true)
              }

              var rL6 = true;
              const bef6 = data.multitaskTables[0].mainHeaderFontColor; 
              for (let i = 1; i < data.multitaskTables.length; i++) {
                if (data.multitaskTables[i].mainHeaderFontColor != bef6) {
                  rL6 = false;

                  break; 
                }
              }
              if (rL6) {
                setchkSelectAllHeadingColor(true)
              }

            data.multitaskTables.map((multi)=> {
                if (multi.pageId === 'main_page') {
                    setRadiusTopLeftmain_page(multi.radiusTopLeft)
                    setRadiusTopRightmain_page(multi.radiusTopRight)
                    setRadiusBottomLeftmain_page(multi.radiusBottomLeft)
                    setRradiusBottomRightmain_page(multi.radiusBottomRight)
                    setbackgroundclrmain_page(multi.backgroundColor)
                    setborderclrmain_page(multi.borderColor)
                    setheadingFClrmain_page(multi.mainHeaderFontColor)
                    sethelpTextmain_page(multi.helpText)

                }
                 if (multi.pageId === 'risk_collection') {
                    setRadiusTopLeftrisk_collection(multi.radiusTopLeft)
                    setRadiusTopRightrisk_collection(multi.radiusTopRight)
                    setRadiusBottomLeftrisk_collection(multi.radiusBottomLeft)
                    setRradiusBottomRightrisk_collection(multi.radiusBottomRight)
                    setbackgroundclrrisk_collection(multi.backgroundColor)
                    setborderclrrisk_collection(multi.borderColor)
                    setheadingFClrrisk_collection(multi.mainHeaderFontColor)
                    sethelpTextrisk_collection(multi.helpText)
                }
                if (multi.pageId === 'questionnaire_collections') {
                  setRadiusTopLeftquestionnaire_collections(multi.radiusTopLeft)
                  setRadiusTopRightquestionnaire_collections(multi.radiusTopRight)
                  setRadiusBottomLeftquestionnaire_collections(multi.radiusBottomLeft)
                  setRradiusBottomRightquestionnaire_collections(multi.radiusBottomRight)
                  setbackgroundclrquestionnaire_collections(multi.backgroundColor)
                  setborderclrquestionnaire_collections(multi.borderColor)
                  setheadingFClrquestionnaire_collections(multi.mainHeaderFontColor)
                  sethelpTextquestionnaire_collections(multi.helpText)
              }

              if (multi.pageId === 'global_standards') {
                setRadiusTopLeftglobal_standards(multi.radiusTopLeft)
                setRadiusTopRightglobal_standards(multi.radiusTopRight)
                setRadiusBottomLeftglobal_standards(multi.radiusBottomLeft)
                setRradiusBottomRightglobal_standards(multi.radiusBottomRight)
                setbackgroundclrglobal_standards(multi.backgroundColor)
                setborderclrglobal_standards(multi.borderColor)
                setheadingFClrglobal_standards(multi.mainHeaderFontColor)
                sethelpTextglobal_standards(multi.helpText)
            }

            if (multi.pageId === 'custom_standards') {
              setRadiusTopLeftcustom_standards(multi.radiusTopLeft)
              setRadiusTopRightcustom_standards(multi.radiusTopRight)
              setRadiusBottomLeftcustom_standards(multi.radiusBottomLeft)
              setRradiusBottomRightcustom_standards(multi.radiusBottomRight)
              setbackgroundclrcustom_standards(multi.backgroundColor)
              setborderclrcustom_standards(multi.borderColor)
              setheadingFClrcustom_standards(multi.mainHeaderFontColor)
              sethelpTextcustom_standards(multi.helpText)
          }

                if (multi.pageId === 'risk_hierarchy') {
                  setRadiusTopLeftrisk_hierarchy(multi.radiusTopLeft)
                  setRadiusTopRightrisk_hierarchy(multi.radiusTopRight)
                  setRadiusBottomLeftrisk_hierarchy(multi.radiusBottomLeft)
                  setRradiusBottomRightrisk_hierarchy(multi.radiusBottomRight)
                  setbackgroundclrrisk_hierarchy(multi.backgroundColor)
                  setborderclrrisk_hierarchy(multi.borderColor)
                  setheadingFClrrisk_hierarchy(multi.mainHeaderFontColor)
                  sethelpTextrisk_hierarchy(multi.helpText)
              }

              if (multi.pageId === 'centre_maintenance') {
                setRadiusTopLeftcentre_maintenance(multi.radiusTopLeft)
                setRadiusTopRightcentre_maintenance(multi.radiusTopRight)
                setRadiusBottomLeftcentre_maintenance(multi.radiusBottomLeft)
                setRradiusBottomRightcentre_maintenance(multi.radiusBottomRight)
                setbackgroundclrcentre_maintenance(multi.backgroundColor)
                setborderclrcentre_maintenance(multi.borderColor)
                setheadingFClrcentre_maintenance(multi.mainHeaderFontColor)
                sethelpTextcentre_maintenance(multi.helpText)
            }         
            if (multi.pageId === 'risk_slider_methods') {
              setRadiusTopLeftrisk_slider_methods(multi.radiusTopLeft)
              setRadiusTopRightrisk_slider_methods(multi.radiusTopRight)
              setRadiusBottomLeftrisk_slider_methods(multi.radiusBottomLeft)
              setRradiusBottomRightrisk_slider_methods(multi.radiusBottomRight)
              setbackgroundclrrisk_slider_methods(multi.backgroundColor)
              setborderclrrisk_slider_methods(multi.borderColor)
              setheadingFClrgrisk_slider_methods(multi.mainHeaderFontColor)
              sethelpTextrisk_slider_methods(multi.helpText)
          }  
          if (multi.pageId === 'risk_slider_colors') {
            setRadiusTopLeftRSC(multi.radiusTopLeft)
            setRadiusTopRightRSC(multi.radiusTopRight)
            setRadiusBottomLeftRSC(multi.radiusBottomLeft)
            setRradiusBottomRightRSC(multi.radiusBottomRight)
            setbackgroundclrRSC(multi.backgroundColor)
            setborderclrRSC(multi.borderColor)
            setheadingFClrgRSC(multi.mainHeaderFontColor)
            sethelpTextRSC(multi.helpText)
        }  
        if (multi.pageId === 'transfer_questionnaire_collection') {
          setRadiusTopLeftTQC(multi.radiusTopLeft)
          setRadiusTopRightTQC(multi.radiusTopRight)
          setRadiusBottomLeftTQC(multi.radiusBottomLeft)
          setRradiusBottomRightTQC(multi.radiusBottomRight)
          setbackgroundclrTQC(multi.backgroundColor)
          setborderclrTQC(multi.borderColor)
          setheadingFClrgTQC(multi.mainHeaderFontColor)
          sethelpTextTQC(multi.helpText)
      }  
      if (multi.pageId === 'create_edit_question') {
        setRadiusTopLeftCEQ(multi.radiusTopLeft)
        setRadiusTopRightCEQ(multi.radiusTopRight)
        setRadiusBottomLeftCEQ(multi.radiusBottomLeft)
        setRradiusBottomRightCEQ(multi.radiusBottomRight)
        setbackgroundclrCEQ(multi.backgroundColor)
        setborderclrCEQ(multi.borderColor)
        setheadingFClrgCEQ(multi.mainHeaderFontColor)
        sethelpTextCEQ(multi.helpText)
      }  
      if (multi.pageId === 'create_edit_answer_scale') {
        setRadiusTopLeftCEAS(multi.radiusTopLeft)
        setRadiusTopRightCEAS(multi.radiusTopRight)
        setRadiusBottomLeftCEAS(multi.radiusBottomLeft)
        setRradiusBottomRightCEAS(multi.radiusBottomRight)
        setbackgroundclrCEAS(multi.backgroundColor)
        setborderclrCEAS(multi.borderColor)
        setheadingFClrgCEAS(multi.mainHeaderFontColor)
        sethelpTextCEAS(multi.helpText)
      } 
      if (multi.pageId === 'evaluate_risk') {
        setRadiusTopLeftER(multi.radiusTopLeft)
        setRadiusTopRightER(multi.radiusTopRight)
        setRadiusBottomLeftER(multi.radiusBottomLeft)
        setRradiusBottomRightER(multi.radiusBottomRight)
        setbackgroundclrER(multi.backgroundColor)
        setborderclrER(multi.borderColor)
        setheadingFClrgER(multi.mainHeaderFontColor)
        sethelpTextER(multi.helpText)
      } 

            })

        }
    })
  
  };




  const [allCentres, setAllCentres] = React.useState([]);


  function getAllCentresFromServiceSystem() {
    var data = '{getAllCentreList{centreId,centreName}}';
    axios(CONFIG.MAIN_URL + '/graphql', {
        method: 'post',
        headers: {
            'Content-Type': 'text/plain',
        },
        data: data,
    })
        .then(function (response) {
            var centreList = response.data.data.getAllCentreList;
            console.log("All Centre List");
            console.log(centreList);
            setAllCentres(centreList);
        })
        .catch(function (error) {
            console.log("Entity Loding error" + error);
        });
}



const [moreDetailOpen, setMoreDetailOpen] = React.useState(false);
const [moreDetailScroll, setMoreDetailScroll] = React.useState('paper');
const [savebutton, setsavebutton] = React.useState('save');

const moreDetailHandleClickClose = () => {
    setMoreDetailOpen(false);
  };

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }





  const radiusTopLeftmain_pageChange = (event) => {
    setRadiusTopLeftmain_page(event.target.value);
    if (chkSelectAllRadiusTopLeft === true) {
        setRadiusTopLeftrisk_collection(event.target.value)
        setRadiusTopLeftquestionnaire_collections(event.target.value)
        setRadiusTopLeftglobal_standards(event.target.value)
        setRadiusTopLeftcustom_standards(event.target.value)
        setRadiusTopLeftrisk_hierarchy(event.target.value)
        setRadiusTopLeftcentre_maintenance(event.target.value)
        setRadiusTopLeftrisk_slider_methods(event.target.value)
        setRadiusTopLeftRSC(event.target.value)
        setRadiusTopLeftTQC(event.target.value)
        setRadiusTopLeftCEQ(event.target.value)
        setRadiusTopLeftCEAS(event.target.value)
        setRadiusTopLeftER(event.target.value)

    }
  };

  const radiusTopRightmain_pageChange = (event) => {
    setRadiusTopRightmain_page(event.target.value);
    if (chkSelectAllRadiusTopRight === true) {
      setRadiusTopRightrisk_collection(event.target.value)
      setRadiusTopRightquestionnaire_collections(event.target.value)
      setRadiusTopRightglobal_standards(event.target.value)
      setRadiusTopRightcustom_standards(event.target.value)
      setRadiusTopRightrisk_hierarchy(event.target.value)
      setRadiusTopRightcentre_maintenance(event.target.value)
      setRadiusTopRightrisk_slider_methods(event.target.value)
      setRadiusTopRightRSC(event.target.value)
      setRadiusTopRightTQC(event.target.value)
      setRadiusTopRightCEQ(event.target.value)
      setRadiusTopRightCEAS(event.target.value)
      setRadiusTopRightER(event.target.value)

  }
  };

  const radiusBottomLeftmain_pageChange = (event) => {
    setRadiusBottomLeftmain_page(event.target.value);
    if (chkSelectAllRadiusBottomLeft === true) {
      setRadiusBottomLeftrisk_collection(event.target.value)
      setRadiusBottomLeftquestionnaire_collections(event.target.value)
      setRadiusBottomLeftglobal_standards(event.target.value)
      setRadiusBottomLeftcustom_standards(event.target.value)
      setRadiusBottomLeftrisk_hierarchy(event.target.value)
      setRadiusBottomLeftcentre_maintenance(event.target.value)
      setRadiusBottomLeftrisk_slider_methods(event.target.value)
      setRadiusBottomLeftRSC(event.target.value)
      setRadiusBottomLeftTQC(event.target.value)
      setRadiusBottomLeftCEQ(event.target.value)
      setRadiusBottomLeftCEAS(event.target.value)
      setRadiusBottomLeftER(event.target.value)

  }
  };

  const radiusBottomRightmain_pageChange = (event) => {
    setRradiusBottomRightmain_page(event.target.value);
    if (chkSelectAllRadiusBottomRight === true) {
      setRradiusBottomRightrisk_collection(event.target.value)
      setRradiusBottomRightquestionnaire_collections(event.target.value)
      setRradiusBottomRightglobal_standards(event.target.value)
      setRradiusBottomRightcustom_standards(event.target.value)
      setRradiusBottomRightrisk_hierarchy(event.target.value)
      setRradiusBottomRightcentre_maintenance(event.target.value)
      setRradiusBottomRightrisk_slider_methods(event.target.value)
      setRradiusBottomRightRSC(event.target.value)
      setRradiusBottomRightTQC(event.target.value)
      setRradiusBottomRightCEQ(event.target.value)
      setRradiusBottomRightCEAS(event.target.value)
      setRradiusBottomRightER(event.target.value)

  }
  };

  const backgroundclrmain_pageChange = (event) => {
    setbackgroundclrmain_page(event.target.value);
    if (chkSelectAllBgColor === true) {
      setbackgroundclrrisk_collection(event.target.value)
      setbackgroundclrquestionnaire_collections(event.target.value)
      setbackgroundclrglobal_standards(event.target.value)
      setbackgroundclrcustom_standards(event.target.value)
      setbackgroundclrrisk_hierarchy(event.target.value)
      setbackgroundclrcentre_maintenance(event.target.value)
      setbackgroundclrrisk_slider_methods(event.target.value)
      setbackgroundclrRSC(event.target.value)
      setbackgroundclrTQC(event.target.value)
      setbackgroundclrCEQ(event.target.value)
      setbackgroundclrCEAS(event.target.value)
      setbackgroundclrER(event.target.value)

  }
  };

  const borderclrmain_pageChange = (event) => {
    setborderclrmain_page(event.target.value);
    if (chkSelectAllBorderColor === true) {
      setborderclrrisk_collection(event.target.value)
      setborderclrquestionnaire_collections(event.target.value)
      setborderclrglobal_standards(event.target.value)
      setborderclrcustom_standards(event.target.value)
      setborderclrrisk_hierarchy(event.target.value)
      setborderclrcentre_maintenance(event.target.value)
      setborderclrrisk_slider_methods(event.target.value)
      setborderclrRSC(event.target.value)
      setborderclrTQC(event.target.value)
      setborderclrCEQ(event.target.value)
      setborderclrCEAS(event.target.value)
      setborderclrER(event.target.value)

  }
  };

  const headingFClrmain_pageChange = (event) => {
    setheadingFClrmain_page(event.target.value);
    if (chkSelectAllHeadingColor === true) {
      setheadingFClrrisk_collection(event.target.value)
      setheadingFClrquestionnaire_collections(event.target.value)
      setheadingFClrglobal_standards(event.target.value)
      setheadingFClrcustom_standards(event.target.value)
      setheadingFClrrisk_hierarchy(event.target.value)
      setheadingFClrcentre_maintenance(event.target.value)
      setheadingFClrgrisk_slider_methods(event.target.value)
      setheadingFClrgRSC(event.target.value)
      setheadingFClrgTQC(event.target.value)
      setheadingFClrgCEQ(event.target.value)
      setheadingFClrgCEAS(event.target.value)
      setheadingFClrgER(event.target.value)

  }
  };
  

  const helpTextmain_pageChange = (event) => {
    sethelpTextmain_page(event.target.value);
  };

  const radiusTopLeftrisk_collectionChange = (event) => {
    setRadiusTopLeftrisk_collection(event.target.value);

  };

  const radiusTopRightrisk_collectionChange = (event) => {
    setRadiusTopRightrisk_collection(event.target.value);
  };

  const radiusBottomLeftrisk_collectioneChange = (event) => {
    setRadiusBottomLeftrisk_collection(event.target.value);
  };

  const radiusBottomRightrisk_collectionChange = (event) => {
    setRradiusBottomRightrisk_collection(event.target.value);
  };

  const backgroundclrrisk_collectioneChange = (event) => {
    setbackgroundclrrisk_collection(event.target.value);
  };

  const borderclrrisk_collectionChange = (event) => {
    setborderclrrisk_collection(event.target.value);
  };

  const headingFClrrisk_collectionChange = (event) => {
    setheadingFClrrisk_collection(event.target.value);
  };

  const helpTextrisk_collectionChange = (event) => {
    sethelpTextrisk_collection(event.target.value);
  };

  const radiusTopLeftquestionnaire_collectionsChange = (event) => {
    setRadiusTopLeftquestionnaire_collections(event.target.value);
  };

  const radiusTopRightquestionnaire_collectionsChange = (event) => {
    setRadiusTopRightquestionnaire_collections(event.target.value);
  };

  const radiusBottomLeftquestionnaire_collectionsChange = (event) => {
    setRadiusBottomLeftquestionnaire_collections(event.target.value);
  };

  const rradiusBottomRightquestionnaire_collectionsChange = (event) => {
    setRradiusBottomRightquestionnaire_collections(event.target.value);
  };

  const backgroundclrquestionnaire_collectionsChange = (event) => {
    setbackgroundclrquestionnaire_collections(event.target.value);
  };

  const borderclrquestionnaire_collectionsChange = (event) => {
    setborderclrquestionnaire_collections(event.target.value);
  };

  const headingFClrquestionnaire_collectionsChange = (event) => {
    setheadingFClrquestionnaire_collections(event.target.value);
  };
  const helpTextquestionnaire_collectionsChange = (event) => {
    sethelpTextquestionnaire_collections(event.target.value);
  };

  const radiusTopLeftglobal_standardsChange = (event) => {
    setRadiusTopLeftglobal_standards(event.target.value);
  };

  const radiusTopRightglobal_standardsChange = (event) => {
    setRadiusTopRightglobal_standards(event.target.value);
  };

  const radiusBottomLeftglobal_standardsChange = (event) => {
    setRadiusBottomLeftglobal_standards(event.target.value);
  };

  const radiusBottomRightglobal_standardsChange = (event) => {
    setRradiusBottomRightglobal_standards(event.target.value);
  };

  const backgroundclglobal_standardsChange = (event) => {
    setbackgroundclrglobal_standards(event.target.value);
  };

  const borderclrglobal_standardsChange = (event) => {
    setborderclrglobal_standards(event.target.value);
  };

  const headingFClrglobal_standardsChange = (event) => {
    setheadingFClrglobal_standards(event.target.value);
  };

  const helpTextglobal_standardsChange = (event) => {
    sethelpTextglobal_standards(event.target.value);
  };

  
  const radiusTopLeftcustom_standardsChange = (event) => {
    setRadiusTopLeftcustom_standards(event.target.value);
  };

  const radiusTopRightcustom_standardsChange = (event) => {
    setRadiusTopRightcustom_standards(event.target.value);
  };

  const radiusBottomLeftcustom_standardsChange = (event) => {
    setRadiusBottomLeftcustom_standards(event.target.value);
  };

  const radiusBottomRightcustom_standardsChange = (event) => {
    setRradiusBottomRightcustom_standards(event.target.value);
  };

  const backgroundclrcustom_standardsChange = (event) => {
    setbackgroundclrcustom_standards(event.target.value);
  };

  const borderclrcustom_standardsChange = (event) => {
    setborderclrcustom_standards(event.target.value);
  };

  const headingFClrcustom_standardsChange = (event) => {
    setheadingFClrcustom_standards(event.target.value);
  };

  const helpTextcustom_standardsChange = (event) => {
    sethelpTextcustom_standards(event.target.value);
  };

  const radiusTopLeftrhChange = (event) => {
    setRadiusTopLeftrisk_hierarchy(event.target.value);
  };

  const radiusTopRightrhChange = (event) => {
    setRadiusTopRightrisk_hierarchy(event.target.value);
  };

  const radiusBottomLeftrhChange = (event) => {
    setRadiusBottomLeftrisk_hierarchy(event.target.value);
  };

  const radiusBottomRightrhChange = (event) => {
    setRradiusBottomRightrisk_hierarchy(event.target.value);
  };

  const backgroundclrrhChange = (event) => {
    setbackgroundclrrisk_hierarchy(event.target.value);
  };

  const borderclrrhChange = (event) => {
    setborderclrrisk_hierarchy(event.target.value);
  };

  const headingFClrrhChange = (event) => {
    setheadingFClrrisk_hierarchy(event.target.value);
  };

  const helpTextrh = (event) => {
    sethelpTextrisk_hierarchy(event.target.value);
  };

  


  const radiusTopLeftcmChange = (event) => {
    setRadiusTopLeftcentre_maintenance(event.target.value);
  };

  const radiusTopRightcmChange = (event) => {
    setRadiusTopRightcentre_maintenance(event.target.value);
  };

  const radiusBottomLeftcmChange = (event) => {
    setRadiusBottomLeftcentre_maintenance(event.target.value);
  };

  const radiusBottomRightcmChange = (event) => {
    setRradiusBottomRightcentre_maintenance(event.target.value);
  };

  const backgroundclrcmChange = (event) => {
    setbackgroundclrcentre_maintenance(event.target.value);
  };

  const borderclrcmChange = (event) => {
    setborderclrcentre_maintenance(event.target.value);
  };

  const headingFClrcmChange = (event) => {
    setheadingFClrcentre_maintenance(event.target.value);
  };

  const helpTextcm = (event) => {
    sethelpTextcentre_maintenance(event.target.value);
  };





  const radiusTopLeftRSMChange = (event) => {
    setRadiusTopLeftrisk_slider_methods(event.target.value);
  };

  const radiusTopRightRSMChange = (event) => {
    setRadiusTopRightrisk_slider_methods(event.target.value);
  };

  const radiusBottomLeftRSMChange = (event) => {
    setRadiusBottomLeftrisk_slider_methods(event.target.value);
  };

  const radiusBottomRightRSMChange = (event) => {
    setRradiusBottomRightrisk_slider_methods(event.target.value);
  };

  const backgroundclrRSMChange = (event) => {
    setbackgroundclrrisk_slider_methods(event.target.value);
  };

  const borderclrRSMChange = (event) => {
    setborderclrrisk_slider_methods(event.target.value);
  };

  const headingFClrRSMChange = (event) => {
    setheadingFClrgrisk_slider_methods(event.target.value);
  };

  const helpTextRSM = (event) => {
    sethelpTextrisk_slider_methods(event.target.value);
  };
  //


  const radiusTopLeftRSCChange = (event) => {
    setRadiusTopLeftRSC(event.target.value);
  };

  const radiusTopRightRSCChange = (event) => {
    setRadiusTopRightRSC(event.target.value);
  };

  const radiusBottomLeftRSCChange = (event) => {
    setRadiusBottomLeftRSC(event.target.value);
  };

  const radiusBottomRightRSCChange = (event) => {
    setRradiusBottomRightRSC(event.target.value);
  };

  const backgroundclrRSCChange = (event) => {
    setbackgroundclrRSC(event.target.value);
  };

  const borderclrRSCChange = (event) => {
    setborderclrRSC(event.target.value);
  };

  const headingFClrRSCChange = (event) => {
    setheadingFClrgRSC(event.target.value);
  };

  const helpTextRSCChange = (event) => {
    sethelpTextRSC(event.target.value);
  };

  //
  const radiusTopLeftTQCChange = (event) => {
    setRadiusTopLeftTQC(event.target.value);
  };

  const radiusTopRightTQCChange = (event) => {
    setRadiusTopRightTQC(event.target.value);
  };

  const radiusBottomLeftTQCChange = (event) => {
    setRadiusBottomLeftTQC(event.target.value);
  };

  const radiusBottomRightTQCChange = (event) => {
    setRradiusBottomRightTQC(event.target.value);
  };

  const backgroundclrTQCChange = (event) => {
    setbackgroundclrTQC(event.target.value);
  };

  const borderclrTQCChange = (event) => {
    setborderclrTQC(event.target.value);
  };

  const headingFClrTQCChange = (event) => {
    setheadingFClrgTQC  (event.target.value);
  };

  const helpTextTQCChange= (event) => {
    sethelpTextTQC(event.target.value);
  };//



  const radiusTopLeftCEQChange = (event) => {
    setRadiusTopLeftCEQ(event.target.value);
  };

  const radiusTopRightCEQChange = (event) => {
    setRadiusTopRightCEQ(event.target.value);
  };

  const radiusBottomLeftCEQChange = (event) => {
    setRadiusBottomLeftCEQ(event.target.value);
  };

  const radiusBottomRightCEQChange = (event) => {
    setRradiusBottomRightCEQ(event.target.value);
  };

  const backgroundclrCEQChange = (event) => {
    setbackgroundclrCEQ(event.target.value);
  };

  const borderclrCEQChange = (event) => {
    setborderclrCEQ(event.target.value);
  };

  const headingFClrCEQChange = (event) => {
    setheadingFClrgCEQ(event.target.value);
  };

  const helpTextCEQChange = (event) => {
    sethelpTextCEQ(event.target.value);
  };



  //1
  const radiusTopLeftCEASChange = (event) => {
    setRadiusTopLeftCEAS(event.target.value);
  };

  const radiusTopRightCEASChange = (event) => {
    setRadiusTopRightCEAS(event.target.value);
  };

  const radiusBottomLeftCEASChange = (event) => {
    setRadiusBottomLeftCEAS(event.target.value);
  };

  const radiusBottomRightCEASChange = (event) => {
    setRradiusBottomRightCEAS(event.target.value);
  };

  const backgroundclrCEASChange = (event) => {
    setbackgroundclrCEAS(event.target.value);
  };

  const borderclrCEASChange = (event) => {
    setborderclrCEAS(event.target.value);
  };

  const headingFClrCEASChange = (event) => {
    setheadingFClrgCEAS(event.target.value);
  };

  const helpTextCEASChange = (event) => {
    sethelpTextCEAS(event.target.value);
  };

//2
  const radiusTopLeftERChange = (event) => {
    setRadiusTopLeftER(event.target.value);
  };

  const radiusTopRightERChange = (event) => {
    setRadiusTopRightER(event.target.value);
  };

  const radiusBottomLeftERChange = (event) => {
    setRadiusBottomLeftER(event.target.value);
  };

  const radiusBottomRightERChange = (event) => {
    setRradiusBottomRightER(event.target.value);
  };

  const backgroundclrERChange = (event) => {
    setbackgroundclrER(event.target.value);
  };

  const borderclrERChange = (event) => {
    setborderclrER(event.target.value);
  };

  const headingFClrERChange = (event) => {
    setheadingFClrgER(event.target.value);
  };

  const helpTextERChange = (event) => {
    sethelpTextER(event.target.value);
  };

  const [showLT, setShowLT] = useState('')
  const [showRT, setShowRT] = useState('')
  const [showLB, setShowLB] = useState('')
  const [showRB, setShowRB] = useState('')
  const [showBack, setShowBack] = useState('')
  const [showBor, setShowBor] = useState('')
  const [showHColor, setShowHColor] = useState('')
  const [showTexr, setShowText] = useState('')

  function showFunction(namesssss){

        selectedCentreMultiData.multitaskTables.map((multi)=> {
        if (multi.pageId === namesssss) {
          setShowLT(multi.radiusTopLeft)
          setShowRT(multi.radiusTopRight)
          setShowLB(multi.radiusBottomLeft)
          setShowRB(multi.radiusBottomRight)
          setShowBack(multi.backgroundColor)
          setShowBor(multi.borderColor)
          setShowHColor(multi.mainHeaderFontColor)
          setShowText(multi.helpText) 
          
        }
        


      })

  

    setMoreDetailOpen(true)
  }




  const previousRowData = (
    <TableBody>
        

        <TableRow>
            <TableCell style={cellStyle}>All</TableCell>
            <TableCell style={cellStyle}>

              <Checkbox color="primary"
                    id={"chkSelectAllRadiusTopLeft"}
                    checked={chkSelectAllRadiusTopLeft}
                    onClick={() => {
                        getchkSelectAllRadiusTopLeft();
                        }}
              />
            </TableCell>
            <TableCell style={cellStyle}>
              <Checkbox color="primary" 
                                  id={"chkSelectAllRadiusTopRight"}
                                  checked={chkSelectAllRadiusTopRight}
                                  onClick={() => {
                                    getchkSelectAllRadiusTopRight();
                                      }}
              />
            </TableCell>
            <TableCell style={cellStyle}>
              <Checkbox color="primary" 
                                  id={"chkSelectAllRadiusBottomLeft"}
                                  checked={chkSelectAllRadiusBottomLeft}
                                  onClick={() => {
                                    getchkSelectAllRadiusBottomLeft();
                                      }}
              
              />
            </TableCell>
            <TableCell style={cellStyle}>
              <Checkbox color="primary" 
                                  id={"chkSelectAllRadiusBottomRight"}
                                  checked={chkSelectAllRadiusBottomRight}
                                  onClick={() => {
                                    getchkSelectAllRadiusBottomRight();
                                      }}
              
              />
            </TableCell>
            <TableCell style={cellStyle}>
              <Box style={{ display: 'flex' }}>
                <Checkbox color="primary" 
                                    id={"chkSelectAllBgColor"}
                                    checked={chkSelectAllBgColor}
                                    onClick={() => {
                                      getchkSelectAllBgColor();
                                        }}
                /> &nbsp;
                {/* <input
                  type="color"
                  style={{ width: '50%', marginTop: '3px' }}
                  id="bgColor"
                  name="bgColor"
                /> */}
              </Box>
            </TableCell>
            <TableCell style={cellStyle}>
              <Box style={{ display: 'flex' }}>
                <Checkbox color="primary" 
                                    id={"chkSelectAllBorderColor"}
                                    checked={chkSelectAllBorderColor}
                                    onClick={() => {
                                      getchkSelectAllBorderColor();
                                        }}
                
                /> &nbsp;
                {/* <input
                  type="color"
                  style={{ width: '50%', marginTop: '3px' }}
                  id="borderColor"
                  name="borderColor"
                /> */}
              </Box>
            </TableCell>
            <TableCell style={cellStyle}>
              <Box style={{ display: 'flex' }}>
                <Checkbox color="primary" 
                                    id={"chkSelectAllHeadingColor"}
                                    checked={chkSelectAllHeadingColor}
                                    onClick={() => {
                                      getchkSelectAllHeadingColor();
                                        }}
                /> &nbsp;
                {/* <input
                  type="color"
                  style={{ width: '50%', marginTop: '3px' }}
                  id="headingFontColor"
                  name="headingFontColor"
                /> */}
              </Box>
            </TableCell>
            <TableCell style={cellStyle}>

            </TableCell>
            <TableCell style={cellStyle}></TableCell>
          </TableRow>
        

                    <StyledTableRow className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Main Page</div>
                    
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftmain_page}
                        onChange={radiusTopLeftmain_pageChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightmain_page}
                        onChange={radiusTopRightmain_pageChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftmain_page}  
                        onChange={radiusBottomLeftmain_pageChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightmain_page}  
                        onChange={radiusBottomRightmain_pageChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclrmain_page}
                        onChange={backgroundclrmain_pageChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrmain_page}
                        onChange={borderclrmain_pageChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrmain_page}
                        onChange={headingFClrmain_pageChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextmain_page} onChange={helpTextmain_pageChange}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small" 
                        onClick={() => { showFunction("main_page") }}
                        
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>



                    <StyledTableRow  className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Risk collection</div>
                    
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftrisk_collection}
                        onChange={radiusTopLeftrisk_collectionChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightrisk_collection}
                        onChange={radiusTopRightrisk_collectionChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftrisk_collection}  
                        onChange={radiusBottomLeftrisk_collectioneChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightrisk_collection}  
                        onChange={radiusBottomRightrisk_collectionChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclrrisk_collection}
                        onChange={backgroundclrrisk_collectioneChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrrisk_collection}
                        onChange={borderclrrisk_collectionChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrrisk_collection}
                        onChange={headingFClrrisk_collectionChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextrisk_collection} onChange={helpTextrisk_collectionChange}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small"
                        onClick={() => { showFunction("risk_collection") }}
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>


                    <StyledTableRow  className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Questionnaire Collections</div>
                    
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftquestionnaire_collections}
                        onChange={radiusTopLeftquestionnaire_collectionsChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightquestionnaire_collections}
                        onChange={radiusTopRightquestionnaire_collectionsChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftquestionnaire_collections}  
                        onChange={radiusBottomLeftquestionnaire_collectionsChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightquestionnaire_collections}  
                        onChange={rradiusBottomRightquestionnaire_collectionsChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclrquestionnaire_collections}
                        onChange={backgroundclrquestionnaire_collectionsChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrquestionnaire_collections}
                        onChange={borderclrquestionnaire_collectionsChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrquestionnaire_collections}
                        onChange={headingFClrquestionnaire_collectionsChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextquestionnaire_collections} onChange={helpTextquestionnaire_collectionsChange}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small"
                        onClick={() => { showFunction("questionnaire_collections") }}
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>




                    <StyledTableRow  className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Global Standards </div>
                  
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftglobal_standards}
                        onChange={radiusTopLeftglobal_standardsChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightglobal_standards}
                        onChange={radiusTopRightglobal_standardsChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftglobal_standards}  
                        onChange={radiusBottomLeftglobal_standardsChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightglobal_standards}  
                        onChange={radiusBottomRightglobal_standardsChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclglobal_standards}
                        onChange={backgroundclglobal_standardsChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrglobal_standards}
                        onChange={borderclrglobal_standardsChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrglobal_standards}
                        onChange={headingFClrglobal_standardsChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextglobal_standards} onChange={helpTextglobal_standardsChange}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small"
                          onClick={() => { showFunction("global_standards") }}
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>


                    <StyledTableRow  className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Custom Standards </div>
                  
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftcustom_standards}
                        onChange={radiusTopLeftcustom_standardsChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightcustom_standards}
                        onChange={radiusTopRightcustom_standardsChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftcustom_standards}  
                        onChange={radiusBottomLeftcustom_standardsChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightcustom_standards}  
                        onChange={radiusBottomRightcustom_standardsChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclrcustom_standards}
                        onChange={backgroundclrcustom_standardsChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrcustom_standards}
                        onChange={borderclrcustom_standardsChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrcustom_standards}
                        onChange={headingFClrcustom_standardsChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextcustom_standards} onChange={helpTextcustom_standardsChange}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small"
                        onClick={() => { showFunction("custom_standards") }}
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>



                    <StyledTableRow  className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Risk Hierarchy </div>
                  
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftrisk_hierarchy}
                        onChange={radiusTopLeftrhChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightrisk_hierarchy}
                        onChange={radiusTopRightrhChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftrisk_hierarchy}  
                        onChange={radiusBottomLeftrhChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightrisk_hierarchy}  
                        onChange={radiusBottomRightrhChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclrrisk_hierarchy}
                        onChange={backgroundclrrhChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrrisk_chierarchy}
                        onChange={borderclrrhChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrrisk_hierarchy}
                        onChange={headingFClrrhChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextrisk_hierarchy} onChange={helpTextrh}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small"
                        onClick={() => { showFunction("risk_hierarchy") }}
                        
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>


                    <StyledTableRow  className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Centre Maintenance </div>
                  
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftcentre_maintenance}
                        onChange={radiusTopLeftcmChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightcentre_maintenance}
                        onChange={radiusTopRightcmChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftcentre_maintenance}  
                        onChange={radiusBottomLeftcmChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightcentre_maintenance}  
                        onChange={radiusBottomRightcmChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclrcentre_maintenance}
                        onChange={backgroundclrcmChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrcentre_maintenance}
                        onChange={borderclrcmChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrcentre_maintenance}
                        onChange={headingFClrcmChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextcentre_maintenance} onChange={helpTextcm}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small"
                        onClick={() => { showFunction("centre_maintenance") }}
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>



                    <StyledTableRow  className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Risk Slider Methods </div>
                
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftrisk_slider_methods}
                        onChange={radiusTopLeftRSMChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightrisk_slider_methods}
                        onChange={radiusTopRightRSMChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftrisk_slider_methods}  
                        onChange={radiusBottomLeftRSMChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightrisk_slider_methods}  
                        onChange={radiusBottomRightRSMChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclrisk_slider_methods}
                        onChange={backgroundclrRSMChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrrisk_slider_methods}
                        onChange={borderclrRSMChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrrisk_slider_methods}
                        onChange={headingFClrRSMChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextrisk_slider_methods} onChange={helpTextRSM}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small"
                        onClick={() => { showFunction("risk_slider_methods") }}
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>


                    <StyledTableRow  className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Risk Slider Colors </div>
                
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftRSC}
                        onChange={radiusTopLeftRSCChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightRSC}
                        onChange={radiusTopRightRSCChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftRSC}  
                        onChange={radiusBottomLeftRSCChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightRSC}  
                        onChange={radiusBottomRightRSCChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclRSC}
                        onChange={backgroundclrRSCChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrRSC}
                        onChange={borderclrRSCChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrRSC}
                        onChange={headingFClrRSCChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextRSC} onChange={helpTextRSCChange}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small"
                        onClick={() => { showFunction("risk_slider_colors") }}
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow  className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Transfer Questionnaire Collection</div>
                
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftTQC}
                        onChange={radiusTopLeftTQCChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightTQC}
                        onChange={radiusTopRightTQCChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftTQC}  
                        onChange={radiusBottomLeftTQCChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightTQC}  
                        onChange={radiusBottomRightTQCChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclTQC}
                        onChange={backgroundclrTQCChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrTQC}
                        onChange={borderclrTQCChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrTQC}
                        onChange={headingFClrTQCChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextTQC} onChange={helpTextTQCChange}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small"
                        onClick={() => { showFunction("transfer_questionnaire_collection") }}
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>


                    <StyledTableRow  className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Create Edit Question</div>
                
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftCEQ}
                        onChange={radiusTopLeftCEQChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightCEQ}
                        onChange={radiusTopRightCEQChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftCEQ}  
                        onChange={radiusBottomLeftCEQChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightCEQ}  
                        onChange={radiusBottomRightCEQChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclCEQ}
                        onChange={backgroundclrCEQChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrCEQ}
                        onChange={borderclrCEQChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrCEQ}
                        onChange={headingFClrCEQChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextCEQ} onChange={helpTextCEQChange}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small"
                        onClick={() => { showFunction("create_edit_question") }}
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>



                    <StyledTableRow  className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Create Edit Answer Scale</div>
                
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftCEAS}
                        onChange={radiusTopLeftCEASChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightCEAS}
                        onChange={radiusTopRightCEASChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftCEAS}  
                        onChange={radiusBottomLeftCEASChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightCEAS}  
                        onChange={radiusBottomRightCEASChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclCEAS}
                        onChange={backgroundclrCEASChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrCEAS}
                        onChange={borderclrCEASChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrCEAS}
                        onChange={headingFClrCEASChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextCEAS} onChange={helpTextCEASChange}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small"
                        onClick={() => { showFunction("create_edit_answer_scale") }}
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>


                    <StyledTableRow  className={classes.tableTrStyle}>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <div style={{ whiteSpace: 'normal'}} className='tableStyle'>Evaluate Risk</div>
                
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 

                        value={radiusTopLeftER}
                        onChange={radiusTopLeftERChange}
                        />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusTopRightER}
                        onChange={radiusTopRightERChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={radiusBottomLeftER}  
                        onChange={radiusBottomLeftERChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth 
                        value={rradiusBottomRightER}  
                        onChange={radiusBottomRightERChange}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundclER}
                        onChange={backgroundclrERChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={borderclrER}
                        onChange={borderclrERChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                    <input
                        type='color'
                        style={{ width: '90%' }}
                        id="headerColor"
                        name="headerColor"
                        value={headingFClrER}
                        onChange={headingFClrERChange}
                    />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" style={cellStyle} className={classes.tableTrStyle}>
                        <TextField variant="outlined" fullWidth value={helpTextER} onChange={helpTextERChange}/>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={cellStyle} className={classes.tableTrStyle}>
                        <Button variant="contained" color="primary" size="small"
                        onClick={() => { showFunction("evaluate_risk") }}
                        >
                        show
                        </Button>
                    </StyledTableCell>
                    </StyledTableRow>
  </TableBody>


  );

       
    function clearFuction(){
        setRadiusTopLeftmain_page('')
        setRadiusTopRightmain_page('')
        setRadiusBottomLeftmain_page('')
        setRradiusBottomRightmain_page('')
        setbackgroundclrmain_page('')
        setborderclrmain_page('')
        setheadingFClrmain_page('')
        sethelpTextmain_page('')
        setRadiusTopLeftrisk_collection('')
        setRadiusTopRightrisk_collection('')
        setRadiusBottomLeftrisk_collection('')
        setRradiusBottomRightrisk_collection('')
        setbackgroundclrrisk_collection('')
        setborderclrrisk_collection('')
        setheadingFClrrisk_collection('')
        sethelpTextrisk_collection('')
        setCentreName('')
        setMutitaskingDataId('')
        setsavebutton('save')
        setRadiusTopLeftquestionnaire_collections('')
        setRadiusTopRightquestionnaire_collections('')
        setRadiusBottomLeftquestionnaire_collections('')
        setRradiusBottomRightquestionnaire_collections('')
        setbackgroundclrquestionnaire_collections('')
        setborderclrquestionnaire_collections('')
        setheadingFClrquestionnaire_collections('')
        sethelpTextquestionnaire_collections('')
        setRadiusTopLeftglobal_standards('')
        setRadiusTopRightglobal_standards('')
        setRadiusBottomLeftglobal_standards('')
        setRradiusBottomRightglobal_standards('')
        setbackgroundclrglobal_standards('')
        setborderclrglobal_standards('')
        setheadingFClrglobal_standards('')
        sethelpTextglobal_standards('')
        setRadiusTopLeftcustom_standards('')
        setRadiusTopRightcustom_standards('')
        setRadiusBottomLeftcustom_standards('')
        setRradiusBottomRightcustom_standards('')
        setbackgroundclrcustom_standards('')
        setborderclrcustom_standards('')
        setheadingFClrcustom_standards('')
        sethelpTextcustom_standards('')
        setRadiusTopLeftrisk_hierarchy('')
        setRadiusTopRightrisk_hierarchy('')
        setRadiusBottomLeftrisk_hierarchy('')
        setRradiusBottomRightrisk_hierarchy('')
        setbackgroundclrrisk_hierarchy('')
        setborderclrrisk_hierarchy('')
        setheadingFClrrisk_hierarchy('')
        sethelpTextrisk_hierarchy('')
        setRadiusTopLeftcentre_maintenance('')
        setRadiusTopRightcentre_maintenance('')
        setRadiusBottomLeftcentre_maintenance('')
        setRradiusBottomRightcentre_maintenance('')
        setbackgroundclrcentre_maintenance('')
        setborderclrcentre_maintenance('')
        setheadingFClrcentre_maintenance('')
        sethelpTextcentre_maintenance('')
        setRadiusTopLeftrisk_slider_methods('')
        setRadiusTopRightrisk_slider_methods('')
        setRadiusBottomLeftrisk_slider_methods('')
        setRradiusBottomRightrisk_slider_methods('')
        setbackgroundclrrisk_slider_methods('')
        setborderclrrisk_slider_methods('')
        setheadingFClrgrisk_slider_methods('')
        sethelpTextrisk_slider_methods('')
        setRadiusTopLeftRSC('')
        setRadiusTopRightRSC('')
        setRadiusBottomLeftRSC('')
        setRradiusBottomRightRSC('')
        setbackgroundclrRSC('')
        setborderclrRSC('')
        setheadingFClrgRSC('')
        sethelpTextRSC('')
        setRadiusTopLeftTQC('')
        setRadiusTopRightTQC('')
        setRadiusBottomLeftTQC('')
        setRradiusBottomRightTQC('')
        setbackgroundclrTQC('')
        setborderclrTQC('')
        setheadingFClrgTQC('')
        sethelpTextTQC('')
        setRadiusTopLeftCEQ('')
        setRadiusTopRightCEQ('')
        setRadiusBottomLeftCEQ('')
        setRradiusBottomRightCEQ('')
        setbackgroundclrCEQ('')
        setborderclrCEQ('')
        setheadingFClrgCEQ('')
        sethelpTextCEQ('')
        setRadiusTopLeftCEAS('')
        setRadiusTopRightCEAS('')
        setRadiusBottomLeftCEAS('')
        setRradiusBottomRightCEAS('')
        setbackgroundclrCEAS('')
        setborderclrCEAS('')
        setheadingFClrgCEAS('')
        sethelpTextCEAS('')
        setRadiusTopLeftER('')
        setRadiusTopRightER('')
        setRadiusBottomLeftER('')
        setRradiusBottomRightER('')
        setbackgroundclrER('')
        setborderclrER('')
        setheadingFClrgER('')
        sethelpTextER('')
        setchkSelectAllRadiusTopLeft(false)
        setchkSelectAllRadiusTopRight(false)
        setchkSelectAllRadiusBottomLeft(false)
        setchkSelectAllRadiusBottomRight(false)
        setchkSelectAllBgColor(false)
        setchkSelectAllBorderColor(false)
        setchkSelectAllHeadingColor(false)
    

    }


  function saveFunction(){
    setLoading1(true)

    if(centreName === ''){
      alert("please select a centre");
      setLoading1(false)
      return
    }
    var obj = {}
    var arr = []
    
    if (mutitaskingDataId != '') {
        obj["id"] = mutitaskingDataId;
    }

    
    obj["centreId"] = centreName;

     var obj1 = {}

     obj1["pageId"] = 'main_page';
     obj1["radiusTopLeft"] = radiusTopLeftmain_page;
     obj1["radiusTopRight"] = radiusTopRightmain_page;
     obj1["radiusBottomLeft"] = radiusBottomLeftmain_page;
     obj1["radiusBottomRight"] = rradiusBottomRightmain_page;
     obj1["backgroundColor"] = backgroundclrmain_page;
     obj1["borderColor"] = borderclrmain_page;
     obj1["mainHeaderFontColor"] = headingFClrmain_page;
     obj1["helpText"] = helpTextmain_page;

     arr.push(obj1)

     var obj2 = {}

     obj2["pageId"] = 'risk_collection';
     obj2["radiusTopLeft"] = radiusTopLeftrisk_collection;
     obj2["radiusTopRight"] = radiusTopRightrisk_collection;
     obj2["radiusBottomLeft"] = radiusBottomLeftrisk_collection;
     obj2["radiusBottomRight"] = rradiusBottomRightrisk_collection;
     obj2["backgroundColor"] = backgroundclrrisk_collection;
     obj2["borderColor"] = borderclrrisk_collection;
     obj2["mainHeaderFontColor"] = headingFClrrisk_collection;
     obj2["helpText"] = helpTextrisk_collection;

     arr.push(obj2)

     var obj3 = {}

     obj3["pageId"] = 'questionnaire_collections';
     obj3["radiusTopLeft"] = radiusTopLeftquestionnaire_collections;
     obj3["radiusTopRight"] = radiusTopRightquestionnaire_collections;
     obj3["radiusBottomLeft"] = radiusBottomLeftquestionnaire_collections;
     obj3["radiusBottomRight"] = rradiusBottomRightquestionnaire_collections;
     obj3["backgroundColor"] = backgroundclrquestionnaire_collections;
     obj3["borderColor"] = borderclrquestionnaire_collections;
     obj3["mainHeaderFontColor"] = headingFClrquestionnaire_collections;
     obj3["helpText"] = helpTextquestionnaire_collections;

     arr.push(obj3)

     var obj4 = {}

     obj4["pageId"] = 'global_standards';
     obj4["radiusTopLeft"] = radiusTopLeftglobal_standards;
     obj4["radiusTopRight"] = radiusTopRightglobal_standards;
     obj4["radiusBottomLeft"] = radiusBottomLeftglobal_standards;
     obj4["radiusBottomRight"] = rradiusBottomRightglobal_standards;
     obj4["backgroundColor"] = backgroundclglobal_standards;
     obj4["borderColor"] = borderclrglobal_standards;
     obj4["mainHeaderFontColor"] = headingFClrglobal_standards;
     obj4["helpText"] = helpTextglobal_standards;

     arr.push(obj4)

     var obj5 = {}

     obj5["pageId"] = 'custom_standards';
     obj5["radiusTopLeft"] = radiusTopLeftcustom_standards;
     obj5["radiusTopRight"] = radiusTopRightcustom_standards;
     obj5["radiusBottomLeft"] = radiusBottomLeftcustom_standards;
     obj5["radiusBottomRight"] = rradiusBottomRightcustom_standards;
     obj5["backgroundColor"] = backgroundclrcustom_standards;
     obj5["borderColor"] = borderclrcustom_standards;
     obj5["mainHeaderFontColor"] = headingFClrcustom_standards;
     obj5["helpText"] = helpTextcustom_standards;

     arr.push(obj5)

     var obj6 = {}

     obj6["pageId"] = 'risk_hierarchy';
     obj6["radiusTopLeft"] = radiusTopLeftrisk_hierarchy;
     obj6["radiusTopRight"] = radiusTopRightrisk_hierarchy;
     obj6["radiusBottomLeft"] = radiusBottomLeftrisk_hierarchy;
     obj6["radiusBottomRight"] = rradiusBottomRightrisk_hierarchy;
     obj6["backgroundColor"] = backgroundclrrisk_hierarchy;
     obj6["borderColor"] = borderclrrisk_chierarchy;
     obj6["mainHeaderFontColor"] = headingFClrrisk_hierarchy;
     obj6["helpText"] = helpTextrisk_hierarchy;

     arr.push(obj6)

     var obj7 = {}

     obj7["pageId"] = 'centre_maintenance';
     obj7["radiusTopLeft"] = radiusTopLeftcentre_maintenance;
     obj7["radiusTopRight"] = radiusTopRightcentre_maintenance;
     obj7["radiusBottomLeft"] = radiusBottomLeftcentre_maintenance;
     obj7["radiusBottomRight"] = rradiusBottomRightcentre_maintenance;
     obj7["backgroundColor"] = backgroundclrcentre_maintenance;
     obj7["borderColor"] = borderclrcentre_maintenance;
     obj7["mainHeaderFontColor"] = headingFClrcentre_maintenance;
     obj7["helpText"] = helpTextcentre_maintenance;

     arr.push(obj7)

     var obj8 = {}

     obj8["pageId"] = 'risk_slider_methods';
     obj8["radiusTopLeft"] = radiusTopLeftrisk_slider_methods;
     obj8["radiusTopRight"] = radiusTopRightrisk_slider_methods;
     obj8["radiusBottomLeft"] = radiusBottomLeftrisk_slider_methods;
     obj8["radiusBottomRight"] = rradiusBottomRightrisk_slider_methods;
     obj8["backgroundColor"] = backgroundclrisk_slider_methods;
     obj8["borderColor"] = borderclrrisk_slider_methods;
     obj8["mainHeaderFontColor"] = headingFClrrisk_slider_methods;
     obj8["helpText"] = helpTextrisk_slider_methods;

     arr.push(obj8)

     var obj9 = {}

     obj9["pageId"] = 'risk_slider_colors';
     obj9["radiusTopLeft"] = radiusTopLeftRSC;
     obj9["radiusTopRight"] = radiusTopRightRSC;
     obj9["radiusBottomLeft"] = radiusBottomLeftRSC;
     obj9["radiusBottomRight"] = rradiusBottomRightRSC;
     obj9["backgroundColor"] = backgroundclRSC;
     obj9["borderColor"] = borderclrRSC;
     obj9["mainHeaderFontColor"] = headingFClrRSC;
     obj9["helpText"] = helpTextRSC;

     arr.push(obj9)

     var obj10 = {}

     obj10["pageId"] = 'transfer_questionnaire_collection';
     obj10["radiusTopLeft"] = radiusTopLeftTQC;
     obj10["radiusTopRight"] = radiusTopRightTQC;
     obj10["radiusBottomLeft"] = radiusBottomLeftTQC;
     obj10["radiusBottomRight"] = rradiusBottomRightTQC;
     obj10["backgroundColor"] = backgroundclTQC;
     obj10["borderColor"] = borderclrTQC;
     obj10["mainHeaderFontColor"] = headingFClrTQC;
     obj10["helpText"] = helpTextTQC;

     arr.push(obj10)

     var obj11 = {}

     obj11["pageId"] = 'create_edit_question';
     obj11["radiusTopLeft"] = radiusTopLeftCEQ;
     obj11["radiusTopRight"] = radiusTopRightCEQ;
     obj11["radiusBottomLeft"] = radiusBottomLeftCEQ;
     obj11["radiusBottomRight"] = rradiusBottomRightCEQ;
     obj11["backgroundColor"] = backgroundclCEQ;
     obj11["borderColor"] = borderclrCEQ;
     obj11["mainHeaderFontColor"] = headingFClrCEQ;
     obj11["helpText"] = helpTextCEQ;

     arr.push(obj11)

     var obj12 = {}

     obj12["pageId"] = 'create_edit_answer_scale';
     obj12["radiusTopLeft"] = radiusTopLeftCEAS;
     obj12["radiusTopRight"] = radiusTopRightCEAS;
     obj12["radiusBottomLeft"] = radiusBottomLeftCEAS;
     obj12["radiusBottomRight"] = rradiusBottomRightCEAS;
     obj12["backgroundColor"] = backgroundclCEAS;
     obj12["borderColor"] = borderclrCEAS;
     obj12["mainHeaderFontColor"] = headingFClrCEAS;
     obj12["helpText"] = helpTextCEAS;

     arr.push(obj12)

     var obj13 = {}

     obj13["pageId"] = 'evaluate_risk';
     obj13["radiusTopLeft"] = radiusTopLeftER;
     obj13["radiusTopRight"] = radiusTopRightER;
     obj13["radiusBottomLeft"] = radiusBottomLeftER;
     obj13["radiusBottomRight"] = rradiusBottomRightER;
     obj13["backgroundColor"] = backgroundclER;
     obj13["borderColor"] = borderclrER;
     obj13["mainHeaderFontColor"] = headingFClrER;
     obj13["helpText"] = helpTextER;

     arr.push(obj13)


    obj["multitaskTables"] = arr;

    var sendJson = JSON.stringify(obj);

    console.log(sendJson)

    var endPoint = '/multitaskPageSetup/create';
    if (mutitaskingDataId != '') {
        endPoint = '/multitaskPageSetup/update';
    }

    console.log(CONFIG.MAIN_URL + endPoint)
    axios(CONFIG.MAIN_URL + endPoint, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: sendJson
    })
        .then(res => {

            clearFuction();
            
            getmultitaskingMenuData()
            setLoading1(false)
                      
            if (mutitaskingDataId != '') {
                alert('Successfully Updated ');
            } else {
                alert('Successfully Saved ');
            }
            
        })
        .catch(function (error) {
            alert('Error Updating the collection');
            console.log(error);
            setLoading1(false)
        });


  }

  return (

<>
    <Grid container spacing={2}>
    <Grid item xs={4}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { mt: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
        > <FormControl fullWidth>
                <TextField
                    label="Select a Centre"
                    sx={{ width: '100%' }}
                    select
                    value={centreName}
                    onChange={handleChangeCentre}
                    style={{backgroundColor:"white"}}
                >
                    {allCentres.map((item) => (
                        <MenuItem
                            value={item.centreId}
                        >
                            {item.centreName}{' ('}{item.centreId}{')'}
                        </MenuItem>
                    ))}

                </TextField>
            </FormControl>

        </Box>
    </Grid>

            <Grid item xs={7} style={{marginTop:'10px', gap:'5px',display:'flex'}}>
            <Box>
            <Button variant="contained" 
            color="primary" 
            size="small"
            onClick={saveFunction}
            style={{padding:'10px 15px'}}

            >
                {savebutton}
              </Button>
            </Box>
            <Box>
              <Button variant="contained" 
            color="primary" 
            size="small"
            onClick={clearFuction}
            style={{padding:'10px 15px'}}
            >
                clear
              </Button>
              </Box>
             
    </Grid>
   
    
    <Grid item xs={12}>
   
    <TableContainer style={tableStyle}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ ...cellStyle, width: '10%' }}></StyledTableCell>
            <StyledTableCell style={{ ...cellStyle, width: '8%' }}>Radius Top Left</StyledTableCell>
            <StyledTableCell style={{ ...cellStyle, width: '8%' }}>Radius Top Right</StyledTableCell>
            <StyledTableCell style={{ ...cellStyle, width: '8%' }}>Radius Bottom Left</StyledTableCell>
            <StyledTableCell style={{ ...cellStyle, width: '8%' }}>Radius Bottom Right</StyledTableCell>
            <StyledTableCell style={{ ...cellStyle, width: '9%' }}>Background Color</StyledTableCell>
            <StyledTableCell style={{ ...cellStyle, width: '9%' }}>Border Color</StyledTableCell>
            <StyledTableCell style={{ ...cellStyle, width: '9%' }}>Heading Font Color</StyledTableCell>
            <StyledTableCell style={{ ...cellStyle, width: '20%' }}>Help text Message</StyledTableCell>
            <StyledTableCell style={{ ...cellStyle, width: '5%' }}></StyledTableCell>
          </TableRow>
        </TableHead>
            {previousRowData}
      </Table>
    </TableContainer>
    </Grid>


    </Grid>


    <Dialog
        maxWidth={'lg'}
        open={moreDetailOpen}
        onClose={moreDetailHandleClickClose}
        scroll={moreDetailScroll}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          <Typography component="th" scope="row" style={{ width: '100%', fontSize: '10px !important' }}>
            <span style={{ fontWeight: '550' }}> </span>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={moreDetailScroll === 'paper'}>
          <DialogContentText
            id="risk-eval-type-scroll-dialog-description"
            tabIndex={-1}
          >
                        <Grid item xs={12} style={{ marginTop: '-12px' }}>
              
                <CardContent>



                  <Card style={{ marginRadius: '20px', width: '800px', height:  '600px',border:`1px solid ${showBor}`, width:'800px', height:'600px', borderTopLeftRadius:`${showLT}` + 'px', borderTopRightRadius:`${showRT}` + 'px',borderBottomLeftRadius:`${showLB}` + 'px',borderBottomRightRadius:`${showRB}` + 'px', backgroundColor:`${showBack}`}}>
                  <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                    <RemoveCircleOutlineIcon  style={{ cursor: 'pointer' }} />
                    <AddCircleOutlineIcon  style={{ cursor: 'pointer' }} />
                    <HighlightOffIcon  style={{ cursor: 'pointer' }} />
                  </Typography>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h6" className={classesss.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color:`${showHColor}`}}>Page Name</span>         
                         &nbsp;&nbsp;&nbsp;&nbsp;<Tooltip title={showTexr}>
            <span style={{ fontSize: '10px', color:' white' }}><InfoIcon/></span>
          </Tooltip>
          </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          className={classesss.leftPageNumberStylesample}
                        >
                          {1}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          className={classesss.rightPageNumberStyle}
                        >
                          {1}
                        </Button>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="h6" className={classesss.mutimenuPageHeadingStyle} style={{ color: 'white' }}>Multitasking Menu - Page name</Typography>
                      </Grid>

                      <Grid item xs={12} style={{ marginLeft: '-10px' }}>

                       


                      </Grid>
                    </Grid>
                          </CardContent>
                          </Card>
                </CardContent>
              

            </Grid>



            </DialogContentText>
            </DialogContent>
            </Dialog>


            <Dialog
        open={loading1}
        onClose={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          <span style={{ textAlign: 'center' }}>Please Wait ...</span>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>

              
              <ClockLoader
                size={100}
                color={'red'}
                className={classes.loaderStyle}
                loading={loading1}
              />
            
            </Grid>
          </Grid>


        </DialogContent>
      </Dialog>



            
    </>
  );

}

export default MultitaskingMenuSetup;
