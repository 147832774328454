import React from 'react'
import useStyle from '../Css/MainCss';
import { Grid, makeStyles } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TransferToExcel from '../Components/TransferToExcel';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { BarChart, Bar, XAxis, YAxis, Cell, Tooltip, LabelList, CartesianGrid, Legend } from 'recharts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CircularProgress } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import { FullscreenRounded, TurnedInOutlined } from '@mui/icons-material';
import Tooltip1 from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { CONFIG } from '../config';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import '../Css/FinalizeRegisterComponent.css'




const FinaliseRegisterComponent = ({colorPatterns,registerTypeData,registerTypeDataOrg,openRegisterDataForEvalScroll,registerEvaluate,registerTypeDataForEvalOrg,createdUserId}) => {

    const classes = useStyle();


    const [registerTypeDataForEval, setRegisterTypeDataForEval] = React.useState({});
    const [waitingOpen, setWaitingOpen] = React.useState(false);
    const [riskCategoryMethodData, setRiskCategoryMethodData] = React.useState([])
    const [riskCategoryMethodDataAppetite, setRiskCategoryMethodDataAppetite] = React.useState([])

    const [colorPatternId, setColorPatternId] = React.useState('')
    const [databClr, setDatabClr] = React.useState([]);
    const [tdPresentage, setTdPresentage] = React.useState()
    const [colorPatternName, setColorPatternName] = React.useState('')
    const [colorPatternPrimaryColorName, setColorPatternPrimaryColorName] = React.useState('')
    const [colorPatternSecondaryColorName, setColorPatternSecondaryColorName] = React.useState('')
    const [colorSelectDropDownRiskLevel, setColorSelectDropDownRiskLevel] = React.useState();
    const [colorSelectDropDownRiskAppetite, setColorSelectDropDownRiskAppetite] = React.useState();
    const [colorSelectDropDownRiskLevelTmp, setColorSelectDropDownRiskLevelTmp] = React.useState(1);
    const [colorSelectDropDownRiskAppetiteTmp, setColorSelectDropDownRiskAppetiteTmp] = React.useState(2);
    const [btnHirachivalClass, setBtnHirachivalClass] = React.useState(classes.btnNormal);
    const [btnGradientClass, setBtnGradientClass] = React.useState(classes.btnNormal);
    const [btnBarChartClass, setBtnBarChartClass] = React.useState(classes.btnNormal);
    const [applyFlag, setApplyFlag] = React.useState(false);
    const [showTranferExlButton, setShowTranferExlButton] = React.useState(false);
    const [showEvalData, setShowEvalData] = React.useState(false)
    const [showTableType, setShowTableType] = React.useState()
    const [commitArrayData, setCommitArrayData] = React.useState([])
    const [commitBarData, setCommitBarData] = React.useState([]);
    const [editRiskHirachy, setEditRiskHirachy] = React.useState(false);
    const [lastRiskTmpId, setLastRiskTmpId] = React.useState(0);
    const [arrayForDisplayDataInTable, setArrayForDisplayDataInTable] = React.useState([])
    const [barData, setBarData] = React.useState([]);
    const [barChartHeight, setBarChartHeight] = React.useState(600);
    const [arrRiskColorState, setArrRiskColorState] = React.useState([]);
    const [arrColorAppState, setArrColorAppState] = React.useState([]);
    const [fullScreenState, setFullScreenState] = React.useState(false);
    const [fullScreenStateMsg, setFullScreenStateMsg] = React.useState('Full Screen');

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label" style={{ fontWeight: 'bold' }}>{`${label} `}</p>
                    <p className="label">{`Risk Level : ${payload[0].value}`}</p>
                    <p className="label">{`Risk Appetite : ${payload[1].value}`}</p>
      
                </div>
            );
        }
      
        return null;
      };
     
    const renderCustomizedLabel = (props) => {
        const {
          x, y, width, height, value,
        } = props;
      
      const fireOffset = value.toString().length < 5;
      const offset = fireOffset ? -40 : 80;
      const orgWidth = width > 500 ? 500 : width;
        return (
            <text x={x + orgWidth + offset} y={y + height - 5} fill={fireOffset ? "#285A64" :"#7e96ac"} textAnchor="end">
              {value}
            </text>
        );
      };
      
      const renderCustomizedLabel1 = (props) => {
        const {
          x, y, width, height, value,
        } = props;
      
      const fireOffset = value.toString().length < 5;
      const offset = fireOffset ? -40 : 100;
      const orgWidth = width > 500 ? 500 : width;
        return (
            <text x={x + orgWidth + offset} y={y + height - 5} fill={fireOffset ? "#285A64" :"#7e96ac"} textAnchor="end">
              {value}
            </text>
        );
      };
     
  

    const [roleNames, setRoleNames] = React.useState([]);
    React.useEffect (() => {
        setRegisterTypeDataForEval(registerTypeData);
        var arr = []
        registerTypeDataOrg.riskTypes.forEach((data, index) => {
            var obj = {}
            obj["role"] = data.role 
            if (index === 0){
                arr.push(obj); 
            }else{
                var found = false;
                arr.forEach((item) => {
                    if (item.role === data.role){
                        found = true;
                    }
                })
                if (!found){
                    arr.push(obj); 
                }
            }
        })
        setRoleNames(arr);

        setShowEvalData(true)
        setShowTableType(1)
        //setEditRiskHirachy(true);
    },[])

    function setMinMaxWindowFunctionRegister() {
        if (!fullScreenState) {
            setFullScreenState(true);
            setFullScreenStateMsg('Full Screen Out');
        } else {
            setFullScreenState(false);
            setFullScreenStateMsg('Full Screen');
        }
      
      }
      

    const handleChangeColorPattern = (event) => {
        setColorPatternId(event.target.value);
        colorPatterns.forEach((data, index) => {
            if (data.id === event.target.value) {
                console.log(data)
                setDatabClr(data.colors)
                setTdPresentage(100 / (data.colors.length + 1))
      
                setColorPatternName(data.name);
                setColorPatternPrimaryColorName(data.primaryColorName);
                setColorPatternSecondaryColorName(data.secondaryColorName);
            }
        })
      };

      const handleChangeDropDown = (event) => {
        setColorSelectDropDownRiskLevelTmp(event.target.value);
      };
      
      const handleChangeDropDownAppetite = (event) => {
        setColorSelectDropDownRiskAppetiteTmp(event.target.value);
      };

      function applyFunctionRegister() {
        setColorSelectDropDownRiskLevel(colorSelectDropDownRiskLevelTmp);
        setColorSelectDropDownRiskAppetite(colorSelectDropDownRiskAppetiteTmp)
      
        setShowEvalData(true)
        setApplyFlag(true)
        setShowTableType(1)
        setShowTranferExlButton(true)
      
        setBtnHirachivalClass(classes.btnActive)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnNormal)
      
      }
     
      function showDataInAViewFunction(no){
        
        setEditRiskHirachy(true);
        setWaitingOpen(true);
        setTimeout(() => {
            setWaitingOpen(false);
        }, 500)
        
      }

      const [finaliseRegisterObj, setFinaliseRegisterObj] = React.useState({});
      const [openConfirm, setOpenConfirm] = React.useState(false);
      const [finaliseRiskName, setFinaliseRiskName] = React.useState('');
     
      const handleCloseConfirm = () => {
        setOpenConfirm(false);
      };
    
      const handleChangeFinaliseRiskName = (event) => {
        setFinaliseRiskName(event.target.value);
      };

      function getMonth(no) {
        if (no <10 ){
            return "0" + no;
        }
        return no;
      }


      function finaliseFunction(){
        
        setWaitingOpen(true);
        
        var obj = {};
        const d = new Date();
        var dateStr = d.getDate() + "/" + getMonth(d.getMonth()+1) + "/" + d.getFullYear() + "  "  + d.getHours() + ":"  + d.getMinutes() + ":"  + d.getSeconds()
        obj["name"]= finaliseRiskName + " - Finalise - " + dateStr;
        obj["description"]= finaliseRegisterObj.description;
        obj["centreId"]= finaliseRegisterObj.centreId;
        obj["status"]= finaliseRegisterObj.status;
        obj["registerUniqueName"]= finaliseRegisterObj.registerUniqueName;
        obj["documentCreator"]= "expert";
        obj["riskRegister"] = finaliseRegisterObj.riskRegister;
        obj["createdUserId"] = createdUserId;

        let sendJson = JSON.stringify(obj);
        axios(CONFIG.MAIN_URL+'/register/save',{
          method: 'post',
          headers: { 
              'Content-Type': 'application/json',
            },
          data : sendJson,  
        })
        .then(function (response) {
          setWaitingOpen(false);
          console.log(JSON.stringify(response.data));
          let rtnData = response.data;
          alert("Register Finalised successfully.");
          setOpenConfirm(false);
          /*
          getAllRegister(globalCentreId)
          setCategoryData([])
          setHierarchyData([])
          setRegisterUniqName('')
          setRegisterSaveBtn("SAVE REGISTER")
          setRegisterName('')
          setRegisterDescription('');
          cancelFunction();
          */
        })
        .catch(function (error) {
          console.log(error);
          setWaitingOpen(false);
          alert("Error saving Register-:" + error);
        });


      }
      
      function setSelectedItemsFunction(){
        var selectedArr = []
        registerTypeDataForEval.riskTypes.forEach((data) => {
            var idVal = data.id;
            if (document.getElementById('chk' + idVal).checked){
                var obj = {}
                obj["mainId"] = idVal;
                var arr = [];
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((item, index) => {
                        if (item.id != undefined && item.id != null){
                            var idSubVal = item.id;
                            if (document.getElementById('chk' + idSubVal) != null && document.getElementById('chk' + idSubVal).checked){
                                var tObj = {}
                                tObj["subId"] = idSubVal;
                                arr.push(tObj);
                            }
                        }
                    })
                }
                obj["subIds"] = arr;
                selectedArr.push(obj)
            }
        })
        console.log(selectedArr)
        
        if (selectedArr.length > 0){
            var sectionArr = [];
            registerTypeDataForEvalOrg.riskRegister.forEach((data) => {
                var sectionId = data.id;
                var sectionObj = data;
                selectedArr.forEach((item) => {
                    if (item.mainId === sectionId){
                        var riskArr = [];
                        data.risks.forEach((riskItem) => {
                            var riskId = riskItem.id;
                            item.subIds.forEach((itemX) => {
                                if (itemX.subId === riskId){
                                    riskArr.push(riskItem);
                                }
                            })
                        })
                        sectionObj["risks"] = riskArr;
                        sectionArr.push(sectionObj);
                    }
                })
            })
            var obj = {}
            const d = new Date();
            var dateStr = d.getDate() + "/" + getMonth(d.getMonth()+1) + "/" + d.getFullYear() + "  "  + d.getHours() + ":"  + d.getMinutes() + ":"  + d.getSeconds()
            if (selectedOwnershipRole != '' && selectedOwnershipRole != 'all'){
                obj["name"]= registerTypeDataForEvalOrg.name + " - " + selectedOwnershipRole + " - " + dateStr;
            }else{
                var mainArr = [];
                sectionArr.forEach((data) => {
                    var secName = data.role;
                    var obj = {};
                    var arr = [];
                    var canTake = true;
                    mainArr.forEach((arrData) => {
                        if (arrData.role === secName){
                            canTake = false;
                        }
                    })
                    if (canTake) {
                        sectionArr.forEach((item) => {
                          if (secName === item.role) {
                              arr.push(item);
                          } 
                      })
                      obj["role"] = data.role
                      mainArr.push(obj);
                  }	
                })
                var main1Arr = [];
                registerTypeDataOrg.riskTypes.forEach((data) => {
                    var secName = data.role;
                    var obj = {};
                    var arr = [];
                    var canTake = true;
                    main1Arr.forEach((arrData) => {
                        if (arrData.role === secName){
                            canTake = false;
                        }
                    })
                    if (canTake) {
                        registerTypeDataOrg.riskTypes.forEach((item) => {
                          if (secName === item.role) {
                              arr.push(item);
                          } 
                      })
                      obj["role"] = data.role
                      main1Arr.push(obj);
                  }	
                })
                var middleName = "Multiple Roles";
                if (main1Arr.length === mainArr.length){
                    middleName = "All Roles";
                }
                obj["name"]= registerTypeDataForEvalOrg.name + " - " + middleName + " - Finalise - " + dateStr;
            }
            obj["description"]= registerTypeDataForEvalOrg.description;
            obj["centreId"]= registerTypeDataForEvalOrg.centreId;
            obj["status"]= registerTypeDataForEvalOrg.status;
            obj["registerUniqueName"]= registerTypeDataForEvalOrg.registerUniqueName;
            obj["documentCreator"]= "expert";
            obj["riskRegister"] = sectionArr;
            obj["createdUserId"] = createdUserId;

            setWaitingOpen(true);
            let sendJson = JSON.stringify(obj);
            axios(CONFIG.MAIN_URL+'/register/save',{
                method: 'post',
                headers: { 
                    'Content-Type': 'application/json',
                },
                data : sendJson,  
            })
            .then(function (response) {
                setWaitingOpen(false);
                console.log(JSON.stringify(response.data));
                let rtnData = response.data;
                alert("Register Finalised successfully.");
                if (document.getElementById('chkAll').checked){
                    document.getElementById('chkAll').click();
                }
                setOpenConfirm(false);
                setSelectedOwnershipRole('');

                /*
                getAllRegister(globalCentreId)
                setCategoryData([])
                setHierarchyData([])
                setRegisterUniqName('')
                setRegisterSaveBtn("SAVE REGISTER")
                setRegisterName('')
                setRegisterDescription('');
                cancelFunction();
                */
            })
            .catch(function (error) {
                console.log(error);
                setWaitingOpen(false);
                alert("Error saving Register-:" + error);
                if (document.getElementById('chkAll').checked){
                    document.getElementById('chkAll').click();
                }
            });

        }else{
            alert("Please select items to Finalise")
        }
        

      }


      function getSubRiskSubNo(comment, riskId, subNo) {
        var no = "0"
        comment.forEach((data, index) => {
            if (data.riskTypeId === riskId) {
                no = subNo + (index + 1) + '';
            }
        });
        if (no === "0") {
            comment.forEach((item, index) => {
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    getSubRiskSubNo(item.subRisks, riskId, (index + 1));
                }
    
            })
        }
        return no;
    }
    

    const [selectedOwnershipRole, setSelectedOwnershipRole] = React.useState('');
    
    const handleChangeOwnershipRole = (event) => {
        var ownershipRole = event.target.value;
        if (document.getElementById('chkAll').checked){
            document.getElementById('chkAll').click()
        }
        setSelectedOwnershipRole(ownershipRole);
        
        var arr = []
        if (ownershipRole === "all"){
            setRegisterTypeDataForEval(registerTypeDataOrg);
        }else{
            var arr = [];
            registerTypeDataOrg.riskTypes.forEach((data, index) => {
                if (data.role === ownershipRole){
                    arr.push(data);
                }
            })
            var obj = {};
            obj["id"]= registerTypeDataOrg.id;
            obj["name"]= registerTypeDataOrg.name;
            obj["description"]= registerTypeDataOrg.description;
            obj["centreId"]= registerTypeDataOrg.centreId;
            obj["status"]= registerTypeDataOrg.status;
            obj["registerUniqueName"]= registerTypeDataOrg.registerUniqueName;
            obj["documentCreator"]= "expert";
            obj["riskTypes"] = arr;
            setRegisterTypeDataForEval(obj);
        }
    };

    const [checkStatus, setCheckStatus] = React.useState(false);
    
    function clickAll(){
        
        var checkAll = false;
        if (document.getElementById('chkAll').checked){
            checkAll = true;
        }

        if (checkAll){
            registerTypeDataForEval.riskTypes.forEach((data) => {
                var mainId = data.id;
                if (!document.getElementById('chk' + mainId).checked){
                    document.getElementById('chk' + mainId).click();
                }
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((item, index) => {
                        if (item.id != undefined && item.id != null){
                            var idSubVal = item.id;
                            if (document.getElementById('chk' + idSubVal) != null && !document.getElementById('chk' + idSubVal).checked){
                                if (!document.getElementById('chk' + idSubVal).checked){
                                    document.getElementById('chk' + idSubVal).click();
                                }    
                            }
                        }
                    })
                }
            })
        }else{
            registerTypeDataForEval.riskTypes.forEach((data) => {
                var mainId = data.id;
                if (document.getElementById('chk' + mainId).checked){
                    document.getElementById('chk' + mainId).click();
                }
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((item, index) => {
                        if (item.id != undefined && item.id != null){
                            var idSubVal = item.id;
                            if (document.getElementById('chk' + idSubVal) != null && !document.getElementById('chk' + idSubVal).checked){
                                if (document.getElementById('chk' + idSubVal).checked){
                                    document.getElementById('chk' + idSubVal).click();
                                }    
                            }
                        }
                    })
                }
            })

        }

    }
    
    const handleChangeAllCheckBox = (event) => {
        //alert(event.target.checked);
        setCheckStatus(event.target.checked);
        


    };
    
    


    function CommentRegister({ comment, lastRiskTmpId, riskNo, fromMainMenu, fromSubMenu, editRiskHirachyData,allChecked }) {
 
        //console.log("++++++++++++++++++++comment++++++++++++++++++++++");
        //console.log(editRiskHirachyData);

        const [checked, setChecked] = React.useState(allChecked);
      
        const [subLastRiskTmpId, setSubLastRiskTmpId] = React.useState(lastRiskTmpId);
        const [subEditRiskHirachyData, setSubEditRiskHirachyData] = React.useState(editRiskHirachyData);
        const [subRiskNo, setSubRiskNo] = React.useState(riskNo);
        const [show, setShow] = React.useState(true)
      
        var noDisplay = '';
        var noDisplaySpacesCount = 0;
      
      
      
      
        var subAvarageRisk = 0;
        var subAvarageAppetite = 0;
        if (fromMainMenu) {
            fromMainMenu = false;
            noDisplay = riskNo;
            subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageRisk = getSubAvarageRisk1(comment);
            subAvarageAppetite = getSubAvarageAppetite(comment);
        }
        if (fromSubMenu) {
            fromSubMenu = false;
            var subNo = getSubNoReg(comment, comment.riskTypeId)
            noDisplay = riskNo + '.' + subNo;
            var tmpArr = noDisplay.split(".");
            if (tmpArr.length === 2) {
                noDisplaySpacesCount = 1;
            }
            if (tmpArr.length === 3) {
                noDisplaySpacesCount = 2;
            }
            if (tmpArr.length === 4) {
                noDisplaySpacesCount = 3;
            }
            if (tmpArr.length === 5) {
                noDisplaySpacesCount = 4;
            }
            if (tmpArr.length === 6) {
                noDisplaySpacesCount = 5;
            }
            if (tmpArr.length === 7) {
                noDisplaySpacesCount = 6;
            }
            if (tmpArr.length === 8) {
                noDisplaySpacesCount = 7;
            }
            if (tmpArr.length === 9) {
                noDisplaySpacesCount = 8;
            }
            if (tmpArr.length === 10) {
                noDisplaySpacesCount = 9;
            }
            if (tmpArr.length === 11) {
                noDisplaySpacesCount = 10;
            }
            //subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageRisk = getSubAvarageRisk1(comment);
            //subAvarageAppetite = getSubAvarageAppetite(comment);

            /*
            if (checked){
                console.log("Clisk Button OK")
                
                if (comment.id != undefined && comment.id != null){
                    console.log(comment)
                    var btn = document.getElementById("btn" + comment.riskTypeTmpId);
                    btn.click();
                    setTimeout(() => {
                        btn.click();
                    }, 10);
                }
            }
            */
        }

        function unTickedOthers(id){
            var mainItem = false;
            registerTypeDataForEval.riskTypes.forEach((data) => {
                if (id === data.id){
                    mainItem = true;
                }
            })

            if (mainItem){
                registerTypeDataForEval.riskTypes.forEach((data) => {
                    if (id === data.id){
                        if (document.getElementById('chk' + id) != undefined  && document.getElementById('chk' + id) != null && !document.getElementById('chk' + id).checked){
                            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                                data.subRisks.forEach((item, index) => {
                                    if (item.id != undefined && item.id != null){
                                        var idSubVal = item.id;
                                        if (document.getElementById('chk' + idSubVal) != null && document.getElementById('chk' + idSubVal).checked){
                                            document.getElementById('chk' + idSubVal).click();
                                        }
            
                                    }
                                })
                            }
                        }
                    }
        
                })
            }else{
                registerTypeDataForEval.riskTypes.forEach((data) => {
                    var mainId = data.id
                    if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                        data.subRisks.forEach((item, index) => {
                            if (item.id != undefined && item.id != null){
                                var idSubVal = item.id;
                                if (id === idSubVal){
                                    if (document.getElementById('chk' + idSubVal) != null && document.getElementById('chk' + idSubVal).checked){
                                        if (!document.getElementById('chk' + mainId).checked){
                                            document.getElementById('chk' + mainId).click();
                                        }
                                        if (!document.getElementById('chkAll').checked){
                                            var checkedAll = true;
                                            registerTypeDataForEval.riskTypes.forEach((tmpData) => {
                                                if (document.getElementById('chk' + tmpData.id) != undefined  && document.getElementById('chk' + tmpData.id) != null && document.getElementById('chk' + tmpData.id).checked){
                                                    if (tmpData.subRisks != undefined && tmpData.subRisks != null && tmpData.subRisks.length > 0) {
                                                        tmpData.subRisks.forEach((tmpItem) => {
                                                            if (tmpItem.id != undefined && tmpItem.id != null){
                                                                if (document.getElementById('chk' + tmpItem.id) != null && !document.getElementById('chk' + tmpItem.id).checked){
                                                                    checkedAll = false;
                                                                }
                                                            }
                                                        })
                                                    }
                                                }else{
                                                    if (document.getElementById('chk' + tmpData.id) != undefined  && document.getElementById('chk' + tmpData.id) != null && !document.getElementById('chk' + tmpData.id).checked){
                                                        checkedAll = false;
                                                    }
                                                }
                                            })
                                            if (checkedAll){
                                                document.getElementById('chkAll').click()
                                            }

                                        }

                                    }else{
                                        if (document.getElementById('chk' + idSubVal) != null && !document.getElementById('chk' + idSubVal).checked){
                                            if (document.getElementById('chkAll').checked){
                                                var tmpArr = [];
                                                registerTypeDataForEval.riskTypes.forEach((tmpData) => {
                                                    if (document.getElementById('chk' + tmpData.id) != undefined  && document.getElementById('chk' + tmpData.id) != null && document.getElementById('chk' + tmpData.id).checked){
                                                        var xObj = {}
                                                        xObj["id"] = tmpData.id;
                                                        var tmpArr1 = [];
                                                        if (tmpData.subRisks != undefined && tmpData.subRisks != null && tmpData.subRisks.length > 0) {
                                                            tmpData.subRisks.forEach((tmpItem) => {
                                                                if (tmpItem.id != undefined && tmpItem.id != null){
                                                                    if (document.getElementById('chk' + tmpItem.id) != null && document.getElementById('chk' + tmpItem.id).checked){
                                                                        var yObj = {}
                                                                        yObj["id"] = tmpItem.id;
                                                                        tmpArr1.push(yObj);
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        xObj["subIds"] = tmpArr1;
                                                        tmpArr.push(xObj);
                                                    }
                                                })
                                                document.getElementById('chkAll').click();
                                                console.log(tmpArr)
                                                registerTypeDataForEval.riskTypes.forEach((tmpData) => {
                                                    if (document.getElementById('chk' + tmpData.id) != undefined  && document.getElementById('chk' + tmpData.id) != null){
                                                        tmpArr.forEach((xData) => {
                                                            if (xData.id === tmpData.id){
                                                                document.getElementById('chk' + tmpData.id).click();
                                                            }
                                                        })
                                                        if (tmpData.subRisks != undefined && tmpData.subRisks != null && tmpData.subRisks.length > 0) {
                                                            tmpData.subRisks.forEach((tmpItem) => {
                                                                if (tmpItem.id != undefined && tmpItem.id != null){
                                                                    if (document.getElementById('chk' + tmpItem.id) != undefined && document.getElementById('chk' + tmpItem.id) != null){
                                                                        tmpArr.forEach((yData) => {
                                                                            if (yData.id === tmpData.id){
                                                                                yData.subIds.forEach((zData  ) => {
                                                                                    if (zData.id === tmpItem.id){
                                                                                        document.getElementById('chk' + tmpItem.id).click();
                                                                                    }
                                                                                })
                                                                                
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    }
                                                })

                                            }
                                        }    
                                    
                                    }
                                }
                            }
                        })
                    }
                })

            }

    
    
        }
    

        

        const nestedComments = (comment.subRisks || []).map(comment => {
            return <CommentRegister key={comment.riskTypeTmpId} comment={comment} lastRiskTmpId={subLastRiskTmpId} riskNo={subRiskNo} fromSubMenu={true} editRiskHirachyData={subEditRiskHirachyData} allChecked={checked} type="child" />
        })
      
      
      
      
      
      
      
      
      
        return (
            <>
                <Grid item xs={8}>
                    {noDisplaySpacesCount === 0 ?
                        <></>
                        : noDisplaySpacesCount === 1 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;</>
                            : noDisplaySpacesCount === 2 ?
                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                : noDisplaySpacesCount === 3 ?
                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                    : noDisplaySpacesCount === 4 ?
                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                        : noDisplaySpacesCount === 5 ?
                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                            : noDisplaySpacesCount === 6 ?
                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                : noDisplaySpacesCount === 7 ?
                                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                    : noDisplaySpacesCount === 8 ?
                                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                        : noDisplaySpacesCount === 9 ?
                                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                            : noDisplaySpacesCount === 10 ?
                                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                                : ''
                    }
                    {comment.id != undefined && comment.id != null?
                        <Button
                            className='expanButton'
                            id={`btn` + comment.riskTypeTmpId}
                            onClick={() => setShow(true)}
                            startIcon={show ? <ExpandMoreIcon className='expandIcon'/> : <ChevronRightIcon className='expandIcon'/>}
                        >
                        </Button>
                    :
                        <Button
                            
                            id={`btn` + comment.riskTypeTmpId}
                            onClick={() => setShow(!show)}
                            startIcon={show ? <ExpandMoreIcon className='expandIcon' /> : <ChevronRightIcon className='expandIcon' />}
                        >
                        </Button>
                    }

                   
                    {comment.id != undefined && comment.id != null?
                    <>
                    <span className='registerNameMainDiv'>
                     {noDisplay}{') '}
                        <FormControlLabel 
                            control={<Checkbox 
                                //defaultChecked={checked} 
                                id={"chk"+comment.id}
                                onClick={() => unTickedOthers(comment.id)} 
                                className='registerNameWithChk'
                                />
                            } 
                            //label={comment.name} 
                        />
                        <span className='registerNameWithChkSpan'>{comment.name}</span>
                        </span>
                        </>
                        
                    :
                    
                    <span className='registerNameWithoutChk'>{noDisplay}{') '}{comment.name}</span>
                    
                    }
                </Grid>
                <Grid item xs={1}>
                        <span className='registerValuesSpan'><div className='registerValuesDiv'> <p className='registerValuesPhara'>{comment.riskLikelihood}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                        <span className='registerValuesSpan'><div className='registerValuesDiv' ><p className='registerValuesPhara'>{comment.riskImpact}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span className='registerValuesSpan'><div className='registerValuesDiv' ><p className='registerValuesPhara'>{comment.riskLevel}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                        <span className='registerValuesSpan'><div className='registerValuesDiv' ><p className='registerValuesPhara'>{comment.riskAppetite}</p></div></span>
                </Grid>
      
                {/*riskDisplayStr*/}
      
                {
                    show ?
                        <>
                            {nestedComments}
                        </>
                        : null
                }
            </>
      
        )
      }

      function getSubAvarageRisk(comment) {
        var subTotal = 0
        var avarage = 0;
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            var len = 0;
            var subTotal = 0;
            comment.subRisks.forEach((item) => {
                subTotal = subTotal + parseInt(item.riskLevel);
                len = len + 1;
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    item.subRisks.forEach((item1) => {
                        subTotal = subTotal + parseInt(item1.riskLevel);
                        len = len + 1;
                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                            item1.subRisks.forEach((item2) => {
                                subTotal = subTotal + parseInt(item2.riskLevel);
                                len = len + 1;
                                if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                    item2.subRisks.forEach((item3) => {
                                        subTotal = subTotal + parseInt(item3.riskLevel);
                                        len = len + 1;
                                        if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                            item3.subRisks.forEach((item4) => {
                                                subTotal = subTotal + parseInt(item4.riskLevel);
                                                len = len + 1;
                                                if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                                    item4.subRisks.forEach((item5) => {
                                                        subTotal = subTotal + parseInt(item5.riskLevel);
                                                        len = len + 1;
                                                        if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                            item5.subRisks.forEach((item6) => {
                                                                subTotal = subTotal + parseInt(item6.riskLevel);
                                                                len = len + 1;
                                                                if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {
      
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
      
            })
            avarage = (subTotal / (len));
            //avarage = Math.round(avarage * 100)/100;
            avarage = Math.round(avarage);
        }
        return avarage;
      
      }

      function getSubAvarageAppetite(comment) {
        var subTotal = 0
        var avarage = 0;
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            var len = 0;
            var subTotal = 0;
            comment.subRisks.forEach((item) => {
                subTotal = subTotal + parseInt(item.riskAppetite);
                len = len + 1;
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    item.subRisks.forEach((item1) => {
                        subTotal = subTotal + parseInt(item1.riskAppetite);
                        len = len + 1;
                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                            item1.subRisks.forEach((item2) => {
                                subTotal = subTotal + parseInt(item2.riskAppetite);
                                len = len + 1;
                                if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                    item2.subRisks.forEach((item3) => {
                                        subTotal = subTotal + parseInt(item3.riskAppetite);
                                        len = len + 1;
                                        if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                            item3.subRisks.forEach((item4) => {
                                                subTotal = subTotal + parseInt(item4.riskAppetite);
                                                len = len + 1;
                                                if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                                    item4.subRisks.forEach((item5) => {
                                                        subTotal = subTotal + parseInt(item5.riskAppetite);
                                                        len = len + 1;
                                                        if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                            item5.subRisks.forEach((item6) => {
                                                                subTotal = subTotal + parseInt(item6.riskAppetite);
                                                                len = len + 1;
                                                                if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {
      
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
      
            })
            avarage = (subTotal / (len));
            //avarage = Math.round(avarage * 100)/100;
            avarage = Math.round(avarage);
        }
        return avarage;
      
      }

      function getSubNoReg(comment, riskId) {
        var subNo = "0";
        registerTypeDataForEval.riskTypes.forEach((data) => {
            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                data.subRisks.forEach((item, index) => {
                    if (item.riskTypeId === riskId) {
                        subNo = (index + 1) + "";
                    }
      
                })
            }
        })
        if (subNo === "0") {
            registerTypeDataForEval.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((item, index) => {
                        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                            
                            var tmpN0 = getSubRiskSubNo(item.subRisks, riskId, '');
                            if (tmpN0 != "0") {
                                subNo = (index + 1) + '.' + tmpN0;
                            } else {
                                registerTypeDataForEval.riskTypes.forEach((data1) => {
                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                        data1.subRisks.forEach((item1, index1) => {
                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                item1.subRisks.forEach((item2, index2) => {
                                                    item2.subRisks.forEach((item3, index3) => {
                                                        if (item3.riskTypeId === riskId) {
                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1);
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    }
                                })
                                if (tmpN0 === "0") {
                                    registerTypeDataForEval.riskTypes.forEach((data1) => {
                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                            data1.subRisks.forEach((item1, index1) => {
                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                    item1.subRisks.forEach((item2, index2) => {
                                                        item2.subRisks.forEach((item3, index3) => {
                                                            item3.subRisks.forEach((item4, index4) => {
                                                                if (item4.riskTypeId === riskId) {
                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1);
                                                                }
                                                            })
                                                        })
                                                    })
                                                }
                                            })
                                        }
                                    })
                                    if (tmpN0 === "0") {
                                        registerTypeDataForEval.riskTypes.forEach((data1) => {
                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                data1.subRisks.forEach((item1, index1) => {
                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                        item1.subRisks.forEach((item2, index2) => {
                                                            item2.subRisks.forEach((item3, index3) => {
                                                                item3.subRisks.forEach((item4, index4) => {
                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                        if (item5.riskTypeId === riskId) {
                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1);
                                                                        }
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                        if (tmpN0 === "0") {
                                            registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                    data1.subRisks.forEach((item1, index1) => {
                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                            item1.subRisks.forEach((item2, index2) => {
                                                                item2.subRisks.forEach((item3, index3) => {
                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                if (item6.riskTypeId === riskId) {
                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1);
                                                                                }
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                            if (tmpN0 === "0") {
                                                registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                        data1.subRisks.forEach((item1, index1) => {
                                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                item1.subRisks.forEach((item2, index2) => {
                                                                    item2.subRisks.forEach((item3, index3) => {
                                                                        item3.subRisks.forEach((item4, index4) => {
                                                                            item4.subRisks.forEach((item5, index5) => {
                                                                                item5.subRisks.forEach((item6, index6) => {
                                                                                    item6.subRisks.forEach((item7, index7) => {
                                                                                        if (item7.riskTypeId === riskId) {
                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1);
                                                                                        }
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            }
                                                        })
                                                    }
                                                })
                                                if (tmpN0 === "0") {
                                                    registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                            data1.subRisks.forEach((item1, index1) => {
                                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                    item1.subRisks.forEach((item2, index2) => {
                                                                        item2.subRisks.forEach((item3, index3) => {
                                                                            item3.subRisks.forEach((item4, index4) => {
                                                                                item4.subRisks.forEach((item5, index5) => {
                                                                                    item5.subRisks.forEach((item6, index6) => {
                                                                                        item6.subRisks.forEach((item7, index7) => {
                                                                                            item7.subRisks.forEach((item8, index8) => {
                                                                                                if (item8.riskTypeId === riskId) {
                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1);
                                                                                                }
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                    if (tmpN0 === "0") {
                                                        registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                data1.subRisks.forEach((item1, index1) => {
                                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                        item1.subRisks.forEach((item2, index2) => {
                                                                            item2.subRisks.forEach((item3, index3) => {
                                                                                item3.subRisks.forEach((item4, index4) => {
                                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                                        item5.subRisks.forEach((item6, index6) => {
                                                                                            item6.subRisks.forEach((item7, index7) => {
                                                                                                item7.subRisks.forEach((item8, index8) => {
                                                                                                    item8.subRisks.forEach((item9, index9) => {
                                                                                                        if (item9.riskTypeId === riskId) {
                                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1);
                                                                                                        }
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        })
                                                        if (tmpN0 === "0") {
                                                            registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                    data1.subRisks.forEach((item1, index1) => {
                                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                            item1.subRisks.forEach((item2, index2) => {
                                                                                item2.subRisks.forEach((item3, index3) => {
                                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                                item6.subRisks.forEach((item7, index7) => {
                                                                                                    item7.subRisks.forEach((item8, index8) => {
                                                                                                        item8.subRisks.forEach((item9, index9) => {
                                                                                                            item9.subRisks.forEach((item10, index10) => {
                                                                                                                if (item10.riskTypeId === riskId) {
                                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1) + "." + (index10 + 1);
                                                                                                                }
                                                                                                            })    
                                                                                                        })
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            })
      
                                                        }
      
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
      
      
                            }
      
                        }
      
                    })
                }
            })
      
        }
        return subNo;
      }

  return (
      <>
        <DialogContent> 
            <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                <Grid container spacing={2}>
                    {Object.keys(registerTypeDataForEval).length > 0 &&
                        <>
                            <Grid item xs={12}>
                                <Card className='SelectOwnershipOptionsCard'>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item className='selectOwnerShipGrid1'>
                                                <span className='SelectOwnershipOptionsSpan'>Select Ownership Options : </span>
                                            </Grid>
                                            <Grid item className='selectOwnerShipGrid2'>
                                                <TextField
                                                    label='Ownership Roles'
                                                    sx={{
                                                        width: '100%',
                                                        "& .MuiInputBase-root": {
                                                            height: 45
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            marginTop: -0.5
                                                        }
                                                    }}
                                                    className='SelectOwnershipOptionsTextField'
                                                    select
                                                    value={selectedOwnershipRole}
                                                    onChange={handleChangeOwnershipRole}
                                                    >
                                                    <MenuItem
                                                            value={'all'}
                                                        >
                                                            {'All Ownerships'}
                                                        </MenuItem>    
                                                    {roleNames.map((item) => (
                                                        <MenuItem
                                                            value={item.role}
                                                        >
                                                            {item.role}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                             
                                            <Grid item className='selectOwnerShipGrid3'>
                                                <Button
                                                    variant='outlined'
                                                    className='SelectOwnershipOptionsButton'
                                                   
                                                    onClick={() => { setSelectedItemsFunction() }}
                                                    >
                                                    Finalise
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </CardContent>

                                </Card>
                            </Grid>
                            
                            
                            <Grid item xs={12}>
                                <div>
                                    {
                                        showEvalData && showTableType === 1 ?
                                            <>
                                                <Grid container spacing={1} id='divHierarchicalViewId'>
                                                <Grid item className='SelectAllItemsGrid'>
                                                    <FormControlLabel 
                                                        control={<Checkbox id={"chkAll"}/>} 
                                                        //label={"Select All Items "}
                                                        //style={{marginLeft: '10px'}} 
                                                        onChange={() => { clickAll()}}
                                                    />&nbsp;<span className='SelectAllItemsSpan' >Select All Items</span>
                                                </Grid>
                                                <Grid item className='SelectAllItemsValuesHead'><Tooltip1 title='Likelihood'><span className='SelectAllItemsValuesHeadSpan'>Likelihood</span></Tooltip1></Grid>
                                                <Grid item className='SelectAllItemsValuesHead'><Tooltip1 title='Impact'><span className='SelectAllItemsValuesHeadSpan'>Impact</span></Tooltip1></Grid>
                                                <Grid item className='SelectAllItemsValuesHead'><Tooltip1 title='Risk Level'><span className='SelectAllItemsValuesHeadSpan'>Risk Level</span></Tooltip1></Grid>
                                                <Grid item className='SelectAllItemsValuesHead'><Tooltip1 title='Appetite'><span className='SelectAllItemsValuesHeadSpan'>Appetite</span></Tooltip1></Grid>
                                                {
                                                    registerTypeDataForEval.riskTypes.map((riskType, index) => {
                                                        return (
                                                            <CommentRegister
                                                                key={riskType.riskTypeTmpId}
                                                                comment={riskType}
                                                                lastRiskTmpId={lastRiskTmpId}
                                                                riskNo={index + 1}
                                                                fromMainMenu={TurnedInOutlined}
                                                                editRiskHirachyData={editRiskHirachy}
                                                                allChecked={checkStatus}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </>
                                        : 
                                        ''
                                    }
                                </div>
                            </Grid>
                        </>
                    }
                </Grid>
            </DialogContentText>
        </DialogContent>




        {/** Finalise Risk Register */}

            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}>
                <DialogTitle>
                    Finalise Risk Register with Risk Register name
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To finalise the Risk register with the selected items, please enter the Risk Register Name here.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Risk Register Name"
                                type="text"
                                value={finaliseRiskName}
                                onChange={handleChangeFinaliseRiskName}
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>Cancel</Button>
                    <Button onClick={() => finaliseFunction()}>Finalise</Button>
                </DialogActions>
            </Dialog>

        {/** Dialog Waiting */}
        <Dialog
            maxWidth={'md'}
            open={waitingOpen}
            PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
            >
            <CircularProgress disableShrink />
        </Dialog>
    </>
  )
}

export default FinaliseRegisterComponent