import React, { useState, useEffect } from "react";

const User = ({ data }) => {
  const [user, setUser] = useState({
    skills: [],
    collectionId:'',
    riskTypes: [
        {
          name:"",
          subRisks:[
            {
              name:'',
              subRisks:[
                {
                  name:''
                }
              ]
            }
          ]
        }
    ]
  });

  const createInputs = () => {
    return user.skills.map((skill, idx) => {
      return (
        <div>
          <input value={skill} onChange={e => updateSkill(e, idx)} />
        </div>
      );
    });
  };

  const updateSkill = (e, index) => {
    const userCopy = { ...user };
    userCopy.skills[index] = e.target.value;
    setUser(userCopy);
  };

  const removeSkill = index => {
    const userCopy = { ...user };
    const userCopySkills = [...userCopy.skills];

    userCopySkills.splice(index, 1);

    setUser({
      ...userCopy,
      skills: [...userCopySkills]
    });
  };

  const addSkill = () => {
    setUser(prevState => {
      return {
        ...prevState.user,
        skills: [...prevState.skills, ""]
      };
    });
  };

  useEffect(() => {
    console.log(user);
  }, [user]);

  return (
    <div style={{marginTop:'100px'}}>
      
      <div>{createInputs()}</div>
      <button onClick={addSkill}>Add</button>
    </div>
  );
};

export default User;