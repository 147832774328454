import React, { useState } from "react";
import axios from "axios";

const DataTable = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  function imageUplad(e){
      e.preventDefault()
   // let file = selectedFile

    let formdata = new FormData()

    formdata.append('file',selectedImage)

    console.log(selectedImage)

    axios('https://192.168.5.9:9001/image/upload',{
            method: 'post',
             data: formdata,
            })
            .then(function (response) {
            console.log(response.data);
            
            })
            .catch(function (error) {
            console.log(error);
            });
  }

  return (
    <div>
        <form>
      <h1>Upload and Display Image usign React Hook's</h1>
      {selectedImage && (
        <div>
        <img alt="not fount" width={"250px"} src={URL.createObjectURL(selectedImage)} />
        <br />
        <button onClick={()=>setSelectedImage(null)}>Remove</button>
        </div>
      )}
      <br />
     
      <br /> 
      <input
        type="file"
        name="file"
        onChange={(event) => {
          console.log(event.target.files[0]);
          setSelectedImage(event.target.files[0]);
        }}
      />
      <button onClick={(e)=>imageUplad(e)}>save</button>
      </form>
    </div>
  );
};

export default DataTable;