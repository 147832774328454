import React from 'react';

const jsonData = {
  _id: "653a3fdbc606c74c8d0f3ab1",
  centreId: "CT2",
  userCode: "ct2customer5",
  firstName: "Athula5ct2",
  lastName: "Perera5ct2",
  userMailAddress: "mailto:athulawts@gmail.com",
  trailStartDate: new Date("2023-09-26T10:30:51.861Z"),
  trialDays: [
    {
      amendDate: "2023-10-20T10:30:51.861Z",
      noOfIncreaseTrialDays: 5,
      endDate: "2023-10-25T10:30:51.861Z",
    },
    {
      amendDate: "2023-09-26T10:30:51.861Z",
      noOfIncreaseTrialDays: 14,
      endDate: "2023-10-10T10:30:51.861Z",
    }
  ]
};

const sortedTrialDays = jsonData.trialDays.sort((a, b) => new Date(a.amendDate) - new Date(b.amendDate));

function App() {
  return (
    <div>
      <h1>Sorted Trial Days</h1>
      <pre>{JSON.stringify({ ...jsonData, trialDays: sortedTrialDays }, null, 2)}</pre>
    </div>
  );
}

export default App;
