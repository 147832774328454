import React, {useEffect,useState} from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import CardHeader from '@mui/material/CardHeader';
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Links from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import PersonAdd from '@mui/icons-material/PersonAdd';
import ListItemIcon from '@mui/material/ListItemIcon';
import { CONFIG } from '../config'
import Tooltip from '@mui/material/Tooltip'
import CollectionImg from '../assets/collection.jpg'
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import { PersonAddAltSharp, PersonRemoveAlt1Rounded, VerifiedUser } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import RightBarDataForUserPages from '../Components/RightBarDataForUserPages';



function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));  


const EntityChange = () => {

    //dialog
    const [waitingOpen, setWaitingOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    const [maxWidth, setMaxWidth] = React.useState('md');
    const [entityMapOpen, setEntityMapOpen] = React.useState(false);
    const [entityMapScroll, setEntityMapScroll] = React.useState('paper');

    const [entityMapArr, setEntityMapArr] = React.useState([]);
    const [entityMapCount, setEntityMapCount] = React.useState(0);
  
    
    const entityMapHandleClickOpen = (scrollType) => () => {
      setEntityMapOpen(true);
    };
    const entityMapHandleClickClose = () => {
      setEntityMapOpen(false);
      setEntityMapCount(0);
      setEntityMapArr([]);
    };


  
  const classes = useStyle();

  const [useName, setUseName] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [golobalCentreId, setGolobalCentreId] = React.useState();
  const [collecId, setcollecId] = React.useState();
  const [quesData, setQuesData] = useState([]); // All Questions In a collectionId 
  const [quesTitle, setQuesTttle]= useState()
  const [abc, setAbc] = useState()
  const [centerCollection, setCenterCollection] = useState([]) // Get All Collections in a Center
  const [collectionFunc, setCollectionFunc] = useState([])

  const [canBuycollection, setCanBuyCollection] = useState([]); // Get All Collections  

  console.log(collectionFunc)

  /** Sort abject sort array */
function compare( a, b ) {
  var nameA = a.entity.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.entity.name.toUpperCase(); // ignore upper and lowercase

  if ( nameA < nameB ){
      return -1;
  }
  if ( nameA > nameB ){
      return 1;
  }
  // names must be equal
  return 0;
}

function compareQa( a, b ) {
  var nameA = a.createdDate; // ignore upper and lowercase
  var nameB = b.createdDate; // ignore upper and lowercase
  if ( nameA < nameB ){ return -1;}
  if ( nameA > nameB ){ return 1;}
  return 0;
}
function compareQd( a, b ) {
  var nameA = a.createdDate; // ignore upper and lowercase
  var nameB = b.createdDate; // ignore upper and lowercase
  if ( nameA > nameB ){ return -1;}
  if ( nameA < nameB ){ return 1;}
  return 0;
}


  useEffect(() => {
    getData();
  }, [])

  function getData () {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId);
    getEntityData(data.centreId);
    getAllCollection(data.centreId);
    setUseName(data.name);
    getCentreData(data.centreId);
    console.log(data.name);
  }

  const [centreName, setCentreName] = React.useState('');
    function getCentreData(centreId){
      var data = '{getCentreData(centreId:\"' + centreId + '\"){centreId,centreName}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          console.log("++++++++++++++++Return centreData++++++++++++++++");
          var centreData = response.data.data.getCentreData;
          console.log(centreData);
          setCentreName(centreData.centreName);
  
      })
      .catch(function (error) {
          console.log("Centre Data Loding error" + error);
      });
    }

  /** Get All Available Collection */
  function getAllCollection(centerId){
    axios(CONFIG.MAIN_URL+'/questionnaire-client/all',{
      method: 'get',
    })
    .then(function (response) {
      console.log("++++++++++++++++Get All Available Collection+++++++++++++++++++");
      console.log((response.data));
      var arr  = response.data
      arr = arr.sort (compareQd);
      console.log((arr));
      setCanBuyCollection(arr);

    })
    .catch(function (error) {
      console.log(error);
    });

  }




  /** Get Questions In a Collection Id*/
  const [buyQuestionShow, setBuyQuestionShow] = useState(true) // Get All Collections in a Center

  function getQuestionsInCollection(collectionUniqueName, no){
    if (no === 1){
      setBuyQuestionShow(false);
    }else{
      setBuyQuestionShow(true);
    }
    console.log(collectionUniqueName)
    axios(CONFIG.MAIN_URL+'/questionnaire-client/all?collectionUniqueName='+ collectionUniqueName,{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get All Questions Using collectionUniqueName")
      //setQuesData(response.data.questions)
      setQuesTttle(response.data[0].title)
      var tmpRiskArr = [];
      if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
        tmpRiskArr = response.data[0].risks; 
      }
      getAllQuestions(tmpRiskArr);


    })
    .catch(function (error) {
      console.log(error);
    });
  }

  /** Get all question from the collection and put in to attay */
  var allQuesArray = [];
  function getAllQuestions(riskArray){
    allQuesArray = [];
    riskArray.forEach((item)=>{
      if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
        item.riskTypes.forEach((item1)=>{
          if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
            item1.questions.forEach((item2)=>{
              allQuesArray.push(item2);
            })  
          }
          if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
            getAllQuestionsInSubRisks(item1.subRisks);
          }
        });
      }
    });
    if (allQuesArray.length > 0){
      var quesArray = allQuesArray.sort (compare);
      setQuesData(allQuesArray);
    }
  }

  function getAllQuestionsInSubRisks(riskArray){
    riskArray.forEach((item)=>{
      if (item.questions != undefined && item.questions != null && item.questions.length > 0){
        item.questions.forEach((item1)=>{
          allQuesArray.push(item1);
        })  
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
        getAllQuestionsInSubRisks(item.subRisks);
      }
    });  
  }








  /** Get All Entity Data and set Centre Entity data from it*/
  const [centreEntityData, setCentreEntityData] = React.useState([]);  
  function getEntityData(centerId){
      var data = '{allEntityList{ entityDataList{entityName,entityId,isActive,createdBy,lastModifiedBy,centreId,basicEntity{basicEntityId,entityName,iamRole,forceIamRole}}}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          var entityList = response.data.data.allEntityList.entityDataList;
          console.log("All entity List");
          console.log(entityList);
          var tmpEntityData = [];
          entityList.forEach((item, index)=>{
              if (item.isActive && item.centreId === centerId) {  
                var forceIamRole = item.basicEntity.forceIamRole;
                var iamRole = item.basicEntity.iamRole;  
                var canTake = true;
                if ((iamRole === "ADMIN" && forceIamRole === "ADMIN") || (iamRole === "supplier" && forceIamRole === "supplier") || (iamRole === "serviceprovider" && forceIamRole === "serviceprovider") || (iamRole === "volunteer" && forceIamRole === "volunteer")){
                  canTake = false;
                }
                if (canTake) {
                  var be = item.basicEntity
                  var arrayData = {}
                  arrayData["entityId"] = item.entityId;
                  arrayData["entityName"] = item.entityName;
                  tmpEntityData.push(arrayData);
                }
              }
          })
          console.log(tmpEntityData);
          setCentreEntityData(tmpEntityData);
      })
      .catch(function (error) {
          console.log("Entity Loding error" + error);
      });
  }



  /** Get Unique Array From Array */
  function getUnique(arr, index) {
    const unique = arr
         .map(e => e[index])
         // store the keys of the unique objects
         .map((e, i, final) => final.indexOf(e) === i && i)
         // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);      
     return unique;
  }


  /** buy collection */
  const [quesEntityArr, setQuesEntityArr] = React.useState([]);
  const [buyCollectionId, setBuyCollectionId] = React.useState('');
  const [buyCollectionUniqueName, setBuyCollectionUniqueName] = React.useState('');
  const [buyCollectionObject, setBuyCollectionObject] = React.useState(null);
  const [buyCollectionTitle, setBuyCollectionTitle] = React.useState('');
  const [buyCollectionStatus, setBuyCollectionStatus] = React.useState('');
  const [buyCollectionCenterId, setBuyCollectionCenterId] = React.useState('');

  function buyCollection(collectionUniqueName,e){
    axios(CONFIG.MAIN_URL+'/questionnaire-client/all?collectionUniqueName='+ collectionUniqueName,{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get Questions To get entities");
      console.log(response.data);
      setBuyCollectionId(response.data[0].collectionId);
      setBuyCollectionUniqueName(response.data[0].collectionUniqueName);
      setBuyCollectionObject(response.data[0]);
      setBuyCollectionTitle(response.data[0].title);
      setBuyCollectionStatus(response.data[0].status);
      setBuyCollectionCenterId(response.data[0].centreId);

      var tmpRiskArr = [];
      if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
        tmpRiskArr = response.data[0].risks; 
      }
      getAllQuestionsForEntity(tmpRiskArr);

    })
    .catch(function (error) {
      console.log(error);
    });

  }

/** Get all question for Entity from the collection and put in to attay */
var allQuesArray = [];
function getAllQuestionsForEntity(riskArray){
  allQuesArray = [];
  riskArray.forEach((item)=>{
    if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
      item.riskTypes.forEach((item1)=>{
        if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
          item1.questions.forEach((item2)=>{
            allQuesArray.push(item2);
          })  
        }
        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
          getAllQuestionsForEntityInSubRisks(item1.subRisks);
        }
      });
    }
  });
  if (allQuesArray.length > 0){
    var entityArr = [];
    allQuesArray.forEach((item)=>{
      entityArr.push(item.entity);
    })
    var questionEntity  = getUnique(entityArr,'entityId');
    console.log(questionEntity);
    setQuesEntityArr(questionEntity);
    setEntityMapOpen(true);
    setEntityMapCount(0);
    setEntityMapArr([]);



    setQuesData(allQuesArray);
  }
}

function getAllQuestionsForEntityInSubRisks(riskArray){
  riskArray.forEach((item)=>{
    if (item.questions != undefined && item.questions != null && item.questions.length > 0){
      item.questions.forEach((item1)=>{
        allQuesArray.push(item1);
      })  
    }
    if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
      getAllQuestionsForEntityInSubRisks(item.subRisks);
    }
  });  
}






 
  const entityMaphandleChange = (event) => {
    var tmpArr = [];
    var tmpObj = [];
    var selectValue = event.target.value;
    var selectValueArr = selectValue.split("]");
    var quesEntityId = selectValueArr[0];
    tmpObj["quesEntityId"] = selectValueArr[0];
    tmpObj["quesEntityName"] = selectValueArr[1];
    tmpObj["centerEntityId"] = selectValueArr[2];
    tmpObj["centerEntityName"] = selectValueArr[3];

    if (entityMapCount === 0){
      tmpArr.push(tmpObj);
      setEntityMapArr(tmpArr);
      setEntityMapCount(entityMapCount + 1);
    }else{
      entityMapArr.forEach((item, index)=>{
        if (item.quesEntityId === quesEntityId ){
          entityMapArr.splice(index, 1);  
          setEntityMapArr(entityMapArr);
        }
      });
      entityMapArr.push(tmpObj);
      setEntityMapArr(entityMapArr);
    }

  };

  const entityMaptableRowData = (
    <>
      <TableBody>
        {quesEntityArr.map((row,index) => (
            <StyledTableRow key={row.id} >
                <StyledTableCell component="th" scope="row" className={classes.tableEntityMapConfirmTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                  {row.name}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableEntityMapConfirmTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                    <FormControl sx={{ m: 0.5, minWidth: '100%' }} size="small">
                        <InputLabel id="select-select-label">Select</InputLabel>
                        <Select
                            labelId="select-select-label"
                            id={"entityMap" + index}
                            onChange={entityMaphandleChange}
                            label="Select"
                            >
                            {centreEntityData.map((item)=>(
                                <MenuItem 
                                    value={`${row.entityId}]${row.name}]${item.entityId}]${item.entityName}`}
                                >
                                {item.entityName}
                            </MenuItem>
                            ))}    
                        </Select>
                    </FormControl>
                </StyledTableCell>
            </StyledTableRow>
        ))}
      </TableBody>  
    </>  
  )

  
  function confirmEntityMapFunction() {
    
    var canBuy = true;
    quesEntityArr.forEach((data)=>{
      var canTaken = false;
      entityMapArr.forEach((item)=>{
        if (parseInt(item.quesEntityId) === data.entityId ){
          canTaken = true;
        }
      });
      if (!canTaken){
        canBuy = false;
      }
    })
    if (!canBuy) {
      alert("You have not mapped the entities completely.");
    }else{
        let obj = {};
        obj["collectionUniqueName"]= buyCollectionUniqueName;
        var tmpArr = [];
        entityMapArr.forEach((item)=>{
          let obj1 = {}
          obj1["expertEntityId"]= parseInt(item.quesEntityId);
          obj1["expertEntityName"]= item.quesEntityName;
          obj1["userEntityId"]= parseInt(item.centerEntityId);
          obj1["userEntityName"]= item.centerEntityName;
          tmpArr.push(obj1);
        });
        obj["entityMap"]= tmpArr;
        var sendJson = JSON.stringify(obj);
        console.log("+++++++++++++++++++sendJson++++++++++++++++++++");
        console.log(sendJson);

        setWaitingOpen(true);
        
        axios(CONFIG.MAIN_URL+'/questionnaire-client/change-entity',{
            method: 'post',
            headers: { 
              'Content-Type': 'application/json',        
            },  
            data:sendJson
        })
        .then(res =>  {
            console.log("++++++++++++++Change Entity Return Data+++++++++++++++++");
            console.log(res.data);
            getAllCollection(golobalCentreId);
            getEntityData(golobalCentreId);
            setWaitingOpen(false);
            alert('Successfully Changed the Entities in Questionnaire Collection')
            setEntityMapOpen(false);
        })
        .catch(function (error) {
          setWaitingOpen(false);
          console.log(error);
        });

        
      
    }

  }


  const [PageName, setPageName] = useState('Change Your Entity')
  return (
    <>
        {CONFIG.EXPERT_LOGIN?
          <RightBarDataForOtherPages PageName={PageName}/> 
        :
          <RightBarDataForUserPages PageName={PageName} CentreName={centreName}/> 
        }
        <div className={classes.pageTop}>
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.pageBodyStyle}>
                    <Grid item xs={12}>
                        <div role="presentation" onClick={handleClick}>
                          <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" to="/login" id='focusHomeLink'>
                              Home
                            </Link>
                            <Typography color="text.primary">Change Entity</Typography>
                          </Breadcrumbs>
                        </div>
                    </Grid>

                    

                    {
                      canBuycollection.map((quesmainn,index) => {
                        //if (quesmainn.centreId != golobalCentreId && !centerCollection.includes(n => n.collectionId)) {
                          return(
                            <Grid item xs={6} >
                              <Card variant="outlined" className={classes.cardoutcenter}>
                                <CardHeader className={classes.qusCardHeadermapdata} 
                                  avatar={
                                    <Avatar sx={{ bgcolor: '#9155FD' }} aria-label="recipe">
                                        {quesmainn.title.charAt(0)}
                                    </Avatar>
                                  }
                                  action={
                                    <>
                                      <p quesmainn={classes.pMarginBottomZero}> 
                                      </p> 
                                    </>
                                  }
                                  title={<Typography sx={{marginTop:"-5px"}}>{quesmainn.title}</Typography>}
                                  subheader={index==0?<Typography sx={{color: "green",marginTop:"-44px"}}>Last Purchased</Typography>:''}
                                />
                                <div>
                                <CardContent>
                                  <div className={classes.quesimggg}>
                                    <Grid container spacing={1}>
                                      <Grid item xs={3} sx={{m:1}}>
                                        <div>
                                          {/* 
                                          <img src={quesmain.imageUrl} alt={quesmain.title} height={100} width={100} ></img>
                                          */}
                                          <img src={CollectionImg} alt={quesmainn.title} height={80} width={80} ></img>
                                        </div>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <div className={classes.descriptionStyle}>
                                          <p>{quesmainn.description}</p>
                                        </div> 
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Stack direction="row" spacing={1} className={classes.rowbtn}>
                                          <Button variant="outlined"  className={classes.btnwidth} 
                                            onClick={() =>{ getQuestionsInCollection(quesmainn.collectionUniqueName,2);handleClickOpen()}}
                                            >
                                            see some questions
                                          </Button>
                                          <Button variant="contained" endIcon={<PersonRemoveAlt1Rounded/>} className={classes.btnwidthcolorbtn}
                                            onClick={()=>buyCollection(quesmainn.collectionUniqueName)}
                                            >
                                            Change Entities
                                          </Button>
                                        </Stack>
                                      </Grid>  
                                    </Grid>
                                  </div>  
                                </CardContent> 
                              </div>
                          </Card>
                        </Grid>
                          )
                        //}
                        
                      })
                    }
  
                </Grid>
            </Grid>  
        </div>

{/** Start Dialog Boxes */}   
{/** Question display for each Collection */}                
<div>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'sm'}
      >
      <DialogTitle id="alert-dialog-title" style={{textAlign:'center'}}>
        {quesTitle}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          id="alert-dialog-description">
            {buyQuestionShow && 
              quesData.map((quData,index)=>{
                if (index < 5) {
                    return(
                      <Grid container spacing={1}>
                        <Grid item xs={1}>
                          {index+1} )
                        </Grid>
                        <Grid item xs={11}>
                          {quData.question}
                        </Grid>
                      </Grid>
                    )
                }
              })
            }
            {!buyQuestionShow && 
              quesData.map((quData,index)=>{
                    return(
                      <Grid container spacing={1}>
                        <Grid item xs={1}>
                          {index+1} )
                        </Grid>
                        <Grid item xs={11}>
                          {quData.question}
                        </Grid>
                      </Grid>
                    )
              })
            }
        </DialogContentText>
      </DialogContent>
        <DialogActions dividers>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
    </Dialog>
  </div>

  {/** Dialog for entity Napping */}

  <Dialog
    maxWidth={maxWidth}
    open={entityMapOpen}
    onClose={entityMapHandleClickClose}
    scroll={entityMapScroll}
    aria-labelledby="entitymap-scroll-dialog-title"
    aria-describedby="entitymap-scroll-dialog-description"
    >
    <DialogTitle id="draggable-dialog-title">
        Please specify the Entity that you need to map from the selected Questionnaire Collection 
    </DialogTitle>
    <DialogContent dividers={entityMapScroll === 'paper'}>
      <DialogContentText
        id="entitymap-scroll-dialog-description"
        tabIndex={-1}
        >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 695 }} aria-label="customized table">
                <TableHead className={classes.tableHeadEntityMapStyle}>
                  <TableRow>
                      <StyledTableCell className={classes.tableThEntityMapStyle} style={{width: '50%'}}>From Questionnaire Entity</StyledTableCell>
                      <StyledTableCell className={classes.tableThEntityMapStyle} style={{width: '50%'}}>To Your Entity</StyledTableCell>
                  </TableRow>
                </TableHead>
                {entityMaptableRowData}
              </Table>
            </TableContainer>    
          </Grid>     
          <Grid item xs={12}>
            <Button 
                variant="outlined" 
                size='small'
                className={classes.btnBorderGreenEntityMap}
                onClick={() => confirmEntityMapFunction()} 
                style={{float:'right'}}>
                    CONFIRM
            </Button>
          </Grid>
       </Grid>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={entityMapHandleClickClose}>Cancel</Button>
    </DialogActions>
  </Dialog>


{/** Dialog Waiting */}
<Dialog
    maxWidth={'md'}
    open={waitingOpen}
    PaperProps={{style:{backgroundColor: 'transparent', boxShadow: 'none'}}} 
    >
    <CircularProgress disableShrink />
  </Dialog>
    </>







  )
}

export default EntityChange