import React, {useEffect,useState} from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import CardHeader from '@mui/material/CardHeader';
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Links from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import PersonAdd from '@mui/icons-material/PersonAdd';
import ListItemIcon from '@mui/material/ListItemIcon';
import { CONFIG } from '../config'
import Tooltip from '@mui/material/Tooltip'
import CollectionImg from '../assets/collection.jpg'

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const QuestionnnaireMainPage = () => {
  
  
  const classes = useStyle();
  const [collection, setCollection] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [golobalCentreId, setGolobalCentreId] = React.useState();
  const [usrName, setUserName] = useState()

  useEffect(() => {
    getData();    
    }, [])

    function getData () {
      let data = sessionStorage.getItem('sessionstorage');
      data = JSON.parse(data);
      setGolobalCentreId(data.centreId);
      getCollection(data.centreId)
      setUserName(data.name)
      console.log(data.name)
    }

    /** Sort abject sort array */
    function compare( a, b ) {
      var nameA = a.title.toUpperCase(); // ignore upper and lowercase
      var nameB = b.title.toUpperCase(); // ignore upper and lowercase
      if ( nameA < nameB ){
          return -1;
      }
      if ( nameA > nameB ){
          return 1;
      }
      // names must be equal
      return 0;
  }

    function getCollection(cId){
      console.log("CONFIG.MAIN_URL");
      console.log(CONFIG.MAIN_URL);
      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?centreId='+ cId,{
        method: 'get',
      })
      .then(function (response) {
        console.log(("+++++++++++++++++++++++++++++++++++++++++++++++"));
        console.log((response.data));
        var sortArr = response.data.sort( compare);
        console.log((sortArr));

        setCollection(sortArr);
      })
      .catch(function (error) {
      console.log(error);
      });
    }












  return (
    <>
        <div className={classes.pageTop}>
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.pageBodyStyle}>
                    <Grid item xs={12}>
                        <Box sx={{ minWidth: 120 }}>
                          <Card className={classes.root}>
                              <div className={classes.posRelative}>      
                                  <CardMedia component="img" image={titleImage} title="Risk Group" alt="Ris Group"/> 
                                      <div className={classes.topImagePos}> 

                                          <p className={classes.topHeaderText1}>
                                            Welcome {usrName}
                                          </p>
                                          <p className={classes.firstphaQM}> Create and Manage your own collection of risk ICF assessment questionnaires.</p>
                                      </div>  
                              </div>
                          </Card>        
                        </Box>
                    </Grid> 
                    <Grid item xs={9}>
                      <div role="presentation" onClick={handleClick}>
                        <Breadcrumbs aria-label="breadcrumb">
                          <Link underline="hover" color="inherit" to="/login">
                            Home
                          </Link>
                          <Typography color="text.primary">Questionnaires Collection Management</Typography>
                        </Breadcrumbs>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                    <Link to={`/newcollection`} style={{textDecoration:'none'}}>
                      <Button 
                        type='submit' 
                        //onClick={`/newcollection`} 
                        variant="outlined"
                        className={classes.btnBorderGreenHomePage}
                        style={{float:'right'}}
                        > 
                        Add A New Collection
                      </Button>
                      </Link>

                    </Grid>

                    {
                      collection.map((quesmain) => (
                        <Grid item xs={4} >
                          <Card variant="outlined" className={classes.cardoutQM}>
                            <CardHeader className={classes.qusCardHeadermapdataQM} 
                              avatar={
                                <Avatar sx={{ bgcolor: '#9155FD' }} aria-label="recipe">
                                    {quesmain.title.charAt(0)}
                                </Avatar>
                              }
                              action={
                                <>
                                  <p className={classes.pMarginBottomZeroQM}> 
                                    <Link 
                                      id={quesmain.id} to={`/editcollection/${quesmain.collectionUniqueName}`}
                                      >
                                      <Tooltip title="Edit this Collection">
                                        <EditIcon />
                                      </Tooltip>
                                    </Link> 
                                  </p> 
                                </>
                              }
                              titleTypographyProps={{
                                fontSize: 14,
                                fontWeight: 500,
                              }}
                              title={quesmain.title}
                            />
                            <CardContent>
                              <Link title='Click Here for Questionnaires Management' to={`/questionManagement/${quesmain.collectionUniqueName}`}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} className={classes.centerCententQM}>
                                      {/* 
                                      <img src={quesmain.imageUrl} alt={quesmain.title} height={80} width={80} ></img>
                                      <img src={CollectionImg} alt={quesmain.title} height={80} width={80}></img>
                                      */}
                                      <img src={quesmain.imageUrl} alt={quesmain.title} height={80} width={80} ></img>
                                      
                                  </Grid>
                                  <Grid item xs={12} className={classes.descriptionStyleQM}>
                                      {quesmain.description}
                                  </Grid>
                                </Grid>
                              </Link>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                <Link title='Click Here for Questionnaires Management' to={`/questionManagement/${quesmain.collectionUniqueName}`}>
                                  <Button 
                                      variant="outlined"
                                      id="riskEvaluateBtn" 
                                      className={classes.btnBorderRedEvalQM}
                                      //onClick={riskEvalTypeHandleClickOpen('paper',quesmain.collectionUniqueName)}
                                      >
                                      Evaluate
                                  </Button>
                                </Link>  
                                </Grid>

                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))
                    }
                    <Grid item xs={12} style={{height: '15px'}} id="lastGridId">
                      &nbsp;
                    </Grid>
                    
                </Grid>
            </Grid>  
        </div>



    </>
  )
}

export default QuestionnnaireMainPage
