import React from 'react'
import Box from '@mui/material/Box';
import { Grid, makeStyles } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const RiskAppetiteSlider = ({appetitesShowSelectBar,appetiteSliderType,appetiteHandleChange,riskCategoryMethodDataAppetite,selectedColorForAppetiteSlider,radioHandler,setSliderValueFunction,appetitesSliderValueStyle,appetiteClearFunction,appetitesSliderValueName,appetitesSliderValue,appetiteNameChange,SliderName,MethodDropdown}) => {
  return (
    <>

    {
      appetitesShowSelectBar ?

        <div>

          <Box >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-labelll">{MethodDropdown}</InputLabel>
                  <Select
                    labelId="demo-simple-select-labelll"
                    id="demo-simple-selectSS"
                    value={appetiteSliderType}
                    label="AgeS"
                    onChange={appetiteHandleChange}
                  >
                    {riskCategoryMethodDataAppetite.map((data, index) => (

                      <MenuItem value={data.id}>{data.methodName}</MenuItem>


                    ))}

                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

        </div>


        : null
    }


    {
      riskCategoryMethodDataAppetite.map((data) => (
        <>
          {
            appetiteSliderType === data.id ?
              <>
                <p style={{ color: 'black' }}>{SliderName}</p>

                <Grid container spacing={2}>

                  <Grid item xs={8}>
                
                 
                    <FormLabel id="radio-buttons-group-label">Please select your color scheme,</FormLabel>
                    <div style={{display:'flex'}}>
                    <RadioGroup
                        row
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        >
                        <FormControlLabel 
                            value="primary" 
                            control={<Radio />} 
                            label="Risk " 
                            checked={selectedColorForAppetiteSlider === 1}
                            onClick={(e) => radioHandler(1)}    
                        />
                        <FormControlLabel 
                            value="secondary" 
                            control={<Radio />} 
                            label="Preferences" 
                            checked={selectedColorForAppetiteSlider === 2}
                            onClick={(e) => radioHandler(2)}
                        />
                    </RadioGroup>
                    </div>
          
                    <div>

                      <input
                        id='slider'
                        type="range"
                        min={data.minimumWeightingNumber}
                        max={data.maximumWeightingNumber}
                        value={appetitesSliderValue}
                        //onChange={({ target: { value } }) => setAppetiteSliderValue(value)}
                        onChange={({ target: { value } }) => {setSliderValueFunction(value, data.minimumWeightingNumber, data.maximumWeightingNumber, 2);appetiteNameChange(value)}}
                        className={`slider`}
                        // className={btnEntityClass()}
                        style={{ background: `${appetitesSliderValueStyle}` }}
                        //onClick={appetiteNameChange} 
                        />
                    </div>
                  </Grid>
                  {/* <Grid item xs={2}>
                    <div >
                      <div >
                        <p style={{ fontSize: '10px' }}>{appetitesSliderValueName}</p>
                      </div>

                    </div>
                  </Grid> */}
                  <Grid item xs={1}>
                    <div>
                      <Button variant="outlined" onClick={appetiteClearFunction}>clear</Button>
                    </div>
                  </Grid>


                </Grid>
                <p>
                  <b>{appetitesSliderValue}&nbsp;-&nbsp;{appetitesSliderValueName}</b>
                </p>
              </>



              : null
          }
        </>
      ))
    }
    </>
  )
}

export default RiskAppetiteSlider