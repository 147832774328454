import React, { useEffect, useState, useRef } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import axios from "axios";
import CardContent from "@mui/material/CardContent";
import { CONFIG } from "../config";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { Info, KeyboardReturnOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import "../Css/Slider.css";
import Draggable from "react-draggable";
import useStyle from "../Css/MainCss";
import Delete from "@mui/icons-material/Delete";
import { Editor } from "@tinymce/tinymce-react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { Select, InputLabel } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { data } from "jquery";
import parseHtml from 'html-react-parser';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  cardRiskManage: {
    width: "100%",
    border: "1px solid black",
  },
  btnColorPurple: {
    fontSize: "12px !important",
    backgroundColor: "#BACDF2 !important",
    height: "40px",
    textAlign: "center",
    color: "#4C7EE0 !important",
    border: "1px solid #4C7EE0",
  },
  thStyle: {
    fontSize: "12px !important",
    lineHeight: "0.5rem !important",
    borderBottom: "0px !important",
  },
  thStyleImage: {
    lineHeight: "0.5rem !important",
    borderBottom: "0px !important",
    width: "130px !important",
  },
  riskImageSize: {
    width: "120px",
    height: "100px !important",
  },
  root: {
    position: "relative",
  },
  posRelativeAndDivBorder: {
    position: "relative",
    border: "1px solid #4C7EE0",
    width: "120px",
    height: "100px !important",
  },
}));
const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 2.75rem;
  }
`;

const ArticlePagesComponent = () => {
  const classe = useStyle();
  const classes = useStyles();
  const editorRef = useRef(null);
  const Input = styled("input")({
    display: "none",
  });

  const [aboutUsDesign, setAboutUsDesign] = useState("");

  const [aboutUsDesignId, setAboutUsDesignId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = React.useState();
  const [imgLocation, setImgLocation] = useState("");

  const [cretedUserName, setCretedUserName] = React.useState("");
  const [aeticleTitle, setAeticleTitle] = React.useState("");
  const [orderNumber, setOrderNumber] = React.useState("");

  const onTextFieldChangeCretedUserName = (event) => {
    setCretedUserName(event.target.value);
  };

  const onTextFieldChangeAeticleTitle = (event) => {
    setAeticleTitle(event.target.value);
  };

  const onTextFieldChangeOrderNumber = (event) => {
    setOrderNumber(event.target.value);
  };

  function clearImageFuction() {
    setPreview();
    setSelectedFile(null);
    setImgLocation("");
  }

  React.useEffect(() => {
    getTemplates();
    getHtmlData();

    if (!selectedFile) {
      getHtmlData();
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const [tinyMCETemplateList, setTinyMCETemplateList] = React.useState();

  var templateArr = [];
  function getTemplateHtml(fileName) {
    axios("https://www.daytaem.co.uk/readFile.php?q=" + fileName, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        var tmpA = fileName.split(".");
        var templateObj = {};
        templateObj["title"] = tmpA[0].replace("RST_", "");
        templateObj["description"] = "Risk System - Template ";
        templateObj["content"] = res.data;
        templateArr.push(templateObj);
        setTinyMCETemplateList(templateArr);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getTemplates() {
    var filesArr = "";
    axios("https://www.daytaem.co.uk/getFile.php?q=aaaaaaa", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        var resData = response.data;
        var arrTmp = resData.split("@@");
        var filesStr = arrTmp[1];

        filesArr = filesStr.split("]");
        var k = 1;

        var templateHtmlArr = [];
        for (var i = 1; i < filesArr.length; i++) {
          if (filesArr[i] != "") {
            if (filesArr[i].slice(0, 3) === "RST") {
              var fileName = filesArr[i];
              getTemplateHtml(fileName);
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const [allAboutUsData, setAllAboutUsData] = React.useState([]);
  const [allDomainData, setAllDomainData] = React.useState([]);
  function getHtmlData() {
    axios(CONFIG.MAIN_URL + `/blogArticlePage/all`, {
      method: "get",
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then(function (response) {
        console.log("All Collections Returns");
        console.log(response.data);
        setAllAboutUsData(response.data);
        var rtnData = response.data;
        var domainArr = [];
        rtnData.forEach((data, index) => {
          if (index === 0) {
            var obj = {};
            obj["name"] = data.domainName;
            domainArr.push(obj);
          } else {
            var dName = data.domainName;
            var found = false;
            domainArr.forEach((item, index) => {
              if (item.name === dName) {
                found = true;
              }
            });
            if (!found) {
              var obj = {};
              obj["name"] = dName;
              domainArr.push(obj);
            }
          }
        });
        setAllDomainData(domainArr);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [selectedDomainName, setSelectedDomainName] = useState("");
  const [selectedKeycodeArr, setSelectedKeycodeArr] = useState([]);

  const domainSelecter = (event, newValue) => {
    clearFunction();
    setSelectedDomainName(newValue);
    var tArr = [];
    allAboutUsData.map((data, index) => {
      if (data.domainName === newValue) {
        tArr.push(data);
      }
    });

    setSelectedKeycodeArr(tArr);
    //setDisplayAddNewBtn(true);
  };
  const domainSelecter1 = (event) => {
    clearFunction();
    setSelectedDomainName(event.target.value);
    var tArr = [];
    allAboutUsData.map((data, index) => {
      if (data.domainName === event.target.value) {
        tArr.push(data);
      }
    });

    setSelectedKeycodeArr(tArr);
    //setDisplayAddNewBtn(true);
  };

  const [selectedKeycode, setSelectedKeycode] = useState("");
  const [selectedVersionsArr, setSelectedVersionsArr] = useState([]);

  function clearkeyCode() {
    setDisplayEditBtn(false);
    setDisplayAddNewBtn(false);
    setSelectedVersionNo("");
    setSelectedId("");
    setBaseVersionNo("");
    setDisplayEditedPage("none");
    setAboutUsDesignId("");
    setAboutUsDesign("");
    editorRef.current.setContent("");
    setSelectedCurrentVersion(false);
    //setSelectedDomainName("");
    setSelectedVersionsArr([]);
    setImgLocation("");
    setPreview();
    setCretedUserName("");
    setAeticleTitle("");
    setCommentBtn(false);
    setArticleComments([]);
    //setSelectedKeycodeArr([]);
    setSelectedKeycode("");
  }

  const ketcodeSelecter = (event, newValue) => {
    clearkeyCode();
    setSelectedKeycode(newValue);
    setDisplayAddNewBtn(false);
    setDisplayEditBtn(true);
  };

  const ketcodeSelecter1 = (event) => {
    clearkeyCode();
    setSelectedKeycode(event.target.value);

    var found = false;
    allAboutUsData.forEach((data) => {
      if (data.keyCode === event.target.value) {
        setDisplayAddNewBtn(false);
        setDisplayEditBtn(true);
        found = true;
      }
    })
    if (event.target.value === "") {
      setDisplayAddNewBtn(false);
      setDisplayEditBtn(false);
    }else{
      if (!found){
        setDisplayAddNewBtn(true);

      }
    }
  };

  const [displayEditedPage, setDisplayEditedPage] = useState("none");
  const [selectedVersionNo, setSelectedVersionNo] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const [baseVersionNo, setBaseVersionNo] = useState("");
  const [displayEditBtn, setDisplayEditBtn] = useState(false);
  const [displayAddNewBtn, setDisplayAddNewBtn] = useState(false);
  const [selectedCurrentVersion, setSelectedCurrentVersion] = useState(false);

  const versionSelecter = (event) => {
    clearFunctionForVersion();
    setSelectedId(event.target.value);
    setDisplayEditBtn(true);
    setDisplayAddNewBtn(false);
  };

  function clearFunction() {
    setDisplayEditBtn(false);
    setDisplayAddNewBtn(false);
    setSelectedVersionNo("");
    setSelectedId("");
    setBaseVersionNo("");
    setDisplayEditedPage("none");
    setAboutUsDesignId("");
    setAboutUsDesign("");
    editorRef.current.setContent("");
    setSelectedCurrentVersion(false);
    setSelectedDomainName("");
    setSelectedVersionsArr([]);
    setImgLocation("");
    setPreview();
    setCretedUserName("");
    setAeticleTitle("");
    setCommentBtn(false);
    setArticleComments([]);
    setSelectedKeycodeArr([]);
    setSelectedKeycode("");
    setOrderNumber("");
  }

  function clearFunctionForVersion() {
    setDisplayEditBtn(false);
    setDisplayAddNewBtn(false);
    setSelectedVersionNo("");
    setSelectedId("");
    setBaseVersionNo("");
    setDisplayEditedPage("none");
    setAboutUsDesignId("");
    setAboutUsDesign("");
    editorRef.current.setContent("");
    setSelectedCurrentVersion(false);
    setImgLocation("");
    setPreview();
    setCretedUserName("");
    setAeticleTitle("");
    setCommentBtn(false);
    setArticleComments([]);
  }

  const [commentBtn, setCommentBtn] = useState(false);
  const [articleComments, setArticleComments] = useState([]);

  function editFunction() {
    allAboutUsData.forEach((data) => {
      if (data.domainName === selectedDomainName) {
        if (data.keyCode === selectedKeycode) {
          console.log(data);
          setDisplayEditedPage("block");
          setAboutUsDesignId(data.id);
          setSelectedVersionNo(data.versionNumber);
          if (data.imageUrl != undefined && data.imageUrl != null) {
            setPreview(data.imageUrl);
            setImgLocation(data.imageUrl);
          }
          setBaseVersionNo(data.baseVersion);
          setAboutUsDesign(data.content);
          setCretedUserName(data.createdUserName);
          //setAeticleTitle(data.title);
          editorRef.current.setContent(data.content);
          setDisplayEditBtn(false);
          //setOrderNumber(data.orderNumber);

          if (data.articleComments != null) {
            setArticleComments(data.articleComments);
            setCommentBtn(true);
          }
        }
      }
    });
  }

  function createNewFunction() {
    setDisplayEditedPage("block");
    setAboutUsDesignId("");
    setSelectedId("");
    setAboutUsDesign("");
    editorRef.current.setContent("");
    setDisplayAddNewBtn(false);
    setCommentBtn(false);
  }

  function saveFunction() {
    var pContent = editorRef.current.getContent();
    if (pContent === "") {
      alert("Please enter page content");
      return;
    }

    if (selectedKeycode === "") {
      alert("Article Keycode field can not be empty");
      return;
    }

    if (cretedUserName === "") {
      alert("Created user field can not be empty");
      return;
    }


   
      var obj = {};
      if (aboutUsDesignId === "") {
      } else {
        obj["id"] = aboutUsDesignId;
      }
      obj["versionNumber"] = "current";
      obj["baseVersion"] = "";
      obj["articleComments"] = articleComments;
      obj["domainName"] = selectedDomainName;
      obj["content"] = editorRef.current.getContent();
      obj["createdUserName"] = cretedUserName;
      //obj["orderNumber"] = orderNumber;
      //obj["imageUrl"] = imgLocation;
      obj["keyCode"] = selectedKeycode;

      var sendJson = JSON.stringify(obj);
      console.log(sendJson);
      var sendUrl = "";
      if (aboutUsDesignId === "") {
        sendUrl = "/blogArticlePage/save";
      } else {
        sendUrl = "/blogArticlePage/update";
      }

      axios(CONFIG.MAIN_URL + sendUrl, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: sendJson,
      })
        .then((res) => {
          getHtmlData();
          if (aboutUsDesignId === "") {
            alert(" Saved Successfully");
          } else {
            alert("Updated Successfully");
          }
          clearFunction();
        })
        .catch(function (error) {
          alert("Error Updating");
          console.log(error);
        });
     
  }

  function deleteFunction() {
    var errorMsg = "";
    allAboutUsData.forEach((data, index) => {
      if (
        data.domainName === selectedDomainName &&
        data.versionNumber === selectedVersionNo
      ) {
        if (data.versionNumber === "current") {
          errorMsg = 'Sorry!. Cannot delete the "CURRENT" version';
        }
      }
    });
    if (errorMsg != "") {
      alert(errorMsg);
      return;
    }

    var tId = "";
    var tContent = "";
    allAboutUsData.forEach((data, index) => {
      if (
        data.domainName === selectedDomainName &&
        data.versionNumber === selectedVersionNo
      ) {
        tId = data.id;
        tContent = data.content;
      }
    });

    var obj = {};
    obj["id"] = tId;
    obj["content"] = tContent;

    var sendJson = JSON.stringify(obj);

    console.log(sendJson);

    axios(CONFIG.MAIN_URL + "/blogArticlePage/delete", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: sendJson,
    })
      .then((res) => {
        getHtmlData();
        alert("Deleted Successfully");
        console.log(res.data);
        clearFunction();
      })
      .catch(function (error) {
        alert("Error Updating the collection");
        console.log(error);
      });
  }

  const [maxWidth, setMaxWidth] = React.useState("xl");
  const [newDialogOpen, setNewDialogOpen] = React.useState(false);
  const [userDerailsScroll, setUserDerailsScroll] = React.useState("paper");

  function openNewDialogClick() {
    if (articleComments != null && articleComments.length != 0) {
      setNewDialogOpen(true);
      setTimeout(() => {
        articleComments.map((chkitm) => {
          if (chkitm.isActive === "yes") {
            console.log("chkMenu" + chkitm.id);
            document.getElementById("chkMenu" + chkitm.id).checked = true;
          }
        });
      }, 500);
    } else {
      alert("no comments to show");
    }
  }

  const newDialogHandleClickClose = () => {
    setNewDialogOpen(false);
  };

  const [selectKeyCodeArr, setSelectKeyCodeArr] = React.useState([]);
  function getCheckStatus(regId) {}

  function updateComments() {
    var arr1 = [];
    articleComments.map((data) => {
      if (document.getElementById("chkMenu" + data.id).checked) {
        var obj1 = {};
        obj1["id"] = data.id;
        obj1["createdUserName"] = data.createdUserName;
        obj1["comment"] = data.comment;
        obj1["isActive"] = "yes";
        arr1.push(obj1);
      } else {
        var obj2 = {};
        obj2["id"] = data.id;
        obj2["createdUserName"] = data.createdUserName;
        obj2["comment"] = data.comment;
        obj2["isActive"] = "no";
        arr1.push(obj2);
      }
    });
    console.log(arr1);
    setArticleComments(arr1);
    //articleComments.push(arr1)
    setNewDialogOpen(false);
  }


  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  function imagePreviw(url) {
        var str = "<img src=\"" + url + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      
  } 


  return (
    <>
      <Card className={classes.cardRiskManage}>
        <CardContent>
          <Card>
            <CardHeader
              avatar={
                <Avatar
                  sx={{ bgcolor: "#69676e", width: "25px", height: "25px" }}
                >
                  <Info />
                </Avatar>
              }
              className={classe.commonMainTableHead}
              title={"Article Page"}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <AutoComplete
                    id="package-name"
                    options={allDomainData.map((option) => option.name)}
                    value={selectedDomainName}
                    style={{ height: "50px" }}
                    onChange={(event, newValue) => {
                      domainSelecter(event, newValue);
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type a new domain or select"
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        onChange={(event) => domainSelecter1(event)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutoComplete
                    id="package-name"
                    options={selectedKeycodeArr.map(
                      (option) => option.keyCode
                    )}
                    value={selectedKeycode}
                    style={{ height: "50px" }}
                    onChange={(event, newValue) => {
                      ketcodeSelecter(event, newValue);
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type a new Page KeyCode or select"
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        onChange={(event) => ketcodeSelecter1(event)}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    {displayEditBtn ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ height: "35px" }}
                          onClick={() => {
                            editFunction();
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ height: "35px" }}
                          onClick={() => deleteFunction()}
                        >
                          delete
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                    {displayAddNewBtn ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ height: "35px" }}
                        onClick={() => {
                          createNewFunction();
                        }}
                      >
                        Create a New Version
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ height: "35px" }}
                      onClick={() => {
                        clearFunction();
                      }}
                    >
                      Clear
                    </Button>

                    {commentBtn ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ height: "35px" }}
                        onClick={() => {
                          openNewDialogClick();
                        }}
                      >
                        show comments
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>

                <div
                  style={{
                    display: displayEditedPage,
                    marginTop: "30px",
                    marginRight: "13px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Typography
                        style={{ marginRight: "8px", fontWeight: 400 }}
                      >
                        Enter Creater Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box component="form" validate autoComplete="off">
                        <div>
                          <TextField
                            id="scaleDescription"
                            //label={"Type Answer Scale Example for " + selectedScaleName}
                            size="small"
                            maxRows={2}
                            style={{ width: "300px" }}
                            value={cretedUserName}
                            onChange={onTextFieldChangeCretedUserName}
                          />
                        </div>
                      </Box>
                    </Grid>

                    {/* <Grid item xs={2}>
                      <Typography
                        style={{ marginRight: "8px", fontWeight: 400 }}
                      >
                        Enter Article Title:
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box component="form" validate autoComplete="off">
                        <div>
                          <textarea
                            id="scaleDescription"
                            //label={"Type Answer Scale Example for " + selectedScaleName}
                            size="small"
                            maxRows={2}
                            style={{ width: "300px" }}
                            value={aeticleTitle}
                            onChange={onTextFieldChangeAeticleTitle}
                          />
                        </div>
                      </Box>
                    </Grid> */}

                    {/* <Grid item xs={2} style={{ marginTop: "25px" }}>
                      <Typography
                        style={{ marginRight: "8px", fontWeight: 400 }}
                      >
                        Upload Main Article image:
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <TableContainer>
                        <Table
                          aria-label="spanning table"
                          className={classes.tableStyle}
                          style={{ marginTop: "-7px" }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                rowSpan={2}
                                className={classes.thStyleImage}
                              >
                                <Box
                                  sx={{ minWidth: 120 }}
                                  className={classes.riskImageSize}
                                >
                                  <Card className={classes.root}>
                                    <div
                                      className={
                                        classes.posRelativeAndDivBorder
                                      }
                                    >
                                      {preview != null && preview != "" && (
                                        <img
                                          src={preview}
                                          className={classes.riskImageSize}
                                        />
                                      )}
                                    </div>
                                  </Card>
                                </Box>
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.thStyle}
                              >
                                <Stack direction="row" spacing={2}>
                                  <label htmlFor="contained-button-file-1">
                                    <Input
                                      accept="image/*"
                                      id="contained-button-file-1"
                                      type="file"
                                      name="file"
                                      onChange={(event) => {
                                        console.log(event.target.files[0]);
                                        setSelectedFile(event.target.files[0]);
                                      }}
                                    />
                                    <Button
                                      variant="contained"
                                      component="span"
                                      className={classes.btnColorPurple}
                                      style={{ marginTop: "-55px" }}
                                    >
                                      Upload
                                    </Button>
                                  </label>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </Grid> */}
{/* 
                    <Grid item xs={1}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          clearImageFuction();
                        }}
                        component="span"
                        className={classes.btnColorPurple}
                        style={{ marginTop: "14px" }}
                      >
                        Clear
                      </Button>
                    </Grid> */}
                    {/* <Grid item xs={2} style={{ marginTop: "25px" }}>
                      <Typography
                        style={{ marginRight: "8px", fontWeight: 400 }}
                      >
                        Enter Order Number:
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ marginTop: "25px" }}>
                      <Box component="form" validate autoComplete="off">
                        <div>
                          <TextField
                            id="scaleDescription"
                            //label={"Type Answer Scale Example for " + selectedScaleName}
                            size="small"
                            maxRows={2}
                            style={{ width: "100px" }}
                            value={orderNumber}
                            onChange={onTextFieldChangeOrderNumber}
                          />
                        </div>
                      </Box>
                    </Grid> */}

                    <Grid item xs={12}>
                      <Editor
                        apiKey="ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj"
                        tinymceScriptSrc={
                          "https://cdn.tiny.cloud/1/ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj/tinymce/6/tinymce.min.js"
                        }
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={aboutUsDesign}
                        init={{
                          referrer_policy: "origin",
                          height: 500,
                          menubar: true,
                          placeholder: "Main Article",
                          plugins:
                            "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media mediaembed template codesample table advtable charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help charmap quickbars emoticons casechange formatpainter pageembed hr fullpage advcode export permanentpen powerpaste",
                          imagetools_cors_hosts: ["picsum.photos"],
                          menubar:
                            "file edit view insert format tools table help",
                          toolbar:
                            "undo redo | bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | casechange forecolor backcolor formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview print | pageembed insertfile image media template tokens link anchor codesample | ltr rtl hr fullpage export permanentpen configurepermanentpen",
                          //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                          export_image_proxy: "proxy.php",
                          toolbar_sticky: true,
                          autosave_ask_before_unload: true,
                          autosave_interval: "30s",
                          autosave_prefix: "{path}{query}-{id}-",
                          autosave_restore_when_empty: false,
                          autosave_retention: "2m",
                          setup: (editor) => {
                            editor.on("init", function (e) {
                              var content = unescape(aboutUsDesign);
                              editor.setContent(content);
                              editor.dom.bind(editor.getBody(), 'click', function (e) {
                                var target = e.target;
                        
                                // Check if the clicked element is an image
                                if (target.nodeName.toLowerCase() === 'img') {
                                  // Your onclick function logic here
                                  imagePreviw(target.src);  // Replace this with your actual onclick function
                                }
                              });
                            });
                          },

                          image_title: true,
                          /* enable automatic uploads of images represented by blob or data URIs*/
                          automatic_uploads: true,
                          /*Here we add custom filepicker only to Image dialog*/
                          file_picker_types: "image",

                          /* and here's our custom image picker*/
                          file_picker_callback: function (cb, value, meta) {
                            var input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", "image/*");

                            input.onchange = function () {
                              var file = this.files[0];
                              var reader = new FileReader();

                              reader.onload = function () {
                                /*
                                                        Note: Now we need to register the blob in TinyMCEs image blob
                                                        registry. In the next release this part hopefully won't be
                                                        necessary, as we are looking to handle it internally.
                                                        */
                                var id = "blobid" + new Date().getTime();

                                var blobCache =
                                  editorRef.current.editorUpload.blobCache;
                                //var blobCache = editorRef.current.activeEditor.editorUpload.blobCache;
                                var base64 = reader.result.split(",")[1];

                                var blobInfo = blobCache.create(
                                  id,
                                  file,
                                  base64
                                );
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              };

                              reader.readAsDataURL(file);
                            };

                            input.click();
                          },

                          //images_upload_handler: function (
                          //  blobInfo,
                          //  success,
                          //</Grid>  failure
                          //) {
                            /*  
                                                var xhr, formData;
                                    
                                                xhr = new XMLHttpRequest();
                                                xhr.withCredentials = false;
                                                xhr.open('POST', 'actionContent/getImage');
                                                xhr.onerror = function() {
                                                    //$('#wait').hide();
                                                };
                                                xhr.onload = function () {
                                                    var json;
                                    
                                                    if (xhr.status != 200) {
                                                        failure('HTTP Error: ' + xhr.status);
                                                        return;
                                                    }
                                                    console.log()
                                                    json = xhr.responseText;
                                                    console.log(json);
                                                    success(json);
                                                    //$('#wait').hide();
                                                };
                                    
                                                formData = new FormData();
                                                formData.append('file', blobInfo.blob(), blobInfo.filename());
                                                //$('#wait').show();
                                                xhr.send(formData);
                                                */
                          //},

                          templates: tinyMCETemplateList,
                          /*
                                            templates : [
                                            {
                                                title: 'Date modified example',
                                                description: 'Adds a timestamp indicating the last time the document modified.',
                                                content: '<p>Last Modified: This will be replaced with the date modified.</time></p>'
                                            },
                                            {
                                                title: 'Replace values example',
                                                description: 'These values will be replaced when the template is inserted into the editor content.',
                                                content: '<p>Name: StaffID: </p>'
                                            }
                                            ],
                                            */
                          referrer_policy: "origin",

                          template_cdate_format:
                            "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
                          template_mdate_format:
                            "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
                          image_caption: true,
                          quickbars_selection_toolbar:
                            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                          noneditable_noneditable_class: "mceNonEditable",
                          toolbar_mode: "sliding",
                          contextmenu: "link image imagetools table",
                          //content_css: useDarkMode ? 'dark' : 'default',
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
                <Grid item xs={12} style={{ display: displayEditedPage }}>
                  <div style={{ display: "flex", float: "right", gap: "5px" }}>
                    {aboutUsDesignId === "" ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ height: "35px" }}
                          onClick={() => saveFunction()}
                        >
                          save
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ height: "35px" }}
                          onClick={() => saveFunction()}
                        >
                          update
                        </Button>
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CardContent>
      </Card>

      <Dialog
        open={newDialogOpen}
        onClose={newDialogHandleClickClose}
        scroll={userDerailsScroll}
        maxWidth={"lg"}
        aria-labelledby="userDerails-scroll-dialog-title"
        aria-describedby="userDerails-scroll-dialog-description"
      >
        <DialogTitle id="draggable-dialog-title">Select comments</DialogTitle>
        <DialogContent dividers={userDerailsScroll === "paper"}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                className="stdCardInside"
                style={{ height: "300px", overflowY: "auto" }}
              >
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <div className={classes.scrollableDivToStandard}>
                        <TableContainer>
                          <Table aria-label="customized table">
                            <TableHead
                              className={classe.commonSecondaryTableHead}
                            >
                              <TableRow>
                                <StyledTableCell
                                  className={classe.commonTableThStyle}
                                  style={{ width: "10%" }}
                                >
                                  Select
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className={classe.commonTableThStyle}
                                  style={{ width: "30%" }}
                                >
                                  User
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  className={classe.commonTableThStyle}
                                  style={{ width: "60%" }}
                                >
                                  Comment
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {articleComments.map((data) => (
                                <>
                                  <StyledTableRow
                                    key={data.id}
                                    className={classes.tableTrStyle}
                                  >
                                    <StyledTableCell
                                      component="th"
                                      scope="row"
                                      className={classes.tableTrStyle}
                                    >
                                      <input
                                        type="checkbox"
                                        onClick={() => {
                                          getCheckStatus(data.id);
                                        }}
                                        id={"chkMenu" + data.id}
                                        name={"chkMenu" + data.id}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      component="th"
                                      scope="row"
                                      className={classes.tableTrStyle}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "1.00rem !important",
                                          marginLeft: "0px",
                                        }}
                                      >
                                        {data.createdUserName}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      component="th"
                                      scope="row"
                                      className={classes.tableTrStyle}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "1.00rem !important",
                                          marginLeft: "0px",
                                        }}
                                      >
                                        {data.comment}
                                      </Typography>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Box style={{ gap: "5px", float: "right", marginTop: "10px" }}>
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ height: "36px" }}
                    onClick={() => {
                      updateComments();
                    }}
                  >
                    confirm
                  </Button>
                </>
                &nbsp;&nbsp;&nbsp;
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ height: "36px" }}
                    onClick={() => {
                      newDialogHandleClickClose();
                    }}
                  >
                    Close
                  </Button>
                </>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>


      <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>
    </>
  );
};

export default ArticlePagesComponent;





