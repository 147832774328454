import React, { useEffect, ReactDOM, useState } from 'react'
import { Grid, makeStyles, Tooltip } from "@material-ui/core";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import { entityData, standards } from '../data';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import QuestionerPageOpenEnded from '../Components/QuestionerPageOpenEnded';
import QuestionerPageCloseEnded from '../Components/QuestionerPageCloseEnded';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Links from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import { useSearchParams } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import {CONFIG} from '../config'
import { standardDataJs } from '../dataB'; 
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Close, Info, Refresh } from '@mui/icons-material';
import { Paper } from '@mui/material';
import Draggable from 'react-draggable'; 
import { width } from '@mui/system';
import Divider from '@mui/material/Divider';
import {CardActionArea, CardActions } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import RiskTypeQustionDisplay from '../Components/RiskTypeQustionDisplay';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import CircularProgress from '@mui/material/CircularProgress';
import RightBarDataForUserPages from '../Components/RightBarDataForUserPages';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CollectionManagement from '../Components/CollectionManagement';
import CenterMaintainersComponent from '../Components/CenterMaintainersComponent';
import SliderMethodsComponent from '../Components/SliderMethodsComponent';
import AddColorComponent from '../Components/AddColorComponent';
import CustomStandardsComponent from '../Components/CustomStandardsComponent';
import CollectionManagementEdit from '../Components/CollectionManagementEdit';

import TransferCollectionComponent from '../Components/TransferCollectionComponent';
import GlobalStandard from '../Components/GlobalStandard';
import RiskHierarchy from '../Components/RiskHierarchy';
import useStyle from '../Css/MainCss';
import AddQuestionComponent from '../Components/AddQuestionComponent';
import AddScales from '../Pages/AddScales';
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import AddScaleComponent from '../Components/AddScaleComponent';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';



function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const useStyles = makeStyles((theme) => ({ 
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageBtn:{
    height: '80px',
    width: '180px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '12px !important',
  },
  globalBtn:{
    height: '80px',
    width: '180px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '12px !important',
  },
  globalBtnAfter:{
    height: '80px',
    width: '180px',
    backgroundColor: '#d6c3fc !important',
    color: 'black !important',
    borderRadius: '12px !important',
  },
  pageBtnRisk:{
    height: '30px',
    width: '210px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '5px !important',
  },
  cardBodyH6: {
    fontSize: '14px !important',
  },
  headerLable:{
    color: '#827d7d',
    marginLeft: '25px',
  },
  pageTop:{
    marginTop: '-13px',
    marginLeft: '-10px'
  },
  pageBodyStyle: {
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '12px',
    backgroundColor: '#f7f7f7',
    marginLeft: '5px',
  },
  mainbtn:{
      justifyContent:'center'
  },
  qusMainCardHeader:{
    backgroundColor:'#d6c3fc !important',
    fontSize:'13px !important',
    height:'44px',
    padding:'0px',
    marginTop: '-7px',
  },
  qusCardHeader:{
    backgroundColor:'#ece9fa !important',
    fontSize:'13px !important',
    height:'44px',
    padding:'0px',
    marginTop: '-7px',
  },
  popupdiv:{
        marginLeft:'15%',
        //marginTop:'-50%',
        border:'2px solid black',
        width:'700px',
        backgroundColor:'#f6f5fc !important',
        boxShadowhadow: '5px 10px #888888',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        
        
  },
  cardcencel:{
     // alignItems:'right !important',
    //  marginLeft:'100px !important'
        float:'right !important'
  },
    stdButtonsStyle:{
        fontSize:'0.70rem !important', 
        height: '50px !important', 
        //paddingRight: '7px !important',
        //paddingLeft: '5px !important',
    },
    cardClose: {
        float: 'right !important'
    },
    topHeaderText1Move: {
      color: '#ffffff',
      fontSize:'20px', 
      paddingBottom:'10px',
      textAlign:'center'
    },
    topHeaderText2Move: {
      color: '#ffffff',
      fontSize:'12px', 
      paddingBottom:'10px',
      textAlign:'center',
      cursor: 'pointer'
    },
    cardDivMove:{
      border:'2px solid #7a769b',
      backgroundColor:'#696873 !important',
      boxShadowhadow: '5px 10px #69676e',
      borderRadius: '5px !important',
      BorderStyle: 'rigid'
    },
    cardTitleMove: {
      fontSize:'15px !important', 
      textAlign:'center',
      fontWeight: '500',
      paddingBottom:'10px',
      color: 'white'
     },
     leftPageNumberStyle: {
      marginTop: '-91px !important',
      marginLeft: '-17px !important',
      padding: '0px !important',
      backgroundColor: 'black !important',
      fontSize: '15px !important',
      maxWidth: '26px !important', 
      maxHeight: '26px !important', 
      minWidth: '26px !important', 
      minHeight: '26px !important'
    },
    rightPageNumberStyle: {
      float: 'right !important',
      marginTop: '-49px !important',
      marginRight: '-16px !important',
      padding: '0px !important',
      backgroundColor: 'black !important',
      fontSize: '15px !important',
      maxWidth: '26px !important', 
      maxHeight: '26px !important', 
      minWidth: '26px !important', 
      minHeight: '26px !important'
    },
    mutimenuPageHeadingStyle: {
      fontSize:'14px !important', 
      fontWeight: 'normal !important', 
      marginTop:'-68px !important', 
      color:'#b60707', 
      marginLeft: '12px !important'
    },
    fontZise10:{
      fontSize: '9px !important',
    },



}));


function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }
  function PaperComponentPreAddedQuestions(props) {
    return (
      <Draggable
        handle="#pre-added-question-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

const QuestionerPageComponent = ({collectionIdFromEval,questionIdFromEval,setOpenAmendQuestionFromEvalFunction}) => {
      const classess = useStyle();

    const [waitingOpen, setWaitingOpen] = React.useState(false);



    useEffect (() => {
        //getRiskTypeData();
        getData();
       // getStandards();
       
        
        window.scrollTo(0, 0);

        
    
    },[])

    //malindu useEffect

    const [questionSliderMethods, setQuestionSliderMethods] = useState([])



    useEffect(()=> {
        
            axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION+"/all",{
              method: 'get',
              headers: { 
                  'Content-Type': 'text/plain', 
                },     
              })
              .then(function (response) {
               
                  console.log("All Risk Returns");
                  console.log(response.data[0]);
                  setQuestionSliderMethods(response.data[0].methods)
                  setQuestionCategoryId(response.data[0].id)
                  console.log(response.data[0].methods);
          
              })
              .catch(function (error) {
              console.log(error);
              });

              allRiskMethodData();
              getColors();
          
    },[])


    function getSliderMethods(){
      
      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION+"/all",{
        method: 'get',
        headers: { 
            'Content-Type': 'text/plain', 
          },     
        })
        .then(function (response) {
         
            console.log("All Risk Returns");
            console.log(response.data[0]);
            setQuestionSliderMethods(response.data[0].methods)
            setQuestionCategoryId(response.data[0].id)
            console.log(response.data[0].methods);
    
        })
        .catch(function (error) {
        console.log(error);
        });

    }






    const [golobalCentreId, setGolobalCentreId] = React.useState();

    function getData () {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        setGolobalCentreId(data.centreId);
        getStandards(data.centreId)
        getEntityData(data.centreId);
        getCentreData(data.centreId);
        getOwnStandared(data.centreId);
        getPrevQuestionData(data.centreId);
        getScaleData(data.centreId)
        console.log(data.centreId)
    }


    const [scaleData, setScaleData] = React.useState([]);

      /** Get Scale data for selected Centre */
  function getScaleData(centreId) {
    axios(CONFIG.MAIN_URL+`/scale/get?centreId=`+ centreId, {
      method: 'get',
    })
    .then(function (response) {
      console.log("++++++++++++++++Return scale Data +++++++++++++++++++");
      console.log(response.data);
      setScaleData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  }


    function reloadAll(){

      setWaitingOpen(true);
      setTimeout(()=> {
        getStandards(golobalCentreId);
        getOwnStandared(golobalCentreId);
        getSliderMethods();
        allRiskMethodData();
        getColors();
        getPrevQuestionData(golobalCentreId);
        setWaitingOpen(false);
      }, 4000);

    }
    
    const [prevAddedQuestions, setPrevAddedQuestions] = React.useState([]);
    const [preAddedQuestionsRows, setPreAddedQuestionsRows] = React.useState([]);

    function getPrevQuestionData(centreId){
      axios(CONFIG.MAIN_URL+"/question/getquestiononly?centreId="+ centreId,{
        method: 'get',
      })
      .then(function (response) {
        console.log("+++++++++++++++++Get All question+++++++++++++++++");
        console.log(response.data);
        setPrevAddedQuestions(response.data)

        var count = 1;
        var tmpArray = [];
        setPreAddedQuestionsRows([]);
        response.data.forEach((item)=>{
          var tmpObj = {};
          tmpObj["id"] = count;
          tmpObj["questionId"] = item.id;
          tmpObj["questionType"] = item.questionType;
          tmpObj["question"] = item.question;
          tmpArray.push(tmpObj);
          setPreAddedQuestionsRows(tmpArray);
          count ++;
        })
      })
      .catch(function (error) {
        console.log(error);

      });
    }
    
    const renderTextId = (params) => {
      return (
          <span className={classes.fontZise10}>{params.row.id}</span>        
      )
    }
    const renderTextQuestion = (params) => {
      return (
          <Tooltip title={params.row.question}>
          <span className={classes.fontZise10}>{params.row.question}</span>        
          </Tooltip>
      )
    }
    const renderTextQuestionType = (params) => {
      return (
          <span className={classes.fontZise10}>{params.row.questionType}</span>        
      )
    }
    function selectQuestion(id){      
      setOwnObjectiveName('');
      setObjectivedData([]);
      setObjectiveId('');
      setObjectiveName('');
      setOwnObjectiveFlag(false);
      setOwnComponentName('');
      setComponentsData([]);
      setComponentId("");
      setComponentName('');
      setOwnComponentFlag(false);
      setOwnPrincipalName('');
      setPrincipalsData([]);
      setPrincipleId("");
      setPrincipleName('');
      setOwnPrincipalFlag(false);
      setEntityData([]);
      setEntity('');
      setEntityName('');
      setOCStatus(1);
      setCETypeStatus(0);
      setCloseEndedTypeMsg('');
      setCEQValue('');
      setShowSelectBarCloseEnded(true);
      setSliderTypeCloseEnded();
      setSliderValueCloseEnded();
      setSliderValueNameCloseEnded('');
      setSliderValueCloseEndedStyle('');
      setAnsMethod('');
      setMinNumber(1);
      setMaxNumber(5);
      //setOptArray(1,2,3,4,5);
      setScalingMethod('');
      setScallingMsg('');
      setSelectedSuggestedValue(0);
      setAnsArray([]);
      setAnsArrayTmp([]);
      setAnswerOrderNumber(1);
      setOEQValue('');
      setShowSelectBar(true);
      setSliderType()
      setSliderValue();
      setSliderValueName('');
      setSliderValueStyle('');
      setOpenEndedAnswerValue('');
      setAnsMethod('');

      prevAddedQuestions.forEach((item)=>{
        if (item.id === id){
          setWaitingOpen(true);
          console.log("+++++++++++++++++++item++++++++++++++++++++");
          console.log(item);
          if (item.objective != null && item.objective != undefined && item.objective != ""){
            if (item.objective.name != null && item.objective.name != undefined && item.objective.name != ""){
              setOwnObjectiveName(item.objective.name);
              setObjectivedData([]);
              var arryObj = [];
              var tmpArray = {};
              tmpArray["objectiveId"] = "OBJCUS";
              tmpArray["name"] = item.objective.name;
              arryObj.push(tmpArray);
              setObjectivedData(arryObj);
              setObjectiveId("OBJCUS");
              setObjectiveName(item.objective.name);
              setOwnObjectiveFlag(true);
            }
          }
          if (item.component != null && item.component != undefined && item.component != ""){
            if (item.component.name != null && item.component.name != undefined && item.component.name != ""){
              setOwnComponentName(item.component.name);
              setComponentsData([]);
              var arryObj = [];
              var tmpArray = {};
              tmpArray["componentId"] = "COMCUS";
              tmpArray["name"] = item.component.name;
              arryObj.push(tmpArray);
              setComponentsData(arryObj);
              setComponentId("COMCUS");
              setComponentName(item.component.name);
              setOwnComponentFlag(true);
            }
          }
          if (item.principle != null && item.principle != undefined && item.principle != ""){
            if (item.principle.name != null && item.principle.name != undefined && item.principle.name != ""){
              setOwnPrincipalName(item.principle.name);
              setPrincipalsData([]);
              var arryObj = [];
              var tmpArray = {};
              tmpArray["principalId"] = "PRICUS";
              tmpArray["name"] = item.principle.name;
              arryObj.push(tmpArray);
              setPrincipalsData(arryObj);
              setPrincipleId("PRICUS");
              setPrincipleName(item.principle.name);
              setOwnPrincipalFlag(true);
            }
          }
          if (item.entity != null && item.entity != undefined && item.entity != ""){
            var tmpEntityData = [];
            var arrayData = {}
            arrayData["entityId"] = item.entity.entityId;
            arrayData["entityName"] = item.entity.name;
            tmpEntityData.push(arrayData);
            setEntityData(tmpEntityData);
            setEntity(item.entity.entityId);
            setEntityName(item.entity.name);
          }
          if (item.questionType != null && item.questionType != undefined && item.questionType != ""){
            if (item.questionType === "Closeended"){
              setOCStatus(2);
              if (item.questionTypeName != null && item.questionTypeName != undefined && item.questionTypeName != ""){
                  setCloseEndedTypeName(item.questionTypeName);
                  if (item.questionTypeName === "Nominal"){
                      setCETypeStatus(1);
                      setCloseEndedTypeMsg("Nominal scale is a naming scale, where Answers are simply \“named\” or labeled, with no specific order");
                  }
                  if (item.questionTypeName === "Ordinal"){
                      setCETypeStatus(2);
                      setCloseEndedTypeMsg("Ordinal scale has all its Answers in a specific order, beyond just naming them");
                  }
                  if (item.questionTypeName === "Interval"){
                      setCETypeStatus(3);
                      setCloseEndedTypeMsg("Interval scale offers labels, order, as well as, a specific interval between each of its variable options");
                  }
                  if (item.questionTypeName === "Ratio"){
                      setCETypeStatus(4);
                      setCloseEndedTypeMsg("Ratio scale bears all the characteristics of an interval scale, in addition to that, it can also accommodate the value of “zero” on any of its variables");
                  }
              }
              if (item.question != null && item.question != ""){
                setCEQValue(item.question);
              }
              if (item.questionScore != undefined && item.questionScore != null && item.questionScore != ""){
                axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION+"/all",{
                  method: 'get',
                  headers: { 
                      'Content-Type': 'text/plain', 
                  },     
                })
                .then(function (res) {
                    setQuestionSliderMethods(res.data[0].methods)
                    setQuestionCategoryId(res.data[0].id)
                    var SliderMethods = []
                    SliderMethods = res.data[0].methods
                    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                      method: 'get',
                      headers: { 
                          'Content-Type': 'text/plain', 
                        },     
                    })
                    .then(function (res1) {
                      setWaitingOpen(false);
                      var colorDatas =  []
                      colorDatas = res1.data
                    })
                    var min = 0;
                    var max = 0
                    setShowSelectBarCloseEnded(false);
                    setSliderTypeCloseEnded(item.questionScoreData.riskMethodId)
                    setSliderValueCloseEnded(item.questionScore);
                    res.data[0].methods.forEach((data)=> {
                      if(item.questionScoreData.riskMethodId === data.id){
                        data.riskTypes.forEach((data1)=> {
                          if (data1.startingNumber <= item.questionScore && item.questionScore <= data1.endingNumber) {
                            setSliderValueNameCloseEnded(data1.riskTypesName)
                            min=data.minimumWeightingNumber;
                            max=data.maximumWeightingNumber;
                          }
                        })
                      }
                    })
                    var posNumber1 = parseInt(item.questionScore) - min;
                    var totalNumber1 = max - min;
                    var percentageVal1 = (posNumber1/totalNumber1) * 100
                    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                      method: 'get',
                      headers: { 
                          'Content-Type': 'text/plain', 
                        },     
                    })
                    .then(function (respo) {
                      setWaitingOpen(false);
                      respo.data.forEach((data)=> {
                        if (parseInt(item.questionScore) === parseInt(data.scoreNumber)){
                          var cCode = data.color;
                          var cssStr1 = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal1 + '%, #d3d3d3 0%)';
                          setSliderValueCloseEndedStyle(cssStr1);
                        }
                      })
                    })
                    .catch(function (error) {
                      console.log(error);
                      setWaitingOpen(false);
                    });
                })
                .catch(function (error) {
                  console.log(error);
                  setWaitingOpen(false);
                });
              }
              //setWaitingOpen(true);
              if (item.answers != null) {
                if(item.answers.answerMethod != null && item.answers.answerMethod != undefined){
                    var minVal = 0;
                    var maxVal = 0;
                    if(item.answers.answerMethod.name != null && item.answers.answerMethod.name != undefined){
                        setAnsMethod(item.answers.answerMethod.name);
                    }    
                    if(item.answers.answerMethod.minValue != null){
                        setMinNumber(item.answers.answerMethod.minValue);
                        minVal = parseInt(item.answers.answerMethod.minValue);
                    }    
                    if(item.answers.answerMethod.maxValue != null){
                        setMaxNumber(item.answers.answerMethod.maxValue);
                        maxVal = parseInt(item.answers.answerMethod.maxValue);
                    }    
                    if (minVal > 0 && maxVal > 0){
                        setOptArray(range(minVal,maxVal)) ;
                    }
                }
                if(item.answers.answerScaleType != null){
                    if(item.answers.answerScaleType.name != null){
                      setScalingMethod(item.answers.answerScaleType.name);
                      {
                        scaleData.forEach((data)=> {
                          if (item.answers.answerScaleType.name === data.id){
                            setScallingMsg(data.name);
                        }
                        })
                      }
                        
                          
                          // if (item.answers.answerScaleType.name === "semanticdifferential"){
                          //     setScallingMsg("Answering options are grammatically on opposite adjectives at each end like love-hate, satisfied-unsatisfied, and likely to return-unlikely to return with intermediate options in between");
                          // }
                          // if (item.answers.answerScaleType.name === "dichotomous"){
                          //     setScallingMsg("It can have two possible answers like Yes/No, True/False, Fair/Unfair or Agree/Disagree answers");
                          // }
                    } 
                }    
                if (item.answers.answerList != null && item.answers.answerList.length > 0) {
                    var tmpArry = [];
                    var count = 1;
                    item.answers.answerList.forEach((ansData,index)=>{
                        var arrayData = {}
                        arrayData["answer"] = ansData.answerName;
                        arrayData["risk"] = ansData.riskLevel;
                        arrayData["riskLevelData"] = ansData.riskLevelData;
                        if (ansData.expectedAnswerName != undefined && ansData.expectedAnswerName != null && ansData.expectedAnswerName != "null" && ansData.expectedAnswerName != ""){
                            arrayData["expectedAnswerName"] = ansData.expectedAnswerName;
                            if (item.answers.answerMethod.name === "radioButtonType") {
                                setSelectedSuggestedValue("" + index);
                            }
                        }else{
                            arrayData["expectedAnswerName"] = "";
                        }    
                        arrayData["orderNumber"] = count;
                        tmpArry.push(arrayData);
                        count ++;
                    }); 
                    tmpArry.sort(compare);
                    setAnsArray(tmpArry);
                    setAnsArrayTmp(tmpArry);
                    setAnswerOrderNumber(count);
                }
                setWaitingOpen(false);
              } 
            }else{
              //setWaitingOpen(true);
              setOCStatus(1);
              if (item.question != null && item.question != ""){
                  setOEQValue(item.question);
              }
              if (item.questionScore != undefined && item.questionScore != null && item.questionScore != ""){
                axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION+"/all",{
                  method: 'get',
                  headers: { 
                      'Content-Type': 'text/plain', 
                  },     
                })
                .then(function (res) {
                    setQuestionSliderMethods(res.data[0].methods)
                    setQuestionCategoryId(res.data[0].id)
                    var SliderMethods = []
                    SliderMethods = res.data[0].methods
                    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                      method: 'get',
                      headers: { 
                          'Content-Type': 'text/plain', 
                      },     
                    })
                    .then(function (res1) {
                      setWaitingOpen(false);
                      var colorDatas =  []
                      colorDatas = res1.data
                    })
                    setShowSelectBar(false);
                    setSliderType(item.questionScoreData.riskMethodId)
                    setSliderValue(item.questionScore);
                    var min = 0;
                    var max = 0;
                    res.data[0].methods.forEach((data)=> {
                      if(item.questionScoreData.riskMethodId === data.id){
                        data.riskTypes.forEach((data1)=> {
                          if (data1.startingNumber <= item.questionScore && item.questionScore <= data1.endingNumber) {
                            setSliderValueName(data1.riskTypesName)
                            min=data.minimumWeightingNumber;
                            max=data.maximumWeightingNumber;
                          }
                        })
                      }
                    })
                    var posNumber1 = parseInt(item.questionScore) - min;
                    var totalNumber1 = max - min;
                    var percentageVal1 = (posNumber1/totalNumber1) * 100
                    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                      method: 'get',
                      headers: { 
                          'Content-Type': 'text/plain', 
                        },     
                    })
                    .then(function (response) {
                      setWaitingOpen(false);
                      response.data.forEach((data)=> {
                        if (parseInt(item.questionScore) === parseInt(data.scoreNumber)){
                          var cCode = data.color;
                          var cssStr1 = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal1 + '%, #d3d3d3 0%)';
                          setSliderValueStyle(cssStr1);
                        }
                      })
                    })
                    .catch(function (error) {
                      console.log(error);
                      setWaitingOpen(false);
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    setWaitingOpen(false);
                });
              }
              //setWaitingOpen(true);
              if (item.answers != undefined && item.answers != null) {
                  if (item.answers.answer != undefined && item.answers.answer != null && item.answers.answer != "") {
                      setOpenEndedAnswerValue(item.answers.answer);
                  }
                  if(item.answers.answerMethod != null){
                      if(item.answers.answerMethod.name != null){
                          setAnsMethod(item.answers.answerMethod.name);
                      }    
                  }
              }
              
              setWaitingOpen(false);
            }
          }else{
            if (item.question != null && item.question != ""){
              setOEQValue(item.question);
            }
          }
          setTimeout(()=> {
            setWaitingOpen(false);        
          }, 500);
          setOpenPreAddedQuestions(false);
        }
      })
      //***************************************** */
    }
    
    const renderSelectButton = (params) => {
      return (
          <strong>
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                      selectQuestion(params.row.questionId)
                  }}
              >
                  Select
              </Button>
          </strong>
      )
  }
  

    const preAddedQuestionsColumns = [
      { field: 'id', headerName: 'No', renderCell: renderTextId, resizable: true, width: 15 },
      { field: 'questionId', headerName: 'No', width: 15, hide: true },
      { field: 'questionType', headerName: 'Type', renderCell: renderTextQuestionType, resizable: true, width: 100 },
      { field: 'question', headerName: 'Question', renderCell: renderTextQuestion, cellClassName: 'super-app-theme--cell', resizable: true, width: 900 },
      {
        field: 'edit',
        headerName: 'Select',
        sortable: false,
        renderCell: renderSelectButton,
        disableClickEventBubbling: true,
      
      }
    
    ];
    const [openPreAddedQuestions, setOpenPreAddedQuestions] = React.useState(false);
    const [openPreAddedQuestionsScroll, setOpenPreAddedQuestionsScroll] = React.useState('paper');
    function openQuestionListFunction(){
      setOpenPreAddedQuestions(true);
      setOpenPreAddedQuestionsScroll('paper');   
    }




    const [centreOwnObjectives, setCentreOwnObjectives] = React.useState([]);
    const [centreOwnComponents, setCentreOwnComponents] = React.useState([]);
    const [centreOwnPrinciples, setCentreOwnPrinciples] = React.useState([]);
    function getOwnStandared(centreId){
        axios(CONFIG.MAIN_URL+'/custom-standard-type-client/filter?centreId='+centreId,{
            method: 'get',
          })
          .then(function (response) {
            console.log("Get All Own standared");
            var rtnArray = response.data;
            console.log(rtnArray);
            var objArr = [];
            var compArr = [];
            var priArr = [];
            rtnArray.forEach((item)=>{
                if (item.type === "objective"){
                    objArr.push(item);
                }
                if (item.type === "component"){
                    compArr.push(item);
                }
                if (item.type === "principle"){
                    priArr.push(item);
                }
            })
            setCentreOwnObjectives(objArr);
            setCentreOwnComponents(compArr);
            setCentreOwnPrinciples(priArr);
          })
          .catch(function (error) {
            console.log(error);
          });
    
    }    
    
    const [centreName, setCentreName] = React.useState('');
    function getCentreData(centreId){
      var data = '{getCentreData(centreId:\"' + centreId + '\"){centreId,centreName}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          console.log("++++++++++++++++Return centreData++++++++++++++++");
          var centreData = response.data.data.getCentreData;
          console.log(centreData);
          setCentreName(centreData.centreName);
  
      })
      .catch(function (error) {
          console.log("Centre Data Loding error" + error);
      });
    }


    const [headerName, setHeaderName] = React.useState('Add New Questionnaire');
    const [saveBtnName, setSaveBtnName] = React.useState('Save');
    const [saveDraftBtnName, setSaveDraftBtnName] = React.useState('Save Draft');
    const [selectYourRiskType, setSelectYourRiskType] = React.useState('Select Your Risk Type');
    

    const [objectivePlaceHolderName, setObjectivePlaceHolderName] = React.useState('Select Objective');
    const [componentPlaceHolderName, setComponentPlaceHolderName] = React.useState('Select Component');
    const [principlePlaceHolderName, setPrinciplePlaceHolderName] = React.useState('Select Principle');
    const [entityPlaceHolderName, setEntityPlaceHolderName] = React.useState('Select Entity');    

    /**
     * Create Own Objective
     */
     const [ownObjectiveFlag, setOwnObjectiveFlag] = React.useState(false);
     const [ownObjectiveName, setOwnObjectiveName] = React.useState('');
     const [ownObjectiveOpen, setOwnObjectiveOpen] = React.useState(false);
     const [ownObjectiveScroll, setOwnObjectiveScroll] = React.useState('paper');

     const ownObjectiveHandleClickOpen = (scrollType) => () => {
        setOwnObjectiveOpen(true);
        setOwnObjectiveScroll(scrollType);
      };

     const ownObjectiveHandleClickClose = () => {
         setOwnObjectiveOpen(false);
     };
     const handleChangeOwnObjective = (event) => {
        setOwnObjectiveName(event.target.value);
     };
     const [previousOwnObjectiveId, setPreviousOwnObjectiveId] = React.useState('');
     const handleChangePreviousOwnObjective = (event) => {
        var id = event.target.value;
        setPreviousOwnObjectiveId(id);
        centreOwnObjectives.forEach((data)=>{
            if (data.id === id){
                setOwnObjectiveName(data.name);
            }
        });    
        
     };
     function setOwnObjectiveFunction(){
         if ( ownObjectiveName === ""){
             alert("Please enter Your Own Objective");
             return;
         }
         setObjectivePlaceHolderName("Type Your Objective");
         setOwnObjectiveOpen(false);
         setObjectivedData([]);
         var arryObj = [];
         var tmpArray = {};
         tmpArray["objectiveId"] = "OBJCUS";
         tmpArray["name"] = ownObjectiveName;
         arryObj.push(tmpArray);
         setObjectivedData(arryObj);
         setObjectiveId("OBJCUS");
         setOwnObjectiveFlag(true);
     }
     /** End of Create Own Objective */

    /**
     * Create Own Component
     */
     const [ownComponentFlag, setOwnComponentFlag] = React.useState(false);
     const [ownComponentName, setOwnComponentName] = React.useState('');
     const [ownComponentOpen, setOwnComponentOpen] = React.useState(false);
     const [ownComponentScroll, setOwnComponentScroll] = React.useState('paper');
     const ownComponentHandleClickOpen = (scrollType) => () => {
        setOwnComponentOpen(true);
        setOwnComponentScroll(scrollType);
     };
     const ownComponentHandleClickClose = () => {
         setOwnComponentOpen(false);
     };
     const handleChangeOwnComponent = (event) => {
        setOwnComponentName(event.target.value);
     };
     
     const [previousOwnComponentId, setPreviousOwnComponentId] = React.useState('');
     const handleChangePreviousOwnComponent = (event) => {
        var id = event.target.value;
        setPreviousOwnComponentId(id);
        centreOwnComponents.forEach((data)=>{
            if (data.id === id){
                setOwnComponentName(data.name);
            }
        });    
     };
     
     function setOwnComponentFunction(){
         if ( ownComponentName === ""){
             alert("Please enter Your Own Component");
             return;
         }
         setComponentPlaceHolderName("Type Your Component");
         setOwnComponentOpen(false);
         setComponentsData([]);
         var arryObj = [];
         var tmpArray = {};
         tmpArray["componentId"] = "COMCUS";
         tmpArray["name"] = ownComponentName;
         arryObj.push(tmpArray);
         setComponentsData(arryObj);
         setComponentId("COMCUS");
         setOwnComponentFlag(true);
     }
     /** End of Create Own Component */


    /**
     * Create own Principal
     */
     const [ownPrincipalFlag, setOwnPrincipalFlag] = React.useState(false);
     const [ownPrincipalName, setOwnPrincipalName] = React.useState('');
     const [ownPrincipalOpen, setOwnPrincipalOpen] = React.useState(false);
     const [ownPrincipalScroll, setOwnPrincipalScroll] = React.useState('paper');
     const ownPrincipalHandleClickOpen = (scrollType) => () => {
        setOwnPrincipalOpen(true);
        setOwnPrincipalScroll(scrollType);
     };
     const ownPrincipalHandleClickClose = () => {
         setOwnPrincipalOpen(false);
     };
     const handleChangeOwnPrincipal = (event) => {
        setOwnPrincipalName(event.target.value);
     };
     
     const [previousOwnPrincipleId, setPreviousOwnPrincipleId] = React.useState('');
     const handleChangePreviousOwnPrinciple = (event) => {
        var id = event.target.value;
        setPreviousOwnPrincipleId(id);
        centreOwnPrinciples.forEach((data)=>{
            if (data.id === id){
                setOwnPrincipalName(data.name);
            }
        });    
        
     };
     
     function setOwnPrincipalFunction(){
         if ( ownPrincipalName === ""){
             alert("Please enter Your Own Principle");
             return;
         }
         setOwnPrincipalOpen(false);
         setPrinciplePlaceHolderName("Type Your Principle");
         setPrincipalsData([]);
         var arryObj = [];
         var tmpArray = {};
         tmpArray["principalId"] = "PRICUS";
         tmpArray["name"] = ownPrincipalName;
         arryObj.push(tmpArray);
         setPrincipalsData(arryObj);
         setPrincipleId("PRICUS");
         setOwnPrincipalFlag(true);
     }
     /** End of Create Own principle */

    const [standardData, setStandardData] = React.useState([]);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setObjectivedData([]);
        setComponentsData([]);
        setPrincipalsData([]);
        setEntityData([]);
        setSelectedStandardName('');

        document.getElementById('customBtn').className = classes.globalBtnAfter; 
        standardData.forEach((data1,index)=>{
            if (data1.centreId === "globle"){
              var idValue = document.getElementById('btn' + index);
              if (idValue != undefined) {
                idValue.className = classes.globalBtn; 
              }  
            }
        })

    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [customId, setCustomId] = React.useState('');

    function cusData(id){
        standardData.forEach((item)=>{
            if(item.standardId === id){
                getObjectives(item.objectives,item.components,item.principles,item.entities,item.standardName);
                    handleClose(false) 
            }                   
        })
    }

    const handleChangeCustom = (event) => {
        setCustomId(event.target.value);
        cusData(event.target.value)
    };
    /** Sort abject array */
    function compare( a, b ) {
        if ( a.orderNumber < b.orderNumber ){
            return -1;
        }
        if ( a.orderNumber > b.orderNumber ){
            return 1;
        }
        return 0;
    }

    function compareShortName( a, b ) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if ( nameA < nameB ){
          return -1;
      }
      if ( nameA > nameB ){
          return 1;
      }
      // names must be equal
      return 0;
    }

    function range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }
      

    function getStandards(cntre) {
        axios(CONFIG.MAIN_URL+`/standard/all??centreId=`+cntre,{
          method: 'get',
        })
        .then(function (response) {
          console.log(response.data);
          console.log(JSON.stringify(response.data));
          //setStandardData(response.data.standards)
          setStandardData(response.data)
          //setStandardData(standardDataJs)
        })
        .catch(function (error) {
          console.log(error);
        });
      };

      const [stdName, setStdName] = useState()
      const [objName, setObjName] = useState()

      function objValurs(a,b){
            console.log(a)
            console.log(b)
            setStdName(a)
            setObjName(b)
      }

    
    const [entityData, setEntityData] = React.useState([]);
    const [entity, setEntity] = React.useState('');
    const [entityName, setEntityName] = React.useState('');
    const handleChangeEntity = (event) => {
        var entityId=event.target.value;
        setEntity(entityId);
        entityData.forEach((data,index)=>{
            if (data.entityId === entityId) {   
                setEntityName(data.entityName); 
            }
        })
    };
    const [allEntityData, setAllEntityData] = React.useState([]);  
    function getEntityData(centreId){
        var data = '{allEntityList{ entityDataList{entityName,entityId,isActive,createdBy,lastModifiedBy,centreId,basicEntity{basicEntityId,entityName,iamRole,forceIamRole}}}}';
        axios(CONFIG.MAIN_URL+'/graphql',{
        method: 'post',
        headers: { 
            'Content-Type': 'text/plain',
          },
        data : data,  
        })
        .then(function (response) {
            console.log("++++++++++++++++++++++++entityList+++++++++++++++++++++");
            var entityList = response.data.data.allEntityList.entityDataList;
            console.log(entityList);

            var tmpEntityData = [];
            entityList.forEach((item, index)=>{
                if (item.isActive && item.centreId === centreId) {  
                    var be = item.basicEntity
                    var forceIamRole = item.basicEntity.forceIamRole;
                    var iamRole = item.basicEntity.iamRole;  
                    var canTake = true;
                    if ((iamRole === "ADMIN" && forceIamRole === "ADMIN") || (iamRole === "supplier" && forceIamRole === "supplier") || (iamRole === "serviceprovider" && forceIamRole === "serviceprovider") || (iamRole === "volunteer" && forceIamRole === "volunteer")){
                        canTake = false;
                    }
                    if (canTake){
                        var arrayData = {};
                        arrayData["entityId"] = item.entityId;
                        arrayData["entityName"] = item.entityName;
                        tmpEntityData.push(arrayData);
                    }
                }
            })
            console.log("++++++++++++++++++++++++tmpEntityData+++++++++++++++++++++");
            console.log(tmpEntityData);
            setAllEntityData(tmpEntityData);
        
        })
        .catch(function (error) {
            console.log("Entity Loding error" + error);
        });
    }

    const classes = useStyles();


    /** These variables are used for closeEnded componebts */
    const [ansMethod, setAnsMethod] = React.useState("");
    const [minNumber, setMinNumber] = React.useState(1);
    const [maxNumber, setMaxNumber] = React.useState(5);
    const [optArray, setOptArray] = React.useState([1,2,3,4,5]);
    const [scalingMethod, setScalingMethod] = React.useState("");
    const [scallingMgs, setScallingMsg] = React.useState("");
    const [closeEndedTypeName, setCloseEndedTypeName] = React.useState("");
    const [closeEndedTypeStatus, setCETypeStatus] = React.useState(0);
    const [ansArrayTmp, setAnsArrayTmp] = React.useState([]);
    const [answerOrderNumber, setAnswerOrderNumber] = React.useState(1);
    const [selectedSuggestedValue, setSelectedSuggestedValue] = React.useState(0);
    const [closeEndedTypeMgs, setCloseEndedTypeMsg] = React.useState("");
    const [questionLableNameOpenEnded, setQuestionLableNameOpenEnded] = React.useState("Open Ended Question");
    const [questionLableNameCloseEnded, setQuestionLableNameCloseEnded] = React.useState("Close Ended Question");


    console.log(ansArrayTmp)



    const [openEndedvalue, setOEQValue] = React.useState('');
    const [closeEndedvalue, setCEQValue] = React.useState('');
    const handleOpenEndedChange = (event) => {
        setOEQValue(event.target.value);
        setCEQValue('');
        console.log(openEndedvalue);
        console.log(closeEndedvalue);
    };
    const handleCloseEndedChange = (event) => {
        setCEQValue(event.target.value);
        setOEQValue('');
        console.log(openEndedvalue);
        console.log(closeEndedvalue);
    };
    /** Scrore of the Open ended Question */
    const [openEndedScoreValue, setOpenEndedScoreValue] = React.useState('');
    const handleOpenEndedScoreChange = (event) => {
        const value = event.target.value.replace(/\D/g, "");
        setOpenEndedScoreValue(value);
    };

    /** Answer of the Open ended Question */
    const [openEndedAnswerValue, setOpenEndedAnswerValue] = React.useState('');
    const handleOpenEndedAnswerChange = (event) => {
        setOpenEndedAnswerValue(event.target.value);
    };

    /** Scrore of the Close ended Question */
    const [closeEndedScoreValue, setCloseEndedScoreValue] = React.useState('');
    const handleCloseEndedScoreChange = (event) => {
        const value = event.target.value.replace(/\D/g, "");
        setCloseEndedScoreValue(value);
    };

    const [openCloseEndedStatus, setOCStatus] = React.useState(1);
    const radioHandler = (status) => {
        setOCStatus(status);
        console.log(openCloseEndedStatus);
    };

    const [selectCustomBtn, setSelectCustomBtn] = React.useState(false);
    const handleCustomStandard = (event) => {
        setSelectCustomBtn(true);
        var arryObj = [];
        var tmpArray = {};
        tmpArray["objectiveId"] = "OBJCUS";
        tmpArray["name"] = "Custom (Select this for assinging Custom Objevtive)";
        arryObj.push(tmpArray);
        setObjectivedData(arryObj);

        arryObj = [];
        tmpArray = {};
        tmpArray["componentId"] = "COMCUS";
        tmpArray["name"] = "Custom (Select this for assinging Custom Component)";
        arryObj.push(tmpArray);
        setComponentsData(arryObj);

        arryObj = [];
        tmpArray = {};
        tmpArray["principalId"] = "PRICUS";
        tmpArray["name"] = "Custom (Select this for assinging Custom principle)";
        arryObj.push(tmpArray);
        setPrincipalsData(arryObj);

        setSelectedStandardName("CUSTOM");
        console.log(selectCustomBtn);
    };

    const [selectedStandardName, setSelectedStandardName] = React.useState('');
    const [objectiveData, setObjectivedData] = React.useState([]);
    const [componentsData, setComponentsData] = React.useState([]);
    const [principalsData, setPrincipalsData] = React.useState([]);
    const [objectiveId, setObjectiveId] = React.useState('');
    const [objectiveName, setObjectiveName] = React.useState('');
    const [componentId, setComponentId] = React.useState('');
    const [componentName, setComponentName] = React.useState('');
    const [principleId, setPrincipleId] = React.useState('');
    const [principleName, setPrincipleName] = React.useState('');
    function getObjectives(standerdItemObjective, standerdItemComponent, standerdItemPrincipal, standerdItemEntity,stdName,indexNo){
        setObjectiveId('');
        setComponentId('');
        setPrincipleId('');
        setObjectivedData([]);
        if (standerdItemObjective != null) {
            setObjectivedData(standerdItemObjective);
        }
        if (standerdItemComponent != null) {
            setComponentsData(standerdItemComponent);
        }
        if (standerdItemPrincipal != null) {
            setPrincipalsData(standerdItemPrincipal);
        }
        if (standerdItemEntity != null) {
            setEntityData(standerdItemEntity);
        }
        setSelectedStandardName(stdName);
        setSelectCustomBtn(false);
        standardData.forEach((data1,index)=>{
            if (data1.centreId === "globle"){
              var idValue = document.getElementById('btn' + index);
              if (idValue != undefined) {
                if (indexNo === index) {  
                  idValue.className = classes.globalBtnAfter; 
                }else{
                  idValue.className = classes.globalBtn; 
                }
              }  
            }
        })
        document.getElementById('customBtn').className = classes.globalBtn; 
    }
    
    const handleChangeObjectives = (event) => {
        if (ownObjectiveFlag){
            setOwnObjectiveName(ownObjectiveName);
            setOwnObjectiveOpen(true);
            setOwnObjectiveScroll('paper');
        }else{
            console.log("ownObjectiveFlag : " + ownObjectiveFlag);
            const oId = event.target.value;
            console.log("oId : " + oId);
            //setObjectiveId('');
            //setComponentId('');
            //setPrincipleId('');
            setObjectiveId(oId);
            //setComponentsData([]);
            //setPrincipalsData([]);
            objectiveData.forEach((data,index)=>{
                if (data.objectiveId === oId) {   
                    setObjectiveName(data.name); 
                    //setComponentsData(data.component);
                }
            })
        }
    };
    
    const handleChangeComponents = (event) => {
        if (ownComponentFlag){
            setOwnComponentName(ownComponentName);
            setOwnComponentOpen(true);
            setOwnComponentScroll('paper');
        }else{
            const cId = event.target.value;
            //setComponentId('');
            //setPrincipleId('');

            setComponentId(cId);
            //setPrincipalsData([]);
            componentsData.forEach((data,index)=>{
                if (data.componentId === cId) {  
                    setComponentName(data.name);  
                    //setPrincipalsData(data.principal);
                }
            })
        }
    };
    
    const handleChangePrinciples = (event) => {
        if (ownPrincipalFlag){
            setOwnPrincipalName(ownPrincipalName);
            setOwnPrincipalOpen(true);
            setOwnPrincipalScroll('paper');
        }else{
            setPrincipleId('');
            const pId = event.target.value;
            setPrincipleId(pId);
            principalsData.forEach((data,index)=>{
                if (data.principalId === pId) {  
                    setPrincipleName(data.name);  
                }
            })
        }
    };

    /** Scrore of the Principle */
    const [principleScoreValue, setPrincipleScoreValue] = React.useState(1);
    const handlePrincipleScoreChange = (event) => {
        const value = event.target.value.replace(/[^0-9.]/g, "");
        setPrincipleScoreValue(value); 
        //    const value = event.target.value.replace(/\D/g, "");
        //    setPrincipleScoreValue(value);
        
    };



    const [maxWidth, setMaxWidth] = React.useState('md');
    /** Global Objective Dialog */
    const [globalObjOpen, setGlobalObjOpen] = React.useState(false);
    const [globalObjScroll, setGlobalObjScroll] = React.useState('paper');
    
    const globalObjHandleClickOpen = (scrollType) => () => {
      setGlobalObjOpen(true);
      setGlobalObjScroll(scrollType);
    };
    const globalObjHandleClickClose = () => {
      setGlobalObjOpen(false);
    };
    /** End of Global Objective Dialog */
    /** Custom Objective Dialog */
    const [customObjOpen, setCustomObjOpen] = React.useState(false);
    const [customObjScroll, setCustomObjScroll] = React.useState('paper');
    
    const customObjHandleClickOpen = (scrollType) => () => {
      setCustomObjOpen(true);
      setCustomObjScroll(scrollType);
    };
    const customObjHandleClickClose = () => {
      setCustomObjOpen(false);
    };
    /** End of Global Objective Dialog */
    /** Global Component Dialog */
    const [globalCompOpen, setGlobalCompOpen] = React.useState(false);
    const [globalCompScroll, setGlobalCompScroll] = React.useState('paper');
    
    const globalCompHandleClickOpen = (scrollType) => () => {
      setGlobalCompOpen(true);
      setGlobalCompScroll(scrollType);
    };
    const globalCompHandleClickClose = () => {
      setGlobalCompOpen(false);
    };
    /** End of Global Component Dialog */
    /** Custom Component Dialog */
    const [customCompOpen, setCustomCompOpen] = React.useState(false);
    const [customCompScroll, setCustomCompScroll] = React.useState('paper');
    
    const customCompHandleClickOpen = (scrollType) => () => {
      setCustomCompOpen(true);
      setCustomCompScroll(scrollType);
    };
    const customCompHandleClickClose = () => {
      setCustomCompOpen(false);
    };
    /** End of Custom Component Dialog */
    /** Global Principles Dialog */
    const [globalPriOpen, setGlobalPriOpen] = React.useState(false);
    const [globalPriScroll, setGlobalPriScroll] = React.useState('paper');
   
    const globalPriHandleClickOpen = (scrollType) => () => {
      setGlobalPriOpen(true);
      setGlobalPriScroll(scrollType);
    };
    const globalPriHandleClickClose = () => {
      setGlobalPriOpen(false);
    };
    /** End of Global Principles Dialog */
    /** Custom Principles Dialog */
    const [customPriOpen, setCustomPriOpen] = React.useState(false);
    const [customPriScroll, setCustomPriScroll] = React.useState('paper');
   
    const customPriHandleClickOpen = (scrollType) => () => {
      setCustomPriOpen(true);
      setCustomPriScroll(scrollType);
    };
    const customPriHandleClickClose = () => {
      setCustomPriOpen(false);
    };
    /** End of Custom Principles Dialog */
    /** Global Entities Dialog */
    const [globalEntOpen, setGlobalEntOpen] = React.useState(false);
    const [globalEntScroll, setGlobalEntScroll] = React.useState('paper');
   
    const globalEntHandleClickOpen = (scrollType) => () => {
      setGlobalEntOpen(true);
      setGlobalEntScroll(scrollType);
    };
    const globalEntHandleClickClose = () => {
      setGlobalEntOpen(false);
    };
    /** End of Global Entities Dialog */
    /** Custom Principles Dialog */
    const [customEntOpen, setCustomEntOpen] = React.useState(false);
    const [customEntScroll, setCustomEntScroll] = React.useState('paper');
   
    const customEntHandleClickOpen = (scrollType) => () => {
      setCustomEntOpen(true);
      setCustomEntScroll(scrollType);
    };
    const customEntHandleClickClose = () => {
      setCustomEntOpen(false);
    };
    /** End of Custom Entities Dialog */

   
    

    const [selectedObjectiveStandardName, setSelectedObjectiveStandardName] = React.useState('');
    const [selectedObjectiveStandardId, setSelectedObjectiveStandardId] = React.useState('');
    const [selectedComponentStandardName, setSelectedComponentStandardName] = React.useState('');
    const [selectedComponentStandardId, setSelectedComponentStandardId] = React.useState('');
    const [selectedPrincipleStandardName, setSelectedPrincipleStandardName] = React.useState('');
    const [selectedPrincipleStandardId, setSelectedPrincipleStandardId] = React.useState('');
    const [selectedEntityStandardName, setSelectedEntityStandardName] = React.useState('');
    const [selectedEntityStandardId, setSelectedEntityStandardId] = React.useState('');


    function getStandardObjectives(standerdItemObjective,stdName,stdId){
        setObjectivedData([]);
        if (standerdItemObjective != null) {
            standerdItemObjective.sort(compare);
            setObjectivedData(standerdItemObjective);
            setObjectivePlaceHolderName("Select Objective from " + stdName);
        }
        standardData.forEach((item)=>{
            if(item.standardId === stdId){
                if ((!ownComponentFlag && ownComponentName === "") && componentId === "" ){
                    if (item.components != undefined && item.components != null && item.components.length > 0){
                        setComponentsData(item.components);
                        setComponentPlaceHolderName("Select Component from " + stdName);
                        setSelectedComponentStandardName(stdName);
                        setSelectedComponentStandardId(stdId);
                        setGlobalCompOpen(false);
                        setOwnComponentFlag(false);
                        setOwnComponentName('');
                    }
                }
                if ((!ownPrincipalFlag && ownPrincipalName === "") && principleId === "" ){
                    if (item.principles != undefined && item.principles != null && item.principles.length > 0){
                      var arr = [];
                      item.principles.forEach((data)=>{
                        if (data.shortName === undefined || data.shortName === null){
                          data["shortName"] = "null"; 
                        }else{
                          var tArr = data.shortName.split(" (Custom)");
                          var dataSName = tArr[0];
                          data["name"] = dataSName + " - " + data.name; 
                        }
                        arr.push(data);
                      })
                      arr = arr.sort(compareShortName);
                      setPrincipalsData(arr);  
                      //setPrincipalsData(item.principles);
                        setPrinciplePlaceHolderName("Select Principle from " + stdName);
                        setSelectedPrincipleStandardName(stdName);
                        setSelectedPrincipleStandardId(stdId);
                        setGlobalPriOpen(false);
                        setOwnPrincipalFlag(false);
                        setOwnPrincipalName('');
                    }
                }
                if ( entity === "" && entityName === ""){
                    if (item.entities != undefined && item.entities != null && item.entities.length > 0){
                        setEntityData(item.entities);
                        setEntityPlaceHolderName("Select Entity from " + stdName);
                        setSelectedEntityStandardName(stdName);
                        setSelectedEntityStandardId(stdId);
                        setGlobalEntOpen(false);
                    }
                }
            }
        });        
        

        setSelectedObjectiveStandardName(stdName);
        setSelectedObjectiveStandardId(stdId);
        setGlobalObjOpen(false);

        setOwnObjectiveFlag(false);
        setOwnObjectiveName('');
    }

    function cusStdObjData(id){
        standardData.forEach((item)=>{
            if(item.standardId === id){
                getStandardObjectives(item.objectives,item.standardName,item.standardId);
                setCustomObjOpen(false); 
            }                   
        })
    }
    const [customObjectiveStanardId, setCustomObjectiveStanardId] = React.useState('');
    const handleChangeCustomObjectiveStanard = (event) => {
        cusStdObjData(event.target.value)
    };

    function getStandardComponents(standerdItemComponent,stdName,stdId){
        setComponentsData([]);
        if (standerdItemComponent != null) {
            standerdItemComponent.sort(compare);
            setComponentsData(standerdItemComponent);
            setComponentPlaceHolderName("Select Component from " + stdName);
        }
        standardData.forEach((item)=>{
            if(item.standardId === stdId){
                if ((!ownPrincipalFlag && ownPrincipalName === "") && principleId === "" ){
                    if (item.principles != undefined && item.principles != null && item.principles.length > 0){
                      var arr = [];
                      item.principles.forEach((data)=>{
                        if (data.shortName === undefined || data.shortName === null){
                          data["shortName"] = "null"; 
                        }else{
                          var tArr = data.shortName.split(" (Custom)");
                          var dataSName = tArr[0];
                          data["name"] = dataSName + " - " + data.name; 
                        }
                        arr.push(data);
                      })
                      arr = arr.sort(compareShortName);
                      setPrincipalsData(arr); 
                        //setPrincipalsData(item.principles);
                        setPrinciplePlaceHolderName("Select Principle from " + stdName);
                        setSelectedPrincipleStandardName(stdName);
                        setSelectedPrincipleStandardId(stdId);
                        setGlobalPriOpen(false);
                        setOwnPrincipalFlag(false);
                        setOwnPrincipalName('');
                    }
                }
                if ( entity === "" && entityName === ""){
                    if (item.entities != undefined && item.entities != null && item.entities.length > 0){
                        setEntityData(item.entities);
                        setEntityPlaceHolderName("Select Entity from " + stdName);
                        setSelectedEntityStandardName(stdName);
                        setSelectedEntityStandardId(stdId);
                        setGlobalEntOpen(false);
                    }
                }
            }
        });


        setSelectedComponentStandardName(stdName);
        setSelectedComponentStandardId(stdId);
        setGlobalCompOpen(false);

        setOwnComponentFlag(false);
        setOwnComponentName('');
    }

    function cusStdCompData(id){
        standardData.forEach((item)=>{
            if(item.standardId === id){
                getStandardComponents(item.components,item.standardName,item.standardId);
                setCustomCompOpen(false); 
            }                   
        })
    }
    const [customComponentStanardId, setCustomComponentStanardId] = React.useState('');
    const handleChangeCustomComponentStanard = (event) => {
        cusStdCompData(event.target.value)
    };

    function getStandardPrinciples(standerdItemPrinciple,stdName,stdId){
        setPrincipalsData([]);
        if (standerdItemPrinciple != null) {
          var arr = [];
          standerdItemPrinciple.forEach((data)=>{
            if (data.shortName === undefined || data.shortName === null){
              data["shortName"] = "null"; 
            }else{
              var tArr = data.shortName.split(" (Custom)");
              var dataSName = tArr[0];
              data["name"] = dataSName + " - " + data.name; 
            }
            arr.push(data);
          })
          arr = arr.sort(compareShortName);
          setPrincipalsData(arr); 

            //standerdItemPrinciple.sort(compare);
            //setPrincipalsData(standerdItemPrinciple);
            setPrinciplePlaceHolderName("Select Principle from " + stdName);
        }
        standardData.forEach((item)=>{
            if(item.standardId === stdId){
                if ( entity === "" && entityName === ""){
                    if (item.entities != undefined && item.entities != null && item.entities.length > 0){
                        setEntityData(item.entities);
                        setEntityPlaceHolderName("Select Entity from " + stdName);
                        setSelectedEntityStandardName(stdName);
                        setSelectedEntityStandardId(stdId);
                        setGlobalEntOpen(false);
                    }
                }
            }
        });
        setSelectedPrincipleStandardName(stdName);
        setSelectedPrincipleStandardId(stdId);
        setGlobalPriOpen(false);

        setOwnPrincipalFlag(false);
        setOwnPrincipalName('');
    }


    function cusStdPriData(id){
        standardData.forEach((item)=>{
            if(item.standardId === id){
                getStandardPrinciples(item.principles,item.standardName,item.standardId);
                setCustomPriOpen(false); 
            }                   
        })
    }
    const [customPrincipleStanardId, setCustomPrincipleStanardId] = React.useState('');
    const handleChangeCustomPrincipleStanard = (event) => {
        cusStdPriData(event.target.value)
    };

    function getStandardEntities(standerdItemEntity,stdName,stdId){
        setEntityData([]);
        if (standerdItemEntity != null) {
            standerdItemEntity.sort(compare);
            setEntityData(standerdItemEntity);
            setEntityPlaceHolderName("Select Entity from " + stdName);
        }
        setSelectedEntityStandardName(stdName);
        setSelectedEntityStandardId(stdId);
        setGlobalEntOpen(false);
    }

    function cusStdEntData(id){
        standardData.forEach((item)=>{
            if(item.standardId === id){
                getStandardEntities(item.entities,item.standardName,item.standardId);
                setCustomEntOpen(false); 
            }                   
        })
    }
    const [customEntityStanardId, setCustomEntityStanardId] = React.useState('');
    const handleChangeCustomEntityStanard = (event) => {
        cusStdEntData(event.target.value)
    };

    const setAllEntities = (event) => {
        setEntityData(allEntityData);
        setEntityPlaceHolderName("Select an Entity from all available entities");
        setSelectedEntityStandardName('');
        setSelectedEntityStandardId('');
    };

    const location = useLocation();
    const collectionUniqueName = collectionIdFromEval;//location.pathname.split("/")[2];
    const [collectnId, setCollectnId] = React.useState('');

    const [riskTypeData, setRiskTypeData] = React.useState([]);
    const [rsType, setRsTtpe] = useState([])

    /** To get the Risk Hitachy array from the Collection */
    useEffect(() => {
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
            method: 'get',
        })
        .then(function (response) {
            console.log("Collection response ");
            console.log(response.data);

            setCollectnId(response.data[0].id);

            var tmpRiskArr = [];
            if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
              tmpRiskArr = response.data[0].risks; 
            }
            setRiskTypeData(tmpRiskArr);
            //To Get All questions in The Collection and put in to "setQuesData"
            getAllQuestions(tmpRiskArr);
            setSelectedCollectionEvaluateId(response.data[0].id);
            setSelectedCollectionEvaluateUniqueName(response.data[0].collectionUniqueName);
            setSelectedCollectionEvaluateTitle(response.data[0].title);

        })
        .catch(function (error) {
          console.log(error);
        });
    },[])

    /** To get collections with Risk after saving the question*/
    function reloadCollectionWithRiks(){
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
            method: 'get',
        })
        .then(function (response) {
              console.log("Collection response ");
              console.log(response.data);
              var tmpRiskArr = [];
              if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
                tmpRiskArr = response.data[0].risks; 
              }
              setRiskTypeData(tmpRiskArr);
              
              //To Get All questions in The Collection and put in to "setQuesData"
              getAllQuestions(tmpRiskArr);
              setSelectedCollectionEvaluateId(response.data[0].id);
              setSelectedCollectionEvaluateUniqueName(response.data[0].collectionUniqueName);
              setSelectedCollectionEvaluateTitle(response.data[0].title);
  
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    /** To get All questions from the collection THIS SHOULD GET FROM THE GRAPHQL*/
    const [quesData, setQuesData] = useState([]);   

    /** Get all question from the collection and put in to attay */
    var allQuesArray = [];
    function getAllQuestions(riskArray){
        allQuesArray = [];
        riskArray.forEach((item)=>{
        if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
            item.riskTypes.forEach((item1)=>{
            if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
                item1.questions.forEach((item2)=>{
                allQuesArray.push(item2);
                })  
            }
            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
                getAllQuestionsInSubRisks(item1.subRisks);
            }
            });
        }
        });
        if (allQuesArray.length > 0){
        var quesArray = allQuesArray.sort (compare);
        setQuesData(allQuesArray);
        }
    }
    function getAllQuestionsInSubRisks(riskArray){
        riskArray.forEach((item)=>{
        if (item.questions != undefined && item.questions != null && item.questions.length > 0){
            item.questions.forEach((item1)=>{
            allQuesArray.push(item1);
            })  
        }
        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
            getAllQuestionsInSubRisks(item.subRisks);
        }
        });  
    }

    useEffect(() => {
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
            method: 'get',
        })
        .then(function (response) {
            console.log("Get Collection And Put In to Question array");
            console.log(response.data);
            /*
            var quesArray = response.data.questions.sort (compare);
            setQuesData(quesArray);
            */
            var tmpRiskArr = [];
            if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
              tmpRiskArr = response.data[0].risks; 
            }
            getAllQuestions(tmpRiskArr);

            setSelectedCollectionEvaluateId(response.data[0].id);
            setSelectedCollectionEvaluateUniqueName(response.data[0].collectionUniqueName);
            setSelectedCollectionEvaluateTitle(response.data[0].title);

        })
        .catch(function (error) {
            console.log(error);
        });
    },[])

    /** To get All questions from the collection after saving the question THIS SHOULD GET FROM THE GRAPHQL*/
    function getAllQuestionsForCollection() {
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
            method: 'get',
        })
        .then(function (response) {
            console.log("Get Collection and put Question Array after Question Savesaving");
            console.log(response.data);
            var tmpRiskArr = [];
            if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
              tmpRiskArr = response.data[0].risks; 
            }
            getAllQuestions(tmpRiskArr);

            setSelectedCollectionEvaluateId(response.data[0].id);
            setSelectedCollectionEvaluateUniqueName(response.data[0].collectionUniqueName);
            setSelectedCollectionEvaluateTitle(response.data[0].title);
            /*
            var quesArray = response.data.questions.sort (compare);
            setQuesData(quesArray);
            */
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    
    const [selectedCollectionRiskId, setSelectedCollectionRiskId] = React.useState(0);
    const [previousSelectedCollectionRiskId, setPreviousSelectedCollectionRiskId] = React.useState(0);
    const handleChangeHierarchy = (event) => {
        var hierarchyId = event.target.value;
        setSelectedCollectionRiskId(hierarchyId)
        riskTypeData.forEach((item,index)=>{
            if (item.collectionRiskId === hierarchyId) {  
                setRsTtpe(item.riskTypes);
            }
        })
    };




    console.log(rsType);
    const search = useLocation().search;
    const questId = questionIdFromEval;//new URLSearchParams(search).get('questionid');
    const subQuestion = new URLSearchParams(search).get('subquestion');
    const [paramsAvails, setParamsAvails] = React.useState(true);
    const [selectedQuestionId, setSelectedQuestionId] = React.useState('');
    console.log("++++++++++++++++++++questId++++++++++++++++++++");
    console.log(questId);
    console.log("++++++++++++++++++++questId++++++++++++++++++++");

    

    function getSelectedQuestions(riskArray, qId){
        var qData = null;
        riskArray.forEach((item)=>{
          if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
            item.riskTypes.forEach((item1)=>{
              if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
                item1.questions.forEach((item2)=>{
                    if (item2.questionId === qId){
                        var tmpObj1 = item2.riskTypes;
                        tmpObj1["riskLevelData"] = item1.riskLevelData;
                        tmpObj1["riskAppetiteData"] = item1.riskAppetiteData;
                        item2["riskTypes"] = tmpObj1;

                        qData = item2;
                    }
                })  
              }
              if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
                var qData1  = getSelectedQuestionsInSubRisks(item1.subRisks, qId, false);
                if (qData1 != undefined && qData1 != null){
                    
                    qData = qData1;
                }
              }
            });
          }
        });
        if (qData != undefined && qData != null){
            return qData;
        }
      }
    
      var qSubData = null;
      function getSelectedQuestionsInSubRisks(riskArray, qId, sameRoutine){
        if (!sameRoutine){
            qSubData = null;
        }
        riskArray.forEach((item)=>{
          if (item.questions != undefined && item.questions != null && item.questions.length > 0){
            item.questions.forEach((item1)=>{
                if (item1.questionId === qId){
                    var tmpObj1 = item1.riskTypes;
                    tmpObj1["riskLevelData"] = item.riskLevelData;
                    tmpObj1["riskAppetiteData"] = item.riskAppetiteData;
                    item1["riskTypes"] = tmpObj1;


                    qSubData = item1;
                }
            })  
          }
          if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
            getSelectedQuestionsInSubRisks(item.subRisks, qId, true);
          }
        });  
        return qSubData;
      }

      
      /** Find the  Risk Type details and Std details*/
      function setSelectedRiskTypeAndStds(riskArray, rId){
        riskArray.forEach((item)=>{
          if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
            item.riskTypes.forEach((item1)=>{
              var riskFound =  false;
              if (item1.collectionRiskTypeId === rId){
                riskFound =  true;
                if (item1.riskStandardData != undefined && item1.riskStandardData != null){
                  if (item1.riskStandardData.objectiveData != undefined && item1.riskStandardData.objectiveData != null){
                    setObjectivedData([]);
                    var arryObj = [];
                     var tmpArray = {};
                    tmpArray["objectiveId"] = item1.riskStandardData.objectiveData.objectiveId;
                    tmpArray["name"] = item1.riskStandardData.objectiveData.objectiveName;
                    arryObj.push(tmpArray);
                    setObjectivedData(arryObj);
                    setObjectiveId(item1.riskStandardData.objectiveData.objectiveId);
                    setObjectiveName(item1.riskStandardData.objectiveData.objectiveName);
                    setOwnObjectiveFlag(false);
                    setSelectedObjectiveStandardName(item1.riskStandardData.objectiveData.standardName);
                    setSelectedObjectiveStandardId(item1.riskStandardData.objectiveData.standardId);
                  }
                  if (item1.riskStandardData.componentData != undefined && item1.riskStandardData.componentData != null){
                    setComponentsData([]);
                    var arryObj = [];
                     var tmpArray = {};
                    tmpArray["componentId"] = item1.riskStandardData.componentData.componentId;
                    tmpArray["name"] = item1.riskStandardData.componentData.componentName;
                    arryObj.push(tmpArray);
                    setComponentsData(arryObj);
                    setComponentId(item1.riskStandardData.componentData.componentId);
                    setComponentName(item1.riskStandardData.componentData.componentName);
                    setOwnComponentFlag(false);
                    setSelectedComponentStandardName(item1.riskStandardData.componentData.standardName);
                    setSelectedComponentStandardId(item1.riskStandardData.componentData.standardId);
                  }
                  if (item1.riskStandardData.principleData != undefined && item1.riskStandardData.principleData != null){
                    setPrincipalsData([]);
                    var arryObj = [];
                     var tmpArray = {};
                    tmpArray["principalId"] = item1.riskStandardData.principleData.principleId;
                    tmpArray["name"] = item1.riskStandardData.principleData.principleName;
                    arryObj.push(tmpArray);
                    setPrincipalsData(arryObj);
                    setPrincipleId(item1.riskStandardData.principleData.principleId);
                    setPrincipleName(item1.riskStandardData.principleData.principleName);
                    setOwnPrincipalFlag(false);
                    setSelectedPrincipleStandardName(item1.riskStandardData.principleData.standardName);
                    setSelectedPrincipleStandardId(item1.riskStandardData.principleData.standardId);
                  }
                }
              }  
              if (!riskFound){
                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
                  setSelectedRiskTypeAndStdsForSubRisks(item1.subRisks, rId);
                }
              }
            });
          }
        });
      }
      function setSelectedRiskTypeAndStdsForSubRisks(riskArray, rId){
        riskArray.forEach((item1)=>{
          var riskFound =  false;
          if (item1.collectionRiskTypeId === rId){
            riskFound =  true;
            if (item1.riskStandardData != undefined && item1.riskStandardData != null){
              if (item1.riskStandardData.objectiveData != undefined && item1.riskStandardData.objectiveData != null){
                setObjectivedData([]);
                var arryObj = [];
                  var tmpArray = {};
                tmpArray["objectiveId"] = item1.riskStandardData.objectiveData.objectiveId;
                tmpArray["name"] = item1.riskStandardData.objectiveData.objectiveName;
                arryObj.push(tmpArray);
                setObjectivedData(arryObj);
                setObjectiveId(item1.riskStandardData.objectiveData.objectiveId);
                setObjectiveName(item1.riskStandardData.objectiveData.objectiveName);
                setOwnObjectiveFlag(false);
                setSelectedObjectiveStandardName(item1.riskStandardData.objectiveData.standardName);
                setSelectedObjectiveStandardId(item1.riskStandardData.objectiveData.standardId);
              }
              if (item1.riskStandardData.componentData != undefined && item1.riskStandardData.componentData != null){
                setComponentsData([]);
                var arryObj = [];
                 var tmpArray = {};
                tmpArray["componentId"] = item1.riskStandardData.componentData.componentId;
                tmpArray["name"] = item1.riskStandardData.componentData.componentName;
                arryObj.push(tmpArray);
                setComponentsData(arryObj);
                setComponentId(item1.riskStandardData.componentData.componentId);
                setComponentName(item1.riskStandardData.componentData.componentName);
                setOwnComponentFlag(false);
                setSelectedComponentStandardName(item1.riskStandardData.componentData.standardName);
                setSelectedComponentStandardId(item1.riskStandardData.componentData.standardId);
              }
              if (item1.riskStandardData.principleData != undefined && item1.riskStandardData.principleData != null){
                setPrincipalsData([]);
                var arryObj = [];
                 var tmpArray = {};
                tmpArray["principalId"] = item1.riskStandardData.principleData.principleId;
                tmpArray["name"] = item1.riskStandardData.principleData.principleName;
                arryObj.push(tmpArray);
                setPrincipalsData(arryObj);
                setPrincipleId(item1.riskStandardData.principleData.principleId);
                setPrincipleName(item1.riskStandardData.principleData.principleName);
                setOwnPrincipalFlag(false);
                setSelectedPrincipleStandardName(item1.riskStandardData.principleData.standardName);
                setSelectedPrincipleStandardId(item1.riskStandardData.principleData.standardId);
              }
            }
          }
          if (!riskFound){
            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
              setSelectedRiskTypeAndStdsForSubRisks(item1.subRisks, rId);
            }
          }
        });  
      }

      /** When trigger entring question from undividual risk */
      const rtnCollectionRiskId = new URLSearchParams(search).get('collectionRiskId');
      const rtnCollectionRiskTypeId = new URLSearchParams(search).get('collectionRiskTypeId');
      const rtnRiskTypeName = new URLSearchParams(search).get('riskTypeName');
      const rtnRiskLevel = new URLSearchParams(search).get('riskLevel');
      const rtnRiskLevelCategoryId = new URLSearchParams(search).get('riskLevelCategoryId');
      const rtnRiskLevelMethodId = new URLSearchParams(search).get('riskLevelMethodId');
      const rtnRiskAppetite = new URLSearchParams(search).get('riskAppetite');
      const rtnRiskAppetiteCategoryId = new URLSearchParams(search).get('riskAppetiteCategoryId');
      const rtnRiskAppetiteMethodId = new URLSearchParams(search).get('riskAppetiteMethodId');
      useEffect(() => {
        if (rtnCollectionRiskId != null && rtnCollectionRiskId != "" && rtnCollectionRiskTypeId != null && rtnCollectionRiskTypeId != "" && rtnRiskTypeName != null && rtnRiskTypeName != "") {

            if (paramsAvails){
                setWaitingOpen(true);    
                axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
                    method: 'get',
                })

                .then(function (response) {
                    console.log("response 3333333333 : " + response);
                    console.log(response);
                    console.log(rtnRiskTypeName);
                    setParamsAvails(false);

                    var tmpRiskArr = [];
                    if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
                      tmpRiskArr = response.data[0].risks; 
                      setSelectedRiskTypeAndStds(tmpRiskArr, rtnCollectionRiskTypeId);
                    }

                    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_RISK+"/all",{
                        method: 'get',
                        headers: { 
                            'Content-Type': 'text/plain', 
                          },     
                    })
                    .then(function (res) {
                        setWaitingOpen(false);
                        console.log("All Risk Returns");
                        console.log(res.data[0]);
                        var methodData = res.data[0].methods;
                        var methodId = res.data[0].id;
                        var riskTypeNameStr  = '';
                        methodData.forEach((data)=> {
                            if(rtnRiskLevelMethodId === data.id){
                                data.riskTypes.forEach((data1)=> {
                                if (data1.startingNumber <= rtnRiskLevel && rtnRiskLevel <= data1.endingNumber) {
                                    riskTypeNameStr = data1.riskTypesName;
                                }
                                })
                            }
                        })
                        var riskTypeAppNameStr  = '';
                        methodData.forEach((data)=> {
                            if(rtnRiskAppetiteMethodId === data.id){
                                data.riskTypes.forEach((data1)=> {
                                if (data1.startingNumber <= rtnRiskAppetite && rtnRiskAppetite <= data1.endingNumber) {
                                    riskTypeAppNameStr = data1.riskTypesName;
                                }
                                })
                            }
                        })

                        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                            method: 'get',
                            headers: { 
                                'Content-Type': 'text/plain', 
                              },     
                        })
                        .then(function (res) {
                            console.log("All colors");
                            console.log(res.data);
                            var colorDatas =  []
                            colorDatas = res.data
                            var risklvlColorData = getRisklvlColorData(rtnRiskLevel,colorDatas)
                            var riskAppetitelvlColorData = getRiskAppetitelvlColorData(rtnRiskAppetite,colorDatas)

                            var riskLevel = "";
                            var riskAppetite = "";
                            var riskLevelStr = "";
                            var riskAppetiteStr = "";
                            if (rtnRiskLevel != undefined && rtnRiskLevel != null && rtnRiskLevel != ""){
                                riskLevel = rtnRiskLevel;
                                riskLevelStr = " (RisK : " + riskLevel + "-" + riskTypeNameStr; 
                                setSelectedRiskLevel(riskLevel);
                            }
                            if (rtnRiskAppetite != undefined && rtnRiskAppetite != null && rtnRiskAppetite != ""){
                                riskAppetite = rtnRiskAppetite;
                                if (riskLevelStr != ""){
                                    riskAppetiteStr += ", Appetite : " + riskAppetite + "-" + riskTypeAppNameStr + ")";
                                }else{
                                    riskAppetiteStr = " (Appetite : " + riskAppetite + "-" + riskTypeAppNameStr + ")";
                                }
                                setSelectedRiskAppetite(riskAppetite);
                            }
                            //setSelectedRiskNameStr(rtnRiskTypeName + riskLevelStr + riskAppetiteStr);
                            setSelectedRiskNameStr(<div>{rtnRiskTypeName}<span style={{color:`${risklvlColorData}`}}> {riskLevelStr}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>);

                            var lbl = "Open Ended Question for "
                            setQuestionLableNameOpenEnded(<div>{lbl}{rtnRiskTypeName}<span style={{color:`${risklvlColorData}`}}> {riskLevelStr}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>)
                            var lbl1 = "Close Ended Question for "
                            setQuestionLableNameCloseEnded(<div>{lbl1}{rtnRiskTypeName}<span style={{color:`${risklvlColorData}`}}> {riskLevelStr}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>)



                            setSelectedCollectionRiskTypeId(rtnCollectionRiskTypeId);
                            setSelectedCollectionRiskId(rtnCollectionRiskId);
                            setSelectedRiskName(rtnRiskTypeName);
                        })    

                          


                  
                    
                    })
                    .catch(function (error) {
                        setWaitingOpen(false);
                        console.log(error);
                    });
                  


                })
                .catch(function (error) {
                    setWaitingOpen(false);
                    console.log(error);
                });
            }
        }

    });



    const [scalingMethodExamples, setScalingMethodExamples] = React.useState([]);
    const [scalingMethodExampleId, setScalingMethodExampleId] = React.useState('');
    const [scalingMethodExampleName, setScalingMethodExampleName] = React.useState('');


    useEffect(() => {

        
    if (questId != null && questId != "" && subQuestion != "ok") {
        //
        if (paramsAvails){
            /*
            axios(CONFIG.MAIN_URL+'/question/questionResponse?questionId='+ questId,{
                method: 'get',
            })
            */
            setWaitingOpen(true);
            axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
                method: 'get',
            })

            .then(function (response) {
                console.log(response.data)
                console.log("response : " + response);
                setParamsAvails(false);

                var CntreId = response.data[0].centreId;

                var tmpRiskArr = [];
                if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
                  tmpRiskArr = response.data[0].risks; 
                }
                console.log("+++++++++++++++++++Start to Build quetionData+++++++++++++++++");
                console.log(tmpRiskArr)
                var quetionData = getSelectedQuestions(tmpRiskArr,questId);
                console.log(quetionData);
                console.log("+++++++++++++++++++End to Build quetionData+++++++++++++++++");
                //var quetionData = response.data.questions[0];
                
                setSelectedQuestionId(quetionData.questionId);
                
                setHeaderName('Edit Questionnaire');
                setSaveBtnName('Update');
                setSaveDraftBtnName('Update Draft');
                setSelectYourRiskType('Display Other Risk Types');



                
                if (quetionData.riskTypes != null){

                    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_RISK+"/all",{
                        method: 'get',
                        headers: { 
                            'Content-Type': 'text/plain', 
                          },     
                    })
                    .then(function (res) {
                         
                        console.log("All Risk Returns");
                        console.log(res.data[0]);
                        setQuestionSliderMethods(res.data[0].methods)
                        setQuestionCategoryId(res.data[0].id)
                        console.log(res.data[0].methods);

                        var SliderMethods = []
                        SliderMethods = res.data[0].methods


                            

                        const riskTypeId = quetionData.riskTypes.riskTypeId === undefined || quetionData.riskTypes.riskTypeId === null?"":quetionData.riskTypes.riskTypeId;
                    
                        const collectionRiskId = quetionData.riskTypes.collectionRiskId;
                        const collectionRiskTypeId = quetionData.riskTypes.collectionRiskTypeId;
    
                        const riskTypeName = quetionData.riskTypes.name;
                        var riskLevel = "";
                        var riskAppetite = "";
                        var riskAppetiteStr = "";

  
                        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                            method: 'get',
                            headers: { 
                                'Content-Type': 'text/plain', 
                              },     
                            })
                            .then(function (res) {
                              console.log("All colors");
                              console.log(res.data);
                      
                              var colorDatas =  []
                              colorDatas = res.data
                             // setDatabClr(res.data)
                      
                             
                            
                        if (quetionData.riskTypes.riskLevel != undefined && quetionData.riskTypes.riskLevel != null && quetionData.riskTypes.riskLevel != ""){
                            
                            riskLevel = quetionData.riskTypes.riskLevel;
                            
                             var risklvl = getRisklvlNme(riskLevel,quetionData,SliderMethods)
                             var risklvlColorData = getRisklvlColorData(riskLevel,colorDatas)


                            console.log(risklvlColorData)

    
                            var RisklvlData = " (RisK : " + riskLevel + "-" +risklvl; 
                            setSelectedRiskLevel(riskLevel);
                        }
                        if (quetionData.riskTypes.riskAppetite != undefined && quetionData.riskTypes.riskAppetite != null && quetionData.riskTypes.riskAppetite != ""){
                            
                            riskAppetite = quetionData.riskTypes.riskAppetite;

                            var riskAppetitelvl = getRiskAppetitelvlNme(riskAppetite,quetionData,SliderMethods)

                            var riskAppetitelvlColorData = getRiskAppetitelvlColorData(riskAppetite,colorDatas)

                            if (RisklvlData != ""){
                                riskAppetiteStr += ", Appetite : " + riskAppetite + '-' + riskAppetitelvl;
                            }else{
                                riskAppetiteStr = " (Appetite : " + riskAppetite +'-' + riskAppetitelvl ;
                            }
                            setSelectedRiskAppetite(riskAppetite);
                        }
                        if (riskAppetiteStr != ""){
                            riskAppetiteStr += ")";
                        }

                        
                        setSelectedRiskNameStr(<div>{riskTypeName}<span style={{color:`${risklvlColorData}`}}> {RisklvlData}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>);

                        var lbl = "Open Ended Question for "
                        setQuestionLableNameOpenEnded(<div>{lbl}{riskTypeName}<span style={{color:`${risklvlColorData}`}}> {RisklvlData}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>)
                        var lbl1 = "Close Ended Question for "
                        setQuestionLableNameCloseEnded(<div>{lbl1}{riskTypeName}<span style={{color:`${risklvlColorData}`}}> {RisklvlData}</span>  <span style={{color:`${riskAppetitelvlColorData}`}}>{riskAppetiteStr}</span> </div>)
                        
                        setSelectedRiskId(riskTypeId);
    
                        console.log("++++++++++++++++++riskTypeIdriskTypeIdriskTypeId++++++++++++++++++");
                        console.log(riskTypeId);
                        
                        setSelectedCollectionRiskTypeId(collectionRiskTypeId);
                        setSelectedCollectionRiskId(collectionRiskId);
    
                        setPreviousSelectedCollectionRiskTypeId(collectionRiskTypeId);
                        setPreviousSelectedCollectionRiskId(collectionRiskId);
                        
                        setSelectedRiskName(riskTypeName);


                    })
                    
                     })
                    .catch(function (error) {
                    console.log(error);
                    });



                }

                if (quetionData.objective != null && quetionData.objective != ""){
                    if (quetionData.objective.id != undefined && quetionData.objective.id != null && quetionData.objective.id != "") {
                        setObjectivedData([]);
                        var arryObj = [];
                         var tmpArray = {};
                        tmpArray["objectiveId"] = quetionData.objective.id;
                        tmpArray["name"] = quetionData.objective.name;
                        arryObj.push(tmpArray);
                        setObjectivedData(arryObj);
                        setObjectiveId(quetionData.objective.id);
                        setObjectiveName(quetionData.objective.name);
                        setOwnObjectiveFlag(false);
                        setSelectedObjectiveStandardName(quetionData.objective.standardName);
                        setSelectedObjectiveStandardId(quetionData.objective.standardId);
                    }else{
                        setOwnObjectiveName(quetionData.objective.name);
                        setObjectivedData([]);
                        var arryObj = [];
                         var tmpArray = {};
                        tmpArray["objectiveId"] = "OBJCUS";
                        tmpArray["name"] = quetionData.objective.name;
                        arryObj.push(tmpArray);
                        setObjectivedData(arryObj);
                        setObjectiveId("OBJCUS");
                        setObjectiveName(quetionData.objective.name);
                        setOwnObjectiveFlag(true);
                    }

                }
                if (quetionData.component != null && quetionData.component != ""){
                    if (quetionData.component.id != undefined && quetionData.component.id != null && quetionData.component.id != "") {
                        setComponentsData([]);
                        var arryObj = [];
                         var tmpArray = {};
                        tmpArray["componentId"] = quetionData.component.id;
                        tmpArray["name"] = quetionData.component.name;
                        arryObj.push(tmpArray);
                        setComponentsData(arryObj);
                        setComponentId(quetionData.component.id);
                        setComponentName(quetionData.component.name);
                        setOwnComponentFlag(false);
                        setSelectedComponentStandardName(quetionData.component.standardName);
                        setSelectedComponentStandardId(quetionData.component.standardId);
                    }else{
                        setOwnComponentName(quetionData.component.name);
                        setComponentsData([]);
                        var arryObj = [];
                         var tmpArray = {};
                        tmpArray["componentId"] = "COMCUS";
                        tmpArray["name"] = quetionData.component.name;
                        arryObj.push(tmpArray);
                        setComponentsData(arryObj);
                        setComponentId("COMCUS");
                        setComponentName(quetionData.component.name);
                        setOwnComponentFlag(true);
                    }
                }
                if (quetionData.principle != null && quetionData.principle != ""){
                    if (quetionData.principle.id != undefined && quetionData.principle.id != null && quetionData.principle.id != "") {
                        setPrincipalsData([]);
                        var arryObj = [];
                         var tmpArray = {};
                        tmpArray["principalId"] = quetionData.principle.id;
                        tmpArray["name"] = quetionData.principle.name;
                        arryObj.push(tmpArray);
                        setPrincipalsData(arryObj);
                        setPrincipleId(quetionData.principle.id);
                        setPrincipleName(quetionData.principle.name);
                        setOwnPrincipalFlag(false);
                        setSelectedPrincipleStandardName(quetionData.principle.standardName);
                        setSelectedPrincipleStandardId(quetionData.principle.standardId);
                    }else{
                        setOwnPrincipalName(quetionData.principle.name);
                        setPrincipalsData([]);
                        var arryObj = [];
                         var tmpArray = {};
                        tmpArray["principalId"] = "PRICUS";
                        tmpArray["name"] = quetionData.principle.name;
                        arryObj.push(tmpArray);
                        setPrincipalsData(arryObj);
                        setPrincipleId("PRICUS");
                        setPrincipleName(quetionData.principle.name);
                        setOwnPrincipalFlag(true);
                    }
                }
                if (quetionData.entity != null && quetionData.entity != ""){
                    if (quetionData.entity.standardId != undefined && quetionData.entity.standardId != null && quetionData.entity.standardId != ""){
                        setSelectedEntityStandardName(quetionData.entity.standardName);
                        setSelectedEntityStandardId(quetionData.entity.standardId);
                    }
                    var tmpEntityData = [];
                    var arrayData = {}
                    arrayData["entityId"] = quetionData.entity.entityId;
                    arrayData["entityName"] = quetionData.entity.name;
                    tmpEntityData.push(arrayData);
                    setEntityData(tmpEntityData);
                    setEntity(quetionData.entity.entityId);
                    setEntityName(quetionData.entity.name);
                }


                if (quetionData.questionType != null && quetionData.questionType != ""){
                    if (quetionData.questionType === "Closeended"){
                        setOCStatus(2);
                        if (quetionData.questionTypeName != null && quetionData.questionTypeName != ""){
                            setCloseEndedTypeName(quetionData.questionTypeName);

                            if (quetionData.questionTypeName === "Nominal"){
                                setCETypeStatus(1);
                                setCloseEndedTypeMsg("Nominal scale is a naming scale, where Answers are simply \“named\” or labeled, with no specific order");
                            }
                            if (quetionData.questionTypeName === "Ordinal"){
                                setCETypeStatus(2);
                                setCloseEndedTypeMsg("Ordinal scale has all its Answers in a specific order, beyond just naming them");
                            }
                            if (quetionData.questionTypeName === "Interval"){
                                setCETypeStatus(3);
                                setCloseEndedTypeMsg("Interval scale offers labels, order, as well as, a specific interval between each of its variable options");
                            }
                            if (quetionData.questionTypeName === "Ratio"){
                                setCETypeStatus(4);
                                setCloseEndedTypeMsg("Ratio scale bears all the characteristics of an interval scale, in addition to that, it can also accommodate the value of “zero” on any of its variables");
                            }
                        } 

                        if (quetionData.question != null && quetionData.question != ""){
                            setCEQValue(quetionData.question);
                        } 
                        if (quetionData.questionScore != undefined && quetionData.questionScore != null && quetionData.questionScore != ""){
                            
                            axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION+"/all",{
                                method: 'get',
                                headers: { 
                                    'Content-Type': 'text/plain', 
                                  },     
                            })
                            .then(function (res) {
                                console.log("+++++++++++++++All Question Score Returns+++++++++++++++");
                                console.log(res.data[0]);
                                setQuestionSliderMethods(res.data[0].methods)
                                setQuestionCategoryId(res.data[0].id)
                                console.log(res.data[0].methods);
        
                                var SliderMethods = []
                                SliderMethods = res.data[0].methods
        
                                axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                    method: 'get',
                                    headers: { 
                                        'Content-Type': 'text/plain', 
                                      },     
                                })
                                .then(function (res1) {
                                      console.log("++++++++++++++All colors+++++++++++++++");
                                      console.log(res1.data);
                              
                                      var colorDatas =  []
                                      colorDatas = res1.data
                                })





                                var min = 0;
                                var max = 0
                                setShowSelectBarCloseEnded(false);
                                setSliderTypeCloseEnded(quetionData.questionScoreData.riskMethodId)
                                setSliderValueCloseEnded(quetionData.questionScore);
                                res.data[0].methods.forEach((data)=> {
                                    if(quetionData.questionScoreData.riskMethodId === data.id){
                                      data.riskTypes.forEach((data1)=> {
                                        if (data1.startingNumber <= quetionData.questionScore && quetionData.questionScore <= data1.endingNumber) {
                                          setSliderValueNameCloseEnded(data1.riskTypesName)
                                          min=data.minimumWeightingNumber;
                                          max=data.maximumWeightingNumber;
                                        }
                                      })
                                    }
                                  })


                                  var posNumber1 = parseInt(quetionData.questionScore) - min;
                                  var totalNumber1 = max - min;
                                  var percentageVal1 = (posNumber1/totalNumber1) * 100
                                  axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                    method: 'get',
                                    headers: { 
                                        'Content-Type': 'text/plain', 
                                      },     
                                    })
                                    .then(function (respo) {
                                      console.log("All colors");
                                      console.log(respo.data);
                              
                              
                                      respo.data.forEach((data)=> {
                                        if (parseInt(quetionData.questionScore) === parseInt(data.scoreNumber)){
                                          var cCode = data.color;
    
                                          var cssStr1 = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal1 + '%, #d3d3d3 0%)';
                                          //alert(cssStr1);
                                    
                                          setSliderValueCloseEndedStyle(cssStr1);
                                        }
                                      })
                              
                                     
                                    })
                                    .catch(function (error) {
                                    console.log(error);
                                    });

     
                            })

                            



                            .catch(function (error) {
                                console.log(error);
                            });
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                        }

                        if (quetionData.answers != null) {
                            if(quetionData.answers.answerMethod != null){
                                var minVal = 0;
                                var maxVal = 0;
                                if(quetionData.answers.answerMethod.name != null){
                                    setAnsMethod(quetionData.answers.answerMethod.name);
                                }    
                                if(quetionData.answers.answerMethod.minValue != null){
                                    setMinNumber(quetionData.answers.answerMethod.minValue);
                                    minVal = parseInt(quetionData.answers.answerMethod.minValue);
                                }    
                                if(quetionData.answers.answerMethod.maxValue != null){
                                    setMaxNumber(quetionData.answers.answerMethod.maxValue);
                                    maxVal = parseInt(quetionData.answers.answerMethod.maxValue);
                                }    
                                if (minVal > 0 && maxVal > 0){
                                    setOptArray(range(minVal,maxVal)) ;
                                }
                            }
                            if(quetionData.answers.answerScaleType != null){
                                if(quetionData.answers.answerScaleType.name != null){
                                    setScalingMethod(quetionData.answers.answerScaleType.name);


                                        axios(CONFIG.MAIN_URL+`/scale/get?centreId=`+ CntreId, {
                                                method: 'get',
                                              })
                                              .then(function (response) {
                                                console.log("++++++++++++++++Return scale Data +++++++++++++++++++");
                                                console.log(response.data);
                                                //setScaleData(response.data);
                                                response.data.forEach((data)=> {
                                                  console.log(quetionData.answers)
                                                  if (quetionData.answers.answerScaleType.name === data.id){
                                                   // alert(data.id)
                                                    setScallingMsg(data.description);
                                                    setScalingMethodExamples(data.examples)
                                                    // data.examples.forEach((data1)=> {
                                                    //   setScalingMethodExampleId(data1.exampleId)
                                                    // })
                                                    

                                                }
                                                })
                                                
                                              })
                                              .catch(function (error) {
                                                console.log(error);
                                              });
                                      
                                    
                                    // if (quetionData.answers.answerScaleType.name === "likert"){
                                    //     setScallingMsg("It is a psychometric scale questions. Typically a five, seven, or nine-point agreement scale are used");
                                    // }
                                    // if (quetionData.answers.answerScaleType.name === "semanticdifferential"){
                                    //     setScallingMsg("Answering options are grammatically on opposite adjectives at each end like love-hate, satisfied-unsatisfied, and likely to return-unlikely to return with intermediate options in between");
                                    // }
                                    // if (quetionData.answers.answerScaleType.name === "dichotomous"){
                                    //     setScallingMsg("It can have two possible answers like Yes/No, True/False, Fair/Unfair or Agree/Disagree answers");
                                    // }

                                } 
                            }    
                            if (quetionData.answers.answerList != null && quetionData.answers.answerList.length > 0) {
                                var tmpArry = [];
                                var count = 1;
                                quetionData.answers.answerList.forEach((ansData,index)=>{
                                    var arrayData = {}
                                    arrayData["answer"] = ansData.answerName;
                                    console.log(ansData)
                                    
                                    arrayData["risk"] = ansData.riskLevel;
                                    arrayData["riskLevelData"] = ansData.riskLevelData;

                                    if (ansData.expectedAnswerName != undefined && ansData.expectedAnswerName != null && ansData.expectedAnswerName != "null" && ansData.expectedAnswerName != ""){
                                        arrayData["expectedAnswerName"] = ansData.expectedAnswerName;
                                        if (quetionData.answers.answerMethod.name === "radioButtonType") {
                                            setSelectedSuggestedValue("" + index);
                                        }
                                    }else{
                                        arrayData["expectedAnswerName"] = "";
                                    }    
                                    arrayData["orderNumber"] = count;
                                   
                                    tmpArry.push(arrayData);
                                    count ++;
                                }); 
                                tmpArry.sort(compare);
                                setAnsArray(tmpArry);
                                setAnsArrayTmp(tmpArry);
                                setAnswerOrderNumber(count);
                            }

                        }    
                        
                        
                    }else{
                        setOCStatus(1);
                        if (quetionData.question != null && quetionData.question != ""){
                            setOEQValue(quetionData.question);
                        }
                        if (quetionData.questionScore != undefined && quetionData.questionScore != null && quetionData.questionScore != ""){
                            
                            axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION+"/all",{
                                method: 'get',
                                headers: { 
                                    'Content-Type': 'text/plain', 
                                  },     
                            })
                            .then(function (res) {
                                console.log("All Question Score Returns");
                                console.log(res.data[0]);
                                setQuestionSliderMethods(res.data[0].methods)
                                setQuestionCategoryId(res.data[0].id)
                                console.log(res.data[0].methods);
        
                                var SliderMethods = []
                                SliderMethods = res.data[0].methods
        
                                axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                    method: 'get',
                                    headers: { 
                                        'Content-Type': 'text/plain', 
                                      },     
                                })
                                .then(function (res1) {
                                      console.log("All colors");
                                      console.log(res1.data);
                              
                                      var colorDatas =  []
                                      colorDatas = res1.data
                                })
                                setShowSelectBar(false);
                                setSliderType(quetionData.questionScoreData.riskMethodId)
                                setSliderValue(quetionData.questionScore);

                                var min = 0;
                                var max = 0;
                                res.data[0].methods.forEach((data)=> {
                                    if(quetionData.questionScoreData.riskMethodId === data.id){
                                      data.riskTypes.forEach((data1)=> {
                                        if (data1.startingNumber <= quetionData.questionScore && quetionData.questionScore <= data1.endingNumber) {
                                          setSliderValueName(data1.riskTypesName)
                                          min=data.minimumWeightingNumber;
                                          max=data.maximumWeightingNumber;
                                        }
                                      })
                                    }
                                  })


                                  


                                  var posNumber1 = parseInt(quetionData.questionScore) - min;
                                  var totalNumber1 = max - min;
                                  var percentageVal1 = (posNumber1/totalNumber1) * 100
                                  axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
                                    method: 'get',
                                    headers: { 
                                        'Content-Type': 'text/plain', 
                                      },     
                                    })
                                    .then(function (response) {
                                      console.log("All colors");
                                      console.log(response.data);
                              
                              
                                      response.data.forEach((data)=> {
                                        if (parseInt(quetionData.questionScore) === parseInt(data.scoreNumber)){
                                          var cCode = data.color;
    
                                          var cssStr1 = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal1 + '%, #d3d3d3 0%)';
                                    
                                          setSliderValueStyle(cssStr1);
                                        }
                                      })
                              
                                     
                                    })
                                    .catch(function (error) {
                                    console.log(error);
                                    });

                                 


    
                            
                            })
                            .catch(function (error) {
                                console.log(error);
                            });



                            
                            
                            
                            
                        }
                        if (quetionData.answers != undefined && quetionData.answers != null) {
                            if (quetionData.answers.answer != undefined && quetionData.answers.answer != null && quetionData.answers.answer != "") {
                                setOpenEndedAnswerValue(quetionData.answers.answer);
                            }
                            if(quetionData.answers.answerMethod != null){
                                if(quetionData.answers.answerMethod.name != null){
                                    setAnsMethod(quetionData.answers.answerMethod.name);
                                }    
                            }
                        }
                    }
                }
                setWaitingOpen(false);

            })
            .catch(function (error) {
                setWaitingOpen(false);
                console.log(error);
            });

        }
        
        }
    },[])


    const [selectedColorForAppetiteSlider, setSelectedColorForAppetiteSlider] = useState(1)

    const [appetiteColorForSaveToDb, setAppetiteColorForSaveToDb] = useState('')
 
    const [ansArray, setAnsArray] = React.useState([]);

    function saveFunction(opt){
        var data = "{\"collectionUniqueName\":\"" + collectionUniqueName + "\",";
        if (selectedQuestionId != '' && subQuestion != "ok" ) {
            data += "\"questionId\":\"" + selectedQuestionId + "\",";
        }else{
            if (selectedQuestionId === 0 && subQuestion === "ok" ) {
                data += "\"mainQuestionId\":" + questId + ",";
            }
        }
        if (openCloseEndedStatus == 1){
            if (openEndedvalue === ""){
                alert("Please enter Open ended Questionnaire");
                return;
            }else{
                data += "\"question\":" + "\"" + openEndedvalue + "\",";
                data += "\"questionType\":" + "\"" + "Openended" + "\",";
                if (sliderValue === ""){
                    alert("Please enter Questionnaire Score");
                    return;
                }else {
                    data += "\"questionScore\":" + sliderValue + ",";
                    data += "\"questionScoreData\":{\"questionScore\":"  + sliderValue + ",\"riskCategoryId\":" + "\"" + questionCategoryId + "\",\"riskMethodId\":" + "\"" + sliderType + "\"},";
                }    

                data += "\"questionTypeName\":" + "\"" + "" + "\",";
            }
            
        }else{
            if (closeEndedvalue === ""){
                alert("Please enter Close ended Questionnaire");
                return;
            }else{

                //questionId    
                data += "\"question\":" + "\"" + closeEndedvalue + "\",";
                data += "\"questionType\":" + "\"" + "Closeended" + "\",";
                if (sliderValueCloseEnded === ""){
                    alert("Please enter Questionnaire Score");
                    return;
                }else {
                    data += "\"questionScore\":" + sliderValueCloseEnded + ",";
                    data += "\"questionScoreData\":{\"questionScore\":"  + sliderValueCloseEnded + ",\"riskCategoryId\":" + "\"" + questionCategoryId + "\",\"riskMethodId\":" + "\"" + sliderTypeCloseEnded + "\"},";
                    
                }
                data += "\"questionTypeName\":" + "\"" + closeEndedTypeName + "\",";
            }
        }
        //data += "\"standardName\":" + "\"" + selectedStandardName + "\",";
        if (opt === 1){
            data += "\"metaInfo\":{\"status\":" + "\"" + "complete" + "\"},";
        }else{
            data += "\"metaInfo\":{\"status\":" + "\"" + "draft" + "\"},";
        }

        if(selectedRiskName === ''){
            alert('please Select a risk type');
            return;
        }else{    
            //data += "\"riskType\":{\"collectionRiskTypeId\":" + selectedCollectionRiskTypeId + ", \"collectionRiskId\":" + selectedCollectionRiskId + ", \"riskTypeId\":" + selectedRiskId + ", \"name\":"+ "\"" + selectedRiskName + "\"},";
            //selectedCollectionRiskId
            //if (previousSelectedCollectionRiskId != 0 && previousSelectedCollectionRiskTypeId != 0 ){
            //    data += "\"riskType\":{\"previousCollectionRiskId\":" + previousSelectedCollectionRiskId + ", \"previousCollectionRiskTypeId\":" + previousSelectedCollectionRiskTypeId + ", \"collectionRiskTypeId\":" + selectedCollectionRiskTypeId + ", \"collectionRiskId\":" + selectedCollectionRiskId + ", \"riskTypeId\":" + selectedRiskId + ", \"name\":"+ "\"" + selectedRiskName + "\", \"riskLevel\":"+ "\"" + selectedRiskLevel + "\", \"riskAppetite\":"+ "\"" + selectedRiskAppetite + "\"},";
            //}else{
                data += "\"riskTypes\":{\"collectionRiskTypeId\":\"" + selectedCollectionRiskTypeId + "\", \"collectionRiskId\":\"" + selectedCollectionRiskId +  "\", \"name\":"+ "\"" + selectedRiskName + "\", \"riskLevel\":"+ "\"" + selectedRiskLevel + "\", \"riskAppetite\":"+ "\"" + selectedRiskAppetite + "\"},";
            //}
        }    

        if (ownObjectiveFlag){
            if (ownObjectiveName != null && ownObjectiveName != "") {
                data += "\"objective\":{\"id\":" + "\"" + "" + "\", \"name\":"+ "\"" + ownObjectiveName + "\"},";
            }else{
                alert("please Select an Objective");
                return;
            }
        }else{
            if (objectiveId === ''){
                alert("please Select an Objective");
                return;
            }else {    
                data += "\"objective\":{\"standardId\":" + "\"" + selectedObjectiveStandardId + "\",\"standardName\":" + "\"" + selectedObjectiveStandardName + "\",\"id\":" + "\"" + objectiveId + "\", \"name\":"+ "\"" + objectiveName + "\"},";
            }
        }
        if (ownComponentFlag){
            if (ownComponentName != null && ownComponentName != "") {
                data += "\"component\":{\"id\":" + "\"" + "" + "\", \"name\":"+ "\"" + ownComponentName + "\"},";
            }else{
                alert("please Select a Component");
                return;
            }
        }else{
            if (componentId === ''){
                alert("please Select a Component");
                return;
            }else {      
                data += "\"component\":{\"standardId\":" + "\"" + selectedComponentStandardId + "\",\"standardName\":" + "\"" + selectedComponentStandardName + "\",\"id\":" + "\"" + componentId + "\", \"name\":"+ "\"" + componentName + "\"},";
            }
        }
        if (ownPrincipalFlag){
            if (ownPrincipalName != null && ownPrincipalName != "") {
                //if (principleScoreValue === ''){
                //    alert("please enter Principle Score");
                //    return;
                //}else{
                    data += "\"principle\":{\"id\":" + "\"" + "" + "\", \"name\":"+ "\"" + ownPrincipalName + "\",\"principleScore\":" + principleScoreValue + "},";
                //}    
            }else{
                alert("please Select a Principle");
                return;
            }
        }else{
            if (principleId === ''){
                alert("please Select a Principle");
                return;
            }else {    
                //if (principleScoreValue === ''){
                //    alert("please enter Principle Score");
                //    return;
                //}else{  
                    data += "\"principle\":{\"standardId\":" + "\"" + selectedPrincipleStandardId + "\",\"standardName\":" + "\"" + selectedPrincipleStandardName + "\",\"id\":" + "\"" + principleId + "\", \"name\":"+ "\"" + principleName + "\",\"principleScore\":" + principleScoreValue + "},";
                //}    
            }    
        }
        
        
        if (entityName === ''){
            alert('please Select an Entity');
            return;
        }else{
            if (selectedEntityStandardId != ""){
                data += "\"entity\":{\"standardId\":" + "\"" + selectedEntityStandardId + "\",\"standardName\":" + "\"" + selectedEntityStandardName + "\",\"entityId\":" + "" + entity + ", \"name\":"+ "\"" + entityName + "\"},";
            }else{
                data += "\"entity\":{\"entityId\":" + "" + entity + ", \"name\":"+ "\"" + entityName + "\"},";
            }    
        }    

        if (openCloseEndedStatus == 1){
            //data += "\"answers\":{}";
            data += "\"answers\":{";
            data += "\"answerMethod\":{\"name\":" + "\"" + "freeTextType" + "\", \"minValue\":"+ "\"" + "0" + "\", \"maxValue\":"+ "\"" + "0" + "\"},";
            data += "\"answer\":" + "\"" + openEndedAnswerValue + "\"";
            data += "}";
        }else{
            data += "\"answers\":{";
            if (ansMethod === "orderNumberType"){
                data += "\"answerMethod\":{\"name\":" + "\"" + ansMethod + "\", \"minValue\":"+ "\"" + minNumber + "\", \"maxValue\":"+ "\"" + maxNumber + "\"},";
            }else{
                data += "\"answerMethod\":{\"name\":" + "\"" + ansMethod + "\", \"minValue\":"+ "\"" + "0" + "\", \"maxValue\":"+ "\"" + "0" + "\"},";
            }
            
            data += "\"answerScaleType\":{\"name\":" + "\"" + scalingMethod + "\"},";
            data += "\"answerList\":[";
            ansArray.forEach((item,index)=>{
                var expctedAns = item.expectedAnswerName;
                if (expctedAns === undefined || expctedAns === null || expctedAns === "null"){
                    expctedAns = "";
                }
                var levelData = item.riskLevelData;
                console.log("+++++++++++++++++++++++++++item+++++++++++++++++++++++++");
                console.log(item);
                console.log(levelData);
                console.log("+++++++++++++++++++++++++++item+++++++++++++++++++++++++");

                if (levelData.color != null && levelData.color != undefined && levelData.color != "" ) {
                  var levelDataStr = "{\"riskLevel\":" + levelData.riskLevel + ", \"riskCategoryId\":\"" + levelData.riskCategoryId + "\", \"riskMethodId\":\"" + levelData.riskMethodId + "\", \"color\":\"" + levelData.color + "\", \"secondaryColor\":\"" + "" + "\"}"
 
                }
                else{
                   levelDataStr = "{\"riskLevel\":" + levelData.riskLevel + ", \"riskCategoryId\":\"" + levelData.riskCategoryId + "\", \"riskMethodId\":\"" + levelData.riskMethodId + "\", \"color\":\"" + "" + "\", \"secondaryColor\":\"" + levelData.secondaryColor + "\"}"

                }
                
               
                if (ansArray.length -1 === index) {
                    data += "{\"answerName\":" + "\"" + item.answer + "\", \"answerType\":"+ "\"" + ansMethod + "\", \"riskLevel\":" + item.risk + ", \"expectedAnswerName\":"+ "\"" + expctedAns + "\", \"riskLevelData\":"+ levelDataStr + "}";
                }else{
                    data += "{\"answerName\":" + "\"" + item.answer + "\", \"answerType\":"+ "\"" + ansMethod + "\", \"riskLevel\":" + item.risk + ", \"expectedAnswerName\":"+ "\"" + expctedAns + "\", \"riskLevelData\":"+ levelDataStr + "},";
                }
            })
            data += "]";
            data += "}";
        }
        /*
        if (openCloseEndedStatus == 1){
            data += "\"answers\":[]";
        }else{
            data += "\"answers\":[";
            ansArray.forEach((item,index)=>{
                if (ansArray.length -1 === index) {
                    data += "{\"answerName\":" + "\"" + item.answer + "\", \"answerType\":"+ "\"" + ansMethod + "\", \"riskLevel\":" + item.risk + "}";
                }else{
                    data += "{\"answerName\":" + "\"" + item.answer + "\", \"answerType\":"+ "\"" + ansMethod + "\", \"riskLevel\":" + item.risk + "},";
                }
            })
            data += "]";
        }
        */
        data += "}";
        console.log(data);
        

        var saveUrl = CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_QUESTION+'/save';
        if (selectedQuestionId != '' && subQuestion != "ok" ) {
            saveUrl = CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_QUESTION+'/update';
        }
        setWaitingOpen(true);
        axios(saveUrl,{
        method: 'post',
        headers: { 
            'Content-Type': 'application/json',
          },
        data : data,  
        })
        .then(function (response) {
            console.log(JSON.stringify(response.data));
            setWaitingOpen(false);
            alert("Question updated successfully");
            setOEQValue("");
            setCEQValue("");
            setAnsArray([]);
            setAnsArrayTmp([]);
            setAnsMethod("");
            setMinNumber(1);
            setMaxNumber(5)
            setScalingMethod("");
            setScallingMsg("");
            setCloseEndedTypeName("")
            setCETypeStatus(0);
            setOpenEndedScoreValue("");
            setCloseEndedScoreValue("");
            setOpenEndedAnswerValue("");
            setSelectedQuestionId('');
            setHeaderName('Add New Questionnaire');
            setSaveBtnName('Save');
            setSaveDraftBtnName('Save Draft');
            setSelectYourRiskType('Select Your Risk Type');
            
            //setOpenAmendQuestionFromEvalFunction(false);

            var stdTypeObj = '';
            var stdNameObj = '';
            var stdTypeComp = '';
            var stdNameComp = '';
            var stdTypePri = '';
            var stdNamePri = '';
            if (ownObjectiveFlag){
                if (ownObjectiveName != null && ownObjectiveName != "") {
                    
                    stdTypeObj = 'objective';
                    stdNameObj = ownObjectiveName;
                }
            }
            if (ownComponentFlag){
                if (ownComponentName != null && ownComponentName != "") {
                    stdTypeComp = 'component';
                    stdNameComp = ownComponentName;
                }
            }
            if (ownPrincipalFlag){
                if (ownPrincipalName != null && ownPrincipalName != "") {
                    stdTypePri = 'principle';
                    stdNamePri = ownPrincipalName;
                }
            }
            if (stdTypeObj != '' || stdTypeComp != '' || stdNamePri != ''){
                //CONFIG.MAIN_URL/custom-standard-type-client/save
                var arrStd = [];
                if (stdTypeObj != ''){
                    var objStd = {};
                    objStd["name"] = stdNameObj;
                    objStd["type"] = stdTypeObj;
                    objStd["centreId"] = golobalCentreId;
                    var canTake = true;
                    centreOwnObjectives.forEach((item)=>{
                        if (item.name === stdNameObj){
                            canTake = false;
                        }
                        arrStd.push(item);    
                    });    
                    if (canTake){
                        arrStd.push(objStd);
                    }
                }
                if (stdTypeComp != ''){
                    var objStd = {};
                    objStd["name"] = stdNameComp;
                    objStd["type"] = stdTypeComp;
                    objStd["centreId"] = golobalCentreId;
                    var canTake = true;
                    centreOwnComponents.forEach((item)=>{
                        if (item.name === stdNameComp){
                            canTake = false;
                        }
                        arrStd.push(item);    
                    });    
                    if (canTake){
                        arrStd.push(objStd);
                    }
                }
                if (stdNamePri != ''){
                    var objStd = {};
                    objStd["name"] = stdNamePri;
                    objStd["type"] = stdTypePri;
                    objStd["centreId"] = golobalCentreId;
                    var canTake = true;
                    centreOwnPrinciples.forEach((item)=>{
                        if (item.name === stdNamePri){
                            canTake = false;
                        }
                        arrStd.push(item);    
                    });    
                    if (canTake){
                        arrStd.push(objStd);
                    }
                }
                let sendJson = JSON.stringify(arrStd);
                console.log("Before Saving Own Standard ");
                console.log(sendJson);

                axios(CONFIG.MAIN_URL+'/custom-standard-type-client/save',{
                  method: 'post',
                  headers: { 
                      'Content-Type': 'application/json',
                    },
                  data : sendJson,  
                })
                .then(function (response) {
                  console.log("Saving Own Standard Retuen");
                  let rtnData = response.data;
                  console.log(rtnData);
                  getOwnStandared(golobalCentreId);
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
    
            
            getAllQuestionsForCollection();
            reloadCollectionWithRiks();
            riskClearFunction()
            riskClearFunctionCloseEnded()



            //window.location.reload(false);
        })
        .catch(function (error) {
            console.log(error);
            setWaitingOpen(false);
            alert("Error saving Question -:" + error);
        });

        
        
    }

    /**
     * QUSTION DIV
     */

    const [riskQuestion, setRiskQuestion] = useState(false);
    const [RiskD, setRiskD] = useState([])

    function riskTypeQues(closeEndedQuesIds,openEndedQuesIds) {
        var quesIds = [];
        var quesDataArray = [];
        closeEndedQuesIds.forEach((item)=>{
            quesIds.push(item);
        });
        openEndedQuesIds.forEach((item)=>{
            quesIds.push(item);
        });

        quesIds.forEach((item)=>{
            quesData.forEach((data)=>{
                if (data.questionId === item){
                    quesDataArray.push(data);
                    return true;
                }
            });
        });
        console.log("xxxxxxxxxxxxxx")
        console.log(quesDataArray)
        setRiskD(quesDataArray);
        setRiskQuestion(!riskQuestion);
    }




    /**
     * RESIK DIV
     */


    const [openRisk, setOpenRisk] = useState(false);
    const [riskBtnTop, setRiskBtnTop] = useState(0);
    const [riskBtnLeft, setRiskBtnLeft] = useState(0);

    function setOpenRiskFuction(riskFlag) {
        var btnId = document.getElementById("riskBtnId");
        var lastGridId = document.getElementById("lastGridId");
        var top = (btnId.offsetTop-lastGridId.offsetTop);
        setRiskBtnTop(top);
        setRiskBtnLeft(btnId.offsetLeft);
        //setRiskBtnTop(lastGridId.offsetTop);
        if (selectedCollectionRiskId != undefined && selectedCollectionRiskId != null && selectedCollectionRiskId != 0){
            riskTypeData.forEach((item,index)=>{
                if (item.collectionRiskId === selectedCollectionRiskId) {  
                    setRsTtpe(item.riskTypes);
                }
            })
        }
        setOpenRisk(riskFlag);
        
        
        
        
        //document.getElementById("riskDiv").style.marginTop = "218px"
    }



    //malindu section 

    const [riskSliderMethods, setRiskSliderMethods] = useState([])
    const [databClr, setDatabClr] = useState([])

   

    function allRiskMethodData() {
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_RISK+"/all",{
            method: 'get',
            headers: { 
                'Content-Type': 'text/plain', 
              },     
        })
        .then(function (res) {
             
            console.log("All Risk Returns");
            console.log(res.data[0]);
            setRiskSliderMethods(res.data[0].methods)
           // setQuestionCategoryId(res.data[0].id)
            console.log(res.data[0].methods);

        })
        .catch(function (error) {
            console.log(error);
        });

    }



    function getColors(){
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
          method: 'get',
          headers: { 
              'Content-Type': 'text/plain', 
            },     
          })
          .then(function (response) {
            console.log("All colors");
            console.log(response.data);
    
            setDatabClr(response.data)
    
           
          })
          .catch(function (error) {
          console.log(error);
          });
      }



    function getRiskLvlName(lvlData,method){
        console.log(method)
        console.log(riskSliderMethods)
       
        var xx =''             
        riskSliderMethods.forEach((data)=>{
 
               if(data.id === method){
                    data.riskTypes.forEach((data1)=> {
                        if(data1.startingNumber <= lvlData && lvlData <= data1.endingNumber){
                          xx = data1.riskTypesName
                          console.log(data1.riskTypesName)
                        }
                      })
                }
            })


       return xx
    }


    function getAppetiteLvlName(lvlData,method){
        var xx =''             
        riskSliderMethods.forEach((data)=>{
 
               if(data.id === method){
                    data.riskTypes.forEach((data1)=> {
                        if(data1.startingNumber <= lvlData && lvlData <= data1.endingNumber){
                          xx = data1.riskTypesName
                          console.log(data1.riskTypesName)
                        }
                      })
                }
            })



       return xx
    }


    function getRiskLvlColor(lvlData){
        var xx = ''

        databClr.forEach((data2)=>{         
          if (parseInt(data2.scoreNumber) === parseInt(lvlData)) {
            xx = data2.color
          }
        })
    

       
return xx
    }

    function getAppetiteLvlColor(lvlData){
        var xx = ''

        databClr.forEach((data2)=>{         
          if (parseInt(data2.scoreNumber) === parseInt(lvlData)) {
            xx = data2.color
          }
        })
    
       
return xx
    }

    function getRisklvlColorData(lvl,coloArray){
        console.log(databClr)
        console.log(lvl)
        var xx = ''

        coloArray.forEach((data2)=>{         
          if (parseInt(data2.scoreNumber) === parseInt(lvl)) {
            xx = data2.color
          }
        })
    
       
        return xx
    }

    function getRiskAppetitelvlColorData(lvl,coloArray){
        var xx = ''

        coloArray.forEach((data2)=>{         
          if (parseInt(data2.scoreNumber) === parseInt(lvl)) {
            xx = data2.color
          }
        })
    
       
return xx
    }


    const [flag, setFlag] = React.useState(true);
     /**
     * Select Risk
     */
         const [selectedRiskName, setSelectedRiskName] = React.useState('');
         const [selectedRiskLevel, setSelectedRiskLevel] = React.useState('');
         const [selectedRiskAppetite, setSelectedRiskAppetite] = React.useState('');
         const [selectedRiskNameStr, setSelectedRiskNameStr] = React.useState('');
         const [selectedRiskId, setSelectedRiskId] = React.useState(0);
         const [selectedCollectionRiskTypeId, setSelectedCollectionRiskTypeId] = React.useState(0);
         const [previousSelectedCollectionRiskTypeId, setPreviousSelectedCollectionRiskTypeId] = React.useState(0);
         const [riskDialogOpen, setRiskDialogOpen] = React.useState(false);
         const [riskDialogScroll, setRiskDialogScroll] = React.useState('paper');
         const riskDialogHandleClickClose = () => {
            setRiskDialogOpen(false);
         };
         const riskDialogHandleClickOpen = (scrollType) => () => {
            setRiskDialogOpen(true);
            setRiskDialogScroll(scrollType);
          };

         
          function setStandardWhenSelect(item1){
            if (item1.riskStandardData != undefined && item1.riskStandardData != null){
              if (item1.riskStandardData.objectiveData != undefined && item1.riskStandardData.objectiveData != null){
                setObjectivedData([]);
                var arryObj = [];
                 var tmpArray = {};
                tmpArray["objectiveId"] = item1.riskStandardData.objectiveData.objectiveId;
                tmpArray["name"] = item1.riskStandardData.objectiveData.objectiveName;
                arryObj.push(tmpArray);
                setObjectivedData(arryObj);
                setObjectiveId(item1.riskStandardData.objectiveData.objectiveId);
                setObjectiveName(item1.riskStandardData.objectiveData.objectiveName);
                setOwnObjectiveFlag(false);
                setSelectedObjectiveStandardName(item1.riskStandardData.objectiveData.standardName);
                setSelectedObjectiveStandardId(item1.riskStandardData.objectiveData.standardId);
              }else{
                setObjectivedData([]);
                setObjectiveId('');
                setObjectiveName('');
                setOwnObjectiveFlag(false);
                setSelectedObjectiveStandardName('');
                setSelectedObjectiveStandardId('');
              }
              if (item1.riskStandardData.componentData != undefined && item1.riskStandardData.componentData != null){
                setComponentsData([]);
                var arryObj = [];
                 var tmpArray = {};
                tmpArray["componentId"] = item1.riskStandardData.componentData.componentId;
                tmpArray["name"] = item1.riskStandardData.componentData.componentName;
                arryObj.push(tmpArray);
                setComponentsData(arryObj);
                setComponentId(item1.riskStandardData.componentData.componentId);
                setComponentName(item1.riskStandardData.componentData.componentName);
                setOwnComponentFlag(false);
                setSelectedComponentStandardName(item1.riskStandardData.componentData.standardName);
                setSelectedComponentStandardId(item1.riskStandardData.componentData.standardId);
              }else{
                setComponentsData([]);
                setComponentId('');
                setComponentName('');
                setOwnComponentFlag(false);
                setSelectedComponentStandardName('');
                setSelectedComponentStandardId('');
              }
              if (item1.riskStandardData.principleData != undefined && item1.riskStandardData.principleData != null){
                setPrincipalsData([]);
                var arryObj = [];
                 var tmpArray = {};
                tmpArray["principalId"] = item1.riskStandardData.principleData.principleId;
                tmpArray["name"] = item1.riskStandardData.principleData.principleName;
                arryObj.push(tmpArray);
                setPrincipalsData(arryObj);
                setPrincipleId(item1.riskStandardData.principleData.principleId);
                setPrincipleName(item1.riskStandardData.principleData.principleName);
                setOwnPrincipalFlag(false);
                setSelectedPrincipleStandardName(item1.riskStandardData.principleData.standardName);
                setSelectedPrincipleStandardId(item1.riskStandardData.principleData.standardId);
              }else{
                setPrincipalsData([]);
                setPrincipleId('');
                setPrincipleName('');
                setOwnPrincipalFlag(false);
                setSelectedPrincipleStandardName('');
                setSelectedPrincipleStandardId('');
              }
            }else{
              setObjectivedData([]);
              setObjectiveId('');
              setObjectiveName('');
              setOwnObjectiveFlag(false);
              setSelectedObjectiveStandardName('');
              setSelectedObjectiveStandardId('');

              setComponentsData([]);
              setComponentId('');
              setComponentName('');
              setOwnComponentFlag(false);
              setSelectedComponentStandardName('');
              setSelectedComponentStandardId('');

              setPrincipalsData([]);
              setPrincipleId('');
              setPrincipleName('');
              setOwnPrincipalFlag(false);
              setSelectedPrincipleStandardName('');
              setSelectedPrincipleStandardId('');

            }

          }
         
         
          const riskHandleChange = (event,comment) => {
            let idAndVal=event.target.value;
            let idAndValArr=idAndVal.split("]");

            
            //setShow(true);
            if (idAndValArr[4] != undefined && idAndValArr[4] != null && idAndValArr[4] != "null" && idAndValArr[4] != "") {
                setSelectedCollectionRiskTypeId(idAndValArr[4]);
                if (idAndValArr[4] === comment.collectionRiskTypeId){
                  setStandardWhenSelect(comment)
                }
            }

            var riskLevelStr = "";
            var riskAppetiteStr = "";

            if (idAndValArr[2] != undefined && idAndValArr[2] != null && idAndValArr[2] != "null" && idAndValArr[2] != "") {
                setSelectedRiskLevel(idAndValArr[2]);

                var riskLvlName = getRiskLvlName(idAndValArr[2],idAndValArr[5]) 

                var riskLvlColor = getRiskLvlColor(idAndValArr[2])

                console.log(riskLvlColor)


                riskLevelStr = " (RisK : " + idAndValArr[2] + "-" + riskLvlName ; 
            }
            if (idAndValArr[3] != undefined && idAndValArr[3] != null && idAndValArr[3] != "null" && idAndValArr[3] != "") {
                setSelectedRiskAppetite(idAndValArr[3]);

                var riskAppetiteName = getAppetiteLvlName(idAndValArr[3],idAndValArr[6])
                
                var riskAppetiteColor = getAppetiteLvlColor(idAndValArr[3])

                if (riskLevelStr != "" ){
                    riskAppetiteStr += ", Appetite : " + idAndValArr[3] + "-" + riskAppetiteName;
                }else{
                    riskAppetiteStr = " (Appetite : " + idAndValArr[3] + "-" + riskAppetiteName;
                }
            }
            if (riskAppetiteStr != ""){
                riskAppetiteStr += ")";
            }
            setSelectedRiskId(idAndValArr[0]);
            setSelectedRiskName(idAndValArr[1]);
            setSelectedRiskNameStr(<div>{idAndValArr[1]}<span style={{color:`${riskLvlColor}`}}>{riskLevelStr}</span><span style={{color:`${riskAppetiteColor}`}}>{riskAppetiteStr}</span></div>);  
            var lbl = "Open Ended Question for "
            setQuestionLableNameOpenEnded(<div>{lbl}{idAndValArr[1]}<span style={{color:`${riskLvlColor}`}}>{riskLevelStr}</span><span style={{color:`${riskAppetiteColor}`}}>{riskAppetiteStr}</span></div>)
            var lbl1 = "Close Ended Question for "
            setQuestionLableNameCloseEnded(<div>{lbl1}{idAndValArr[1]}<span style={{color:`${riskLvlColor}`}}>{riskLevelStr}</span><span style={{color:`${riskAppetiteColor}`}}>{riskAppetiteStr}</span></div>)

            setRiskDialogOpen(false);
        };
         /** End of Select Risk */

        function getQuestionIds(comment,type){
            var count = 0;
            var ids = [];

            if (comment.questions != undefined && comment.questions!= null){
                if (type === 1){
                    if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                        comment.questions.forEach((quesItem)=>{
                            if (quesItem.questionType === "Closeended"){
                                ids.push(quesItem.questionId);
                            }
                        })  
                    }
                    /*
                    if (comment.questions.closeEndedQuestionIds != undefined && comment.questions.closeEndedQuestionIds != null && comment.questions.closeEndedQuestionIds.length > 0){
                        comment.questions.closeEndedQuestionIds.forEach((item,index)=>{
                            ids.push(item.questionId);
                        });
                    }else{
                        ids = [];
                    }
                    */
                }
                if (type === 2){
                    if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                        comment.questions.forEach((quesItem)=>{
                            if (quesItem.questionType === "Openended"){
                                ids.push(quesItem.questionId);
                            }
                        })  
                    }
                    /*
                    if (comment.questions.openEdndedQuestionIds != undefined && comment.questions.openEdndedQuestionIds != null && comment.questions.openEdndedQuestionIds.length > 0){
                        comment.questions.openEdndedQuestionIds.forEach((item,index)=>{
                            ids.push(item.questionId);
                        });
                    }else{
                        ids = [];
                    }
                    */
                }
            }else{
                ids = [];
            }
            return ids;
        }

        function getQuestionIdsWithSubRisks(comment,type){
            var count = 0;
            var riskIds = "";
            var ids = [];
            if (type === 1){
                if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                    comment.questions.forEach((quesItem)=>{
                        if (quesItem.questionType === "Closeended"){
                            ids.push(quesItem.questionId);
                        }
                    })  
                }
                /*
                if (comment.questions != undefined && comment.questions!= null){
                    if (comment.questions.closeEndedQuestionIds != undefined && comment.questions.closeEndedQuestionIds != null && comment.questions.closeEndedQuestionIds.length > 0){
                        comment.questions.closeEndedQuestionIds.forEach((item,index)=>{
                            ids.push(item.questionId);
                        });
                    }else{
                        ids = [];
                    }
                }else{
                    ids = [];
                }
                */
            }
            if (type === 2){
                if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                    comment.questions.forEach((quesItem)=>{
                        if (quesItem.questionType === "Openended"){
                            ids.push(quesItem.questionId);
                        }
                    })  
                }
                /*
                if (comment.questions != undefined && comment.questions!= null){
                    if (comment.questions.openEdndedQuestionIds != undefined && comment.questions.openEdndedQuestionIds != null && comment.questions.openEdndedQuestionIds.length > 0){
                        comment.questions.openEdndedQuestionIds.forEach((item,index)=>{
                            ids.push(item.questionId);
                        });
                    }else{
                        ids = [];
                    }
                }else{
                    ids = [];
                }
                */
            }
            if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
                return getQuestionSubIdsWithSubRisks(comment.subRisks,type,ids);
            }else{
                return ids;
            }
        }
        function getQuestionSubIdsWithSubRisks(comment,type,ids ){
            comment.forEach((data,index)=>{
                if (type === 1){
                    if (data.questions != undefined && data.questions != null && data.questions.length > 0){
                        data.questions.forEach((quesItem)=>{
                            if (quesItem.questionType === "Closeended"){
                                ids.push(quesItem.questionId);
                            }
                        })  
                    }  
                    /* 
                    if (data.questions != undefined && data.questions!= null){
                        if (data.questions.closeEndedQuestionIds != undefined && data.questions.closeEndedQuestionIds != null && data.questions.closeEndedQuestionIds.length > 0){
                            data.questions.closeEndedQuestionIds.forEach((item,index)=>{
                                ids.push(item.questionId);
                            });
                        }
                    }
                    */
                }
                if (type === 2){
                    if (data.questions != undefined && data.questions != null && data.questions.length > 0){
                        data.questions.forEach((quesItem)=>{
                            if (quesItem.questionType === "Openended"){
                                ids.push(quesItem.questionId);
                            }
                        })  
                    }  
                    /*
                    if (data.questions != undefined && data.questions!= null){
                        if (data.questions.openEdndedQuestionIds != undefined && data.questions.openEdndedQuestionIds != null && data.questions.openEdndedQuestionIds.length > 0){
                            data.questions.openEdndedQuestionIds.forEach((item,index)=>{
                                ids.push(item.questionId);
                            });
                        }
                    }
                    */
                }
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0){
                    getQuestionSubIdsWithSubRisks(data.subRisks,type,ids);
                }
            });
            return ids;
            
        }    

        console.log(ansArrayTmp);
        
        function Comment({ comment }) {
            console.log("**************** comment ****************");
            console.log(comment);

            var closeEndedQuestionIds = getQuestionIds(comment,1);
            var openEndedQuestionIds = getQuestionIds(comment,2);
            var closeEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,1);
            var openEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,2);


            const [show, setShow] = React.useState(true);

            const nestedComments = (comment.subRisks || []).map(comment => {
                return <Comment key={comment.riskTypeId} comment={comment} type="child" />
            })
        
            return (
                <div style={{"marginLeft": "15px", "marginTop": "10px"}}>
                    <div style={{display:'flex',textAlign:'center'}}> 
                        <Button 
                            style={{width:'0px', height:'0px', marginTop:'14px'}} 
                            onClick={()=>setShow(!show)} 
                            startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            >
                        </Button>
                        
                        <span style={{marginTop:'-7px !important'}} >
                        <Radio
                            checked={selectedCollectionRiskTypeId === `${comment.collectionRiskTypeId}`}
                            //onChange={riskHandleChange}
                            onChange={(e)=>riskHandleChange(e,comment)} 
                            value={`${comment.riskTypeId}]${comment.name}]${comment.riskLevel}]${comment.riskAppetite}]${comment.collectionRiskTypeId}]${comment.riskLevelData.riskMethodId}]${comment.riskAppetiteData.riskMethodId}`}
                            name="radio-buttons-risks"
                            inputProps={{ 'aria-label': 'A' }}
                            disabled={questId != null?subQuestion != "ok"?true:false:false}
                            style={{marginLeft:'-1px !important'}} 
                            />
                            {show?comment.name + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' (' )   + (closeEndedQuestionIds.length > 0 ?' C : '
                             + closeEndedQuestionIds.length:'')+ (openEndedQuestionIds.length > 0 
                                ?closeEndedQuestionIds.length > 0 ?', O : ' + openEndedQuestionIds.length:' O : ' + openEndedQuestionIds.length:'') + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' )' ) 
                             :comment.name + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' (' )   + (closeEndedQuestionIdsWithSub.length > 0 ?' C : '
                             + closeEndedQuestionIdsWithSub.length:'')+ (openEndedQuestionIdsWithSub.length > 0 
                                ?closeEndedQuestionIdsWithSub.length > 0 ?', O : ' + openEndedQuestionIdsWithSub.length:' O : ' + openEndedQuestionIdsWithSub.length:'') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' )' )    
                             } 

                            {show?closeEndedQuestionIds.length > 0 || openEndedQuestionIds.length > 0 ? 
                              <Button onClick={()=>riskTypeQues(closeEndedQuestionIds,openEndedQuestionIds,!show)}
                            > <DoubleArrowIcon/></Button> : ''
                             :closeEndedQuestionIdsWithSub.length > 0 || openEndedQuestionIdsWithSub.length > 0 ? 
                             <Button onClick={()=>riskTypeQues(closeEndedQuestionIdsWithSub,openEndedQuestionIdsWithSub,!show)}
                           > <DoubleArrowIcon/></Button> : ''   
                            }


                            {/*comment.name + (comment.questions.closeEndedQuestions.length === 0 && comment.questions.openEdndedQuestions.length === 0 ?'' : ' (' )   + (comment.questions.closeEndedQuestions.length > 0 ?' C : '
                             + comment.questions.closeEndedQuestions.length:'')+ (comment.questions.openEdndedQuestions.length > 0 
                                ?' O : ' + comment.questions.openEdndedQuestions.length:'') + (comment.questions.closeEndedQuestions.length === 0 && comment.questions.openEdndedQuestions.length === 0 ?'' : ' )' ) 
                                
                             */} 

                              {/*comment.questions.closeEndedQuestions.length > 0 || comment.questions.openEdndedQuestions.length > 0 ? 
                              <Button onClick={()=>riskTypeQues(comment)}
                            > <DoubleArrowIcon/></Button> : ''*/}


                            
                            
                            
                     </span>    
                    </div>
                    {
                        show?
                            <>
                                {nestedComments}
                            </>
                        :null
                    }
            </div>
          )
        }



        // slider malindu section



        
        const [questionCategoryId, setQuestionCategoryId] = useState()
        
        const [sliderType, setSliderType] = React.useState();

        const [showSelectBar, setShowSelectBar] = React.useState(true);

        const [sliderValue, setSliderValue] = React.useState();

        const [sliderValueStyle, setSliderValueStyle] = React.useState('');

        const [sliderValueName, setSliderValueName] = React.useState('');



        const handleChange = (event) => {  
            setSliderType(event.target.value);
            setShowSelectBar(false);
            
          };
        
        
          function riskClearFunction(){
            setShowSelectBar(true);
            setSliderType('');
            setSliderValue();
            setSliderValueName('');

          }

          function nameChange(){
            questionSliderMethods.forEach((data)=> {
              if(sliderType === data.id){
                data.riskTypes.forEach((data1)=> {
                  if (data1.startingNumber <= sliderValue && sliderValue <= data1.endingNumber) {
                    setSliderValueName(data1.riskTypesName)
                    
                  }
                })
              }
            })
            
          
          }


          //close ended

          
          const [sliderTypeCloseEnded, setSliderTypeCloseEnded] = React.useState();
  
          const [showSelectBarCloseEnded, setShowSelectBarCloseEnded] = React.useState(true);
  
          const [sliderValueCloseEnded, setSliderValueCloseEnded] = React.useState();

          const [sliderValueCloseEndedStyle, setSliderValueCloseEndedStyle] = React.useState('');
  
          const [sliderValueNameCloseEnded, setSliderValueNameCloseEnded] = React.useState('');
  

  
  
          const handleChangeCloseEnded = (event) => {  
              setSliderTypeCloseEnded(event.target.value);
              setShowSelectBarCloseEnded(false);
              
            };
          
          
            function riskClearFunctionCloseEnded(){
              setShowSelectBarCloseEnded(true);
              setSliderTypeCloseEnded('');
              setSliderValueCloseEnded();
              setSliderValueNameCloseEnded('');
  
            }
  
            function nameChangeCloseEnded(){
              questionSliderMethods.forEach((data)=> {
                if(sliderTypeCloseEnded === data.id){
                  data.riskTypes.forEach((data1)=> {
                    if (data1.startingNumber <= sliderValueCloseEnded && sliderValueCloseEnded <= data1.endingNumber) {
                      setSliderValueNameCloseEnded(data1.riskTypesName)
                      
                    }
                  })
                }
              })
              
            
            }

            function getRisklvlNme(lvl,quesDta,SliderMethods){
                var xx =''             
                SliderMethods.forEach((data)=>{

                 if(data.id === quesDta.riskTypes.riskLevelData.riskMethodId){
                        data.riskTypes.forEach((data1)=> {
                            if(data1.startingNumber <= lvl && lvl <= data1.endingNumber){
                              xx = data1.riskTypesName
                              console.log(data1.riskTypesName)
                            }
                          })
        
                    }
                })
                     
               return xx
            }

            function getRiskAppetitelvlNme(lvl,quesDta,SliderMethods){
                console.log(quesDta)
                var xx =''             
                SliderMethods.forEach((data)=>{
                 if(data.id === quesDta.riskTypes.riskAppetiteData.riskMethodId){
                        data.riskTypes.forEach((data1)=> {
                            if(data1.startingNumber <= lvl && lvl <= data1.endingNumber){
                              xx = data1.riskTypesName
                              console.log(data1.riskTypesName)
                            }
                          })
        
                    }
                })
                     
               return xx
            }
// malindu section menu


const [isActiveDivOpenComment, setIsActiveDivOpenComment] = React.useState(true);
const [isActiveDivOpenRiskCollection, setIsActiveDivOpenRiskCollection] = React.useState(true);
const [isActiveDivOpenCollectionData, setIsActiveDivOpenCollectionData] = React.useState(true);
const [isActiveDivOpenRiskHierarchy, setIsActiveDivOpenRiskHierarchy] = React.useState(true);
const [isActiveDivOpenGlobalStandard, setIsActiveDivOpenGlobalStandard] = React.useState(true);
const [isActiveDivOpenCreateNewCollection, setIsActiveDivOpenCreateNewCollection] = React.useState(true);
const [isActiveDivOpenEditCollection, setIsActiveDivOpenEditCollection] = React.useState(true);
const [isActiveDivOpenAddColor, setIsActiveDivOpenAddColor] = React.useState(true);
const [isActiveDivOpenSliderMethods, setIsActiveDivOpenSliderMethods] = React.useState(true);
const [isActiveDivOpenCustomStandards, setIsActiveDivOpenCustomStandards] = React.useState(true);
const [isActiveDivOpenCentreMaintenance, setIsActiveDivOpenCentreMaintenance] = React.useState(true);
const [isActiveDivOpenTransferQuestionnaire, setIsActiveDivOpenTransferQuestionnaire] = React.useState(true);
const [isActiveDivOpenAddQuestion, setIsActiveDivOpenAddQuestion] = React.useState(true);
const [isActiveDivOpenAnswerScale, setIsActiveDivOpenAnswerScale] = React.useState(true);


const [isActiveBtnOpenRiskCollection , setIsActiveBtnOpenRiskCollection] = React.useState(true);
const [isActiveBtnOpenCreateNewCollection , setIsActiveBtnOpenCreateNewCollection] = React.useState(true);
const [isActiveMenuIcon , setIsActiveMenuIcon] = React.useState(true);

function disabledOtherDivs(divId) {
    if (divId === 'divOpenEditCollection'){
      setIsActiveDivOpenComment(false);
      setIsActiveDivOpenRiskCollection(false);
      setIsActiveDivOpenCollectionData(false);
      setIsActiveDivOpenRiskHierarchy(false);
      setIsActiveDivOpenGlobalStandard(false);
      setIsActiveDivOpenCreateNewCollection(false);
      //setIsActiveDivOpenEditCollection(false);
      setIsActiveDivOpenAddColor(false);
      setIsActiveDivOpenSliderMethods(false);
      setIsActiveDivOpenCustomStandards(false);
      setIsActiveDivOpenCentreMaintenance(false);
      setIsActiveDivOpenTransferQuestionnaire(false);
      setIsActiveDivOpenAddQuestion(false)
      setIsActiveDivOpenAnswerScale(false)

      setIsActiveBtnOpenRiskCollection(false);
      setIsActiveBtnOpenCreateNewCollection(false);
      setIsActiveMenuIcon(false);
    }
  }
  function enabledOtherDivs() {
    setIsActiveDivOpenComment(true);
    setIsActiveDivOpenRiskCollection(true);
    setIsActiveDivOpenCollectionData(true);
    setIsActiveDivOpenRiskHierarchy(true);
    setIsActiveDivOpenGlobalStandard(true);
    setIsActiveDivOpenCreateNewCollection(true);
    setIsActiveDivOpenEditCollection(true);
    setIsActiveDivOpenAddColor(true);
    setIsActiveDivOpenSliderMethods(true);
    setIsActiveDivOpenCustomStandards(true);
    setIsActiveDivOpenCentreMaintenance(true);
    setIsActiveDivOpenTransferQuestionnaire(true);
    setIsActiveDivOpenAddQuestion(true)
    setIsActiveDivOpenAnswerScale(true)
    setIsActiveBtnOpenRiskCollection(true);
    setIsActiveBtnOpenCreateNewCollection(true);
    setIsActiveMenuIcon(true);
  }


const [divCurrentLeftPos, setDivCurrentLeftPos] = React.useState(1);
const [divCurrentTopPos, setDivCurrentTopPos] = React.useState(1);
const [noOfDivsOpen, setNoOfDivsOpen] = React.useState(0);

function addToTop(divId) {
  var addAmount = 14;
  var addAmountLeft = 8;
  var plusAmount = 40;
  var plusAmountLeft = 20;
  var tmpDivOpen = 0;
  
  if (!openRiskCollection && !openCollectionData && !openExistingRiskCollection && !openRiskHierarchy && !openGlobalStandard && !openCreateNewCollection && !openEditCollection && !centreMaintenance && !openAddColor && !openTransferQuestionnaire && !openCustomStandards && !opensliderMethods) {
    tmpDivOpen = 0;
  }else{
    tmpDivOpen = noOfDivsOpen;
  }

  if (tmpDivOpen === 0){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 1){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 2){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 3){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 4){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 5){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 6){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 7){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 8){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 9){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 10){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 11){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 12){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 13){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }
  if (tmpDivOpen === 14){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }

  if (tmpDivOpen === 15){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
    addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
  }

  setNoOfDivsOpen(tmpDivOpen + 1);

  if (divId === "divOpenRiskCollection") {
    setOpenRiskCollectionOriginalTop(addAmount);
    setOpenRiskCollectionOriginalLeft(addAmountLeft);
  }
  if (divId === "divOpenCreateNewCollection") {
    setOpenCreateNewCollectionOriginalTop(addAmount);
    setOpenCreateNewCollectionOriginalLeft(addAmountLeft);
  }
  if (divId === "divOpenCollectionData") {
    setOpenCollectionDataOriginalTop(addAmount);
    setOpenCollectionDataOriginalLeft(addAmountLeft);
  }
  if (divId === "divOpenRiskHierarchy") {
    setOpenRiskHierarchyOriginalTop(addAmount);
    setOpenRiskHierarchyOriginalLeft(addAmountLeft);
  }
  if (divId === "divOpenGlobalStandard") {
    setOpenGlobalStandardOriginalTop(addAmount);
    setOpenGlobalStandardOriginalLeft(addAmountLeft);
  }
  if (divId === "divOpenEditCollection") {
    setOpenEditCollectionOriginalTop(addAmount);
    setOpenEditCollectionOriginalLeft(addAmountLeft);
  }
  if (divId === "divOpenAddColor") {
    setOpenAddColorOriginalTop(addAmount);
    setOpenAddColorOriginalLeft(addAmountLeft);
  }
  if (divId === "divOpenSliderMethods") {
    setOpensliderMethodsOriginalTop(addAmount);
    setOpensliderMethodsOriginalLeft(addAmountLeft);
  }
  if (divId === "divOpenCustomStandards") {
    setOpenCustomStandardsOriginalTop(addAmount);
    setOpenCustomStandardsOriginalLeft(addAmountLeft);
  }
  if (divId === "divOpenCentreMaintenance") {
    setOpenCentreMaintenanceOriginalTop(addAmount);
    setOpenCentreMaintenanceOriginalLeft(addAmountLeft);
  }
  if (divId === "divOpenTransferQuestionnaire") {
    setOpenTransferQuestionnaireOriginalTop(addAmount);
    setOpenTransferQuestionnaireOriginalLeft(addAmountLeft);
  }
  if (divId === "divOpenAddQuestion") {
    setOpenAddQuestionOriginalTop(addAmount);
    setOpenAddQuestionOriginalLeft(addAmountLeft);
  }
  if (divId === "divOpenAnswerScale") {
    setOpenAnswerScaleOriginalTop(addAmount);
    setOpenAnswerScaleOriginalLeft(addAmountLeft);
  }


}



function addToTopPos() {
  var addAmount = 100;
  var plusAmount = 40;
  var tmpDivOpen = 0;
  
  if (!openRiskCollection && !openCollectionData && !openExistingRiskCollection && !openRiskHierarchy && !openGlobalStandard && !openCreateNewCollection && !openEditCollection && !centreMaintenance && !openAddColor && !openTransferQuestionnaire && !openCustomStandards && !opensliderMethods && !openAddQuestion && !openAnswerScale) {
    tmpDivOpen = 0;
  }else{
    tmpDivOpen = noOfDivsOpen;
  }

  if (tmpDivOpen === 0){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 1){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 2){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 3){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 4){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 5){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 6){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 7){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 8){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 9){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 10){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 11){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 12){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
  if (tmpDivOpen === 13){
    addAmount = addAmount + (plusAmount * tmpDivOpen);
  }
          if (tmpDivOpen === 14){
          addAmount = addAmount + (plusAmount * tmpDivOpen);
        }

        if (tmpDivOpen === 15){
          addAmount = addAmount + (plusAmount * tmpDivOpen);
        }


  return addAmount;

}

const [openRiskCollectionOrder,setOpenRiskCollectionOrder] = useState(0);
const [openCreateNewCollectionOrder,setOpenCreateNewCollectionOrder] = useState(0);
const [openGlobalStandardOrder,setOpenGlobalStandardOrder] = useState(0);
const [openCustomStandardsOrder,setOpenCustomStandardsOrder] = useState(0);
const [openRiskHierarchyOrder,setOpenRiskHierarchyOrder] = useState(0);
const [openCentreMaintenanceOrder,setOpenCentreMaintenanceOrder] = useState(0);
const [opensliderMethodsOrder,setOpensliderMethodsOrder] = useState(0);
const [openAddColorOrder,setOpenAddColorOrder] = useState(0);
const [openTransferQuestionnaireOrder,setOpenTransferQuestionnaireOrder] = useState(0);
const [openAddQuestionOrder,setOpenAddQuestionOrder] = useState(0);
const [openAnswerScaleOrder,setOpenAnswerScaleOrder] = useState(0);

const [openOrderNumber,setOpenOrderNumber] = useState(0);

var relatedDivOriginalHeight = '10';
var relatedDivLoadingHeight = '10';


const [openRiskHierarchyOriginalRelatedHeight,setOpenRiskHierarchyOriginalRelatedHeight] = useState(relatedDivOriginalHeight);
const [openGlobalStandardOriginalRelatedHeight,setOpenGlobalStandardOriginalRelatedHeight] = useState(relatedDivOriginalHeight);
const [openCreateNewCollectionOriginalRelatedHeight,setOpenCreateNewCollectionOriginalRelatedHeight] = useState(relatedDivOriginalHeight);
const [openCentreMaintenanceOriginalRelatedHeight,setOpenCentreMaintenanceOriginalRelatedHeight] = useState(relatedDivOriginalHeight);
const [opensliderMethodsOriginalRelatedHeight,setOpensliderMethodsOriginalRelatedHeight] = useState(relatedDivOriginalHeight);
const [openAddColorOriginalRelatedHeight,setOpenAddColorOriginalRelatedHeight] = useState(relatedDivOriginalHeight);	
const [openCustomStandardsOriginalRelatedHeight,setOpenCustomStandardsOriginalRelatedHeight] = useState(relatedDivOriginalHeight);
const [openRiskCollectionOriginalRelatedHeight,setOpenRiskCollectionOriginalRelatedHeight] = useState(relatedDivOriginalHeight);
const [openTransferQuestionnaireOriginalRelatedHeight,setOpenTransferQuestionnaireOriginalRelatedHeight] = useState(relatedDivOriginalHeight);




function viewLayersFuction(order,openOrderNo){
  var addAmount = 100;
  var plusAmount = 40;
  var addAmountLeft = 8;
  var plusAmountLeft = 20;
  
  var topBtnId = document.getElementById("topBtnId");
  var tabId1 = document.getElementById("tabId1");

  

  setOpenRiskCollection(false);
  setOpenCreateNewCollection(false);
  setOpenGlobalStandard(false);
  setOpenCustomStandards(false);
  setOpenRiskHierarchy(false);
  setCentreMaintenance(false);
  setOpensliderMethods(false);
  setOpenAddColor(false);
  setOpenEditCollection(false);
  setOpenTransferQuestionnaire(false);
  setOpenAddQuestion(false)
  setOpenAddQuestion(false)
  setOpenAnswerScale(false)

  setWaitingOpen(true);
  setTimeout(()=> {
    if (openRiskCollectionOrder > 0 || order === 1){
      var tabId2 = document.getElementById("tabId2");
      var addToLeftAmount = 0;
      if (order === 1){
        var addToTopAmount =  addAmount + (plusAmount * (openOrderNo - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1)); 
      }else{
        var addToTopAmount =  addAmount + (plusAmount * (openRiskCollectionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskCollectionOrder - 1)); 
      }
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId2.offsetTop + topBtnId.offsetTop));
      setTopBtnBottomPos(pos);
      setOpenRiskCollectionOriginalLeft(addToLeftAmount);
      setOpenRiskCollection(true);
      setOpenRiskCollectionOriginalWidth(width);
      setOpenRiskCollectionOriginalHeight(height);
      setOpenRiskCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
    }
    if (openCreateNewCollectionOrder > 0 || order === 2){
      var tabId6 = document.getElementById("tabId6");
      var addToLeftAmount = 0;
      if (order === 2){
        var addToTopAmount =  addAmount + (plusAmount * (openOrderNo - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1)); 
      }else{
        var addToTopAmount =  addAmount + (plusAmount * (openCreateNewCollectionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCreateNewCollectionOrder - 1)); 
      }
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId6.offsetTop + topBtnId.offsetTop));
      setOpenCreateNewCollectionPos(pos);
      setOpenCreateNewCollectionOriginalLeft(addToLeftAmount);
      setOpenCreateNewCollection(true);
      setOpenCreateNewCollectionOriginalWidth(width);
      setOpenCreateNewCollectionOriginalHeight(height);
      setOpenCreateNewCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
    }
    if (openGlobalStandardOrder > 0 || order === 3){
      var tabId5 = document.getElementById("tabId5");
      var addToLeftAmount = 0;
      if (order === 3){
        var addToTopAmount =  addAmount + (plusAmount * (openOrderNo - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1)); 
      }else{
        var addToTopAmount =  addAmount + (plusAmount * (openGlobalStandardOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openGlobalStandardOrder - 1)); 
      }
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId5.offsetTop + topBtnId.offsetTop));
      setOpenGlobalStandardPos(pos);
      setOpenGlobalStandardOriginalLeft(addToLeftAmount);
      setOpenGlobalStandard(true);
      setOpenGlobalStandardOriginalWidth(width);
      setOpenGlobalStandardOriginalHeight(height);
      setOpenGlobalStandardOriginalRelatedHeight(relatedDivLoadingHeight);
    }
    if (openCustomStandardsOrder > 0 || order === 4){
      var tabId10 = document.getElementById("tabId10");
      var addToLeftAmount = 0;
      if (order === 4){
        var addToTopAmount =  addAmount + (plusAmount * (openOrderNo - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1)); 
      }else{
        var addToTopAmount =  addAmount + (plusAmount * (openCustomStandardsOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCustomStandardsOrder - 1)); 
      }
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId10.offsetTop + topBtnId.offsetTop));
      setCustomStandardsPos(pos);
      setOpenCustomStandardsOriginalLeft(addToLeftAmount);
      setOpenCustomStandards(true);
      setOpenCustomStandardsOriginalWidth(width);
      setOpenCustomStandardsOriginalHeight(height);
      setOpenCustomStandardsOriginalRelatedHeight(relatedDivLoadingHeight);

    
    }
    if (openRiskHierarchyOrder > 0 || order === 5){
      var tabId3 = document.getElementById("tabId3");
      var addToLeftAmount = 0;
      if (order === 5){
        var addToTopAmount =  addAmount + (plusAmount * (openOrderNo - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1)); 
      }else{
        var addToTopAmount =  addAmount + (plusAmount * (openRiskHierarchyOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskHierarchyOrder - 1)); 
      }
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId3.offsetTop + topBtnId.offsetTop));

      setOpenExistingRiskHierarchyPos(pos);
      setOpenRiskHierarchyOriginalLeft(addToLeftAmount);
      setOpenRiskHierarchy(true);
      setOpenRiskHierarchyOriginalWidth(width);
      setOpenRiskHierarchyOriginalHeight(height);
      setOpenRiskHierarchyOriginalRelatedHeight(relatedDivLoadingHeight);
    
    }
    if (openCentreMaintenanceOrder > 0 || order === 6){
      var tabId7 = document.getElementById("tabId7");
      var addToLeftAmount = 0;
      if (order === 6){
        var addToTopAmount =  addAmount + (plusAmount * (openOrderNo - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1)); 
      }else{
        var addToTopAmount =  addAmount + (plusAmount * (openCentreMaintenanceOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCentreMaintenanceOrder - 1)); 
      }
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId7.offsetTop + topBtnId.offsetTop));
      setOpenCentreMaintenancePos(pos);
      setOpenCentreMaintenanceOriginalLeft(addToLeftAmount);
      setCentreMaintenance(true);
      setOpenCentreMaintenanceOriginalWidth(width);
      setOpenCentreMaintenanceOriginalHeight(height);
      setOpenCentreMaintenanceOriginalRelatedHeight(relatedDivLoadingHeight);

    }
    if (opensliderMethodsOrder > 0 || order === 7){
      var tabId8 = document.getElementById("tabId8");
      var addToLeftAmount = 0;
      if (order === 7){
        var addToTopAmount =  addAmount + (plusAmount * (openOrderNo - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1)); 
      }else{
        var addToTopAmount =  addAmount + (plusAmount * (opensliderMethodsOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (opensliderMethodsOrder - 1)); 
      }
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId8.offsetTop + topBtnId.offsetTop));
      setOpensliderMethodsPos(pos);
      setOpensliderMethodsOriginalLeft(addToLeftAmount);
      setOpensliderMethods(true);
      setOpensliderMethodsOriginalWidth(width);
      setOpensliderMethodsOriginalHeight(height);
      setOpensliderMethodsOriginalRelatedHeight(relatedDivLoadingHeight);
    }
    if (openAddColorOrder > 0 || order === 8){
      var tabId9 = document.getElementById("tabId9");
      var addToLeftAmount = 0;
      if (order === 8){
        var addToTopAmount =  addAmount + (plusAmount * (openOrderNo - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1)); 
      }else{
        var addToTopAmount =  addAmount + (plusAmount * (openAddColorOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAddColorOrder - 1)); 
      }
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId9.offsetTop + topBtnId.offsetTop));
      setOpeAddColorPos(pos);
      setOpenAddColorOriginalLeft(addToLeftAmount);
      setOpenAddColor(true);
      setOpenAddColorOriginalWidth(width);
      setOpenAddColorOriginalHeight(height);
      setOpenAddColorOriginalRelatedHeight(relatedDivLoadingHeight);
    }
    if (openTransferQuestionnaireOrder > 0 || order === 9){
      var tabId14 = document.getElementById("tabId14");
      var addToLeftAmount = 0;
      if (order === 9){
        var addToTopAmount =  addAmount + (plusAmount * (openOrderNo - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1)); 
      }else{
        var addToTopAmount =  addAmount + (plusAmount * (openTransferQuestionnaireOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openTransferQuestionnaireOrder - 1)); 
      }
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
      setOpenTransferQuestionnairePos(pos);
      setOpenTransferQuestionnaireOriginalLeft(addToLeftAmount);
      setOpenTransferQuestionnaire(true);
      setOpenTransferQuestionnaireOriginalWidth(width);
      setOpenTransferQuestionnaireOriginalHeight(height);
      setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);

    }

    if (openAddQuestionOrder > 0 || order === 11){
      var tabId15 = document.getElementById("tabId15");
      var addToLeftAmount = 0;
      if (order === 11){
        var addToTopAmount =  addAmount + (plusAmount * (openOrderNo - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1)); 
      }else{
        var addToTopAmount =  addAmount + (plusAmount * (openAddQuestionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAddQuestionOrder - 1)); 
      }
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId15.offsetTop + topBtnId.offsetTop));
      setOpenAddQuestionPos(pos);
      setOpenAddQuestionOriginalLeft(addToLeftAmount);
      setOpenAddQuestion(true);
      setOpenAddQuestionOriginalWidth(width);
      setOpenAddQuestionOriginalHeight(height);
      setOpenAddQuestionOriginalRelatedHeight(relatedDivLoadingHeight);

    }

    if (openAnswerScaleOrder > 0 || order === 12){
      var tabId16 = document.getElementById("tabId16");
      var addToLeftAmount = 0;
      if (order === 12){
        var addToTopAmount =  addAmount + (plusAmount * (openOrderNo - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1)); 
      }else{
        var addToTopAmount =  addAmount + (plusAmount * (openAnswerScaleOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAnswerScaleOrder - 1)); 
      }
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId16.offsetTop + topBtnId.offsetTop));
      setOpenAnswerScalePos(pos);
      setOpenAnswerScaleOriginalLeft(addToLeftAmount);
      setOpenAnswerScale(true);
      setOpenAnswerScaleOriginalWidth(width);
      setOpenAnswerScaleOriginalHeight(height);
      setOpenAnswerScaleOriginalRelatedHeight(relatedDivLoadingHeight);
    }
    setWaitingOpen(false);
  }, 500);  

}


function closeLayersFuction(order){
  var addAmount = 100;
  var plusAmount = 40;
  var addAmountLeft = 8;
  var plusAmountLeft = 20;
  
  var topBtnId = document.getElementById("topBtnId");
  var tabId1 = document.getElementById("tabId1");

  setOpenRiskCollection(false);
  setOpenCreateNewCollection(false);
  setOpenGlobalStandard(false);
  setOpenCustomStandards(false);
  setOpenRiskHierarchy(false);
  setCentreMaintenance(false);
  setOpensliderMethods(false);
  setOpenAddColor(false);
  setOpenEditCollection(false);
  setOpenTransferQuestionnaire(false);
  setOpenAddQuestion(false)
  setOpenAnswerScale(false)

  setWaitingOpen(true);
  setTimeout(()=> {
    if (openRiskCollectionOrder > 0 && order != 1){
      var tabId2 = document.getElementById("tabId2");
      var addToLeftAmount = 0;
      var addToTopAmount =  addAmount + (plusAmount * (openRiskCollectionOrder - 1));
      addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskCollectionOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId2.offsetTop + topBtnId.offsetTop));
      setTopBtnBottomPos(pos);
      setOpenRiskCollectionOriginalLeft(addToLeftAmount);
      setOpenRiskCollection(true);
      setOpenRiskCollectionOriginalWidth(width);
      setOpenRiskCollectionOriginalHeight(height);
      setOpenRiskCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
    }
    if (openCreateNewCollectionOrder > 0 && order != 2){
      var tabId6 = document.getElementById("tabId6");
      var addToLeftAmount = 0;
        var addToTopAmount =  addAmount + (plusAmount * (openCreateNewCollectionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCreateNewCollectionOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId6.offsetTop + topBtnId.offsetTop));
      setOpenCreateNewCollectionPos(pos);
      setOpenCreateNewCollectionOriginalLeft(addToLeftAmount);
      setOpenCreateNewCollection(true);
      setOpenCreateNewCollectionOriginalWidth(width);
      setOpenCreateNewCollectionOriginalHeight(height);
      setOpenCreateNewCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
    }
    if (openGlobalStandardOrder > 0 && order != 3){
      var tabId5 = document.getElementById("tabId5");
        var addToTopAmount =  addAmount + (plusAmount * (openGlobalStandardOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openGlobalStandardOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId5.offsetTop + topBtnId.offsetTop));
      setOpenGlobalStandardPos(pos);
      setOpenGlobalStandardOriginalLeft(addToLeftAmount);
      setOpenGlobalStandard(true);
      setOpenGlobalStandardOriginalWidth(width);
      setOpenGlobalStandardOriginalHeight(height);
      setOpenGlobalStandardOriginalRelatedHeight(relatedDivLoadingHeight);
    }
    if (openCustomStandardsOrder > 0 && order != 4){
      var tabId10 = document.getElementById("tabId10");
      var addToLeftAmount = 0;
        var addToTopAmount =  addAmount + (plusAmount * (openCustomStandardsOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCustomStandardsOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId10.offsetTop + topBtnId.offsetTop));
      setCustomStandardsPos(pos);
      setOpenCustomStandardsOriginalLeft(addToLeftAmount);
      setOpenCustomStandards(true);
      setOpenCustomStandardsOriginalWidth(width);
      setOpenCustomStandardsOriginalHeight(height);
      setOpenCustomStandardsOriginalRelatedHeight(relatedDivLoadingHeight);
    }
    if (openRiskHierarchyOrder > 0 && order != 5){
      var tabId3 = document.getElementById("tabId3");
      var addToLeftAmount = 0;
        var addToTopAmount =  addAmount + (plusAmount * (openRiskHierarchyOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskHierarchyOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId3.offsetTop + topBtnId.offsetTop));

      setOpenExistingRiskHierarchyPos(pos);
      setOpenRiskHierarchyOriginalLeft(addToLeftAmount);
      setOpenRiskHierarchy(true);
      setOpenRiskHierarchyOriginalWidth(width);
      setOpenRiskHierarchyOriginalHeight(height);
      setOpenRiskHierarchyOriginalRelatedHeight(relatedDivLoadingHeight);
    
    }
    if (openCentreMaintenanceOrder > 0 && order != 6){
      var tabId7 = document.getElementById("tabId7");
      var addToLeftAmount = 0;
        var addToTopAmount =  addAmount + (plusAmount * (openCentreMaintenanceOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCentreMaintenanceOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId7.offsetTop + topBtnId.offsetTop));
      setOpenCentreMaintenancePos(pos);
      setOpenCentreMaintenanceOriginalLeft(addToLeftAmount);
      setCentreMaintenance(true);
      setOpenCentreMaintenanceOriginalWidth(width);
      setOpenCentreMaintenanceOriginalHeight(height);
      setOpenCentreMaintenanceOriginalRelatedHeight(relatedDivLoadingHeight);

    }
    if (opensliderMethodsOrder > 0 && order != 7){
      var tabId8 = document.getElementById("tabId8");
      var addToLeftAmount = 0;
        var addToTopAmount =  addAmount + (plusAmount * (opensliderMethodsOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (opensliderMethodsOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId8.offsetTop + topBtnId.offsetTop));
      setOpensliderMethodsPos(pos);
      setOpensliderMethodsOriginalLeft(addToLeftAmount);
      setOpensliderMethods(true);
      setOpensliderMethodsOriginalWidth(width);
      setOpensliderMethodsOriginalHeight(height);
      setOpensliderMethodsOriginalRelatedHeight(relatedDivLoadingHeight);
    }
    if (openAddColorOrder > 0 && order != 8){
      var tabId9 = document.getElementById("tabId9");
      var addToLeftAmount = 0;
        var addToTopAmount =  addAmount + (plusAmount * (openAddColorOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAddColorOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId9.offsetTop + topBtnId.offsetTop));
      setOpeAddColorPos(pos);
      setOpenAddColorOriginalLeft(addToLeftAmount);
      setOpenAddColor(true);
      setOpenAddColorOriginalWidth(width);
      setOpenAddColorOriginalHeight(height);
      setOpenAddColorOriginalRelatedHeight(relatedDivLoadingHeight);
    }
    if (openTransferQuestionnaireOrder > 0 && order != 9){
      var tabId14 = document.getElementById("tabId14");
      var addToLeftAmount = 0;
        var addToTopAmount =  addAmount + (plusAmount * (openTransferQuestionnaireOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openTransferQuestionnaireOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
      setOpenTransferQuestionnairePos(pos);
      setOpenTransferQuestionnaireOriginalLeft(addToLeftAmount);
      setOpenTransferQuestionnaire(true);
      setOpenTransferQuestionnaireOriginalWidth(width);
      setOpenTransferQuestionnaireOriginalHeight(height);
      setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);

    }

    if (openAddQuestionOrder > 0 && order != 11){
      var tabId15 = document.getElementById("tabId15");
      var addToLeftAmount = 0;
        var addToTopAmount =  addAmount + (plusAmount * (openAddQuestionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAddQuestionOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId15.offsetTop + topBtnId.offsetTop));
      setOpenAddQuestionPos(pos);
      setOpenAddQuestionOriginalLeft(addToLeftAmount);
      setOpenAddQuestion(true);
      setOpenAddQuestionOriginalWidth(width);
      setOpenAddQuestionOriginalHeight(height);
      setOpenAddQuestionOriginalRelatedHeight(relatedDivLoadingHeight);

    }

    if (openAnswerScaleOrder > 0 && order != 12){
      var tabId16 = document.getElementById("tabId16");
      var addToLeftAmount = 0;
        var addToTopAmount =  addAmount + (plusAmount * (openAnswerScaleOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAnswerScaleOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId16.offsetTop + topBtnId.offsetTop));
      setOpenAnswerScalePos(pos);
      setOpenAnswerScaleOriginalLeft(addToLeftAmount);
      setOpenAnswerScale(true);
      setOpenAnswerScaleOriginalWidth(width);
      setOpenAnswerScaleOriginalHeight(height);
      setOpenAnswerScaleOriginalRelatedHeight(relatedDivLoadingHeight);

    }
    setWaitingOpen(false);
  }, 500);  

}


function refreshLayersFuction(){
  var addAmount = 100;
  var plusAmount = 40;
  var addAmountLeft = 8;
  var plusAmountLeft = 20;
  
  var topBtnId = document.getElementById("topBtnId");
  var tabId1 = document.getElementById("tabId1");

  setOpenRiskCollection(false);
  setOpenCreateNewCollection(false);
  setOpenGlobalStandard(false);
  setOpenCustomStandards(false);
  setOpenRiskHierarchy(false);
  setCentreMaintenance(false);
  setOpensliderMethods(false);
  setOpenAddColor(false);
  setOpenEditCollection(false);
  setOpenTransferQuestionnaire(false);
  setOpenAddQuestion(false)
  setOpenAnswerScale(false)

  var maxZIndex = findMaxZIndex();

  setWaitingOpen(true);
  setTimeout(()=> {
    if (openRiskCollectionOrder > 0){
      var tabId2 = document.getElementById("tabId2");
      var addToLeftAmount = 0;
      var addToTopAmount =  addAmount + (plusAmount * (openRiskCollectionOrder - 1));
      addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskCollectionOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId2.offsetTop + topBtnId.offsetTop));
      setTopBtnBottomPos(pos);
      setOpenRiskCollectionOriginalLeft(addToLeftAmount);
      setOpenRiskCollection(true);
      setOpenRiskCollectionOriginalWidth(width);
      setOpenRiskCollectionOriginalHeight(height);
      setOpenRiskCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
      setOpenRiskCollectionZIndex(maxZIndex + openRiskCollectionOrder);
    }
    if (openCreateNewCollectionOrder > 0){
      var tabId6 = document.getElementById("tabId6");
      var addToLeftAmount = 0;
      var addToTopAmount =  addAmount + (plusAmount * (openCreateNewCollectionOrder - 1));
      addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCreateNewCollectionOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId6.offsetTop + topBtnId.offsetTop));
      setOpenCreateNewCollectionPos(pos);
      setOpenCreateNewCollectionOriginalLeft(addToLeftAmount);
      setOpenCreateNewCollection(true);
      setOpenCreateNewCollectionOriginalWidth(width);
      setOpenCreateNewCollectionOriginalHeight(height);
      setOpenCreateNewCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
      setOpenCreateNewCollectionZIndex(maxZIndex + openCreateNewCollectionOrder);
    }
    if (openGlobalStandardOrder > 0){
      var tabId5 = document.getElementById("tabId5");
      var addToLeftAmount = 0;
      var addToTopAmount =  addAmount + (plusAmount * (openGlobalStandardOrder - 1));
      addToLeftAmount = addAmountLeft + (plusAmountLeft * (openGlobalStandardOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId5.offsetTop + topBtnId.offsetTop));
      setOpenGlobalStandardPos(pos);
      setOpenGlobalStandardOriginalLeft(addToLeftAmount);
      setOpenGlobalStandard(true);
      setOpenGlobalStandardOriginalWidth(width);
      setOpenGlobalStandardOriginalHeight(height);
      setOpenGlobalStandardOriginalRelatedHeight(relatedDivLoadingHeight);
      setOpenGlobalStandardZIndex(maxZIndex + openGlobalStandardOrder);
    }
    if (openCustomStandardsOrder > 0){
      var tabId10 = document.getElementById("tabId10");
      var addToLeftAmount = 0;
      var addToTopAmount =  addAmount + (plusAmount * (openCustomStandardsOrder - 1));
      addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCustomStandardsOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId10.offsetTop + topBtnId.offsetTop));
      setCustomStandardsPos(pos);
      setOpenCustomStandardsOriginalLeft(addToLeftAmount);
      setOpenCustomStandards(true);
      setOpenCustomStandardsOriginalWidth(width);
      setOpenCustomStandardsOriginalHeight(height);
      setOpenCustomStandardsOriginalRelatedHeight(relatedDivLoadingHeight);
      setOpenCustomStandardsZIndex(maxZIndex + openCustomStandardsOrder);
    }
    if (openRiskHierarchyOrder > 0){
      var tabId3 = document.getElementById("tabId3");
      var addToLeftAmount = 0;
      var addToTopAmount =  addAmount + (plusAmount * (openRiskHierarchyOrder - 1));
      addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskHierarchyOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId3.offsetTop + topBtnId.offsetTop));
      setOpenExistingRiskHierarchyPos(pos);
      setOpenRiskHierarchyOriginalLeft(addToLeftAmount);
      setOpenRiskHierarchy(true);
      setOpenRiskHierarchyOriginalWidth(width);
      setOpenRiskHierarchyOriginalHeight(height);
      setOpenRiskHierarchyOriginalRelatedHeight(relatedDivLoadingHeight);
      setOpenRiskHierarchyZIndex(maxZIndex + openRiskHierarchyOrder);          
    }
    if (openCentreMaintenanceOrder > 0){
      var tabId7 = document.getElementById("tabId7");
      var addToLeftAmount = 0;
      var addToTopAmount =  addAmount + (plusAmount * (openCentreMaintenanceOrder - 1));
      addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCentreMaintenanceOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId7.offsetTop + topBtnId.offsetTop));
      setOpenCentreMaintenancePos(pos);
      setOpenCentreMaintenanceOriginalLeft(addToLeftAmount);
      setCentreMaintenance(true);
      setOpenCentreMaintenanceOriginalWidth(width);
      setOpenCentreMaintenanceOriginalHeight(height);
      setOpenCentreMaintenanceOriginalRelatedHeight(relatedDivLoadingHeight);
      setOpenCentreMaintenanceZIndex(maxZIndex + openCentreMaintenanceOrder);          
    }
    if (opensliderMethodsOrder > 0){
      var tabId8 = document.getElementById("tabId8");
      var addToLeftAmount = 0;
      var addToTopAmount =  addAmount + (plusAmount * (opensliderMethodsOrder - 1));
      addToLeftAmount = addAmountLeft + (plusAmountLeft * (opensliderMethodsOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId8.offsetTop + topBtnId.offsetTop));
      setOpensliderMethodsPos(pos);
      setOpensliderMethodsOriginalLeft(addToLeftAmount);
      setOpensliderMethods(true);
      setOpensliderMethodsOriginalWidth(width);
      setOpensliderMethodsOriginalHeight(height);
      setOpensliderMethodsOriginalRelatedHeight(relatedDivLoadingHeight);
      setOpenSliderMethodsZIndex(maxZIndex + opensliderMethodsOrder);
    }
    if (openAddColorOrder > 0){
      var tabId9 = document.getElementById("tabId9");
      var addToLeftAmount = 0;
      var addToTopAmount =  addAmount + (plusAmount * (openAddColorOrder - 1));
      addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAddColorOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId9.offsetTop + topBtnId.offsetTop));
      setOpeAddColorPos(pos);
      setOpenAddColorOriginalLeft(addToLeftAmount);
      setOpenAddColor(true);
      setOpenAddColorOriginalWidth(width);
      setOpenAddColorOriginalHeight(height);
      setOpenAddColorOriginalRelatedHeight(relatedDivLoadingHeight);
      setOpenAddColorZIndex(maxZIndex + openAddColorOrder);
    }
    if (openTransferQuestionnaireOrder > 0){
      var tabId14 = document.getElementById("tabId14");
      var addToLeftAmount = 0;
      var addToTopAmount =  addAmount + (plusAmount * (openTransferQuestionnaireOrder - 1));
      addToLeftAmount = addAmountLeft + (plusAmountLeft * (openTransferQuestionnaireOrder - 1)); 
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
      setOpenTransferQuestionnairePos(pos);
      setOpenTransferQuestionnaireOriginalLeft(addToLeftAmount);
      setOpenTransferQuestionnaire(true);
      setOpenTransferQuestionnaireOriginalWidth(width);
      setOpenTransferQuestionnaireOriginalHeight(height);
      setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);
      setOpenTransferQuestionnaireZIndex(maxZIndex + openTransferQuestionnaireOrder);
    }

              // add question
              if (openAddQuestionOrder > 0){
                var tabId15 = document.getElementById("tabId15");
                var addToLeftAmount = 0;
                var addToTopAmount =  addAmount + (plusAmount * (openAddQuestionOrder - 1));
                addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAddQuestionOrder - 1)); 
                var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId15.offsetTop + topBtnId.offsetTop));
                setOpenAddQuestionPos(pos);
                setOpenAddQuestionOriginalLeft(addToLeftAmount);
                setOpenAddQuestion(true);
                setOpenAddQuestionOriginalWidth(width);
                setOpenAddQuestionOriginalHeight(height);
                setOpenAddQuestionOriginalRelatedHeight(relatedDivLoadingHeight);
                setOpenAddQuestionZIndex(maxZIndex + openAddQuestionOrder);
              }

              if (openAnswerScaleOrder > 0){
                var tabId16 = document.getElementById("tabId16");
                var addToLeftAmount = 0;
                var addToTopAmount =  addAmount + (plusAmount * (openAnswerScaleOrder - 1));
                addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAnswerScaleOrder - 1)); 
                var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId16.offsetTop + topBtnId.offsetTop));
                setOpenAnswerScalePos(pos);
                setOpenAnswerScaleOriginalLeft(addToLeftAmount);
                setOpenAnswerScale(true);
                setOpenAnswerScaleOriginalWidth(width);
                setOpenAnswerScaleOriginalHeight(height);
                setOpenAnswerScaleOriginalRelatedHeight(relatedDivLoadingHeight);
                setOpenAnswerScaleZIndex(maxZIndex + openAnswerScaleOrder);
              }


    setWaitingOpen(false);
  }, 500);  

}








  const [topBtnBottomPos, setTopBtnBottomPos] = useState(0);
  const [openRiskCollection, setOpenRiskCollection] = useState(false);
  function setOpenRiskCollectionFuction(flag){
      
    var maxZIndex = findMaxZIndex();
    setOpenRiskCollectionZIndex(maxZIndex + 1);
    

    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    var tabId2 = document.getElementById("tabId2");
    
    var addToTopAmount =  addToTop();
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId2.offsetTop + topBtnId.offsetTop));

    setTopBtnBottomPos(pos);
    setOpenRiskCollection(true);

    setOpenRiskCollectionOriginalWidth(width)
    setOpenRiskCollectionOriginalHeight(height)

    
    //document.getElementById('divOpenRiskCollection').style.marginLeft = '500px'; 



    
  }


const [openCollectionData, setOpenCollectionData] = useState(false);
const [openCollectionDataTopPos, setOpenCollectionDataTopPos] = useState(0);
const [selectedCollectionEvaluateId, setSelectedCollectionEvaluateId] = useState('');
const [selectedCollectionEvaluateUniqueName, setSelectedCollectionEvaluateUniqueName] = useState('');
const [selectedCollectionEvaluateTitle, setSelectedCollectionEvaluateTitle] = useState('');






const [openExistingRiskCollection, setOpenExistingRiskCollection] = useState(false);
const [openExistingRiskCollectionPos, setOpenExistingRiskCollectionPos] = useState(0);
function setOpenExistingRiskCollectionFuction(flag){

  var maxZIndex = findMaxZIndex();
  setOpenExistingRiskCollectionZIndex(maxZIndex + 1);

    


    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    var tabId4 = document.getElementById("tabId4");
    var addToTopAmount =  addToTop();
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId4.offsetTop + topBtnId.offsetTop));

    setOpenExistingRiskCollectionPos(pos);
    setOpenExistingRiskCollection(flag);
}





/** ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */  


const [openRiskHierarchy,setOpenRiskHierarchy] = useState(false);
const [openExistingRiskHierarchyPos, setOpenExistingRiskHierarchyPos] = useState(0);




function setOpenRiskHierarchyFuction(flag){
       
  var maxZIndex = findMaxZIndex();
  setOpenRiskHierarchyZIndex(maxZIndex + 1);

  if (!openRiskHierarchy){
    var openOrderNo = 0;
    if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !  centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale) {
      setOpenOrderNumber(1);
      openOrderNo = 1;
    }else{
      openOrderNo = openOrderNumber+1;
    }
    setOpenOrderNumber(openOrderNo);
    setOpenRiskHierarchyOrder(openOrderNo);

    /*

  var topBtnId = document.getElementById("topBtnId");
  var tabId1 = document.getElementById("tabId1");
  var tabId3 = document.getElementById("tabId3");
  var addToTopAmount =  addToTopPos();
  addToTop('divOpenRiskHierarchy');
  var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId3.offsetTop + topBtnId.offsetTop));
  console.log(pos)

  setOpenExistingRiskHierarchyPos(pos);
  setOpenRiskHierarchy(true);

    setOpenRiskHierarchyOriginalWidth(width)
    setOpenRiskHierarchyOriginalHeight(height)
*/
viewLayersFuction(5,openOrderNo);
    
      }


}



const [openGlobalStandard,setOpenGlobalStandard] = useState(false);
const [openGlobalStandardPos, setOpenGlobalStandardPos] = useState(0);

function setOpenGlobalStandardFuction(flag){
       
  var maxZIndex = findMaxZIndex();
  setOpenGlobalStandardZIndex(maxZIndex + 1);

  if (!openGlobalStandard){  
    var openOrderNo = 0;
    if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !  centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale) {
      setOpenOrderNumber(1);
      openOrderNo = 1;
    }else{
      openOrderNo = openOrderNumber+1;
    }
    setOpenOrderNumber(openOrderNo);
    setOpenGlobalStandardOrder(openOrderNo);

    /*
  var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    
    var tabId5 = document.getElementById("tabId5");
    var addToTopAmount =  addToTopPos();
    addToTop('divOpenGlobalStandard');
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId5.offsetTop + topBtnId.offsetTop));
    console.log(pos)

    setOpenGlobalStandardPos(pos);
    setOpenGlobalStandard(true);

      setOpenGlobalStandardOriginalWidth(width)
      setOpenGlobalStandardOriginalHeight(height)
      */
      viewLayersFuction(3,openOrderNo);

    } 

}


const [openCreateNewCollection,setOpenCreateNewCollection] = useState(false);
const [openCreateNewCollectionPos, setOpenCreateNewCollectionPos] = useState(0);

function setOpenCreateNewCollectionFuction(flag){

       
  
  var maxZIndex = findMaxZIndex();
  setOpenCreateNewCollectionZIndex(maxZIndex + 1);
  
  setOpenCreateNewCollectionWithColId('');

  if (!openCreateNewCollection){
    var openOrderNo = 0;
    if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !  centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale) {
      setOpenOrderNumber(1);
      openOrderNo = 1;
    }else{
      openOrderNo = openOrderNumber+1;
    }
    setOpenOrderNumber(openOrderNo);
    setOpenCreateNewCollectionOrder(openOrderNo);

    /*
  var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    
    var tabId6 = document.getElementById("tabId6");
    var addToTopAmount =  addToTopPos();
    addToTop('divOpenCreateNewCollection');
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId6.offsetTop + topBtnId.offsetTop));
    console.log(pos)

    setOpenCreateNewCollectionPos(pos);
    setOpenCreateNewCollection(true);

      setOpenCreateNewCollectionOriginalWidth(width)
      setOpenCreateNewCollectionOriginalHeight(height)
    */
      viewLayersFuction(2,openOrderNo);
  }  
    
}



const [openEditCollection,setOpenEditCollection] = useState(false);
const [openEditCollectionPos, setOpenEditCollectionPos] = useState(0);

function setOpenEditCollectionFuction(flag){

   
  var maxZIndex = findMaxZIndex();
  setOpenEditCollectionZIndex(maxZIndex + 1);
  

    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    
    var tabId12 = document.getElementById("tabId12");
    var addToTopAmount =  addToTop();
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId12.offsetTop + topBtnId.offsetTop));
    console.log(pos)

    setOpenEditCollectionPos(pos);
    setOpenEditCollection(true);
   
}


const [openCreateNewCollectionWithColId,setOpenCreateNewCollectionWithColId] = useState('');

function setOpenCreateNewCollectionFuctionWithEdit(colId){
  var maxZIndex = findMaxZIndex();
  setOpenEditCollectionZIndex(maxZIndex + 1);
  setOpenCreateNewCollectionWithColId(colId);

  disabledOtherDivs('divOpenEditCollection');

    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    var tabId12 = document.getElementById("tabId12");
    var addToTopAmount =  addToTop();
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId12.offsetTop + topBtnId.offsetTop));
    console.log(pos)

    setOpenEditCollectionPos(pos);
    setOpenEditCollection(true);

    
    
}


const [centreMaintenance,setCentreMaintenance] = useState(false);
const [openCentreMaintenancePos, setOpenCentreMaintenancePos] = useState(0);

function setOpenCentreMaintenanceFuction(flag){

  var maxZIndex = findMaxZIndex();
  setOpenCentreMaintenanceZIndex(maxZIndex + 1);

    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    var tabId7 = document.getElementById("tabId7");
    var addToTopAmount =  addToTop();
    addToTop('divOpenCentreMaintenance');
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId7.offsetTop + topBtnId.offsetTop));
    console.log(pos)

    setOpenCentreMaintenancePos(pos);
    setCentreMaintenance(true);

      setOpenCentreMaintenanceOriginalWidth(width)
      setOpenCentreMaintenanceOriginalHeight(height)



}

const [opensliderMethods,setOpensliderMethods] = useState(false);
const [opensliderMethodsPos, setOpensliderMethodsPos] = useState(0);

function setOpenSliderMethodsFuction(flag){

  var maxZIndex = findMaxZIndex();
  setOpenSliderMethodsZIndex(maxZIndex + 1);

  if (!opensliderMethods){
    var openOrderNo = 0;
    if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !  centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale) {
      setOpenOrderNumber(1);
      openOrderNo = 1;
    }else{
      openOrderNo = openOrderNumber+1;
    }

    setOpenOrderNumber(openOrderNo);
    setOpensliderMethodsOrder(openOrderNo);
    /*
    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    var tabId8 = document.getElementById("tabId8");
    var addToTopAmount =  addToTopPos();
    addToTop('divOpenSliderMethods');
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId8.offsetTop + topBtnId.offsetTop));
    console.log(pos)

    setOpensliderMethodsPos(pos);
    setOpensliderMethods(true);

      setOpensliderMethodsOriginalWidth(width)
      setOpensliderMethodsOriginalHeight(height)
    */
      viewLayersFuction(7,openOrderNo);
  }  
  
}

const [openAddColor,setOpenAddColor] = useState(false);
const [opeAddColorPos, setOpeAddColorPos] = useState(0);

function setOpenAddColorFuction(flag){
  var maxZIndex = findMaxZIndex();
  setOpenAddColorZIndex(maxZIndex + 1);

  if (!openAddColor) {  
    var openOrderNo = 0;
    if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !  centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale) {
      setOpenOrderNumber(1);
      openOrderNo = 1;
    }else{
      openOrderNo = openOrderNumber+1;
    }
    setOpenOrderNumber(openOrderNo);
    setOpenAddColorOrder(openOrderNo);
    /*
  var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    var tabId9 = document.getElementById("tabId9");
    var addToTopAmount =  addToTopPos();
    addToTop('divOpenAddColor');
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId9.offsetTop + topBtnId.offsetTop));
    console.log(pos)

    setOpeAddColorPos(pos);
    setOpenAddColor(true);

      setOpenAddColorOriginalWidth(width)
      setOpenAddColorOriginalHeight(height)
    */
      viewLayersFuction(8,openOrderNo);
    }



}

const [openTransferQuestionnaire,setOpenTransferQuestionnaire] = useState(false);
const [openTransferQuestionnairePos, setOpenTransferQuestionnairePos] = useState(0);

function setOpenTransferQuestionnaireFuction(flag){
  var maxZIndex = findMaxZIndex();
  setOpenTransferQuestionnaireZIndex(maxZIndex + 1);

  if (!openTransferQuestionnaire){
    var openOrderNo = 0;
    if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !  centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale) {
      setOpenOrderNumber(1);
      openOrderNo = 1;
    }else{
      openOrderNo = openOrderNumber+1;
    }
    setOpenOrderNumber(openOrderNo);
    setOpenTransferQuestionnaireOrder(openOrderNo);

    /*
    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    var tabId14 = document.getElementById("tabId14");
    var addToTopAmount =  addToTopPos();
    addToTop('divOpenTransferQuestionnaire');
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
    console.log(pos)

    setOpenTransferQuestionnairePos(pos);
    setOpenTransferQuestionnaire(true);
   
    setOpenTransferQuestionnaireOriginalWidth(width);
    setOpenTransferQuestionnaireOriginalHeight(height)
    */
    viewLayersFuction(9,openOrderNo);
  }

}

const [openAddQuestion,setOpenAddQuestion] = useState(false);
const [openAddQuestionPos, setOpenAddQuestionPos] = useState(0);

function setOpenAddQuestionFuction(flag){
  var maxZIndex = findMaxZIndex();
  setOpenAddQuestionZIndex(maxZIndex + 1);

  if (!openAddQuestion){
    var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !  centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      }else{
        openOrderNo = openOrderNumber+1;
      }
    setOpenOrderNumber(openOrderNo);
    setOpenAddQuestionOrder(openOrderNo);
    /*
    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    var tabId14 = document.getElementById("tabId14");
    var addToTopAmount =  addToTopPos();
    addToTop('divOpenTransferQuestionnaire');
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
    setOpenTransferQuestionnairePos(pos);
    setOpenTransferQuestionnaire(true);
    setOpenTransferQuestionnaireOriginalWidth(width);
    setOpenTransferQuestionnaireOriginalHeight(height);
    setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);
    */
    viewLayersFuction(11,openOrderNo);
  }

}

const [openAnswerScale,setOpenAnswerScale] = useState(false);
const [openAnswerScalePos, setOpenAnswerScalePos] = useState(0);

function setOpenAnswerScaleFuction(flag){
  var maxZIndex = findMaxZIndex();
  setOpenAnswerScaleZIndex(maxZIndex + 1);

  if (!openAnswerScale){
    var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !  centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      }else{
        openOrderNo = openOrderNumber+1;
      }
    setOpenOrderNumber(openOrderNo);
    setOpenAnswerScaleOrder(openOrderNo);
    /*
    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    var tabId14 = document.getElementById("tabId14");
    var addToTopAmount =  addToTopPos();
    addToTop('divOpenTransferQuestionnaire');
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
    setOpenTransferQuestionnairePos(pos);
    setOpenTransferQuestionnaire(true);
    setOpenTransferQuestionnaireOriginalWidth(width);
    setOpenTransferQuestionnaireOriginalHeight(height);
    setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);
    */
    viewLayersFuction(12,openOrderNo);
  }

}



const [openCustomStandards,setOpenCustomStandards] = useState(false);
const [customStandardsPos, setCustomStandardsPos] = useState(0);



function setOpenCustomStandardFuction(flag){
  var maxZIndex = findMaxZIndex();
  setOpenCustomStandardsZIndex(maxZIndex + 1);
  if (!openCustomStandards){
    var openOrderNo = 0;
    if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !  centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale) {
      setOpenOrderNumber(1);
      openOrderNo = 1;
    }else{
      openOrderNo = openOrderNumber+1;
    }
    setOpenOrderNumber(openOrderNo);
    setOpenCustomStandardsOrder(openOrderNo);

    /*
   var topBtnId = document.getElementById("topBtnId");
   var tabId1 = document.getElementById("tabId1");
   var tabId10 = document.getElementById("tabId10");
   var addToTopAmount =  addToTopPos();
   addToTop('divOpenCustomStandards');
   var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId10.offsetTop + topBtnId.offsetTop));
   console.log(pos)

   setCustomStandardsPos(pos);
   setOpenCustomStandards(true);

     setOpenCustomStandardsOriginalWidth(width)
     setOpenCustomStandardsOriginalHeight(height)
   */
     viewLayersFuction(4,openOrderNo);
    }  

}



            const [openRiskCollectionZIndex, setOpenRiskCollectionZIndex] = React.useState(1);
            const [openCollectionDataZIndex, setOpenCollectionDataZIndex] = React.useState(1);
            const [openExistingRiskCollectionZIndex, setOpenExistingRiskCollectionZIndex] = React.useState(1);
            const [openRiskHierarchyZIndex, setOpenRiskHierarchyZIndex] = React.useState(1);
            const [openRiskHierarchyZIndexPrevious, setOpenRiskHierarchyZIndexPrevious] = React.useState(1);
            const [openGlobalStandardZIndex, setOpenGlobalStandardZIndex] = React.useState(1);
            const [openCreateNewCollectionZIndex, setOpenCreateNewCollectionZIndex] = React.useState(1);
            const [openCreateNewCollectionZIndexPrevious, setOpenCreateNewCollectionZIndexPrevious] = React.useState(1);
            const [openEditCollectionZIndex, setOpenEditCollectionZIndex] = React.useState(1);
            const [openEditCollectionZIndexPrevious, setOpenEditCollectionZIndexPrevious] = React.useState(1);
            const [openAddColorZIndex, setOpenAddColorZIndex] = React.useState(1);
            const [openSliderMethodsZIndex, setOpenSliderMethodsZIndex] = React.useState(1);
            const [openCustomStandardsZIndex, setOpenCustomStandardsZIndex] = React.useState(1);
            const [openCentreMaintenanceZIndex, setOpenCentreMaintenanceZIndex] = React.useState(1);
            const [openCommentZIndex, setOpenCommentZIndex] = React.useState(1);
            const [openTransferQuestionnaireZIndex, setOpenTransferQuestionnaireZIndex] = React.useState(1);
            const [openAddQuestionZIndex, setOpenAddQuestionZIndex] = React.useState(1);
            const [openAnswerScaleZIndex, setOpenAnswerScaleZIndex] = React.useState(1);

        
            const [openCommentPos, setOpenCommentPos] = useState(-10);


            const [openRiskHierarchyOriginalLeft,setOpenRiskHierarchyOriginalLeft] = useState(0);
            const [openGlobalStandardOriginalLeft,setOpenGlobalStandardOriginalLeft] = useState(0);
            const [openCreateNewCollectionOriginalLeft,setOpenCreateNewCollectionOriginalLeft] = useState(0);
            const [openCentreMaintenanceOriginalLeft,setOpenCentreMaintenanceOriginalLeft] = useState(0);
            const [opensliderMethodsOriginalLeft,setOpensliderMethodsOriginalLeft] = useState(0);
            const [openAddColorOriginalLeft,setOpenAddColorOriginalLeft] = useState(0);
            const [openCustomStandardsOriginalLeft,setOpenCustomStandardsOriginalLeft] = useState(0);
            const [openEditCollectionOriginalLeft,setOpenEditCollectionOriginalLeft] = useState(0);
            const [openRiskCollectionOriginalLeft,setOpenRiskCollectionOriginalLeft] = useState(20);
            const [openTransferQuestionnaireOriginalLeft,setOpenTransferQuestionnaireOriginalLeft] = useState(0);
            const [openCollectionDataOriginalLeft,setOpenCollectionDataOriginalLeft] = useState(0);
            const [openAddQuestionOriginalLeft,setOpenAddQuestionOriginalLeft] = useState(0);
            const [openAnswerScaleOriginalLeft,setOpenAnswerScaleOriginalLeft] = useState(0);
      
            const [openRiskHierarchyOriginalTop,setOpenRiskHierarchyOriginalTop] = useState(0);
            const [openGlobalStandardOriginalTop,setOpenGlobalStandardOriginalTop] = useState(0);
            const [openCreateNewCollectionOriginalTop,setOpenCreateNewCollectionOriginalTop] = useState(0);
            const [openCentreMaintenanceOriginalTop,setOpenCentreMaintenanceOriginalTop] = useState(0);
            const [opensliderMethodsOriginalTop,setOpensliderMethodsOriginalTop] = useState(0);
            const [openAddColorOriginalTop,setOpenAddColorOriginalTop] = useState(0);
            const [openCustomStandardsOriginalTop,setOpenCustomStandardsOriginalTop] = useState(0);
            const [openEditCollectionOriginalTop,setOpenEditCollectionOriginalTop] = useState(0);
            const [openRiskCollectionOriginalTop,setOpenRiskCollectionOriginalTop] = useState(37);
            const [openTransferQuestionnaireOriginalTop,setOpenTransferQuestionnaireOriginalTop] = useState(0);
            const [openAddQuestionOriginalTop,setOpenAddQuestionOriginalTop] = useState(0);
            const [openCollectionDataOriginalTop,setOpenCollectionDataOriginalTop] = useState(0);
            const [openAnswerScaleOriginalTop,setOpenAnswerScaleOriginalTop] = useState(0);
      

            function setOpenRiskCollectionCloseFunction(flag){
              setOpenRiskCollection(flag);
              setOpenRiskCollectionOriginalRelatedHeight(relatedDivOriginalHeight);
              //setOpenOrderNumber(openOrderNumber-1);
              setOpenRiskCollectionOrder(0);
              closeLayersFuction(1);
            }
            function setOpenCreateNewCollectionCloseFunction(flag){
              setOpenCreateNewCollection(flag);
              setOpenCreateNewCollectionOriginalRelatedHeight(relatedDivOriginalHeight);
              //setOpenOrderNumber(openOrderNumber-1);
              setOpenCreateNewCollectionOrder(0);
              closeLayersFuction(2);
            } 
            function setOpenGlobalStandardCloseFunction(flag){
              setOpenGlobalStandard(flag);
              setOpenGlobalStandardOriginalRelatedHeight(relatedDivOriginalHeight);
              //setOpenOrderNumber(openOrderNumber-1);
              setOpenGlobalStandardOrder(0);
              closeLayersFuction(3);
            } 
            function setOpenCustomStandardsCloseFunction(flag){
              setOpenCustomStandards(flag);
              setOpenCustomStandardsOriginalRelatedHeight(relatedDivOriginalHeight);
              //setOpenOrderNumber(openOrderNumber-1);
              setOpenCustomStandardsOrder(0);
              closeLayersFuction(4);
            } 
            function setOpenRiskHierarchyCloseFunction(flag){
              setOpenRiskHierarchy(flag);
              setOpenRiskHierarchyOriginalRelatedHeight(relatedDivOriginalHeight);
              //setOpenOrderNumber(openOrderNumber-1);
              setOpenRiskHierarchyOrder(0);
              closeLayersFuction(5);
            } 
            function setCentreMaintenanceCloseFunction(flag){
              setCentreMaintenance(flag);
              setOpenCentreMaintenanceOriginalRelatedHeight(relatedDivOriginalHeight);
              //setOpenOrderNumber(openOrderNumber-1);
              setOpenCentreMaintenanceOrder(0);
              closeLayersFuction(6);
            } 
            function setOpensliderMethodsCloseFunction(flag){
              setOpensliderMethods(flag);
              setOpensliderMethodsOriginalRelatedHeight(relatedDivOriginalHeight);
              //setOpenOrderNumber(openOrderNumber-1);
              setOpensliderMethodsOrder(0);
              closeLayersFuction(7);
            } 
            function setOpenAddColorCloseFunction(flag){
              setOpenAddColor(flag);
              setOpenAddColorOriginalRelatedHeight(relatedDivOriginalHeight);
              //setOpenOrderNumber(openOrderNumber-1);
              setOpenAddColorOrder(0);
              closeLayersFuction(8);
            }       
            function setOpenTransferQuestionnaireCloseFunction(flag){
              setOpenTransferQuestionnaire(flag);
              setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivOriginalHeight);
              //setOpenOrderNumber(openOrderNumber-1);
              setOpenTransferQuestionnaireOrder(0);
              closeLayersFuction(9);
            }
            
            function setOpenAddQuestionCloseFunction(flag){
              setOpenAddQuestion(flag);
              setOpenAddQuestionOriginalRelatedHeight(relatedDivOriginalHeight);
              //setOpenOrderNumber(openOrderNumber-1);
              setOpenAddQuestionOrder(0);
              closeLayersFuction(11);
            }    
            
            function setOpenAnswerScaleCloseFunction(flag){
              setOpenAnswerScale(flag);
              setOpenAnswerScaleOriginalRelatedHeight(relatedDivOriginalHeight);
              //setOpenOrderNumber(openOrderNumber-1);
              setOpenAnswerScaleOrder(0);
              closeLayersFuction(12);
            } 
      
      


            const [anchorEl, setAnchorEl] = React.useState(null);
            const opens = Boolean(anchorEl);
            const handleClick = (event) => {
              setAnchorEl(event.currentTarget);
            };
            const handleCloseMunu = () => {
              setAnchorEl(null);
            };


            function divActiveFuction(divId){
        
                if (divId === "divOpenComment") {
                  var maxZIndex = findMaxZIndex();
                  setOpenCommentZIndex(maxZIndex + 1);
                }
        
                if (divId === "divOpenRiskCollection") {
                  var maxZIndex = findMaxZIndex();
                  setOpenRiskCollectionZIndex(maxZIndex + 1);
                }
                if (divId === "divOpenCollectionData") {
                  var maxZIndex = findMaxZIndex();
                  setOpenCollectionDataZIndex(maxZIndex + 1);
                }
                if (divId === "divOpenRiskHierarchy") {
                  var maxZIndex = findMaxZIndex();
                  if (maxZIndex != openRiskHierarchyZIndexPrevious){
                    setOpenRiskHierarchyZIndexPrevious(maxZIndex + 1)
                    setOpenRiskHierarchyZIndex(maxZIndex + 1);
                  }
                }
                if (divId === "divOpenGlobalStandard") {
                  var maxZIndex = findMaxZIndex();
                  setOpenGlobalStandardZIndex(maxZIndex + 1);
                }
                if (divId === "divOpenCreateNewCollection") {
                  var maxZIndex = findMaxZIndex();
                  if (maxZIndex != openCreateNewCollectionZIndexPrevious){
                    setOpenCreateNewCollectionZIndex(maxZIndex + 1);
                    setOpenCreateNewCollectionZIndexPrevious(maxZIndex + 1);
                  } 
                }
                if (divId === "divOpenEditCollection") {
                  var maxZIndex = findMaxZIndex();
                  if (maxZIndex != openEditCollectionZIndexPrevious){
                    setOpenEditCollectionZIndex(maxZIndex + 1);
                    setOpenEditCollectionZIndexPrevious(maxZIndex + 1);
                  }  

                }
                if (divId === "divOpenAddColor") {
                  var maxZIndex = findMaxZIndex();
                  setOpenAddColorZIndex(maxZIndex + 1);
                }
                if (divId === "divOpenSliderMethods") {
                  var maxZIndex = findMaxZIndex();
                  setOpenSliderMethodsZIndex(maxZIndex + 1);
                }
                if (divId === "divOpenCustomStandards") {
                  var maxZIndex = findMaxZIndex();
                  setOpenCustomStandardsZIndex(maxZIndex + 1);
                }
                if (divId === "divOpenCentreMaintenance") {
                  var maxZIndex = findMaxZIndex();
                  setOpenCentreMaintenanceZIndex(maxZIndex + 1);
                }
        
                if (divId === "divOpenTransferQuestionnaire") {
                  var maxZIndex = findMaxZIndex();
                  setOpenTransferQuestionnaireZIndex(maxZIndex + 1);
                }

                if (divId === "divOpenAddQuestion") {
                  var maxZIndex = findMaxZIndex();
                  setOpenAddQuestionZIndex(maxZIndex + 1);
                }
                if (divId === "divOpenAnswerScale") {
                  var maxZIndex = findMaxZIndex();
                  setOpenAnswerScaleZIndex(maxZIndex + 1);
                }
        
        
              }
        
              function findMaxZIndex(){
                var maxZIndex = 1;
                
                maxZIndex = openCommentZIndex;
                if (openRiskCollectionZIndex > maxZIndex){
                  maxZIndex = openRiskCollectionZIndex;
                }
                if (openCollectionDataZIndex > maxZIndex){
                  maxZIndex = openCollectionDataZIndex;
                }
                if (openRiskHierarchyZIndex > maxZIndex){
                  maxZIndex = openRiskHierarchyZIndex;
                }
                if (openGlobalStandardZIndex > maxZIndex){
                  maxZIndex = openGlobalStandardZIndex;
                }
                if (openCreateNewCollectionZIndex > maxZIndex){
                  maxZIndex = openCreateNewCollectionZIndex;
                }
                if (openEditCollectionZIndex > maxZIndex){
                  maxZIndex = openEditCollectionZIndex;
                }
                if (openAddColorZIndex > maxZIndex){
                  maxZIndex = openAddColorZIndex;
                }
                if (openSliderMethodsZIndex > maxZIndex){
                  maxZIndex = openSliderMethodsZIndex;
                }
                if (openCustomStandardsZIndex > maxZIndex){
                  maxZIndex = openCustomStandardsZIndex;
                }
                if (openCentreMaintenanceZIndex > maxZIndex){
                  maxZIndex = openCentreMaintenanceZIndex;
                }
                if (openTransferQuestionnaireZIndex > maxZIndex){
                  maxZIndex = openTransferQuestionnaireZIndex;
                }
                if (openAddQuestionZIndex > maxZIndex){
                  maxZIndex = openAddQuestionZIndex;
                }
                if (openAnswerScaleZIndex > maxZIndex){
                  maxZIndex = openAnswerScaleZIndex;
                }
        
                return maxZIndex;
        
              }
        
        
              //rezite divs
        
              var height = '750';
              var width = '1150'
        
        
              const [openRiskHierarchyOriginalWidth,setOpenRiskHierarchyOriginalWidth] = useState(width);
              const [openRiskHierarchyOriginalHeight,setOpenRiskHierarchyOriginalHeight] = useState(height);
        
              const [openGlobalStandardOriginalWidth,setOpenGlobalStandardOriginalWidth] = useState(width);
              const [openGlobalStandardOriginalHeight,setOpenGlobalStandardOriginalHeight] = useState(height);
        
              const [openCreateNewCollectionOriginalWidth,setOpenCreateNewCollectionOriginalWidth] = useState(width);
              const [openCreateNewCollectionOriginalHeight,setOpenCreateNewCollectionOriginalHeight] = useState(height);
        
              const [openCentreMaintenanceOriginalWidth,setOpenCentreMaintenanceOriginalWidth] = useState(width);
              const [openCentreMaintenanceOriginalHeight,setOpenCentreMaintenanceOriginalHeight] = useState(height);
        
              const [opensliderMethodsOriginalWidth,setOpensliderMethodsOriginalWidth] = useState(width);
              const [opensliderMethodsOriginalHeight,setOpensliderMethodsOriginalHeight] = useState(height);
        
              const [openAddColorOriginalWidth,setOpenAddColorOriginalWidth] = useState(width);
              const [openAddColorOriginalHeight,setOpenAddColorOriginalHeight] = useState(height);	
        
              const [openCustomStandardsOriginalWidth,setOpenCustomStandardsOriginalWidth] = useState(width);
              const [openCustomStandardsOriginalHeight,setOpenCustomStandardsOriginalHeight] = useState(height);
        
              const [openEditCollectionOriginalWidth,setOpenEditCollectionOriginalWidth] = useState(width);
              const [openEditCollectionOriginalHeight,setOpenEditCollectionOriginalHeight] = useState(height);
        
              const [openRiskCollectionOriginalWidth,setOpenRiskCollectionOriginalWidth] = useState(width);
              const [openRiskCollectionOriginalHeight,setOpenRiskCollectionOriginalHeight] = useState(height);
        
              const [openTransferQuestionnaireOriginalWidth,setOpenTransferQuestionnaireOriginalWidth] = useState(width);
              const [openTransferQuestionnaireOriginalHeight,setOpenTransferQuestionnaireOriginalHeight] = useState(height);

              const [openAddQuestionOriginalWidth,setOpenAddQuestionOriginalWidth] = useState(width);
              const [openAddQuestionOriginalHeight,setOpenAddQuestionOriginalHeight] = useState(height);
              const [openAddQuestionOriginalRelatedHeight,setOpenAddQuestionOriginalRelatedHeight] = useState(relatedDivOriginalHeight);


              const [openAnswerScaleOriginalWidth,setOpenAnswerScaleOriginalWidth] = useState(width);
              const [openAnswerScaleOriginalHeight,setOpenAnswerScaleOriginalHeight] = useState(height);
              const [openAnswerScaleOriginalRelatedHeight,setOpenAnswerScaleOriginalRelatedHeight] = useState(relatedDivOriginalHeight);


        
              const [openCommentOriginalWidth,setOpenCommentOriginalWidth] = useState(width);
              const [openCommentOriginalHeight,setOpenCommentOriginalHeight] = useState(height);
              const [openCommentOriginalLeft,setOpenCommentOriginalLeft] = useState(0);
        
              
        
              function divPlusFuction(divId){
        
                if (divId === 'divOpenComment'){
                  var obj = document.getElementById("divOpenComment");
                  if (openCommentOriginalWidth != 0){ 
                    var oldWidth = openCommentOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenCommentOriginalWidth(newWidth);
                      setOpenCommentOriginalLeft(openCommentOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openCommentOriginalHeight != 0){       
                        setOpenCommentOriginalHeight(openCommentOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
        
        
        
                if (divId === 'divOpenRiskHierarchy'){
                  var obj = document.getElementById("divOpenRiskHierarchy");
                  if (openRiskHierarchyOriginalWidth != 0){ 
                    var oldWidth = openRiskHierarchyOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenRiskHierarchyOriginalWidth(newWidth);
                      setOpenRiskHierarchyOriginalLeft(openRiskHierarchyOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openRiskHierarchyOriginalHeight != 0){       
                        setOpenRiskHierarchyOriginalHeight(openRiskHierarchyOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
              }
              if (divId === 'divOpenCreateNewCollection'){
                var obj = document.getElementById("divOpenRiskHierarchy");
                if (openCreateNewCollectionOriginalWidth != 0){ 
                  var oldWidth = openCreateNewCollectionOriginalWidth.toString();
                  var newWidth = oldWidth * 110/100;
                  if (((newWidth/width) * 100) < 180 ){
                    setOpenCreateNewCollectionOriginalWidth(newWidth);
                    setOpenCreateNewCollectionOriginalLeft(openCreateNewCollectionOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openRiskHierarchyOriginalHeight != 0){       
                      setOpenCreateNewCollectionOriginalHeight(openCreateNewCollectionOriginalHeight.toString() * 110/100)
                    }
                  }
                }
            }
        
            if (divId === 'divOpenGlobalStandard'){
              if (openGlobalStandardOriginalWidth != 0){ 
                var oldWidth = openGlobalStandardOriginalWidth.toString();
                var newWidth = oldWidth * 110/100;
                if (((newWidth/width) * 100) < 180 ){
                  setOpenGlobalStandardOriginalWidth(newWidth);
                  setOpenGlobalStandardOriginalLeft(openGlobalStandardOriginalLeft + (oldWidth - newWidth)/2);  
                  if (openGlobalStandardOriginalHeight != 0){       
                    setOpenGlobalStandardOriginalHeight(openGlobalStandardOriginalHeight.toString() * 110/100)
                  }
                }
              }
            }
              if (divId === 'divOpenCustomStandards'){
                    
                if (openCustomStandardsOriginalWidth != 0){ 
                  var oldWidth = openCustomStandardsOriginalWidth.toString();
                  var newWidth = oldWidth * 110/100;
                  if (((newWidth/width) * 100) < 180 ){
                    setOpenCustomStandardsOriginalWidth(newWidth);
                    setOpenCustomStandardsOriginalLeft(openCustomStandardsOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openCustomStandardsOriginalHeight != 0){       
                      setOpenCustomStandardsOriginalHeight(openCustomStandardsOriginalHeight.toString() * 110/100)
                    }
                  }
                }
            }
        
            if (divId === 'divOpenCentreMaintenance'){
                    
              if (openCentreMaintenanceOriginalWidth != 0){ 
                var oldWidth = openCentreMaintenanceOriginalWidth.toString();
                var newWidth = oldWidth * 110/100;
                if (((newWidth/width) * 100) < 180 ){
                  setOpenCentreMaintenanceOriginalWidth(newWidth);
                  setOpenCentreMaintenanceOriginalLeft(openCentreMaintenanceOriginalLeft + (oldWidth - newWidth)/2);  
                  if (openCentreMaintenanceOriginalHeight != 0){       
                    setOpenCentreMaintenanceOriginalHeight(openCentreMaintenanceOriginalHeight.toString() * 110/100)
                  }
                }
              }
            }
        
              if (divId === 'divOpenSliderMethods'){
                          
                if (opensliderMethodsOriginalWidth != 0){ 
                  var oldWidth = opensliderMethodsOriginalWidth.toString();
                  var newWidth = oldWidth * 110/100;
                  if (((newWidth/width) * 100) < 180 ){
                    setOpensliderMethodsOriginalWidth(newWidth);
                    setOpensliderMethodsOriginalLeft(opensliderMethodsOriginalLeft + (oldWidth - newWidth)/2);  
                    if (opensliderMethodsOriginalHeight != 0){       
                      setOpensliderMethodsOriginalHeight(opensliderMethodsOriginalHeight.toString() * 110/100)
                    }
                  }
                }
            }
        
            if (divId === 'divOpenAddColor'){
                          
              if (openAddColorOriginalWidth != 0){ 
                var oldWidth = openAddColorOriginalWidth.toString();
                var newWidth = oldWidth * 110/100;
                if (((newWidth/width) * 100) < 180 ){
                  setOpenAddColorOriginalWidth(newWidth);
                  setOpenAddColorOriginalLeft(openAddColorOriginalLeft + (oldWidth - newWidth)/2);  
                  if (openAddColorOriginalHeight != 0){       
                    setOpenAddColorOriginalHeight(openAddColorOriginalHeight.toString() * 110/100)
                  }
                }
              }
            }
              if (divId === 'divOpenEditCollection'){
                if (openEditCollectionOriginalWidth != 0){ 
                  var oldWidth = openEditCollectionOriginalWidth.toString();
                  var newWidth = oldWidth * 110/100;
                  if (((newWidth/width) * 100) < 180 ){
                    setOpenEditCollectionOriginalWidth(newWidth);
                    setOpenEditCollectionOriginalLeft(openEditCollectionOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openEditCollectionOriginalHeight != 0){       
                      setOpenEditCollectionOriginalHeight(openEditCollectionOriginalHeight.toString() * 110/100)
                    }
                  }
                }
              }
        
              if (divId === 'divOpenTransferQuestionnaire'){
                            
                if (openTransferQuestionnaireOriginalWidth != 0){ 
                  var oldWidth = openTransferQuestionnaireOriginalWidth.toString();
                  var newWidth = oldWidth * 110/100;
                  if (((newWidth/width) * 100) < 180 ){
                    setOpenTransferQuestionnaireOriginalWidth(newWidth);
                    setOpenTransferQuestionnaireOriginalLeft(openTransferQuestionnaireOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openTransferQuestionnaireOriginalHeight != 0){       
                      setOpenTransferQuestionnaireOriginalHeight(openTransferQuestionnaireOriginalHeight.toString() * 110/100)
                    }
                  }
                }
              }

              if (divId === 'divOpenAddQuestion'){
                if (openAddQuestionOriginalWidth != 0){ 
                  var oldWidth = openAddQuestionOriginalWidth.toString();
                  var newWidth = oldWidth * 110/100;
                  if (((newWidth/width) * 100) < 180 ){
                    setOpenAddQuestionOriginalWidth(newWidth);
                    setOpenAddQuestionOriginalLeft(openAddQuestionOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openAddQuestionOriginalHeight != 0){       
                      setOpenAddQuestionOriginalHeight(openAddQuestionOriginalHeight.toString() * 110/100)
                    }
                  }
                }
              }

              if (divId === 'divOpenAnswerScale'){
                if (openAnswerScaleOriginalWidth != 0){ 
                  var oldWidth = openAnswerScaleOriginalWidth.toString();
                  var newWidth = oldWidth * 110/100;
                  if (((newWidth/width) * 100) < 180 ){
                    setOpenAnswerScaleOriginalWidth(newWidth);
                    setOpenAnswerScaleOriginalLeft(openAnswerScaleOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openAnswerScaleOriginalHeight != 0){       
                      setOpenAnswerScaleOriginalHeight(openAnswerScaleOriginalHeight.toString() * 110/100)
                    }
                  }
                }
              }
        
              if (divId === 'divOpenRiskCollection'){
                if (openRiskCollectionOriginalWidth != 0){ 
                  var oldWidth = openRiskCollectionOriginalWidth.toString();
                  var newWidth = oldWidth * 110/100;
                  if (((newWidth/width) * 100) < 180 ){
                    setOpenRiskCollectionOriginalWidth(newWidth);
                    setOpenRiskCollectionOriginalLeft(openRiskCollectionOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openRiskCollectionOriginalHeight != 0){       
                      setOpenRiskCollectionOriginalHeight(openRiskCollectionOriginalHeight.toString() * 110/100)
                    }
                  }
                }
              }
        
              if(openRiskCollection === true){
                if (divId === undefined){
                  if (openRiskCollectionOriginalWidth != 0){ 
                    var oldWidth = openRiskCollectionOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenRiskCollectionOriginalWidth(newWidth);
                      setOpenRiskCollectionOriginalLeft(openRiskCollectionOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openRiskCollectionOriginalHeight != 0){       
                        setOpenRiskCollectionOriginalHeight(openRiskCollectionOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
              }
              if(openCreateNewCollection === true){
                if (divId === undefined){
                  if (openCreateNewCollectionOriginalWidth != 0){ 
                    var oldWidth = openCreateNewCollectionOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenCreateNewCollectionOriginalWidth(newWidth);
                      setOpenCreateNewCollectionOriginalLeft(openCreateNewCollectionOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openRiskHierarchyOriginalHeight != 0){       
                        setOpenCreateNewCollectionOriginalHeight(openCreateNewCollectionOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
              }
              if(openGlobalStandard === true){
                if (divId === undefined){
                  if (openGlobalStandardOriginalWidth != 0){ 
                    var oldWidth = openGlobalStandardOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenGlobalStandardOriginalWidth(newWidth);
                      setOpenGlobalStandardOriginalLeft(openGlobalStandardOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openGlobalStandardOriginalHeight != 0){       
                        setOpenGlobalStandardOriginalHeight(openGlobalStandardOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
              }
              if(openCustomStandards === true){
                if (divId === undefined){
                  if (openCustomStandardsOriginalWidth != 0){ 
                    var oldWidth = openCustomStandardsOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenCustomStandardsOriginalWidth(newWidth);
                      setOpenCustomStandardsOriginalLeft(openCustomStandardsOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openCustomStandardsOriginalHeight != 0){       
                        setOpenCustomStandardsOriginalHeight(openCustomStandardsOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
              }
              if(openRiskHierarchy === true){
                if (divId === undefined){
                  if (openRiskHierarchyOriginalWidth != 0){ 
                    var oldWidth = openRiskHierarchyOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenRiskHierarchyOriginalWidth(newWidth);
                      setOpenRiskHierarchyOriginalLeft(openRiskHierarchyOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openRiskHierarchyOriginalHeight != 0){       
                        setOpenRiskHierarchyOriginalHeight(openRiskHierarchyOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
              }
        
              if(centreMaintenance === true){
                if (divId === undefined){
                  if (openCentreMaintenanceOriginalWidth != 0){ 
                    var oldWidth = openCentreMaintenanceOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenCentreMaintenanceOriginalWidth(newWidth);
                      setOpenCentreMaintenanceOriginalLeft(openCentreMaintenanceOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openCentreMaintenanceOriginalHeight != 0){       
                        setOpenCentreMaintenanceOriginalHeight(openCentreMaintenanceOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
              }
              if(opensliderMethods === true){
                if (divId === undefined){
                  if (opensliderMethodsOriginalWidth != 0){ 
                    var oldWidth = opensliderMethodsOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpensliderMethodsOriginalWidth(newWidth);
                      setOpensliderMethodsOriginalLeft(opensliderMethodsOriginalLeft + (oldWidth - newWidth)/2);  
                      if (opensliderMethodsOriginalHeight != 0){       
                        setOpensliderMethodsOriginalHeight(opensliderMethodsOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
              }
              if(openAddColor === true){
                if (divId === undefined){
                  if (openAddColorOriginalWidth != 0){ 
                    var oldWidth = openAddColorOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenAddColorOriginalWidth(newWidth);
                      setOpenAddColorOriginalLeft(openAddColorOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openAddColorOriginalHeight != 0){       
                        setOpenAddColorOriginalHeight(openAddColorOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
              }
              if(openEditCollection === true){
                if (divId === undefined){
                  if (openEditCollectionOriginalWidth != 0){ 
                    var oldWidth = openEditCollectionOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenEditCollectionOriginalWidth(newWidth);
                      setOpenEditCollectionOriginalLeft(openEditCollectionOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openEditCollectionOriginalHeight != 0){       
                        setOpenEditCollectionOriginalHeight(openEditCollectionOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
              }
        
              if(openTransferQuestionnaire === true){
                if (divId === undefined){
                  if (openTransferQuestionnaireOriginalWidth != 0){ 
                    var oldWidth = openTransferQuestionnaireOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenTransferQuestionnaireOriginalWidth(newWidth);
                      setOpenTransferQuestionnaireOriginalLeft(openTransferQuestionnaireOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openTransferQuestionnaireOriginalHeight != 0){       
                        setOpenTransferQuestionnaireOriginalHeight(openTransferQuestionnaireOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
              }  
              
              if(openAddQuestion === true){
                if (divId === undefined){
                  if (openAddQuestionOriginalWidth != 0){ 
                    var oldWidth = openAddQuestionOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenAddQuestionOriginalWidth(newWidth);
                      setOpenAddQuestionOriginalLeft(openAddQuestionOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openAddQuestionOriginalHeight != 0){       
                        setOpenAddQuestionOriginalHeight(openAddQuestionOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
              }


              if(openAnswerScale === true){
                if (divId === undefined){
                  if (openAnswerScaleOriginalWidth != 0){ 
                    var oldWidth = openAnswerScaleOriginalWidth.toString();
                    var newWidth = oldWidth * 110/100;
                    if (((newWidth/width) * 100) < 180 ){
                      setOpenAnswerScaleOriginalWidth(newWidth);
                      setOpenAnswerScaleOriginalLeft(openAnswerScaleOriginalLeft + (oldWidth - newWidth)/2);  
                      if (openAnswerScaleOriginalHeight != 0){       
                        setOpenAnswerScaleOriginalHeight(openAnswerScaleOriginalHeight.toString() * 110/100)
                      }
                    }
                  }
                }
              }
            }
        
        
            function divMinusFuction(divId){
        
              if (divId === 'divOpenComment'){
                var obj = document.getElementById("divOpenComment");
                if (openCommentOriginalWidth != 0){ 
                  var oldWidth = openCommentOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpenCommentOriginalWidth(newWidth);
                    setOpenCommentOriginalLeft(openCommentOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openCommentOriginalHeight != 0){       
                      setOpenCommentOriginalHeight(openCommentOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
              
              if (divId === 'divOpenRiskHierarchy'){
                var obj = document.getElementById("divOpenRiskHierarchy");
                if (openRiskHierarchyOriginalWidth != 0){ 
                  var oldWidth = openRiskHierarchyOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpenRiskHierarchyOriginalWidth(newWidth);
                    setOpenRiskHierarchyOriginalLeft(openRiskHierarchyOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openRiskHierarchyOriginalHeight != 0){       
                      setOpenRiskHierarchyOriginalHeight(openRiskHierarchyOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
      
            if (divId === 'divOpenCreateNewCollection'){
              var obj = document.getElementById("divOpenRiskHierarchy");
              if (openCreateNewCollectionOriginalWidth != 0){ 
                var oldWidth = openCreateNewCollectionOriginalWidth.toString();
                var newWidth = oldWidth * 90/100;
                if (((newWidth/width) * 100) > 30 ){
                  setOpenCreateNewCollectionOriginalWidth(newWidth);
                  setOpenCreateNewCollectionOriginalLeft(openCreateNewCollectionOriginalLeft + (oldWidth - newWidth)/2);  
                  if (openCreateNewCollectionOriginalHeight != 0){       
                    setOpenCreateNewCollectionOriginalHeight(openCreateNewCollectionOriginalHeight.toString() * 90/100)
                  }
                }
              }
          }
          if (divId === 'divOpenGlobalStandard'){
            
            if (openGlobalStandardOriginalWidth != 0){ 
              var oldWidth = openGlobalStandardOriginalWidth.toString();
              var newWidth = oldWidth * 90/100;
              if (((newWidth/width) * 100) > 30 ){
                setOpenGlobalStandardOriginalWidth(newWidth);
                setOpenGlobalStandardOriginalLeft(openGlobalStandardOriginalLeft + (oldWidth - newWidth)/2);  
                if (openGlobalStandardOriginalHeight != 0){       
                  setOpenGlobalStandardOriginalHeight(openGlobalStandardOriginalHeight.toString() * 90/100)
                }
              }
            }
          }
          if (divId === 'divOpenCustomStandards'){
              
            if (openCustomStandardsOriginalWidth != 0){ 
              var oldWidth = openCustomStandardsOriginalWidth.toString();
              var newWidth = oldWidth * 90/100;
              if (((newWidth/width) * 100) > 30 ){
                setOpenCustomStandardsOriginalWidth(newWidth);
                setOpenCustomStandardsOriginalLeft(openCustomStandardsOriginalLeft + (oldWidth - newWidth)/2);  
                if (openCustomStandardsOriginalHeight != 0){       
                  setOpenCustomStandardsOriginalHeight(openCustomStandardsOriginalHeight.toString() * 90/100)
                }
              }
            }
        }
          if (divId === 'divOpenCentreMaintenance'){
                    
            if (openCentreMaintenanceOriginalWidth != 0){ 
              var oldWidth = openCentreMaintenanceOriginalWidth.toString();
              var newWidth = oldWidth * 90/100;
              if (((newWidth/width) * 100) > 30 ){
                setOpenCentreMaintenanceOriginalWidth(newWidth);
                setOpenCentreMaintenanceOriginalLeft(openCentreMaintenanceOriginalLeft + (oldWidth - newWidth)/2);  
                if (openCentreMaintenanceOriginalHeight != 0){       
                  setOpenCentreMaintenanceOriginalHeight(openCentreMaintenanceOriginalHeight.toString() * 90/100)
                }
              }
            }
        }
      
        if (divId === 'divOpenSliderMethods'){
                    
          if (opensliderMethodsOriginalWidth != 0){ 
            var oldWidth = opensliderMethodsOriginalWidth.toString();
            var newWidth = oldWidth * 90/100;
            if (((newWidth/width) * 100) > 30 ){
              setOpensliderMethodsOriginalWidth(newWidth);
              setOpensliderMethodsOriginalLeft(opensliderMethodsOriginalLeft + (oldWidth - newWidth)/2);  
              if (opensliderMethodsOriginalHeight != 0){       
                setOpensliderMethodsOriginalHeight(opensliderMethodsOriginalHeight.toString() * 90/100)
              }
            }
          }
        }
      
          if (divId === 'divOpenAddColor'){
                        
            if (openAddColorOriginalWidth != 0){ 
              var oldWidth = openAddColorOriginalWidth.toString();
              var newWidth = oldWidth * 90/100;
              if (((newWidth/width) * 100) > 30 ){
                setOpenAddColorOriginalWidth(newWidth);
                setOpenAddColorOriginalLeft(openAddColorOriginalLeft + (oldWidth - newWidth)/2);  
                if (openAddColorOriginalHeight != 0){       
                  setOpenAddColorOriginalHeight(openAddColorOriginalHeight.toString() * 90/100)
                }
              }
            }
          }
      
          if (divId === 'divOpenEditCollection'){
                          
            if (openEditCollectionOriginalWidth != 0){ 
              var oldWidth = openEditCollectionOriginalWidth.toString();
              var newWidth = oldWidth * 90/100;
              if (((newWidth/width) * 100) > 30 ){
                setOpenEditCollectionOriginalWidth(newWidth);
                setOpenEditCollectionOriginalLeft(openEditCollectionOriginalLeft + (oldWidth - newWidth)/2);  
                if (openEditCollectionOriginalHeight != 0){       
                  setOpenEditCollectionOriginalHeight(openEditCollectionOriginalHeight.toString() * 90/100)
                }
              }
            }
          }
      
      
          if (divId === 'divOpenRiskCollection'){
            
            if (openRiskCollectionOriginalWidth != 0){ 
              var oldWidth = openRiskCollectionOriginalWidth.toString();
              var newWidth = oldWidth * 90/100;
              if (((newWidth/width) * 100) > 30 ){
                setOpenRiskCollectionOriginalWidth(newWidth);
                setOpenRiskCollectionOriginalLeft(openRiskCollectionOriginalLeft + (oldWidth - newWidth)/2);  
                if (openRiskCollectionOriginalHeight != 0){       
                  setOpenRiskCollectionOriginalHeight(openRiskCollectionOriginalHeight.toString() * 90/100)
                }
              }
            }
      
          }
      
      
          if (divId === 'divOpenTransferQuestionnaire'){
                          
            if (openTransferQuestionnaireOriginalWidth != 0){ 
              var oldWidth = openTransferQuestionnaireOriginalWidth.toString();
              var newWidth = oldWidth * 90/100;
              if (((newWidth/width) * 100) > 30 ){
                setOpenTransferQuestionnaireOriginalWidth(newWidth);
                setOpenTransferQuestionnaireOriginalLeft(openTransferQuestionnaireOriginalLeft + (oldWidth - newWidth)/2);  
                if (openTransferQuestionnaireOriginalHeight != 0){       
                  setOpenTransferQuestionnaireOriginalHeight(openTransferQuestionnaireOriginalHeight.toString() * 90/100)
                }
              }
            }
          }

          if (divId === 'divAddQuestion'){
                    
            if (openAddQuestionOriginalWidth != 0){ 
              var oldWidth = openAddQuestionOriginalWidth.toString();
              var newWidth = oldWidth * 90/100;
              if (((newWidth/width) * 100) > 30 ){
                setOpenAddQuestionOriginalWidth(newWidth);
                setOpenAddQuestionOriginalLeft(openAddQuestionOriginalLeft + (oldWidth - newWidth)/2);  
                if (openAddQuestionOriginalHeight != 0){       
                  setOpenAddQuestionOriginalHeight(openAddQuestionOriginalHeight.toString() * 90/100)
                }
              }
            }
          }

          if (divId === 'divAnswerScale'){
                    
            if (openAnswerScaleOriginalWidth != 0){ 
              var oldWidth = openAnswerScaleOriginalWidth.toString();
              var newWidth = oldWidth * 90/100;
              if (((newWidth/width) * 100) > 30 ){
                setOpenAnswerScaleOriginalWidth(newWidth);
                setOpenAnswerScaleOriginalLeft(openAnswerScaleOriginalLeft + (oldWidth - newWidth)/2);  
                if (openAnswerScaleOriginalHeight != 0){       
                  setOpenAnswerScaleOriginalHeight(openAnswerScaleOriginalHeight.toString() * 90/100)
                }
              }
            }
          }
      
      
             if(openRiskCollection === true){
              if (divId === undefined){
                var oldWidth = openRiskCollectionOriginalWidth.toString();
                var newWidth = oldWidth * 90/100;
                if (((newWidth/width) * 100) > 30 ){
                  setOpenRiskCollectionOriginalWidth(newWidth);
                  setOpenRiskCollectionOriginalLeft(openRiskCollectionOriginalLeft + (oldWidth - newWidth)/2);  
                  if (openRiskCollectionOriginalHeight != 0){       
                    setOpenRiskCollectionOriginalHeight(openRiskCollectionOriginalHeight.toString() * 90/100)
                  }
                }
              }
            }
            if(openCreateNewCollection === true){
              if (divId === undefined){
                if (openCreateNewCollectionOriginalWidth != 0){ 
                  var oldWidth = openCreateNewCollectionOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpenCreateNewCollectionOriginalWidth(newWidth);
                    setOpenCreateNewCollectionOriginalLeft(openCreateNewCollectionOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openCreateNewCollectionOriginalHeight != 0){       
                      setOpenCreateNewCollectionOriginalHeight(openCreateNewCollectionOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
            }
            if(openGlobalStandard === true){
              if (divId === undefined){
                if (openGlobalStandardOriginalWidth != 0){ 
                  var oldWidth = openGlobalStandardOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpenGlobalStandardOriginalWidth(newWidth);
                    setOpenGlobalStandardOriginalLeft(openGlobalStandardOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openGlobalStandardOriginalHeight != 0){       
                      setOpenGlobalStandardOriginalHeight(openGlobalStandardOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
            }
            if(openCustomStandards === true){
              if (divId === undefined){
                if (openCustomStandardsOriginalWidth != 0){ 
                  var oldWidth = openCustomStandardsOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpenCustomStandardsOriginalWidth(newWidth);
                    setOpenCustomStandardsOriginalLeft(openCustomStandardsOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openCustomStandardsOriginalHeight != 0){       
                      setOpenCustomStandardsOriginalHeight(openCustomStandardsOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
            }
            if(openRiskHierarchy === true){
              if (divId === undefined){
                if (openRiskHierarchyOriginalWidth != 0){ 
                  var oldWidth = openRiskHierarchyOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpenRiskHierarchyOriginalWidth(newWidth);
                    setOpenRiskHierarchyOriginalLeft(openRiskHierarchyOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openRiskHierarchyOriginalHeight != 0){       
                      setOpenRiskHierarchyOriginalHeight(openRiskHierarchyOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
            }
      
            if(centreMaintenance === true){
              if (divId === undefined){
                if (openCentreMaintenanceOriginalWidth != 0){ 
                  var oldWidth = openCentreMaintenanceOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpenCentreMaintenanceOriginalWidth(newWidth);
                    setOpenCentreMaintenanceOriginalLeft(openCentreMaintenanceOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openCentreMaintenanceOriginalHeight != 0){       
                      setOpenCentreMaintenanceOriginalHeight(openCentreMaintenanceOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
            }
            if(opensliderMethods === true){
              if (divId === undefined){
                if (opensliderMethodsOriginalWidth != 0){ 
                  var oldWidth = opensliderMethodsOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpensliderMethodsOriginalWidth(newWidth);
                    setOpensliderMethodsOriginalLeft(opensliderMethodsOriginalLeft + (oldWidth - newWidth)/2);  
                    if (opensliderMethodsOriginalHeight != 0){       
                      setOpensliderMethodsOriginalHeight(opensliderMethodsOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
            }
            if(openAddColor === true){
              if (divId === undefined){
                if (openAddColorOriginalWidth != 0){ 
                  var oldWidth = openAddColorOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpenAddColorOriginalWidth(newWidth);
                    setOpenAddColorOriginalLeft(openAddColorOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openAddColorOriginalHeight != 0){       
                      setOpenAddColorOriginalHeight(openAddColorOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
            }
            if(openEditCollection === true){
              if (divId === undefined){
                if (openEditCollectionOriginalWidth != 0){ 
                  var oldWidth = openEditCollectionOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpenEditCollectionOriginalWidth(newWidth);
                    setOpenEditCollectionOriginalLeft(openEditCollectionOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openEditCollectionOriginalHeight != 0){       
                      setOpenEditCollectionOriginalHeight(openEditCollectionOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
            }
      
            if(openTransferQuestionnaire === true){
              if (divId === undefined){
                if (openTransferQuestionnaireOriginalWidth != 0){ 
                  var oldWidth = openTransferQuestionnaireOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpenTransferQuestionnaireOriginalWidth(newWidth);
                    setOpenTransferQuestionnaireOriginalLeft(openTransferQuestionnaireOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openTransferQuestionnaireOriginalHeight != 0){       
                      setOpenTransferQuestionnaireOriginalHeight(openTransferQuestionnaireOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
            }

            if(openAddQuestion === true){
              if (divId === undefined){
                if (openAddQuestionOriginalWidth != 0){ 
                  var oldWidth = openAddQuestionOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpenAddQuestionOriginalWidth(newWidth);
                    setOpenAddQuestionOriginalLeft(openAddQuestionOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openAddQuestionOriginalHeight != 0){       
                      setOpenAddQuestionOriginalHeight(openAddQuestionOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
            }

            if(openAnswerScale === true){
              if (divId === undefined){
                if (openAnswerScaleOriginalWidth != 0){ 
                  var oldWidth = openAnswerScaleOriginalWidth.toString();
                  var newWidth = oldWidth * 90/100;
                  if (((newWidth/width) * 100) > 30 ){
                    setOpenAnswerScaleOriginalWidth(newWidth);
                    setOpenAnswerScaleOriginalLeft(openAnswerScaleOriginalLeft + (oldWidth - newWidth)/2);  
                    if (openAnswerScaleOriginalHeight != 0){       
                      setOpenAnswerScaleOriginalHeight(openAnswerScaleOriginalHeight.toString() * 90/100)
                    }
                  }
                }
              }
            }
         
            }
        
              function closeDiv(divId){
                if (divId === "divOpenEditCollection") {
                  setOpenEditCollection(false);
                  enabledOtherDivs();
                }
              }


            //adding colors to slider

              function getSubColorCode(number) {

                alert(number)
                var cCode = "#ffffff";
                databClr.forEach((data)=> {
                  if (parseInt(number) === parseInt(data.scoreNumber)){
                    cCode = data.color;
                    alert(cCode)
                  }
                })
                return cCode;
              }
              
              function setRiskLvlEditFunction(val, min, max, type){
                var posNumber = val - min;
                var totalNumber = max - min;
                var percentageVal = (posNumber/totalNumber) * 100
                var cCode = getSubColorCode(val);
                var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
                
                setSliderValue(val);
                setSliderValueStyle(cssStr);
                
              }  


              function getSubColorCodeCloseEnded(number) {
                var cCode = "#ffffff";
                databClr.forEach((data)=> {
                  if (parseInt(number) === parseInt(data.scoreNumber)){
                    cCode = data.color;
                  }
                })
                return cCode;
              }
              
              function setRiskLvlEditFunctionCloseEnded(val, min, max, type){
                var posNumber = val - min;
                var totalNumber = max - min;
                var percentageVal = (posNumber/totalNumber) * 100
                var cCode = getSubColorCodeCloseEnded(val);
                var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
                
                setSliderValueCloseEnded(val);
                setSliderValueCloseEndedStyle(cssStr);
                
              }  


        // end of the slider malindu section 


        /** new variable for load question data */




        /** Show All Question Related the Collection Name */  
  
        const [rows, setRows] = useState([]);
        const [openAllQuestionData, setOpenAllQuestionData] = useState(false);
        const [openAllQuestionDataScroll, setOpenAllQuestionDataScroll] = useState('paper');

        function PaperComponentAllQuestionData(props) {
          return (
            <Draggable
              handle="#collection-data-dialog-title"
              cancel={'[class*="MuiDialogContent-root"]'}
            >
              <Paper {...props} />
            </Draggable>
          );
        }

        const renderEditButton = (params) => {
          return (
              <strong>
                  <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                          editQuestion(params.row.questionId,params.row.collectionUniqueName)
                      }}
                  >
                      Edit
                  </Button>
              </strong>
          )
      }
      function editQuestion(questionId, collectionUniqueName){
          let a= document.createElement('a');
          a.target= '_self';
          a.href= '/questioPage/' + collectionUniqueName + "?questionid=" + questionId;
          a.click();
      }
      
      
      const [selectedQuesData, setSelectedQuesData] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [quesDataCount, setQuesDataCount] = useState(0);
  const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
  const [questionDetailScroll, setQuestionDetailScroll] = React.useState('paper');

    function parseId(no,questionId, collectionId, question){
      var tmpArray = [];
      setQuesDataCount(no);
      setSelectedQuestion(question);
      quesData.forEach((item)=>{
        if (item.questionId === questionId) {
          tmpArray.push(item);
          setSelectedQuesData(tmpArray);
        }
      });

      setQuestionDetailOpen(true);
      setQuestionDetailScroll('paper');

    }
    
    const questionDetailHandleClickClose = () => {
      setQuestionDetailOpen(false);
    };
          
      
          const renderDetailsButton = (params) => {
            return (
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                            parseId(params.row.id,params.row.questionId,params.row.collectionId,params.row.question )
                        }}
                    >
                        More Info
                    </Button>
                </strong>
            )
        }
      
        const renderTextRiskName = (params) => {
          return (
              <Tooltip title={params.row.riskName}>
              <span className={classes.fontZise10}>{params.row.riskName}</span>        
              </Tooltip>
          )
        }
        const renderTextId2 = (params) => {
          return (
              <span className={classes.fontZise10}>{params.row.id}</span>        
          )
        }
        const renderTextObjectiveName = (params) => {
          return (
              <Tooltip title={params.row.objectiveName}>
              <span className={classes.fontZise10}>{params.row.objectiveName}</span>        
              </Tooltip>
          )
        }
        const renderTextComponentName = (params) => {
          return (
              <Tooltip title={params.row.componentName}>
              <span className={classes.fontZise10}>{params.row.componentName}</span>        
              </Tooltip>
          )
        }
        const renderTextPrincipalName = (params) => {
          return (
              <Tooltip title={params.row.principalName}>
              <span className={classes.fontZise10}>{params.row.principalName}</span>        
              </Tooltip>
          )
        }
        const renderTextQuestion2 = (params) => {
          return (
              <Tooltip title={params.row.question}>
              <span className={classes.fontZise10}>{params.row.question}</span>        
              </Tooltip>
          )
        }
        const renderTextEntity = (params) => {
          return (
              <Tooltip title={params.row.entityName}>
              <span className={classes.fontZise10}>{params.row.entityName}</span>        
              </Tooltip>
          )
        }
      
        const renderTextQuestionType2 = (params) => {
          return (
              <span className={classes.fontZise10}>{params.row.questionType}</span>        
          )
        }
        const renderTextQuestionScore = (params) => {
          var riskLvlColor = getRiskLvlColor(params.row.questionScore)
          return (
              <span className={classes.fontZise10} style={{color: riskLvlColor}}>{params.row.questionScore}</span>        
          )
        }
      

        const columns = [
          { field: 'id', headerName: 'No', renderCell: renderTextId2, resizable: true, width: 15 },
          { field: 'questionId', headerName: 'No', width: 15, hide: true },
          { field: 'collectionId', headerName: 'No', width: 15, hide: true },
          { field: 'collectionUniqueName', headerName: 'No', width: 15, hide: true },      
          { field: 'riskName', headerName: 'Risk', renderCell: renderTextRiskName, resizable: true, cellClassName: 'super-app-theme--cell', width: 180 },
          { field: 'objectiveName', headerName: 'Objective', renderCell: renderTextObjectiveName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
          { field: 'componentName', headerName: 'Component', renderCell: renderTextComponentName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
          { field: 'principalName', headerName: 'Principal', renderCell: renderTextPrincipalName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
          { field: 'entityName', headerName: 'Entity', renderCell: renderTextEntity, cellClassName: 'super-app-theme--cell', resizable: true, width: 80 },
          { field: 'question', headerName: 'Question', renderCell: renderTextQuestion2, cellClassName: 'super-app-theme--cell', resizable: true, width: 210 },
          { field: 'questionType', headerName: 'Type', renderCell: renderTextQuestionType2, resizable: true, width: 80 },
          { field: 'questionScore', headerName: 'Score', renderCell: renderTextQuestionScore, resizable: true, width: 50 },
          {
            field: 'action',
            headerName: 'More Info',
            sortable: false,
            renderCell: renderDetailsButton,
            disableClickEventBubbling: true,
          
          },
          {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            renderCell: renderEditButton,
            disableClickEventBubbling: true,
          
          }
        
        ];

        

        function RiskScrumb( riskName, collectionRiskId, riskTypeData ) {
    var rsTypeArr = [];
    riskTypeData.forEach((data)=>{
        if (data.collectionRiskId === collectionRiskId){
            rsTypeArr = data.riskTypes;
        }
    });  
    var riskScrumbPath  = "";
    rsTypeArr.forEach((item)=>{
        var pathStr = "";
        pathStr = getRiskScrumb(item,riskName);
        if (pathStr != undefined && pathStr != ""){
            riskScrumbPath = pathStr;
            return true;
        }
    });  
    return riskScrumbPath;
  }

  // *Risk Scrumb****
function getRiskScrumb(comment,riskName){
var riskScrumbPath = "";
if (riskName === comment.name){
    riskScrumbPath =  riskName;
}else{
    if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
        var riskPath = comment.name;
        var subRiskPath =  getSubRiskScrumb(comment.subRisks,riskName);
        if (subRiskPath != ""){
            riskScrumbPath = riskPath + subRiskPath;
        }
    }
}
return riskScrumbPath;
}
function getSubRiskScrumb(subData,riskName){
var tmpSubPath = "";
subData.forEach((item,index)=>{
    if (item.name === riskName){
        tmpSubPath += " --> " + item.name;
        return true; 
    }else{
        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
            var tmpSubPath1 = " --> " + item.name;
            var tmpSubSubPath1 = getSubRiskScrumb(item.subRisks,riskName);
            if (tmpSubSubPath1 != ""){
                tmpSubPath += tmpSubPath1 + tmpSubSubPath1;
            }
        }    
    }
});
return tmpSubPath;
}    


  function setRiskTypeEvalFuction() {
    var count = 1;
    var tmpArray = [];
    setRows([]);
    quesData.forEach((item)=>{
      var tmpObj = {};
      tmpObj["questionId"] = item.questionId;
      tmpObj["collectionId"] = selectedCollectionEvaluateId;
      tmpObj["collectionUniqueName"] = selectedCollectionEvaluateUniqueName;
      tmpObj["id"] = count;
      var riskScrubStr = RiskScrumb( item.riskTypes.name, item.riskTypes.collectionRiskId, riskTypeData )
      tmpObj["riskName"] = riskScrubStr;

      tmpObj["objectiveName"] = item.objective.name;
      tmpObj["componentName"] = item.component.name;
      tmpObj["principalName"] = item.principle.name;
      tmpObj["entityName"] = item.entity.name;
      tmpObj["question"] = item.question;
      tmpObj["questionType"] = item.questionType;
      tmpObj["questionScore"] = item.questionScore;
      tmpObj["questionData"] = item;
      tmpArray.push(tmpObj);
      setRows(tmpArray);
      count ++;
    })
    setOpenAllQuestionData(true);
    setOpenAllQuestionDataScroll('paper');
  }





  return (
    <>

        <div className={classes.pageTop}>
            <Grid container spacing={2} >
                {/** 
                <Grid item xs={12} className={classes.pageBodyStyle} id="topBtnId">
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        
                    <div role="presentation" onClick={handleClick}>
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/login" id='focusHomeLink'>
                          Home
                        </Link>
                        
                        <Typography color="text.primary">{headerName}</Typography>
                      </Breadcrumbs>
                    </div>
                    

                    <div >
                            <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <div style={{gap:'10px',display:'flex'}}>
          <div>
      <Avatar sx={{ width: 32, height: 32 }} style={{color:'black', cursor: 'pointer'}} onClick={() => divPlusFuction()}><AddCircleOutlineIcon /></Avatar>
      </div>
      <div>
        <Avatar sx={{ width: 32, height: 32 }} style={{color:'black', cursor: 'pointer'}} onClick={() => divMinusFuction()}><RemoveCircleOutlineIcon/></Avatar>
        </div>
        </div>
        <Tooltip title="Multitasking Menu">

          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={opens ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={opens ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }} style={{color:'black'}}><MoreVertIcon/></Avatar>
          </IconButton>
        </Tooltip>
        <Tooltip title="Reset Multitasking Menu">
        <IconButton
                                    onClick={()=>refreshLayersFuction()}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    >
                                    <Avatar sx={{ width: 32, height: 32 }} style={{color:'black'}}><Refresh/></Avatar>
                                  </IconButton>
                                  </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={opens}
        onClose={handleCloseMunu}
        onClick={handleCloseMunu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        
        
        <MenuItem onClick={ setOpenCreateNewCollectionFuction}>
          Questionnaire Collections
                                                
        </MenuItem>

        <MenuItem onClick={ setOpenGlobalStandardFuction}>
          
          Global Standards
        </MenuItem>

        <MenuItem onClick={ setOpenCustomStandardFuction}>
          
          Custom Standards
        </MenuItem>

        <MenuItem onClick={ setOpenRiskHierarchyFuction}>
          
          Risk Hierarchy
        </MenuItem>
        <MenuItem onClick={ setOpenSliderMethodsFuction }>
          
        Risk Slider Methods
        </MenuItem>

        <MenuItem onClick={ setOpenAddColorFuction }>
          
          Risk Slider Colors
          </MenuItem>

          <MenuItem onClick={ setOpenTransferQuestionnaireFuction }>
          
          Transfer Questionnaire Collection
          </MenuItem>
          <MenuItem onClick={ setOpenAddQuestionFuction }>
           Create/Edit Question
          </MenuItem>
          <MenuItem onClick={ setOpenAnswerScaleFuction }>
                Create/Edit Answer Scale
        </MenuItem>

          
        
      </Menu>
    </React.Fragment>
    </ div>

    </div>
                </Grid>

*/}
                
                <Grid item xs={12} id="tabId1">
                </Grid>
        
                <Draggable>
                  <Grid container spacing={2} className={classes.pageBodyStyle} >


                      <Grid item xs={3} >
                          <Button 
                              variant="contained" 
                              sx={{ boxShadow: 6 }} 
                              id="riskBtnId"
                              className={classes.pageBtnRisk}
                              //onClick={()=>setOpenRisk(!openRisk)}
                              onClick={()=>setOpenRiskFuction(!openRisk)}
                              // onClick={riskDialogHandleClickOpen('paper')}
                              >
                              {selectYourRiskType} &#9660;
                          </Button>
                          {/** Risk Type Dialog box at the end of js */}
                      </Grid> 

                      <Grid item xs={7} >
                          <Typography variant="h6" className={classes.cardBodyH6} style={{marginLeft:'-35px'}}>{selectedRiskNameStr}</Typography>
                      </Grid>
                      {/** 
                      <Grid item xs={2}>
                        <Button 
                          variant="contained" 
                          onClick={e => setRiskTypeEvalFuction()}  
                          style={{float:'right'}}
                          >Display / Edit All Questions
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button 
                          variant="contained" 
                          onClick={e => openQuestionListFunction()}  
                          style={{float:'right'}}
                          >Select a Question from Sample List
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button 
                          variant="contained" 
                          onClick={e => reloadAll(e)}  
                          style={{float:'right'}}
                          >Reload latest Updates
                        </Button>
                      </Grid>
                      */}

                      <Grid item xs={12}>
                          <Card style={{"width": "100% !important"}}>
                              <CardHeader 
                                  avatar={
                                      <Avatar sx={{ bgcolor: '#9155FD', width:'25px', height:'25px' }}>
                                          <Info/>
                                      </Avatar>
                                  } 
                                  className={classes.qusCardHeader} 
                                  title="Pick your Objective, Component, Principle and Entity from Standards OR create your own items"
                              />
                              <CardContent>
                                  <Grid container spacing={2}>
                                      <Grid item xs={6}>
                                          <Box sx={{ minWidth: 120}}>    
                                              <FormControl fullWidth>
                                                  <TextField
                                                      label={objectivePlaceHolderName}
                                                      sx={{ width: '100%' }}
                                                      select
                                                      value={objectiveId}
                                                      onChange={handleChangeObjectives}
                                                      >
                                                      {objectiveData.map((item)=>(
                                                          <MenuItem 
                                                              value={item.objectiveId}
                                                              >
                                                          {item.name}
                                                          </MenuItem>
                                                      ))}

                                                  </TextField>
                                              </FormControl>
                                          </Box>
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={globalObjHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Pick From Global Standards
                                          </Button>
                                                        
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={customObjHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Pick From Custom Standards
                                          </Button>
                                                        
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={ownObjectiveHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Create Your Own Objective
                                          </Button>
                                                        
                                      </Grid>


                                      <Grid item xs={6}>
                                          <Box sx={{ minWidth: 120 }}>    
                                              <FormControl fullWidth>
                                                  <TextField
                                                      label={componentPlaceHolderName}
                                                      sx={{ width: '100%' }}
                                                      select
                                                      value={componentId}
                                                      onChange={handleChangeComponents}
                                                      >
                                                      {componentsData.map((item)=>(
                                                          <MenuItem 
                                                              value={item.componentId}
                                                              >
                                                              {item.name}
                                                          </MenuItem>
                                                      ))}
                                                  </TextField>
                                              </FormControl>
                                          </Box>
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={globalCompHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Pick From Global Standards
                                          </Button>
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={customCompHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Pick From Custom Standards
                                          </Button>
                                                        
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={ownComponentHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                          Create Your Own Component
                                          </Button>
                                      </Grid>                  
                    
                    <Grid item xs={6}>
                          <Box sx={{ minWidth: 120 }}>    
                              <FormControl fullWidth>
                                  <TextField
                                      label={principlePlaceHolderName}
                                      sx={{ width: '100%' }}
                                      select
                                      value={principleId}
                                      style={{onMouseOver: principleName}}
                                      onChange={handleChangePrinciples}
                                      >
                                      {principalsData.map((item)=>(
                                          <MenuItem 
                                              value={item.principalId}
                                              >
                                              {item.name}
                                          </MenuItem>
                                      ))}
                                  </TextField>
                              </FormControl>
                          </Box> 
                    </Grid>
                    {/* 
                    <Grid item xs={1}>
                          <Box
                              component="form"
                              sx={{
                                  '& .MuiTextField-root': { width: '100%' },
                              }}
                              noValidate
                              autoComplete="off"
                              >
                              <div>
                                  <TextField
                                      id="principleScore"
                                      label="Score"
                                      value={principleScoreValue}
                                      onChange={handlePrincipleScoreChange}
                                  />
                              </div>
                          </Box>
                      </Grid>
  */}





                    
  <Grid item xs={2}>
                                          <Button variant="outlined" onClick={globalPriHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Pick From Global Standards
                                          </Button>
                                                        
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={customPriHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                              Pick From Custom Standards
                                          </Button>
                                                        
                                      </Grid>  
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={ownPrincipalHandleClickOpen('paper')} className={classes.stdButtonsStyle}>
                                          Create Your Own Principle
                                          </Button>
                                                        
                                      </Grid>                  
                    
                    
                    
                    <Grid item xs={6}>
                          <Box sx={{ minWidth: 120 }}>    
                              <FormControl fullWidth>
                                  <TextField
                                      label={entityPlaceHolderName}
                                      sx={{ width: '100%' }}
                                      select
                                      value={entity}
                                      onChange={handleChangeEntity}
                                      >
                                      {entityData.map((item)=>(
                                          <MenuItem value={item.entityId}>{item.entityName}</MenuItem>
                                      ))}
                                  </TextField>
                              </FormControl>
                          </Box>    
                      </Grid>            
                          <Grid item xs={2}>
                                          <Button variant="outlined" onClick={globalEntHandleClickOpen('paper')} style={{fontSize:'0.7rem', height: '50px'}}>
                                              Pick From Global Standards
                                          </Button>
                                                        
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={customEntHandleClickOpen('paper')} style={{fontSize:'0.7rem', height: '50px'}}>
                                              Pick From Custom Standards
                                          </Button>
                                                        
                                      </Grid>
                                      <Grid item xs={2}>
                                          <Button variant="outlined" onClick={setAllEntities} style={{fontSize:'0.7rem', height: '50px'}}>
                                              Set All Available Entities
                                          </Button>
                                                        
                                      </Grid>



                                  </Grid>
                              </CardContent>
                          </Card>    

                      </Grid>


                      <Grid item xs={12}>
                              <FormLabel id="radio-buttons-group-label">Question Type</FormLabel>
                              <RadioGroup
                                  row
                                  aria-labelledby="radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  >
                                  <FormControlLabel 
                                      value="openEnded" 
                                      control={<Radio />} 
                                      label="Open-Ended Question" 
                                      checked={openCloseEndedStatus === 1}
                                      onClick={(e) => radioHandler(1)}    
                                  />
                                  <FormControlLabel 
                                      value="closeEnded" 
                                      control={<Radio />} 
                                      label="Close-Ended Question" 
                                      checked={openCloseEndedStatus === 2}
                                      onClick={(e) => radioHandler(2)}
                                  />
                              </RadioGroup>
                      </Grid>
                      
                      {
                          openCloseEndedStatus === 1 && (
                              <QuestionerPageOpenEnded 
                                  handleOpenEndedChange={handleOpenEndedChange} 
                                  openEndedvalue={openEndedvalue} 
                                  openEndedScoreValue={openEndedScoreValue} 
                                  handleOpenEndedScoreChange={handleOpenEndedScoreChange} 
                                  openEndedAnswerValue={openEndedAnswerValue}
                                  handleOpenEndedAnswerChange={handleOpenEndedAnswerChange}
                                  questionSliderMethods={questionSliderMethods}
                                  sliderType={sliderType}
                                  handleChange={handleChange}
                                  riskClearFunction={riskClearFunction}
                                  showSelectBar={showSelectBar}
                                  sliderValue={sliderValue}
                                  sliderValueName={sliderValueName}
                                  sliderValueStyle={sliderValueStyle}
                                  setSliderValueStyle={setSliderValueStyle}
                                  setSliderValue={setSliderValue}
                                  nameChange={nameChange}
                                  questionLableNameOpenEnded={questionLableNameOpenEnded}
                                  setRiskLvlEditFunction={setRiskLvlEditFunction}
                                  
                                  
                                  
                                  
                              />
                          )
                      }
                      
                      {
                          openCloseEndedStatus === 2 && (
                              
                              <QuestionerPageCloseEnded 
                                  closeEndedvalue={closeEndedvalue}
                                  handleCloseEndedChange={handleCloseEndedChange}
                                  ansArray={ansArray}     
                                  setAnsArray={setAnsArray}
                                  ansMethod={ansMethod}  
                                  setAnsMethod={setAnsMethod}   
                                  minNumber={minNumber}  
                                  setMinNumber={setMinNumber}
                                  maxNumber={maxNumber}   
                                  setMaxNumber={setMaxNumber}   
                                  scalingMethod={scalingMethod}   
                                  setScalingMethod={setScalingMethod} 
                                  scallingMgs={scallingMgs}   
                                  setScallingMsg={setScallingMsg}
                                  closeEndedTypeName={closeEndedTypeName}
                                  setCloseEndedTypeName={setCloseEndedTypeName}
                                  closeEndedTypeStatus={closeEndedTypeStatus}
                                  setCETypeStatus={setCETypeStatus}
                                  ansArrayTmp={ansArrayTmp}
                                  setAnsArrayTmp={setAnsArrayTmp}
                                  closeEndedScoreValue={closeEndedScoreValue} 
                                  handleCloseEndedScoreChange={handleCloseEndedScoreChange} 
                                  answerOrderNumber={answerOrderNumber}
                                  setAnswerOrderNumber={setAnswerOrderNumber}
                                  selectedRiskName={selectedRiskName}
                                  selectedSuggestedValue={selectedSuggestedValue}
                                  setSelectedSuggestedValue={setSelectedSuggestedValue}
                                  optArray={optArray}
                                  setOptArray={setOptArray}
                                  closeEndedTypeMgs={closeEndedTypeMgs}
                                  setCloseEndedTypeMsg={setCloseEndedTypeMsg}

                                  questionSliderMethods={questionSliderMethods}
                                  sliderTypeCloseEnded={sliderTypeCloseEnded}
                                  handleChangeCloseEnded={handleChangeCloseEnded}
                                  riskClearFunctionCloseEnded={riskClearFunctionCloseEnded}
                                  showSelectBarCloseEnded={showSelectBarCloseEnded}
                                  sliderValueCloseEnded={sliderValueCloseEnded}
                                  sliderValueNameCloseEnded={sliderValueNameCloseEnded}
                                  setSliderValueCloseEnded={setSliderValueCloseEnded}
                                  nameChangeCloseEnded={nameChangeCloseEnded}
                                  questionLableNameCloseEnded={questionLableNameCloseEnded}
                                  sliderValueCloseEndedStyle={sliderValueCloseEndedStyle}
                                  setRiskLvlEditFunctionCloseEnded={setRiskLvlEditFunctionCloseEnded}
                                  databClr={databClr}
                                  scaleData={scaleData}


                                  scalingMethodExamples={scalingMethodExamples}
                                  setScalingMethodExamples={setScalingMethodExamples}
                                  scalingMethodExampleId={scalingMethodExampleId}
                                  setScalingMethodExampleId={setScalingMethodExampleId}
                                  scalingMethodExampleName={scalingMethodExampleName}
                                  setScalingMethodExampleName={setScalingMethodExampleName}
                                  selectedColorForAppetiteSlider={selectedColorForAppetiteSlider}
                                  setSelectedColorForAppetiteSlider={setSelectedColorForAppetiteSlider}
                                  setAppetiteColorForSaveToDb={setAppetiteColorForSaveToDb}
                                  appetiteColorForSaveToDb={appetiteColorForSaveToDb}

                              />
                          )
                      }
                      <Grid item xs={12}>
                          <Stack direction="row" spacing={2}>
                              <Button variant="contained" onClick={() => saveFunction(1)}>{saveBtnName}</Button>

                              {/*<Button variant="contained" onClick={() => saveFunction(2)}>{saveDraftBtnName}</Button>*/}
                          </Stack>
                      </Grid>

                      <Grid item xs={12} id="lastGridId">
                          &nbsp;
                      </Grid>

                  </Grid>
                </Draggable>



                <Grid item xs={12} id="tabId6" style={{height:`${openCreateNewCollectionOriginalRelatedHeight}`+ 'px'}}>
                    </Grid> 
                    {
                    openCreateNewCollection &&
                      <Draggable>
                        <Grid  id='divOpenCreateNewCollection' className={isActiveDivOpenCreateNewCollection ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenCreateNewCollection')} style={{marginTop: + `${openCreateNewCollectionPos}`+ 'px', zIndex: `${openCreateNewCollectionZIndex}`, position: 'relative'}}>
                        <Card  className={classes.cardDivMove} style={{marginRadius:'10px',width:`${openCreateNewCollectionOriginalWidth}`+ 'px',height:`${openCreateNewCollectionOriginalHeight}`+ 'px',overflowY: 'scroll', marginLeft: `${openCreateNewCollectionOriginalLeft}`+ 'px'}}>
                              <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '23px', padding: '7px', marginTop: '-10px', color:'white'}}>
                              <RemoveCircleOutlineIcon onClick={()=>divMinusFuction('divOpenCreateNewCollection')} style={{cursor: 'pointer'}}/>
                               <AddCircleOutlineIcon onClick={()=>divPlusFuction('divOpenCreateNewCollection')} style={{cursor: 'pointer'}}/>
                                <HighlightOffIcon onClick={()=>setOpenCreateNewCollectionCloseFunction(false)} style={{cursor: 'pointer'}}/></Typography>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.cardTitleMove} style={{fontSize:'18px !important', marginTop:'-32px'}}><span style={{borderStyle:'groove', padding:'10px'}}>Create/Edit Questionnaire Collections</span></Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Button 
                                      variant="contained" 
                                      className={classes.leftPageNumberStyle}
                                      >
                                      {openCreateNewCollectionOrder}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                  <Button 
                                      variant="contained" 
                                      className={classes.rightPageNumberStyle}
                                      >
                                      {openCreateNewCollectionOrder}  
                                    </Button>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{color:'#4907dc'}}>Multitasking Menu - Create/Edit Questionnaire Collections</Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <CollectionManagement
                                    openCreateNewCollectionWithColId={openCreateNewCollectionWithColId}
                                    />
                                  </Grid>  
                                </Grid> 
                              </CardContent>
                            </Card>
                        </Grid>
                      </Draggable>
                    }

                    <Grid item xs={12} id="tabId5" style={{height:`${openGlobalStandardOriginalRelatedHeight}`+ 'px'}}>
                    </Grid> 
                    {
                    openGlobalStandard &&
                      <Draggable>
                        <Grid id='divOpenGlobalStandard' className={isActiveDivOpenGlobalStandard ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenGlobalStandard')} style={{marginTop: + `${openGlobalStandardPos}`+ 'px', zIndex: `${openGlobalStandardZIndex}`, position: 'relative'}}>
                        <Card className={classes.cardDivMove} style={{marginRadius:'10px',width:`${openGlobalStandardOriginalWidth}`+ 'px',height:`${openGlobalStandardOriginalHeight}`+ 'px',overflowY: 'scroll', marginLeft: `${openGlobalStandardOriginalLeft}`+ 'px'}}>
                            <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '23px', padding: '7px', marginTop: '-10px', color:'white'}}>
                            <RemoveCircleOutlineIcon onClick={()=>divMinusFuction('divOpenGlobalStandard')} style={{cursor: 'pointer'}}/>
                              <AddCircleOutlineIcon onClick={()=>divPlusFuction('divOpenGlobalStandard')} style={{cursor: 'pointer'}}/>
                              <HighlightOffIcon onClick={()=>setOpenGlobalStandardCloseFunction(false)} style={{cursor: 'pointer'}}/></Typography>
                            <CardContent>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography variant="h6" className={classes.cardTitleMove} style={{fontSize:'18px !important', marginTop:'-32px'}}><span style={{borderStyle:'groove', padding:'10px'}}>Create/Edit Global Standards</span></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button 
                                      variant="contained" 
                                      className={classes.leftPageNumberStyle}
                                      >
                                      {openGlobalStandardOrder}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                  <Button 
                                      variant="contained" 
                                      className={classes.rightPageNumberStyle}
                                      >
                                      {openGlobalStandardOrder}  
                                    </Button>
                                  </Grid>  
                                  <Grid item xs={7}>
                                    <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{color:'#0742f1'}}>Multitasking Menu - Create/Edit Global Standards</Typography>
                                  </Grid>
                                <Grid item xs={12}>
                                  <GlobalStandard/>
                                </Grid>  
                              </Grid> 
                            </CardContent>
                          </Card>
                        </Grid>
                      </Draggable>
                    } 

                    <Grid item xs={12} id="tabId10" style={{height:`${openCustomStandardsOriginalRelatedHeight}`+ 'px'}}>
                    </Grid> 
                    {
                      openCustomStandards &&
                      <Draggable>
                        <Grid id='divOpenCustomStandards' className={isActiveDivOpenCustomStandards ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenCustomStandards')} style={{marginTop: + `${customStandardsPos}`+ 'px', zIndex: `${openCustomStandardsZIndex}`, position: 'relative'}}>
                        <Card  className={classes.cardDivMove} style={{marginRadius:'10px',width:`${openCustomStandardsOriginalWidth}`+ 'px',height:`${openCustomStandardsOriginalHeight}`+ 'px',overflowY: 'scroll', marginLeft: `${openCustomStandardsOriginalLeft}`+ 'px'}}>
                            <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '23px', padding: '7px', marginTop: '-10px', color:'white'}}>
                            <RemoveCircleOutlineIcon onClick={()=>divMinusFuction('divOpenCustomStandards')} style={{cursor: 'pointer'}}/>
                              <AddCircleOutlineIcon onClick={()=>divPlusFuction('divOpenCustomStandards')} style={{cursor: 'pointer'}}/>
                              <HighlightOffIcon onClick={()=>setOpenCustomStandardsCloseFunction(false)} style={{cursor: 'pointer'}}/>
                              </Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{fontSize:'18px !important', marginTop:'-32px'}}><span style={{borderStyle:'groove', padding:'10px'}}>Create/Edit Custom Standards</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Button 
                                      variant="contained" 
                                      className={classes.leftPageNumberStyle}
                                      >
                                      {openCustomStandardsOrder}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                  <Button 
                                      variant="contained" 
                                      className={classes.rightPageNumberStyle}
                                      >
                                      {openCustomStandardsOrder}  
                                    </Button>
                                  </Grid>  
                                  <Grid item xs={7}>
                                    <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{color:'#f606a3'}}>Multitasking Menu - Create/Edit Custom Standards</Typography>
                                  </Grid>

                                    <Grid item xs={12}>
      
                                                  <CustomStandardsComponent/>


                                    </Grid>  
                                  </Grid> 
                                </CardContent>
                            </Card>
                        </Grid>
                      </Draggable>

                    }                
                    <Grid item xs={12} id="tabId3" style={{height:`${openRiskHierarchyOriginalRelatedHeight}`+ 'px'}}>
                    </Grid> 
                    {
                    openRiskHierarchy &&
                      <Draggable>
                        <Grid id='divOpenRiskHierarchy' className={isActiveDivOpenRiskHierarchy ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenRiskHierarchy')} style={{marginTop: + `${openExistingRiskHierarchyPos}`+ 'px', zIndex: `${openRiskHierarchyZIndex}`, position: 'relative'}}>
                          <Card className={classes.cardDivMove} style={{marginRadius:'10px',width:`${openRiskHierarchyOriginalWidth}`+ 'px',height:`${openRiskHierarchyOriginalHeight}`+ 'px',overflowY: 'scroll', marginLeft: `${openRiskHierarchyOriginalLeft}`+ 'px'}}>
                            <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '23px', padding: '7px', marginTop: '-10px', color:'white'}}>
                              <RemoveCircleOutlineIcon onClick={()=>divMinusFuction('divOpenRiskHierarchy')} style={{cursor: 'pointer'}}/>
                              <AddCircleOutlineIcon onClick={()=>divPlusFuction('divOpenRiskHierarchy')} style={{cursor: 'pointer'}}/>
                              <HighlightOffIcon onClick={()=>setOpenRiskHierarchyCloseFunction(false)} style={{cursor: 'pointer'}}/>
                              </Typography>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.cardTitleMove} style={{fontSize:'18px !important', marginTop:'-32px'}}><span style={{borderStyle:'groove', padding:'10px'}}>Create / Edit a Risk Hierarchy</span></Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Button 
                                      variant="contained" 
                                      className={classes.leftPageNumberStyle}
                                      >
                                      {openRiskHierarchyOrder}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                  <Button 
                                      variant="contained" 
                                      className={classes.rightPageNumberStyle}
                                      >
                                      {openRiskHierarchyOrder}  
                                    </Button>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{color:'#06f6f6'}}>Multitasking Menu - Create / Edit a Risk Hierarchy</Typography>
                                  </Grid>  
                                  <Grid item xs={12}>
                                    <RiskHierarchy/>
                                  </Grid>  
                                </Grid> 
                              </CardContent>
                          </Card>
                        </Grid>
                      </Draggable>
                    } 
                    <Grid item xs={12} id="tabId7" style={{height:`${openCentreMaintenanceOriginalRelatedHeight}`+ 'px'}}>
                    </Grid> 
                    {
                      centreMaintenance &&
                      <Draggable>
                        <Grid id='divOpenCentreMaintenance' className={isActiveDivOpenCentreMaintenance ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenCentreMaintenance')} style={{marginTop: + `${openCentreMaintenancePos}`+ 'px', zIndex: `${openCentreMaintenanceZIndex}`, position: 'relative'}}>
                        <Card  className={classes.cardDivMove} style={{marginRadius:'10px',width:`${openCentreMaintenanceOriginalWidth}`+ 'px',height:`${openCentreMaintenanceOriginalHeight}`+ 'px',overflowY: 'scroll', marginLeft: `${openCentreMaintenanceOriginalLeft}`+ 'px'}}>
                              <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '23px', padding: '7px', marginTop: '-10px', color:'white'}}>
<RemoveCircleOutlineIcon onClick={()=>divMinusFuction('divOpenCentreMaintenance')} style={{cursor: 'pointer'}}/>
                              <AddCircleOutlineIcon onClick={()=>divPlusFuction('divOpenCentreMaintenance')} style={{cursor: 'pointer'}}/>
                                <HighlightOffIcon onClick={()=>setCentreMaintenanceCloseFunction(false)} style={{cursor: 'pointer'}}/></Typography>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.cardTitleMove} style={{fontSize:'18px !important', marginTop:'-32px'}}><span style={{borderStyle:'groove', padding:'10px'}}>Centre Maintenance</span></Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Button 
                                      variant="contained" 
                                      className={classes.leftPageNumberStyle}
                                      >
                                      {openCentreMaintenanceOrder}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                  <Button 
                                      variant="contained" 
                                      className={classes.rightPageNumberStyle}
                                      >
                                      {openCentreMaintenanceOrder}  
                                    </Button>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{color:'#06f664'}}>Multitasking Menu - Centre Maintenance</Typography>
                                  </Grid>  
                                  <Grid item xs={12}>
                                    <CenterMaintainersComponent/>
                                  </Grid>  
                                </Grid> 
                              </CardContent>
                            </Card>
                        </Grid>
                      </Draggable>
                    }             

<Grid item xs={12} id="tabId8" style={{height:`${opensliderMethodsOriginalRelatedHeight}`+ 'px'}}>
                    </Grid> 
                    {
                    opensliderMethods &&
                    <Draggable>
                      <Grid id='divOpenSliderMethods' className={isActiveDivOpenSliderMethods ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenSliderMethods')} style={{marginTop: + `${opensliderMethodsPos}`+ 'px', zIndex: `${openSliderMethodsZIndex}`, position: 'relative'}}>
                      <Card className={classes.cardDivMove} style={{marginRadius:'10px',width:`${opensliderMethodsOriginalWidth}`+ 'px',height:`${opensliderMethodsOriginalHeight}`+ 'px',overflowY: 'scroll', overflowX: 'scroll', marginLeft: `${opensliderMethodsOriginalLeft}`+ 'px'}}>
                            <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '23px', padding: '7px', marginTop: '-10px', color:'white'}}>
                            <RemoveCircleOutlineIcon onClick={()=>divMinusFuction('divOpenSliderMethods')} style={{cursor: 'pointer'}}/>
                              <AddCircleOutlineIcon onClick={()=>divPlusFuction('divOpenSliderMethods')} style={{cursor: 'pointer'}}/>
                             <HighlightOffIcon onClick={()=>setOpensliderMethodsCloseFunction(false)} style={{cursor: 'pointer'}}/></Typography>
                            <CardContent>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography variant="h6" className={classes.cardTitleMove} style={{fontSize:'18px !important', marginTop:'-32px'}}><span style={{borderStyle:'groove', padding:'10px'}}>Create / Edit Slider Methods</span></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button 
                                      variant="contained" 
                                      className={classes.leftPageNumberStyle}
                                      >
                                      {opensliderMethodsOrder}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                  <Button 
                                      variant="contained" 
                                      className={classes.rightPageNumberStyle}
                                      >
                                      {opensliderMethodsOrder}  
                                    </Button>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{color:'#89f606'}}>Multitasking Menu - Create / Edit Slider Methods</Typography>
                                  </Grid>  
                                <Grid item xs={12}>
                                  <SliderMethodsComponent/>
                                </Grid>  
                              </Grid> 
                            </CardContent>
                          </Card>
                      </Grid>
                    </Draggable>
                    }                

                    <Grid item xs={12} id="tabId9" style={{height:`${openAddColorOriginalRelatedHeight}`+ 'px'}}>
                    </Grid> 
                    {
                      openAddColor &&
                      <Draggable>
                        <Grid id='divOpenAddColor' className={isActiveDivOpenAddColor ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenAddColor')} style={{marginTop: + `${opeAddColorPos}`+ 'px', zIndex: `${openAddColorZIndex}`, position: 'relative'}}>
                        <Card  className={classes.cardDivMove} style={{marginRadius:'10px',width:`${openAddColorOriginalWidth}`+ 'px',height:`${openAddColorOriginalHeight}`+ 'px',overflowY: 'scroll', marginLeft: `${openAddColorOriginalLeft}`+ 'px'}}>
                              
                              <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '23px', padding: '7px', marginTop: '-10px', color:'white'}}>
                              <RemoveCircleOutlineIcon onClick={()=>divMinusFuction('divOpenAddColor')} style={{cursor: 'pointer'}}/>
                              <AddCircleOutlineIcon onClick={()=>divPlusFuction('divOpenAddColor')} style={{cursor: 'pointer'}}/>
                                <HighlightOffIcon onClick={()=>setOpenAddColorCloseFunction(false)} style={{cursor: 'pointer'}}/>
                                </Typography>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.cardTitleMove} style={{fontSize:'18px !important', marginTop:'-32px'}}><span style={{borderStyle:'groove', padding:'10px'}}>Create / Edit Slider Colors</span></Typography>
                                  </Grid>  
                                  <Grid item xs={6}>
                                    <Button 
                                      variant="contained" 
                                      className={classes.leftPageNumberStyle}
                                      >
                                      {openAddColorOrder}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                  <Button 
                                      variant="contained" 
                                      className={classes.rightPageNumberStyle}
                                      >
                                      {openAddColorOrder}  
                                    </Button>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{color:'#f6c506'}}>Multitasking Menu - Create / Edit Slider Colors</Typography>
                                  </Grid>
                                  <Grid item xs={12}>
    
                                                <AddColorComponent/>


                                  </Grid>  
                                </Grid> 
                              </CardContent>
                            </Card>
                        </Grid>
                      </Draggable>
                    }



                <Grid item xs={12} id="tabId14" style={{height:`${openTransferQuestionnaireOriginalRelatedHeight}`+ 'px'}}>
                                </Grid> 


                   {
                      openTransferQuestionnaire &&
                      <Draggable>
                        <Grid id='divOpenTransferQuestionnaire' className={isActiveDivOpenTransferQuestionnaire ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenCustomStandards')} style={{marginTop: + `${openTransferQuestionnairePos}`+ 'px', zIndex: `${openTransferQuestionnaireZIndex}`, position: 'relative'}}>
                        <Card  className={classes.cardDivMove} style={{marginRadius:'10px',width:`${openTransferQuestionnaireOriginalWidth}`+ 'px',height:`${openTransferQuestionnaireOriginalHeight}`+ 'px',overflowY: 'scroll', marginLeft: `${openTransferQuestionnaireOriginalLeft}`+ 'px'}}>
                            <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '23px', padding: '7px', marginTop: '-10px', color:'white'}}>
                              <RemoveCircleOutlineIcon onClick={()=>divMinusFuction('divOpenTransferQuestionnaire')} style={{cursor: 'pointer'}}/>
                              <AddCircleOutlineIcon onClick={()=>divPlusFuction('divOpenTransferQuestionnaire')} style={{cursor: 'pointer'}}/>
                              <HighlightOffIcon onClick={()=>setOpenTransferQuestionnaireCloseFunction(false)} style={{cursor: 'pointer'}}/>
                            </Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{fontSize:'18px !important', marginTop:'-32px'}}><span style={{borderStyle:'groove', padding:'10px'}}>Transfer Questionnaire Collections To end Users</span></Typography>
                                    </Grid>  
                                    <Grid item xs={6}>
                                    <Button 
                                      variant="contained" 
                                      className={classes.leftPageNumberStyle}
                                      >
                                      {openTransferQuestionnaireOrder}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                  <Button 
                                      variant="contained" 
                                      className={classes.rightPageNumberStyle}
                                      >
                                      {openTransferQuestionnaireOrder}  
                                    </Button>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{color:'#f61c06'}}>Multitasking Menu - Transfer Questionnaire Collections</Typography>
                                  </Grid>
                                    <Grid item xs={12}  style={{marginLeft: '-10px'}}>
      
                                                  <TransferCollectionComponent/>


                                    </Grid>  
                                  </Grid> 
                                </CardContent>
                            </Card>
                        </Grid>
                      </Draggable>

                    }



                  <Grid item xs={12} id="tabId15" style={{height:`${openAddQuestionOriginalRelatedHeight}`+ 'px'}}>
                   </Grid> 

                    {
                      openAddQuestion && 
                      <Draggable>
                      <Grid id='divOpenAddQuestion' className={isActiveDivOpenAddQuestion ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenAddQuestion')} style={{marginTop: + `${openAddQuestionPos}`+ 'px', zIndex: `${openAddQuestionZIndex}`, position: 'relative'}}>
                      <Card  className={classes.cardDivMove} style={{marginRadius:'10px',width:`${openAddQuestionOriginalWidth}`+ 'px',height:`${openAddQuestionOriginalHeight}`+ 'px',overflowY: 'scroll', marginLeft: `${openAddQuestionOriginalLeft}`+ 'px'}}>
                          <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '23px', padding: '7px', marginTop: '-10px', color:'white'}}>
                            <RemoveCircleOutlineIcon onClick={()=>divMinusFuction('divOpenAddQuestion')} style={{cursor: 'pointer'}}/>
                            <AddCircleOutlineIcon onClick={()=>divPlusFuction('divOpenAddQuestion')} style={{cursor: 'pointer'}}/>
                            <HighlightOffIcon onClick={()=>setOpenAddQuestionCloseFunction(false)} style={{cursor: 'pointer'}}/>
                          </Typography>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.cardTitleMove} style={{fontSize:'18px !important', marginTop:'-32px'}}><span style={{borderStyle:'groove', padding:'10px'}}>Create/Edit Question</span></Typography>
                                  </Grid>  
                                  <Grid item xs={6}>
                                  <Button 
                                    variant="contained" 
                                    className={classes.leftPageNumberStyle}
                                    >
                                    {openAddQuestionOrder}
                                  </Button>
                                </Grid>
                                <Grid item xs={6}>
                                <Button 
                                    variant="contained" 
                                    className={classes.rightPageNumberStyle}
                                    >
                                    {openAddQuestionOrder}  
                                  </Button>
                                </Grid>
                                <Grid item xs={7}>
                                  <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{color:'#f61c06'}}>Multitasking Menu - Create/Edit Question</Typography>
                                </Grid>

                                  <Grid item xs={12}  style={{marginLeft: '-10px'}}>
    
                                                <AddQuestionComponent/>


                                  </Grid>  
                                </Grid> 
                              </CardContent>
                          </Card>
                      </Grid>
                    </Draggable>
                    }


                <Grid item xs={12} id="tabId16" style={{height:`${openAnswerScaleOriginalRelatedHeight}`+ 'px'}}>
                   </Grid> 

                    {
                      openAnswerScale && 
                      <Draggable>
                      <Grid id='divOpenAnswerScale' className={isActiveDivOpenAnswerScale ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenAnswerScale')} style={{marginTop: + `${openAnswerScalePos}`+ 'px', zIndex: `${openAnswerScaleZIndex}`, position: 'relative'}}>
                      <Card  className={classes.cardDivMove} style={{marginRadius:'10px',width:`${openAnswerScaleOriginalWidth}`+ 'px',height:`${openAnswerScaleOriginalHeight}`+ 'px',overflowY: 'scroll', marginLeft: `${openAnswerScaleOriginalLeft}`+ 'px'}}>
                          <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '23px', padding: '7px', marginTop: '-10px', color:'white'}}>
                            <RemoveCircleOutlineIcon onClick={()=>divMinusFuction('divOpenAnswerScale')} style={{cursor: 'pointer'}}/>
                            <AddCircleOutlineIcon onClick={()=>divPlusFuction('divOpenAnswerScale')} style={{cursor: 'pointer'}}/>
                            <HighlightOffIcon onClick={()=>setOpenAnswerScaleCloseFunction(false)} style={{cursor: 'pointer'}}/>
                          </Typography>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.cardTitleMove} style={{fontSize:'18px !important', marginTop:'-32px'}}><span style={{borderStyle:'groove', padding:'10px'}}>Create/Edit Answer Scale</span></Typography>
                                  </Grid>  
                                  <Grid item xs={6}>
                                  <Button 
                                    variant="contained" 
                                    className={classes.leftPageNumberStyle}
                                    >
                                    {openAnswerScaleOrder}
                                  </Button>
                                </Grid>
                                <Grid item xs={6}>
                                <Button 
                                    variant="contained" 
                                    className={classes.rightPageNumberStyle}
                                    >
                                    {openAnswerScaleOrder}  
                                  </Button>
                                </Grid>
                                <Grid item xs={7}>
                                  <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{color:'#93180C'}}>Multitasking Menu - Create/Edit AnswerScale</Typography>
                                </Grid>

                                  <Grid item xs={12}  style={{marginLeft: '-10px'}}>
    
                                                <AddScaleComponent/>


                                  </Grid>  
                                </Grid> 
                              </CardContent>
                          </Card>
                      </Grid>
                    </Draggable>
                    }

















            </Grid> 

            <div>
     
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"select custom standard"}
        </DialogTitle>
        <DialogContent>
         
        <Box sx={{ minWidth: 120 ,mt:1}}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">standards</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={customId}
          label="standards"
          onChange={handleChangeCustom}
        >
        {standardData.map((stData)=>{
            if(stData.centreId === golobalCentreId){
                return(
                    <MenuItem value={stData.standardId}>{stData.standardName}</MenuItem>
                )
            }
            
        })}
               
        </Select>
      </FormControl>
    </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
         
        </DialogActions>
      </Dialog>
            </div> 
        </div>

    {/** Starting Dialog Box */}
    {/** Risk Pikup dialog box */}






    
        {
           openRisk?
           <Draggable>
           <div id="riskDiv" style={{zIndex:'1000'}}>
              
           <Grid  spacing={2}>
           <Card className={classes.popupdiv} style={{marginTop: + `${riskBtnTop}`+ 'px'}}>
           <Typography variant="h6" style={{float: 'right', marginTop:'-5px'}}><Tooltip title="Close"><Close style={{cursor: 'pointer'}} onClick={()=>setOpenRisk(!openRisk)}/></Tooltip></Typography>        
           <Typography variant="h6" style={{textAlign:'center', marginTop:'0px'}}>{selectYourRiskType}</Typography>
           <Divider light />
               <CardContent>
                   <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ minWidth: 120}}>    
                                <FormControl fullWidth>
                                    <TextField
                                        label="Select a Risk Hierarchy"
                                        sx={{ width: '100%' }}
                                        select
                                        value={selectedCollectionRiskId}
                                        onChange={handleChangeHierarchy}
                                        >
                                        {riskTypeData.map((item)=>(
                                            <MenuItem 
                                                value={item.collectionRiskId}
                                                >
                                                {item.name}
                                            </MenuItem>
                                        ))}

                                    </TextField>
                                </FormControl>
                            </Box>




                        </Grid> 
                        <Grid item xs={12} spacing={2} style={{overflowY: 'scroll', height:'200px'}}>
                            {
                                rsType.map((risktypp) => {
                                    return (
                                        <Comment 
                                            key={risktypp.riskTypeId} 
                                            comment={risktypp} 
                                        />
                                    )
                                })
                            }
                        </Grid>  
                   </Grid> 
               </CardContent>
               <Divider light />
               <CardActions style={{float:'right'}}>
                    <Button  color="primary" onClick={()=>setOpenRisk(!openRisk)} className={classes.cardClose}>
                    Close
                    </Button>
                </CardActions>
           </Card>
       </Grid>
       </div>
       </Draggable>
       :null

        }



        {riskQuestion?
            <Draggable>
                <div style={{zIndex:'1000'}}>
                <Grid item xs={6} style={{marginLeft:'20%',backgroundColor:'rgb(246 245 252)', border:'1px solid black'}}>
                        <Typography variant="h6" style={{float: 'right', marginTop:'-5px'}}><Tooltip title="Close"><Close style={{cursor: 'pointer'}} onClick={()=>setRiskQuestion(!riskQuestion)}/></Tooltip></Typography>            
                        <Grid container spacing={2}>
                            <RiskTypeQustionDisplay
                                quesData={RiskD}
                                collectnId={collectnId}
                                setRiskQuestion={setRiskQuestion}
                                riskQuestion={riskQuestion}
                            />
                        </Grid>    
                    </Grid>
                </div>
       </Draggable>
    :null
    }
  

    
     <Dialog
             PaperComponent={PaperComponent}
            open={riskDialogOpen}
            onClose={riskDialogHandleClickClose}
            scroll={riskDialogScroll}
            // aria-labelledby="risk-dialog-scroll-dialog-title"
            aria-describedby="risk-dialog-scroll-dialog-description"
            aria-labelledby="draggable-dialog-title"
            >
            <DialogTitle id="draggable-dialog-title">Select a Risk Type</DialogTitle>
            <DialogContent dividers={riskDialogScroll === 'paper'}>
            <DialogContentText
                id="risk-dialog-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid item xs={12} spacing={2}>
                    <Card style={{"width": "100% !important"}}>
                        <CardContent>
                            <Grid container spacing={2}>
                                    <Grid item xs={12} spacing={2}>
                                                    {
                                                        rsType.map((risktypp) => {
                                                            return (
                                                                <Comment 
                                                                    key={risktypp.riskTypeId} 
                                                                    comment={risktypp} 
                                                                />
                                                            )
                                                        })
                                                    }
                                            
                                    </Grid>  
                            </Grid> 
                        </CardContent>
                    </Card>
                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={riskDialogHandleClickClose}>Cancel</Button>
        </DialogActions>
    </Dialog> 
    {/** Risk Pikup dialog box */}
    {/** Global Objective Dialog Box */}
        <Dialog
            maxWidth={maxWidth}
            open={globalObjOpen}
            onClose={globalObjHandleClickClose}
            scroll={globalObjScroll}
            aria-labelledby="global-obj-scroll-dialog-title"
            aria-describedby="global-obj-scroll-dialog-description"
            >
            <DialogTitle id="global-obj-scroll-dialog-title">Select your Objectives from Global Standards</DialogTitle>
            <DialogContent dividers={globalObjScroll === 'paper'}>
              <DialogContentText
                id="global-obj-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel id="radio-buttons-group-label">Pick your Standard</FormLabel>
                  </Grid>       
                  <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                    <Stack spacing={10} direction="row" className={classes.mainbtn}>
                      {standardData.map((item,index)=>{
                        if (item.centreId === "globle" && item.objectives != null && item.objectives.length > 0 ){
                          return(
                          <Button 
                              variant="contained" 
                              id={"btn" + index}
                              className={classes.globalBtn}
                              onClick={() => getStandardObjectives(item.objectives,item.standardName,item.standardId)}
                              sx={{ boxShadow: 6 }}
                              >
                              {item.standardName}
                          </Button>
                          )
                        }  
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={globalObjHandleClickClose}>Cancel</Button>
            </DialogActions>
        </Dialog>

    {/** End of Global Objective Dialog */}
    {/** Custom Objective Dialog Box */}
    <Dialog
            maxWidth={maxWidth}
            open={customObjOpen}
            onClose={customObjHandleClickClose}
            scroll={customObjScroll}
            aria-labelledby="custom-obj-scroll-dialog-title"
            aria-describedby="custom-obj-scroll-dialog-description"
            >
            <DialogTitle id="custom-obj-scroll-dialog-title">Select your Objectives from Custom Standards</DialogTitle>
            <DialogContent dividers={customObjScroll === 'paper'}>
              <DialogContentText
                id="global-obj-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel id="radio-buttons-group-label">Select your Standard</FormLabel>
                  </Grid>       
                  <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Standard</InputLabel>
                      

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={customObjectiveStanardId}
                        label="standards"
                        onChange={handleChangeCustomObjectiveStanard}
                        >
                        {standardData.map((item)=>{
                            if(item.centreId === golobalCentreId && item.objectives != null && item.objectives.length > 0 ){
                                return(
                                    <MenuItem value={item.standardId}>{item.standardName}</MenuItem>
                                )
                            }
            
                        })}
                     </Select>
                     </FormControl>   
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={customObjHandleClickClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    {/** End of Custom Objective Dialog */}




    {/** Global Component Dialog Box */}
        <Dialog
          maxWidth={maxWidth}
          open={globalCompOpen}
          onClose={globalCompHandleClickClose}
          scroll={globalCompScroll}
          aria-labelledby="global-comp-scroll-dialog-title"
          aria-describedby="global-comp-scroll-dialog-description"
          >
          <DialogTitle id="global-comp-scroll-dialog-title">Select your Components from Global Standards</DialogTitle>
          <DialogContent dividers={globalCompScroll === 'paper'}>
            <DialogContentText
              id="global-comp-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel id="radio-buttons-group-label">Pick your Standard</FormLabel>
                </Grid>       
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <Stack spacing={10} direction="row" className={classes.mainbtn}>
                  {standardData.map((item,index)=>{
                        if (item.centreId === "globle" && item.components != null && item.components.length > 0){
                          return(
                          <Button 
                              variant="contained" 
                              id={"btn" + index}
                              className={classes.globalBtn}
                              onClick={() => getStandardComponents(item.components,item.standardName,item.standardId)}
                              sx={{ boxShadow: 6 }}
                              >
                              {item.standardName}
                          </Button>
                          )
                        }  
                      })}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={globalCompHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Global Component Dialog Box */}
      {/** Custom Component Dialog Box */}
        <Dialog
            maxWidth={maxWidth}
            open={customCompOpen}
            onClose={customCompHandleClickClose}
            scroll={customCompScroll}
            aria-labelledby="custom-Comp-scroll-dialog-title"
            aria-describedby="custom-Comp-scroll-dialog-description"
            >
            <DialogTitle id="custom-Comp-scroll-dialog-title">Select your Components from Custom Standards</DialogTitle>
            <DialogContent dividers={customCompScroll === 'paper'}>
              <DialogContentText
                id="global-obj-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel id="radio-buttons-group-label">Select your Standard</FormLabel>
                  </Grid>       
                  <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Standard</InputLabel>
                      

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={customComponentStanardId}
                        label="standards"
                        onChange={handleChangeCustomComponentStanard}
                        >
                        {standardData.map((item)=>{
                            if(item.centreId === golobalCentreId && item.components != null && item.components.length > 0 ){
                                return(
                                    <MenuItem value={item.standardId}>{item.standardName}</MenuItem>
                                )
                            }
            
                        })}
                     </Select>
                     </FormControl>   
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={customCompHandleClickClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    {/** End of Custom Components Dialog */}


      {/** Global Principal Dialog Box */}
        <Dialog
          maxWidth={maxWidth}
          open={globalPriOpen}
          onClose={globalPriHandleClickClose}
          scroll={globalPriScroll}
          aria-labelledby="global-pri-scroll-dialog-title"
          aria-describedby="global-pri-scroll-dialog-description"
          >
          <DialogTitle id="global-pri-scroll-dialog-title">Select your Principles from Global Standards</DialogTitle>
          <DialogContent dividers={globalPriScroll === 'paper'}>
            <DialogContentText
              id="global-pri-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel id="radio-buttons-group-label">Pick your Standard</FormLabel>
                </Grid>       
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <Stack spacing={10} direction="row" className={classes.mainbtn}>
                  {standardData.map((item,index)=>{
                        if (item.centreId === "globle" && item.principles != null && item.principles.length > 0){
                          return(
                          <Button 
                              variant="contained" 
                              id={"btn" + index}
                              className={classes.globalBtn}
                              onClick={() => getStandardPrinciples(item.principles,item.standardName,item.standardId)}
                              sx={{ boxShadow: 6 }}
                              >
                              {item.standardName}
                          </Button>
                          )
                        }  
                      })}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={globalPriHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Global Principal Dialog Box */}  

      {/** Custom Objective Dialog Box */}
        <Dialog
            maxWidth={maxWidth}
            open={customPriOpen}
            onClose={customPriHandleClickClose}
            scroll={customPriScroll}
            aria-labelledby="custom-Pri-scroll-dialog-title"
            aria-describedby="custom-Pri-scroll-dialog-description"
            >
            <DialogTitle id="custom-Pri-scroll-dialog-title">Select your Principles from Custom Standards</DialogTitle>
            <DialogContent dividers={customPriScroll === 'paper'}>
              <DialogContentText
                id="global-obj-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel id="radio-buttons-group-label">Select your Standard</FormLabel>
                  </Grid>       
                  <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Standard</InputLabel>
                      

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={customPrincipleStanardId}
                        label="standards"
                        onChange={handleChangeCustomPrincipleStanard}
                        >
                        {standardData.map((item)=>{
                            if(item.centreId === golobalCentreId && item.principles != null && item.principles.length > 0 ){
                                return(
                                    <MenuItem value={item.standardId}>{item.standardName}</MenuItem>
                                )
                            }
            
                        })}
                     </Select>
                     </FormControl>   
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={customPriHandleClickClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    {/** End of Custom Principle Dialog */}
    {/** Global Principal Dialog Box */}
      <Dialog
          maxWidth={maxWidth}
          open={globalEntOpen}
          onClose={globalEntHandleClickClose}
          scroll={globalEntScroll}
          aria-labelledby="global-ent-scroll-dialog-title"
          aria-describedby="global-ent-scroll-dialog-description"
          >
          <DialogTitle id="global-ent-scroll-dialog-title">Select your Entities from Global Standards</DialogTitle>
          <DialogContent dividers={globalEntScroll === 'paper'}>
            <DialogContentText
              id="global-ent-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel id="radio-buttons-group-label">Pick your Standard</FormLabel>
                </Grid>       
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <Stack spacing={10} direction="row" className={classes.mainbtn}>
                  {standardData.map((item,index)=>{
                        if (item.centreId === "globle" && item.entities != null && item.entities.length > 0){
                          return(
                          <Button 
                              variant="contained" 
                              id={"btn" + index}
                              className={classes.globalBtn}
                              onClick={() => getStandardEntities(item.entities,item.standardName,item.standardId)}
                              sx={{ boxShadow: 6 }}
                              >
                              {item.standardName}
                          </Button>
                          )
                        }  
                      })}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={globalEntHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Global Entity Dialog Box */}  
      {/** Custom Entity Dialog Box */}
        <Dialog
            maxWidth={maxWidth}
            open={customEntOpen}
            onClose={customEntHandleClickClose}
            scroll={customEntScroll}
            aria-labelledby="custom-Ent-scroll-dialog-title"
            aria-describedby="custom-Ent-scroll-dialog-description"
            >
            <DialogTitle id="custom-Pri-scroll-dialog-title">Select your Entities from Custom Standards</DialogTitle>
            <DialogContent dividers={customEntScroll === 'paper'}>
              <DialogContentText
                id="global-obj-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel id="radio-buttons-group-label">Select your Standard</FormLabel>
                  </Grid>       
                  <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Standard</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={customEntityStanardId}
                        label="standards"
                        onChange={handleChangeCustomEntityStanard}
                        >
                        {standardData.map((item)=>{
                            if(item.centreId === golobalCentreId && item.entities != null && item.entities.length > 0 ){
                                return(
                                    <MenuItem value={item.standardId}>{item.standardName}</MenuItem>
                                )
                            }
            
                        })}
                     </Select>
                     </FormControl>   
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={customEntHandleClickClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    {/** End of Custom Principle Dialog */}
    {/** Create Your Own Objective Dialog */}
    <Dialog
        open={ownObjectiveOpen}
        onClose={ownObjectiveHandleClickClose}
        scroll={ownObjectiveScroll}
        aria-labelledby="own-objective-scroll-dialog-title"
        aria-describedby="own-objective-scroll-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
        >
        <DialogTitle id="own-objective-scroll-dialog-title">Create Your Own Objective</DialogTitle>
        <DialogContent dividers={ownObjectiveScroll === 'paper'}>
            <DialogContentText
                id="custom-objective-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                        <Box sx={{ minWidth: 120}}>    
                            <FormControl fullWidth>
                                <TextField
                                    label='Select Your own objective from the previous Own objectives'
                                    sx={{ width: '100%' }}
                                    select
                                    value={previousOwnObjectiveId}
                                    onChange={handleChangePreviousOwnObjective}
                                    >
                                    {centreOwnObjectives.map((item)=>(
                                        <MenuItem 
                                            value={item.id}
                                            >
                                        {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Box>
                    </Grid>    
                
                    <Grid item xs={10} spacing={2}>
                        <Box
                            component="form"
                            sx={{
                            '& .MuiTextField-root': { width: '100%' },
                            }}
                            validate
                            autoComplete="off"
                            >
                            <div>
                                <TextField
                                    id="ownObjectiveType"
                                    label="Own Objective"
                                    multiline
                                    maxRows={2}
                                    value={ownObjectiveName}
                                    onChange={handleChangeOwnObjective}
                                />
                            </div>
                        </Box> 
                    </Grid>
                    <Grid item xs={2} spacing={2}>
                        <Button 
                            variant="outlined" 
                            startIcon={<AddIcon />} 
                            onClick={() => setOwnObjectiveFunction()}
                            style={{fontSize:'0.7rem', height: '50px'}}
                            >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={ownObjectiveHandleClickClose}>Cancel</Button>
        </DialogActions>
    </Dialog>
    {/** End of Create Your Own Objective Dialog */}
    {/** Create Your Own Component Dialog */}
    <Dialog
        open={ownComponentOpen}
        onClose={ownComponentHandleClickClose}
        scroll={ownComponentScroll}
        aria-labelledby="own-component-scroll-dialog-title"
        aria-describedby="own-component-scroll-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
        >
        <DialogTitle id="own-component-scroll-dialog-title">Create Your Own Component</DialogTitle>
        <DialogContent dividers={ownComponentScroll === 'paper'}>
            <DialogContentText
                id="custom-component-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                   <Grid item xs={12}>
                        <Box sx={{ minWidth: 120}}>    
                            <FormControl fullWidth>
                                <TextField
                                    label='Select Your own component from the previous Own Components'
                                    sx={{ width: '100%' }}
                                    select
                                    value={previousOwnComponentId}
                                    onChange={handleChangePreviousOwnComponent}
                                    >
                                    {centreOwnComponents.map((item)=>(
                                        <MenuItem 
                                            value={item.id}
                                            >
                                        {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={10} spacing={2}>
                        <Box
                            component="form"
                            sx={{
                            '& .MuiTextField-root': { width: '100%' },
                            }}
                            validate
                            autoComplete="off"
                            >
                            <div>
                                <TextField
                                    id="ownComponentType"
                                    label="Your Own Component"
                                    multiline
                                    maxRows={2}
                                    value={ownComponentName}
                                    onChange={handleChangeOwnComponent}
                                />
                            </div>
                        </Box> 
                    </Grid>
                    <Grid item xs={2} spacing={2}>
                        <Button 
                            variant="outlined" 
                            startIcon={<AddIcon />} 
                            onClick={() => setOwnComponentFunction()}
                            style={{fontSize:'0.7rem', height: '50px'}}
                            >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={ownComponentHandleClickClose}>Cancel</Button>
        </DialogActions>
    </Dialog>
    {/** End of Create Your Own Component Dialog */}
    {/** Your Own Principal Dialog */}
    <Dialog
        open={ownPrincipalOpen}
        onClose={ownPrincipalHandleClickClose}
        scroll={ownPrincipalScroll}
        aria-labelledby="own-principal-scroll-dialog-title"
        aria-describedby="own-principal-scroll-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
        >
        <DialogTitle id="custom-principal-scroll-dialog-title">Create Your Own Principle</DialogTitle>
        <DialogContent dividers={ownPrincipalScroll === 'paper'}>
            <DialogContentText
                id="own-principal-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <Box sx={{ minWidth: 120}}>    
                            <FormControl fullWidth>
                                <TextField
                                    label='Select Your own Principle from the previous Own Principle'
                                    sx={{ width: '100%' }}
                                    select
                                    value={previousOwnPrincipleId}
                                    onChange={handleChangePreviousOwnPrinciple}
                                    >
                                    {centreOwnPrinciples.map((item)=>(
                                        <MenuItem 
                                            value={item.id}
                                            >
                                        {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Box>
                    </Grid>
                
                    <Grid item xs={10} spacing={2}>
                        <Box
                            component="form"
                            sx={{
                            '& .MuiTextField-root': { width: '100%' },
                            }}
                            validate
                            autoComplete="off"
                            >
                            <div>
                                <TextField
                                    id="ownPrincipalType"
                                    label="Your Own Principle"
                                    multiline
                                    maxRows={2}
                                    value={ownPrincipalName}
                                    onChange={handleChangeOwnPrincipal}
                                />
                            </div>
                        </Box> 
                    </Grid>
                    <Grid item xs={2} spacing={2}>
                        <Button 
                            variant="outlined" 
                            startIcon={<AddIcon />} 
                            onClick={() => setOwnPrincipalFunction()}
                            style={{fontSize:'0.7rem', height: '50px'}}
                            >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={ownPrincipalHandleClickClose}>Cancel</Button>
        </DialogActions>
    </Dialog>

{/** All Question List dialog */}
<Dialog
      open={openPreAddedQuestions}
      onClose={false}
      scroll={openPreAddedQuestionsScroll}
      aria-labelledby="pre-added-question-dialog-title"
      aria-describedby="pre-added-question-dialog-description"
      fullWidth={true}
      maxWidth={'lg'}
      PaperComponent={PaperComponentPreAddedQuestions}
      >
      <DialogTitle style={{ cursor: 'move' }} id="pre-added-question-dialog-title">
        <Typography variant="h6" style={{fontSize:'25px !important'}}>
          Select a Question from Previous Added Questions 
        </Typography>
	      <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '-25px', padding: '7px', marginTop: '-50px'}}>
          <Tooltip title="Close"><Close onClick={()=>setOpenPreAddedQuestions(false)} style={{cursor: 'pointer'}}/></Tooltip>
        </Typography>
      </DialogTitle>
      <DialogContent dividers={openPreAddedQuestionsScroll === 'paper'}>
		    <DialogContentText id="pre-added-question-scroll-dialog-description" tabIndex={-1}>
		      <Grid container spacing={2}>
			      <Grid item xs={12}>
              <Grid container spacing={2}>
                <Box    
                  sx={{
                      height: 400,
                      width: '100%',
                      '& .super-app-theme--cell': {
                        whiteSpace: 'normal !important',
                        lineHeight: '1',
                      },
                      '& .super-app-theme--Open': {
                          fontSize: '10px !important',
                      }, 
						      }}
						      >
                  <div style={{ height: 400, width: '100%',backgroundColor: '#ffffff', fontSize: '9px !important' }}>
                    <DataGrid
                        rows={preAddedQuestionsRows}
                        columns={preAddedQuestionsColumns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        getRowClassName={`super-app-theme--Open`}
                        disableSelectionOnClick
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
			    </Grid>
		    </DialogContentText>
	    </DialogContent>
    	<DialogActions>
        	<Button onClick={()=>setOpenPreAddedQuestions(false)}>Cancel</Button>
	    </DialogActions>
    </Dialog>

    <Dialog
	open={openAllQuestionData}
	onClose={false}
	scroll={openAllQuestionDataScroll}
	aria-labelledby="collection-data-dialog-title"
	aria-describedby="collection-data-dialog-description"
	fullWidth={true}
	maxWidth={'lg'}
        PaperComponent={PaperComponentAllQuestionData}
        >
        <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
        	<Typography variant="h6" style={{fontSize:'25px !important'}}>Questions in "{selectedCollectionEvaluateTitle}"
        	</Typography>
	        <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '-25px', padding: '7px', marginTop: '-50px'}}>
                 <Tooltip title="Close"><Close onClick={()=>setOpenAllQuestionData(false)} style={{cursor: 'pointer'}}/></Tooltip>
               </Typography>
        </DialogTitle>
        <DialogContent dividers={openAllQuestionDataScroll === 'paper'}>
		<DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
		   <Grid container spacing={2}>
			    <Grid item xs={12}>
            <Grid container spacing={2}>

                                                <Box    
                                                sx={{
							height: 300,
							width: '100%',
							'& .super-app-theme--cell': {
							  whiteSpace: 'normal !important',
							  lineHeight: '1',
							},
							'& .super-app-theme--Open': {
							    fontSize: '10px !important',
							  }, 
						  
						    }}
						    >





                                                    <div style={{ height: 400, width: '100%',backgroundColor: '#ffffff', fontSize: '9px !important' }}>
                                                    <DataGrid
                                                        rows={rows}
                                                        columns={columns}
                                                        pageSize={5}
                                                        rowsPerPageOptions={[5]}
                                                        getRowClassName={`super-app-theme--Open`}
                                                        disableSelectionOnClick
                                                    />
                                                    </div>

                                                    </Box>
                                                </Grid>
                                            </Grid>
			 
			 
			 </Grid>
			 
			 
			 
			 
			 
		</DialogContentText>
	</DialogContent>
    	<DialogActions>
        	<Button onClick={()=>setOpenAllQuestionData(false)}>Cancel</Button>
	</DialogActions>
</Dialog>


    {/** Question Details Show Dialog */}
    <Dialog
          maxWidth='xs'
          open={questionDetailOpen}
          onClose={questionDetailHandleClickClose}
          scroll={questionDetailScroll}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Typography variant="h6" gutterBottom component="div">
                    Question No - {quesDataCount}
                </Typography>
                <Typography component="th" scope="row" style={{width: '100%', fontSize: '10px !important'}}>
                    <span style={{fontWeight: '550'}}>Question - </span>{selectedQuestion}
                </Typography>
          </DialogTitle>
          <DialogContent dividers={questionDetailScroll === 'paper'}>
            <DialogContentText
              id="risk-eval-type-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                {selectedQuesData.map((data) => {
                  var scoreColor = getRiskLvlColor(data.questionScore)
                  return (
                    <Grid item xs={12}>
                      <Box >
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Detail Weightings
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                        <Typography variant="h6" component="div">
                                            Score
                                        </Typography>
                                    </TableCell>
                                </TableRow>    
                            </TableBody>
                        </Table>
                        <Table size="small" aria-label="purchases">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                        Questionnaire weighting
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                    <span style={{color:scoreColor}}>{data.questionScore}</span>
                                    </TableCell>
                                </TableRow>    
                            </TableBody>
                        </Table>
                        {data.questionType === "Closeended" && (
                            <>
                            <Table size="small" aria-label="purchases">
                                <TableBody>        
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                Answers
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                        &nbsp;
                                        </TableCell>
                                    </TableRow>  
                                </TableBody>
                            </Table>        
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                        var riskLvlColor = getRiskLvlColor(item.riskLevel)
                                        return (
                                            <>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                                        <span >{item.answerName}</span>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                        <span style={{color:riskLvlColor }}>{item.riskLevel}</span>
                                                    </TableCell>
                                                </TableRow>    
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            </> 
                        )}
                        <Table size="small" aria-label="purchases">
                            <TableBody>        
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Model Answer(s)
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                    &nbsp;
                                    </TableCell>
                                </TableRow>  
                            </TableBody>
                        </Table>
                        {data.questionType === "Closeended" && (
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                        var riskLvlColor = getRiskLvlColor(item.riskLevel)
                                        if (item.expectedAnswerName != ""){
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                                            <span>
                                                                {item.answerName}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                        <span style={{color:riskLvlColor }}>{item.expectedAnswerName === "Yes" || item.expectedAnswerName === "checked"? item.riskLevel:item.expectedAnswerName}</span>
                                                        </TableCell>
                                                    </TableRow>    
                                                </>
                                            )
                                        }    
                                    })}
                                </TableBody>
                            </Table>
                        )}
                        {data.questionType === "Openended" && (
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                            <span>
                                                {data.answers.answer}
                                            </span>
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                            &nbsp;
                                        </TableCell>
                                    </TableRow>    
                                </TableBody>
                            </Table>
                        )}      
                      </Box>
                    </Grid>     
                  )
                })} 
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={questionDetailHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Question Details Show Dialog*/}




{/** Dialog Waiting */}
<Dialog
    maxWidth={'md'}
    open={waitingOpen}
    PaperProps={{style:{backgroundColor: 'transparent', boxShadow: 'none'}}} 
    >
    <CircularProgress disableShrink />
  </Dialog>

    </>
    



  )
}

export default QuestionerPageComponent