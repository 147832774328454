import React, {useEffect,useState} from 'react'
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from "@mui/material/Typography";
import { CONFIG } from '../config'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { Info } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';





function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));  



const CentreMaintenancePage = () => {


  const navigate = useNavigate();
  const classes = useStyle();
  const [golobalCentreId, setGolobalCentreId] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [userRoleId, setUserRoleId] = React.useState('');


/** ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  useEffect(() => {
    getData();
  }, [])

  function getData () {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId);
    setUserId(data.userId);
    setUserRoleId(data.userRoleId);
    getAllCentresFromServiceSystem();
    getAllUpdatedCentres();
    getAllUpdatedClientCentres(data.centreId);
  }


  const [allCentres, setAllCentres] = React.useState([]);  
  function getAllCentresFromServiceSystem(){
      var data = '{getAllCentreList{centreId,centreName}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          var centreList = response.data.data.getAllCentreList;
          console.log("All Centre List");
          console.log(centreList);
          setAllCentres(centreList);
      })
      .catch(function (error) {
          console.log("Entity Loding error" + error);
      });
  }

  /** Get All Updated Centers */
  const [allUpdatedCentres, setAllUpdatedCentres] = React.useState([]);
  function getAllUpdatedCentres(){
    axios(CONFIG.MAIN_URL+'/centreLimitation/all',{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get All Updated Centers");
      console.log((response.data));
      setAllUpdatedCentres(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  }


  


  const [centreId, setCentreId] = React.useState('');
  const [selectedCentreCollectionId, setSelectedCentreCollectionId] = React.useState('');
  const handleChangeCentre = (event) => {
    var id = event.target.value;
    setCentreId(id);
    var found = false;
    allUpdatedCentres.forEach((item)=>{
      if (item.centreId === id ){
        found = true;
        setSelectedCentreCollectionId(item.id);
        setMaximumNumber(item.maximumRiskCollection);
        setMaximumNumberTrasfered(item.maximumCollectionTransfered);
        setSaveBtnName('Update');
      }
    });
    if (!found){
      setSelectedCentreCollectionId('');
      setMaximumNumber('');
      setMaximumNumberTrasfered('');
      setSaveBtnName('Save');
    }

  };

  const [maximumNumber, setMaximumNumber] = React.useState('');
  const handleMaximumNumber = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setMaximumNumber(value);
  };

  const [maximumNumberTrasfered, setMaximumNumberTrasfered] = React.useState('');
  const handleMaximumNumberTrasfered = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setMaximumNumberTrasfered(value);
  };


  
  const [saveBtnName, setSaveBtnName] = React.useState('Save');

  /** Clear Maximum Questionnaire Collection */
  function clearFuction() {
    setSaveBtnName('Save');
    setMaximumNumber('');
    setMaximumNumberTrasfered('');
    setCentreId('');
    setSelectedCentreCollectionId('');
  }
  /** Save/ Update Maximum Questionnaire Collection */
  function onFormSubmit() {
    var canSave = true;
    if (centreId === ''){
      canSave = false;
    }
    if (maximumNumber === ''){
      canSave = false;
    }
    if (maximumNumberTrasfered === ''){
      canSave = false;
    }
    if (canSave) {
      var obj = {};
      if (selectedCentreCollectionId != ''){
        obj["id"] = selectedCentreCollectionId;
      }
      obj["centreId"] = centreId;
      obj["maximumRiskCollection"] = maximumNumber;
      obj["maximumCollectionTransfered"] = maximumNumberTrasfered;
      var sendJson = JSON.stringify(obj);
      console.log("+++++++++++++++++++sendJson++++++++++++++++++++");
      console.log(sendJson);
      var endPoint = '/centreLimitation/create';
      if (selectedCentreCollectionId != ''){
        endPoint = '/centreLimitation/update';
      }
      axios(CONFIG.MAIN_URL+endPoint,{
        method: 'post',
        headers: { 
          'Content-Type': 'application/json',   
        },  
        data:sendJson
      })
      .then(res =>  {
          console.log("++++++++++++++Update Center Limitation+++++++++++++++++");
          console.log(res.data);
          getAllUpdatedCentres();
          if (selectedCentreCollectionId != ''){
            alert('Successfully Updated the Maximum Number of Questionnaire Collection');
          }else{
            alert('Successfully Save the Maximum Number of Questionnaire Collection');
          }  
          clearFuction();
      })
      .catch(function (error) {
          console.log(error);
      });

    }else{
      if (centreId === ''){
        alert("Please select a centre");
      }else{
        if (maximumNumber === ''){
          alert("Please Enter Maximum Questionnaire Collection Allowed");
        }else{
          if (maximumNumberTrasfered === ''){
            alert("Please Enter Maximum Number of Transfered Questionnaire Collection Allowed");
          }
        }
      }
    }


  }
  /** +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /** +++++++++++++++++++++++++++Client Centre Section+++++++++++++++++++++++++++++++ */

  /** Get All Updated Centers */
  const [allUpdatedClientCentres, setAllUpdatedClientCentres] = React.useState([]);
  function getAllUpdatedClientCentres(centerId){
    axios(CONFIG.MAIN_URL+'/clientCentreDetails/all',{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get All Updated Client Centers");
      console.log((response.data));

      var arr = [];
      response.data.forEach((item)=>{
        if (item.buyUserCentreId === centerId){
          arr.push(item);
        }
      })
      setAllUpdatedClientCentres(arr);

    })
    .catch(function (error) {
      console.log(error);
    });
  }


  const [clientCentreId, setClientCentreId] = React.useState('');
  const [selectedClientCentreCollectionId, setSelectedClientCentreCollectionId] = React.useState('');
  const handleClientCentreId = (event) => {
    var id = event.target.value;
    setClientCentreId(id);
  };

  const [boughtUserId, setBoughtUserId] = React.useState('');
  const handleClientBoughtUserId = (event) => {
    var id = event.target.value;
    setBoughtUserId(id);
    var found = false;
    allUpdatedClientCentres.forEach((item)=>{
      if (item.buyUserId === id ){
        found = true;
        setClientCentreId(item.centreId);
        setClientCentreAdminCode(item.centerAdminCode);
        setClientCentreAdminPassword(item.centerAdminPassword);
        setSelectedClientCentreCollectionId(item.id);
        setClientCentreName(item.centreName);
        setClientCentreUrl(item.serverUrl);
        setKeepClientCentreUrl(item.serverUrl);
        setSaveClientBtnName('Update');
      }
    });
    
    if (!found){
      setSelectedClientCentreCollectionId('');
      setClientCentreId('');
      setClientCentreAdminCode('');
      setClientCentreAdminPassword('');
      setClientCentreName('');
      setClientCentreUrl('');
      setSaveClientBtnName('Save');
      if (clientCentreUrl === ""){
        if (keepClientCentreUrl != ""){
          setClientCentreUrl(keepClientCentreUrl);
        }
      }
    }
    


  };

  const [boughtUserCentreId, setBoughtUserCentreId] = React.useState('');
  const handleClientBoughtUserCentreId = (event) => {
    var id = event.target.value;
    setBoughtUserCentreId(id);
  };

  const [clientCentreAdminCode, setClientCentreAdminCode] = React.useState('');
  const handleClientCentreAdminCode = (event) => {
    var id = event.target.value;
    setClientCentreAdminCode(id);

  };
  const [clientCentreAdminPassword, setClientCentreAdminPassword] = React.useState('');
  const handleClientCentreAdminPassword = (event) => {
    var id = event.target.value;
    setClientCentreAdminPassword(id);
  };

  const [clientCentreAdminFirstName, setClientCentreAdminFirstName] = React.useState('');
  const handleClientCentreAdminFirstName = (event) => {
    var id = event.target.value;
    setClientCentreAdminFirstName(id);

  };
  const [clientCentreAdminLastName, setClientCentreAdminLastName] = React.useState('');
  const handleClientCentreAdminLastName = (event) => {
    var id = event.target.value;
    setClientCentreAdminLastName(id);

  };
  const [clientCentreAdminEmail, setClientCentreAdminEmail] = React.useState('');
  const handleClientCentreAdminEmail = (event) => {
    var id = event.target.value;
    setClientCentreAdminEmail(id);
  };



  const [clientCentreName, setClientCentreName] = React.useState('');
  const handleClientCentreName = (event) => {
    setClientCentreName(event.target.value)
  };

  const [clientCentreUrl, setClientCentreUrl] = React.useState('');
  const [keepClientCentreUrl, setKeepClientCentreUrl] = React.useState('');
  const handleClientCentreUrl = (event) => {
    setClientCentreUrl(event.target.value);
    setKeepClientCentreUrl(event.target.value);
  };

  const [saveClientBtnName, setSaveClientBtnName] = React.useState('Save');
  function clearClientFuction() {
    setSaveClientBtnName('Save');
    setClientCentreId('');
    setClientCentreName('');
    setClientCentreUrl('');
    setBoughtUserId('');
    setBoughtUserCentreId('');
    setClientCentreAdminCode('');
    setClientCentreAdminPassword('');
    setClientCentreAdminFirstName('');
    setClientCentreAdminLastName('');
    setClientCentreAdminEmail('');
    setSelectedClientCentreCollectionId('');
  }

  function onClientFormSubmit() {
    var canSave = true;
    if (clientCentreId === ''){
      canSave = false;
    }
    if (clientCentreName === ''){
      canSave = false;
    }
    if (clientCentreUrl === ''){
      canSave = false;
    }
    if (boughtUserId === ''){
      canSave = false;
    }
    if (boughtUserCentreId === ''){
      canSave = false;
    }
    if (clientCentreAdminCode === ''){
      canSave = false;
    }
    if (clientCentreAdminPassword === ''){
      canSave = false;
    }
    if (clientCentreAdminFirstName === ''){
      canSave = false;
    }
    if (clientCentreAdminLastName === ''){
      canSave = false;
    }
    if (clientCentreAdminEmail === ''){
      canSave = false;
    }

    if (canSave) {
      if (selectedClientCentreCollectionId === ''){
        allUpdatedClientCentres.forEach((item)=>{
          if (item.centreId === clientCentreId){
            alert("Please use the different Centre Code as \"" + clientCentreId + "\" is already in the data list");
            canSave = false;
          }else{
            if (item.centerAdminCode === clientCentreAdminCode){
              alert("Please use the different Centre Administrator Code as \"" + clientCentreAdminCode + "\" is already in the data list");
              canSave = false;
            }else{
              if (item.centerAdminPassword === clientCentreAdminPassword){
                alert("Please use the different Centre Administrator Password as \"" + clientCentreAdminPassword + "\" is already in the data list");
                canSave = false;
              }else{
                if (item.centreName === clientCentreName){
                  alert("Please use the different Centre name as \"" + clientCentreName + "\" is already in the data list");
                  canSave = false;
                }/*else{
                  if (item.centreAdminFirstName === clientCentreAdminFirstName){
                    alert("Please use the different Centre Admin First name as \"" + clientCentreAdminFirstName + "\" is already in the data list");
                    canSave = false;
                  }else{
                    if (item.centreAdminLastName === clientCentreAdminLastName){
                      alert("Please use the different Centre Admin Last name as \"" + clientCentreAdminLastName + "\" is already in the data list");
                      canSave = false;
                    }  
                  }  
                }*/
              } 
            }  
          }
        })
      }
    }  

    if (canSave) {
      var obj = {};
      if (selectedClientCentreCollectionId != ''){
        obj["id"] = selectedClientCentreCollectionId;
      }
      obj["buyUserId"] = boughtUserId;
      obj["buyUserCentreId"] = boughtUserCentreId;
      obj["centreId"] = clientCentreId;
      obj["centerAdminCode"] = clientCentreAdminCode;
      obj["centerAdminPassword"] = clientCentreAdminPassword;
      obj["centreAdminFirstName"] = clientCentreAdminFirstName;
      obj["centreAdminLastName"] = clientCentreAdminLastName;
      obj["centreAdminEmail"] = clientCentreAdminEmail;
      obj["centreName"] = clientCentreName;
      obj["serverUrl"] = clientCentreUrl;

      var sendJson = JSON.stringify(obj);
      console.log("+++++++++++++++++++sendJson++++++++++++++++++++");
      console.log(sendJson);
      var endPoint = '/clientCentreDetails/create';
      if (selectedClientCentreCollectionId != ''){
        endPoint = '/clientCentreDetails/update';
      }
      axios(CONFIG.MAIN_URL+endPoint,{
        method: 'post',
        headers: { 
          'Content-Type': 'application/json',   
        },  
        data:sendJson
      })
      .then(res =>  {
          console.log("++++++++++++++Update Center Limitation+++++++++++++++++");
          console.log(res.data);
          getAllUpdatedClientCentres(golobalCentreId);
          if (selectedClientCentreCollectionId != ''){
            alert('Successfully Updated the Client Server Details');
          }else{
            alert('Successfully Save the Client Server Details');
          }  
          clearClientFuction();
      })
      .catch(function (error) {
          console.log(error);
      });

    }else{
      if (clientCentreId === ''){
        alert("Please enter client Centre Code");
      }else {
        if (clientCentreName === ''){
          alert("Please enter client Centre Name");
        }else{
          if (clientCentreUrl === ''){
            alert("Please enter client Centre Url");
          }else {
            if (boughtUserId === '' || boughtUserCentreId === ''){
              if (boughtUserId === ''){
                alert("Please enter Bought User Code");
              }else{
                alert("Please enter Bought User Centre Code");
              }
              
            }else{
              if (clientCentreAdminCode === ''){
                alert("Please enter Client Centre Administrator Code");
              }else{
                if (clientCentreAdminPassword === ''){
                  alert("Please enter Client Centre Administrator Password");
                }else{
                  if (clientCentreAdminFirstName === ''){
                    alert("Please enter Client Centre Administrator First Name");
                  }else{
                    if (clientCentreAdminLastName === ''){
                      alert("Please enter Client Centre Administrator Last Name");
                    }else{
                      if (clientCentreAdminEmail === ''){
                        alert("Please enter Client Centre Administrator Email Address");
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  
  const previousClientServerRowData = (
    <TableBody>
    {allUpdatedClientCentres.map((row,index) => (
      
    
      <StyledTableRow key={index}  className={classes.tableTrStyle}>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal' }}>{row.buyUserId}</div>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal' }}>{row.buyUserCentreId}</div>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal' }}>{row.centreId}</div>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal' }}>{row.centerAdminCode}</div>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal' }}>{row.centerAdminPassword}</div>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal' }}>{row.centreName}</div>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal' }}>{row.serverUrl}</div>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal' }}>{row.centreAdminFirstName}</div>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal' }}>{row.centreAdminLastName}</div>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal' }}>{row.centreAdminEmail}</div>
        </StyledTableCell>
        <StyledTableCell 
          align="right"
          className={classes.tableTrStyle}
          >
          <EditIcon 
            className={classes.mousePointer}
            onClick ={ ()=>{editClentServerDetails(row.id)}}
          />
        </StyledTableCell>
      </StyledTableRow>
       ))}
  </TableBody>
  )

  function editClentServerDetails(id){
    allUpdatedClientCentres.forEach((item)=>{
      if (item.id === id ){
        setBoughtUserId(item.buyUserId);
        setBoughtUserCentreId(item.buyUserCentreId);
        setClientCentreId(item.centreId);
        setClientCentreAdminCode(item.centerAdminCode);
        setClientCentreAdminPassword(item.centerAdminPassword);
        setSelectedClientCentreCollectionId(item.id);
        setClientCentreName(item.centreName);
        setClientCentreUrl(item.serverUrl);
        setClientCentreAdminFirstName(item.centreAdminFirstName);
        setClientCentreAdminLastName(item.centreAdminLastName);
        setClientCentreAdminEmail(item.centreAdminEmail);
        setSaveClientBtnName('Update');
      }
    });

  }



  const [PageName, setPageName] = useState('Centre Maintenance')
  const [headerInfoMessage1, setHeaderInfoMessage1] = useState('Maintenance Centre Questionnaire Collection Limitation')
  const [headerInfoMessage2, setHeaderInfoMessage2] = useState('Maintenance Client Centres')
  return (
    <>
        <RightBarDataForOtherPages PageName={PageName}/>
        <div className={classes.pageTop}>
          <Grid container spacing={2} className={classes.pageBodyStyleCM}>
            {userRoleId != "superadmin"?
                <>
                <Grid item xs={12}>
                    <div role="presentation" onClick={handleClick}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/login" id='focusHomeLink'>
                        Home
                        </Link>
                        <Typography color="text.primary">Main menu - Centre Maintenance</Typography>
                    </Breadcrumbs>
                    </div>
                </Grid>
                <Grid item xs={12}>
                  <a style={{float: 'right',marginTop: '-36px',color: '#0056b3', cursor:'pointer'}} onClick={() => navigate(-1)}><ArrowBack/> Go Back</a>
                </Grid>
                </>
            : ''
            }    
            {userRoleId === "superadmin"?        
            <Grid item xs={12}>
              <Card id='focusCardId' style={{"width": "100% !important", border:"1px solid black"}}>
                <CardHeader 
                    avatar={
                        <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                            <Info/>
                        </Avatar>
                    } 
                    className={classes.commonMainTableHead} 
                    title={headerInfoMessage1}
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box sx={{ minWidth: 120}}>    
                        <FormControl fullWidth>
                            <TextField
                                label="Select a Centre"
                                sx={{ width: '100%' }}
                                select
                                value={centreId}
                                onChange={handleChangeCentre}
                                >
                                {allCentres.map((item)=>(
                                    <MenuItem 
                                        value={item.centreId}
                                        >
                                        {item.centreName}
                                    </MenuItem>
                                ))}

                            </TextField>
                        </FormControl>
                      </Box>
                      </Grid>
                      <Grid item xs={4} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="maximunNumber"
                              label="Type Maximum Questionnaire Collection Allowed"
                              multiline
                              maxRows={1}
                              value={maximumNumber}
                              onChange={handleMaximumNumber}
                            />
                          </div>
                        </Box> 
                      </Grid>
                      <Grid item xs={4} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="maximunNumber"
                              label="Type Maximum number of Transfered Allowed"
                              multiline
                              maxRows={1}
                              value={maximumNumberTrasfered}
                              onChange={handleMaximumNumberTrasfered}
                            />
                          </div>
                        </Box> 
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2} style={{float: 'right'}}>
                          <Button variant="contained" type='submit' onClick={e => onFormSubmit(e)}>{saveBtnName}</Button>
                          <Button variant="contained" onClick={clearFuction} >Clear</Button>
                        </Stack>
                      </Grid>
                    </Grid>  
                </CardContent>  
              </Card>  
            </Grid>
            :''
            }



            <Grid item xs={12}>
              <Card id='focusCardId' style={{"width": "100% !important", border:"1px solid black"}}>
                <CardHeader 
                    avatar={
                        <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                            <Info/>
                        </Avatar>
                    } 
                    className={classes.commonMainTableHead} 
                    title={headerInfoMessage2}
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={3} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="boughtUserId"
                              label="Type Bought User Code"
                              maxRows={1}
                              value={boughtUserId}
                              onChange={handleClientBoughtUserId}
                            />
                          </div>
                        </Box> 
                    </Grid>
                    <Grid item xs={3} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="boughtUserId"
                              label="Type Bought User Centre Code"
                              maxRows={1}
                              value={boughtUserCentreId}
                              onChange={handleClientBoughtUserCentreId}
                            />
                          </div>
                        </Box> 
                    </Grid>
                    <Grid item xs={3} >
                      &nbsp;
                    </Grid>
                    <Grid item xs={2} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="clientCentreId"
                              label="Type Client Centre Code"
                              multiline
                              maxRows={1}
                              value={clientCentreId}
                              onChange={handleClientCentreId}
                            />
                          </div>
                        </Box> 
                      </Grid>
                      <Grid item xs={3} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="clientCentreAdminCode"
                              label="Type Client Centre Admin Code"
                              multiline
                              maxRows={1}
                              value={clientCentreAdminCode}
                              onChange={handleClientCentreAdminCode}
                            />
                          </div>
                        </Box> 
                      </Grid>
                      <Grid item xs={2} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="clientCentreAdminPassword"
                              label="Type Admin Password"
                              multiline
                              maxRows={1}
                              value={clientCentreAdminPassword}
                              onChange={handleClientCentreAdminPassword}
                            />
                          </div>
                        </Box> 
                      </Grid>
                      <Grid item xs={2} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="clientCentreName"
                              label="Type Client Centre Name"
                              multiline
                              maxRows={1}
                              value={clientCentreName}
                              onChange={handleClientCentreName}
                            />
                          </div>
                        </Box> 
                      </Grid>
                      <Grid item xs={3} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="clientCentreUrl"
                              label="Type Client Centre URL"
                              multiline
                              maxRows={2}
                              value={clientCentreUrl}
                              onChange={handleClientCentreUrl}
                            />
                          </div>
                        </Box> 
                      </Grid>

                      <Grid item xs={3} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="clientCentreUrl"
                              label="Type Admin First Name"
                              multiline
                              maxRows={1}
                              value={clientCentreAdminFirstName}
                              onChange={handleClientCentreAdminFirstName}
                            />
                          </div>
                        </Box> 
                      </Grid>
                      <Grid item xs={3} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="clientCentreUrl"
                              label="Type Admin Last Name"
                              multiline
                              maxRows={1}
                              value={clientCentreAdminLastName}
                              onChange={handleClientCentreAdminLastName}
                            />
                          </div>
                        </Box> 
                      </Grid>
                      <Grid item xs={3} >
                        <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                          <div>
                            <TextField
                              id="clientCentreUrl"
                              label="Type Admin Email Address"
                              multiline
                              maxRows={1}
                              value={clientCentreAdminEmail}
                              onChange={handleClientCentreAdminEmail}
                            />
                          </div>
                        </Box> 
                      </Grid>


                      <Grid item xs={3}>
                        <Stack direction="row" spacing={2} style={{float: 'right'}}>
                          <Button variant="contained" type='submit' onClick={e => onClientFormSubmit(e)} style={{height:'48px'}}>{saveClientBtnName}</Button>
                          <Button variant="contained" onClick={clearClientFuction} style={{height:'48px'}}>Clear</Button>
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                      <Card className={classes.stdCardCM}>
                        <CardHeader 
                          avatar={
                            <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                              <Info/>
                            </Avatar>
                            } 
                          className={classes.commonMainTableHead} 
                          title="Previous Client Centres"
                          />
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div className={classes.scrollableDivToStandardCM}>
                                  <TableContainer 
                                    component={Paper}
                                    //sx={{ maxHeight: 440 }}
                                    >
                                    <Table  aria-label="customized table">
                                      <TableHead className={classes.tableHeadStyle}>
                                        <TableRow>
                                        <StyledTableCell className={classes.tableThStyle} style={{width: '10%'}}>User Code</StyledTableCell>
                                        <StyledTableCell className={classes.tableThStyle} style={{width: '10%'}}>User Centre</StyledTableCell>
                                          <StyledTableCell className={classes.tableThStyle} style={{width: '10%'}}>Centre Code</StyledTableCell>
                                          <StyledTableCell className={classes.tableThStyle} style={{width: '10%'}}>Admin Code</StyledTableCell>
                                          <StyledTableCell className={classes.tableThStyle} style={{width: '10%'}}>Password</StyledTableCell>
                                          <StyledTableCell className={classes.tableThStyle} style={{width: '22%'}}>Centre Name</StyledTableCell>
                                          <StyledTableCell className={classes.tableThStyle} style={{width: '22%'}}>Centre Endpoint Url</StyledTableCell>
                                          <StyledTableCell className={classes.tableThStyle} style={{width: '22%'}}>Admin First Name</StyledTableCell>
                                          <StyledTableCell className={classes.tableThStyle} style={{width: '24%'}}>Admin Last Name</StyledTableCell>
                                          <StyledTableCell className={classes.tableThStyle} style={{width: '25%'}}>Admin Email</StyledTableCell>
                                          <StyledTableCell align="right" className={classes.tableThStyle} style={{width: '10%'}}>Edit</StyledTableCell>
                                        </TableRow>
                                      </TableHead>
                                      {previousClientServerRowData}
                                    </Table>
                                  </TableContainer>    
                                </div>
                              </Grid>  

                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>  




                    </Grid>  
                </CardContent>  
              </Card>  
            </Grid>








          </Grid>
  
                
        </div>

    </>

  )
}

export default CentreMaintenancePage