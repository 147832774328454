import React, { useEffect, useState } from 'react'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";

import useStyle from '../Css/MainCss';
import { ArrowBack, Details } from '@mui/icons-material';
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info } from '@mui/icons-material';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { CONFIG } from '../config'

import Tooltip from '@mui/material/Tooltip';
import { Close } from '@mui/icons-material';
import RiskHierarchyNew from '../Components/RiskHierarchyNew';
import Draggable from 'react-draggable';
import { event } from 'jquery';
import { CircularProgress } from "@material-ui/core";
import RiskRegisterComponent from '../Components/RiskRegisterComponent';




function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const CreateEditRiskRegister = ({fromMultiTaskin}) => {
  const classes = useStyle();
  const [PageName, setPageName] = React.useState('Risk Register');
  const navigate = useNavigate();
  

  return (
    <>
    {
      fromMultiTaskin ? '':
      <RightBarDataForOtherPages PageName={PageName} />
    }
     
     <div className={fromMultiTaskin ? classes.evapageleft : classes.pageTop} >
        <Grid container spacing={2}>
          <Grid container spacing={2} className={classes.pageBodyStyleRiskReg}>
            {fromMultiTaskin ? '' :
            <>
              <Grid item xs={12}>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" to="/login">
                    Home
                  </Link>
                  <Typography color="text.primary">Main menu - {PageName}</Typography>
                </Breadcrumbs>
              </div>
            </Grid>
            <Grid item xs={12}>
              <a style={{ float: 'right', marginTop: '-36px', color: '#0056b3', cursor: 'pointer' }} onClick={() => navigate(-1)}><ArrowBack /> Go Back</a>
            </Grid>
            </>
            }


            <Grid item xs={12}>
              <RiskRegisterComponent/>
            </Grid>
          </Grid>
        </Grid>
      </div>


    </>
  )
}

export default CreateEditRiskRegister
