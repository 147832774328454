import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import { CONFIG } from '../config'
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info, KeyboardReturnOutlined } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import '../Css/Slider.css';
import Draggable from 'react-draggable';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import useStyle from '../Css/MainCss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete1 from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Edit from '@mui/icons-material/Edit';
import EvaluateComponent from './EvaluateComponent';
import Typography from "@mui/material/Typography";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { Close } from '@mui/icons-material';
import "../Css/RiskTypeOptionNew.css";

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageBodyStyle: {
    paddingTop: '12px',
  },
  root: {
    position: 'relative',
  },
  tableTrStyle: {
    //lineHeight: '0.20rem !important',
    height: '19px !important'
  },
  mousePointer: {
    cursor: 'pointer'
  },
  stdMainCardHeader: {
    backgroundColor: '#4C7EE0 !important',
    fontSize: '13px !important',
    height: '50px',
    padding: '0px',
    marginTop: '-7px',
    color: 'white'
  },
  stdCard: {
    height: '100%',
    border: '1px solid black'
  },
  scrollableDivToStandard: {
    height: '350px',
    overflow: 'auto',
    width: '100%',
  },
  slider1: {
    background: 'linear-gradient(90deg, rgb(70, 163, 56) 10%,#d3d3d3 0%)',
  },
  cardDivDB: {
    border: '2px solid 69676e',
    width: '100% !important',
    backgroundColor: '#f6f5fc !important',
    boxShadowhadow: '5px 10px #69676e',
    resize: 'horizontal',
    overflow: 'auto',
    height: '100%',
    borderRadius: '5px !important'
  },
  sliderStyle: {
    WebkitAppearance: 'media-volume-sliderthumb !important'
  },
  focusBorder: {
    border: '1px solid red !important',
    //backgroundColor: 'red !important'
  },
  timeOutBorder: {
    border: '1px solid white !important',
    //backgroundColor: 'white !important'
  },
  textFieldHeight: {
    height: '22px'
  }

}));




const RiskHierarchyNew = ({ riskHierarchyId, updatedRiskHierarchyId, setUpdatedRiskHierarchyId, displayOnly, intImpactValesfromRegister, intLikelihoodValuefromRegister, intRiskAppetiteValuefromRegister, selectedRiskHierarchyfromRegister, dataFromRegister, keyFromRegister, registerKey }) => {

  console.log(keyFromRegister)
  const classe = useStyle();
  const classes = useStyles();
  const Input = styled('input')({
    display: 'none',
  });

  const [waitingOpen, setWaitingOpen] = React.useState(false);
  const [golobalCentreId, setGolobalCentreId] = React.useState('');
  const [sliderType, setSliderType] = React.useState();

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    console.log("recorence")
    getData();
    getColors();
    getColors1();
  }, [])


  const [databClr, setDatabClr] = useState([])
  function getColors() {
    axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR + "/all", {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("+++++++++++++++++All colors++++++++++++++++++");
        console.log(response.data);
        setDatabClr(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const [colorPatterns, setColorPatterns] = useState([])
  function getColors1() {
    axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR + "/all", {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("+++++++++++++++++All colors++++++++++++++++++");
        console.log(response.data);
        setColorPatterns(response.data);

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function getData() {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId);
    getAllRisksMethods();
    getAllRisks(data.centreId);
    getStandards(data.centreId)
    console.log(data)
  }

  const heightTextField = 22;
  const [standardData, setStandardData] = React.useState([]);
  const [globalStandardData, setGlobalStandardData] = React.useState([]);
  const [customStandardData, setCustomStandardData] = React.useState([]);
  function getStandards(cntre) {
    axios(CONFIG.MAIN_URL + `/standard/all??centreId=` + cntre, {
      method: 'get',
    })
      .then(function (response) {
        console.log("++++++++++++++++Return Standard Data +++++++++++++++++++");
        console.log(response.data);
        console.log(JSON.stringify(response.data));
        setStandardData(response.data);
        var arrG = [];
        var arrC = [];
        response.data.forEach((item) => {
          if (item.centreId === "globle") {
            arrG.push(item);
          }
          if (item.centreId === cntre) {
            arrC.push(item);
          }
        })
        setGlobalStandardData(arrG);
        setCustomStandardData(arrC);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [riskTypeName, setRiskTypeName] = useState([])
  const [riskTypeKeys, setRiskTypeKeys] = useState([])
  const [editClickRiskId, setEditClickRiskId] = useState()

  function getAllRisks(centId) {
    axios(CONFIG.MAIN_URL + `/risk/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("All Risk Returns");
        console.log(response.data);
        var arr = [];
        var arrKey = [];
        response.data.forEach((item) => {
          if (centId === item.centreId) {
            arr.push(item);
            if (item.key != undefined && item.key != null && item.key != '') {
              arrKey.push(item);
            }
          }
        })
        setRiskTypeName(arr);
        setRiskTypeKeys(arrKey);
        setTimeout(() => {
          if (riskHierarchyId != undefined && riskHierarchyId != null && riskHierarchyId != '') {
            editRiskDataFromUploadJson(riskHierarchyId, arr);
          }
        }, 500);


      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const previousRiskTableRowData = (
    <TableBody>
      {riskTypeName.map((item) => {
        // if (item.centreId != "globle" && item.centreId === globalCentreId){
        return (
          <StyledTableRow key={item.riskId} className={classes.tableTrStyle}>
            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
              <div style={{ whiteSpace: 'normal', fontSize: '9px' }}>{item.key}</div>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
              <div style={{ whiteSpace: 'normal', fontSize: '9px' }}>{item.name}</div>
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={classes.tableTrStyle}
            >
              <EditIcon
                className={classes.mousePointer}
                onClick={() => { editRiskData(item.riskId) }}
              />
            </StyledTableCell>
          </StyledTableRow>
        )
        //  }
      })}
    </TableBody>

  )



  useEffect(() => {
    if (selectedRiskHierarchyfromRegister != undefined && selectedRiskHierarchyfromRegister != null && selectedRiskHierarchyfromRegister != '') {
      setRiskName(selectedRiskHierarchyfromRegister)
    }
    if (intImpactValesfromRegister != undefined && intImpactValesfromRegister != null && intImpactValesfromRegister != '') {
      setIntImpactVales(intImpactValesfromRegister)
    }
    if (intLikelihoodValuefromRegister != undefined && intLikelihoodValuefromRegister != null && intLikelihoodValuefromRegister != '') {
      setIntLikelihoodValue(intLikelihoodValuefromRegister)
    }
    if (intRiskAppetiteValuefromRegister != undefined && intRiskAppetiteValuefromRegister != null && intRiskAppetiteValuefromRegister != '') {
      setIntRiskAppetiteValue(intRiskAppetiteValuefromRegister)
    }
    if (registerKey != undefined && registerKey != null && registerKey != '') {
      setRiskKey(registerKey)
    }




  }, [])


  // console.log(selectedFile)
  const [saveBtnName, setSaveBtnName] = useState("Save");
  const [saveBtnNameForRisk, setSaveBtnNameForRisk] = useState("Save Risk");
  const [editGroup, setEditGroup] = useState(false);
  const [riskName, setRiskName] = useState('')
  const [riskIdvalue, setRiskIdvalue] = React.useState(0);

  const [showRiskHierarchyTable, setShowRiskHierarchyTable] = React.useState(true);

  const [riskKey, setRiskKey] = useState('')

  const onTextFieldChangeKey = (event) => {
    setRiskKey(event.target.value);
  }


  const onTextFieldOnBlurKey = (event) => {
    var fieldVal = document.getElementById("txtRiskKey").value;
    if (fieldVal != '') {
      setRiskKey(fieldVal);
      /*
      riskTypeKeys.forEach((data) => {
        if (data.key === fieldVal){
          setWaitingOpen(true)
          var riskId = data.riskId
          editRiskData(riskId);
          setWaitingOpen(false)
        }
      })
      */
    }
  }


  const onTextFieldOnBlurName = (event) => {
    var fieldVal = document.getElementById("txtRiskName").value;
    if (fieldVal != '') {
      setRiskName(fieldVal);
    }
  }


  const onTextFieldChange = (event) => {
    setRiskName(event.target.value);
  }

  function clearFuction(e) {
    setSaveRiskFlag(true);
    clearFuctionForUpdate();
    getAllRisks(golobalCentreId);
    setRiskIdvalue(0)
  }

  function clearFuctionForUpdate(e) {
    setFrameSize(9)
    setDivChange(false)
    setRiskName("");
    setRiskKey('');
    document.getElementById("txtRiskName").value = "";
    document.getElementById("txtRiskKey").value = "";

    setIntLikelihoodValue('')
    setIntImpactVales('');
    setIntRiskAppetiteValue('')
    calculatedRiskLevel();

    setSaveBtnName("Save");
    setEditGroup(false);
    setSaveRiskFlag(false);
    setRiskTypeData({});
    setRiskIdvalue(0);
    setShowSaveBtn(false);
    setCreateHeaderInfoMessage("Create A New Risk Hierarchy");
    setSaveBtnNameForRisk("Save Risk");
    setAppetiteShowSelectBar(true);
    setAppetiteSliderValue();
    setAppetiteSliderType();
    setAppetiteSliderValueName();
    setShowSelectBar(true);

    setSliderType(0);
    setSliderValue(0);
    setSliderValueName("");
    setShowRiskHierarchyTable(true)

    //For Standard
    setChkStandardAllChecked(false);
    setOpenStandardGridAll(false);
    setGlobalStandardIdForAll('');
    setCustomStandardIdForAll('');
    setGlobalStandardDataForMainRisk('');
    setCustomStandardDataForMainRisk('');
    setObjectiveId('');
    setComponentId('');
    setPrincipleId('');

  }

  const [showSaveBtn, setShowSaveBtn] = React.useState(false)
  const [riskTypeData, setRiskTypeData] = React.useState({});
  const [createheaderInfoMessage, setCreateHeaderInfoMessage] = useState("Create A New Risk Hierarchy");

  const options = ['1', '2', '3', '4', '5'];
  const [intImpactVales, setIntImpactVales] = useState('');
  const options1 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  const [intRiskAppetiteValue, setIntRiskAppetiteValue] = useState('');
  const [intRiskTypeValue, setIntRiskTypeValue] = useState('');
  const [intLikelihoodValue, setIntLikelihoodValue] = useState('');
  function calculatedRiskLevel() {
    var x = '';
    if (!isNaN(parseFloat(intImpactVales)) && !isNaN(parseFloat(intLikelihoodValue))) {
      x = parseInt(intImpactVales) * parseInt(intLikelihoodValue)
      //setIntRiskTypeValue(x);
    }
    return x;
  }

  function getColorCodeValue(number, type) {
    var cCode = "#ffffff";
    databClr.forEach((data) => {
      if (parseInt(number) === parseInt(data.scoreNumber)) {
        if (type === 1) {
          cCode = data.color;
        } else {
          cCode = data.secondaryColor;
        }
      }
    })
    return cCode;
  }


  /**
   * Create Main Risk
   */
  const [mainRiskName, setMainRiskName] = React.useState('');


  const handleChangeMainRisk = (event) => {
    setMainRiskName(event.target.value);
  };

  function handleKeyDownMain(event) {
    var fieldValue = event.target.value;
    if (event.keyCode == 9 && fieldValue != '') {
      addMainRisk()
      setMainRiskName('');
    }
  }

  function addMainRisk() {
    var mainTextField = document.getElementById("txt0");
    if (mainTextField.value === '') {
      alert("Please enter Main Risk Name")
      mainTextField.focus();
      return;
    }
    if (intLikelihoodValue === "") {
      alert("Please enter Likelihood Rate");
      return
    }
    if (intImpactVales === '') {
      alert("Please enter Impact Rate");
      return
    }
    if (intRiskAppetiteValue === '') {
      alert("Please enter a Appetite Level ");
      return
    }
    if (document.getElementById('chkStandard') != undefined && document.getElementById('chkStandard') != null && document.getElementById('chkStandard').checked) {
      if (objectiveId === '' && componentId === '' && principleId === '') {
        alert("Please select an Objective , a Component or a Principle ");
        return;
      }
    }
    var tmpArray = {};
    var tmoN0 = findNextRiskTypeTmpId();
    var tmpId = 0;
    tmpId = nextRiskTypeTmpId;
    //var tmpId = (lastRiskTmpId + 1);
    tmpArray["riskTypeTmpId"] = tmpId;
    tmpArray["name"] = mainRiskName;
    tmpArray["riskLikelihood"] = intLikelihoodValue;
    tmpArray["riskImpact"] = intImpactVales;
    tmpArray["riskLevel"] = parseInt(intImpactVales) * parseInt(intLikelihoodValue);

    var tmpArray1 = {}
    tmpArray1["riskLikelihood"] = intLikelihoodValue;
    tmpArray1["riskImpact"] = intImpactVales;
    tmpArray1["riskLevel"] = parseInt(intImpactVales) * parseInt(intLikelihoodValue);
    tmpArray1["riskCategoryId"] = "";
    tmpArray1["riskMethodId"] = "";
    tmpArray["riskLevelData"] = tmpArray1;

    tmpArray["riskAppetite"] = intRiskAppetiteValue;
    var tmpArrayApp = {};
    tmpArrayApp["riskAppetite"] = intRiskAppetiteValue;
    tmpArrayApp["riskCategoryId"] = "";
    tmpArrayApp["riskMethodId"] = "";
    var cCode = getColorCodeValue(intRiskAppetiteValue, 2);
    tmpArrayApp["color"] = "";
    tmpArrayApp["secondaryColor"] = cCode;
    tmpArray["riskAppetiteData"] = tmpArrayApp;

    //For Standared
    if (document.getElementById('chkStandard') != undefined && document.getElementById('chkStandard') != null && document.getElementById('chkStandard').checked) {
      var tmpRiskStandardData = {};
      var takenStd = false;
      if (globalStandardDataForMainRisk != '' || customStandardDataForMainRisk != '') {
        takenStd = true;
        var tmpStdData = {}
        if (globalStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = globalStandardDataForMainRisk
          tmpStdData["standardName"] = globalStandardDataNameForMainRisk
        }
        if (customStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = customStandardDataForMainRisk
          tmpStdData["standardName"] = customStandardDataNameForMainRisk
        }
        tmpRiskStandardData["standardData"] = tmpStdData;
      }
      if (objectiveId != '') {
        takenStd = true;
        var tmpStdData = {};
        tmpStdData["objectiveId"] = objectiveId;
        tmpStdData["objectiveName"] = objectiveName;
        if (globalStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = globalStandardDataForMainRisk;
          tmpStdData["standardName"] = globalStandardDataNameForMainRisk;
        }
        if (customStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = customStandardDataForMainRisk;
          tmpStdData["standardName"] = customStandardDataNameForMainRisk;
        }
        tmpRiskStandardData["objectiveData"] = tmpStdData;
      }
      if (componentId != '') {
        takenStd = true;
        var tmpStdData = {};
        tmpStdData["componentId"] = componentId;
        tmpStdData["componentName"] = componentName;
        if (globalStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = globalStandardDataForMainRisk;
          tmpStdData["standardName"] = globalStandardDataNameForMainRisk;
        }
        if (customStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = customStandardDataForMainRisk;
          tmpStdData["standardName"] = customStandardDataNameForMainRisk;
        }
        tmpRiskStandardData["componentData"] = tmpStdData;
      }
      if (principleId != '') {
        takenStd = true;
        var tmpStdData = {};
        tmpStdData["principleId"] = principleId;
        tmpStdData["principleName"] = principleName;
        if (globalStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = globalStandardDataForMainRisk;
          tmpStdData["standardName"] = globalStandardDataNameForMainRisk;
        }
        if (customStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = customStandardDataForMainRisk;
          tmpStdData["standardName"] = customStandardDataNameForMainRisk;
        }
        tmpRiskStandardData["principleData"] = tmpStdData;
      }
      if (takenStd) {
        tmpArray["riskStandardData"] = tmpRiskStandardData;
      }
    }





    setMainRiskName('');

    if (Object.keys(riskTypeData).length == 0) {
      var arryObj = [];

      arryObj.push(tmpArray);
      tmpArray = {};
      tmpArray["name"] = riskName;
      tmpArray["key"] = riskKey;
      tmpArray["centreId"] = golobalCentreId;
      tmpArray["riskLikelihood"] = intLikelihoodValue;
      tmpArray["riskImpact"] = intImpactVales;
      tmpArray["riskLevel"] = parseInt(intImpactVales) * parseInt(intLikelihoodValue);
      tmpArray["riskAppetite"] = intRiskAppetiteValue;
      tmpArray["riskTypes"] = arryObj;

      setRiskTypeData(tmpArray);
      setLastRiskTmpId(tmpId);
      setShowSaveBtn(true);
      setTimeout(() => {
        var txtField = document.getElementById("txt" + tmpId);
        txtField.focus();
      }, 100);

    } else {

      riskTypeData.riskTypes.push(tmpArray);
      setLastRiskTmpId(tmpId);
      setTimeout(() => {
        var txtField = document.getElementById("txt" + tmpId);
        txtField.focus();
      }, 100);
    }
  }

  const [lastRiskTmpId, setLastRiskTmpId] = React.useState(0);





  // getall risk slider method data
  const [riskCategoryMethodData, setRiskCategoryMethodData] = useState([])
  const [riskCategoryId, setRiskCategoryId] = useState('')
  const [riskCategoryMethodDataAppetite, setRiskCategoryMethodDataAppetite] = useState([])
  const [riskCategoryIdAppetite, setRiskCategoryIdAppetite] = useState('')
  function getAllRisksMethods() {
    axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_RISK + "/all", {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("+++++++++++++++++++All Risk Returns++++++++++++++++++++++++++++");
        console.log(response);
        console.log(response.data[0]);
        setRiskCategoryMethodData(response.data[0].methods)
        setRiskCategoryId(response.data[0].id)
        setRiskCategoryMethodDataAppetite(response.data[0].methods)
        setRiskCategoryIdAppetite(response.data[0].id)
        console.log(response.data[0].methods);

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  /** Save Risk */

  /** Make empty object where name is '' */
  function deleteEmptyObjectWithNameNull(data) {
    data.forEach((item, index) => {
      var canDelete = false;
      if (item.name === "" || item.name === null) {
        canDelete = true;
        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
          canDelete = false;
        }
      }
      if (canDelete) {
        delete item.riskTypeTmpId;
        if (item.riskTypeId != undefined) {
          delete item.riskTypeId;
        }
        delete item.name;
        if (item.riskImpact != undefined) {
          delete item.riskImpact;
        }
        if (item.riskLikelihood != undefined) {
          delete item.riskLikelihood;
        }
        delete item.riskLevel;
        delete item.riskLevelData;
        delete item.riskAppetite;
        delete item.riskAppetiteData;
        if (item.riskStandardData != undefined) {
          delete item.riskStandardData;
        }
        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length === 0) {
          delete item.subRisks;
        }
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
        deleteEmptyObjectWithNameNull(item.subRisks);
      }
    })
  }


  /** Remove Empty Object in a JSON Object */
  function removeEmpty(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        const childObject = removeEmpty(obj[key]);
        if (childObject === undefined) {
          delete obj[key];
        }
      } else if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    });
    return Object.keys(obj).length > 0 || obj instanceof Array ? obj : undefined;
  };


  const [saveRiskFlag, setSaveRiskFlag] = React.useState(false);


  /** For Common Evaluation Only */
  const [openRegisterDataForEval, setOpenRegisterDataForEval] = React.useState(false);
  const [openRegisterDataForEvalScroll, setOpenRegisterDataForEvalScroll] = React.useState('paper');
  const [selectedRegisterEvaluateTitle, setSelectedRegisterEvaluateTitle] = React.useState('');
  const [registerTypeDataForEval, setRegisterTypeDataForEval] = React.useState({});

  function evaluateRiskFunc() {
    if (riskName === "") {
      alert("Please enter Risk Name ");
      return
    }
    riskTypeData["name"] = riskName;
    riskTypeData["key"] = riskKey;
    if (intLikelihoodValue != '') {
      riskTypeData["riskLikelihood"] = intLikelihoodValue;
    }
    if (intImpactVales != '') {
      riskTypeData["riskImpact"] = intImpactVales
    }
    if (intLikelihoodValue != '' && intImpactVales != '') {
      riskTypeData["riskLevel"] = parseInt(intImpactVales) * parseInt(intLikelihoodValue);
    }
    if (intRiskAppetiteValue != '') {
      riskTypeData["riskAppetite"] = intRiskAppetiteValue
    }

    riskTypeData.riskTypes.forEach((item, index) => {
      var canDelete = false;
      if (item.name === "" || item.name === null) {
        canDelete = true;
      }
      if (canDelete) {
        delete item.riskTypeTmpId;
        if (item.riskTypeId != undefined) {
          delete item.riskTypeId;
        }
        delete item.name;
        if (item.riskImpact != undefined) {
          delete item.riskImpact;
        }
        if (item.riskLikelihood != undefined) {
          delete item.riskLikelihood;
        }
        delete item.riskLevel;
        delete item.riskLevelData;
        delete item.riskAppetite;
        delete item.riskAppetiteData;
        if (item.riskStandardData != undefined) {
          delete item.riskStandardData;
        }
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
        deleteEmptyObjectWithNameNull(item.subRisks)
      }
    })

    setTimeout(() => {
      var removeData = removeEmpty(riskTypeData)
      setTimeout(() => {
        var data = JSON.stringify(riskTypeData).replaceAll(",null", "");
        data = data.replaceAll("null,", "");
        data = data.replaceAll("null", "");
        console.log(data);
        var jsonObj = JSON.parse(data);
        console.log(jsonObj);
        setSelectedRegisterEvaluateTitle(jsonObj.name)
        setRegisterTypeDataForEval(jsonObj);
        setOpenRegisterDataForEval(true)
        setOpenRegisterDataForEvalScroll('paper');

      }, 100);
    }, 100);

  }

  function RegisterDataForEvalfunctionClose() {
    setOpenRegisterDataForEval(false)
  }

  /** End of For Common Evaluation Only */


  function saveRisk() {
    if (riskName === "") {
      alert("Please enter Risk Name ");
      return
    }
    riskTypeData["name"] = riskName;
    riskTypeData["key"] = riskKey;
    if (intLikelihoodValue != '') {
      riskTypeData["riskLikelihood"] = intLikelihoodValue;
    }
    if (intImpactVales != '') {
      riskTypeData["riskImpact"] = intImpactVales
    }
    if (intLikelihoodValue != '' && intImpactVales != '') {
      riskTypeData["riskLevel"] = parseInt(intImpactVales) * parseInt(intLikelihoodValue);
    }
    if (intRiskAppetiteValue != '') {
      riskTypeData["riskAppetite"] = intRiskAppetiteValue
    }

    riskTypeData.riskTypes.forEach((item, index) => {
      var canDelete = false;
      if (item.name === "" || item.name === null) {
        canDelete = true;
      }
      if (canDelete) {
        delete item.riskTypeTmpId;
        if (item.riskTypeId != undefined) {
          delete item.riskTypeId;
        }
        delete item.name;
        if (item.riskImpact != undefined) {
          delete item.riskImpact;
        }
        if (item.riskLikelihood != undefined) {
          delete item.riskLikelihood;
        }
        delete item.riskLevel;
        delete item.riskLevelData;
        delete item.riskAppetite;
        delete item.riskAppetiteData;
        if (item.riskStandardData != undefined) {
          delete item.riskStandardData;
        }
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
        deleteEmptyObjectWithNameNull(item.subRisks)
      }
    })
    setWaitingOpen(true);
    setTimeout(() => {
      var removeData = removeEmpty(riskTypeData)
      setTimeout(() => {
        console.log(riskTypeData)
        var data = JSON.stringify(riskTypeData).replaceAll(",null", "");
        data = data.replaceAll("null,", "");
        data = data.replaceAll("null", "");
        console.log("++++++++++++++++Saving data++++++++++++++++++++++");
        console.log(data);
        
        axios(CONFIG.MAIN_URL + '/risk/save', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          data: data,
        })
          .then(function (response) {

            setWaitingOpen(false);
            if (riskIdvalue != 0) {
              alert("Risk Updated successfully");
            } else {
              alert("Risk saved successfully");
            }

            setRiskTypeData(response.data);
            if (updatedRiskHierarchyId != undefined && updatedRiskHierarchyId != null) {
              setUpdatedRiskHierarchyId(response.data.riskId);
            }
            console.log(response.data);
            console.log(JSON.stringify(riskTypeData));
            setSaveRiskFlag(true);
            clearFuctionForUpdate();
            getAllRisks(golobalCentreId);
            setRiskIdvalue(0);
          })
          .catch(function (error) {
            setWaitingOpen(false);
            console.log(error);
            alert("Error saving Risk Type -:" + error);
          });
      }, 100);
    }, 100);
  }

  /** End of previous collection for editing */

  /**
   * all risk
   */



  /**Edit risk Hirachy Data */
  var tmpId = 0;
  function editRiskData(id) {
    setShowRiskHierarchyTable(false)
    setFrameSize(12)
    setDivChange(true)
    console.log(id)
    setEditClickRiskId(id)
    riskTypeName.forEach((data) => {

      //console.log(data)
      if (data.riskId === id) {

        setWaitingOpen(true);
        setRiskIdvalue(data.riskId)
        setRiskName(data.name)
        //alert(data.name)
        //var ele = document.getElementById("txtRiskName");
        //ele.value = data.name;

        if (data.key != undefined && data.key != null && data.key != '') {
          setRiskKey(data.key)


        }
        if (data.riskLikelihood != undefined && data.riskLikelihood != null) {
          setIntLikelihoodValue(data.riskLikelihood);
        }
        if (data.riskImpact != undefined && data.riskImpact != null) {
          setIntImpactVales(data.riskImpact);
        }
        if (data.riskAppetite != undefined && data.riskAppetite != null) {
          setIntRiskAppetiteValue(data.riskAppetite);
        }

        data.riskTypes.forEach((item, index) => {
          item["riskTypeTmpId"] = item.riskTypeId
          if (index === 0) {
            console.log("+++++++++++++++++item+++++++++++++++++++++")
            console.log(item)
            if (item.riskStandardData != undefined && item.riskStandardData != null && item.riskStandardData.standardData != undefined && item.riskStandardData.standardData != null) {
              setChkStandardAllChecked(true);
              setOpenStandardGridAll(true);
              var stdId = '';
              globalStandardData.forEach((item1, index) => {
                if (item1.standardId === item.riskStandardData.standardData.standardId) {
                  setGlobalStandardDataForMainRisk(item1.standardId);
                  stdId = item1.standardId;
                  setGlobalStandardDataNameForMainRisk(item.riskStandardData.standardData.standardName);
                  setCustomStandardDataForMainRisk('');
                  setCustomStandardDataNameForMainRisk('');
                }
              })
              customStandardData.forEach((item1, index) => {
                if (item1.standardId === item.riskStandardData.standardData.standardId) {
                  setGlobalStandardDataForMainRisk('');
                  setGlobalStandardDataNameForMainRisk('');
                  setCustomStandardDataForMainRisk(item1.standardId);
                  stdId = item1.standardId;
                  setCustomStandardDataNameForMainRisk(item.riskStandardData.standardData.standardName);
                }
              })
              if (item.riskStandardData.objectiveData != undefined && item.riskStandardData.objectiveData != null) {
                standardData.forEach((item1) => {
                  if (item1.standardId === stdId) {
                    item1.objectives.forEach((data1) => {
                      if (data1.objectiveId === item.riskStandardData.objectiveData.objectiveId) {
                        setObjectiveId(data1.objectiveId);
                        setObjectiveName(data1.name);
                      }
                    })
                  }
                })
              }
              if (item.riskStandardData.componentData != undefined && item.riskStandardData.componentData != null) {
                standardData.forEach((item1) => {
                  if (item1.standardId === stdId) {
                    item1.components.forEach((data1) => {
                      if (data1.componentId === item.riskStandardData.componentData.componentId) {
                        setComponentId(data1.componentId);
                        setComponentName(data1.name);
                      }
                    })
                  }
                })
              }
              if (item.riskStandardData.principleData != undefined && item.riskStandardData.principleData != null) {
                standardData.forEach((item1) => {
                  if (item1.standardId === stdId) {
                    item1.principles.forEach((data1) => {
                      if (data1.principalId === item.riskStandardData.principleData.principleId) {
                        setPrincipleId(data1.principalId);
                        setPrincipleName(data1.name);
                      }
                    })
                  }
                })
              }
            }

          }
          if (parseInt(item.riskTypeId) > tmpId) {
            tmpId = parseInt(item.riskTypeId);
          }
          if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
            assignExtraAttributes(item.subRisks, tmpId);
          }
        })
        setTimeout(() => {
          setRiskTypeData(data);
          setLastRiskTmpId(tmpId + 1);
          setWaitingOpen(false);
        }, 100);
        setSaveRiskFlag(true);
        setShowSaveBtn(true);
        setSaveBtnName("Update");
        setSaveBtnNameForRisk("Update Risk");
        if (displayOnly != undefined && displayOnly != null && displayOnly === 'OK') {
          setCreateHeaderInfoMessage("Risk Hierarchy")
        } else {
          setCreateHeaderInfoMessage("Edit A Risk Hierarchy")
        }

        setEditGroup(true);
      }
    })
  }

  function editRiskDataFromUploadJson(id, riskTypeNameData) {
    setShowRiskHierarchyTable(false)
    setFrameSize(12)
    setDivChange(true)
    console.log(id)
    setEditClickRiskId(id)
    riskTypeNameData.forEach((data) => {

      //console.log(data)
      if (data.riskId === id) {

        setWaitingOpen(true);
        setRiskIdvalue(data.riskId)
        setRiskName(data.name)
        if (data.key != undefined && data.key != null && data.key != '') {
          setRiskKey(data.key)
        }
        if (data.riskLikelihood != undefined && data.riskLikelihood != null) {
          setIntLikelihoodValue(data.riskLikelihood);
        }
        if (data.riskImpact != undefined && data.riskImpact != null) {
          setIntImpactVales(data.riskImpact);
        }
        if (data.riskAppetite != undefined && data.riskAppetite != null) {
          setIntRiskAppetiteValue(data.riskAppetite);
        }

        data.riskTypes.forEach((item, index) => {
          item["riskTypeTmpId"] = item.riskTypeId
          if (index === 0) {
            console.log("+++++++++++++++++item+++++++++++++++++++++")
            console.log(item)
            if (item.riskStandardData != undefined && item.riskStandardData != null && item.riskStandardData.standardData != undefined && item.riskStandardData.standardData != null) {
              setChkStandardAllChecked(true);
              setOpenStandardGridAll(true);
              var stdId = '';
              if (item.riskStandardData.standardData != undefined && item.riskStandardData.standardData != null) {
                globalStandardData.forEach((item1, index) => {
                  if (item1.standardId === item.riskStandardData.standardData.standardId) {
                    setGlobalStandardDataForMainRisk(item1.standardId);
                    stdId = item1.standardId;
                    setGlobalStandardDataNameForMainRisk(item.riskStandardData.standardData.standardName);
                    setCustomStandardDataForMainRisk('');
                    setCustomStandardDataNameForMainRisk('');
                  }
                })
                customStandardData.forEach((item1, index) => {
                  if (item1.standardId === item.riskStandardData.standardData.standardId) {
                    setGlobalStandardDataForMainRisk('');
                    setGlobalStandardDataNameForMainRisk('');
                    setCustomStandardDataForMainRisk(item1.standardId);
                    stdId = item1.standardId;
                    setCustomStandardDataNameForMainRisk(item.riskStandardData.standardData.standardName);
                  }
                })
              }

              if (item.riskStandardData.objectiveData != undefined && item.riskStandardData.objectiveData != null) {
                standardData.forEach((item1) => {
                  if (item1.standardId === stdId) {
                    item1.objectives.forEach((data1) => {
                      if (data1.objectiveId === item.riskStandardData.objectiveData.objectiveId) {
                        setObjectiveId(data1.objectiveId);
                        setObjectiveName(data1.name);
                      }
                    })
                  }
                })
              }
              if (item.riskStandardData.componentData != undefined && item.riskStandardData.componentData != null) {
                standardData.forEach((item1) => {
                  if (item1.standardId === stdId) {
                    item1.components.forEach((data1) => {
                      if (data1.componentId === item.riskStandardData.componentData.componentId) {
                        setComponentId(data1.componentId);
                        setComponentName(data1.name);
                      }
                    })
                  }
                })
              }
              if (item.riskStandardData.principleData != undefined && item.riskStandardData.principleData != null) {
                standardData.forEach((item1) => {
                  if (item1.standardId === stdId) {
                    item1.principles.forEach((data1) => {
                      if (data1.principalId === item.riskStandardData.principleData.principleId) {
                        setPrincipleId(data1.principalId);
                        setPrincipleName(data1.name);
                      }
                    })
                  }
                })
              }
            }

          }
          if (parseInt(item.riskTypeId) > tmpId) {
            tmpId = parseInt(item.riskTypeId);
          }
          if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
            assignExtraAttributes(item.subRisks, tmpId);
          }
        })
        setTimeout(() => {
          setRiskTypeData(data);
          setLastRiskTmpId(tmpId + 1);
          setWaitingOpen(false);
        }, 100);
        setSaveRiskFlag(true);
        setShowSaveBtn(true);
        setSaveBtnName("Update");
        setSaveBtnNameForRisk("Update Risk");
        setCreateHeaderInfoMessage("Edit A Risk Hierarchy")
        setEditGroup(true);
      }
    })
  }









  function assignExtraAttributes(data, tmpId) {
    data.forEach((item) => {
      item["riskTypeTmpId"] = item.riskTypeId
      if (parseInt(item.riskTypeId) > tmpId) {
        tmpId = parseInt(item.riskTypeId);
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
        assignExtraAttributes(item.subRisks, tmpId);
      }
    })
  }



  //risk Appetite slider

  const [appetitesSliderValue, setAppetiteSliderValue] = React.useState();
  const [appetitesSliderValueStyle, setAppetiteSliderValueStyle] = React.useState('');
  const [appetitesSliderValueName, setAppetiteSliderValueName] = React.useState('');
  const [appetiteSliderType, setAppetiteSliderType] = React.useState('');
  const [appetitesShowSelectBar, setAppetiteShowSelectBar] = React.useState(true);

  // risk level slider
  const [sliderValue, setSliderValue] = React.useState();
  const [sliderValueStyle, setSliderValueStyle] = React.useState('');
  const [sliderValueName, setSliderValueName] = React.useState('');
  const [showSelectBar, setShowSelectBar] = React.useState(true);
  const [appetiteColorForSaveToDb, setAppetiteColorForSaveToDb] = useState('')

  //console.log(databClr)
  function getColorCode(number) {
    var cCode = "#ffffff";
    databClr.forEach((data) => {
      if (parseInt(number) === parseInt(data.scoreNumber)) {
        cCode = data.color;
      }
    })
    return cCode;
  }

  function getColorCodeAppetite(number) {
    var cCode = "#ffffff";
    databClr.forEach((data) => {
      if (selectedColorForAppetiteSlider === 1) {
        if (parseInt(number) === parseInt(data.scoreNumber)) {
          cCode = data.color;
          setAppetiteColorForSaveToDb(cCode)
        }
      }
      else {
        if (parseInt(number) === parseInt(data.scoreNumber)) {
          cCode = data.secondaryColor;
          setAppetiteColorForSaveToDb(cCode)
        }
      }

    })
    return cCode;
  }




  const [openStandardGridAll, setOpenStandardGridAll] = useState(false);
  const [chkStandardAllChecked, setChkStandardAllChecked] = useState(false);

  function activeStandardGridForAll(flag) {
    setChkStandardAllChecked(flag);
    setOpenStandardGridAll(flag);
  }

  const [openStandardGrid, setOpenStandardGrid] = useState(false);


  const [frameSize, setFrameSize] = useState(9)
  const [displayTitle, setDisplayTitle] = useState(true)

  useEffect(() => {
    if (window.screen.width < 811) {
      setDisplayTitle(false)
      console.log("+++++++++++++++window. screen. width++++++++++++++++++")
      console.log(window.screen.width)
      setFrameSize(12);
    }
  }, [])


  /* set global and custem standard for main risk */
  const [globalStandardDataForMainRisk, setGlobalStandardDataForMainRisk] = useState('')
  const [globalStandardDataNameForMainRisk, setGlobalStandardDataNameForMainRisk] = useState('')
  const [customStandardDataForMainRisk, setCustomStandardDataForMainRisk] = useState('')
  const [customStandardDataNameForMainRisk, setCustomStandardDataNameForMainRisk] = useState('')

  const handleChangeGlobalStandardDataForMainRisk = (event) => {
    setGlobalStandardDataForMainRisk(event.target.value);
    globalStandardData.forEach((item, index) => {
      if (item.standardId === event.target.value) {
        setGlobalStandardDataNameForMainRisk(item.standardName);
      }
    })
    setCustomStandardDataForMainRisk('')
    setObjectiveId('');
    setComponentId('');
    setPrincipleId('');
  }

  const handleChangeCustomStandardDataForMainRisk = (event) => {
    setCustomStandardDataForMainRisk(event.target.value);
    customStandardData.forEach((item, index) => {
      if (item.standardId === event.target.value) {
        setGlobalStandardDataNameForMainRisk(item.standardName);
      }
    })
    setGlobalStandardDataForMainRisk('')
    setObjectiveId('');
    setComponentId('');
    setPrincipleId('');
  }

  /* set  standard for risk name*/

  const [globalStandardIdForAll, setGlobalStandardIdForAll] = useState('')
  const [globalStandardNameForAll, setGlobalStandardNameForAll] = useState('')
  const [customStandardIdForAll, setCustomStandardIdForAll] = useState('')
  const [customStandardNameForAll, setCustomStandardNameForAll] = useState('')


  const [objectiveId, setObjectiveId] = useState('')
  const [objectiveName, setObjectiveName] = useState('')

  const [componentId, setComponentId] = useState('')
  const [componentName, setComponentName] = useState('')

  const [principleId, setPrincipleId] = useState('')
  const [principleName, setPrincipleName] = useState('')


  const handleChangeObjectives = (event) => {
    setObjectiveId(event.target.value)
    standardData.forEach((item) => {
      if (item.standardId === globalStandardDataForMainRisk || item.standardId === customStandardDataForMainRisk) {
        item.objectives.forEach((data) => {
          if (data.objectiveId === event.target.value) {
            setObjectiveName(data.name);
          }
        })
      }
    })
  }

  const handleChangecomponent = (event) => {
    setComponentId(event.target.value);
    standardData.forEach((item) => {
      if (item.standardId === globalStandardDataForMainRisk || item.standardId === customStandardDataForMainRisk) {
        item.components.forEach((data) => {
          if (data.componentId === event.target.value) {
            setComponentName(data.name);
          }
        })
      }
    })
  }

  const handleChangeprinciple = (event) => {
    setPrincipleId(event.target.value)
    standardData.forEach((item) => {
      if (item.standardId === globalStandardDataForMainRisk || item.standardId === customStandardDataForMainRisk) {
        item.principles.forEach((data) => {
          if (data.principalId === event.target.value) {
            setPrincipleName(data.name);
          }
        })
      }
    })
  }



  const [selectedColorForAppetiteSlider, setSelectedColorForAppetiteSlider] = useState(1)

  const radioHandler = (status) => {
    setSelectedColorForAppetiteSlider(status);
    if (selectedColorForAppetiteSlider != status) {
      setAppetiteSliderValueStyle('')
      setAppetiteSliderValueName('')
      setAppetiteSliderValue('')
    }
  };




  /** To build a empty textField object with default values */
  function objectBuild(id) {
    var tmpArray = {};
    tmpArray["riskTypeTmpId"] = id;
    tmpArray["name"] = "";

    tmpArray["riskLikelihood"] = intLikelihoodValue;
    tmpArray["riskImpact"] = intImpactVales;
    tmpArray["riskLevel"] = parseInt(intImpactVales) * parseInt(intLikelihoodValue);

    var tmpArray1 = {}
    tmpArray1["riskLikelihood"] = intLikelihoodValue;
    tmpArray1["riskImpact"] = intImpactVales;
    tmpArray1["riskLevel"] = parseInt(intImpactVales) * parseInt(intLikelihoodValue);
    tmpArray1["riskCategoryId"] = "";
    tmpArray1["riskMethodId"] = "";
    tmpArray["riskLevelData"] = tmpArray1;

    tmpArray["riskAppetite"] = intRiskAppetiteValue;
    var tmpArrayApp = {};
    tmpArrayApp["riskAppetite"] = intRiskAppetiteValue;
    tmpArrayApp["riskCategoryId"] = "";
    tmpArrayApp["riskMethodId"] = "";
    var cCode = getColorCodeValue(intRiskAppetiteValue, 2);
    tmpArrayApp["color"] = "";
    tmpArrayApp["secondaryColor"] = cCode;
    tmpArray["riskAppetiteData"] = tmpArrayApp;

    //For Standared
    if (document.getElementById('chkStandard') != undefined && document.getElementById('chkStandard') != null && document.getElementById('chkStandard').checked) {
      var tmpRiskStandardData = {};
      var takenStd = false;
      if (globalStandardDataForMainRisk != '' || customStandardDataForMainRisk != '') {
        takenStd = true;
        var tmpStdData = {}
        if (globalStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = globalStandardDataForMainRisk
          tmpStdData["standardName"] = globalStandardDataNameForMainRisk
        }
        if (customStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = customStandardDataForMainRisk
          tmpStdData["standardName"] = customStandardDataNameForMainRisk
        }
        tmpRiskStandardData["standardData"] = tmpStdData;
      }
      if (objectiveId != '') {
        takenStd = true;
        var tmpStdData = {};
        tmpStdData["objectiveId"] = objectiveId;
        tmpStdData["objectiveName"] = objectiveName;
        if (globalStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = globalStandardDataForMainRisk;
          tmpStdData["standardName"] = globalStandardDataNameForMainRisk;
        }
        if (customStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = customStandardDataForMainRisk;
          tmpStdData["standardName"] = customStandardDataNameForMainRisk;
        }
        tmpRiskStandardData["objectiveData"] = tmpStdData;
      }
      if (componentId != '') {
        takenStd = true;
        var tmpStdData = {};
        tmpStdData["componentId"] = componentId;
        tmpStdData["componentName"] = componentName;
        if (globalStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = globalStandardDataForMainRisk;
          tmpStdData["standardName"] = globalStandardDataNameForMainRisk;
        }
        if (customStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = customStandardDataForMainRisk;
          tmpStdData["standardName"] = customStandardDataNameForMainRisk;
        }
        tmpRiskStandardData["componentData"] = tmpStdData;
      }
      if (principleId != '') {
        takenStd = true;
        var tmpStdData = {};
        tmpStdData["principleId"] = principleId;
        tmpStdData["principleName"] = principleName;
        if (globalStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = globalStandardDataForMainRisk;
          tmpStdData["standardName"] = globalStandardDataNameForMainRisk;
        }
        if (customStandardDataForMainRisk != '') {
          tmpStdData["standardId"] = customStandardDataForMainRisk;
          tmpStdData["standardName"] = customStandardDataNameForMainRisk;
        }
        tmpRiskStandardData["principleData"] = tmpStdData;
      }
      if (takenStd) {
        tmpArray["riskStandardData"] = tmpRiskStandardData;
      }
    }



    return tmpArray;
  }

  /** Escape key handle in Risk section*/
  function escapeKeyPressedFunction() {
    var mainTextField = document.getElementById("txt0");
    mainTextField.focus();
  }


  /** Find Next riskTypeTmpId for All instant */
  var nextRiskTypeTmpId = 0;
  function findNextRiskTypeTmpId() {
    nextRiskTypeTmpId = 0;
    if (riskTypeData.riskTypes != undefined) {
      riskTypeData.riskTypes.forEach((item, index) => {
        var itemId = item.riskTypeTmpId
        if (parseInt(itemId) >= nextRiskTypeTmpId) {
          nextRiskTypeTmpId = parseInt(itemId) + 1;
        }
        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
          findNextRiskTypeTmpIdsInOthers(item.subRisks)
        }
      })
    } else {
      nextRiskTypeTmpId = 1;
    }
  }
  function findNextRiskTypeTmpIdsInOthers(data) {
    data.forEach((item) => {
      var itemId = item.riskTypeTmpId
      if (parseInt(itemId) >= nextRiskTypeTmpId) {
        nextRiskTypeTmpId = parseInt(itemId) + 1;
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
        findNextRiskTypeTmpIdsInOthers(item.subRisks);
      }
    })

  }

  const [openRiskGpt, setOpenRiskGpt] = React.useState(false);
  const [openRiskGptScroll, setOpenRiskGptScroll] = React.useState('paper');

  const [riskDescriptionText, setRiskDescriptionText] = React.useState('');
  const [impactDescriptionText, setImpactDescriptionText] = React.useState('');
  const [mitigationText, setMitigationText] = React.useState('');
  const [selectedRiskId, setSelectedRiskId] = React.useState('');
  const [selectedRiskName, setSelectedRiskName] = React.useState('');


  const handleChangeRiskDescriptionText = (e) => {
    setRiskDescriptionText(e.target.value);
  }
  const handleChangeImpactDescriptionText = (e) => {
    setImpactDescriptionText(e.target.value);
  }
  const handleChangeMitigationText = (e) => {
    setMitigationText(e.target.value);
  }

  function displayMitigation(comment) {
    console.log(comment)
    console.log(riskTypeData)
    setSelectedRiskName(comment.riskDescription)
    setRiskDescriptionText(comment.riskDescription);
    setImpactDescriptionText(comment.impactDescription);
    setMitigationText(comment.mitigation);
    setSelectedRiskId(comment.riskTypeId);

    setOpenRiskGpt(true)
    setOpenRiskGptScroll('paper')
  }

  function handleMitigationUpdate() {
    var fondSelected = false;
    riskTypeData.riskTypes.map((riskType) => {
      if (selectedRiskId === riskType.riskTypeId){
        riskType["riskDescription"] = riskDescriptionText;
        riskType["impactDescription"] = impactDescriptionText;
        riskType["mitigation"] = mitigationText;
        fondSelected = true;
      }
    })
    console.log(fondSelected)
    if (!fondSelected){
      findItemInSubRisks();
    }else{
      setRiskTypeData(riskTypeData);
      setOpenRiskGpt(false)
    }
  }
  function findItemInSubRisks() {
    var fondSelected = false;
    riskTypeData.riskTypes.map((riskType) => {
      riskType.subRisks.map((subRisk) => {
        if (selectedRiskId === subRisk.riskTypeId){
          subRisk["riskDescription"] = riskDescriptionText;
          subRisk["impactDescription"] = impactDescriptionText;
          subRisk["mitigation"] = mitigationText;
          fondSelected = true;
        }
      })
    })
    if (!fondSelected){
      findItemInSubSubRisks();
    }else{
      setRiskTypeData(riskTypeData);
      setOpenRiskGpt(false)
    }
  }

  function findItemInSubSubRisks() {
    var fondSelected = false;
    riskTypeData.riskTypes.map((riskType) => {
      riskType.subRisks.map((subRisk) => {
        subRisk.subRisks.map((subRisk1) => {
          if (selectedRiskId === subRisk1.riskTypeId){
            subRisk1["riskDescription"] = riskDescriptionText;
            subRisk1["impactDescription"] = impactDescriptionText;
            subRisk1["mitigation"] = mitigationText;
            fondSelected = true;
          }
        })
      })
    })
    if (!fondSelected){
      findItemInSubSubSubRisks();
    }else{
      setRiskTypeData(riskTypeData);
      setOpenRiskGpt(false)
    }
  }

  function findItemInSubSubSubRisks() {
    var fondSelected = false;
    riskTypeData.riskTypes.map((riskType) => {
      riskType.subRisks.map((subRisk) => {
        subRisk.subRisks.map((subRisk1) => {
          subRisk1.subRisks.map((subRisk2) => {
            if (selectedRiskId === subRisk2.riskTypeId){
              subRisk2["riskDescription"] = riskDescriptionText;
              subRisk2["impactDescription"] = impactDescriptionText;
              subRisk2["mitigation"] = mitigationText;
              fondSelected = true;
            }
          })
        })
      })
    })
    if (!fondSelected){
      findItemInSubSubSubSubRisks();
    }else{
      setRiskTypeData(riskTypeData);
      setOpenRiskGpt(false)
    }
  }

  function findItemInSubSubSubSubRisks() {
    var fondSelected = false;
    riskTypeData.riskTypes.map((riskType) => {
      riskType.subRisks.map((subRisk) => {
        subRisk.subRisks.map((subRisk1) => {
          subRisk1.subRisks.map((subRisk2) => {
            subRisk2.subRisks.map((subRisk3) => {
              if (selectedRiskId === subRisk3.riskTypeId){
                subRisk3["riskDescription"] = riskDescriptionText;
                subRisk3["impactDescription"] = impactDescriptionText;
                subRisk3["mitigation"] = mitigationText;
                fondSelected = true;
              }
            })
          })
        })
      })
    })
    if (!fondSelected){
      findItemInSubSubSubSubSubRisks();
    }else{
      setRiskTypeData(riskTypeData);
      setOpenRiskGpt(false)
    }
  }
  function findItemInSubSubSubSubSubRisks() {
    var fondSelected = false;
    riskTypeData.riskTypes.map((riskType) => {
      riskType.subRisks.map((subRisk) => {
        subRisk.subRisks.map((subRisk1) => {
          subRisk1.subRisks.map((subRisk2) => {
            subRisk2.subRisks.map((subRisk3) => {
              subRisk3.subRisks.map((subRisk4) => {
                if (selectedRiskId === subRisk4.riskTypeId){
                  subRisk4["riskDescription"] = riskDescriptionText;
                  subRisk4["impactDescription"] = impactDescriptionText;
                  subRisk4["mitigation"] = mitigationText;
                  fondSelected = true;
                  setRiskTypeData(riskTypeData);
                  setOpenRiskGpt(false)
                }
              })
            })
          })
        })
      })
    })
  }





  function Comment({ comment, lastRiskTmpId }) {
    const [subLastRiskTmpId, setSubLastRiskTmpId] = React.useState(lastRiskTmpId);
    const [show, setShow] = useState(true)
    const nestedComments = (comment.subRisks || []).map(comment => {
      return <Comment key={comment.riskTypeTmpId} comment={comment} lastRiskTmpId={subLastRiskTmpId} type="child" />
    })

    function onBlurChageEvent(event) {
      comment["name"] = event.target.value
    }

    

    var commentArr = [];
    function handleKeyDown(event) {
      if (event.keyCode == 27) {
        escapeKeyPressedFunction();
        return;
      }
      var fieldValue = event.target.value;
      var shidtKeyPressed = false;
      if (event.shiftKey && event.keyCode == 9) {
        shidtKeyPressed = true;
      }
      comment["name"] = fieldValue;
      if (event.keyCode == 9 && fieldValue != '' && !shidtKeyPressed) {
        var txtRiskLikelihood = document.getElementById("txtRiskLikelihood" + comment.riskTypeTmpId);
        var txtRiskImpact = document.getElementById("txtRiskImpact" + comment.riskTypeTmpId);
        var txtRiskLevel = document.getElementById("txtRiskLevel" + comment.riskTypeTmpId);
        var txtRiskAppetite = document.getElementById("txtRiskAppetite" + comment.riskTypeTmpId);

        txtRiskLikelihood.disabled = true;
        txtRiskImpact.disabled = true;
        txtRiskLevel.disabled = true;
        txtRiskAppetite.disabled = true;
        setTimeout(() => {
          txtRiskLikelihood.disabled = false;
          txtRiskImpact.disabled = false;
          txtRiskLevel.disabled = false;
          txtRiskAppetite.disabled = false;
        }, 1000);
      }
      if (event.keyCode == 9 && fieldValue != '' && !shidtKeyPressed) {
        if (comment.subRisks === undefined) {

          var arryObj = [];
          var nextNo = findNextRiskTypeTmpId();
          var tmpId = nextRiskTypeTmpId; //(subLastRiskTmpId + 1);
          var tmpArray = objectBuild((tmpId));

          arryObj.push(tmpArray);
          comment["subRisks"] = arryObj;
          setSubLastRiskTmpId((tmpId));
          setShow(true);
          setTimeout(() => {
            var txtField = document.getElementById("txt" + tmpId);
            txtField.focus();
          }, 1000);

        } else {
          var nextNo = findNextRiskTypeTmpId();
          var tmpId = nextRiskTypeTmpId; //(subLastRiskTmpId + 1);
          var tmpArray = objectBuild((tmpId));
          comment.subRisks.push(tmpArray);
          setSubLastRiskTmpId(tmpId);
          setTimeout(() => {
            var btnElement = document.getElementById("btn" + comment.riskTypeTmpId);
            setShow(false);
            btnElement.click();
            var txtElement = document.getElementById("txt" + subLastRiskTmpId + 1);
            if (txtElement != undefined) {
              txtElement.focus();
            }
            setTimeout(() => {
              var txtField = document.getElementById("txt" + tmpId);
              txtField.focus();
            }, 1000);



          }, 100);
        }
      }
      if (event.keyCode == 9 && fieldValue === '' && !shidtKeyPressed) {

        riskTypeData.riskTypes.forEach((item, index) => {
          if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
            commentArr = [];
            var rtn = findHirachyParent2(item.subRisks, comment);
            /** When remove from the top */
            if (commentArr.length === 0) {
              setTimeout(() => {
                var btnElement = document.getElementById("btn1");
                setShow(false)
                btnElement.click();
                escapeKeyPressedFunction();
                return
              }, 100);
            }
            if (commentArr.length === 1) {
              var tmpArr = [];
              tmpArr.push(item);
              var tmpArray = [];
              tmpArray.push(tmpArr);
              tmpArray.push(commentArr[0]);
              commentArr = [];
              commentArr.push(tmpArray[0]);
              commentArr.push(tmpArray[1]);
            }
            if (commentArr.length > 0) {
              var itemCount = 0;
              var tmpArr = [];
              var updatedId = 0;
              commentArr.slice().reverse().forEach(function (entry) {
                itemCount++
                if (itemCount === 2) {
                  updatedId = entry[0].riskTypeTmpId;
                  var nextNo = findNextRiskTypeTmpId();
                  var tmpId = nextRiskTypeTmpId; //(subLastRiskTmpId + 1);
                  var tmpArray = objectBuild(tmpId);;

                  setTimeout(() => {
                    entry[0].subRisks.push(tmpArray);
                    tmpArr = entry[0].subRisks;
                    setSubLastRiskTmpId(tmpId);

                    var btnId = "btn" + updatedId;
                    var btnElement = document.getElementById(btnId);
                    btnElement.click();
                    setTimeout(() => {
                      btnElement.click();
                      var btnElement1 = document.getElementById("txt" + (tmpId));
                      if (btnElement1 != undefined) {
                        btnElement1.focus();
                      }
                    }, 100);
                  }, 500);
                }
              });
            }
          }
        })
      }
    }

    function findHirachyParent2(data, comment1) {
      var found = false;
      data.forEach((item, index) => {
        if (item.riskTypeTmpId === comment1.riskTypeTmpId) {
          found = true;
          delete item.riskTypeTmpId;
          if (item.riskTypeId != undefined) {
            delete item.riskTypeId;
          }
          delete item.name;
          if (item.riskImpact != undefined) {
            delete item.riskImpact;
          }
          if (item.riskLikelihood != undefined) {
            delete item.riskLikelihood;
          }
          delete item.riskLevel;
          delete item.riskLevelData;
          delete item.riskAppetite;
          delete item.riskAppetiteData;
          if (item.riskStandardData != undefined) {
            delete item.riskStandardData;
          }

        }
      })
      if (!found) {
        commentArr.push(data);
        data.forEach((item, index) => {
          if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
            findHirachyParent2(item.subRisks, comment1);
          }
        })
      } else {
        return commentArr;
      }
    }

    function setFocusStyle(varName) {
      if (varName != '') {
        varName(true);
        setTimeout(() => {
          varName(false);
          setTimeout(() => {
            varName(true);
            setTimeout(() => {
              varName(false);
              setTimeout(() => {
                varName(true);
                setTimeout(() => {
                  varName(false);
                }, 500);
              }, 500);
            }, 500);
          }, 500);
        }, 500);
      }
    }
    const [elementFocus, setElementFocus] = React.useState(false);
    React.useEffect(() => {
      if (subLastRiskTmpId === comment.riskTypeTmpId) {
        setFocusStyle(setElementFocus);
      }
    }, [])


    function setShowFunction(flag) {
      setShow(flag);
    }

    function deleteFunction() {
      var id = comment.riskTypeTmpId;
      var name = comment.name;
      var subData = comment.subRisks;
      var cannotDelete = true;
      if (name != null && name != '') {
        cannotDelete = false;
      }
      if (subData != undefined && subData != null && subData.length > 0) {
        var canDelete = true
        subData.forEach((item, index) => {
          if (item.riskTypeTmpId != undefined && item.riskTypeTmpId != null) {
            canDelete = false
          }
        })
        if (!canDelete) {
          cannotDelete = false;
        }
      }

      if (cannotDelete) {
        delete comment.riskTypeTmpId;
        if (comment.riskTypeId != undefined) {
          delete comment.riskTypeId;
        }
        delete comment.name;
        if (comment.riskImpact != undefined) {
          delete comment.riskImpact;
        }
        if (comment.riskLikelihood != undefined) {
          delete comment.riskLikelihood;
        }
        delete comment.riskLevel;
        delete comment.riskLevelData;
        delete comment.riskAppetite;
        delete comment.riskAppetiteData;
        if (comment.riskStandardData != undefined) {
          delete comment.riskStandardData;
        }
        setTimeout(() => {
          var btnId = "btn" + id;
          var btnElement = document.getElementById(btnId);
          if (btnElement != undefined) {
            btnElement.click();
          }
        }, 500);
      }

    }

    const [intImpactValesForEachRisk, setIntImpactValesForEachRisk] = useState('');
    const [intRiskAppetiteValueForEachRisk, setIntRiskAppetiteValueForEachRisk] = useState('');
    const [intRiskTypeValueForEachRisk, setIntRiskTypeValueForEachRisk] = useState('');
    const [intLikelihoodValueForEachRisk, setIntLikelihoodValueForEachRisk] = useState('');

    const onTextFieldChangeLikelihood = (event) => {

      if (event != null && event.target.value != null) {
        if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
          event.target.value = ''
        } else {
          comment["riskLikelihood"] = event.target.value;
          comment.riskLevelData["riskLikelihood"] = event.target.value;
          setIntLikelihoodValueForEachRisk(event.target.value)
        }
      }
    }

    const onTextFieldChangeImpact = (event) => {
      if (event != null && event.target.value != null) {
        if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
          event.target.value = ''
        } else {
          comment["riskImpact"] = event.target.value;
          comment.riskLevelData["riskImpact"] = event.target.value;
          setIntImpactValesForEachRisk(event.target.value)
        }
      }
    }

    const onTextFieldChangeAppetite = (event) => {
      if (event != null && event.target.value != null) {
        if (event.target.value <= 0 || event.target.value > 10 || !Number.isInteger(parseInt(event.target.value))) {
          event.target.value = ''
          var eleId = document.getElementById(event.target.id);
          setTimeout(() => {
            if (eleId != undefined) {
              eleId.focus();
            }
          }, 300);

        } else {
          comment["riskAppetite"] = event.target.value
          comment.riskAppetiteData["riskAppetite"] = event.target.value
        }
      }
      //setIntRiskAppetiteValueForEachRisk(event.target.value);
    }

    function handleKeyDownAppetite(event) {
      var fieldValue = event.target.value;
      var shidtKeyPressed = false;
      if (event.shiftKey && event.keyCode == 9) {
        shidtKeyPressed = true;
      }
      comment["riskAppetite"] = fieldValue;
      comment.riskAppetiteData["riskAppetite"] = fieldValue;
      if (event.keyCode == 9 && fieldValue != '' && !shidtKeyPressed) {
        var id = event.target.id;
        var idNo = id.replace("txtRiskAppetite", "");
        if (idNo != undefined && idNo != null && idNo != '') {
          setTimeout(() => {
            var nextNo = parseInt(idNo) + 1;
            var nextEle = document.getElementById("txtRiskLikelihood" + nextNo);
            if (nextEle != undefined) {
              nextEle.focus();
            } else {
              var tArr = [2, 3, 4, 5, 6, 7, 8, 9, 10]
              var BreakException = "Break Loop";
              try {
                tArr.forEach((item) => {
                  nextNo = parseInt(idNo) + item;
                  nextEle = document.getElementById("txtRiskLikelihood" + nextNo);
                  if (nextEle != undefined) {
                    nextEle.focus();
                    throw BreakException;
                  }
                })
              } catch (e) {
              }
            }
          }, 100);

        }

      }
    }

    function calculatedRiskLevelForEachRish() {
      var x = '';
      x = parseInt(comment.riskLikelihood) * parseInt(comment.riskImpact)
      comment["riskLevel"] = x;
      comment.riskLevelData["riskLevel"] = x;

      return x;

    }

    return (

      <div style={{ "marginLeft": "15px", "marginTop": "10px" }}>
        {comment.riskTypeTmpId != undefined ?
          <Grid container spacing={1}>

            <div style={{ display: 'flex', gap: '2px', textAlign: 'center' }}>
              <Button
                className='riskBtn'
                id={`btn` + comment.riskTypeTmpId}
                onClick={() => setShowFunction(!show)}
                startIcon={show ? <ExpandMoreIcon style={{ height: '30px', fontSize: '38px', marginTop: '20px' }} /> : <ChevronRightIcon style={{ height: '30px', fontSize: '38px', marginTop: '20px' }} />}
              >
              </Button>


              <TextField
                size="small"
                id={`txt` + comment.riskTypeTmpId}
                label="Edit Risk Name"
                defaultValue={comment.name}
                title={comment.name}
                onBlur={onBlurChageEvent}
                onKeyDown={e => handleKeyDown(e)}
                className={elementFocus ? classes.focusBorder : classes.timeOutBorder}
                style={displayTitle ? { height: '44px' } : { height: '44px', marginLeft: '-25px' }}
              />
              <DeleteForeverOutlinedIcon
                onClick={() => deleteFunction(comment)}
                style={{ height: '44px', width: '44px', marginTop: '-7px', cursor: 'pointer', color: '#838383', marginLeft: '-9px' }}
              />

              <TextField
                size="small"
                id={`txtRiskLikelihood` + comment.riskTypeTmpId}
                sx={{
                  width: '100%',
                  "& .MuiInputBase-root": {
                    height: 31,
                    padding: '1.5px 1px !important',
                    marginTop: -0.5
                  },
                  "& .MuiInputLabel-root": {
                    marginTop: -0.5
                  },
                  "& .MuiInputBase-input": {
                    padding: '1.5px 1px !important',
                    fontSize: '11px !important'
                  }
                }}
                name='Likelihood'
                label={displayTitle ? 'Likelihood' : ''}
                defaultValue={comment.riskLikelihood}
                title={comment.riskLikelihood}
                onChange={onTextFieldChangeLikelihood}
                className='appetiteTxt'
              />

              {/** 



          <TextField variant="outlined"
            id={`txtRiskLikelihood` + comment.riskTypeTmpId}
            size='small'
            label="Likelihood"
            name='Likelihood'
            defaultValue={comment.riskLikelihood}
            onChange={onTextFieldChangeLikelihood}
            style={{width:'75px'}}
          />
          */}
              <TextField
                size="small"
                id={`txtRiskImpact` + comment.riskTypeTmpId}
                sx={{
                  width: '100%',
                  "& .MuiInputBase-root": {
                    height: 31,
                    padding: '1.5px 1px !important',
                    marginTop: -0.5
                  },
                  "& .MuiInputLabel-root": {
                    marginTop: -0.5
                  },
                  "& .MuiInputBase-input": {
                    padding: '1.5px 1px !important',
                    fontSize: '11px !important'
                  }
                }}
                name='Impact'
                label={displayTitle ? 'Impact' : ''}
                defaultValue={comment.riskImpact}
                title={comment.riskLikelihood}
                onChange={onTextFieldChangeImpact}
                className='appetiteTxt'
              />

              {/** 

          <TextField  variant="outlined"
            id={`txtRiskImpact` + comment.riskTypeTmpId}
            size='small'
            label="Impact"
            name='Impact'
            defaultValue={comment.riskImpact}
            onChange={onTextFieldChangeImpact}
            style={{width:'75px'}} 
          />

          */}

              <TextField
                size="small"
                id={`txtRiskLevel` + comment.riskTypeTmpId}
                sx={{
                  width: '100%',
                  "& .MuiInputBase-root": {
                    height: 31,
                    padding: '1.5px 1px !important',
                    marginTop: -0.5
                  },
                  "& .MuiInputLabel-root": {
                    marginTop: -0.5
                  },
                  "& .MuiInputBase-input": {
                    padding: '1.5px 1px !important',
                    fontSize: '11px !important'
                  }
                }}
                name='Risk Level'
                label={displayTitle ? 'Risk Level' : ''}
                value={calculatedRiskLevelForEachRish()}
                title={comment.riskLikelihood}
                className='appetiteTxt'
              />

              {/** 

          <TextField
            id={`txtRiskLevel` + comment.riskTypeTmpId}
            label="Risk Level"
            variant="outlined"
            value={calculatedRiskLevelForEachRish()}
            size='small'
            style={{width:'75px'}} 
          />
          */}

              <TextField
                size="small"
                id={`txtRiskAppetite` + comment.riskTypeTmpId}
                sx={{
                  width: '100%',
                  "& .MuiInputBase-root": {
                    height: 31,
                    padding: '1.5px 1px !important',
                    marginTop: -0.5
                  },
                  "& .MuiInputLabel-root": {
                    marginTop: -0.5
                  },
                  "& .MuiInputBase-input": {
                    padding: '1.5px 1px !important',
                    fontSize: '11px !important'
                  }
                }}
                name='Appetite'
                label={displayTitle ? 'Appetite' : ''}
                defaultValue={comment.riskAppetite}
                onBlur={onTextFieldChangeAppetite}
                title={comment.riskAppetite}
                onKeyDown={e => handleKeyDownAppetite(e)}
                className='appetiteTxt'
              />

              {/** 
          <TextField variant="outlined"
            id={`txtRiskAppetite` + comment.riskTypeTmpId}
            size='small'
            label="Appetite"
            name='Appetite'
            defaultValue={comment.riskAppetite}
            onBlur={onTextFieldChangeAppetite}
            onKeyDown={e => handleKeyDownAppetite(e)}
            style={{width:'75px'}} 
          />
          */}


              {comment.mitigation != undefined && comment.mitigation != null ?
                <>
                    <Tooltip title={<span style={{ fontSize: "12px" }}><u>Risk Description:</u> {comment.riskDescription} <br></br><u>Impact Description: </u> {comment.impactDescription}<br></br><u>Mitigation:</u> {comment.mitigation}</span>}>
                      <Button variant='outlined' style={{ padding: "3px 3px", marginTop: '-4px', height: '31px' }} onClick={() => displayMitigation(comment)}>&nbsp; Edit Others</Button>
                    </Tooltip>
                </>
                :
                ''
              }



            </div>
          </Grid>
          : ''
        }
        {
          show ?
            <>
              {nestedComments}
            </>
            : null
        }
      </div>
    )

  }

  const [divChange, setDivChange] = useState(false)



  return (
    <>
      {/* className={classes.pageTop} */}
      <div className={classes.pageBodyStyle}>

        <Grid container spacing={2}>
          <Grid item className={divChange ? 'cardRiskManage2' : 'cardRiskManage1'}>
            <Card style={{ border: '1px solid black' }} >


              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                    <Info />
                  </Avatar>
                }
                className={classe.commonMainTableHead}
                title={createheaderInfoMessage}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Box component="form" sx={{ '& .MuiTextField-root': { mt: 1, width: '100%' }, }} noValidate autoComplete="off">
                      <div>
                        <Autocomplete
                          id="txtRiskKey"
                          freeSolo
                          options={riskTypeKeys.map((option) => option.key)}
                          //value={riskKey}
                          inputValue={riskKey}
                          onInputChange={(event, newInputValue) => {
                            setRiskKey(newInputValue);
                          }}
                          renderInput={(params) => <TextField {...params} value={riskKey} id="txtRiskKeyTextField" onChange={onTextFieldChangeKey} onBlur={onTextFieldOnBlurKey} label="User Reference (optional)" />}
                        />
                      </div>
                    </Box>
                  </Grid>

                  <Grid item xs={8}>
                    <Box component="form" sx={{ '& .MuiTextField-root': { mt: 1, width: '100%' }, }} noValidate autoComplete="off">
                      <div>
                        <Autocomplete

                          id="txtRiskName"
                          freeSolo
                          options={riskTypeName.map((option) => option.name)}
                          //value={riskName}
                          inputValue={riskName}
                          onInputChange={(event, newInputValue) => {
                            setRiskName(newInputValue);
                          }}
                          renderInput={(params) => <TextField {...params} value={riskName} onChange={onTextFieldChange} onBlur={onTextFieldOnBlurName} label={<>Risk Hierarchy Name <span style={{ color: 'red' }}>*</span></>} />}
                        />
                      </div>
                    </Box>
                  </Grid>
                  <Grid item className='gridd1'>
                    <Autocomplete
                      value={intLikelihoodValue}
                      onChange={(event, intLikelihoodValue) => {
                        if (intLikelihoodValue != null) {
                          setIntLikelihoodValue(intLikelihoodValue);
                        }
                      }}
                      inputValue={intLikelihoodValue}
                      onInputChange={(event) => {
                        if (event != null && event.target.value != null) {
                          if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                            setIntLikelihoodValue('')
                          } else {
                            setIntLikelihoodValue(event.target.value);
                          }
                        }
                      }}
                      options={options}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<>Likelihood <span style={{ color: 'red' }}>*</span></>}
                          variant="outlined"
                        //onChange={handleChangeIntLikelihood}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item className='gridd2'>
                    <Autocomplete
                      value={intImpactVales}
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          setIntImpactVales(newValue);
                        }
                      }}
                      inputValue={intImpactVales}
                      onInputChange={(event, newInputValue) => {
                        if (event != null && event.target.value != null) {
                          if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                            setIntImpactVales('')
                          } else {
                            setIntImpactVales(event.target.value);
                          }
                        }
                      }}
                      options={options}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="txtImpact"
                          label={<>Impact <span style={{ color: 'red' }}>*</span></>}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item className='gridd3'>
                    <TextField
                      id="outlined-basic"
                      label="Risk Level"
                      variant="outlined"
                      value={calculatedRiskLevel()}
                      inputProps={{
                        style: {
                          height: "22px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item className='gridd4'>
                    <Autocomplete
                      value={intRiskAppetiteValue}
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          setIntRiskAppetiteValue(newValue);
                        }
                      }}
                      inputValue={intRiskAppetiteValue}
                      onInputChange={(event, newInputValue) => {
                        if (event != null && event.target.value != null) {
                          if (event.target.value <= 0 || event.target.value > 10 || !Number.isInteger(parseInt(event.target.value))) {
                            setIntRiskAppetiteValue('')
                          } else {
                            setIntRiskAppetiteValue(event.target.value);
                          }
                        }
                      }}
                      options={options1}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<>Risk Appetite <span style={{ color: 'red' }}>*</span></>}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox
                        id={"chkStandard"}
                        onClick={() => activeStandardGridForAll(!chkStandardAllChecked)}
                        checked={chkStandardAllChecked}
                      />}
                      label={<span className='stdLabel'>Add related Standard for all Risk Types if needed</span>} />
                  </Grid>
                  {openStandardGridAll ?
                    <Grid item xs={12}>
                      <div style={{ textAlign: 'left' }}>
                        <Card className={classes.cardDivDB}>
                          <CardHeader
                            avatar={
                              <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                <Info />
                              </Avatar>
                            }
                            className={classes.stdMainCardHeader}
                            title="Select a Standared"
                          />
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select From Global Standaed</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Select From Global Standaed"
                                      value={globalStandardDataForMainRisk}
                                      onChange={handleChangeGlobalStandardDataForMainRisk}
                                    >
                                      {globalStandardData.map((item) => (
                                        <MenuItem
                                          value={item.standardId}
                                        >
                                          {item.standardName}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select From Custom Standaed</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Select From Global Standaed"
                                      value={customStandardDataForMainRisk}
                                      onChange={handleChangeCustomStandardDataForMainRisk}
                                    >
                                      {customStandardData.map((item) => (
                                        <MenuItem
                                          value={item.standardId}
                                        >
                                          {item.standardName}
                                        </MenuItem>
                                      ))}

                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Objective</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Select Objective"
                                      sx={{ width: '100%' }}

                                      value={objectiveId}
                                      onChange={handleChangeObjectives}
                                    >
                                      {standardData.filter(object => object.standardId === globalStandardDataForMainRisk || object.standardId === customStandardDataForMainRisk).map((stndrd) => (
                                        stndrd.objectives.map((obj) => (
                                          <MenuItem value={obj.objectiveId}> {obj.name}</MenuItem>
                                        ))
                                      ))}


                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>


                              <Grid item xs={12}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Component</InputLabel>
                                    <Select
                                      label='Select Component'
                                      sx={{ width: '100%' }}

                                      value={componentId}
                                      onChange={handleChangecomponent}
                                    >
                                      {standardData.filter(object => object.standardId === globalStandardDataForMainRisk || object.standardId === customStandardDataForMainRisk).map((stndrd) => (
                                        stndrd.components.map((com) => (
                                          <MenuItem value={com.componentId}> {com.name}</MenuItem>
                                        ))
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>


                              <Grid item xs={12}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Principle</InputLabel>
                                    <Select
                                      label='Select Principle'
                                      sx={{ width: '100%' }}

                                      value={principleId}
                                      onChange={handleChangeprinciple}
                                    >

                                      {standardData.filter(object => object.standardId === globalStandardDataForMainRisk || object.standardId === customStandardDataForMainRisk).map((stndrd) => (
                                        stndrd.principles.map((pri) => (
                                          <MenuItem value={pri.principalId}> {pri.name}</MenuItem>
                                        ))
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                    : ''
                  }



                  <Grid item className='grid5'>
                    <TextField
                      size="small"
                      id={`txt0`}
                      label={<><span className='fontTextField'>Main Risk Name </span><span style={{ color: 'red' }}>*</span></>}
                      value={mainRiskName}
                      onKeyDown={e => handleKeyDownMain(e)}
                      onChange={handleChangeMainRisk}
                    />
                  </Grid>
                  <Grid item className='grid6'>
                    <span className='fontRed'>Please use the Tab key to create the risk type. To create a sub-risk, please navigate to the relevant text field and press the Tab key. You can delete an empty risk by clicking on the delete icon.</span>
                  </Grid>

                  {/** 
                              <Button
                                variant="outlined"
                                size='small'
                                style={{ height: '35px' }}
                                onClick={e => addMainRisk()}
                              >
                                + Add
                              </Button>
                              */}

                  {Object.keys(riskTypeData).length > 0 ?
                    <>
                      {
                        riskTypeData.riskTypes.map((riskType) => {
                          return (
                            <Comment
                              key={riskType.riskTypeTmpId}
                              comment={riskType}
                              lastRiskTmpId={lastRiskTmpId}
                            />
                          )
                        })
                      }
                    </>
                    :
                    ''
                  }

                  {
                    showSaveBtn ?
                      <Grid item xs={10} spacing={2} style={{ marginBottom: '5px' }}>
                        <div style={{ float: 'right' }}>
                          {displayOnly != undefined && displayOnly != null && displayOnly === 'OK' ?
                            ''
                            :
                            <>
                              <Button
                                variant="contained"
                                size='small'
                                className='btnStyle'
                                onClick={saveRisk}
                              >
                                {saveBtnNameForRisk}
                              </Button>&nbsp;&nbsp;
                            </>
                          }
                          {riskHierarchyId != undefined && riskHierarchyId != null && riskHierarchyId != '' ?
                            ''
                            :
                            <>
                              <Button
                                className='btnStyle'
                                variant="contained"
                                size='small'
                                onClick={clearFuction}
                              >Clear</Button>&nbsp;&nbsp;
                            </>
                          }
                          {riskIdvalue != 0 ?
                            <>
                              <Button
                                className='btnStyle'
                                variant="contained"
                                size='small'
                                onClick={() => evaluateRiskFunc()}
                              >Evaluate</Button>&nbsp;&nbsp;
                            </>
                            :
                            ''
                          }
                        </div>
                      </Grid>
                      : null
                  }
                </Grid>
              </CardContent>


            </Card>
          </Grid>
          {
            showRiskHierarchyTable ?
              <Grid item className='previousGrid'>
                <Card className={classes.stdCard}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                        <Info />
                      </Avatar>
                    }
                    className={classes.stdMainCardHeader}
                    title="RisK Hierarchies Created"
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className={classes.scrollableDivToStandard}>
                          <TableContainer
                          // component={Paper}
                          // sx={{ maxHeight: 540 }}
                          >
                            <Table aria-label="customized table">
                              <TableHead className={classe.commonSecondaryTableHead}>
                                <TableRow>
                                  <StyledTableCell className={classe.commonTableThStyle} style={{ width: '10%' }}>Key</StyledTableCell>
                                  <StyledTableCell className={classe.commonTableThStyle} style={{ width: '75%' }}>Name</StyledTableCell>
                                  <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '15%' }}>Edit</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {previousRiskTableRowData}
                            </Table>

                          </TableContainer>
                        </div>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              : null
          }
        </Grid>



      </div>

      {/** Evaluate Register */}
      <Dialog
        open={openRegisterDataForEval}
        onClose={false}
        scroll={openRegisterDataForEvalScroll}
        aria-labelledby="register-data-dialog-title"
        aria-describedby="register-data-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
        fullScreen={false}
      //PaperComponent={PaperComponentCollectionDataRegister}
      >
        <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>Evaluate "{selectedRegisterEvaluateTitle}"</Typography>
          <Typography variant="h6" style={{ marginRight: '-22px', padding: '7px', marginTop: '-50px', float: 'right' }}>
            <Tooltip title="Close"><Close onClick={RegisterDataForEvalfunctionClose} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={openRegisterDataForEvalScroll === 'paper'}>
          <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <EvaluateComponent
                  colorPatterns={colorPatterns}
                  registerTypeDataForEval={registerTypeDataForEval}
                  openRegisterDataForEvalScroll={openRegisterDataForEvalScroll}
                  registerEvaluate={false}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={RegisterDataForEvalfunctionClose}>Cancel</Button>
        </DialogActions>
      </Dialog>


      {/** Dialog Waiting */}
      <Dialog
        maxWidth={'md'}
        open={waitingOpen}
        PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
      >
        <CircularProgress disableShrink />
      </Dialog>

      {/** Edit save Mitigations */}
      <Dialog
        open={openRiskGpt}
        onClose={false}
        scroll={openRiskGptScroll}
        aria-labelledby="collection-data-dialog-title"
        aria-describedby="collection-data-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>Edit Risk description / Impact Description / Mitigation for {selectedRiskName}
          </Typography>
          <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
            <Tooltip title="Close"><Close onClick={() => setOpenRiskGpt(false)} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={openRiskGptScroll === 'paper'}>
          <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} noValidate autoComplete="off">
                  <div>Risk Description</div>
                  <textarea
                    placeholder="Risk Description..."
                    variant="outlined"
                    value={riskDescriptionText}
                    id='gptText'
                    rows={2}
                    style={{ width: "100%" }}
                    onChange={handleChangeRiskDescriptionText}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} noValidate autoComplete="off">
                  <div>Impact Description</div>
                  <textarea
                    placeholder="Impact Description..."
                    variant="outlined"
                    value={impactDescriptionText}
                    id='gptText'
                    rows={2}
                    style={{ width: "100%" }}
                    onChange={handleChangeImpactDescriptionText}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} noValidate autoComplete="off">
                  <div>Mitigation</div>
                  <textarea
                    placeholder="Mitigation..."
                    variant="outlined"
                    value={mitigationText}
                    id='gptText'
                    rows={2}
                    style={{ width: "100%" }}
                    onChange={handleChangeMitigationText}
                  />
                </Box>
              </Grid>


            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleMitigationUpdate(selectedRiskId)}
          >
            Apply
          </Button>
          <Button onClick={() => setOpenRiskGpt(false)}>Close</Button>


        </DialogActions>
      </Dialog>

    </>
  )
}

export default RiskHierarchyNew
