import { Tab, Tabs } from '@material-ui/core'
import React, { useState } from 'react'
import WeightingQuestion from '../Components/WeightingQuestion'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import WeightingRisk from '../Components/WeightingRisk';
import WeightingAnswers from '../Components/WeightingAnswers';
import { ArrowBack } from '@mui/icons-material';
import  "../Css/CreateSliderMthodPage.css";

const useStyles = makeStyles((theme) => ({ 
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageTop:{
    marginTop: '115px',
    marginLeft:'20px'
  },
  pageBodyStyle: {
      paddingTop: '12px',
  },
  mousePointer: {
    cursor: 'pointer'
  },

}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}


function TabPanel(props){

  const {children, value, index} = props;

  return(
    <div>
      {
        value === index && (
          <h1>{children}</h1>
        )
      }
    </div>
  )
}

const CreateSliderMthodPage = () => {
  const navigate = useNavigate();
  const [value, setTabValur] = useState(0)
  const handleTabs = (e,val)=> {
    console.log(val)
    setTabValur(val)
  }

  const [PageName, setPageName] = useState('Create and Edit Slider Methods')

  const classes = useStyles();
  return (
    <>
      <RightBarDataForOtherPages PageName={PageName}/> 
      <div className={classes.pageTop}>
        <Grid container spacing={2}>
          <Grid container spacing={2} className={classes.pageBodyStyle}>
            <Grid item xs={12}>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" to="/login">
                  <span className='breadcrumbFonts'>Home</span>
                  </Link>
                  <Typography color="text.primary"><span className='breadcrumbFonts'>Main menu - Create and Edit Slider Mthods</span></Typography>
                </Breadcrumbs>
              </div>
            </Grid>
            <Grid item xs={12}>
              <a className='goBack' onClick={() => navigate(-1)}><ArrowBack/> Go Back</a>
            </Grid>
            <Grid item xs={12}>
              <Tabs value={value} className='tabTop'  onChange={handleTabs}>
                <Tab label={<span className='tabFonts'>Risk Weighting</span>}/>
                <Tab label={<span className='tabFonts'>Question Weighting</span>}/>
                <Tab label={<span className='tabFonts'>Answer Weighting</span>}/>
              </Tabs>
              <TabPanel value={value} index={0}>
                <WeightingRisk/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <WeightingQuestion/>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <WeightingAnswers/>
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default CreateSliderMthodPage