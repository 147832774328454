import React, { useEffect, useState, useRef } from 'react'
import { Grid, makeStyles, Tooltip } from "@material-ui/core";
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { ArrowBack } from '@mui/icons-material';
import { Editor } from '@tinymce/tinymce-react';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info, KeyboardReturnOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import useStyle from '../Css/MainCss';
import { CONFIG} from '../config'
import axios from 'axios';

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  const useStyles = makeStyles((theme) => ({ 
    right: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    pageBodyStyle: {
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '12px',
      backgroundColor: '#f7f7f7',
      marginLeft: '25px',
    },
    root: {
      position: 'relative',
    },
  
  }));

const SendMailToAdmin = ({tinyMCETemplateList}) => {

    useEffect(() => {
        getData()
        gerSuperAdminData()
      },[])

      const [senderUserId, setsenderUserId] = useState('')
      const [senderUserName, setsenderUserName] = useState('')
      const [senderEmail, setsenderEmail] = useState('')

    function getData() {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        console.log(data)
        setsenderUserId(data.userId)
        setsenderUserName(data.name)
        setsenderEmail(data.userEmail)
        //setGolobalCentreId(data.centreId);

      }

      const [reciverUserId, setReciverUserId] = useState('')
      const [reciverUserName, setReciverUserName] = useState('')
      const [reciverEmail, setReciverEmail] = useState('')

      function gerSuperAdminData(){
        var data = '{getUserById(userId:"superadmin"){userId,name,email}}';
        axios(CONFIG.MAIN_URL+'/graphql',{
        method: 'post',
        headers: { 
            'Content-Type': 'text/plain',
        },
        data : data,  
        })
        .then(function (response) {
            console.log("++++++++++++++++Super Admin Return++++++++++++++++");
            var userData = response.data.data.getUserById;
            console.log(userData);
            userData.forEach((item, index) => {
                if (index === 0){
                    console.log(item);
                    setReciverEmail(item.email)
                    setReciverUserName(item.name)
                    setReciverUserId(item.userId)
                }
            })

        })
        .catch(function (error) {
            console.log("Super admin Data Loding error" + error);
        });
    }


    const classe = useStyles();
    const classes = useStyle();
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const [headerName, setHeaderName] = React.useState('Compose Mail');

    const [emailBody, setEmailBody] = useState('')
    const [mailSubject, setMailSubject] = useState('')

    const mailSubjectOnchange = (event) => {
      setMailSubject(event.target.value);
    }

    function sendMailFunction(){
        if (mailSubject === ''){
            alert("Please enter Mail Subject");
            return;
          }
      
          var desc = '';
          if (editorRef.current) {
            desc = editorRef.current.getContent();
            console.log(desc)

            
          }
          if (desc === ''){
            alert("Please enter mail body");
            return;
          }

          var mainobj = {}
          var obj1 = {}
            obj1["mailBody"] = desc

            const d = new Date();
             var dateStr = d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear() + "  "  + d.getHours() + ":"  + d.getMinutes() + ":"  + d.getSeconds()

            obj1["subject"] = mailSubject + " - " + dateStr

            mainobj["email"] = obj1

            var obj2 = {}
            obj2["userId"] = reciverUserId
            obj2["userName"] = reciverUserName
            obj2["email"] = reciverEmail
            obj2["entityId"] = "Admin"

            mainobj["toUser"] = obj2

            var obj3 = {}
            obj3["userId"] = senderUserId
            obj3["userName"] = senderUserName
            obj3["email"] = senderEmail
            obj3["entityId"] = "SuperAdmin"

            mainobj["fromUser"] = obj3
            mainobj["fromStatus"] = "REQUEST USER"

            var data = JSON.stringify(mainobj)

            console.log(data)

            axios(CONFIG.MAIN_URL+`/email/send`,{
                method: 'post',
                headers: { 
                    'Content-Type': 'application/json', 
                  },
                  data:data
                  
            })      
            .then(res =>  {
                    console.log(res)
                    alert("Thank You. Your message has been delivered.")
                    clearFuction()

            })
            .catch(function (error) {
                console.log(error);
            });
            
    }

    function clearFuction(){
        setEmailBody('')
        setMailSubject('')
        editorRef.current.setContent('');
      }

    return (
        <>
            <RightBarDataForOtherPages PageName={headerName} />

            <div style={{ marginTop: '120px', padding: '5px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={8} className={classe.pageBodyStyle}>
                        <div role="presentation" onClick={handleClick}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link underline="hover" color="inherit" to="/login" id='focusHomeLink'>
           
                                    Home
                                </Link>

                                <Typography color="text.primary">Main menu - {headerName}</Typography>
                            </Breadcrumbs>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <a style={{ float: 'right', marginTop: '-36px', color: '#0056b3', cursor: 'pointer' }} onClick={() => navigate(-1)}><ArrowBack /> Go Back</a>
                    </Grid>
                </Grid>


                <Grid >
                <Grid container spacing={2}>
                <>
                <Grid item xs={12} >
                    <Card style={{width:'100%',border:'1px solid black'}} >
                        <CardContent>
                            <Card >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                        <Info />
                                        </Avatar>
                                    }
                                    className={classes.commonMainTableHead}
                                    title={headerName}
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                     
                                        <Grid item xs={12}>
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& .MuiTextField-root': { mt: 1, width: '100%' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                                >
                                                <div>
                                                    <TextField
                                                        id="title"
                                                        label="Mail Subject"
                                                        multiline
                                                        maxRows={4}
                                                        name='title'
                                                        required
                                                        value={mailSubject}
                                                        onChange={mailSubjectOnchange}
                                                    />
                                                </div>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Editor
                                                apiKey='ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj'
                                                tinymceScriptSrc={'https://cdn.tiny.cloud/1/ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj/tinymce/5/tinymce.min.js'}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                initialValue={emailBody}
                                                init={{
                                                  referrer_policy: 'origin',  
                                                  height: 300,
                                                    menubar: true,
                                                    placeholder: 'Mail Body',
                                                    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media mediaembed template codesample table advtable charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help charmap quickbars emoticons casechange formatpainter pageembed hr fullpage advcode export permanentpen powerpaste',
                                                    imagetools_cors_hosts: ['picsum.photos'],
                                                    menubar: 'file edit view insert format tools table help',
                                                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | casechange forecolor backcolor formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview print | pageembed insertfile image media template tokens link anchor codesample | ltr rtl hr fullpage export permanentpen configurepermanentpen',
                                                    //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                                                    export_image_proxy: 'proxy.php',
                                                    toolbar_sticky: true,
                                                    autosave_ask_before_unload: true,
                                                    autosave_interval: '30s',
                                                    autosave_prefix: '{path}{query}-{id}-',
                                                    autosave_restore_when_empty: false,
                                                    autosave_retention: '2m',
                                                    setup: (editor) => {
                                                      editor.on('init', function (e) {
                                                                 var content = unescape(emailBody);
                                                                 editor.setContent(content);
                                                      });
                                                  },
                                          
                                          
                                                  image_title: true,
                                                  /* enable automatic uploads of images represented by blob or data URIs*/
                                                  automatic_uploads: true,
                                                  /*Here we add custom filepicker only to Image dialog*/
                                                  file_picker_types: 'image',
                                          
                                                  /* and here's our custom image picker*/
                                                  file_picker_callback: function (cb, value, meta) {
                                                      var input = document.createElement('input');
                                                      input.setAttribute('type', 'file');
                                                      input.setAttribute('accept', 'image/*');
                                          
                                                      input.onchange = function () {
                                                          var file = this.files[0];
                                                          var reader = new FileReader();
                                          
                                                          reader.onload = function () {
                                                              /*
                                                              Note: Now we need to register the blob in TinyMCEs image blob
                                                              registry. In the next release this part hopefully won't be
                                                              necessary, as we are looking to handle it internally.
                                                              */
                                                              var id = 'blobid' + (new Date()).getTime();
                                                              
                                                              var blobCache = editorRef.current.editorUpload.blobCache;
                                                              //var blobCache = editorRef.current.activeEditor.editorUpload.blobCache;
                                                              var base64 = reader.result.split(',')[1];
                                          
                                                              var blobInfo = blobCache.create(id, file, base64);
                                                              blobCache.add(blobInfo);
                                          
                                                              /* call the callback and populate the Title field with the file name */
                                                              cb(blobInfo.blobUri(), {title: file.name});
                                                          };
                                          
                                                          reader.readAsDataURL(file);
                                                      };
                                          
                                                      input.click();
                                                  },
                                          
                                                  images_upload_handler: function (blobInfo, success, failure) {
                                                    /*  
                                                    var xhr, formData;
                                          
                                                      xhr = new XMLHttpRequest();
                                                      xhr.withCredentials = false;
                                                      xhr.open('POST', 'actionContent/getImage');
                                                      xhr.onerror = function() {
                                                          //$('#wait').hide();
                                                      };
                                                      xhr.onload = function () {
                                                          var json;
                                          
                                                          if (xhr.status != 200) {
                                                              failure('HTTP Error: ' + xhr.status);
                                                              return;
                                                          }
                                                          console.log()
                                                          json = xhr.responseText;
                                                          console.log(json);
                                                          success(json);
                                                          //$('#wait').hide();
                                                      };
                                          
                                                      formData = new FormData();
                                                      formData.append('file', blobInfo.blob(), blobInfo.filename());
                                                      //$('#wait').show();
                                                      xhr.send(formData);
                                                      */
                                                  },
                                          
                                                  templates : tinyMCETemplateList,
                                                  /*
                                                  templates : [
                                                    {
                                                      title: 'Date modified example',
                                                      description: 'Adds a timestamp indicating the last time the document modified.',
                                                      content: '<p>Last Modified: This will be replaced with the date modified.</time></p>'
                                                    },
                                                    {
                                                      title: 'Replace values example',
                                                      description: 'These values will be replaced when the template is inserted into the editor content.',
                                                      content: '<p>Name: StaffID: </p>'
                                                    }
                                                  ],
                                                  */
                                                  referrer_policy: 'origin',
                                          
                                                    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                                                    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                                                    image_caption: true,
                                                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                                    noneditable_noneditable_class: 'mceNonEditable',
                                                    toolbar_mode: 'sliding',
                                                    contextmenu: 'link image imagetools table',
                                                    //content_css: useDarkMode ? 'dark' : 'default',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                
                                                }}
                                            />
                                        </Grid>
                              
                                        
                                        <Grid item xs={12} style={{textAlign: 'right'}}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ marginTop: '10px', height: '35px' }}
                                                onClick={() => {
                                                    sendMailFunction();
                                                }}
                                                >
                                                send
                                            </Button>&nbsp;&nbsp;
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ marginTop: '10px', height: '35px' }}
                                                onClick={() => {
                                                    clearFuction()
                                                }}
                                                >
                                                Clear
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardContent>
                    </Card>
                </Grid>
           
            </>
          </Grid>
        </Grid>
            </div>
        </>
    )
}

export default SendMailToAdmin