import React, {useEffect,useState} from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {CONFIG} from '../config';
import axios from 'axios';
import classNames from 'classnames';
import useStyle from '../Css/MainCss';
import {dispatchLogout} from "../actions/sign-out";
import { Link, useLocation } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';



const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));


const RightBarUser = ({userRoleId,centreId,name}) => {
    function compareMenu( a, b ) {
        var nameA = a.no; // ignore upper and lowercase
        var nameB = b.no; // ignore upper and lowercase
      
        if ( nameA < nameB ){
            return -1;
        }
        if ( nameA > nameB ){
            return 1;
        }
        // names must be equal
        return 0;
    }
    const classes = useStyle();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    


      const [menuItem, setMenuItem] = useState([]);

      const [userName, setUserName] = useState()

      useEffect(() => {
        getSideBarData()
        getAllCollectionToTransfer();
        getCentreData();
      }, [])
    
    
      function getSideBarData(){
        if(userRoleId === 'staff'){
          userRoleId = 'ADMIN'
          console.log(userRoleId)     
        }

    
        var data = '{allMenuRoleList(centreId:"'+centreId+'",iamRole:"'+userRoleId+'"){menuRoleId,centerMenuName,centerMenuOrderNum,centreId,createdDate,updatedDate,isActive,menu{menuUri,menuHtmlId},subMenuList{subMenuRoleId,centerMenuName,centerMenuOrderNum,centreId,createdDate,updatedDate,isActive,menu{menuUri,menuHtmlId}}}}';
        axios(CONFIG.MAIN_URL+`/graphql`,{
          method: 'post',
          headers: { 
              'Content-Type': 'text/plain', 
            },
            data:data
            
          })      
          .then(function (response) {
            console.log("++++++++++++++++++response+++++++++++++++++++");
            console.log(response);
            console.log(response.data.data.allMenuRoleList);
            
            var arr = response.data.data.allMenuRoleList;
            
            arr.forEach((data)=>{
              var arr1 = data.subMenuList;
              var count = 7;
              arr1.forEach((item)=>{
                if (item.menu.menuUri === "mycollections"){
                  item["no"] = 1;
                }else{
                  if (item.menu.menuUri === "centreEntities"){
                    item["no"] = 2;
                  }else{
                    if (item.menu.menuUri === "entityChange"){
                      item["no"] = 3;
                    }else{
                      if (item.menu.menuUri === "bulkUpload"){
                        item["no"] = 4;
                      }else{
                        if (item.menu.menuUri === "allUsers"){
                          item["no"] = 5;
                        }else{
                          if (item.menu.menuUri === "publishcollections"){
                            item["no"] = 6;
                          }else{
                            item["no"] = count;
                            count ++;
                          }
                        }
                      }
                    }
                  }
                }
              })
              arr1 = arr1.sort (compareMenu);
              data["subMenuList"] = arr1;
            })
            
            console.log("+++++++++++++++++++++++++++++++++Menu arr+++++++++++++++++++++++");
            console.log(arr);
            setMenuItem(arr)

            
            //setMenuItem(response.data.data.allMenuRoleList)
          //console.log(response.data.data.allMenuRoleList)
      
          })
          .catch(function (error) {
            console.log("++++++++++++++++++Error+++++++++++++++++++");
            console.log(centreId);
            console.log(userRoleId);
            console.log(CONFIG.MAIN_URL+`/graphql`);

          console.log(error);
          });
      }
      
      const [centreName, setCentreName] = React.useState('');
      function getCentreData(){
        var data = '{getCentreData(centreId:\"' + centreId + '\"){centreId,centreName}}';
        axios(CONFIG.MAIN_URL+'/graphql',{
        method: 'post',
        headers: { 
            'Content-Type': 'text/plain',
          },
        data : data,  
        })
        .then(function (response) {
            console.log("++++++++++++++++Return centreData++++++++++++++++");
            var centreData = response.data.data.getCentreData;
            console.log(centreData);
            setCentreName(centreData.centreName);
    
        })
        .catch(function (error) {
            console.log("Centre Data Loding error" + error);
        });
      }
  

      const [preparingCount, setPreparingCount] = React.useState(0);
      const [pendingCount, setPendingCount] = React.useState(0);

      function getAllCollectionToTransfer(){
        axios(CONFIG.MAIN_URL+'/transactiondata/all',{
          method: 'get',
        })
        .then(function (response) {
          console.log("Get All Transaction Collection");
          console.log((response.data));
          var arr = response.data;
          var tmpPreparingCount = 0;
          var tmpPendingCount = 0;
          arr.forEach((item)=>{
            if (item.expertCentreId === centreId) {
              if (item.transferStatus === "Preparing"){
                tmpPreparingCount ++;
              }
              if (item.transferStatus === "Pending"){
                tmpPendingCount ++;
              }
            }  
          })
          setPreparingCount(tmpPreparingCount);
          setPendingCount(tmpPendingCount);
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    






      function handleLogoutBtnClick() {
        dispatchLogout();
      };

      function handleLogoutBtnlCickAbout(){
        let a= document.createElement('a');
        a.target= '_blank';
        a.href= '/aboutus';
        a.click();
      };
      function handleLogoutBtnlCickServices(){
        let a= document.createElement('a');
        a.target= '_blank';
        a.href= '/ourservices';
        a.click();
      }
      function handleOtherServiceClick(url){
        let a= document.createElement('a');
        a.target= '_self';
        a.href= url;
        a.click();
      }



  return (
    <div>
        
        <Box sx={{ display: 'flex'}}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{backgroundColor:'white', height:'100px' }}>
        <Toolbar style={{display:'flex',justifyContent:'space-between', color:'black'}}>
            <div >
              <Typography variant="h6"  component="div">
                Risk Management System
              </Typography>
            </div>
            <div style={{textAlign:'center',marginTop:'10px'}}>
              <Typography variant="h6"  component="div" >
                {centreName}
              </Typography>
              <Typography variant="h6"  component="div" className={classes.firstphaHeader} style={{fontFamily:'cursive'}} >
                Risk Assesment Collection
              </Typography>
            </div>
            <div style={{textAlign:'center',marginTop:'10px'}}>
              <Typography variant="h6"  component="div" className={classes.fontZise11}>
                Welcome {name}
              </Typography>
              <Typography variant="h6"  component="div" >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  sx={{ ...(open && { display: 'none' }) }}
                  >
                  <MenuIcon />
                </IconButton>
              </Typography>
              <Typography variant="h6"  component="div" className={classes.fontZise11} onClick={handleLogoutBtnClick} style={{cursor:'pointer'}}>
                  Logout
              </Typography>
            </div>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItem.map((text, index) => (
            text.subMenuList.map((text1, index) => (
            <ListItem key={text1.centerMenuName} disablePadding>
              {text1.menu.menuUri === "transferCollection"?
              <ListItemButton onClick={()=>handleOtherServiceClick(`../${text1.menu.menuUri}`)}>
                {preparingCount > 0 && pendingCount > 0 ?
                <>
                  <Button variant="contained" className={classes.rightBarBadgeBtnRed}>{'preparing-'+ preparingCount}</Button>
                  <Button variant="contained" className={classes.rightBarBadgeBtnGreenWithRed}>{'pending-'+ pendingCount}</Button>
                  <ListItemText primary={text1.centerMenuName} className={classes.rightBarTextTableLeftBoth}/>
                </>
                :
                <>
                {preparingCount > 0 ?
                 <>
                  <Button variant="contained" className={classes.rightBarBadgeBtnRed}>{'preparing-'+ preparingCount}</Button>
                  <ListItemText primary={text1.centerMenuName} className={classes.rightBarTextTableLeft}/>
                  </>
                :
                <>
                {pendingCount > 0 ?
                <>
                  <Button variant="contained" className={classes.rightBarBadgeBtnGreen}>{'pending-'+ pendingCount}</Button>
                  <ListItemText primary={text1.centerMenuName} className={classes.rightBarTextTableLeft}/>
                </>
                :
                <ListItemText primary={text1.centerMenuName}/>
                }
                </>
                }
                  </>
                }
              </ListItemButton>
              :
              <ListItemButton onClick={()=>handleOtherServiceClick(`../${text1.menu.menuUri}`)}>
                <ListItemText primary={text1.centerMenuName} />
              </ListItemButton>
              }
            </ListItem>
            ))
          ))}
            <ListItem  disablePadding >
              <ListItemButton onClick={handleLogoutBtnlCickServices}>
                <ListItemText >
                  Help
                </ListItemText>
              </ListItemButton>
            </ListItem>
            {/** 
           <ListItem  disablePadding >
              <ListItemButton onClick={handleLogoutBtnClick}>
                <ListItemText >
                    Logout
                </ListItemText>
              </ListItemButton>
            </ListItem>
            */}
        </List>
        
      </Drawer>
    </Box>
    </div>
  )
}

export default RightBarUser