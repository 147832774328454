import React from 'react'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import RiskHierarchy from '../Components/RiskHierarchy';
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { ArrowBack } from '@mui/icons-material';
import RiskHierarchyNew from '../Components/RiskHierarchyNew';
import  "../Css/RiskTypeOptionNew.css";


const RiskTypeOptionNew = () => {

  const [PageName, setPageName] = React.useState('Create And Edit a Risk Hierarchy');
  const navigate = useNavigate();


  return (
    <>
    <RightBarDataForOtherPages PageName={PageName}/>
   
    <div style={{marginTop:'120px'}}>
      <Grid item xs={12}>
               
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/login">
                      <span className='breadcrumbFonts'>Home</span>
                    </Link>
                    
                    <Typography color="text.primary"><span className='breadcrumbFonts'>Main menu - Create And Edit a Risk Hierarchy</span></Typography>
                    
                  </Breadcrumbs>
                
      </Grid>
      <Grid item xs={12}>
        <a className='goBack' onClick={() => navigate(-1)}><ArrowBack/> Go Back</a>
      </Grid>
      <Grid item xs={12}>
        <RiskHierarchyNew/>
      </Grid>  
    </div>
    </>
    
  )
}

export default RiskTypeOptionNew