
import React, {useEffect,useState} from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import Draggable from 'react-draggable'; 
import axios from 'axios';
import {CONFIG} from '../config';
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FormLabel from '@mui/material/FormLabel';
import { FormControlLabel } from '@material-ui/core';
import { FormGroup } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { Close } from '@mui/icons-material';
import RightBarWithoutUserRole from '../Components/RightBarWithoutUserRole';


const MultiRoleLoginPage = ({userRoleList,centreId,name,userId}) => {

    const classes = useStyle();

    console.log("++++++++++++++++name+++++++++++++++++++");
    console.log(name);

    return (
        <div >
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.pageBodyStyleDB}>
                    <Grid item xs={12} id="tabId1">
                        <div style={{textAlign:'center', marginTop:'120px'}}>
                            <Grid container spacing={2}>
                                {
                                    userRoleList.map((items) => {
                                        return (
                                            <Grid item xs={12}>
                                                <Link to={`/dashboardmultiuser/userdets?centreId=${centreId}&name=${name}&userId=${userId}&userRoleId=${items.roleId}`}>
                                                <Button 
                                                    variant="contained" 
                                                    className={classes.selectRoleTypeBtn}
                                                    //onClick={() => linkToDirectPage()}
                                                    sx={{ boxShadow: 6 }}
                                                    >
                                                    {"Login As " + items.entity.entityName}
                                                </Button>
                                                </Link>
                                            </Grid>
                                        )
                                    })
                                }                                         
                            </Grid>
                        </div>
                    </Grid>
                </Grid>    
            </Grid>
        </div>
  )
}

export default MultiRoleLoginPage