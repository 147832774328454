import React from 'react'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';

const TransferToExcelRegister = ({ allRegister, registerId }) => {

    const handleDownload = () => {

        // var arr = [];
        // if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
        //     //arr = commitArray.sort(compareRiskTypeOrder);
        //     arr = commitArray
        // } else {
        //     if (commitBarData != undefined && commitBarData != null && commitBarData.length > 0) {
        //         arr = commitBarData;
        //     } else {
        //         arr = commitArrayData;
        //     }
        // }

        var riskTypeDataForEval = []

        allRegister.forEach((data) => {
            if (data.id === registerId) {
                data.riskRegister.forEach((item)=> {
                    riskTypeDataForEval.push(item)
                })

            }

        })


        var arrX = [];
        var arrY = []
        var objx = {}
        var arrZ = []

        arrZ.push("Register Name");
        arrZ.push(" Impact");
        arrZ.push(" Likelihood");

        arrZ.push("Level");
        arrZ.push("Appetite");


        arrX.push(arrZ)
        // arrX.push(arrY)

        riskTypeDataForEval.forEach((data, index) => {

            arrY = []
            arrZ = []
            arrZ.push("");
            arrZ.push("");
            arrZ.push("");
            arrZ.push("");
            arrZ.push("");
            arrX.push(arrZ)


            arrY = []
            arrZ = []
            arrZ.push((index + 1) + ") " + data.section);
            arrZ.push(parseInt(data.likelihood));
            arrZ.push(parseInt(data.likelihood));
            arrZ.push(parseInt(data.riskLevel));
            arrZ.push(parseInt(data.riskAppetite));
            arrX.push(arrZ)
            if (data.risks != undefined && data.risks != null && data.risks.length > 0) {
                data.risks.forEach((item, index1) => {
                    arrY = []
                    arrZ = []
                    arrZ.push((index + 1) + "." + (index1 + 1) + ") " + item.name);
                    arrZ.push(parseInt(item.likelihood));
                    arrZ.push(parseInt(item.likelihood));
                    arrZ.push(parseInt(item.riskLevel));
                    arrZ.push(parseInt(item.riskAppetite));
                    arrX.push(arrZ)



                })
            }


        })



        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(arrX);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
        saveAs(new Blob([buffer]), 'data.xlsx');
    };
    return (
        <div>
            <Button style={{ float: 'right' }} variant='contained' onClick={handleDownload}>Transfer to Excel File</Button>
        </div>
    )
}

export default TransferToExcelRegister