import {makeStyles} from '@material-ui/core';


const userDetailsPageStyle = makeStyles((theme) => ({
    right: {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      pageTop:{
        marginTop: '105px',
      },
      pageBodyStyle: {
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingTop: '12px',
        marginLeft: '25px',
      },
      root: {
        position: 'relative',
      },
      fontZise10:{
        fontSize: '10px !important',
      },
      fontZise10MosusePonter:{
        fontSize: '10px !important',
        cursor: 'pointer',
      },
      fontZise9AndGreen:{
        fontSize: '9px !important',
        backgroundColor:'green !important',
        width:'80px !important',
        fontWeight: '700 !important',
        textAlign: 'center'
      },
      fontZise9AndRed:{
        fontSize: '9px !important',
        backgroundColor:'red !important',
        width:'80px !important',
        fontWeight: '700 !important',
        textAlign: 'center'
      },
      fontZise9AndRedSmall:{
        fontSize: '9px !important',
        backgroundColor:'red !important',
        width:'90px !important',
        fontWeight: '700 !important',
      },
      fontZise9AndBlue:{
        fontSize: '9px !important',
        backgroundColor:'blue !important',
        width:'80px !important',
        fontWeight: '700 !important',
        textAlign: 'center'
      },
      cardDiv2DB:{
        border:'2px solid 69676e',
        width:'600px !important',
        backgroundColor:'#c7c5d0 !important',
        boxShadowhadow: '5px 10px #69676e',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        borderRadius: '30px !important',
        marginLeft: '40% !important'
      },
      cardDivUserData:{
        border:'2px solid 69676e',
        width:'900px !important',
        backgroundColor:'#c7c5d0 !important',
        boxShadowhadow: '5px 10px #69676e',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        borderRadius: '30px !important',
        marginLeft: '40% !important'
      },
      cardDiv2UR:{
        /*
        border:'2px solid 69676e',
        width:'600px !important',
        backgroundColor:'#c7c5d0 !important',
        boxShadowhadow: '5px 10px #69676e',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        borderRadius: '30px !important',
        marginLeft: '40% !important'
*/

        border:'2px solid 69676e',
        width:'100% !important',
        backgroundColor:'#c7c5d0 !important',
        boxShadowhadow: '5px 10px #69676e',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        borderRadius: '50px !important'




      },
      cardTitleDB: {
        fontSize:'15px !important', 
        textAlign:'center',
        fontWeight: '500',
        paddingBottom:'10px',
       },
       cardTitleUserData: {
        fontSize:'15px !important', 
        textAlign:'right',
        fontWeight: '500',
        paddingBottom:'10px',
       },
       tableHeadEntityMapStyle: {
        backgroundColor: '#ece9fa',
        color: '#ffffff',
      },
      tableEntityMapConfirmTrStyle: {
        lineHeight: '0.25rem !important',
        borderBottom: '0px !important',
        paddingBottom: '0px !important',
        paddingTop: '0px !important',
        fontSize: '10px !important',
      }, 
      tableHeadStyle: {
        backgroundColor: '#ece9fa',
        color: '#ffffff',
      },
      tableAnsConfirmHeadStyle: {
          backgroundColor: '#6666FF',
          color: '#ffffff',
          height: '100px !important',
          fontSize: '14px !important',
          fontWeight: '600'
      },
      tableAnsConfrnThStyle: {
          lineHeight: '0.50rem !important',
          fontSize: '22px !important',
          fontWeight: '600',
          color: '#ffffff !important'
      },
      tableAnsConfirmTrStyle: {
          lineHeight: '0.50rem !important',
          backgroundColor: '#FFFFFF',
          borderBottom: '0px !important'
      },
      tableAnsConfirmTrStyleEmpty: {
        lineHeight: '0.20rem !important',
        backgroundColor: '#FFFFFF',
        borderBottom: '0px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
      },
      tableAnsConfirmTrStyle1: {
          lineHeight: '0.25rem !important',
          backgroundColor: '#FFFFFF',
          borderBottom: '0px !important',
          paddingBottom: '0px !important',
          paddingTop: '0px !important',
      },
      btnBorderGreenEntityMap:{
        border: '1px solid green !important',
        color: 'green !important',
        padding:'5px 10px !important',
        height: '40px !important',
        fontSize: '11px !important',
      },
      pageTopUserQuestion:{
        marginTop: '105px',
        marginLeft: '10px !important',
        paddingRight: '25px !important',
      },
      formControlLabelStyle:{
        marginTop: '-10px !important',
      },
      loaderStyle:{
        textAlign:'center',
        display:'flex',
        marginTop: '20%',
        marginLeft:'40%'
      }


}));

export default userDetailsPageStyle;
