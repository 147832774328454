import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import CardHeader from '@mui/material/CardHeader';
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import axios from 'axios'
import { CONFIG } from '../config';
import parseHtml from 'html-react-parser';
import "../Css/Slider.css";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';


const AboutUs = () => {

  const classes = useStyle();

  useEffect(() => {
    getLoginPageDetails()
    getAboutPageDetails()
  }, []);


  const [pageBackgroundUrl, setPageBackgroundUrl] = React.useState('');
  const [pageBackgroundColor, setPageBackgroundColor] = React.useState('');

  function getLoginPageDetails() {
    axios(CONFIG.EXPERT_SERVER_URL + `/loginPageContent/allCurrent`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("++++Login Page Details +++++++++++++++++");
        console.log(response.data);
        response.data.forEach((item, index) => {
          if (item.domainName === window.location.hostname || ('www.' + item.domainName) === window.location.hostname) {
            if (item.backgroundUrl != undefined && item.backgroundUrl != null) {
              setPageBackgroundUrl(item.backgroundUrl);
            }
            if (item.backgroundColor != undefined && item.backgroundColor != null) {
              setPageBackgroundColor(item.backgroundColor);
            }
          }
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const [pageTopHtml, setPageTopHtml] = React.useState('');

  function getAboutPageDetails() {
    axios(CONFIG.EXPERT_SERVER_URL + `/aboutUsPageContent/filter?versionNumber=current`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("++++About Us Page Based On Current +++++++++++++++++");
        console.log(response.data);
        response.data.forEach((item, index) => {
          if (item.domainName === window.location.hostname || ('www.' + item.domainName) === window.location.hostname) {
            if (item.pageContent != undefined && item.pageContent != null) {
              //alert(item.pageContent)
              var tArr = item.pageContent.split("<body>");
              if (tArr.length > 1) {
                var tArr1 = tArr[1].split("</body>");
                //alert(tArr1[0])
                console.log(tArr1[0].replaceAll("\\n", ''))
                setPageTopHtml(tArr1[0].replaceAll("\\n", ''));
              } else {
                if (item.pageContent != "") {
                  setPageTopHtml(item.pageContent.replaceAll("\\n", ''));
                }
              }

            }
          }
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  setTimeout(() => {

    var imgTags = document.getElementsByTagName('img');
    for (var i = 0; i < imgTags.length; i++) {
      imgTags[i].onclick = function () {
        var str = "<img src=\"" + this.src + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      };
      imgTags[i].onmouseover = function () {
        this.style.cursor = 'pointer';
      };
    }
  }, 1000);


  return (
    <>
      <Header />
      <main >
        {pageBackgroundUrl != '' ?
          <div style={{
            height: '98vh',
            backgroundSize: 'cover',
            backgroundImage: `linear-gradient( 0deg , rgb(63 54 80), rgb(240 232 239 / 0%)),url(${pageBackgroundUrl})`,
            backgroundRepeat: 'no-repeat'
          }}
          >
            <div class="scrollable-container-main">
              <div class="scrollable-content">
                <div className={classes.pageLayout} >

                  {pageTopHtml != '' ?
                    <>
                      <div className={classes.pageLayout}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <div>
                              {parseHtml(pageTopHtml)}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </>

                    :
                    <>
                    ''
                    {/** 
                    <div className={classes.pageLayout}>
                      <Grid container spacing={2}>
                        <Grid container spacing={2} className={classes.pageBodyStyleabout}>
                          <Grid item xs={12}>
                            <Box sx={{ minWidth: 120 }}>
                              <Card className={classes.root}>
                                <div className={classes.posRelative}>
                                  <CardMedia component="img" image={titleImage} title="Risk Group" alt="Ris Group" />
                                  <div className={classes.topImagePosabout}>
                                    <p className={classes.topHeaderTextabout}>
                                      ABOUT US
                                    </p>

                                  </div>
                                </div>
                              </Card>
                            </Box>
                          </Grid>
                          <div className={classes.margin}>
                            <Grid item xs={12}>
                              <div>
                                <br></br>
                                <h3 className={classes.topHeaderText1}>OUR STOR</h3>
                              </div>
                            </Grid>

                            <Grid container spacing={2} className={classes.storytop}>
                              <Grid item xs={8} >
                                <div className={classes.phaMain}>
                                  <p style={{ fontSize: '15px' }}><b>
                                    &nbsp;&nbsp;&nbsp; Our story starts in 1965 in a sleepy little village in North London UK.
                                    Its a two-story terraced building in a small parade with a shop on the
                                    ground floor and a spacious two bed apartment on the first floor.
                                  </b></p>
                                  <p style={{ fontSize: '15px' }}>
                                    &nbsp;&nbsp;&nbsp; The first founder of the company bought this building for £500, in 1965,
                                    moved into the apartment up above and opened an Insurance
                                    brokerage and property management company from the shop
                                    below. The Former name of Data management Group Ltd was Van Houten & co (insurance) Ltd.
                                    Young VanHouten  ran a very successful business from 1965 to 1977 when
                                    he decided to emigrate to the United States..
                                  </p>
                                </div>
                              </Grid>
                              <Grid item xs={4}>
                                <div>
                                  <img src='https://datam.co.uk/images/u18_bqqio65k.png' alt='image'></img>
                                </div>
                              </Grid>

                            </Grid>

                            <Grid container spacing={2} className={classes.storytop}>
                              <Grid item xs={3} >
                                <div style={{ marginBottom: '10px' }}>
                                  <b>Ananda Jayasinghe</b>
                                </div>
                                <div >
                                  <img src='https://datam.co.uk/images/u20.png' alt='image'></img>
                                </div>

                              </Grid>
                              <Grid item xs={8}>
                                <div className={classes.phaMain}>
                                  <br></br>
                                  <p style={{ fontSize: '15px' }}>
                                    &nbsp;&nbsp;&nbsp;(also  known as Andy Jay by  those who could not pronounce hisreal Christian and Surname)
                                    bought the  business of Van Houten and the whole  building for a  sum in the region of  £25,000.
                                  </p>
                                  <p style={{ fontSize: '15px' }}>
                                    <b>
                                      &nbsp;&nbsp;&nbsp; Andy Graduated in Sri Lanka in 1963, emigrated to UK in 1965 and completed his Accountancy
                                      qualifications whilst working in the profession and in service and manufacturing industries. by 1977 he had achieved the highest possible position in industry related to his profession as the finance director of a construction company.
                                    </b>
                                  </p>
                                  <p style={{ fontSize: '15px' }}>
                                    <b>
                                      Andy recalls  ..</b> <br></br>

                                    &nbsp;&nbsp;&nbsp;"In the 1960's and 70s Managing Data was a very manual process. The only machinery available in those days was a Typewriter
                                    and a Comptometer. The latter being the calculator used by a trained operator to key in   numbers handwritten  in a
                                    spread sheet type of format. Communication was mainly by post. The telephone became the technology evolution with a telex machine being used for long distance.
                                    the photo copier and the fax machine arrived in the late 80."
                                    "Yet even with all of this, everything... more or less... required manual effort.
                                    Factory production lines and clerical work in the offices required loads and loads of staff.
                                    There was a hunger for labour saving mechanisation and automation of every conceivable process in the late 70s and 80s.
                                    However, new Equipment was expensive, and only the larger companies could afford the huge costs of mechanisation."
                                  </p>

                                </div>

                              </Grid>

                            </Grid>
                            <Grid container spacing={2} className={classes.storytop}>
                              <Grid item xs={12} >
                                <p style={{ fontSize: '15px' }}>
                                  <b>Says Andy :</b>
                                  &nbsp;&nbsp;&nbsp; "In 1977 I saw a gap in the market for the automation of management processes for the smallto medium sized businessesand that was the birth of  The Data Management Group Ltd ".
                                </p>

                                <p style={{ fontSize: '15px' }}>
                                  &nbsp;&nbsp;&nbsp;  Apart from the Freehold property the other main asset was Van Houten's Client list of some 1500 names and contact details of a mixture of businesses and private individuals.
                                  Andy didnt waste any time contacting each and every one of them and
                                  offering them a rounded financial and management consultancy service which included
                                  the offer to manage their insurances, real estate, and financial DATA, in addition to the services that
                                  Van Houten offered.
                                  Accountancy, Taxation, and System Analysis was taken up by many.
                                  Some of the success stories arising out of this effort soon prompted a name change of  Van Houten to
                                  Data Management Group Ltd.
                                </p>
                                <p style={{ fontSize: '15px' }}>
                                  Here are a few success stories.A small Bakery in Knights Bridge London, making cakes,became a large manufacturer of Frozen Gateau supplying all the supermarkets of the day. The company was sold for £20 million in the late 1980's. A Ugandan Asian family who was importing fresh vegetables from India ended up Owning most of the stalls and stores in Ealing Road to distribute tropical vegetables grown by the family business in newly acquired land in Portugal.
                                </p>
                                <p style={{ fontSize: '15px' }}>
                                  &nbsp;&nbsp;&nbsp;  a small Packaged tour operator struggling to compute the prices in good time
                                  to publish the seasonal brochures, ended up competing
                                  and beating the largest tour operator on price when their brochures
                                  wewere published soon after the giants’ brochures were delivered to travel
                                  agents.
                                </p>
                                <p style={{ fontSize: '15px' }}>
                                  &nbsp;&nbsp;&nbsp;  In a nutshell Data Management Group has helped many small to medium sized businesses to scale their business
                                  through the use of technology.
                                  Whats pertinent is DMGs ability to use the best technology available at the time the requirements are known.
                                  In 2021 we are focussing on a completely new requirement.
                                </p>
                                <p style={{ fontSize: '15px' }}>
                                  &nbsp;&nbsp;&nbsp;  MAKE EVERY BUSINESS PANDEMIC PROOF.
                                  End of Feb 2020 saw the UK and most of the world just shutting down to protect human beings from the devastating effects of a new virus.
                                  We lost the revenues from many of our customers.
                                  But we have continued with a 100% service to all customers at no cost until the situation gets back to normal.
                                  we  also built a brand new FRAME WORK to make businesses pandemic proof in the future
                                </p>

                              </Grid>
                            </Grid>
                          </div>

                        </Grid>
                      </Grid>
                    </div>
                    */}
                    </>
                  }
                </div>
                  </div>
                  <div style={{ marginTop: '200px' }}>
                    <Footer />
                  </div>
            </div>
          </div>

          :
          <>
            {pageBackgroundColor != '' ?
              <div style={{

                backgroundSize: 'cover',
                //backgroundImage: `linear-gradient( 0deg , rgb(63 54 80), rgb(240 232 239 / 0%)),url(${pageBackgroundUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: `${pageBackgroundColor}`
              }}
              >
                <div class="scrollable-container-main">
                    <div class="scrollable-content">
                      <div className={classes.pageLayout}>

                      {pageTopHtml != '' ?
                        <>
                          <div className={classes.pageLayout}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div>
                                  {parseHtml(pageTopHtml)}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </>

                        :
                        <>
                        {/** 
                        <div className={classes.pageLayout}>
                          <Grid container spacing={2}>
                            <Grid container spacing={2} className={classes.pageBodyStyleabout}>
                              <Grid item xs={12}>
                                <Box sx={{ minWidth: 120 }}>
                                  <Card className={classes.root}>
                                    <div className={classes.posRelative}>
                                      <CardMedia component="img" image={titleImage} title="Risk Group" alt="Ris Group" />
                                      <div className={classes.topImagePosabout}>
                                        <p className={classes.topHeaderTextabout}>
                                          ABOUT US
                                        </p>

                                      </div>
                                    </div>
                                  </Card>
                                </Box>
                              </Grid>
                              <div className={classes.margin}>
                                <Grid item xs={12}>
                                  <div>
                                    <br></br>
                                    <h3 className={classes.topHeaderText1}>OUR STOR</h3>
                                  </div>
                                </Grid>

                                <Grid container spacing={2} className={classes.storytop}>
                                  <Grid item xs={8} >
                                    <div className={classes.phaMain}>
                                      <p style={{ fontSize: '15px' }}><b>
                                        &nbsp;&nbsp;&nbsp; Our story starts in 1965 in a sleepy little village in North London UK.
                                        Its a two-story terraced building in a small parade with a shop on the
                                        ground floor and a spacious two bed apartment on the first floor.
                                      </b></p>
                                      <p style={{ fontSize: '15px' }}>
                                        &nbsp;&nbsp;&nbsp; The first founder of the company bought this building for £500, in 1965,
                                        moved into the apartment up above and opened an Insurance
                                        brokerage and property management company from the shop
                                        below. The Former name of Data management Group Ltd was Van Houten & co (insurance) Ltd.
                                        Young VanHouten  ran a very successful business from 1965 to 1977 when
                                        he decided to emigrate to the United States..
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div>
                                      <img src='https://datam.co.uk/images/u18_bqqio65k.png' alt='image'></img>
                                    </div>
                                  </Grid>

                                </Grid>

                                <Grid container spacing={2} className={classes.storytop}>
                                  <Grid item xs={3} >
                                    <div style={{ marginBottom: '10px' }}>
                                      <b>Ananda Jayasinghe</b>
                                    </div>
                                    <div >
                                      <img src='https://datam.co.uk/images/u20.png' alt='image'></img>
                                    </div>

                                  </Grid>
                                  <Grid item xs={8}>
                                    <div className={classes.phaMain}>
                                      <br></br>
                                      <p style={{ fontSize: '15px' }}>
                                        &nbsp;&nbsp;&nbsp;(also  known as Andy Jay by  those who could not pronounce hisreal Christian and Surname)
                                        bought the  business of Van Houten and the whole  building for a  sum in the region of  £25,000.
                                      </p>
                                      <p style={{ fontSize: '15px' }}>
                                        <b>
                                          &nbsp;&nbsp;&nbsp; Andy Graduated in Sri Lanka in 1963, emigrated to UK in 1965 and completed his Accountancy
                                          qualifications whilst working in the profession and in service and manufacturing industries. by 1977 he had achieved the highest possible position in industry related to his profession as the finance director of a construction company.
                                        </b>
                                      </p>
                                      <p style={{ fontSize: '15px' }}>
                                        <b>
                                          Andy recalls  ..</b> <br></br>

                                        &nbsp;&nbsp;&nbsp;"In the 1960's and 70s Managing Data was a very manual process. The only machinery available in those days was a Typewriter
                                        and a Comptometer. The latter being the calculator used by a trained operator to key in   numbers handwritten  in a
                                        spread sheet type of format. Communication was mainly by post. The telephone became the technology evolution with a telex machine being used for long distance.
                                        the photo copier and the fax machine arrived in the late 80."
                                        "Yet even with all of this, everything... more or less... required manual effort.
                                        Factory production lines and clerical work in the offices required loads and loads of staff.
                                        There was a hunger for labour saving mechanisation and automation of every conceivable process in the late 70s and 80s.
                                        However, new Equipment was expensive, and only the larger companies could afford the huge costs of mechanisation."
                                      </p>

                                    </div>

                                  </Grid>

                                </Grid>
                                <Grid container spacing={2} className={classes.storytop}>
                                  <Grid item xs={12} >
                                    <p style={{ fontSize: '15px' }}>
                                      <b>Says Andy :</b>
                                      &nbsp;&nbsp;&nbsp; "In 1977 I saw a gap in the market for the automation of management processes for the smallto medium sized businessesand that was the birth of  The Data Management Group Ltd ".
                                    </p>

                                    <p style={{ fontSize: '15px' }}>
                                      &nbsp;&nbsp;&nbsp;  Apart from the Freehold property the other main asset was Van Houten's Client list of some 1500 names and contact details of a mixture of businesses and private individuals.
                                      Andy didnt waste any time contacting each and every one of them and
                                      offering them a rounded financial and management consultancy service which included
                                      the offer to manage their insurances, real estate, and financial DATA, in addition to the services that
                                      Van Houten offered.
                                      Accountancy, Taxation, and System Analysis was taken up by many.
                                      Some of the success stories arising out of this effort soon prompted a name change of  Van Houten to
                                      Data Management Group Ltd.
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      Here are a few success stories.A small Bakery in Knights Bridge London, making cakes,became a large manufacturer of Frozen Gateau supplying all the supermarkets of the day. The company was sold for £20 million in the late 1980's. A Ugandan Asian family who was importing fresh vegetables from India ended up Owning most of the stalls and stores in Ealing Road to distribute tropical vegetables grown by the family business in newly acquired land in Portugal.
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      &nbsp;&nbsp;&nbsp;  a small Packaged tour operator struggling to compute the prices in good time
                                      to publish the seasonal brochures, ended up competing
                                      and beating the largest tour operator on price when their brochures
                                      wewere published soon after the giants’ brochures were delivered to travel
                                      agents.
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      &nbsp;&nbsp;&nbsp;  In a nutshell Data Management Group has helped many small to medium sized businesses to scale their business
                                      through the use of technology.
                                      Whats pertinent is DMGs ability to use the best technology available at the time the requirements are known.
                                      In 2021 we are focussing on a completely new requirement.
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      &nbsp;&nbsp;&nbsp;  MAKE EVERY BUSINESS PANDEMIC PROOF.
                                      End of Feb 2020 saw the UK and most of the world just shutting down to protect human beings from the devastating effects of a new virus.
                                      We lost the revenues from many of our customers.
                                      But we have continued with a 100% service to all customers at no cost until the situation gets back to normal.
                                      we  also built a brand new FRAME WORK to make businesses pandemic proof in the future
                                    </p>

                                  </Grid>
                                </Grid>
                              </div>

                            </Grid>
                          </Grid>
                        </div>
                        */}
                        </>
                      }
                    </div>
                    </div>
                    <div style={{ marginTop: '200px' }}>
                      <Footer />
                    </div>
                  </div>
              </div>
              :

              <div style={{
                height: '100vh',
                backgroundSize: 'cover',
                backgroundImage: `linear-gradient( 0deg , rgb(63 54 80), rgb(240 232 239 / 0%)),url(https://assets.kpmg/is/image/kpmg/pointing-digital-screen:cq5dam.web.512.341)`,
                backgroundRepeat: 'no-repeat'
              }}
              >
                <div class="scrollable-container-main">
                  <div class="scrollable-content">
                    <div className={classes.pageLayout}>
                      {pageTopHtml != '' ?
                        <>
                          
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div>
                                  {parseHtml(pageTopHtml)}
                                </div>
                              </Grid>
                            </Grid>
                          
                        </>

                        :
                        <>
                        {/** 
                          <Grid container spacing={2}>
                            <Grid container spacing={2} className={classes.pageBodyStyleabout}>
                              <Grid item xs={12}>
                                <Box sx={{ minWidth: 120 }}>
                                  <Card className={classes.root}>
                                    <div className={classes.posRelative}>
                                      <CardMedia component="img" image={titleImage} title="Risk Group" alt="Ris Group" />
                                      <div className={classes.topImagePosabout}>
                                        <p className={classes.topHeaderTextabout}>
                                          ABOUT US
                                        </p>

                                      </div>
                                    </div>
                                  </Card>
                                </Box>
                              </Grid>
                              <div className={classes.margin}>
                                <Grid item xs={12}>
                                  <div>
                                    <br></br>
                                    <h3 className={classes.topHeaderText1}>OUR STOR</h3>
                                  </div>
                                </Grid>

                                <Grid container spacing={2} className={classes.storytop}>
                                  <Grid item xs={8} >
                                    <div className={classes.phaMain}>
                                      <p style={{ fontSize: '15px' }}><b>
                                        &nbsp;&nbsp;&nbsp; Our story starts in 1965 in a sleepy little village in North London UK.
                                        Its a two-story terraced building in a small parade with a shop on the
                                        ground floor and a spacious two bed apartment on the first floor.
                                      </b></p>
                                      <p style={{ fontSize: '15px' }}>
                                        &nbsp;&nbsp;&nbsp; The first founder of the company bought this building for £500, in 1965,
                                        moved into the apartment up above and opened an Insurance
                                        brokerage and property management company from the shop
                                        below. The Former name of Data management Group Ltd was Van Houten & co (insurance) Ltd.
                                        Young VanHouten  ran a very successful business from 1965 to 1977 when
                                        he decided to emigrate to the United States..
                                      </p>
                                    </div>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div>
                                      <img src='https://datam.co.uk/images/u18_bqqio65k.png' alt='image'></img>
                                    </div>
                                  </Grid>

                                </Grid>

                                <Grid container spacing={2} className={classes.storytop}>
                                  <Grid item xs={3} >
                                    <div style={{ marginBottom: '10px' }}>
                                      <b>Ananda Jayasinghe</b>
                                    </div>
                                    <div >
                                      <img src='https://datam.co.uk/images/u20.png' alt='image'></img>
                                    </div>

                                  </Grid>
                                  <Grid item xs={8}>
                                    <div className={classes.phaMain}>
                                      <br></br>
                                      <p style={{ fontSize: '15px' }}>
                                        &nbsp;&nbsp;&nbsp;(also  known as Andy Jay by  those who could not pronounce hisreal Christian and Surname)
                                        bought the  business of Van Houten and the whole  building for a  sum in the region of  £25,000.
                                      </p>
                                      <p style={{ fontSize: '15px' }}>
                                        <b>
                                          &nbsp;&nbsp;&nbsp; Andy Graduated in Sri Lanka in 1963, emigrated to UK in 1965 and completed his Accountancy
                                          qualifications whilst working in the profession and in service and manufacturing industries. by 1977 he had achieved the highest possible position in industry related to his profession as the finance director of a construction company.
                                        </b>
                                      </p>
                                      <p style={{ fontSize: '15px' }}>
                                        <b>
                                          Andy recalls  ..</b> <br></br>

                                        &nbsp;&nbsp;&nbsp;"In the 1960's and 70s Managing Data was a very manual process. The only machinery available in those days was a Typewriter
                                        and a Comptometer. The latter being the calculator used by a trained operator to key in   numbers handwritten  in a
                                        spread sheet type of format. Communication was mainly by post. The telephone became the technology evolution with a telex machine being used for long distance.
                                        the photo copier and the fax machine arrived in the late 80."
                                        "Yet even with all of this, everything... more or less... required manual effort.
                                        Factory production lines and clerical work in the offices required loads and loads of staff.
                                        There was a hunger for labour saving mechanisation and automation of every conceivable process in the late 70s and 80s.
                                        However, new Equipment was expensive, and only the larger companies could afford the huge costs of mechanisation."
                                      </p>

                                    </div>

                                  </Grid>

                                </Grid>
                                <Grid container spacing={2} className={classes.storytop}>
                                  <Grid item xs={12} >
                                    <p style={{ fontSize: '15px' }}>
                                      <b>Says Andy :</b>
                                      &nbsp;&nbsp;&nbsp; "In 1977 I saw a gap in the market for the automation of management processes for the smallto medium sized businessesand that was the birth of  The Data Management Group Ltd ".
                                    </p>

                                    <p style={{ fontSize: '15px' }}>
                                      &nbsp;&nbsp;&nbsp;  Apart from the Freehold property the other main asset was Van Houten's Client list of some 1500 names and contact details of a mixture of businesses and private individuals.
                                      Andy didnt waste any time contacting each and every one of them and
                                      offering them a rounded financial and management consultancy service which included
                                      the offer to manage their insurances, real estate, and financial DATA, in addition to the services that
                                      Van Houten offered.
                                      Accountancy, Taxation, and System Analysis was taken up by many.
                                      Some of the success stories arising out of this effort soon prompted a name change of  Van Houten to
                                      Data Management Group Ltd.
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      Here are a few success stories.A small Bakery in Knights Bridge London, making cakes,became a large manufacturer of Frozen Gateau supplying all the supermarkets of the day. The company was sold for £20 million in the late 1980's. A Ugandan Asian family who was importing fresh vegetables from India ended up Owning most of the stalls and stores in Ealing Road to distribute tropical vegetables grown by the family business in newly acquired land in Portugal.
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      &nbsp;&nbsp;&nbsp;  a small Packaged tour operator struggling to compute the prices in good time
                                      to publish the seasonal brochures, ended up competing
                                      and beating the largest tour operator on price when their brochures
                                      wewere published soon after the giants’ brochures were delivered to travel
                                      agents.
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      &nbsp;&nbsp;&nbsp;  In a nutshell Data Management Group has helped many small to medium sized businesses to scale their business
                                      through the use of technology.
                                      Whats pertinent is DMGs ability to use the best technology available at the time the requirements are known.
                                      In 2021 we are focussing on a completely new requirement.
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                      &nbsp;&nbsp;&nbsp;  MAKE EVERY BUSINESS PANDEMIC PROOF.
                                      End of Feb 2020 saw the UK and most of the world just shutting down to protect human beings from the devastating effects of a new virus.
                                      We lost the revenues from many of our customers.
                                      But we have continued with a 100% service to all customers at no cost until the situation gets back to normal.
                                      we  also built a brand new FRAME WORK to make businesses pandemic proof in the future
                                    </p>

                                  </Grid>
                                </Grid>
                              </div>

                            </Grid>
                          </Grid>
                        */}  
                        </>  
                        
                      }

</div>
                  </div>
                  <div style={{ marginTop: '200px' }}>
                    <Footer />
                  </div>
                </div>
              </div>
            }

          </>
        }
      </main>

      <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>




    </>
  )
}

export default AboutUs