import React from 'react'
import Box from '@mui/material/Box';
import { Grid, makeStyles } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

const RiskLevelSlider = ({showSelectBar,handleChange,sliderType,riskCategoryMethodData,nameChange,sliderValue,sliderValueStyle,sliderValueName,riskClearFunction,setSliderValueFunction,SliderName,MethodDropdown}) => {
  return (
    <>

    {
      showSelectBar ?

        <div>

          <Box >
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">{MethodDropdown}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sliderType}
                    label="Age"
                    onChange={handleChange}
                  >

                    {riskCategoryMethodData.map((data, index) => (

                      <MenuItem value={data.id}>{data.methodName}</MenuItem>

                    ))}

                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

        </div>
        : null
    }
    {
      riskCategoryMethodData.map((data) => (
        <>
          {
            sliderType === data.id ?
              <>

                <p style={{ color: 'black' }}>{SliderName}</p>
                <Grid container spacing={2}>

                  <Grid item xs={7}>
                    <div>

                      <input
                        id='slider'
                        type="range"
                        min={data.minimumWeightingNumber}
                        max={data.maximumWeightingNumber}
                        value={sliderValue}
                        onChange={({ target: { value } }) => {setSliderValueFunction(value, data.minimumWeightingNumber, data.maximumWeightingNumber, 1);nameChange(value);}}
                        className={`slider`}
                        // className={btnEntityClass()}
                        //style={{background: `${sliderValueStyle}`}}
                        //linear-gradient(90deg, #014502 12%, #d3d3d3 0%)
                        style={{ background: `${sliderValueStyle}` }}
                        onClick={() => nameChange(sliderValue)}
                         />
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div >
                      <div >
                        <p style={{ fontSize: '10px' }}>{sliderValueName}</p>
                      </div>

                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    <div>
                      <Button variant="outlined" onClick={riskClearFunction}>clear</Button>
                    </div>
                  </Grid>


                </Grid>
                <p>
                  <b>{sliderValue}&nbsp;-&nbsp;{sliderValueName}</b>
                </p>
              </>

              : null
          }
     
        </>
      ))
    }

  </>
  )
}

export default RiskLevelSlider