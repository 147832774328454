import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import CardHeader from '@mui/material/CardHeader';
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import axios from 'axios'
import { CONFIG } from '../config';
import parseHtml from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

const DomainEndpoint = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const keyCode = queryParams.get('keyCode');
  const pageType = queryParams.get('type');
  const domVersion = queryParams.get('domVersion');


  const [loggedDomainName, setLoggedDomainName] = useState('');
  const [pageTopHtml, setPageTopHtml] = React.useState('');
  const [pageBackgroundUrl, setPageBackgroundUrl] = React.useState('');
  const [pageBackgroundColor, setPageBackgroundColor] = React.useState('');

  useEffect(() => {
    var domainName = window.location.hostname;
    var hostNameArr = domainName.split('www.')
    if (hostNameArr.length > 1) {
      domainName = hostNameArr[1]
    } else {
      domainName = hostNameArr[0]
    }

    setLoggedDomainName(domainName);
    getAllSubPages(domainName);
  }, []);

  function getAllSubPages(domainName) {
    var ePoint = '/loginPageSubPage/filter?parentDomainName=' + domainName;
    if (pageType != null && pageType != undefined) {

      if (pageType === 'ourservice') {
        ePoint = '/ourServicesPageSubPage/filter?parentDomainName=' + domainName;
      }
      if (pageType === 'aboutus') {
        ePoint = '/aboutUsPageSubPage/filter?parentDomainName=' + domainName;
      }


    }
    axios(CONFIG.EXPERT_SERVER_URL + ePoint, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("++++Login Page Sub Pages Details +++++++++++++++++");
        console.log(response.data);

        response.data.forEach((item, index) => {
          if (domVersion != undefined && domVersion != null) {
            if (item.parentVersionNumber === domVersion){
              item.pages.forEach((data) => {
                if (data.keyCode === keyCode) {
                  if (data.pageContent != undefined && data.pageContent != null) {
                    var tArr = data.pageContent.split("<body>");
                    if (tArr.length > 1) {
                      var tArr1 = tArr[1].split("</body>");
                      setPageTopHtml(tArr1[0].replaceAll("\\n", ''));
                    } else {
                      if (data.pageContent != "") {
                        setPageTopHtml(data.pageContent.replaceAll("\\n", ''));
                      }
                    }

                  }
                  //alert(data.backgroundUrl)
                  //alert(data.backgroundColor)
                  if (data.backgroundUrl != undefined && data.backgroundUrl != null) {
                    setPageBackgroundUrl(data.backgroundUrl);
                  }
                  if (data.backgroundColor != undefined && data.backgroundColor != null) {
                    setPageBackgroundColor(data.backgroundColor);
                  }
                  if (data.pageName != undefined && data.pageName != null) {
                    document.title = data.pageName;
                  }
                }
              })
            }
          } else {
            item.pages.forEach((data) => {
              if (data.keyCode === keyCode) {
                if (data.pageContent != undefined && data.pageContent != null) {
                  var tArr = data.pageContent.split("<body>");
                  if (tArr.length > 1) {
                    var tArr1 = tArr[1].split("</body>");
                    setPageTopHtml(tArr1[0].replaceAll("\\n", ''));
                  } else {
                    if (data.pageContent != "") {
                      setPageTopHtml(data.pageContent.replaceAll("\\n", ''));
                    }
                  }

                }
                //alert(data.backgroundUrl)
                //alert(data.backgroundColor)
                if (data.backgroundUrl != undefined && data.backgroundUrl != null) {
                  setPageBackgroundUrl(data.backgroundUrl);
                }
                if (data.backgroundColor != undefined && data.backgroundColor != null) {
                  setPageBackgroundColor(data.backgroundColor);
                }
                if (data.pageName != undefined && data.pageName != null) {
                  document.title = data.pageName;
                }
              }
            })
          }



        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const classes = useStyle();


  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  setTimeout(() => {

    var imgTags = document.getElementsByTagName('img');
    for (var i = 0; i < imgTags.length; i++) {
      imgTags[i].onclick = function () {
        var str = "<img src=\"" + this.src + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      };
      imgTags[i].onmouseover = function () {
        this.style.cursor = 'pointer';
      };
    }
  }, 1000);
  return (
    <>
      <Header />
      <main>
        {pageBackgroundUrl !== '' ? (
          <div
            style={{
              height: '99vh',
              backgroundSize: 'cover',
              backgroundImage: `linear-gradient(0deg , rgb(63 54 80), rgb(240 232 239 / 0%)),url(${pageBackgroundUrl})`,
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div>
              <div className="scrollable-container">
                <div className="scrollable-content">
                  {pageTopHtml !== '' && (
                    <div className={classes.pageLayout}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div>{parseHtml(pageTopHtml)}</div>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
                <footer style={{ marginTop: '100px' }}>
                  <Footer />
                </footer>
              </div>
            </div>
          </div>
        ) : (
          <>
            {pageBackgroundColor !== '' ? (
              <div
                style={{
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: `${pageBackgroundColor}`,
                }}
              >
                <div>
                  <div class="scrollable-container-main">
                    <div class="scrollable-content">
                      <div className={classes.pageLayout}>
                        {pageTopHtml != '' ?
                          <>

                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div>
                                  {parseHtml(pageTopHtml)}
                                </div>
                              </Grid>
                            </Grid>

                          </>

                          :

                          ''

                        }

                      </div>

                    </div>
                    <footer style={{ marginTop: '100px' }}>
                      <Footer />
                    </footer>
                  </div>
                </div>
              </div>
            )
              : (
                <div
                  style={{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <div>
                    <div class="scrollable-container-main">
                      <div class="scrollable-content">
                        <div className={classes.pageLayout}>
                          {pageTopHtml != '' ?
                            <>

                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <div>
                                    {parseHtml(pageTopHtml)}
                                  </div>
                                </Grid>
                              </Grid>

                            </>

                            :

                            ''

                          }

                        </div>

                      </div>
                      <footer style={{ marginTop: '100px' }}>
                        <Footer />
                      </footer>
                    </div>
                  </div>
                </div>
              )
            }
          </>
        )}
      </main>


      <Dialog
        open={openImage}
        //onClose={setOpenImage(false)}
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        aria-labelledby="draggable-dialog-title"

      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

        </DialogTitle>
        <DialogContent>
          {parseHtml(imageHtmlData)}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseClick()}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DomainEndpoint