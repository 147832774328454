import React, { useEffect, useState, useRef } from 'react'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages'
import { Grid, Input, makeStyles } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { ArrowBack, Delete, DetailsSharp, Javascript, Label } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { CONFIG } from '../config'
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SavePageAs from '../Components/SavePageAs';
import EvaluateComponent1 from '../Components/EvaluateComponent1';
import EvaluateComponent from '../Components/EvaluateComponent';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Close } from '@mui/icons-material';
import { ClockLoader } from 'react-spinners';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageTop: {
    marginTop: '115px',
  },
  pageBodyStyle: {

    paddingTop: '12px',
  },

}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TrialUserList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [PageName, setPageName] = useState('Trial Users List')

  const [rows, setRows] = React.useState([]);

  const [globalCentreId, setGlobalCentreId] = React.useState('');
  const [globalUserId, setGlobalUserId] = React.useState('');
  const [globalUserRoleId, setGlobalUserRoleId] = React.useState('');

  const [moreDetailOpen, setMoreDetailOpen] = React.useState(false);
  const [moreDetailScroll, setMoreDetailScroll] = React.useState('paper');

  const [userCode, setUserCode] = React.useState('');
  const [allRegisters, setAllRegisters] = React.useState([]);
  const [selectedRiskEvaluateTitle, setSelectedRiskEvaluateTitle] = React.useState('');
  const [openShowQuestions, setOpenShowQuestions] = React.useState(false);
  const [openShowQuestionsScroll, setOpenShowQuestionsScroll] = React.useState('paper');
  const [rowss, setRowss] = React.useState([]);



  const moreDetailHandleClickClose = () => {
    setMoreDetailOpen(false);
  };

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  function PaperComponentAllQuestionData(props) {
    return (
      <Draggable
        handle="#collection-data-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  React.useEffect(() => {
    getSessionData()
    getColors();


  }, [])

  function getSessionData() {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    console.log("++++++++++++++++++++++11111data1111111");
    console.log(data)
    setGlobalUserId(data.userId);
    setGlobalUserRoleId(data.userRoleId);
    setGlobalCentreId(data.centreId);
    validateTrailUser(data.centreId)
    getAllRegisterEval(data.centreId)

  }

  const [colorPatterns, setColorPatterns] = React.useState([])

  function getColors() {
    axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR + "/all", {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("+++++++++++++++++All colors++++++++++++++++++");
        console.log(response.data);
        setColorPatterns(response.data);

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function compareEndDate( a, b ) {
    var nameA = a.endDate; // ignore upper and lowercase
    var nameB = b.endDate; // ignore upper and lowercase
  
    if ( nameA < nameB ){
        return -1;
    }
    if ( nameA > nameB ){
        return 1;
    }
    // names must be equal
    return 0;
  }



  function validateTrailUser(centreId) {

    axios(CONFIG.MAIN_URL + `/trialCentreSetup/filter?centreId=` + centreId, {
      method: 'get',
    })
      .then(function (response) {
        console.log("++++Trial Centre setup Details +++++++++++++++++");
        console.log(response.data);
        var trialDays = 0;
        response.data.forEach((item, index) => {
          if (index === 0) {
            trialDays = item.noOfTrialDays
          }
        })

        axios(CONFIG.MAIN_URL + `/trialUsers/filter?centreId=` + centreId, {
          method: 'get',
        })
          .then(function (response) {


            console.log("++++Trial User setup Details +++++++++++++++++");
            console.log(response.data);

            var arr = []
            response.data.forEach((item, index) => {
              var dayMsg = '';
              console.log(item);
              var startDate = new Date(item.trailStartDate);

              if (item.trialDays != undefined && item.trialDays != null && item.trialDays.length > 0) {
                var trilDayArr = item.trialDays;
                trilDayArr = trilDayArr.sort(compareEndDate);
                console.log("++++XXXXXXXXXXXX trilDayArr XXXXXXXXXXXXXXXXXXXXXXXXXXXXX +++++++++++++++++");
                console.log(trilDayArr);
                var tObject = {}
                trilDayArr.forEach((itemDay) => {
                  tObject = itemDay;
                });
                if (tObject.endDate != undefined && tObject.endDate != null) {
                  var startDate = new Date(tObject.amendDate); 
                  var endDate = new Date(tObject.endDate);
                  var currentDate = new Date();
                  var DifferenceInTime = endDate.getTime() - currentDate.getTime();
                  var DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
                  if (DifferenceInDays > 0) {
                    var daysRemaining = DifferenceInDays
                    var tArr2 = daysRemaining.toString().split(".");
                    var dayCount = parseInt(tArr2[0]);
                    if (tArr2.length > 1) {
                      if (parseFloat("0." + tArr2[1]) > 0.5) {
                        dayCount = dayCount + 1;
                      }
                    }

                    dayMsg = (dayCount + " days remaning for Trialing. ")
                  } else {
                    dayMsg = (" Trial Period is over.")
                  }
                }
              }




              /*

              var currentDate = new Date();
              var DifferenceInTime = currentDate.getTime() - startDate.getTime();
              var DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
              var increaseTrialDays = 0;
              if (item.noOfIncreaseTrialDays != undefined && item.noOfIncreaseTrialDays != null) {
                increaseTrialDays = item.noOfIncreaseTrialDays;
              }
              if (DifferenceInDays > trialDays + increaseTrialDays) {
                dayMsg = (" Trial Period is over.")
              } else {
                var daysRemaining = (trialDays + increaseTrialDays) - DifferenceInDays
                var tArr2 = daysRemaining.toString().split(".");
                var dayCount = parseInt(tArr2[0]);
                if (tArr2.length > 1) {
                  if (parseFloat("0." + tArr2[1]) > 0.5) {
                    dayCount = dayCount + 1;
                  }
                }
                dayMsg = (dayCount + " days remaning for Trialing. ")
              }
              */

              console.log(item);
              console.log("++++++++++++++++DifferenceInDays+++++++++++++++++++++");
              console.log(DifferenceInDays);

              var obj = {}

              obj["id"] = index + 1;
              obj["centreId"] = index.centreId;
              obj["firstName"] = item.firstName;
              obj["lastName"] = item.lastName;
              obj["trailStartDate"] = item.trailStartDate;
              obj["trailEndDate"] = dayMsg;
              obj["userMailAddress"] = item.userMailAddress;
              obj["userCode"] = item.userCode;
              arr.push(obj)

            })
            setRows(arr)


          })
          .catch(function (error) {
            console.log(error);
          });

      })
      .catch(function (error) {
        console.log(error);
      });


  }
  const renderTextId3 = (params) => {
    return (
      <span >{params.row.id}</span>
    )
  }

  const renderTextfirstName = (params) => {
    return (
      <span >{params.row.firstName}</span>
    )
  }

  const renderTextlastName = (params) => {
    return (
      <span >{params.row.lastName}</span>
    )
  }

  const renderTextTrialStartDate = (params) => {
    const datePart = params.row.trailStartDate.substring(0, 10);
    return (
      <span >{datePart}</span>
    )
  }

  const renderTextTrialEndDate = (params) => {

    return (
      <span>
        {params.row.trailEndDate}
      </span>
    );
  };

  const renderTextUserCode = (params) => {
    return (
      <span >{params.row.userCode}</span>
    )
  }

  const renderTextUserMailAddress = (params) => {
    return (
      <span >{params.row.userMailAddress}</span>
    )
  }

  function parseId(userCode) {
    console.log(allRegisters)
    setUserCode(userCode)

    // var tmpArray = [];
    // setQuesDataCount(no);
    // setSelectedQuestion(question);
    // savedQuestionArray.forEach((item) => {
    //   if (item.questionId === questionId) {
    //     tmpArray.push(item);
    //     setSelectedQuesData(tmpArray);
    //   }
    // });

    setMoreDetailOpen(true);
    setMoreDetailScroll('paper');
  }

  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            parseId(params.row.userCode, params.row.centreId)
          }}
        >
          More Info
        </Button>
      </strong>
    )
  }

  const columns = [
    { field: 'id', headerName: 'No', renderCell: renderTextId3, resizable: true, width: 20 },
    { field: 'userCode', headerName: 'User Code', renderCell: renderTextUserCode, resizable: true, cellClassName: 'super-app-theme--cell', width: 120 },
    { field: 'firstName', headerName: 'First Name', renderCell: renderTextfirstName, resizable: true, cellClassName: 'super-app-theme--cell', width: 120 },
    { field: 'lastName', headerName: 'Last Name', renderCell: renderTextlastName, resizable: true, cellClassName: 'super-app-theme--cell', width: 150 },
    { field: 'trailStartDate', headerName: 'Trial Start Date', renderCell: renderTextTrialStartDate, resizable: true, cellClassName: 'super-app-theme--cell', width: 150 },
    { field: 'trailEndDate', headerName: 'Remaining Days ', renderCell: renderTextTrialEndDate, resizable: true, cellClassName: 'super-app-theme--cell', width: 250 },

    { field: 'userMailAddress', headerName: 'User Mail Address', renderCell: renderTextUserMailAddress, resizable: true, cellClassName: 'super-app-theme--cell', width: 170 },
    {
      field: 'action',
      headerName: 'More Info',
      sortable: false,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: true,

    },
  ];

  function getAllRegisterEval(centrId) {
    console.log(userCode)
    axios(CONFIG.MAIN_URL + `/register/all`, {
      method: 'get',
    })
      .then(function (response) {
        //var arr = []
        console.log("all register");
        console.log(response.data);


        var arr = []
        response.data.forEach((item) => {

          if (item.centreId === centrId) {
            arr.push(item)
            console.log(item)
          }
        })

        setAllRegisters(arr)
        console.log(arr)

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function formatDate(string) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleString("en-GB");
  }

  const registerTableRowData = (
    <TableBody>
      {allRegisters.map((row, index) => {
        return row.createdUserId === userCode ? (
          <StyledTableRow key={index} className={classes.tableTrStyle}>
            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
              <div style={{ whiteSpace: 'normal' }} className='tableStyle'>{row.gptSessionId}</div>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
              <div style={{ whiteSpace: 'normal' }} className='tableStyle'>{row.name}</div>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{ textAlign: 'center' }}>
              <div style={{ whiteSpace: 'normal' }} className='tableStyle'>{(row.createdDate.substring(0, 10))}</div>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{ textAlign: 'center' }}>
              <div style={{ whiteSpace: 'normal' }} className='tableStyle'>{row.createdUserId}</div>
            </StyledTableCell>
            <StyledTableCell align="right" className={classes.tableTrStyle}>
              <DetailsSharp
                className={'mousePointer'}
                onClick={() => { showRegisterArrary(row) }}
              />
            </StyledTableCell>
          </StyledTableRow>
        ) : (

          null
        );
      })}
    </TableBody>
  );


  const [openHaDetails, setOpenHaDetails] = React.useState(false);
  const [rHName, setRHName] = React.useState('');
  const [rHId, setRHId] = React.useState('');
  const [selectedRegisteredId, setSelectedRegisteredId] = React.useState('');

  const [registerObjFromTable, setRegisterObjFromTable] = React.useState({});
  const [registerEvaluate, setRegisterEvaluate] = React.useState(false);
  const [openRegisterDataForEvalScroll, setOpenRegisterDataForEvalScroll] = React.useState('paper');
  const [openRegisterDataForEval, setOpenRegisterDataForEval] = React.useState(false);
  const [fullScreenState, setFullScreenState] = React.useState(false);
  const [selectedRegisterEvaluateTitle, setSelectedRegisterEvaluateTitle] = React.useState('');
  const [multipleRoles, setMultipleRoles] = React.useState(false);
  const [registerTypeDataForEval, setRegisterTypeDataForEval] = React.useState({});
  const [maxWidthState, setMaxWidthState] = React.useState('lg');
  const [loading1, setLoading1] = React.useState(false);
  const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
  const [questionDetailScroll, setQuestionDetailScroll] = React.useState('paper');

  const [quesDataCount, setQuesDataCount] = React.useState(0);
  const [selectedQuestion, setSelectedQuestion] = React.useState('');
  const [savedQuestionArray, setSavedQuestionArray] = React.useState([]);
  const [selectedQuesData, setSelectedQuesData] = React.useState([]);

  function RegisterDataForEvalfunctionClose() {
    setOpenRegisterDataForEval(false)
  }

  const questionDetailHandleClickClose = () => {
    setQuestionDetailOpen(false);
  };

  function PaperComponentCollectionDataRegister(props) {
    return (
      <Draggable
        handle="#register-data-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  const haDetailshandleClose = () => {
    setOpenHaDetails(false);
  };


  function showRegisterArrary(gObj) {
    setMoreDetailOpen(false)
    setRHName(gObj.name);
    //setRHId(gObj.id);
    setSelectedRegisteredId(gObj.id);
    setRegisterObjFromTable(gObj)
    setOpenHaDetails(true);

  }

  const [openDisplayChoice, setOpenDisplayChoice] = React.useState(false);
  const handleCloseDisplayChoice = () => {
    setOpenDisplayChoice(false);
  };

  function evaluateRiskFuction() {
    setOpenDisplayChoice(true)


  }

  function createJsonObject(obj) {
    console.log("+++++++++++++++++++obj+++++++++++++++++++++");
    console.log(obj);
    var regTmpId = 100000;
    obj.riskRegister.forEach((data) => {
      data["riskTypeId"] = regTmpId
      regTmpId++;
      if (data.risks != undefined && data.risks != null) {
        data.risks.forEach((risk) => {
          risk["riskTypeId"] = regTmpId
          regTmpId++;
          if (risk.riskHierarchy != undefined && risk.riskHierarchy != null) {
            if (risk.riskHierarchy.riskHierarchyData != undefined && risk.riskHierarchy.riskHierarchyData != null) {
              var riskHierarchyObj = risk.riskHierarchy.riskHierarchyData;
              if (riskHierarchyObj.riskTypes != undefined && riskHierarchyObj.riskTypes != null && riskHierarchyObj.riskTypes.length > 0) {
                risk["subRisks"] = riskHierarchyObj.riskTypes;
              }
            }

          }

        })
      }

    })

    var dataStr = JSON.stringify(obj);
    dataStr = dataStr.replaceAll("\"riskRegister\"", "\"riskTypes\"");
    dataStr = dataStr.replaceAll("\"section\"", "\"name\"");
    dataStr = dataStr.replaceAll("\"risks\"", "\"subRisks\"");
    dataStr = dataStr.replaceAll("\"likelihood\"", "\"riskLikelihood\"");
    dataStr = dataStr.replaceAll("\"impact\"", "\"riskImpact\"");
    var jsonObj = JSON.parse(dataStr)

    return jsonObj;


  }

  var globalIdCount = 1;


  function assignExtraAttributes(data, tmpIdCount) {
    data.forEach((item) => {
      item["riskTypeTmpId"] = globalIdCount
      console.log(globalIdCount)
      globalIdCount++;
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
        assignExtraAttributes(item.subRisks, tmpIdCount);
      }
    })
    return tmpIdCount;
  }

  function getAllRegisterEvalUser(centrId, regId, type) {
    axios(CONFIG.MAIN_URL + `/register/all`, {
      method: 'get',
    })
      .then(function (response) {
        //var arr = []
        console.log("all register");
        console.log(response.data);
        response.data.forEach((item) => {
          if (item.centreId === centrId && item.id === regId) {
            var tmpIdCount = 1;
            var tmpIdCount1 = 1;
            var jsonObj = createJsonObject(item);
            setSelectedRegisterEvaluateTitle(item.name)


            globalIdCount = 1;
            jsonObj.riskTypes.forEach((item, index) => {
              item["riskTypeTmpId"] = globalIdCount;
              console.log(globalIdCount)
              globalIdCount++
              if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                tmpIdCount = assignExtraAttributes(item.subRisks, tmpIdCount);
                tmpIdCount++;
              }

            })


            setRegisterTypeDataForEval(jsonObj);

            setMultipleRoles(false)

            var mainArr = []
            jsonObj.riskTypes.forEach((data) => {
              var secName = data.role;
              var obj = {};
              var arr = [];
              var canTake = true;

              mainArr.forEach((arrData) => {
                if (arrData.role === secName) {
                  canTake = false;
                }
              })

              if (canTake) {
                jsonObj.riskTypes.forEach((item) => {
                  if (secName === item.role) {
                    arr.push(item);
                  }
                })

                obj["role"] = data.role

                mainArr.push(obj);
              }
            })

            if (mainArr.length > 1) {
              setMultipleRoles(true)
            }

            if (type === "RiskName") {
              setMultipleRoles(false)
            }




          }

        })

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function byRoleFuction() {
    getAllRegisterEvalUser(globalCentreId, selectedRegisteredId, 'Role');
    setRegisterEvaluate(true)
    setOpenRegisterDataForEval(true)
    setOpenRegisterDataForEvalScroll('paper');
    setOpenDisplayChoice(false);
  }

  function byRiskNameFuction() {
    getAllRegisterEvalUser(globalCentreId, selectedRegisteredId, 'RiskName');
    setRegisterEvaluate(true)
    setOpenRegisterDataForEval(true)
    setOpenRegisterDataForEvalScroll('paper');
    setOpenDisplayChoice(false);
  }


  function handleButtonShowQuestion() {
    setLoading1(true);
    var gObj = registerObjFromTable;
    setSelectedRiskEvaluateTitle(gObj.name)
    axios(CONFIG.MAIN_URL + "/question/getquestiononly?centreId=" + globalCentreId, {
      method: 'get',
    })
      .then(function (response) {
        var tArr = [];
        gObj.riskRegister.map((data) => {
          if (data.risks != undefined && data.risks != null) {
            data.risks.map((data1) => {
              if (
                data1.riskHierarchy != undefined &&
                data1.riskHierarchy != null &&
                data1.riskHierarchy.riskHierarchyData != undefined &&
                data1.riskHierarchy.riskHierarchyData != null
              ) {
                response.data.forEach((itemX, index) => {
                  if (itemX.riskTypes != undefined && itemX.riskTypes != null) {
                    if (parseInt(itemX.riskTypes.collectionRiskId) === parseInt(data1.riskHierarchy.riskHierarchyData.riskId)) {
                      tArr.push(itemX);
                    }
                  }
                })
              }
            })
          }
        })
        setLoading1(false)
        var count = 1;
        var tmpArray = [];
        setRowss([]);
        tArr.forEach((item) => {
          console.log(item);
          var tmpObj = {};
          tmpObj["questionId"] = item.questionId;
          tmpObj["id"] = count;
          tmpObj["riskName"] = item.riskTypes.name;
          if (item.entity != undefined && item.entity != null) {
            tmpObj["entityName"] = item.entity.name;
          } else {
            tmpObj["entityName"] = "";
          }

          var priNumber = ""
          if (item.principle.principleNumber != undefined && item.principle.principleNumber != null) {
            priNumber = item.principle.principleNumber;
          }
          if (priNumber != '') {
            tmpObj["principalName"] = "Principle " + priNumber + ": " + item.principle.name;
          } else {
            tmpObj["principalName"] = item.principle.name;
          }

          tmpObj["objectiveName"] = item.objective.name;
          tmpObj["componentName"] = item.component.name;


          //tmpObj["principalName"] = item.principle.name;
          tmpObj["question"] = item.question;
          tmpObj["questionType"] = item.questionType;
          tmpObj["questionData"] = item;
          tmpArray.push(tmpObj);
          setRowss(tmpArray);
          count++;
        })
        setOpenShowQuestions(true);
        setOpenShowQuestionsScroll('paper');
        //setSavedQuestionArray(tArr);
        //showQuestionFuction()
      })
      .catch(function (error) {
        setLoading1(false);
        console.log(error);
      });


  }

  const renderTextRiskName = (params) => {
    return (
      <Tooltip title={params.row.riskName}>
        <span style={{ fontSize: '10px' }}>{params.row.riskName}</span>
      </Tooltip>
    )
  }
  const renderTextId2 = (params) => {
    return (
      <span >{params.row.id}</span>
    )
  }
  const renderTextObjectiveName = (params) => {
    return (
      <Tooltip title={params.row.objectiveName}>
        <span style={{ fontSize: '10px' }}>{params.row.objectiveName}</span>
      </Tooltip>
    )
  }
  const renderTextComponentName = (params) => {
    return (
      <Tooltip title={params.row.componentName}>
        <span style={{ fontSize: '10px' }}>{params.row.componentName}</span>
      </Tooltip>
    )
  }
  const renderTextPrincipalName = (params) => {
    return (
      <Tooltip title={params.row.principalName}>
        <span style={{ fontSize: '10px' }}>{params.row.principalName}</span>
      </Tooltip>
    )
  }

  const renderTextEntityName = (params) => {
    return (
      <Tooltip title={params.row.entityName}>
        <span style={{ fontSize: '10px' }}>{params.row.entityName}</span>
      </Tooltip>
    )
  }

  const renderTextQuestion2 = (params) => {
    return (
      <Tooltip title={params.row.question}>
        <span style={{ fontSize: '10px' }}>{params.row.question}</span>
      </Tooltip>
    )
  }

  const renderTextQuestionType2 = (params) => {
    return (
      <span style={{ fontSize: '10px' }}>{params.row.questionType}</span>
    )
  }

  function parseId1(no, questionId, question) {
    var tmpArray = [];
    setQuesDataCount(no);
    setSelectedQuestion(question);
    savedQuestionArray.forEach((item) => {
      if (item.questionId === questionId) {
        tmpArray.push(item);
        setSelectedQuesData(tmpArray);
      }
    });
    setQuestionDetailOpen(true);
    setQuestionDetailScroll('paper');
  }

  const renderDetailsButton1 = (params) => {
    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            parseId1(params.row.id, params.row.questionId, params.row.question)
          }}
        >
          More Info
        </Button>
      </strong>
    )
  }

  const columnss = [
    { field: 'id', headerName: 'No', renderCell: renderTextId2, resizable: true, width: 15 },
    /*
    { field: 'questionId', headerName: 'No', width: 15, hide: true },
    { field: 'riskName', headerName: 'Risk', renderCell: renderTextRiskName, resizable: true, cellClassName: 'super-app-theme--cell', width: 240 },
    { field: 'principalName', headerName: 'Principal', renderCell: renderTextPrincipalName, resizable: true, cellClassName: 'super-app-theme--cell', width: 175 },
    { field: 'entityName', headerName: 'Entity', renderCell: renderTextEntityName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
    { field: 'question', headerName: 'Question', renderCell: renderTextQuestion2, cellClassName: 'super-app-theme--cell', resizable: true, width: 325 },
    { field: 'questionType', headerName: 'Type', renderCell: renderTextQuestionType2, resizable: true, width: 80 },
   */
    { field: 'questionId', headerName: 'No', width: 15, hide: true },
    { field: 'riskName', headerName: 'Risk', renderCell: renderTextRiskName, resizable: true, cellClassName: 'super-app-theme--cell', width: 130 },
    { field: 'objectiveName', headerName: 'Objective', renderCell: renderTextObjectiveName, resizable: true, cellClassName: 'super-app-theme--cell', width: 90 },
    { field: 'componentName', headerName: 'Component', renderCell: renderTextComponentName, resizable: true, cellClassName: 'super-app-theme--cell', width: 90 },
    { field: 'principalName', headerName: 'Principal', renderCell: renderTextPrincipalName, resizable: true, cellClassName: 'super-app-theme--cell', width: 180 },
    { field: 'entityName', headerName: 'Entity', renderCell: renderTextEntityName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
    { field: 'question', headerName: 'Question', renderCell: renderTextQuestion2, cellClassName: 'super-app-theme--cell', resizable: true, width: 250 },
    { field: 'questionType', headerName: 'Type', renderCell: renderTextQuestionType2, resizable: true, width: 80 },
    {
      field: 'action',
      headerName: 'More Info',
      sortable: false,
      renderCell: renderDetailsButton1,
      disableClickEventBubbling: true,

    }


  ];





  return (
    <>
      <RightBarDataForOtherPages PageName={PageName} />

      <div className={classes.pageTop}>
        <Grid container spacing={2}>
          <Grid container spacing={2} className={classes.pageBodyStyle}>

            <Grid item xs={12}>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" to="/login">
                    <span className='breadcrumbsStyle'>Home</span>
                  </Link>
                  <Typography color="text.primary"><span className='breadcrumbsStyle'>Main menu - {PageName}</span></Typography>
                </Breadcrumbs>
              </div>
            </Grid>
            <Grid item xs={12}>
              <a style={{ float: 'right', marginTop: '-36px', color: '#0056b3', cursor: 'pointer' }} onClick={() => navigate(-1)}><ArrowBack /> <span className='breadcrumbsStyle'>Go Back</span></a>
            </Grid>

            <Grid item xs={12}>
              <div style={{ height: 400, width: '100%', backgroundColor: '#ffffff', fontSize: '9px !important' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  getRowClassName={`super-app-theme--Open`}
                  disableSelectionOnClick
                />
              </div>

            </Grid>





          </Grid>
        </Grid>


      </div>

      {/** Question Details Show Dialog */}
      <Dialog
        maxWidth={maxWidthState}
        open={moreDetailOpen}
        onClose={moreDetailHandleClickClose}
        scroll={moreDetailScroll}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          <Typography component="th" scope="row" style={{ width: '100%', fontSize: '10px !important' }}>
            <span style={{ fontWeight: '550' }}>User Code - {userCode}</span>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={moreDetailScroll === 'paper'}>
          <DialogContentText
            id="risk-eval-type-scroll-dialog-description"
            tabIndex={-1}
          >
            <Grid item xs={12} style={{ marginTop: '-12px' }}>
              <Card style={{ "width": "100% !important", border: "1px solid black" }}>
                <CardContent>
                  <Grid item xs={12}>
                    <div style={{ marginTop: '-6px' }}>
                      <TableContainer component={Paper} >
                        <Table stickyHeader aria-label="sticky table" style={{ border: '1px solid #ddd' }}>
                          <TableHead className='commonSecondaryTableHead'>
                            <TableRow>
                              <StyledTableCell className={'commonTableThStikyStyle'} style={{ width: '10%' }}>Session Id</StyledTableCell>
                              <StyledTableCell className={'commonTableThStikyStyle'} style={{ width: '33%' }}>Register</StyledTableCell>
                              <StyledTableCell align="right" className={'commonTableThStikyStyle'} style={{ width: '10%', textAlign: 'center' }}>Created</StyledTableCell>
                              <StyledTableCell align="right" className={'commonTableThStikyStyle'} style={{ width: '10%', textAlign: 'center' }}>user Id</StyledTableCell>
                              <StyledTableCell align="right" className={'commonTableThStikyStyle'} style={{ width: '10%' }}>Show</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {registerTableRowData}
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>
                </CardContent>
              </Card>

            </Grid>



          </DialogContentText>
        </DialogContent>
      </Dialog>



      <Dialog
        open={openHaDetails}
        //TransitionComponent={Transition}
        keepMounted
        onClose={haDetailshandleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogTitle>{rHName}</DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button variant="contained"
                  onClick={evaluateRiskFuction}
                  style={{ height: '50px', float: 'right' }}>Evaluate</Button>
              </Grid>

              <Grid item xs={6}>
                <Button variant="contained"
                  onClick={handleButtonShowQuestion}
                  style={{ height: '50px' }}>Show Questions</Button>
              </Grid>

            </Grid>


          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={haDetailshandleClose}>Close</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openDisplayChoice}
        onClose={handleCloseDisplayChoice}>
        <DialogTitle>
          Select the Display Method

        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            How do you want to display the Register
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>&nbsp;</Grid>
            <Grid item xs={5}>
              <Button
                variant="contained"
                component="label"
                onClick={() => byRoleFuction()}
                className='btnFonts'
                style={{ float: 'right' }}
              >
                By Role
              </Button>
            </Grid>
            <Grid item xs={7}>
              <Button
                variant="contained"
                component="label"
                onClick={() => byRiskNameFuction()}
                className='btnFonts'
              >
                By Risk Name
              </Button>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisplayChoice}>Close</Button>
        </DialogActions>
      </Dialog>


      {/** For Register Eval */}

      <Dialog
        open={openRegisterDataForEval}
        onClose={false}
        scroll={openRegisterDataForEvalScroll}
        aria-labelledby="register-data-dialog-title"
        aria-describedby="register-data-dialog-description"
        fullWidth={true}
        maxWidth={maxWidthState}
        fullScreen={fullScreenState}
        PaperComponent={PaperComponentCollectionDataRegister}
      >
        <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>Evaluate "{selectedRegisterEvaluateTitle}" - <SavePageAs /></Typography>
          <Typography variant="h6" style={{ marginRight: '-22px', padding: '7px', marginTop: '-50px', float: 'right' }}>
            <Tooltip title="Close"><Close onClick={RegisterDataForEvalfunctionClose} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>

        <DialogContent dividers={openRegisterDataForEvalScroll === 'paper'}>
          <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {multipleRoles ?
                  <EvaluateComponent1
                    colorPatterns={colorPatterns}
                    registerTypeDataForEval={registerTypeDataForEval}
                    openRegisterDataForEvalScroll={openRegisterDataForEvalScroll}
                    registerEvaluate={true}
                  />
                  :
                  <EvaluateComponent
                    colorPatterns={colorPatterns}
                    registerTypeDataForEval={registerTypeDataForEval}
                    openRegisterDataForEvalScroll={openRegisterDataForEvalScroll}
                    registerEvaluate={registerEvaluate}
                  />
                }
              </Grid>
            </Grid>


          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={RegisterDataForEvalfunctionClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={loading1}
        onClose={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          <span style={{ textAlign: 'center' }}>Please Wait ...</span>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>


              <ClockLoader
                size={100}
                color={'red'}
                className={classes.loaderStyle}
                loading={loading1}
              />

            </Grid>
          </Grid>


        </DialogContent>
      </Dialog>

      <Dialog
        open={openShowQuestions}
        onClose={false}
        scroll={openShowQuestionsScroll}
        aria-labelledby="collection-data-dialog-title"
        aria-describedby="collection-data-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
        PaperComponent={PaperComponentAllQuestionData}
      >
        <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>Questions related to "{selectedRiskEvaluateTitle}" - <SavePageAs />
          </Typography>
          <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '-25px', padding: '7px', marginTop: '-50px' }}>
            <Tooltip title="Close"><Close onClick={() => setOpenShowQuestions(false)} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={openShowQuestionsScroll === 'paper'}>
          <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>

                  <Box
                    sx={{
                      height: 400,
                      width: '100%',
                      '& .super-app-theme--cell': {
                        whiteSpace: 'normal !important',
                        lineHeight: '1',
                      },
                      '& .super-app-theme--Open': {
                        fontSize: '10px !important',
                      },

                    }}
                  >





                    <div style={{ height: 400, width: '100%', backgroundColor: '#ffffff', fontSize: '9px !important' }}>
                      <DataGrid
                        rows={rowss}
                        columns={columnss}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        getRowClassName={`super-app-theme--Open`}
                        disableSelectionOnClick
                      />
                    </div>

                  </Box>
                </Grid>
              </Grid>


            </Grid>





          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenShowQuestions(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>


      {/** Question Details Show Dialog */}
      <Dialog
        maxWidth='xs'
        open={questionDetailOpen}
        onClose={questionDetailHandleClickClose}
        scroll={questionDetailScroll}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Typography variant="h6" gutterBottom component="div">
            Question No - {quesDataCount}
          </Typography>
          <Typography component="th" scope="row" style={{ width: '100%', fontSize: '10px !important' }}>
            <span style={{ fontWeight: '550' }}>Question - </span>{selectedQuestion}
          </Typography>
        </DialogTitle>
        <DialogContent dividers={questionDetailScroll === 'paper'}>
          <DialogContentText
            id="risk-eval-type-scroll-dialog-description"
            tabIndex={-1}
          >
            <Grid container spacing={2}>
              {selectedQuesData.map((data) => {
                return (
                  <Grid item xs={12}>
                    <Box >
                      <Table size="small" aria-label="purchases">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{ width: '80%' }}>
                              <Typography variant="h6" gutterBottom component="div">
                                Detail Weightings
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                              <Typography variant="h6" component="div">
                                Score
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Table size="small" aria-label="purchases">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{ width: '80%' }}>
                              Questionnaire weighting
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                              <span >{data.questionScore}</span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      {data.questionType === "Closeended" && (
                        <>
                          <Table size="small" aria-label="purchases">
                            <TableBody>
                              <TableRow>
                                <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                  <Typography variant="h6" gutterBottom component="div">
                                    Answers
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                  &nbsp;
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <Table size="small" aria-label="purchases">
                            <TableBody>
                              {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                //var riskLvlColor = getRiskLvlColor(item.riskLevel)
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                        <span >{item.answerName}</span>
                                      </TableCell>
                                      <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                        <span>{item.riskLevel}</span>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </>
                      )}
                      {data.questionType === "Closeended" && (
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                            {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                              //var riskLvlColor = getRiskLvlColor(item.riskLevel)
                              if (item.expectedAnswerName != "") {
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                        <span>
                                          {item.answerName}
                                        </span>
                                      </TableCell>
                                      <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                        <span>{item.expectedAnswerName === "Yes" || item.expectedAnswerName === "checked" ? item.riskLevel : item.expectedAnswerName}</span>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )
                              }
                            })}
                          </TableBody>
                        </Table>
                      )}
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={questionDetailHandleClickClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/** End of Question Details Show Dialog*/}
    </>
  )
}

export default TrialUserList
