import React, { useEffect, useState } from "react";
import RightBarDataForOtherPages from "../Components/RightBarDataForOtherPages";
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import useStyle from "../Css/MainCss";
import {
  ArrowBack,
  CheckBox,
  ClearAllOutlined,
  Details,
  SendOutlined,
} from "@mui/icons-material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { Info } from "@mui/icons-material";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { CONFIG } from "../config";

import Tooltip from "@mui/material/Tooltip";
import { Close } from "@mui/icons-material";
import RiskHierarchyNew from "../Components/RiskHierarchyNew";
import Draggable from "react-draggable";
import { event } from "jquery";
import { CircularProgress } from "@material-ui/core";
import RiskRegisterComponent from "../Components/RiskRegisterComponent";
import "../Css/UserRights.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularWithValueLabel from "../Components/ProgressTimer";
import { ClockLoader } from "react-spinners";


function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Setupupcentrefortrailusers = () => {
  const classe = useStyle();

  function compareMenu(a, b) {
    var nameA = a.no; // ignore upper and lowercase
    var nameB = b.no; // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }

  React.useEffect(() => {
    getData();
    getAllTrailData();
  }, []);

  const [amendUserName, setAmendUserName] = React.useState("");
  const [saveBtnName, setSaveBtnName] = React.useState("Save");

  const [allCentres, setAllCentres] = React.useState([]);

  const [trialDays, setTrialDays] = React.useState("");
  const [noOfRegisters, setNoOfRegisters] = React.useState("");
  const [noOfCollections, setNoOfCollections] = React.useState("");

  const [chkSelectFinalize, setChkSelectFinalize] = React.useState(false);
  const [
    chkSelectAllowcreateNewCollection,
    setChkSelectAllowcreateNewCollection,
  ] = React.useState(false);
  const [chkSelectAllowDelete, setChkSelectAllowDelete] = React.useState(false);
  const [chkSelectAllowRedo, setChkSelectAllowRedo] = React.useState(false);
  const [chkSelectAllowEditQuestion, setChkSelectAllowEditQuestion] =
    React.useState(false);
  const [chkSelectMultitaskingMenuAtHome, setChkSelectMultitaskingMenuAtHome] =
    React.useState(false);  
  const [menuItem, setMenuItem] = useState([]);

  function getChkSelectFinalize() {
    setChkSelectFinalize(true);
  }

  function getChkSelectAllowcreateNewCollection() {
    setChkSelectAllowcreateNewCollection(!chkSelectAllowcreateNewCollection);
  }

  function getChkSelectAllowDelete() {
    setChkSelectAllowDelete(!chkSelectAllowDelete);
  }

  function getChkSelectAllowRedo() {
    setChkSelectAllowRedo(!chkSelectAllowRedo);
  }

  function getChkSelectAllowEditQuestion() {
    setChkSelectAllowEditQuestion(!chkSelectAllowEditQuestion);
  }

  function getChkSelectMultitaskingMenuAtHome() {
    setChkSelectMultitaskingMenuAtHome(!chkSelectMultitaskingMenuAtHome);
  }

  const handleChangeTrailDays = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, ''); 
    const truncatedValue = numericValue.slice(0, 4);
    setTrialDays(truncatedValue);
  };

  const handleChangeNoOfRegister = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, ''); 
    setNoOfRegisters(numericValue);
  };

  const handleChangeNoOfCollection = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, ''); 

    setNoOfCollections(numericValue);
  };

  function getCheckStatus(regId) {}

  React.useEffect(() => {
    getData();
  }, []);

  function getData() {
    getAllCentresFromServiceSystem();
  }

  const [centreNa, setCentreName] = useState('')

  function assignRigntsFuction(item, centreName) {
    clearFunction();
    setAmendUserName(item);
    getSideBarData(item);
    setCentreName(centreName)
  }

  function getSideBarData(centre) {
    console.log(centre);

    var centreName = amendUserName;
    var userRoleId = "volunteer";

    var data =
      '{allMenuRoleList(centreId:"' +
      centre +
      '",iamRole:"' +
      userRoleId +
      '"){menuRoleId,centerMenuName,centerMenuOrderNum,centreId,createdDate,updatedDate,isActive,menu{menuUri,menuHtmlId},subMenuList{subMenuRoleId,centerMenuName,centerMenuOrderNum,centreId,createdDate,updatedDate,isActive,menu{menuUri,menuHtmlId}}}}';
    axios(CONFIG.MAIN_URL + `/graphql`, {
      method: "post",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    })
      .then(function (response) {
        console.log("++++++++++++++++++response+++++++++++++++++++");
        console.log(response);
        console.log(response.data.data.allMenuRoleList);

        var arr = response.data.data.allMenuRoleList;

        arr.forEach((data) => {
          var arr1 = data.subMenuList;
          var count = 7;
          arr1.forEach((item) => {
            if (item.menu.menuUri === "mycollections") {
              item["no"] = 1;
            } else {
              if (item.menu.menuUri === "centreEntities") {
                item["no"] = 2;
              } else {
                if (item.menu.menuUri === "entityChange") {
                  item["no"] = 3;
                } else {
                  if (item.menu.menuUri === "bulkUpload") {
                    item["no"] = 4;
                  } else {
                    if (item.menu.menuUri === "allUsers") {
                      item["no"] = 5;
                    } else {
                      if (item.menu.menuUri === "publishcollections") {
                        item["no"] = 6;
                      } else {
                        item["no"] = count;
                        count++;
                      }
                    }
                  }
                }
              }
            }
          });
          arr1 = arr1.sort(compareMenu);
          data["subMenuList"] = arr1;
        });

        console.log(
          "+++++++++++++++++++++++++++++++++Menu arr+++++++++++++++++++++++"
        );
        console.log(arr);
        setMenuItem(arr);

        setMenuItem(response.data.data.allMenuRoleList);
        console.log(response.data.data.allMenuRoleList);
      })
      .catch(function (error) {
        console.log("++++++++++++++++++Error+++++++++++++++++++");
        //console.log(centreId);
        console.log(userRoleId);
        console.log(CONFIG.MAIN_URL + `/graphql`);

        console.log(error);
      });
  }

  function getAllCentresFromServiceSystem() {
    var data = "{getAllCentreList{centreId,centreName}}";
    axios(CONFIG.MAIN_URL + "/graphql", {
      method: "post",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    })
      .then(function (response) {
        var centreList = response.data.data.getAllCentreList;
        console.log("All Centre List");
        console.log(centreList);
        setAllCentres(centreList);
      })
      .catch(function (error) {
        console.log("Entity Loding error" + error);
      });
  }

  const [assignedTrailData, setAssignedTrailData] = useState([]);

  function getAllTrailData() {
    axios(
      CONFIG.MAIN_URL +
        `/trialCentreSetup/all

    `,
      {
        method: "get",
      }
    )
      .then(function (response) {
        console.log(
          "++++++++++++++++++User Rights Create / Update Response+++++++++++++++++++"
        );
        console.log(response.data);
        setAssignedTrailData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        alert("Error updating User Rights -:" + error);
      });
  }

  const [idForEdit, setIdForEdit] = useState("");


  function editassignRigntsFuction(item) {
    console.log(item)
    getAllTrailUser(item.centreId)
    clearFunction();
    setSaveBtnName("update");
    setCentreName(item.centreName)

    var centreName = amendUserName;
    var userRoleId = "volunteer";

    var data =
      '{allMenuRoleList(centreId:"' +
      item.centreId +
      '",iamRole:"' +
      userRoleId +
      '"){menuRoleId,centerMenuName,centerMenuOrderNum,centreId,createdDate,updatedDate,isActive,menu{menuUri,menuHtmlId},subMenuList{subMenuRoleId,centerMenuName,centerMenuOrderNum,centreId,createdDate,updatedDate,isActive,menu{menuUri,menuHtmlId}}}}';
    axios(CONFIG.MAIN_URL + `/graphql`, {
      method: "post",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    })
      .then(function (response) {
        console.log("++++++++++++++++++response+++++++++++++++++++");
        console.log(response);
        console.log(response.data.data.allMenuRoleList);

        var arr = response.data.data.allMenuRoleList;

        arr.forEach((data) => {
          var arr1 = data.subMenuList;
          var count = 7;
          arr1.forEach((item) => {
            if (item.menu.menuUri === "mycollections") {
              item["no"] = 1;
            } else {
              if (item.menu.menuUri === "centreEntities") {
                item["no"] = 2;
              } else {
                if (item.menu.menuUri === "entityChange") {
                  item["no"] = 3;
                } else {
                  if (item.menu.menuUri === "bulkUpload") {
                    item["no"] = 4;
                  } else {
                    if (item.menu.menuUri === "allUsers") {
                      item["no"] = 5;
                    } else {
                      if (item.menu.menuUri === "publishcollections") {
                        item["no"] = 6;
                      } else {
                        item["no"] = count;
                        count++;
                      }
                    }
                  }
                }
              }
            }
          });
          arr1 = arr1.sort(compareMenu);
          data["subMenuList"] = arr1;
        });

        console.log(
          "+++++++++++++++++++++++++++++++++Menu arr+++++++++++++++++++++++"
        );
        console.log(arr);
        setMenuItem(arr);

        setMenuItem(response.data.data.allMenuRoleList);
        console.log(response.data.data.allMenuRoleList);

        var menuItemData = response.data.data.allMenuRoleList;

        axios(
          CONFIG.MAIN_URL +
            `/trialCentreSetup/filter?centreId=` +
            item.centreId,
          {
            method: "get",
          }
        )
          .then(function (res) {
            console.log(res.data);
            var rtnData = res.data;
            rtnData.forEach((tData) => {
              console.log();
              console.log(tData);
              if (tData.centreId === item.centreId) {
                setIdForEdit(tData.id);
                setTrialDays(tData.noOfTrialDays);
                if (tData.redoRegisters === "yes") {
                  setNoOfRegisters(tData.noOfRegistersCanCreate - 1);
                }else{
                  setNoOfRegisters(tData.noOfRegistersCanCreate - 1);
                }
                setNoOfCollections(tData.noOfCollectionCanCreate);
                setAmendUserName(tData.centreId);
                if (tData.editQuestions === "yes") {
                  setChkSelectAllowEditQuestion(true);
                }

                if (tData.displayMultitaskingMenuAtHome === "yes") {
                  setChkSelectMultitaskingMenuAtHome(true);
                }

                if (tData.finaliseRegisters === "yes") {
                  setChkSelectFinalize(true);
                }
                if (tData.deleteRegisters === "yes") {
                  setChkSelectAllowDelete(true);
                }
                if (tData.editRegisters === "yes") {
                  setChkSelectAllowcreateNewCollection(true);
                }
                if (tData.redoRegisters === "yes") {
                  setChkSelectAllowRedo(true);
                }

                menuItemData.forEach((menuList) => {
                  menuList.subMenuList.forEach((row) => {
                    tData.menusToDisplay.forEach((men) => {
                      if (row.menu.menuHtmlId === men.htmlMenuId) {
                        document.getElementById(
                          "chkMenu" + men.htmlMenuId
                        ).checked = true;
                      }
                    });
                  });
                });
              }
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log("++++++++++++++++++Error+++++++++++++++++++");
        //console.log(centreId);
        console.log(userRoleId);
        console.log(CONFIG.MAIN_URL + `/graphql`);

        console.log(error);
      });
  }

  function clearFunction() {
    setTrialDays("");
    setNoOfRegisters("");
    setNoOfCollections("");
    setChkSelectFinalize(false);
    setChkSelectAllowcreateNewCollection(false);
    setChkSelectAllowDelete(false);
    setChkSelectAllowRedo(false);
    setChkSelectAllowEditQuestion(false);
    setChkSelectMultitaskingMenuAtHome(false);
    setSaveBtnName("save");
    setAmendUserName("");
    setMenuItem([]);
    setIdForEdit("");
    setCentreName('')
    setTrialUsrcentreId('')
  }

  function saveFuction() {
    if (trialDays === "") {
      alert("Please Enter trial days");
      return;
    }

    if (noOfRegisters === "") {
      noOfRegisters = 0;
      //alert("Please Enter Number of Re-dos you want To Create");
      //return;
    }

    if (noOfCollections === "") {
      alert("Please Enter Number of Collections you want To Assign");
      return;
    }

    var obj = {};
    var menuItemArr = [];

    if (idForEdit != "") {
      obj["id"] = idForEdit;
    }

    obj["centreId"] = amendUserName;
    obj["noOfTrialDays"] = trialDays;

    if (chkSelectAllowRedo === true) {
      obj["noOfRegistersCanCreate"] = parseInt(noOfRegisters) + 1;
    }else{
      obj["noOfRegistersCanCreate"] = 1;
    }
    
    if (chkSelectAllowcreateNewCollection === true) {
      obj["editRegisters"] = "yes";
    } else {
      obj["editRegisters"] = "no";
    }
    if (chkSelectFinalize === true) {
      obj["finaliseRegisters"] = "yes";
    } else {
      obj["finaliseRegisters"] = "no";
    }
    if (chkSelectAllowDelete === true) {
      obj["deleteRegisters"] = "yes";
    } else {
      obj["deleteRegisters"] = "no";
    }
    if (chkSelectAllowRedo === true) {
      obj["redoRegisters"] = "yes";
    } else {
      obj["redoRegisters"] = "no";
    }

    obj["noOfCollectionCanCreate"] = noOfCollections;

    if (chkSelectAllowEditQuestion === true) {
      obj["editQuestions"] = "yes";
    } else {
      obj["editQuestions"] = "no";
    }
    if (chkSelectMultitaskingMenuAtHome === true) {
      obj["displayMultitaskingMenuAtHome"] = "yes";
    } else {
      obj["displayMultitaskingMenuAtHome"] = "no";
    }

    menuItem.forEach((menuIt) => {
      menuIt.subMenuList.forEach((row) => {
        if (document.getElementById("chkMenu" + row.menu.menuHtmlId).checked) {
          var obj1 = {};
          obj1["htmlMenuId"] = row.menu.menuHtmlId;
          menuItemArr.push(obj1);
        }
      });
    });
    obj["menusToDisplay"] = menuItemArr;

    let sendJson = JSON.stringify(obj);
    console.log(sendJson);

    axios(CONFIG.MAIN_URL + `/trialCentreSetup/create`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: sendJson,
    })
      .then(function (resp) {
        console.log(
          "++++++++++++++++++User Rights Create / Update Response+++++++++++++++++++"
        );
        console.log(resp.data);
        if (idForEdit != "") {
          alert("Trial User Rights Updated successfully.");
        } else {
          alert("Trial User Rights Created successfully.");
        }

        getAllTrailData();
        clearFunction();
        setSelectAllChecked(false);
      })
      .catch(function (error) {
        console.log(error);
        alert("Error updating User Rights -:" + error);
      });
  }

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !selectAllChecked;
    });
  };



  const [trialUsers, setTrialUsers] = useState([]);
  const [openTrialDays, setOpenTrialDays] = useState(false);
  const [maxWidthState, setMaxWidthState] = React.useState('md');
  const [trialUsrSelected, setTrialUsrSelected] = React.useState('');


  const [trialDaysForDb, setTrialDaysForDb] = React.useState('');

  const handleChangeTrailDaysForDb = (event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, '');
    setTrialDaysForDb(numericValue);

  };

  const [trialDaysForDbTextField, setTrialDaysForDbTextField] = React.useState('');

  const handleChangeTrailDaysForDbTextField = (event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, '');
    setTrialDaysForDbTextField(numericValue);

  };
  

  const handleTrialDaysClose = () => {
    setOpenTrialDays(false);
    setTrialUsrfirstName('')
    setTrialDaysForDb('')
    setTrialUsrlastName('')
    setTrialUsruserCode('')
    setTrialUsruserMailAddress('')
    setTrialUsrSelected('')
    setTrialUserSelct(false)
    setTrialDaysForDbTextField('')
  };

  const handleTrialDaysopen = () => {
    setOpenTrialDays(true);
  };

  function getAllTrailUser(entre) {

    axios(
      CONFIG.MAIN_URL +`/trialUsers/filter?centreId=` + entre,
      {
        method: "get",
      }
    )
      .then(function (response) {
        console.log(
          "++++++++++++++++++User Rights Create / Update Response+++++++++++++++++++"
        );
        console.log(response.data);
        setTrialUsers(response.data);
      })
      .catch(function (error) {
        console.log(error);
        alert("Error updating User Rights -:" + error);
      });
  }

  const [trialUsrcentreId, setTrialUsrcentreId] = React.useState('');
  const [trialUsrfirstName, setTrialUsrfirstName] = React.useState('');
  const [trialUsrlastName, setTrialUsrlastName] = React.useState('');
  const [trialUsruserCode, setTrialUsruserCode] = React.useState('');
  const [trialUsruserMailAddress, setTrialUsruserMailAddress] = React.useState('');
  const [trialStartDate, setTrialStartDate] = React.useState('');

  const [trialUserSelct, setTrialUserSelct] = React.useState(false);
  const [trialExtendedArray, setTrialExtendedArray]= React.useState([]);
  const [trialDaysSum, setTrialDaysSum]= React.useState('');




  const handleChangeTrialUser = (event) => {
    setTrialUserSelct(true)
    setTrialDaysForDb('')
    setTrialDaysForDbTextField('')
    setTrialUsrSelected(event.target.value)
    trialUsers.map((data)=> {
      if (data.id === event.target.value) {
        var sortedTrialDays = data.trialDays.sort((a, b) => new Date(a.amendDate) - new Date(b.amendDate));
        var sumOfNoOfIncreaseTrialDays = data.trialDays.reduce((sum, trialDay) => sum + trialDay.noOfIncreaseTrialDays, 0);
        setTrialDaysSum(sumOfNoOfIncreaseTrialDays)
        console.log(sumOfNoOfIncreaseTrialDays)
        console.log(sortedTrialDays)
        setTrialExtendedArray(sortedTrialDays)
        setTrialUsrcentreId(data.centreId)
        setTrialUsrfirstName(data.firstName)
        var dd = data.noOfIncreaseTrialDays;
        if (dd === null) {
          setTrialDaysForDb('')
        }else{
        setTrialDaysForDb(dd)
        }
        setTrialUsrlastName(data.lastName)
        setTrialUsruserCode(data.userCode)
        setTrialUsruserMailAddress(data.userMailAddress)
        setTrialStartDate(data.trailStartDate)
      }
    })



  }


  function saveIncresedTrialDays(){
    if (trialDaysForDbTextField === '') {
      alert("please enter increase trial days");
      return;
      
    }
    var obj = {}
    var arr = []

    obj["id"] = trialUsrSelected;
    obj["centreId"] = trialUsrcentreId;
    obj["userCode"] =trialUsruserCode;
    obj["firstName"] = trialUsrfirstName;
    obj["lastName"] = trialUsrlastName;
    obj["userMailAddress"] = trialUsruserMailAddress;

    var obj1 = {}
    obj1["noOfIncreaseTrialDays"] = trialDaysForDbTextField;

    arr.push(obj1)

    obj["trialDays"] = arr;


    console.log(obj)

    let sendJson = JSON.stringify(obj);
    console.log(sendJson);

    axios(CONFIG.MAIN_URL + `/trialUsers/update`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: sendJson,
    })
      .then(function (resp) {
        console.log(
          "++++++++++++++++++User Rights Create / Update Response+++++++++++++++++++"
        );
        console.log(resp.data);

          alert("Incresed Trial user Days successfully.");
         // setTrialUsrcentreId('')
          setTrialUsrfirstName('')
          setTrialDaysForDb('')
          setTrialUsrlastName('')
          setTrialUsruserCode('')
          setTrialUsruserMailAddress('')
          setTrialUsrSelected('')
          getAllTrailUser(trialUsrcentreId)
          setTrialUserSelct(false)
          setTrialDaysForDbTextField('')


      })
      .catch(function (error) {
        console.log(error);
        alert("Error updating User Rights -:" + error);
      });
  }


  function calculateEndDateFiffrence(enddate) {
    console.log(enddate)

    var lastEndDate = new Date(enddate);

    var currentDate = new Date();
    var timeDifference = lastEndDate - currentDate;
    var daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    console.log(daysDifference)
    return daysDifference;
  }
  

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Card className="stdCard">
            <CardHeader
              avatar={
                <Avatar
                  sx={{ bgcolor: "#69676e", width: "25px", height: "25px" }}
                >
                  <Info />
                </Avatar>
              }
              className={classe.commonMainTableHead}
              title={
                <>          
                    Centre rights For Trial Users  
                    {
                      amendUserName === '' ? ""
                      :
                      <>
                      {" - "}
                      <span style={{ color: "white", fontWeight: "bold" , fontSize:'13px'}}>
                        {centreNa + " (" + amendUserName + ")"}
                      </span>
                      </>
                    }
                    

                  
                </>
              }
            />
            <CardContent>
              <Grid item xs={12}>
                <Card className="stdCardInside">
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                          <Grid item xs={3}>
                            <Typography
                              style={{ marginRight: "8px", fontWeight: 400 }}
                            >
                              Trial Days:
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField
                              id="trailDays"
                              label=""
                              variant="outlined"
                              size="small"
                              value={trialDays}
                              onChange={handleChangeTrailDays}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                            />
                          </Grid>
                        </Box>
                      </Grid>
                      <br></br>

                      <br></br>
                      <br></br>

                      <Grid item xs={12}>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Grid item xs={3}>
                            <Typography style={{ fontWeight: 400 }}>
                              Assign Menu :
                            </Typography>
                          </Grid>

                          <Grid item xs={9}>
                            <Card
                              className="stdCardInside"
                              style={{ height: "100px", overflowY: "auto" }}
                            >
                              <CardContent>
                                <Grid container spacing={1}>
                                  {menuItem.map((data) =>
                                    data.subMenuList.map((row) => (
                                      <>
                                        <Grid item xs={6}>
                                          <Box
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Grid item xs={1}>
                                              <input
                                                type="checkbox"
                                                onClick={() => {
                                                  getCheckStatus(
                                                    row.menu.menuHtmlId
                                                  );
                                                }}
                                                id={
                                                  "chkMenu" +
                                                  row.menu.menuHtmlId
                                                }
                                                name={
                                                  "chkMenu" +
                                                  row.menu.menuHtmlId
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={11}>
                                              <Typography
                                                sx={{
                                                  fontSize:
                                                    "1.00rem !important",
                                                  marginLeft: "0px",
                                                }}
                                              >
                                                {row.centerMenuName}
                                              </Typography>
                                            </Grid>
                                          </Box>
                                        </Grid>
                                      </>
                                    ))
                                  )}
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <Grid item xs={3}></Grid>
                          <Grid item xs={8}>
                            <Box display="flex" alignItems="center">
                              <input
                                type="checkbox"
                                id="selectAllCheckbox"
                                checked={selectAllChecked}
                                onChange={handleSelectAllChange}
                              />

                              <Typography
                                style={{ marginRight: "8px", fontWeight: 400 }}
                              >
                                Select All
                              </Typography>
                            </Box>
                          </Grid>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" style={{marginTop:'-25px'}}>
                          <Grid item xs={3}>
                            <Typography
                              style={{ marginRight: "8px", fontWeight: 400 }}
                            >
                              Number of re-do for 1 Register
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              id="trailDays"
                              label=""
                              variant="outlined"
                              size="small"
                              value={noOfRegisters}
                              onChange={handleChangeNoOfRegister}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Box style={{ marginTop: "30px" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id={"chkSelectFinalize"}
                                    checked={chkSelectFinalize}
                                    onClick={() => {
                                      getChkSelectFinalize();
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{ fontSize: "0.8rem !important" }}
                                  >
                                    Allow Finalize
                                  </Typography>
                                }
                                className={"formControlLabelStyle"}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id={"chkSelectAllowcreateNewCollection"}
                                    checked={chkSelectAllowcreateNewCollection}
                                    onClick={() => {
                                      getChkSelectAllowcreateNewCollection();
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{ fontSize: "0.8rem !important" }}
                                  >
                                    Allow create New Collection
                                  </Typography>
                                }
                                className={"formControlLabelStyle"}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id={"chkSelectAllowDelete"}
                                    checked={chkSelectAllowDelete}
                                    onClick={() => {
                                      getChkSelectAllowDelete();
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{ fontSize: "0.8rem !important" }}
                                  >
                                    Allow Delete
                                  </Typography>
                                }
                                className={"formControlLabelStyle"}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id={"chkSelectAllowRedo"}
                                    checked={chkSelectAllowRedo}
                                    onClick={() => {
                                      getChkSelectAllowRedo();
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{ fontSize: "0.8rem !important" }}
                                  >
                                    Allow Redo
                                  </Typography>
                                }
                                className={"formControlLabelStyle"}
                              />
                            </Box>
                          </Grid>
                        </Box>
                      </Grid>

                      <br></br>
                      <br></br>
                      <br></br>

                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                          <Grid item xs={3}>
                            <Typography
                              style={{ marginRight: "8px", fontWeight: 400 }}
                            >
                              Number of Collections Can Create
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField
                              id="trailDays"
                              label=""
                              variant="outlined"
                              size="small"
                              value={noOfCollections}
                              onChange={handleChangeNoOfCollection}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                            />
                          </Grid>
                        </Box>
                        <br></br> 
                        <Grid item xs={12}>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id={"chkSelectMultitaskingMenuAtHome"}
                                    checked={chkSelectMultitaskingMenuAtHome}
                                    onClick={() => {
                                      getChkSelectMultitaskingMenuAtHome();
                                    }}

                                  />
                                }
                                label={
                                  <Typography
                                    sx={{ fontSize: "1rem !important" }}
                                  >
                                    Display Multitasking Menu in Home Page
                                  </Typography>
                                }
                                className={"formControlLabelStyle"}
                                labelPlacement="start"
                                style={{ marginLeft: "0px" }}
                              />
                            </Grid>
                          </Box>
                        </Grid>








                        <Grid item xs={12}>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid item xs={5}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id={"chkSelectAllowEditQuestion"}
                                    checked={chkSelectAllowEditQuestion}
                                    onClick={() => {
                                      getChkSelectAllowEditQuestion();
                                    }}

                                  />
                                }
                                label={
                                  <Typography
                                    sx={{ fontSize: "1rem !important" }}
                                  >
                                    Allow Edit Question
                                  </Typography>
                                }
                                className={"formControlLabelStyle"}
                                labelPlacement="start"
                                style={{ marginLeft: "0px" }}
                              />
                            </Grid>
                            <Grid item xs={7} style={{ textAlign: "right" }}>
                              {
                                saveBtnName === "update" ? 
                                <>
                                <Button
                                variant="contained"
                                id="riskEvaluateBtn"
                                
                                onClick={() => handleTrialDaysopen()}
                              >
                                Increase Trial Days
                              </Button>
                              &nbsp;&nbsp;&nbsp;
                              
                                </>
                                :''
                              }
  
                              <Button
                                variant="contained"
                                id="riskEvaluateBtn"
                                endIcon={<SendOutlined />}
                                onClick={() => saveFuction()}
                              >
                                {saveBtnName}
                              </Button>
                              &nbsp;&nbsp;&nbsp;
                              <Button
                                variant="contained"
                                id="riskEvaluateBtn"
                                endIcon={<ClearAllOutlined />}
                                onClick={() => clearFunction()}
                              >
                                Clear
                              </Button>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card className="stdCard">
            <CardHeader
              avatar={
                <Avatar
                  sx={{ bgcolor: "#69676e", width: "25px", height: "25px" }}
                >
                  <Info />
                </Avatar>
              }
              className={classe.commonMainTableHead}
              title="Centres"
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={"scrollableDivToStandard"}>
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead className={classe.commonSecondaryTableHead}>
                          <TableRow>
                            <StyledTableCell
                              className={classe.commonTableThStyle}
                              style={{ width: "70%" }}
                            >
                              Centre Name(Code)
                            </StyledTableCell>
                            <StyledTableCell
                              align="right"
                              className={classe.commonTableThStyle}
                              style={{ width: "20%" }}
                            >
                              Assign
                            </StyledTableCell>
                            <StyledTableCell
                              align="right"
                              className={classe.commonTableThStyle}
                              style={{ width: "10%" }}
                            >
                              Edit
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allCentres.map((row, index) => (
                            <StyledTableRow
                              key={index + 1}
                              className={classe.tableTrStyle}
                            >
                              <StyledTableCell
                                component="th"
                                scope="row"
                                className={classe.tableTrStyle}
                              >
                                <div
                                  style={{ whiteSpace: "normal" }}
                                  key={index}
                                >
                                  {row.centreId + "(" + row.centreName + ")"}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                className={classe.tableTrStyle}
                              >
                                {assignedTrailData.some(
                                  (trail) => row.centreId === trail.centreId
                                ) ? null : (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      assignRigntsFuction(row.centreId,row.centreName);
                                    }}
                                  >
                                    Assign
                                  </Button>
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                align="right"
                                className={classe.tableTrStyle}
                              >
                                {assignedTrailData.some(
                                  (trail) => row.centreId === trail.centreId
                                ) ? (
                                  <EditIcon
                                    className={"mousePointer"}
                                    onClick={() => {
                                      editassignRigntsFuction(row);
                                    }}
                                  />
                                ) : null}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

              {/** Finalise Register */}
      <Dialog
        open={openTrialDays}
        onClose={false}
        //scroll={openRegisterFinaliseScroll}
        aria-labelledby="register-data-dialog-title"
        aria-describedby="register-data-dialog-description"
        fullWidth={true}
        maxWidth={maxWidthState}
        //fullScreen={fullScreenState}
      //PaperComponent={PaperComponentCollectionDataRegister}
      >
        <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>Increase Trial Days</Typography>
          <Typography variant="h6" style={{ marginRight: '-22px', padding: '7px', marginTop: '-50px', float: 'right' }}>
            <Tooltip title="Close"><Close onClick={handleTrialDaysClose} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>


              <Grid item xs={12}>
                  <Box
                  component="form"
                  sx={{
                      '& .MuiTextField-root': { mt: 1, width: '40%' },
                  }}
                  noValidate
                  autoComplete="off"
              > <FormControl fullWidth>
                      <TextField
                          label="Select a user"
                          sx={{ width: '100%' }}
                          select
                          value={trialUsrSelected}
                          onChange={handleChangeTrialUser}
                          style={{backgroundColor:"white"}}
                      >
                          {trialUsers.map((item) => (
                              <MenuItem
                                  value={item.id}
                              >
                                  {item.firstName}{" "}{item.lastName}({item.userCode})
                              </MenuItem>
                          ))}

                      </TextField>
                  </FormControl>

              </Box>
              </Grid>
              {
                trialUserSelct ? 
                <>
               <Grid item xs={4}>
              <Box style={{ display: 'flex', gap: '20px' }}>
                <label style={{ fontWeight: 'bold' }}>Trial Start Date :</label>
                
                <label>{trialExtendedArray[0].amendDate.split('T')[0]}</label>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box style={{ display: 'flex', gap: '20px' }}>
                <label style={{ fontWeight: 'bold' }}>Trial Days :</label>
                <label>{trialDaysSum}</label>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box style={{ display: 'flex', gap: '20px' }}>
                <label style={{ fontWeight: 'bold' }}>Trial End Date :</label>
                 <label>{trialExtendedArray[trialExtendedArray.length - 1].endDate.split('T')[0]}</label> 
              </Box>
            </Grid>

              <Grid item xs={6}>
              <Box style={{ display: 'flex', gap: '20px' }}>
               
                {calculateEndDateFiffrence(trialExtendedArray[trialExtendedArray.length - 1].endDate) > 0 ? 
                <>
                  <label style={{ fontWeight: 'bold' }}> Days left to trial end date : </label>
                  <label style={{color:'green'}}> {calculateEndDateFiffrence(trialExtendedArray[trialExtendedArray.length - 1].endDate) + 1}</label>
                </>
                :
                <>
                 <label style={{ fontWeight: 'bold' }}> Days exceeded since trial end date : </label>
                 <label style={{color:'red'}}> {(Math.abs(calculateEndDateFiffrence(trialExtendedArray[trialExtendedArray.length - 1].endDate)) ) - 1}</label>

                </>
                }
 

              </Box>
              </Grid>

              {calculateEndDateFiffrence(trialExtendedArray[trialExtendedArray.length - 1].endDate) > 0 ? 
              <>
              {''}
              </>
              :
              <>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
              <TextField
                              id="trailDays"
                              label=" Increase Trial Days"
                              variant="outlined"
                              value={trialDaysForDbTextField}
                               onChange={handleChangeTrailDaysForDbTextField}
                              style={{marginTop:'6px',width:'80%'}}
                            />
                </Grid>
                <Grid item xs={9}>&nbsp;</Grid>
                <Grid item xs={1}>
                  <Box >
                <Button
                                variant="contained"
                                id="riskEvaluateBtn"
                                endIcon={<ClearAllOutlined />}
                                onClick={() => saveIncresedTrialDays()}
                                style={{marginTop:'6px'}}
                              >
                                update
                              </Button>
                              </Box>
                  </Grid>
                  </>
}
                  </>
                :''
              }

              </Grid>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTrialDaysClose}>Close</Button>
        </DialogActions>
      </Dialog>
      </Grid>


      
    </>
  );
};

export default Setupupcentrefortrailusers;
