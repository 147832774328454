import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import useStyle from '../Css/MainCss';
import {
    Badge,
    Toolbar,
  } from "@material-ui/core";
  import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
  import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
  import Box from '@mui/material/Box';
  import Avatar from '@mui/material/Avatar';
  import Menu from '@mui/material/Menu';
  import MenuItem from '@mui/material/MenuItem';
  import ListItemIcon from '@mui/material/ListItemIcon';
  import Divider from '@mui/material/Divider';
  import IconButton from '@mui/material/IconButton';
  import Tooltip from '@mui/material/Tooltip';
  import PersonAdd from '@mui/icons-material/PersonAdd';
  import Settings from '@mui/icons-material/Settings';
  import Logout from '@mui/icons-material/Logout';
  import { useState } from "react";
  import axios from 'axios';
  import {dispatchLogout} from "../actions/sign-out";

const NavBar = () => {
    const [opens, setOpens] = useState(false);
    const classes = useStyle({ opens });
    const [anchorEl, setAnchorEl] = 
    useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };



   function handleLogoutBtnClick() {
      dispatchLogout();
  };  

  return (
    <div>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar className={classes.toolbarr}>
            <div className={classes.log}>
                <Typography variant="h6" noWrap component="div" className={classes.risktit}>
                    Risk Management System
                </Typography>
          </div>
          <div className={classes.icons}>
                <Badge  color="secondary" className={classes.badge}>
                    <MailOutlineOutlinedIcon />
                    </Badge>
                    <Badge  color="secondary" className={classes.badge}>
                    <NotificationsNoneOutlinedIcon />
                    </Badge>

                    <Box>

                <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar sx={{ width: 32, height: 28 }}>M</Avatar>
                </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                    },
                    '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    },
                },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* <MenuItem>
                <Avatar /> Profile
                </MenuItem> */}
                <MenuItem>
                <Avatar /> My account
                </MenuItem>
                <Divider />
                {/* <MenuItem>
                <ListItemIcon>
                    <PersonAdd fontSize="small" />
                </ListItemIcon>
                Add another account
                </MenuItem>
                <MenuItem>
                <ListItemIcon>
                    <Settings fontSize="small" />
                </ListItemIcon>
                Settings
                </MenuItem> */}
                <MenuItem onClick={handleLogoutBtnClick}>
                <ListItemIcon >
                    <Logout fontSize="small" />
                </ListItemIcon >
                Logout
                </MenuItem>
            </Menu>
          </div>

        </Toolbar>
      </AppBar>
    </div>
  )
}

export default NavBar
