import React from 'react'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';
import { height } from '@mui/system';
import  "../Css/EvaluateComponent.css";

const TransferToExcel1 = ({ commitArray, commitArrayData, commitBarData, riskTypeDataForEval,registerName }) => {

    const handleDownload = () => {

        var arr = [];
        if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
            //arr = commitArray.sort(compareRiskTypeOrder);
            arr = commitArray
        } else {
            if (commitBarData != undefined && commitBarData != null && commitBarData.length > 0) {
                arr = commitBarData;
            } else {
                arr = commitArrayData;
            }
        }

        console.log("riskTypeDataForEval");
        console.log(riskTypeDataForEval);


        var arrX = [];
        var arrY = []
        var objx = {}
        var arrZ = []

        arrZ.push("Risk Name");
        arrZ.push("Risk Impact");
        arrZ.push("Risk Likelihood");

        arrZ.push("Risk level");
        arrZ.push("RiskAppetite");
        arrZ.push("Risk Description");
        arrZ.push("Impact Description");
        arrZ.push("Mitigation");


        arrX.push(arrZ)
        // arrX.push(arrY)

        riskTypeDataForEval.riskTypes.forEach((data, index) => {

            arrY = []
            arrZ = []
            arrZ.push("");
            arrZ.push("");
            arrZ.push("");
            arrZ.push("");
            arrZ.push("");
            arrZ.push("");
            arrZ.push("");
            arrZ.push("");
            arrX.push(arrZ)


            arrY = []
            arrZ = []
            arrZ.push(  "" + data.name);
          //  addOtherSubRisks(data,arrZ);
            addOtherSubRisks1(data,arrZ);
             
            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                data.subRisks.forEach((item, index1) => {
                    arrZ = []
                    arrZ.push( "-- " + item.name);
                    addOtherSubRisks(item,arrZ);
                    if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                        item.subRisks.forEach((item1, index2) => {
                            arrZ = []
                            arrZ.push( "---- " + item1.name);
                            addOtherSubRisks(item1,arrZ);
                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                item1.subRisks.forEach((item2, index3) => {
                                    arrZ = []
                                    arrZ.push("------ " + item2.name);
                                    addOtherSubRisks(item2,arrZ);
                                    if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                        item2.subRisks.forEach((item3, index4) => {
                                            arrZ = []
                                            arrZ.push( "-------- " + item3.name);
                                            addOtherSubRisks(item3,arrZ);
                                            if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                           	    item3.subRisks.forEach((item4, index5) => {
                                                    arrZ = []
                                                    arrZ.push(  "---------- " + item4.name);
                                                    addOtherSubRisks(item4,arrZ);
                                                    if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                                        item4.subRisks.forEach((item5, index6) => {
                                                            arrZ = []
                                                            arrZ.push( "------------ " + item5.name);
                                                            addOtherSubRisks(item5,arrZ);
                                                            if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                                item5.subRisks.forEach((item6, index7) => {
                                                                    arrZ = []
                                                                    arrZ.push("-------------- " + item6.name);
                                                                    addOtherSubRisks(item6,arrZ);
                                                                    if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {
                                                                        item6.subRisks.forEach((item7, index8) => {
                                                                            arrZ = []
                                                                            arrZ.push( "---------------- " + item7.name);
                                                                            addOtherSubRisks(item7,arrZ);
                                                                            if (item7.subRisks != undefined && item7.subRisks != null && item7.subRisks.length > 0) {
                                                                                item7.subRisks.forEach((item8, index9) => {
                                                                                    arrZ = []
                                                                                    arrZ.push("------------------ " + item8.name);
                                                                                    addOtherSubRisks(item8,arrZ);
                                                                                    if (item8.subRisks != undefined && item8.subRisks != null && item8.subRisks.length > 0) {
                                                                                        item8.subRisks.forEach((item9, index10) => {
                                                                                            arrZ = []
                                                                                            arrZ.push("-------------------- " + item9.name);
                                                                                            addOtherSubRisks(item9,arrZ);
                                                                                        })
                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        })
                                                    }
                                            	})
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })

        function addOtherSubRisks(item, arrZ){
            console.log(item)
            console.log(arrZ)
            arrZ.push(parseInt(item.riskImpact));
            arrZ.push(parseInt(item.riskLikelihood));
            arrZ.push(parseInt(item.riskLevel ));
            arrZ.push(parseInt(item.riskAppetite));
            if (item.riskDescription != undefined && item.riskDescription != null){
                arrZ.push(item.riskDescription);
            }else{
                arrZ.push('');
            }
            if (item.impactDescription != undefined && item.impactDescription != null){
                arrZ.push(item.impactDescription);
            }else{
                arrZ.push('');
            }
            if (item.mitigation != undefined && item.mitigation != null){
                arrZ.push(item.mitigation);
            }else{
                arrZ.push('');
            }

            arrX.push(arrZ)
        }

        function addOtherSubRisks1(item, arrZ){
            arrZ.push('');
            arrZ.push('');
            arrZ.push('');
            arrZ.push('');
            arrZ.push('');
            arrZ.push('');
            arrZ.push('');
            arrX.push(arrZ)
        }



        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(arrX);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
        var fileName = "data.xlsx";
        if (registerName != undefined && registerName != null){
            fileName = registerName;
            var arr = fileName.split(" - "); 
            if (arr.length === 3){
                fileName = arr[0] + " - " + arr[1]
            }
            if (arr.length === 2){
                fileName = arr[0] + " - " + "All Roles"
            }
            fileName = fileName + ".xlsx"
        }
        saveAs(new Blob([buffer]), fileName);
    };
    return (
        <div>
            <Button
                className='btnNormal' 
                variant='outlined' 
                onClick={handleDownload}
            >
            Transfer to Excel File
            </Button>
        </div>
    )
}

export default TransferToExcel1
