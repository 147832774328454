import React from "react";
import { sendAuthorizationRequest, sendTokenRequest } from "./actions/sign-in";
import {dispatchLogout} from "./actions/sign-out";
import {isValidSession, getAllSessionParameters, decodeIdToken} from "./actions/session";
import SaveHome from "./SaveHome";
import Home from "./Pages/Home";
import MainPage from "./Pages/MainPage";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import QuestionnnaireMainPage from "./Pages/QuestionnnaireMainPage";
import { Grid } from "@material-ui/core";
import SideBar from "./Components/SideBar";
import Dashboard from "./Pages/Dashboard";
import Container from '@mui/material/Container';
import RightBar from "./Components/RightBar";
import axios from 'axios';
import {CONFIG} from './config';
import RightBarWithoutUserRole from "./Components/RightBarWithoutUserRole";
import MultiRoleLoginPage from "./Pages/MultiRoleLoginPage";
import CentreMaintenancePage from "./Pages/CentreMaintenancePage";
import RightBarUser from "./Components/RightBarUser";
import SuperAdminLoginPage from "./Pages/SuperAdminLoginPage";

//import HeaderComponent from "./Components/HeaderComponent";



class HomeComponent extends React.Component {  
    state = {
        idToken: {},
        tokenResponse: {},
        isLoggedIn: false,
        multipleRoles: false,
        userRoleList: [],
        singleRole : false,
        singleRoleId : '',
    };

    componentWillMount() {
        // See if there is a valid session.
        if (isValidSession()) {
            const session = getAllSessionParameters();
            const _tokenResponse = {
                access_token: session.ACCESS_TOKEN,
                refresh_token: session.REFRESH_TOKEN,
                scope: session.SCOPE,
                id_token: session.ID_TOKEN,
                token_type: session.TOKEN_TYPE,
                expires_in: parseInt(session.EXPIRES_IN),
            };
            this.setState({
                tokenResponse: _tokenResponse,
                idToken: decodeIdToken(session.ID_TOKEN),
                isLoggedIn: true
            });
            var data = '{getUserRoleList(userId:\"' + decodeIdToken(session.ID_TOKEN).sub + '\"){roleId,entity{entityId,entityName,basicEntity{iamRole}}}}';
            axios(CONFIG.MAIN_URL+'/graphql',{
                method: 'post',
                headers: { 
                    'Content-Type': 'text/plain',
                },
                data : data,  
            })
            .then( (res) => {
                if (res.data.data.getUserRoleList[0] != undefined){
                    var multipleRole = false;
                    var singleRoleIdValue = '' 
                    if (res.data.data.getUserRoleList.length > 1){
                        multipleRole = true;
                    }else{
                        singleRoleIdValue = res.data.data.getUserRoleList[0].roleId;
                    }
                    this.setState({ 
                        multipleRoles: multipleRole,
                        userRoleList:res.data.data.getUserRoleList,
                        singleRoleId:singleRoleIdValue
                    }); 
                }else{
                    console.log("+++++++++++NOT A Registered User in the Services System++++++++++++");
                    this.setState({ 
                        multipleRoles: false,
                        userRoleList:null,
                        singleRoleId:'volunteer'
                    }); 
                }
            })

            return;
        }

        // Reads the URL and retrieves the `code` param.
       const code = new URL(window.location.href).searchParams.get("code");

        if (code) {
            sendTokenRequest(code)
                .then(response => {
                    console.log("++++++++++++componentWillMount response ++++++++++++++++");
                    console.log(response);

                    if (response[1].sub === "superadmin"){
                        this.setState({ isLoggedIn: true }, function () {
                            console.log("Superadmin Login");
                        });
                    }
                    
                    this.setState({
                        tokenResponse: response[0],
                        idToken: response[1],
                        isLoggedIn: true
                    })
                    

                    var data = '{getUserRoleList(userId:\"' + response[1].sub + '\"){roleId,entity{entityId,entityName,basicEntity{iamRole}}}}';
                    axios(CONFIG.MAIN_URL+'/graphql',{
                        method: 'post',
                        headers: { 
                            'Content-Type': 'text/plain',
                        },
                        data : data,  
                    })
                    .then( (res) => {
                        console.log("++++++++++++++++++userRoles Returns+++++++++++++++");
                        if (res.data.data.getUserRoleList[0] != undefined){
                            console.log(res.data.data.getUserRoleList[0].roleId);
                            var multipleRole = false;
                            var singleRoleStatus = false;
                            var singleRoleIdValue = '' 
                            if (res.data.data.getUserRoleList.length > 1){
                                multipleRole = true;
                            }else{
                                singleRoleStatus = true
                                singleRoleIdValue = res.data.data.getUserRoleList[0].roleId;
                            }
                            this.setState({ 
                                multipleRoles: multipleRole,
                                userRoleList:res.data.data.getUserRoleList,
                                singleRole :singleRoleStatus,
                                singleRoleId:singleRoleIdValue
                            }); 
                        }else{
                            console.log("+++++++++++NOT A Registered User in the Services System++++++++++++");
                            this.setState({ 
                                multipleRoles: false,
                                userRoleList:null,
                                singleRole :true,
                                singleRoleId:'volunteer'
                            });
                        }
                    })
                    .catch((error => {
                        console.log("userRoles Returns Error " + error);
                        this.setState({ isLoggedIn: false });
                    }));

                })
                .catch((error => {
                    console.log("TOKEN REQUEST ERROR", error);
                    this.setState({ isLoggedIn: false });
                }));
        }  
    }

    handleLoginBtnClick = () => {
        sendAuthorizationRequest(true);
    };

    handleLogoutBtnClick = () => {
        dispatchLogout();
    };


    handleChange = (e) => {
        //this.setState({[e.target.id]: e.target.value})
        const newdata = {...this.state}
        newdata[e.target.id] = e.target.value
        this.setState(newdata)
        console.log(newdata)
      }

    setSessionData = () => {
        sessionStorage.setItem('sessionstorage', JSON.stringify(this.state.idToken))
    }



    render() {
        const { tokenResponse, idToken, isLoggedIn,multipleRoles, userRoleList, singleRole, singleRoleId } = this.state;

       // const middleName = this.state.idToken.middle_name;

       var middleName="";
       var name="";
       var lastName="";
       var poBox="";
       var addressLineOne="";
       var userId = "";
       var centreId="";
       let userRoleId = ""
       var roleList = [];
       var roleString = [];
       var token=""
       var userId="";
       var userEmail="";
       var zoneinfo="";
       var city="";
       var country=""; 
       var phoneNumber=""; 
       var postalCode="";
       var upn="";

        middleName=idToken.middle_name;
        userId=idToken.sub;
        name=idToken.given_name;
        lastName=idToken.family_name;
        poBox=idToken.pobox;
        addressLineOne=idToken.pobox;
        roleList=idToken.groups;
        token = tokenResponse.id_token;
        userEmail = idToken.email;
        zoneinfo = idToken.zoneinfo;
        city = idToken.city;
        country = idToken.country;
        phoneNumber = idToken.phone_number;
        postalCode = idToken.postal_code;
        upn = idToken.upn;




        if(roleList !== undefined){
            var length = roleList.length;
            for (let index = 0; index <length; index++) {
                if(roleList[index] === "superadmin" ){
                    userRoleId = roleList[index]
                }
                else{
                    if (roleList[index] != "Internal/everyone"){
                        if (roleList[index] != "Internal/selfsignup"){
                            roleString = roleList[index].split("_");
                            userRoleId = roleString[1];
                            console.log(userRoleId)
                            centreId = roleString[0];
                            break;
                        }
                    }    
                        
                }
                
            }
        }
        
        

        console.log("+++++++++++++++++++++++++++++++++userRoleId+++++++++++++++++++++++++++++++");
        console.log(userRoleId);

        console.log(singleRole);
        console.log(singleRoleId);
        console.log("+++++++++++++++++++++++++++++++++centreId+++++++++++++++++++++++++++++++");
        console.log(idToken);



        return (
            <div>
                
                <br />
                {
                    isLoggedIn?
                        <>   

                            {userRoleId === "superadmin"? 
                                <>
                                {/** <CentreMaintenancePage/>
                                */}
                                <SuperAdminLoginPage/>
                                </>
                            :

                            <>
                            {multipleRoles?
                                <>
                                <RightBarWithoutUserRole centreId={centreId} name={name}/>
                                <br />
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Container >
                                            <MultiRoleLoginPage 
                                                userRoleList={userRoleList} 
                                                centreId={centreId} 
                                                name={name}
                                                userId={userId}
                                                />
                                        </Container>
                                    </Grid>
                               </Grid>
                                </>
                            :  
                            <>
                            
                            {CONFIG.EXPERT_LOGIN?

                                userRoleId === 'staff' ? 
                                    <RightBar userRoleId={userRoleId} centreId={centreId} name={name} userId={userId}/>
                                :

                                 singleRoleId != '' && (
                                        <>
                                        <RightBar userRoleId={singleRoleId} centreId={centreId} name={name} userId={userId}/>
                                        </> )
                                        :
                                        <RightBarUser userRoleId={singleRoleId} centreId={centreId} name={name}/>
                                        }
                                        
                                        <br />
                                       

                                                    <Dashboard 
                                                        userRoleId={userRoleId} 
                                                        centreId={centreId} 
                                                        name={name}
                                                        userId={userId}
                                                    />
     
                                    </> 
                            }
</>
                        }

                          
                            {
                            userRoleId === 'staff' ? 
                            
                            <SaveHome 
                            idToken={idToken} 
                            middleName={middleName} 
                            name={name}
                            lastName={lastName} 
                            poBox={poBox} 
                            addressLineOne={addressLineOne}
                            token={token}
                            centreId={centreId}
                            userRoleId={userRoleId}
                            userId={userId}
                            userEmail={userEmail}
                            zoneinfo={zoneinfo}
                            city={city}
                            country={country}
                            phoneNumber={phoneNumber}
                            postalCode={postalCode}
                            upn={upn}
                    


                            />
                            :   
                            
                            singleRoleId != '' &&
                            
                             <SaveHome 
                             idToken={idToken} 
                             middleName={middleName} 
                             name={name}
                             lastName={lastName} 
                             poBox={poBox} 
                             addressLineOne={addressLineOne}
                             token={token}
                             centreId={centreId}
                             userRoleId={userRoleId}
                             userId={userId}
                             userEmail={userEmail}
                             zoneinfo={zoneinfo}
                             city={city}
                             country={country}
                             phoneNumber={phoneNumber}
                             postalCode={postalCode}
                             upn={upn}
                             />

                            }
                           
                           
                            
                        </>
                        :
                        
                        <>
                      <MainPage/>

                        </>
                }
            </div>
        )
    }
}

export default HomeComponent;