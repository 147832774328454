import {getSessionParameter, resetAuthenticatedSession} from "./session";
import {CONFIG} from "../config";

/**
 * Logs out from the session.
 */
export const dispatchLogout = () => {
    const token = getSessionParameter("ID_TOKEN");
    if (sessionStorage.getItem("sessionLogoutUrl") != null){
        const logOutSessionUrl =  sessionStorage.getItem("sessionLogoutUrl");
        const logOutUrl =  "https://" + logOutSessionUrl + CONFIG.REDIRECT_URI_PORT;
        // Clear the session storage
        resetAuthenticatedSession();
        console.log(`${CONFIG.LOGOUT_URL}?post_logout_redirect_uri=${logOutUrl}&id_token_hint=${token}`)
        //window.location.href = `${CONFIG.REDIRECT_URI}?id_token_hint=${token}&post_logout_redirect_uri=${CONFIG.REDIRECT_URI}`;
        window.location.href = `${CONFIG.LOGOUT_URL}?post_logout_redirect_uri=${logOutUrl}&id_token_hint=${token}`;
    }else{
        resetAuthenticatedSession();
        console.log(`${CONFIG.LOGOUT_URL}?post_logout_redirect_uri=${CONFIG.REDIRECT_LOGOUT_URI}&id_token_hint=${token}`)
        window.location.href = `${CONFIG.LOGOUT_URL}?post_logout_redirect_uri=${CONFIG.REDIRECT_LOGOUT_URI}&id_token_hint=${token}`;    
    }
};