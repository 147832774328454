import React from 'react'
import { Fab, Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { ArrowBack } from '@mui/icons-material';
import RightBarSuperAdmin from '../Components/RightBarSuperAdmin';
import Button from '@mui/material/Button';
import useStyle from '../Css/MainCss';
import imgdash from '../assets/risk.jpg'
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import SuperAdminLoginAddCollection from '../Components/SuperAdminLoginAddCollection';
import SuperAdminLoginCentreLimitation from '../Components/SuperAdminLoginCenterLimitation';
import SuperAdminLoginCentreMaintenance from '../Components/SuperAdminLoginCenterMaintenance';
import axios from 'axios';
import SchemaMaintainers from '../Components/SchemaMaintainers';
import { Tabs, Tab } from '@mui/material';
import SuperAdminLoginLoinPageDesign from '../Components/SuperAdminLoginLoinPageDesign';
import SuperAdminLoginContactUsPageDesign from '../Components/SuperAdminLoginContactUsPageDesign';
import SuperAdminLoginLandingPageDesign from '../Components/SuperAdminLoginLandingPageDesign';
import SuperAdminLoginAboutUsPageDesign from '../Components/SuperAdminLoginAboutUsPageDesign';
import SuperAdminLoginPageDesign from '../Components/SuperAdminLoginPageDesign';
import SuperAdminFooterDesign from '../Components/SuperAdminFooterDesign';
import SuperAdminHeaderSettings from '../Components/SuperAdminHeaderSettings';
import Setupupcentrefortrailusers from '../Components/Setupupcentrefortrailusers';
import MultitaskingMenuSetup from '../Components/MultitaskingMenuSetup';
import Pricing from '../Components/Pricing';
import Pricing2 from '../Components/Pricing2';
import MainMenuSetup from '../Components/MainMenuSetup';
import { CONFIG } from '../config'
import BlogComponent from '../Components/BlogComponent';



const SuperAdminLoginPage = () => {

  const [PageName, setPageName] = React.useState('Home Page and Centre Maintenance');
  const navigate = useNavigate();
  const classes = useStyle();

  React.useEffect(() => {
    getTemplates();
    getAllKeyCodes();
    getAllKeyCodesBlogs();
    getAllKeyCodesOurService();
    getAllKeyCodesAboutUs();
  }, [])

  const [tinyMCETokens, setTinyMCETokens] = React.useState([]);
  function getAllKeyCodes() {
    axios(CONFIG.EXPERT_SERVER_URL + `/loginPageSubPage/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
    .then(function (response) {
        console.log("++++All Domains Sub Pages Details +++++++++++++++++");
        console.log(response.data);
        var tokenArr = [];
        response.data.forEach((item, index) => {
            item.pages.forEach((data) => {
                var canTake = false;
                if (data.isHide != undefined && data.isHide != null) {
                    if (data.isHide === 'no'){
                        canTake = true;
                    }
                }else{
                    canTake = true;
                }
                if (canTake){
                    var tObj = {}
                    var domName = item.parentDomainName;
                    var domVersion = 'version ' + item.parentVersionNumber;
                    var keyCode = data.keyCode;
                    tObj["text"] = domName + " - " + domVersion + " - " + keyCode;
                    tObj["value"] = "<a href='" + "/domainendpoint?keyCode=" + keyCode + "&type=login&domVersion=" + item.parentVersionNumber + "' target='_blank'>Click Here</a>";
                    tokenArr.push(tObj)
                }
            })
        })
        setTinyMCETokens(tokenArr);

    })
    .catch(function (error) {
        console.log(error);
    });
}


const [tinyMCETokensAboutUs, setTinyMCETokensAboutUs] = React.useState([]);
function getAllKeyCodesAboutUs() {
  axios(CONFIG.EXPERT_SERVER_URL + `/aboutUsPageSubPage/all`, {
    method: 'get',
    headers: {
      'Content-Type': 'text/plain',
    },
  })
  .then(function (response) {
      console.log("++++All Domains Sub Pages Details +++++++++++++++++");
      console.log(response.data);
      var tokenArr = [];
      response.data.forEach((item, index) => {
          item.pages.forEach((data) => {
              var canTake = false;
              if (data.isHide != undefined && data.isHide != null) {
                  if (data.isHide === 'no'){
                      canTake = true;
                  }
              }else{
                  canTake = true;
              }
              if (canTake){
                  var tObj = {}
                  var domName = item.parentDomainName;
                  var domVersion = 'version ' + item.parentVersionNumber;
                  var keyCode = data.keyCode;
                  tObj["text"] = domName + " - " + domVersion + " - " + keyCode;
                  tObj["value"] = "<a href='" + "/domainendpoint?keyCode=" + keyCode + "&type=aboutus' target='_blank'>Click Here</a>";
                  tokenArr.push(tObj)
              }
          })
      })
      setTinyMCETokensAboutUs(tokenArr);

  })
  .catch(function (error) {
      console.log(error);
  });
}


const [tinyMCETokensOurService, setTinyMCETokensOurService] = React.useState([]);
function getAllKeyCodesOurService() {
  axios(CONFIG.EXPERT_SERVER_URL + `/ourServicesPageSubPage/all`, {
    method: 'get',
    headers: {
      'Content-Type': 'text/plain',
    },
  })
  .then(function (response) {
      console.log("++++All Domains Sub Pages Details +++++++++++++++++");
      console.log(response.data);
      var tokenArr = [];
      response.data.forEach((item, index) => {
          item.pages.forEach((data) => {
              var canTake = false;
              if (data.isHide != undefined && data.isHide != null) {
                  if (data.isHide === 'no'){
                      canTake = true;
                  }
              }else{
                  canTake = true;
              }
              if (canTake){
                  var tObj = {}
                  var domName = item.parentDomainName;
                  var domVersion = 'version ' + item.parentVersionNumber;
                  var keyCode = data.keyCode;
                  tObj["text"] = domName + " - " + domVersion + " - " + keyCode;
                  tObj["value"] = "<a href='" + "/domainendpoint?keyCode=" + keyCode + "&type=ourservice' target='_blank'>Click Here</a>";
                  tokenArr.push(tObj)
              }
          })
      })
      setTinyMCETokensOurService(tokenArr);

  })
  .catch(function (error) {
      console.log(error);
  });
}









const [tinyMCETokensBlogs, setTinyMCETokensBlogs] = React.useState([]);
function getAllKeyCodesBlogs() {
  axios(CONFIG.EXPERT_SERVER_URL + `/blogArticlePage/allCurrent`, {
    method: 'get',
    headers: {
      'Content-Type': 'text/plain',
    },
  })
  .then(function (response) {
      console.log("++++Blog ArticlesPages Details +++++++++++++++++");
      console.log(response.data);
      var tokenArr = [];
      response.data.forEach((item, index) => {
        var tObj = {}
        var domName = item.domainName;
        var keyCode = item.keyCode;
        tObj["text"] = domName + " - " + keyCode;
        tObj["value"] = "<a href='" + "/blogpageendpoint?keyCode=" + keyCode + "&domainName=" + domName + "' target='_blank'>Click Here</a>";
        tokenArr.push(tObj)
      })
      setTinyMCETokensBlogs(tokenArr);

  })
  .catch(function (error) {
      console.log(error);
  });
}



  const [tinyMCETemplateList, setTinyMCETemplateList] = React.useState()

  var templateArr = [];
  function getTemplateHtml(fileName){
    axios('https://www.daytaem.co.uk/readFile.php?q=' + fileName, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
    })
    .then(function (res) {
        var tmpA = fileName.split(".")
        var templateObj = {};
        templateObj["title"] = tmpA[0].replace("RST_","");
        templateObj["description"] = "Risk System - Template ";
        templateObj["content"] = res.data;
        templateArr.push(templateObj);
        setTinyMCETemplateList(templateArr);
    })
    .catch(function (error) {
        console.log(error);
    });

  }

  function getTemplates(){
      var filesArr = "";
      axios('https://www.daytaem.co.uk/getFile.php?q=aaaaaaa', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
        var resData = response.data;
        var arrTmp= resData.split("@@");
        var filesStr=arrTmp[1];
        
        filesArr = filesStr.split("]");
        var k = 1;
        
        var templateHtmlArr = [];
        for (var i=1; i < filesArr.length; i++) {
            if (filesArr[i] != "") {
                if (filesArr[i].slice(0, 3) === "RST"){
                    var fileName = filesArr[i];
                    getTemplateHtml(fileName)
                }
            }

        }
      })
      .catch(function (error) {
        console.log(error);
      });  
  }





  const [openCentreLimitation,setOpenCentreLimitation] = React.useState(false);
  const [openCentreMaintenance,setOpenCentreMaintenance] = React.useState(false);
  const [openAddCollections,setOpenAddCollections] = React.useState(false);
  const [openCreateSchema,setOpenCreateSchema] = React.useState(false);
  const [opensetOpensetupTrailUsers,setOpensetOpensetupTrailUsers] = React.useState(false);
  const [multitaskingMenuSetup,setMultitaskingMenuSetup] = React.useState(false);
  const [pricing,setPricing] = React.useState(false);
  const [blog,setBlog] = React.useState(false);
  const [mainMenuSetup,setMainMenuSetup] = React.useState(false);

  function setOpenCentreLimitationFuction(flag){
    setOpenCentreLimitation(true);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(false)
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(false);
    setMainMenuSetup(false);
    setPricing(false);
    setBlog(false)
  }
  function setOpenCentreMaintenanceFuction(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(true);
    setOpenAddCollections(false);
    setOpenCreateSchema(false);
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(false);
    setMainMenuSetup(false);
    setPricing(false);
    setBlog(false)
  }
  function setOpenAddCollectionFuction(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(true);
    setOpenCreateSchema(false)
    setOpensetOpensetupTrailUsers(false);
    setMainMenuSetup(false);
    setMultitaskingMenuSetup(false);
    setPricing(false);
    setBlog(false)
  }

  function setOpenCreateSchemaFuction(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(true);
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(false);
    setMainMenuSetup(false);
    setPricing(false);
    setBlog(false)
  }
  

  function setOpensetupTrailUsersFuction(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(false)
    setOpensetOpensetupTrailUsers(true);
    setMultitaskingMenuSetup(false);
    setMainMenuSetup(false);
    setPricing(false);
    setBlog(false)
  }

  function setOpenMultitaskingMenuSetup(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(false)
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(true);
    setMainMenuSetup(false);
    setPricing(false);
    setBlog(false)
  }
  
  function setOpenMainMenuSetup(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(false);
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(false);
    setPricing(false);
    setMainMenuSetup(true);
    setBlog(false)
  }

  function setOpenPricing(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(false);
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(false);
    setMainMenuSetup(false);
    setPricing(true);
    setBlog(false)
  }

  function setOpenBlog(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(false);
    setOpensetOpensetupTrailUsers(false);
    setMultitaskingMenuSetup(false);
    setMainMenuSetup(false);
    setPricing(false);
    setBlog(true)
  }


  const [activeTab, setActiveTab] = React.useState(0); // Set the default active tab to the first tab

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  


  return (
    <>
    <RightBarSuperAdmin PageName={PageName}/>
   
    <div style={{marginLeft: '10px', marginRight: '10px', marginTop:'92px'}}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div style={{textAlign:'left',display:'flex', justifyContent:'space-between'}}>
                    <div>
                        <Button 
                            id='btnCentreLimitation'
                            variant="outlined" 
                            size="medium"
                            className={(openCentreLimitation?classes.superAdmintabBtnDBClicked:classes.tabBtnDB)}
                            onClick={()=>setOpenCentreLimitationFuction(true)} 
                            >
                            Maintain Centre Limitations
                        </Button>&nbsp;&nbsp;
                        <Button 
                            id='btnCentreMaintenance'
                            variant="outlined" 
                            size="medium" 
                            className={(openCentreMaintenance?classes.superAdmintabBtnDBClicked:classes.tabBtnDB)}
                            onClick={()=>setOpenCentreMaintenanceFuction(true)} 
                            >
                            Maintain Client Centers
                        </Button>&nbsp;&nbsp;
                        <Button 
                            id='btnOpenRiskCollection'
                            variant="outlined" 
                            size="medium" 
                            className={(openAddCollections?classes.superAdmintabBtnDBClicked:classes.tabBtnDB)}
                            onClick={()=>setOpenAddCollectionFuction(true)} 
                            >
                            Display Settings
                        </Button>&nbsp;&nbsp;
                        <Button 
                            id='btnOpenRiskCollection'
                            variant="outlined" 
                            size="medium" 
                            className={(openCreateSchema?classes.superAdmintabBtnDBClicked:classes.tabBtnDB)}
                            onClick={()=>setOpenCreateSchemaFuction(true)} 
                            >
                            create json schemas
                        </Button>&nbsp;&nbsp;
                        <Button 
                            id='btnOpenRiskCollection'
                            variant="outlined" 
                            size="medium" 
                            className={(opensetOpensetupTrailUsers?classes.superAdmintabBtnDBClicked:classes.tabBtnDB)}
                            onClick={()=>setOpensetupTrailUsersFuction(true)} 
                            >
                            setup centre for trial users
                        </Button>&nbsp;&nbsp;
                        <Button 
                            id='btnOpenRiskCollection'
                            variant="outlined" 
                            size="medium" 
                            className={(multitaskingMenuSetup?classes.superAdmintabBtnDBClicked:classes.tabBtnDB)}
                            onClick={()=>setOpenMultitaskingMenuSetup(true)} 
                            >
                            multitasking menusetup
                        </Button>&nbsp;&nbsp;
                        <Button 
                            id='btnOpenRiskCollection'
                            variant="outlined" 
                            size="medium" 
                            className={(mainMenuSetup?classes.superAdmintabBtnDBClicked:classes.tabBtnDB)}
                            onClick={()=>setOpenMainMenuSetup(true)} 
                            >
                            Main menu setup
                        </Button>&nbsp;&nbsp;
                        <Button 
                            id='btnOpenRiskCollection'
                            variant="outlined" 
                            size="medium" 
                            className={(pricing?classes.superAdmintabBtnDBClicked:classes.tabBtnDB)}
                            onClick={()=>setOpenPricing(true)} 
                            >
                            pricing
                        </Button>&nbsp;&nbsp;
                        <Button 
                            id='btnOpenRiskCollection'
                            variant="outlined" 
                            size="medium" 
                            className={(blog?classes.superAdmintabBtnDBClicked:classes.tabBtnDB)}
                            onClick={()=>setOpenBlog(true)} 
                            >
                            blog
                        </Button>
                    </div>
                </div>
            </Grid>
            {openCentreLimitation?
                <Grid item xs={12}>
                    <Card 
                        className={classes.cardDivMove}  
                        style={{marginRadius:'10px',width:'100%',height:'245px',overflowY: 'scroll'}}
                        >
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SuperAdminLoginCentreLimitation/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            :''
            }
            {openCentreMaintenance?
                <Grid item xs={12}>
                    <Card 
                        className={classes.cardDivMove}  
                        style={{marginRadius:'10px',width:'100%',height:'750px',overflowY: 'scroll'}}
                        >
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SuperAdminLoginCentreMaintenance/>
                                </Grid> 
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            :''
            }

                {blog?
                    <Grid item xs={12}>
                    <Card 
                        className={classes.cardDivMove}  
                        style={{marginRadius:'10px',width:'100%',height:'750px',overflowY: 'scroll'}}
                        >
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <BlogComponent
                                        tokens={tinyMCETokensBlogs}
                                    />
                                </Grid> 
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            :''
            }

            {openAddCollections?
                <Grid item xs={12}>
                    <Card 
                        className={classes.cardDivMove}  
                        style={{marginRadius:'10px',width:'100%',height:'750px',overflowY: 'scroll'}}
                        >
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>

                                <Tabs
                                    value={activeTab}
                                    onChange={handleTabChange}
                                    textColor="primary"
                                    indicatorColor="primary"
                                   // centered // Centers the tabs horizontally
                                    sx={{ bgcolor: '#f5f5f5', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}
                                    >
                                    <Tab
                                        label="Log In Page Design"
                                        sx={{
                                        textTransform: 'none', // Disable uppercase text
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        }}
                                    />
                                    <Tab
                                        label="About Us Page Design"
                                        sx={{
                                        textTransform: 'none',
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        }}
                                    />
                                    <Tab
                                        label="Our Services Page Design"
                                        sx={{
                                        textTransform: 'none',
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        }}
                                    />
                                    <Tab
                                        label="Landing Page Design"
                                        sx={{
                                        textTransform: 'none',
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        }}
                                    />       
                                    <Tab
                                        label="Footer Design"
                                        sx={{
                                        textTransform: 'none',
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        }}
                                    /> 
                                    <Tab
                                        label="Header Settings"
                                        sx={{
                                        textTransform: 'none',
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        }}
                                    /> 
                                    <Tab 
                                        label="Collections Shown in Login Page"
                                        sx={{
                                        textTransform: 'none',
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        }}
                                    /> 
                                    </Tabs>

                                    {activeTab === 0 ? 
                                    <>
                                    <br></br>
                                    <SuperAdminLoginPageDesign
                                        tokens={tinyMCETokens} 
                                    />
                                    </>
                                    : activeTab === 1 ?
                                    <>
                                    <br></br>
                                        <SuperAdminLoginAboutUsPageDesign
                                        tokens={tinyMCETokensAboutUs} 
                                        />
                                    </>
                                    :  activeTab === 2 ?
                                    <>
                                    <br></br>
                                        <SuperAdminLoginContactUsPageDesign
                                        tokens={tinyMCETokensOurService} 
                                        />
                                    </>:
                                     activeTab === 3 ?
                                     <>
                                     <br></br>
                                         <SuperAdminLoginLandingPageDesign/>
                                     </>:
                                     activeTab === 4 ?
                                     <>
                                     <br></br>
                                         <SuperAdminFooterDesign/>
                                     </>:
                                     activeTab === 5 ?
                                     <>
                                     <br></br>
                                         <SuperAdminHeaderSettings/>
                                     </>
                                     :
                                     activeTab === 6 ?
                                     <>
                                     <br></br>
                                     <SuperAdminLoginAddCollection
                                        tinyMCETemplateList={tinyMCETemplateList}
                                    />
                                    
                                    </>
                                     :''
                                }

                                    
                                </Grid> 
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            :''
            }
             {openCreateSchema?
                <Grid item xs={12}>
                    <Card 
                        className={classes.cardDivMove}  
                        style={{marginRadius:'10px',width:'100%',height:'750px',overflowY: 'scroll'}}
                        >
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SchemaMaintainers/>
                                </Grid> 
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            :''
            }

            {opensetOpensetupTrailUsers?
                <Grid item xs={12}>
                    <Card 
                        className={classes.cardDivMove}  
                        style={{marginRadius:'10px',width:'100%',height:'750px',overflowY: 'scroll'}}
                        >
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Setupupcentrefortrailusers/>
                                </Grid> 
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            :''
            }

            {multitaskingMenuSetup?
                <Grid item xs={12}>
                    <Card 
                        className={classes.cardDivMove}  
                        style={{marginRadius:'10px',width:'100%',height:'750px',overflowY: 'scroll'}}
                        >
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MultitaskingMenuSetup/>
                                </Grid> 
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            :''
            }
            {mainMenuSetup?
                <Grid item xs={12}>
                    <Card 
                        className={classes.cardDivMove}  
                        style={{marginRadius:'10px',width:'100%',height:'750px',overflowY: 'scroll'}}
                        >
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MainMenuSetup/>
                                </Grid> 
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            :''
            }

            {pricing?
                <Grid item xs={12}>
                    <Card 
                        className={classes.cardDivMove}  
                        style={{marginRadius:'10px',width:'100%',height:'750px',overflowY: 'scroll'}}
                        >
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Pricing2/> 
                                </Grid> 
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            :''
            }




               
        </Grid>    
    </div>
    </>
    
  )
}

export default SuperAdminLoginPage