import React from 'react'
import useStyle from '../Css/MainCss';
import { Grid, makeStyles } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TransferToExcel1 from '../Components/TransferToExcel1';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { BarChart, Bar, XAxis, YAxis, Cell, Tooltip, LabelList, CartesianGrid, Legend } from 'recharts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CircularProgress } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import { FullscreenRounded, TryRounded, TurnedInOutlined } from '@mui/icons-material';
import Tooltip1 from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import "../Css/EvaluateComponent.css";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CONFIG } from '../config'
import axios from 'axios';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';



const EvaluateComponent1 = ({ colorPatterns, registerTypeDataForEval, openRegisterDataForEvalScroll, registerEvaluate }) => {

    console.log("registerTypeDataForEval")
    console.log(registerTypeDataForEval)
    console.log(registerEvaluate)

    const classes = useStyle();



    const [waitingOpen, setWaitingOpen] = React.useState(false);
    const [riskCategoryMethodData, setRiskCategoryMethodData] = React.useState([])
    const [riskCategoryMethodDataAppetite, setRiskCategoryMethodDataAppetite] = React.useState([])

    const [colorPatternId, setColorPatternId] = React.useState('')
    const [databClr, setDatabClr] = React.useState([]);
    const [tdPresentage, setTdPresentage] = React.useState()
    const [colorPatternName, setColorPatternName] = React.useState('')
    const [colorPatternPrimaryColorName, setColorPatternPrimaryColorName] = React.useState('')
    const [colorPatternSecondaryColorName, setColorPatternSecondaryColorName] = React.useState('')
    const [colorSelectDropDownRiskLevel, setColorSelectDropDownRiskLevel] = React.useState();
    const [colorSelectDropDownRiskAppetite, setColorSelectDropDownRiskAppetite] = React.useState();
    const [colorSelectDropDownRiskLevelTmp, setColorSelectDropDownRiskLevelTmp] = React.useState(1);
    const [colorSelectDropDownRiskAppetiteTmp, setColorSelectDropDownRiskAppetiteTmp] = React.useState(2);
    const [btnHirachivalClass, setBtnHirachivalClass] = React.useState('btnNormal');
    const [btnGradientClass, setBtnGradientClass] = React.useState('btnNormal');
    const [btnBarChartClass, setBtnBarChartClass] = React.useState('btnNormal');
    const [applyFlag, setApplyFlag] = React.useState(false);
    const [showTranferExlButton, setShowTranferExlButton] = React.useState(false);
    const [showEvalData, setShowEvalData] = React.useState(false)
    const [showTableType, setShowTableType] = React.useState()
    const [commitArrayData, setCommitArrayData] = React.useState([])
    const [commitBarData, setCommitBarData] = React.useState([]);
    const [editRiskHirachy, setEditRiskHirachy] = React.useState(false);
    const [lastRiskTmpId, setLastRiskTmpId] = React.useState(0);
    const [arrayForDisplayDataInTable, setArrayForDisplayDataInTable] = React.useState([])
    const [barData, setBarData] = React.useState([]);
    const [barChartHeight, setBarChartHeight] = React.useState(600);
    const [arrRiskColorState, setArrRiskColorState] = React.useState([]);
    const [arrColorAppState, setArrColorAppState] = React.useState([]);
    const [fullScreenState, setFullScreenState] = React.useState(false);
    const [fullScreenStateMsg, setFullScreenStateMsg] = React.useState('Full Screen');

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            // console.log(payload[1].value)
            if (payload[0].value === 0 && payload[1].value === 0) {
                return null
            } else {
                return (
                    <div className="custom-tooltip" style={{ backgroundColor: '#E2E4EC', padding: '3px 15px', border: '1px solid black', borderRadius: '5PX' }}>
                        <p className="label" style={{ fontWeight: 'bold' }}>{`${label} `}</p>
                        <p className="label">{`Risk Level : ${payload[0].value}`}</p>
                        <p className="label">{`Risk Appetite : ${payload[1].value}`}</p>

                    </div>
                );
            }
        }

        return null;
    };

    const renderCustomizedLabel = (props) => {
        const {
            x, y, width, height, value,
        } = props;

        const fireOffset = value.toString().length < 5;
        const offset = fireOffset ? -40 : 80;
        const orgWidth = width > 500 ? 500 : width;
        return (
            <text x={x + orgWidth + offset} y={y + height - 5} fill={fireOffset ? "#285A64" : "#7e96ac"} textAnchor="end">
                {value}
            </text>
        );
    };

    const renderCustomizedLabel1 = (props) => {
        const {
            x, y, width, height, value,
        } = props;

        const fireOffset = value.toString().length < 5;
        const offset = fireOffset ? -40 : 100;
        const orgWidth = width > 500 ? 500 : width;
        return (
            <text x={x + orgWidth + offset} y={y + height - 5} fill={fireOffset ? "#285A64" : "#7e96ac"} textAnchor="end">
                {value}
            </text>
        );
    };




    React.useEffect(() => {

        colorPatterns.forEach((data, index) => {
            if (index === 0) {
                setColorPatternId(data.id);
                setDatabClr(data.colors)
                setTdPresentage(100 / (data.colors.length + 1))
                setColorPatternName(data.name);
                setColorPatternPrimaryColorName(data.primaryColorName);
                setColorPatternSecondaryColorName(data.secondaryColorName);
            }
        })
        setColorSelectDropDownRiskLevelTmp(1)
        setColorSelectDropDownRiskAppetiteTmp(2)

        setBtnHirachivalClass('btnNormal')
        setBtnGradientClass('btnNormal')
        setBtnBarChartClass('btnNormal')

        setShowEvalData(false)
        setEditRiskHirachy(false);

        setTimeout(() => {
            applyFunctionRegister()
        }, 500)
    }, [])

    function setMinMaxWindowFunctionRegister() {
        if (!fullScreenState) {
            setFullScreenState(true);
            setFullScreenStateMsg('Full Screen Out');
        } else {
            setFullScreenState(false);
            setFullScreenStateMsg('Full Screen');
        }

    }


    const handleChangeColorPattern = (event) => {
        setColorPatternId(event.target.value);
        colorPatterns.forEach((data, index) => {
            if (data.id === event.target.value) {
                console.log(data)
                setDatabClr(data.colors)
                setTdPresentage(100 / (data.colors.length + 1))

                setColorPatternName(data.name);
                setColorPatternPrimaryColorName(data.primaryColorName);
                setColorPatternSecondaryColorName(data.secondaryColorName);
            }
        })
    };

    const handleChangeDropDown = (event) => {
        setColorSelectDropDownRiskLevelTmp(event.target.value);
    };

    const handleChangeDropDownAppetite = (event) => {
        setColorSelectDropDownRiskAppetiteTmp(event.target.value);
    };

    const [arrayForDisplayDataWithRole, setArrayForDisplayDataWithRole] = React.useState({})

    const [arrayForGradiantView, setArrayForGradiantView] = React.useState([])

    function assignExtraAttributes(data, tmpIdCount) {
        data.forEach((item) => {
            item["riskTypeTmpId"] = globalIdCount
            globalIdCount++;
            if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                assignExtraAttributes(item.subRisks, tmpIdCount);
            }
        })
        return tmpIdCount;
    }

    var globalIdCount = 1;

    function applyFunctionRegister() {
        setColorSelectDropDownRiskLevel(colorSelectDropDownRiskLevelTmp);
        setColorSelectDropDownRiskAppetite(colorSelectDropDownRiskAppetiteTmp)

        setShowEvalData(true)
        setApplyFlag(true)
        setShowTableType(1)
        setShowTranferExlButton(true)

        setBtnHirachivalClass('btnActive')
        setBtnGradientClass('btnNormal')
        setBtnBarChartClass('btnNormal')

        console.log(registerTypeDataForEval)

        var regTmpId = 99950;
        var orderNo = 1;
        var idNo = 888;
        var mainArr = []
        if (registerTypeDataForEval.riskTypes != undefined && registerTypeDataForEval.riskTypes != null) {
            registerTypeDataForEval.riskTypes.forEach((data) => {
                var secName = data.role;
                var obj = {};
                var arr = [];
                var canTake = true;

                mainArr.forEach((arrData) => {
                    if (arrData.role === secName) {
                        canTake = false;
                    }
                })

                if (canTake) {
                    registerTypeDataForEval.riskTypes.forEach((item) => {
                        if (secName === item.role) {
                            arr.push(item);
                        }
                    })

                    obj["id"] = "6449106bb631343cfe835" + idNo;
                    idNo++;

                    obj["name"] = data.role
                    obj["orderNo"] = orderNo;
                    orderNo++;
                    obj["riskLevel"] = 0
                    obj["riskAppetite"] = 0
                    obj["riskImpact"] = 0
                    obj["riskLikelihood"] = 0
                    obj["riskTypeId"] = regTmpId
                    regTmpId++;
                    obj["subRisks"] = arr
                    obj["role"] = data.role

                    mainArr.push(obj);
                }
            })
        }

        console.log(mainArr)

        mainArr.forEach((data) => {
            data.subRisks.forEach((data1) => {
                data1["role"] = data.role
                data1.subRisks.forEach((data2) => {
                    data2["role"] = data1.role
                    if (data2.subRisks != undefined && data2.subRisks != null && data2.subRisks != []) {
                        data2.subRisks.forEach((data3) => {
                            data3["role"] = data2.role
                            if (data3.subRisks != undefined && data3.subRisks != null && data3.subRisks != []) {
                                data3.subRisks.forEach((data4) => {
                                    data4["role"] = data3.role
                                    if (data4.subRisks != undefined && data4.subRisks != null && data4.subRisks != []) {
                                        data4.subRisks.forEach((data5) => {
                                            data5["role"] = data4.role
                                            if (data5.subRisks != undefined && data5.subRisks != null && data5.subRisks != []) {
                                                data5.subRisks.forEach((data6) => {
                                                    data6["role"] = data5.role
                                                    if (data6.subRisks != undefined && data6.subRisks != null && data6.subRisks != []) {
                                                        data6.subRisks.forEach((data7) => {
                                                            data7["role"] = data6.role

                                                        })
                                                    }
                                                })
                                            }
                                        })
                                    }

                                })
                            }
                        })
                    }
                })
            })
        })



        var tmpIdCount = 2;
        globalIdCount = 1;
        mainArr.forEach((item1, index) => {
            item1["riskTypeTmpId"] = globalIdCount;
            globalIdCount++
            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                tmpIdCount = assignExtraAttributes(item1.subRisks, tmpIdCount);
            }
            tmpIdCount++;
        })

        tmpIdCount = 100001;
        mainArr.forEach((item1, index) => {
            item1["riskTypeId"] = tmpIdCount;
            tmpIdCount++
            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                item1.subRisks.forEach((item2) => {
                    item2["riskTypeId"] = tmpIdCount;
                    tmpIdCount++
                    if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                        item2.subRisks.forEach((item3) => {
                            item3["riskTypeId"] = tmpIdCount;
                            tmpIdCount++
                        })
                    }
                })
            }
        })


        console.log("++++++++++++++++++TTTTTTTTTTTTTTTTTmainArr++++++++++++++++")
        console.log(mainArr)
        console.log(JSON.stringify(mainArr));

        var tmpObj = {}
        tmpObj["id"] = registerTypeDataForEval.id;
        tmpObj["registerUniqueName"] = registerTypeDataForEval.registerUniqueName;
        tmpObj["documentCreator"] = registerTypeDataForEval.documentCreator;
        tmpObj["name"] = registerTypeDataForEval.name;
        tmpObj["description"] = registerTypeDataForEval.description;
        tmpObj["centreId"] = registerTypeDataForEval.centreId;
        tmpObj["status"] = registerTypeDataForEval.status;
        tmpObj["createdDate"] = registerTypeDataForEval.createdDate;
        tmpObj["updatedDate"] = registerTypeDataForEval.updatedDate;
        tmpObj["riskTypes"] = mainArr;

        //setArrayForDisplayDataWithRole(mainArr)
        setArrayForDisplayDataWithRole(tmpObj);

        console.log(tmpObj);



        var datafrGra = []
        mainArr.forEach((data) => {
            datafrGra.push(data)
            data.subRisks.forEach((data1) => {
                datafrGra.push(data1)
                data1.subRisks.forEach((data2) => {
                    datafrGra.push(data2)
                    if (data2.subRisks != undefined && data2.subRisks != null && data2.subRisks != []) {
                        data2.subRisks.forEach((data3) => {
                            datafrGra.push(data3)
                            if (data3.subRisks != undefined && data3.subRisks != null && data3.subRisks != []) {
                                data3.subRisks.forEach((data4) => {
                                    datafrGra.push(data4)
                                    if (data4.subRisks != undefined && data4.subRisks != null && data4.subRisks != []) {
                                        data4.subRisks.forEach((data5) => {
                                            datafrGra.push(data5)
                                            if (data5.subRisks != undefined && data5.subRisks != null && data5.subRisks != []) {
                                                data5.subRisks.forEach((data6) => {
                                                    datafrGra.push(data6)
                                                    if (data6.subRisks != undefined && data6.subRisks != null && data6.subRisks != []) {
                                                        data6.subRisks.forEach((data7) => {
                                                            datafrGra.push(data7)

                                                        })
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            })
        })

        //console.log("+++++++++++++++++datafrGra++++++++++++++")
        //console.log(datafrGra)

        setArrayForGradiantView(datafrGra)

    }

    function showDataInAViewFunction(no) {

        setEditRiskHirachy(true);
        setWaitingOpen(true);
        setTimeout(() => {
            setWaitingOpen(false);
        }, 500)

    }

    var commitArray = []
    function pushToArray(item) {
        commitArray.push(item);
        //console.log("+++++++++++++++++++++commitArray+++++++++++++++");
        //console.log(commitArray);
    }

    function hierarchicalFunctionRegister() {
        setBtnHirachivalClass('btnActive')
        setBtnGradientClass('btnNormal')
        setBtnBarChartClass('btnNormal')
    }



    function createBarFunctionRegister() {

        var arr = [];
        if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
            //arr = commitArray.sort(compareRiskTypeOrder);
            arr = commitArray
        } else {
            if (commitBarData != undefined && commitBarData != null && commitBarData.length > 0) {
                arr = commitBarData;
            } else {
                arr = commitArrayData;
            }
        }

        setShowEvalData(true)
        setShowTableType(3)


        if (arr.length <= 10) {
            setBarChartHeight(600)
        } else {
            if (arr.length > 10 && arr.length <= 17) {
                setBarChartHeight(800)
            } else {
                if (arr.length > 17 && arr.length <= 30) {
                    setBarChartHeight(1000)
                } else {
                    if (arr.length > 30 && arr.length <= 40) {
                        setBarChartHeight(1500)
                    } else {
                        if (arr.length > 40 && arr.length <= 60) {
                            setBarChartHeight(2200)
                        } else {
                            setBarChartHeight(3500)
                        }
                    }
                }
            }
        }


        var arr1 = [];
        var arrRiskColor = []
        var arrColorApp = []

        arr.forEach((data) => {
            var obj = {}
            var name = data.name;
            obj["argument"] = name;
            obj["riskLevel"] = parseInt(data.riskLevel);
            arrRiskColor.push(data.riskColor)
            obj["riskAppetite"] = parseInt(data.riskAppetite);

            if (data.riskLevel != 0 && data.riskAppetite != 0) {
                obj["riskLevelStr"] = "Risk Level: " + data.riskLevel;
                obj["riskAppetiteStr"] = "Risk Appetite: " + data.riskAppetite;
            } else {
                obj["riskLevelStr"] = '';
                obj["riskAppetiteStr"] = '';
            }

            arrColorApp.push(data.appetiteColor)
            arr1.push(obj)
        })
        setCommitBarData(arr);
        console.log(arr1)
        setBarData(arr1);
        setArrRiskColorState(arrRiskColor)
        setArrColorAppState(arrColorApp)


        setBtnHirachivalClass('btnNormal')
        setBtnGradientClass('btnNormal')
        setBtnBarChartClass('btnActive')

    }


    function displayRiskAsendingRegister() {
        setShowEvalData(true)

        var arr = [];
        if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
            //arr = commitArray.sort(compareRiskTypeOrder);
            arr = commitArray
        } else {
            arr = commitArrayData;
        }
        console.log(arr)
        setArrayForDisplayDataInTable(arr);
        setCommitArrayData(arr);
        setShowTableType(2)

        /*
        var arr = []
        allRegister.forEach((data) => {
            if (data.id === registerId) {
                data.riskRegister.forEach((data1) => {
                    arr.push(data1)
                    data1.risks.forEach((risk) => {
                        var obj = {}
                        obj["section"] = risk.name
                        obj["riskLevel"] = risk.riskLevel
                        obj["riskAppetite"] = risk.riskAppetite
      
                        arr.push(obj)
                    })
                })
            }
        })
      
        allRegisterArr()
        setArrayForDisplayDataInTable(arr);
        setCommitArrayData(arr);
        setShowTableType(2)
        */

        setBtnHirachivalClass('btnNormal')
        setBtnGradientClass('btnActive')
        setBtnBarChartClass('btnNormal')

    }

    function getSubRiskSubNo(comment, riskId, subNo) {
        var no = "0"
        comment.forEach((data, index) => {
            if (data.riskTypeId === riskId) {
                no = subNo + (index + 1) + '';
            }
        });
        if (no === "0") {
            comment.forEach((item, index) => {
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    getSubRiskSubNo(item.subRisks, riskId, (index + 1));
                }

            })
        }
        return no;
    }

    const [openRiskGpt, setOpenRiskGpt] = React.useState(false);
    const [openRiskGptScroll, setOpenRiskGptScroll] = React.useState('paper');

    const [riskDescriptionText, setRiskDescriptionText] = React.useState('');
    const [impactDescriptionText, setImpactDescriptionText] = React.useState('');
    const [mitigationText, setMitigationText] = React.useState('');
    const [selectedRiskId, setSelectedRiskId] = React.useState('');
    const [selectedRegisterRiskId, setSelectedRegisterRiskId] = React.useState('');


    const handleChangeRiskDescriptionText = (e) => {
        setRiskDescriptionText(e.target.value);
    }
    const handleChangeImpactDescriptionText = (e) => {
        setImpactDescriptionText(e.target.value);
    }
    const handleChangeMitigationText = (e) => {
        setMitigationText(e.target.value);
    }

    function displayMitigation(comment) {
        console.log(comment)
        console.log(registerTypeDataForEval)
        setRiskDescriptionText(comment.riskDescription);
        setImpactDescriptionText(comment.impactDescription);
        setMitigationText(comment.mitigation);
        setSelectedRiskId(comment.id);
        setSelectedRegisterRiskId(comment.riskTypeId)

        setOpenRiskGpt(true)
        setOpenRiskGptScroll('paper')
    }

    function handleMitigationUpdate() {

        var arr = [];
        var itemFound = false;
        registerTypeDataForEval.riskTypes.forEach((item) => {
            item["section"] = item.name;
            item["impact"] = item.riskImpact;
            item["likelihood"] = item.riskLikelihood;
            item["likelihood"] = item.riskLikelihood;
            var arr1 = [];

            item.subRisks.forEach((item1) => {
                item1["impact"] = item1.riskImpact;
                item1["likelihood"] = item1.riskLikelihood;
                if (item1.id === selectedRiskId) {
                    item1["riskDescription"] = riskDescriptionText
                    item1["impactDescription"] = impactDescriptionText
                    item1["mitigation"] = mitigationText
                    itemFound = true;
                }
                arr1.push(item1)
            })
            console.log(itemFound);
            console.log(selectedRiskId);
            console.log(selectedRegisterRiskId);
            item["risks"] = arr1;
            arr.push(item)
        })
        registerTypeDataForEval["riskRegister"] = arr;
        
        if (!itemFound) {
            registerTypeDataForEval.riskTypes.forEach((item) => {
                item.subRisks.forEach((subRisk) => {
                    if (subRisk.riskHierarchy != undefined && subRisk.riskHierarchy != null) {
                        if (subRisk.riskHierarchy.riskHierarchyData != undefined && subRisk.riskHierarchy.riskHierarchyData != null) {
                            findItemInHierarchyData(subRisk.riskHierarchy.riskHierarchyData);
                        }
                    }
                })
            })
        } else {

       
            registerTypeDataForEval["riskRegister"] = arr;
    
            console.log(registerTypeDataForEval)
            let sendJson = JSON.stringify(registerTypeDataForEval);

            axios(CONFIG.MAIN_URL + '/register/save', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: sendJson,
            })
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    let rtnData = response.data;
                    alert("Register Update successfully.");
                    setOpenRiskGpt(false)
                })
                .catch(function (error) {
                    console.log(error);
                    alert("Error saving Register-:" + error);
                });
        }


    }

    function findItemInSubSubSubSubSubRisksInReg(hierarchyData) {
        var fondSelected = false;
        hierarchyData.riskTypes.map((riskType) => {
            riskType.subRisks.map((subRisk) => {
                subRisk.subRisks.map((subRisk1) => {
                    subRisk1.subRisks.map((subRisk2) => {
                        subRisk2.subRisks.map((subRisk3) => {
                            subRisk3.subRisks.map((subRisk4) => {
                                if (selectedRiskId === subRisk4.riskTypeId) {
                                    subRisk4["riskDescription"] = riskDescriptionText;
                                    subRisk4["impactDescription"] = impactDescriptionText;
                                    subRisk4["mitigation"] = mitigationText;
                                    fondSelected = true;
                                    updateRegisterWithHierarchy();
                                }
                            })
                        })
                    })
                })
            })
        })
    }

    function findItemInSubSubSubSubRisksInReg(hierarchyData) {
        var fondSelected = false;
        hierarchyData.riskTypes.map((riskType) => {
            riskType.subRisks.map((subRisk) => {
                subRisk.subRisks.map((subRisk1) => {
                    subRisk1.subRisks.map((subRisk2) => {
                        subRisk2.subRisks.map((subRisk3) => {
                            if (selectedRiskId === subRisk3.riskTypeId) {
                                subRisk3["riskDescription"] = riskDescriptionText;
                                subRisk3["impactDescription"] = impactDescriptionText;
                                subRisk3["mitigation"] = mitigationText;
                                fondSelected = true;
                            }
                        })
                    })
                })
            })
        })
        if (!fondSelected) {
            findItemInSubSubSubSubSubRisksInReg(hierarchyData);
        } else {
            updateRegisterWithHierarchy()
        }
    }

    function findItemInSubSubSubRisksInReg(hierarchyData) {
        var fondSelected = false;
        hierarchyData.riskTypes.map((riskType) => {
            riskType.subRisks.map((subRisk) => {
                subRisk.subRisks.map((subRisk1) => {
                    subRisk1.subRisks.map((subRisk2) => {
                        if (selectedRegisterRiskId === subRisk2.riskTypeId) {
                            subRisk2["riskDescription"] = riskDescriptionText;
                            subRisk2["impactDescription"] = impactDescriptionText;
                            subRisk2["mitigation"] = mitigationText;
                            fondSelected = true;
                        }
                    })
                })
            })
        })
        if (!fondSelected) {
            findItemInSubSubSubSubRisksInReg(hierarchyData);
        } else {
            updateRegisterWithHierarchy()
        }
    }

    function findItemInSubSubRisksInReg(hierarchyData) {
        var fondSelected = false;
        hierarchyData.riskTypes.map((riskType) => {
            riskType.subRisks.map((subRisk) => {
                subRisk.subRisks.map((subRisk1) => {
                    if (selectedRegisterRiskId === subRisk1.riskTypeId) {
                        subRisk1["riskDescription"] = riskDescriptionText;
                        subRisk1["impactDescription"] = impactDescriptionText;
                        subRisk1["mitigation"] = mitigationText;
                        fondSelected = true;
                    }
                })
            })
        })
        if (!fondSelected) {
            findItemInSubSubSubRisksInReg(hierarchyData);
        } else {
            updateRegisterWithHierarchy()
        }
    }


    function findItemInSubRisksInReg(hierarchyData) {
        var fondSelected = false;
        hierarchyData.riskTypes.map((riskType) => {
            riskType.subRisks.map((subRisk) => {
                if (selectedRegisterRiskId === subRisk.riskTypeId) {
                    subRisk["riskDescription"] = riskDescriptionText;
                    subRisk["impactDescription"] = impactDescriptionText;
                    subRisk["mitigation"] = mitigationText;
                    fondSelected = true;
                }
            })
        })
        if (!fondSelected) {
            findItemInSubSubRisksInReg(hierarchyData);
        } else {
            updateRegisterWithHierarchy()
        }
    }

    function findItemInHierarchyData(hierarchyData) {
        var found = false;
        hierarchyData.riskTypes.forEach((item) => {
            if (item.riskTypeId === selectedRegisterRiskId) {
                item["riskDescription"] = riskDescriptionText
                item["impactDescription"] = impactDescriptionText
                item["mitigation"] = mitigationText
                found = true;
            }
        })
        if (found) {
            updateRegisterWithHierarchy()
        } else {
            findItemInSubRisksInReg(hierarchyData);
        }
    }


    function updateRegisterWithHierarchy() {
        var arr = [];
        var itemFound = false;
        registerTypeDataForEval.riskTypes.forEach((item) => {
            item["section"] = item.name;
            item["impact"] = item.riskImpact;
            item["likelihood"] = item.riskLikelihood;
            item["likelihood"] = item.riskLikelihood;
            var arr1 = [];
            item.subRisks.forEach((item1) => {
                item1["impact"] = item1.riskImpact;
                item1["likelihood"] = item1.riskLikelihood;
                arr1.push(item1)
            })
            item["risks"] = arr1;
            arr.push(item)
        })
        registerTypeDataForEval["riskRegister"] = arr;
        console.log(registerTypeDataForEval);
        setOpenRiskGpt(false)
        setWaitingOpen(true);
        setTimeout(() => {
            setTimeout(() => {
                let sendJson = JSON.stringify(registerTypeDataForEval);
                //console.log(sendJson)

                axios(CONFIG.MAIN_URL + '/register/save', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: sendJson,
                })
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        let rtnData = response.data;
                        arrangeRegister();
                        alert("Register Update successfully.");
                        console.log(registerTypeDataForEval);
                        setWaitingOpen(false);
                        setSelectedRegisterRiskId('');
                        
                    })
                    .catch(function (error) {
                        console.log(error);
                        setWaitingOpen(false);
                        alert("Error saving Register-:" + error);
                        setSelectedRegisterRiskId('');
                    });

            }, 100);
        }, 100);

    }

    function arrangeRegister(){
        var found = false;
        registerTypeDataForEval.riskTypes.forEach((item) => {
            item.subRisks.forEach((subRisk) => {
                if (subRisk.subRisks != undefined && subRisk.subRisks != null && subRisk.subRisks.length > 0){
                    subRisk.subRisks.forEach((subRisk1) => {
                        if (subRisk1.riskTypeId === selectedRegisterRiskId) {
                            subRisk1["riskDescription"] = riskDescriptionText;
                            subRisk1["impactDescription"] = impactDescriptionText;
                            subRisk1["mitigation"] = mitigationText;
                            found = true;
                        }
                    })
                }

            })
        })
        if (found){
            return;
        }else{
            arrangeRegister1();
        }
    }

    function arrangeRegister1(){
        var found = false;
        registerTypeDataForEval.riskTypes.forEach((item) => {
            item.subRisks.forEach((subRisk) => {
                if (subRisk.subRisks != undefined && subRisk.subRisks != null && subRisk.subRisks.length > 0){
                    subRisk.subRisks.forEach((subRisk1) => {
                        if (subRisk1.subRisks != undefined && subRisk1.subRisks != null && subRisk1.subRisks.length > 0){
                            subRisk1.subRisks.forEach((subRisk2) => {
                                if (subRisk2.riskTypeId === selectedRegisterRiskId) {
                                    subRisk2["riskDescription"] = riskDescriptionText;
                                    subRisk2["impactDescription"] = impactDescriptionText;
                                    subRisk2["mitigation"] = mitigationText;
                                    found = true;
                                }
                            })
                        }
                    })
                }

            })
        })
        if (found){
            return;
        }else{
            arrangeRegister2();
        }
    }

    function arrangeRegister2(){
        var found = false;
        registerTypeDataForEval.riskTypes.forEach((item) => {
            item.subRisks.forEach((subRisk) => {
                if (subRisk.subRisks != undefined && subRisk.subRisks != null && subRisk.subRisks.length > 0){
                    subRisk.subRisks.forEach((subRisk1) => {
                        if (subRisk1.subRisks != undefined && subRisk1.subRisks != null && subRisk1.subRisks.length > 0){
                            subRisk1.subRisks.forEach((subRisk2) => {
                                if (subRisk2.subRisks != undefined && subRisk2.subRisks != null && subRisk2.subRisks.length > 0){
                                    subRisk2.subRisks.forEach((subRisk3) => {
                                        if (subRisk3.riskTypeId === selectedRegisterRiskId) {
                                            subRisk3["riskDescription"] = riskDescriptionText;
                                            subRisk3["impactDescription"] = impactDescriptionText;
                                            subRisk3["mitigation"] = mitigationText;
                                            found = true;
                                        }
                                    })
                                }
                            })
                        }
                    })
                }    
            })
        })
        if (found){
            return;
        }else{
            arrangeRegister3();
        }
    }
    function arrangeRegister3(){
        var found = false;
        registerTypeDataForEval.riskTypes.forEach((item) => {
            item.subRisks.forEach((subRisk) => {
                if (subRisk.subRisks != undefined && subRisk.subRisks != null && subRisk.subRisks.length > 0){
                    subRisk.subRisks.forEach((subRisk1) => {
                        if (subRisk1.subRisks != undefined && subRisk1.subRisks != null && subRisk1.subRisks.length > 0){
                            subRisk1.subRisks.forEach((subRisk2) => {
                                if (subRisk2.subRisks != undefined && subRisk2.subRisks != null && subRisk2.subRisks.length > 0){
                                    subRisk2.subRisks.forEach((subRisk3) => {
                                        if (subRisk3.subRisks != undefined && subRisk3.subRisks != null && subRisk3.subRisks.length > 0){
                                            subRisk3.subRisks.forEach((subRisk4) => {
                                                if (subRisk4.riskTypeId === selectedRegisterRiskId) {
                                                    subRisk4["riskDescription"] = riskDescriptionText;
                                                    subRisk4["impactDescription"] = impactDescriptionText;
                                                    subRisk4["mitigation"] = mitigationText;
                                                    found = true;
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }

            })
        })
        if (found){
            return;
        }else{
            arrangeRegister4();
        }
    }
    function arrangeRegister4(){
        var found = false;
        registerTypeDataForEval.riskTypes.forEach((item) => {
            item.subRisks.forEach((subRisk) => {
                if (subRisk.subRisks != undefined && subRisk.subRisks != null && subRisk.subRisks.length > 0){
                    subRisk.subRisks.forEach((subRisk1) => {
                        if (subRisk1.subRisks != undefined && subRisk1.subRisks != null && subRisk1.subRisks.length > 0){
                            subRisk1.subRisks.forEach((subRisk2) => {
                                if (subRisk2.subRisks != undefined && subRisk2.subRisks != null && subRisk2.subRisks.length > 0){
                                    subRisk2.subRisks.forEach((subRisk3) => {
                                        if (subRisk3.subRisks != undefined && subRisk3.subRisks != null && subRisk3.subRisks.length > 0){
                                            subRisk3.subRisks.forEach((subRisk4) => {
                                                if (subRisk4.subRisks != undefined && subRisk4.subRisks != null && subRisk4.subRisks.length > 0){
                                                    subRisk4.subRisks.forEach((subRisk5) => {
                                                        if (subRisk5.riskTypeId === selectedRegisterRiskId) {
                                                            subRisk5["riskDescription"] = riskDescriptionText;
                                                            subRisk5["impactDescription"] = impactDescriptionText;
                                                            subRisk5["mitigation"] = mitigationText;
                                                            found = true;
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        })
        if (found){
            return;
        }else{
            arrangeRegister5();
        }
    }
    function arrangeRegister5(){
        var found = false;
        registerTypeDataForEval.riskTypes.forEach((item) => {
            item.subRisks.forEach((subRisk) => {
                if (subRisk.subRisks != undefined && subRisk.subRisks != null && subRisk.subRisks.length > 0){
                    subRisk.subRisks.forEach((subRisk1) => {
                        if (subRisk1.subRisks != undefined && subRisk1.subRisks != null && subRisk1.subRisks.length > 0){
                            subRisk1.subRisks.forEach((subRisk2) => {
                                if (subRisk2.subRisks != undefined && subRisk2.subRisks != null && subRisk2.subRisks.length > 0){
                                    subRisk2.subRisks.forEach((subRisk3) => {
                                        if (subRisk3.subRisks != undefined && subRisk3.subRisks != null && subRisk3.subRisks.length > 0){
                                            subRisk3.subRisks.forEach((subRisk4) => {
                                                if (subRisk4.subRisks != undefined && subRisk4.subRisks != null && subRisk4.subRisks.length > 0){
                                                    subRisk4.subRisks.forEach((subRisk5) => {
                                                        if (subRisk5.subRisks != undefined && subRisk5.subRisks != null && subRisk5.subRisks.length > 0){
                                                            subRisk5.subRisks.forEach((subRisk6) => {
                                                                if (subRisk6.riskTypeId === selectedRegisterRiskId) {
                                                                    subRisk6["riskDescription"] = riskDescriptionText;
                                                                    subRisk6["impactDescription"] = impactDescriptionText;
                                                                    subRisk6["mitigation"] = mitigationText;
                                                                    found = true;
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }   

            })
        })
        return;
    }



    function CommentRegister({ comment, lastRiskTmpId, riskNo, fromMainMenu, fromSubMenu, editRiskHirachyData }) {

        //console.log("++++++++++++++++++++comment++++++++++++++++++++++");
        //console.log(comment);

        const [subLastRiskTmpId, setSubLastRiskTmpId] = React.useState(lastRiskTmpId);
        const [subEditRiskHirachyData, setSubEditRiskHirachyData] = React.useState(editRiskHirachyData);
        const [subRiskNo, setSubRiskNo] = React.useState(riskNo);
        const [show, setShow] = React.useState(true)

        var noDisplay = '';
        var noDisplaySpacesCount = 0;


        const onTextFieldChangeLikelihood = (event) => {
            if (event != null && event.target.value != null) {
                var riskLevelVal = 1;
                var riskImpactVal = 1;
                if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                    event.target.value = '';
                    if (comment.riskImpact != undefined && comment.riskImpact != null && comment.riskImpact != '') {
                        riskImpactVal = parseInt(comment.riskImpact);
                    }
                } else {
                    if (comment.riskImpact != undefined && comment.riskImpact != null && comment.riskImpact != '') {
                        riskImpactVal = parseInt(comment.riskImpact);
                    }
                    riskLevelVal = parseInt(event.target.value)
                }
                comment["riskLikelihood"] = event.target.value;
                var rLevel = riskImpactVal * riskLevelVal;
                comment["riskLevel"] = rLevel.toString();
                //  comment.riskLevelData["riskLikelihood"] = event.target.value;
                //  comment.riskLevelData["riskLevel"] = rLevel.toString();
                var btn = document.getElementById("btn" + comment.riskTypeTmpId);
                btn.click();
                setTimeout(() => {
                    btn.click();
                }, 10);

            }
        }

        const onTextFieldChangeImpact = (event) => {
            if (event != null && event.target.value != null) {
                var riskLevelVal = 1;
                var riskImpactVal = 1;
                if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                    event.target.value = ''
                    if (comment.riskLikelihood != undefined && comment.riskLikelihood != null && comment.riskLikelihood != '') {
                        riskLevelVal = parseInt(comment.riskLikelihood);
                    }
                } else {
                    if (comment.riskLikelihood != undefined && comment.riskLikelihood != null && comment.riskLikelihood != '') {
                        riskLevelVal = parseInt(comment.riskLikelihood);
                    }
                    riskImpactVal = parseInt(event.target.value)
                }
                comment["riskImpact"] = event.target.value;
                //comment.riskLevelData["riskImpact"] = event.target.value;
                var rLevel = riskImpactVal * riskLevelVal;
                comment["riskLevel"] = rLevel.toString();
                //  comment.riskLevelData["riskLevel"] = rLevel.toString();
                var btn = document.getElementById("btn" + comment.riskTypeTmpId);
                btn.click();
                setTimeout(() => {
                    btn.click();
                }, 10);
            }
        }

        const onTextFieldChangeAppetite = (event) => {
            if (event != null && event.target.value != null) {
                if (event.target.value <= 0 || event.target.value > 10 || !Number.isInteger(parseInt(event.target.value))) {
                    event.target.value = ''
                }
                comment["riskAppetite"] = event.target.value;
                // comment.riskAppetiteData["riskAppetite"] = event.target.value;
                var btn = document.getElementById("btn" + comment.riskTypeTmpId);
                btn.click();
                setTimeout(() => {
                    btn.click();
                }, 10);
            }
        }



        var subAvarageRisk = 0;
        var subAvarageAppetite = 0;
        if (fromMainMenu) {
            //fromMainMenu = false;
            noDisplay = riskNo;
            subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageRisk = getSubAvarageRisk1(comment);
            subAvarageAppetite = getSubAvarageAppetite(comment);
        }
        if (fromSubMenu) {
            fromSubMenu = false;
            var subNo = getSubNoReg(comment, comment.riskTypeId)
            noDisplay = riskNo + '.' + subNo;
            var tmpArr = noDisplay.split(".");
            if (tmpArr.length === 2) {
                noDisplaySpacesCount = 1;
            }
            if (tmpArr.length === 3) {
                noDisplaySpacesCount = 2;
            }
            if (tmpArr.length === 4) {
                noDisplaySpacesCount = 3;
            }
            if (tmpArr.length === 5) {
                noDisplaySpacesCount = 4;
            }
            if (tmpArr.length === 6) {
                noDisplaySpacesCount = 5;
            }
            if (tmpArr.length === 7) {
                noDisplaySpacesCount = 6;
            }
            if (tmpArr.length === 8) {
                noDisplaySpacesCount = 7;
            }
            if (tmpArr.length === 9) {
                noDisplaySpacesCount = 8;
            }
            if (tmpArr.length === 10) {
                noDisplaySpacesCount = 9;
            }
            if (tmpArr.length === 11) {
                noDisplaySpacesCount = 10;
            }


            subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageRisk = getSubAvarageRisk1(comment);
            subAvarageAppetite = getSubAvarageAppetite(comment);
        }
        const nestedComments = (comment.subRisks || []).map(comment => {
            return <CommentRegister key={comment.riskTypeTmpId} comment={comment} lastRiskTmpId={subLastRiskTmpId} riskNo={subRiskNo} fromSubMenu={true} editRiskHirachyData={subEditRiskHirachyData} type="child" />
        })

        /** Need */
        function getRiskRiskTypeName(Comment) {
            var rName = ''
            riskCategoryMethodData.forEach((data) => {
                if (data.id === Comment.riskLevelData.riskMethodId) {
                    data.riskTypes.forEach((data1) => {
                        if (data1.startingNumber <= Comment.riskLevel && Comment.riskLevel <= data1.endingNumber) {
                            rName = data1.riskTypesName
                        }
                    })
                }

            })
            return rName;
        }

        /** Need */
        function getAppetiteRiskTypeName(Comment) {
            var aName = ''
            riskCategoryMethodDataAppetite.forEach((data) => {
                if (data.id === Comment.riskAppetiteData.riskMethodId) {
                    data.riskTypes.forEach((data1) => {
                        if (data1.startingNumber <= Comment.riskAppetite && Comment.riskAppetite <= data1.endingNumber) {
                            aName = data1.riskTypesName
                        }
                    })
                }

            })
            return aName;
        }


        /** Need */
        function getRiskRiskColor(Comment) {
            var rColor = ''
            var lastNumber = ''
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rColor = data2.color
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rColor = data2.secondaryColor
                        }
                    }
                }
            })
            if (rColor === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.color
                            }
                        } else if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.secondaryColor
                            }
                        }
                    }
                })
            }
            return rColor;
        }

        function getRiskRiskAction(Comment) {
            var rAction = ''
            var lastNumber = ''
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rAction = data2.primaryColorName
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rAction = data2.secondaryColorName
                        }
                    }
                }
            })
            if (rAction === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rAction = data2.primaryColorName
                            }
                        } else if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rAction = data2.secondaryColorName
                            }
                        }
                    }
                })
            }
            return rAction;
        }

        function getRiskRiskColorAve(avarageRisk) {
            var rColor = ''
            var lastNumber = '';
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageRisk)) {
                            rColor = data2.secondaryColor;
                        }
                    } else if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageRisk)) {
                            rColor = data2.color;
                        }
                    }
                }
            })
            if (rColor === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.secondaryColor;
                            }
                        } else if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.color;
                            }
                        }
                    }
                })
            }
            return rColor;
        }

        /** Need */
        function getAppetiteColor(Comment) {
            var aColor = ''
            var colorFg1 = ''

            databClr.forEach((data2) => {
                if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
                    //console.log("++++++++++++++data2+++++++++++++++")
                    //console.log(data2)
                    //console.log(applyFlag)
                    //console.log(Comment.riskAppetiteData)
                    //console.log(colorSelectDropDownRiskAppetite)

                    if (Comment.riskAppetite != null && Comment.riskAppetite != undefined) {
                        /*
                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (Comment.riskAppetiteData.color != null && Comment.riskAppetiteData.color != undefined && Comment.riskAppetiteData.color != '') {
                                    aColor = Comment.riskAppetiteData.color;
                                }
                            }
                            else if (colorSelectDropDownRiskAppetite === 2) {
                                if (Comment.riskAppetiteData.secondaryColor != null && Comment.riskAppetiteData.secondaryColor != undefined && Comment.riskAppetiteData.secondaryColor != '') {
                                    aColor = Comment.riskAppetiteData.secondaryColor;
      
                                }
                            }
                        }
                        */

                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (aColor === '') {
                                    aColor = data2.color
                                }
                            }
                            if (colorSelectDropDownRiskAppetite === 2) {
                                if (aColor === '') {
                                    aColor = data2.secondaryColor
                                }
                            }
                        }
                    }
                }
            })
            return aColor;
        }

        function getRiskAppetiteAction(Comment) {
            var aColor = ''
            var colorFg1 = ''

            databClr.forEach((data2) => {
                if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
                    //console.log("++++++++++++++data2+++++++++++++++")
                    //console.log(data2)
                    //console.log(applyFlag)
                    //console.log(Comment.riskAppetiteData)
                    //console.log(colorSelectDropDownRiskAppetite)

                    if (Comment.riskAppetite != null && Comment.riskAppetite != undefined) {
                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (aColor === '') {
                                    aColor = data2.primaryColorName
                                }
                            }
                            if (colorSelectDropDownRiskAppetite === 2) {
                                if (aColor === '') {
                                    aColor = data2.secondaryColorName
                                }
                            }
                        }
                    }
                }
            })
            return aColor;
        }

        function getAppetiteColorAve(avarageAppetite) {
            var aColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskAppetite === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageAppetite)) {
                            aColor = data2.secondaryColor;
                        }
                    } else if (colorSelectDropDownRiskAppetite === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageAppetite)) {
                            aColor = data2.color;
                        }
                    }
                }


            })
            return aColor;



        }

        /** Need */
        function getRiskLikeliColor(Comment) {

            var rColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLikelihood)) {
                            rColor = data2.color
                            //setApplyFlag(false)
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLikelihood)) {
                            rColor = data2.secondaryColor
                            //setApplyFlag(false)
                        }
                    }
                }

            })
            return rColor;
        }

        /** Need */
        function getRiskImpactColor(Comment) {

            var rColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskImpact)) {
                            rColor = data2.color
                            //setApplyFlag(false)
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskImpact)) {
                            rColor = data2.secondaryColor
                            //setApplyFlag(false)
                        }
                    }
                }

            })
            return rColor;
        }

        var riskLikelihood = getRiskLikeliColor(comment)
        var appetiteColor = getAppetiteColor(comment);
        var appetiteAction = getRiskAppetiteAction(comment);
        var riskColor = getRiskRiskColor(comment);
        var riskAction = getRiskRiskAction(comment);
        var riskImpact = getRiskImpactColor(comment)
        var riskColorAverage = getRiskRiskColorAve(subAvarageRisk);
        var appetiteColorAverage = getAppetiteColorAve(subAvarageAppetite)

        /** Need */
        function DisplayStandared({ comment }) {
            console.log("+++++++++++++Athula+++++++comment++++++++++++++++++++++")
            console.log(comment)
            var objectiveId = '';
            var objectiveName = '';
            var componentId = '';
            var componentName = '';
            var principleId = '';
            var principleName = '';
            var standardId = '';
            var standardName = '';
            if (comment.riskStandardData != undefined && comment.riskStandardData != null) {
                if (comment.riskStandardData.objectiveData != undefined && comment.riskStandardData.objectiveData != null) {
                    objectiveId = comment.riskStandardData.objectiveData.objectiveId;
                    objectiveName = comment.riskStandardData.objectiveData.objectiveName;
                    standardId = comment.riskStandardData.objectiveData.standardId;
                    standardName = comment.riskStandardData.objectiveData.standardName;
                }
                if (comment.riskStandardData.componentData != undefined && comment.riskStandardData.componentData != null) {
                    componentId = comment.riskStandardData.componentData.componentId;
                    componentName = comment.riskStandardData.componentData.componentName;
                    if (standardId === '') {
                        standardId = comment.riskStandardData.componentData.standardId;
                    }
                    if (standardName === '') {
                        standardName = comment.riskStandardData.componentData.standardName;
                    }
                }
                if (comment.riskStandardData.principleData != undefined && comment.riskStandardData.principleData != null) {
                    principleId = comment.riskStandardData.principleData.principleId;
                    principleName = comment.riskStandardData.principleData.principleName;
                    if (standardId === '') {
                        standardId = comment.riskStandardData.principleData.standardId;
                    }
                    if (standardName === '') {
                        standardName = comment.riskStandardData.principleData.standardName;
                    }
                }
            }
            var altString = 'Standard Name : ' + standardName;
            if (objectiveName != '') {
                altString += '\nObjective Name : ' + objectiveName;
            }
            if (componentName != '') {
                altString += '\nComponent Name : ' + componentName;
            }
            if (principleName != '') {
                altString += '\nPrinciple Name : ' + principleName;
            }
            if (standardName.length > 10) {
                standardName = standardName.substring(0, 10) + '...';
            }

            console.log("++++++++++++++++++++++++++++altString+++++++++++++++++++++++");
            console.log(altString);
            console.log(standardName);

            return (
                <>

                    {
                        <span title={`${altString}`} style={{ cursor: 'pointer' }}>&nbsp;{standardName}</span>
                    }

                </>
            )
        }
        comment["riskColor"] = riskColor;
        comment["appetiteColor"] = appetiteColor;

        var createArr = pushToArray(comment);

        return (
            <>
                <Grid item xs={6}>

                    {/* {
                        comment.role != undefined && comment.role != null && comment.role != '' ? 
                        
                       <span style={{fontWeight:'bold', marginLeft:'20px'}}>{comment.role}</span> 
                        
                        : ''
                    }
                    <br></br> */}

                    {noDisplaySpacesCount === 0 ?
                        <></>
                        : noDisplaySpacesCount === 1 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;</>
                            : noDisplaySpacesCount === 2 ?
                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                : noDisplaySpacesCount === 3 ?
                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                    : noDisplaySpacesCount === 4 ?
                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                        : noDisplaySpacesCount === 5 ?
                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                            : noDisplaySpacesCount === 6 ?
                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                : noDisplaySpacesCount === 7 ?
                                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                    : noDisplaySpacesCount === 8 ?
                                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                        : noDisplaySpacesCount === 9 ?
                                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                            : noDisplaySpacesCount === 10 ?
                                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                                : ''
                    }

                    <Button
                        className='expandMoreBtn'
                        id={`btn` + comment.riskTypeTmpId}
                        onClick={() => setShow(!show)}
                        startIcon={show ? <ExpandMoreIcon className='expandMoreIcon' /> : <ChevronRightIcon className='expandMoreIcon' />}
                    >
                    </Button>

                    {!fromMainMenu ?
                        <>
                            {comment.mitigation != undefined && comment.mitigation != null ?
                                <>
                                    <span className='commentNameNormal'>{noDisplay}{') '}{comment.name + ' '}
                                        <Tooltip1 title={<span style={{ fontSize: "12px" }}><u>Risk Description:</u> {comment.riskDescription} <br></br><u>Impact Description: </u> {comment.impactDescription}<br></br><u>Mitigation:</u> {comment.mitigation}</span>}>
                                            <Button variant='outlined' style={{ padding: "0px 0px" }} onClick={() => displayMitigation(comment)}>&nbsp; Edit..</Button>
                                        </Tooltip1>

                                    </span>
                                </>
                                :
                                <span className='commentNameNormal'>{noDisplay}{') '}{comment.name}</span>
                            }
                        </>
                        :
                        <span className='commentNameBold'>{noDisplay}{') '}{comment.name}</span>
                    }

                    {/* {!editRiskHirachyData ?
                        comment.name
                        :
                        <TextField
                            size="small"
                            id={`txt` + comment.riskTypeTmpId}
                            label="Type/Edit Risk Name"
                            defaultValue={comment.name}
                            title={comment.name}
                            onChange={onTxtNameChageEvent}
                            style={{ height: '10px !important' }}riskAppetite
                        />
                    } */}
                </Grid>
                {!fromMainMenu ?
                    <>
                        <Grid item xs={1}>
                            {!editRiskHirachyData ?
                                <span className='likelihoodSpan'><div className='likelihoodDiv'><p className='likelihoodP'>{comment.riskLikelihood}</p></div></span>
                                :
                                <TextField
                                    size="small"
                                    id={`txtRiskLikelihood` + comment.riskTypeTmpId}
                                    //label="Likelihood"
                                    sx={{
                                        width: '100%',
                                        "& .MuiInputBase-root": {
                                            height: 31,
                                            padding: '1.5px 1px !important',
                                            marginTop: -0.5
                                        },
                                        "& .MuiInputLabel-root": {
                                            marginTop: -0.5
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: '1.5px 1px !important',
                                            fontSize: '11px !important'
                                        }
                                    }}
                                    defaultValue={comment.riskLikelihood}
                                    title={comment.riskLikelihood}
                                    onChange={onTextFieldChangeLikelihood}
                                    className='appetiteTxt'
                                />
                            }

                        </Grid>

                        <Grid item xs={1}>
                            {!editRiskHirachyData ?
                                <span className='impactSpan'><div className='impactDiv'><p className='impactP'>{comment.riskImpact}</p></div></span>
                                :
                                <TextField
                                    size="small"
                                    id={`txtRiskImpact` + comment.riskTypeTmpId}
                                    sx={{
                                        width: '100%',
                                        "& .MuiInputBase-root": {
                                            height: 31,
                                            padding: '1.5px 1px !important',
                                            marginTop: -0.5
                                        },
                                        "& .MuiInputLabel-root": {
                                            marginTop: -0.5
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: '1.5px 1px !important',
                                            fontSize: '11px !important'
                                        }
                                    }}
                                    //label="Impact"
                                    defaultValue={comment.riskImpact}
                                    title={comment.riskImpact}
                                    onChange={onTextFieldChangeImpact}
                                    className='appetiteTxt'
                                />
                            }
                        </Grid>

                        <Grid item xs={1}>
                            <span className='riskSpan'><div className='riskDiv' style={{ backgroundColor: `${riskColor}`, color: '#ffffff' }}><p className='riskP'>{comment.riskLevel}</p></div></span>
                            {/** <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLevel}</p></div></span>*/}
                        </Grid>
                        <Grid item xs={1}>
                            <span className='riskActionSpan'><div className='riskActionDiv'><p className='riskActionP'>{riskAction}</p></div></span>
                        </Grid>
                        <Grid item xs={1}>
                            {!editRiskHirachyData ?
                                <span className='appetiteSpan'><div className='appetiteDiv' style={{ backgroundColor: `${appetiteColor}`, color: '#ffffff' }}><p className='appetiteP'>{comment.riskAppetite}</p></div></span>
                                :
                                <TextField
                                    size="small"
                                    id={`txtRiskAppetite` + comment.riskTypeTmpId}
                                    sx={{
                                        width: '100%',
                                        "& .MuiInputBase-root": {
                                            height: 31,
                                            padding: '1.5px 1px !important',
                                            marginTop: -0.5
                                        },
                                        "& .MuiInputLabel-root": {
                                            marginTop: -0.5
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: '1.5px 1px !important',
                                            fontSize: '11px !important',
                                            color: '#ffffff !important',
                                            fontWeight: 'bold'
                                        }
                                    }}
                                    //label="Appetite"
                                    defaultValue={comment.riskAppetite}
                                    title={comment.riskAppetite}
                                    onChange={onTextFieldChangeAppetite}
                                    className='appetiteTxt'
                                    style={{ backgroundColor: `${appetiteColor}`, color: '#ffffff' }}
                                />
                            }
                        </Grid>
                        <Grid item xs={1}>
                            <span className='appetiteActionSpan'><div className='appetiteActionDiv'><p className='appetiteActionP'>{appetiteAction}</p></div></span>
                        </Grid>
                    </>
                    : <span>&nbsp;</span>
                }
                {/*riskDisplayStr*/}

                {
                    show ?
                        <>
                            {nestedComments}
                        </>
                        : null
                }
            </>

        )
    }

    function getSubAvarageRisk(comment) {
        var subTotal = 0
        var avarage = 0;
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            var len = 0;
            var subTotal = 0;
            comment.subRisks.forEach((item) => {
                subTotal = subTotal + parseInt(item.riskLevel);
                len = len + 1;
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    item.subRisks.forEach((item1) => {
                        subTotal = subTotal + parseInt(item1.riskLevel);
                        len = len + 1;
                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                            item1.subRisks.forEach((item2) => {
                                subTotal = subTotal + parseInt(item2.riskLevel);
                                len = len + 1;
                                if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                    item2.subRisks.forEach((item3) => {
                                        subTotal = subTotal + parseInt(item3.riskLevel);
                                        len = len + 1;
                                        if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                            item3.subRisks.forEach((item4) => {
                                                subTotal = subTotal + parseInt(item4.riskLevel);
                                                len = len + 1;
                                                if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                                    item4.subRisks.forEach((item5) => {
                                                        subTotal = subTotal + parseInt(item5.riskLevel);
                                                        len = len + 1;
                                                        if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                            item5.subRisks.forEach((item6) => {
                                                                subTotal = subTotal + parseInt(item6.riskLevel);
                                                                len = len + 1;
                                                                if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {

                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }

            })
            avarage = (subTotal / (len));
            //avarage = Math.round(avarage * 100)/100;
            avarage = Math.round(avarage);
        }
        return avarage;

    }

    function getSubAvarageAppetite(comment) {
        var subTotal = 0
        var avarage = 0;
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            var len = 0;
            var subTotal = 0;
            comment.subRisks.forEach((item) => {
                subTotal = subTotal + parseInt(item.riskAppetite);
                len = len + 1;
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    item.subRisks.forEach((item1) => {
                        subTotal = subTotal + parseInt(item1.riskAppetite);
                        len = len + 1;
                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                            item1.subRisks.forEach((item2) => {
                                subTotal = subTotal + parseInt(item2.riskAppetite);
                                len = len + 1;
                                if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                    item2.subRisks.forEach((item3) => {
                                        subTotal = subTotal + parseInt(item3.riskAppetite);
                                        len = len + 1;
                                        if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                            item3.subRisks.forEach((item4) => {
                                                subTotal = subTotal + parseInt(item4.riskAppetite);
                                                len = len + 1;
                                                if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                                    item4.subRisks.forEach((item5) => {
                                                        subTotal = subTotal + parseInt(item5.riskAppetite);
                                                        len = len + 1;
                                                        if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                            item5.subRisks.forEach((item6) => {
                                                                subTotal = subTotal + parseInt(item6.riskAppetite);
                                                                len = len + 1;
                                                                if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {

                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }

            })
            avarage = (subTotal / (len));
            //avarage = Math.round(avarage * 100)/100;
            avarage = Math.round(avarage);
        }
        return avarage;

    }

    function getSubNoReg(comment, riskId) {
        var subNo = "0";
        arrayForDisplayDataWithRole.riskTypes.forEach((data) => {
            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                data.subRisks.forEach((data1, index) => {
                    if (data1.riskTypeId === riskId) {
                        subNo = (index + 1) + "";
                    }

                })
            }
        })

        if (subNo === "0") {
            arrayForDisplayDataWithRole.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((data1, index1) => {
                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                            data1.subRisks.forEach((data2, index2) => {
                                if (data2.riskTypeId === riskId) {
                                    subNo = (index1 + 1) + "." + (index2 + 1);
                                }
                            })
                        }
                    })
                }
            })
        }
        if (subNo === "0") {
            arrayForDisplayDataWithRole.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((data1, index1) => {
                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                            data1.subRisks.forEach((data2, index2) => {
                                if (data2.subRisks != undefined && data2.subRisks != null && data2.subRisks.length > 0) {
                                    data2.subRisks.forEach((data3, index3) => {
                                        if (data3.riskTypeId === riskId) {
                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1);
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
        if (subNo === "0") {
            arrayForDisplayDataWithRole.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((data1, index1) => {
                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                            data1.subRisks.forEach((data2, index2) => {
                                if (data2.subRisks != undefined && data2.subRisks != null && data2.subRisks.length > 0) {
                                    data2.subRisks.forEach((data3, index3) => {
                                        if (data3.subRisks != undefined && data3.subRisks != null && data3.subRisks.length > 0) {
                                            data3.subRisks.forEach((data4, index4) => {
                                                if (data4.riskTypeId === riskId) {
                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1);
                                                }
                                            })
                                        }

                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
        if (subNo === "0") {
            arrayForDisplayDataWithRole.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((data1, index1) => {
                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                            data1.subRisks.forEach((data2, index2) => {
                                if (data2.subRisks != undefined && data2.subRisks != null && data2.subRisks.length > 0) {
                                    data2.subRisks.forEach((data3, index3) => {
                                        if (data3.subRisks != undefined && data3.subRisks != null && data3.subRisks.length > 0) {
                                            data3.subRisks.forEach((data4, index4) => {
                                                if (data4.subRisks != undefined && data4.subRisks != null && data4.subRisks.length > 0) {
                                                    data4.subRisks.forEach((data5, index5) => {
                                                        if (data5.riskTypeId === riskId) {
                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1);
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
        if (subNo === "0") {
            arrayForDisplayDataWithRole.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((data1, index1) => {
                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                            data1.subRisks.forEach((data2, index2) => {
                                if (data2.subRisks != undefined && data2.subRisks != null && data2.subRisks.length > 0) {
                                    data2.subRisks.forEach((data3, index3) => {
                                        if (data3.subRisks != undefined && data3.subRisks != null && data3.subRisks.length > 0) {
                                            data3.subRisks.forEach((data4, index4) => {
                                                if (data4.subRisks != undefined && data4.subRisks != null && data4.subRisks.length > 0) {
                                                    data4.subRisks.forEach((data5, index5) => {
                                                        if (data5.subRisks != undefined && data5.subRisks != null && data5.subRisks.length > 0) {
                                                            data5.subRisks.forEach((data6, index6) => {
                                                                if (data6.riskTypeId === riskId) {
                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1);
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
        if (subNo === "0") {
            arrayForDisplayDataWithRole.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((data1, index1) => {
                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                            data1.subRisks.forEach((data2, index2) => {
                                if (data2.subRisks != undefined && data2.subRisks != null && data2.subRisks.length > 0) {
                                    data2.subRisks.forEach((data3, index3) => {
                                        if (data3.subRisks != undefined && data3.subRisks != null && data3.subRisks.length > 0) {
                                            data3.subRisks.forEach((data4, index4) => {
                                                if (data4.subRisks != undefined && data4.subRisks != null && data4.subRisks.length > 0) {
                                                    data4.subRisks.forEach((data5, index5) => {
                                                        if (data5.subRisks != undefined && data5.subRisks != null && data5.subRisks.length > 0) {
                                                            data5.subRisks.forEach((data6, index6) => {
                                                                if (data6.subRisks != undefined && data6.subRisks != null && data6.subRisks.length > 0) {
                                                                    data6.subRisks.forEach((data7, index7) => {
                                                                        if (data7.riskTypeId === riskId) {
                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1);
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
        if (subNo === "0") {
            arrayForDisplayDataWithRole.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((data1, index1) => {
                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                            data1.subRisks.forEach((data2, index2) => {
                                if (data2.subRisks != undefined && data2.subRisks != null && data2.subRisks.length > 0) {
                                    data2.subRisks.forEach((data3, index3) => {
                                        if (data3.subRisks != undefined && data3.subRisks != null && data3.subRisks.length > 0) {
                                            data3.subRisks.forEach((data4, index4) => {
                                                if (data4.subRisks != undefined && data4.subRisks != null && data4.subRisks.length > 0) {
                                                    data4.subRisks.forEach((data5, index5) => {
                                                        if (data5.subRisks != undefined && data5.subRisks != null && data5.subRisks.length > 0) {
                                                            data5.subRisks.forEach((data6, index6) => {
                                                                if (data6.subRisks != undefined && data6.subRisks != null && data6.subRisks.length > 0) {
                                                                    data6.subRisks.forEach((data7, index7) => {
                                                                        if (data7.subRisks != undefined && data7.subRisks != null && data7.subRisks.length > 0) {
                                                                            data7.subRisks.forEach((data8, index8) => {
                                                                                if (data8.riskTypeId === riskId) {
                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1);
                                                                                }
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
        if (subNo === "0") {
            arrayForDisplayDataWithRole.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((data1, index1) => {
                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                            data1.subRisks.forEach((data2, index2) => {
                                if (data2.subRisks != undefined && data2.subRisks != null && data2.subRisks.length > 0) {
                                    data2.subRisks.forEach((data3, index3) => {
                                        if (data3.subRisks != undefined && data3.subRisks != null && data3.subRisks.length > 0) {
                                            data3.subRisks.forEach((data4, index4) => {
                                                if (data4.subRisks != undefined && data4.subRisks != null && data4.subRisks.length > 0) {
                                                    data4.subRisks.forEach((data5, index5) => {
                                                        if (data5.subRisks != undefined && data5.subRisks != null && data5.subRisks.length > 0) {
                                                            data5.subRisks.forEach((data6, index6) => {
                                                                if (data6.subRisks != undefined && data6.subRisks != null && data6.subRisks.length > 0) {
                                                                    data6.subRisks.forEach((data7, index7) => {
                                                                        if (data7.subRisks != undefined && data7.subRisks != null && data7.subRisks.length > 0) {
                                                                            data7.subRisks.forEach((data8, index8) => {
                                                                                if (data8.subRisks != undefined && data8.subRisks != null && data8.subRisks.length > 0) {
                                                                                    data8.subRisks.forEach((data9, index9) => {
                                                                                        if (data9.riskTypeId === riskId) {
                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1);
                                                                                        }
                                                                                    })
                                                                                }
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
        if (subNo === "0") {
            arrayForDisplayDataWithRole.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((data1, index1) => {
                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                            data1.subRisks.forEach((data2, index2) => {
                                if (data2.subRisks != undefined && data2.subRisks != null && data2.subRisks.length > 0) {
                                    data2.subRisks.forEach((data3, index3) => {
                                        if (data3.subRisks != undefined && data3.subRisks != null && data3.subRisks.length > 0) {
                                            data3.subRisks.forEach((data4, index4) => {
                                                if (data4.subRisks != undefined && data4.subRisks != null && data4.subRisks.length > 0) {
                                                    data4.subRisks.forEach((data5, index5) => {
                                                        if (data5.subRisks != undefined && data5.subRisks != null && data5.subRisks.length > 0) {
                                                            data5.subRisks.forEach((data6, index6) => {
                                                                if (data6.subRisks != undefined && data6.subRisks != null && data6.subRisks.length > 0) {
                                                                    data6.subRisks.forEach((data7, index7) => {
                                                                        if (data7.subRisks != undefined && data7.subRisks != null && data7.subRisks.length > 0) {
                                                                            data7.subRisks.forEach((data8, index8) => {
                                                                                if (data8.subRisks != undefined && data8.subRisks != null && data8.subRisks.length > 0) {
                                                                                    data8.subRisks.forEach((data9, index9) => {
                                                                                        if (data9.subRisks != undefined && data9.subRisks != null && data9.subRisks.length > 0) {
                                                                                            data9.subRisks.forEach((data10, index10) => {
                                                                                                if (data10.riskTypeId === riskId) {
                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1) + "." + (index10 + 1);
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                }
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
        if (subNo === "0") {
            arrayForDisplayDataWithRole.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((data1, index1) => {
                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                            data1.subRisks.forEach((data2, index2) => {
                                if (data2.subRisks != undefined && data2.subRisks != null && data2.subRisks.length > 0) {
                                    data2.subRisks.forEach((data3, index3) => {
                                        if (data3.subRisks != undefined && data3.subRisks != null && data3.subRisks.length > 0) {
                                            data3.subRisks.forEach((data4, index4) => {
                                                if (data4.subRisks != undefined && data4.subRisks != null && data4.subRisks.length > 0) {
                                                    data4.subRisks.forEach((data5, index5) => {
                                                        if (data5.subRisks != undefined && data5.subRisks != null && data5.subRisks.length > 0) {
                                                            data5.subRisks.forEach((data6, index6) => {
                                                                if (data6.subRisks != undefined && data6.subRisks != null && data6.subRisks.length > 0) {
                                                                    data6.subRisks.forEach((data7, index7) => {
                                                                        if (data7.subRisks != undefined && data7.subRisks != null && data7.subRisks.length > 0) {
                                                                            data7.subRisks.forEach((data8, index8) => {
                                                                                if (data8.subRisks != undefined && data8.subRisks != null && data8.subRisks.length > 0) {
                                                                                    data8.subRisks.forEach((data9, index9) => {
                                                                                        if (data9.subRisks != undefined && data9.subRisks != null && data9.subRisks.length > 0) {
                                                                                            data9.subRisks.forEach((data10, index10) => {
                                                                                                if (data10.subRisks != undefined && data10.subRisks != null && data10.subRisks.length > 0) {
                                                                                                    data10.subRisks.forEach((data11, index11) => {
                                                                                                        if (data11.riskTypeId === riskId) {
                                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1) + "." + (index10 + 1) + "." + (index11 + 1);
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                }
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }



        /** 
        if (subNo === "0") {
            arrayForDisplayDataWithRole.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((item, index) => {
                        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                            var tmpN0 = getSubRiskSubNo(item.subRisks, riskId, '');
                            if (tmpN0 != "0") {
                                subNo = (index + 1) + '.' + tmpN0;
                            } else {
                                arrayForDisplayDataWithRole.riskTypes.forEach((data1) => {
                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                        data1.subRisks.forEach((item1, index1) => {
                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                item1.subRisks.forEach((item2, index2) => {
                                                    if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                                        item2.subRisks.forEach((item3, index3) => {
                                                            if (item3.riskTypeId === riskId) {
                                                                subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1);
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                                if (tmpN0 === "0") {
                                    arrayForDisplayDataWithRole.riskTypes.forEach((data1) => {
                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                            data1.subRisks.forEach((item1, index1) => {
                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                    item1.subRisks.forEach((item2, index2) => {
                                                        if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                                            item2.subRisks.forEach((item3, index3) => {
                                                                if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                        if (item4.riskTypeId === riskId) {
                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1);
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                    if (tmpN0 === "0") {
                                        arrayForDisplayDataWithRole.riskTypes.forEach((data1) => {
                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                data1.subRisks.forEach((item1, index1) => {
                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                        item1.subRisks.forEach((item2, index2) => {
                                                            item2.subRisks.forEach((item3, index3) => {
                                                                item3.subRisks.forEach((item4, index4) => {
                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                        if (item5.riskTypeId === riskId) {
                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1);
                                                                        }
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                        if (tmpN0 === "0") {
                                            arrayForDisplayDataWithRole.riskTypes.forEach((data1) => {
                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                    data1.subRisks.forEach((item1, index1) => {
                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                            item1.subRisks.forEach((item2, index2) => {
                                                                item2.subRisks.forEach((item3, index3) => {
                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                if (item6.riskTypeId === riskId) {
                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1);
                                                                                }
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                            if (tmpN0 === "0") {
                                                arrayForDisplayDataWithRole.riskTypes.forEach((data1) => {
                                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                        data1.subRisks.forEach((item1, index1) => {
                                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                item1.subRisks.forEach((item2, index2) => {
                                                                    item2.subRisks.forEach((item3, index3) => {
                                                                        item3.subRisks.forEach((item4, index4) => {
                                                                            item4.subRisks.forEach((item5, index5) => {
                                                                                item5.subRisks.forEach((item6, index6) => {
                                                                                    item6.subRisks.forEach((item7, index7) => {
                                                                                        if (item7.riskTypeId === riskId) {
                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1);
                                                                                        }
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            }
                                                        })
                                                    }
                                                })
                                                if (tmpN0 === "0") {
                                                    arrayForDisplayDataWithRole.riskTypes.forEach((data1) => {
                                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                            data1.subRisks.forEach((item1, index1) => {
                                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                    item1.subRisks.forEach((item2, index2) => {
                                                                        item2.subRisks.forEach((item3, index3) => {
                                                                            item3.subRisks.forEach((item4, index4) => {
                                                                                item4.subRisks.forEach((item5, index5) => {
                                                                                    item5.subRisks.forEach((item6, index6) => {
                                                                                        item6.subRisks.forEach((item7, index7) => {
                                                                                            item7.subRisks.forEach((item8, index8) => {
                                                                                                if (item8.riskTypeId === riskId) {
                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1);
                                                                                                }
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                    if (tmpN0 === "0") {
                                                        arrayForDisplayDataWithRole.riskTypes.forEach((data1) => {
                                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                data1.subRisks.forEach((item1, index1) => {
                                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                        item1.subRisks.forEach((item2, index2) => {
                                                                            item2.subRisks.forEach((item3, index3) => {
                                                                                item3.subRisks.forEach((item4, index4) => {
                                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                                        item5.subRisks.forEach((item6, index6) => {
                                                                                            item6.subRisks.forEach((item7, index7) => {
                                                                                                item7.subRisks.forEach((item8, index8) => {
                                                                                                    item8.subRisks.forEach((item9, index9) => {
                                                                                                        if (item9.riskTypeId === riskId) {
                                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1);
                                                                                                        }
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        })
                                                        if (tmpN0 === "0") {
                                                            arrayForDisplayDataWithRole.riskTypes.forEach((data1) => {
                                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                    data1.subRisks.forEach((item1, index1) => {
                                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                            item1.subRisks.forEach((item2, index2) => {
                                                                                item2.subRisks.forEach((item3, index3) => {
                                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                                item6.subRisks.forEach((item7, index7) => {
                                                                                                    item7.subRisks.forEach((item8, index8) => {
                                                                                                        item8.subRisks.forEach((item9, index9) => {
                                                                                                            item9.subRisks.forEach((item10, index10) => {
                                                                                                                if (item10.riskTypeId === riskId) {
                                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1) + "." + (index10 + 1);
                                                                                                                }
                                                                                                            })    
                                                                                                        })
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            })
      
                                                        }
      
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
      
      
                            }
      
                        }
      
                    })
                }
            })
      
        }
        */

        return subNo;
    }

    const [gradiantdropDown, setGradiantdropDown] = React.useState(1);

    const handleChangeGradiantdropDown = (event) => {
        setGradiantdropDown(event.target.value);
    };


    function saveRegister() {


        console.log(registerTypeDataForEval)
        let obj = {};


        obj["id"] = registerTypeDataForEval.id;
        obj["name"] = registerTypeDataForEval.name;
        obj["description"] = registerTypeDataForEval.description;
        obj["centreId"] = registerTypeDataForEval.centreId;
        obj["status"] = 'Draft';
        obj["registerUniqueName"] = registerTypeDataForEval.registerUniqueName;
        obj["documentCreator"] = "expert";

        let arr = []
        registerTypeDataForEval.riskTypes.map((data) => {

            let obj1 = {}
            obj1["id"] = data.id;
            obj1["orderNo"] = data.orderNo;
            obj1["section"] = data.name;
            obj1["role"] = data.role;
            obj1["likelihood"] = data.riskLikelihood;
            obj1["impact"] = data.riskImpact;
            obj1["riskLevel"] = data.riskLevel;
            obj1["riskAppetite"] = data.riskAppetite;


            var arr1 = []
            data.subRisks.map((data1) => {
                let obj2 = {}
                obj2["id"] = data1.id;
                obj2["orderNo"] = data1.orderNo;
                obj2["name"] = data1.name;
                obj2["description"] = data1.description;
                obj2["likelihood"] = parseInt(data1.riskLikelihood);
                obj2["impact"] = parseInt(data1.riskImpact);
                obj2["riskLevel"] = parseInt(data1.riskLevel);
                obj2["riskAppetite"] = parseInt(data1.riskAppetite);

                let obj3 = {}

                obj3["riskId"] = data1.riskHierarchy.riskId;
                obj3["key"] = data1.riskHierarchy.key;
                obj3["name"] = data1.riskHierarchy.name;

                let obj4 = {}


                if (data1.riskHierarchy != undefined && data1.riskHierarchy != null && data1.riskHierarchy.riskHierarchyData != undefined && data1.riskHierarchy.riskHierarchyData != null) {
                    if (data1.riskHierarchy.riskHierarchyData.riskId != undefined && data1.riskHierarchy.riskHierarchyData.riskId != null && data1.riskHierarchy.riskHierarchyData.riskId != '') {
                        obj4["riskId"] = data1.riskHierarchy.riskHierarchyData.riskId;
                        obj4["key"] = data1.riskHierarchy.riskHierarchyData.key;
                        obj4["name"] = data1.riskHierarchy.riskHierarchyData.name;
                        obj4["riskLikelihood"] = data1.riskHierarchy.riskHierarchyData.riskLikelihood;
                        obj4["riskImpact"] = data1.riskHierarchy.riskHierarchyData.riskImpact;
                        obj4["riskLevel"] = data1.riskHierarchy.riskHierarchyData.riskLevel;
                        obj4["riskAppetite"] = data1.riskHierarchy.riskHierarchyData.riskAppetite;
                        obj4["centreId"] = data1.riskHierarchy.riskHierarchyData.centreId;

                        obj4["riskTypes"] = data1.subRisks;


                    }
                }





                obj3["riskHierarchyData"] = obj4;



                obj2["riskHierarchy"] = obj3;
                obj2["subRisks"] = data1.subRisks;





                arr1.push(obj2)
            })
            obj1["risks"] = arr1;

            arr.push(obj1)
        })
        obj["riskRegister"] = arr;







        console.log(obj);
        console.log(JSON.stringify(obj));
        let sendJson = JSON.stringify(obj);

        axios(CONFIG.MAIN_URL + '/register/save', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: sendJson,
        })
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                let rtnData = response.data;
                alert("Register Update successfully.");
                setEditRiskHirachy(false);

            })
            .catch(function (error) {
                console.log(error);
                alert("Error saving Register-:" + error);
            });

        // }

    }

    return (
        <>
            <Typography variant="h6" className='fullScreenIcon'>
                <Tooltip1 title={fullScreenStateMsg}><FullscreenRounded onClick={() => { setMinMaxWindowFunctionRegister() }} style={{ cursor: 'pointer' }} /></Tooltip1>
            </Typography>
            {!fullScreenState ?
                <>


                    <Grid container className='viewsMargin2'>
                        <Grid item className='viewsGrid1'>
                            <span className='viewTop fontSize12'>Views</span>
                        </Grid>
                        <Grid item className='viewsGrid2'>
                            <Button
                                variant='outlined'
                                className={btnHirachivalClass + ' ' + 'btnTop'}
                                onClick={() => { setShowEvalData(true); setShowTableType(1); hierarchicalFunctionRegister() }}
                            >
                                Hierarchical View
                            </Button>
                        </Grid>
                        <Grid item className='viewsGrid3'>
                            <Button
                                variant='outlined'
                                className={btnGradientClass + ' ' + 'btnTop'}
                                onClick={() => displayRiskAsendingRegister()}
                            >
                                Gradient View
                            </Button>
                        </Grid>
                        <Grid item className='viewsGrid4'>
                            <Button
                                variant='outlined'
                                className={btnBarChartClass}
                                onClick={() => createBarFunctionRegister()}
                            >
                                BarChart View
                            </Button>
                        </Grid>
                        {showTranferExlButton ?
                            <>
                                <Grid item className='viewsGrid5'>
                                    <TransferToExcel1
                                        commitData={commitArray}
                                        commitArrayData={commitArrayData}
                                        commitBarData={commitBarData}
                                        riskTypeDataForEval={arrayForDisplayDataWithRole}
                                        registerName={registerTypeDataForEval.name}
                                    />
                                </Grid>
                            </>
                            : ''}


                    </Grid>

                    <br></br>

                    <Grid container spacing={2} style={{ marginBottom: '15px' }}>
                        <Grid item className='selectPattersGrid2'>
                            <span className='fontSize12'>select your color patterns here</span>
                        </Grid>
                        <Grid item className='selectPattersGrid3'>
                            <TextField
                                label='Select Color Pattern'
                                required
                                sx={{
                                    width: '100%',
                                    "& .MuiInputBase-root": {
                                        height: 37
                                    },
                                    "& .MuiInputLabel-root": {
                                        marginTop: -0.5
                                    }
                                }}
                                select
                                value={colorPatternId}
                                onChange={handleChangeColorPattern}
                            >
                                {colorPatterns.map((item) => (
                                    <MenuItem
                                        value={item.id}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item className='selectPattersGrid3'>

                            <TextField
                                label='Risk Level'
                                required
                                sx={{
                                    width: '100%',
                                    "& .MuiInputBase-root": {
                                        height: 37
                                    },
                                    "& .MuiInputLabel-root": {
                                        marginTop: -0.5
                                    }
                                }}
                                select
                                value={colorSelectDropDownRiskLevelTmp}
                                onChange={handleChangeDropDown}
                            >
                                <MenuItem value={1}>{colorPatternPrimaryColorName}</MenuItem>
                                <MenuItem value={2}>{colorPatternSecondaryColorName}</MenuItem>
                            </TextField>



                        </Grid>
                        <Grid item className='selectPattersGrid3'>
                            <TextField
                                label='Risk Appetite'
                                required
                                sx={{
                                    width: '100%',
                                    "& .MuiInputBase-root": {
                                        height: 37
                                    },
                                    "& .MuiInputLabel-root": {
                                        marginTop: -0.5
                                    }
                                }}
                                select
                                value={colorSelectDropDownRiskAppetiteTmp}
                                onChange={handleChangeDropDownAppetite}
                            >
                                <MenuItem value={1}>{colorPatternPrimaryColorName}</MenuItem>
                                <MenuItem value={2}>{colorPatternSecondaryColorName}</MenuItem>
                            </TextField>


                        </Grid>
                        <Grid item className='selectPattersGrid1'>
                            <Button variant='outlined' style={{ padding: "7px 5px" }} onClick={applyFunctionRegister}>Apply</Button>
                        </Grid>
                    </Grid>

                </>

                : ''
            }
            <DialogContent
                dividers={!fullScreenState ? openRegisterDataForEvalScroll === 'paper' : ''}
            >
                <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {Object.keys(arrayForDisplayDataWithRole).length > 0 &&
                                <>
                                    <Grid item xs={12}>
                                        <div>
                                            {
                                                showEvalData && showTableType === 1 ?
                                                    <>
                                                        <div style={{ display: 'flex', gap: "15px", float: 'right' }}>
                                                            <div>
                                                                <Button
                                                                    variant='outlined'
                                                                    className={classes.btnSepateWindow}
                                                                    onClick={() => { showDataInAViewFunction(1) }}
                                                                >
                                                                    Edit {registerEvaluate ? 'Register' : 'Risk Hierarchy'} to Tranfer Excel Spread Sheet
                                                                </Button>
                                                            </div>
                                                            {
                                                                editRiskHirachy &&
                                                                <div>
                                                                    <Button
                                                                        variant='outlined'
                                                                        className={classes.btnSepateWindow}
                                                                        onClick={() => { saveRegister() }}
                                                                    >
                                                                        Update
                                                                    </Button>

                                                                </div>

                                                            }

                                                        </div>
                                                        <Grid container spacing={1} id='divHierarchicalViewId'>
                                                            <Grid item xs={6}>&nbsp;</Grid>
                                                            <Grid item xs={1}><Tooltip1 title='Likelihood'><span className='hViewTableHeading'>Likelihood</span></Tooltip1></Grid>
                                                            <Grid item xs={1}><Tooltip1 title='Impact'><span className='hViewTableHeading'>Impact</span></Tooltip1></Grid>
                                                            <Grid item xs={1}><Tooltip1 title='Risk Level'><span className='hViewTableHeading'>Risk Level</span></Tooltip1></Grid>
                                                            <Grid item xs={1}><Tooltip1 title='Action'><span className='hViewTableHeading'>Action</span></Tooltip1></Grid>
                                                            <Grid item xs={1}><Tooltip1 title='Appetite'><span className='hViewTableHeading'>Appetite</span></Tooltip1></Grid>
                                                            <Grid item xs={1}><Tooltip1 title='Action'><span className='hViewTableHeading'>Action</span></Tooltip1></Grid>
                                                            {

                                                                arrayForDisplayDataWithRole.riskTypes.map((riskType, index) => {

                                                                    return (

                                                                        <CommentRegister
                                                                            key={riskType.riskTypeTmpId}
                                                                            comment={riskType}
                                                                            lastRiskTmpId={lastRiskTmpId}
                                                                            riskNo={index + 1}
                                                                            fromMainMenu={TurnedInOutlined}
                                                                            editRiskHirachyData={editRiskHirachy}
                                                                        //lastRiskTmpId={lastRiskTmpId}
                                                                        />

                                                                    )

                                                                })


                                                            }


                                                        </Grid>
                                                    </>

                                                    : showEvalData && showTableType === 2 ?


                                                        <>

                                                            <Grid item xs={12} >
                                                                <Box >
                                                                    <FormControl size="small" sx={{ minWidth: 250 }}>
                                                                        <InputLabel id="demo-simple-select-label">Select Gradiant View</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            value={gradiantdropDown}
                                                                            label="Age"
                                                                            onChange={handleChangeGradiantdropDown}
                                                                        // style={{width:'280px'}}
                                                                        >
                                                                            <MenuItem value={1}>Both Risk Levels and Appetite</MenuItem>
                                                                            <MenuItem value={2}>Risk Level</MenuItem>
                                                                            <MenuItem value={3}>Risk Appetite</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>


                                                            <Grid container>



                                                                {
                                                                    gradiantdropDown === 2 || gradiantdropDown === 1 ?
                                                                        <>

                                                                            <Grid item xs={12} className='riskText'>
                                                                                {gradiantdropDown === 1 ? 'Risk Level' : ''}

                                                                            </Grid>

                                                                            {
                                                                                arrayForDisplayDataWithRole.riskTypes.map((head) => (
                                                                                    <>

                                                                                        <Grid item xs={12} className='registerText'>
                                                                                            {head.name}
                                                                                        </Grid>
                                                                                        <Grid item xs={6} className='lowText'>
                                                                                            Low
                                                                                        </Grid>
                                                                                        <Grid item xs={6} className='highText'>
                                                                                            High
                                                                                        </Grid>

                                                                                        <Grid item xs={12}>

                                                                                            <table style={{ backgroundImage: colorSelectDropDownRiskAppetiteTmp === 1 ? 'linear-gradient(to right, green , red)' : colorSelectDropDownRiskAppetiteTmp === 2 ? 'linear-gradient(to right, red , green)' : '', width: '100%', color: 'white', minHeight: '200px' }} border='0'>

                                                                                                <tr>
                                                                                                    {
                                                                                                        databClr.map((item, index1) => (

                                                                                                            <td style={{ verticalAlign: 'top', width: `${tdPresentage}%` }}>
                                                                                                                <table style={{ verticalAlign: 'top' }}>
                                                                                                                    {
                                                                                                                        arrayForGradiantView.map((data, index) => (
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    data.role === head.name ?
                                                                                                                                        <>
                                                                                                                                            {
                                                                                                                                                parseInt(data.riskLevel) === item.scoreNumber || (databClr.length === (index1 + 1) && parseInt(data.riskLevel) >= item.scoreNumber) ?

                                                                                                                                                    <tr>
                                                                                                                                                        <td className={gradiantdropDown === 2 ? 'resFontSize1' : 'resFontSize'} style={{ width: '5%', verticalAlign: 'top' }}><span style={{ height: '6px', width: '6px', display: 'inline-block', backgroundColor: 'white', borderRadius: '50%' }}></span></td>
                                                                                                                                                        <td className={gradiantdropDown === 2 ? 'resFontSize1' : 'resFontSize'}>{data.name}</td>
                                                                                                                                                    </tr>
                                                                                                                                                    :
                                                                                                                                                    ''
                                                                                                                                            }

                                                                                                                                        </>
                                                                                                                                        : ''
                                                                                                                                }
                                                                                                                            </>


                                                                                                                        ))
                                                                                                                    }
                                                                                                                </table>

                                                                                                            </td>
                                                                                                        ))
                                                                                                    }




                                                                                                </tr>


                                                                                            </table>
                                                                                        </Grid>
                                                                                    </>
                                                                                ))
                                                                            }


                                                                        </>

                                                                        : ''}




                                                                {
                                                                    gradiantdropDown === 3 || gradiantdropDown === 1 ?

                                                                        <>

                                                                            <Grid item xs={12} className='riskText'>
                                                                                {gradiantdropDown === 1 ? 'Risk Appetite' : ''}

                                                                            </Grid>
                                                                            {
                                                                                arrayForDisplayDataWithRole.riskTypes.map((head) => (
                                                                                    <>

                                                                                        <Grid item xs={12} className='registerText'>
                                                                                            {head.name}
                                                                                        </Grid>
                                                                                        <Grid item xs={6} className='lowText'>
                                                                                            Low
                                                                                        </Grid>
                                                                                        <Grid item xs={6} className='highText'>
                                                                                            High
                                                                                        </Grid>

                                                                                        <Grid item xs={12}>

                                                                                            <table style={{ backgroundImage: colorSelectDropDownRiskAppetiteTmp === 1 ? 'linear-gradient(to right, red , green)' : colorSelectDropDownRiskAppetiteTmp === 2 ? 'linear-gradient(to right, green , red)' : '', width: '100%', color: 'white', minHeight: '200px' }} border='0'>

                                                                                                <tr>
                                                                                                    {
                                                                                                        databClr.map((item, index1) => (

                                                                                                            <td style={{ verticalAlign: 'top', width: `${tdPresentage}%` }}>
                                                                                                                <table style={{ verticalAlign: 'top' }}>
                                                                                                                    {
                                                                                                                        arrayForGradiantView.map((data, index) => (
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    data.role === head.name ?
                                                                                                                                        <>
                                                                                                                                            {
                                                                                                                                                parseInt(data.riskAppetite) === item.scoreNumber || (databClr.length === (index1 + 1) && parseInt(data.riskAppetite) >= item.scoreNumber) ?

                                                                                                                                                    <tr>
                                                                                                                                                        <td className={gradiantdropDown === 3 ? 'resFontSize1' : 'resFontSize'} style={{ width: '5%', verticalAlign: 'top' }}><span style={{ height: '6px', width: '6px', display: 'inline-block', backgroundColor: 'white', borderRadius: '50%' }}></span></td>
                                                                                                                                                        <td className={gradiantdropDown === 3 ? 'resFontSize1' : 'resFontSize'}>{data.name}</td>
                                                                                                                                                    </tr>
                                                                                                                                                    :
                                                                                                                                                    ''
                                                                                                                                            }

                                                                                                                                        </>
                                                                                                                                        : ''
                                                                                                                                }
                                                                                                                            </>


                                                                                                                        ))
                                                                                                                    }
                                                                                                                </table>

                                                                                                            </td>
                                                                                                        ))
                                                                                                    }




                                                                                                </tr>


                                                                                            </table>
                                                                                        </Grid>
                                                                                    </>
                                                                                ))
                                                                            }

                                                                        </>

                                                                        : ''}





                                                            </Grid>

                                                        </>
                                                        :
                                                        showEvalData && showTableType === 3 ?
                                                            <>


                                                                <BarChart width={1000} height={barChartHeight} data={barData} margin={{ bottom: 50 }} layout="vertical">
                                                                    <XAxis
                                                                        type="number"
                                                                        axisLine={false}

                                                                        margin={{ top: 0, right: 40, left: 40, bottom: 20 }}
                                                                    />
                                                                    <YAxis dataKey="argument" type="category" width={400} />
                                                                    <CartesianGrid strokeDasharray="2 2" />
                                                                    <Tooltip content={<CustomTooltip />} />



                                                                    <Bar dataKey="riskLevel" fill={arrRiskColorState}>
                                                                        {barData.map((entry, index) => (
                                                                            <>
                                                                                <Cell key={`cell-${index}`} fill={arrRiskColorState[index % arrRiskColorState.length]} />
                                                                                <LabelList dataKey="riskLevelStr" content={renderCustomizedLabel} />
                                                                            </>
                                                                        ))}
                                                                    </Bar>


                                                                    <Bar dataKey="riskAppetite" fill={arrColorAppState}>
                                                                        {barData.map((entry, index) => (
                                                                            <>
                                                                                <Cell key={`cell-${index}`} fill={arrColorAppState[index % arrColorAppState.length]} />
                                                                                <LabelList dataKey="riskAppetiteStr" content={renderCustomizedLabel1} />
                                                                            </>
                                                                        ))}

                                                                    </Bar>

                                                                </BarChart>
                                                            </>

                                                            :

                                                            ''
                                            }


                                        </div>
                                    </Grid>




                                </>
                            }
                        </Grid>



                    </Grid>





                </DialogContentText>
            </DialogContent>

            {/** Dialog Waiting */}
            <Dialog
                maxWidth={'md'}
                open={waitingOpen}
                PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
            >
                <CircularProgress disableShrink />
            </Dialog>
            {/** Edit save Mitigations */}
            <Dialog
                open={openRiskGpt}
                onClose={false}
                scroll={openRiskGptScroll}
                aria-labelledby="collection-data-dialog-title"
                aria-describedby="collection-data-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
                    <Typography variant="h6" style={{ fontSize: '25px !important' }}>Edit Risk description / Impact Description / Mitigation
                    </Typography>
                    <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                        <Tooltip title="Close"><Close onClick={() => setOpenRiskGpt(false)} style={{ cursor: 'pointer' }} /></Tooltip>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={openRiskGptScroll === 'paper'}>
                    <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} noValidate autoComplete="off">
                                    <div>Risk Description</div>
                                    <textarea
                                        placeholder="Risk Description..."
                                        variant="outlined"
                                        value={riskDescriptionText}
                                        id='gptText'
                                        rows={2}
                                        style={{ width: "100%" }}
                                        onChange={handleChangeRiskDescriptionText}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} noValidate autoComplete="off">
                                    <div>Impact Description</div>
                                    <textarea
                                        placeholder="Impact Description..."
                                        variant="outlined"
                                        value={impactDescriptionText}
                                        id='gptText'
                                        rows={2}
                                        style={{ width: "100%" }}
                                        onChange={handleChangeImpactDescriptionText}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} noValidate autoComplete="off">
                                    <div>Mitigation</div>
                                    <textarea
                                        placeholder="Mitigation..."
                                        variant="outlined"
                                        value={mitigationText}
                                        id='gptText'
                                        rows={2}
                                        style={{ width: "100%" }}
                                        onChange={handleChangeMitigationText}
                                    />
                                </Box>
                            </Grid>


                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleMitigationUpdate(selectedRiskId)}
                    >
                        Update
                    </Button>
                    <Button onClick={() => setOpenRiskGpt(false)}>Close</Button>


                </DialogActions>
            </Dialog>



        </>
    )
}

export default EvaluateComponent1