
import React, {useEffect,useState} from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { Link, useLocation } from 'react-router-dom';
import Draggable from 'react-draggable'; 
import axios from 'axios';
import {CONFIG} from '../config';
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FormLabel from '@mui/material/FormLabel';
import { FormControlLabel } from '@material-ui/core';
import { FormGroup } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { Close } from '@mui/icons-material';
import RightBar from '../Components/RightBar';
import parseHtml from 'html-react-parser';
import imgdash from '../assets/risk.jpg'

const DashboardMultiUser = () => {
    const classes = useStyle();
    /** Sort abject sort array */
    function compare( a, b ) {
        var nameA = a.title.toUpperCase(); // ignore upper and lowercase
        var nameB = b.title.toUpperCase(); // ignore upper and lowercase
        if ( nameA < nameB ){
            return -1;
        }
        if ( nameA > nameB ){
            return 1;
        }
        // names must be equal
        return 0;
    }



    const location = useLocation();
    const search = location.search;
    const userRoleId = new URLSearchParams(search).get('userRoleId');
    const centreId = new URLSearchParams(search).get('centreId');
    const name = new URLSearchParams(search).get('name');
    const userId = new URLSearchParams(search).get('userId');



    const [golobalCentreId, setGolobalCentreId] = React.useState('');
    const [userName, setUserName] = React.useState('');
    const [userLoggedIn, setUserLoggedIn] = React.useState(false);
    


    
    useEffect(() => {
        //getCollection();    
        //getData();
        setSessionData();
        getLoginPageDetails()
    }, [])
    

    function getData () {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        console.log(data)
        setGolobalCentreId(data.centreId);
        getCollection()
        setUserName(data.name)
        console.log(data.name)
    }

    function setSessionData  ()  {
        let obj = {centreId:centreId, userRoleId:userRoleId, name:name, userId:userId}
        sessionStorage.setItem('sessionstorage', JSON.stringify(obj));
    }


    const [topBtnBottomZIndex, setTopBtnBottomZIndex] = React.useState(1);
    const [openCollectionDataZIndex, setOpenCollectionDataZIndex] = React.useState(1);
    const [openExistingRiskCollectionZIndex, setOpenExistingRiskCollectionZIndex] = React.useState(1);
    

    /** ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    const [allCollectionData, setAllCollectionData] = React.useState([]);
    function getCollection(){
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?centreId='+ centreId,{
          method: 'get',
        })
        .then(function (response) {
          console.log(("+++++++++++++++++++++All Collection in A Center +++++++++++++++++++++"));
          console.log((response.data));
          console.log(("+++++++++++++++++++++All Collection in A Center +++++++++++++++++++++"));
          var sortArr = response.data.sort( compare);
          //var sortArr = response.data;
          console.log((sortArr));
          findCollectionWithQuestions(sortArr);
        })
        .catch(function (error) {
        console.log(error);
        });
    }

    function findCollectionWithQuestions(collections){
        
        collections.forEach((item)=>{
            var collectionData = {};
            collectionData["id"] = item.id;
            collectionData["documentCreator"] = item.documentCreator;
            collectionData["collectionUniqueName"] = item.collectionUniqueName;
            collectionData["title"] = item.title;
            collectionData["description"] = item.description;
            collectionData["imageUrl"] = item.imageUrl;
            collectionData["status"] = item.status;
            if (item.documentCreator === "user"){
                setUserLoggedIn(true);
            }
            
            var tmpRiskArr = [];
            if (item.risks != undefined && item.risks != null && item.risks.length > 0){
              collectionData["risks"] = item.risks;
              tmpRiskArr = item.risks; 
            }
            getAllQuestions(tmpRiskArr, collectionData);
        });

    }
    var allQuesArray = [];
    function getAllQuestions(riskArray, collectionData){
        allQuesArray = [];
        riskArray.forEach((item)=>{
          if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
            item.riskTypes.forEach((item1)=>{
              if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
                item1.questions.forEach((item2)=>{
                  allQuesArray.push(item2);
                })  
              }
              if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
                getAllQuestionsInSubRisks(item1.subRisks);
              }
            });
          }
        });
        if (allQuesArray.length > 0){
          //var quesArray = allQuesArray.sort (compare);
          collectionData["questions"] = allQuesArray;
        }else{
          collectionData["questions"] = [];
        }
        allCollectionData.push(collectionData);
        setAllCollectionData(allCollectionData);
        console.log("+++++++++++++++++++++++++allCollectionData++++++++++++++++");
        console.log(allCollectionData);
        console.log("+++++++++++++++++++++++++allCollectionData++++++++++++++++");
        
      }
    
      function getAllQuestionsInSubRisks(riskArray){
        riskArray.forEach((item)=>{
          if (item.questions != undefined && item.questions != null && item.questions.length > 0){
            item.questions.forEach((item1)=>{
              allQuesArray.push(item1);
            })  
          }
          if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
            getAllQuestionsInSubRisks(item.subRisks);
          }
        });  
      }
      const [topBtnBottomPos, setTopBtnBottomPos] = useState(0);
      const [openRiskCollection, setOpenRiskCollection] = useState(false);
      function setOpenRiskCollectionFuction(flag){
          
        if (topBtnBottomZIndex > openCollectionDataZIndex) {
        }else{
            setTopBtnBottomZIndex(openCollectionDataZIndex + 1);
        }
        if (topBtnBottomZIndex > openExistingRiskCollectionZIndex) {
        }else{
            setTopBtnBottomZIndex(openExistingRiskCollectionZIndex + 1);
        }
        
    
          var topBtnId = document.getElementById("topBtnId");
          var tabId1 = document.getElementById("tabId1");
          var tabId2 = document.getElementById("tabId2");
          var pos = ((tabId1.offsetTop + 20) - (tabId2.offsetTop + topBtnId.offsetTop));
          setTopBtnBottomPos(pos);
          setOpenRiskCollection(flag);
      }

    /** ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */  

    const [riskEvalTypeOpen, setRiskEvalTypeOpen] = React.useState(false);
    const [riskEvalTypeScroll, setRiskEvalTypeScroll] = React.useState('paper');
    const [selectedRiskEvalArray, setSelectedRiskEvalArray] = React.useState([]);
    const [rsType, setRsTtpe] = useState([]);
    const [riskTypeData, setRiskTypeData] = useState([])
    
    const riskEvalTypeHandleClickClose = () => {
        setRiskEvalTypeOpen(false);
        setSelectedRiskEvalArray([]);
    };

    const [hierarchyId, setHierarchyId] = React.useState();
    const handleChangeHierarchy = (event) => {
        var hierarchyId = event.target.value;
        setHierarchyId(event.target.value)
        riskTypeData.forEach((item,index)=>{
            if (item.collectionRiskId === hierarchyId) {  
                setRsTtpe(item.riskTypes);
            }
        })
    };
   
    function checkSelected(id, name, closeIds,openIds){
        if ( document.getElementById("chkRiskType" + id).checked) {
            var tmpArray = {};
            tmpArray["collectionRiskTypeId"] = id;
            var ar = [];
            closeIds.forEach((data)=>{
                ar.push(data);
            });    
            openIds.forEach((data)=>{
                ar.push(data);
            }); 
            tmpArray["collectionQuestionIds"] = ar;
            tmpArray["name"] = name;
            selectedRiskEvalArray.push(tmpArray);
            setSelectedRiskEvalArray(selectedRiskEvalArray);
        }else{
            selectedRiskEvalArray.forEach((data, index)=>{
                if (data.collectionRiskTypeId === id) { 
                    selectedRiskEvalArray.splice(index, 1);  
                    setSelectedRiskEvalArray(selectedRiskEvalArray);
                }
            })
        }    
    }


    function getQuestionIds(comment,type){
      var count = 0;
      var ids = [];
      if (comment.questions != undefined && comment.questions!= null){
          if (type === 1){
              if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                  comment.questions.forEach((quesItem)=>{
                      if (quesItem.questionType === "Closeended"){
                          ids.push(quesItem.questionId);
                      }
                  })  
              }
          }
          if (type === 2){
              if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                  comment.questions.forEach((quesItem)=>{
                      if (quesItem.questionType === "Openended"){
                          ids.push(quesItem.questionId);
                      }
                  })  
              }
          }
      }else{
          ids = [];
      }
      return ids;
  }

  function getQuestionIdsWithSubRisks(comment,type){
      var count = 0;
      var riskIds = "";
      var ids = [];
      if (type === 1){
          if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
              comment.questions.forEach((quesItem)=>{
                  if (quesItem.questionType === "Closeended"){
                      ids.push(quesItem.questionId);
                  }
              })  
          }
      }
      if (type === 2){
          if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
              comment.questions.forEach((quesItem)=>{
                  if (quesItem.questionType === "Openended"){
                      ids.push(quesItem.questionId);
                  }
              })  
          }
      }
      if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
          return getQuestionSubIdsWithSubRisks(comment.subRisks,type,ids);
      }else{
          return ids;
      }
  }
  function getQuestionSubIdsWithSubRisks(comment,type,ids ){
      comment.forEach((data,index)=>{
          if (type === 1){
              if (data.questions != undefined && data.questions!= null){
                  if (data.questions.closeEndedQuestionIds != undefined && data.questions.closeEndedQuestionIds != null && data.questions.closeEndedQuestionIds.length > 0){
                      data.questions.closeEndedQuestionIds.forEach((item,index)=>{
                          ids.push(item.questionId);
                      });
                  }
              }
          }
          if (type === 2){
              if (data.questions != undefined && data.questions!= null){
                  if (data.questions.openEdndedQuestionIds != undefined && data.questions.openEdndedQuestionIds != null && data.questions.openEdndedQuestionIds.length > 0){
                      data.questions.openEdndedQuestionIds.forEach((item,index)=>{
                          ids.push(item.questionId);
                      });
                  }
              }
          }
          if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0){
              getQuestionSubIdsWithSubRisks(data.subRisks,type,ids);
          }
      });
      return ids;
      
  }

    
  function RiskEval({ comment }) {
      var closeEndedQuestionIds = getQuestionIds(comment,1);
      var openEndedQuestionIds = getQuestionIds(comment,2);
      var closeEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,1);
      var openEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,2);

      const [show, setShow] = React.useState(false)
      const nestedComments = (comment.subRisks || []).map(comment => {
          return <RiskEval key={comment.collectionRiskTypeId} comment={comment} type="child" />
      })

    return (
      <div style={{"marginLeft": "15px", "marginTop": "10px"}}>
           <div style={{display:'flex',textAlign:'center'}}> 
              <Button 
                  style={{width:'0px', height:'0px', marginTop:'14px'}} 
                  onClick={()=>setShow(!show)} 
                  startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  >
              </Button>
              
              <span style={{marginTop:'-7px !important'}} >

              <FormControlLabel control={<Checkbox onClick={()=>checkSelected(comment.collectionRiskTypeId,comment.name,show?closeEndedQuestionIds:closeEndedQuestionIdsWithSub,show?openEndedQuestionIds:openEndedQuestionIdsWithSub )} id={"chkRiskType" + comment.collectionRiskTypeId}/>} 
              label={show?comment.name+ (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' (' )   + (closeEndedQuestionIds.length > 0 ?' C : '
              + closeEndedQuestionIds.length:'')+ (openEndedQuestionIds.length > 0 
                 ?closeEndedQuestionIds.length > 0 ?', O : ' + openEndedQuestionIds.length:' O : ' + openEndedQuestionIds.length:'') + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' )' )
               :
               comment.name+ (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' (' )   + (closeEndedQuestionIdsWithSub.length > 0 ?' C : '
              + closeEndedQuestionIdsWithSub.length:'')+ (openEndedQuestionIdsWithSub.length > 0 
                 ?closeEndedQuestionIdsWithSub.length > 0 ?', O : ' + openEndedQuestionIdsWithSub.length:' O : ' + openEndedQuestionIdsWithSub.length:'') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' )' )   
              }
              
              />
               </span>    
           </div>
          {
              show?
                  <>
                      {nestedComments}
                  </>
              :null
          }
      </div>
      )
  }

  const [quesData, setQuesData] = useState([]);

  function setRiskTypeEvalFuction() {
    var takenFlag = true;
    var takenFlag = false;
    var tmpRiskData = [];
    var headerMsg = "";
    if (document.getElementById('chkRiskType0').checked){
        takenFlag = true;
        quesData.forEach((data)=>{
            tmpRiskData.push(data);
            takenFlag = false;
            headerMsg = "All";
        });
    }else{
        var k = 0;
        selectedRiskEvalArray.forEach((item)=>{
            if (document.getElementById('chkRiskType' + item.collectionRiskTypeId) != null && document.getElementById('chkRiskType' + item.collectionRiskTypeId).checked){
                takenFlag = true;
                item.collectionQuestionIds.forEach((quesId)=>{
                    quesData.forEach((data)=>{
                        if (data.questionId === quesId){
                            tmpRiskData.push(data);
                            takenFlag = false;
                        }
                    });
                });
                if (k === 0){
                    headerMsg =  item.name;
                }else{
                    headerMsg += ", " + item.name;
                }
                k ++;
            }
        });
    }
    if (takenFlag){
        if (takenFlag){
            alert("Please note that you don't have any Questioners for the selected Risk Types")
        }else{
            alert("Please select atleast one Risk Type for evaluation")
        }    
    }else{
        //setRiskEvalHeaderMsg(headerMsg);
        var count = 1;
        var tmpArray = [];
        setRows([]);
        tmpRiskData.forEach((item)=>{
          var tmpObj = {};
          tmpObj["questionId"] = item.questionId;
          tmpObj["collectionId"] = selectedCollectionEvaluateId;
          tmpObj["collectionUniqueName"] = selectedCollectionEvaluateUniqueName;
          tmpObj["id"] = count;
          var riskScrubStr = RiskScrumb( item.riskTypes.name, item.riskTypes.collectionRiskId, riskTypeData )
          tmpObj["riskName"] = riskScrubStr;

          tmpObj["objectiveName"] = item.objective.name;
          tmpObj["componentName"] = item.component.name;
          tmpObj["principalName"] = item.principle.name;
          tmpObj["entityName"] = item.entity.name;
          tmpObj["question"] = item.question;
          tmpObj["questionType"] = item.questionType;
          tmpObj["questionScore"] = item.questionScore;
          tmpArray.push(tmpObj);
          setRows(tmpArray);
          count ++;
        })
        setRiskEvalTypeOpen(false);
        setOpenCollectionData(true);
    }
  }
    
    function PaperComponent(props) {
      return (
        <Draggable
          handle="#draggable-dialog-title"
          cancel={'[class*="MuiDialogContent-root"]'}
        >
          <Paper {...props} />
        </Draggable>
      );
    }

    const [openCollectionData, setOpenCollectionData] = useState(false);
    const [openCollectionDataTopPos, setOpenCollectionDataTopPos] = useState(0);
    const [selectedCollectionEvaluateId, setSelectedCollectionEvaluateId] = useState('');
    const [selectedCollectionEvaluateUniqueName, setSelectedCollectionEvaluateUniqueName] = useState('');
    const [selectedCollectionEvaluateTitle, setSelectedCollectionEvaluateTitle] = useState('');

    const [rows, setRows] = useState([]);

    function setOpenCollectionDataFuction(flag, collectionId, title){
      
        if (openCollectionDataZIndex > topBtnBottomZIndex) {
        }else{
            setOpenCollectionDataZIndex(topBtnBottomZIndex + 1);
        }
        if (openCollectionDataZIndex > openExistingRiskCollectionZIndex) {
        }else{
            setOpenCollectionDataZIndex(openExistingRiskCollectionZIndex + 1);
        }

      
        var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId2 = document.getElementById("tabId2");
      var tabId3 = document.getElementById("tabId3");
      var pos = ((tabId1.offsetTop + 20) - (tabId3.offsetTop + topBtnId.offsetTop));
      setRiskTypeData([]);
      allCollectionData.forEach((data)=>{
        if (data.id === collectionId) {
          var tmpRiskArr = [];
          if (data.risks != undefined && data.risks != null && data.risks.length > 0){
            tmpRiskArr = data.risks; 
          }
          setSelectedCollectionEvaluateUniqueName(data.collectionUniqueName);
          setRiskTypeData(tmpRiskArr);
          getAllQuestionsForCollection(tmpRiskArr);
        }
      });
      setOpenCollectionDataTopPos(pos);
      setSelectedCollectionEvaluateId(collectionId);
      
      setSelectedCollectionEvaluateTitle(title);

      setRiskEvalTypeOpen(true);
      setRiskEvalTypeScroll('paper');
      setSelectedRiskEvalArray([]);

    }

  /** Get all question from the collection and put in to attay */
  var allQuesArray = [];
  function getAllQuestionsForCollection(riskArray){
    allQuesArray = [];
    riskArray.forEach((item)=>{
      if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
        item.riskTypes.forEach((item1)=>{
          if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
            item1.questions.forEach((item2)=>{
              allQuesArray.push(item2);
            })  
          }
          if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
            getAllQuestionsInSubRisksForCollection(item1.subRisks);
          }
        });
      }
    });
    if (allQuesArray.length > 0){
      //var quesArray = allQuesArray.sort (compare);
      setQuesData(allQuesArray);
    }
  }

  function getAllQuestionsInSubRisksForCollection(riskArray){
    riskArray.forEach((item)=>{
      if (item.questions != undefined && item.questions != null && item.questions.length > 0){
        item.questions.forEach((item1)=>{
          allQuesArray.push(item1);
        })  
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
        getAllQuestionsInSubRisksForCollection(item.subRisks);
      }
    });  
  }

  const [selectedQuesData, setSelectedQuesData] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [quesDataCount, setQuesDataCount] = useState(0);
  const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
  const [questionDetailScroll, setQuestionDetailScroll] = React.useState('paper');

    function parseId(no,questionId, collectionId, question){
      var tmpArray = [];
      setQuesDataCount(no);
      setSelectedQuestion(question);
      allCollectionData.forEach((data)=>{
        if (data.id === collectionId) {
          data.questions.forEach((item)=>{
            if (item.questionId === questionId) {
              tmpArray.push(item);
              setSelectedQuesData(tmpArray);
            }
          });  
        }
      });

      setQuestionDetailOpen(true);
      setQuestionDetailScroll('paper');

    }
    
    const questionDetailHandleClickClose = () => {
      setQuestionDetailOpen(false);
    };



    function RiskScrumb( riskName, collectionRiskId, riskTypeData ) {
      var rsTypeArr = [];
      riskTypeData.forEach((data)=>{
          if (data.collectionRiskId === collectionRiskId){
              rsTypeArr = data.riskTypes;
          }
      });  
      var riskScrumbPath  = "";
      rsTypeArr.forEach((item)=>{
          var pathStr = "";
          pathStr = getRiskScrumb(item,riskName);
          if (pathStr != undefined && pathStr != ""){
              riskScrumbPath = pathStr;
              return true;
          }
      });  
      return riskScrumbPath;
    }

    // *Risk Scrumb****
function getRiskScrumb(comment,riskName){
  var riskScrumbPath = "";
  if (riskName === comment.name){
      riskScrumbPath =  riskName;
  }else{
      if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
          var riskPath = comment.name;
          var subRiskPath =  getSubRiskScrumb(comment.subRisks,riskName);
          if (subRiskPath != ""){
              riskScrumbPath = riskPath + subRiskPath;
          }
      }
  }
  return riskScrumbPath;
}
function getSubRiskScrumb(subData,riskName){
  var tmpSubPath = "";
  subData.forEach((item,index)=>{
      if (item.name === riskName){
          tmpSubPath += " --> " + item.name;
          return true; 
      }else{
          if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
              var tmpSubPath1 = " --> " + item.name;
              var tmpSubSubPath1 = getSubRiskScrumb(item.subRisks,riskName);
              if (tmpSubSubPath1 != ""){
                  tmpSubPath += tmpSubPath1 + tmpSubSubPath1;
              }
          }    
      }
  });
  return tmpSubPath;
}    

  



const renderEditButton = (params) => {
    return (
        <strong>
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                    editQuestion(params.row.questionId,params.row.collectionUniqueName)
                }}
            >
                Edit
            </Button>
        </strong>
    )
}
function editQuestion(questionId, collectionUniqueName){
    let a= document.createElement('a');
    a.target= '_self';
    a.href= '/questioPage/' + collectionUniqueName + "?questionid=" + questionId;
    a.click();
}



    

    const renderDetailsButton = (params) => {
      return (
          <strong>
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                      parseId(params.row.id,params.row.questionId,params.row.collectionId,params.row.question )
                  }}
              >
                  More Info
              </Button>
          </strong>
      )
  }

  const renderTextRiskName = (params) => {
    return (
        <Tooltip title={params.row.riskName}>
        <span className={classes.fontZise10}>{params.row.riskName}</span>        
        </Tooltip>
    )
  }
  const renderTextId = (params) => {
    return (
        <span className={classes.fontZise10}>{params.row.id}</span>        
    )
  }
  const renderTextObjectiveName = (params) => {
    return (
        <Tooltip title={params.row.objectiveName}>
        <span className={classes.fontZise10}>{params.row.objectiveName}</span>        
        </Tooltip>
    )
  }
  const renderTextComponentName = (params) => {
    return (
        <Tooltip title={params.row.componentName}>
        <span className={classes.fontZise10}>{params.row.componentName}</span>        
        </Tooltip>
    )
  }
  const renderTextPrincipalName = (params) => {
    return (
        <Tooltip title={params.row.principalName}>
        <span className={classes.fontZise10}>{params.row.principalName}</span>        
        </Tooltip>
    )
  }
  const renderTextQuestion = (params) => {
    return (
        <Tooltip title={params.row.question}>
        <span className={classes.fontZise10}>{params.row.question}</span>        
        </Tooltip>
    )
  }
  const renderTextEntity = (params) => {
    return (
        <Tooltip title={params.row.entityName}>
        <span className={classes.fontZise10}>{params.row.entityName}</span>        
        </Tooltip>
    )
  }

  const renderTextQuestionType = (params) => {
    return (
        <span className={classes.fontZise10}>{params.row.questionType}</span>        
    )
  }
  const renderTextQuestionScore = (params) => {
    return (
        <span className={classes.fontZise10}>{params.row.questionScore}</span>        
    )
  }



    const columns = [
      { field: 'id', headerName: 'No', renderCell: renderTextId, resizable: true, width: 15 },
      { field: 'questionId', headerName: 'No', width: 15, hide: true },
      { field: 'collectionId', headerName: 'No', width: 15, hide: true },
      { field: 'collectionUniqueName', headerName: 'No', width: 15, hide: true },      
      { field: 'riskName', headerName: 'Risk', renderCell: renderTextRiskName, resizable: true, cellClassName: 'super-app-theme--cell', width: 180 },
      { field: 'objectiveName', headerName: 'Objective', renderCell: renderTextObjectiveName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
      { field: 'componentName', headerName: 'Component', renderCell: renderTextComponentName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
      { field: 'principalName', headerName: 'Principal', renderCell: renderTextPrincipalName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
      { field: 'entityName', headerName: 'Entity', renderCell: renderTextEntity, cellClassName: 'super-app-theme--cell', resizable: true, width: 80 },
      { field: 'question', headerName: 'Question', renderCell: renderTextQuestion, cellClassName: 'super-app-theme--cell', resizable: true, width: 200 },
      { field: 'questionType', headerName: 'Type', renderCell: renderTextQuestionType, resizable: true, width: 80 },
      { field: 'questionScore', headerName: 'Score', renderCell: renderTextQuestionScore, resizable: true, width: 50 },
      {
        field: 'action',
        headerName: 'More Info',
        sortable: false,
        renderCell: renderDetailsButton,
        disableClickEventBubbling: true,
      
      },
      {
        field: 'edit',
        headerName: 'Edit',
        sortable: false,
        renderCell: renderEditButton,
        disableClickEventBubbling: true,
      
      }
    
    ];

    





    /** ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */  

    const [openExistingRiskCollection, setOpenExistingRiskCollection] = useState(false);
    const [openExistingRiskCollectionPos, setOpenExistingRiskCollectionPos] = useState(0);
    function setOpenExistingRiskCollectionFuction(flag){

        if (openExistingRiskCollectionZIndex > openCollectionDataZIndex) {
        }else{
            setOpenExistingRiskCollectionZIndex(openCollectionDataZIndex + 1);
        }
        if (openExistingRiskCollectionZIndex > topBtnBottomZIndex) {
        }else{
            setOpenExistingRiskCollectionZIndex(topBtnBottomZIndex + 1);
        }

        var topBtnId = document.getElementById("topBtnId");
        var tabId1 = document.getElementById("tabId1");
        var tabId2 = document.getElementById("tabId2");
        var tabId4 = document.getElementById("tabId4");
        var pos = ((tabId1.offsetTop + 20) - (tabId4.offsetTop + topBtnId.offsetTop));

        setOpenExistingRiskCollectionPos(pos);
        setOpenExistingRiskCollection(flag);
    }





    /** ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */  



    const handleRiskArticle = () => {
        let a= document.createElement('a');
        a.target= '_blank';
        a.href= 'https://www.techtarget.com/searchsecurity/definition/What-is-risk-management-and-why-is-it-important';
        a.click();
    };




      // dashboard description 

  const [loading1, setLoading1] = React.useState(false);

  const [pageTopHtml, setPageTopHtml] = React.useState('');

  const [dbContentDisplay, setDbContentDisplay] = React.useState(false);

  const [loadingDisplay, setLoadingDisplay] = React.useState(true);
    
  function getLoginPageDetails(){
    setLoading1(true)

    axios(CONFIG.EXPERT_SERVER_URL + `/homePageContent/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("++++Login Page Details +++++++++++++++++");
        console.log(response.data);
        var foundCentre = false;
        response.data.forEach((item,index) => {
          if (item.centreId === centreId) {
            if (item.pageContent != undefined && item.pageContent != null){
              setDbContentDisplay(true)
              foundCentre = true;
              //alert(item.pageContent)
              var tArr = item.pageContent.split("<body>");
              if (tArr.length >1){
                var tArr1 = tArr[1].split("</body>");
                //alert(tArr1[0])
                console.log(tArr1[0].replaceAll("\\n", ''))
                setPageTopHtml(tArr1[0].replaceAll("\\n", ''));
              }
            }
          }
        })
        if (!foundCentre){
          setDbContentDisplay(true);
        }

        setLoading1(false)

      })
      .catch(function (error) {
        setLoading1(false)
        setDbContentDisplay(true);
        console.log(error);
      });
  }

  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  setTimeout(() => {

    var imgTags = document.getElementsByTagName('img');
    for (var i = 0; i < imgTags.length; i++) {
      imgTags[i].onclick = function () {
        var str = "<img src=\"" + this.src + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      };
      imgTags[i].onmouseover = function () {
        this.style.cursor = 'pointer';
      };
    }
  }, 1000);

  return (
    <div >
         <RightBar userRoleId={userRoleId} centreId={centreId} name={name}/>
         <br />
         <br />
         <Grid container spacing={2}>
            {userRoleId === "volunteer"?
                <Grid container spacing={2} className={classes.pageBodyStyleDB}>
                    <Grid item xs={12} id="tabId1">
                        <div style={{textAlign:'left', marginTop:'70px'}}>
                        <Card className={classes.cardDivDB}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div>
                                            <h3 className={classes.topHeaderText1DB}>What is risk management and why is it important?</h3>
                                            <h6 className={classes.topHeaderText2DB} onClick={handleRiskArticle}>https://www.techtarget.com/searchsecurity/definition/What-is-risk-management-and-why-is-it-important</h6>
                                        </div>
                                        <div className='mt-4'>
                                            <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Risk management is the process of identifying, assessing and controlling threats to an organization's capital and earnings. These risks stem from a variety of sources including financial uncertainties, legal liabilities, technology issues, strategic management errors, accidents and natural disasters.
                                                A successful risk management program helps an organization consider the full range of risks it faces. Risk management also examines the relationship between risks and the cascading impact they could have on an organization's strategic goals.
                                                This holistic approach to managing risk is sometimes described as enterprise risk management because of its emphasis on anticipating and understanding risk across an organization. In addition to a focus on internal and external threats, enterprise risk management (ERM) emphasizes the importance of managing positive risk. Positive risks are opportunities that could increase business value or, conversely, damage an organization if not taken. Indeed, the aim of any risk management program is not to eliminate all risk but to preserve and add to enterprise value by making smart risk decisions.
                                                "We don't manage risks so we can have no risk. We manage risks so we know which risks are worth taking, which ones will get us to our goal, which ones have enough of a payout to even take them," said Forrester Research senior analyst Alla Valente, a specialist in governance, risk and compliance.
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thus, a risk management program should be intertwined with organizational strategy. To link them, risk management leaders must first define the organization's risk appetite -- i.e., the amount of risk it is willing to accept to realize its objectives.
                                                The formidable task is to then determine "which risks fit within the organization's risk appetite and which require additional controls and actions before they are acceptable," explained Notre Dame University Senior Director of IT Mike Chapple in his article on risk appetite vs. risk tolerance. Some risks will be accepted with no further action necessary. Others will be mitigated, shared with or transferred to another party, or avoided altogether.
                                                Every organization faces the risk of unexpected, harmful events that can cost it money or cause it to close. Risks untaken can also spell trouble, as the companies disrupted by born-digital powerhouses, such as Amazon and Netflix, will attest. This guide to risk management provides a comprehensive overview of the key concepts, requirements, tools, trends and debates driving this dynamic field. Throughout, hyperlinks connect to other TechTarget articles that deliver in-depth information on the topics covered here, so readers should be sure to click on them to learn more. 
                                            </p>
                                        </div>
                                    </Grid> 
                                </Grid>
                            </CardContent>
                        </Card>
                        </div>
                    </Grid>


                </Grid>    
            : 
            

            <Grid container spacing={2} className={classes.pageBodyStyleDB}>
                

                    <Grid item xs={12} id="tabId1" style={{marginTop:'100px'}}>
                        <Card className={classes.cardDivDB}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>

                                       
                    {dbContentDisplay ?
                      <>
                      {pageTopHtml != '' ?
                        <div style={{ minHeight: '400px', marginTop:'0px'}}>
                          {parseHtml(pageTopHtml)}
                        </div>  
                      :
                      <div >
                        <div>
                          <h3 className={classes.topHeaderText1DB}>What is risk management and why is it important?</h3>

                        </div>
                        <div className='mt-4'>
                          <div >

                            <p style={{ fontSize: '15px' }}>
                              Data Management Group’s (DMG) Risk Assessment system provides a user-friendly approach to risk management.
                              It allows individuals responsible for managing risks, such as senior management, risk and compliance teams, finance teams, IT teams,
                              and the board of directors, to easily assess and mitigate risks.
                            </p>
                            <p style={{ fontSize: '15px' }}>
                              The system allows users to either ask questions about a specific risk or define a risk and its hierarchy, providing a comprehensive and flexible
                              solution for organizations of all sizes.
                            </p>
                            <p style={{ fontSize: '15px' }}>
                              With the DMG system, entities within an organization can fulfill their responsibilities in assessing and managing risks effectively and efficiently.
                            </p>
                          </div>
                          <Grid container spacing={2}>
                            <Grid item xs={1}>
                              &nbsp;
                            </Grid>
                            <Grid item xs={10}>
                              <div style={{ marginTop: '20px' }}>
                                <img src={imgdash} alt='image' style={{ width: '100%', margin: '0px auto' }} />
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              &nbsp;
                            </Grid>
                          </Grid>

                          {/* <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Risk management is the process of identifying, assessing and controlling threats to an organization's capital and earnings. These risks stem from a variety of sources including financial uncertainties, legal liabilities, technology issues, strategic management errors, accidents and natural disasters.
                                                A successful risk management program helps an organization consider the full range of risks it faces. Risk management also examines the relationship between risks and the cascading impact they could have on an organization's strategic goals.
                                                This holistic approach to managing risk is sometimes described as enterprise risk management because of its emphasis on anticipating and understanding risk across an organization. In addition to a focus on internal and external threats, enterprise risk management (ERM) emphasizes the importance of managing positive risk. Positive risks are opportunities that could increase business value or, conversely, damage an organization if not taken. Indeed, the aim of any risk management program is not to eliminate all risk but to preserve and add to enterprise value by making smart risk decisions.
                                                "We don't manage risks so we can have no risk. We manage risks so we know which risks are worth taking, which ones will get us to our goal, which ones have enough of a payout to even take them," said Forrester Research senior analyst Alla Valente, a specialist in governance, risk and compliance.
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thus, a risk management program should be intertwined with organizational strategy. To link them, risk management leaders must first define the organization's risk appetite -- i.e., the amount of risk it is willing to accept to realize its objectives.
                                                The formidable task is to then determine "which risks fit within the organization's risk appetite and which require additional controls and actions before they are acceptable," explained Notre Dame University Senior Director of IT Mike Chapple in his article on risk appetite vs. risk tolerance. Some risks will be accepted with no further action necessary. Others will be mitigated, shared with or transferred to another party, or avoided altogether.
                                                Every organization faces the risk of unexpected, harmful events that can cost it money or cause it to close. Risks untaken can also spell trouble, as the companies disrupted by born-digital powerhouses, such as Amazon and Netflix, will attest. This guide to risk management provides a comprehensive overview of the key concepts, requirements, tools, trends and debates driving this dynamic field. Throughout, hyperlinks connect to other TechTarget articles that deliver in-depth information on the topics covered here, so readers should be sure to click on them to learn more. 
                                            </p> */}
                        </div>
                      </div>

                      }
                      </>
                     :
                     ''
                    } 



                                        
                                    </Grid> 



                                    
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} id="tabId2">
                    </Grid>   
                    {
                        openRiskCollection?
                            <Draggable>
                                <Grid item xs={12} style={{marginTop: + `${topBtnBottomPos}`+ 'px', zIndex: `${topBtnBottomZIndex}`}}>
                                    <Card className={classes.cardDiv1DB}>
                                    <Typography variant="h6" style={{textAlign:'right', marginTop:'-5px', marginRight: '25px'}}><Tooltip title="Close"><Close onClick={()=>setOpenRiskCollection(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={12}>
                                              <Typography variant="h6" className={classes.cardTitleDB}>{userLoggedIn?"My Risk Collection":"Risk Collection"}</Typography>
                                            </Grid>  

                                            <Grid item xs={12}>
                                            <Grid container spacing={2}>

                                              {
                                                allCollectionData.map((collection) => {
                                                        return (
                                                          <Grid item xs={3}>
                                                          <Card className={classes.collectionCardDB}>
                                                            <CardContent className={classes.collectionCardContentDB}>
                                                                <Grid item xs={12}>
                                                                  <Typography variant="h6" className={classes.collectionCardTitleDB}>{collection.title}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                  <Typography variant="h6" className={classes.collectionCardDetailsDB}>({"Status: " + collection.status + ", Q - " + collection.questions.length})</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} className={classes.alignCenterDB}>
                                                                    <Link id={collection.id} to={`/editcollection/${collection.collectionUniqueName}`}>
                                                                        <Button variant="outlined" className={classes.btnBorderAshDB}
                                                                        >
                                                                        Edit
                                                                        </Button>&nbsp;&nbsp;
                                                                    </Link>   
                                                                    <Button variant="outlined" className={classes.btnBorderAshDB}
                                                                      onClick={e => setOpenCollectionDataFuction(true,collection.id,collection.title)} 
                                                                      >
                                                                      Evaluate
                                                                    </Button>
                                                                </Grid>
                                                                {userLoggedIn?
                                                                    <Grid item xs={12} className={classes.alignCenterDB}>
                                                                        <Link id={collection.id} to={`/questionManagementExpert/${collection.collectionUniqueName}_expert`}>
                                                                            <Button variant="outlined" className={classes.btnBorderAshDBTop3}
                                                                            >
                                                                            Expert Evaluation
                                                                            </Button>
                                                                        </Link>
                                                                    </Grid>
                                                                    :
                                                                    ''
                                                                }  
                                                            </CardContent>
                                                          </Card>
                                                          </Grid>      

                                                        )
                                                      })
                                                  }
                                                </Grid>


                                            </Grid>  
                                          </Grid> 
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Draggable>
                        :null
                    }
                    <Grid item xs={12} id="tabId3">
                    </Grid>  

                    {
                        openCollectionData?
                            <Draggable>
                                <Grid item xs={12} style={{marginTop: + `${openCollectionDataTopPos}`+ 'px', zIndex: `${openCollectionDataZIndex}`}}>
                                    <Card className={classes.cardDiv2DB}>
                                    <Typography variant="h6" style={{textAlign:'right', marginTop:'-5px', marginRight: '25px'}}><Tooltip title="Close"><Close onClick={()=>setOpenCollectionData(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={12}>
                                              <Typography variant="h6" className={classes.cardTitleDB}>{selectedCollectionEvaluateTitle}</Typography>
                                            </Grid>  

                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>

                                                <Box    
                                                sx={{
        height: 300,
        width: '100%',
        '& .super-app-theme--cell': {
          whiteSpace: 'normal !important',
          lineHeight: '1',
        },
        '& .super-app-theme--Open': {
            fontSize: '10px !important',
          }, 
  
    }}
    >





                                                    <div style={{ height: 400, width: '100%',backgroundColor: '#ffffff', fontSize: '9px !important' }}>
                                                    <DataGrid
                                                        rows={rows}
                                                        columns={columns}
                                                        pageSize={5}
                                                        rowsPerPageOptions={[5]}
                                                        getRowClassName={`super-app-theme--Open`}
                                                        disableSelectionOnClick
                                                    />
                                                    </div>

                                                    </Box>
                                                </Grid>
                                            </Grid>  
                                          </Grid> 
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Draggable>
                        :null
                    }

                    <Grid item xs={12} id="tabId4">
                    </Grid>  

                    {
                        openExistingRiskCollection?
                            <Draggable>
                                <Grid item xs={12} style={{marginTop: + `${openExistingRiskCollectionPos}`+ 'px', zIndex: `${openExistingRiskCollectionZIndex}`}}>
                                    <Card className={classes.cardDiv3DB}>
                                    <Typography variant="h6" style={{textAlign:'right', marginTop:'-5px', marginRight: '25px'}}><Tooltip title="Close"><Close onClick={()=>setOpenExistingRiskCollection(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={12}>
                                              <Typography variant="h6" className={classes.cardTitleDB}>Existing Risk Collection</Typography>
                                            </Grid>  

                                            <Grid item xs={12}>
                                            <Grid container spacing={2}>

                                              {
                                                allCollectionData.map((collection) => {
                                                        return (
                                                          <Grid item xs={3}>
                                                          <Card className={classes.collectionCardDB}>
                                                            <CardContent className={classes.collectionCardContentDB}>
                                                                <Grid item xs={12}>
                                                                  <Typography variant="h6" className={classes.collectionCardTitleDB}>{collection.title}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                  <Typography variant="h6" className={classes.collectionCardDetailsDB}>({"Status: " + collection.status + ", Q - " + collection.questions.length})</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} className={classes.alignCenterDB}>
                                                                    <Link id={collection.id} to={`/editcollection/${collection.collectionUniqueName}`}>
                                                                    <Button variant="outlined" className={classes.btnBorderAshDB}
                                                                      >
                                                                      Edit
                                                                    </Button>&nbsp;&nbsp;
                                                                    </Link>
                                                                    <Button variant="outlined" className={classes.btnBorderAshDB}
                                                                      onClick={e => setOpenCollectionDataFuction(true,collection.id,collection.title)} 
                                                                      >
                                                                      Evaluate
                                                                    </Button>
                                                                </Grid>  
                                                            </CardContent>
                                                          </Card>
                                                          </Grid>      

                                                        )
                                                      })
                                                  }
                                                </Grid>


                                            </Grid>  
                                          </Grid> 
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Draggable>
                        :null
                    }

                </Grid>


            }

            </Grid>

    {/** Question Details Show Dialog */}
    <Dialog
          maxWidth='xs'
          open={questionDetailOpen}
          onClose={questionDetailHandleClickClose}
          scroll={questionDetailScroll}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Typography variant="h6" gutterBottom component="div">
                    Question No - {quesDataCount}
                </Typography>
                <Typography component="th" scope="row" style={{width: '100%', fontSize: '10px !important'}}>
                    <span style={{fontWeight: '550'}}>Question - </span>{selectedQuestion}
                </Typography>
          </DialogTitle>
          <DialogContent dividers={questionDetailScroll === 'paper'}>
            <DialogContentText
              id="risk-eval-type-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                {selectedQuesData.map((data) => {
                  return (
                    <Grid item xs={12}>
                      <Box >
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Detail Weightings
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                        <Typography variant="h6" component="div">
                                            Score
                                        </Typography>
                                    </TableCell>
                                </TableRow>    
                            </TableBody>
                        </Table>
                        <Table size="small" aria-label="purchases">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                        Questionnaire weighting
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                    {data.questionScore}
                                    </TableCell>
                                </TableRow>    
                            </TableBody>
                        </Table>
                        {data.questionType === "Closeended" && (
                            <>
                            <Table size="small" aria-label="purchases">
                                <TableBody>        
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                Answers
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                        &nbsp;
                                        </TableCell>
                                    </TableRow>  
                                </TableBody>
                            </Table>        
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                        return (
                                            <>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                                        <span >{item.answerName}</span>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                        {item.riskLevel}
                                                    </TableCell>
                                                </TableRow>    
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            </> 
                        )}
                        <Table size="small" aria-label="purchases">
                            <TableBody>        
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Model Answer(s)
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                    &nbsp;
                                    </TableCell>
                                </TableRow>  
                            </TableBody>
                        </Table>
                        {data.questionType === "Closeended" && (
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                        if (item.expectedAnswerName != ""){
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                                            <span>
                                                                {item.answerName}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                            {item.expectedAnswerName === "Yes" || item.expectedAnswerName === "checked"? item.riskLevel:item.expectedAnswerName}
                                                        </TableCell>
                                                    </TableRow>    
                                                </>
                                            )
                                        }    
                                    })}
                                </TableBody>
                            </Table>
                        )}
                        {data.questionType === "Openended" && (
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                            <span>
                                                {data.answers.answer}
                                            </span>
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                            &nbsp;
                                        </TableCell>
                                    </TableRow>    
                                </TableBody>
                            </Table>
                        )}      
                      </Box>
                    </Grid>     
                  )
                })} 
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={questionDetailHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Question Details Show Dialog*/}

    {/** Risk Evaluate Options */}
    <Dialog
          maxWidth='md'
          open={riskEvalTypeOpen}
          onClose={riskEvalTypeHandleClickClose}
          scroll={riskEvalTypeScroll}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Select "All" or other Risk Type to Evaluate Risk <br></br>for {selectedCollectionEvaluateTitle}</DialogTitle>
          <DialogContent dividers={riskEvalTypeScroll === 'paper'}>
            <DialogContentText
              id="risk-eval-type-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel id="radio-buttons-group-label">Pick your Risk Type</FormLabel>
                </Grid>
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                    <FormGroup>
                        <FormControlLabel className={classes.marginleftEval} control={<Checkbox id={"chkRiskType0"} />} label="All" />
                        <Box sx={{ minWidth: 120}}>    
                            <FormControl fullWidth>
                                <TextField
                                    label="Select a Risk Hierarchy"
                                    sx={{ width: '100%' }}
                                    select
                                    value={hierarchyId}
                                    onChange={handleChangeHierarchy}
                                    >
                                    {riskTypeData.map((item)=>(
                                        <MenuItem 
                                            value={item.collectionRiskId}
                                            >
                                            {item.name}
                                        </MenuItem>
                                    ))}

                                </TextField>
                            </FormControl>
                        </Box>

                        {
                            rsType.map((risktypp) => {
                                return (
                                    <RiskEval 
                                        key={risktypp.collectionRiskTypeId} 
                                        comment={risktypp} 
                                    />
                                )
                            })
                        }



                        {/*mainRiskArr.map((item)=>{
                            return(
                                <FormControlLabel control={<Checkbox id={"chkRiskType" + item.riskTypeId}/>} label={item.name} />
                            )
                        })*/}
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    &nbsp;
                </Grid>
                <Grid item xs={12}>
                    <Button 
                        variant="outlined"
                        id="riskEvaluateBtn" 
                        className={classes.btnBorderRedEval}
                        style={{float: 'right'}}
                        //onClick={()=>setOpenRiskEvalFuction(!openRiskEval)}
                        onClick={()=>setRiskTypeEvalFuction()}
                        >
                        Confirm
                    </Button>     
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={riskEvalTypeHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Risk Evaluation Options*/}



      <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>



    </div>
  )
}

export default DashboardMultiUser