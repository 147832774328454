import React, {useEffect,useState} from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {CONFIG} from '../config';
import axios from 'axios';
import classNames from 'classnames';
import useStyle from '../Css/MainCss';
import {dispatchLogout} from "../actions/sign-out";
import { Link, useLocation } from 'react-router-dom';



const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));


const RightBarWithoutUserRole = ({centreId,name}) => {
    const classes = useStyle();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

      const [menuItem, setMenuItem] = useState([]);

      const [userName, setUserName] = useState()

      useEffect(() => {
      }, [])
    
    
      
      function handleLogoutBtnClick() {
        dispatchLogout();
      };

      function handleLogoutBtnlCickAbout(){
        let a= document.createElement('a');
        a.target= '_blank';
        a.href= '/aboutus';
        a.click();
      };
      function handleLogoutBtnlCickServices(){
        let a= document.createElement('a');
        a.target= '_blank';
        a.href= '/ourservices';
        a.click();
      }
      function handleOtherServiceClick(url){
        let a= document.createElement('a');
        a.target= '_self';
        a.href= url;
        a.click();
      }



  return (
    <div>
        
        <Box sx={{ display: 'flex'}}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{backgroundColor:'white', height:'100px' }}>
        <Toolbar style={{display:'flex',justifyContent:'space-between', color:'black'}}>
            <div >
              <Typography variant="h6"  component="div">
                Risk Management System
              </Typography>
            </div>
            <div style={{textAlign:'center',marginTop:'10px'}}>
              <Typography variant="h6"  component="div" >
                Data Management Group
              </Typography>
              <Typography variant="h6"  component="div" className={classes.firstphaHeader} style={{fontFamily:'cursive'}} >
                Select Your Login Type
              </Typography>
            </div>
            <div style={{textAlign:'center',marginTop:'10px'}}>
              <Typography variant="h6"  component="div" className={classes.fontZise11}>
                Welcome {name}
              </Typography>
              <Typography variant="h6"  component="div" >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  sx={{ ...(open && { display: 'none' }) }}
                  >
                  <MenuIcon />
                </IconButton>
              </Typography>
            </div>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
            <ListItem  disablePadding >
              <ListItemButton onClick={handleLogoutBtnlCickAbout}>
                <ListItemText >
                    About Us
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem  disablePadding >
              <ListItemButton onClick={handleLogoutBtnlCickServices}>
                <ListItemText >
                  Our Services
                </ListItemText>
              </ListItemButton>
            </ListItem>
            
           <ListItem  disablePadding >
              <ListItemButton onClick={handleLogoutBtnClick}>
                <ListItemText >
                    Logout
                </ListItemText>
              </ListItemButton>
            </ListItem>
        </List>
        
      </Drawer>
    </Box>
    </div>
  )
}

export default RightBarWithoutUserRole