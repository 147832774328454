import React, {useEffect, useState} from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import { tableCellClasses } from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Links from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { textAlign } from '@mui/system';
import {CONFIG} from '../config'
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Circle, CompressOutlined, Info, KeyboardReturnOutlined } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Draggable from 'react-draggable';
import useStyle from '../Css/MainCss';
import  "../Css/WeightingAnswers.css";




function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({ 
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageBtn:{

    height: '60px',
    width: '170px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '12px !important',
  },
  headerLable:{

    color: '#827d7d',
  },
  pageTop:{
    marginTop: '115px',
    marginLeft:'20px'
  },
  pageBodyStyle: {
      paddingTop: '12px',

   
  },
  root: {
    position: 'relative',
  },
  posRelative: {
    position: 'relative',
  },
  topImagePos: {
    position: 'absolute', 
    top: '33%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  topHeaderText1: {
    color: '#9155FD',
    fontSize:'25px', 
    paddingBottom:'10px',
  },
  topHeaderText2: {
    color: '#3A3541',
    fontSize:'12px', 
    textAlign:'center',
    fontFamily: 'Inter',
  },
  thStyle: {
    fontSize: '12px !important',
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important'
  },
  thStyleImage: {
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important',
    width: '130px !important',
  },
  btnColorPurple:{
    fontSize: '12px !important',
    backgroundColor: '#9155FD !important',
    height:'40px',
    textAlign:'center'
  },
  riskImageSize: {
    width: '120px',
    height: '100px !important',
  },
  tableStyle: {
    width: '55% !important',
    minWidth:'720px !important'
  },
  btnBorderGreen: {
    border: '1px solid #0e640e !important',
    color: '#0e640e !important',
    height:'40px'
  },
  btnclear:{
    height:'40px'
  },
  btnBorderAsh: {
    border: '1px solid #8a8181 !important',
    color: '#8a8181 !important',
  },
  btnBorderRed: {
    border: '1px solid #ff0000 !important',
    color: '#ff0000 !important',
  },
  qusCard: {
    width: '100% !important',
  },
  qusCardHeader: {
    backgroundColor: '#ece9fa !important',
  },
  subText: {
    fontSize: '12px !important',
  },
  btnPadding: {
    paddingLeft:'2px !important',
    paddingRight:'2px !important',
  },
  cardBodyH6: {
    fontSize: '14px !important',
  },
  cardBodyH6Ans: {
    fontSize: '13px',
    paddingLeft: '12px',
  },
  addBodyPlus: {
    fontSize: '146px !important',
    fontWeight: '1000 !important',
    textAlign: 'center !important',
    color: '#3A3541 !important',
  },
  posRelativeAndDivBorder: {
    position: 'relative',
    border: '1px solid #9155FD', 
    width: '120px',
    height: '100px !important',
  },
  hightrachytitle:{
    textAlign:'left',
    color:'#3A3541',
    
  },
  btnBorderpurple:{
    border: '1px solid #9155FD !important',
    color: '#9155FD !important',
    padding:'5px 10px !important',
    height: '40px !important',
  },
  hightrachytitlestyle:{
    fontSize:'14px !important',
    fontWeight:500
  },
  modalbox:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 14,
    padding: 10,
  },
  addriskbtn:{
    color:'#9155FD !important',
    border:'1px solid #9155FD !important',
    marginTop:'20px !important'
  },
  risktytext:{
    width:'350px',

  },
  canclbtn:{
    alignItem:'left !important' ,
    justifyContent: 'end'
  },
  cardRiskManage:{
      width:'100%',
      border:'1px solid black'
  },
  scrollableDiv: {
    height: '150px',
    overflow: 'auto',
    width: '100%',
  },
  tableHeadStyle: {
    backgroundColor: '#d5d5e1',
    color: '#ffffff',
  },
  tableThStyle: {
    lineHeight: '0.25rem !important'
  },
  tableTrStyle: {
    //lineHeight: '0.20rem !important',
    height: '19px !important'
  },
  mousePointer: {
    cursor: 'pointer'
  },
  scrollableDiv: {
    height: '250px',
    overflow: 'auto',
    width: '100%',
  },
  stdMainCardHeader:{
    backgroundColor:'#acacbe !important',
    fontSize:'13px !important',
    height:'50px',
    padding:'0px',
    marginTop: '-7px',
  },
  addrisktypebtn:{
    marginLeft:'10px !important',
    border: '1px solid #0e640e !important',
    color: '#0e640e !important',
    padding:'5px 10px !important',
    height: '40px !important',
  },

  btnBorderpurple:{
    border: '1px solid #9155FD !important',
    color: '#9155FD !important',
    padding:'5px 10px !important',
    height: '40px !important',
  },
  stdCard:{
    height:'100%',
    border:'1px solid black'
  },
  scrollableDivToStandard: {
    height: '350px',
    overflow: 'auto',
    width: '100%',
  },
  focusBorder:{
    border: '2px solid red !important',
  },
  timeOutBorder:{
    border: '0px solid red !important',
  },

}));

function WeightingAnswers() {
  const classe = useStyle();
    const [clrCha, setClrCha] = React.useState('');

    const handleChangeClr = (event) => {
         setClrCha(event.target.value);
    };
  
    function setFocusStyle(varName){
      varName(true);
      setTimeout(()=> {
          varName(false);
          setTimeout(()=> {
              varName(true);
              setTimeout(()=> {
                  varName(false);
                  setTimeout(()=> {
                      varName(true);
                      setTimeout(()=> {
                          varName(false);
                      }, 500);
                  }, 500);
              }, 500);
          }, 500);
      }, 1000);
  
  }


    //tAb
  
    const [riskCategoryId, setRiskCategoryid] = useState()
  
    const [getRiskCategoryId,   setgetRiskCategoryId] = useState(false)
  
    function getAllRisksdatas(){
      axios(CONFIG.MAIN_URL+"/answer-category/all",{
        method: 'get',
        headers: { 
            'Content-Type': 'text/plain', 
          },     
        })
        .then(function (response) {
          console.log("All Risk Returns");
          console.log(response.data[0].id);
          setRiskCategoryid(response.data[0].id)
          setgetRiskCategoryId(true)
        

          
        })
        .catch(function (error) {
        console.log(error);
        });
    }
  
  
  
    function getAllRisks(){
      axios(CONFIG.MAIN_URL+"/answer-category/all",{
        method: 'get',
        headers: { 
            'Content-Type': 'text/plain', 
          },     
        })
        .then(function (response) {
         
            console.log("All Risk Returns");
            console.log(response.data[0]);
            setNewData(response.data)
           console.log(response.data);
            setRiskTypeName(response.data[0].methods)
           
            setCategoryid(response.data[0].categoryid);
           
            setSliderId(response.data[0].id)
          
         
  
          
        })
        .catch(function (error) {
        console.log(error);
        });
    }

  
  
      const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  
  const [riskTypeDa, setRiskTypeDa] = useState('')
  const [riskTypeMinNumber, setRiskTypeminNumber] = useState('')
  const [riskTypeMaxNumber, setRiskTypeMaxNumber] = useState('')

  const [riskMethodName, setRiskMethodName] = useState('');
  
  function handleClickOpen (id,maxNum,minNum,methName)  {

     setRiskTypeDa(id)
     console.log(id)
     setOpen(true);
     setRiskTypeminNumber(minNum)
     setRiskTypeMaxNumber(maxNum)
     setRiskMethodName(methName);

    riskTypeName.forEach((method)=> {
      console.log(method)
      if (method.id === id) {
        if(method.riskTypes.length === 0){
          setStartingNumber(minNum);
        }
        else if(method.riskTypes.length > 0){
          riskTypeName.forEach((metho)=>{
            if (metho.id === id) {
              var a = metho.riskTypes.slice(-1)[0] 
              if(a.endingNumber != maxNum){
                setStartingNumber(a.endingNumber + 1)
              }else{
                setStartingNumber('')
              }
              

            }

          })
          
           
        }
       
      }
    })
    
  };

  function setValuesToStratingNumAfterSavingOneRiskType() {
    riskTypeName.forEach((metho)=>{
      console.log(metho)
      if (metho.id === riskTypeDa) {
        console.log(metho)
        var a = metho.riskTypes.slice(-1)[0] 
        if(a.endingNumber != riskTypeMaxNumber){
          setStartingNumber(a.endingNumber + 1)
        }else{
          setStartingNumber('')
        }
        

      }

    })
  }
  
  const handleClose = () => {
    setRiskTypeNameForPop('');
    setStartingNumber('')
    setEndingNumber('')
    setOpen(false);
    setRiskTypeminNumber('')
    setRiskTypeMaxNumber('')
    setSaveBtnForAddMethod("+ Add Method");
    setsaveBtnForAddRiskTypes("+ Add Risk type");
  };
  
  
      const classes = useStyles();
      const Input = styled('input')({
         display: 'none',
      });
    
      const [golobalCentreId, setGolobalCentreId] = React.useState('');
    
    
      const [selectedFile, setSelectedFile] = useState(null)
      const [preview, setPreview] = React.useState()
    
      // create a preview as a side effect, whenever selected file is changed
      React.useEffect(() => {
          getData();
          
          if (!selectedFile) {
              setPreview(undefined)
              return
          }
          const objectUrl = URL.createObjectURL(selectedFile)
          setPreview(objectUrl)
          // free memory when ever this component is unmounted
          return () => URL.revokeObjectURL(objectUrl)
      }, [selectedFile])
    
      function getData () {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        setGolobalCentreId(data.centreId);
        getAllRisksdatas()
        getAllRisks();
        
        getColors();
        console.log(data)
      }
  
      const [databClr, setDatabClr] = useState([])
      const [colorPatterns, setColorPatterns] = useState([])
  
      function getColors(){
        axios(CONFIG.MAIN_URL+"/color/all",{
          method: 'get',
          headers: { 
              'Content-Type': 'text/plain', 
            },     
          })
          .then(function (response) {
            console.log("All colors");
            console.log(response.data);
  
            setColorPatterns(response.data)
  
           
          })
          .catch(function (error) {
          console.log(error);
          });
      }
    
      const onSelectFile = e => {
          if (!e.target.files || e.target.files.length === 0) {
              setSelectedFile(undefined)
              return
          }
          // I've kept this example simple by using the first image instead of multiple
          setSelectedFile(e.target.files[0])
          
      }
     // console.log(selectedFile)
      const [saveBtnName, setSaveBtnName] = useState("Save");
      const [saveBtnNameForRisk, setSaveBtnNameForRisk] = useState("Save Risk");
      const [pageBannerName, setPageBannerName] = useState("New Questionnaire Group");
      const [editGroup, setEditGroup] = useState(false);
      const [showquesbtn, setShowquesbtn] = useState();
  
  
      const [riskIdvalue, setRiskIdvalue] = React.useState(0);
      const [riskTypeIdvalue, setRiskTypeIdvalue] = React.useState(0);
      const [sliderColorvalue, setSliderColorvalue] = React.useState(0);
  
      ///////////////////////////////////////////////////////////////////////////////
  
      const [riskName, setRiskName] = useState('')
      const [sliderColor, setSliderColor] = useState('')
      const [minNumber, setMinNumber] = useState('')
      const [maxNumber, setMaxNumber] = useState('')
      const [methodDescri, setMethodDescri] = useState('')
  
  
      const [riskTypeNameForPop, setRiskTypeNameForPop] = useState('')
  
      const [startingNumber, setStartingNumber] = useState('')
  
      const [endingNumber, setEndingNumber] = useState('')
  
      const [scoreNumber, setScoreNumber] = useState()
  
      const [colorDropDown, setColorDropDown] = useState('')
      
 
      
      const onTextFieldChange = (event) => {
        setRiskName(event.target.value);
            
      }
  
      const onTextFieldChangeForMinNumber = (event) => {
        const value = event.target.value;
        return (Number.isInteger(+value) || value === '-') ? setMinNumber(value) : value.slice(0,-1);
      }
      const onTextFieldChangeForMaxNumber = (event) => {
        const value = event.target.value;
        return (Number.isInteger(+value) || value === '-') ? setMaxNumber(value) : value.slice(0,-1);
      }
  
        const onTextFieldChangeForMethodDescri = (event) => {
          setMethodDescri(event.target.value);
              
        }
    
  
        const onTextFieldChangeRiskTypeName = (event) => {
          setRiskTypeNameForPop(event.target.value);
              
        }
  
        const onTextFieldChangeForstartingNumber = (event) => {
          const value = event.target.value;
          return (Number.isInteger(+value) || value === '-') ? setStartingNumber(value) : value.slice(0,-1);
              
        }
  
        const onTextFieldChangeForEndingNumber = (event) => {
          const value = event.target.value;
          return (Number.isInteger(+value) || value === '-') ? setEndingNumber(value) : value.slice(0,-1);
        }
        
        const [colorPatternId, setColorPatternId] = useState('')
        const [colorPatternName, setColorPatternName] = useState('')

        const handleChangeColorPattern = (event) => {
          setColorPatternId(event.target.value);
          colorPatterns.forEach((data,index)=>{
              if (data.id === event.target.value) {   
                setColorPatternName(data.name); 
              }
          })
        };
  
        const scoreNumberChange = (event) => {
          setScoreNumber(event.target.value);
              
        }
  
        const colorChangeDropDown = (event) => {
          setColorDropDown(event.target.value);
          
              
        }

    
      function clearFuction() {
        setRiskName("");
        setPreview("");
        setMinNumber("")
        setMaxNumber("")
        setMethodDescri("")
        setSliderColor("")
        setSaveBtnName("Save");
        setEditGroup(false);
        setSaveRiskFlag(false);
        setRiskTypeData({});
        setRiskIdvalue(0);
        setSliderColorvalue(0)
        setShowSaveBtn(false);
        setCreateHeaderInfoMessage("Add and Edit Slider Colors");
        setSaveBtnNameForRisk("Save Risk");
        setriskDataEditId('')
        setSaveBtnForAddMethod('+ add method')
        setColorPatternId('');
        setColorPatternName('');
      }
    
      const [showSaveBtn, setShowSaveBtn] = React.useState(false)
      const [riskTypeData, setRiskTypeData] = React.useState({});
      const [createheaderInfoMessage, setCreateHeaderInfoMessage] = useState("Add and Edit Slider Colors");
    
  
  
         /** End of Create mainRisk */
    
         /** Save Risk */
    
        const [saveRiskFlag, setSaveRiskFlag] = React.useState(false);
  
        function saveRisk(){
            console.log(riskTypeDa)

            if (riskName === '') {
              alert("Method Name can't be empty")
              return
            }
            if (minNumber === '') {
              alert("Minimum Number can't be empty")
              return
            }
            if (maxNumber === '') {
              alert("Maximum Number can't be empty")
              return
            }

            if (methodDescri === '') {
              alert("Method Description can't be empty")
              return
            }
            if (colorPatternId === '') {
              alert("Please select color pattern")
              return
            }
            

  
            var id = sliderId;
  
            var objCategory = {};
            var tmpMethodArr = [];
            
    
            objCategory["id"] = sliderId;
            objCategory["categoryName"] = categoryName;
            objCategory["description"] = description;
            if (minNumber > maxNumber){
              alert("Minumn number cannot exceed Maximun number")
              return;
            }
    
            var tmpRiskTypesArray = [] 
            if(RiskDataEditId != ''){
                riskTypeName.forEach((item)=>{ 
                    if(item.id === RiskDataEditId){
                        console.log(RiskDataEditId)
                        console.log(item.id)
                        var objMthods = {}
                        objMthods["id"] = item.id;
                        objMthods["methodName"] = riskName;
                        objMthods["minimumWeightingNumber"] = minNumber;
                        objMthods["maximumWeightingNumber"] = maxNumber;
                        objMthods["methodDescription"] = methodDescri;
                        objMthods["colorPatternId"] = colorPatternId;
                        objMthods["colorPatternName"] = colorPatternName;

                        var tempColorArray = []
                        item.riskTypes.forEach((data)=> {
                            var objRiskTypes = {}
                            objRiskTypes["id"] = data.id;
                            objRiskTypes["riskTypesName"] = data.riskTypesName;
                            objRiskTypes["startingNumber"] = data.startingNumber;
                            objRiskTypes["endingNumber"] = data.endingNumber;


                            data.colorDataList.forEach((data2)=> {
                                var objColors = {}
                                objColors["id"] = data2.id
                                objColors["colorId"] = data2.colorId;
                                objColors["scoreNumber"] = data2.scoreNumber;
                                objColors["color"] = data2.color;
                                objColors["secondaryColor"] = data2.secondaryColor;
                                objColors["primaryColorName"] = data2.primaryColorName;
                                objColors["secondaryColorName"] = data2.secondaryColorName;
                                tempColorArray.push(objColors)
                            })
                            objRiskTypes["colorDataList"] = tempColorArray;
                            tmpRiskTypesArray.push(objRiskTypes)

                        })
                        objMthods["riskTypes"] = tmpRiskTypesArray;
    
                        tmpMethodArr.push(objMthods)
                    }
                    else{
                        tmpMethodArr.push(item)
                    }

                })
            }else{
                riskTypeName.forEach((item)=>{ 
                    tmpMethodArr.push(item)
                })
                    var objMthods = {}
                    objMthods["methodName"] = riskName;
                    objMthods["minimumWeightingNumber"] = minNumber;
                    objMthods["maximumWeightingNumber"] = maxNumber;
                    objMthods["methodDescription"] = methodDescri;
                    objMthods["colorPatternId"] = colorPatternId;
                    objMthods["colorPatternName"] = colorPatternName;


                    tmpMethodArr.push(objMthods)


            }

            objCategory["methods"] = tmpMethodArr;
     
  
  
            var data = JSON.stringify(objCategory);
                
            console.log(data);
            var url = '';
            if(RiskDataEditId != ''){
              url = CONFIG.MAIN_URL+'/answer-category/update';
            }else{
              url = CONFIG.MAIN_URL+'/answer-category/create';
            }  

            axios(url,{
                method: 'post',
                headers: { 
                    'Content-Type': 'application/json',
                  },
                data : data,  
            })
            .then(function (response) {
                
                if (riskIdvalue != 0){
                  alert("Method Updated successfully");
                }else{
                  alert("Method saved successfully");
                } 
               
                setRiskTypeData(response.data);
                console.log(JSON.stringify(riskTypeData));
                setSaveRiskFlag(true);
                clearFuction();
                getAllRisks();
                setRiskIdvalue(0)
                setSliderColorvalue(0)
    
            })
            .catch(function (error) {
                console.log(error);
                alert("Error saving Risk Type -:" + error);
          });
    
        }
  
 
        const [saveBtnForAddMethod, setSaveBtnForAddMethod] = useState("+ Add Method");
        const [saveBtnForAddRiskTypes, setsaveBtnForAddRiskTypes] = useState("+ Add Risk type");
  
  
  
        function saveRiskType(){

          if (riskTypeNameForPop === '') {
            alert("Risk Type Name can't be empty")
            return
          }
          if (startingNumber === '') {
            alert("Starting Number can't be empty")
            return
          }
          if (endingNumber === '') {
            alert("Ending Number can't be empty")
            return
          }
          if (startingNumber > endingNumber){
            alert("Starting Number can't be greater than ending number")
            return
          }
          

          var canTake = true;
          riskTypeName.forEach((methodData)=>{
            if (methodData.id === riskTypeDa) {
              console.log(methodData)
              if (rtId != '') {
                methodData.riskTypes.forEach((data)=>{
                  if (rtId !=data.id){
                    if (parseInt(data.startingNumber) === parseInt(startingNumber) || (parseInt(data.startingNumber) < parseInt(startingNumber) && parseInt(data.endingNumber) > parseInt(startingNumber)) || parseInt(data.endingNumber) === parseInt(startingNumber)){
                      canTake = false;
                    }
                    if (parseInt(data.startingNumber) === parseInt(endingNumber) || (parseInt(data.startingNumber) < parseInt(endingNumber) && parseInt(data.endingNumber) > parseInt(endingNumber)) || parseInt(data.endingNumber) === parseInt(endingNumber)){
                      canTake = false;
                    }
                  }
                })
              }else{
                methodData.riskTypes.forEach((data)=>{
                  if (parseInt(data.startingNumber) === parseInt(startingNumber) || (parseInt(data.startingNumber) < parseInt(startingNumber) && parseInt(data.endingNumber) > parseInt(startingNumber)) || parseInt(data.endingNumber) === parseInt(startingNumber)){
                    canTake = false;
                  }
                  if (parseInt(data.startingNumber) === parseInt(endingNumber) || (parseInt(data.startingNumber) < parseInt(endingNumber) && parseInt(data.endingNumber) > parseInt(endingNumber)) || parseInt(data.endingNumber) === parseInt(endingNumber)){
                    canTake = false;
                  }
                })
              }  
            }
          })
          if (!canTake){
            alert("Invalid starting or ending number")
            return
          }

  
            var id = sliderId;
  
            var objCategory = {};
            var tmpMethodArr = [];
            
    
            objCategory["id"] = sliderId;
            objCategory["categoryName"] = categoryName;
            objCategory["description"] = description;
    
            riskTypeName.forEach((item)=>{
    
                 var tmpRiskTypesArray = [] 	
              if (item.id === riskTypeDa){
                var objMthods= {};	  
                objMthods["id"] = item.id;
                objMthods["methodName"] = item.methodName;
                objMthods["minimumWeightingNumber"] = item.minimumWeightingNumber;
                objMthods["maximumWeightingNumber"] = item.maximumWeightingNumber;
                objMthods["methodDescription"] = item.methodDescription;
                objMthods["colorPatternId"] = item.colorPatternId;
                objMthods["colorPatternName"] = item.colorPatternName;

                var selectedColorPatternId = item.colorPatternId;
    
         
                var tempColorArray = [];
                if (rtId != '') {
                  item.riskTypes.forEach((data)=>{
                    if (data.id === rtId) {
                      var objRiskTypes = {}
                      objRiskTypes["id"] = data.id;
                      objRiskTypes["riskTypesName"] = riskTypeNameForPop;
                      objRiskTypes["startingNumber"] = startingNumber;
                      objRiskTypes["endingNumber"] = endingNumber;
                      tempColorArray = [];
                      var count = parseInt(startingNumber);
                      for (let index = parseInt(startingNumber); index < (parseInt(endingNumber) + 1); index++) {
                        if (count < (parseInt(endingNumber) + 1)) {
                          console.log(databClr);
                          colorPatterns.forEach((data5)=> {
                            if (data5.id === selectedColorPatternId){
                              var colorList = data5.colors; 
                              colorList.forEach((data1)=> {
                                if (count === parseInt(data1.scoreNumber)){
                                  var objColors = {}
                                  //objColors["id"] = data1.id
                                  objColors["colorId"] = data1.id;
                                  objColors["color"] = data1.color;
                                  objColors["secondaryColor"] = data1.secondaryColor;
                                  objColors["primaryColorName"] = data1.primaryColorName;
                                  objColors["secondaryColorName"] = data1.secondaryColorName;
                                  objColors["scoreNumber"] = data1.scoreNumber;
                                  tempColorArray.push(objColors)
                                }
                              })
                            }
                          })
                        }
                        count ++;
                      } 
     
                      objRiskTypes["colorDataList"] = tempColorArray; 
                      tmpRiskTypesArray.push(objRiskTypes);
    
    
                    }else{
                      tmpRiskTypesArray.push(data);
                    }
                  })
                }else{
                  item.riskTypes.forEach((data)=>{
                    tmpRiskTypesArray.push(data);
                  })
    
    
                  var objRiskTypes = {}
                  objRiskTypes["riskTypesName"] = riskTypeNameForPop;
                  objRiskTypes["startingNumber"] = startingNumber;
                  objRiskTypes["endingNumber"] = endingNumber;
                  tempColorArray = [];
                  var count = parseInt(startingNumber);
                  for (let index = parseInt(startingNumber); index < (parseInt(endingNumber) + 1); index++) {
                    if (count < (parseInt(endingNumber) + 1)) {
                      console.log(databClr);
                      colorPatterns.forEach((data5)=> {
                        if (data5.id === selectedColorPatternId){
                          var colorList = data5.colors; 
                          colorList.forEach((data1)=> {
                            if (count === parseInt(data1.scoreNumber)){
                              var objColors = {}
                              //objColors["id"] = data1.id
                              objColors["colorId"] = data1.id;
                              objColors["color"] = data1.color;
                              objColors["secondaryColor"] = data1.secondaryColor;
                              objColors["primaryColorName"] = data1.primaryColorName;
                              objColors["secondaryColorName"] = data1.secondaryColorName;
                              objColors["scoreNumber"] = data1.scoreNumber;
                              tempColorArray.push(objColors)
                            }
                          })
                        }
                      })
                    }
                    count ++;
                  }
                                  
                  objRiskTypes["colorDataList"] = tempColorArray; 
                  tmpRiskTypesArray.push(objRiskTypes);
                }
            
    
                objMthods["riskTypes"] = tmpRiskTypesArray; 
                
                tmpMethodArr.push(objMthods)
    
    
    
              }else{
                tmpMethodArr.push(item)
              }
              
            })
    
            
            objCategory["methods"] = tmpMethodArr;
           
    
    
            var data = JSON.stringify(objCategory);
              
            console.log(data);
  
  
  
              axios(CONFIG.MAIN_URL+'/answer-category/update',{
                  method: 'post',
                  headers: { 
                     'Content-Type': 'application/json',
                    },
                 data : data,  
              })
              .then(function (response) {
                  
                  if (riskTypeIdvalue != 0){
                    alert("Risk Type Updated successfully");
                  }else{
                     alert("Risk Type saved successfully");
                   } 
                 
                  setRiskTypeData(response.data);
                   console.log(JSON.stringify(riskTypeData));
                  setSaveRiskFlag(true);
                  clearFuction();
                   getAllRisks();
                   setRiskTypeIdvalue(0)
                  setSliderColorvalue(0)
                  riskTypeClear();
                  
                  //setValuesToStratingNumAfterSavingOneRiskType()

      
              })
               .catch(function (error) {
                  console.log(error);
                   alert("Error saving Risk Type -:" + error);
             });





        }
       
        /** End of previous collection for editing */
    
    /**Comment section for Risk Tree*/
    function Comment({ comment, lastRiskTmpId }) {
      const [subLastRiskTmpId, setSubLastRiskTmpId] = React.useState(lastRiskTmpId);
      const [show, setShow] = useState(false)
      const nestedComments = (comment.subRisks || []).map(comment => {
          return <Comment key={comment.riskTypeTmpId} comment={comment} lastRiskTmpId={subLastRiskTmpId} type="child" />
      })
    
  
    
      /**
      * Edit Risk
      */
       const [editRiskName, setEditRiskName] = React.useState('');
       const [editRiskLevel, setEditRiskLevel] = React.useState('');
       const [editRiskAppetite, setEditRiskAppetite] = React.useState('');
    
       const [editRiskId, setEditRiskId] = React.useState(0);
       const [editRiskOpen, setEditRiskOpen] = React.useState(false);
       const [editRiskScroll, setEditRiskScroll] = React.useState('paper');
       const editRiskHandleClickClose = () => {
           setEditRiskOpen(false);
       };
       const handleChangeEditRisk = (event) => {
          setEditRiskName(event.target.value);
          setEditRiskId(event.target.id);
       };
       const handleChangeEditRiskLevel = (event) => {
          const value = event.target.value.replace(/\D/g, "");
          if ((value != 0 && value < 11) || value === "" ){
            setEditRiskLevel(value);
          }  
       };
       const handleChangeEditRiskAppetite = (event) => {
          const value = event.target.value.replace(/\D/g, "");
          if ((value != 0 && value < 11) || value === "" ){
            setEditRiskAppetite(value);
          } 
       };
    
       function setEditRiskFunction(event){
           if ( editRiskName === ""){
               alert("Please Edit the Risk");
               return
           }
           setEditRiskName('');
           setEditRiskLevel('');
           setEditRiskAppetite('');
           setEditRiskOpen(false);
           console.log(comment.subRisks);
           comment.name = editRiskName;
           comment.riskLevel = editRiskLevel;
           comment.riskAppetite = editRiskAppetite;
           setShow(true);
       }
       const editRiskHandleClickOpen = (scrollType) => () => {
          setEditRiskOpen(true);
          setEditRiskScroll(scrollType);
          setEditRiskName(comment.name);
          setEditRiskLevel(comment.riskLevel);
          setEditRiskAppetite(comment.riskAppetite);
       };
       /** End of Edit Risk */    
   
  
    }
    
    /**
     * all risk
     */
    
    const [riskTypeName, setRiskTypeName] = useState([])
    const [newData, setNewData] = useState()
    const [categoryName, setCategoryName] = useState("Answer category")
    const [categoryid, setCategoryid] = useState()
    const [description, setDescription] = useState("Answer Weighting")
     const [sliderId, setSliderId] = useState()
  
     
     const [RiskDataEditId, setriskDataEditId] = useState('')
     const [txtMethodNameFocus, setTxtMethodNameFocus] = React.useState(false);
  
    
     function editRiskData(id) {
        setriskDataEditId(id)
         console.log(id);
      riskTypeName.forEach((data)=>{
        if (data.id === id) {
            setRiskIdvalue(data.id)
            
            setRiskName(data.methodName);
            setMinNumber(data.minimumWeightingNumber);
            setMaxNumber(data.maximumWeightingNumber);
            setMethodDescri(data.methodDescription);
            setSaveBtnForAddMethod("update method")
          
  
            setRiskTypeData(data);
            // setRsType(data.riskTypes);
            setSaveRiskFlag(true);    
            setShowSaveBtn(true);
            setSaveBtnName("Update");
            //setSaveBtnNameForRisk("Update Slider Colors");
            //setPageBannerName("Edit Questionnaire Group")
            //setCreateHeaderInfoMessage("Edit Slider Colors")
            setEditGroup(true);
  
  
        }  
      })
      var txtElement = document.getElementById('txtMethodName');
      txtElement.focus();
      setFocusStyle(setTxtMethodNameFocus);

    }

    const [colorId, setColorId] = useState('')

    function editRiskColorData(id){
        setColorId(id)
        console.log(id)
        riskTypeName.forEach((data)=>{
            data.riskTypes.forEach((data1)=> {
                data1.colorDataList.forEach((colorListDa)=> {
                    if (colorListDa.id === id) {
                        console.log(colorListDa.id )
    
                        setScoreNumber(colorListDa.scoreNumber);
                        setColorDropDown(colorListDa.colorId)
                        
                                           
                    }
                })
                
            })
        })
    }
    
    const [rtId, setRtId] = useState('')
    const [txtRiskTypeNameFocus, setTxtRiskTypeNameFocus] = React.useState(false);
  
    function editRiskDataRiskTypeNa(id){
      setRtId(id)
  
      riskTypeName.forEach((data)=>{
          data.riskTypes.forEach((data1)=> {
              if (data1.id === id) {
                setRiskTypeIdvalue(data1.id)
  
                  setRiskTypeNameForPop(data1.riskTypesName);
                  setStartingNumber(data1.startingNumber)
                  setEndingNumber(data1.endingNumber)
                  setsaveBtnForAddRiskTypes("update risk type")
                  
               
                  
              }
          })
      })
      var txtElement = document.getElementById('txtRiskTypeName');
      txtElement.focus();
      setFocusStyle(setTxtRiskTypeNameFocus);
          
  }
    /** End of Comment section */
    
    const [PageName, setPageName] = useState('Create and Edit Slider Mthods')
  

    const [openColor, setOpenColor] = React.useState(false);
  
   
    const handleClickOpenColor = () => {
      openColor(true);
    };
  
  
    function riskTypeClear(){
      setRiskTypeNameForPop('');
      setStartingNumber('')
      setEndingNumber('')
      setRtId('')
      setsaveBtnForAddRiskTypes("+ add risk type")
      setRiskTypeIdvalue(0);
    }
  
  
  
    const [showColor, setShowColor] = useState(true)

  
        const [addColoeSectionId, setAddColoeSectionId] = useState("")
        const [riskTypeDatasForDiv, setRiskTypeDatasForDiv] = useState([])

        const [openEntityDataTopPos, setOpenEntityDataTopPos] = useState(0);
  
        function setAddColorId(id,RiskTdata, index, colorPatId){
          setAddColoeSectionId(id)
          setRiskTypeDatasForDiv(RiskTdata)
          colorPatterns.forEach((data,index)=>{
            if (data.id === colorPatId) {   
              console.log("++++++++++++++++data.colors+++++++++++++++++");
              console.log(data.colors);

              setDatabClr(data.colors); 
            }
          })
          var btn = document.getElementById("btn" + index);
          var posBtn = btn.offsetTop;
          var tableEndId = document.getElementById("tableEndId");
          var pos = tableEndId.offsetTop;
          var entityDataPos = ((posBtn + 33) - pos); 
          setOpenEntityDataTopPos(entityDataPos);
            console.log(id)
        }

        console.log(riskTypeDatasForDiv.colorDataList)
  
  // ColorEditButtonEnable
        const [colorEditButtonEnable,setColorEditButtonEnable] = useState(true)
  
        function funcColorEditButtonEnable(flag){
            setColorEditButtonEnable(flag)
  
          var elementTxtScoreNumber = document.getElementById('txtScoreNumber');
          var elementTxtColor = document.getElementById('TxtColor');
          elementTxtScoreNumber.disabled = true;
          elementTxtColor.disabled = true;
          
        }


  return (
    <>
      <div className='pageTopDiv300'>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <Card className={classes.cardRiskManage} >
                <CardContent>
                  <Card>
                    <CardHeader 
                      avatar={
                        <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                          <Info/>
                        </Avatar>
                      } 
                      className={classe.commonMainTableHead} 
                      title={'Add and Edit Slider Methods for Answer Weighting'}
                    />
                    <CardContent>
                      <Grid container spacing={1}>
                      <Grid item xs={4} className='methodGrid1300'>
                          <Box
                            component="form"
                            sx={{
                              '& .MuiTextField-root': { width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                            >
                            <div>
                                  <TextField
                                      id="txtMethodName"
                                      size='small'
                                      label={<>Method Name <span style={{color: 'red'}}>*</span></>}
                                      //label="Method Name"
                                      //multiline
                                      //maxRows={2}
                                      name='title'
                                      value={riskName}
                                      onChange={onTextFieldChange}
                                      
                                  />
                              </div>
                        </Box>
                      </Grid>
                      <Grid item className='methodGrid2300'>
                        <Box
                          component="form"
                          sx={{
                              '& .MuiTextField-root': { width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                          >
                          <div>
                                  <TextField
                                      id="title"
                                      size='small'
                                      label={<>Method Description <span style={{color: 'red'}}>*</span></>}
                                      //label="Method Description"
                                      //multiline
                                      maxRows={2}
                                      name='title'
                                      value={methodDescri}
                                      onChange={onTextFieldChangeForMethodDescri}
                                  />
                                  
                              </div>
                              </Box>
                      </Grid>    
                      <Grid item className='methodGrid3300'>
                        <Box
                          component="form"
                          sx={{
                              '& .MuiTextField-root': { width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                          >
                          <div>





                                  <TextField
                                      id="title"
                                      size='small'
                                      label={<>Minimum Weighting Number <span style={{color: 'red'}}>*</span></>}
                                      //label=" Minimum Weighting Number"
                                      //multiline
                                      maxRows={2}
                                      name='title'
                                      value={minNumber}
                                      onChange={onTextFieldChangeForMinNumber}
                                  />
                              </div>
                              </Box>
                      </Grid>
                      <Grid item className='methodGrid4300'>
                        <Box
                          component="form"
                          sx={{
                              '& .MuiTextField-root': { width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                          >    
                          <div>
                                  <TextField
                                      id="title"
                                      size='small'
                                      label={<>Maximum Weighting Number <span style={{color: 'red'}}>*</span></>}
                                      //label="Maximum Weighting Number"
                                      //multiline
                                      maxRows={2}
                                      name='title'
                                      value={maxNumber}
                                      onChange={onTextFieldChangeForMaxNumber}
                                  />
</div>
                          </Box>
                      </Grid>
                      <Grid item className='methodGrid5300'>
                        <TextField
                            //label='Select Color Pattern'
                            label={<>Select Color Pattern <span style={{color: 'red'}}>*</span></>}
                            sx={{
                              width: '100%',
                              "& .MuiInputBase-root": {
                                  height: 37
                              },
                              "& .MuiInputLabel-root": {
                                marginTop: -0.5
                                }
                              }}
                            select
                            value={colorPatternId}
                            onChange={handleChangeColorPattern}
                            >
                            {colorPatterns.map((item)=>(
                                <MenuItem 
                                    value={item.id}
                                    >
                                {item.name}
                                </MenuItem>
                            ))}
                        </TextField>
                      </Grid>

                      <Grid item className='methodGrid6300' style={{textAlign: 'right'}}>                                              <Button 
                                                  variant="outlined" 
                                                  //size='small' 
                                                 // style={{"border": "5px solid #9155FD !important",  "color": " !important",  "padding":"5px 10px !important",  "height": "40px !important","marginLeft":"15px !important"}}
                                                  onClick={saveRisk}
                                                  className='btnSize300'
                                                  > 
                                                 {saveBtnForAddMethod}
                                              </Button>
                                              &nbsp;
                                              <Button 
                                                  variant="outlined" 
                                                  //size='small' 
                                                 // style={{"border": "5px solid #9155FD !important",  "color": " !important",  "padding":"5px 10px !important",  "height": "40px !important","marginLeft":"15px !important"}}
                                                  onClick={clearFuction}
                                                  className='btnSize300'
                                                  > 
                                                  clear
                                              </Button>
                                 
  
  
                                        </Grid>

                                        <Grid item xs={12}>
                                 <Card className={classes.stdCard}>
                                 <CardHeader 
                                  avatar={
                                    <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                      <Info/>
                                    </Avatar>
                                    } 
                                  className={classe.commonMainTableHead} 
                                  title="Previous Added Slider Methods for Answer Weighting"
                                  />
                                  <CardContent>
                                  <Grid container spacing={1}>
                                   <Grid item xs={12}>
                                  <div className={classes.scrollableDivToStandard}>
                                    <TableContainer 
                                      // component={Paper}
                                     // sx={{ maxHeight: 540 }}
                                      >
                                      <Table  aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                            <StyledTableCell className='commonTableThStikyStyle1300' style={{width: '20%'}}>Method Name</StyledTableCell>
                                            <StyledTableCell className='commonTableThStikyStyle1300' style={{width: '13%'}}>Minimum Number</StyledTableCell>
                                            <StyledTableCell className='commonTableThStikyStyle1300' style={{width: '13%'}}>Maximum Number</StyledTableCell>
                                            <StyledTableCell className='commonTableThStikyStyle1300' style={{width: '30%'}}>Description</StyledTableCell>
                                            <StyledTableCell className='commonTableThStikyStyle1300' style={{width: '14%'}}>Add Risk Type</StyledTableCell>
                                            <StyledTableCell align="right" className='commonTableThStikyStyle1300' style={{width: '10%'}}>Edit</StyledTableCell>
                                          </TableRow>
                                        </TableHead>
                                        

                                       {riskTypeName.map((meth,index)=> (
                                           <>
                                           <StyledTableRow key={meth.methodid}  className='tableTrStyle1300'>     
                                              <StyledTableCell component="th" scope="row" className='tableTrStyle1300' >
                                                  <div className='tableTrStyle1300' style= {{ whiteSpace: 'normal' }}>{meth.methodName}</div>
                                                  
                                              </StyledTableCell>
                                              <StyledTableCell component="th" scope="row" className='tableTrStyle1300' >
                                                  <div className='tableTrStyle1300' style= {{ whiteSpace: 'normal' }}>{meth.minimumWeightingNumber}</div>
                                                  
                                              </StyledTableCell>
                                              <StyledTableCell component="th" scope="row" className='tableTrStyle1300' >
                                                  <div className='tableTrStyle1300' style= {{ whiteSpace: 'normal' }}>{meth.maximumWeightingNumber}</div>
                                                  
                                              </StyledTableCell>
                                              <StyledTableCell component="th" scope="row" className='tableTrStyle1300' >
                                                  <div className='tableTrStyle1300' style= {{ whiteSpace: 'normal' }}>{meth.methodDescription}</div>
                                                  
                                              </StyledTableCell>
                                              <StyledTableCell 
                                                  align="center"
                                                  className='tableTrStyle1300'
                                                  >
                                                 <Button 
                                                      variant="outlined" 
                                                      //size='small' 
                                                      // style={{"border": "5px solid #9155FD !important",  "color": " !important",  "padding":"5px 10px !important",  "height": "40px !important","marginLeft":"15px !important"}}
                                                      onClick={ ()=>handleClickOpen(meth.id,meth.maximumWeightingNumber,meth.minimumWeightingNumber,meth.methodName)}
                                                      className='riskTypeBtn300'
                                                      > 
                                                      + Risk Types
                                                  </Button>
                                              </StyledTableCell>
                                             
                                              <StyledTableCell 
                                                  align="right"
                                                  className='tableTrStyle1300'
                                                  >
                                                  <EditIcon 
                                                  className={classes.mousePointer}
                                                  onClick ={ ()=>{editRiskData(meth.id)}}
                                                  />
                                              </StyledTableCell>
                                              
                                              </StyledTableRow>
                                          </>
                                       ))
                                       
                                       }
                                       
                                                                                   
                                      </Table>
                                      
                                    </TableContainer>    
                                  </div>
                                  </Grid>  
  
                              </Grid>
                            </CardContent>
                                  </Card>
                                </Grid> 
                                          
                      </Grid>
                    </CardContent>  
                  </Card>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      <div>
                    <Dialog
                    
                    open={open}
                    
                    aria-labelledby="main-risk-scroll-dialog-title"
                     aria-describedby="main-risk-scroll-dialog-description"
                      fullWidth={true}
                      maxWidth={'md'}
                      onClose={true}
                    
                    >
                    <DialogTitle id="responsive-dialog-title">
                    Risk Types for "{riskMethodName}"
                    </DialogTitle>
                    <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                          
                          <Card className={classes.cardRiskManage} >
                         
                           <CardContent>
                          <Card >
                          <CardHeader 
                            avatar={
                              <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                <Info/>
                              </Avatar>
                              } 
                            className={classe.commonMainTableHead} 
                            title={'Add and Edit Risk Types'}
                            />
                            <CardContent>

                              <Grid container spacing={2}>
      
                              <Grid item xs={12}>
                                <Box
                                  component="form"
                                  sx={{
                                    '& .MuiTextField-root': { width: '100%' },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                  >
                                  <div>
                                    <TextField
                                      id="txtRiskTypeName"
                                      size='small'
                                      label={<>Risk Types Name <span style={{color: 'red'}}>*</span></>}
                                      //label="Risk Types Name"
                                      multiline
                                      maxRows={2}
                                      name='title'
                                      value={riskTypeNameForPop}
                                      onChange={onTextFieldChangeRiskTypeName}
                                    />
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item className='riskTypegrid1300'>
                                <Box
                                  component="form"
                                  sx={{
                                    '& .MuiTextField-root': { width: '100%' },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                  >
                                  <div>
                                    <TextField
                                      id="title"
                                      size='small'
                                      label={<>Starting Number {riskTypeMinNumber} to {riskTypeMaxNumber} <span style={{color: 'red'}}>*</span></>}
                                      //label={"Starting Number " + riskTypeMinNumber + " to " + riskTypeMaxNumber}
                                      //multiline
                                      maxRows={2}
                                      name='title'
                                      value={startingNumber}
                                      onChange={onTextFieldChangeForstartingNumber}
                                      //style={{width:'250px'}}
                                    />
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item className='riskTypegrid2300'>
                                <Box
                                  component="form"
                                  sx={{
                                    '& .MuiTextField-root': { width: '100%' },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                  >
                                  <div>    
                                    <TextField
                                        id="title"
                                        size='small'
                                        label={<>Ending Number {riskTypeMinNumber} to {riskTypeMaxNumber} <span style={{color: 'red'}}>*</span></>}
                                        //label={"Ending Number " + riskTypeMinNumber + " to " + riskTypeMaxNumber}
                                        //multiline
                                        maxRows={2}
                                        name='title'
                                        value={endingNumber}
                                      onChange={onTextFieldChangeForEndingNumber}
                                        //style={{width:'250px'}}
                                    />
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item className='riskTypegrid3300' style={{textAlign: 'right'}}>
                                <Button 
                                    variant="outlined" 
                                    //size='small' 
                                    onClick={saveRiskType}
                                    className='btnSize1300'
                                    > 
                                    {saveBtnForAddRiskTypes}
                                </Button>
                                &nbsp;
                                <Button 
                                    variant="outlined" 
                                    //size='small' 
                                    onClick={riskTypeClear}
                                    className='btnSize1300'
                                    > 
                                    clear
                                </Button>
                              </Grid>
                                        <Grid item xs={12}>
                                 <Card className={classes.stdCard}>
                                 <CardHeader 
                                  avatar={
                                    <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                      <Info/>
                                    </Avatar>
                                    } 
                                  className={classe.commonMainTableHead} 
                                  title="Previous Added Risk Types"
                                  />
                                  <CardContent>
                                  <Grid container spacing={1}>
                                   <Grid item xs={12}>
                                  <div className={classes.scrollableDivToStandard}>
                                    <TableContainer 
                                      // component={Paper}
                                     // sx={{ maxHeight: 540 }}
                                      >
                                      <Table  aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                                    <StyledTableCell className='commonTableThStikyStyle1300' style={{width: '30%'}}>Risk Types Name</StyledTableCell>
                                                    <StyledTableCell className='commonTableThStikyStyle1300' style={{width: '20%'}}>Starting Number</StyledTableCell>
                                                    <StyledTableCell className='commonTableThStikyStyle1300' style={{width: '20%'}}>Ending Number</StyledTableCell>
                                                    <StyledTableCell className='commonTableThStikyStyle1300' style={{width: '20%'}}></StyledTableCell>
                                                    <StyledTableCell align="right" className='commonTableThStikyStyle1300' style={{width: '10%'}}>Edit</StyledTableCell>
                                                  </TableRow>
                                        </TableHead>
                                        <>
                                        {
                                            riskTypeName.map((item)=> (
                                                
                                                    <>
                                                    {item.riskTypes.map((risktypes, index)=> (
                                                      
                                                      
                                                        <>
                                                        {riskTypeDa === item.id ? 
                                                        <>
                                                        <StyledTableRow key={item.methodid}  className='tableTrStyle1300'>     
                                                           <StyledTableCell component="th" scope="row" className='tableTrStyle1300' >
                                                               <div className='tableTrStyle1300' style= {{ whiteSpace: 'normal' }}>{risktypes.riskTypesName}</div>
                                                               
                                                           </StyledTableCell>
                                                           <StyledTableCell component="th" scope="row" className='tableTrStyle1300' >
                                                               <div className='tableTrStyle1300' style= {{ whiteSpace: 'normal' }}>{risktypes.startingNumber}</div>
                                                               
                                                           </StyledTableCell>
                                                           <StyledTableCell component="th" scope="row" className='tableTrStyle1300' >
                                                               <div className='tableTrStyle1300' style= {{ whiteSpace: 'normal' }}>{risktypes.endingNumber}</div>
                                                               
                                                           </StyledTableCell>
                                                          
                                                           <StyledTableCell 
                                                               align="center"
                                                               className='tableTrStyle1300'
                                                               >
                                                              <Button 
                                                                   variant="outlined" 
                                                                   size='small' 
                                                                   id={'btn' + index}
                                                                   onClick={()=>{ setShowColor(!showColor); setAddColorId(risktypes.id,risktypes, index, item.colorPatternId)}}
                                                                   className='riskTypeBtn300'
                                                                   > 
                                                                   + show colors
                                                               </Button>

                                                              

                                                           </StyledTableCell>
                                                           
                                                          
                                                           <StyledTableCell 
                                                               align="right"
                                                               className='tableTrStyle1300'
                                                               >
                                                               <EditIcon 
                                                               className={classes.mousePointer}
                                                               onClick ={ ()=>{editRiskDataRiskTypeNa(risktypes.id)}}
                                                               />
                                                           </StyledTableCell>
                                                           
                                                           </StyledTableRow>
                                                           <StyledTableRow >

                                                          
                                                           </StyledTableRow>
                                                 
                                                       </>
                                                        
                                                        : null                                                        
                                                            }

                                                        </>
                                                        
                                                       ))
                                                       
                                                    }

                                                    </>
                                           
                                                ))
                                                }                                               
                                                </>


                                      </Table>
                                    
                                    </TableContainer>    
                                  </div>

                                  
                                  </Grid>  
  
                              </Grid>


                              <Grid item xs={12} id="tableEndId">
                                        </Grid>

                                {
                                                             showColor === true ? null : 
                                                             <Draggable style={{backgroundColor:"#8d8b8b"}}>
                                                             <div style={{backgroundColor:'#b59191', height:'210px', width:'300px',zIndex:'10000', position:'relative'
                                                            ,marginTop: + `${openEntityDataTopPos}`+ 'px',marginLeft:'380px', borderRadius:'10px'}}
                                                             >
                                                               <div label="close" style={{float:'right',cursor:'pointer' }} onClick={()=>{ setShowColor(!showColor)}}>X</div>
                                                              <div>
                                                                <br></br>

                                                                <div>
                                                              <h5 style={{textAlign:'center'}}>Colors for Risk Type : {riskTypeDatasForDiv.riskTypesName}</h5>
                                                              <div style={{display:'flex'}}>
                                          <Grid container spacing={2} >
                                            <Grid item xs={1}>&nbsp;</Grid>
                                            <Grid item xs={1}>&nbsp;</Grid>
                                            <Grid item xs={4}><u>Pri. Colors</u></Grid>
                                            <Grid item xs={1}>&nbsp;</Grid>
                                            <Grid item xs={4}><u>Sec. Colors</u></Grid>
                                            <Grid item xs={1}>&nbsp;</Grid>
                                          
                                          
                                          
                                            {databClr.map((clrDatas,index)=> (

<>
{riskTypeDatasForDiv.colorDataList.map((riskData)=> (                                                                      
  <> 
    {
    clrDatas.id === riskData.colorId ? 
    <>
    <Grid item xs={2} style={{textAlign: 'right'}}>{clrDatas.scoreNumber} - </Grid>
    <Grid item xs={3}>{clrDatas.color}</Grid>
    <Grid item xs={1}><Circle  style={{color:`${clrDatas.color}`}}></Circle></Grid>
    <Grid item xs={1}>&nbsp;</Grid>
    <Grid item xs={3}>{clrDatas.secondaryColor}</Grid>
    <Grid item xs={1}><Circle  style={{color:`${clrDatas.secondaryColor}`}}></Circle></Grid>
    <Grid item xs={1}>&nbsp;</Grid>
    </>
    :''
    }
  
  </>
))}
</>
))}
                                            </Grid>
                                          
                                        </div>
                                                                </div>
                                                               </div>
                                                               </div>
                                                               </Draggable>


                                  }


                            </CardContent>

                                  </Card>
                                  
                                </Grid> 
                                          
                              </Grid>
                            </CardContent>

                                    </Card>
                                             
                                          
                                </CardContent>
                       
                            </Card>

                          </Grid>
  
                                
                          </Grid>

                    </DialogContent>
                    
                    <DialogActions>
                        
                        <Button onClick={handleClose} autoFocus>
                        cancel
                        </Button>
                    </DialogActions>
                    </Dialog>

                    </div>
    
                    </div>
    
    </>
  )
}

export default WeightingAnswers