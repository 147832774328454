import React, { useEffect, useState } from 'react';
import { makeStyles} from '@material-ui/core';
import useStyle from '../Css/MainCss';
import axios from 'axios';
import { CONFIG } from '../config';
import parseHtml from 'html-react-parser';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import {

  Button,

} from '@mui/material';

const Footer = () => {
  const classes = useStyle();

  useEffect(() => {
    getFooterDetails();
  }, []);

  const [footerHtml, setFooterHtml] = React.useState('');
  const [displayPageContent, setDisplayPageContent] = React.useState(false);

  function getFooterDetails(){
    axios(CONFIG.EXPERT_SERVER_URL + `/loginPageFooter/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
    .then(function (response) {
      console.log("++++++++++++++++++++++++Footer Details+++++++++++++++++++");
      console.log(response.data);
      response.data.forEach((item,index) => {
        if (index === 0) {
          if (item.pageContent != undefined && item.pageContent != null){
            var tArr = item.pageContent.split("<body>");
            if (tArr.length >1){
              var tArr1 = tArr[1].split("</body>");
              setFooterHtml(tArr1[0].replaceAll("\\n", ''));
            }
          }
        }
      })
      setDisplayPageContent(true);
    })
    .catch(function (error) {
      setDisplayPageContent(true);
      console.log(error);
    });
  }

  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  setTimeout(() => {

    var imgTags = document.getElementsByTagName('img');
    for (var i = 0; i < imgTags.length; i++) {
      imgTags[i].onclick = function () {
        var str = "<img src=\"" + this.src + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      };
      imgTags[i].onmouseover = function () {
        this.style.cursor = 'pointer';
      };
    }
  }, 1000);



  return (
    <>
      {displayPageContent?
       <>
        {footerHtml != ''?
          <>
            <footer className={classes.rootss1}>
              {parseHtml(footerHtml)}
            </footer>  
          </>
        :
        <div>
            <footer className={classes.rootss}>
                    <p className={classes.footer}>Copyright @{new Date().getFullYear()} Data Management Group Ltd.</p>
            </footer>
        </div>
        }
      </>  
      :
      ''
      }


<Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>

    </>
  )
}

export default Footer