import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Links from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { textAlign } from '@mui/system';
import { CONFIG } from '../config'
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { FullscreenExitRounded, FullscreenRounded, Info, KeyboardReturnOutlined, MaximizeRounded, Minimize, TurnedInOutlined } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import '../Css/Slider.css';
import Draggable from 'react-draggable';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Alert } from 'bootstrap';
import Tooltip1 from '@mui/material/Tooltip';
import useStyle from '../Css/MainCss';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { Close } from '@mui/icons-material';
import { Circle } from '@mui/icons-material';
import { CardActionArea, CardActions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Paper1 from '@mui/material/Paper';
// import { Chart, BarSeries, ArgumentAxis, ValueAxis, Legend, Title } from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation, Palette } from '@devexpress/dx-react-chart';
import { BarChart, Bar, XAxis, YAxis,Cell, Tooltip, LabelList ,CartesianGrid, Legend} from 'recharts';
import RiskHierarchyNew from './RiskHierarchyNew';
import TransferToExcel from './TransferToExcel';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import EvaluateComponent from './EvaluateComponent';
import SavePageAs from './SavePageAs'
import '../Css/EvaluateRisk.css'



const colors = ['#d72e3d', '#249e3d', '#ffb90c', '#1698af', '#616a72', '#d72e3d', '#249e3d', '#ffb90c', '#1698af'];

function PaperComponentMainRisk(props) {
    return (
        <Draggable
            handle="#main-risk-scroll-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
function PaperComponentMainRiskEdit(props) {
    return (
        <Draggable
            handle="#edit-risk-scroll-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
function PaperComponentSubRisk(props) {
    return (
        <Draggable
            handle="#sub-risk-scroll-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

function PaperComponentSubRiskEdit(props) {
    return (
        <Draggable
            handle="#edit-sub-risk-scroll-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}




function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const useStyles = makeStyles((theme) => ({
    right: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    pageBtn: {

        height: '60px',
        width: '170px',
        backgroundColor: '#f9f9f9 !important',
        color: '#9481df !important',
        borderRadius: '12px !important',
    },
    headerLable: {

        color: '#827d7d',
    },
    pageTop: {
        marginTop: '115px',
        marginLeft: '20px'
    },
    pageBodyStyle: {
        paddingTop: '0px',


    },
    root: {
        position: 'relative',
    },
    posRelative: {
        position: 'relative',
    },
    topImagePos: {
        position: 'absolute',
        top: '33%',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    topHeaderText1: {
        color: '#9155FD',
        fontSize: '25px',
        paddingBottom: '10px',
    },
    topHeaderText2: {
        color: '#3A3541',
        fontSize: '12px',
        textAlign: 'center',
        fontFamily: 'Inter',
    },
    thStyle: {
        fontSize: '12px !important',
        lineHeight: '0.5rem !important',
        borderBottom: '0px !important'
    },
    thStyleImage: {
        lineHeight: '0.5rem !important',
        borderBottom: '0px !important',
        width: '130px !important',
    },
    btnColorPurple: {
        fontSize: '12px !important',
        backgroundColor: '#9155FD !important',
        height: '40px',
        textAlign: 'center'
    },
    riskImageSize: {
        width: '120px',
        height: '100px !important',
    },
    tableStyle: {
        width: '55% !important',
        minWidth: '720px !important'
    },
    btnBorderGreen: {
        border: '1px solid #0e640e !important',
        color: '#0e640e !important',
        height: '40px'
    },
    btnclear: {
        height: '40px'
    },
    btnBorderAsh: {
        border: '1px solid #8a8181 !important',
        color: '#8a8181 !important',
    },
    btnBorderRed: {
        border: '1px solid #ff0000 !important',
        color: '#ff0000 !important',
    },
    qusCard: {
        width: '100% !important',
    },
    qusCardHeader: {
        backgroundColor: '#ece9fa !important',
    },
    subText: {
        fontSize: '12px !important',
    },
    btnPadding: {
        paddingLeft: '2px !important',
        paddingRight: '2px !important',
    },
    cardBodyH6: {
        fontSize: '14px !important',
    },
    cardBodyH6Ans: {
        fontSize: '13px',
        paddingLeft: '12px',
    },
    addBodyPlus: {
        fontSize: '146px !important',
        fontWeight: '1000 !important',
        textAlign: 'center !important',
        color: '#3A3541 !important',
    },
    posRelativeAndDivBorder: {
        position: 'relative',
        border: '1px solid #9155FD',
        width: '120px',
        height: '100px !important',
    },
    hightrachytitle: {
        textAlign: 'left',
        color: '#3A3541',

    },
    btnBorderpurple: {
        border: '1px solid #9155FD !important',
        color: '#9155FD !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },
    hightrachytitlestyle: {
        fontSize: '14px !important',
        fontWeight: 500
    },
    modalbox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: 14,
        padding: 10,
    },
    addriskbtn: {
        color: '#9155FD !important',
        border: '1px solid #9155FD !important',
        marginTop: '20px !important'
    },
    risktytext: {
        width: '350px',

    },
    canclbtn: {
        alignItem: 'left !important',
        justifyContent: 'end'
    },
    cardRiskManage: {
        width: '95%',
        border: '1px solid black'
    },
    scrollableDiv: {
        height: '150px',
        overflow: 'auto',
        width: '100%',
    },
    tableHeadStyle: {
        backgroundColor: '#7B9DE0',
        color: '#ffffff',
    },
    tableThStyle: {
        lineHeight: '0.25rem !important',
        color: 'white !important'
    },
    tableTrStyle: {
        //lineHeight: '0.20rem !important',
        height: '19px !important'
    },
    mousePointer: {
        cursor: 'pointer'
    },
    scrollableDiv: {
        height: '250px',
        overflow: 'auto',
        width: '100%',
    },
    stdMainCardHeader: {
        backgroundColor: '#4C7EE0 !important',
        fontSize: '13px !important',
        height: '50px',
        padding: '0px',
        marginTop: '-7px',
        color: 'white'
    },
    addrisktypebtn: {
        marginLeft: '0px !important',
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },

    btnBorderpurple: {
        border: '1px solid #9155FD !important',
        color: '#9155FD !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },
    stdCard: {
        height: '100%',
        border: '1px solid black'
    },
    scrollableDivToEval: {
        height: '400px',
        overflow: 'auto',
        width: '100%',
    },
    noclickbackdrop: {
        pointerEevents: "none",
    },
    slider1: {
        background: 'linear-gradient(90deg, rgb(70, 163, 56) 10%,#d3d3d3 0%)',
    },
    cardDivDB: {
        border: '2px solid 69676e',
        width: '100% !important',
        backgroundColor: '#f6f5fc !important',
        boxShadowhadow: '5px 10px #69676e',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        borderRadius: '5px !important'
    },
    stdButtonsStyle: {
        fontSize: '0.70rem !important',
        height: '50px !important',
    },
    collectionCardDBEval: {
        height: '145px',
        borderRadius: '15px !important',
        backgroundColor: '#dce3f2 !important',
    },
    collectionCardTitleDBEval: {
        fontSize: '13px !important',
        textAlign: 'center',
        fontWeight: '500',
    },
    commonMainTableHeadEval: {
        backgroundColor: '#4C7EE0 !important',
        fontSize: '16px !important',
        height: '50px',
        padding: '0px',
        marginTop: '-7px',
        color: 'white'
    },
    alignCenterDBEval: {
        textAlign: 'center !important',
        alignItems: 'center  !important',
        justifyContent: 'center  !important'
    },

    btnActive: {
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        backgroundColor:'#BACDF2 !important',
        padding:'5px 10px !important',
        height:'40px !important',
        width: '160px !important'
      },
      
    btnNormal:{
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        padding:'5px 10px !important',
        height: '40px !important',
        width: '160px !important'
    },

    btnSepateWindow:{
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        padding:'5px 10px !important',
        height: '34px !important',
        //width: '160px !important',
        float: 'right'
    },



}));




const EvaluateRiskRiskComponent = ({ centreId, riskTypeName }) => {

    const [maxWidthTable, setMaxWidthTable] = React.useState(400);


    useEffect (() => {
      console.log("++++++++++++window. screen. width+++++++++++++");
      if (window. screen. width <= 480){
            setMaxWidthTable(150)
      }else{
        if (window. screen. width <= 810 && window. screen. width >= 481){
            setMaxWidthTable(200)
  
        }
      }
      console.log(window. screen. width);
    },[])

    const classe = useStyle();
    const classes = useStyles();
    const Input = styled('input')({
        display: 'none',
    });

    const [golobalCentreId, setGolobalCentreId] = React.useState('');
    const [sliderType, setSliderType] = React.useState();


    const [selectedFile, setSelectedFile] = useState(null)
    const [preview, setPreview] = React.useState()

    function PaperComponentCollectionData(props) {
        return (
            <Draggable
                handle="#collection-data-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    function PaperComponentEditData(props) {
        return (
            <Draggable
                handle="#edit-data-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }


    // create a preview as a side effect, whenever selected file is changed
    React.useEffect(() => {
        getData();
        getColors();


        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])




    const [databClr, setDatabClr] = useState([]);
    const [colorPatterns, setColorPatterns] = useState([])

    function getColors() {
        axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR + "/all", {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("+++++++++++++++++All colors++++++++++++++++++");
                console.log(response.data);
                //setDatabClr(response.data)
                setColorPatterns(response.data);

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function getData() {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        setGolobalCentreId(data.centreId);
        getAllRisksMethods();

        getStandards(data.centreId)
        console.log(data)
    }

    const [standardData, setStandardData] = React.useState([]);
    const [globalStandardData, setGlobalStandardData] = React.useState([]);
    const [customStandardData, setCustomStandardData] = React.useState([]);
    function getStandards(cntre) {
        axios(CONFIG.MAIN_URL + `/standard/all??centreId=` + cntre, {
            method: 'get',
        })
            .then(function (response) {
                console.log("++++++++++++++++Return Standard Data +++++++++++++++++++");
                console.log(response.data);
                console.log(JSON.stringify(response.data));
                setStandardData(response.data);
                var arrG = [];
                var arrC = [];
                response.data.forEach((item) => {
                    if (item.centreId === "globle") {
                        arrG.push(item);
                    }
                    if (item.centreId === cntre) {
                        arrC.push(item);
                    }
                })
                setGlobalStandardData(arrG);
                setCustomStandardData(arrC);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [btnHirachivalClass, setBtnHirachivalClass] = useState(classes.btnNormal);
    const [btnGradientClass, setBtnGradientClass] = useState(classes.btnNormal);
    const [btnBarChartClass, setBtnBarChartClass] = useState(classes.btnNormal);


    /**
     * Create Main Risk
     */
    const [lastRiskTmpId, setLastRiskTmpId] = React.useState(0);

    // getall risk slider method data

    const [riskCategoryMethodData, setRiskCategoryMethodData] = useState([])
    const [riskCategoryId, setRiskCategoryId] = useState('')

    const [riskCategoryMethodDataAppetite, setRiskCategoryMethodDataAppetite] = useState([])
    const [riskCategoryIdAppetite, setRiskCategoryIdAppetite] = useState('')

    function getAllRisksMethods() {
        axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_RISK + "/all", {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {

                console.log("+++++++++++++++++++All Risk Returns++++++++++++++++++++++++++++");
                console.log(response);
                console.log(response.data[0]);
                setRiskCategoryMethodData(response.data[0].methods)
                setRiskCategoryId(response.data[0].id)

                setRiskCategoryMethodDataAppetite(response.data[0].methods)
                setRiskCategoryIdAppetite(response.data[0].id)

                console.log(response.data[0].methods);

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    /**Comment section for Risk Tree*/

    function getSubSubAvarageRisk1(comment, subTotal, len) {
        comment.forEach((item) => {
            subTotal = subTotal + parseInt(item.riskLevel);
            len = len + 1;
            if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                getSubSubAvarageRisk1(item.subRisks, subTotal, len);
            }
        })
        var obj = {};
        obj["total"] = subTotal;
        obj["len"] = len;
        return obj;
    }

    function getSubAvarageRisk1(comment) {
        var subTotal = 0
        var avarage = 0;
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            var len = 0;
            var subTotal = 0;
            var dataRtn = {};
            comment.subRisks.forEach((item) => {
                subTotal = subTotal + parseInt(item.riskLevel);
                len = len + 1;
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    dataRtn = getSubSubAvarageRisk1(item.subRisks, subTotal, len);
                }
            })
            if (Object.keys(dataRtn).length != 0) {
                subTotal = parseInt(dataRtn.total);
                len = parseInt(dataRtn.len);
            }
            avarage = (subTotal / (len));
            avarage = Math.round(avarage);
        }
        return avarage;
    }











    function getSubAvarageRisk(comment) {
        var subTotal = 0
        var avarage = 0;
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            var len = 0;
            var subTotal = 0;
            comment.subRisks.forEach((item) => {
                subTotal = subTotal + parseInt(item.riskLevel);
                len = len + 1;
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    item.subRisks.forEach((item1) => {
                        subTotal = subTotal + parseInt(item1.riskLevel);
                        len = len + 1;
                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                            item1.subRisks.forEach((item2) => {
                                subTotal = subTotal + parseInt(item2.riskLevel);
                                len = len + 1;
                                if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                    item2.subRisks.forEach((item3) => {
                                        subTotal = subTotal + parseInt(item3.riskLevel);
                                        len = len + 1;
                                        if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                            item3.subRisks.forEach((item4) => {
                                                subTotal = subTotal + parseInt(item4.riskLevel);
                                                len = len + 1;
                                                if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                                    item4.subRisks.forEach((item5) => {
                                                        subTotal = subTotal + parseInt(item5.riskLevel);
                                                        len = len + 1;
                                                        if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                            item5.subRisks.forEach((item6) => {
                                                                subTotal = subTotal + parseInt(item6.riskLevel);
                                                                len = len + 1;
                                                                if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {

                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }

            })
            avarage = (subTotal / (len));
            //avarage = Math.round(avarage * 100)/100;
            avarage = Math.round(avarage);
        }
        return avarage;

    }



    function getSubAvarageAppetite(comment) {
        var subTotal = 0
        var avarage = 0;
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            var len = 0;
            var subTotal = 0;
            comment.subRisks.forEach((item) => {
                subTotal = subTotal + parseInt(item.riskAppetite);
                len = len + 1;
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    item.subRisks.forEach((item1) => {
                        subTotal = subTotal + parseInt(item1.riskAppetite);
                        len = len + 1;
                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                            item1.subRisks.forEach((item2) => {
                                subTotal = subTotal + parseInt(item2.riskAppetite);
                                len = len + 1;
                                if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                    item2.subRisks.forEach((item3) => {
                                        subTotal = subTotal + parseInt(item3.riskAppetite);
                                        len = len + 1;
                                        if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                            item3.subRisks.forEach((item4) => {
                                                subTotal = subTotal + parseInt(item4.riskAppetite);
                                                len = len + 1;
                                                if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                                    item4.subRisks.forEach((item5) => {
                                                        subTotal = subTotal + parseInt(item5.riskAppetite);
                                                        len = len + 1;
                                                        if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                            item5.subRisks.forEach((item6) => {
                                                                subTotal = subTotal + parseInt(item6.riskAppetite);
                                                                len = len + 1;
                                                                if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {

                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }

            })
            avarage = (subTotal / (len));
            //avarage = Math.round(avarage * 100)/100;
            avarage = Math.round(avarage);
        }
        return avarage;

    }




    function getSubAvarage(comment, type) {
        var subTotal = 0
        var avarage = 0;
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            comment.subRisks.forEach((item) => {
                if (type === 1) {
                    subTotal = subTotal + parseInt(item.riskLevel);
                } else {
                    subTotal = subTotal + parseInt(item.riskAppetite);
                }

            })
            avarage = (subTotal / (comment.subRisks.length));
            avarage = Math.round(avarage * 100) / 100;
        }
        return avarage;

    }

    function getSubNo(comment, riskId) {
        var subNo = "0";
        riskTypeDataForEval.riskTypes.forEach((data) => {
            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                data.subRisks.forEach((item, index) => {
                    if (item.riskTypeId === riskId) {
                        subNo = (index + 1) + "";
                    }

                })
            }
        })
        if (subNo === "0") {
            riskTypeDataForEval.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((item, index) => {
                        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                            var tmpN0 = getSubRiskSubNo(item.subRisks, riskId, '');
                            if (tmpN0 != "0") {
                                subNo = (index + 1) + '.' + tmpN0;
                            } else {
                                riskTypeDataForEval.riskTypes.forEach((data1) => {
                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                        data1.subRisks.forEach((item1, index1) => {
                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                item1.subRisks.forEach((item2, index2) => {
                                                    item2.subRisks.forEach((item3, index3) => {
                                                        if (item3.riskTypeId === riskId) {
                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1);
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    }
                                })
                                if (tmpN0 === "0") {
                                    riskTypeDataForEval.riskTypes.forEach((data1) => {
                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                            data1.subRisks.forEach((item1, index1) => {
                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                    item1.subRisks.forEach((item2, index2) => {
                                                        item2.subRisks.forEach((item3, index3) => {
                                                            item3.subRisks.forEach((item4, index4) => {
                                                                if (item4.riskTypeId === riskId) {
                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1);
                                                                }
                                                            })
                                                        })
                                                    })
                                                }
                                            })
                                        }
                                    })
                                    if (tmpN0 === "0") {
                                        riskTypeDataForEval.riskTypes.forEach((data1) => {
                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                data1.subRisks.forEach((item1, index1) => {
                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                        item1.subRisks.forEach((item2, index2) => {
                                                            item2.subRisks.forEach((item3, index3) => {
                                                                item3.subRisks.forEach((item4, index4) => {
                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                        if (item5.riskTypeId === riskId) {
                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1);
                                                                        }
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                        if (tmpN0 === "0") {
                                            riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                    data1.subRisks.forEach((item1, index1) => {
                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                            item1.subRisks.forEach((item2, index2) => {
                                                                item2.subRisks.forEach((item3, index3) => {
                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                if (item6.riskTypeId === riskId) {
                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1);
                                                                                }
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                            if (tmpN0 === "0") {
                                                riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                        data1.subRisks.forEach((item1, index1) => {
                                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                item1.subRisks.forEach((item2, index2) => {
                                                                    item2.subRisks.forEach((item3, index3) => {
                                                                        item3.subRisks.forEach((item4, index4) => {
                                                                            item4.subRisks.forEach((item5, index5) => {
                                                                                item5.subRisks.forEach((item6, index6) => {
                                                                                    item6.subRisks.forEach((item7, index7) => {
                                                                                        if (item7.riskTypeId === riskId) {
                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1);
                                                                                        }
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            }
                                                        })
                                                    }
                                                })
                                                if (tmpN0 === "0") {
                                                    riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                            data1.subRisks.forEach((item1, index1) => {
                                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                    item1.subRisks.forEach((item2, index2) => {
                                                                        item2.subRisks.forEach((item3, index3) => {
                                                                            item3.subRisks.forEach((item4, index4) => {
                                                                                item4.subRisks.forEach((item5, index5) => {
                                                                                    item5.subRisks.forEach((item6, index6) => {
                                                                                        item6.subRisks.forEach((item7, index7) => {
                                                                                            item7.subRisks.forEach((item8, index8) => {
                                                                                                if (item8.riskTypeId === riskId) {
                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1);
                                                                                                }
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                    if (tmpN0 === "0") {
                                                        riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                data1.subRisks.forEach((item1, index1) => {
                                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                        item1.subRisks.forEach((item2, index2) => {
                                                                            item2.subRisks.forEach((item3, index3) => {
                                                                                item3.subRisks.forEach((item4, index4) => {
                                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                                        item5.subRisks.forEach((item6, index6) => {
                                                                                            item6.subRisks.forEach((item7, index7) => {
                                                                                                item7.subRisks.forEach((item8, index8) => {
                                                                                                    item8.subRisks.forEach((item9, index9) => {
                                                                                                        if (item9.riskTypeId === riskId) {
                                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1);
                                                                                                        }
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        })
                                                        if (tmpN0 === "0") {
                                                            riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                    data1.subRisks.forEach((item1, index1) => {
                                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                            item1.subRisks.forEach((item2, index2) => {
                                                                                item2.subRisks.forEach((item3, index3) => {
                                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                                item6.subRisks.forEach((item7, index7) => {
                                                                                                    item7.subRisks.forEach((item8, index8) => {
                                                                                                        item8.subRisks.forEach((item9, index9) => {
                                                                                                            item9.subRisks.forEach((item10, index10) => {
                                                                                                                if (item10.riskTypeId === riskId) {
                                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1) + "." + (index10 + 1);
                                                                                                                }
                                                                                                            })    
                                                                                                        })
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            })

                                                        }

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }


                            }

                        }

                    })
                }
            })

        }
        return subNo;
    }


    function getSubRiskSubNo(comment, riskId, subNo) {
        var no = "0"
        comment.forEach((data, index) => {
            if (data.riskTypeId === riskId) {
                no = subNo + (index + 1) + '';
            }
        });
        if (no === "0") {
            comment.forEach((item, index) => {
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    getSubRiskSubNo(item.subRisks, riskId, (index + 1));
                }

            })
        }
        return no;
    }

    function compareRiskTypeOrder(a, b) {
        var nameA = parseInt(a.riskLevel); // ignore upper and lowercase
        var nameB = parseInt(b.riskLevel); // ignore upper and lowercase

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // names must be equal
        return 0;
    }

    const [arrayForDisplayDataInTable, setArrayForDisplayDataInTable] = useState([])

    var commitArray = []
    function pushToArray(item) {
        commitArray.push(item);
        console.log("+++++++++++++++++++++commitArray+++++++++++++++");
        console.log(commitArray);
    }

    const [commitArrayData, setCommitArrayData] = useState([])

    function displayRiskAsending() {
        setShowEvalData(true)
        var arr = [];
        if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
            //arr = commitArray.sort(compareRiskTypeOrder);
            arr = commitArray
        } else {
            arr = commitArrayData;
        }
        setArrayForDisplayDataInTable(arr);
        setCommitArrayData(arr);
        setShowTableType(2)

        setBtnHirachivalClass(classes.btnNormal)
        setBtnGradientClass(classes.btnActive)
        setBtnBarChartClass(classes.btnNormal)
    }

    /** Referes data after amending */
    function refreshData(comment){
        riskTypeDataForEval.riskTypes.forEach((data) => {
            if (data.riskTypeTmpId === comment.riskTypeTmpId){
                data["appetiteColor"] = comment.appetiteColor;
                data["name"] = comment.name;
                data["riskAppetite"] = comment.riskAppetite;
                data["riskAppetiteData"] = comment.riskAppetiteData;
                data["riskColor"] = comment.riskColor;
                data["riskImpact"] = comment.riskImpact;
                data["riskLevel"] = comment.riskLevel;
                data["riskLevelData"] = comment.riskLevelData;
                data["riskLikelihood"] = comment.riskLikelihood;
                data["riskStandardData"] = comment.riskStandardData;
                data["riskTypeId"] = comment.riskTypeId;
                data["riskTypeTmpId"] = comment.riskTypeTmpId;
                data["subRisks"] = comment.subRisks;
            }
        })
        setTimeout(() => {
            console.log("++++++++++ Check comment +++++++++++++++");
            console.log(comment);
            console.log(riskTypeDataForEval.riskTypes);
            setRiskTypeDataForEval(riskTypeDataForEval);
            showDataInAViewFunction(1);
        }, 500);
  
        










    }


    function Comment({ comment, lastRiskTmpId, riskNo, fromMainMenu, fromSubMenu,editRiskHirachyData }) {

        console.log("++++++++++++++++++++comment++++++++++++++++++++++");
        console.log(comment);

        const [subLastRiskTmpId, setSubLastRiskTmpId] = React.useState(lastRiskTmpId);
        const [subEditRiskHirachyData, setSubEditRiskHirachyData] = React.useState(editRiskHirachyData);
        const [subRiskNo, setSubRiskNo] = React.useState(riskNo);
        const [show, setShow] = useState(true)

        var noDisplay = '';
        var noDisplaySpacesCount = 0;


        function onTxtNameChageEvent(event) {
            comment["name"]=event.target.value
        }
        function onBlurChageEvent(event) {
            comment["name"]=event.target.value
        }
      

        const onTextFieldChangeLikelihood = (event) => {
            if (event != null && event.target.value != null) {
                var riskLevelVal = 1;
                var riskImpactVal = 1;
                if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                    event.target.value = '';
                    if (comment.riskImpact != undefined && comment.riskImpact != null && comment.riskImpact != ''){
                        riskImpactVal = parseInt(comment.riskImpact);
                    }
                } else {
                    if (comment.riskImpact != undefined && comment.riskImpact != null && comment.riskImpact != ''){
                        riskImpactVal = parseInt(comment.riskImpact);
                    }
                    riskLevelVal = parseInt(event.target.value)
                }
                comment["riskLikelihood"]=event.target.value;
                var rLevel = riskImpactVal * riskLevelVal;
                comment["riskLevel"]= rLevel.toString();
                comment.riskLevelData["riskLikelihood"]=event.target.value;
                comment.riskLevelData["riskLevel"]=rLevel.toString();
                var btn = document.getElementById("btn" + comment.riskTypeTmpId);
                btn.click();
                setTimeout(() => {
                    btn.click();
                }, 10);

            }
        }
        
        const onTextFieldChangeImpact = (event) => {
            if (event != null && event.target.value != null) {
                var riskLevelVal = 1;
                var riskImpactVal = 1;
                if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                    event.target.value = ''
                    if (comment.riskLikelihood != undefined && comment.riskLikelihood != null && comment.riskLikelihood != ''){
                        riskLevelVal = parseInt(comment.riskLikelihood);
                    }
                } else {
                    if (comment.riskLikelihood != undefined && comment.riskLikelihood != null && comment.riskLikelihood != ''){
                        riskLevelVal = parseInt(comment.riskLikelihood);
                    }
                    riskImpactVal = parseInt(event.target.value)
                }
                comment["riskImpact"]=event.target.value;
                comment.riskLevelData["riskImpact"]=event.target.value;
                var rLevel = riskImpactVal * riskLevelVal;
                comment["riskLevel"]= rLevel.toString();
                comment.riskLevelData["riskLevel"]=rLevel.toString();
                var btn = document.getElementById("btn" + comment.riskTypeTmpId);
                btn.click();
                setTimeout(() => {
                    btn.click();
                }, 10);
            }
          }
      
          const onTextFieldChangeAppetite = (event) => {
            if (event != null && event.target.value != null) {
              if (event.target.value <= 0 || event.target.value > 10 || !Number.isInteger(parseInt(event.target.value))) {
                event.target.value = ''
              }
              comment["riskAppetite"]=event.target.value;
              comment.riskAppetiteData["riskAppetite"]=event.target.value;
              var btn = document.getElementById("btn" + comment.riskTypeTmpId);
              btn.click();
              setTimeout(() => {
                  btn.click();
              }, 10);
            }
          }
   


        var subAvarageRisk = 0;
        var subAvarageAppetite = 0;
        if (fromMainMenu) {
            fromMainMenu = false;
            noDisplay = riskNo;
            subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageRisk = getSubAvarageRisk1(comment);
            subAvarageAppetite = getSubAvarageAppetite(comment);
        }
        if (fromSubMenu) {
            fromSubMenu = false;
            var subNo = getSubNo(comment, comment.riskTypeId)
            noDisplay = riskNo + '.' + subNo;
            var tmpArr = noDisplay.split(".");
            if (tmpArr.length === 2) {
                noDisplaySpacesCount = 1;
            }
            if (tmpArr.length === 3) {
                noDisplaySpacesCount = 2;
            }
            if (tmpArr.length === 4) {
                noDisplaySpacesCount = 3;
            }
            if (tmpArr.length === 5) {
                noDisplaySpacesCount = 4;
            }
            if (tmpArr.length === 6) {
                noDisplaySpacesCount = 5;
            }
            if (tmpArr.length === 7) {
                noDisplaySpacesCount = 6;
            }
            if (tmpArr.length === 8) {
                noDisplaySpacesCount = 7;
            }
            if (tmpArr.length === 9) {
                noDisplaySpacesCount = 8;
            }
            if (tmpArr.length === 10) {
                noDisplaySpacesCount = 9;
            }
            if (tmpArr.length === 11) {
                noDisplaySpacesCount = 10;
            }


            subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageRisk = getSubAvarageRisk1(comment);
            subAvarageAppetite = getSubAvarageAppetite(comment);
        }
        const nestedComments = (comment.subRisks || []).map(comment => {
            return <Comment key={comment.riskTypeTmpId} comment={comment} lastRiskTmpId={subLastRiskTmpId} riskNo={subRiskNo} fromSubMenu={true} editRiskHirachyData={subEditRiskHirachyData} type="child" />
        })






        /** Need */
        function getRiskRiskTypeName(Comment) {
            var rName = ''
            riskCategoryMethodData.forEach((data) => {
                if (data.id === Comment.riskLevelData.riskMethodId) {
                    data.riskTypes.forEach((data1) => {
                        if (data1.startingNumber <= Comment.riskLevel && Comment.riskLevel <= data1.endingNumber) {
                            rName = data1.riskTypesName
                        }
                    })
                }

            })
            return rName;
        }

        /** Need */
        function getAppetiteRiskTypeName(Comment) {
            var aName = ''
            riskCategoryMethodDataAppetite.forEach((data) => {
                if (data.id === Comment.riskAppetiteData.riskMethodId) {
                    data.riskTypes.forEach((data1) => {
                        if (data1.startingNumber <= Comment.riskAppetite && Comment.riskAppetite <= data1.endingNumber) {
                            aName = data1.riskTypesName
                        }
                    })
                }

            })
            return aName;
        }


        /** Need */
        function getRiskRiskColor(Comment) {
            var rColor = ''
            var lastNumber = ''
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rColor = data2.color
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rColor = data2.secondaryColor
                        }
                    }
                }
            })
            if (rColor === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.color
                            }
                        } else if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.secondaryColor
                            }
                        }
                    }
                })
            }
            return rColor;
        }


        function getRiskRiskAction(Comment) {
            var rAction = ''
            var lastNumber = ''
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rAction = data2.primaryColorName
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rAction = data2.secondaryColorName
                        }
                    }
                }
            })
            if (rAction === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rAction = data2.primaryColorName
                            }
                        } else if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rAction = data2.secondaryColorName
                            }
                        }
                    }
                })
            }
            return rAction;
        }


        function getRiskRiskColorAve(avarageRisk) {
            var rColor = ''
            var lastNumber = '';
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageRisk)) {
                            rColor = data2.secondaryColor;
                        }
                    } else if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageRisk)) {
                            rColor = data2.color;
                        }
                    }
                }
            })
            if (rColor === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.secondaryColor;
                            }
                        } else if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.color;
                            }
                        }
                    }
                })
            }
            return rColor;
        }





        /** Need */
        function getAppetiteColor(Comment) {
            var aColor = ''
            var colorFg1 = ''

            databClr.forEach((data2) => {
                if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
                    //console.log("++++++++++++++data2+++++++++++++++")
                    //console.log(data2)
                    //console.log(applyFlag)
                    //console.log(Comment.riskAppetiteData)
                    //console.log(colorSelectDropDownRiskAppetite)

                    if (Comment.riskAppetiteData != null && Comment.riskAppetiteData != undefined) {
                        /*
                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (Comment.riskAppetiteData.color != null && Comment.riskAppetiteData.color != undefined && Comment.riskAppetiteData.color != '') {
                                    aColor = Comment.riskAppetiteData.color;
                                }
                            }
                            else if (colorSelectDropDownRiskAppetite === 2) {
                                if (Comment.riskAppetiteData.secondaryColor != null && Comment.riskAppetiteData.secondaryColor != undefined && Comment.riskAppetiteData.secondaryColor != '') {
                                    aColor = Comment.riskAppetiteData.secondaryColor;

                                }
                            }
                        }
                        */

                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (aColor === '') {
                                    aColor = data2.color
                                }
                            }
                            if (colorSelectDropDownRiskAppetite === 2) {
                                if (aColor === '') {
                                    aColor = data2.secondaryColor
                                }
                            }
                        }
                    }
                }
            })
            return aColor;
        }


        function getRiskAppetiteAction(Comment) {
            var aColor = ''
            var colorFg1 = ''

            databClr.forEach((data2) => {
                if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
                    //console.log("++++++++++++++data2+++++++++++++++")
                    //console.log(data2)
                    //console.log(applyFlag)
                    //console.log(Comment.riskAppetiteData)
                    //console.log(colorSelectDropDownRiskAppetite)

                    if (Comment.riskAppetiteData != null && Comment.riskAppetiteData != undefined) {
                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (aColor === '') {
                                    aColor = data2.primaryColorName
                                }
                            }
                            if (colorSelectDropDownRiskAppetite === 2) {
                                if (aColor === '') {
                                    aColor = data2.secondaryColorName
                                }
                            }
                        }
                    }
                }
            })
            return aColor;
        }

        function getAppetiteColorAve(avarageAppetite) {
            var aColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskAppetite === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageAppetite)) {
                            aColor = data2.secondaryColor;
                        }
                    } else if (colorSelectDropDownRiskAppetite === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageAppetite)) {
                            aColor = data2.color;
                        }
                    }
                }


            })
            return aColor;



        }


        /** Need */
        function getRiskLikeliColor(Comment) {

            var rColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLikelihood)) {
                            rColor = data2.color
                            //setApplyFlag(false)
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLikelihood)) {
                            rColor = data2.secondaryColor
                            //setApplyFlag(false)
                        }
                    }
                }

            })
            return rColor;
        }

        /** Need */
        function getRiskImpactColor(Comment) {

            var rColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskImpact)) {
                            rColor = data2.color
                            //setApplyFlag(false)
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskImpact)) {
                            rColor = data2.secondaryColor
                            //setApplyFlag(false)
                        }
                    }
                }

            })
            return rColor;
        }

        var riskLikelihood = getRiskLikeliColor(comment)
        var appetiteColor = getAppetiteColor(comment);
        var appetiteAction = getRiskAppetiteAction(comment);
        var riskColor = getRiskRiskColor(comment);
        var riskAction = getRiskRiskAction(comment);
        var riskImpact = getRiskImpactColor(comment)
        var riskColorAverage = getRiskRiskColorAve(subAvarageRisk);
        var appetiteColorAverage = getAppetiteColorAve(subAvarageAppetite)

        /** Need */
        function OnlyRiskLvl({ comment }) {
            var riskName = getRiskRiskTypeName(comment)
            var riskColor = getRiskRiskColor(comment)
            return (
                <>

                    {comment.name} {" - (Risk : "}{' '} {comment.riskLevel}{" - "}
                    {
                        <span style={{ color: `${riskColor}` }}><b>{riskName}</b>  </span>
                    }

                    {" ) "}




                </>
            )
        }
        /** Need */
        function OnlyAppetiteLvl({ comment }) {
            var appetiteName = getAppetiteRiskTypeName(comment)
            var appetiteColor = getAppetiteColor(comment)
            return (
                <>

                    {comment.name} {" - ( Appetite : "} {appetitesSliderValue} {" - "}
                    {
                        <span style={{ color: `${appetiteColor}` }}> <b>{appetiteName}</b> </span>
                    }
                    {" ) "}




                </>
            )
        }
        /** Need */
        function DisplayStandared({ comment }) {
            console.log("+++++++++++++Athula+++++++comment++++++++++++++++++++++")
            console.log(comment)
            var objectiveId = '';
            var objectiveName = '';
            var componentId = '';
            var componentName = '';
            var principleId = '';
            var principleName = '';
            var standardId = '';
            var standardName = '';
            if (comment.riskStandardData != undefined && comment.riskStandardData != null) {
                if (comment.riskStandardData.objectiveData != undefined && comment.riskStandardData.objectiveData != null) {
                    objectiveId = comment.riskStandardData.objectiveData.objectiveId;
                    objectiveName = comment.riskStandardData.objectiveData.objectiveName;
                    standardId = comment.riskStandardData.objectiveData.standardId;
                    standardName = comment.riskStandardData.objectiveData.standardName;
                }
                if (comment.riskStandardData.componentData != undefined && comment.riskStandardData.componentData != null) {
                    componentId = comment.riskStandardData.componentData.componentId;
                    componentName = comment.riskStandardData.componentData.componentName;
                    if (standardId === '') {
                        standardId = comment.riskStandardData.componentData.standardId;
                    }
                    if (standardName === '') {
                        standardName = comment.riskStandardData.componentData.standardName;
                    }
                }
                if (comment.riskStandardData.principleData != undefined && comment.riskStandardData.principleData != null) {
                    principleId = comment.riskStandardData.principleData.principleId;
                    principleName = comment.riskStandardData.principleData.principleName;
                    if (standardId === '') {
                        standardId = comment.riskStandardData.principleData.standardId;
                    }
                    if (standardName === '') {
                        standardName = comment.riskStandardData.principleData.standardName;
                    }
                }
            }
            var altString = 'Standard Name : ' + standardName;
            if (objectiveName != '') {
                altString += '\nObjective Name : ' + objectiveName;
            }
            if (componentName != '') {
                altString += '\nComponent Name : ' + componentName;
            }
            if (principleName != '') {
                altString += '\nPrinciple Name : ' + principleName;
            }
            if (standardName.length > 10) {
                standardName = standardName.substring(0, 10) + '...';
            }

            console.log("++++++++++++++++++++++++++++altString+++++++++++++++++++++++");
            console.log(altString);
            console.log(standardName);

            return (
                <>

                    {
                        <span title={`${altString}`} style={{ cursor: 'pointer' }}>&nbsp;{standardName}</span>
                    }

                </>
            )
        }
        comment["riskColor"] = riskColor;
        comment["appetiteColor"] = appetiteColor;

        var createArr = pushToArray(comment);





        return (
            <>
                <Grid item xs={4}>
                    {noDisplaySpacesCount === 0 ?
                        <></>
                        : noDisplaySpacesCount === 1 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;</>
                            : noDisplaySpacesCount === 2 ?
                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                : noDisplaySpacesCount === 3 ?
                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                    : noDisplaySpacesCount === 4 ?
                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                        : noDisplaySpacesCount === 5 ?
                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                            : noDisplaySpacesCount === 6 ?
                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                : noDisplaySpacesCount === 7 ?
                                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                    : noDisplaySpacesCount === 8 ?
                                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                        : noDisplaySpacesCount === 9 ?
                                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                            : noDisplaySpacesCount === 10 ?
                                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                                : ''
                    }
                    <Button
                        style={{ width: '0px', height: '0px' }}
                        id={`btn` + comment.riskTypeTmpId}
                        onClick={() => setShow(!show)}
                        startIcon={show ? <ExpandMoreIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} /> : <ChevronRightIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} />}
                    >
                    </Button>
                    {noDisplay}{') '}
                    {!editRiskHirachyData?
                        comment.name
                    :
                    <TextField
                        size="small"
                        id={`txt` + comment.riskTypeTmpId}
                        label="Type/Edit Risk Name"
                        defaultValue={comment.name}
                        title={comment.name}
                        onChange={onTxtNameChageEvent}
                        style={{ height: '10px !important'}}
                    />
                    }
                </Grid>
                <Grid item xs={1}>
                {!editRiskHirachyData?
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLikelihood}</p></div></span>
                :
                <TextField
                    size="small"
                    id={`txtRiskLikelihood` + comment.riskTypeTmpId}
                    label="Likelihood"
                    defaultValue={comment.riskLikelihood}
                    title={comment.riskLikelihood}
                    onChange={onTextFieldChangeLikelihood}
                    //onBlur={onTextFieldChangeLikelihood}
                    style={{ height: '10px !important'}}
                />
                }

                </Grid>
                <Grid item xs={1}>
                {!editRiskHirachyData?
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskImpact}</p></div></span>
                    :
                    <TextField
                        size="small"
                        id={`txtRiskImpact` + comment.riskTypeTmpId}
                        label="Impact"
                        defaultValue={comment.riskImpact}
                        title={comment.riskImpact}
                        onChange={onTextFieldChangeImpact}
                        style={{ height: '10px !important'}}
                    />
                    }    
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLevel}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskColorAverage}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{subAvarageRisk}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px' }}><p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '9px' }}>{riskAction}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                {!editRiskHirachyData?
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${appetiteColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskAppetite}</p></div></span>
                    :
                    <TextField
                        size="small"
                        id={`txtRiskAppetite` + comment.riskTypeTmpId}
                        label="Appetite"
                        defaultValue={comment.riskAppetite}
                        title={comment.riskAppetite}
                        onChange={onTextFieldChangeAppetite}
                        style={{ height: '10px !important',backgroundColor:`${appetiteColor}`,width: '66px'}}
                    />
                    }    
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${appetiteColorAverage}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{subAvarageAppetite}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px' }}><p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '9px' }}>{appetiteAction}</p></div></span>
                </Grid>

                {/*riskDisplayStr*/}

                {
                    show ?
                        <>
                            {nestedComments}
                        </>
                        : null
                }
            </>

        )
    }


    /** End of Comment section */


    const [appetitesSliderValue, setAppetiteSliderValue] = React.useState();

    /* set global and custem standard for main risk */


    const [riskTypeDataForEval, setRiskTypeDataForEval] = React.useState({});
    const [openRiskDataForEval, setOpenRiskDataForEval] = useState(false);
    const [openRiskDataForEvalScroll, setOpenRiskDataForEvalScroll] = useState('paper');
    const [selectedRiskEvaluateTitle, setSelectedRiskEvaluateTitle] = useState('');

    const [fullScreenState, setFullScreenState] = useState(false);
    const [fullScreenStateMsg, setFullScreenStateMsg] = useState('Full Screen');
    const [maxWidthState, setMaxWidthState] = useState('lg');
    
    
    function setMinMaxWindowFunction() {
        if (!fullScreenState){
            setFullScreenState(true);
            setFullScreenStateMsg('Full Screen Out');
        }else{
            setFullScreenState(false);
            setFullScreenStateMsg('Full Screen');
        }

    }

    function assignExtraAttributes(data) {
        data.forEach((item) => {
          item["riskTypeTmpId"] = item.riskTypeId
          if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
            assignExtraAttributes(item.subRisks);
          }
        })
    }

    function evaluateRiskFuction(riskId) {
        riskTypeName.forEach((data) => {
            if (data.riskId === riskId) {
                setSelectedRiskEvaluateTitle(data.name)
                data.riskTypes.forEach((item, index) => {
                    item["riskTypeTmpId"] = item.riskTypeId;
                    if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                        assignExtraAttributes(item.subRisks);
                    }
                })
                console.log("+++++++++++++++++++++data++++++++++++++++++++++++")
                console.log(data)

                setRiskTypeDataForEval(data);
                setFullScreenState(false);
                setFullScreenStateMsg("Full Screen");


                colorPatterns.forEach((data1, index) => {
                    if (index === 0) {
                        setColorPatternId(data1.id);
                        setDatabClr(data1.colors)
                        setTdPresentage(100 / (data1.colors.length + 1))
        
                        setColorPatternName(data1.name);
                        setColorPatternPrimaryColorName(data1.primaryColorName);
                        setColorPatternSecondaryColorName(data1.secondaryColorName);
                    }
                })
                setColorSelectDropDownRiskLevelTmp(1)
                setColorSelectDropDownRiskAppetiteTmp(2)
                setBtnHirachivalClass(classes.btnNormal)
                setBtnGradientClass(classes.btnNormal)
                setBtnBarChartClass(classes.btnNormal)


                setOpenRiskDataForEval(true);
                setOpenRiskDataForEvalScroll('paper');
            }
        })
    }




    const [applyFlag, setApplyFlag] = useState(false)

    const [showEvalData, setShowEvalData] = useState(false)

    const [showTableType, setShowTableType] = useState()

    const [showTranferExlButton, setShowTranferExlButton] = useState(false)

    const [allData, setAllData] = useState([])

    function applyFunction() {
        setColorSelectDropDownRiskLevel(colorSelectDropDownRiskLevelTmp);
        setColorSelectDropDownRiskAppetite(colorSelectDropDownRiskAppetiteTmp)

        setShowEvalData(true)
        setApplyFlag(true)
        setShowTableType(1)
        setShowTranferExlButton(true)
        
        setBtnHirachivalClass(classes.btnActive)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnNormal)
        
    }

    function hierarchicalFunction(){
        setBtnHirachivalClass(classes.btnActive)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnNormal)
    }




    const [colorSelectDropDownRiskLevel, setColorSelectDropDownRiskLevel] = React.useState();
    const [colorSelectDropDownRiskLevelTmp, setColorSelectDropDownRiskLevelTmp] = React.useState();

    const [colorSelectDropDownRiskAppetite, setColorSelectDropDownRiskAppetite] = React.useState();
    const [colorSelectDropDownRiskAppetiteTmp, setColorSelectDropDownRiskAppetiteTmp] = React.useState();

    const handleChangeDropDown = (event) => {
        setColorSelectDropDownRiskLevelTmp(event.target.value);

    };

    const handleChangeDropDownAppetite = (event) => {
        setColorSelectDropDownRiskAppetiteTmp(event.target.value);
        //setShowEvalData(false)
    };

    function setOpenRiskDataForEvalfunction() {
        setOpenRiskDataForEval(false)
        setColorSelectDropDownRiskLevel()
        setColorSelectDropDownRiskAppetite()
        setApplyFlag(false)
        setShowEvalData(false)
        setColorPatternId('');
        setColorPatternPrimaryColorName('');
        setColorPatternSecondaryColorName('');
        setDatabClr([]);
        setBarData([]);
        setCommitArrayData([]);
        setCommitBarData([]);
        commitArray = [];
        setBtnHirachivalClass(classes.btnNormal);
        setBtnGradientClass(classes.btnNormal);
        setBtnBarChartClass(classes.btnNormal);
        setShowTranferExlButton(false)
        setEditRiskHirachy(false);
        setRiskTypeDataForEval({})


    }

    const [colorPatternId, setColorPatternId] = useState('')
    const [colorPatternName, setColorPatternName] = useState('')
    const [colorPatternPrimaryColorName, setColorPatternPrimaryColorName] = useState('')
    const [colorPatternSecondaryColorName, setColorPatternSecondaryColorName] = useState('')
    const [tdPresentage, setTdPresentage] = useState()

    const handleChangeColorPattern = (event) => {
        setColorPatternId(event.target.value);
        colorPatterns.forEach((data, index) => {
            if (data.id === event.target.value) {
                console.log(data)
                setDatabClr(data.colors)
                setTdPresentage(100 / (data.colors.length + 1))

                setColorPatternName(data.name);
                setColorPatternPrimaryColorName(data.primaryColorName);
                setColorPatternSecondaryColorName(data.secondaryColorName);
            }
        })
    };



    const [barData, setBarData] = React.useState([]);
    const [commitBarData, setCommitBarData] = React.useState([]);


    const [arrRiskColorState, setArrRiskColorState] = React.useState([]);
    const [arrColorAppState, setArrColorAppState] = React.useState([]);


    
    const [barChartHeight, setBarChartHeight] = React.useState(600);
    function createBarFunction() {


        var arr = [];
        if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
            //arr = commitArray.sort(compareRiskTypeOrder);
            arr = commitArray
        } else {
            if (commitBarData != undefined && commitBarData != null && commitBarData.length > 0) {
                arr = commitBarData;
            } else {
                arr = commitArrayData;
            }
        }

        setShowEvalData(true)
        setShowTableType(3)

        if (arr.length <=10){
            setBarChartHeight(600)
        }else{
            if (arr.length > 10 && arr.length <=17){
                setBarChartHeight(800)
            }else{
                if (arr.length > 17 && arr.length <=30){
                    setBarChartHeight(1000)
                }else{
                    setBarChartHeight(1200)
                }
            }    
        }


        var arr1 = [];
        var arrRiskColor = []
        var arrColorApp = []

        arr.forEach((data) => {
            var obj = {}
            var name = data.name;
            obj["argument"] = name;
            obj["riskLevel"] = parseInt(data.riskLevel);
            arrRiskColor.push(data.riskColor)
            obj["riskAppetite"] = parseInt(data.riskAppetite);
            arrColorApp.push(data.appetiteColor)
            arr1.push(obj)
        })
        setCommitBarData(arr);
        console.log(arr1)
        setBarData(arr1);
        setArrRiskColorState(arrRiskColor)
        setArrColorAppState(arrColorApp)


        setBtnHirachivalClass(classes.btnNormal)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnActive)

    }

    const Root = props => (
        <Legend.Root {...props} sx={{ display: 'flex', margin: 'auto', flexDirection: 'row' }} />
    );
    const Label = props => (
        <Legend.Label {...props} sx={{ whiteSpace: 'nowrap' }} />
    );


    const [editRiskHirachy, setEditRiskHirachy] = React.useState(false);

    function showDataInAViewFunction(no){
        
        setEditRiskHirachy(true);
        setWaitingOpen(true);
        setTimeout(() => {
            setWaitingOpen(false);
        }, 500)
        
        /*
        if (no === 1){
            var ele = document.getElementById("divHierarchicalViewId");
            //var ele = document.documentElement.innerHTML
            //alert(ele.innerHTML);
            var str = "";
            str += "<style>";
            str += "#content {";
            str += "    display: flex;";
            str += "  },";
            str += ".MuiGrid-grid-xs-4 {";
            str += "    flex-grow: 0 !important;";
            str += "    max-width: 33.333333%;"
            str += "    flex-basis: 33.333333% !important;";
            str += "  },";
            str += ".MuiGrid-spacing-xs-1 > .MuiGrid-item {";
            str += "    padding: 4px;";
            str += "  },";
            str += ".MuiGrid-item {";
            str += "    margin: 0;";
            str += "    box-sizing: border-box;";
            str += "  },";
            str += ".MuiGrid-grid-xs-1 {";
            str += "    flex-grow: 0;";
            str += "    max-width: 8.333333%;";
            str += "    flex-basis: 8.333333%;";
            str += "  }";
            str += "</style>";
            var myWindow = window.open("", "MsgWindow", "width=200,height=100");
            myWindow.document.write(str+ ele.innerHTML); 
        }
        */
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip" style={{backgroundColor:'#E2E4EC', padding:'3px 15px', border:'1px solid black', borderRadius:'5PX'}}>
              <p className="label" style={{fontWeight:'bold'}}>{`${label} `}</p>
              <p className="label">{`Risk Level : ${payload[0].value}`}</p>
              <p className="label">{`Risk Appetite : ${payload[1].value}`}</p>

            </div>
          );
        }
      
        return null;
      };


          /** Start Edit Section Section */

    const [openRiskDataEdit, setOpenRiskDataEdit] = React.useState(false);
    const [openRiskDataEditScroll, setopenRiskDataEditScroll] = React.useState('paper');

    const [selectedRiskId, setSelectedRiskId] = React.useState('');

    function editRiskFuction(id) {
        setSelectedRiskId(id)
       
        setOpenRiskDataEdit(true);
        setopenRiskDataEditScroll('paper');
    }
    const [waitingOpen, setWaitingOpen] = React.useState(false);

    function setOpenRiskDataEditfunction() {
        setOpenRiskDataEdit(false)
        setWaitingOpen(true);
        axios(CONFIG.MAIN_URL + `/risk/all`, {
            method: 'get',
            headers: {
              'Content-Type': 'text/plain',
            },
          })
          .then(function (response) {
              console.log("All Risk Returns");
              console.log(response.data);
              setWaitingOpen(false);
              var arr = [];
              response.data.forEach((item) => {
                if (centreId === item.centreId && item.riskId === selectedRiskId) {
                    setWaitingOpen(false);
                    setRiskTypeDataForEval(item);
                }
              })
      
          })
            .catch(function (error) {
                setWaitingOpen(false);
              console.log(error);
          });

    }


    function saveRisk() {
        setWaitingOpen(true);
        var data = JSON.stringify(riskTypeDataForEval);
        console.log("++++++++++++++++Saving data++++++++++++++++++++++");
        console.log(data);    
        
        axios(CONFIG.MAIN_URL + '/risk/save', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          data: data,
        })
          .then(function (response) {
            console.log("++++++++++++++Updatd Return+++++++++++");
            console.log(response.data);
            setWaitingOpen(false);
            alert("Risk Updated successfully");
            setEditRiskHirachy(false)
            //setOpenRiskDataEditfunction();
          })
          .catch(function (error) {
            setWaitingOpen(false);
            console.log(error);
            alert("Error saving Risk Type -:" + error);
          });
          


    }
    

    /** End of Edit Section */

    const risksTableRowData = (
        <TableBody>
            {riskTypeName.map((item, index) => {

                return (
                    <StyledTableRow key={index} >
                        <StyledTableCell component="th" scope="row"  style={{width:'90%'}}>
                            <div style={{ whiteSpace: 'normal' }} className='celltxt'>{item.name}</div>
                        </StyledTableCell>

                        <StyledTableCell
                            align="right">
                            <AssessmentOutlinedIcon
                            onClick={e => evaluateRiskFuction(item.riskId)}
                            style={{cursor: 'pointer'}}
                            />
                           
                                
                           
                        </StyledTableCell>
                    </StyledTableRow>
                )

            })}
        </TableBody>
    )




    return (
        <>
            {/* className={classes.pageTop} */}
            <div >
                <div>
                    <Grid container spacing={2} className={classes.pageBodyStyle}>

                    <Grid item xs={12}>

                            <TableContainer
                                component={Paper}
                                style={{maxHeight:maxWidthTable}}
                                
                            >
                                <TableHead className={classe.commonSecondaryTableHead}>
                                        <TableRow>
                                                <StyledTableCell className='celltxthead' style={{width: '90%'}}>Risks</StyledTableCell>
                                                <StyledTableCell align="right" className='celltxthead' style={{width: '10%'}}>Evaluate</StyledTableCell>
                                                
                                        </TableRow>
                                </TableHead>                     
                                <Table aria-label="customized table" style={{ border: '1px solid #ddd' }}>
                                    {risksTableRowData}
                                </Table>
                            </TableContainer>

                            </Grid>
                        {/* {
                            riskTypeName.map((item) => {
                                return (
                                    <Grid item xs={6}>
                                        <Card className={classes.collectionCardDBEval} style={{ marginTop: "7px" }}>
                                            <CardContent className={classes.collectionCardContentDB} style={{ height: '100px', overflowY: 'scroll' }}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" className={classes.collectionCardTitleDBEval}>{item.name}</Typography>
                                                </Grid>
                                            </CardContent>
                                            <Divider light />
                                            <CardActions style={{ justifyContent: 'center', backgroundColor: '#bac5dd' }}>
                                                <Button variant="outlined" className={classes.btnBorderAshDB}
                                                    onClick={e => evaluateRiskFuction(item.riskId)}
                                                >
                                                    Evaluate
                                                </Button>
                                            </CardActions>

                                        </Card>
                                    </Grid>
                                )
                            })
                        } */}
                    </Grid>
                </div>
            </div>



            <Dialog
          open={openRiskDataForEval}
          onClose={false}
          scroll={openRiskDataForEvalScroll}
          aria-labelledby="register-data-dialog-title"
          aria-describedby="register-data-dialog-description"
          fullWidth={true}
          maxWidth={'lg'}
          fullScreen={false}
          //PaperComponent={PaperComponentCollectionDataRegister}
          >
          <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
            <Typography variant="h6" style={{ fontSize: '25px !important' }}>Evaluate Risk Hierachy "{selectedRiskEvaluateTitle}" - <SavePageAs/></Typography>
            <Typography variant="h6" style={{ marginRight: '-22px', padding: '7px', marginTop: '-50px', float: 'right' }}>
              <Tooltip1 title="Close"><Close onClick={setOpenRiskDataForEvalfunction} style={{ cursor: 'pointer' }} /></Tooltip1>
            </Typography>
          </DialogTitle>
          <DialogContent dividers={openRiskDataForEvalScroll === 'paper'}>
            <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <EvaluateComponent
                      colorPatterns={colorPatterns}
                      registerTypeDataForEval={riskTypeDataForEval}
                      openRegisterDataForEvalScroll={openRiskDataForEvalScroll}
                      registerEvaluate={false}
                    />  
                  </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>        
          <DialogActions>
            <Button onClick={setOpenRiskDataForEvalfunction}>Cancel</Button>
          </DialogActions>
        </Dialog>





            {/** Open Evaluation dialog */}

            



                        {/** Edit Risk */}
                        <Dialog
                open={openRiskDataEdit}
                onClose={false}
                scroll={openRiskDataEditScroll}
                aria-labelledby="edit-data-dialog-title"
                aria-describedby="edit-data-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
                PaperComponent={PaperComponentEditData}
                >
                <DialogTitle style={{ cursor: 'move' }} id="edit-data-dialog-title">
                    <Typography variant="h6" style={{ fontSize: '25px !important' }}>Edit "{selectedRiskEvaluateTitle}"
                    </Typography>
                    <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                        <Tooltip title="Close"><Close onClick={setOpenRiskDataEditfunction} style={{ cursor: 'pointer' }} /></Tooltip>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={openRiskDataEditScroll === 'paper'}>
                    <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RiskHierarchyNew
                                    riskHierarchyId={selectedRiskId}
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={setOpenRiskDataEditfunction}>Cancel</Button>
                </DialogActions>
            </Dialog>


{/** Dialog Waiting */}
<Dialog
                maxWidth={'md'}
                open={waitingOpen}
                PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
            >
                <CircularProgress disableShrink />
            </Dialog>





        </>
    )
}

export default EvaluateRiskRiskComponent

