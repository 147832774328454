import React from 'react'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import RiskHierarchy from '../Components/RiskHierarchy';
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { ArrowBack } from '@mui/icons-material';
import RiskHierarchyNew from '../Components/RiskHierarchyNew';


const RiskTypeOption = () => {

  const [PageName, setPageName] = React.useState('Create And Edit a Risk Hierarchy');
  const navigate = useNavigate();


  return (
    <>
    <RightBarDataForOtherPages PageName={PageName}/>
   
    <div style={{marginTop:'120px'}}>
      <Grid item xs={12}>
               
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/login">
                      Home
                    </Link>
                    
                    <Typography color="text.primary">Main menu - Create And Edit a Risk Hierarchy </Typography>
                    
                  </Breadcrumbs>
                
      </Grid>
      <Grid item xs={12}>
        <a style={{float: 'right',marginTop: '-20px',color: '#0056b3', cursor:'pointer'}} onClick={() => navigate(-1)}><ArrowBack/> Go Back</a>
      </Grid>
      <Grid item xs={12}>
        <RiskHierarchy/>
      </Grid>  
    </div>
    </>
    
  )
}

export default RiskTypeOption