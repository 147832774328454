const jsonFormat1 =
{
    "$schema": "http://json-schema.org/draft-07/schema#",
    "type": "object",
    "properties": {
      "riskComponents": {
        "type": "array",
        "items": {
          "type": "object",
          "properties": {
            "componentName": {
              "type": "string"
            },
            "subRiskComponents": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "objectiveName": {
                    "type": "string"
                  },
                  "subSubRiskComponents": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "principleName": {
                          "type": "string"
                        }
                      },
                      "required": [
                        "principleName"
                      ]
                    }
                  }
                },
                "required": [
                  "objectiveName",
                  "subSubRiskComponents"
                ]
              }
            }
          },
          "required": [
            "componentName",
            "subRiskComponents"
          ]
        }
      }
    },
    "required": [
      "riskComponents"
    ]
}

export default jsonFormat1;      
