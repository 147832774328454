import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, RadioGroup } from "@material-ui/core";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import titleImage from '../assets/risktitle.jpg';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { red, green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useLocation } from 'react-router-dom';
import Links from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip'
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Close, Save, SaveAltSharp, SmartDisplayRounded } from '@mui/icons-material';
import axios from 'axios';
import {CONFIG} from '../config';
import Draggable from 'react-draggable'; 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import FormLabel from '@mui/material/FormLabel';
import { FormControlLabel } from '@material-ui/core';
import { FormGroup } from '@material-ui/core';
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';


const QuestionerMgtPageItemOthers = ({conSize, rsType, setRsTtpe, riskTypeData, quesData, classes, subHandleClickOpen, subOpen, subHandleClose, subScroll, descriptionElementRef, collectnId, collectionUniqueName,databClr}) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));

      function PaperComponent(props) {
        return (
          <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper {...props} />
          </Draggable>
        );
      }
      
      function getRiskLvlColor(lvlData){
        var colorCode = ''
        databClr.forEach((data2)=>{         
          if (parseInt(data2.scoreNumber) === parseInt(lvlData)) {
            colorCode = data2.color;
          }
        })
        return colorCode;
      }
      
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        
    }));  
      console.log(quesData);

    const [rows, setRows] = useState([]);

    useEffect(() => {
        rowSettings();
    }, []);

    function rowSettings(){
        var count = 1;
        var tmpArray = [];
        setRows([]);
        quesData.forEach((item)=>{
            var tmpObj = {};
            tmpObj["questionId"] = item.questionId;
            tmpObj["collectionId"] = collectnId;
            tmpObj["collectionUniqueName"] = collectionUniqueName;
            tmpObj["id"] = count;
            var riskScrumbStr = RiskScrumbTable( item.riskTypes.name, item.riskTypes.collectionRiskId, riskTypeData )

            tmpObj["riskName"] = riskScrumbStr;

            tmpObj["objectiveName"] = item.objective.name;
            tmpObj["componentName"] = item.component.name;
            tmpObj["principalName"] = item.principle.name;
            tmpObj["entityName"] = item.entity.name;
            tmpObj["question"] = item.question;
            tmpObj["questionType"] = item.questionType;
            tmpObj["questionScore"] = item.questionScore;
            tmpArray.push(tmpObj);
            setRows(tmpArray);
            count ++;
        })

    }

    const [selectedQuesData, setSelectedQuesData] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [quesDataCount, setQuesDataCount] = useState(0);
    const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
    const [questionDetailScroll, setQuestionDetailScroll] = React.useState('paper');
  
      function parseId(no,questionId, collectionId, question){
        var tmpArray = [];
        setQuesDataCount(no);
        setSelectedQuestion(question);
        quesData.forEach((item)=>{
            if (item.questionId === questionId) {
            tmpArray.push(item);
            setSelectedQuesData(tmpArray);
            }
        });  
  
        setQuestionDetailOpen(true);
        setQuestionDetailScroll('paper');
  
      }
      
      const questionDetailHandleClickClose = () => {
        setQuestionDetailOpen(false);
      };
  

    function RiskScrumbTable( riskName, collectionRiskId, riskTypeData ) {
        var rsTypeArr = [];
        riskTypeData.forEach((data)=>{
            if (data.collectionRiskId === collectionRiskId){
                rsTypeArr = data.riskTypes;
            }
        });  
        var riskScrumbPath  = "";
        rsTypeArr.forEach((item)=>{
            var pathStr = "";
            pathStr = getRiskScrumbTable(item,riskName);
            if (pathStr != undefined && pathStr != ""){
                riskScrumbPath = pathStr;
                return true;
            }
        });  
        return riskScrumbPath;
      }
  
      // *Risk Scrumb****
  function getRiskScrumbTable(comment,riskName){
    var riskScrumbPath = "";
    if (riskName === comment.name){
        riskScrumbPath =  riskName;
    }else{
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
            var riskPath = comment.name;
            var subRiskPath =  getSubRiskScrumbTable(comment.subRisks,riskName);
            if (subRiskPath != ""){
                riskScrumbPath = riskPath + subRiskPath;
            }
        }
    }
    return riskScrumbPath;
  }
  function getSubRiskScrumbTable(subData,riskName){
    var tmpSubPath = "";
    subData.forEach((item,index)=>{
        if (item.name === riskName){
            tmpSubPath += " --> " + item.name;
            return true; 
        }else{
            if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
                var tmpSubPath1 = " --> " + item.name;
                var tmpSubSubPath1 = getSubRiskScrumbTable(item.subRisks,riskName);
                if (tmpSubSubPath1 != ""){
                    tmpSubPath += tmpSubPath1 + tmpSubSubPath1;
                }
            }    
        }
    });
    return tmpSubPath;
  }    
  


  const renderEditButton = (params) => {
    return (
        <strong>
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                    editQuestion(params.row.questionId,params.row.collectionUniqueName)
                }}
            >
                Edit
            </Button>
        </strong>
    )
}
function editQuestion(questionId, collectionUniqueName){
    let a= document.createElement('a');
    a.target= '_self';
    a.href= '/questioPage/' + collectionUniqueName + "?questionid=" + questionId;
    a.click();
}


    const renderDetailsButton = (params) => {
      return (
          <strong>
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                      parseId(params.row.id,params.row.questionId,params.row.collectionId,params.row.question )
                  }}
              >
                  More Info
              </Button>
          </strong>
      )
  }

  const renderTextRiskName = (params) => {
    return (
        <Tooltip title={params.row.riskName}>
        <span className={classes.fontZise10}>{params.row.riskName}</span>        
        </Tooltip>
    )
  }
  const renderTextId = (params) => {
    return (
        <span className={classes.fontZise10}>{params.row.id}</span>        
    )
  }
  const renderTextObjectiveName = (params) => {
    return (
        <Tooltip title={params.row.objectiveName}>
        <span className={classes.fontZise10}>{params.row.objectiveName}</span>        
        </Tooltip>
    )
  }
  const renderTextComponentName = (params) => {
    return (
        <Tooltip title={params.row.componentName}>
        <span className={classes.fontZise10}>{params.row.componentName}</span>        
        </Tooltip>
    )
  }
  const renderTextPrincipalName = (params) => {
    return (
        <Tooltip title={params.row.principalName}>
        <span className={classes.fontZise10}>{params.row.principalName}</span>        
        </Tooltip>
    )
  }
  const renderTextQuestion = (params) => {
    return (
        <Tooltip title={params.row.question}>
        <span className={classes.fontZise10}>{params.row.question}</span>        
        </Tooltip>
    )
  }
  const renderTextEntity = (params) => {
    return (
        <Tooltip title={params.row.entityName}>
        <span className={classes.fontZise10}>{params.row.entityName}</span>        
        </Tooltip>
    )
  }

  const renderTextQuestionType = (params) => {
    return (
        <span className={classes.fontZise10}>{params.row.questionType}</span>        
    )
  }
  const renderTextQuestionScore = (params) => {
    var riskLvlColor = getRiskLvlColor(params.row.questionScore)
    return (
        <span className={classes.fontZise10} style={{color: riskLvlColor}}>{params.row.questionScore}</span>        
    )
  }



    const columns = [
      { field: 'id', headerName: 'No', renderCell: renderTextId, resizable: true, width: 15 },
      { field: 'questionId', headerName: 'No', width: 15, hide: true },
      { field: 'collectionId', headerName: 'No', width: 15, hide: true },
      { field: 'collectionUniqueName', headerName: 'No', width: 15, hide: true },      
      { field: 'riskName', headerName: 'Risk', renderCell: renderTextRiskName, resizable: true, cellClassName: 'super-app-theme--cell', width: 180 },
      { field: 'objectiveName', headerName: 'Objective', renderCell: renderTextObjectiveName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
      { field: 'componentName', headerName: 'Component', renderCell: renderTextComponentName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
      { field: 'principalName', headerName: 'Principal', renderCell: renderTextPrincipalName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
      { field: 'entityName', headerName: 'Entity', renderCell: renderTextEntity, cellClassName: 'super-app-theme--cell', resizable: true, width: 90 },
      { field: 'question', headerName: 'Question', renderCell: renderTextQuestion, cellClassName: 'super-app-theme--cell', resizable: true, width: 200 },
      { field: 'questionType', headerName: 'Type', renderCell: renderTextQuestionType, resizable: true, width: 80 },
      { field: 'questionScore', headerName: 'Score', renderCell: renderTextQuestionScore, resizable: true, width: 50 },
      {
        field: 'action',
        headerName: 'More Info',
        sortable: false,
        renderCell: renderDetailsButton,
        disableClickEventBubbling: true,
      
      },
      {
        field: 'edit',
        headerName: 'Edit',
        sortable: false,
        renderCell: renderEditButton,
        disableClickEventBubbling: true,
      
      }
    
    ];


    /** Only upto this is needed Others vhave to be deleted */

      
      var riskArr = [];
      var entityArr = [];
      var objectiveArr = [];
      var componentArr = [];
      var principleArr = [];
      quesData.forEach((data)=>{
        riskArr.push(data.riskTypes);
        entityArr.push(data.entity);
        objectiveArr.push(data.objective);
        componentArr.push(data.component);
        principleArr.push(data.principle);
      })

      console.log("**************riskArr*************");
      console.log(riskArr);


      /** Risk and entity for searck */
      const [mainRiskArr, setMainRiskArr] = useState(getUnique(riskArr,'collectionRiskTypeId'));
      const [mainEntityArr, setMainEntityArr] = useState(getUnique(entityArr,'name'));
      const [mainObjectiveArr, setMainObjectiveArr] = useState(getUnique(objectiveArr,'name'));
      const [mainComponentArr, setMainComponentArr] = useState(getUnique(componentArr,'name'));
      const [mainPrincipleArr, setMainPrincipleArr] = useState(getUnique(principleArr,'name'));
      
      function getUnique(arr, index) {
        const unique = arr
             .map(e => e[index])
             // store the keys of the unique objects
             .map((e, i, final) => final.indexOf(e) === i && i)
             // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);      
         return unique;
      }

      const [isLoaded, setIsLoaded] = useState(false);
      const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

      useEffect(() => {
        setIsLoaded(true);
        //getRiskType()
      }, []);

      React.useEffect(() => {
        if (isLoaded) {
            setIsPageLoaded(true);
        }
      }, [isLoaded]);


/**
       * get risktypes
       */

 const [serverRiskType, setServerRiskType] = useState([]);
 const [recursiveEvent, setRecursiveEvent] = useState(true);


     /** select box*/

     const [hierarchyId, setHierarchyId] = React.useState();
     const handleChangeHierarchy = (event) => {
         var hierarchyId = event.target.value;
         setHierarchyId(event.target.value)
         riskTypeData.forEach((item,index)=>{
             if (item.collectionRiskId === hierarchyId) {  
                 setRsTtpe(item.riskTypes);
             }
         })
     };


    const [riskTypeName, setRiskTypeName] = React.useState('');
    const [selectedRiskSortName, setSelectedRiskSortName] = React.useState('All Risk Types');
    const [selectedRiskSortId, setSelectedRiskSortId] = React.useState('');
    const [selectedCollectionRiskSortId, setSelectedCollectionRiskSortId] = React.useState('');
    const [selectedRiskSortIdArray, setSelectedRiskSortIdArray] = React.useState([]);
    const [selectedRiskSortArray, setSelectedRiskSortArray] = React.useState([]);

    const [openRiskSort, setOpenRiskSort] = useState(false);
    const [riskSortBtnTop, setRiskSortBtnTop] = useState(0);
    const [riskSortBtnLeft, setRiskSortBtnLeft] = useState(0);


    const riskTypehandleChange = (event) => {

        var textStr = event.rangeParent.data;
        if (textStr === "Select"){
            setSelectedRiskSortName('');
        }else{
            setSelectedRiskSortName(textStr);
        }
        if (event.target.value != ""){
            setSelectedCollectionRiskSortId(event.target.value);
        }else{
            setSelectedCollectionRiskSortId('');
        }
        

    };

    function setOpenRiskSortFuction(flag) {
        var btnId = document.getElementById("riskBtnId");
        var lastGridId = document.getElementById("lastGridId");
        var top = (btnId.offsetTop-lastGridId.offsetTop);
        setRiskSortBtnTop(top);
        setRiskSortBtnLeft(btnId.offsetLeft);
        setOpenRiskSort(flag);
    }
    function getQuestionCount(comment,type){
        var count = 0;
        var riskIds = "";
        if (type === 1){
            if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                comment.questions.forEach((quesItem)=>{
                    if (quesItem.questionType === "Closeended"){
                        count ++; 
                    }
                })  
            }
        }
        if (type === 2){
            count = comment.questions.openEdndedQuestions.length;
            if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                comment.questions.forEach((quesItem)=>{
                    if (quesItem.questionType === "Openended"){
                        count ++; 
                    }
                })  
            }
        }  
        if (type === 3){
            /*
            if (comment.questions.closeEndedQuestions.length > 0 || comment.questions.openEdndedQuestions.length > 0){
                riskIds = comment.collectionRiskTypeId;
            }
            */
            if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                riskIds = comment.collectionRiskTypeId;
            }
        }     
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
            return getQuestionSubLength(comment.subRisks,count,type,riskIds);
        }else{
            if (type === 3){
                return riskIds;
            }else{
                return count;
            }    
            
        }    
    }
    function getQuestionSubLength(subData,count,type,riskIds ){
        subData.forEach((item,index)=>{
            if (type === 1){
                if (item.questions != undefined && item.questions != null && item.questions.length > 0){
                    item.questions.forEach((quesItem)=>{
                        if (quesItem.questionType === "Closeended"){
                            count ++; 
                        }
                    })  
                }
            }
            if (type === 2){
                if (item.questions != undefined && item.questions != null && item.questions.length > 0){
                    item.questions.forEach((quesItem)=>{
                        if (quesItem.questionType === "Openended"){
                            count ++; 
                        }
                    })  
                }
            }
            if (type === 3){
                /*
                if (item.questions.closeEndedQuestions.length > 0 || item.questions.openEdndedQuestions.length > 0){
                    riskIds += "@" + item.collectionRiskTypeId;
                }
                */
                if (item.questions != undefined && item.questions != null && item.questions.length > 0){
                    riskIds += "@" + item.collectionRiskTypeId;
                }
            }
                
            if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
                if (type === 3){
                    riskIds = getQuestionSubLength(item.subRisks,count,type,riskIds);
                }else{
                    count = getQuestionSubLength(item.subRisks,count,type,riskIds);
                }   
            }
        });
        if (type === 3){
            return riskIds;
        }else{
            return count;
        }    
        
    }    

    function RiskSort({ key,comment,isMain }) {
       console.log(comment)
       
       var closeEndedLength = getQuestionCount(comment,1);
       var openEndedLength = getQuestionCount(comment,2);
       //var closeEndedLength = 0;
       //var openEndedLength = 0;

       var selectedRiskIds = getQuestionCount(comment,3);
       
       const [show, setShow] = React.useState(true)
       const nestedComments = (comment.subRisks || []).map(comment => {
           return <RiskSort key={comment.collectionRiskTypeId} comment={comment} isMain={false} type="child" />
       })
   
       return (
           <div style={{"marginLeft": "15px", "marginTop": "10px"}}>
                <div style={{display:'flex',textAlign:'center'}}> 
                   <Button 
                       style={{width:'0px', height:'0px', marginTop:'14px'}} 
                       onClick={()=>setShow(!show)} 
                       startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                       >
                   </Button>
                   
                   <span style={{marginTop:'-7px !important'}} >
                   <Radio
                       checked={selectedCollectionRiskSortId === `${comment.collectionRiskTypeId}`}
                       onChange={riskTypehandleChange}
                       value={`${comment.riskTypeId === undefined? "":comment.riskTypeId}]${comment.name}]${comment.riskLevel}]${comment.riskAppetite}]${selectedRiskIds}]${comment.collectionRiskTypeId}` }
                       name="radio-buttons"
                       inputProps={{ 'aria-label': 'A' }}
                       style={{marginLeft:'-1px !important'}} 
                       />
                       {comment.name + (closeEndedLength === 0 && openEndedLength === 0 ?'' : ' (' )   + (closeEndedLength > 0 ?' C : '
                        + closeEndedLength:'')+ (openEndedLength > 0 
                           ?' O : ' + openEndedLength:'') + (closeEndedLength === 0 && openEndedLength === 0 ?'' : ' )' ) 
                       } 
                    </span>    
                </div>
               {
                   show?
                       <>
                           {nestedComments}
                       </>
                   :null
               }
       </div>
     )
   }

   const [selectedRiskEvalArray, setSelectedRiskEvalArray] = React.useState([]);
   function checkSelected(id, name, closeIds,openIds){

        if ( document.getElementById("chkRiskType" + id).checked) {
            var tmpArray = {};
            tmpArray["collectionRiskTypeId"] = id;
            var ar = [];
            closeIds.forEach((data)=>{
                ar.push(data);
            });    
            openIds.forEach((data)=>{
                ar.push(data);
            }); 
            tmpArray["collectionQuestionIds"] = ar;
            tmpArray["name"] = name;
            selectedRiskEvalArray.push(tmpArray);
            setSelectedRiskEvalArray(selectedRiskEvalArray);
        }else{
            selectedRiskEvalArray.forEach((data, index)=>{
                if (data.collectionRiskTypeId === id) { 
                    selectedRiskEvalArray.splice(index, 1);  
                    setSelectedRiskEvalArray(selectedRiskEvalArray);
                }
            })
        }    
    
    }

    function getQuestionIds(comment,type){
        var count = 0;
        var ids = [];
        if (comment.questions != undefined && comment.questions!= null){
            if (type === 1){
                if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                    comment.questions.forEach((quesItem)=>{
                        if (quesItem.questionType === "Closeended"){
                            ids.push(quesItem.questionId);
                        }
                    })  
                }
                /*
                if (comment.questions.closeEndedQuestionIds != undefined && comment.questions.closeEndedQuestionIds != null && comment.questions.closeEndedQuestionIds.length > 0){
                    comment.questions.closeEndedQuestionIds.forEach((item,index)=>{
                        ids.push(item.questionId);
                    });
                }else{
                    ids = [];
                }
                */
            }
            if (type === 2){
                if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                    comment.questions.forEach((quesItem)=>{
                        if (quesItem.questionType === "Openended"){
                            ids.push(quesItem.questionId);
                        }
                    })  
                }
                /*
                if (comment.questions.openEdndedQuestionIds != undefined && comment.questions.openEdndedQuestionIds != null && comment.questions.openEdndedQuestionIds.length > 0){
                    comment.questions.openEdndedQuestionIds.forEach((item,index)=>{
                        ids.push(item.questionId);
                    });
                }else{
                    ids = [];
                }
                */
            }
        }else{
            ids = [];
        }
        return ids;
    }

    function getQuestionIdsWithSubRisks(comment,type){
        var count = 0;
        var riskIds = "";
        var ids = [];
        if (type === 1){
            if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                comment.questions.forEach((quesItem)=>{
                    if (quesItem.questionType === "Closeended"){
                        ids.push(quesItem.questionId);
                    }
                })  
            }
            /*
            if (comment.questions != undefined && comment.questions!= null){
                if (comment.questions.closeEndedQuestionIds != undefined && comment.questions.closeEndedQuestionIds != null && comment.questions.closeEndedQuestionIds.length > 0){
                    comment.questions.closeEndedQuestionIds.forEach((item,index)=>{
                        ids.push(item.questionId);
                    });
                }else{
                    ids = [];
                }
            }else{
                ids = [];
            }
            */

        }
        if (type === 2){
            if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                comment.questions.forEach((quesItem)=>{
                    if (quesItem.questionType === "Openended"){
                        ids.push(quesItem.questionId);
                    }
                })  
            }
            /*
            if (comment.questions != undefined && comment.questions!= null){
                if (comment.questions.openEdndedQuestionIds != undefined && comment.questions.openEdndedQuestionIds != null && comment.questions.openEdndedQuestionIds.length > 0){
                    comment.questions.openEdndedQuestionIds.forEach((item,index)=>{
                        ids.push(item.questionId);
                    });
                }else{
                    ids = [];
                }
            }else{
                ids = [];
            }
            */
        }
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
            return getQuestionSubIdsWithSubRisks(comment.subRisks,type,ids);
        }else{
            return ids;
        }
    }
    function getQuestionSubIdsWithSubRisks(comment,type,ids ){
        comment.forEach((data,index)=>{
            if (type === 1){
                if (data.questions != undefined && data.questions!= null){
                    if (data.questions.closeEndedQuestionIds != undefined && data.questions.closeEndedQuestionIds != null && data.questions.closeEndedQuestionIds.length > 0){
                        data.questions.closeEndedQuestionIds.forEach((item,index)=>{
                            ids.push(item.questionId);
                        });
                    }
                }
            }
            if (type === 2){
                if (data.questions != undefined && data.questions!= null){
                    if (data.questions.openEdndedQuestionIds != undefined && data.questions.openEdndedQuestionIds != null && data.questions.openEdndedQuestionIds.length > 0){
                        data.questions.openEdndedQuestionIds.forEach((item,index)=>{
                            ids.push(item.questionId);
                        });
                    }
                }
            }
            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0){
                getQuestionSubIdsWithSubRisks(data.subRisks,type,ids);
            }
        });
        return ids;
        
    }


   function RiskEval({ comment }) {
        console.log(comment)
    
        var closeEndedQuestionIds = getQuestionIds(comment,1);
        var openEndedQuestionIds = getQuestionIds(comment,2);
        
        var closeEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,1);
        var openEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,2);

    
    const [show, setShow] = React.useState(false)
    const nestedComments = (comment.subRisks || []).map(comment => {
        return <RiskEval key={comment.collectionRiskTypeId} comment={comment} type="child" />
    })

    return (
        <div style={{"marginLeft": "15px", "marginTop": "10px"}}>
             <div style={{display:'flex',textAlign:'center'}}> 
                <Button 
                    style={{width:'0px', height:'0px', marginTop:'14px'}} 
                    onClick={()=>setShow(!show)} 
                    startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    >
                </Button>
                
                <span style={{marginTop:'-7px !important'}} >

                <FormControlLabel control={<Checkbox onClick={()=>checkSelected(comment.collectionRiskTypeId,comment.name,show?closeEndedQuestionIds:closeEndedQuestionIdsWithSub,show?openEndedQuestionIds:openEndedQuestionIdsWithSub )} id={"chkRiskType" + comment.collectionRiskTypeId}/>} 
                label={show?comment.name+ (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' (' )   + (closeEndedQuestionIds.length > 0 ?' C : '
                + closeEndedQuestionIds.length:'')+ (openEndedQuestionIds.length > 0 
                   ?closeEndedQuestionIds.length > 0 ?', O : ' + openEndedQuestionIds.length:' O : ' + openEndedQuestionIds.length:'') + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' )' )
                 :
                 comment.name+ (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' (' )   + (closeEndedQuestionIdsWithSub.length > 0 ?' C : '
                + closeEndedQuestionIdsWithSub.length:'')+ (openEndedQuestionIdsWithSub.length > 0 
                   ?closeEndedQuestionIdsWithSub.length > 0 ?', O : ' + openEndedQuestionIdsWithSub.length:' O : ' + openEndedQuestionIdsWithSub.length:'') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' )' )   
                }
                
                />


 
                 </span>    
             </div>
            {
                show?
                    <>
                        {nestedComments}
                    </>
                :null
            }
        </div>
        )
    }

   
     const [entityName, setEntityName] = React.useState('');
     const [entityNameId, setEntityNameId] = React.useState('');
     const entityHandleChange = (event) => {
        var textStr = event.rangeParent.data;
        if (textStr === "Select"){
            setEntityName('');
        }else{
            setEntityName(textStr);
        }
        setEntityNameId(event.target.value);
        console.log(event.target.value);
     };
     const [objectiveName, setObjectiveName] = React.useState('');
     const [objectiveId, setObjectiveId] = React.useState('');
     const objectiveHandleChange = (event) => {
        var textStr = event.rangeParent.data;
        if (textStr === "Select"){
            setObjectiveName('');
        }else{
            setObjectiveName(textStr);
        }
        setObjectiveId(event.target.value);
     };
     const [componentName, setComponentName] = React.useState('');
     const [componentId, setComponentId] = React.useState('');
     const componentHandleChange = (event) => {
        var textStr = event.rangeParent.data;
        if (textStr === "Select"){
            setComponentName('');
        }else{
            setComponentName(textStr);
        }
         setComponentId(event.target.value);
     };
     const [principleName, setPrincipleName] = React.useState('');
     const [principleId, setPrincipleId] = React.useState('');
     const principleHandleChange = (event) => {
         var textStr = event.rangeParent.data;
         if (textStr === "Select"){
            setPrincipleName('');
        }else{
            setPrincipleName(textStr);
        }

         setPrincipleId(event.target.value);
     };



    if (isPageLoaded) {
        setTimeout(loadAfterTime, 5000)
    }  

    function loadAfterTime(){
        quesData.forEach((data)=>{
            if (data.answers != undefined && data.answers != null && data.answers.answerList != undefined && data.answers.answerList != null && data.answers.answerList.length > 0){
                data.answers.answerList.forEach((item,index)=>{
                    if (data.answers.answerMethod.name === "radioButtonType") {
                        var id = "radio" + data.questionId + index;
                        if (item.expectedAnswerName === "Yes"){
                            var getId = document.getElementById(id);
                            if (getId != undefined && getId != null) {
                                document.getElementById(id).checked = true;
                            } 
                        }
                    }
                    if (data.answers.answerMethod.name === "checkBoxType") {
                        var id = "chk" + data.questionId + index;
                        if (item.expectedAnswerName === "checked"){
                            var getId = document.getElementById(id);
                            if (getId != undefined && getId != null) {
                                document.getElementById(id).checked = true;
                            } 
                        }
                    }
                }) 
            }
            
        })
    }
    function checkedRadio(qId, id){
        quesData.forEach((data)=>{
            if (data.questionId === qId){
                data.answers.answerList.forEach((item,index)=>{
                    var idGet = "radio" + data.questionId + index;
                    if (id === idGet){
                        document.getElementById(idGet).checked = true;
                    }else{
                        document.getElementById(idGet).checked = false;
                    }
                })    
            }
        })  
    }
    function saveChanges(quesId){
        var jsonStr = "";
        quesData.forEach((data)=>{
            if (data.questionId === quesId){
                jsonStr += "{\"collectionUniqueName\":\"" + collectionUniqueName + "\",";
                jsonStr += "\"questionId\":\"" + quesId + "\",";
                if (data.mainQuestionId != undefined && data.mainQuestionId != null){
                    jsonStr += "\"mainQuestionId\":\"" + data.mainQuestionId + "\",";
                }
                jsonStr += "\"question\":" + "\"" + data.question + "\",";
                jsonStr += "\"questionType\":" + "\"" + data.questionType + "\",";
                jsonStr += "\"questionScore\":" + data.questionScore + ",";
                if (data.questionType === "Openended"){
                    jsonStr += "\"questionTypeName\":" + "\"" + "" + "\",";
                }
                if (data.questionType === "Closeended"){
                    jsonStr += "\"questionTypeName\":" + "\"" + data.questionTypeName + "\",";    
                }
                jsonStr += "\"metaInfo\":{\"status\":" + "\"" + data.metaInfo.status + "\"},";
                jsonStr += "\"riskTypes\":{\"collectionRiskTypeId\":\"" + data.riskTypes.collectionRiskTypeId + "\", \"collectionRiskId\":\"" + data.riskTypes.collectionRiskId + "\", \"name\":"+ "\"" + data.riskTypes.name + "\"},";
                jsonStr += "\"objective\":{\"standardName\":" + "\"" + data.objective.standardName + "\", \"name\":"+ "\"" + data.objective.name + "\"},";
                jsonStr += "\"component\":{\"standardName\":" + "\"" + data.component.standardName + "\", \"name\":"+ "\"" + data.component.name + "\"},";
                jsonStr += "\"principle\":{\"standardName\":" + "\"" + data.principle.standardName + "\", \"name\":"+ "\"" + data.principle.name + "\"},";
                jsonStr += "\"entity\":{\"standardId\":" + "\"" + data.entity.standardId + "\",\"standardName\":" + "\"" + data.entity.standardName + "\",\"entityId\":" + "\"" + data.entity.entityId + "\", \"name\":"+ "\"" + data.entity.name + "\"},";
                jsonStr += "\"answers\":{";
                jsonStr += "\"answerMethod\":{\"name\":" + "\"" + data.answers.answerMethod.name + "\", \"minValue\":"+ "\"" + data.answers.answerMethod.minValue + "\", \"maxValue\":"+ "\"" + data.answers.answerMethod.maxValue + "\"},";
                if (data.questionType === "Openended"){
                    var ansStr = document.getElementById("txt" + data.questionId).value
                    jsonStr += "\"answer\":" + "\"" + ansStr + "\"";
                    jsonStr += "}";
                }else{
                    jsonStr += "\"answerScaleType\":{\"name\":" + "\"" + data.answers.answerScaleType.name + "\"},";
                    jsonStr += "\"answerList\":[";
                    data.answers.answerList.forEach((item,index)=>{
                        var expctedAns = "";
                        if (data.answers.answerMethod.name === "radioButtonType"){
                            if ( document.getElementById("radio" + data.questionId + index).checked) {
                                expctedAns = "Yes";
                            }
                        }
                        if (data.answers.answerMethod.name === "checkBoxType"){
                            if ( document.getElementById("chk" + data.questionId + index).checked) {
                                expctedAns = "checked";
                            }
                        }
                        if (data.answers.answerMethod.name === "orderNumberType"){
                            expctedAns =  document.getElementById("select" + data.questionId + index).value;
                        }
                        if (data.answers.answerMethod.name === "answerTypeType"){
                            expctedAns =  document.getElementById("txt" + data.questionId + index).value;
                        }
                        if (expctedAns === undefined || expctedAns === null || expctedAns === "null"){
                            expctedAns = "";
                        }
                        if (data.answers.answerList.length -1 === index) {
                            jsonStr += "{\"answerName\":" + "\"" + item.answerName + "\", \"answerType\":"+ "\"" + item.answerType + "\", \"riskLevel\":" + item.riskLevel + ", \"expectedAnswerName\":"+ "\"" + expctedAns + "\"}";
                        }else{
                            jsonStr += "{\"answerName\":" + "\"" + item.answerName + "\", \"answerType\":"+ "\"" + item.answerType + "\", \"riskLevel\":" + item.riskLevel + ", \"expectedAnswerName\":"+ "\"" + expctedAns + "\"},";
                        }
                    })
                    jsonStr += "]";
                    jsonStr += "}";
                }

                jsonStr += "}";
                console.log(jsonStr);
            }
        })
        if (jsonStr != ""){
            
            axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_QUESTION+'/update',{
                method: 'post',
                headers: { 
                    'Content-Type': 'application/json',
                  },
                data : jsonStr,  
            })
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                alert("Expert Answer Updated successfully");
            })
            .catch(function (error) {
                console.log(error);
                alert("Error saving Question -:" + error);
            });
            
        }
    }


    const [openRisk, setOpenRisk] = useState(false);
    const [riskDivTop, setRiskDivTop] = useState(0);
    const [riskDivLeft, setRiskDivLeft] = useState(0);
    const [riskDivRiskName, setRiskDivRiskName] = useState(0);
    const [selectedRiskName, setSelectedRiskName] = React.useState('');
    const [selectedRiskId, setSelectedRiskId] = React.useState(0);

    const [rsTypeItemHierarchy, setRsTypeItemHierarchy] = React.useState([]);

    function setOpenRiskFuction(riskFlag,index, rName, riskTypeId, collectionRiskId, collectionRiskTypeId) {

        var cardId = document.getElementById("cardId" + index);
        var displayRiskId = document.getElementById("displayRiskId" + index);
        var lastGridId = document.getElementById("lastGridId");
        var top = (displayRiskId.offsetTop-(lastGridId.offsetTop + 20));
        var left = (displayRiskId.offsetLeft - 235);
        //alert(displayRiskId.offsetLeft)

        var rsTypeArr = [];
        riskTypeData.forEach((data)=>{
            if (data.collectionRiskId === collectionRiskId){
                rsTypeArr = data.riskTypes;
            }
        });  
        setRsTypeItemHierarchy(rsTypeArr);

        setRiskDivTop(top);
        setRiskDivLeft(left);
        setRiskDivRiskName(rName);
        setOpenRisk(riskFlag);
    }
    const [mainRiskMenu, setMainRiskMenu] = useState([]);

    const riskHandleChange = (event) => {
        let idAndVal=event.target.value;
        let idAndValArr=idAndVal.split("]");
        setSelectedRiskId(idAndValArr[0]);
        setSelectedRiskName(idAndValArr[1]);

        setRiskTypeName(idAndValArr[0]);
        var arryObj = [];
        var tmpArray = {};
        tmpArray["id"] = idAndValArr[0];
        tmpArray["name"] = idAndValArr[1];
        arryObj.push(tmpArray);
        setMainRiskMenu(arryObj);
        setOpenRisk(false);
    };

    /** Sort Assending/Decending question */
    function compareQa( a, b ) {
        var nameA = a.question.toUpperCase(); // ignore upper and lowercase
        var nameB = b.question.toUpperCase(); // ignore upper and lowercase
        if ( nameA < nameB ){ return -1;}
        if ( nameA > nameB ){ return 1;}
        return 0;
    }
    function compareQd( a, b ) {
        var nameA = a.question.toUpperCase(); // ignore upper and lowercase
        var nameB = b.question.toUpperCase(); // ignore upper and lowercase
        if ( nameA > nameB ){ return -1;}
        if ( nameA < nameB ){ return 1;}
        return 0;
    }

    /** Sort Assending/Decending Entity */
    function compareEa( a, b ) {
        var nameA = a.entity.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.entity.name.toUpperCase(); // ignore upper and lowercase
        if ( nameA < nameB ){ return -1;}
        if ( nameA > nameB ){ return 1;}
        return 0;
    }
    function compareEd( a, b ) {
        var nameA = a.entity.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.entity.name.toUpperCase(); // ignore upper and lowercase
        if ( nameA > nameB ){ return -1;}
        if ( nameA < nameB ){ return 1;}
        return 0;
    }

     /** Sort Assending/Decending Objective */
     function compareOa( a, b ) {
        var nameA = a.objective.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.objective.name.toUpperCase(); // ignore upper and lowercase
        if ( nameA < nameB ){ return -1;}
        if ( nameA > nameB ){ return 1;}
        return 0;
    }
    function compareOd( a, b ) {
        var nameA = a.objective.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.objective.name.toUpperCase(); // ignore upper and lowercase
        if ( nameA > nameB ){ return -1;}
        if ( nameA < nameB ){ return 1;}
        return 0;
    }

        /** Sort Assending/Decending component */
        function compareCa( a, b ) {
        var nameA = a.component.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.component.name.toUpperCase(); // ignore upper and lowercase
        if ( nameA < nameB ){ return -1;}
        if ( nameA > nameB ){ return 1;}
        return 0;
    }
    function compareCd( a, b ) {
        var nameA = a.component.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.component.name.toUpperCase(); // ignore upper and lowercase
        if ( nameA > nameB ){ return -1;}
        if ( nameA < nameB ){ return 1;}
        return 0;
    }

    /** Sort Assending/Decending principle */
     function comparePa( a, b ) {
        var nameA = a.principle.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.principle.name.toUpperCase(); // ignore upper and lowercase
        if ( nameA < nameB ){ return -1;}
        if ( nameA > nameB ){ return 1;}
        return 0;
    }
    function comparePd( a, b ) {
        var nameA = a.principle.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.principle.name.toUpperCase(); // ignore upper and lowercase
        if ( nameA > nameB ){ return -1;}
        if ( nameA < nameB ){ return 1;}
        return 0;
    }

    /** Sort Assending/Decending Risk */
    function compareRa( a, b ) {
        var nameA = a.riskTypes.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.riskTypes.name.toUpperCase(); // ignore upper and lowercase
        if ( nameA < nameB ){ return -1;}
        if ( nameA > nameB ){ return 1;}
        return 0;
    }
    function compareRd( a, b ) {
        var nameA = a.riskTypes.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.riskTypes.name.toUpperCase(); // ignore upper and lowercase
        if ( nameA > nameB ){ return -1;}
        if ( nameA < nameB ){ return 1;}
        return 0;
    }
    

    const [showSortBtnEntities, setShowSortBtnEntities] = useState(false);
    const [showSortBtnQuestionnaires, setShowSortBtnQuestionnaires] = useState(false);
    const [showSortBtnObjectives, setShowSortBtnObjectives] = useState(false);
    const [showSortBtnComponents, setShowSortBtnComponents] = useState(false);
    const [showSortBtnPrinciples, setShowSortBtnPrinciples] = useState(false);
    const [showSortBtnRisk, setShowSortBtnRisk] = useState(false);
    

    function sortEntitiesFun(trueFalse) {
        setShowSortBtnEntities(trueFalse);
        if (trueFalse){
            quesData = quesData.sort (compareEa);
        }else{
            quesData = quesData.sort (compareEd);
        }    
    }
    function sortQuestionnairesFun(trueFalse) {
        setShowSortBtnQuestionnaires(trueFalse);
        if (trueFalse){
            quesData = quesData.sort (compareQa);
        }else{
            quesData = quesData.sort (compareQd);
        }    
    }
    function sortObjectivesFun(trueFalse) {
        setShowSortBtnObjectives(trueFalse);
        if (trueFalse){
            quesData = quesData.sort (compareOa);
        }else{
            quesData = quesData.sort (compareOd);
        }    
    }
    function sortComponentsFun(trueFalse) {
        setShowSortBtnComponents(trueFalse);
        if (trueFalse){
            quesData = quesData.sort (compareCa);
        }else{
            quesData = quesData.sort (compareCd); 
        }    
    }
    function sortPrinciplesFun(trueFalse) {
        setShowSortBtnPrinciples(trueFalse);
        if (trueFalse){
            quesData = quesData.sort (comparePa);
        }else{
            quesData = quesData.sort (comparePd);
        }    
    }
    function sortRiskFun(trueFalse) {
        setShowSortBtnRisk(trueFalse);
        if (trueFalse){
            quesData = quesData.sort (compareRa);
        }else{
            quesData = quesData.sort (compareRd);
        }    
    }

    const [openRiskEval, setOpenRiskEval] = useState(false);
    const [riskDivEvalTop, setRiskDivEvalTop] = useState(0);
    const [riskEvalData, setRiskEvalData] = useState([]);
    const [riskEvalHeaderMsg, setRiskEvalHeaderMsg] = useState('');

    function setOpenRiskEvalFuction(riskFlag) {
        //setRiskEvalData(quesData);
        var displayRiskEvalId = document.getElementById("riskEvaluateBtn");
        var lastGridId = document.getElementById("lastGridId");
        var top = displayRiskEvalId.offsetTop - lastGridId.offsetTop - 400;
        setRiskDivEvalTop(top)
        setOpenRiskEval(riskFlag);
    }

    const [riskEvalTypeOpen, setRiskEvalTypeOpen] = React.useState(false);
    const [riskEvalTypeScroll, setRiskEvalTypeScroll] = React.useState('paper');
    
    const riskEvalTypeHandleClickOpen = (scrollType) => () => {
        setRiskEvalTypeOpen(true);
        setRiskEvalTypeScroll(scrollType);
        setSelectedRiskEvalArray([]);
    };
    const riskEvalTypeHandleClickClose = () => {
        setRiskEvalTypeOpen(false);
        setSelectedRiskEvalArray([]);
    };

    function setRiskTypeEvalFuction() {
        var takenFlag = true;
        var takenFlag = false;
        var tmpRiskData = [];
        setRiskEvalData([]);
        var headerMsg = "";
        if (document.getElementById('chkRiskType0').checked){
            takenFlag = true;
            quesData.forEach((data)=>{
                tmpRiskData.push(data);
                takenFlag = false;
                headerMsg = "All";
            });
        }else{
            var k = 0;
            selectedRiskEvalArray.forEach((item)=>{
                if (document.getElementById('chkRiskType' + item.collectionRiskTypeId) != null && document.getElementById('chkRiskType' + item.collectionRiskTypeId).checked){
                    takenFlag = true;
                    item.collectionQuestionIds.forEach((quesId)=>{
                        quesData.forEach((data)=>{
                            if (data.questionId === quesId){
                                tmpRiskData.push(data);
                                takenFlag = false;
                            }
                        });
                    });
                    if (k === 0){
                        headerMsg =  item.name;
                    }else{
                        headerMsg += ", " + item.name;
                    }
                    k ++;
                }
            });
        }
        if (takenFlag){
            if (takenFlag){
                alert("Please note that you don't have any Questioners for the selected Risk Types")
            }else{
                alert("Please select atleast one Risk Type for evaluation")
            }    
        }else{
            setRiskEvalHeaderMsg(headerMsg);
            setRiskEvalData(tmpRiskData);
            setRiskEvalTypeOpen(false);
            setOpenRiskEvalFuction(true);
        }
    }
    
    
    
    
    const [evaluationSortBy, setEvaluationSortBy] = useState(1);

    const evaluationSortChange = (event) => {
        var sortType = event.target.value;
        setEvaluationSortBy(sortType);
        if (sortType === 1){
            setRiskEvalData(riskEvalData.sort (compareEa));
        }
        if (sortType === 2){
            setRiskEvalData(riskEvalData.sort (compareRa));
        }
        if (sortType === 3){
            setRiskEvalData(riskEvalData.sort (compareQa));
        }
        if (sortType === 4){
            setRiskEvalData(riskEvalData.sort (compareOa));
        }
        if (sortType === 5){
            setRiskEvalData(riskEvalData.sort (compareCa));
        }
        if (sortType === 6){
            setRiskEvalData(riskEvalData.sort (comparePa));
        }

    };    

    //const [answerArray, setAnswerArray] = React.useState([]);

    function EvalRisk({ data, type, riskTypeMethod}) {
        var str = "";
        var calcSystem = "";
        //var answerArrayTmp = [];
        //setAnswerArray([]);
        if(type === "radioButtonType") { 
            data.answers.answerList.forEach((item)=>{
                if(item.expectedAnswerName === "Yes"){
                    if (riskTypeMethod === 1 ){
                        str = data.riskTypes.riskLevel * data.questionScore * item.riskLevel;
                        calcSystem = data.riskTypes.riskLevel + "(R.L.)" + " X " + data.questionScore + "(Q.S.)" + " X " + item.riskLevel + "(A.L)";

                    }else{
                        str = data.riskTypes.riskAppetite * data.questionScore * item.riskLevel;
                        calcSystem = data.riskTypes.riskAppetite + "(R.A.)" + " X " + data.questionScore + "(Q.S.)" + " X " + item.riskLevel + "(A.L)";
                    }
                    /*
                    var tmpObj = {};
                    tmpObj["name"] = item.answerName;
                    tmpObj["value"] = null;
                    tmpObj["level"] = item.riskLevel;
                    answerArrayTmp.push(tmpObj);
                    */
                }
            });
        }
        if(type === "checkBoxType") {
            var totalCkecked = 0;
            var tmpStr = "";
            var i = 0;
            data.answers.answerList.forEach((item)=>{
                if(item.expectedAnswerName === "checked"){
                    totalCkecked = totalCkecked + parseInt(item.riskLevel);
                    if (i === 0){
                        tmpStr += " ( " + item.riskLevel + "(A.L.)";
                    }else{
                        tmpStr += " + " + item.riskLevel + "(A.L.)";
                    }
                    i ++;
                    /*
                    var tmpObj = {};
                    tmpObj["name"] = item.answerName;
                    tmpObj["value"] = null;
                    tmpObj["level"] = item.riskLevel;
                    answerArrayTmp.push(tmpObj);
                    */
                }
            });
            if (i === 0){
                tmpStr += " ( 0 ) ";
            }else{
                tmpStr += " ) ";
            }
            
            if (riskTypeMethod === 1 ){
                str = data.riskTypes.riskLevel * data.questionScore * totalCkecked;
                calcSystem = data.riskTypes.riskLevel + "(R.L.)" + " X " + data.questionScore + "(Q.S.)" + " X " + tmpStr;
            }else{
                str = data.riskTypes.riskAppetite * data.questionScore * totalCkecked;
                calcSystem = data.riskTypes.riskAppetite + "(R.A.)" + " X " + data.questionScore + "(Q.S.)" + " X " + tmpStr;
            }
        }
        if(type === "orderNumberType") {
            var totalCkecked = 0;
            var tmpStr = "";
            var i = 0;
            data.answers.answerList.forEach((item)=>{
                if(item.expectedAnswerName != ""){
                    totalCkecked = totalCkecked + parseInt(item.expectedAnswerName) *  parseInt(item.riskLevel);
                    if (i === 0){
                        tmpStr += " ( " + item.expectedAnswerName + "(E.A.V) X " + item.riskLevel + "(A.L.)";
                    }else{
                        tmpStr += " + " + item.expectedAnswerName + "(E.A.V) X " + item.riskLevel + "(A.L.)";
                    }
                    i ++;
                    /*
                    var tmpObj = {};
                    tmpObj["name"] = item.answerName;
                    tmpObj["value"] = item.expectedAnswerName;
                    tmpObj["level"] = item.riskLevel;
                    answerArrayTmp.push(tmpObj);
                    */
                }
            });
            if (i === 0){
                tmpStr += " ( 0 ) ";
            }else{
                tmpStr += " ) ";
            }
            if (riskTypeMethod === 1 ){
                str = data.riskTypes.riskLevel * data.questionScore * totalCkecked;
                calcSystem = data.riskTypes.riskLevel + "(R.L.)" + " X " + data.questionScore + "(Q.S.)" + " X " + tmpStr;
            }else{
                str = data.riskTypes.riskAppetite * data.questionScore * totalCkecked;
                calcSystem = data.riskTypes.riskAppetite + "(R.A.)" + " X " + data.questionScore + "(Q.S.)" + " X " + tmpStr;
            }

        }
        if(type === "answerTypeType") {
            var totalCkecked = 0;
            var tmpStr = "";
            var i = 0;
            data.answers.answerList.forEach((item)=>{
                if(item.expectedAnswerName != ""){
                    totalCkecked = totalCkecked + parseInt(item.expectedAnswerName) *  parseInt(item.riskLevel);
                    if (i === 0){
                        tmpStr += " ( " + item.expectedAnswerName + "(E.A.V) X " + item.riskLevel + "(A.L.)";
                    }else{
                        tmpStr += " + " + item.expectedAnswerName + "(E.A.V) X " + item.riskLevel + "(A.L.)";
                    }
                    i ++;
                    /*
                    var tmpObj = {};
                    tmpObj["name"] = item.answerName;
                    tmpObj["value"] = item.expectedAnswerName;
                    tmpObj["level"] = item.riskLevel;
                    answerArrayTmp.push(tmpObj);
                    */
                }
            });
            if (i === 0){
                tmpStr += " ( 0 ) ";
            }else{
                tmpStr += " ) ";
            }
            if (riskTypeMethod === 1 ){
                str = data.riskTypes.riskLevel * data.questionScore * totalCkecked;
                calcSystem = data.riskTypes.riskLevel + "(R.L.)" + " X " + data.questionScore + "(Q.S.)" + " X " + tmpStr;
            }else{
                str = data.riskTypes.riskAppetite * data.questionScore * totalCkecked;
                calcSystem = data.riskTypes.riskAppetite + "(R.A.)" + " X " + data.questionScore + "(Q.S.)" + " X " + tmpStr;
            }
        }
        //if (answerArrayTmp.length > 0) {
        //    setAnswerArray(answerArrayTmp);
        //}

        return (
            <>
                <span style={{cursor: 'pointer'}} title={calcSystem}>{str}</span>
            </>
        )
    }

    const [expandOpenBtn, setExpandOpenBtn] = React.useState('');
    const [expandOpenFlag, setExpandOpenFlag] = React.useState(false);
    
    function setExpandOpen(id, flag) {
        setExpandOpenBtn(id);
        setExpandOpenFlag(flag);
    }

    const riskvalTableBody = (
        <TableBody>
            {riskEvalData.map((data,index) => {
                return (
                    <>
                    <StyledTableRow key={index}>
                        <StyledTableCell className={classes.tableRadioTrStyleRiskEval}> 
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                id={"expand" + index}
                                onClick={() => setExpandOpen("expand" + index, !expandOpenFlag)}
                                >
                                {expandOpenFlag && expandOpenBtn === "expand" + index? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
        
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableRadioTrStyleRiskEval}> 
                            {index + 1}
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableRadioTrStyleRiskEval}>
                            {data.riskTypes.name}
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableRadioTrStyleRiskEval}>
                            {data.entity.name}
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableRadioTrStyleRiskEval}>
                            {data.question}
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableRadioTrStyleRiskEval} align="right">
                            {data.riskTypes.riskLevel}
                            {/*data.answers.answerMethod.name != ""
                                ?
                                <EvalRisk data={data} type={data.answers.answerMethod.name} riskTypeMethod={1}/>
                                :
                                null
                            */}
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableRadioTrStyleRiskEval} align="right">
                            {data.riskTypes.riskAppetite}
                            {/*data.answers.answerMethod.name != ""
                                ?<EvalRisk data={data} type={data.answers.answerMethod.name} riskTypeMethod={2}/>
                                :null
                            */}
                        </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow key={index}>
                        <StyledTableCell className={classes.tableRadioTrStyleRiskEval}  style={{ paddingBottom: '0px !important' , paddingTop: '0px !important' }} colSpan={7}> 
                            <Collapse in={expandOpenFlag && expandOpenBtn === "expand" + index} timeout="auto" unmountOnExit>
                                <Box >
                                    
                                    <Table size="small" aria-label="purchases">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableTrStyleNormalBottom} style={{width: '80%'}}>
                                                    <Typography variant="h6" className={classes.cardBodyH6MarginLeftMinus} gutterBottom component="div">
                                                        Detail Weightings
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row" className={classes.tableTrStyleNormalAll} style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                    <Typography variant="h6" className={classes.cardBodyH6} gutterBottom component="div">
                                                        Score
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>    
                                        </TableBody>
                                    </Table>
                                    <Table size="small" aria-label="purchases">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableTrStyleNormalBottom} style={{width: '80%'}}>
                                                    Questionnaire weighting
                                                </TableCell>
                                                <TableCell component="th" scope="row" className={classes.tableTrStyleNormalAll} style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                {data.questionScore}
                                                </TableCell>
                                            </TableRow>    
                                        </TableBody>
                                    </Table>
                                    {data.questionType === "Closeended" && (
                                        <>
                                        <Table size="small" aria-label="purchases">
                                            <TableBody>        
                                                <TableRow>
                                                    <TableCell component="th" scope="row" className={classes.tableTrStyleNormalBottom} style={{width: '80%'}}>
                                                        <Typography variant="h6" className={classes.cardBodyH6} gutterBottom component="div">
                                                            Answers
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={classes.tableTrStyleNormalAll} style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                    &nbsp;
                                                    </TableCell>
                                                </TableRow>  
                                            </TableBody>
                                        </Table>        
                                        <Table size="small" aria-label="purchases">
                                            <TableBody>
                                                {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                                    return (
                                                        <>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row" className={classes.tableTrStyleNormalBottom} style={{width: '80%'}}>
                                                                    <span class={classes.cardBodyH6MarginLeftPlus}>{item.answerName}</span>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row" className={classes.tableTrStyleNormalAll} style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                                    {item.riskLevel}
                                                                </TableCell>
                                                            </TableRow>    
                                                        </>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                        </> 
                                    )}
                                    <Table size="small" aria-label="purchases">
                                        <TableBody>        
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableTrStyleNormalBottom} style={{width: '80%'}}>
                                                    <Typography variant="h6" className={classes.cardBodyH6} gutterBottom component="div">
                                                        Model Answer(s)
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row" className={classes.tableTrStyleNormalAll} style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                &nbsp;
                                                </TableCell>
                                            </TableRow>  
                                        </TableBody>
                                    </Table>
                                    {data.questionType === "Closeended" && (
                                        <Table size="small" aria-label="purchases">
                                            <TableBody>
                                                {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                                    if (item.expectedAnswerName != ""){
                                                        return (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row" className={classes.tableTrStyleNormalBottom} style={{width: '80%'}}>
                                                                        <span class={classes.cardBodyH6MarginLeftPlus}>
                                                                            {item.answerName}
                                                                        </span>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" className={classes.tableTrStyleNormalAll} style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                                        {item.expectedAnswerName === "Yes" || item.expectedAnswerName === "checked"? item.riskLevel:item.expectedAnswerName}
                                                                    </TableCell>
                                                                </TableRow>    
                                                            </>
                                                        )
                                                    }    
                                                })}
                                            </TableBody>
                                        </Table>
                                    )}
                                    {data.questionType === "Openended" && (
                                        <Table size="small" aria-label="purchases">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" className={classes.tableTrStyleNormalBottom} style={{width: '80%'}}>
                                                        <span class={classes.cardBodyH6MarginLeftPlus}>
                                                            {data.answers.answer}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={classes.tableTrStyleNormalAll} style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                        &nbsp;
                                                    </TableCell>
                                                </TableRow>    
                                            </TableBody>
                                        </Table>
                                    )}      
                                </Box>
                            </Collapse>      
                        </StyledTableCell>
                    </StyledTableRow>
                </>
                )

            })}
        </TableBody>
    );


    function Comment({ comment }) {
        const [show, setShow] = React.useState(true);
        
        var closeEndedQuestionIds = getQuestionIds(comment,1);
        var openEndedQuestionIds = getQuestionIds(comment,2);
        var closeEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,1);
        var openEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,2);

        const nestedComments = (comment.subRisks || []).map(comment => {
            return <Comment key={comment.collectionRiskTypeId} comment={comment} type="child" />
        })
    
        return (
            <div style={{"marginLeft": "15px", "marginTop": "10px"}}>
                <div style={{display:'flex',textAlign:'center'}}> 
                    <Button 
                        style={{width:'0px', height:'0px', marginTop:'4px'}} 
                        onClick={()=>setShow(!show)} 
                        startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        >
                    </Button>
                        {riskDivRiskName === comment.name? <span style={{color:'red'}}>
                            {show?comment.name + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' (' )   + (closeEndedQuestionIds.length > 0 ?' C : '
                             + closeEndedQuestionIds.length:'')+ (openEndedQuestionIds.length > 0 
                                ?closeEndedQuestionIds.length > 0 ?', O : ' + openEndedQuestionIds.length:' O : ' + openEndedQuestionIds.length:'') + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' )' ) 
                             :comment.name + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' (' )   + (closeEndedQuestionIdsWithSub.length > 0 ?' C : '
                             + closeEndedQuestionIdsWithSub.length:'')+ (openEndedQuestionIdsWithSub.length > 0 
                                ?closeEndedQuestionIdsWithSub.length > 0 ?', O : ' + openEndedQuestionIdsWithSub.length:' O : ' + openEndedQuestionIdsWithSub.length:'') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' )' )    
                            }
                            </span>:
                            show?comment.name + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' (' )   + (closeEndedQuestionIds.length > 0 ?' C : '
                            + closeEndedQuestionIds.length:'')+ (openEndedQuestionIds.length > 0 
                               ?closeEndedQuestionIds.length > 0 ?', O : ' + openEndedQuestionIds.length:' O : ' + openEndedQuestionIds.length:'') + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' )' ) 
                            :comment.name + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' (' )   + (closeEndedQuestionIdsWithSub.length > 0 ?' C : '
                            + closeEndedQuestionIdsWithSub.length:'')+ (openEndedQuestionIdsWithSub.length > 0 
                               ?closeEndedQuestionIdsWithSub.length > 0 ?', O : ' + openEndedQuestionIdsWithSub.length:' O : ' + openEndedQuestionIdsWithSub.length:'') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' )' )    
                             
                        }
                        
                </div>
                {
                    show?
                        <>
                            {nestedComments}
                        </>
                    :null
                }
        </div>
      )
    }
// *Risk Scrumb****
function getRiskScrumb(comment,riskName){
    var riskScrumbPath = "";
    if (riskName === comment.name){
        riskScrumbPath =  riskName;
    }else{
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
            var riskPath = comment.name;
            var subRiskPath =  getSubRiskScrumb(comment.subRisks,riskName);
            if (subRiskPath != ""){
                riskScrumbPath = riskPath + subRiskPath;
            }
        }
    }
    return riskScrumbPath;
}
function getSubRiskScrumb(subData,riskName){
    var tmpSubPath = "";
    subData.forEach((item,index)=>{
        if (item.name === riskName){
            tmpSubPath += " --> " + item.name;
            return true; 
        }else{
            if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
                var tmpSubPath1 = " --> " + item.name;
                var tmpSubSubPath1 = getSubRiskScrumb(item.subRisks,riskName);
                if (tmpSubSubPath1 != ""){
                    tmpSubPath += tmpSubPath1 + tmpSubSubPath1;
                }
            }    
        }
    });
    return tmpSubPath;


}    

function RiskScrumb({ riskName, riskTypeId, collectionRiskId, collectionRiskTypeId }) {
    var rsTypeArr = [];
    riskTypeData.forEach((data)=>{
        if (data.collectionRiskId === collectionRiskId){
            rsTypeArr = data.riskTypes;
        }
    });  
    var riskScrumbPath  = "";
    rsTypeArr.forEach((item)=>{
        var pathStr = "";
        pathStr = getRiskScrumb(item,riskName);
        if (pathStr != undefined && pathStr != ""){
            riskScrumbPath = pathStr;
            return true;
        }
    });  
    
    return (
        <>
            {riskScrumbPath}
        </>
    )

}

    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={2}>

                <Box    
                    sx={{
                        height: 300,
                        width: '100%',
                        '& .super-app-theme--cell': {
                        whiteSpace: 'normal !important',
                        lineHeight: '1',
                        },
                        '& .super-app-theme--Open': {
                            fontSize: '10px !important',
                        }, 
                    }}
                    >

                    <div style={{ height: 400, width: '100%',backgroundColor: '#ffffff', fontSize: '9px !important' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        getRowClassName={`super-app-theme--Open`}
                        disableSelectionOnClick
                    />
                    </div>

                </Box>
            </Grid>
        </Grid>  

        <Grid item xs={12} id="lastGridId">
            &nbsp;                                    
        </Grid>



    {/** Question Details Show Dialog */}
    <Dialog
          maxWidth='xs'
          open={questionDetailOpen}
          onClose={questionDetailHandleClickClose}
          scroll={questionDetailScroll}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Typography variant="h6" gutterBottom component="div">
                    Question No - {quesDataCount}
                </Typography>
                <Typography component="th" scope="row" style={{width: '100%', fontSize: '10px !important'}}>
                    <span style={{fontWeight: '550'}}>Question - </span>{selectedQuestion}
                </Typography>
          </DialogTitle>
          <DialogContent dividers={questionDetailScroll === 'paper'}>
            <DialogContentText
              id="risk-eval-type-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                {selectedQuesData.map((data) => {
                    var scoreColor = getRiskLvlColor(data.questionScore)
                  return (
                    <Grid item xs={12}>
                      <Box >
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Detail Weightings
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                        <Typography variant="h6" component="div">
                                            Score
                                        </Typography>
                                    </TableCell>
                                </TableRow>    
                            </TableBody>
                        </Table>
                        <Table size="small" aria-label="purchases">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                        Questionnaire weighting
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                    <span style={{color:scoreColor}}>{data.questionScore}</span>
                                    </TableCell>
                                </TableRow>    
                            </TableBody>
                        </Table>
                        {data.questionType === "Closeended" && (
                            <>
                            <Table size="small" aria-label="purchases">
                                <TableBody>        
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                Answers
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                        &nbsp;
                                        </TableCell>
                                    </TableRow>  
                                </TableBody>
                            </Table>        
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                        var riskLvlColor = getRiskLvlColor(item.riskLevel)
                                        return (
                                            <>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                                        <span >{item.answerName}</span>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                    <span style={{color:riskLvlColor }}>{item.riskLevel}</span>
                                                    </TableCell>
                                                </TableRow>    
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            </> 
                        )}
                        <Table size="small" aria-label="purchases">
                            <TableBody>        
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '80%'}}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Model Answer(s)
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                    &nbsp;
                                    </TableCell>
                                </TableRow>  
                            </TableBody>
                        </Table>
                        {data.questionType === "Closeended" && (
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                        if (item.expectedAnswerName != ""){
                                            var riskLvlColor = getRiskLvlColor(item.riskLevel);
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                                            <span>
                                                                {item.answerName}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                                        <span style={{color:riskLvlColor }}>{item.expectedAnswerName === "Yes" || item.expectedAnswerName === "checked"? item.riskLevel:item.expectedAnswerName}</span>
                                                        </TableCell>
                                                    </TableRow>    
                                                </>
                                            )
                                        }    
                                    })}
                                </TableBody>
                            </Table>
                        )}
                        {data.questionType === "Openended" && (
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                            <span>
                                                {data.answers.answer}
                                            </span>
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="right">
                                            &nbsp;
                                        </TableCell>
                                    </TableRow>    
                                </TableBody>
                            </Table>
                        )}      
                      </Box>
                    </Grid>     
                  )
                })} 
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={questionDetailHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Question Details Show Dialog*/}



    {/** Risk   Pop up Div */}

    {
        openRisk?
           <Draggable>
           <div id="riskDiv" style={{zIndex:'1000'}}>
            <Grid  spacing={2}>
            <Card className={classes.popupdiv} style={{marginTop: + `${riskDivTop}`+ 'px', marginLeft: + `${riskDivLeft}`+ 'px' }}>
            <Typography variant="h6" style={{textAlign:'right', marginTop:'-5px'}}><Tooltip title="Close"><Close onClick={()=>setOpenRisk(!openRisk)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
            <Typography variant="h6" style={{textAlign:'center', marginTop:'-10px'}}>Risk Hierarchy</Typography>
            <Divider light />
                <CardContent>
                    <Grid container spacing={2}>
                            <Grid item xs={12} spacing={2} >
                                    {
                                        rsTypeItemHierarchy.map((risktypp) => {
                                            return (
                                                <Comment 
                                                    key={risktypp.collectionRiskTypeId} 
                                                    comment={risktypp} 
                                                />
                                            )
                                        })
                                    }
                            </Grid>  
                    </Grid> 
                </CardContent>
                <Divider light />
                <CardActions style={{textAlign:'right', float: 'right'}}>
                        <Button  color="primary" onClick={()=>setOpenRisk(!openRisk)} className={classes.cardcencel} style={{float: 'right'}}>
                        Cancel
                        </Button>
                    </CardActions>
            </Card>
        </Grid>
       </div>
       </Draggable>
       :null

        }

    {/** Risk  Evaluation Pop up Div */}

    {
        openRiskEval?
            <Draggable>
                <div id="riskEvalDiv" style={{zIndex:'1000'}}>
                    <Grid  container spacing={2}>
                        <Card className={classes.popupDivRiskEval} style={{marginTop: + `${riskDivEvalTop}`+ 'px', marginLeft: '30px' }}>
                            <Typography variant="h6" style={{textAlign:'right', marginTop:'-5px'}}><Tooltip title="Close"><Close onClick={()=>setOpenRiskEval(!openRiskEval)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
                            <Typography variant="h6" style={{textAlign:'center', marginTop:'-10px'}}>Risk Evaluation - <span style={{fontSize: '10px !important'}}>{riskEvalHeaderMsg}</span></Typography>
                            <Divider light />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        

                                        <Box sx={{ minWidth: 120}}>    
                                            <FormControl fullWidth>
                                                <TextField
                                                    label="Evaluation Sort By "
                                                    sx={{ width: '100%' }}
                                                    select
                                                    defaultValue={1}
                                                    onChange={evaluationSortChange}
                                                    >
                                                    <MenuItem value={1}>Entities </MenuItem>
                                                    <MenuItem value={2}>Risk Type </MenuItem>
                                                    <MenuItem value={3}>Questionnaires </MenuItem>
                                                    <MenuItem value={4}>Objectives </MenuItem>
                                                    <MenuItem value={5}>Components </MenuItem>
                                                    <MenuItem value={6}>principles </MenuItem>
                                                </TextField>
                                            </FormControl>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper}>
                                            <div className={classes.tableFixHead}>
                                            <Table aria-label="customized table">
                                                <TableHead className={classes.tableHeadStyleRiskEvak}>
                                                    <TableRow>
                                                    <StyledTableCell rowSpan={2} className={classes.tableThStyleEval} style={{width: '2%', paddingTop: '7px !important', paddingBottom: '7px !important'}}></StyledTableCell>
                                                        <StyledTableCell rowSpan={2} className={classes.tableThStyleEval} style={{width: '2%', paddingTop: '7px !important', paddingBottom: '7px !important'}}>No</StyledTableCell>
                                                        <StyledTableCell rowSpan={2} className={classes.tableThStyleEval} style={{width: '15%', paddingTop: '7px !important', paddingBottom: '7px !important'}}>Risk Type</StyledTableCell>
                                                        <StyledTableCell rowSpan={2} className={classes.tableThStyleEval} style={{width: '12%', paddingTop: '7px !important', paddingBottom: '7px !important'}}>Entity</StyledTableCell>
                                                        <StyledTableCell rowSpan={2} className={classes.tableThStyleEval} style={{width: '37%', paddingTop: '7px !important', paddingBottom: '7px !important'}}>Questionnaire</StyledTableCell>
                                                        <StyledTableCell align='center' colSpan={2} className={classes.tableThStyleEval} style={{width: '32%', paddingTop: '7px !important', paddingBottom: '7px !important'}}>Score</StyledTableCell>
                                                    </TableRow>    
                                                    <TableRow>
                                                        <StyledTableCell className={classes.tableThStyleEval} style={{width: '15%', paddingTop: '7px !important', paddingBottom: '7px !important'}}>Risk Level</StyledTableCell>
                                                        <StyledTableCell className={classes.tableThStyleEval} style={{width: '17%', paddingTop: '7px !important', paddingBottom: '7px !important'}}>Risk Appetite</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {riskvalTableBody}
                                            </Table>
                                            </div>
                                        </TableContainer>            
                                    
                                    </Grid>  
                                </Grid> 
                            </CardContent>
                            <Divider light />
                            <CardActions>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <p style={{fontSize: '8px'}}>
                                        <span style={{fontSize: '8px', color: 'red', fontWeight: 400}}>R.L</span> - Risk Level, <span style={{fontSize: '8px', color: 'red', fontWeight: 400}}>R.A.</span> - Risk Appetite, <span style={{fontSize: '8px', color: 'red', fontWeight: 400}}>Q.s.</span> - Questionnaire Score, 
                                        <span style={{fontSize: '8px', color: 'red', fontWeight: 400}}>E.A.V.</span> - Expected Answer Value, <span style={{fontSize: '8px', color: 'red', fontWeight: 400}}>A.L.</span> - Answer Level
                                    </p>
                                </Grid>    
                                <Grid item xs={3}>
                                <Button  style={{textAlign:'right', float: 'right'}} color="primary" onClick={()=>setOpenRiskEval(!openRiskEval)} className={classes.cardcencel}>
                                    Cancel
                                </Button>
                                </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                </div>
            </Draggable>
        :null

    }

    {/** Risk Evaluate Options */}
        <Dialog
          maxWidth='md'
          open={riskEvalTypeOpen}
          onClose={riskEvalTypeHandleClickClose}
          scroll={riskEvalTypeScroll}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          //aria-describedby="risk-eval-type-scroll-dialog-description"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Select "All" or other Risk Type to Evaluate Risk</DialogTitle>
          <DialogContent dividers={riskEvalTypeScroll === 'paper'}>
            <DialogContentText
              id="risk-eval-type-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel id="radio-buttons-group-label">Pick your Risk Type</FormLabel>
                </Grid>
                
                   
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                    <FormGroup>
                        <FormControlLabel className={classes.marginleftEval} control={<Checkbox id={"chkRiskType0"} />} label="All" />
                        <Box sx={{ minWidth: 120}}>    
                            <FormControl fullWidth>
                                <TextField
                                    label="Select a Risk Hierarchy"
                                    sx={{ width: '100%' }}
                                    select
                                    value={hierarchyId}
                                    onChange={handleChangeHierarchy}
                                    >
                                    {riskTypeData.map((item)=>(
                                        <MenuItem 
                                            value={item.collectionRiskId}
                                            >
                                            {item.name}
                                        </MenuItem>
                                    ))}

                                </TextField>
                            </FormControl>
                        </Box>

                        {
                            rsType.map((risktypp) => {
                                return (
                                    <RiskEval 
                                        key={risktypp.collectionRiskTypeId} 
                                        comment={risktypp} 
                                    />
                                )
                            })
                        }



                        {/*mainRiskArr.map((item)=>{
                            return(
                                <FormControlLabel control={<Checkbox id={"chkRiskType" + item.riskTypeId}/>} label={item.name} />
                            )
                        })*/}
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    &nbsp;
                </Grid>
                <Grid item xs={12}>
                    <Button 
                        variant="outlined"
                        id="riskEvaluateBtn" 
                        className={classes.btnBorderRedEval}
                        style={{float: 'right'}}
                        //onClick={()=>setOpenRiskEvalFuction(!openRiskEval)}
                        onClick={()=>setRiskTypeEvalFuction()}
                        >
                        Confirm
                    </Button>     
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={riskEvalTypeHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Risk Evaluation Options*/}

      
      {/** Risk Sort Options */}
      {
           openRiskSort?
           <Draggable>
           <div id="riskDiv" style={{zIndex:'1000'}}>
           <Grid  spacing={2}>
           <Card className={classes.popupdivSort} style={{marginTop: + `${riskSortBtnTop}`+ 'px'}}>
           <Typography variant="h6" style={{float: 'right', marginTop:'-5px'}}><Tooltip title="Close"><Close style={{cursor: 'pointer'}} onClick={()=>setOpenRiskSortFuction(!openRiskSort)}/></Tooltip></Typography>        
           <Typography variant="h6" style={{textAlign:'center', marginTop:'0px'}}>Select a Risk Type for Sorting</Typography>
           <Divider light />
               <CardContent>
                   <Grid container spacing={2}>
                   <Grid item xs={12}>
                   <span className={classes.marginleft} >
                   <Radio
                       checked={selectedRiskSortId === ''}
                       onChange={riskTypehandleChange}
                       value={"]All Risk Types]]]"}
                       name="radio-buttons"
                       inputProps={{ 'aria-label': 'A' }}
                       style={{marginLeft:'75px !important'}} 
                       />
                       All Risk Type 
                    </span>  
                    </Grid>




                           <Grid item xs={12}>
                                {
                                    rsType.map((risktypp) => {
                                        return (
                                            <RiskSort 
                                                key={risktypp.collectionRiskTypeId} 
                                                comment={risktypp}
                                                isMain={true} 
                                            />
                                        )
                                    })
                                }
                           </Grid>  
                   </Grid> 
               </CardContent>
               <Divider light />
               <CardActions style={{textAlign:'right'}}>
                    <Button  style={{float: 'right'}} color="primary" onClick={()=>setOpenRiskSortFuction(!openRiskSort)} className={classes.cardcencel}>
                    Cancel
                    </Button>
                </CardActions>
           </Card>
       </Grid>
       </div>
       </Draggable>
       :null

        }

        </>

      )
    }
  
  export default QuestionerMgtPageItemOthers