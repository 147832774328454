import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import useStyle from '../Css/MainCss';
import Paper from '@mui/material/Paper';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import Draggable from 'react-draggable';
import Tooltip1 from '@mui/material/Tooltip';
import { FullscreenExitRounded, FullscreenRounded, Info, KeyboardReturnOutlined, MaximizeRounded, Minimize, TurnedInOutlined } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import axios from 'axios'
import { CONFIG } from '../config'
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TransferToExcelRegister from './TransferToExcelRegister';
import { BarChart, Bar, XAxis, YAxis, Cell, Tooltip, LabelList, CartesianGrid, Legend } from 'recharts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const useStyles = makeStyles((theme) => ({
    right: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    pageBtn: {

        height: '60px',
        width: '170px',
        backgroundColor: '#f9f9f9 !important',
        color: '#9481df !important',
        borderRadius: '12px !important',
    },
    headerLable: {

        color: '#827d7d',
    },
    pageTop: {
        marginTop: '115px',
        marginLeft: '20px'
    },
    pageBodyStyle: {
        paddingTop: '0px',


    },
    root: {
        position: 'relative',
    },
    posRelative: {
        position: 'relative',
    },
    topImagePos: {
        position: 'absolute',
        top: '33%',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    topHeaderText1: {
        color: '#9155FD',
        fontSize: '25px',
        paddingBottom: '10px',
    },
    topHeaderText2: {
        color: '#3A3541',
        fontSize: '12px',
        textAlign: 'center',
        fontFamily: 'Inter',
    },
    thStyle: {
        fontSize: '12px !important',
        lineHeight: '0.5rem !important',
        borderBottom: '0px !important'
    },
    thStyleImage: {
        lineHeight: '0.5rem !important',
        borderBottom: '0px !important',
        width: '130px !important',
    },
    btnColorPurple: {
        fontSize: '12px !important',
        backgroundColor: '#9155FD !important',
        height: '40px',
        textAlign: 'center'
    },
    riskImageSize: {
        width: '120px',
        height: '100px !important',
    },
    tableStyle: {
        width: '55% !important',
        minWidth: '720px !important'
    },
    btnBorderGreen: {
        border: '1px solid #0e640e !important',
        color: '#0e640e !important',
        height: '40px'
    },
    btnclear: {
        height: '40px'
    },
    btnBorderAsh: {
        border: '1px solid #8a8181 !important',
        color: '#8a8181 !important',
    },
    btnBorderRed: {
        border: '1px solid #ff0000 !important',
        color: '#ff0000 !important',
    },
    qusCard: {
        width: '100% !important',
    },
    qusCardHeader: {
        backgroundColor: '#ece9fa !important',
    },
    subText: {
        fontSize: '12px !important',
    },
    btnPadding: {
        paddingLeft: '2px !important',
        paddingRight: '2px !important',
    },
    cardBodyH6: {
        fontSize: '14px !important',
    },
    cardBodyH6Ans: {
        fontSize: '13px',
        paddingLeft: '12px',
    },
    addBodyPlus: {
        fontSize: '146px !important',
        fontWeight: '1000 !important',
        textAlign: 'center !important',
        color: '#3A3541 !important',
    },
    posRelativeAndDivBorder: {
        position: 'relative',
        border: '1px solid #9155FD',
        width: '120px',
        height: '100px !important',
    },
    hightrachytitle: {
        textAlign: 'left',
        color: '#3A3541',

    },
    btnBorderpurple: {
        border: '1px solid #9155FD !important',
        color: '#9155FD !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },
    hightrachytitlestyle: {
        fontSize: '14px !important',
        fontWeight: 500
    },
    modalbox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: 14,
        padding: 10,
    },
    addriskbtn: {
        color: '#9155FD !important',
        border: '1px solid #9155FD !important',
        marginTop: '20px !important'
    },
    risktytext: {
        width: '350px',

    },
    canclbtn: {
        alignItem: 'left !important',
        justifyContent: 'end'
    },
    cardRiskManage: {
        width: '95%',
        border: '1px solid black'
    },
    scrollableDiv: {
        height: '150px',
        overflow: 'auto',
        width: '100%',
    },
    tableHeadStyle: {
        backgroundColor: '#7B9DE0',
        color: '#ffffff',
    },
    tableThStyle: {
        lineHeight: '0.25rem !important',
        color: 'white !important'
    },
    tableTrStyle: {
        //lineHeight: '0.20rem !important',
        height: '19px !important'
    },
    mousePointer: {
        cursor: 'pointer'
    },
    scrollableDiv: {
        height: '250px',
        overflow: 'auto',
        width: '100%',
    },
    stdMainCardHeader: {
        backgroundColor: '#4C7EE0 !important',
        fontSize: '13px !important',
        height: '50px',
        padding: '0px',
        marginTop: '-7px',
        color: 'white'
    },
    addrisktypebtn: {
        marginLeft: '0px !important',
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },

    btnBorderpurple: {
        border: '1px solid #9155FD !important',
        color: '#9155FD !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },
    stdCard: {
        height: '100%',
        border: '1px solid black'
    },
    scrollableDivToEval: {
        height: '400px',
        overflow: 'auto',
        width: '100%',
    },
    noclickbackdrop: {
        pointerEevents: "none",
    },
    slider1: {
        background: 'linear-gradient(90deg, rgb(70, 163, 56) 10%,#d3d3d3 0%)',
    },
    cardDivDB: {
        border: '2px solid 69676e',
        width: '100% !important',
        backgroundColor: '#f6f5fc !important',
        boxShadowhadow: '5px 10px #69676e',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        borderRadius: '5px !important'
    },
    stdButtonsStyle: {
        fontSize: '0.70rem !important',
        height: '50px !important',
    },
    collectionCardDBEval: {
        height: '145px',
        borderRadius: '15px !important',
        backgroundColor: '#dce3f2 !important',
    },
    collectionCardTitleDBEval: {
        fontSize: '13px !important',
        textAlign: 'center',
        fontWeight: '500',
    },
    commonMainTableHeadEval: {
        backgroundColor: '#4C7EE0 !important',
        fontSize: '16px !important',
        height: '50px',
        padding: '0px',
        marginTop: '-7px',
        color: 'white'
    },
    alignCenterDBEval: {
        textAlign: 'center !important',
        alignItems: 'center  !important',
        justifyContent: 'center  !important'
    },

    btnActive: {
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        backgroundColor: '#BACDF2 !important',
        padding: '5px 10px !important',
        height: '40px !important',
        width: '160px !important'
    },

    btnNormal: {
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        padding: '5px 10px !important',
        height: '40px !important',
        width: '160px !important'
    },

    btnSepateWindow: {
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        padding: '5px 10px !important',
        height: '34px !important',
        //width: '160px !important',
        float: 'right'
    },



}));

const RegisterEvaluateComponent = ({ centreId, allRegister }) => {

    const classes = useStyles();

    React.useEffect(() => {
        getColors();
        getAllRisks(centreId);
    }, [])

    console.log(allRegister)


    const [allAvailableRisk, setAllAvailableRisk] = React.useState([]);
    function getAllRisks(centId) {
        axios(CONFIG.MAIN_URL + `/risk/all`, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
        .then(function (response) {
            console.log("All Risk Returns");
            console.log(response.data);
            var arr = [];
            response.data.forEach((item) => {
                if (centId === item.centreId) {
                    arr.push(item);
                }
            })
            setAllAvailableRisk(arr);

            allRegister.forEach((data) => {
                data.riskRegister.forEach((data1) => {
                    data1.risks.forEach((risk) => {
                        if (risk.riskHierarchy.riskId != undefined && risk.riskHierarchy.riskId != null && risk.riskHierarchy.riskId != ''){
                            arr.forEach((item) => {
                                if (item.riskId === parseInt(risk.riskHierarchy.riskId)){
                                    risk["riskHierarchyData"] = item    
                                } 
                            })
                        }

                    })
                })
            })
            console.log("++++++++++++++allRegister+++++++++++++++");
            console.log(allRegister);




        })
        .catch(function (error) {
            console.log(error);
        });
    }




    const classe = useStyle();

    function PaperComponentCollectionData(props) {
        return (
            <Draggable
                handle="#collection-data-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    const [btnHirachivalClass, setBtnHirachivalClass] = useState(classes.btnNormal);
    const [btnGradientClass, setBtnGradientClass] = useState(classes.btnNormal);
    const [btnBarChartClass, setBtnBarChartClass] = useState(classes.btnNormal);

    const [openRegisterDataForEval, setOpenRegisterDataForEval] = useState(false);
    const [openRegisterDataForEvalScroll, setOpenRegisterDataForEvalScroll] = useState('paper');
    const [maxWidthState, setMaxWidthState] = useState('lg');
    const [fullScreenState, setFullScreenState] = useState(false);
    const [selectedRegisterEvaluateTitle, setSelectedRegisterEvaluateTitle] = useState('');
    const [fullScreenStateMsg, setFullScreenStateMsg] = useState('Full Screen');
    const [colorPatternId, setColorPatternId] = useState('')
    const [colorPatterns, setColorPatterns] = useState([])
    const [tdPresentage, setTdPresentage] = useState()
    const [colorPatternName, setColorPatternName] = useState('')
    const [colorPatternPrimaryColorName, setColorPatternPrimaryColorName] = useState('')
    const [colorPatternSecondaryColorName, setColorPatternSecondaryColorName] = useState('')
    const [colorSelectDropDownRiskLevelTmp, setColorSelectDropDownRiskLevelTmp] = React.useState();
    const [colorSelectDropDownRiskAppetiteTmp, setColorSelectDropDownRiskAppetiteTmp] = React.useState();
    const [colorSelectDropDownRiskLevel, setColorSelectDropDownRiskLevel] = React.useState();
    const [colorSelectDropDownRiskAppetite, setColorSelectDropDownRiskAppetite] = React.useState();
    const [showEvalData, setShowEvalData] = useState(false)
    const [applyFlag, setApplyFlag] = useState(false)
    const [showTableType, setShowTableType] = useState()
    const [showTranferExlButton, setShowTranferExlButton] = useState(false)
    const [commitBarData, setCommitBarData] = React.useState([]);
    const [barChartHeight, setBarChartHeight] = React.useState(600);
    const [barData, setBarData] = React.useState([]);
    const [arrRiskColorState, setArrRiskColorState] = React.useState([]);
    const [arrColorAppState, setArrColorAppState] = React.useState([]);
    const [editRiskHirachy, setEditRiskHirachy] = React.useState(false);
    const [waitingOpen, setWaitingOpen] = React.useState(false);
    const [riskTypeDataForEval, setRiskTypeDataForEval] = React.useState({});
    const [registerId, setRegisterId] = React.useState('');


    function riskLevelcolorFunction(risklevel){
        var riskColor = ''
        var lastScoreColor = ''
        var lastScoreColorSecondary = ''
        colorPatterns.map((pattern) => {
            if (pattern.id === colorPatternId) {
                pattern.colors.map((clr) => {
                    lastScoreColor = clr.color
                    lastScoreColorSecondary = clr.secondaryColor
                    if (colorSelectDropDownRiskLevelTmp === 1) {
                        if (clr.scoreNumber === parseInt(risklevel)) {
                            riskColor = clr.color
                        }
                    }else if(colorSelectDropDownRiskLevelTmp === 2){
                        if (clr.scoreNumber === parseInt(risklevel)) {
                            riskColor = clr.secondaryColor
                        }
                    }
                })
            }
        })
         if (riskColor === '') {
            if (colorSelectDropDownRiskLevelTmp === 1) {
                riskColor = lastScoreColor
            }else{
                riskColor = lastScoreColorSecondary
            } 
         }
        return riskColor
    }

    function riskApopetitecolorFunction(riskApp){
        var riskColor = ''
        var lastScoreColor = ''
        var lastScoreColorSecondary = ''
        colorPatterns.map((pattern) => {
            if (pattern.id === colorPatternId) {
                pattern.colors.map((clr) => {
                    lastScoreColor = clr.color
                    lastScoreColorSecondary = clr.secondaryColor
                    if (colorSelectDropDownRiskAppetiteTmp === 1) {
                        if (clr.scoreNumber === parseInt(riskApp)) {
                            riskColor = clr.color
                        }
                        if (riskColor === '') {
                            riskColor = lastScoreColor
                        }
                    }else if(colorSelectDropDownRiskAppetiteTmp === 2){
                        if (clr.scoreNumber === parseInt(riskApp)) {
                            riskColor = clr.secondaryColor
                        }
                        if (riskColor === '') {
                            riskColor = lastScoreColorSecondary
                        }
                        
                    }


                })
            }
        })
        // if (riskColor === '') {
        //     riskColor = lastScoreColor
        // }
        return riskColor
    }

    function riskLevelActionFunction(level){
        var riskAction = ''
        var lastScoreAction = ''
        var lastScoreActionSecondary = ''
        colorPatterns.map((pattern) => {
            if (pattern.id === colorPatternId) {
                pattern.colors.map((clr) => {
                    lastScoreAction = clr.primaryColorName
                    lastScoreActionSecondary = clr.secondaryColorName
                    if (colorSelectDropDownRiskLevelTmp === 1) {
                        if (clr.scoreNumber === parseInt(level)) {
                            riskAction = clr.primaryColorName
                        }
                        if (riskAction === '') {
                            riskAction = lastScoreAction
                        }
                    }else if(colorSelectDropDownRiskLevelTmp === 2){
                        if (clr.scoreNumber === parseInt(level)) {
                            riskAction = clr.secondaryColorName
                        }
                        if (riskAction === '') {
                            riskAction = lastScoreActionSecondary
                        }
                        
                    }


                })
            }
        })
        // if (riskColor === '') {
        //     riskColor = lastScoreColor
        // }
        return riskAction
    }

    function riskApopetiteActionFunction(appetite){
        var riskAction = ''
        var lastScoreAction = ''
        var lastScoreActionSecondary = ''
        colorPatterns.map((pattern) => {
            if (pattern.id === colorPatternId) {
                pattern.colors.map((clr) => {
                    lastScoreAction = clr.primaryColorName
                    lastScoreActionSecondary = clr.secondaryColorName
                    if (colorSelectDropDownRiskLevelTmp === 1) {
                        if (clr.scoreNumber === parseInt(appetite)) {
                            riskAction = clr.primaryColorName
                        }
                        if (riskAction === '') {
                            riskAction = lastScoreAction
                        }
                    }else if(colorSelectDropDownRiskLevelTmp === 2){
                        if (clr.scoreNumber === parseInt(appetite)) {
                            riskAction = clr.secondaryColorName
                        }
                        if (riskAction === '') {
                            riskAction = lastScoreActionSecondary
                        }
                        
                    }


                })
            }
        })

        return riskAction
    }


    function getSubNo(riskId) {
        var subNo = "0";
        allRegister.forEach((item) => {
            if (item.id === registerId){
                item.riskRegister.forEach((data) => {
                    if (data.risks != undefined && data.risks != null && data.risks.length > 0) {
                        data.risks.forEach((data1, index) => {
                            if (data1.id === riskId) {
                                subNo = (index + 1) + "";
                            }

                        })
                    }
                })
            }
        })    
        return subNo;
    }

    function getSubNo1(allSubRiskData, riskId) {
        var subNo = "0";
        allSubRiskData.riskTypes.forEach((data) => {
            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                data.subRisks.forEach((item, index) => {
                    if (item.riskTypeId === riskId) {
                        subNo = (index + 1) + "";
                    }

                })
            }
        })
        if (subNo === "0") {
            allSubRiskData.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((item, index) => {
                        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                            var tmpN0 = getSubRiskSubNo(item.subRisks, riskId, '');
                            if (tmpN0 != "0") {
                                subNo = (index + 1) + '.' + tmpN0;
                            } else {
                                allSubRiskData.riskTypes.forEach((data1) => {
                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                        data1.subRisks.forEach((item1, index1) => {
                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                item1.subRisks.forEach((item2, index2) => {
                                                    item2.subRisks.forEach((item3, index3) => {
                                                        if (item3.riskTypeId === riskId) {
                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1);
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    }
                                })
                                if (tmpN0 === "0") {
                                    allSubRiskData.riskTypes.forEach((data1) => {
                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                            data1.subRisks.forEach((item1, index1) => {
                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                    item1.subRisks.forEach((item2, index2) => {
                                                        item2.subRisks.forEach((item3, index3) => {
                                                            item3.subRisks.forEach((item4, index4) => {
                                                                if (item4.riskTypeId === riskId) {
                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1);
                                                                }
                                                            })
                                                        })
                                                    })
                                                }
                                            })
                                        }
                                    })
                                    if (tmpN0 === "0") {
                                        allSubRiskData.riskTypes.forEach((data1) => {
                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                data1.subRisks.forEach((item1, index1) => {
                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                        item1.subRisks.forEach((item2, index2) => {
                                                            item2.subRisks.forEach((item3, index3) => {
                                                                item3.subRisks.forEach((item4, index4) => {
                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                        if (item5.riskTypeId === riskId) {
                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1);
                                                                        }
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                        if (tmpN0 === "0") {
                                            allSubRiskData.riskTypes.forEach((data1) => {
                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                    data1.subRisks.forEach((item1, index1) => {
                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                            item1.subRisks.forEach((item2, index2) => {
                                                                item2.subRisks.forEach((item3, index3) => {
                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                if (item6.riskTypeId === riskId) {
                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1);
                                                                                }
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    }
                                }


                            }

                        }

                    })
                }
            })

        }
        return subNo;
    }

    function getSubRiskSubNo(comment, riskId, subNo) {
        var no = "0"
        comment.forEach((data, index) => {
            if (data.riskTypeId === riskId) {
                no = subNo + (index + 1) + '';
            }
        });
        if (no === "0") {
            comment.forEach((item, index) => {
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    getSubRiskSubNo(item.subRisks, riskId, (index + 1));
                }

            })
        }
        return no;
    }



    function Comment({ section, riskNo, fromMainMenu, fromSubMenu }) {

        const [show, setShow] = useState(true)
        const [subRiskNo, setSubRiskNo] = React.useState(riskNo);

        var noDisplay = '';
        var noDisplaySpacesCount = 0;

        if (fromMainMenu) {
            fromMainMenu = false;
            noDisplay = riskNo;
            //subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageAppetite = getSubAvarageAppetite(comment);
        }

        if (fromSubMenu) {
            fromSubMenu = false;
            var subNo = getSubNo(section.id)
            noDisplay = riskNo + '.' + subNo;
            var tmpArr = noDisplay.split(".");
            if (tmpArr.length === 2) {
                noDisplaySpacesCount = 1;
            }

            //subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageRisk = getSubAvarageRisk1(comment);
            //subAvarageAppetite = getSubAvarageAppetite(comment);
        }





        const nestedComments = (section.risks || []).map(section => {
            return <Comment section={section} riskNo={subRiskNo} fromSubMenu={true} type="child" />
        })

        var riskColor = riskLevelcolorFunction(section.riskLevel)
        var riskApopetitecolor = riskApopetitecolorFunction(section.riskAppetite)
        var riskAction = riskLevelActionFunction(section.riskLevel)
        var riskApopetiteAction = riskApopetiteActionFunction(section.riskAppetite)

        return (
            <>
                <Grid item xs={6}>
                    {noDisplaySpacesCount === 0 ?
                        <></>
                        : noDisplaySpacesCount === 1 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;</>
                            :
                                ''
                    }


                    <Button
                        style={{ width: '0px', height: '0px' }}
                        id={`btn`}
                        onClick={() => setShow(!show)}
                        startIcon={show ? <ExpandMoreIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} /> : <ChevronRightIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} />}
                        >
                    </Button>
                    {noDisplay}{') '}
                    {section.section != null?
                        section.section
                    :
                        <>
                        {section.name}        
                        </>
                    }
                </Grid>

                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{section.likelihood}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{section.impact}</p></div></span>
                </Grid>
                <Grid item xs={1}><span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{section.riskLevel}</p></div></span></Grid> 
                <Grid item xs={1}><span style={{fontSize:'10px',fontWeight:'bold'}}>{riskAction}</span></Grid> 
                <Grid item xs={1}><span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskApopetitecolor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{section.riskAppetite}</p></div></span></Grid>
                <Grid item xs={1}><span style={{fontSize:'10px',fontWeight:'bold'}}>{riskApopetiteAction}</span></Grid>


                {section.section != null?
                    ''
                :
                    <>
                        {section.riskHierarchyData != undefined && section.riskHierarchyData != null ?
                            <>
                                {
                                    section.riskHierarchyData.riskTypes.map((riskType, index) => {
                                        return (
                                            <Comment1
                                                comment={riskType}
                                                fromMainMenu1={true}
                                                riskNo1={index + 1}
                                                parentRiskNo={noDisplay}
                                                allRiskData={section.riskHierarchyData}
                                            />
                                        )
                                    })
                                }

                            
                            </>
                        :
                            ''
                        }
                    </>
                }



                {
                    show ?
                        <>
                            {nestedComments}
                        </>
                    : null
                }

            
            </>
            )
        












        {/** 
        var riskColor = riskLevelcolorFunction(section.riskLevel)
        var riskApopetitecolor = riskApopetitecolorFunction(section.riskAppetite)
        var riskAction = riskLevelActionFunction(section.riskLevel)
        var riskApopetiteAction = riskApopetiteActionFunction(section.riskAppetite)
        return (
            <>


                <Grid item xs={4}>{section.section}</Grid>

                <Grid item xs={1}>{section.likelihood}</Grid>
                <Grid item xs={1}>{section.impact}</Grid>
                <Grid item xs={1}><span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{section.riskLevel}</p></div></span></Grid> 
                <Grid item xs={1}><span style={{fontSize:'10px',fontWeight:'bold'}}>{riskAction}</span></Grid> 
                <Grid item xs={1}><span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskApopetitecolor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{section.riskAppetite}</p></div></span></Grid>
                <Grid item xs={1}><span style={{fontSize:'10px',fontWeight:'bold'}}>{riskApopetiteAction}</span></Grid>

                {
                    section.risks.map((risk)=> {

                        var riskColor = riskLevelcolorFunction(risk.riskLevel)
                        var riskApopetitecolor = riskApopetitecolorFunction(risk.riskAppetite)
                        var riskAction = riskLevelActionFunction(risk.riskLevel)
                        var riskApopetiteAction = riskApopetiteActionFunction(risk.riskAppetite)

                        return(
                            <>
                            <Grid item xs={4}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{risk.name}</Grid>

                            <Grid item xs={1}>{risk.likelihood}</Grid>
                            <Grid item xs={1}>{risk.impact}</Grid>
                            <Grid item xs={1}><span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{risk.riskLevel}</p></div></span></Grid>    
                            <Grid item xs={1}><span style={{fontSize:'10px',fontWeight:'bold'}}>{riskAction}</span></Grid>
                            <Grid item xs={1}><span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskApopetitecolor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{risk.riskAppetite}</p></div></span></Grid>
                            <Grid item xs={1}><span style={{fontSize:'10px',fontWeight:'bold'}}>{riskApopetiteAction}</span></Grid>
                            </>
                        )
                    })
                }



          
            </>





        )

        */}

    }


    function Comment1({ comment, riskNo1, fromMainMenu1, fromSubMenu1, parentRiskNo,allRiskData }) {
        
        console.log("++++++++++++++++allRiskData++++++++++");
        console.log(allRiskData);
        const [show, setShow] = useState(true)
        const [subRiskNo1, setSubRiskNo1] = React.useState(riskNo1);
        const [parentSubRiskNo, setParentSubRiskNo] = React.useState(parentRiskNo);
        const [allSubRiskData, setAllSubRiskData] = React.useState(allRiskData);

        var noDisplay = '';
        var noDisplaySpacesCount = 2;

        if (fromMainMenu1) {
            fromMainMenu1 = false;
            noDisplay = parentRiskNo + '.' + riskNo1;
            //subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageAppetite = getSubAvarageAppetite(comment);
        }

        if (fromSubMenu1) {
            fromSubMenu1 = false;
            var subNo = getSubNo1(allSubRiskData, comment.riskTypeId)
            noDisplay = parentRiskNo + '.' + riskNo1 + '.' + subNo;
            var tmpArr = noDisplay.split(".");
            if (tmpArr.length === 2) {
                noDisplaySpacesCount = 1;
            }
            if (tmpArr.length === 3) {
                noDisplaySpacesCount = 2;
            }
            if (tmpArr.length === 4) {
                noDisplaySpacesCount = 3;
            }
            if (tmpArr.length === 5) {
                noDisplaySpacesCount = 4;
            }
            if (tmpArr.length === 6) {
                noDisplaySpacesCount = 5;
            }
            if (tmpArr.length === 7) {
                noDisplaySpacesCount = 6;
            }
            if (tmpArr.length === 8) {
                noDisplaySpacesCount = 7;
            }
            if (tmpArr.length === 9) {
                noDisplaySpacesCount = 8;
            }
            if (tmpArr.length === 10) {
                noDisplaySpacesCount = 9;
            }
            if (tmpArr.length === 11) {
                noDisplaySpacesCount = 10;
            }


            //subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageAppetite = getSubAvarageAppetite(comment);
        }


        const nestedComments = (comment.subRisks || []).map(comment => {
            return <Comment1 parentRiskNo={parentSubRiskNo} allRiskData={allSubRiskData} riskNo1={subRiskNo1} fromSubMenu1={true} comment={comment} type="child" />
        })

        var appetiteColor = getAppetiteColor(comment);
        var appetiteAction = getRiskAppetiteAction(comment);
        var riskColor = getRiskRiskColor(comment);
        var riskAction = getRiskRiskAction(comment);

        function getRiskRiskColor(Comment) {
            var rColor = ''
            var lastNumber = ''
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rColor = data2.color
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rColor = data2.secondaryColor
                        }
                    }
                }
            })
            if (rColor === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.color
                            }
                        } else if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.secondaryColor
                            }
                        }
                    }
                })
            }
            return rColor;
        }


        function getRiskRiskAction(Comment) {
            var rAction = ''
            var lastNumber = ''
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rAction = data2.primaryColorName
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rAction = data2.secondaryColorName
                        }
                    }
                }
            })
            if (rAction === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rAction = data2.primaryColorName
                            }
                        } else if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rAction = data2.secondaryColorName
                            }
                        }
                    }
                })
            }
            return rAction;
        }

        function getRiskAppetiteAction(Comment) {
            var aColor = ''
            var colorFg1 = ''
            databClr.forEach((data2) => {
                if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
                    if (Comment.riskAppetiteData != null && Comment.riskAppetiteData != undefined) {
                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (aColor === '') {
                                    aColor = data2.primaryColorName
                                }
                            }
                            if (colorSelectDropDownRiskAppetite === 2) {
                                if (aColor === '') {
                                    aColor = data2.secondaryColorName
                                }
                            }
                        }
                    }
                }
            })
            return aColor;
        }

        function getAppetiteColor(Comment) {
            var aColor = ''
            var colorFg1 = ''
            databClr.forEach((data2) => {
                if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
                    if (Comment.riskAppetiteData != null && Comment.riskAppetiteData != undefined) {
                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (aColor === '') {
                                    aColor = data2.color
                                }
                            }
                            if (colorSelectDropDownRiskAppetite === 2) {
                                if (aColor === '') {
                                    aColor = data2.secondaryColor
                                }
                            }
                        }
                    }
                }
            })
            return aColor;
        }

        return (
            <>
                <Grid item xs={6}>
                    {noDisplaySpacesCount === 0 ?
                        <></>
                        : noDisplaySpacesCount === 1 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;</>
                            : noDisplaySpacesCount === 2 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                : noDisplaySpacesCount === 3 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                    : noDisplaySpacesCount === 4 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                        : noDisplaySpacesCount === 5 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                            : noDisplaySpacesCount === 6 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                : noDisplaySpacesCount === 7 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                    : noDisplaySpacesCount === 8 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                        : noDisplaySpacesCount === 9 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                            : noDisplaySpacesCount === 10 ?
                                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                                : ''
                    }
                    <Button
                        style={{ width: '0px', height: '0px' }}
                        id={`btn`}
                        onClick={() => setShow(!show)}
                        startIcon={show ? <ExpandMoreIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} /> : <ChevronRightIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} />}
                    >
                    </Button>
                    {noDisplay}{') '}
                    {comment.name}
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLikelihood}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskImpact}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLevel}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px' }}><p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '9px' }}>{riskAction}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${appetiteColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskAppetite}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px' }}><p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '9px' }}>{appetiteAction}</p></div></span>
                </Grid>


                {
                    show ?
                        <>
                            {nestedComments}
                        </>
                    : null
                }
            </>

        )       


    }










    const handleChangeDropDown = (event) => {
        setColorSelectDropDownRiskLevelTmp(event.target.value);

    };

    const handleChangeDropDownAppetite = (event) => {
        setColorSelectDropDownRiskAppetiteTmp(event.target.value);
        //setShowEvalData(false)
    };

    function setMinMaxWindowFunction() {
        if (!fullScreenState) {
            setFullScreenState(true);
            setFullScreenStateMsg('Full Screen Out');
        } else {
            setFullScreenState(false);
            setFullScreenStateMsg('Full Screen');
        }

    }

    function hierarchicalFunction() {
        setBtnHirachivalClass(classes.btnActive)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnNormal)
    }

    const [arrayForDisplayDataInTable, setArrayForDisplayDataInTable] = useState([])

    var commitArray = []
    function pushToArray(item) {
        commitArray.push(item);
        console.log("+++++++++++++++++++++commitArray+++++++++++++++");
        console.log(commitArray);
    }

    const [commitArrayData, setCommitArrayData] = useState([])

    function allRegisterArr() {
        var arr = []
        allRegister.forEach((data) => {
            if (data.id === registerId) {
                data.riskRegister.forEach((data1) => {
                    arr.push(data1)
                    data1.risks.forEach((risk) => {
                        var obj = {}
                        obj["section"] = risk.name
                        obj["riskLevel"] = risk.riskLevel
                        obj["riskAppetite"] = risk.riskAppetite

                        arr.push(obj)
                    })
                })
            }
        })
        console.log(arr)

    }

    function displayRiskAsending() {
        setShowEvalData(true)
        // var arr = [];
        // if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
        //     //arr = commitArray.sort(compareRiskTypeOrder);
        //     arr = commitArray
        // } else {
        //     arr = commitArrayData;
        // }

        var arr = []
        allRegister.forEach((data) => {
            if (data.id === registerId) {
                data.riskRegister.forEach((data1) => {
                    arr.push(data1)
                    data1.risks.forEach((risk) => {
                        var obj = {}
                        obj["section"] = risk.name
                        obj["riskLevel"] = risk.riskLevel
                        obj["riskAppetite"] = risk.riskAppetite

                        arr.push(obj)
                    })
                })
            }
        })

        allRegisterArr()
        setArrayForDisplayDataInTable(arr);
        setCommitArrayData(arr);
        setShowTableType(2)

        setBtnHirachivalClass(classes.btnNormal)
        setBtnGradientClass(classes.btnActive)
        setBtnBarChartClass(classes.btnNormal)
    }

    function createBarFunction() {


        // var arr = [];
        // if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
        //     //arr = commitArray.sort(compareRiskTypeOrder);
        //     arr = commitArray
        // } else {
        //     if (commitBarData != undefined && commitBarData != null && commitBarData.length > 0) {
        //         arr = commitBarData;
        //     } else {
        //         arr = commitArrayData;
        //     }
        // }

        var arr = []
        var riskColor = ''
        var appetiteColor = ''
        allRegister.forEach((data) => {
            if (data.id === registerId) {
                data.riskRegister.forEach((data1) => {
                    var obj1 = {}
                    obj1["section"] = data1.section
                    obj1["riskLevel"] = data1.riskLevel
                    obj1["riskAppetite"] = data1.riskAppetite
                    riskColor = '';
                    colorPatterns.map((pattern) => {
                        if (pattern.id === colorPatternId) {
                            var lastScoreColor = ''
                            var lastScoreColorSecondary = ''
                            pattern.colors.map((clr) => {
                                lastScoreColor = clr.color;
                                lastScoreColorSecondary = clr.secondaryColor
                                if(colorSelectDropDownRiskLevelTmp === 1){
                                    if (clr.scoreNumber === parseInt(data1.riskLevel)) {
                                        riskColor = clr.color
                                    }
                                }
                                if (colorSelectDropDownRiskLevelTmp === 2) {
                                    if (clr.scoreNumber === parseInt(data1.riskLevel)) {
                                        riskColor = clr.secondaryColor
                                    }
                                }
                            })
                            if (riskColor === ''){
                                if(colorSelectDropDownRiskLevelTmp === 1){
                                    riskColor = lastScoreColor
                                }else{
                                    riskColor = lastScoreColorSecondary
                                }
                            }

                        }
                    })
                    obj1["riskColor"] = riskColor

                    colorPatterns.map((pattern) => {
                        if (pattern.id === colorPatternId) {
                            pattern.colors.map((clr) => {
                                if(colorSelectDropDownRiskAppetiteTmp === 1){
                                    if (clr.scoreNumber === parseInt(data1.riskAppetite)) {
                                        appetiteColor = clr.color
                                    }
                                }
                                if(colorSelectDropDownRiskAppetiteTmp === 2){
                                    if (clr.scoreNumber === parseInt(data1.riskAppetite)) {
                                        appetiteColor = clr.secondaryColor
                                    }
                                }

                            })
                        }
                    })
                    obj1["appetiteColor"] = appetiteColor

                    arr.push(obj1)
                    //arr.push(data1)
                    data1.risks.forEach((risk) => {
                        var obj = {}
                        obj["section"] = risk.name
                        obj["riskLevel"] = risk.riskLevel
                        obj["riskAppetite"] = risk.riskAppetite
                        riskColor = ''
                        colorPatterns.map((pattern) => {
                            if (pattern.id === colorPatternId) {
                                var lastScoreColor = ''
                                var lastScoreColorSecondary = ''
                                pattern.colors.map((clr) => {
                                    lastScoreColor = clr.color;
                                    lastScoreColorSecondary = clr.secondaryColor
                                    if(colorSelectDropDownRiskLevelTmp === 1){
                                        if (parseInt(clr.scoreNumber) === parseInt(risk.riskLevel)) {
                                           riskColor = clr.color
                                        }
                                    }
                                    if (colorSelectDropDownRiskLevelTmp === 2) {
                                        if (parseInt(clr.scoreNumber) === parseInt(risk.riskLevel)) {
                                            riskColor = clr.secondaryColor
                                        }
                                    }

                                })
                                if (riskColor === ''){
                                    if(colorSelectDropDownRiskLevelTmp === 1){
                                        riskColor = lastScoreColor
                                    }else{
                                        riskColor = lastScoreColorSecondary
                                    }
                                }
                            }
                        })
                        obj["riskColor"] = riskColor

                        colorPatterns.map((pattern) => {
                            if (pattern.id === colorPatternId) {
                                pattern.colors.map((clr) => {
                                    if(colorSelectDropDownRiskAppetiteTmp === 1){
                                        if (clr.scoreNumber === parseInt(risk.riskAppetite)) {
                                            appetiteColor = clr.color
                                        }
                                    }
                                    if(colorSelectDropDownRiskAppetiteTmp === 2){
                                        if (clr.scoreNumber === parseInt(risk.riskAppetite)) {
                                            appetiteColor = clr.secondaryColor
                                        }
                                    }    

                                })
                            }
                        })
                        obj["appetiteColor"] = appetiteColor

                        arr.push(obj)

                        console.log(arr)
                    })
                })
            }
        })
            
        setShowEvalData(true)
        setShowTableType(3)

            if (arr.length <= 10) {
                setBarChartHeight(600)
            } else {
                if (arr.length > 10 && arr.length <= 17) {
                    setBarChartHeight(800)
                } else {
                    if (arr.length > 17 && arr.length <= 30) {
                        setBarChartHeight(1000)
                    } else {
                        setBarChartHeight(1200)
                    }
                }
            }


            var arr1 = [];
            var arrRiskColor = []
            var arrColorApp = []

            arr.forEach((data) => {
                var obj = {}
                var name = data.section;
                obj["argument"] = name;
                obj["riskLevel"] = parseInt(data.riskLevel);

                // colorPatterns.map((data1)=> {
                //     if(data1.id === colorPatternId){
                //         data1.color.map((clr)=> {
                //             if (clr.scoreNumber === parseInt(data.riskLevel)) {
                //                 arrRiskColor = clr.color
                //             }

                //         })
                //     }
                // })

                arrRiskColor.push(data.riskColor)
                obj["riskAppetite"] = parseInt(data.riskAppetite);
                arrColorApp.push(data.appetiteColor)
                arr1.push(obj)
            })
                setCommitBarData(arr);
                console.log(arr)
                setBarData(arr1);
                setArrRiskColorState(arrRiskColor)
                setArrColorAppState(arrColorApp)


            setBtnHirachivalClass(classes.btnNormal)
            setBtnGradientClass(classes.btnNormal)
            setBtnBarChartClass(classes.btnActive)
    }



    function applyFunction() {
        setColorSelectDropDownRiskLevel(colorSelectDropDownRiskLevelTmp);
        setColorSelectDropDownRiskAppetite(colorSelectDropDownRiskAppetiteTmp)

        setShowEvalData(true)
        setApplyFlag(true)
        setShowTableType(1)
        setShowTranferExlButton(true)

        setBtnHirachivalClass(classes.btnActive)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnNormal)

    }

    function setOpenRegisterDataForEvalfunction(status, id) {
        


        
        colorPatterns.forEach((data, index) => {
            if (index === 0) {
                setColorPatternId(data.id);
                setDatabClr(data.colors)
                setTdPresentage(100 / (data.colors.length + 1))

                setColorPatternName(data.name);
                setColorPatternPrimaryColorName(data.primaryColorName);
                setColorPatternSecondaryColorName(data.secondaryColorName);
            }
        })
        setColorSelectDropDownRiskLevelTmp(1)
        setColorSelectDropDownRiskAppetiteTmp(2)
        setBtnHirachivalClass(classes.btnNormal)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnNormal)

        setOpenRegisterDataForEval(status)
        setRegisterId(id)


    }

    function RegisterDataForEvalfunctionClose() {
        setOpenRegisterDataForEval(false)
        setDatabClr([]);
        setBarData([]);
        setCommitBarData([])
        setColorPatternPrimaryColorName('');
        setColorPatternSecondaryColorName('');
        setColorSelectDropDownRiskLevel()
        setColorSelectDropDownRiskAppetite()
        setApplyFlag(false)
        setShowEvalData(false)
        setShowTranferExlButton(false)
    }

    const [databClr, setDatabClr] = useState([]);

    const handleChangeColorPattern = (event) => {
        setColorPatternId(event.target.value);
        colorPatterns.forEach((data, index) => {
            if (data.id === event.target.value) {
                console.log(data)
                setDatabClr(data.colors)
                setTdPresentage(100 / (data.colors.length + 1))

                setColorPatternName(data.name);
                setColorPatternPrimaryColorName(data.primaryColorName);
                setColorPatternSecondaryColorName(data.secondaryColorName);
            }
        })
    };

    function getColors() {
        axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR + "/all", {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("+++++++++++++++++All colors++++++++++++++++++");
                console.log(response.data);
                //setDatabClr(response.data)
                setColorPatterns(response.data);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function showDataInAViewFunction(no) {

        setEditRiskHirachy(true);
        setWaitingOpen(true);
        setTimeout(() => {
            setWaitingOpen(false);
        }, 500)

    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#E2E4EC', padding: '3px 15px', border: '1px solid black', borderRadius: '5PX' }}>
                    <p className="label" style={{ fontWeight: 'bold' }}>{`${label} `}</p>
                    <p className="label">{`Risk Level : ${payload[0].value}`}</p>
                    <p className="label">{`Risk Appetite : ${payload[1].value}`}</p>

                </div>
            );
        }

        return null;
    };


    function saveRisk() {
        setWaitingOpen(true);
        var data = JSON.stringify(riskTypeDataForEval);
        console.log("++++++++++++++++Saving data++++++++++++++++++++++");
        console.log(data);

        axios(CONFIG.MAIN_URL + '/risk/save', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        })
            .then(function (response) {
                console.log("++++++++++++++Updatd Return+++++++++++");
                console.log(response.data);
                setWaitingOpen(false);
                alert("Risk Updated successfully");
                setEditRiskHirachy(false)
                //setOpenRiskDataEditfunction();
            })
            .catch(function (error) {
                setWaitingOpen(false);
                console.log(error);
                alert("Error saving Risk Type -:" + error);
            });



    }



    const registerTableRowData = (
        <TableBody>
            {allRegister.map((register, index) => {

                return (
                    <StyledTableRow key={index} >
                        <StyledTableCell component="th" scope="row" style={{ width: '90%' }}>
                            <div style={{ whiteSpace: 'normal' }}>{register.name}</div>
                        </StyledTableCell>

                        <StyledTableCell
                            align="right">
                            <AssessmentOutlinedIcon
                                style={{ cursor: 'pointer' }}
                                onClick={e => setOpenRegisterDataForEvalfunction(true, register.id)}
                            />

                        </StyledTableCell>
                    </StyledTableRow>
                )

            })}
        </TableBody>
    )
    return (
        <>
            <div>
                <div >
                    <Grid item xs={12}>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>

                                <TableContainer
                                    component={Paper}
                                    sx={{ maxHeight: 400 }}

                                >
                                    <TableHead className={classe.commonSecondaryTableHead}>
                                        <TableRow>
                                            <StyledTableCell className={classe.commonTableThStyle} style={{ width: '90%' }}>Registers</StyledTableCell>
                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '10%' }}>Evaluate</StyledTableCell>

                                        </TableRow>
                                    </TableHead>
                                    <Table aria-label="customized table" style={{ border: '1px solid #ddd' }}>
                                        {registerTableRowData}
                                    </Table>
                                </TableContainer>

                            </Grid>

                        </Grid>
                    </Grid>
                </div>


                <Dialog
                    open={openRegisterDataForEval}
                    onClose={false}
                    scroll={openRegisterDataForEvalScroll}
                    aria-labelledby="collection-data-dialog-title"
                    aria-describedby="collection-data-dialog-description"
                    fullWidth={true}
                    maxWidth={maxWidthState}
                    fullScreen={fullScreenState}
                    PaperComponent={PaperComponentCollectionData}
                >
                    <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
                        <Typography variant="h6" style={{ fontSize: '25px !important' }}>Evaluate Risk Register - "{selectedRegisterEvaluateTitle}"
                        </Typography>
                        <Typography variant="h6" style={{ marginRight: '-22px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                            <Tooltip1 title={fullScreenStateMsg}><FullscreenRounded onClick={() => { setMinMaxWindowFunction() }} style={{ cursor: 'pointer' }} /></Tooltip1>
                            <Tooltip1 title="Close"><Close onClick={RegisterDataForEvalfunctionClose} style={{ cursor: 'pointer' }} /></Tooltip1>
                        </Typography>
                    </DialogTitle>

                    {!fullScreenState ?
                        <Grid container spacing={2} style={{ marginLeft: '10px', marginBottom: '20px' }}>
                            <Grid item xs={2.5}>
                                select your color patterns here :
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label='Select Color Pattern'
                                    required
                                    sx={{
                                        width: '100%',
                                        "& .MuiInputBase-root": {
                                            height: 37
                                        },
                                        "& .MuiInputLabel-root": {
                                            marginTop: -0.5
                                        }
                                    }}
                                    select
                                    value={colorPatternId}
                                    onChange={handleChangeColorPattern}
                                >
                                    {colorPatterns.map((item) => (
                                        <MenuItem
                                            value={item.id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>



                            <Grid item xs={2}>

                                <TextField
                                    label='Risk Level'
                                    required
                                    sx={{
                                        width: '100%',
                                        "& .MuiInputBase-root": {
                                            height: 37
                                        },
                                        "& .MuiInputLabel-root": {
                                            marginTop: -0.5
                                        }
                                    }}
                                    select
                                    value={colorSelectDropDownRiskLevelTmp}
                                    onChange={handleChangeDropDown}
                                >
                                    <MenuItem value={1}>{colorPatternPrimaryColorName}</MenuItem>
                                    <MenuItem value={2}>{colorPatternSecondaryColorName}</MenuItem>
                                </TextField>



                            </Grid>

                            <Grid item xs={2}>
                                <TextField
                                    label='Risk Appetite'
                                    required
                                    sx={{
                                        width: '100%',
                                        "& .MuiInputBase-root": {
                                            height: 37
                                        },
                                        "& .MuiInputLabel-root": {
                                            marginTop: -0.5
                                        }
                                    }}
                                    select
                                    value={colorSelectDropDownRiskAppetiteTmp}
                                    onChange={handleChangeDropDownAppetite}
                                >
                                    <MenuItem value={1}>{colorPatternPrimaryColorName}</MenuItem>
                                    <MenuItem value={2}>{colorPatternSecondaryColorName}</MenuItem>
                                </TextField>


                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='outlined' style={{ padding: "7px 5px" }} onClick={applyFunction}>Apply</Button>
                            </Grid>


                            <Grid container spacing={2}>


                                <Grid item xs={2}>
                                    &nbsp; &nbsp;Views
                                </Grid>  &nbsp;
                                <Grid item xs={1.5}>
                                    <Button
                                        variant='outlined'
                                        className={btnHirachivalClass}
                                        onClick={() => { setShowEvalData(true); setShowTableType(1); hierarchicalFunction() }}
                                    >
                                        Hierarchical View
                                    </Button>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <Button
                                        variant='outlined'
                                        className={btnGradientClass}
                                        onClick={() => displayRiskAsending()}
                                    >
                                        Gradient View
                                    </Button>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <Button
                                        variant='outlined'
                                        className={btnBarChartClass}
                                        onClick={() => createBarFunction()}
                                    >
                                        BarChart View
                                    </Button>
                                </Grid>

                                <Grid item xs={2}>
                                </Grid>
                                {showTranferExlButton ?
                                    <>
                                        <Grid item xs={2}>
                                            <TransferToExcelRegister
                                                allRegister={allRegister}
                                                registerId = {registerId}

                                            />

                                        </Grid>
                                    </>
                                    : ''}


                            </Grid>
                        </Grid>

                        : ''
                    }

                    <DialogContent dividers={openRegisterDataForEvalScroll === 'paper'}>
                        <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {Object.keys(allRegister).length > 0 &&
                                        <>
                                            <Grid item xs={12}>
                                                <div>

                                                    {
                                                        showEvalData && showTableType === 1 ?
                                                            <>

                                                                <Grid container spacing={1} id='divHierarchicalViewId'>
                                                                    <Grid item xs={6}>&nbsp;</Grid>
                                                                    <Grid item xs={1}><b><u>Likelihood</u></b></Grid>
                                                                    <Grid item xs={1}><b><u>Impact</u></b></Grid>
                                                                    <Grid item xs={1}><b><u>Risk Level</u></b></Grid>
                                                                    <Grid item xs={1}><b><u>Action</u></b></Grid>
                                                                    <Grid item xs={1}><b><u>Appetite</u></b></Grid>
                                                                    <Grid item xs={1}><b><u>Action</u></b></Grid>
                                                                    {
                                                                        allRegister.map((data) => {
                                                                            return (
                                                                                <>
                                                                                    {data.id === registerId ?
                                                                                        <>

                                                                                            {
                                                                                                data.riskRegister.map((sec,index) => {

                                                                                                    return (

                                                                                                        <>
                                                                                                            <Comment
                                                                                                                section={sec}
                                                                                                                fromMainMenu={true}
                                                                                                                riskNo={index + 1}
                                                                                                            />
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </>



                                                                                        : ''
                                                                                    }
                                                                                </>

                                                                            )

                                                                        })
                                                                    }

                                                                    {/* {
                                                                    riskTypeDataForEval.riskTypes.map((riskType, index) => {

                                                                        return (

                                                                            <Comment
                                                                                key={riskType.riskTypeTmpId}
                                                                                comment={riskType}
                                                                                lastRiskTmpId={lastRiskTmpId}
                                                                                riskNo={index + 1}
                                                                                fromMainMenu={TurnedInOutlined}
                                                                                editRiskHirachyData={editRiskHirachy}
                                                                            //lastRiskTmpId={lastRiskTmpId}
                                                                            />

                                                                        )

                                                                    })


                                                                } */}


                                                                </Grid>
                                                            </>

                                                            : showEvalData && showTableType === 2 ?

                                                                <>
                                                                    <Grid item xs={12}>

                                                                        <p>Risk Level</p>
                                                                        <table style={{ backgroundImage: colorSelectDropDownRiskLevel === 1 ? 'linear-gradient(to right, green , red)' : colorSelectDropDownRiskLevel === 2 ? 'linear-gradient(to right, red , green)' : '', width: '100%', color: 'white', minHeight: '200px' }} border='0'>
                                                                            {/** 
                                                <tr style={{width:'100%'}}>

                                                {databClr.map((data, index) => (
                                                    <td style={{ width: `${tdPresentage}%` }} key={index} value={index}>
                                                        
                                                            &nbsp;
                                                    </td>
                                                    ))}
                                                    
                                                  
                                                </tr>
                                                */}

                                                                            <tr>
                                                                                {
                                                                                    databClr.map((item, index1) => (

                                                                                        <td style={{ verticalAlign: 'top', width: `${tdPresentage}%` }}>
                                                                                            <table style={{ verticalAlign: 'top' }}>
                                                                                                {
                                                                                                    arrayForDisplayDataInTable.map((data, index) => (
                                                                                                        parseInt(data.riskLevel) === item.scoreNumber || (databClr.length === (index1 + 1) && parseInt(data.riskLevel) >= item.scoreNumber) ?

                                                                                                            <tr>
                                                                                                                <td style={{ fontSize: '10px', width: '5%', verticalAlign: 'top' }}><span style={{ height: '6px', width: '6px', display: 'inline-block', backgroundColor: 'white', borderRadius: '50%' }}></span></td>
                                                                                                                <td style={{ fontSize: '10px' }}>{data.section}</td>
                                                                                                            </tr>
                                                                                                            :
                                                                                                            ''

                                                                                                    ))
                                                                                                }
                                                                                            </table>

                                                                                        </td>
                                                                                    ))
                                                                                }




                                                                            </tr>


                                                                        </table>
                                                                    </Grid>


                                                                    <Grid item xs={12}>

                                                                        <p style={{ marginTop: '8px', marginBottom: '5px' }}>Risk Appetite</p>
                                                                        <table style={{ backgroundImage: colorSelectDropDownRiskLevel === 1 ? 'linear-gradient(to right, green , red)' : colorSelectDropDownRiskLevel === 2 ? 'linear-gradient(to right, red , green)' : '', width: '100%', color: 'white', minHeight: '200px' }} border='0'>
                                                                            {/** 
                                            <tr style={{width:'100%'}}>

                                            {databClr.map((data, index) => (
                                                <td style={{ width: `${tdPresentage}%` }} key={index} value={index}>
                                                    
                                                        &nbsp;
                                                </td>
                                                ))}
                                                
                                            
                                            </tr>
                                            */}

                                                                            <tr>
                                                                                {
                                                                                    databClr.map((item, index1) => (

                                                                                        <td style={{ verticalAlign: 'top', width: `${tdPresentage}%` }}>
                                                                                            <table style={{ verticalAlign: 'top' }}>
                                                                                                {
                                                                                                    arrayForDisplayDataInTable.map((data, index) => (
                                                                                                        parseInt(data.riskAppetite) === item.scoreNumber || (databClr.length === (index1 + 1) && parseInt(data.riskAppetite) >= item.scoreNumber) ?

                                                                                                            <tr>
                                                                                                                <td style={{ fontSize: '10px', width: '5%', verticalAlign: 'top' }}><span style={{ height: '6px', width: '6px', display: 'inline-block', backgroundColor: 'white', borderRadius: '50%' }}></span></td>
                                                                                                                <td style={{ fontSize: '10px' }}>{data.section}</td>
                                                                                                            </tr>
                                                                                                            :
                                                                                                            ''

                                                                                                    ))
                                                                                                }
                                                                                            </table>

                                                                                        </td>
                                                                                    ))
                                                                                }




                                                                            </tr>


                                                                        </table>
                                                                    </Grid>

                                                                </>
                                                                :
                                                                showEvalData && showTableType === 3 ?
                                                                    <>


                                                                        <BarChart width={1000} height={barChartHeight} data={barData} margin={{ bottom: 50 }} layout="vertical">
                                                                            <XAxis
                                                                                type="number"
                                                                                axisLine={false}

                                                                                margin={{ top: 0, right: 40, left: 40, bottom: 20 }}
                                                                            />
                                                                            <YAxis dataKey="argument" type="category" width={400} />
                                                                            <CartesianGrid strokeDasharray="2 2" />
                                                                            <Tooltip content={<CustomTooltip />} />



                                                                            <Bar dataKey="riskLevel" fill={arrRiskColorState}>
                                                                                {barData.map((entry, index) => (
                                                                                    <Cell key={`cell-${index}`} fill={arrRiskColorState[index % arrRiskColorState.length]} />
                                                                                ))}
                                                                            </Bar>


                                                                            <Bar dataKey="riskAppetite" fill={arrColorAppState}>
                                                                                {barData.map((entry, index) => (
                                                                                    <Cell key={`cell-${index}`} fill={arrColorAppState[index % arrColorAppState.length]} />
                                                                                ))}
                                                                            </Bar>

                                                                        </BarChart>
                                                                    </>

                                                                    :

                                                                    ''
                                                    }


                                                </div>
                                            </Grid>




                                        </>
                                    }
                                </Grid>




                            </Grid>





                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {editRiskHirachy ?
                            <>
                                <Button
                                    variant="contained"
                                    size='small'
                                    style={{ "border": "1px solid #9155FD !important", "color": "#9155FD !important", "padding": "5px 10px !important", "height": "40px !important", }}
                                    onClick={saveRisk}
                                >
                                    Update Risk
                                </Button>&nbsp;&nbsp;
                            </>
                            :
                            ''
                        }
                        <Button onClick={RegisterDataForEvalfunctionClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}

export default RegisterEvaluateComponent