import React, { useEffect, useState, useRef } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import CardContent from '@mui/material/CardContent';
import { CONFIG } from '../config'
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info, KeyboardReturnOutlined } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import '../Css/Slider.css';
import Draggable from 'react-draggable';
import useStyle from '../Css/MainCss';
import Delete from '@mui/icons-material/Delete';
import { Editor } from '@tinymce/tinymce-react';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from "@mui/material/Typography";
import MenuItem from '@mui/material/MenuItem';
import { Alert } from 'bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddSubContentComponent from './AddSubContentComponent';
import { Select, InputLabel } from '@mui/material';
import FormControl from "@mui/material/FormControl";
import parseHtml from 'html-react-parser';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles((theme) => ({

    cardRiskManage: {
        width: '100%',
        border: '1px solid black'
    },
    riskImageSize: {
        width: '120px',
        height: '100px !important',
    },
    tableStyle: {
        // width: '55% !important',
        //minWidth: '720px !important'
    },
    root: {
        position: 'relative',
    },
    thStyleImage: {
        lineHeight: '0.5rem !important',
        borderBottom: '0px !important',
        width: '130px !important',
    },
    posRelativeAndDivBorder: {
        position: 'relative',
        border: '1px solid #4C7EE0',
        width: '120px',
        height: '100px !important',
    },
    thStyle: {
        fontSize: '12px !important',
        lineHeight: '0.5rem !important',
        borderBottom: '0px !important'
    },
    right: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    cardRiskManage: {
        width: '100%',
        border: '1px solid black'
    },
    tableTrStyle: {
        height: '19px !important'
    },
    mousePointer: {
        cursor: 'pointer'
    },
    stdMainCardHeader: {
        backgroundColor: '#4C7EE0 !important',
        fontSize: '13px !important',
        height: '50px',
        padding: '0px',
        marginTop: '-7px',
        color: 'white'
    },
    addrisktypebtn: {
        marginLeft: '0px !important',
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },
    btnBorderpurple: {
        border: '1px solid #9155FD !important',
        color: '#9155FD !important',
        padding: '5px 10px !important',
        height: '40px !important',
    },
    stdCard: {
        height: '100%',
        border: '1px solid black'
    },
    scrollableDivToStandard: {
        height: '350px',
        overflow: 'auto',
        width: '100%',
    },
    btnColorPurple: {
        fontSize: '12px !important',
        backgroundColor: '#BACDF2 !important',
        height: '40px',
        textAlign: 'center',
        color: '#4C7EE0 !important',
        border: '1px solid #4C7EE0'
    },
    btnBorderGreen: {
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        height: '40px'
    },


}));

const SuperAdminLoginPageDesign = ({ tokens }) => {

    const classe = useStyle();
    const classes = useStyles();
    const Input = styled('input')({
        display: 'none',
    });

    const editorRef = useRef(null);

    const [logInPageDesign, setLogInPageDesign] = useState('')
    const [logInPageDesignId, setLogInPageDesignId] = useState('')
    const [logInPageVersionNo, setLogInPageVersionNo] = useState('')
    const [backgroundColor, setBackgroundColor] = useState('');


    const [allLoginPages, setAllLoginPages] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null)
    const [preview, setPreview] = React.useState()
    const [imgLocation, setImgLocation] = useState('');


    React.useEffect(() => {
        getData();
        getTemplates();


        if (!selectedFile) {
            getHtmlData();
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)

    }, [selectedFile])

    const [globalCentreId, setGlobalCentreId] = React.useState('');
    const [userRoleId, setUserRoleId] = React.useState('');
    function getData() {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        console.log("++++++++++++++++++++++++++=DATA++++++++++++++++++++++++");
        console.log(data);
        var roleId = data.userRoleId
        if (roleId === "staff") {
            roleId = "ADMIN";
        }
        setUserRoleId(roleId);

        setGlobalCentreId(data.centreId);
        getAllCentresFromServiceSystem(data.centreId, roleId);
    }

    const [centreName, setCentreName] = useState('')
    const [centreId, setCentreId] = useState('')
    const [allCentres, setAllCentres] = React.useState([]);
    function getAllCentresFromServiceSystem(tCenterId, tRoleId) {
        var data = '{getAllCentreList{centreId,centreName}}';
        axios(CONFIG.MAIN_URL + '/graphql', {
            method: 'post',
            headers: {
                'Content-Type': 'text/plain',
            },
            data: data,
        })
            .then(function (response) {
                var centreList = response.data.data.getAllCentreList;
                console.log("All Centre List");
                console.log(centreList);
                var arr = [];
                if (tRoleId === "ADMIN") {
                    centreList.forEach((item, index) => {
                        if (item.centreId === tCenterId) {
                            arr.push(item);
                        }
                    })
                } else {
                    arr = centreList;
                }
                setAllCentres(arr);
            })
            .catch(function (error) {
                console.log("Entity Loding error" + error);
            });
    }

    const handleChangeCentre = (event) => {
        setCentreId(event.target.value)
        allCentres.forEach((data) => {
            if (data.centreId === event.target.value) {
                setCentreName(data.centreName)
            }
        })

    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const [arrCentreNames, setArrCentreNames] = React.useState([]);

    const handleChangeCentreNames = (event) => {
        const {
            target: { value },
        } = event;
        var msg = "";
        allLoginPages.forEach((item) => {
            if (item.domainName != selectedDomainName) {
                if (item.domainAllocatedCentres != undefined && item.domainAllocatedCentres != null){
                    item.domainAllocatedCentres.forEach((data) => {
                        let cName = data.centreName;
                        let cNames = event.target.value;
                        //let cNamesArr = cNames.split(',')
                        cNames.forEach((name) => {
                            if (name === cName){
                                msg = "You cannot select the \"" + cName + "\" centre as it was already allocated to domain \"" + item.domainName + "\"";
                            }
                        })
                    })
                }
            }
        })
        if (msg != ''){
            alert(msg);
            return;
        }

        setArrCentreNames(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };




    const [tinyMCETemplateList, setTinyMCETemplateList] = React.useState()

    var templateArr = [];
    function getTemplateHtml(fileName) {
        axios('https://www.daytaem.co.uk/readFile.php?q=' + fileName, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(function (res) {
                var tmpA = fileName.split(".")
                var templateObj = {};
                templateObj["title"] = tmpA[0].replace("RST_", "");
                templateObj["description"] = "Risk System - Template ";
                templateObj["content"] = res.data;
                templateArr.push(templateObj);
                setTinyMCETemplateList(templateArr);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    function getTemplates() {
        var filesArr = "";
        axios('https://www.daytaem.co.uk/getFile.php?q=aaaaaaa', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(function (response) {
                console.log(response);
                var resData = response.data;
                var arrTmp = resData.split("@@");
                var filesStr = arrTmp[1];

                filesArr = filesStr.split("]");
                var k = 1;

                var templateHtmlArr = [];
                for (var i = 1; i < filesArr.length; i++) {
                    if (filesArr[i] != "") {
                        if (filesArr[i].slice(0, 3) === "RST") {
                            var fileName = filesArr[i];
                            getTemplateHtml(fileName)
                        }
                    }

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function compareDomain(a, b) {
        var nameA = a.domainName.toUpperCase(); // ignore upper and lowercase
        var nameB = b.domainName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) { return -1; }
        if (nameA > nameB) { return 1; }
        return 0;
    }

    function getHtmlData() {
        axios(CONFIG.MAIN_URL + `/loginPageContent/all`, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("++++++++++++++++All loginPage Returns+++++++++++++");
                console.log(response.data);
                var allData = response.data;
                allData = allData.sort(compareDomain)
                setAllLoginPages(allData);

                setLogInPageDesignId('');
                setLogInPageVersionNo('');
                setLogInPageDesign('');
                setPreview()
                setBackgroundColor('#ffffff');
                setPressClearColor(true);
                setImgLocation('');

                setNeedToDisplayInLoginPage('');
                showBoxFun('block');
                setTypeOfDisplay('');
                setWhereToDisplay('');
                setAlignContent('');
                setBackgroundColorSub('#ffffff');
                setButtonBackground('');
                setFontColor('#ffffff');
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleColor = (event) => {
        setBackgroundColor(event.target.value);
        setPressClearColor(false);
    };


    function saveFunction() {
        saveDialogHandleClickClose();
        if (selectedFile === null) {
            var obj = {}
            if (logInPageDesignId === '') {
                obj["versionNumber"] = "1";
            } else {
                obj["versionNumber"] = selectedVersionNo;
                obj["id"] = logInPageDesignId;
                allLoginPages.map((data) => {
                    if (data.id === logInPageDesignId) {
                        if (data.baseVersion != undefined && data.baseVersion != null) {
                            obj["baseVersion"] = data.baseVersion;
                        }
                        if (data.baseVersionId != undefined && data.baseVersionId != null) {
                            obj["baseVersionId"] = data.baseVersionId;
                        }
                    }
                })


            }
            obj["domainName"] = selectedDomainName;
            obj["assignedCentreId"] = centreId;
            obj["assignedCentreName"] = centreName;

            if (arrCentreNames.length > 0){
                var tArr = [];
                arrCentreNames.forEach((data) => {
                    let tSelectedName  = data;
                    allCentres.forEach((item) => {
                        if (item.centreName === tSelectedName) {
                            var ttObj = {};
                            ttObj["centreId"] = item.centreId
                            ttObj["centreName"] = item.centreName
                            tArr.push(ttObj);
                        }
                    })
                })
                if (tArr.length > 0){
                    obj["domainAllocatedCentres"] = tArr;
                }


            }


            obj["pageContent"] = editorRef.current.getContent();
            obj["backgroundUrl"] = imgLocation;
            if (pressClearColor) {
                obj["backgroundColor"] = '';
            } else {
                obj["backgroundColor"] = backgroundColor;
            }
            var obj1 = {}
            obj1["needToDisplayInLoginPage"] = needToDisplayInLoginPage;
            obj1["typeOfDisplay"] = typeOfDisplay;
            obj1["whereToDisplay"] = whereToDisplay;
            obj1["alignContent"] = alignContent;
            obj1["backgroundColor"] = backgroundColorSub;
            obj1["buttonBackground"] = buttonBackground;
            obj1["fontColor"] = fontColor;

            obj["subPageFeatures"] = obj1;
            var sendJson = JSON.stringify(obj);
            console.log(sendJson)

            var sendURL = '/loginPageContent/save';
            if (logInPageDesignId != '') {
                sendURL = '/loginPageContent/update';
            }
            //alert(sendURL)
            axios(CONFIG.MAIN_URL + sendURL, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: sendJson
            })
                .then(res => {


                    if (logInPageDesignId === '') {

                        var rtnData = res.data;
                        var tVersionNumber = rtnData.versionNumber;
                        var tid = rtnData.id;
                        var obj = {}
                        obj["versionNumber"] = "current";
                        obj["baseVersion"] = tVersionNumber;
                        obj["baseVersionId"] = tid;
                        obj["domainName"] = selectedDomainName;


                        if (arrCentreNames.length > 0){
                            var tArr = [];
                            arrCentreNames.forEach((data) => {
                                let tSelectedName  = data;
                                allCentres.forEach((item) => {
                                    if (item.centreName === tSelectedName) {
                                        var ttObj = {};
                                        ttObj["centreId"] = item.centreId
                                        ttObj["centreName"] = item.centreName
                                        tArr.push(ttObj);
                                    }
                                })
                            })
                            if (tArr.length > 0){
                                obj["domainAllocatedCentres"] = tArr;
                            }
            
            
                        }

                        obj["assignedCentreId"] = centreId;
                        obj["assignedCentreName"] = centreName;
                        obj["pageContent"] = editorRef.current.getContent();
                        obj["backgroundUrl"] = imgLocation;
                        if (pressClearColor) {
                            obj["backgroundColor"] = '';
                        } else {
                            obj["backgroundColor"] = backgroundColor;
                        }
                        var obj1 = {}
                        obj1["needToDisplayInLoginPage"] = needToDisplayInLoginPage;
                        obj1["typeOfDisplay"] = typeOfDisplay;
                        obj1["whereToDisplay"] = whereToDisplay;
                        obj1["alignContent"] = alignContent;
                        obj1["backgroundColor"] = backgroundColorSub;
                        obj1["buttonBackground"] = buttonBackground;
                        obj1["fontColor"] = fontColor;

                        obj["subPageFeatures"] = obj1;
                        var sendJson = JSON.stringify(obj);
                        console.log(sendJson)
                        var sendURL = '/loginPageContent/save';
                        axios(CONFIG.MAIN_URL + sendURL, {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            data: sendJson
                        })
                            .then(res => {
                                getHtmlData();
                                alert(" Saved Successfully")
                                clearFuctionDomains();
                            })
                            .catch(function (error) {
                                alert('Error Updating');
                                console.log(error);
                            });

                    } else {
                        getHtmlData();
                        alert("Updated Successfully")
                        clearFuctionDomains();
                    }


                })
                .catch(function (error) {
                    alert('Error Updating');
                    console.log(error);
                });
        } else {
            let formdata = new FormData();
            formdata.append('file', selectedFile);
            formdata.append('centreId', 'superadmin');
            axios(CONFIG.MAIN_URL + '/image/upload', {
                method: 'post',
                data: formdata,
            })
                .then(function (response) {
                    console.log(response.data.location);
                    if (response.data.location === null || response.data.location === "") {
                        alert(response.data.message + " and please use a different image name");
                        return;
                    } else {
                        setImgLocation(response.data.location);
                        setSelectedFile(null);
                        var obj = {}
                        if (logInPageDesignId === '') {
                            obj["versionNumber"] = "1";
                        } else {
                            obj["versionNumber"] = selectedVersionNo;
                            obj["id"] = logInPageDesignId
                            allLoginPages.map((data) => {
                                if (data.id === logInPageDesignId) {
                                    if (data.baseVersion != undefined && data.baseVersion != null) {
                                        obj["baseVersion"] = data.baseVersion;
                                    }
                                    if (data.baseVersionId != undefined && data.baseVersionId != null) {
                                        obj["baseVersionId"] = data.baseVersionId;
                                    }
                                }
                            })
                        }
                        obj["domainName"] = selectedDomainName;
                        obj["assignedCentreId"] = centreId;
                        obj["assignedCentreName"] = centreName;

                        if (arrCentreNames.length > 0){
                            var tArr = [];
                            arrCentreNames.forEach((data) => {
                                let tSelectedName  = data;
                                allCentres.forEach((item) => {
                                    if (item.centreName === tSelectedName) {
                                        var ttObj = {};
                                        ttObj["centreId"] = item.centreId
                                        ttObj["centreName"] = item.centreName
                                        tArr.push(ttObj);
                                    }
                                })
                            })
                            if (tArr.length > 0){
                                obj["domainAllocatedCentres"] = tArr;
                            }
            
            
                        }

                        obj["pageContent"] = editorRef.current.getContent();
                        obj["backgroundUrl"] = response.data.location;
                        if (pressClearColor) {
                            obj["backgroundColor"] = '';
                        } else {
                            obj["backgroundColor"] = backgroundColor;
                        }
                        var obj1 = {}
                        obj1["needToDisplayInLoginPage"] = needToDisplayInLoginPage;
                        obj1["typeOfDisplay"] = typeOfDisplay;
                        obj1["whereToDisplay"] = whereToDisplay;
                        obj1["alignContent"] = alignContent;
                        obj1["backgroundColor"] = backgroundColorSub;
                        obj1["buttonBackground"] = buttonBackground;
                        obj1["fontColor"] = fontColor;


                        obj["subPageFeatures"] = obj1;
                        var sendJson = JSON.stringify(obj);
                        console.log(sendJson)
                        var sendURL = '/loginPageContent/save';
                        if (logInPageDesignId != '') {
                            sendURL = '/loginPageContent/update';
                        }
                        axios(CONFIG.MAIN_URL + sendURL, {

                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            data: sendJson
                        })
                            .then(res => {

                                if (logInPageDesignId === '') {
                                    var rtnData = res.data;
                                    var tVersionNumber = rtnData.versionNumber;
                                    var tid = rtnData.id;
                                    var obj = {}
                                    obj["versionNumber"] = "current";
                                    obj["baseVersion"] = tVersionNumber;
                                    obj["baseVersionId"] = tid;
                                    obj["domainName"] = selectedDomainName;
                                    obj["assignedCentreId"] = centreId;
                                    obj["assignedCentreName"] = centreName;


                                    if (arrCentreNames.length > 0){
                                        var tArr = [];
                                        arrCentreNames.forEach((data) => {
                                            let tSelectedName  = data;
                                            allCentres.forEach((item) => {
                                                if (item.centreName === tSelectedName) {
                                                    var ttObj = {};
                                                    ttObj["centreId"] = item.centreId
                                                    ttObj["centreName"] = item.centreName
                                                    tArr.push(ttObj);
                                                }
                                            })
                                        })
                                        if (tArr.length > 0){
                                            obj["domainAllocatedCentres"] = tArr;
                                        }
                        
                        
                                    }


                                    obj["pageContent"] = editorRef.current.getContent();
                                    obj["backgroundUrl"] = imgLocation;
                                    if (pressClearColor) {
                                        obj["backgroundColor"] = '';
                                    } else {
                                        obj["backgroundColor"] = backgroundColor;
                                    }
                                    var obj1 = {}
                                    obj1["needToDisplayInLoginPage"] = needToDisplayInLoginPage;
                                    obj1["typeOfDisplay"] = typeOfDisplay;
                                    obj1["whereToDisplay"] = whereToDisplay;
                                    obj1["alignContent"] = alignContent;
                                    obj1["backgroundColor"] = backgroundColorSub;
                                    obj1["buttonBackground"] = buttonBackground;
                                    obj1["fontColor"] = fontColor;

                                    obj["subPageFeatures"] = obj1;
                                    var sendJson = JSON.stringify(obj);
                                    console.log(sendJson)
                                    var sendURL = '/loginPageContent/save';
                                    axios(CONFIG.MAIN_URL + sendURL, {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        data: sendJson
                                    })
                                        .then(res => {
                                            getHtmlData();
                                            alert(" Saved Successfully")
                                            clearFuctionDomains();
                                        })
                                        .catch(function (error) {
                                            alert('Error Updating');
                                            console.log(error);
                                        });

                                } else {
                                    getHtmlData()
                                    alert("Updated Successfully")
                                    clearFuctionDomains();
                                }

                            })
                            .catch(function (error) {
                                alert('Error Updating');
                                console.log(error);
                            });
                    }
                })
                .catch(function (error) {
                    alert("Image Uploading Error. Image size is too big. Please reduce the image size before uploding the background image.");
                    console.log(error);
                })

        }

    }
    /** Save As a current Version */
    function saveFunctionCurrentVersion() {
        var docId = ""
        allLoginPages.map((data) => {
            if (data.domainName === selectedDomainName && data.versionNumber === 'current') {
                docId = data.id;
            }
        })
        saveDialogHandleClickClose();

        if (selectedFile === null) {
            let text = "The 'current' version will Update from the selected version " + selectedVersionNo;
            text += "\n\nDo you want to create a new version from the current version and create a new 'current' version?."
            text += "\n\n'OK' to confirm.";
            text += "\n\nOr 'Cancel' to create a new 'current' version without creating a new version in existing 'current' version.";
            if (window.confirm(text) == true) {
                var obj = {}
                allLoginPages.map((data) => {
                    if (data.domainName === selectedDomainName && data.versionNumber === 'current') {
                        obj = data;
                        obj["versionNumber"] = 'new';
                    }
                })
                var sendJson = JSON.stringify(obj);
                console.log(sendJson)
                var sendURL = '/loginPageContent/save';
                if (logInPageDesignId != '') {
                    sendURL = '/loginPageContent/update';
                }
                axios(CONFIG.MAIN_URL + sendURL, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: sendJson
                })
                    .then(res => {

                        var obj = {}
                        obj["id"] = docId;
                        obj["versionNumber"] = 'current';
                        obj["baseVersion"] = selectedVersionNo;
                        obj["baseVersionId"] = logInPageDesignId;
                        obj["domainName"] = selectedDomainName;
                        obj["assignedCentreId"] = centreId;
                        obj["assignedCentreName"] = centreName;
                        obj["pageContent"] = editorRef.current.getContent();
                        obj["backgroundUrl"] = imgLocation;
                        if (pressClearColor) {
                            obj["backgroundColor"] = '';
                        } else {
                            obj["backgroundColor"] = backgroundColor;
                        }
                        var obj1 = {}
                        obj1["needToDisplayInLoginPage"] = needToDisplayInLoginPage;
                        obj1["typeOfDisplay"] = typeOfDisplay;
                        obj1["whereToDisplay"] = whereToDisplay;
                        obj1["alignContent"] = alignContent;
                        obj1["backgroundColor"] = backgroundColorSub;
                        obj1["buttonBackground"] = buttonBackground;
                        obj1["fontColor"] = fontColor;

                        obj["subPageFeatures"] = obj1;
                        var sendJson = JSON.stringify(obj);
                        console.log(sendJson)
                        var sendURL = '/loginPageContent/save';
                        axios(CONFIG.MAIN_URL + sendURL, {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            data: sendJson
                        })
                            .then(res => {
                                getHtmlData();
                                alert("Created a new version as well as the 'current' version Successfully")
                                clearFuctionDomains();
                            })
                            .catch(function (error) {
                                alert('Error Updating');
                                console.log(error);
                            });

                    })
                    .catch(function (error) {
                        alert('Error Updating');
                        console.log(error);
                    });

            } else {
                var obj = {}
                obj["id"] = docId;
                obj["versionNumber"] = 'current';
                obj["baseVersion"] = selectedVersionNo;
                obj["baseVersionId"] = logInPageDesignId;
                obj["domainName"] = selectedDomainName;
                obj["assignedCentreId"] = centreId;
                obj["assignedCentreName"] = centreName;
                obj["pageContent"] = editorRef.current.getContent();
                obj["backgroundUrl"] = imgLocation;
                if (pressClearColor) {
                    obj["backgroundColor"] = '';
                } else {
                    obj["backgroundColor"] = backgroundColor;
                }
                var obj1 = {}
                obj1["needToDisplayInLoginPage"] = needToDisplayInLoginPage;
                obj1["typeOfDisplay"] = typeOfDisplay;
                obj1["whereToDisplay"] = whereToDisplay;
                obj1["alignContent"] = alignContent;
                obj1["backgroundColor"] = backgroundColorSub;
                obj1["buttonBackground"] = buttonBackground;
                obj1["fontColor"] = fontColor;

                obj["subPageFeatures"] = obj1;
                var sendJson = JSON.stringify(obj);
                console.log(sendJson)
                var sendURL = '/loginPageContent/save';
                axios(CONFIG.MAIN_URL + sendURL, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: sendJson
                })
                    .then(res => {
                        getHtmlData();
                        alert("Saved Successfully as the current version ")
                        clearFuctionDomains();
                    })
                    .catch(function (error) {
                        alert('Error Updating');
                        console.log(error);
                    });

            }


            /*
            
            var obj = {}
            obj["id"] = docId;
            obj["versionNumber"] = 'current';
            obj["baseVersion"] = selectedVersionNo;
            obj["baseVersionId"] = logInPageDesignId;
            obj["domainName"] = selectedDomainName;
            obj["assignedCentreId"] = centreId;
            obj["assignedCentreName"] = centreName;
            obj["pageContent"] = editorRef.current.getContent();
            obj["backgroundUrl"] = imgLocation;
            if (pressClearColor) {
                obj["backgroundColor"] = '';
            } else {
                obj["backgroundColor"] = backgroundColor;
            }
            var obj1 = {}
            obj1["needToDisplayInLoginPage"] = needToDisplayInLoginPage;
            obj1["typeOfDisplay"] = typeOfDisplay;
            obj1["whereToDisplay"] = whereToDisplay;
            obj1["alignContent"] = alignContent;
            obj1["backgroundColor"] = backgroundColorSub;
            obj1["buttonBackground"] = buttonBackground;
            obj1["fontColor"] = fontColor;

            obj["subPageFeatures"] = obj1;
            var sendJson = JSON.stringify(obj);
            console.log(sendJson)
            var sendURL = '/loginPageContent/save';
            axios(CONFIG.MAIN_URL + sendURL, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: sendJson
            })
                .then(res => {
                        getHtmlData();
                        alert("Saved Successfully as the current version ")
                        clearFuctionDomains();
                })
                .catch(function (error) {
                    alert('Error Updating');
                    console.log(error);
                });
                */

        } else {
            let formdata = new FormData();
            formdata.append('file', selectedFile);
            formdata.append('centreId', 'superadmin');
            axios(CONFIG.MAIN_URL + '/image/upload', {
                method: 'post',
                data: formdata,
            })
                .then(function (response) {
                    console.log(response.data.location);
                    if (response.data.location === null || response.data.location === "") {
                        alert(response.data.message + " and please use a different image name");
                        return;
                    } else {
                        var imgLocation = response.data.location;
                        setImgLocation(imgLocation);
                        setSelectedFile(null);
                        let text = "The 'current' version will Update from the selected version " + selectedVersionNo;
                        text += "\n\nDo you want to create a new version from the current version and create a new 'current' version?."
                        text += "\n\n'OK' to confirm.";
                        text += "\n\nOr 'Cancel' to create a new 'current' version without creating a new version in existing 'current' version.";
                        if (window.confirm(text) == true) {
                            var obj = {}
                            allLoginPages.map((data) => {
                                if (data.domainName === selectedDomainName && data.versionNumber === 'current') {
                                    obj = data;
                                    obj["versionNumber"] = 'new';
                                }
                            })

                            var sendJson = JSON.stringify(obj);
                            console.log(sendJson)
                            var sendURL = '/loginPageContent/save';
                            if (logInPageDesignId != '') {
                                sendURL = '/loginPageContent/update';
                            }
                            axios(CONFIG.MAIN_URL + sendURL, {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                data: sendJson
                            })
                                .then(res => {
                                    var obj = {}
                                    obj["id"] = docId;
                                    obj["versionNumber"] = 'current';
                                    obj["baseVersion"] = selectedVersionNo;
                                    obj["baseVersionId"] = logInPageDesignId;
                                    obj["domainName"] = selectedDomainName;
                                    obj["assignedCentreId"] = centreId;
                                    obj["assignedCentreName"] = centreName;

                                    obj["pageContent"] = editorRef.current.getContent();
                                    obj["backgroundUrl"] = imgLocation;
                                    if (pressClearColor) {
                                        obj["backgroundColor"] = '';
                                    } else {
                                        obj["backgroundColor"] = backgroundColor;
                                    }
                                    var obj1 = {}
                                    obj1["needToDisplayInLoginPage"] = needToDisplayInLoginPage;
                                    obj1["typeOfDisplay"] = typeOfDisplay;
                                    obj1["whereToDisplay"] = whereToDisplay;
                                    obj1["alignContent"] = alignContent;
                                    obj1["backgroundColor"] = backgroundColorSub;
                                    obj1["buttonBackground"] = buttonBackground;
                                    obj1["fontColor"] = fontColor;


                                    obj["subPageFeatures"] = obj1;
                                    var sendJson = JSON.stringify(obj);
                                    console.log(sendJson)
                                    var sendURL = '/loginPageContent/save';
                                    axios(CONFIG.MAIN_URL + sendURL, {

                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        data: sendJson
                                    })
                                        .then(res => {

                                            getHtmlData()
                                            alert("Created a new version as well as the 'current' version Successfully");
                                            clearFuctionDomains();

                                        })
                                        .catch(function (error) {
                                            alert('Error Updating');
                                            console.log(error);
                                        });
                                })
                                .catch(function (error) {
                                    alert('Error Updating');
                                    console.log(error);
                                });

                        } else {

                            var obj = {}
                            obj["id"] = docId;
                            obj["versionNumber"] = 'current';
                            obj["baseVersion"] = selectedVersionNo;
                            obj["baseVersionId"] = logInPageDesignId;
                            obj["domainName"] = selectedDomainName;
                            obj["assignedCentreId"] = centreId;
                            obj["assignedCentreName"] = centreName;

                            obj["pageContent"] = editorRef.current.getContent();
                            obj["backgroundUrl"] = imgLocation;
                            if (pressClearColor) {
                                obj["backgroundColor"] = '';
                            } else {
                                obj["backgroundColor"] = backgroundColor;
                            }
                            var obj1 = {}
                            obj1["needToDisplayInLoginPage"] = needToDisplayInLoginPage;
                            obj1["typeOfDisplay"] = typeOfDisplay;
                            obj1["whereToDisplay"] = whereToDisplay;
                            obj1["alignContent"] = alignContent;
                            obj1["backgroundColor"] = backgroundColorSub;
                            obj1["buttonBackground"] = buttonBackground;
                            obj1["fontColor"] = fontColor;


                            obj["subPageFeatures"] = obj1;
                            var sendJson = JSON.stringify(obj);
                            console.log(sendJson)
                            var sendURL = '/loginPageContent/save';
                            axios(CONFIG.MAIN_URL + sendURL, {

                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                data: sendJson
                            })
                                .then(res => {

                                    getHtmlData()
                                    alert("Saved Successfully as the Current version ");
                                    clearFuctionDomains();

                                })
                                .catch(function (error) {
                                    alert('Error Updating');
                                    console.log(error);
                                });


                        }


                        /*
                        var obj = {}
                        obj["id"] = docId;
                        obj["versionNumber"] = 'current';
                        obj["baseVersion"] = selectedVersionNo;
                        obj["baseVersionId"] = logInPageDesignId;
                        obj["domainName"] = selectedDomainName;
                        obj["assignedCentreId"] = centreId;
                        obj["assignedCentreName"] = centreName;

                        obj["pageContent"] = editorRef.current.getContent();
                        obj["backgroundUrl"] = imgLocation;
                        if (pressClearColor) {
                            obj["backgroundColor"] = '';
                        } else {
                            obj["backgroundColor"] = backgroundColor;
                        }
                        var obj1 = {}
                        obj1["needToDisplayInLoginPage"] = needToDisplayInLoginPage;
                        obj1["typeOfDisplay"] = typeOfDisplay;
                        obj1["whereToDisplay"] = whereToDisplay;
                        obj1["alignContent"] = alignContent;
                        obj1["backgroundColor"] = backgroundColorSub;
                        obj1["buttonBackground"] = buttonBackground;
                        obj1["fontColor"] = fontColor;


                        obj["subPageFeatures"] = obj1;
                        var sendJson = JSON.stringify(obj);
                        console.log(sendJson)
                        var sendURL = '/loginPageContent/save';
                        axios(CONFIG.MAIN_URL + sendURL, {

                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            data: sendJson
                        })
                            .then(res => {

                                getHtmlData()
                                alert("Saved Successfully as the Current version ");
                                clearFuctionDomains();

                            })
                            .catch(function (error) {
                                alert('Error Updating');
                                console.log(error);
                            });
                            */
                    }
                })
                .catch(function (error) {
                    alert("Image Uploading Error. Image size is too big. Please reduce the image size before uploding the background image.");
                    console.log(error);
                })

        }





    }

    function saveFunctionNewVersion() {
        saveDialogHandleClickClose();
        if (selectedFile === null) {
            var obj = {}
            obj["id"] = logInPageDesignId
            obj["versionNumber"] = 'new';
            obj["baseVersion"] = selectedBaseVersion;
            obj["baseVersionId"] = selectedBaseVersionId;
            obj["domainName"] = selectedDomainName;
            obj["assignedCentreId"] = centreId;
            obj["assignedCentreName"] = centreName;
            obj["pageContent"] = editorRef.current.getContent();
            obj["backgroundUrl"] = imgLocation;
            if (pressClearColor) {
                obj["backgroundColor"] = '';
            } else {
                obj["backgroundColor"] = backgroundColor;
            }
            var obj1 = {}
            obj1["needToDisplayInLoginPage"] = needToDisplayInLoginPage;
            obj1["typeOfDisplay"] = typeOfDisplay;
            obj1["whereToDisplay"] = whereToDisplay;
            obj1["alignContent"] = alignContent;
            obj1["backgroundColor"] = backgroundColorSub;
            obj1["buttonBackground"] = buttonBackground;
            obj1["fontColor"] = fontColor;

            obj["subPageFeatures"] = obj1;
            var sendJson = JSON.stringify(obj);
            console.log(sendJson)
            var sendURL = '/loginPageContent/save';
            if (logInPageDesignId != '') {
                sendURL = '/loginPageContent/update';
            }
            axios(CONFIG.MAIN_URL + sendURL, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: sendJson
            })
                .then(res => {
                    getHtmlData();
                    alert("Saved Successfully in a new version ")
                    clearFuctionDomains();
                })
                .catch(function (error) {
                    alert('Error Updating');
                    console.log(error);
                });

        } else {
            let formdata = new FormData();
            formdata.append('file', selectedFile);
            formdata.append('centreId', 'superadmin');
            axios(CONFIG.MAIN_URL + '/image/upload', {
                method: 'post',
                data: formdata,
            })
                .then(function (response) {
                    console.log(response.data.location);
                    if (response.data.location === null || response.data.location === "") {
                        alert(response.data.message + " and please use a different image name");
                        return;
                    } else {
                        setImgLocation(response.data.location);
                        setSelectedFile(null);
                        var obj = {}
                        obj["id"] = logInPageDesignId
                        obj["versionNumber"] = 'new';
                        obj["baseVersion"] = selectedBaseVersion;
                        obj["baseVersionId"] = selectedBaseVersionId;
                        obj["domainName"] = selectedDomainName;
                        obj["assignedCentreId"] = centreId;
                        obj["assignedCentreName"] = centreName;

                        obj["pageContent"] = editorRef.current.getContent();
                        obj["backgroundUrl"] = response.data.location;
                        if (pressClearColor) {
                            obj["backgroundColor"] = '';
                        } else {
                            obj["backgroundColor"] = backgroundColor;
                        }
                        var obj1 = {}
                        obj1["needToDisplayInLoginPage"] = needToDisplayInLoginPage;
                        obj1["typeOfDisplay"] = typeOfDisplay;
                        obj1["whereToDisplay"] = whereToDisplay;
                        obj1["alignContent"] = alignContent;
                        obj1["backgroundColor"] = backgroundColorSub;
                        obj1["buttonBackground"] = buttonBackground;
                        obj1["fontColor"] = fontColor;


                        obj["subPageFeatures"] = obj1;
                        var sendJson = JSON.stringify(obj);
                        console.log(sendJson)
                        var sendURL = '/loginPageContent/save';
                        if (logInPageDesignId != '') {
                            sendURL = '/loginPageContent/update';
                        }
                        axios(CONFIG.MAIN_URL + sendURL, {

                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            data: sendJson
                        })
                            .then(res => {

                                getHtmlData()
                                alert("Saved Successfully in a new version ");
                                clearFuctionDomains();

                            })
                            .catch(function (error) {
                                alert('Error Updating');
                                console.log(error);
                            });
                    }
                })
                .catch(function (error) {
                    alert("Image Uploading Error. Image size is too big. Please reduce the image size before uploding the background image.");
                    console.log(error);
                })

        }

    }
















    function deleteFunction() {
        var deleteErrorMsg = "";
        var tSelectedVersionNo = "";
        allLoginPages.map((data) => {
            if (data.id === selectedDomainId) {
                tSelectedVersionNo = data.versionNumber;
                if (data.versionNumber === 'current') {
                    deleteErrorMsg = "Sorry!. You cannot delete this version as this is the \"CURRENT\" version"
                }
            }
        })
        if (deleteErrorMsg != '') {
            alert(deleteErrorMsg);
            return;
        }
        allLoginPages.map((data) => {
            if (data.versionNumber === 'current') {
                var currentVersionBasedVersion = data.baseVersion;
                if (currentVersionBasedVersion === tSelectedVersionNo) {
                    deleteErrorMsg = "Sorry!. You cannot delete this version as the \"CURRENT\" version is based on this version(" + tSelectedVersionNo + ")";
                }
            }
        })
        if (deleteErrorMsg != '') {
            alert(deleteErrorMsg);
            return;
        }

        var fondPre = false;
        allLoginPages.map((data) => {
            if (data.id === selectedDomainId) {
                fondPre = true; saveDialogHandleClickClose()
                var obj = {}
                obj["id"] = data.id
                obj["pageContent"] = data.pageContent;
                var sendJson = JSON.stringify(obj);
                console.log(sendJson)

                axios(CONFIG.MAIN_URL + '/loginPageContent/delete', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: sendJson
                })
                    .then(res => {
                        getHtmlData()
                        alert("Deleted Successfully")
                        console.log(res.data);
                        clearFuctionDomains();
                        return;

                    })
                    .catch(function (error) {
                        alert('Error Deleting page');
                        console.log(error);
                    });

            }
        })
        if (!fondPre) {
            alert("No previous domain name selected to delete")
        }

    }

    function clearImageFuction() {
        setPreview();
        setSelectedFile(null);
        setImgLocation('');
    }

    const [pressClearColor, setPressClearColor] = useState(false);
    function clearColorFuction() {
        setBackgroundColor('#ffffff');
        setPressClearColor(true);
    }

    const [showLoginConent, setShowLoginConent] = useState('none');
    const [showEditBtn, setShowEditBtn] = useState('none');
    const [editBtnName, setEditBtnName] = useState('Edit');
    const [showDeleteBtn, setShowDeleteBtn] = useState('none');
    const [showAddNewBtn, setShowAddNewBtn] = useState('none');
    const [showSaveUpdateBtn, setShowSaveUpdateBtn] = useState('none');



    const [showEditButton, setShowEditButton] = useState(true);
    const [selectedDomainName, setSelectedDomainName] = useState('');

    const [selectedDomainHeaderName, setSelectedDomainHeaderName] = useState('Log In Page Design');
    const [selectedDomainHeaderNameUpdate, setSelectedDomainHeaderNameUpdate] = useState('Update / Create a new Version / Convert to Current Version');



    const [selectedDomainId, setSelectedDomainId] = useState('');
    const [selectedDomainVersion, setSelectedDomainVersion] = useState('');

    const [selectedBaseVersion, setSelectedBaseVersion] = useState('');
    const [selectedBaseVersionId, setSelectedBaseVersionId] = useState('');


    const domainSelecter = (event, newValue) => {
        clearFuctionDomains();
        if (newValue != null) {
            setSelectedDomainName(newValue.label);
            setSelectedDomainId(newValue.id)
            setSelectedDomainVersion(newValue.versionNumber)
            setEditBtnName('Edit ' + newValue.versionNumber + ' version')
        }
        setShowEditBtn('block');
        setShowDeleteBtn('block');
        setShowAddNewBtn('none');

    }
    const domainSelecter1 = (event) => {
        clearFuctionDomains();
        setSelectedDomainName(event.target.value);

        var found = false;
        allLoginPages.map((data) => {
            if (data.domainName === event.target.value) {
                found = true;
                setEditBtnName('Edit ' + data.versionNumber + ' version')
                setShowEditBtn('block');
                setShowDeleteBtn('block');
                setShowAddNewBtn('none');
            }
        })
        if (!found) {
            setSelectedDomainId('')
            setEditBtnName('')
            setShowEditBtn('none');
            setShowDeleteBtn('none');
            if (event.target.value != '') {
                setShowAddNewBtn('block');
            } else {
                setShowAddNewBtn('none');
            }
        }

    }

    function clearFuctionDomains() {
        setShowOtherContentBtn(false)
        clearImageFuction();
        setLogInPageDesignId('');
        setLogInPageVersionNo('');
        setLogInPageDesign('');
        setPreview();
        setBackgroundColor('#ffffff');
        setPressClearColor(true)
        setImgLocation('');
        setSelectedDomainName('')
        setSelectedDomainId('')
        setSelectedDomainVersion('')
        setSelectedBaseVersion('');
        setSelectedBaseVersionId('');
        setAddUpdateButton('SAVE');
        setCentreId('');
        setCentreName('');
        setNeedToDisplayInLoginPage('')
        showBoxFun('block');
        setTypeOfDisplay('')
        setWhereToDisplay('')
        setAlignContent('');
        setBackgroundColorSub('#ffffff');
        setButtonBackground('');
        setFontColor('#ffffff');
        setShowLoginConent('none')
        setShowEditBtn('none');
        setShowDeleteBtn('none');
        setShowAddNewBtn('none');
        setShowSaveUpdateBtn('none');
        setSelectedDomainHeaderName('Log In Page Design')
        setSelectedDomainHeaderNameUpdate('Update / Create a new Version / Convert to Current Version');
    }

    const [saveDialogOpen, setSaveDialogOpen] = React.useState(false);
    const [saveDialogScroll, setSaveDialogScroll] = React.useState('paper');

    const saveDialogHandleClickClose = () => {
        setSaveDialogOpen(false);
    };



    const [addUpdateButton, setAddUpdateButton] = useState('SAVE')





    function addDomains() {
        var prev = true;
        const packageNameToSave = selectedDomainName;
        allLoginPages.map((data) => {
            if (data.domainName === packageNameToSave) {
                prev = false
                return;
            }
        })
        if (packageNameToSave === '') {
            alert("Please select OR type a new domain name")

        } else {
            if (centreId === "") {
                alert("Please select a trialing centre")
            } else {
                if (editorRef.current.getContent() === "") {
                    alert("Please type the page content")
                } else {
                    if (logInPageDesignId === '') {
                        saveFunction();
                    } else {
                        setSaveDialogOpen(true);
                        //saveFunction();
                    }
                }
            }
        }
    }

    const [showOtherContentBtn, setShowOtherContentBtn] = useState(false)


    function addNewDomains() {
        setShowLoginConent('block');
        setShowSaveUpdateBtn('block');
        setShowAddNewBtn('none');
    }

    const [versionArray, setVersionArray] = useState([])
    const [selectedVersionNo, setSelectedVersionNo] = useState('')

    const versionSelecter = (event) => {
        setSelectedVersionNo(event.target.value);
    };




    function domainEdit() {
        allLoginPages.map((data) => {
            if (data.id === selectedDomainId) {
                var versionArray = [];
                var ttObj = {};
                ttObj["name"] = data.versionNumber + " Version ";
                ttObj["value"] = data.versionNumber;
                setSelectedVersionNo(data.versionNumber + "")

                versionArray.push(ttObj);
                setVersionArray(versionArray);
                if (data.versionNumber === 'current') {
                    setSelectedDomainHeaderName(<><span>Log In Page Design - </span><span style={{ fontWeight: 550 }}>Domain Name : </span><span>{data.domainName}</span><span style={{ fontWeight: 550 }}> - Version Number  : </span><span>{data.versionNumber}</span><span style={{ fontWeight: 550 }}> - Based on version  : </span><span>{data.baseVersion}</span></>)
                    setSelectedDomainHeaderNameUpdate(<><span>Update / Create a new Version / Convert to Current Version - </span><span style={{ fontWeight: 550 }}>Domain Name : </span><span style={{ fontWeight: 200 }}>{data.domainName}</span><span style={{ fontWeight: 550 }}> - Version Number  : </span><span style={{ fontWeight: 200 }}>{data.versionNumber}</span><span style={{ fontWeight: 550 }}> - Based on version  : </span><span>{data.baseVersion}</span></>)
                } else {
                    setSelectedDomainHeaderName(<><span>Log In Page Design - </span><span style={{ fontWeight: 550 }}>Domain Name : </span><span>{data.domainName}</span><span style={{ fontWeight: 550 }}> - Version Number  : </span><span>{data.versionNumber}</span></>)
                    setSelectedDomainHeaderNameUpdate(<><span>Update / Create a new Version / Convert to Current Version - </span><span style={{ fontWeight: 550 }}>Domain Name : </span><span style={{ fontWeight: 200 }}>{data.domainName}</span><span style={{ fontWeight: 550 }}> - Version Number  : </span><span style={{ fontWeight: 200 }}>{data.versionNumber}</span></>)
                }


                setShowLoginConent('block');

                setShowSaveUpdateBtn('block');
                setShowEditBtn('none');
                setShowDeleteBtn('none');

                setShowOtherContentBtn(true)
                console.log(data)
                setAddUpdateButton('Update')
                setLogInPageDesignId(data.id)
                setLogInPageVersionNo(data.versionNumber);


                setSelectedDomainVersion(data.versionNumber);
                if (data.baseVersion != undefined && data.baseVersion != null) {
                    setSelectedBaseVersion(data.baseVersion);
                }
                if (data.baseVersionId != undefined && data.baseVersionId != null) {
                    setSelectedBaseVersionId(data.baseVersionId);
                }

                if (data.subPageFeatures != null) {
                    setNeedToDisplayInLoginPage(data.subPageFeatures.needToDisplayInLoginPage)
                    if (data.subPageFeatures.needToDisplayInLoginPage === 'yes') {
                        showBoxFun('block');
                    } else {
                        showBoxFun('none');
                    }
                    setTypeOfDisplay(data.subPageFeatures.typeOfDisplay);
                    setWhereToDisplay(data.subPageFeatures.whereToDisplay);
                    if (data.subPageFeatures.alignContent != null) {
                        setAlignContent(data.subPageFeatures.alignContent);
                    }
                    if (data.subPageFeatures.backgroundColor != null) {
                        setBackgroundColorSub(data.subPageFeatures.backgroundColor);
                    }
                    if (data.subPageFeatures.buttonBackground != null) {
                        setButtonBackground(data.subPageFeatures.buttonBackground);
                    }
                    if (data.subPageFeatures.fontColor != null) {
                        setFontColor(data.subPageFeatures.fontColor);
                    }
                }
                if (data.assignedCentreId != null) {
                    setCentreId(data.assignedCentreId);
                    allCentres.map((item) => {
                        if (item.centreId === data.assignedCentreId) {
                            setCentreName(data.assignedCentreName);
                        }
                    })
                }
                if (data.domainAllocatedCentres != undefined && data.domainAllocatedCentres != null) {
                    var ttArr = [];
                    data.domainAllocatedCentres.forEach((tItems) => {
                        ttArr.push(tItems.centreName)
                    })
                    setArrCentreNames(ttArr);
                }else{
                    setArrCentreNames([]);
                }





                setLogInPageDesign(data.pageContent);
                if (data.backgroundUrl != undefined && data.backgroundUrl != null) {
                    setPreview(data.backgroundUrl)
                    setImgLocation(data.backgroundUrl);
                }
                if (data.backgroundColor != undefined && data.backgroundColor != null && data.backgroundColor != '') {
                    setBackgroundColor(data.backgroundColor);
                    setPressClearColor(false);
                } else {
                    setBackgroundColor('#ffffff');
                    setPressClearColor(true);
                }
                editorRef.current.setContent(data.pageContent);

                /*
                
                                    })
                
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                
                
                */











            }
        })
    }



    const [maxWidth, setMaxWidth] = React.useState('xl');
    const [newDialogOpen, setNewDialogOpen] = React.useState(false);
    const [userDerailsScroll, setUserDerailsScroll] = React.useState('paper');

    const newDialogHandleClickClose = () => {
        setNewDialogOpen(false);
    };


    function openNewDialogClick() {
        setNewDialogOpen(true)
    }


    const [openButton1, setOpenButton1] = React.useState(true);

    const [openButton2, setOpenButton2] = React.useState(false);

    function setOpenButton1Function(flag) {
        setOpenButton1(true)
        setOpenButton2(false)
    }
    function setOpenButton2Function(flag) {
        setOpenButton2(true)
        setOpenButton1(false)

    }
    const [selectedValue, setSelectedValue] = React.useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const [needToDisplayInLoginPage, setNeedToDisplayInLoginPage] = React.useState('');


    const [showBox1, setShowBox1] = React.useState('block');
    const [showBox2, setShowBox2] = React.useState('block');
    const [showBox3, setShowBox3] = React.useState('block');
    const [showBox4, setShowBox4] = React.useState('block');
    const [showBox5, setShowBox5] = React.useState('block');
    const [showBox6, setShowBox6] = React.useState('block');

    function showBoxFun(type) {
        setShowBox1(type);
        setShowBox2(type);
        setShowBox3(type);
        setShowBox4(type);
        setShowBox5(type);
        setShowBox6(type);
    }

    const handleChangeNeedToDisplayInLoginPage = (event) => {
        setNeedToDisplayInLoginPage(event.target.value);
        if (event.target.value === 'yes') {
            showBoxFun('block');
        } else {
            showBoxFun('none');
        }
    };
    const [typeOfDisplay, setTypeOfDisplay] = React.useState('');

    const handleChangeTypeOfDisplay = (event) => {
        setTypeOfDisplay(event.target.value);
    };

    const [whereToDisplay, setWhereToDisplay] = React.useState('');

    const handleChangewhereToDisplay = (event) => {
        setWhereToDisplay(event.target.value);
    };

    const [alignContent, setAlignContent] = React.useState('');

    const handleChangeAlignContent = (event) => {
        setAlignContent(event.target.value);
    };

    const [backgroundColorSub, setBackgroundColorSub] = useState('');

    const handleColorSub = (event) => {
        setBackgroundColorSub(event.target.value);
    };

    const [buttonBackground, setButtonBackground] = useState('');

    const handleChangeButtonBackground = (event) => {
        setButtonBackground(event.target.value);
    };

    const [fontColor, setFontColor] = useState('');

    const handleFontColor = (event) => {
        setFontColor(event.target.value);
    };

    const [openImage, setOpenImage] = React.useState(false);
    const [imageHtmlData, setImageHtmlData] = React.useState('');

    function handleCloseClick() {
        setOpenImage(false)
    }

    function imagePreviw(url) {
        var str = "<img src=\"" + url + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);

    }



    return (
        <>

            <Card className={classes.cardRiskManage} >

                <CardContent>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                            id='btnOpenRiskCollection'
                            variant="outlined"
                            size="medium"
                            className={(openButton1 ? classe.superAdmintabBtnDBClicked : classe.tabBtnDB)}
                            onClick={() => setOpenButton1Function(true)}
                        >
                            log in page design
                        </Button>
                        <Button
                            id='btnOpenRiskCollection'
                            variant="outlined"
                            size="medium"
                            className={(openButton2 ? classe.superAdmintabBtnDBClicked : classe.tabBtnDB)}
                            onClick={() => setOpenButton2Function(true)}
                        >
                            create edit sub pages
                        </Button>

                    </div>
                    <br></br>


                    {
                        openButton1 ?
                            <>

                                <Card >
                                    <CardHeader
                                        avatar={
                                            <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                                <Info />
                                            </Avatar>
                                        }
                                        className={classe.commonMainTableHead}
                                        title={selectedDomainHeaderName}
                                    />
                                    <CardContent>


                                        <Grid container spacing={1}>

                                            <Grid item xs={2}>
                                                <Typography
                                                    style={{ marginRight: "8px", fontWeight: 400 }}
                                                >
                                                    Domain Name:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>

                                                <Autocomplete
                                                    id="package-name"
                                                    options={allLoginPages.map((option) => ({
                                                        label: option.domainName,
                                                        id: option.id,
                                                        versionNumber: option.versionNumber,
                                                        baseVersion: option.baseVersion,
                                                    }))}
                                                    //options={allLoginPages.map((option) => option.id)}
                                                    value={selectedDomainName}
                                                    onChange={(event, newValue) => {
                                                        domainSelecter(event, newValue)
                                                    }}
                                                    freeSolo
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Type a new Domain name or select (Ex: riskarchi.com)"
                                                            variant="outlined"
                                                            size="small"
                                                            style={{ width: '100%' }}
                                                            onChange={(event) => domainSelecter1(event)}
                                                        />
                                                    )}
                                                    renderOption={(props, option) => (
                                                        <li {...props}>
                                                            <div>{option.label}</div>
                                                            <span style={{ fontSize: '9px' }}>
                                                                <b>{' - (version: '}</b>{option.versionNumber}{''}
                                                                {option.versionNumber === 'current' ?
                                                                    <>
                                                                        <b>{' - Base Version: '}</b>
                                                                        {option.baseVersion}{')'}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {')'}
                                                                    </>

                                                                }
                                                            </span>
                                                        </li>
                                                    )}
                                                />


                                            </Grid>
                                            <Grid item xs={4}>
                                                <div alignItems="center" style={{ display: 'flex', gap: '5px' }}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        style={{ height: '36px', display: `${showSaveUpdateBtn}` }}
                                                        onClick={() => {
                                                            addDomains()
                                                        }}
                                                    >
                                                        {addUpdateButton}
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        style={{ height: '36px', display: `${showAddNewBtn}` }}
                                                        onClick={() => {
                                                            addNewDomains()
                                                        }}
                                                    >
                                                        {'Add New'}
                                                    </Button>
                                                    {showEditButton ?
                                                        <>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{ height: '36px', display: `${showEditBtn}` }}
                                                                onClick={() => {
                                                                    domainEdit()
                                                                }}
                                                            >
                                                                {editBtnName}
                                                            </Button>
                                                        </>
                                                        : ''
                                                    }


                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        style={{ height: '36px', display: `${showDeleteBtn}` }}
                                                        onClick={() => {
                                                            deleteFunction()
                                                        }}
                                                    >
                                                        DELETE
                                                    </Button>

                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        style={{ height: '36px' }}
                                                        onClick={() => {
                                                            clearFuctionDomains()
                                                        }}
                                                    >
                                                        CLEAR
                                                    </Button>



                                                </div>
                                            </Grid>


                                            <Grid item xs={12} style={{ display: `${showLoginConent}` }}>
                                                <Grid container spacing={2}>


                                                    <Grid item xs={4}>
                                                        <Box
                                                            component="form"
                                                            sx={{
                                                                '& .MuiTextField-root': { mt: 1, width: '100%' },
                                                            }}
                                                            noValidate
                                                            autoComplete="off"
                                                        > <FormControl fullWidth>
                                                                <TextField
                                                                    label="Select a Trial Centre"
                                                                    sx={{ width: '100%' }}
                                                                    select
                                                                    value={centreId}
                                                                    onChange={handleChangeCentre}
                                                                >
                                                                    {allCentres.map((item) => (
                                                                        <MenuItem
                                                                            value={item.centreId}
                                                                        >
                                                                            {item.centreName}{" ("}{item.centreId}{")"}
                                                                        </MenuItem>
                                                                    ))}

                                                                </TextField>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Box
                                                            component="form"
                                                            sx={{
                                                                '& .MuiTextField-root': { mt: 1, width: '100%' },
                                                            }}
                                                            noValidate
                                                            autoComplete="off"
                                                        >
                                                            {/*
                                                        <FormControl fullWidth>
                                                                <TextField
                                                                    label="Allocate Centres For above Domain"
                                                                    sx={{ width: '100%' }}
                                                                    multiple
                                                                    value={centreId}
                                                                    onChange={handleChangeCentre}
                                                                >
                                                                    {allCentres.map((item) => (
                                                                        <MenuItem
                                                                            value={item.centreId}
                                                                        >
                                                                            {item.centreName}{" ("}{item.centreId}{")"}
                                                                        </MenuItem>
                                                                    ))}

                                                                </TextField>
                                                        </FormControl>
                                                        */}

                                                            <FormControl sx={{ m: 1, width: '100%' }}>
                                                                <InputLabel id="demo-multiple-checkbox-label">Allocate Centres For above Domain</InputLabel>
                                                                <Select
                                                                    labelId="demo-multiple-checkbox-label"
                                                                    id="demo-multiple-checkbox"
                                                                    multiple
                                                                    value={arrCentreNames}
                                                                    onChange={handleChangeCentreNames}
                                                                    input={<OutlinedInput label="Allocate Centres For above Domain" />}
                                                                    renderValue={(selected) => selected.join(', ')}
                                                                    MenuProps={MenuProps}
                                                                >
                                                                    {allCentres.map((name) => (
                                                                        <MenuItem key={name.centreName} value={name.centreName}>
                                                                            <Checkbox checked={arrCentreNames.indexOf(name.centreName) > -1} />
                                                                            <ListItemText primary={`${name.centreName} (${name.centreId})`} />
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>



                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        &nbsp;
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TableContainer>
                                                            <Table aria-label="spanning table" className={classes.tableStyle} style={{ marginTop: '-7px' }}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="center" rowSpan={2} className={classes.thStyleImage}>
                                                                            <Box sx={{ minWidth: 120 }} className={classes.riskImageSize}>
                                                                                <Card className={classes.root}>
                                                                                    <div className={classes.posRelativeAndDivBorder}>
                                                                                        {preview != null && preview != "" && <img src={preview} className={classes.riskImageSize} />}
                                                                                    </div>
                                                                                </Card>
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell align="right" className={classes.thStyle}>
                                                                            <Stack direction="row" spacing={2}>
                                                                                <label htmlFor="contained-button-file-1">
                                                                                    <Input accept="image/*" id="contained-button-file-1" type="file" name="file"
                                                                                        onChange={(event) => {
                                                                                            console.log(event.target.files[0]);
                                                                                            setSelectedFile(event.target.files[0]);
                                                                                        }} />
                                                                                    <Button variant="contained" component="span" className={classes.btnColorPurple} style={{ marginTop: '-55px' }}>
                                                                                        Upload background Image
                                                                                    </Button>

                                                                                </label>

                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Button variant="contained" onClick={() => { clearImageFuction() }} component="span" className={classes.btnColorPurple} style={{ marginTop: '9px' }}>
                                                            Clear Image
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>

                                                        <label>Pick background Color : </label>
                                                        &nbsp;&nbsp;&nbsp;

                                                        <input
                                                            type='color'
                                                            style={{ width: '50%' }}
                                                            id="headerColor"
                                                            name="headerColor"
                                                            value={backgroundColor}
                                                            onChange={handleColor}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Button variant="contained" onClick={() => { clearColorFuction() }} component="span" className={classes.btnColorPurple} style={{ marginTop: '0px', height: '32px' }}>
                                                            Clear Color
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div style={{ display: "flex", gap: '10px' }}>
                                                            <Box style={{ width: '154px' }}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="select-label" sx={{ top: -5 }}>Display sub pages?</InputLabel>
                                                                    <Select
                                                                        labelId="select-label"
                                                                        id="demo-simple-select"
                                                                        label="Package Name"
                                                                        value={needToDisplayInLoginPage}
                                                                        onChange={handleChangeNeedToDisplayInLoginPage}
                                                                        size="small"
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <MenuItem value="yes">Yes</MenuItem>
                                                                        <MenuItem value="no">No</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                            <Box style={{ width: '142px', display: `${showBox1}` }}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="select-label" sx={{ top: -5 }}>Display Style</InputLabel>
                                                                    <Select
                                                                        id="demo-simple-select"
                                                                        value={typeOfDisplay}
                                                                        onChange={handleChangeTypeOfDisplay}
                                                                        size="small"
                                                                        label="Package Name"
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <MenuItem value="carousel">Carousel</MenuItem>
                                                                        <MenuItem value="textOnly">TextOnly</MenuItem>
                                                                        <MenuItem value="buttonFormat">Button Format</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                            <Box style={{ width: '165px', display: `${showBox2}` }}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="select-label" sx={{ top: -5 }}>Display location</InputLabel>
                                                                    <Select
                                                                        id="demo-simple-select"
                                                                        label="Package Name"
                                                                        value={whereToDisplay}
                                                                        onChange={handleChangewhereToDisplay}
                                                                        size="small"
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <MenuItem value="top">Top of the page</MenuItem>
                                                                        <MenuItem value="bottom">Bottom of the page</MenuItem>

                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                            <Box style={{ width: '142px', display: `${showBox3}` }}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="select-label" sx={{ top: -5 }}>Align Items</InputLabel>
                                                                    <Select
                                                                        id="demo-simple-select"
                                                                        value={alignContent}
                                                                        onChange={handleChangeAlignContent}
                                                                        size="small"
                                                                        label="Package Name"
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <MenuItem value="left">Left Justify</MenuItem>
                                                                        <MenuItem value="center">Centre</MenuItem>
                                                                        <MenuItem value="right">Right Justify</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                            <Box style={{ width: '135px', display: `${showBox4}` }}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="select-label" sx={{ top: -10 }}>background Color</InputLabel>
                                                                    <input
                                                                        type='color'
                                                                        style={{ width: '100%', height: '36px' }}
                                                                        id="headerColor"
                                                                        name="headerColor"
                                                                        value={backgroundColorSub}
                                                                        onChange={handleColorSub}
                                                                        disabled=""
                                                                    />
                                                                </FormControl>
                                                            </Box>
                                                            <Box style={{ width: '155px', display: `${showBox5}` }}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="select-label" sx={{ top: -5 }}>Button background</InputLabel>
                                                                    <Select
                                                                        id="demo-simple-select"
                                                                        value={buttonBackground}
                                                                        onChange={handleChangeButtonBackground}
                                                                        size="small"
                                                                        label="Package Name"
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <MenuItem value="contained">Filled</MenuItem>
                                                                        <MenuItem value="outlined">outlined</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                            <Box style={{ width: '135px', display: `${showBox6}` }}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="select-label" sx={{ top: -10 }}>Font Color</InputLabel>
                                                                    <input
                                                                        type='color'
                                                                        style={{ width: '100%', height: '36px' }}
                                                                        id="headerColor"
                                                                        name="headerColor"
                                                                        value={fontColor}
                                                                        onChange={handleFontColor}
                                                                    />
                                                                </FormControl>
                                                            </Box>


                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Editor
                                                            apiKey='ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj'
                                                            tinymceScriptSrc={'https://cdn.tiny.cloud/1/ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj/tinymce/6/tinymce.min.js'}
                                                            onInit={(evt, editor) => editorRef.current = editor}
                                                            initialValue={logInPageDesign}
                                                            init={{
                                                                referrer_policy: 'origin',
                                                                height: 300,
                                                                menubar: true,
                                                                placeholder: 'Log In Page Design',
                                                                plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media mediaembed template codesample table advtable charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help charmap quickbars emoticons casechange formatpainter pageembed hr fullpage advcode export permanentpen powerpaste',
                                                                imagetools_cors_hosts: ['picsum.photos'],
                                                                //font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
                                                                menubar: 'file edit view insert format tools table help',
                                                                toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | casechange forecolor backcolor formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview print | pageembed insertfile image media template tokens link anchor codesample | ltr rtl hr fullpage export permanentpen configurepermanentpen',
                                                                //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                                //content_css: '/TinyStyle.css', 
                                                                content_css: [
                                                                    'Bootstrap/bootstrap.css',
                                                                    'Bootstrap/bootstrap.min.css',
                                                                    'Bootstrap/bootstrap-theme.css',
                                                                    'Bootstrap/bootstrap-theme.min.css',
                                                                    '/TinyStyle.css',
                                                                    '/stylesTiny.css',
                                                                    '/Hero10Style.css'
                                                                ],
                                                                allow_script_urls: true,
                                                                export_image_proxy: 'proxy.php',
                                                                toolbar_sticky: true,
                                                                autosave_ask_before_unload: true,
                                                                autosave_interval: '30s',
                                                                autosave_prefix: '{path}{query}-{id}-',
                                                                autosave_restore_when_empty: false,
                                                                autosave_retention: '2m',
                                                                setup: (editor) => {
                                                                    editor.ui.registry.addMenuButton("tokens", {
                                                                        text: "Token",
                                                                        tooltip: "Insert token",
                                                                        fetch: (callback) => {
                                                                            var items = tokens.map((token) => {
                                                                                return {
                                                                                    type: "menuitem",
                                                                                    text: token.text,
                                                                                    onAction: () => {
                                                                                        editor.insertContent(token.value);
                                                                                    }
                                                                                }
                                                                            });
                                                                            callback(items);
                                                                        }
                                                                    });
                                                                    editor.on('init', function (e) {
                                                                        var content = unescape(logInPageDesign);
                                                                        editor.setContent(content);
                                                                        editor.dom.bind(editor.getBody(), 'click', function (e) {
                                                                            var target = e.target;

                                                                            // Check if the clicked element is an image
                                                                            if (target.nodeName.toLowerCase() === 'img') {
                                                                                // Your onclick function logic here
                                                                                imagePreviw(target.src);  // Replace this with your actual onclick function
                                                                            }
                                                                        });
                                                                    });
                                                                },


                                                                image_title: true,
                                                                /* enable automatic uploads of images represented by blob or data URIs*/
                                                                automatic_uploads: true,
                                                                /*Here we add custom filepicker only to Image dialog*/
                                                                file_picker_types: 'image',

                                                                /* and here's our custom image picker*/
                                                                file_picker_callback: function (cb, value, meta) {
                                                                    var input = document.createElement('input');
                                                                    input.setAttribute('type', 'file');
                                                                    input.setAttribute('accept', 'image/*');

                                                                    input.onchange = function () {
                                                                        var file = this.files[0];
                                                                        var reader = new FileReader();

                                                                        reader.onload = function () {
                                                                            /*
                                                                            Note: Now we need to register the blob in TinyMCEs image blob
                                                                            registry. In the next release this part hopefully won't be
                                                                            necessary, as we are looking to handle it internally.
                                                                            */
                                                                            var id = 'blobid' + (new Date()).getTime();

                                                                            var blobCache = editorRef.current.editorUpload.blobCache;
                                                                            //var blobCache = editorRef.current.activeEditor.editorUpload.blobCache;
                                                                            var base64 = reader.result.split(',')[1];

                                                                            var blobInfo = blobCache.create(id, file, base64);
                                                                            blobCache.add(blobInfo);

                                                                            /* call the callback and populate the Title field with the file name */
                                                                            cb(blobInfo.blobUri(), { title: file.name });
                                                                        };

                                                                        reader.readAsDataURL(file);
                                                                    };

                                                                    input.click();
                                                                },

                                                                //images_upload_handler: function (blobInfo, success, failure) {

                                                                //},

                                                                templates: tinyMCETemplateList,

                                                                referrer_policy: 'origin',

                                                                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                                                                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                                                                image_caption: true,
                                                                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                                                noneditable_noneditable_class: 'mceNonEditable',
                                                                toolbar_mode: 'sliding',
                                                                contextmenu: 'link image imagetools table',
                                                                //content_css: useDarkMode ? 'dark' : 'default',
                                                                //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'





                                                            }}
                                                        />
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardContent>
                                </Card>
                            </>
                            :

                            <>
                                <AddSubContentComponent
                                    tokens={tokens}
                                    subType={'loginpage'}
                                />
                            </>
                    }


                </CardContent>
            </Card>

            <Dialog
                maxWidth={maxWidth}
                open={newDialogOpen}
                onClose={newDialogHandleClickClose}
                scroll={userDerailsScroll}
                aria-labelledby="userDerails-scroll-dialog-title"
                aria-describedby="userDerails-scroll-dialog-description"
            >
                <DialogTitle id="draggable-dialog-title">
                    create / edit sub pages
                </DialogTitle>
                <DialogContent dividers={userDerailsScroll === 'paper'}>
                    <DialogContentText
                        id="userDerails-scroll-dialog-description"
                        tabIndex={-1}
                    >

                        <AddSubContentComponent
                            parentId={logInPageDesignId}
                            selectedDomainName={selectedDomainName}
                        />


                    </DialogContentText>
                </DialogContent>
            </Dialog>


            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={saveDialogOpen}
                onClose={saveDialogHandleClickClose}
                scroll={saveDialogScroll}
                aria-labelledby="userDerails-scroll-dialog-title"
                aria-describedby="userDerails-scroll-dialog-description"
            >
                <DialogTitle id="draggable-dialog-title">
                    {selectedDomainHeaderNameUpdate}
                </DialogTitle>
                <DialogContent dividers={saveDialogScroll === 'paper'}>
                    <DialogContentText
                        id="userDerails-scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={7}>
                                <Box display="flex" alignItems="center" style={{ gap: '5px' }}>
                                    {/** 
                                    <FormControl fullWidth>
                                        <InputLabel id="select-label">Select a version to Update OR new to create new version</InputLabel>
                                        <Select
                                            labelId="select-label"
                                            id="select"
                                            value={selectedVersionNo}
                                            label="package-name"
                                            onChange={versionSelecter}

                                        >
                                            {versionArray.map((option, index) => (
                                                <MenuItem key={index} value={option.value}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
*/}

                                    <FormControl fullWidth>
                                        <TextField
                                            label={'Version Number'}
                                            sx={{ width: '100%' }}
                                            select
                                            value={selectedVersionNo}
                                            onChange={versionSelecter}
                                        >
                                            {versionArray.map((option, index) => (
                                                <MenuItem value={option.value}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}

                                        </TextField>
                                    </FormControl>




                                </Box>
                            </Grid>

                            <Grid item xs={5}>
                                <div alignItems="center" style={{ display: 'flex', gap: '5px' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        style={{ height: "49px" }}
                                        onClick={() => {
                                            saveFunction();
                                        }}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        style={{ height: "49px" }}
                                        onClick={() => {
                                            saveFunctionNewVersion();
                                        }}
                                    >
                                        Save As A New Version
                                    </Button>
                                    {selectedVersionNo != 'current' ?
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            style={{ height: "49px" }}
                                            onClick={() => {
                                                saveFunctionCurrentVersion();
                                            }}
                                        >
                                            Make this as the Current Version
                                        </Button>
                                        :
                                        ''
                                    }
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        style={{ height: "49px" }}
                                        onClick={() => {
                                            saveDialogHandleClickClose();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>

                            </Grid>

                        </Grid>





                    </DialogContentText>
                </DialogContent>
            </Dialog>



            <Dialog
                open={openImage}
                //onClose={setOpenImage(false)}
                aria-describedby="alert-dialog-description"
                maxWidth={"md"}
                aria-labelledby="draggable-dialog-title"

            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

                </DialogTitle>
                <DialogContent>
                    {parseHtml(imageHtmlData)}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleCloseClick()}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SuperAdminLoginPageDesign