import React, { useState } from 'react';
import { Link,useLocation } from 'react-router-dom';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HomeOutlined from '@mui/icons-material/HomeOutlined';  
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Grid, makeStyles } from "@material-ui/core";
import Divider from '@mui/material/Divider';


const SidebarLink = styled.span`
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: 14px;
  
  &:hover {
    background:linear-gradient(270deg, #9155FD 0%, #C6A7FE 100%);
    border-radius:border-radius: 0px 50px 50px 0px;
    color:#ffff;
    border-left: 4px solid #632ce4;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 13px;
`;

const DropdownLink = styled(Link)`
  background: #c2b4b442;
  height: 40px;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 14px;
  margin-left:50px;
  
  &:hover {
    background:linear-gradient(270deg, #9155FD 0%, #C6A7FE 100%);
    border-radius:border-radius: 0px 50px 50px 0px;
    color:#ffff;
    cursor: pointer;
  }

`;

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink to=''  onClick={item.subMenuList && showSubnav} >
        <div>
    
          <SidebarLabel >{item.centerMenuName}</SidebarLabel>
          
        </div>
        <div>
          {item.subMenuList && subnav
            ? <ArrowDropUpIcon />
            : item.subMenuList
            ? <KeyboardArrowDownIcon fontSize='5px'  />
            : null}
        </div>
      </SidebarLink>

      {subnav &&
        item.subMenuList.map((item, index) => {
          return (
            <DropdownLink 
              to={"/"+item.menu.menuUri}  
              key={index}
             
              >
              <ArrowForwardIcon fontSize='small'/>
              <SidebarLabel
                id={item.centerMenuOrderNum} 
                >
                  {item.centerMenuName}
              </SidebarLabel>
            </DropdownLink>
            
          );
        })}
    </>
  );
};

export default SubMenu;
