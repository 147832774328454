import React, { useState, useEffect } from 'react'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages'
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { ArrowBack } from '@mui/icons-material';
import { CONFIG } from '../config';
import axios from 'axios';
import Card from "@mui/material/Card";

import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import FormLabel from '@mui/material/FormLabel';
import { FormGroup } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useStyle from '../Css/MainCss';
import Tooltip from '@mui/material/Tooltip';
import { Add, AddToPhotosSharp, Close, Redo, Refresh, RestartAlt, Restore, RestorePage } from '@mui/icons-material';
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { CardActions } from '@mui/material';
import Divider from '@mui/material/Divider';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import '../Css/EvaluateRisk.css'
import SavePageAs from './SavePageAs'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

const EvaluateRiskQuestionerComponent = ({ centreId, allCollectionData }) => {

    const [maxWidthTable, setMaxWidthTable] = React.useState(400);


    useEffect (() => {
      console.log("++++++++++++window. screen. width+++++++++++++");
      if (window. screen. width <= 480){
            setMaxWidthTable(150)
      }else{
        if (window. screen. width <= 810 && window. screen. width >= 481){
            setMaxWidthTable(200)

        }
      }
      console.log(window. screen. width);
    },[])

    const classes = useStyle();


    function compare(a, b) {
        var nameA = a.title.toUpperCase(); // ignore upper and lowercase
        var nameB = b.title.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        getColors();
    }, [])

    const [databClr, setDatabClr] = useState([]);
    function getColors() {
        axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR + "/all", {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("+++++++++++++++++All colors++++++++++++++++++");
                console.log(response.data);
                setDatabClr(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    const [quesData, setQuesData] = useState([]);
    const [selectedCollectionEvaluateId, setSelectedCollectionEvaluateId] = useState('');

    const [selectedCollectionEvaluateTitle, setSelectedCollectionEvaluateTitle] = useState('');

    const [riskEvalTypeOpen, setRiskEvalTypeOpen] = React.useState(false);

    const [riskEvalTypeScroll, setRiskEvalTypeScroll] = React.useState('paper');

    const [selectedRiskEvalArray, setSelectedRiskEvalArray] = React.useState([]);

    const [hierarchyId, setHierarchyId] = React.useState();

    const [selectedRiskArrForEval, setSelectedRiskArrForEval] = useState([]);


    const [openCollectionData, setOpenCollectionData] = useState(false);

    const [openCollectionDataScroll, setOpenCollectionDataScroll] = useState('paper');

    const [rows, setRows] = useState([]);

    function RiskScrumb(riskName, collectionRiskId, riskTypeData) {
        var rsTypeArr = [];
        riskTypeData.forEach((data) => {
            if (data.collectionRiskId === collectionRiskId) {
                rsTypeArr = data.riskTypes;
            }
        });
        var riskScrumbPath = "";
        rsTypeArr.forEach((item) => {
            var pathStr = "";
            pathStr = getRiskScrumb(item, riskName);
            if (pathStr != undefined && pathStr != "") {
                riskScrumbPath = pathStr;
                return true;
            }
        });
        return riskScrumbPath;
    }


    function getRiskScrumb(comment, riskName) {
        var riskScrumbPath = "";
        if (riskName === comment.name) {
            riskScrumbPath = riskName;
        } else {
            if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
                var riskPath = comment.name;
                var subRiskPath = getSubRiskScrumb(comment.subRisks, riskName);
                if (subRiskPath != "") {
                    riskScrumbPath = riskPath + subRiskPath;
                }
            }
        }
        return riskScrumbPath;
    }

    function getSubRiskScrumb(subData, riskName) {
        var tmpSubPath = "";
        subData.forEach((item, index) => {
            if (item.name === riskName) {
                tmpSubPath += " --> " + item.name;
                return true;
            } else {
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    var tmpSubPath1 = " --> " + item.name;
                    var tmpSubSubPath1 = getSubRiskScrumb(item.subRisks, riskName);
                    if (tmpSubSubPath1 != "") {
                        tmpSubPath += tmpSubPath1 + tmpSubSubPath1;
                    }
                }
            }
        });
        return tmpSubPath;
    }


    function PaperComponentCollectionData(props) {
        return (
            <Draggable
                handle="#collection-data-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    function checkSelected(id, name, closeIds, openIds) {
        if (document.getElementById("chkRiskType" + id).checked) {
            var tmpArray = {};
            tmpArray["collectionRiskTypeId"] = id;
            var ar = [];
            closeIds.forEach((data) => {
                ar.push(data);
            });
            openIds.forEach((data) => {
                ar.push(data);
            });
            tmpArray["collectionQuestionIds"] = ar;
            tmpArray["name"] = name;
            selectedRiskEvalArray.push(tmpArray);
            setSelectedRiskEvalArray(selectedRiskEvalArray);
        } else {
            selectedRiskEvalArray.forEach((data, index) => {
                if (data.collectionRiskTypeId === id) {
                    selectedRiskEvalArray.splice(index, 1);
                    setSelectedRiskEvalArray(selectedRiskEvalArray);
                }
            })
        }
    }

    function getQuestionSubIdsWithSubRisks(comment, type, ids) {
        comment.forEach((data, index) => {
            if (type === 1) {
                if (data.questions != undefined && data.questions != null) {
                    if (data.questions.closeEndedQuestionIds != undefined && data.questions.closeEndedQuestionIds != null && data.questions.closeEndedQuestionIds.length > 0) {
                        data.questions.closeEndedQuestionIds.forEach((item, index) => {
                            ids.push(item.questionId);
                        });
                    }
                }
            }
            if (type === 2) {
                if (data.questions != undefined && data.questions != null) {
                    if (data.questions.openEdndedQuestionIds != undefined && data.questions.openEdndedQuestionIds != null && data.questions.openEdndedQuestionIds.length > 0) {
                        data.questions.openEdndedQuestionIds.forEach((item, index) => {
                            ids.push(item.questionId);
                        });
                    }
                }
            }
            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                getQuestionSubIdsWithSubRisks(data.subRisks, type, ids);
            }
        });
        return ids;

    }


    function setRiskTypeEvalFuction() {
        setSelectedRiskArrForEval([]);
        var takenFlag = true;
        var takenFlag = false;
        var tmpRiskData = [];
        var headerMsg = "";
        var selectedRiskArr = [];
        if (document.getElementById('chkRiskType0').checked) {
            takenFlag = true;
            selectedRiskArr.push("ALL");
            quesData.forEach((data) => {
                tmpRiskData.push(data);
                takenFlag = false;
                headerMsg = "All";
            });
        } else {
            var k = 0;
            selectedRiskEvalArray.forEach((item) => {
                if (document.getElementById('chkRiskType' + item.collectionRiskTypeId) != null && document.getElementById('chkRiskType' + item.collectionRiskTypeId).checked) {
                    selectedRiskArr.push(item.collectionRiskTypeId);
                    takenFlag = true;
                    item.collectionQuestionIds.forEach((quesId) => {
                        quesData.forEach((data) => {
                            if (data.questionId === quesId) {
                                tmpRiskData.push(data);
                                takenFlag = false;
                            }
                        });
                    });
                    if (k === 0) {
                        headerMsg = item.name;
                    } else {
                        headerMsg += ", " + item.name;
                    }
                    k++;
                }
            });
        }
        if (takenFlag) {
            if (takenFlag) {
                alert("Please note that you don't have any Questioners for the selected Risk Types")
            } else {
                alert("Please select atleast one Risk Type for evaluation")
            }
        } else {
            //setRiskEvalHeaderMsg(headerMsg);
            setSelectedRiskArrForEval(selectedRiskArr);
            var count = 1;
            var tmpArray = [];
            setRows([]);
            tmpRiskData.forEach((item) => {
                var tmpObj = {};
                tmpObj["questionId"] = item.questionId;
                tmpObj["collectionId"] = selectedCollectionEvaluateId;
                tmpObj["collectionUniqueName"] = selectedCollectionEvaluateUniqueName;
                tmpObj["id"] = count;
                var riskScrubStr = RiskScrumb(item.riskTypes.name, item.riskTypes.collectionRiskId, riskTypeData)
                tmpObj["riskName"] = riskScrubStr;

                tmpObj["objectiveName"] = item.objective.name;
                tmpObj["componentName"] = item.component.name;
                tmpObj["principalName"] = item.principle.name;
                tmpObj["entityName"] = item.entity.name;
                tmpObj["question"] = item.question;
                tmpObj["questionType"] = item.questionType;
                tmpObj["questionScore"] = item.questionScore;
                tmpObj["questionData"] = item;
                tmpArray.push(tmpObj);
                setRows(tmpArray);
                count++;
            })
            setRiskEvalTypeOpen(false);




            setOpenCollectionData(true);
            setOpenCollectionDataScroll('paper');
        }
    }





    const [rsType, setRsTtpe] = useState([]);

    function RiskEval({ comment }) {
        var closeEndedQuestionIds = getQuestionIds(comment, 1);
        var openEndedQuestionIds = getQuestionIds(comment, 2);
        var closeEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment, 1);
        var openEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment, 2);

        const [show, setShow] = React.useState(false)
        const nestedComments = (comment.subRisks || []).map(comment => {
            return <RiskEval key={comment.collectionRiskTypeId} comment={comment} type="child" />
        })

        return (
            <div style={{ "marginLeft": "15px", "marginTop": "10px" }}>
                <div style={{ display: 'flex', textAlign: 'center' }}>
                    <Button
                        style={{ width: '0px', height: '0px', marginTop: '14px' }}
                        onClick={() => setShow(!show)}
                        startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    >
                    </Button>

                    <span style={{ marginTop: '-7px !important' }} >

                        <FormControlLabel control={<Checkbox onClick={() => checkSelected(comment.collectionRiskTypeId, comment.name, show ? closeEndedQuestionIds : closeEndedQuestionIdsWithSub, show ? openEndedQuestionIds : openEndedQuestionIdsWithSub)} id={"chkRiskType" + comment.collectionRiskTypeId} />}
                            label={show ? comment.name + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ? '' : ' (') + (closeEndedQuestionIds.length > 0 ? ' C : '
                                + closeEndedQuestionIds.length : '') + (openEndedQuestionIds.length > 0
                                    ? closeEndedQuestionIds.length > 0 ? ', O : ' + openEndedQuestionIds.length : ' O : ' + openEndedQuestionIds.length : '') + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ? '' : ' )')
                                :
                                comment.name + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ? '' : ' (') + (closeEndedQuestionIdsWithSub.length > 0 ? ' C : '
                                    + closeEndedQuestionIdsWithSub.length : '') + (openEndedQuestionIdsWithSub.length > 0
                                        ? closeEndedQuestionIdsWithSub.length > 0 ? ', O : ' + openEndedQuestionIdsWithSub.length : ' O : ' + openEndedQuestionIdsWithSub.length : '') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ? '' : ' )')
                            }

                        />
                    </span>
                </div>
                {
                    show ?
                        <>
                            {nestedComments}
                        </>
                        : null
                }
            </div>
        )
    }


    function getQuestionIdsWithSubRisks(comment, type) {
        var count = 0;
        var riskIds = "";
        var ids = [];
        if (type === 1) {
            if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0) {
                comment.questions.forEach((quesItem) => {
                    if (quesItem.questionType === "Closeended") {
                        ids.push(quesItem.questionId);
                    }
                })
            }
        }
        if (type === 2) {
            if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0) {
                comment.questions.forEach((quesItem) => {
                    if (quesItem.questionType === "Openended") {
                        ids.push(quesItem.questionId);
                    }
                })
            }
        }
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            return getQuestionSubIdsWithSubRisks(comment.subRisks, type, ids);
        } else {
            return ids;
        }
    }


    function getQuestionIds(comment, type) {
        var count = 0;
        var ids = [];
        if (comment.questions != undefined && comment.questions != null) {
            if (type === 1) {
                if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0) {
                    comment.questions.forEach((quesItem) => {
                        if (quesItem.questionType === "Closeended") {
                            ids.push(quesItem.questionId);
                        }
                    })
                }
            }
            if (type === 2) {
                if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0) {
                    comment.questions.forEach((quesItem) => {
                        if (quesItem.questionType === "Openended") {
                            ids.push(quesItem.questionId);
                        }
                    })
                }
            }
        } else {
            ids = [];
        }
        return ids;
    }



    const handleChangeHierarchy = (event) => {
        var hierarchyId = event.target.value;
        setHierarchyId(event.target.value)
        riskTypeData.forEach((item, index) => {
            if (item.collectionRiskId === hierarchyId) {
                setRsTtpe(item.riskTypes);
            }
        })
    };

    const riskEvalTypeHandleClickClose = () => {
        setRiskEvalTypeOpen(false);
        setSelectedRiskEvalArray([]);
    };

    function PaperComponent(props) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }







    const [riskTypeData, setRiskTypeData] = useState([])
    const [selectedCollectionEvaluateUniqueName, setSelectedCollectionEvaluateUniqueName] = useState('');

    var allQuesArray = [];
    function getAllQuestionsForCollection(riskArray) {
        allQuesArray = [];
        riskArray.forEach((item) => {
            if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
                item.riskTypes.forEach((item1) => {
                    if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0) {
                        item1.questions.forEach((item2) => {
                            allQuesArray.push(item2);
                        })
                    }
                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                        getAllQuestionsInSubRisksForCollection(item1.subRisks);
                    }
                });
            }
        });
        if (allQuesArray.length > 0) {
            //var quesArray = allQuesArray.sort (compare);
            setQuesData(allQuesArray);
        }
    }

    function getAllQuestionsInSubRisksForCollection(riskArray) {
        riskArray.forEach((item) => {
            if (item.questions != undefined && item.questions != null && item.questions.length > 0) {
                item.questions.forEach((item1) => {
                    allQuesArray.push(item1);
                })
            }
            if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                getAllQuestionsInSubRisksForCollection(item.subRisks);
            }
        });
    }

    function setOpenCollectionDataFuction(flag, collectionId, title) {

        /*
        var maxZIndex = findMaxZIndex();
        setOpenCollectionDataZIndex(maxZIndex + 1);
  
  
        
        var topBtnId = document.getElementById("topBtnId");
        var tabId1 = document.getElementById("tabId1");
        var tabId11 = document.getElementById("tabId11");
        
        var addToTopAmount =  addToTopPos();
        addToTop('divOpenCollectionData');
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId11.offsetTop + topBtnId.offsetTop));
        
        */
        setRiskTypeData([]);
        allCollectionData.forEach((data) => {
            if (data.id === collectionId) {
                var tmpRiskArr = [];
                if (data.risks != undefined && data.risks != null && data.risks.length > 0) {
                    tmpRiskArr = data.risks;
                }
                setSelectedCollectionEvaluateUniqueName(data.collectionUniqueName);
                setRiskTypeData(tmpRiskArr);
                getAllQuestionsForCollection(tmpRiskArr);
            }
        });
        /*
        setOpenCollectionDataTopPos(pos);
        */
        setSelectedCollectionEvaluateId(collectionId);

        setSelectedCollectionEvaluateTitle(title);

        setRiskEvalTypeOpen(true);
        setRiskEvalTypeScroll('paper');
        setSelectedRiskEvalArray([]);



    }

    const [closeEndedData, setCloseEndedData] = React.useState([]);
    const [openEndedData, setOpenEndedData] = React.useState([]);
    const [closeEndedTotal, setCloseEndedTotal] = React.useState({});
    const [evaluateAllOpen, setEvaluateAllOpen] = React.useState(false);
    const [evaluateAllScroll, setEvaluateAllScroll] = React.useState('paper');

    function evaluateAll() {
        console.log(rows);
        var countCloseEnded = 1;
        var countOpenEnded = 1;
        var closeEndedArray = [];
        var openEndedArray = [];
        var tmpCOTotalQuestionWeight = 0;
        var tmpCOTotalUsers = 0;
        var tmpCOTotalAnswer1 = 0;
        var tmpCOTotalAnswer2 = 0;
        var tmpCOTotalAnswer3 = 0;
        var tmpCOTotalAnswer4 = 0;
        var tmpCOTotalAnswer5 = 0;
        rows.forEach((data, index) => {
            var tmpArray = [];
            var objAllCe = {};
            var objAllOe = {};
            var tmpDataArray = [];
            var questionData = data.questionData;
            if (data.questionType === "Closeended") {
                objAllCe["id"] = countCloseEnded;
                objAllCe["questionId"] = questionData.questionId;
                objAllCe["question"] = questionData.question;
                objAllCe["questionType"] = questionData.questionType;
                objAllCe["questionWeigth"] = questionData.questionScore;
                objAllCe["noOfRespondedUsers"] = 1;
                tmpCOTotalUsers += 1;
                objAllCe["totalQuestionWeigth"] = (1 * parseInt(questionData.questionScore));
                tmpCOTotalQuestionWeight += (1 * parseInt(questionData.questionScore));
                objAllCe["questionData"] = questionData;
                countCloseEnded++;

                if (questionData.answers.answerMethod.name != "" && questionData.answers.answerList.length > 0) {
                    var tmpAnsName = '';
                    var tmpRiskLevel = '';
                    var tmpTotalUserValue = 0;
                    var count1 = 1;
                    questionData.answers.answerList.forEach((ansData, index2) => {
                        var tmpRiskLevel = ansData.riskLevel;
                        tmpAnsName = ansData.answerName;
                        tmpTotalUserValue = 0;
                        if (ansData.expectedAnswerName === "Yes" || ansData.expectedAnswerName === "checked") {
                            tmpTotalUserValue = 1;
                        } else {
                            if (ansData.expectedAnswerName != '') {
                                tmpTotalUserValue = 1;
                            }
                        }
                        var objData = {};
                        objData["answerName"] = tmpAnsName;
                        objData["riskLevel"] = tmpRiskLevel;
                        objData["userResponseCount"] = tmpTotalUserValue;
                        tmpDataArray.push(objData);
                    })
                }

            } else {
                objAllOe["id"] = countOpenEnded;
                objAllOe["questionId"] = questionData.questionId;
                objAllOe["question"] = questionData.question;
                objAllOe["questionType"] = questionData.questionType;
                objAllOe["questionWeigth"] = questionData.questionScore;
                objAllOe["noOfRespondedUsers"] = 1;
                objAllOe["totalQuestionWeigth"] = (1 * parseInt(questionData.questionScore));
                objAllOe["questionData"] = questionData;
                //objAllOe["responseUserData"] = tmpArray; 
                countOpenEnded++;
                var objData = {};
                objData["answer"] = questionData.answers.answer;
                tmpDataArray.push(objData);

            }
            if (Object.keys(objAllCe).length != 0) {
                var answerLevelArr = [];
                var level1 = false;
                var level2 = false;
                var level3 = false;
                var level4 = false;
                var level5 = false;
                tmpDataArray.forEach((itemDataData) => {
                    var riskLevelVal = parseInt(itemDataData.riskLevel);
                    if (riskLevelVal === -5 || riskLevelVal === -3 || (riskLevelVal > -5 && riskLevelVal < -3)) {
                        level1 = true;
                        var obj = {}
                        obj["no"] = 1;
                        obj["riskName"] = 'Heighly Negative';
                        obj["riskLevel"] = '-5';
                        obj["userResponseCount"] = itemDataData.userResponseCount;
                        obj["totalRiskLevel"] = -5 * parseInt(itemDataData.userResponseCount);
                        tmpCOTotalAnswer1 += -5 * parseInt(itemDataData.userResponseCount);
                        answerLevelArr.push(obj);
                    }
                    if (riskLevelVal === -2 || riskLevelVal === 0 || (riskLevelVal > -2 && riskLevelVal < 0)) {
                        level2 = true;
                        var obj = {}
                        obj["no"] = 2;
                        obj["riskName"] = 'Negative';
                        obj["riskLevel"] = '-2';
                        obj["userResponseCount"] = itemDataData.userResponseCount;
                        obj["totalRiskLevel"] = -2 * parseInt(itemDataData.userResponseCount);
                        tmpCOTotalAnswer2 += -2 * parseInt(itemDataData.userResponseCount);
                        answerLevelArr.push(obj);
                    }
                    if (riskLevelVal === 1 || riskLevelVal === 3 || (riskLevelVal > 1 && riskLevelVal < 3)) {
                        level3 = true;
                        var obj = {}
                        obj["no"] = 3;
                        obj["riskName"] = 'Somewhat Positive';
                        obj["riskLevel"] = '1';
                        obj["userResponseCount"] = itemDataData.userResponseCount;
                        obj["totalRiskLevel"] = 1 * parseInt(itemDataData.userResponseCount);
                        tmpCOTotalAnswer3 += 1 * parseInt(itemDataData.userResponseCount);
                        answerLevelArr.push(obj);
                    }
                    if (riskLevelVal === 4 || riskLevelVal === 7 || (riskLevelVal > 4 && riskLevelVal < 7)) {
                        level4 = true;
                        var obj = {}
                        obj["no"] = 4;
                        obj["riskName"] = 'Positive';
                        obj["riskLevel"] = '4';
                        obj["userResponseCount"] = itemDataData.userResponseCount;
                        obj["totalRiskLevel"] = 4 * parseInt(itemDataData.userResponseCount);
                        tmpCOTotalAnswer4 += 4 * parseInt(itemDataData.userResponseCount);
                        answerLevelArr.push(obj);
                    }
                    if (riskLevelVal === 8 || riskLevelVal === 10 || (riskLevelVal > 8 && riskLevelVal < 10)) {
                        level5 = true;
                        var obj = {}
                        obj["no"] = 5;
                        obj["riskName"] = 'Heightly Positive';
                        obj["riskLevel"] = '8';
                        obj["userResponseCount"] = itemDataData.userResponseCount;
                        obj["totalRiskLevel"] = 8 * parseInt(itemDataData.userResponseCount);
                        tmpCOTotalAnswer5 += 8 * parseInt(itemDataData.userResponseCount);
                        answerLevelArr.push(obj);
                    }
                })
                if (!level1) {
                    var obj = {}
                    obj["no"] = 1;
                    obj["riskName"] = 'Heighly Negative';
                    obj["riskLevel"] = '-5';
                    obj["userResponseCount"] = 0;
                    obj["totalRiskLevel"] = 0;
                    answerLevelArr.push(obj);
                }
                if (!level2) {
                    var obj = {}
                    obj["no"] = 2;
                    obj["riskName"] = 'Negative';
                    obj["riskLevel"] = '-2';
                    obj["userResponseCount"] = 0;
                    obj["totalRiskLevel"] = 0;
                    answerLevelArr.push(obj);
                }
                if (!level3) {
                    var obj = {}
                    obj["no"] = 3;
                    obj["riskName"] = 'Somewhat Positive';
                    obj["riskLevel"] = '1';
                    obj["userResponseCount"] = 0;
                    obj["totalRiskLevel"] = 0;
                    answerLevelArr.push(obj);
                }
                if (!level4) {
                    var obj = {}
                    obj["no"] = 4;
                    obj["riskName"] = 'Positive';
                    obj["riskLevel"] = '4';
                    obj["userResponseCount"] = 0;
                    obj["totalRiskLevel"] = 0;
                    answerLevelArr.push(obj);
                }
                if (!level5) {
                    var obj = {}
                    obj["no"] = 5;
                    obj["riskName"] = 'Heightly Positive';
                    obj["riskLevel"] = '8';
                    obj["userResponseCount"] = 0;
                    obj["totalRiskLevel"] = 0;
                    answerLevelArr.push(obj);
                }
                answerLevelArr = answerLevelArr.sort(compareRiskNo);
                var no = 0;
                var totalRiskLevel = 0;
                var riskName = '';
                var riskLevel = '';
                var userResponseCount = 0;
                var tmpAnswerLevelArr = [];
                //console.log(answerLevelArr);
                answerLevelArr.forEach((itemDataData, index2) => {
                    if (index2 === 0) {
                        no = itemDataData.no;
                        totalRiskLevel = itemDataData.totalRiskLevel;
                        riskName = itemDataData.riskName;
                        riskLevel = itemDataData.riskLevel;
                        userResponseCount = itemDataData.userResponseCount;
                        if ((answerLevelArr.length - 1) === index2) {
                            var obj = {};
                            obj["no"] = no;
                            obj["riskName"] = riskName;
                            obj["riskLevel"] = riskLevel;
                            obj["userResponseCount"] = userResponseCount;
                            obj["totalRiskLevel"] = totalRiskLevel;
                            tmpAnswerLevelArr.push(obj);
                        }
                    } else {
                        if (no === itemDataData.no) {
                            totalRiskLevel += itemDataData.totalRiskLevel;
                            riskName = itemDataData.riskName;
                            riskLevel = itemDataData.riskLevel;
                            userResponseCount += itemDataData.userResponseCount;
                            if ((answerLevelArr.length - 1) === index2) {
                                var obj = {};
                                obj["no"] = no;
                                obj["riskName"] = riskName;
                                obj["riskLevel"] = riskLevel;
                                obj["userResponseCount"] = userResponseCount;
                                obj["totalRiskLevel"] = totalRiskLevel;
                                tmpAnswerLevelArr.push(obj);
                            }
                        } else {
                            var obj = {};
                            obj["no"] = no;
                            obj["riskName"] = riskName;
                            obj["riskLevel"] = riskLevel;
                            obj["userResponseCount"] = userResponseCount;
                            obj["totalRiskLevel"] = totalRiskLevel;
                            tmpAnswerLevelArr.push(obj);
                            no = itemDataData.no;
                            totalRiskLevel = itemDataData.totalRiskLevel;
                            riskName = itemDataData.riskName;
                            riskLevel = itemDataData.riskLevel;
                            userResponseCount = itemDataData.userResponseCount;
                            if ((answerLevelArr.length - 1) === index2) {
                                var obj = {};
                                obj["no"] = no;
                                obj["riskName"] = riskName;
                                obj["riskLevel"] = riskLevel;
                                obj["userResponseCount"] = userResponseCount;
                                obj["totalRiskLevel"] = totalRiskLevel;
                                tmpAnswerLevelArr.push(obj);
                            }
                        }
                    }
                })

                //console.log(tmpAnswerLevelArr);
                tmpAnswerLevelArr = tmpAnswerLevelArr.sort(compareRiskNo);
                objAllCe["answerRiskLevelList"] = tmpAnswerLevelArr;
            } else {
                objAllOe["answers"] = tmpDataArray;
            }
            if (Object.keys(objAllCe).length === 0) {
                openEndedArray.push(objAllOe);
            }
            if (Object.keys(objAllOe).length === 0) {
                closeEndedArray.push(objAllCe);
            }
        });
        var obj = {}
        obj["totalQuestionWeight"] = tmpCOTotalQuestionWeight;
        obj["totalUsers"] = tmpCOTotalUsers;
        obj["totalAnswer1"] = tmpCOTotalAnswer1;
        obj["totalAnswer2"] = tmpCOTotalAnswer2;
        obj["totalAnswer3"] = tmpCOTotalAnswer3;
        obj["totalAnswer4"] = tmpCOTotalAnswer4;
        obj["totalAnswer5"] = tmpCOTotalAnswer5;


        console.log(closeEndedArray);
        console.log(obj);
        setCloseEndedData(closeEndedArray);
        setOpenEndedData(openEndedArray);
        setCloseEndedTotal(obj);

        setEvaluateAllOpen(true);
        setEvaluateAllScroll('paper');

    }

    function compareRiskNo(a, b) {
        var nameA = a.no; // ignore upper and lowercase
        var nameB = b.no; // ignore upper and lowercase

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // names must be equal
        return 0;
    }

    const renderTextId = (params) => {
        return (
            <span className={classes.fontZise10}>{params.row.id}</span>
        )
    }

    const renderTextRiskName = (params) => {
        return (
            <Tooltip title={params.row.riskName}>
                <span className={classes.fontZise10}>{params.row.riskName}</span>
            </Tooltip>
        )
    }

    const renderTextObjectiveName = (params) => {
        return (
            <Tooltip title={params.row.objectiveName}>
                <span className={classes.fontZise10}>{params.row.objectiveName}</span>
            </Tooltip>
        )
    }

    const renderTextComponentName = (params) => {
        return (
            <Tooltip title={params.row.componentName}>
                <span className={classes.fontZise10}>{params.row.componentName}</span>
            </Tooltip>
        )
    }
    const renderTextPrincipalName = (params) => {
        return (
            <Tooltip title={params.row.principalName}>
                <span className={classes.fontZise10}>{params.row.principalName}</span>
            </Tooltip>
        )
    }
    const renderTextQuestion = (params) => {
        return (
            <Tooltip title={params.row.question}>
                <span className={classes.fontZise10}>{params.row.question}</span>
            </Tooltip>
        )
    }

    const renderTextEntity = (params) => {
        return (
            <Tooltip title={params.row.entityName}>
                <span className={classes.fontZise10}>{params.row.entityName}</span>
            </Tooltip>
        )
    }

    const renderTextQuestionType = (params) => {
        return (
            <span className={classes.fontZise10}>{params.row.questionType}</span>
        )
    }
    const renderTextQuestionScore = (params) => {
        var riskLvlColor = getRiskLvlColor(params.row.questionScore)
        return (
            <span className={classes.fontZise10} style={{ color: riskLvlColor }}>{params.row.questionScore}</span>
        )
    }

    function getRiskLvlColor(lvlData) {
        var colorCode = ''
        databClr.forEach((data2) => {
            if (parseInt(data2.scoreNumber) === parseInt(lvlData)) {
                colorCode = data2.color;
            }
        })
        return colorCode;
    }



    const renderDetailsButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                        parseId(params.row.id, params.row.questionId, params.row.collectionId, params.row.question)
                    }}
                >
                    More Info
                </Button>
            </strong>
        )
    }

    const [quesDataCount, setQuesDataCount] = useState(0);
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [selectedQuesData, setSelectedQuesData] = useState([]);
    const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
    const [questionDetailScroll, setQuestionDetailScroll] = React.useState('paper');

    function parseId(no, questionId, collectionId, question) {
        var tmpArray = [];
        setQuesDataCount(no);
        setSelectedQuestion(question);
        allCollectionData.forEach((data) => {
            if (data.id === collectionId) {
                data.questions.forEach((item) => {
                    if (item.questionId === questionId) {
                        tmpArray.push(item);
                        setSelectedQuesData(tmpArray);
                    }
                });
            }
        });

        setQuestionDetailOpen(true);
        setQuestionDetailScroll('paper');

    }

    //   const renderEditButton = (params) => {
    //     return (
    //         <strong>
    //             <Button
    //                 variant="contained"
    //                 color="primary"
    //                 size="small"
    //                 onClick={() => {
    //                     editQuestion(params.row.questionId,params.row.collectionUniqueName,params.row.question)
    //                 }}
    //             >
    //                 Edit
    //             </Button>
    //         </strong>
    //     )
    // }


    // const renderEditButton = (params) => {
    //     return (
    //         <strong>
    //             <Button
    //                 variant="contained"
    //                 color="primary"
    //                 size="small"
    //                 onClick={() => {
    //                     editQuestion(params.row.questionId,params.row.collectionUniqueName)
    //                 }}
    //             >
    //                 Edit
    //             </Button>
    //         </strong>
    //     )
    // }

    const renderEditButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                        editQuestion(params.row.questionId, params.row.collectionUniqueName)
                    }}
                >
                    Edit
                </Button>
            </strong>
        )
    }
    function editQuestion(questionId, collectionUniqueName) {
        let a = document.createElement('a');
        a.target = '_self';
        a.href = '/questioPage/' + collectionUniqueName + "?questionid=" + questionId;
        a.click();
    }

    const columns = [
        { field: 'id', headerName: 'No', renderCell: renderTextId, resizable: true, width: 15 },
        { field: 'questionId', headerName: 'No', width: 15, hide: true },
        { field: 'collectionId', headerName: 'No', width: 15, hide: true },
        { field: 'collectionUniqueName', headerName: 'No', width: 15, hide: true },
        { field: 'riskName', headerName: 'Risk', renderCell: renderTextRiskName, resizable: true, cellClassName: 'super-app-theme--cell', width: 180 },
        { field: 'objectiveName', headerName: 'Objective', renderCell: renderTextObjectiveName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
        { field: 'componentName', headerName: 'Component', renderCell: renderTextComponentName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
        { field: 'principalName', headerName: 'Principal', renderCell: renderTextPrincipalName, resizable: true, cellClassName: 'super-app-theme--cell', width: 175 },
        { field: 'entityName', headerName: 'Entity', renderCell: renderTextEntity, cellClassName: 'super-app-theme--cell', resizable: true, width: 80 },
        { field: 'question', headerName: 'Question', renderCell: renderTextQuestion, cellClassName: 'super-app-theme--cell', resizable: true, width: 230 },
        { field: 'questionType', headerName: 'Type', renderCell: renderTextQuestionType, resizable: true, width: 80 },
        { field: 'questionScore', headerName: 'Score', renderCell: renderTextQuestionScore, resizable: true, width: 50 },
        {
            field: 'action',
            headerName: 'More Info',
            sortable: false,
            renderCell: renderDetailsButton,
            disableClickEventBubbling: true,

        }
        /*
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            renderCell: renderEditButton,
            disableClickEventBubbling: true,

        }
        */

    ];



    const [collectionIdFromEval, setCollectionIdFromEval] = useState('');
    const [questionIdFromEval, setQuestionIdFromEval] = useState('');
    const [questionNameFromEval, setQuestionNameFromEval] = useState('');

    const [openAmendQuestionFromEval, setOpenAmendQuestionFromEval] = useState(false);
    const [openAmendQuestionFromEvalScroll, setOpenAmendQuestionFromEvalScroll] = useState('paper');

    const questionDetailHandleClickClose = () => {
        setQuestionDetailOpen(false);
    };

    const evaluateAllHandleClickClose = () => {
        setEvaluateAllOpen(false);
    };

    // function editQuestion(questionId, collectionUniqueName, questionName){
    //     setCollectionIdFromEval(collectionUniqueName);
    //     setQuestionIdFromEval(questionId);
    //     setQuestionNameFromEval(questionName);
    //     setOpenAmendQuestionFromEval(true);
    //     setOpenAmendQuestionFromEvalScroll('paper');

    //       //let a= document.createElement('a');
    //       //a.target= '_self';
    //       //a.href= '/questioPage/' + collectionUniqueName + "?questionid=" + questionId;
    //       //a.click();
    //   }

    const collectionTableRowData = (
        <TableBody>
            {allCollectionData.map((collection, index) => {

                return (
                    <StyledTableRow key={index} >
                        <StyledTableCell component="th" scope="row"  style={{width:'90%'}}>
                            <div style={{ whiteSpace: 'normal' }} className='celltxt'>{collection.title}</div>
                        </StyledTableCell>

                        <StyledTableCell
                            align="right">
                            <AssessmentOutlinedIcon
                            onClick={e => setOpenCollectionDataFuction(true, collection.id, collection.title)}
                            style={{cursor: 'pointer'}}
                            />
                           
                                
                           
                        </StyledTableCell>
                    </StyledTableRow>
                )

            })}
        </TableBody>
    )


    return (
        <>
            <div>
                <div >
                    <Grid item xs={12}>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>

                                <TableContainer
                                    component={Paper}
                                    //sx={{ maxHeight: 200 }}
                                    style={{maxHeight:maxWidthTable}}
                                    
                                >
                                     <TableHead className={classes.commonSecondaryTableHead}>
                                             <TableRow>
                                             <StyledTableCell className='celltxthead' style={{ width: '90%' }}>Questionnaire</StyledTableCell>
                                                     <StyledTableCell align="right" className='celltxthead' style={{width: '10%'}}>Evaluate</StyledTableCell>
                                                     
                                            </TableRow>
                                     </TableHead>                     
                                    <Table aria-label="customized table" style={{ border: '1px solid #ddd' }}>
                                        {collectionTableRowData}
                                    </Table>
                                </TableContainer>

                            </Grid>
                            {/* {
                            allCollectionData.map((collection) => {
                                return (
                                    <Grid item xs={6}>
                                        <Card className={classes.collectionCardDBEval} style={{ marginTop: "7px" }}>
                                            <CardContent className={classes.collectionCardContentDB} style={{height: '90px', overflowY: 'scroll'}}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" className={classes.collectionCardTitleDBEval}>{collection.title}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" className={classes.collectionCardDetailsDB}>({"Status: " + collection.status + ", Q - " + collection.questions.length})</Typography>
                                                </Grid>
                                            </CardContent>
                                            <Divider light />
                                            <CardActions style={{justifyContent:'center', backgroundColor: '#bac5dd'}}>
                                                <Button variant="outlined" className={classes.btnBorderAshDB}
                                                    onClick={e => setOpenCollectionDataFuction(true, collection.id, collection.title)}
                                                    >
                                                    Evaluate
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                )
                            })
                        } */}
                        </Grid>
                    </Grid>
                </div>
            </div>


            {/** Risk Evaluate Options */}
            <Dialog
                maxWidth='md'
                open={riskEvalTypeOpen}
                onClose={riskEvalTypeHandleClickClose}
                scroll={riskEvalTypeScroll}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Select "All" or other Risk Type to Evaluate Risk <br></br>for {selectedCollectionEvaluateTitle}</DialogTitle>
                <DialogContent dividers={riskEvalTypeScroll === 'paper'}>
                    <DialogContentText
                        id="risk-eval-type-scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormLabel id="radio-buttons-group-label">Pick your Risk Type</FormLabel>
                            </Grid>
                            <Grid item xs={12} style={{ "marginTop": "-17px !important" }}>
                                <FormGroup>
                                    <FormControlLabel className={classes.marginleftEval} control={<Checkbox id={"chkRiskType0"} />} label="All" />
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Select a Risk Hierarchy"
                                                sx={{ width: '100%' }}
                                                select
                                                value={hierarchyId}
                                                onChange={handleChangeHierarchy}
                                            >
                                                {riskTypeData.map((item) => (
                                                    <MenuItem
                                                        value={item.collectionRiskId}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                        </FormControl>
                                    </Box>

                                    {
                                        rsType.map((risktypp) => {
                                            return (
                                                <RiskEval
                                                    key={risktypp.collectionRiskTypeId}
                                                    comment={risktypp}
                                                />
                                            )
                                        })
                                    }



                                    {/*mainRiskArr.map((item)=>{
                            return(
                                <FormControlLabel control={<Checkbox id={"chkRiskType" + item.riskTypeId}/>} label={item.name} />
                            )
                        })*/}
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    id="riskEvaluateBtn"
                                    className={classes.btnBorderRedEval}
                                    style={{ float: 'right' }}
                                    //onClick={()=>setOpenRiskEvalFuction(!openRiskEval)}
                                    onClick={() => setRiskTypeEvalFuction()}
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={riskEvalTypeHandleClickClose}>Cancel</Button>
                </DialogActions>
            </Dialog>



            <Dialog
                open={openCollectionData}
                onClose={false}
                scroll={openCollectionDataScroll}
                aria-labelledby="collection-data-dialog-title"
                aria-describedby="collection-data-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
                PaperComponent={PaperComponentCollectionData}
            >
                <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
                    <Typography variant="h6" style={{ fontSize: '25px !important' }}>{selectedCollectionEvaluateTitle} - <SavePageAs/>
                    </Typography>
                    <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '-25px', padding: '7px', marginTop: '-50px' }}>
                        <Tooltip title="Close"><Close onClick={() => setOpenCollectionData(false)} style={{ cursor: 'pointer' }} /></Tooltip>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={openCollectionDataScroll === 'paper'}>
                    <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginTop: '-17px', float: 'right' }}
                                    onClick={() => {
                                        evaluateAll()
                                    }}
                                >
                                    Evaluate All Questions
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2}>

                                    <Box
                                        sx={{
                                            height: 300,
                                            width: '100%',
                                            '& .super-app-theme--cell': {
                                                whiteSpace: 'normal !important',
                                                lineHeight: '1',
                                            },
                                            '& .super-app-theme--Open': {
                                                fontSize: '10px !important',
                                            },

                                        }}
                                    >





                                        <div style={{ height: 400, width: '100%', backgroundColor: '#ffffff', fontSize: '9px !important' }}>
                                            <DataGrid
                                                rows={rows}
                                                columns={columns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                getRowClassName={`super-app-theme--Open`}
                                                disableSelectionOnClick
                                            />
                                        </div>

                                    </Box>
                                </Grid>
                            </Grid>


                        </Grid>





                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCollectionData(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>

            {/** Collecton Amend  dialog */}


            {/** Question Details Show Dialog */}
            <Dialog
                maxWidth='xs'
                open={questionDetailOpen}
                onClose={questionDetailHandleClickClose}
                scroll={questionDetailScroll}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <Typography variant="h6" gutterBottom component="div">
                        Question No - {quesDataCount}
                    </Typography>
                    <Typography component="th" scope="row" style={{ width: '100%', fontSize: '10px !important' }}>
                        <span style={{ fontWeight: '550' }}>Question - </span>{selectedQuestion}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={questionDetailScroll === 'paper'}>
                    <DialogContentText
                        id="risk-eval-type-scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <Grid container spacing={2}>
                            {selectedQuesData.map((data) => {
                                var scoreColor = getRiskLvlColor(data.questionScore)
                                return (
                                    <Grid item xs={12}>
                                        <Box >
                                            <Table size="small" aria-label="purchases">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                                            <Typography variant="h6" gutterBottom component="div">
                                                                Detail Weightings
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                                            <Typography variant="h6" component="div">
                                                                Score
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <Table size="small" aria-label="purchases">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                                            Questionnaire weighting
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                                            <span style={{ color: scoreColor }}>{data.questionScore}</span>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            {data.questionType === "Closeended" && (
                                                <>
                                                    <Table size="small" aria-label="purchases">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                                                    <Typography variant="h6" gutterBottom component="div">
                                                                        Answers
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                                                    &nbsp;
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    <Table size="small" aria-label="purchases">
                                                        <TableBody>
                                                            {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                                                var riskLvlColor = getRiskLvlColor(item.riskLevel)
                                                                return (
                                                                    <>
                                                                        <TableRow>
                                                                            <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                                                                <span >{item.answerName}</span>
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                                                                <span style={{ color: riskLvlColor }}>{item.riskLevel}</span>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </>
                                            )}
                                            <Table size="small" aria-label="purchases">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                                            <Typography variant="h6" gutterBottom component="div">
                                                                Model Answer(s)
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                                            &nbsp;
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            {data.questionType === "Closeended" && (
                                                <Table size="small" aria-label="purchases">
                                                    <TableBody>
                                                        {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                                            var riskLvlColor = getRiskLvlColor(item.riskLevel)
                                                            if (item.expectedAnswerName != "") {
                                                                return (
                                                                    <>
                                                                        <TableRow>
                                                                            <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                                                                <span>
                                                                                    {item.answerName}
                                                                                </span>
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                                                                <span style={{ color: riskLvlColor }}>{item.expectedAnswerName === "Yes" || item.expectedAnswerName === "checked" ? item.riskLevel : item.expectedAnswerName}</span>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>
                                                                )
                                                            }
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            )}
                                            {data.questionType === "Openended" && (
                                                <Table size="small" aria-label="purchases">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                                                <span>
                                                                    {data.answers.answer}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                                                &nbsp;
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            )}
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={questionDetailHandleClickClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
            {/** End of Question Details Show Dialog*/}


            {/************************ */}

            <Dialog
                maxWidth='xl'
                fullWidth={true}
                open={evaluateAllOpen}
                onClose={evaluateAllHandleClickClose}
                scroll={evaluateAllScroll}
                PaperComponent={PaperComponent}
                aria-labelledby="evaluateAll-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <Typography variant="h6" style={{ textAlign: 'right', marginTop: '-22px', marginRight: '-24px' }}><Tooltip title="Close"><Close onClick={() => setEvaluateAllOpen(false)} style={{ cursor: 'pointer' }} /></Tooltip></Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom component="div">
                                Evaluation Results
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers={questionDetailScroll === 'paper'}>
                    <DialogContentText
                        id="risk-eval-type-scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Paper sx={{ overflow: 'hidden' }}>
                                    <TableContainer sx={{ maxHeight: 370 }}>
                                        <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell colSpan={5} style={{ textAlign: 'center', fontSize: '14px', borderBottomColor: 'transparent' }}>Questions</StyledTableCell>
                                                    <StyledTableCell colSpan={5} style={{ textAlign: 'center', fontSize: '14px' }}>Answers</StyledTableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <StyledTableCell style={{ verticalAlign: 'top', width: '4%' }}>No</StyledTableCell>
                                                    <StyledTableCell style={{ verticalAlign: 'top', width: '40%' }}>Question</StyledTableCell>
                                                    <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Weight</StyledTableCell>
                                                    <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>No of Responses</StyledTableCell>
                                                    <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Total Weight</StyledTableCell>
                                                    <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Heighly Negative <br></br>(-5)</StyledTableCell>
                                                    <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Negative <br></br><br></br>(-2)</StyledTableCell>
                                                    <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Somewhat Positive <br></br>(1)</StyledTableCell>
                                                    <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Positive <br></br><br></br>(4)</StyledTableCell>
                                                    <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Heighly Positive <br></br>(8)</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {closeEndedData.map((data, index) => {
                                                    return (
                                                        <>
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell component="th" scope="row" style={{ fontSize: '12px' }}>
                                                                    {data.id}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ fontSize: '12px' }}>{data.question}</StyledTableCell>
                                                                <StyledTableCell style={{ fontSize: '12px' }}>{data.questionWeigth}</StyledTableCell>
                                                                <StyledTableCell style={{ fontSize: '12px' }}>{data.noOfRespondedUsers}</StyledTableCell>
                                                                <StyledTableCell style={{ fontSize: '12px' }}>{data.totalQuestionWeigth}</StyledTableCell>
                                                                {data.answerRiskLevelList.map((item, index1) => {
                                                                    return (
                                                                        <>
                                                                            {index1 === 0 ?
                                                                                <StyledTableCell style={{ fontSize: '12px' }}>{item.totalRiskLevel === 0 ? '--' : item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel) / (-5 * data.noOfRespondedUsers)) + '%)'}</StyledTableCell>
                                                                                :
                                                                                <>
                                                                                    {index1 === 1 ?
                                                                                        <StyledTableCell style={{ fontSize: '12px' }}>{item.totalRiskLevel === 0 ? '--' : item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel) / (-2 * data.noOfRespondedUsers)) + '%)'}</StyledTableCell>
                                                                                        :
                                                                                        <>
                                                                                            {index1 === 2 ?
                                                                                                <StyledTableCell style={{ fontSize: '12px' }}>{item.totalRiskLevel === 0 ? '--' : item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel) / (1 * data.noOfRespondedUsers)) + '%)'}</StyledTableCell>
                                                                                                :
                                                                                                <>
                                                                                                    {index1 === 3 ?
                                                                                                        <StyledTableCell style={{ fontSize: '12px' }}>{item.totalRiskLevel === 0 ? '--' : item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel) / (4 * data.noOfRespondedUsers)) + '%)'}</StyledTableCell>
                                                                                                        :
                                                                                                        <>
                                                                                                            {index1 === 4 ?
                                                                                                                <StyledTableCell style={{ fontSize: '12px' }}>{item.totalRiskLevel === 0 ? '--' : item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel) / (8 * data.noOfRespondedUsers)) + '%)'}</StyledTableCell>
                                                                                                                :
                                                                                                                ''
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </>

                                                                                    }
                                                                                </>
                                                                            }

                                                                        </>
                                                                    )
                                                                })}


                                                            </StyledTableRow>


                                                        </>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <StyledTableRow key={1} style={{ backgroundColor: 'black', color: 'white' }}>
                                                <StyledTableCell align='right' colSpan={3} component="th" scope="row" style={{ color: 'white', fontWeight: '550', width: '51%' }}>
                                                    {'TOTAL'}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalUsers}</StyledTableCell>
                                                <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalQuestionWeight}</StyledTableCell>
                                                <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalAnswer1 === 0 ? '--' : closeEndedTotal.totalAnswer1 + '(' + Math.round((100 * closeEndedTotal.totalAnswer1) / (-5 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                                                <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalAnswer2 === 0 ? '--' : closeEndedTotal.totalAnswer2 + '(' + Math.round((100 * closeEndedTotal.totalAnswer2) / (-2 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                                                <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalAnswer3 === 0 ? '--' : closeEndedTotal.totalAnswer3 + '(' + Math.round((100 * closeEndedTotal.totalAnswer3) / (1 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                                                <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalAnswer4 === 0 ? '--' : closeEndedTotal.totalAnswer4 + '(' + Math.round((100 * closeEndedTotal.totalAnswer4) / (4 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                                                <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalAnswer5 === 0 ? '--' : closeEndedTotal.totalAnswer5 + '(' + Math.round((100 * closeEndedTotal.totalAnswer5) / (8 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>





                            </Grid>
                        </Grid>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={evaluateAllHandleClickClose}>Close</Button>
                </DialogActions>
            </Dialog>
            {/** End of Question Details Show Dialog*/}
        </>
    )
}

export default EvaluateRiskQuestionerComponent