import React, { useState } from 'react';
import {
    Typography,
    Button,
    Paper,
    Grid,
    Container,
    CssBaseline,
    FormControlLabel,
    Switch as MuiSwitch,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import axios from 'axios'
import CardContent from '@mui/material/CardContent';
import { CONFIG } from '../config'
import parseHtml from 'html-react-parser';
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';

const Purchase = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        getData()
    }, []);
    const [globalCentreId, setGlobalCentreId] = React.useState();
    const [loginUserId, setLoginUserId] = React.useState();
    const [loginUserName, setLoginUserName] = React.useState();

    function getData() {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        console.log(data);
        setGlobalCentreId(data.centreId);
        setLoginUserId(data.userId);
        setLoginUserName(data.name);
    }

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const methodId = queryParams.get('id');
    const method = queryParams.get('method');
    const price = queryParams.get('price');
    const duration = queryParams.get('duration');

    function handleConfirmPurchase () {
        var obj= {};
        obj["packageId"] = methodId;
        obj["duration"] = duration;
        obj["totalPrice"] = (parseFloat(price)*100) + "";
        obj["centreId"] = globalCentreId;
        obj["userId"] = loginUserId;
        obj["userName"] = loginUserName;
        var sendJson = JSON.stringify(obj);
        console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
        console.log(sendJson);
        var endPoint = '/packageRequest/save';
        
        axios(CONFIG.MAIN_URL+endPoint,{
          method: 'post',
          headers: { 
            'Content-Type': 'application/json',   
          },  
          data:sendJson
        })
        .then(function (response) {
            console.log("All Updated Order Details");
            console.log((response.data));
            var rtnData = response.data;
            const formData = new FormData();
            formData.append('PSPID', 'sW6MRbC5');
            formData.append('ORDERID', rtnData.orderId); 
            formData.append('AMOUNT', rtnData.totalPrice); 
            formData.append('CURRENCY', 'GBP'); 
            formData.append('LANGUAGE', 'en_US'); 
            formData.append('OPERATION', 'PAU'); 
            formData.append('TITLE', 'DMG Payment');
            formData.append('SHASIGN', rtnData.shasinString); 
    
            const form = document.createElement('form');
            form.method = 'post';
            form.action = 'https://mdepayments.epdq.co.uk/ncol/test/orderstandard_utf8.asp';
            form.id = 'formPayment';
            form.name = 'form1';
            for (const key of formData.keys()) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = formData.get(key);
                form.appendChild(input);
            }
            console.log(form);
            document.body.appendChild(form);
            form.submit();
        })
        .catch(function (error) {
          alert('Error in Updating order' );
          console.log(error);
        });
      


    }


    const [PageName, setPageName] = useState('Purchase Details')

    return (
        <>
            <RightBarDataForOtherPages PageName={PageName} />
            <div className='pageTopRC'>
                <Grid container spacing={2}>
                    <Grid container spacing={2} className='pageBodyStyleRC'>

                        <Grid item xs={12}>
                            <div role="presentation" onClick={handleClick}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" to="/login">
                                        <span className='breadcrumbFonts'>Home</span>
                                    </Link>

                                    <Typography color="text.primary"><span className='breadcrumbFonts'>Main menu - {PageName}</span></Typography>
                                </Breadcrumbs>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <a className='goBack' onClick={() => navigate(-1)}><ArrowBack /> Go Back</a>
                        </Grid>
                    </Grid>
                </Grid>
                <br></br>
                <h2>Your selected package details are:</h2>
                <p>Method: {method}</p>
                <p>Price: ${price}</p>
                <p>Duration: {duration}</p>

                <Grid item xs={12}>
                    <Card style={{ border: '1px solid #0000004a' }}>
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>Please confirm your package</span><br></br>
                            <Button onClick={() => handleConfirmPurchase()} variant="contained" color="primary" style={{ marginTop: '15px', textAlign: 'center' }}>Confirm</Button>
                        </CardContent>
                    </Card>
                </Grid>

            </div>
        </>
    )
}

export default Purchase
