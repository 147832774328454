import React from 'react'
import { CONFIG } from '../config';

const Home = ({centreId, userId, token}) => {
    console.log(token)

    

    var hrefcat = CONFIG.CATEGORY_URL+"?centreId="+ centreId+''+"&userId="+ userId+''+"&token="+ token+'';
    var hrefunit = "https://localhost:8021/loadUnit?centreId="+ centreId+''+"&userId="+ userId+''+"&token="+ token+'';
    var hrefupdate = "https://localhost:8080/loadProduct?centreId="+ centreId+''+"&userId="+ userId+''+"&token="+ token+'';
    var hrefcart = "https://localhost:8022/loadCart?centreId="+ centreId+''+"&userId="+ userId+''+"&token="+ token+'';

    return (
        
        <div>
            <h3 className="mb-5">Welcome to ORDER PROCESSING SYSTEM</h3>

             <div className="mb-4">
             <a href={hrefcat}>Save & Update Category</a>
            </div>
        

            <div className="mb-4">
            <a href={hrefunit} >Save & Update Unitfgggggggggggggggggggggggggggggggggggg</a>
            </div>

            <div className="mb-4">
            <a href={hrefupdate} >Save & Update Productsfgggggggggggggggggggggggg</a>
            </div>

            <div className="mb-4">
            <a href={hrefcart}>Save & Update Cartfgggggggggggggggggggggg</a>
            </div>
        
            
            </div>
    )
}

export default Home
