import React from 'react'
import '../Css/RightBarDataForOtherPages.css'
import { Grid } from "@material-ui/core";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';


const SavePageAs = () => {


    const Input = styled('input')({
        display: 'none',
    });

    const [openConfirm, setOpenConfirm] = React.useState(false);

    function confirmOpen() {
        setOpenConfirm(true);
    }
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };
    const [pageName, setPageName] = React.useState('');

    const pageNameOnChange = (event) => {
        setPageName(event.target.value)
    }


    function triggerSaveAs() {

        if (pageName === '') {
            alert("Please assign a file name")
            return;
        }
        setOpenConfirm(false);

        setTimeout(() => {
            const filename = pageName;
            var text = document.documentElement.innerHTML.replace("(Save This Page", "");
            text = text.replace("Create index.html Page)", "");
            const link = document.createElement('a');
            link.style.display = 'none';
            //link.href = window.URL.createObjectURL(new Blob([locationDir]));
            link.setAttribute('download', filename);
            const blob = new Blob([text], { type: 'text/html' });
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);

        }, 1000)

    }


    const handleFileChange = (event) => {
        const fileList = event.target.files;
        const fileNames = [];

        let indexContent = '<html><head><title>Demo Project</title></head><body>';


        indexContent += '<div><div style="display: flex; justify-content: space-between; height: 100px"><div style="font-weight: bold; margin-top: 40px;"><span style="text-align: center; ">Risk management system</span></div><div style="font-weight: bold; text-align: center;><span style="color:black;">Data management group</span><br><span style="font-size: 33px; margin-top: 25px;">Risk Evaluate Samples</span></div><div style="font-weight: bold; color:black;text-align: center; margin-top: 40px;">welcome</div></div><div><hr style="height: 1px; background-color: black; color:black;"></div><div><p></p></div></div>';

        var findFiles = false;
        var count = 1;
        for (let i = 0; i < fileList.length; i++) {
            let fileName = fileList[i].name
            if (fileName.endsWith('.html')) {
                findFiles = true;
                indexContent += '<a href="' + fileName + '">' + count + ") " + fileName + '</a><br>';
                count ++;
            }
        }
        indexContent += '</body></html>';
        if (findFiles) {
            const filename = "index.html";
            const text = indexContent;

            const link = document.createElement('a');
            link.style.display = 'none';
            //link.href = window.URL.createObjectURL(new Blob([locationDir]));
            link.setAttribute('download', filename);
            const blob = new Blob([text], { type: 'text/html' });
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);

        } else {
            alert("Please select .html files to create index.html file")
        }

    };

    return (
        <>
            <span className='savePageas' >
                <a onClick={() => confirmOpen()}>(Save This Page</a> | 
                &nbsp;
                <label htmlFor="contained-button-file">
                    <Input
                        id="contained-button-file"
                        multiple
                        type="file"
                        name="file"
                        onChange={handleFileChange}
                    />
                    <Tooltip title={'Click here to select html files to create index.html file'}><a style={{ cursor: 'pointer', color: 'black' }}> Create index.html Page)</a></Tooltip>
                </label>





                {/*<a onClick={() => createIndexOpen()}>Create index.html Page)</a>*/}
            </span>

            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}>
                <DialogTitle>
                    Save this Page with Page name (Eg: Page1.html)
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ textAlign: 'justify' }}>
                        Please note that the pages are normally saving in the Browser in default folder location called "Downloads". If you need to save the pages in a different folder location, you will have to change the Download folder in the Browser CommonSeriesSettings. <br></br> <u><b>In a Firefox browser: </b></u><br></br> {'1)'} Open the Firefox Browser and Click the menu button <br></br>{'2)'}Then select Settings and
                        In the General panel, find the Downloads section under Files and Applications.  <br></br>
                        {'3)'} Then Tick "Always ask where to save files" checkbox (Then you will be prompted and ask the location when saving the files )
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Page Name"
                                type="text"
                                value={pageName}
                                onChange={pageNameOnChange}
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>Cancel</Button>
                    <Button onClick={() => triggerSaveAs()}>Save Page</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default SavePageAs
