import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { CONFIG } from "./config";
import { reportTypeError } from 'ajv/dist/compile/validate/dataType';

const SaveHome = ({ idToken, middleName, name, lastName, poBox, addressLineOne, token, centreId, userRoleId, userId, userEmail, zoneinfo, city, country, phoneNumber, postalCode, upn }) => {

    const [names, setName] = useState(name);
    const [middleNames, setMiddleName] = useState(middleName);
    const [lastNames, setLastName] = useState(lastName);
    const [poBoxs, setPoBox] = useState(poBox);
    const [addressLineOnes, setAddressLineOne] = useState(city);

    useEffect(() => {
        saveUser();
        setSessionData();
        getData();
    }, [])

    function saveUser(e) {
        //alert(zoneinfo);
        var data = '{getAllUsers(centreId:\"' + centreId + '\",roleId:\"' + 'volunteer' + '\"){userId,name,middleName,lastName,poBox,addressLineOne,addressLineTwo,addressLineThree,city,state,postalCode,country,email,phoneNumber,mobileNumber,createdDate,isActive,lastLoginDate,centreId,firstLogin,department{departmentId,departmentName,isActive},userRole{roleId,isActive,entity{entityId,entityName}}}}';
        axios(CONFIG.MAIN_URL + '/graphql', {
            method: 'post',
            headers: {
                'Content-Type': 'text/plain',
            },
            data: data,
        })
            .then(function (response) {
                console.log("+++++++++++++++++All User List in Save User Section++++++++++++++++++");
                console.log(response);
                var userList = response.data.data.getAllUsers;
                console.log(userList);
                var foundUser = false;
                userList.forEach((item, index) => {
                    if (item.userId === userId) {
                        foundUser = true;
                    }
                })
                if (userRoleId === 'staff') {
                    foundUser = true;
                }
                if (!foundUser) {
                    //idToken, middleName, name, lastName, poBox, addressLineOne, token, 
                    //centreId, userRoleId, userId, userEmail, zoneinfo, city, country, phoneNumber, 
                    //postalCode, upn

                    let formdata = new FormData();
                    formdata.append('userId', userId);
                    formdata.append('schoolId', centreId);
                    formdata.append('name', name);
                    formdata.append('middleName', '');
                    formdata.append('lastName', lastName);
                    if (addressLineOne != undefined) {
                        formdata.append('addressLineOne', addressLineOne);
                    } else {
                        formdata.append('addressLineOne', 'AD1');
                    }
                    formdata.append('addressLineTwo', '');
                    formdata.append('addressLineThree', '');
                    if (city != undefined) {
                        formdata.append('city', city);
                    } else {
                        formdata.append('city', 'CITY1');
                    }
                    formdata.append('postalCode', postalCode);
                    if (country != undefined) {
                        formdata.append('country', country);
                    } else {
                        formdata.append('country', 'COUNTRY1');
                    }
                    formdata.append('email', userEmail);
                    formdata.append('phoneNumber', phoneNumber);
                    formdata.append('isActive', true);

                    axios(CONFIG.SERVICES_SYSTEM_URL + '/saveuserprofile', {
                        method: 'post',
                        contentType: false,
                        data: formdata,
                    })
                        .then(function (response) {
                            console.log("++++++User Save Return Data+++++++++++++");
                            console.log(response.data);
                            var msg = response.data;
                            if (msg == "This user is Already Exist") {
                            } else {
                                var mobileNo = null;
                                var emailAdd = null;
                                if (zoneinfo != '') {
                                    zoneinfo = zoneinfo.replace("protocolType", "\"protocolType\"");
                                    zoneinfo = zoneinfo.replace("protocolValue", "\"protocolValue\"");
                                    zoneinfo = zoneinfo.replace("formatType", "\"formatType\"");
                                    var jsonObj = JSON.parse(zoneinfo);

                                    if (jsonObj.protocolType == "email") {
                                        emailAdd = jsonObj.protocolValue;
                                    }
                                    if (jsonObj.protocolType == "mobileNumber") {
                                        mobileNo = phoneNumber;
                                    }
                                    let formdata1 = new FormData();
                                    formdata1.append('email', emailAdd);
                                    formdata1.append('mobileNumber', mobileNo);
                                    formdata1.append('formatType', jsonObj.formatType);
                                    formdata1.append('userId', userId);
                                    axios(CONFIG.SERVICES_SYSTEM_URL + '/saveuserendpoint', {
                                        method: 'post',
                                        contentType: false,
                                        data: formdata1,
                                    })
                                        .then(function (response) {
                                            console.log("+++++++Contact Point Potocol return");
                                            console.log(response.data);

                                        })
                                        .catch(function (error) {
                                            console.log(error + " Error return in saving contact point potocole");
                                        })

                                }
                                if (upn != undefined && upn != '') {
                                    var jsonObj = JSON.parse(upn);
                                    if (jsonObj.entityRole != undefined && jsonObj.entityRole != null) {
                                        var arr = jsonObj.entityRole;
                                        var entityRoleAll = "";
                                        var entityRoleStr = "{";
                                        arr.forEach((data) => {
                                            entityRoleAll += "\"" + data + "\"" + ',';
                                        })
                                        entityRoleAll = entityRoleAll.replace(/,\s*$/, "");
                                        entityRoleStr += "\"" + "entityRole" + "\"" + ":" + "[" + entityRoleAll + "]";
                                        entityRoleStr += "}";
                                        console.log(entityRoleStr);

                                        let formdata2 = new FormData();
                                        formdata2.append('userId', userId);
                                        formdata2.append('entityRole', entityRoleStr);
                                        formdata2.append('isApproved', true);
                                        formdata2.append('isActive', true);
                                        axios(CONFIG.SERVICES_SYSTEM_URL + '/saveuserrole', {
                                            method: 'post',
                                            contentType: false,
                                            data: formdata2,
                                        })
                                            .then(function (response) {
                                                var msg = response.data;
                                                console.log("+++++++++++++++++++msg Entity Data Save Rtn+++++++++++++++++++++");
                                                console.log(msg);
                                                if (msg === "Success") {
                                                    console.log("+++++++++++++++++++Entity Data Save Success+++++++++++++++++++++");
                                                } else {
                                                    console.log("+++++++++++++++++++Entity Data Save Fail+++++++++++++++++++++");
                                                }
                                            })
                                            .catch(function (error) {
                                                console.log(error + " Entity Data Saving Error");
                                            })
                                    }
                                } else {
                                    var data = '{allEntityList(centreId:\"' + centreId + '\",iamRole:\"volunteer\"){ entityDataList{entityName,entityId,isActive,createdBy,lastModifiedBy,centreId,basicEntity{basicEntityId,entityName,iamRole,forceIamRole}}}}';
                                    axios(CONFIG.MAIN_URL + '/graphql', {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'text/plain',
                                        },
                                        data: data,
                                    })
                                        .then(function (response) {
                                            var entityList = response.data.data.allEntityList.entityDataList;
                                            console.log("All entity List");
                                            console.log(entityList);
                                            var entityRoleAll = "";
                                            var entityRoleStr = "{";
                                            entityList.forEach((item, index) => {
                                                if (item.isActive && item.centreId === centreId) {
                                                    entityRoleAll += "\"" + item.entityId + "\"" + ',';
                                                }
                                            })
                                            entityRoleAll = entityRoleAll.replace(/,\s*$/, "");
                                            entityRoleStr += "\"" + "entityRole" + "\"" + ":" + "[" + entityRoleAll + "]";
                                            entityRoleStr += "}";
                                            console.log(entityRoleStr);

                                            let formdata2 = new FormData();
                                            formdata2.append('userId', userId);
                                            formdata2.append('entityRole', entityRoleStr);
                                            formdata2.append('isApproved', true);
                                            formdata2.append('isActive', true);
                                            axios(CONFIG.SERVICES_SYSTEM_URL + '/saveuserrole', {
                                                method: 'post',
                                                contentType: false,
                                                data: formdata2,
                                            })
                                                .then(function (response) {
                                                    var msg = response.data;
                                                    console.log("+++++++++++++++++++msg Entity Data Save Rtn+++++++++++++++++++++");
                                                    console.log(msg);
                                                    if (msg === "Success") {
                                                        console.log("+++++++++++++++++++Entity Data Save Success+++++++++++++++++++++");
                                                    } else {
                                                        console.log("+++++++++++++++++++Entity Data Save Fail+++++++++++++++++++++");
                                                    }
                                                })
                                                .catch(function (error) {
                                                    console.log(error + " Entity Data Saving Error");
                                                })
                                        })
                                        .catch(function (error) {
                                            console.log("Entity Loding error" + error);
                                        });

                                }

                                axios(CONFIG.MAIN_URL + `/trialCentreSetup/filter?centreId=` + centreId, {
                                    method: 'get',
                                })
                                .then(function (response) {
                                    console.log("++++Trial Centre setup In Save Home  ++++++++++++++++++++++++++++");
                                      console.log(response.data);
                                      var trialDays = 0;
                                      response.data.forEach((item, index) => {
                                        if (index === 0) {
                                          trialDays = item.noOfTrialDays
                                        }
                                      })
                                      if (trialDays > 0) {
                                        var obj = {};
                                    obj["centreId"] = centreId;
                                    obj["userCode"] = userId;
                                    obj["firstName"] = name;
                                    obj["lastName"] = lastName;
                                    obj["userMailAddress"] = userEmail;

                                    var arrT = [];
                                    var obj1 = {};
                                    obj1["noOfIncreaseTrialDays"] = trialDays;
                                    arrT.push(obj1);

                                    obj["trialDays"] = arrT;

                                    let sendJson = JSON.stringify(obj);
                                    console.log(sendJson);
                                    var urlEndPoint = '/trialUsers/create';
                                    axios(CONFIG.MAIN_URL + urlEndPoint, {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        data: sendJson,
                                    })
                                    .then(function (resp) {
                                        console.log("++++++++++++++++++Trial User Created  Response+++++++++++++++++++");
                                        console.log(resp.data);
                                    })
                                    .catch(function (error) {
                                        console.log("++++++++++++++++++Trial User Created  Error+++++++++++++++++++");
                                        console.log(error);
                                    });
                            
                                      }
                                    })
                                    .catch(function (error) {
                                      console.log(error);
                                    });







                                /*
                                if (CONFIG.TRIAL_CENTRE_ID === centreId){
                                    var obj = {};
                                    obj["centreId"] = centreId;
                                    obj["userCode"] = userId;
                                    obj["firstName"] = name;
                                    obj["lastName"] = lastName;
                                    obj["userMailAddress"] = userEmail;
                                    let sendJson = JSON.stringify(obj);
                                    console.log(sendJson);
                                    var urlEndPoint = '/trialUsers/create';
                                    axios(CONFIG.MAIN_URL + urlEndPoint, {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        data: sendJson,
                                    })
                                    .then(function (resp) {
                                        console.log("++++++++++++++++++Trial User Created  Response+++++++++++++++++++");
                                        console.log(resp.data);
                                    })
                                    .catch(function (error) {
                                        console.log("++++++++++++++++++Trial User Created  Error+++++++++++++++++++");
                                        console.log(error);
                                    });
                            

                                }
                                */

                            }

                        })
                        .catch(function (error) {
                            console.log(error + " User Save Error");
                        })
                }
            })
            .catch(function (error) {
                console.log("Error Loding User List" + error);
            });
    }


    function setSessionData() {
        let obj = { centreId: centreId, userRoleId: userRoleId, name: name, userId: userId, userEmail: userEmail }
        sessionStorage.setItem('sessionstorage', JSON.stringify(obj));
    }

    function getData() {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        console.log(data)
    }

    console.log(name)

    return (
        <div>
            <form onClick={saveUser}>


                <input value={middleNames} id="middleName" name="middleName"
                    type="hidden" onChange={(e) => setMiddleName(e.target.value)} />

                <input value={names} id="name" name="name"
                    type="hidden" onChange={(e) => setName(e.target.value)} />

                <input value={lastNames} type="hidden" id="lastName" name="lastName"
                    onChange={(e) => setLastName(e.target.value)} />


                <input value={poBoxs} id="poBox" name="poBox"
                    type="hidden" onChange={(e) => setPoBox(e.target.value)} />

                <input value={addressLineOnes} id="addressLineOne" name="addressLineOne"
                    type="hidden" onChange={(e) => setAddressLineOne(e.target.value)} />

            </form>
        </div>
    )
}

export default SaveHome
