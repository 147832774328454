import React, { useEffect, useState, useRef } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import CardContent from '@mui/material/CardContent';
import { CONFIG } from '../config'
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info, KeyboardReturnOutlined } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import '../Css/Slider.css';
import Draggable from 'react-draggable';
import useStyle from '../Css/MainCss';
import Delete from '@mui/icons-material/Delete';
import { Editor } from '@tinymce/tinymce-react';
import Typography from "@mui/material/Typography";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { Alert } from 'bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import parseHtml from 'html-react-parser';




function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  root: {
    position: 'relative',
  },
  cardRiskManage: {
    width: '100%',
    border: '1px solid black'
  },
  tableTrStyle: {
    height: '19px !important'
  },
  mousePointer: {
    cursor: 'pointer'
  },
  stdMainCardHeader: {
    backgroundColor: '#4C7EE0 !important',
    fontSize: '13px !important',
    height: '50px',
    padding: '0px',
    marginTop: '-7px',
    color:'white'
  },
  addrisktypebtn: {
    marginLeft: '0px !important',
    border: '1px solid #4C7EE0 !important',
    color: '#4C7EE0 !important',
    padding: '5px 10px !important',
    height: '40px !important',
  },

  btnBorderpurple: {
    border: '1px solid #9155FD !important',
    color: '#9155FD !important',
    padding: '5px 10px !important',
    height: '40px !important',
  },
  stdCard: {
    height: '100%',
    border: '1px solid black'
  },
  scrollableDivToStandard: {
    height: '350px',
    overflow: 'auto',
    width: '100%',
  },

}));


const Pricing = ({tinyMCETemplateList}) => {
  const classe = useStyle();
  const classes = useStyles();
  const Input = styled('input')({
    display: 'none',
  });

  const editorRef = useRef(null);
  const editorRef2 = useRef(null);
  const [golobalCentreId, setGolobalCentreId] = React.useState('');

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    getData();
  }, [])


  function getData() {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId);
    getAllPricingData();
  }

  const [pricingPageAllData, setPricingPageAllData] = useState({})

  const [pricingData, setPricingData] = useState([])

  const [pricingPageHeader, setPricingPageHeader] = useState('')
  // console.log(selectedFile)
  const [saveBtnName, setSaveBtnName] = useState("update");
  const [createheaderInfoMessage, setCreateHeaderInfoMessage] = useState("Create / Edit Pricing ");
  const [packageName, setPackageName] = useState('')
  const [annualFee, setAnnualFee] = useState('')
  const [monthlyFee, setMonthlyFee] = useState('')
  const [pricingDescription, setPricingDescription] = useState('')
  const [headerContent, setHeaderContent] = useState('')
  const [editPricingDataId, setEditPricingDataId] = useState('')
  const [selectedCurrency, setSelectedCurrency] = useState('$');

  const [selectedPackageName, setSelectedPackageName] = useState('');

  const [mainArrayId, setMainArrayId] = useState('')

  const [addUpdateButton, setAddUpdateButton] = useState('SAVE NEW')

  const [showEditButton, setShowEditButton] = useState(true)

  const [unChangedpricingData, setUnChangedpricingData] = useState([])

  function getAllPricingData() {
    axios(CONFIG.MAIN_URL + `/pricing/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
    .then(function (response) {
        console.log("All package Returns");
        console.log(response.data[0]);
        console.log(response.data[0].pricingMethods);
        setPricingData(response.data[0].pricingMethods);
        setUnChangedpricingData(response.data[0].pricingMethods)
        setPricingPageAllData(response.data[0])
        setMainArrayId(response.data[0].id)
        setHeaderContent(response.data[0].pricingPageHeader)
        editorRef.current.setContent(response.data[0].pricingPageHeader);

    })
    .catch(function (error) {
        console.log(error);
    });
  }


  const packageSelecter = (event) => {

    setSelectedPackageName(event.target.value);

  }


  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };


  const packageNameChange = (event) => {
    setPackageName(event.target.value);
  }

  const annualFeeChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setAnnualFee(value);
  }

  const monthlyFeeChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setMonthlyFee(value);
  }




  function deletepricingData1(){

    const packageNameToSave = selectedPackageName;
    const updatedData = pricingData.filter(item => item.method !== packageNameToSave);
    console.log(updatedData);

    var obj= {};

    obj["id"] = mainArrayId;
  
    obj["pricingPageHeader"] = headerContent;

    obj["pricingMethods"] = updatedData; 

      var sendJson = JSON.stringify(obj);
      console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
      console.log(sendJson);

      
      const userConfirmed = window.confirm("Do you want to delete ", packageNameToSave);

      if (userConfirmed) {
        axios(CONFIG.MAIN_URL+`/pricing/update`,{
          method: 'post',
          headers: { 
            'Content-Type': 'application/json',   
          },  
          data:sendJson
        })
        .then(res =>  { 
          alert('Successfully deleted');
          //clearFuction();
          getAllPricingData();
          clearFuctionPackages()
        })
        .catch(function (error) {
          alert('Error Deleting' );
          console.log(error);
        });
      } else {

      }
    setPricingData(updatedData)
    clearFuctionPackages()

  }

  function savePricingFunction(){
    console.log(mainArrayId)
    var desc = '';
    if (editorRef.current) {
      desc = editorRef.current.getContent();
    }
    if (desc === ''){
      alert("Please Header Content");
      return;
    }

    var obj= {};

      obj["id"] = mainArrayId;
    
      obj["pricingPageHeader"] = desc;

      obj["pricingMethods"] = unChangedpricingData; 

    var sendJson = JSON.stringify(obj);
    console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
    console.log(sendJson);
    var endPoint = '/pricing/save';
    if (editPricingDataId != ''){
      endPoint = '/pricing/update';
    }
    
    
    axios(CONFIG.MAIN_URL+`/pricing/update`,{
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',   
      },  
      data:sendJson
    })
    .then(res =>  { 
      alert('Successfully Updated Header Content');
      //clearFuction();
      getAllPricingData();
    })
    .catch(function (error) {
      alert('Error Updating the Pricing' );
      console.log(error);
    });
  }

  function clearFuction(){
    setSaveBtnName("update");
  }

  

  function packageEdit(){
    pricingData.map((data)=> {
      if (data.method === selectedPackageName) {
        setAddUpdateButton('update')
        setEditPricingDataId(data.id);
        setSelectedPackageName(data.method)
        setPackageName(data.method);
        setAnnualFee(data.anualFee);
        setMonthlyFee(data.monthlyFee);
        setPricingDescription(data.pricingDetails);
        setSelectedCurrency(data.currency)
        editorRef2.current.setContent(data.pricingDetails);
        setSaveBtnName("Update");

        setCreateHeaderInfoMessage("Edit/Create Package Pricing");
        
      }
    })
  }


  function clearFuctionPackages(){
    setAnnualFee('')
    setMonthlyFee('')
    setPricingDescription('')
    //setHeaderContent('')
    setSelectedCurrency('$')
    editorRef2.current.setContent('');
    setSelectedPackageName('')
    setAddUpdateButton('SAVE NEW')
  }
  
  function addpackages(){

    var prev = true;

    const packageNameToSave = selectedPackageName;
    pricingData.map((data)=> {
      if (data.method === packageNameToSave) {
        //alert('Package already exists:');
        prev = false
        return;
      }
    })

    if (prev) {

      if ( monthlyFee === '' && annualFee === ''){
        alert("Annual price and Monthly both not be empty");
        return;
      }
  
      var desc = '';
      if (editorRef2.current) {
        desc = editorRef2.current.getContent();
      }
      if (desc === ''){
        alert("Please enter Pricing Description");
        return;
      }
      var obj = {}
      obj["method"] = selectedPackageName;
      obj["currency"] = selectedCurrency;
      obj["monthlyFee"] = monthlyFee;
      obj["anualFee"] = annualFee;
      obj["pricingDetails"] = desc;
      pricingData.push(obj)

      var obj= {};

      obj["id"] = mainArrayId;
    
      obj["pricingPageHeader"] = headerContent;
  
      obj["pricingMethods"] = pricingData; 
  
        var sendJson = JSON.stringify(obj);
        console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
        console.log(sendJson);
        var endPoint = '/pricing/save';
        if (editPricingDataId != ''){
          endPoint = '/pricing/update';
        }
        
        axios(CONFIG.MAIN_URL+`/pricing/update`,{
          method: 'post',
          headers: { 
            'Content-Type': 'application/json',   
          },  
          data:sendJson
        })
        .then(res =>  { 
     
          alert('Successfully Saved Package Details');
          //clearFuction();
          getAllPricingData();
        })
        .catch(function (error) {
          alert('Error Updating the Pricing' );
          console.log(error);
        });
  
      console.log(pricingData)
      
      clearFuctionPackages()
    }else{

      const updatedData = pricingData.filter(item => item.method !== packageNameToSave);
      console.log(updatedData);
      pricingData.map((data)=> {
        if (data.method === packageNameToSave) {

          var desc = '';
          if (editorRef2.current) {
            desc = editorRef2.current.getContent();
          }
          if (desc === ''){
            alert("Please enter Package Description");
            return;
          }

          var obj = {}
          obj["id"] = editPricingDataId;
          obj["method"] = packageNameToSave;
          obj["currency"] = selectedCurrency;
          obj["monthlyFee"] = monthlyFee;
          obj["anualFee"] = annualFee;
          obj["pricingDetails"] = desc;

          updatedData.push(obj)
        }
      })

      console.log(updatedData)
      setPricingData(updatedData)

      var obj= {};

      obj["id"] = mainArrayId;
    
      obj["pricingPageHeader"] = headerContent;
  
      obj["pricingMethods"] = updatedData; 
  
        var sendJson = JSON.stringify(obj);
        console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
        console.log(sendJson);
        var endPoint = '/pricing/save';
        if (editPricingDataId != ''){
          endPoint = '/pricing/update';
        }
        
        axios(CONFIG.MAIN_URL+`/pricing/update`,{
          method: 'post',
          headers: { 
            'Content-Type': 'application/json',   
          },  
          data:sendJson
        })
        .then(res =>  { 
          alert('Successfully Updated Pricing Details');
          //clearFuction();
          getAllPricingData();
        })
        .catch(function (error) {
          alert('Error Updating the Pricing' );
          console.log(error);
        });
      clearFuctionPackages()


    }

  }

  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  function imagePreviw(url) {
        var str = "<img src=\"" + url + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      
  } 



  return (
    <>
        <div>
            <Grid >
                <Grid container spacing={2}>
                <>
                <Grid item xs={12} >
                    <Card className={classes.cardRiskManage} >
                        <CardContent>
                        <Card >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                        <Info />
                                        </Avatar>
                                    }
                                    className={classe.commonMainTableHead}
                                    title={"Page Content"}
                                />
                                <CardContent>
                                    <Grid container spacing={2}>

                        <Grid item xs={11}>
                                            <Editor
                                                apiKey='ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj'
                                                tinymceScriptSrc={'https://cdn.tiny.cloud/1/ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj/tinymce/6/tinymce.min.js'}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                initialValue={headerContent}
                                                init={{
                                                  referrer_policy: 'origin',  
                                                  height: 300,
                                                    menubar: true,
                                                    placeholder: 'Page Header Content',
                                                    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media mediaembed template codesample table advtable charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help charmap quickbars emoticons casechange formatpainter pageembed hr fullpage advcode export permanentpen powerpaste',
                                                    imagetools_cors_hosts: ['picsum.photos'],
                                                    menubar: 'file edit view insert format tools table help',
                                                    toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | casechange forecolor backcolor formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview print | pageembed insertfile image media template tokens link anchor codesample | ltr rtl hr fullpage export permanentpen configurepermanentpen',
                                                    //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                                                    export_image_proxy: 'proxy.php',
                                                    toolbar_sticky: true,
                                                    autosave_ask_before_unload: true,
                                                    autosave_interval: '30s',
                                                    autosave_prefix: '{path}{query}-{id}-',
                                                    autosave_restore_when_empty: false,
                                                    autosave_retention: '2m',
                                                    setup: (editor) => {
                                                      editor.on('init', function (e) {
                                                                 var content = unescape(headerContent);
                                                                 editor.setContent(content);
                                                                 editor.dom.bind(editor.getBody(), 'click', function (e) {
                                                                  var target = e.target;
                                                          
                                                                  // Check if the clicked element is an image
                                                                  if (target.nodeName.toLowerCase() === 'img') {
                                                                    // Your onclick function logic here
                                                                    imagePreviw(target.src);  // Replace this with your actual onclick function
                                                                  }
                                                                });
                                                      });
                                                  },
                                          
                                          
                                                  image_title: true,
                                                  /* enable automatic uploads of images represented by blob or data URIs*/
                                                  automatic_uploads: true,
                                                  /*Here we add custom filepicker only to Image dialog*/
                                                  file_picker_types: 'image',
                                          
                                                  /* and here's our custom image picker*/
                                                  file_picker_callback: function (cb, value, meta) {
                                                      var input = document.createElement('input');
                                                      input.setAttribute('type', 'file');
                                                      input.setAttribute('accept', 'image/*');
                                          
                                                      input.onchange = function () {
                                                          var file = this.files[0];
                                                          var reader = new FileReader();
                                          
                                                          reader.onload = function () {
                                                              /*
                                                              Note: Now we need to register the blob in TinyMCEs image blob
                                                              registry. In the next release this part hopefully won't be
                                                              necessary, as we are looking to handle it internally.
                                                              */
                                                              var id = 'blobid' + (new Date()).getTime();
                                                              
                                                              var blobCache = editorRef.current.editorUpload.blobCache;
                                                              //var blobCache = editorRef.current.activeEditor.editorUpload.blobCache;
                                                              var base64 = reader.result.split(',')[1];
                                          
                                                              var blobInfo = blobCache.create(id, file, base64);
                                                              blobCache.add(blobInfo);
                                          
                                                              /* call the callback and populate the Title field with the file name */
                                                              cb(blobInfo.blobUri(), {title: file.name});
                                                          };
                                          
                                                          reader.readAsDataURL(file);
                                                      };
                                          
                                                      input.click();
                                                  },
                                          
                                                  images_upload_handler: function (blobInfo, success, failure) {
                                                   
                                                  },
                                          
                                                  templates : tinyMCETemplateList,
                                                  
                                                  referrer_policy: 'origin',
                                          
                                                    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                                                    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                                                    image_caption: true,
                                                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                                    noneditable_noneditable_class: 'mceNonEditable',
                                                    toolbar_mode: 'sliding',
                                                    contextmenu: 'link image imagetools table',
                                                    //content_css: useDarkMode ? 'dark' : 'default',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                             



                                                
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1} >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ marginTop: '10px', height: '46px',width:'100%' }}
                                                onClick={() => {
                                                    savePricingFunction();
                                                }} 
                                                >
                                                {saveBtnName}
                                            </Button>&nbsp;&nbsp;
                                            {/* <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ marginTop: '10px', height: '46px',width:'100%' }}
                                                onClick={() => {
                                                    clearFuction()
                                                }}
                                                >
                                                Clear
                                            </Button> */}
                                        </Grid>
  
                            <Card >
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                        <Info />
                                        </Avatar>
                                    }
                                    className={classe.commonMainTableHead}
                                    title={createheaderInfoMessage}
                                />
                                <CardContent>
                                    <Grid container spacing={2}>


                                        <Grid item xs={12}>

                                        <Box display="flex" alignItems="center">
                                                <Grid item xs={3}>
                                                    <Typography
                                                    style={{ marginRight: "8px", fontWeight: 400 }}
                                                    >
                                                    Package Name:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                <Box display="flex" alignItems="center" style={{gap:'5px'}}>
                                                    {/* <TextField
                                                    id="trailDays"
                                                    label="Ex: Premium"
                                                    variant="outlined"
                                                    size="small"
                                                    value={packageName}
                                                    onChange={packageNameChange}
                                                    style={{width:'400px'}}

                                                    /> */}

                                                      <Autocomplete
                                                        id="package-name"
                                                        options={pricingData.map((option) => option.method)}
                                                        value={selectedPackageName}
                                                        onChange={(event, newValue) => {
                                                          setSelectedPackageName(newValue);
                                                          
                                                        }}
                                                        freeSolo
                                                        renderInput={(params) => (
                                                          <TextField
                                                            {...params}
                                                            label="Ex: Premium"
                                                            variant="outlined"
                                                            size="small"
                                                            style={{ width: '400px' }}
                                                            onChange={(event) => packageSelecter(event)}
                                                          />
                                                        )}
                                                      />


                                          <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{  height: '36px' }}
                                                onClick={() => {
                                                    addpackages()
                                                }}
                                                >
                                                {addUpdateButton}
                                            </Button>
                                            {showEditButton ? 
                                              <>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ height: '36px' }}
                                                onClick={() => {
                                                    packageEdit()
                                                }}
                                                >
                                              EDIT
                                            </Button>
                                              </>  
                                              :''
                                          }
                                            

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{height: '36px' }}
                                                onClick={() => {
                                                  deletepricingData1()
                                                }}
                                                >
                                                DELETE
                                            </Button>

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{height: '36px' }}
                                                onClick={() => {
                                                    clearFuctionPackages()
                                                }}
                                                >
                                                CLEAR
                                            </Button>
                                                </Box>
                                                </Grid>

                                                </Box>


                                                <Box display="flex" alignItems="center" style={{marginTop:'20px'}}>
                                                <Grid item xs={3}>
                                                    <Typography
                                                    style={{ marginRight: "8px", fontWeight: 400 }}
                                                    >
                                                    Pricing Annual/Monthly:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                <Box display="flex" alignItems="center" >
                                                <Typography
                                                    style={{ marginRight: "8px", fontWeight: 400 }}
                                                    >
                                                    Currency:
                                                    </Typography>
                                                <FormControl>
                                                    <Select
                                                        //label="Currency"
                                                        value={selectedCurrency}
                                                        onChange={handleCurrencyChange}
                                                        style={{width:'75px'}}
                                                        
                                                        variant="outlined"
                                                        size="small"
                                                    >
                                                        <MenuItem value="$"> USD</MenuItem>
                                                        <MenuItem value="€"> EUR</MenuItem>
                                                        <MenuItem value="¥">JPY</MenuItem>
                                                        {/* Add more currencies as needed */}
                                                    </Select>
                                                    </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2}>
                                                <Box display="flex" alignItems="center" >
                                                <Typography
                                                    style={{ marginRight: "8px", fontWeight: 400 }}
                                                    >
                                                    Annual:
                                                    </Typography>
                                                    <TextField
                                                    id="trailDays"
                                                    label=""
                                                    variant="outlined"
                                                    size="small"
                                                    value={annualFee}
                                                    onChange={annualFeeChange}
                                                    style={{width:'100px'}}

                                                    />
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={3}>
                                                <Box display="flex" alignItems="center">
                                                <Typography
                                                    style={{  fontWeight: 400 , marginLeft:'30px !important'}}
                                                    >
                                                    Monthly:&nbsp;
                                                    </Typography>
                                                    <TextField
                                                    id="trailDays"
                                                    label=""
                                                    variant="outlined"
                                                    size="small"
                                                    value={monthlyFee}
                                                    onChange={monthlyFeeChange}
                                                    style={{width:'100px'}}

                                                    />
                                                    </Box>  
                                                </Grid>
                                                </Box>


                                        </Grid>
                                        
                                        <Grid item xs={12}>
                                            <Editor
                                                apiKey='ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj'
                                                tinymceScriptSrc={'https://cdn.tiny.cloud/1/ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj/tinymce/6/tinymce.min.js'}
                                                onInit={(evt, editor) => editorRef2.current = editor}
                                                initialValue={pricingDescription}
                                                init={{
                                                  referrer_policy: 'origin',  
                                                  height: 300,
                                                    menubar: true,
                                                    placeholder: 'Pricing Description',
                                                    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media mediaembed template codesample table advtable charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help charmap quickbars emoticons casechange formatpainter pageembed hr fullpage advcode export permanentpen powerpaste',
                                                    imagetools_cors_hosts: ['picsum.photos'],
                                                    menubar: 'file edit view insert format tools table help',
                                                    toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | casechange forecolor backcolor formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview print | pageembed insertfile image media template tokens link anchor codesample | ltr rtl hr fullpage export permanentpen configurepermanentpen',
                                                    //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                                                    export_image_proxy: 'proxy.php',
                                                    toolbar_sticky: true,
                                                    autosave_ask_before_unload: true,
                                                    autosave_interval: '30s',
                                                    autosave_prefix: '{path}{query}-{id}-',
                                                    autosave_restore_when_empty: false,
                                                    autosave_retention: '2m',
                                                    setup: (editor) => {
                                                      editor.on('init', function (e) {
                                                                 var content = unescape(pricingDescription);
                                                                 editor.setContent(content);
                                                      });
                                                  },
                                          
                                          
                                                  image_title: true,
                                                  /* enable automatic uploads of images represented by blob or data URIs*/
                                                  automatic_uploads: true,
                                                  /*Here we add custom filepicker only to Image dialog*/
                                                  file_picker_types: 'image',
                                          
                                                  /* and here's our custom image picker*/
                                                  file_picker_callback: function (cb, value, meta) {
                                                      var input = document.createElement('input');
                                                      input.setAttribute('type', 'file');
                                                      input.setAttribute('accept', 'image/*');
                                          
                                                      input.onchange = function () {
                                                          var file = this.files[0];
                                                          var reader = new FileReader();
                                          
                                                          reader.onload = function () {
                                                              /*
                                                              Note: Now we need to register the blob in TinyMCEs image blob
                                                              registry. In the next release this part hopefully won't be
                                                              necessary, as we are looking to handle it internally.
                                                              */
                                                              var id = 'blobid' + (new Date()).getTime();
                                                              
                                                              var blobCache = editorRef2.current.editorUpload.blobCache;
                                                              //var blobCache = editorRef.current.activeEditor.editorUpload.blobCache;
                                                              var base64 = reader.result.split(',')[1];
                                          
                                                              var blobInfo = blobCache.create(id, file, base64);
                                                              blobCache.add(blobInfo);
                                          
                                                              /* call the callback and populate the Title field with the file name */
                                                              cb(blobInfo.blobUri(), {title: file.name});
                                                          };
                                          
                                                          reader.readAsDataURL(file);
                                                      };
                                          
                                                      input.click();
                                                  },
                                          
                                                  images_upload_handler: function (blobInfo, success, failure) {
                                                    /*  
                                                    var xhr, formData;
                                          
                                                      xhr = new XMLHttpRequest();
                                                      xhr.withCredentials = false;
                                                      xhr.open('POST', 'actionContent/getImage');
                                                      xhr.onerror = function() {
                                                          //$('#wait').hide();
                                                      };
                                                      xhr.onload = function () {
                                                          var json;
                                          
                                                          if (xhr.status != 200) {
                                                              failure('HTTP Error: ' + xhr.status);
                                                              return;
                                                          }
                                                          console.log()
                                                          json = xhr.responseText;
                                                          console.log(json);
                                                          success(json);
                                                          //$('#wait').hide();
                                                      };
                                          
                                                      formData = new FormData();
                                                      formData.append('file', blobInfo.blob(), blobInfo.filename());
                                                      //$('#wait').show();
                                                      xhr.send(formData);
                                                      */
                                                  },
                                          
                                                  templates : tinyMCETemplateList,
                                                  /*
                                                  templates : [
                                                    {
                                                      title: 'Date modified example',
                                                      description: 'Adds a timestamp indicating the last time the document modified.',
                                                      content: '<p>Last Modified: This will be replaced with the date modified.</time></p>'
                                                    },
                                                    {
                                                      title: 'Replace values example',
                                                      description: 'These values will be replaced when the template is inserted into the editor content.',
                                                      content: '<p>Name: StaffID: </p>'
                                                    }
                                                  ],
                                                  */
                                                  referrer_policy: 'origin',
                                          
                                                    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                                                    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                                                    image_caption: true,
                                                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                                    noneditable_noneditable_class: 'mceNonEditable',
                                                    toolbar_mode: 'sliding',
                                                    contextmenu: 'link image imagetools table',
                                                    //content_css: useDarkMode ? 'dark' : 'default',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                             



                                                
                                                }}
                                            />
                                        </Grid>

  
                                    </Grid>
                                </CardContent>
                            </Card>
                            </Grid>
                                        </CardContent>
                                        </Card>
                        </CardContent>
                    </Card>
                </Grid>
{/* 
                    <Grid item xs={4}>
                        <Card className={classes.stdCard}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                    <Info />
                                    </Avatar>
                                }
                                className={classes.stdMainCardHeader}
                                title="Pricing"
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className={classes.scrollableDivToStandard}>
                                            <TableContainer>
                                                <Table aria-label="customized table">
                                                    <TableHead className={classe.commonSecondaryTableHead}>
                                                        <TableRow>
                                                            <StyledTableCell className={classe.commonTableThStyle} style={{ width: '70%' }}>Package Name</StyledTableCell>
                                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '15%' }}>Edit</StyledTableCell>
                                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '15%' }}>Delete</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {previousCollectionTableRowData}
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid> */}

                 
                
            </>
          </Grid>
        </Grid>
      </div>


      <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>
    </>
  )
}

export default Pricing
