import {makeStyles} from '@material-ui/core';
import {alpha} from "@material-ui/core";
import { Balcony, BorderStyle } from '@mui/icons-material';
import { color, padding, textAlign } from '@mui/system';


const useStyle = makeStyles((theme) => ({
  divDisabled: {
    pointerEvents: 'none !important',
    opacity: '0.7 !important',
  },  
  toolbar: {
        display:'flex',
        justifyContent:'space-between',
        backgroundColor:'white'
    },
    appbar:{
        background:'none'
    },
    topic:{
        justifyContent:'center'
    },
    logbtn:{
        background:'linear-gradient(270deg, #9155FD 0%, #C6A7FE 100%)',
        borderRadius:'border-radius: 0px 50px 50px 0px;',
        color:'#ffff'
        
    },
    logo:{
        width:'60px',
        justifyContent:'center'
    },
    regbtn:{         
        fontSize:'10px',
        color:'white'
    },
    navitem:{
        padding:'0 20px',
        color:'#586CDE',
        listStyle:'none',
        fontWeight:'500',
        
          
    },
    underlinelist:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
       
    },underlinelist:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        marginTop:'10PX'
       
    },

    navlink:{
        color:'#9155FD',
        textDecoration:'none',
        fontSize:'15px',
        
        "&:hover": {
            color: '#9155FD',
            borderBottom:'2px solid #1635E1 !important',
            transitions:'.3s'
            
            }
    },
    rootss:{
        backgroundColor:'#9155fdbf',
        height:'50px',
        marginTop:'-70px',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
    },

    rootss1:{
      //backgroundColor:'#9155fdbf',
      //height:'50px',
      marginTop:'-70px',
      //justifyContent:'center',
      //alignItems:'center',
      //textAlign:'center',
    },
    footer:{
        color:'white',
       padding:'15px',

    },
    toolbarr: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor:'#ad8ce4',
        
      },
      logoLg: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
          display: "block",
        },
      },
      logoSm: {
        display: "block",
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
      search: {
        display: "flex",
        alignItems: "center",
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        borderRadius: theme.shape.borderRadius,
        width: "20%",
        [theme.breakpoints.down("sm")]: {
          display: (props) => (props.open ? "flex" : "none"),
          width: "70%",
        },
      },
      input: {
        color: "white",
        marginLeft: theme.spacing(1),
      },
      cancel: {
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
      searchButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
      icons: {
        alignItems: "center",
        display: (props) => (props.open ? "none" : "flex"),
        //color:'black'
      },
  
      log:{
          display:'flex',
          //color:'black'
          
          
      },
      risktit:{
        textShadow: '1px 1px',
      },

      badge: {
        marginRight: theme.spacing(2),
      },
      rootbackground:{
       // Height:'100vh',
        backgroundImage: `linear-gradient( 
          0deg
           , rgb(63 54 80), rgb(240 232 239 / 0%)),url(https://assets.kpmg/is/image/kpmg/pointing-digital-screen:cq5dam.web.512.341) !important`,
      //  backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url("https://assets.kpmg/is/image/kpmg/pointing-digital-screen:cq5dam.web.512.341") !important`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover', 
        //fontSize: '10px',
        height:'98vh'
        //backgroundImage: `url("https://wallpaperaccess.com/full/2204240.jpg") !important`
        
     
        
    },
    alignTitle:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        
         
    },
    title: {
        color: '#fff',
        fontSize: '3.5rem',
        fontWeight:'400'
        

      },
      colorText: {
        color:'#9155FD',
        fontSize:'40px'
      },
      secondtitle:{
        
        fontSize: '1,5rem',
        color: '#fff',
        fontWeight:'200'
      },

      lgbtn:{
            fontSize:'10px',
            //fontWeight:'100 !important',
            color:"white !important"           
      },

      secalign:{
          
      },
      btnalign:{
          marginTop:'20px'
      },
      regbtn:{
          border:"1px solid #9155FD !important",
          color:"#9155FD !important",
          //fontWeight:'700'
          width:'300px'

      },


      

      //sidebar

      sidebarbtn:{
          backgroundColor:'red',
        "&:hover": {
            backgroundColor:'red',
          },  
      },

      //questiomain

      quesmainroot:{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("https://www.teahub.io/photos/full/288-2888618_1920…free-blue-color-backgrounds-1080p-solid-color.jpg")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',  
        minHeight:'30vh'
      },

      qmainalignTitle:{
        display:'grid',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        
      
    },

    qusCard:{
      height:'240px'
    },
    qusCardHeader:{
      backgroundColor:'#c7b7e4a3',
      fontSize:'14px',
      
    },
    qusCardHeadermapdata:{
      backgroundColor: '#ece9fa !important',
      fontSize:'13px',
      height:'70px',
      padding:'0px'
    },
    pMarginBottomZero: {
      marginBottom: '0rem !important'
    },
    qmainusername:{
        color:"white",
        fontSize:'18px',
        marginTop:'90px',
        fontWeight:'600'

    },
    firstpha:{
        color:'black',
        fontSize:'13px',
        marginTop:'10px'

    },
    search: {
        display: "flex",
        alignItems: "center",
        marginTop:'10px',
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        borderRadius: theme.shape.borderRadius,
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          display: (props) => (props.open ? "flex" : "none"),
          width: "70%",
        },
      },
      input: {
        color: "white",
        marginLeft: theme.spacing(1),
      },
      cancel: {
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
      quesimg:{
        display:'grid',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
      },
      qusmaintitle:{
          marginTop:'10px',
          fontWeight:'500'
      },
      des:{
          fontSize:'14px'
      },
      descriptionStyle:{
        fontSize:'14px'
      },
      descriptionStyleRed:{
        fontSize:'16px !important',
        color: 'red',
        textAlign: 'center'
      },

      tableHeadEntityMapStyle: {
        backgroundColor: '#ece9fa',
        color: '#ffffff',
      },
      tableThEntityMapStyle: {
         lineHeight: '0.25rem !important'
      },
       tableTrEntityMapStyle: {
         lineHeight: '0.25rem !important',
         paddingTop: '0px !important',
         fontSize: '10px !important',
         paddingBottom: '0px !important'
      },
      btnBorderGreenEntityMap:{
        border: '1px solid green !important',
        color: 'green !important',
        padding:'5px 10px !important',
        height: '40px !important',
        fontSize: '11px !important',
      },
      tableEntityMapConfirmTrStyle: {
        lineHeight: '0.25rem !important',
        //backgroundColor: '#FFFFFF',
        borderBottom: '0px !important',
        paddingBottom: '0px !important',
        paddingTop: '0px !important',
        fontSize: '10px !important',
      },  


      //questionnMain

      
  
      pageBtn:{
        height: '60px',
        width: '170px',
        backgroundColor: '#f9f9f9 !important',
        color: '#9481df !important',
        borderRadius: '12px !important',
      },
      headerLable:{
        color: '#827d7d',
      },
      pageTop:{
        marginTop: '115px',
        marginLeft: '10px',
      },
      evapageleft:{
        marginLeft: '13px',
      },
      pageBodyStyle: {
       paddingLeft: '5px',
        paddingRight: '5px',
        paddingTop: '12px',
        /*backgroundColor: '#f7f7f7',*/
       marginLeft: '25px',
      },
      
      pageBodyStyleRiskReg: {
        paddingLeft: '5px',
         paddingRight: '5px',
         paddingTop: '12px',
         /*backgroundColor: '#f7f7f7',*/
       },
      root: {
        position: 'relative',
      },
      posRelative: {
        position: 'relative',
      },
      topImagePos: {
        position: 'absolute', 
        top: '10%',
        left: '50%',
        transform: 'translateX(-50%)',
      },
      topHeaderText1: {
        color: '#9155FD',
        fontSize:'20px', 
        paddingBottom:'10px',
        textAlign:'center'
      },
      topHeaderText2: {
        color: '#9155FD',
        fontSize:'12px !important', 
        paddingBottom:'10px',
        textAlign:'center',
        cursor: 'pointer'
        
      },
      addBodyPlus:{
        fontSize: '116px ',
        fontWeight: '1000 ',
        textAlign: 'center !important ',
        color: '#a29fa6ad ',
        
      },
      btnBorderGreenHomePage: {
        border: '1px solid #0e640e !important',
        color: '#0e640e !important',
        height:'40px'
      },  
      boxback:{
        //backgroundColor:'#c7b7e4a3',
        //padding:"0 122px 0px 122px"
        width:'100% !important'
      },
      cardout:{
        height:'240px',
        borderRadius:'5px',
        boxshadow: '5px 10px'
      },
      collection:{
        textDecoration:'none !important',
        color:'black !important'
      },

      addquesbtn:{
        color:'white !important',
        backgroundColor:'#9155FD !important',
        height:'40px',
        marginTop:'10px !important',
        marginBottom:'10px !important'
      },

      //sidebar

      sidebarmenu:{
        "&:hover": {
          background:'linear-gradient(270deg, #9155FD 0%, #C6A7FE 100%)',
          borderRadius:'border-radius: 0px 50px 50px 0px;',
          color:'#ffff',

          
          }
      },

      //center collection
      quesimggg:{
     // display:'flex',
     //   justifyContent:'center',
     //   alignItems:'center',
      //  textAlign:'left',
        //marginLeft:'170px'
      },
      rowbtn:{
        marginTop:'30px !important',
        justifyContent:'center !important'
      },
      rowbtnWithoutTop:{
        //marginTop:'30px !important',
        justifyContent:'center !important'
      },
      rowbtnMinusTop:{
        marginTop:'-13px !important',
        justifyContent:'center !important'
      },

      btnwidth:{
        width:'160px',
        height:'35px',
        borderRadius:'10px',
        color:'#9155FD !important',
        border:'1px solid #9155FD !important'
      },
      btnwidthDisable:{
        width:'160px',
        height:'35px',
        //borderRadius:'10px',
        //color:'#9155FD !important',
        //border:'1px solid #9155FD !important'
      },
      btnwidthLarge:{
        width:'170px',
        height:'35px',
        borderRadius:'10px',
        color:'#9155FD !important',
        border:'1px solid #9155FD !important'
      },
      quespadding:{
        padding:'15px !important'
      },
      btnwidthcolorbtn:{
        width:'160px',
        height:'35px',
        borderRadius:'10px',
        background:'linear-gradient(270deg, #9155FD 0%, #C6A7FE 100%)',
        
        color:'#ffff'
      },
      cardoutcenter:{
        height:'300px',
        borderRadius:'5px',
        boxshadow: '5px 10px'
      },
      //dashboard

      dashtitle:{
        textAlign:'center !important',
        //fontSize:'50px'
        textDecoration:'underline'
      },
      fbtn:{
        width:'250px !important',
        color:'#9155FD !important',
        border:'1px solid #9155FD !important',
        height:'50px',
        textDecoration:'none'
      },
      sbtn:{
        width:'250px !important',
        color:'#9155FD !important',
        border:'1px solid #9155FD !important',
        height:'50px',
        textDecoration:'none'
      },
      btnBorderpurple:{
        border: '1px solid #9155FD !important',
        color: '#9155FD !important',
        padding:'5px 10px !important',
        height: '40px !important',
      },
      
      /** QuestionnaireMain Page */
      cardoutQM:{
        height:'300px',
        borderRadius:'5px',
        boxshadow: '5px 10px'
      },
      qusCardHeadermapdataQM:{
        backgroundColor: '#ece9fa !important',
        fontSize:'14px !important',
        padding:'0px',
        fontWeight: '500 !important'
      },
      pMarginBottomZeroQM: {
        marginBottom: '0rem !important'
      },
      centerCententQM:{
        display:'grid',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        marginTop: '-10px !important'
      },
      descriptionStyleQM:{
        color: '#000000 !important',
        overflowY:'scroll',
        height:'80px',
        textAlign:'justify',
        fontSize: '11px !important'
      },
      btnBorderRedEvalQM: {
        border: '1px solid #ff0000 !important',
        color: '#ff0000 !important',
        height: '35px !important',
        marginTop: '10px !important',
        float: 'right !important'
      },
      firstphaQM:{
        color:'black',
        fontSize:'16px',
        marginTop:'6px',
        textAlign: 'center !important',
        fontWeight: '500'
      },
      /** About Us page */
      pageTopabout:{
        marginTop: '100px',
        marginLeft:'50px',
        marginRight:'50px'

      },
      pageLayout:{
        marginTop: '115px',
        marginLeft:'25px',
        marginRight:'25px'
      },
      

      pageBodyStyleabout:{

      },
      topImagePosabout: {
        position: 'absolute', 
        top: '42%',
        left: '50%',
        transform: 'translateX(-50%)',
      },
      topHeaderTextabout: {
        color: '#9155FD',
        fontSize:'30px', 
        textAlign:'center'
      },
      storytop:{
        marginTop:'40px'
      },
      phaMain:{
        //padding: '0 50px'
      },
      margin:{
        marginLeft:'30px',
        marginRight:'30px',
        fontSize:'16px'
      },


//headercom

    pageBodyStyleDash:{
      marginLeft:'30px',
      marginRight:'30px',
      marginTop:'5%'

      /*backgroundColor: '#f7f7f7',*/
    
    },
    topImageHeader:{
      position: 'absolute', 
      top: '5%',     
      transform: 'translateX(-50%)',
  
    },
    firstphaHeader:{
     fontSize:'33px !important',
      marginTop:'10%',
      color: 'black',
     
    },
    firstphaHeaderBottom:{
      fontSize:'11px !important',
       marginTop:'-10px important',
       color: 'black',
       paddingBottom:'10px !important',
    },
    topHeaderTextHeader: {
      color:'black',
      fontSize:'13px', 
      paddingBottom:'10px',
      textAlign:'center'
    },
     /** Dash Board */
      tabBtnDB:{
        width:'10% !important',
        color:'#F5F5F5 !important',
        backgroundColor:'#4C7EE0 !important',
        border:'1px solid #69676e !important',
        height:'50px',
        fontSize: '10px !important',
        textDecoration:'none'
      },
      tabBtnDBClicked:{
        width:'150px !important',
        color:'#F5F5F5 !important',
        backgroundColor:'#0D4CC8 !important',
        border:'1px solid #69676e !important',
        height:'50px',
        textDecoration:'none'
      },

      superAdmintabBtnDBClicked:{
        width:'10% !important',
        color:'#F5F5F5 !important',
        backgroundColor:'#0D4CC8 !important',
        border:'1px solid #69676e !important',
        height:'50px',
        fontSize: '10px !important',
        textDecoration:'none'
      },
      topHeaderText1Move: {
        //color: '#ffffff',
        color:'#57544A',
        textShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontSize:'20px', 
        paddingBottom:'10px',
        textAlign:'center'
      },
      topHeaderText2Move: {
        color: '#57544A',
        fontSize:'12px', 
        paddingBottom:'10px',
        textAlign:'center',
        cursor: 'pointer'
      },
      topHeaderText1DB: {
        color: '#69676e',
        fontSize:'24px', 
        paddingBottom:'10px',
        textAlign:'center'
      },
      topHeaderText3DB: {
        color: '#69676e',
        fontSize:'24px', 
        paddingBottom:'10px',
        textAlign:'center',
        marginTop: '-15px'
      },
      topHeaderText2DB: {
        color: '#69676e',
        fontSize:'12px !important', 
        paddingBottom:'10px',
        textAlign:'center',
        cursor: 'pointer'
        
      },
      cardDivMove:{
        border:'2px solid #F5F5F5',
        backgroundColor:'#E5EAF4 !important',
        boxShadowhadow: '5px 10px #69676e',
        borderRadius: '5px !important',
        BorderStyle: 'rigid',
        color:'#57544A',
        border:'2px solid #4C7EE0'
      },
      cardDivDB:{
        border:'2px solid 69676e',
        width:'100% !important',
        backgroundColor:'#f6f5fc !important',
        boxShadowhadow: '5px 10px #69676e',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        borderRadius: '5px !important'
      },
      cardDiv1DB:{
        border:'2px solid 69676e',
        width:'100% !important',
        backgroundColor:'#d6d4e1 !important',
        boxShadowhadow: '5px 10px #69676e',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        borderRadius: '5px !important'
      },
      cardDiv2DB:{
        border:'2px solid 69676e',
        width:'100% !important',
        backgroundColor:'#c7c5d0 !important',
        boxShadowhadow: '5px 10px #69676e',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        //borderRadius: '50px !important'
      },
      cardDiv3DB:{
        border:'2px solid 69676e',
        width:'100% !important',
        backgroundColor:'#bcb9ce !important',
        boxShadowhadow: '5px 10px #69676e',
        resize: 'horizontal',
        overflow: 'auto',
        height: '100%',
        borderRadius: '50px !important'
      },

      pageBodyStyleDB: {
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingTop: '12px',
       },
       cardTitleMove: {
        fontSize:'15px !important', 
        textAlign:'center',
        fontWeight: '500',
        paddingBottom:'10px',
        color: 'rgba(87, 84, 74, 0.9)'
       },
       cardTitleDB: {
        fontSize:'15px !important', 
        textAlign:'center',
        fontWeight: '500',
        paddingBottom:'10px',
       },
       collectionCardTitleDB: {
        fontSize:'14px !important', 
        textAlign:'center',
        fontWeight: '500',
       },
       collectionCardTitleDBEval: {
        fontSize:'13px !important', 
        textAlign:'center',
        fontWeight: '500',
       },
       collectionCardDetailsDB: {
        fontSize:'11px !important', 
        textAlign:'center',
        paddingBottom:'12px',
       },
       btnBorderAshDB: {
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        height: '25px !important',
      },
      btnBorderAshDBTop3: {
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        height: '30px !important',
        marginTop: '3px !important'
      },
      alignCenterDB: {
        textAlign:'center !important',
        alignItems:'center  !important',
        justifyContent:'center  !important'
      },
      collectionCardDB:{
        height:'135px'
      },
      collectionCardDBEval:{
        height:'135px',
        borderRadius:'15px !important',
        backgroundColor: '#dce3f2 !important',
      },
      collectionCardContentDB:{
        overflowY: 'scroll',
        height:'145px'
      },
      fontZise10:{
        fontSize: '10px !important',
      },
      fontZise11:{
        fontSize: '11px !important',
      },
      fontZise12:{
        fontSize: '12px !important',
      },
      cardoutcenterBuy:{
        height:'265px',
        borderRadius:'5px',
        boxshadow: '5px 10px'
      },
      fontZise9AndGreen:{
        fontSize: '9px !important',
        backgroundColor:'green !important',
        width:'125px !important',
        fontWeight: '700 !important',
      },
      fontZise9AndRed:{
        fontSize: '9px !important',
        backgroundColor:'red !important',
        width:'125px !important',
        fontWeight: '700 !important',
      },
      fontZise9AndRedSmall:{
        fontSize: '9px !important',
        backgroundColor:'red !important',
        width:'90px !important',
        fontWeight: '700 !important',
      },
      fontZise9AndBlue:{
        fontSize: '9px !important',
        backgroundColor:'blue !important',
        width:'125px !important',
        fontWeight: '700 !important',
      },
      selectRoleTypeBtn:{
        height: '80px',
        width: '312px',
        backgroundColor: '#f9f9f9 !important',
        color: '#9481df !important',
        borderRadius: '12px !important',
      },
      btnwidthMyQ:{
        width:'90px',
        height:'35px',
        borderRadius:'10px',
        color:'#9155FD !important',
        border:'1px solid #9155FD !important'
      },
      btnwidthMyQDisable:{
        width:'90px',
        height:'35px',
        //borderRadius:'10px',
        //color:'#9155FD !important',
        //border:'1px solid #9155FD !important'
      },
      btnwidthMyQL:{
        width:'220px',
        height:'35px',
        borderRadius:'10px',
        color:'#9155FD !important',
        border:'1px solid #9155FD !important'
      },
      btnwidthMyQLDisable:{
        width:'220px',
        height:'35px',
        //borderRadius:'10px',
        //color:'#9155FD !important',
        //border:'1px solid #9155FD !important'
      },
      stdMainCardHeaderCM:{
        backgroundColor:'#acacbe !important',
        fontSize:'13px !important',
        height:'50px',
        padding:'0px',
        marginTop: '-7px',
      },
      pageBodyStyleCM: {
        paddingLeft: '15px',
        paddingRight: '17px',
        width: '100% !important',
        marginTop: '-12px !important'
       },
      gridMarginTopCM: {
        marginTop: '-15px !important'
       },
       stdCardCM:{
        height:'100%',
        border:'1px solid black'
       },
       stdMainCardHeaderCM:{
        backgroundColor:'#acacbe !important',
        fontSize:'13px !important',
        height:'50px',
        padding:'0px',
        marginTop: '-7px',
      },
      stdCardHeaderCM:{
        backgroundColor:'#d5d5e1 !important',
        fontSize:'13px !important',
        height:'44px',
        padding:'0px',
        marginTop: '-7px',
      },
      stdCardHeadermapdataCM:{
        backgroundColor: '#ece9fa !important',
        fontSize:'13px',
        height:'70px',
        padding:'0px'
      },
      scrollableDivToStandardCM: {
        //height: '550px',
        overflow: 'auto',
        width: '100%',
      },
      mousePointer: {
        cursor: 'pointer'
      },
      rightBarBadgeBtnRed: {
        marginTop: '-49px !important',
        height: '17px !important',
        width: '77px !important',
        borderRadius: '13px !important',
        fontSize: '9px !important',
        backgroundColor: 'red !important',
      },
      rightBarBadgeBtnGreen: {
        marginTop: '-49px !important',
        height: '17px !important',
        width: '77px !important',
        borderRadius: '13px !important',
        fontSize: '9px !important',
        backgroundColor: 'Green !important',
      },
      rightBarBadgeBtnGreenWithRed: {
        marginTop: '-49px !important',
        marginLeft: '3px !important',
        height: '17px !important',
        width: '77px !important',
        borderRadius: '13px !important',
        fontSize: '9px !important',
        backgroundColor: 'Green !important',
      },
      rightBarTextTableLeft: {
        marginLeft: '-67px',
      },
      rightBarTextTableLeftBoth: {
        marginLeft: '-139px',
      },
      leftPageNumberStyle: {
        marginTop: '-91px !important',
        marginLeft: '-17px !important',
        padding: '0px !important',
        backgroundColor: 'black !important',
        fontSize: '15px !important',
        maxWidth: '26px !important', 
        maxHeight: '26px !important', 
        minWidth: '26px !important', 
        minHeight: '26px !important',
      },
      rightPageNumberStyle: {
        float: 'right !important',
        marginTop: '-49px !important',
        marginRight: '-16px !important',
        padding: '0px !important',
        backgroundColor: 'black !important',
        fontSize: '15px !important',
        maxWidth: '26px !important', 
        maxHeight: '26px !important', 
        minWidth: '26px !important', 
        minHeight: '26px !important'
      },
      mutimenuPageHeadingStyle: {
        fontSize:'14px !important', 
        fontWeight: 'normal !important', 
        marginTop:'-68px !important', 
        color:'#b60707', 
        marginLeft: '12px !important'
      },



      //common colors

      commonButton:{
        marginLeft: '0px !important',
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        padding: '5px 10px !important',
        height: '40px !important',
      },
      sliderCommonBtn: {
        border: '1px solid #4C7EE0 !important',
        color: '#4C7EE0 !important',
        height: '35px !important',
        padding: '5px 10px !important',
      },


      commonMainTableHead:{
        backgroundColor: '#4C7EE0 !important',
        fontSize: '13px !important',
        height: '50px',
        padding: '0px',
        marginTop: '-7px',
        color:'white'
      },
      commonMainTableHeadEval:{
        backgroundColor: '#4C7EE0 !important',
        fontSize: '16px !important',
        height: '50px',
        padding: '0px',
        marginTop: '-7px',
        color:'white'
      },
      commonSecondaryTableHead:{
        backgroundColor: '#7B9DE0',
        color: '#ffffff',
      },
      commonTableThStyle:{
        lineHeight: '0.25rem !important',
        color:'white !important'
      },
      commonTableThStikyStyle:{
        lineHeight: '0.25rem !important',
        color:'white !important',
        backgroundColor: '#7B9DE0 !important'
      },
      loaderStyle:{
        textAlign:'center',
        display:'flex',
        marginTop: '20%',
        marginLeft:'40%'
      },
      loaderStyle1:{
        textAlign:'center',
        display:'flex',
        marginTop: '0%',
        marginLeft:'0%'
      },

      scrollableDivToEval: {
        height: '400px',
        overflow: 'auto',
        width: '100%',
    },
    btnActive: {
      border: '1px solid #4C7EE0 !important',
      color: '#4C7EE0 !important',
      backgroundColor:'#BACDF2 !important',
      padding:'5px 10px !important',
      height:'40px !important',
      width: '160px !important'
    },
    
  btnNormal:{
      border: '1px solid #4C7EE0 !important',
      color: '#4C7EE0 !important',
      padding:'5px 10px !important',
      height: '40px !important',
      width: '160px !important'
  },
  scrollableDivPosChange: {
    height: '275px',
    overflow: 'auto',
    width: '100%',
  },
  trBackColor1: {
    backgroundColor: '#f4efef'
  },
  trBackColor2: {
    backgroundColor: 'white'
  }






}));

export default useStyle;
