import React from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { ArrowBack } from '@mui/icons-material';
import RightBarSuperAdmin from '../Components/RightBarSuperAdmin';
import Button from '@mui/material/Button';
import useStyle from '../Css/MainCss';
import imgdash from '../assets/risk.jpg'
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import SuperAdminLoginAddCollection from '../Components/SuperAdminLoginAddCollection';
import SuperAdminLoginCentreLimitation from '../Components/SuperAdminLoginCenterLimitation';
import SuperAdminLoginCentreMaintenance from '../Components/SuperAdminLoginCenterMaintenance';
import axios from 'axios';
import SchemaMaintainers from '../Components/SchemaMaintainers';



const CreateJsonSchemas = () => {

  const [PageName, setPageName] = React.useState('Create Json Schemas');
  const navigate = useNavigate();
  const classes = useStyle();

  React.useEffect(() => {
    //getTemplates()
  }, [])

  const [tinyMCETemplateList, setTinyMCETemplateList] = React.useState()

  var templateArr = [];
  function getTemplateHtml(fileName){
    axios('https://www.daytaem.co.uk/readFile.php?q=' + fileName, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
    })
    .then(function (res) {
        var tmpA = fileName.split(".")
        var templateObj = {};
        templateObj["title"] = tmpA[0].replace("RST_","");
        templateObj["description"] = "Risk System - Template ";
        templateObj["content"] = res.data;
        templateArr.push(templateObj);
        setTinyMCETemplateList(templateArr);
    })
    .catch(function (error) {
        console.log(error);
    });

  }

  function getTemplates(){
      var filesArr = "";
      axios('https://www.daytaem.co.uk/getFile.php?q=aaaaaaa', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
        var resData = response.data;
        var arrTmp= resData.split("@@");
        var filesStr=arrTmp[1];
        
        filesArr = filesStr.split("]");
        var k = 1;
        
        var templateHtmlArr = [];
        for (var i=1; i < filesArr.length; i++) {
            if (filesArr[i] != "") {
                if (filesArr[i].slice(0, 3) === "RST"){
                    var fileName = filesArr[i];
                    getTemplateHtml(fileName)
                }
            }

        }
        /*
        for (var i=1; i < filesArr.length; i++) {
          if (filesArr[i] != "") {
            if (filesArr[i].slice(0, 3) === "RST"){
                var tmpA = filesArr[i].split(".")
                var templateObj = {};
                if (i == filesArr.length - 1) {
                    templateObj["title"] = tmpA[0].replace("RST_","");
                    //templateObj["url"] = "https://www.daytaem.co.uk/templates/" + filesArr[i];
                    templateObj["description"] = " Saved Template " + k;
                    templateObj["content"] = "hhjjkhkhkhk hkjhkj khkjhk khkjh khkh khkjhjk " + filesArr[i];
                }else{
                    templateObj["title"] = tmpA[0].replace("RST_","");
                    //templateObj["url"] = "https://www.daytaem.co.uk/templates/" + filesArr[i];
                    templateObj["description"] = " Saved Template " + k;
                    templateObj["content"] = "hhjjkhkhkhk hkjhkj khkjhk khkjh khkh khkjhjk " + filesArr[i];
                }
                k ++;
                templateArr.push(templateObj);
            }
          }
        }
        console.log(templateArr);
        setTinyMCETemplateList(templateArr);
        */
      })
      .catch(function (error) {
        console.log(error);
      });  
  }





  const [openCentreLimitation,setOpenCentreLimitation] = React.useState(true);
  const [openCentreMaintenance,setOpenCentreMaintenance] = React.useState(false);
  const [openAddCollections,setOpenAddCollections] = React.useState(false);
  const [openCreateSchema,setOpenCreateSchema] = React.useState(false);

  function setOpenCentreLimitationFuction(flag){
    setOpenCentreLimitation(true);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(false)
  }
  function setOpenCentreMaintenanceFuction(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(true);
    setOpenAddCollections(false);
    setOpenCreateSchema(false)
  }
  function setOpenAddCollectionFuction(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(true);
    setOpenCreateSchema(false)
  }

  function setOpenCreateSchemaFuction(flag){
    setOpenCentreLimitation(false);
    setOpenCentreMaintenance(false);
    setOpenAddCollections(false);
    setOpenCreateSchema(true)
  }

  


  return (
    <>
    <RightBarSuperAdmin PageName={PageName}/>
    <div style={{marginLeft: '-40px', marginRight: '-40px', marginTop:'130px'}}>
        <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card 
                        className={classes.cardDivMove}  
                        style={{marginRadius:'10px',width:'100%',height:'750px',overflowY: 'scroll'}}
                        >
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SchemaMaintainers/>
                                </Grid> 
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

               
        </Grid>    
    </div>
    </>
    
  )
}

export default CreateJsonSchemas