const MainQuestionnaire = [
    {
      id: 1,
      img: "https://i.ibb.co/DG69bQ4/2.png",
      title: "HR Risk Assestment",
      desc: "questionnaire #001",  
      subdesc: "ISO 3001", 
    },
    {
      id: 2,
      img: "https://i.ibb.co/DG69bQ4/2.png",
      title: "HR Risk Assestment",
      desc: "questionnaire #001",
      subdesc: "ISO 3001",
    },
    {
      id: 3,
      img: "https://i.ibb.co/cXFnLLV/3.png",
      title: "HR Risk Assestment",
      desc: "questionnaire #001",    
      subdesc: "ISO 3001",   
    },
    {
        id: 4,
        img: "https://i.ibb.co/DG69bQ4/2.png",
        title: "HR Risk Assestment",
        desc: "questionnaire #001",
        subdesc: "ISO 3001",
      },
      {
        id: 5,
        img: "https://i.ibb.co/cXFnLLV/3.png",
        title: "HR Risk Assestment",
        desc: "questionnaire #001",    
        subdesc: "ISO 3001",   
      },
  ];

  export default MainQuestionnaire;