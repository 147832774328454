import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Links from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { textAlign } from '@mui/system';
import { CONFIG } from '../config'
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info, KeyboardReturnOutlined } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import '../Css/Slider.css';
import Draggable from 'react-draggable';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Alert } from 'bootstrap';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import useStyle from '../Css/MainCss';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import RiskLevelSlider from './RiskLevelSlider';
import RiskAppetiteSlider from './RiskAppetiteSlider';



function PaperComponentMainRisk(props) {
  return (
    <Draggable
      handle="#main-risk-scroll-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
function PaperComponentMainRiskEdit(props) {
  return (
    <Draggable
      handle="#edit-risk-scroll-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
function PaperComponentSubRisk(props) {
  return (
    <Draggable
      handle="#sub-risk-scroll-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function PaperComponentSubRiskEdit(props) {
  return (
    <Draggable
      handle="#edit-sub-risk-scroll-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}




function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageBtn: {

    height: '60px',
    width: '170px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '12px !important',
  },
  headerLable: {

    color: '#827d7d',
  },
  pageTop: {
    marginTop: '115px',
    marginLeft: '20px'
  },
  pageBodyStyle: {
    paddingTop: '12px',


  },
  root: {
    position: 'relative',
  },
  posRelative: {
    position: 'relative',
  },
  topImagePos: {
    position: 'absolute',
    top: '33%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  topHeaderText1: {
    color: '#9155FD',
    fontSize: '25px',
    paddingBottom: '10px',
  },
  topHeaderText2: {
    color: '#3A3541',
    fontSize: '12px',
    textAlign: 'center',
    fontFamily: 'Inter',
  },
  thStyle: {
    fontSize: '12px !important',
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important'
  },
  thStyleImage: {
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important',
    width: '130px !important',
  },
  btnColorPurple: {
    fontSize: '12px !important',
    backgroundColor: '#9155FD !important',
    height: '40px',
    textAlign: 'center'
  },
  riskImageSize: {
    width: '120px',
    height: '100px !important',
  },
  tableStyle: {
    width: '55% !important',
    minWidth: '720px !important'
  },
  btnBorderGreen: {
    border: '1px solid #0e640e !important',
    color: '#0e640e !important',
    height: '40px'
  },
  btnclear: {
    height: '40px'
  },
  btnBorderAsh: {
    border: '1px solid #8a8181 !important',
    color: '#8a8181 !important',
  },
  btnBorderRed: {
    border: '1px solid #ff0000 !important',
    color: '#ff0000 !important',
  },
  qusCard: {
    width: '100% !important',
  },
  qusCardHeader: {
    backgroundColor: '#ece9fa !important',
  },
  subText: {
    fontSize: '12px !important',
  },
  btnPadding: {
    paddingLeft: '2px !important',
    paddingRight: '2px !important',
  },
  cardBodyH6: {
    fontSize: '14px !important',
  },
  cardBodyH6Ans: {
    fontSize: '13px',
    paddingLeft: '12px',
  },
  addBodyPlus: {
    fontSize: '146px !important',
    fontWeight: '1000 !important',
    textAlign: 'center !important',
    color: '#3A3541 !important',
  },
  posRelativeAndDivBorder: {
    position: 'relative',
    border: '1px solid #9155FD',
    width: '120px',
    height: '100px !important',
  },
  hightrachytitle: {
    textAlign: 'left',
    color: '#3A3541',

  },
  btnBorderpurple: {
    border: '1px solid #9155FD !important',
    color: '#9155FD !important',
    padding: '5px 10px !important',
    height: '40px !important',
  },
  hightrachytitlestyle: {
    fontSize: '14px !important',
    fontWeight: 500
  },
  modalbox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 14,
    padding: 10,
  },
  addriskbtn: {
    color: '#9155FD !important',
    border: '1px solid #9155FD !important',
    marginTop: '20px !important'
  },
  risktytext: {
    width: '350px',

  },
  canclbtn: {
    alignItem: 'left !important',
    justifyContent: 'end'
  },
  cardRiskManage: {
    width: '95%',
    border: '1px solid black'
  },
  scrollableDiv: {
    height: '150px',
    overflow: 'auto',
    width: '100%',
  },
  tableHeadStyle: {
    backgroundColor: '#7B9DE0',
    color: '#ffffff',
  },
  tableThStyle: {
    lineHeight: '0.25rem !important',
    color: 'white !important'
  },
  tableTrStyle: {
    //lineHeight: '0.20rem !important',
    height: '19px !important'
  },
  mousePointer: {
    cursor: 'pointer'
  },
  scrollableDiv: {
    height: '250px',
    overflow: 'auto',
    width: '100%',
  },
  stdMainCardHeader: {
    backgroundColor: '#4C7EE0 !important',
    fontSize: '13px !important',
    height: '50px',
    padding: '0px',
    marginTop: '-7px',
    color: 'white'
  },
  addrisktypebtn: {
    marginLeft: '0px !important',
    border: '1px solid #4C7EE0 !important',
    color: '#4C7EE0 !important',
    padding: '5px 10px !important',
    height: '40px !important',
  },

  btnBorderpurple: {
    border: '1px solid #9155FD !important',
    color: '#9155FD !important',
    padding: '5px 10px !important',
    height: '40px !important',
  },
  stdCard: {
    height: '100%',
    border: '1px solid black'
  },
  scrollableDivToStandard: {
    height: '350px',
    overflow: 'auto',
    width: '100%',
  },
  noclickbackdrop: {
    pointerEevents: "none",
  },
  slider1: {
    background: 'linear-gradient(90deg, rgb(70, 163, 56) 10%,#d3d3d3 0%)',
  },
  cardDivDB: {
    border: '2px solid 69676e',
    width: '100% !important',
    backgroundColor: '#f6f5fc !important',
    boxShadowhadow: '5px 10px #69676e',
    resize: 'horizontal',
    overflow: 'auto',
    height: '100%',
    borderRadius: '5px !important'
  },
  stdButtonsStyle: {
    fontSize: '0.70rem !important',
    height: '50px !important',
  },
  sliderStyle: {
    WebkitAppearance: 'media-volume-sliderthumb !important'
  }

}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '15px'
  },
}));



const RiskHierarchy = () => {
  const classe = useStyle();
  const classes = useStyles();
  const Input = styled('input')({
    display: 'none',
  });

  const [golobalCentreId, setGolobalCentreId] = React.useState('');
  const [sliderType, setSliderType] = React.useState();


  const [selectedFile, setSelectedFile] = useState(null)
  const [preview, setPreview] = React.useState()

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    getData();
    getColors();


    if (!selectedFile) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])




  const [databClr, setDatabClr] = useState([])
  const [colorPatterns, setColorPatterns] = useState([])

  function getColors() {
    axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR + "/all", {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("All colors");
        console.log(response.data);

        //setDatabClr(response.data);
        setColorPatterns(response.data);
        response.data.forEach((data, index) => {
          if (index === 0) {
            setDatabClr(data.colors)
          }
        })


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function getData() {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId);
    getAllRisksMethods();
    getAllRisks(data.centreId);

    getStandards(data.centreId)
    console.log(data)
  }

  const [standardData, setStandardData] = React.useState([]);
  const [globalStandardData, setGlobalStandardData] = React.useState([]);
  const [customStandardData, setCustomStandardData] = React.useState([]);
  function getStandards(cntre) {
    axios(CONFIG.MAIN_URL + `/standard/all??centreId=` + cntre, {
      method: 'get',
    })
      .then(function (response) {
        console.log("++++++++++++++++Return Standard Data +++++++++++++++++++");
        console.log(response.data);
        console.log(JSON.stringify(response.data));
        setStandardData(response.data);
        var arrG = [];
        var arrC = [];
        response.data.forEach((item) => {
          if (item.centreId === "globle") {
            arrG.push(item);
          }
          if (item.centreId === cntre) {
            arrC.push(item);
          }
        })
        setGlobalStandardData(arrG);
        setCustomStandardData(arrC);

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0])

  }
  // console.log(selectedFile)
  const [saveBtnName, setSaveBtnName] = useState("Save");
  const [saveBtnNameForRisk, setSaveBtnNameForRisk] = useState("Save Risk");
  const [pageBannerName, setPageBannerName] = useState("New Questionnaire Group");
  const [editGroup, setEditGroup] = useState(false);
  const [showquesbtn, setShowquesbtn] = useState();
  const [riskName, setRiskName] = useState('')
  const [riskIdvalue, setRiskIdvalue] = React.useState(0);

  const [showRiskHierarchyTable, setShowRiskHierarchyTable] = React.useState(true);


  const onTextFieldChange = (event) => {
    setRiskName(event.target.value);

  }

  function clearFuction(e) {
    // window.location.reload(false);
    // setRiskName("");
    // setPreview("");
    // setSaveBtnName("Save");
    // setEditGroup(false);
    // setSaveRiskFlag(false);
    // setRiskTypeData({});
    // setRiskIdvalue(0);
    // setShowSaveBtn(false);
    // setCreateHeaderInfoMessage("Create A New Risk Hierarchy");
    // setSaveBtnNameForRisk("Save Risk");
    // setAppetiteShowSelectBar(true);
    // setAppetiteSliderValue();
    // setAppetiteSliderType();
    // setAppetiteSliderValueName();
    // setShowSelectBar(true);

    // setSliderType(0);
    // setSliderValue(0);
    // setSliderValueName("");
    setSaveRiskFlag(true);
    clearFuctionForUpdate();
    getAllRisks(golobalCentreId);
    setRiskIdvalue(0)





  }

  function clearFuctionForUpdate(e) {
    setFrameSize(8)
    setRiskName("");
    setPreview("");
    setSaveBtnName("Save");
    setEditGroup(false);
    setSaveRiskFlag(false);
    setRiskTypeData({});
    setRiskIdvalue(0);
    setShowSaveBtn(false);
    setCreateHeaderInfoMessage("Create A New Risk Hierarchy");
    setSaveBtnNameForRisk("Save Risk");
    setAppetiteShowSelectBar(true);
    setAppetiteSliderValue();
    setAppetiteSliderType();
    setAppetiteSliderValueName();
    setShowSelectBar(true);

    setSliderType(0);
    setSliderValue(0);
    setSliderValueName("");
    setShowRiskHierarchyTable(true)

    //For Standard
    setChkStandardAllChecked(false);
    setOpenStandardGridAll(false);
    setGlobalStandardIdForAll('');
    setCustomStandardIdForAll('');
    setGlobalStandardDataForMainRisk('');
    setCustomStandardDataForMainRisk('');
    setObjectiveId('');
    setComponentId('');
    setPrincipleId('');

  }

  const [showSaveBtn, setShowSaveBtn] = React.useState(false)
  const [riskTypeData, setRiskTypeData] = React.useState({});
  const [createheaderInfoMessage, setCreateHeaderInfoMessage] = useState("Create A New Risk Hierarchy");

  /**
   * Create Main Risk
   */
  const [mainRiskName, setMainRiskName] = React.useState('');
  const [mainRiskLevel, setMainRiskLevel] = React.useState('');
  const [mainRiskAppetite, setMainRiskAppetite] = React.useState('');
  const [lastRiskTmpId, setLastRiskTmpId] = React.useState(0);
  const [mainRiskOpen, setMainRiskOpen] = React.useState(false);
  const [mainRiskScroll, setMainRiskScroll] = React.useState('paper');



  const mainRiskHandleClickClose = () => {
    setMainRiskOpen(false);
    setShowSelectBar(true);
    setSliderType('');
    setSliderValue('');
    setSliderValueName("");
    setAppetiteShowSelectBar(true);
    setAppetiteSliderValue();
    setAppetiteSliderType();
    setAppetiteSliderValueName();
    setMainRiskName('');
    setSliderValueStyle('')
    setAppetiteSliderValueStyle('')




  };
  const handleChangeMainRisk = (event) => {
    setMainRiskName(event.target.value);
  };
  const handleChangeMainRiskLevel = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    if ((value != 0 && value < 11) || value === "") {
      setMainRiskLevel(value);
    }
  };
  const handleChangeMainRiskAppetite = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    if ((value != 0 && value < 11) || value === "") {
      setMainRiskAppetite(value);
    }
  };

  function setMainRiskFunction() {
    if (mainRiskName === "") {
      alert("Please enter a Main Risk");
      return
    }
    if (sliderValue === undefined) {
      alert("Please enter a Risk level");
      return
    }
    if (appetitesSliderValue === undefined) {
      alert("Please enter a Appetite Level ");
      return
    }

    if (document.getElementById('chkStandard').checked) {
      if (objectiveId === '' && componentId === '' && principleId === '') {
        alert("Please select an Objective , a Component or a Principle ");
        return;
      }
    }

    setMainRiskName('');
    setMainRiskOpen(false);
    //new
    if (Object.keys(riskTypeData).length == 0) {
      var arryObj = [];
      var tmpArray = {};
      tmpArray["riskTypeTmpId"] = lastRiskTmpId + 1;
      tmpArray["name"] = mainRiskName;
      tmpArray["riskLevel"] = sliderValue;

      var tmpArray1 = {}
      tmpArray1["riskLevel"] = sliderValue
      tmpArray1["riskCategoryId"] = riskCategoryId
      tmpArray1["riskMethodId"] = sliderType
      tmpArray["riskLevelData"] = tmpArray1;

      tmpArray["riskAppetite"] = appetitesSliderValue;


      var tmpArrayApp = {}
      tmpArrayApp["riskAppetite"] = appetitesSliderValue
      tmpArrayApp["riskCategoryId"] = riskCategoryIdAppetite
      tmpArrayApp["riskMethodId"] = appetiteSliderType
      if (selectedColorForAppetiteSlider === 1) {
        tmpArrayApp["color"] = appetiteColorForSaveToDb
        tmpArrayApp["secondaryColor"] = ""
      }
      else {
        tmpArrayApp["color"] = ""
        tmpArrayApp["secondaryColor"] = appetiteColorForSaveToDb
      }

      tmpArray["riskAppetiteData"] = tmpArrayApp;


      //For Standared
      if (document.getElementById('chkStandard').checked) {
        var tmpRiskStandardData = {};
        var takenStd = false;
        if (globalStandardDataForMainRisk != '' || customStandardDataForMainRisk != '') {
          takenStd = true;
          var tmpStdData = {}
          if (globalStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = globalStandardDataForMainRisk
            tmpStdData["standardName"] = globalStandardDataNameForMainRisk
          }
          if (customStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = customStandardDataForMainRisk
            tmpStdData["standardName"] = customStandardDataNameForMainRisk
          }
          tmpRiskStandardData["standardData"] = tmpStdData;
        }
        if (objectiveId != '') {
          takenStd = true;
          var tmpStdData = {};
          tmpStdData["objectiveId"] = objectiveId;
          tmpStdData["objectiveName"] = objectiveName;
          if (globalStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = globalStandardDataForMainRisk;
            tmpStdData["standardName"] = globalStandardDataNameForMainRisk;
          }
          if (customStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = customStandardDataForMainRisk;
            tmpStdData["standardName"] = customStandardDataNameForMainRisk;
          }
          tmpRiskStandardData["objectiveData"] = tmpStdData;
        }
        if (componentId != '') {
          takenStd = true;
          var tmpStdData = {};
          tmpStdData["componentId"] = componentId;
          tmpStdData["componentName"] = componentName;
          if (globalStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = globalStandardDataForMainRisk;
            tmpStdData["standardName"] = globalStandardDataNameForMainRisk;
          }
          if (customStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = customStandardDataForMainRisk;
            tmpStdData["standardName"] = customStandardDataNameForMainRisk;
          }
          tmpRiskStandardData["componentData"] = tmpStdData;
        }
        if (principleId != '') {
          takenStd = true;
          var tmpStdData = {};
          tmpStdData["principleId"] = principleId;
          tmpStdData["principleName"] = principleName;
          if (globalStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = globalStandardDataForMainRisk;
            tmpStdData["standardName"] = globalStandardDataNameForMainRisk;
          }
          if (customStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = customStandardDataForMainRisk;
            tmpStdData["standardName"] = customStandardDataNameForMainRisk;
          }
          tmpRiskStandardData["principleData"] = tmpStdData;
        }
        if (takenStd) {
          tmpArray["riskStandardData"] = tmpRiskStandardData;
        }
      }



      arryObj.push(tmpArray);

      tmpArray = {};
      tmpArray["name"] = riskName;
      tmpArray["centreId"] = golobalCentreId;
      tmpArray["riskTypes"] = arryObj;

      setRiskTypeData(tmpArray);
      console.log(tmpArray);
      setLastRiskTmpId(lastRiskTmpId + 1);
      setShowSaveBtn(true);

    } else {
      var tmpArray = {};
      tmpArray["riskTypeTmpId"] = lastRiskTmpId + 1;
      tmpArray["name"] = mainRiskName;
      tmpArray["riskLevel"] = sliderValue;

      var tmpArray1 = {}
      tmpArray1["riskLevel"] = sliderValue
      tmpArray1["riskCategoryId"] = riskCategoryId
      tmpArray1["riskMethodId"] = sliderType
      tmpArray["riskLevelData"] = tmpArray1;


      tmpArray["riskAppetite"] = appetitesSliderValue;

      var tmpArrayApp = {}
      tmpArrayApp["riskAppetite"] = appetitesSliderValue
      tmpArrayApp["riskCategoryId"] = riskCategoryIdAppetite
      tmpArrayApp["riskMethodId"] = appetiteSliderType
      if (selectedColorForAppetiteSlider === 1) {
        tmpArrayApp["color"] = appetiteColorForSaveToDb
        tmpArrayApp["secondaryColor"] = ""
      }
      else {
        tmpArrayApp["color"] = ""
        tmpArrayApp["secondaryColor"] = appetiteColorForSaveToDb
      }
      tmpArray["riskAppetiteData"] = tmpArrayApp;

      //For Standared
      if (document.getElementById('chkStandard').checked) {
        var tmpRiskStandardData = {};
        var takenStd = false;

        if (globalStandardDataForMainRisk != '' || customStandardDataForMainRisk != '') {
          takenStd = true;
          var tmpStdData = {}
          if (globalStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = globalStandardDataForMainRisk
            tmpStdData["standardName"] = globalStandardDataNameForMainRisk
          }
          if (customStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = customStandardDataForMainRisk
            tmpStdData["standardName"] = customStandardDataNameForMainRisk
          }
          tmpRiskStandardData["standardData"] = tmpStdData;
        }
        if (objectiveId != '') {
          takenStd = true;
          var tmpStdData = {};
          tmpStdData["objectiveId"] = objectiveId;
          tmpStdData["objectiveName"] = objectiveName;
          if (globalStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = globalStandardDataForMainRisk;
            tmpStdData["standardName"] = globalStandardDataNameForMainRisk;
          }
          if (customStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = customStandardDataForMainRisk;
            tmpStdData["standardName"] = customStandardDataNameForMainRisk;
          }
          tmpRiskStandardData["objectiveData"] = tmpStdData;
        }
        if (componentId != '') {
          takenStd = true;
          var tmpStdData = {};
          tmpStdData["componentId"] = componentId;
          tmpStdData["componentName"] = componentName;
          if (globalStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = globalStandardDataForMainRisk;
            tmpStdData["standardName"] = globalStandardDataNameForMainRisk;
          }
          if (customStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = customStandardDataForMainRisk;
            tmpStdData["standardName"] = customStandardDataNameForMainRisk;
          }
          tmpRiskStandardData["componentData"] = tmpStdData;
        }
        if (principleId != '') {
          takenStd = true;
          var tmpStdData = {};
          tmpStdData["principleId"] = principleId;
          tmpStdData["principleName"] = principleName;
          if (globalStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = globalStandardDataForMainRisk;
            tmpStdData["standardName"] = globalStandardDataNameForMainRisk;
          }
          if (customStandardDataForMainRisk != '') {
            tmpStdData["standardId"] = customStandardDataForMainRisk;
            tmpStdData["standardName"] = customStandardDataNameForMainRisk;
          }
          tmpRiskStandardData["principleData"] = tmpStdData;
        }
        if (takenStd) {
          tmpArray["riskStandardData"] = tmpRiskStandardData;
        }
      }


      riskTypeData.riskTypes.push(tmpArray);
      setLastRiskTmpId(lastRiskTmpId + 1);
      console.log(riskTypeData);
    }

    setSliderValueStyle('')
    setAppetiteSliderValueStyle('')
  }


  const mainRiskHandleClickOpen = (scrollType) => () => {
    if (riskName === "") {
      alert("Please enter Risk Hierarchy Name")
      return
    }
    setMainRiskOpen(true);
    setMainRiskScroll(scrollType);
    setMainRiskLevel();
    setMainRiskAppetite();
    setShowSelectBar(true);
    setSliderType();
    setSliderValue();
    setSliderValueName("");
    setAppetiteShowSelectBar(true);
    setAppetiteSliderValue();
    setAppetiteSliderType();
    setAppetiteSliderValueName('')

    //For Standared
    setOpenStandardGrid(false);
    setGlobalStandardDataForMainRisk('');
    setCustomStandardDataForMainRisk('');
    /*
    if (document.getElementById('chkStandardAll').checked) {
      var canDisplay = false;
      if (globalStandardIdForAll != ''){
        canDisplay = true;
        setGlobalStandardDataForMainRisk(globalStandardIdForAll); 
      }
      if (customStandardIdForAll != ''){
        canDisplay = true;
        setCustomStandardDataForMainRisk(customStandardIdForAll);
      }
      if (canDisplay){
        document.getElementById('chkStandard').checked = true;
      }
    }
    */

  };
  /** End of Create mainRisk */



  // getall risk slider method data

  const [riskCategoryMethodData, setRiskCategoryMethodData] = useState([])
  const [riskCategoryId, setRiskCategoryId] = useState('')

  const [riskCategoryMethodDataAppetite, setRiskCategoryMethodDataAppetite] = useState([])
  const [riskCategoryIdAppetite, setRiskCategoryIdAppetite] = useState('')

  function getAllRisksMethods() {
    axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_RISK + "/all", {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {

        console.log("+++++++++++++++++++All Risk Returns++++++++++++++++++++++++++++");
        console.log(response);
        console.log(response.data[0]);
        setRiskCategoryMethodData(response.data[0].methods)
        setRiskCategoryId(response.data[0].id)

        setRiskCategoryMethodDataAppetite(response.data[0].methods)
        setRiskCategoryIdAppetite(response.data[0].id)

        console.log(response.data[0].methods);

      })
      .catch(function (error) {
        console.log(error);
      });
  }





  /** Save Risk */

  const [saveRiskFlag, setSaveRiskFlag] = React.useState(false);

  function saveRisk() {
    if (riskName === "") {
      alert("Please enter Risk Name ");
      return
    }
    riskTypeData["name"] = riskName;
    var data = JSON.stringify(riskTypeData);

    console.log("++++++++++++++++Saving data++++++++++++++++++++++");
    console.log(data);
    axios(CONFIG.MAIN_URL + '/risk/save', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(function (response) {

        if (riskIdvalue != 0) {
          alert("Risk Updated successfully");
        } else {
          alert("Risk saved successfully");
        }

        setRiskTypeData(response.data);
        console.log(JSON.stringify(riskTypeData));
        setSaveRiskFlag(true);
        clearFuctionForUpdate();
        getAllRisks(golobalCentreId);
        setRiskIdvalue(0);


      })
      .catch(function (error) {
        console.log(error);
        alert("Error saving Risk Type -:" + error);
      });

  }

  /** End of previous collection for editing */

  /**Comment section for Risk Tree*/
  function Comment({ comment, lastRiskTmpId }) {
    const [subLastRiskTmpId, setSubLastRiskTmpId] = React.useState(lastRiskTmpId);
    const [show, setShow] = useState(false)
    const nestedComments = (comment.subRisks || []).map(comment => {
      return <Comment key={comment.riskTypeTmpId} comment={comment} lastRiskTmpId={subLastRiskTmpId} type="child" />
    })


    const [appetiteColorForSaveToDbEdit, setAppetiteColorForSaveToDbEdit] = useState("")

    const [appetiteColorForSaveToDbSubRisk, setAppetiteColorForSaveToDbSubRisk] = useState("")

    function getSubColorCode(number) {
      var cCode = "#ffffff";
      databClr.forEach((data) => {
        if (parseInt(number) === parseInt(data.scoreNumber)) {
          cCode = data.color;
        }
      })
      return cCode;
    }

    function getSubColorCodeAppetite(number, colorScheme) {
      var cCode = "#ffffff";
      databClr.forEach((data) => {
        if (colorScheme === 1) {
          if (parseInt(number) === parseInt(data.scoreNumber)) {
            cCode = data.color;
            setAppetiteColorForSaveToDbEdit(cCode)
          }
        } else {
          if (parseInt(number) === parseInt(data.scoreNumber)) {
            cCode = data.secondaryColor;
            setAppetiteColorForSaveToDbEdit(cCode)
          }
        }
      })
      return cCode;
    }

    function getSubColorCodeAppetiteSubRisk(number, colorScheme) {
      var cCode = "#ffffff";
      databClr.forEach((data) => {
        if (colorScheme === 1) {
          if (parseInt(number) === parseInt(data.scoreNumber)) {
            cCode = data.color;
            setAppetiteColorForSaveToDbSubRisk(cCode)
          }
        } else {
          if (parseInt(number) === parseInt(data.scoreNumber)) {
            cCode = data.secondaryColor;
            setAppetiteColorForSaveToDbSubRisk(cCode)
          }
        }
      })
      return cCode;
    }


    function setRiskLvlEditFunction(val, min, max, type) {
      var posNumber = val - min;
      var totalNumber = max - min;
      var percentageVal = (posNumber / totalNumber) * 100
      var cCode = getSubColorCode(val);
      var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
      if (type === 1) {
        setRiskLvlEdit(val);
        setRiskLvlEditStyle(cssStr);
      }
      if (type === 2) {
        setappetiteEdit(val);
        cCode = getSubColorCodeAppetite(val, selectedColorForAppetiteSliderEdit);
        cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
        setappetiteEditStyle(cssStr);
      }
    }






    function setSubRiskLevelValueFunction(val, min, max, type) {
      var posNumber = val - min;
      var totalNumber = max - min;
      var percentageVal = (posNumber / totalNumber) * 100
      var cCode = getSubColorCode(val);
      var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
      if (type === 1) {
        setSubRiskLevelValue(val);
        setSubRiskLevelValueStyle(cssStr);
      }
      if (type === 2) {
        setSubRiskAppetite(val);
        cCode = getSubColorCodeAppetiteSubRisk(val, selectedColorForAppetiteSliderSubRisk);
        cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';

        setSubRiskAppetiteStyle(cssStr);
      }
    }


























    /**
    * Create Sub Risk
    */
    const [subRiskName, setSubRiskName] = React.useState('');
    const [subRiskLevelType, setSubRiskLevelType] = React.useState();
    const [subRiskAppetite, setSubRiskAppetite] = React.useState();
    const [subRiskAppetiteStyle, setSubRiskAppetiteStyle] = React.useState('');
    const [subRiskId, setSubRiskId] = React.useState();
    const [subRiskOpen, setSubRiskOpen] = React.useState(false);
    const [subRiskScroll, setSubRiskScroll] = React.useState('paper');
    const [subRiskLvlShow, setSubRiskLvlShow] = React.useState(true);
    const [subRiskDropDown, setSubRiskDropDown] = React.useState();
    const [subRiskLevelValue, setSubRiskLevelValue] = React.useState();
    const [subRiskLevelValueStyle, setSubRiskLevelValueStyle] = React.useState('');


    const [subAppetitesShowSelectBar, setSubAppetitesShowSelectBar] = useState(true)


    const [subAppetiteSliderType, setSubAppetiteSliderType] = useState()

    const [subAppetitesSliderValue, setSubAppetiteSliderValue] = useState()

    const [selectedColorForAppetiteSliderSubRisk, setSelectedColorForAppetiteSliderSubRisk] = useState(1)

    const radioHandlerAppetiteSubEdit = (status) => {
      if (selectedColorForAppetiteSliderSubRisk != status) {
        setSubRiskAppetite('')
        setSubAppetitesSliderValueName('')
        setSubRiskAppetiteStyle('');
        setSelectedColorForAppetiteSliderSubRisk(status);
      }
    };



    const subAppetiteHandleChange = (event) => {
      console.log(event.target.value)
      setSubAppetiteSliderType(event.target.value);
      setSubAppetitesShowSelectBar(false)

    };



    const subRiskHandleClickClose = () => {
      setSubRiskAppetiteStyle('')
      setSubRiskLevelValueStyle('')
      setSubRiskName()

      setSubRiskOpen(false);
      //setSubAppetiteSliderType()
      subAppeClearFunction()
      subRiskClearFunction()
      setOpenStandardGridAddSubRisk(false);
      //openStandardGridAddSubRisk
    };
    const handleChangeSubRisk = (event) => {
      setSubRiskName(event.target.value);
      setSubRiskId(event.target.id);
    };
    const handleChangeSubRiskLevel = (event) => {
      setSubRiskLevelType(event.target.value);
      setSubRiskLvlShow(false)

      riskCategoryMethodData.forEach((data) => {
        if (data.id === event.target.value) {
          var colorPatId = data.colorPatternId;
          if (data.colorPatternId != undefined && data.colorPatternId != null) {
            colorPatterns.forEach((item) => {
              if (item.id === data.colorPatternId) {
                setDatabClr(item.colors);
              }
            })
          }
        }
      })



      if (subAppetiteSliderType === undefined || subAppetiteSliderType === '') {
        setSubAppetiteSliderType(event.target.value);
        setSubAppetitesShowSelectBar(false);
      }



    };
    const handleChangeSubRiskAppetite = (event) => {
      // setSubRiskAppetite(value);

    };

    function subRiskClearFunction() {
      setSubRiskLvlShow(true)
      setSubRiskLevelType()
      setSubRiskLevelValue()
      setSubRiskSliderValueName('')
      setSubRiskLevelValueStyle('');

    }

    function subAppeClearFunction() {
      setSubAppetitesShowSelectBar(true)
      setSubAppetiteSliderType()
      setSubRiskAppetite()
      setSubRiskAppetiteStyle('');
      setSelectedColorForAppetiteSliderSubRisk(1);
      setSubAppetitesSliderValueName('');
    }




    function setSubRiskFunction(event) {
      if (subRiskName === "") {
        alert("Please enter a Main Risk");
        return
      }
      if (subRiskLevelValue === undefined) {
        alert("Please enter a Risk level");
        return
      }
      if (subRiskAppetite === undefined) {
        alert("Please enter a Appetite Level ");
        return
      }

      if (document.getElementById('chkStandardAddSubRisk').checked) {
        if (objectiveIdForAddSubRisk === '' && componentIdForAddSubRisk === '' && principleIdForAddSubRisk === '') {
          alert("Please select an Oblective, a component or a Principle ");
          return
        }
      }



      setSubRiskName('');
      setSubRiskOpen(false);
      console.log(comment.subRisks);
      console.log(subLastRiskTmpId);
      if (comment.subRisks === undefined) {
        var arryObj = [];
        var tmpArray = {};
        tmpArray["riskTypeTmpId"] = subLastRiskTmpId + 1;
        tmpArray["name"] = subRiskName;
        tmpArray["riskLevel"] = subRiskLevelValue;
        var tmpArray1 = {}

        tmpArray1["riskLevel"] = subRiskLevelValue
        tmpArray1["riskCategoryId"] = riskCategoryId
        tmpArray1["riskMethodId"] = subRiskLevelType
        tmpArray["riskLevelData"] = tmpArray1;


        tmpArray["riskAppetite"] = subRiskAppetite;

        var tmpArrayApp = {}

        tmpArrayApp["riskAppetite"] = subRiskAppetite
        tmpArrayApp["riskCategoryId"] = riskCategoryIdAppetite
        tmpArrayApp["riskMethodId"] = subAppetiteSliderType
        if (selectedColorForAppetiteSliderSubRisk === 1) {
          tmpArrayApp["color"] = appetiteColorForSaveToDbSubRisk
          tmpArrayApp["secondaryColor"] = ""
        } else {
          tmpArrayApp["color"] = ""
          tmpArrayApp["secondaryColor"] = appetiteColorForSaveToDbSubRisk
        }
        tmpArray["riskAppetiteData"] = tmpArrayApp;


        //For Standared
        if (document.getElementById('chkStandardAddSubRisk').checked) {
          var tmpRiskStandardData = {};
          var takenStd = false;
          if (objectiveIdForAddSubRisk != '') {
            takenStd = true;
            var tmpStdData = {};
            tmpStdData["objectiveId"] = objectiveIdForAddSubRisk;
            tmpStdData["objectiveName"] = objectiveNameForAddSubRisk;
            if (globalStandardDataForMainRiskAddSubRisk != '') {
              tmpStdData["standardId"] = globalStandardDataForMainRiskAddSubRisk;
              tmpStdData["standardName"] = globalStandardDataNameForMainRiskAddSubRisk;
            }
            if (customStandardDataForMainRiskAddSubRisk != '') {
              tmpStdData["standardId"] = customStandardDataForMainRiskAddSubRisk;
              tmpStdData["standardName"] = customStandardDataNameForMainRiskAddSubRisk;
            }
            tmpRiskStandardData["objectiveData"] = tmpStdData;
          }
          if (componentIdForAddSubRisk != '') {
            takenStd = true;
            var tmpStdData = {};
            tmpStdData["componentId"] = componentIdForAddSubRisk;
            tmpStdData["componentName"] = componentNameForAddSubRisk;
            if (globalStandardDataForMainRiskAddSubRisk != '') {
              tmpStdData["standardId"] = globalStandardDataForMainRiskAddSubRisk;
              tmpStdData["standardName"] = globalStandardDataNameForMainRiskAddSubRisk;
            }
            if (customStandardDataForMainRiskAddSubRisk != '') {
              tmpStdData["standardId"] = customStandardDataForMainRiskAddSubRisk;
              tmpStdData["standardName"] = customStandardDataNameForMainRiskAddSubRisk;
            }
            tmpRiskStandardData["componentData"] = tmpStdData;
          }
          if (principleIdForAddSubRisk != '') {
            takenStd = true;
            var tmpStdData = {};
            tmpStdData["principleId"] = principleIdForAddSubRisk;
            tmpStdData["principleName"] = principleNameForAddSubRisk;
            if (globalStandardDataForMainRiskAddSubRisk != '') {
              tmpStdData["standardId"] = globalStandardDataForMainRiskAddSubRisk;
              tmpStdData["standardName"] = globalStandardDataNameForMainRiskAddSubRisk;
            }
            if (customStandardDataForMainRiskAddSubRisk != '') {
              tmpStdData["standardId"] = customStandardDataForMainRiskAddSubRisk;
              tmpStdData["standardName"] = customStandardDataNameForMainRiskAddSubRisk;
            }
            tmpRiskStandardData["principleData"] = tmpStdData;
          }
          if (takenStd) {
            tmpArray["riskStandardData"] = tmpRiskStandardData;
          }
        }


        arryObj.push(tmpArray);
        comment["subRisks"] = arryObj;
        setSubLastRiskTmpId(subLastRiskTmpId + 1);
        setShow(true);
      } else {
        var tmpArray = {};
        tmpArray["riskTypeTmpId"] = subLastRiskTmpId + 1;
        tmpArray["name"] = subRiskName;
        tmpArray["riskLevel"] = subRiskLevelValue;

        var tmpArray1 = {}

        tmpArray1["riskLevel"] = subRiskLevelValue
        tmpArray1["riskCategoryId"] = riskCategoryId
        tmpArray1["riskMethodId"] = subRiskLevelType
        tmpArray["riskLevelData"] = tmpArray1;

        tmpArray["riskAppetite"] = subRiskAppetite;

        var tmpArrayApp = {}

        tmpArrayApp["riskAppetite"] = subRiskAppetite
        tmpArrayApp["riskCategoryId"] = riskCategoryIdAppetite
        tmpArrayApp["riskMethodId"] = subAppetiteSliderType
        if (selectedColorForAppetiteSliderSubRisk === 1) {
          tmpArrayApp["color"] = appetiteColorForSaveToDbSubRisk
          tmpArrayApp["secondaryColor"] = ""
        } else {
          tmpArrayApp["color"] = ""
          tmpArrayApp["secondaryColor"] = appetiteColorForSaveToDbSubRisk
        }
        tmpArray["riskAppetiteData"] = tmpArrayApp;


        //For Standared
        if (document.getElementById('chkStandardAddSubRisk').checked) {
          var tmpRiskStandardData = {};
          var takenStd = false;
          if (objectiveIdForAddSubRisk != '') {
            takenStd = true;
            var tmpStdData = {};
            tmpStdData["objectiveId"] = objectiveIdForAddSubRisk;
            tmpStdData["objectiveName"] = objectiveNameForAddSubRisk;
            if (globalStandardDataForMainRiskAddSubRisk != '') {
              tmpStdData["standardId"] = globalStandardDataForMainRiskAddSubRisk;
              tmpStdData["standardName"] = globalStandardDataNameForMainRiskAddSubRisk;
            }
            if (customStandardDataForMainRiskAddSubRisk != '') {
              tmpStdData["standardId"] = customStandardDataForMainRiskAddSubRisk;
              tmpStdData["standardName"] = customStandardDataNameForMainRiskAddSubRisk;
            }
            tmpRiskStandardData["objectiveData"] = tmpStdData;
          }
          if (componentIdForAddSubRisk != '') {
            takenStd = true;
            var tmpStdData = {};
            tmpStdData["componentId"] = componentIdForAddSubRisk;
            tmpStdData["componentName"] = componentNameForAddSubRisk;
            if (globalStandardDataForMainRiskAddSubRisk != '') {
              tmpStdData["standardId"] = globalStandardDataForMainRiskAddSubRisk;
              tmpStdData["standardName"] = globalStandardDataNameForMainRiskAddSubRisk;
            }
            if (customStandardDataForMainRiskAddSubRisk != '') {
              tmpStdData["standardId"] = customStandardDataForMainRiskAddSubRisk;
              tmpStdData["standardName"] = customStandardDataNameForMainRiskAddSubRisk;
            }
            tmpRiskStandardData["componentData"] = tmpStdData;
          }
          if (principleIdForAddSubRisk != '') {
            takenStd = true;
            var tmpStdData = {};
            tmpStdData["principleId"] = principleIdForAddSubRisk;
            tmpStdData["principleName"] = principleNameForAddSubRisk;
            if (globalStandardDataForMainRiskAddSubRisk != '') {
              tmpStdData["standardId"] = globalStandardDataForMainRiskAddSubRisk;
              tmpStdData["standardName"] = globalStandardDataNameForMainRiskAddSubRisk;
            }
            if (customStandardDataForMainRiskAddSubRisk != '') {
              tmpStdData["standardId"] = customStandardDataForMainRiskAddSubRisk;
              tmpStdData["standardName"] = customStandardDataNameForMainRiskAddSubRisk;
            }
            tmpRiskStandardData["principleData"] = tmpStdData;
          }
          if (takenStd) {
            tmpArray["riskStandardData"] = tmpRiskStandardData;
          }
        }


        comment.subRisks.push(tmpArray);
        setSubLastRiskTmpId(subLastRiskTmpId + 1);
      }
      setOpenStandardGridAddSubRisk(false)
    }
    /** Sub Risk Add dialog */
    const subRiskHandleClickOpen = (scrollType, comment) => () => {
      console.log(comment);
      if (comment.riskStandardData != undefined && comment.riskStandardData != null) {
        var foundStdId = false;
        if (comment.riskStandardData.objectiveData != undefined && comment.riskStandardData.objectiveData != null) {
          setObjectiveId(comment.riskStandardData.objectiveData.objectiveId);
          globalStandardData.forEach((data) => {
            if (data.standardId === comment.riskStandardData.objectiveData.standardId) {
              foundStdId = true;
              setGlobalStandardDataForMainRisk(data.standardId)
              setCustomStandardDataForMainRisk('')
            }
          })
          if (!foundStdId) {
            customStandardData.forEach((data) => {
              if (data.standardId === comment.riskStandardData.objectiveData.standardId) {
                foundStdId = true;
                setGlobalStandardDataForMainRisk('')
                setCustomStandardDataForMainRisk(data.standardId)
              }
            })
          }
        }
        if (comment.riskStandardData.componentData != undefined && comment.riskStandardData.componentData != null) {
          setComponentId(comment.riskStandardData.componentData.componentId);
          if (!foundStdId) {
            globalStandardData.forEach((data) => {
              if (data.standardId === comment.riskStandardData.componentData.standardId) {
                foundStdId = true;
                setGlobalStandardDataForMainRisk(data.standardId)
                setCustomStandardDataForMainRisk('')
              }
            })
            if (!foundStdId) {
              customStandardData.forEach((data) => {
                if (data.standardId === comment.riskStandardData.componentData.standardId) {
                  foundStdId = true;
                  setGlobalStandardDataForMainRisk('')
                  setCustomStandardDataForMainRisk(data.standardId)
                }
              })
            }
          }
        }
        if (comment.riskStandardData.principleData != undefined && comment.riskStandardData.principleData != null) {
          setPrincipleId(comment.riskStandardData.principleData.principleId);
          if (!foundStdId) {
            globalStandardData.forEach((data) => {
              if (data.standardId === comment.riskStandardData.principleData.standardId) {
                foundStdId = true;
                setGlobalStandardDataForMainRisk(data.standardId)
                setCustomStandardDataForMainRisk('')
              }
            })
            if (!foundStdId) {
              customStandardData.forEach((data) => {
                if (data.standardId === comment.riskStandardData.principleData.standardId) {
                  foundStdId = true;
                  setGlobalStandardDataForMainRisk('')
                  setCustomStandardDataForMainRisk(data.standardId)
                }
              })
            }
          }
        }
      }
      setSubRiskOpen(true);
      setSubRiskScroll(scrollType);
    };




    //sub appetite








    /** End of Create subRisk */

    /**
    * Edit Risk
    */
    const [editRiskName, setEditRiskName] = React.useState();
    const [editRiskLevel, setEditRiskLevel] = React.useState();
    const [editRiskAppetite, setEditRiskAppetite] = React.useState();

    const [editRiskId, setEditRiskId] = React.useState();
    const [editRiskOpen, setEditRiskOpen] = React.useState(false);
    const [editRiskScroll, setEditRiskScroll] = React.useState('paper');
    const editRiskHandleClickClose = () => {
      setEditRiskOpen(false);
      setShowRiskDropDown(false)
      setShowAppetiteDropDown(false)

    };
    const handleChangeEditRisk = (event) => {
      setEditRiskName(event.target.value);
      setEditRiskId(event.target.id);

    };
    const handleChangeEditRiskLevel = (event) => {
      const value = event.target.value.replace(/\D/g, "");
      if ((value != 0 && value < 11) || value === "") {
        setEditRiskLevel(value);
      }
    };
    const handleChangeEditRiskAppetite = (event) => {
      const value = event.target.value.replace(/\D/g, "");
      if ((value != 0 && value < 11) || value === "") {
        setEditRiskAppetite(value);
      }
    };

    function setEditRiskFunction(event) {

      //alert("sssssssss")

      if (editRiskName === "") {
        alert("Please Edit the Risk");
        return
      }
      if (riskLvlEdit === undefined) {
        alert("Please Edit the Risk level");
        return
      }
      if (appetiteEdit === undefined) {
        alert("Please Edit the Risk Appetite");
        return
      }

      setEditRiskName('');
      // setEditRiskLevel();
      // setEditRiskAppetite();
      setEditRiskOpen(false);
      setRiskLvlEdit()
      setappetiteEdit()
      setSliderTypeEdit()
      setEditSliderMthodType()
      setEditAppetiteSliderMthod()
      console.log(comment.subRisks);
      comment.name = editRiskName;
      comment.riskLevel = riskLvlEdit;
      comment.riskAppetite = appetiteEdit;
      comment.riskLevelData.riskMethodId = editSliderMthodType;
      comment.riskAppetiteData.riskMethodId = editSliderMthodType
      if (selectedColorForAppetiteSliderEdit === 1) {
        comment.riskAppetiteData.color = appetiteColorForSaveToDbEdit;
        comment.riskAppetiteData.secondaryColor = "";
      }
      else {
        comment.riskAppetiteData.color = "";
        comment.riskAppetiteData.secondaryColor = appetiteColorForSaveToDbEdit;
      }

      // Editing Stds
      if (chkStandardEditChecked) {
        if (objectiveIdForEdit != '') {
          if (comment.riskStandardData === undefined || comment.riskStandardData === null || comment.riskStandardData.objectiveData === undefined || comment.riskStandardData.objectiveData === null) {
            if (comment.riskStandardData === undefined || comment.riskStandardData === null) {
              //alert("111111")
              //alert(globalStandardDataNameForMainRisk);
              var tmpStdData = {};
              comment["riskStandardData"] = {};
              tmpStdData = {};
              tmpStdData["objectiveId"] = objectiveIdForEdit;
              tmpStdData["objectiveName"] = objectiveNameForEdit;
              if (globalStandardDataForMainRiskEdit != '') {
                tmpStdData["standardId"] = globalStandardDataForMainRiskEdit;
                tmpStdData["standardName"] = globalStandardDataNameForMainRiskEdit;
              }
              if (customStandardDataForMainRiskEdit != '') {
                tmpStdData["standardId"] = customStandardDataForMainRiskEdit;
                tmpStdData["standardName"] = customStandardDataNameForMainRiskEdit;
              }
              comment.riskStandardData["objectiveData"] = tmpStdData
            } else {
              if (comment.riskStandardData.objectiveData === undefined || comment.riskStandardData.objectiveData === null) {
                //alert("22222")
                var tmpStdData = {};
                tmpStdData["objectiveId"] = objectiveIdForEdit;
                tmpStdData["objectiveName"] = objectiveNameForEdit;
                if (globalStandardDataForMainRiskEdit != '') {
                  tmpStdData["standardId"] = globalStandardDataForMainRiskEdit;
                  tmpStdData["standardName"] = globalStandardDataNameForMainRiskEdit;
                }
                if (customStandardDataForMainRiskEdit != '') {
                  tmpStdData["standardId"] = customStandardDataForMainRiskEdit;
                  tmpStdData["standardName"] = customStandardDataNameForMainRiskEdit;
                }
                comment.riskStandardData["objectiveData"] = tmpStdData
              }
            }
          } else {
            //alert("33333")
            comment.riskStandardData.objectiveData.objectiveId = objectiveIdForEdit;
            comment.riskStandardData.objectiveData.objectiveName = objectiveNameForEdit;
            if (globalStandardDataForMainRiskEdit != '') {
              comment.riskStandardData.objectiveData.standardId = globalStandardDataForMainRiskEdit;
              comment.riskStandardData.objectiveData.standardName = globalStandardDataNameForMainRiskEdit;
            }
            if (customStandardDataForMainRiskEdit != '') {
              comment.riskStandardData.objectiveData.standardId = customStandardDataForMainRiskEdit;
              comment.riskStandardData.objectiveData.standardName = customStandardDataNameForMainRiskEdit;
            }
          }
        }
        if (componentIdForEdit != '') {
          if (comment.riskStandardData === undefined || comment.riskStandardData === null || comment.riskStandardData.componentData === undefined || comment.riskStandardData.componentData === null) {
            if (comment.riskStandardData === undefined || comment.riskStandardData === null) {
              comment["riskStandardData"] = {};
              var tmpStdData = {};
              tmpStdData["componentId"] = componentIdForEdit;
              tmpStdData["componentName"] = componentNameForEdit;
              if (globalStandardDataForMainRiskEdit != '') {
                tmpStdData["standardId"] = globalStandardDataForMainRiskEdit;
                tmpStdData["standardName"] = globalStandardDataNameForMainRiskEdit;
              }
              if (customStandardDataForMainRiskEdit != '') {
                tmpStdData["standardId"] = customStandardDataForMainRiskEdit;
                tmpStdData["standardName"] = customStandardDataNameForMainRiskEdit;
              }
              comment.riskStandardData["componentData"] = tmpStdData
            } else {
              if (comment.riskStandardData.componentData === undefined || comment.riskStandardData.componentData === null) {
                var tmpStdData = {};
                tmpStdData["componentId"] = componentIdForEdit;
                tmpStdData["componentName"] = componentNameForEdit;
                if (globalStandardDataForMainRiskEdit != '') {
                  tmpStdData["standardId"] = globalStandardDataForMainRiskEdit;
                  tmpStdData["standardName"] = globalStandardDataNameForMainRiskEdit;
                }
                if (customStandardDataForMainRiskEdit != '') {
                  tmpStdData["standardId"] = customStandardDataForMainRiskEdit;
                  tmpStdData["standardName"] = customStandardDataNameForMainRiskEdit;
                }
                comment.riskStandardData["componentData"] = tmpStdData
              }
            }
          } else {
            comment.riskStandardData.componentData.componentId = componentIdForEdit;
            comment.riskStandardData.componentData.componentName = componentNameForEdit;
            if (globalStandardDataForMainRiskEdit != '') {
              comment.riskStandardData.componentData.standardId = globalStandardDataForMainRiskEdit;
              comment.riskStandardData.componentData.standardName = globalStandardDataNameForMainRiskEdit;
            }
            if (customStandardDataForMainRiskEdit != '') {
              comment.riskStandardData.componentData.standardId = customStandardDataForMainRiskEdit;
              comment.riskStandardData.componentData.standardName = customStandardDataNameForMainRiskEdit;
            }
          }
        }
        if (principleIdForEdit != '') {
          if (comment.riskStandardData === undefined || comment.riskStandardData === null || comment.riskStandardData.principleData === undefined || comment.riskStandardData.principleData === null) {
            if (comment.riskStandardData === undefined || comment.riskStandardData === null) {
              comment["riskStandardData"] = {};
              var tmpStdData = {};
              tmpStdData["principleId"] = principleIdForEdit;
              tmpStdData["principleName"] = principleNameForEdit;
              if (globalStandardDataForMainRiskEdit != '') {
                tmpStdData["standardId"] = globalStandardDataForMainRiskEdit;
                tmpStdData["standardName"] = globalStandardDataNameForMainRiskEdit;
              }
              if (customStandardDataForMainRiskEdit != '') {
                tmpStdData["standardId"] = customStandardDataForMainRiskEdit;
                tmpStdData["standardName"] = customStandardDataNameForMainRiskEdit;
              }
              comment.riskStandardData["principleData"] = tmpStdData
            } else {
              if (comment.riskStandardData.principleData === undefined || comment.riskStandardData.principleData === null) {
                var tmpStdData = {};
                tmpStdData["principleId"] = principleIdForEdit;
                tmpStdData["principleName"] = principleNameForEdit;
                if (globalStandardDataForMainRiskEdit != '') {
                  tmpStdData["standardId"] = globalStandardDataForMainRiskEdit;
                  tmpStdData["standardName"] = globalStandardDataNameForMainRiskEdit;
                }
                if (customStandardDataForMainRiskEdit != '') {
                  tmpStdData["standardId"] = customStandardDataForMainRiskEdit;
                  tmpStdData["standardName"] = customStandardDataNameForMainRiskEdit;
                }
                comment.riskStandardData["principleData"] = tmpStdData
              }
            }
          } else {
            comment.riskStandardData.principleData.principleId = principleIdForEdit;
            comment.riskStandardData.principleData.principleName = principleNameForEdit;
            if (globalStandardDataForMainRiskEdit != '') {
              comment.riskStandardData.principleData.standardId = globalStandardDataForMainRiskEdit;
              comment.riskStandardData.principleData.standardName = globalStandardDataNameForMainRiskEdit;
            }
            if (customStandardDataForMainRiskEdit != '') {
              comment.riskStandardData.principleData.standardId = customStandardDataForMainRiskEdit;
              comment.riskStandardData.principleData.standardName = customStandardDataNameForMainRiskEdit;
            }
          }
        }
      } else {
        comment.riskStandardData = null;
      }


      setShow(true);
      // setShowSelectBarEdit(false)


    }

    const [selectedColorForAppetiteSliderEdit, setSelectedColorForAppetiteSliderEdit] = useState("")

    const [idForNullObj, setIdForNullObj] = useState('')

    const editRiskHandleClickOpen = (scrollType) => () => {

      console.log(comment)

      setEditRiskOpen(true);
      // setEditRiskScroll(scrollType);
      setEditRiskName(comment.name);
      // setEditRiskLevel(comment.riskLevel);
      // setEditRiskAppetite(comment.riskAppetite);
      setRiskLvlEdit(comment.riskLevel);
      setappetiteEdit(comment.riskAppetite)
      // setSliderTypeEdit(comment.riskLevelData.riskMethodId)
      setEditSliderMthodType(comment.riskLevelData.riskMethodId)
      setEditAppetiteSliderMthod(comment.riskAppetiteData.riskMethodId)
      setShowRiskDropDown(false)
      setShowAppetiteDropDown(false)


      //alert(comment.riskAppetiteData.color)
      //alert(comment.riskAppetiteData.secondaryColor)
      var colorScheme = 1;
      if (comment.riskAppetiteData.color != null && comment.riskAppetiteData.color != undefined && comment.riskAppetiteData.color != "") {
        setSelectedColorForAppetiteSliderEdit(1)
      } else if (comment.riskAppetiteData.secondaryColor != null && comment.riskAppetiteData.secondaryColor != undefined && comment.riskAppetiteData.secondaryColor != "") {
        setSelectedColorForAppetiteSliderEdit(2)
        colorScheme = 2;
      }

      /* To set slider Type name to risklevel when Editing (setRiskLvlSliderValueNameEdit)*/

      if (comment.riskLevelData != undefined && comment.riskLevelData != null && comment.riskLevelData.riskMethodId != undefined && comment.riskLevelData.riskMethodId != null && comment.riskLevelData.riskMethodId != '' &&        comment.riskAppetiteData != undefined && comment.riskAppetiteData != null && comment.riskAppetiteData.riskMethodId != undefined && comment.riskAppetiteData.riskMethodId != null && comment.riskAppetiteData.riskMethodId != ''){

      var min = 0;
      var max = 0;
      riskCategoryMethodData.forEach((data) => {
        if (comment.riskLevelData.riskMethodId === data.id) {
          data.riskTypes.forEach((data1) => {
            if (data1.startingNumber <= comment.riskLevel && comment.riskLevel <= data1.endingNumber) {
              setRiskLvlSliderValueNameEdit(data1.riskTypesName)
              //console.log("+++++++++++++++++++++++data1+++++++++++++++++++");
              //console.log(data1);

              min = data.minimumWeightingNumber;
              max = data.maximumWeightingNumber;
            }
          })
        }
      })



      var posNumber = comment.riskLevel - min;
      var totalNumber = max - min;
      var percentageVal = (posNumber / totalNumber) * 100
      var cCode = getSubColorCode(comment.riskLevel);
      var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
      //alert(cssStr);
      setRiskLvlEditStyle(cssStr);



      /* To set slider Type name to riskAppetite when Editing (setSubAppetitesSliderValueName)*/
      riskCategoryMethodDataAppetite.forEach((data) => {
        if (comment.riskAppetiteData.riskMethodId === data.id) {
          data.riskTypes.forEach((data1) => {
            if (data1.startingNumber <= comment.riskAppetite && comment.riskAppetite <= data1.endingNumber) {
              setAppetiteSliderValueNameEdit(data1.riskTypesName)
              min = data.minimumWeightingNumber;
              max = data.maximumWeightingNumber;
            }
          })
        }
      })

      var posNumber1 = comment.riskAppetite - min;
      var totalNumber1 = max - min;
      var percentageVal1 = (posNumber1 / totalNumber1) * 100
      var cCode1 = getSubColorCodeAppetite(comment.riskAppetite, colorScheme);
      var cssStr1 = 'linear-gradient(90deg, ' + cCode1 + ' ' + percentageVal1 + '%, #d3d3d3 0%)';

      setappetiteEditStyle(cssStr1);
    }else{
      setShowRiskDropDown(true)
      setShowAppetiteDropDown(true)
    }

      //Set Standared for Editing

      if (comment.riskStandardData != undefined && comment.riskStandardData != null) {
        var foundStdId = false;
        var foundStdData = false;
        console.log(comment)
        if (comment.riskStandardData.objectiveData != undefined && comment.riskStandardData.objectiveData != null) {


          if (comment.riskStandardData.objectiveData.standardId != null) {
            setObjectiveIdForEdit(comment.riskStandardData.objectiveData.objectiveId);
            setObjectiveNameForEdit(comment.riskStandardData.objectiveData.objectiveName);
            foundStdData = true;

            globalStandardData.forEach((data) => {

              if (data.standardId === comment.riskStandardData.objectiveData.standardId) {
                console.log("+++++++++++++++++++++++++data7777777++++++++++++++++++");
                console.log(data);
                foundStdId = true;
                setGlobalStandardDataForMainRiskEdit(data.standardId);
                setGlobalStandardDataNameForMainRiskEdit(data.standardName);
                setCustomStandardDataForMainRiskEdit('')
              }
            })
            if (!foundStdId) {
              customStandardData.forEach((data) => {
                if (data.standardId === comment.riskStandardData.objectiveData.standardId) {
                  foundStdId = true;
                  setGlobalStandardDataForMainRiskEdit('')
                  setCustomStandardDataForMainRiskEdit(data.standardId)
                  setCustomStandardDataNameForMainRiskEdit(data.standardName)
                }
              })
            }

          } else {

            setObjectiveIdForEdit(5000);
            foundStdData = true;
            setGlobalStandardDataForMainRiskEdit('')
            setCustomStandardDataForMainRiskEdit('')


          }


        }

        if (comment.riskStandardData.componentData != undefined && comment.riskStandardData.componentData != null) {

          if (comment.riskStandardData.componentData.standardId != null) {
            setComponentIdForEdit(comment.riskStandardData.componentData.componentId);
            setComponentNameForEdit(comment.riskStandardData.componentData.componentName);
            foundStdData = true;
            if (!foundStdId) {
              globalStandardData.forEach((data) => {
                if (data.standardId === comment.riskStandardData.componentData.standardId) {
                  foundStdId = true;
                  setGlobalStandardDataForMainRiskEdit(data.standardId);
                  setGlobalStandardDataNameForMainRiskEdit(data.standardName);
                  setCustomStandardDataForMainRiskEdit('')
                }
              })
              if (!foundStdId) {
                customStandardData.forEach((data) => {
                  if (data.standardId === comment.riskStandardData.componentData.standardId) {
                    foundStdId = true;
                    setGlobalStandardDataForMainRiskEdit('')
                    setCustomStandardDataForMainRiskEdit(data.standardId)
                    setCustomStandardDataNameForMainRiskEdit(data.standardName)
                  }
                })
              }
            }
          }
          else {
            setComponentIdForEdit(5000);
            foundStdData = true;
            setGlobalStandardDataForMainRiskEdit('')
            setCustomStandardDataForMainRiskEdit('')

          }
        }
        if (comment.riskStandardData.principleData != undefined && comment.riskStandardData.principleData != null) {

          if (comment.riskStandardData.principleData.standardId != null) {
            setPrincipleIdForEdit(comment.riskStandardData.principleData.principleId);
            setPrincipleNameForEdit(comment.riskStandardData.principleData.principleName);
            foundStdData = true;
            if (!foundStdId) {
              globalStandardData.forEach((data) => {
                if (data.standardId === comment.riskStandardData.principleData.standardId) {
                  foundStdId = true;
                  setGlobalStandardDataForMainRiskEdit(data.standardId);
                  setGlobalStandardDataNameForMainRiskEdit(data.standardName);
                  setCustomStandardDataForMainRiskEdit('')
                }
              })
              if (!foundStdId) {
                customStandardData.forEach((data) => {
                  if (data.standardId === comment.riskStandardData.principleData.standardId) {
                    foundStdId = true;
                    setGlobalStandardDataForMainRiskEdit('')
                    setCustomStandardDataForMainRiskEdit(data.standardId)
                    setCustomStandardDataNameForMainRiskEdit(data.standardName)
                  }
                })
              }
            }
          }
        } else {
          setPrincipleIdForEdit(5000);
          foundStdData = true;
          setGlobalStandardDataForMainRiskEdit('')
          setCustomStandardDataForMainRiskEdit('')
        }
      }
      if (foundStdData) {
        setChkStandardEditChecked(true);
        setOpenStandardGridEdit(true);
      } else {
        setChkStandardEditChecked(false);
      }







    };

    const radioHandlerAppetiteEdit = (status) => {
      if (selectedColorForAppetiteSliderEdit != status) {
        setappetiteEdit('')
        setAppetiteSliderValueNameEdit('')
        setappetiteEditStyle('');
        setSelectedColorForAppetiteSliderEdit(status);
      }
    };


    console.log(databClr)

    function getRiskRiskTypeName(Comment) {
      var xx = ''
      console.log(Comment)
      riskCategoryMethodData.forEach((data) => {
        if (data.id === Comment.riskLevelData.riskMethodId) {
          data.riskTypes.forEach((data1) => {
            if (data1.startingNumber <= Comment.riskLevel && Comment.riskLevel <= data1.endingNumber) {
              xx = data1.riskTypesName
            }
          })
        }

      })

      return xx


    }

    function getAppetiteRiskTypeName(Comment) {
      var xx = ''
      console.log(Comment)
      riskCategoryMethodDataAppetite.forEach((data) => {
        if (data.id === Comment.riskAppetiteData.riskMethodId) {
          data.riskTypes.forEach((data1) => {
            if (data1.startingNumber <= Comment.riskAppetite && Comment.riskAppetite <= data1.endingNumber) {
              xx = data1.riskTypesName
            }
          })
        }

      })

      return xx


    }



    function getRiskRiskColor(Comment) {
      var xx = ''

      databClr.forEach((data2) => {
        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
          xx = data2.color
        }
      })



      return xx
    }

    function getAppetiteColor(Comment) {
      var xx = ''

      databClr.forEach((data2) => {
        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
          if (Comment.riskAppetiteData != null && Comment.riskAppetiteData != undefined) {
            if (Comment.riskAppetiteData.color != null && Comment.riskAppetiteData.color != undefined && Comment.riskAppetiteData.color != '') {
              xx = Comment.riskAppetiteData.color;
            }
            if (Comment.riskAppetiteData.secondaryColor != null && Comment.riskAppetiteData.secondaryColor != undefined && Comment.riskAppetiteData.secondaryColor != '') {
              xx = Comment.riskAppetiteData.secondaryColor;
            }
          }
          if (xx === '') {
            xx = data2.color
          }
        }
      })



      return xx
    }




    /** End of Edit Risk */



    function XXXXXX({ comment }) {
      var riskName = getRiskRiskTypeName(comment)
      var appetiteName = getAppetiteRiskTypeName(comment)
      var riskColor = getRiskRiskColor(comment)
      var appetiteColor = getAppetiteColor(comment)

      console.log(riskColor)

      return (
        <>

          {comment.name} {" - (Risk : "}{' '} {comment.riskLevel}{" - "}
          {
            <span style={{ color: `${riskColor}` }}> <b>{riskName}</b> </span>

          }
          {" , "}  {" - Appetite : "} {comment.riskAppetite} {" - "}
          {
            <span style={{ color: `${appetiteColor}` }}> <b>{appetiteName}</b> </span>
          }
          {" ) "}




        </>
      )
    }

    function OnlyRiskLvl({ comment }) {
      var riskName = getRiskRiskTypeName(comment)
      var riskColor = getRiskRiskColor(comment)
      return (
        <>

          {comment.name} {" - (Risk : "}{' '} {comment.riskLevel}{" - "}
          {
            <span style={{ color: `${riskColor}` }}><b>{riskName}</b>  </span>
          }

          {" ) "}




        </>
      )
    }

    function OnlyAppetiteLvl({ comment }) {
      var appetiteName = getAppetiteRiskTypeName(comment)
      var appetiteColor = getAppetiteColor(comment)
      return (
        <>

          {comment.name} {" - ( Appetite : "} {appetitesSliderValue} {" - "}
          {
            <span style={{ color: `${appetiteColor}` }}> <b>{appetiteName}</b> </span>
          }
          {" ) "}




        </>
      )
    }

    const [objName, setObjName] = useState('')
    const [objId, setObjId] = useState(false)

    function DisplayStandared({ comment }) {

      console.log("+++++++++++++Athula+++++++comment++++++++++++++++++++++")
      console.log(comment)

      var objectiveId = '';
      var objectiveName = '';
      var componentId = '';
      var componentName = '';
      var principleId = '';
      var principleName = '';
      var standardId = '';
      var standardName = '';
      if (comment.riskStandardData != undefined && comment.riskStandardData != null) {
        if (comment.riskStandardData.objectiveData != undefined && comment.riskStandardData.objectiveData != null) {
          objectiveId = comment.riskStandardData.objectiveData.objectiveId;
          objectiveName = comment.riskStandardData.objectiveData.objectiveName;
          standardId = comment.riskStandardData.objectiveData.standardId;
          standardName = comment.riskStandardData.objectiveData.standardName;
        }
        if (comment.riskStandardData.componentData != undefined && comment.riskStandardData.componentData != null) {
          componentId = comment.riskStandardData.componentData.componentId;
          componentName = comment.riskStandardData.componentData.componentName;
          if (standardId === '') {
            standardId = comment.riskStandardData.componentData.standardId;
          }
          if (standardName === '') {
            standardName = comment.riskStandardData.componentData.standardName;
          }
        }
        if (comment.riskStandardData.principleData != undefined && comment.riskStandardData.principleData != null) {
          principleId = comment.riskStandardData.principleData.principleId;
          principleName = comment.riskStandardData.principleData.principleName;
          if (standardId === '') {
            standardId = comment.riskStandardData.principleData.standardId;
          }
          if (standardName === '') {
            standardName = comment.riskStandardData.principleData.standardName;
          }
        }
      }
      var altString = 'Standard Name : ' + standardName;
      if (objectiveName != '') {
        altString += '\nObjective Name : ' + objectiveName;
      }
      if (componentName != '') {
        altString += '\nComponent Name : ' + componentName;
      }
      if (principleName != '') {
        altString += '\nPrinciple Name : ' + principleName;
      }
      if (standardName != null && standardName.length > 10) {
        standardName = standardName.substring(0, 10) + '...';
      }

      console.log("++++++++++++++++++++++++++++altString+++++++++++++++++++++++");
      console.log(altString);
      console.log(standardName);
      console.log(objectiveName);
      console.log(objectiveId);

      if (objectiveId === null) {
        setObjectiveNameForEdit(objectiveName)
      }
      if (componentId === null) {
        setComponentNameForEdit(componentName)
      }
      if (principleId === null) {
        setPrincipleNameForEdit(principleName)
      }


      return (
        <>

          {
            <span title={`${altString}`} style={{ cursor: 'pointer' }}>&nbsp;{standardName}</span>
          }

        </>
      )
    }

    console.log(objName)

    const riskDisplayStr = (
      <>

        {comment.riskLevel != undefined && comment.riskLevel != null && comment.riskLevel != "" && comment.riskAppetite != undefined && comment.riskAppetite != null && comment.riskAppetite != "" &&
          (
            <XXXXXX

              comment={comment}
            />
          )}
        {comment.riskLevel != undefined && comment.riskLevel != null && comment.riskLevel != "" && (comment.riskAppetite === undefined || comment.riskAppetite === null || comment.riskAppetite === "" || comment.riskAppetite === 0) && (
          <OnlyRiskLvl
            comment={comment}
          />
        )}
        {(comment.riskLevel === undefined || comment.riskLevel === null || comment.riskLevel === "" || comment.riskLevel === 0) && comment.riskAppetite != undefined && comment.riskAppetite != null && comment.riskAppetite != "" && (
          <OnlyAppetiteLvl
            comment={comment}

          />
        )}
        {comment.riskStandardData != undefined && comment.riskStandardData != null ?
          <DisplayStandared
            comment={comment}
          />
          :
          ''
        }

        {(comment.riskLevel === undefined || comment.riskLevel === null || comment.riskLevel === "" || comment.riskLevel === 0) && (comment.riskAppetite === undefined || comment.riskAppetite === null || comment.riskAppetite === "" || comment.riskAppetite === 0) && (
          comment.name
        )}
      </>

    );


    //edit risk level 



    const [riskLvlEdit, setRiskLvlEdit] = useState();
    const [appetiteEdit, setappetiteEdit] = useState();

    const [riskLvlEditStyle, setRiskLvlEditStyle] = useState('');
    const [appetiteEditStyle, setappetiteEditStyle] = useState('')

    const [riskLvlSliderValueNameEdit, setRiskLvlSliderValueNameEdit] = React.useState('');
    const [appetitesSliderValueNameEdit, setAppetiteSliderValueNameEdit] = React.useState('');
    const [subAppetitesSliderValueName, setSubAppetitesSliderValueName] = React.useState('');
    const [subRiskSliderValueName, setSubRiskSliderValueName] = React.useState('');


    const [showSelectBarEdit, setShowSelectBarEdit] = React.useState(true);








    //console.log(sliderTypeEdit)




    //appetite edit 

    function appetiteNameChangeEdit(sVal) {
      var fondType = false;
      riskCategoryMethodDataAppetite.forEach((data) => {
        if (editAppetiteSliderMthod === data.id) {
          data.riskTypes.forEach((data1) => {
            if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
              setAppetiteSliderValueNameEdit(data1.riskTypesName)
              fondType = true;
            }
          })
        }
      })
      if (!fondType) {
        setAppetiteSliderValueNameEdit('');
        setappetiteEdit();
      }


    }




    //RISK LEVEL VALUE NAME edit

    function nameChangeRiskLvlEdit(sVal) {
      var fondType = false;
      riskCategoryMethodData.forEach((data) => {
        if (editSliderMthodType === data.id) {
          data.riskTypes.forEach((data1) => {
            if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
              setRiskLvlSliderValueNameEdit(data1.riskTypesName)
              fondType = true;
            }
          })
        }
      })
      if (!fondType) {
        setRiskLvlSliderValueNameEdit('');
        setRiskLvlEdit();
      }
    }


    function subRiskNameChange(sVal) {
      var fondType = false;
      riskCategoryMethodData.forEach((data) => {
        if (subRiskLevelType === data.id) {
          data.riskTypes.forEach((data1) => {
            if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
              setSubRiskSliderValueName(data1.riskTypesName)
              fondType = true;
            }
          })
        }
      })
      if (!fondType) {
        setSubRiskSliderValueName('');
        setSubRiskLevelValue();
      }
    }

    function subAppetiteNameChange(sVal) {
      var fondType = false;
      riskCategoryMethodDataAppetite.forEach((data) => {
        if (subAppetiteSliderType === data.id) {
          data.riskTypes.forEach((data1) => {
            if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
              setSubAppetitesSliderValueName(data1.riskTypesName)
              fondType = true;
            }
          })
        }
      })
      if (!fondType) {
        setSubAppetitesSliderValueName('');
        setSubRiskAppetite();
      }
    }





    const [showRiskDropDown, setShowRiskDropDown] = useState(false)

    const [showAppetiteDropDown, setShowAppetiteDropDown] = useState(false)

    const [editSliderMthodType, setEditSliderMthodType] = useState('')

    const [editAppetiteSliderMthod, setEditAppetiteSliderMthod] = useState('')

    function handleChangeRiskEdit(event) {
      riskCategoryMethodData.forEach((data) => {
        if (data.id === event.target.value) {
          var colorPatId = data.colorPatternId;
          if (colorPatId != undefined && colorPatId != null) {
            colorPatterns.forEach((item) => {
              if (item.id === colorPatId) {
                setDatabClr(item.colors);
              }
            })
          }
        }
      })

      setEditSliderMthodType(event.target.value);
      setShowRiskDropDown(false)


    };

    function riskClearFunctionEdit() {
      //setSliderTypeEdit();
      setRiskLvlEdit();
      setEditSliderMthodType('');
      setRiskLvlSliderValueNameEdit()
      setShowRiskDropDown(true)
      setRiskLvlEditStyle('');
    }

    function handleChangeAppetiteEdit(event) {
      // console.log(event.target.value)
      //setSliderTypeEdit(event.target.value);
      setEditAppetiteSliderMthod(event.target.value);
      setShowAppetiteDropDown(false)


    };


    function appetiteClearFunctionEdit() {
      setappetiteEdit()
      setAppetiteSliderValueNameEdit()
      setEditAppetiteSliderMthod('');
      setShowAppetiteDropDown(true)
      setappetiteEditStyle('');
      setSelectedColorForAppetiteSliderEdit(1);

    }




    const [openStandardGridEdit, setOpenStandardGridEdit] = useState(false);
    const [chkStandardEditChecked, setChkStandardEditChecked] = useState(false);



    function activeStandardGridForEdit(flag, comment) {
      console.log(comment);
      setChkStandardEditChecked(flag);
      if (document.getElementById('chkStandardEdit').checked) {
        setOpenStandardGridEdit(true);
        //Add edit details regarding the std

      } else {
        setOpenStandardGridEdit(false);
        //Remove edit details regarding the std
      }
    }

    //select risk edit

    const [globalStandardDataForMainRiskEdit, setGlobalStandardDataForMainRiskEdit] = useState('')
    const [globalStandardDataNameForMainRiskEdit, setGlobalStandardDataNameForMainRiskEdit] = useState('')
    const [customStandardDataForMainRiskEdit, setCustomStandardDataForMainRiskEdit] = useState('')
    const [customStandardDataNameForMainRiskEdit, setCustomStandardDataNameForMainRiskEdit] = useState('')

    const handleChangeGlobalStandardDataForMainRiskEdit = (event) => {
      setObjectiveIdForEdit('')
      setObjectiveNameForEdit('')
      setComponentIdForEdit('');
      setComponentNameForEdit('');
      setPrincipleIdForEdit('');
      setPrincipleNameForEdit('');
      setGlobalStandardDataForMainRiskEdit(event.target.value);
      //alert(event.target.textContent);
      setGlobalStandardDataNameForMainRiskEdit(event.target.textContent);
      setCustomStandardDataForMainRiskEdit('')

    }

    const handleChangeCustomStandardDataForMainRiskEdit = (event) => {
      setObjectiveIdForEdit('')
      setObjectiveNameForEdit('')
      setComponentIdForEdit('');
      setComponentNameForEdit('');
      setPrincipleIdForEdit('');
      setPrincipleNameForEdit('');
      setCustomStandardDataForMainRiskEdit(event.target.value);
      setCustomStandardDataNameForMainRiskEdit(event.target.textContent);
      setGlobalStandardDataForMainRiskEdit('')
    }




    const [objectiveIdForEdit, setObjectiveIdForEdit] = useState('')
    const [objectiveNameForEdit, setObjectiveNameForEdit] = useState('')

    const [componentIdForEdit, setComponentIdForEdit] = useState('')
    const [componentNameForEdit, setComponentNameForEdit] = useState('')

    const [principleIdForEdit, setPrincipleIdForEdit] = useState('')
    const [principleNameForEdit, setPrincipleNameForEdit] = useState('')


    const handleChangeObjectivesForEdit = (event) => {
      setObjectiveIdForEdit(event.target.value)
      setObjectiveNameForEdit(event.target.textContent)

    }

    const handleChangecomponentForEdit = (event) => {
      setComponentIdForEdit(event.target.value)
      setComponentNameForEdit(event.target.textContent)
    }

    const handleChangeprincipleForEdit = (event) => {
      setPrincipleIdForEdit(event.target.value)
      setPrincipleNameForEdit(event.target.textContent)
    }


    /* add standaRD FOR SUB RISK*/


    const [openStandardGridAddSubRisk, setOpenStandardGridAddSubRisk] = useState(false);
    function activeStandardGridAddSubRisk() {
      if (document.getElementById('chkStandardAddSubRisk').checked) {
        setOpenStandardGridAddSubRisk(true);
        //alert(globalStandardDataForMainRisk);
        //alert(globalStandardDataNameForMainRisk);
        //alert(objectiveId);
        //alert(componentId);
        //alert(principleId);

        var stdId = '';
        if (globalStandardDataForMainRisk != '') {
          setGlobalStandardDataForMainRiskAddSubRisk(globalStandardDataForMainRisk);
          stdId = globalStandardDataForMainRisk;
          setGlobalStandardDataNameForMainRiskAddSubRisk(globalStandardDataNameForMainRisk);
          setCustomStandardDataForMainRiskAddSubRisk('');
        }
        if (customStandardDataForMainRisk != '') {
          setGlobalStandardDataForMainRiskAddSubRisk('');
          setCustomStandardDataForMainRiskAddSubRisk(customStandardDataForMainRisk);
          stdId = customStandardDataForMainRisk;
          setCustomStandardDataNameForMainRiskAddSubRisk(customStandardDataNameForMainRisk);
        }
        if (objectiveId != '') {
          setObjectiveIdForAddSubRisk(objectiveId);
          standardData.forEach((data) => {
            if (data.standardId === stdId) {
              data.objectives.forEach((item) => {
                if (item.objectiveId === objectiveId) {
                  setObjectiveNameForAddSubRisk(item.name);
                }
              })
            }
          })
        }
        if (componentId != '') {
          setComponentIdForAddSubRisk(componentId);
          standardData.forEach((data) => {
            if (data.standardId === stdId) {
              data.components.forEach((item) => {
                if (item.componentId === componentId) {
                  setComponentNameForAddSubRisk(item.name);
                }
              })
            }
          })
        }
        if (principleId != '') {
          setPrincipleIdForAddSubRisk(principleId);
          standardData.forEach((data) => {
            if (data.standardId === stdId) {
              data.principles.forEach((item) => {
                if (item.principalId === principleId) {
                  setPrincipleNameForAddSubRisk(item.name);
                }
              })
            }
          })

        }

      } else {
        setOpenStandardGridAddSubRisk(false);
        setGlobalStandardDataForMainRiskAddSubRisk('');
        setCustomStandardDataForMainRiskAddSubRisk('');
        setObjectiveIdForAddSubRisk('');
        setComponentIdForAddSubRisk('');
        setPrincipleIdForAddSubRisk('');
      }
    }

    const [globalStandardDataForMainRiskAddSubRisk, setGlobalStandardDataForMainRiskAddSubRisk] = useState('')
    const [globalStandardDataNameForMainRiskAddSubRisk, setGlobalStandardDataNameForMainRiskAddSubRisk] = useState('')
    const [customStandardDataForMainRiskAddSubRisk, setCustomStandardDataForMainRiskAddSubRisk] = useState('')
    const [customStandardDataNameForMainRiskAddSubRisk, setCustomStandardDataNameForMainRiskAddSubRisk] = useState('')

    const handleChangeGlobalStandardDataForMainRiskAddSubRisk = (event) => {
      setGlobalStandardDataForMainRiskAddSubRisk(event.target.value);
      setGlobalStandardDataNameForMainRiskAddSubRisk(event.target.textContent);
      setCustomStandardDataForMainRiskAddSubRisk('');
      setObjectiveIdForAddSubRisk('');
      setComponentIdForAddSubRisk('');
      setPrincipleIdForAddSubRisk('');

    }

    const handleChangeCustomStandardDataForMainRiskAddSubRisk = (event) => {
      setCustomStandardDataForMainRiskAddSubRisk(event.target.value);
      setCustomStandardDataNameForMainRiskAddSubRisk(event.target.textContent);
      setGlobalStandardDataForMainRiskAddSubRisk('')
      setObjectiveIdForAddSubRisk('');
      setComponentIdForAddSubRisk('');
      setPrincipleIdForAddSubRisk('');

    }




    const [objectiveIdForAddSubRisk, setObjectiveIdForAddSubRisk] = useState('')
    const [objectiveNameForAddSubRisk, setObjectiveNameForAddSubRisk] = useState('')

    const [componentIdForAddSubRisk, setComponentIdForAddSubRisk] = useState('')
    const [componentNameForAddSubRisk, setComponentNameForAddSubRisk] = useState('')

    const [principleIdForAddSubRisk, setPrincipleIdForAddSubRisk] = useState('')
    const [principleNameForAddSubRisk, setPrincipleNameForAddSubRisk] = useState('')


    const handleChangeObjectivesForAddSubRisk = (event) => {
      setObjectiveIdForAddSubRisk(event.target.value)
      setObjectiveNameForAddSubRisk(event.target.textContent);
    }

    const handleChangecomponentForAddSubRisk = (event) => {
      setComponentIdForAddSubRisk(event.target.value)
      setComponentNameForAddSubRisk(event.target.textContent);
    }

    const handleChangeprincipleForAddSubRisk = (event) => {
      setPrincipleIdForAddSubRisk(event.target.value)
      setPrincipleNameForAddSubRisk(event.target.textContent);

    }


    console.log(subRiskLevelType)

    return (


      <div style={{ "marginLeft": "25px", "marginTop": "10px" }}>
        <div style={{ display: 'flex', textAlign: 'center' }}>
          <Button
            style={{ width: '0px', height: '0px' }}
            onClick={() => setShow(!show)}
            startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          >
          </Button>
          {riskDisplayStr}
          &nbsp;&nbsp;<Button
            variant="outlined"
            size='small'
            style={{ "border": "1px solid #000000 !important", "color": "#8a8181 !important", "padding": "5px 10px !important", "height": "35px !important" }}
            onClick={subRiskHandleClickOpen('paper', comment)}
          >
            + Add Sub Risk
          </Button>&nbsp;&nbsp;
          <Dialog
            open={subRiskOpen}
            onClose={false}
            scroll={subRiskScroll}
            aria-labelledby="sub-risk-scroll-dialog-title"
            aria-describedby="sub-risk-scroll-dialog-description"
            fullWidth={true}
            maxWidth={'md'}
            PaperComponent={PaperComponentSubRisk}
          >
            <DialogTitle style={{ cursor: 'move' }} id="sub-risk-scroll-dialog-title">Create a Sub Risk for {comment.name}</DialogTitle>
            <DialogContent dividers={subRiskScroll === 'paper'}>
              <DialogContentText
                id="sub-risk-scroll-dialog-description"
                tabIndex={-1}
              >
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} validate autoComplete="off">
                      <div>
                        <TextField
                          id={comment.riskTypeTmpId}
                          label="Sub Risk"
                          multiline
                          maxRows={2}
                          value={subRiskName}
                          onChange={handleChangeSubRisk}
                        />
                      </div>
                    </Box>
                  </Grid>



                  <Grid item xs={5}>
                    <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} validate autoComplete="off">
                      <div>
                        <RiskLevelSlider
                          MethodDropdown={'Risk Level Slider'}
                          SliderName={'Risk level'}
                          showSelectBar={subRiskLvlShow}
                          handleChange={handleChangeSubRiskLevel}
                          sliderType={subRiskLevelType}
                          riskCategoryMethodData={riskCategoryMethodData}
                          nameChange={subRiskNameChange}
                          sliderValue={subRiskLevelValue}
                          sliderValueStyle={subRiskLevelValueStyle}
                          sliderValueName={subRiskSliderValueName}
                          riskClearFunction={subRiskClearFunction}
                          setSliderValueFunction={setSubRiskLevelValueFunction}
                        />

                      </div>
                    </Box>
                  </Grid>



                  <Grid item xs={1} spacing={2}></Grid>
                  <Grid item xs={5}>
                    <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} validate autoComplete="off">

                      <div>
                        <RiskAppetiteSlider
                          SliderName={'Risk Appetites'}
                          MethodDropdown={'Risk Appetites Slider'}
                          appetitesShowSelectBar={subAppetitesShowSelectBar}
                          appetiteSliderType={subAppetiteSliderType}
                          appetiteHandleChange={subAppetiteHandleChange}
                          riskCategoryMethodDataAppetite={riskCategoryMethodDataAppetite}
                          selectedColorForAppetiteSlider={selectedColorForAppetiteSliderSubRisk}
                          radioHandler={radioHandlerAppetiteSubEdit}
                          setSliderValueFunction={setSubRiskLevelValueFunction}
                          appetitesSliderValueStyle={subRiskAppetiteStyle}
                          appetiteClearFunction={subAppeClearFunction}
                          appetitesSliderValueName={subAppetitesSliderValueName}
                          appetitesSliderValue={subRiskAppetite}
                          appetiteNameChange={subAppetiteNameChange}
                        />
                      </div>
                    </Box>
                  </Grid>




                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox
                        id={"chkStandardAddSubRisk"}
                        onClick={() => activeStandardGridAddSubRisk()}
                      />}
                      label={"Add related Standared if needed"}
                    />
                  </Grid>
                  {openStandardGridAddSubRisk ?
                    <Grid item xs={12}>
                      <div style={{ textAlign: 'left' }}>
                        <Card className={classes.cardDivDB}>
                          <CardHeader
                            avatar={
                              <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                <Info />
                              </Avatar>
                            }
                            className={classes.stdMainCardHeader}
                            title="Add related Standared, Objective, Component and Principle"
                          />
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select From Global Standaed</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Select From Global Standaed"
                                      value={globalStandardDataForMainRiskAddSubRisk}
                                      onChange={handleChangeGlobalStandardDataForMainRiskAddSubRisk}

                                    >
                                      {globalStandardData.map((item) => (
                                        <MenuItem
                                          value={item.standardId}
                                        >
                                          {item.standardName}
                                        </MenuItem>
                                      ))}

                                    </Select>

                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select From Custom Standaed</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Select From Global Standaed"
                                      value={customStandardDataForMainRiskAddSubRisk}
                                      onChange={handleChangeCustomStandardDataForMainRiskAddSubRisk}

                                    >
                                      {customStandardData.map((item) => (
                                        <MenuItem
                                          value={item.standardId}
                                        >
                                          {item.standardName}
                                        </MenuItem>
                                      ))}

                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Objective</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Select From Global Standaed"
                                      sx={{ width: '100%' }}

                                      value={objectiveIdForAddSubRisk}
                                      onChange={handleChangeObjectivesForAddSubRisk}
                                    >
                                      {standardData.filter(object =>
                                        object.standardId === globalStandardDataForMainRiskAddSubRisk || object.standardId === customStandardDataForMainRiskAddSubRisk).map((stndrd) => (
                                          stndrd.objectives.map((obj) => (
                                            <MenuItem value={obj.objectiveId}> {obj.name}</MenuItem>
                                          ))
                                        ))}


                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>


                              <Grid item xs={12}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Component</InputLabel>
                                    <Select
                                      label='Select Component'
                                      sx={{ width: '100%' }}

                                      value={componentIdForAddSubRisk}
                                      onChange={handleChangecomponentForAddSubRisk}
                                    >
                                      {standardData.filter(object =>
                                        object.standardId === globalStandardDataForMainRiskAddSubRisk || object.standardId === customStandardDataForMainRiskAddSubRisk).map((stndrd) => (
                                          stndrd.components.map((com) => (
                                            <MenuItem value={com.componentId}> {com.name}</MenuItem>
                                          ))
                                        ))}






                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>


                              <Grid item xs={12}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Principle</InputLabel>
                                    <Select
                                      label='Select Principle'
                                      sx={{ width: '100%' }}

                                      value={principleIdForAddSubRisk}
                                      onChange={handleChangeprincipleForAddSubRisk}
                                    >

                                      {standardData.filter(object =>
                                        object.standardId === globalStandardDataForMainRiskAddSubRisk || object.standardId === customStandardDataForMainRiskAddSubRisk).map((stndrd) => (
                                          stndrd.principles.map((pri) => (
                                            <MenuItem value={pri.principalId}> {pri.name}</MenuItem>
                                          ))
                                        ))}


                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>






                            </Grid>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                    : ''
                  }






                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSubRiskFunction(this)}>add</Button>
              <Button onClick={subRiskHandleClickClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
          <Button
            variant="outlined"
            size='small'
            style={{ "border": "1px solid #000000 !important", "color": "#8a8181 !important", "padding": "5px 10px !important", "height": "35px !important" }}
            onClick={editRiskHandleClickOpen('paper')}
          >
            Edit
          </Button>
          <Dialog
            open={editRiskOpen}
            onClose={false}
            //scroll={editRiskScroll}
            aria-labelledby="edit-risk-scroll-dialog-title"
            aria-describedby="edit-risk-scroll-dialog-description"
            fullWidth={true}
            maxWidth={'md'}
            PaperComponent={PaperComponentMainRiskEdit}
          >
            <DialogTitle style={{ cursor: 'move' }} id="edit-risk-scroll-dialog-title">Edit Risk - {comment.name}</DialogTitle>
            <DialogContent dividers={editRiskScroll === 'paper'}>
              <DialogContentText
                id="edit-risk-scroll-dialog-description"
                tabIndex={-1}
              >
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} validate autoComplete="off">
                      <div>
                        <TextField
                          id={comment.riskTypeTmpId}
                          label="Edit Risk"
                          multiline
                          maxRows={2}
                          value={editRiskName}
                          onChange={handleChangeEditRisk}
                        />
                      </div>
                    </Box>
                  </Grid>





                  <Grid item xs={5}>
                    <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} validate autoComplete="off">
                      <div>


                        <>

                          <RiskLevelSlider
                      
                            MethodDropdown={'Risk Level Slider'}
                            SliderName={'Risk level'}
                            showSelectBar={showRiskDropDown}
                            handleChange={handleChangeRiskEdit}
                            sliderType={editSliderMthodType}
                            riskCategoryMethodData={riskCategoryMethodData}
                            nameChange={nameChangeRiskLvlEdit}
                            sliderValue={riskLvlEdit}
                            sliderValueStyle={riskLvlEditStyle}
                            sliderValueName={riskLvlSliderValueNameEdit}
                            riskClearFunction={riskClearFunctionEdit}
                            setSliderValueFunction={setRiskLvlEditFunction}
                          />

                        </>
                      </div>
                    </Box>
                  </Grid>



                  <Grid item xs={1} spacing={2}></Grid>
                  <Grid item xs={5}>
                    <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} validate autoComplete="off">
                      <RiskAppetiteSlider
                        SliderName={'Risk Appetites'}
                        MethodDropdown={'Risk Appetites Slider'}
                        appetitesShowSelectBar={showAppetiteDropDown}
                        appetiteSliderType={editAppetiteSliderMthod}
                        appetiteHandleChange={handleChangeAppetiteEdit}
                        riskCategoryMethodDataAppetite={riskCategoryMethodDataAppetite}
                        selectedColorForAppetiteSlider={selectedColorForAppetiteSliderEdit}
                        radioHandler={radioHandlerAppetiteEdit}
                        setSliderValueFunction={setRiskLvlEditFunction}
                        appetitesSliderValueStyle={appetiteEditStyle}
                        appetiteClearFunction={appetiteClearFunctionEdit}
                        appetitesSliderValueName={appetitesSliderValueNameEdit}
                        appetitesSliderValue={appetiteEdit}
                        appetiteNameChange={appetiteNameChangeEdit}
                      />

                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox
                        id={"chkStandardEdit"}
                        onClick={() => activeStandardGridForEdit(!chkStandardEditChecked, comment)}
                        checked={chkStandardEditChecked}
                      />} label={"Add related Standared for all Risk Types if needed"} />
                  </Grid>
                  {openStandardGridEdit ?


                    <Grid item xs={12}>
                      <div style={{ textAlign: 'left' }}>
                        <Card className={classes.cardDivDB}>
                          <CardHeader
                            avatar={
                              <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                <Info />
                              </Avatar>
                            }
                            className={classes.stdMainCardHeader}
                            title="Add / Edit related Standared, Objective, Component and Principle"
                          />
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select From Global Standaed</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Select From Global Standaed"
                                      value={globalStandardDataForMainRiskEdit}
                                      onChange={handleChangeGlobalStandardDataForMainRiskEdit}

                                    >
                                      {globalStandardData.map((item) => (
                                        <MenuItem
                                          value={item.standardId}
                                        >
                                          {item.standardName}
                                        </MenuItem>
                                      ))}

                                    </Select>

                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select From Custom Standaed</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Select From Global Standaed"
                                      value={customStandardDataForMainRiskEdit}
                                      onChange={handleChangeCustomStandardDataForMainRiskEdit}

                                    >
                                      {customStandardData.map((item) => (
                                        <MenuItem
                                          value={item.standardId}
                                        >
                                          {item.standardName}
                                        </MenuItem>
                                      ))}

                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>



                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Objective</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Select From Global Standaed"
                                      sx={{ width: '100%' }}

                                      value={objectiveIdForEdit}
                                      onChange={handleChangeObjectivesForEdit}
                                    //disabled={objectiveIdForEdit === 5000 ? true : false}
                                    >
                                      {objectiveIdForEdit === 5000 ?

                                        <MenuItem value={5000}> {objectiveNameForEdit}</MenuItem>

                                        :

                                        standardData.filter(object =>
                                          object.standardId === globalStandardDataForMainRiskEdit || object.standardId === customStandardDataForMainRiskEdit).map((stndrd) => (
                                            stndrd.objectives.map((obj) => (


                                              <MenuItem value={obj.objectiveId}> {obj.name}</MenuItem>

                                            ))
                                          ))
                                      }






                                    </Select>
                                  </FormControl>
                                </Box>


                              </Grid>


                              <Grid item xs={12}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Component</InputLabel>
                                    <Select
                                      label='Select Component'
                                      sx={{ width: '100%' }}

                                      value={componentIdForEdit}
                                      onChange={handleChangecomponentForEdit}
                                    >
                                      {componentIdForEdit === 5000 ?

                                        <MenuItem value={5000}> {componentNameForEdit}</MenuItem>

                                        :
                                        standardData.filter(object =>
                                          object.standardId === globalStandardDataForMainRiskEdit || object.standardId === customStandardDataForMainRiskEdit).map((stndrd) => (
                                            stndrd.components.map((com) => (
                                              <MenuItem value={com.componentId}> {com.name}</MenuItem>
                                            ))
                                          ))}






                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>



                              <Grid item xs={12}>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Principle</InputLabel>
                                    <Select
                                      label='Select Principle'
                                      sx={{ width: '100%' }}

                                      value={principleIdForEdit}
                                      onChange={handleChangeprincipleForEdit}
                                    >

                                      {principleIdForEdit === 5000 ?

                                        <MenuItem value={5000}> {principleNameForEdit}</MenuItem>

                                        :
                                        standardData.filter(object =>
                                          object.standardId === globalStandardDataForMainRiskEdit || object.standardId === customStandardDataForMainRiskEdit).map((stndrd) => (
                                            stndrd.principles.map((pri) => (
                                              <MenuItem value={pri.principalId}> {pri.name}</MenuItem>
                                            ))
                                          ))}


                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>






                            </Grid>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                    : ''
                  }
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button id={comment.riskTypeTmpId} onClick={() => setEditRiskFunction(this)}>update</Button>
              <Button onClick={editRiskHandleClickClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </div>
        {
          show ?
            <>
              {nestedComments}
            </>
            : null
        }
      </div>
    )
  }

  /**
   * all risk
   */

  const [riskTypeName, setRiskTypeName] = useState([])
  const [editClickRiskId, setEditClickRiskId] = useState()





  //updated slider







  function editRiskData(id) {
    setShowRiskHierarchyTable(false)
    setFrameSize(12)
    console.log(id)
    setEditClickRiskId(id)
    riskTypeName.forEach((data) => {
      console.log("+++++++++++++data+++++++++++++++");
      console.log(data)
      if (data.riskId === id) {
        data.riskTypes.forEach((data1) => {
          console.log(data1.riskAppetiteData.color)
          //setSliderTypeEdit(data1.riskLevelData.riskMethodId)

          setRiskIdvalue(data.riskId)

          setRiskName(data.name)
          setRiskTypeData(data);
          // setRsType(data.riskTypes);
          setSaveRiskFlag(true);
          setShowSaveBtn(true);
          setSaveBtnName("Update");
          setSaveBtnNameForRisk("Update Risk");
          setPageBannerName("Edit Questionnaire Group")
          setCreateHeaderInfoMessage("Edit A Risk Hierarchy")
          setEditGroup(true);
        })


      }
    })
  }

  function getAllRisks(centId) {
    axios(CONFIG.MAIN_URL + `/risk/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("All Risk Returns");
        console.log(response.data);
        var arr = [];
        response.data.forEach((item) => {
          if (centId === item.centreId) {
            arr.push(item);
          }
        })
        setRiskTypeName(arr);

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const previousRiskTableRowData = (
    <TableBody>
      {riskTypeName.map((item) => {

        // if (item.centreId != "globle" && item.centreId === globalCentreId){
        return (
          <StyledTableRow key={item.riskId} className={classes.tableTrStyle}>
            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
              <div style={{ whiteSpace: 'normal' }}>{item.name}</div>
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={classes.tableTrStyle}
            >
              <EditIcon
                className={classes.mousePointer}
                onClick={() => { editRiskData(item.riskId) }}
              />
            </StyledTableCell>
          </StyledTableRow>
        )
        //  }
      })}
    </TableBody>

  )

  const [sliderTypeEdit, setSliderTypeEdit] = React.useState('');


  /** End of Comment section */

  const [PageName, setPageName] = useState('Create And Edit a Risk Hierarchy')

  //risk Appetite slider

  const [appetitesSliderValue, setAppetiteSliderValue] = React.useState();
  const [appetitesSliderValueStyle, setAppetiteSliderValueStyle] = React.useState('');

  const [appetitesSliderValueName, setAppetiteSliderValueName] = React.useState('');

  const [appetiteSliderType, setAppetiteSliderType] = React.useState('');

  const [appetitesShowSelectBar, setAppetiteShowSelectBar] = React.useState(true);

  const appetiteHandleChange = (event) => {
    setAppetiteSliderType(event.target.value);
    setAppetiteShowSelectBar(false);
  };

  function appetiteClearFunction() {
    setAppetiteShowSelectBar(true);
    setAppetiteSliderValue();
    setAppetiteSliderType(0);
    setAppetiteSliderValueName('')
    setAppetiteSliderValueStyle('')



  }

  // risk level slider


  const [sliderValue, setSliderValue] = React.useState();
  const [sliderValueStyle, setSliderValueStyle] = React.useState('');

  const [sliderValueName, setSliderValueName] = React.useState('');


  const [showSelectBar, setShowSelectBar] = React.useState(true);

  const [appetiteColorForSaveToDb, setAppetiteColorForSaveToDb] = useState('')


  console.log(databClr)
  function getColorCode(number) {
    var cCode = "#ffffff";
    databClr.forEach((data) => {
      if (parseInt(number) === parseInt(data.scoreNumber)) {
        cCode = data.color;
      }
    })
    return cCode;
  }

  function getColorCodeAppetite(number) {
    var cCode = "#ffffff";
    databClr.forEach((data) => {
      if (selectedColorForAppetiteSlider === 1) {
        if (parseInt(number) === parseInt(data.scoreNumber)) {
          cCode = data.color;
          setAppetiteColorForSaveToDb(cCode)
        }
      }
      else {
        if (parseInt(number) === parseInt(data.scoreNumber)) {
          cCode = data.secondaryColor;
          setAppetiteColorForSaveToDb(cCode)
        }
      }

    })
    return cCode;
  }

  function setSliderValueFunction(val, min, max, type) {

    var posNumber = val - min;
    var totalNumber = max - min;
    var percentageVal = (posNumber / totalNumber) * 100
    var cCode = getColorCode(val);
    var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
    if (type === 1) {
      setSliderValue(val);
      setSliderValueStyle(cssStr);
    }
    if (type === 2) {
      setAppetiteSliderValue(val);
      cCode = getColorCodeAppetite(val);
      cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
      setAppetiteSliderValueStyle(cssStr);
    }
  }

  const handleChange = (event) => {
    console.log("+++++++++++++++++riskCategoryMethodData+++++++++++++++++");
    console.log(riskCategoryMethodData);
    console.log(event.target.value);

    riskCategoryMethodData.forEach((data) => {
      if (data.id === event.target.value) {
        var colorPatId = data.colorPatternId;
        if (colorPatId != undefined && colorPatId != null) {
          colorPatterns.forEach((item) => {
            if (item.id === colorPatId) {
              setDatabClr(item.colors);
            }
          })
        }
      }
    })

    setSliderType(event.target.value);

    setShowSelectBar(false);
    if (appetiteSliderType === undefined || appetiteSliderType === '') {
      setAppetiteSliderType(event.target.value);
      setAppetiteShowSelectBar(false);
    }
  };


  function riskClearFunction() {
    setShowSelectBar(true);
    setSliderType('');
    setSliderValue();
    setSliderValueName('');



  }

  //RISK APPE VALUE NAME

  function appetiteNameChange(sVal) {

    var fondType = false;
    riskCategoryMethodDataAppetite.forEach((data) => {
      if (appetiteSliderType === data.id) {
        data.riskTypes.forEach((data1) => {
          if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
            setAppetiteSliderValueName(data1.riskTypesName)
            fondType = true;
          }
        })
      }
    })
    if (!fondType) {
      setAppetiteSliderValueName('');
      setAppetiteSliderValue();
    }
  }


  //RISK LEVEL VALUE NAME

  function nameChange(sVal) {
    var fondType = false;
    riskCategoryMethodData.forEach((data) => {
      if (sliderType === data.id) {
        data.riskTypes.forEach((data1) => {
          if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
            setSliderValueName(data1.riskTypesName)
            fondType = true;
          }
        })
      }
    })
    if (!fondType) {
      setSliderValueName('');
      setSliderValue();
    }
  }

  const [openStandardGridAll, setOpenStandardGridAll] = useState(false);
  const [chkStandardAllChecked, setChkStandardAllChecked] = useState(false);

  function activeStandardGridForAll(flag) {
    setChkStandardAllChecked(flag);
    if (document.getElementById('chkStandardAll').checked) {
      setOpenStandardGridAll(true);
    } else {
      setOpenStandardGridAll(false);
      setGlobalStandardIdForAll('')
      setCustomStandardIdForAll('')
    }
  }

  const [openStandardGrid, setOpenStandardGrid] = useState(false);
  function activeStandardGrid() {
    if (document.getElementById('chkStandard').checked) {
      setOpenStandardGrid(true);
      if (document.getElementById('chkStandardAll').checked) {
        if (globalStandardIdForAll != '') {
          setGlobalStandardDataForMainRisk(globalStandardIdForAll);
          setGlobalStandardDataNameForMainRisk(globalStandardNameForAll)
        }
        if (customStandardIdForAll != '') {
          setCustomStandardDataForMainRisk(customStandardIdForAll);
          setCustomStandardDataNameForMainRisk(customStandardNameForAll);
        }

      }

    } else {
      setOpenStandardGrid(false);
      setGlobalStandardDataForMainRisk('');
      setCustomStandardDataForMainRisk('');
      setObjectiveId('');
      setComponentId('');
      setPrincipleId('');

    }
  }




  const [frameSize, setFrameSize] = useState(8)

  /* set global and custem standard for main risk */

  const [globalStandardDataForMainRisk, setGlobalStandardDataForMainRisk] = useState('')
  const [globalStandardDataNameForMainRisk, setGlobalStandardDataNameForMainRisk] = useState('')
  const [customStandardDataForMainRisk, setCustomStandardDataForMainRisk] = useState('')
  const [customStandardDataNameForMainRisk, setCustomStandardDataNameForMainRisk] = useState('')

  const handleChangeGlobalStandardDataForMainRisk = (event) => {
    setGlobalStandardDataForMainRisk(event.target.value);
    setGlobalStandardDataNameForMainRisk(event.target.textContent);
    console.log(event.target.textContent);
    setCustomStandardDataForMainRisk('')
    setObjectiveId('');
    setComponentId('');
    setPrincipleId('');
    //setGlobalStandardIdForAll('')
    //setCustomStandardIdForAll('')

  }

  const handleChangeCustomStandardDataForMainRisk = (event) => {

    setCustomStandardDataForMainRisk(event.target.value);
    setCustomStandardDataNameForMainRisk(event.target.textContent);
    setGlobalStandardDataForMainRisk('')
    setObjectiveId('');
    setComponentId('');
    setPrincipleId('');

    //setGlobalStandardIdForAll('')
    //setCustomStandardIdForAll('')
  }

  /* set  standard for risk name*/

  const [globalStandardIdForAll, setGlobalStandardIdForAll] = useState('')
  const [globalStandardNameForAll, setGlobalStandardNameForAll] = useState('')
  const [customStandardIdForAll, setCustomStandardIdForAll] = useState('')
  const [customStandardNameForAll, setCustomStandardNameForAll] = useState('')

  const handleChangeGlobalStandardIdForAll = (event) => {
    setGlobalStandardIdForAll(event.target.value);
    setGlobalStandardNameForAll(event.target.textContent);
    setCustomStandardIdForAll('')
    setCustomStandardDataForMainRisk('')
    setGlobalStandardDataForMainRisk('')
  }

  const handleChangeCustomStandardIdForAll = (event) => {
    setCustomStandardIdForAll(event.target.value);
    setCustomStandardNameForAll(event.target.textContent);
    setGlobalStandardIdForAll('')
    setCustomStandardDataForMainRisk('')
    setGlobalStandardDataForMainRisk('')
  }


  const [objectiveId, setObjectiveId] = useState('')
  const [objectiveName, setObjectiveName] = useState('')

  const [componentId, setComponentId] = useState('')
  const [componentName, setComponentName] = useState('')

  const [principleId, setPrincipleId] = useState('')
  const [principleName, setPrincipleName] = useState('')


  const handleChangeObjectives = (event) => {
    setObjectiveId(event.target.value)
    setObjectiveName(event.target.textContent);
  }

  const handleChangecomponent = (event) => {
    setComponentId(event.target.value);
    setComponentName(event.target.textContent);
  }

  const handleChangeprinciple = (event) => {
    setPrincipleId(event.target.value)
    setPrincipleName(event.target.textContent);
  }



  const [selectedColorForAppetiteSlider, setSelectedColorForAppetiteSlider] = useState(1)

  const radioHandler = (status) => {
    setSelectedColorForAppetiteSlider(status);
    if (selectedColorForAppetiteSlider != status) {
      setAppetiteSliderValueStyle('')
      setAppetiteSliderValueName('')
      setAppetiteSliderValue('')
    }
  };






  return (
    <>
      {/* className={classes.pageTop} */}
      <div >
        <Grid >
          <Grid container spacing={2} className={classes.pageBodyStyle}>


            <>

              <Grid container spacing={2}>


                <Grid item xs={frameSize} >

                  <Card className={classes.cardRiskManage} >

                    <CardContent>
                      <Card >
                        <CardHeader
                          avatar={
                            <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                              <Info />
                            </Avatar>
                          }
                          className={classe.commonMainTableHead}
                          title={createheaderInfoMessage}
                        />
                        <Grid container spacing={2}>

                          <Grid item xs={12}>

                            <Box
                              component="form"
                              sx={{
                                '& .MuiTextField-root': { mt: 1, width: '100%' },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <div>
                                <TextField
                                  id="title"
                                  size='small'
                                  label="Risk Name"
                                  multiline
                                  maxRows={2}
                                  name='title'
                                  required
                                  value={riskName}
                                  onChange={onTextFieldChange}
                                />
                              </div>
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <FormControlLabel
                              control={<Checkbox
                                id={"chkStandardAll"}
                                onClick={() => activeStandardGridForAll(!chkStandardAllChecked)}
                                checked={chkStandardAllChecked}
                              />}
                              label={"Add related Standared for all Risk Types if needed"} />
                          </Grid>
                          {openStandardGridAll ?
                            <Grid item xs={12}>
                              <div style={{ textAlign: 'left' }}>
                                <Card className={classes.cardDivDB}>
                                  <CardHeader
                                    avatar={
                                      <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                        <Info />
                                      </Avatar>
                                    }
                                    className={classes.stdMainCardHeader}
                                    title="Select a Standared"
                                  />
                                  <CardContent>
                                    <Grid container spacing={2}>
                                      <Grid item xs={6}>
                                        <Box sx={{ minWidth: 120 }}>
                                          <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Select From Global Standard</InputLabel>
                                            <Select
                                              label='Select From Global Standard'
                                              sx={{ width: '100%' }}

                                              value={globalStandardIdForAll}
                                              onChange={handleChangeGlobalStandardIdForAll}
                                            >
                                              {globalStandardData.map((item) => (
                                                <MenuItem
                                                  value={item.standardId}
                                                >
                                                  {item.standardName}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Box sx={{ minWidth: 120 }}>
                                          <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Select From Custom Standaed</InputLabel>
                                            <Select
                                              label='Select From Custom Standaed'
                                              sx={{ width: '100%' }}

                                              value={customStandardIdForAll}
                                              onChange={handleChangeCustomStandardIdForAll}
                                            >
                                              {customStandardData.map((item) => (
                                                <MenuItem
                                                  value={item.standardId}
                                                >
                                                  {item.standardName}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </Grid>





                                    </Grid>
                                  </CardContent>
                                </Card>
                              </div>
                            </Grid>
                            : ''
                          }



                          <Grid item xs={12} spacing={2}>
                            <Button
                              variant="outlined"
                              size='small'
                              // style={{"border": "5px solid #9155FD !important",  "color": " !important",  "padding":"5px 10px !important",  "height": "40px !important","marginLeft":"15px !important"}}
                              onClick={mainRiskHandleClickOpen('paper')}
                              className={classes.addrisktypebtn}
                            >
                              + Add Risk Type
                            </Button>
                            <Dialog
                              open={mainRiskOpen}
                              onClose={false}
                              scroll={mainRiskScroll}
                              aria-labelledby="main-risk-scroll-dialog-title"
                              aria-describedby="main-risk-scroll-dialog-description"
                              fullWidth={true}
                              maxWidth={'md'}
                              PaperComponent={PaperComponentMainRisk}
                            >
                              <DialogTitle style={{ cursor: 'move' }} id="main-risk-scroll-dialog-title">Create a Main Risk</DialogTitle>
                              <DialogContent dividers={mainRiskScroll === 'paper'}>
                                <DialogContentText
                                  id="main-risk-scroll-dialog-description"
                                  tabIndex={-1}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                      <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} validate autoComplete="off">
                                        <div>
                                          <TextField
                                            id="mainRiskType"
                                            label="Main Risk"
                                            multiline
                                            maxRows={2}
                                            value={mainRiskName}
                                            onChange={handleChangeMainRisk}
                                          />
                                        </div>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} validate autoComplete="off">
                                        <div>

                                          <RiskLevelSlider
                                            MethodDropdown={'Risk Level Slider'}
                                            SliderName={'Risk level'}
                                            showSelectBar={showSelectBar}
                                            handleChange={handleChange}
                                            sliderType={sliderType}
                                            riskCategoryMethodData={riskCategoryMethodData}
                                            nameChange={nameChange}
                                            sliderValue={sliderValue}
                                            sliderValueStyle={sliderValueStyle}
                                            sliderValueName={sliderValueName}
                                            riskClearFunction={riskClearFunction}
                                            setSliderValueFunction={setSliderValueFunction}

                                          />

                                        </div>
                                      </Box>
                                    </Grid>
                                    {/* <Grid item xs={1} spacing={2}></Grid> */}
                                    <Grid item xs={5}>
                                      <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} validate autoComplete="off">
                                        <div>
                                          <RiskAppetiteSlider

                                            SliderName={'Risk Appetites'}
                                            MethodDropdown={'Risk Appetites Slider'}
                                            appetitesShowSelectBar={appetitesShowSelectBar}
                                            appetiteSliderType={appetiteSliderType}
                                            appetiteHandleChange={appetiteHandleChange}
                                            riskCategoryMethodDataAppetite={riskCategoryMethodDataAppetite}
                                            selectedColorForAppetiteSlider={selectedColorForAppetiteSlider}
                                            radioHandler={radioHandler}
                                            setSliderValueFunction={setSliderValueFunction}
                                            appetitesSliderValueStyle={appetitesSliderValueStyle}
                                            appetiteClearFunction={appetiteClearFunction}
                                            appetitesSliderValueName={appetitesSliderValueName}
                                            appetitesSliderValue={appetitesSliderValue}
                                            appetiteNameChange={appetiteNameChange}
                                          />
                                        </div>
                                      </Box>
                                    </Grid>



                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        control={<Checkbox
                                          id={"chkStandard"}
                                          onClick={() => activeStandardGrid()}
                                        />
                                        }
                                        label={"Add related Standared if needed"} />
                                    </Grid>
                                    {openStandardGrid ?


                                      <Grid item xs={12}>
                                        <div style={{ textAlign: 'left' }}>
                                          <Card className={classes.cardDivDB}>
                                            <CardHeader
                                              avatar={
                                                <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                                  <Info />
                                                </Avatar>
                                              }
                                              className={classes.stdMainCardHeader}
                                              title="Add related Standared, Objective, Component and Principle"
                                            />
                                            <CardContent>
                                              <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                  <Box sx={{ minWidth: 120 }}>
                                                    <FormControl fullWidth>
                                                      <InputLabel id="demo-simple-select-label">Select From Global Standaed</InputLabel>
                                                      <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Select From Global Standaed"
                                                        value={globalStandardDataForMainRisk}
                                                        onChange={handleChangeGlobalStandardDataForMainRisk}

                                                      >
                                                        {globalStandardData.map((item) => (
                                                          <MenuItem
                                                            value={item.standardId}
                                                          >
                                                            {item.standardName}
                                                          </MenuItem>
                                                        ))}

                                                      </Select>

                                                    </FormControl>
                                                  </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                  <Box sx={{ minWidth: 120 }}>
                                                    <FormControl fullWidth>
                                                      <InputLabel id="demo-simple-select-label">Select From Custom Standaed</InputLabel>
                                                      <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Select From Global Standaed"
                                                        value={customStandardDataForMainRisk}
                                                        onChange={handleChangeCustomStandardDataForMainRisk}

                                                      >
                                                        {customStandardData.map((item) => (
                                                          <MenuItem
                                                            value={item.standardId}
                                                          >
                                                            {item.standardName}
                                                          </MenuItem>
                                                        ))}

                                                      </Select>
                                                    </FormControl>
                                                  </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <Box sx={{ minWidth: 120 }}>
                                                    <FormControl fullWidth>
                                                      <InputLabel id="demo-simple-select-label">Select Objective</InputLabel>
                                                      <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Select From Global Standaed"
                                                        sx={{ width: '100%' }}

                                                        value={objectiveId}
                                                        onChange={handleChangeObjectives}
                                                      >
                                                        {standardData.filter(object => object.standardId === globalStandardDataForMainRisk || object.standardId === customStandardDataForMainRisk).map((stndrd) => (
                                                          stndrd.objectives.map((obj) => (
                                                            <MenuItem value={obj.objectiveId}> {obj.name}</MenuItem>
                                                          ))
                                                        ))}


                                                      </Select>
                                                    </FormControl>
                                                  </Box>
                                                </Grid>


                                                <Grid item xs={12}>
                                                  <Box sx={{ minWidth: 120 }}>
                                                    <FormControl fullWidth>
                                                      <InputLabel id="demo-simple-select-label">Select Component</InputLabel>
                                                      <Select
                                                        label='Select Component'
                                                        sx={{ width: '100%' }}

                                                        value={componentId}
                                                        onChange={handleChangecomponent}
                                                      >
                                                        {standardData.filter(object => object.standardId === globalStandardDataForMainRisk || object.standardId === customStandardDataForMainRisk).map((stndrd) => (
                                                          stndrd.components.map((com) => (
                                                            <MenuItem value={com.componentId}> {com.name}</MenuItem>
                                                          ))
                                                        ))}


                                                      </Select>
                                                    </FormControl>
                                                  </Box>
                                                </Grid>


                                                <Grid item xs={12}>
                                                  <Box sx={{ minWidth: 120 }}>
                                                    <FormControl fullWidth>
                                                      <InputLabel id="demo-simple-select-label">Select Principle</InputLabel>
                                                      <Select
                                                        label='Select Principle'
                                                        sx={{ width: '100%' }}

                                                        value={principleId}
                                                        onChange={handleChangeprinciple}
                                                      >

                                                        {standardData.filter(object => object.standardId === globalStandardDataForMainRisk || object.standardId === customStandardDataForMainRisk).map((stndrd) => (
                                                          stndrd.principles.map((pri) => (
                                                            <MenuItem value={pri.principalId}> {pri.name}</MenuItem>
                                                          ))
                                                        ))}


                                                      </Select>
                                                    </FormControl>
                                                  </Box>
                                                </Grid>






                                              </Grid>
                                            </CardContent>
                                          </Card>
                                        </div>
                                      </Grid>
                                      : ''
                                    }






                                  </Grid>
                                </DialogContentText>
                              </DialogContent>

                              <DialogActions>
                                <Button onClick={() => setMainRiskFunction()}>add</Button>
                                <Button onClick={mainRiskHandleClickClose}>Cancel</Button>
                              </DialogActions>
                            </Dialog>




                          </Grid>
                          <Grid item xs={12} spacing={2}>
                            {Object.keys(riskTypeData).length > 0 &&
                              <>
                                {
                                  riskTypeData.riskTypes.map((riskType) => {
                                    return (
                                      <Comment
                                        key={riskType.riskTypeTmpId}
                                        comment={riskType}
                                        lastRiskTmpId={lastRiskTmpId}
                                      //lastRiskTmpId={lastRiskTmpId}
                                      />
                                    )
                                  })
                                }
                              </>
                            }
                          </Grid>
                          {
                            showSaveBtn ?
                              <Grid item xs={10} spacing={2} style={{ marginBottom: '5px' }}>
                                <div style={{ float: 'right' }}>
                                  <Button
                                    variant="contained"
                                    size='small'
                                    style={{ "border": "1px solid #9155FD !important", "color": "#9155FD !important", "padding": "5px 10px !important", "height": "40px !important", }}
                                    onClick={saveRisk}
                                  >
                                    {saveBtnNameForRisk}
                                  </Button>&nbsp;&nbsp;
                                  <Button
                                    style={{ "border": "1px solid #9155FD !important", "color": "#9155FD !important", "padding": "5px 10px !important", "height": "40px !important" }}
                                    variant="contained"
                                    size='small'
                                    onClick={clearFuction}

                                  >Clear</Button>&nbsp;&nbsp;
                                </div>
                              </Grid>
                              : null
                          }

                        </Grid>

                      </Card>



                    </CardContent>



                  </Card>
                </Grid>










                {
                  showRiskHierarchyTable ?
                    <Grid item xs={4}>
                      <Card className={classes.stdCard}>
                        <CardHeader
                          avatar={
                            <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                              <Info />
                            </Avatar>
                          }
                          className={classes.stdMainCardHeader}
                          title="RisK Hierarchies Created"
                        />
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <div className={classes.scrollableDivToStandard}>
                                <TableContainer
                                // component={Paper}
                                // sx={{ maxHeight: 540 }}
                                >
                                  <Table aria-label="customized table">
                                    <TableHead className={classe.commonSecondaryTableHead}>
                                      <TableRow>
                                        <StyledTableCell className={classe.commonTableThStyle} style={{ width: '65%' }}>Risk Hierarchy Name</StyledTableCell>
                                        <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '15%' }}>Edit</StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    {previousRiskTableRowData}
                                  </Table>

                                </TableContainer>
                              </div>
                            </Grid>

                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    : null
                }




              </Grid>



            </>

          </Grid>

        </Grid>




      </div>
    </>
  )
}

export default RiskHierarchy
