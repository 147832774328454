import React, { useEffect, useState, useRef } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import axios from "axios";
import CardContent from "@mui/material/CardContent";
import { CONFIG } from "../config";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { Info, KeyboardReturnOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import "../Css/Slider.css";
import Draggable from "react-draggable";
import useStyle from "../Css/MainCss";
import Delete from "@mui/icons-material/Delete";
import { Editor } from "@tinymce/tinymce-react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { Alert } from "bootstrap";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Select, InputLabel } from '@mui/material';
import parseHtml from 'html-react-parser';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const useStyles = makeStyles((theme) => ({
  cardRiskManage: {
    width: "100%",
    border: "1px solid black",
  },
  riskImageSize: {
    width: "120px",
    height: "100px !important",
  },
  tableStyle: {
    // width: '55% !important',
    //minWidth: '720px !important'
  },
  root: {
    position: "relative",
  },
  thStyleImage: {
    lineHeight: "0.5rem !important",
    borderBottom: "0px !important",
    width: "130px !important",
  },
  posRelativeAndDivBorder: {
    position: "relative",
    border: "1px solid #4C7EE0",
    width: "120px",
    height: "100px !important",
  },
  thStyle: {
    fontSize: "12px !important",
    lineHeight: "0.5rem !important",
    borderBottom: "0px !important",
  },
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  cardRiskManage: {
    width: "100%",
    border: "1px solid black",
  },
  tableTrStyle: {
    height: "19px !important",
  },
  mousePointer: {
    cursor: "pointer",
  },
  stdMainCardHeader: {
    backgroundColor: "#4C7EE0 !important",
    fontSize: "13px !important",
    height: "50px",
    padding: "0px",
    marginTop: "-7px",
    color: "white",
  },
  addrisktypebtn: {
    marginLeft: "0px !important",
    border: "1px solid #4C7EE0 !important",
    color: "#4C7EE0 !important",
    padding: "5px 10px !important",
    height: "40px !important",
  },
  btnBorderpurple: {
    border: "1px solid #9155FD !important",
    color: "#9155FD !important",
    padding: "5px 10px !important",
    height: "40px !important",
  },
  stdCard: {
    height: "100%",
    border: "1px solid black",
  },
  scrollableDivToStandard: {
    height: "350px",
    overflow: "auto",
    width: "100%",
  },
  btnColorPurple: {
    fontSize: "12px !important",
    backgroundColor: "#BACDF2 !important",
    height: "40px",
    textAlign: "center",
    color: "#4C7EE0 !important",
    border: "1px solid #4C7EE0",
  },
  btnBorderGreen: {
    border: "1px solid #4C7EE0 !important",
    color: "#4C7EE0 !important",
    height: "40px",
  },
}));

const AddSubContentComponent = ({tokens, subType}) => {
  const classe = useStyle();
  const classes = useStyles();
  const Input = styled("input")({
    display: "none",
  });

  const [selectedFileSubPages, setSelectedFileSubPages] = useState(null);
  const [previewSubPages, setPreviewSubPages] = React.useState();
  const [imgLocationSubPages, setImgLocationSubPages] = useState("");
  const [subPageDesign, setsubPageDesign] = useState("");
  const [logInPageDesignId, setLogInPageDesignId] = useState("");
  const [backgroundColorSubPages, setBackgroundColorSubPages] = useState("");
  const [pagesTemplates, setPagesTemplates] = useState([]);
  const [documentId, setDocumentId] = useState("");
  const [parentIdFromDb, setParentIdFromDb] = useState("");
  const [parentDomainName, setParentDomainName] = useState("");
  const [allLoginPages, setAllLoginPages] = useState([]);

  var hideDisplay = 'none';
  if (subType === 'loginpage'){
    hideDisplay = 'block';
  }

  React.useEffect(() => {
    getHtmlData()
    // getTemplates()
    getHtmlSubData();

    getAllSubPages();


    if (!selectedFileSubPages) {
      getHtmlData();
      setPreviewSubPages(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFileSubPages);
    setPreviewSubPages(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFileSubPages]);

  const handleColorSubPages = (event) => {
    setBackgroundColorSubPages(event.target.value);
    setPressClearColor(false);
  };

  const [pageNameForSave, setPageNameForSave] = React.useState('');
  const onTextFieldChangepageNameForSave = (event) => {
    setPageNameForSave(event.target.value);
  }

  const [pageOrderForSave, setPageOrderForSave] = React.useState('');
  const onTextFieldChangepageOrderForSave = (event) => {
    var inputValue = event.target.value.replace(/[^0-9]/g, '');
    setPageOrderForSave(inputValue);
  }

  function compareDomain(a, b) {
    var nameA = a.domainName.toUpperCase(); // ignore upper and lowercase
    var nameB = b.domainName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) { return -1; }
    if (nameA > nameB) { return 1; }
    return 0;
}

  function getHtmlData() {
    var sendUrl = "";
    if (subType === 'loginpage'){
      sendUrl = '/loginPageContent/all';
    }else{
      if (subType === 'aboutus'){
        sendUrl = '/aboutUsPageContent/all';
      }else{
        sendUrl = '/ourServicesPageContent/all';
      }
    }
    axios(CONFIG.MAIN_URL + sendUrl, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("All Collections Returns");
        console.log(response.data);
        var arr = [];
        response.data.forEach((data) => {
          if (data.versionNumber != 'current'){
            arr.push(data);
          }
        })
        arr = arr.sort(compareDomain)
        console.log(arr)
        setAllLoginPages(arr);
      })
      .catch(function (error) {
        console.log(error);
      });
  } 

  const editorRef1 = useRef(null);

  const [tinyMCETemplateListSubPages, setTinyMCETemplateListSubPages] =
    React.useState();


    const [allSubPagesData, setAllSubPagesData] = useState([]);
  
    function getAllSubPages() {
      var sendUrl = "";
    if (subType === 'loginpage'){
      sendUrl = '/loginPageSubPage/all';
    }else{
      if (subType === 'aboutus'){
        sendUrl = '/aboutUsPageSubPage/all';
      }else{
        sendUrl = '/ourServicesPageSubPage/all';
      }
    }
      axios(CONFIG.MAIN_URL + sendUrl, {
        method: "get",
        headers: {
          "Content-Type": "text/plain",
        },
      })
        .then(function (response) {
          console.log("All Collections Returns");
          console.log(response.data);
          setAllSubPagesData(response.data);
  
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  
  
  
    function getHtmlSubData(parentIdd) {

      var sendUrl = "";
    if (subType === 'loginpage'){
      sendUrl = '/loginPageSubPage/filter?parentId=' + parentIdd;
    }else{
      if (subType === 'aboutus'){
        sendUrl = '/aboutUsPageSubPage/filter?parentId=' + parentIdd;
      }else{
        sendUrl = '/ourServicesPageSubPage/filter?parentId=' + parentIdd;
      }
    }

    axios(CONFIG.MAIN_URL + sendUrl, {
      method: "get",
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then(function (response) {
        console.log("All Collections Returns");
        console.log(response.data);
        setPagesTemplates(response.data[0].pages);
        setDocumentId(response.data[0].id);
        setParentIdFromDb(response.data[0].parentId);
        setParentDomainName(response.data[0].parentDomainName);

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function clearImageFuctionSubPages() {
    setPreviewSubPages();
    setSelectedFileSubPages(null);
    setImgLocationSubPages("");
  }

  const [pressClearColor, setPressClearColor] = useState(false);
  function clearColorFuctionSubPages() {
    setBackgroundColorSubPages("#ffffff");
    setPressClearColor(true);
  }


  const [showKeyCodeSection, setShowKeyCodeSection] = useState('none');
  const [showLoginConent, setShowLoginConent] = useState('none');
  const [showEditBtn, setShowEditBtn] = useState('none');
  const [showDeleteBtn, setShowDeleteBtn] = useState('none');
  const [showAddNewBtn, setShowAddNewBtn] = useState('none');
  const [showSaveUpdateBtn, setShowSaveUpdateBtn] = useState('none');


  const [addUpdateButtonSubPages, setAddUpdateButtonSubPages] =
    useState("SAVE");
  const [showEditButtonSubPages, setShowEditButtonSubPages] = useState(true);
  const [selectedKeyCode, setSelectedKeyCode] = useState("");

  const keyCodeSelecter = (event, newValue) => {
    clearFuctionKeyCode();
    setSelectedKeyCode(newValue);
    setShowEditBtn('block');
    setShowDeleteBtn('block');
    setShowAddNewBtn('none');
  };
  const keyCodeSelecter1 = (event) => {
    clearFuctionKeyCode();
    setSelectedKeyCode(event.target.value);
    var found = false;
    pagesTemplates.map((data) => {
      if (data.keyCode === event.target.value) {
        found = true;
        setShowEditBtn('block');
        setShowDeleteBtn('block');
        setShowAddNewBtn('none');
      }
    });
    if (!found) {
      setShowEditBtn('none');
      setShowDeleteBtn('none');
      if (event.target.value != '') {
        setShowAddNewBtn('block');
      } else {
        setShowAddNewBtn('none');
      }
    }






  };

  function clearFuctionKeyCode() {
    // setShowOtherContentBtn(false)
    clearImageFuctionSubPages();
    setPreviewSubPages();
    setBackgroundColorSubPages("#ffffff");
    setPressClearColor(true);
    setImgLocationSubPages("");
    setAddUpdateButtonSubPages("SAVE");
    setSelectedKeyCode('')
    setsubPageDesign('')
    setPageNameForSave('')
    setPageOrderForSave('')
    setIsHide('');

    setShowLoginConent('none');
    setShowEditBtn('none');
    setShowDeleteBtn('none');
    setShowAddNewBtn('none');
    setShowSaveUpdateBtn('none');



    editorRef1.current.getContent()
  }

  function addNewKeyCode() {
    setShowLoginConent('block');
    setShowSaveUpdateBtn('block');
    setShowAddNewBtn('none');
  }

  function keyCodeEdit() {
    pagesTemplates.map((data) => {
      if (data.keyCode === selectedKeyCode) {
        setShowLoginConent('block');
        setShowSaveUpdateBtn('block');
        setShowEditBtn('none');
        setShowDeleteBtn('none');

        console.log(data);
        setPageNameForSave(data.pageName)
        setPageOrderForSave(data.orderNumber)
        //setShowOtherContentBtn(true);
        setAddUpdateButtonSubPages("Update");
        setsubPageDesign(data.pageContent);

        if (data.isHide != undefined && data.isHide != null) {
          setIsHide(data.isHide);
        } else {
          setIsHide('no');
        }

        if (data.backgroundUrl != undefined && data.backgroundUrl != null) {
          setPreviewSubPages(data.backgroundUrl);
          setImgLocationSubPages(data.backgroundUrl);
          //setSelectedFileSubPages(data.backgroundUrl);
        }
        if (data.backgroundColor != undefined && data.backgroundColor != null && data.backgroundColor != '') {
          setBackgroundColorSubPages(data.backgroundColor);
          setPressClearColor(false);
        } else {
          setBackgroundColorSubPages('#ffffff');
          setPressClearColor(true);
        }
        editorRef1.current.setContent(data.pageContent);
      }
    });
  }

  function addeditpagetem() {

    if (pageNameForSave === '') {
      alert('page name can not be empty');
      return;
    }
    if (pageOrderForSave === '') {
      alert('page order number can not be empty');
      return;
    }
    var obj = {};

    obj["id"] = documentId;
    obj["parentId"] = parentIdFromDb;
    obj["parentDomainName"] = selectedDomainRealName;
    obj["parentVersionNumber"] = selectedDomainVersionNo;



    var editData = false;

    //new
    pagesTemplates.map((data) => {
      if (data.keyCode === selectedKeyCode) {
        editData = true
      }

    });




    var arr = [];

    if (editData) {
      if (selectedFileSubPages != null) {
        let formdata = new FormData();
        formdata.append('file', selectedFileSubPages);
        formdata.append('centreId', 'superadmin');
        axios(CONFIG.MAIN_URL + '/image/upload', {
          method: 'post',
          data: formdata,
        })
          .then(function (response) {
            console.log(response.data.location);
            if (response.data.location === null || response.data.location === "") {
              alert(response.data.message + " and please use a different image name");
              return;
            } else {
              var tmpLocation = response.data.location;
              setImgLocationSubPages(tmpLocation);
              setSelectedFileSubPages(null);
              pagesTemplates.map((data) => {
                var obj1 = {};
                if (data.keyCode === selectedKeyCode) {
                  obj1["keyCode"] = selectedKeyCode;
                  obj1["pageName"] = pageNameForSave;
                  obj1["orderNumber"] = pageOrderForSave;
                  obj1["pageContent"] = editorRef1.current.getContent();
                  obj1["backgroundUrl"] = tmpLocation;
                  if (pressClearColor) {
                    obj1["backgroundColor"] = '';
                  } else {
                    obj1["backgroundColor"] = backgroundColorSubPages;
                  }
                  if (isHide === '') {
                    obj1["isHide"] = 'no';
                  } else {
                    obj1["isHide"] = isHide;
                  }
                  arr.push(obj1);
                } else {
                  arr.push(data);
                }
              });
              obj["pages"] = arr;

              console.log(obj);
              var sendJson = JSON.stringify(obj);
              console.log(sendJson);

              var sendUrl = "";
    if (subType === 'loginpage'){
      sendUrl = '/loginPageSubPage/update';
    }else{
      if (subType === 'aboutus'){
        sendUrl = '/aboutUsPageSubPage/update';
      }else{
        sendUrl = '/ourServicesPageSubPage/update';
      }
    }

              axios(CONFIG.MAIN_URL + sendUrl, {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                data: sendJson,
              })
                .then((res) => {
                  getHtmlSubData();
                  getHtmlData()
                  getAllSubPages()
                  alert("Updated Successfully");
                  clearFuctionKeyCode()
                  //setSelectedDomainName('')
                  setSelectedDomainName('')
                  setShowKeyCodeSection('none')
                })
                .catch(function (error) {
                  alert("Error Updating");
                  console.log(error);
                });
            }
          })
          .catch(function (error) {
            alert("Image Uploading Error. Image size is too big. Please reduce the image size before uploading the background image. Clear image to continue.");
            clearImageFuctionSubPages();
            console.log(error);
          })
      } else {
        pagesTemplates.map((data) => {
          var obj1 = {};
          if (data.keyCode === selectedKeyCode) {
            obj1["keyCode"] = selectedKeyCode;
            obj1["pageName"] = pageNameForSave;
            obj1["orderNumber"] = pageOrderForSave;
            obj1["pageContent"] = editorRef1.current.getContent();
            obj1["backgroundUrl"] = imgLocationSubPages;
            if (pressClearColor) {
              obj1["backgroundColor"] = '';
            } else {
              obj1["backgroundColor"] = backgroundColorSubPages;
            }
            if (isHide === '') {
              obj1["isHide"] = 'no';
            } else {
              obj1["isHide"] = isHide;
            }
            arr.push(obj1);
          } else {
            arr.push(data);
          }
        });
        obj["pages"] = arr;

        console.log(obj);
        var sendJson = JSON.stringify(obj);
        console.log(sendJson);

        var sendUrl = "";
    if (subType === 'loginpage'){
      sendUrl = '/loginPageSubPage/update';
    }else{
      if (subType === 'aboutus'){
        sendUrl = '/aboutUsPageSubPage/update';
      }else{
        sendUrl = '/ourServicesPageSubPage/update';
      }
    }

        axios(CONFIG.MAIN_URL + sendUrl, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: sendJson,
        })
          .then((res) => {
            getHtmlSubData();
            getHtmlData()
            getAllSubPages()
            alert("Updated Successfully");
            clearFuctionKeyCode()
            //setSelectedDomainName('')
            setSelectedDomainName('')
            setShowKeyCodeSection('none')
          })
          .catch(function (error) {
            alert("Error Updating");
            console.log(error);
          });
      }

    } else {

      if (selectedFileSubPages != null) {
        let formdata = new FormData();
        formdata.append('file', selectedFileSubPages);
        formdata.append('centreId', 'superadmin');
        axios(CONFIG.MAIN_URL + '/image/upload', {
          method: 'post',
          data: formdata,
        })
          .then(function (response) {
            console.log(response.data.location);
            if (response.data.location === null || response.data.location === "") {
              alert(response.data.message + " and please use a different image name");
              return;
            } else {
              var tmpLocation = response.data.location;
              setImgLocationSubPages(tmpLocation);
              setSelectedFileSubPages(null);
              var obj1 = {};

              obj1["keyCode"] = selectedKeyCode;
              obj1["pageName"] = pageNameForSave;
              obj1["orderNumber"] = pageOrderForSave;
              obj1["pageContent"] = editorRef1.current.getContent();
              obj1["backgroundUrl"] = tmpLocation;
              if (pressClearColor) {
                obj1["backgroundColor"] = '';
              } else {
                obj1["backgroundColor"] = backgroundColorSubPages;
              }

              if (isHide === '') {
                obj1["isHide"] = 'no';
              } else {
                obj1["isHide"] = isHide;
              }

              pagesTemplates.push(obj1)
              console.log(pagesTemplates);

              obj["pages"] = pagesTemplates;

              console.log(obj);
              var sendJson = JSON.stringify(obj);
              console.log(sendJson);

              var sendUrl = "";
    if (subType === 'loginpage'){
      sendUrl = '/loginPageSubPage/save';
    }else{
      if (subType === 'aboutus'){
        sendUrl = '/aboutUsPageSubPage/save';
      }else{
        sendUrl = '/ourServicesPageSubPage/save';
      }
    }


              axios(CONFIG.MAIN_URL + sendUrl, {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                data: sendJson,
              })
                .then((res) => {
                  getHtmlSubData();
                  getHtmlData()
                  getAllSubPages()
                  //setSelectedDomainName('')
                  alert("saved Successfully");
                  clearFuctionKeyCode();
                  setSelectedDomainName('')
                  setShowKeyCodeSection('none')


                })
                .catch(function (error) {
                  alert("Error Updating");
                  console.log(error);
                });

            }
          })
          .catch(function (error) {
            alert("Image Uploading Error. Image size is too big. Please reduce the image size before uploading the background image. Clear image to continue.");
            clearImageFuctionSubPages();

            console.log(error);
          })
      } else {
        var obj1 = {};

        obj1["keyCode"] = selectedKeyCode;
        obj1["pageName"] = pageNameForSave;
        obj1["orderNumber"] = pageOrderForSave;
        obj1["pageContent"] = editorRef1.current.getContent();
        obj1["backgroundUrl"] = imgLocationSubPages;
        if (pressClearColor) {
          obj1["backgroundColor"] = '';
        } else {
          obj1["backgroundColor"] = backgroundColorSubPages;
        }

        if (isHide === '') {
          obj1["isHide"] = 'no';
        } else {
          obj1["isHide"] = isHide;
        }

        pagesTemplates.push(obj1)
        console.log(pagesTemplates);

        obj["pages"] = pagesTemplates;

        console.log(obj);
        var sendJson = JSON.stringify(obj);
        console.log(sendJson);

        var sendUrl = "";
    if (subType === 'loginpage'){
      sendUrl = '/loginPageSubPage/save';
    }else{
      if (subType === 'aboutus'){
        sendUrl = '/aboutUsPageSubPage/save';
      }else{
        sendUrl = '/ourServicesPageSubPage/save';
      }
    }

        axios(CONFIG.MAIN_URL + sendUrl, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: sendJson,
        })
          .then((res) => {
            getHtmlSubData();
            getHtmlData()
            getAllSubPages()
            //setSelectedDomainName('')
            alert("saved Successfully");
            clearFuctionKeyCode();
            setSelectedDomainName('')
            setShowKeyCodeSection('none')


          })
          .catch(function (error) {
            alert("Error Updating");
            console.log(error);
          });
      }




    }
  }

  const [selectedDomainName, setSelectedDomainName] = useState('');
  const [selectedDomainRealName, setSelectedDomainRealName] = useState('');
  const [selectedDomainVersionNo, setSelectedDomainVersionNo] = useState('');

  const [foundOtherDomain, setFoundOtherDomain] = useState(false);

  const domainSelecter = (event) => {
    setFoundOtherDomain(false)
    clearFuctionKeyCode()
    setPagesTemplates([])
    setSelectedDomainName(event.target.value);
    setShowKeyCodeSection('block')
    var tDomeinName = ''
    allLoginPages.map((data) => {
      if (data.id === event.target.value) {
        setDialogTitleHeader("Domain Name: " + data.domainName + " - " + "version: " +data.versionNumber)
        setParentIdFromDb(data.id)
        setSelectedDomainRealName(data.domainName);
        setSelectedDomainVersionNo(data.versionNumber)
        getHtmlSubData(data.id)

        tDomeinName = data.domainName
      }

    })

     var tFoundOtherDomain = false
    if (tDomeinName != '') {
      allLoginPages.map((data) => {
        if (data.domainName === tDomeinName && data.id != event.target.value && data.versionNumber != "current") {
            tFoundOtherDomain = true
        }
      })
    }

    if (tFoundOtherDomain) {
      setFoundOtherDomain(true)
    }

  }



  function selectDomainFunction() {
    allLoginPages.map((data) => {
      if (data.domainName === selectedDomainName) {
        setParentIdFromDb(data.id)
        getHtmlSubData(data.id);
      }
    })

  }

  const [isHide, setIsHide] = useState('');
  const isHideSelecter = (event) => {
    setIsHide(event.target.value);

  }

  const [maxWidth, setMaxWidth] = React.useState('md');
  const [newDialogOpen, setNewDialogOpen] = React.useState(false);
  const [userDerailsScroll, setUserDerailsScroll] = React.useState('paper');
  const [selectDomainForTemplateList, setSelectDomainForTemplateList] = React.useState([]);
  const [selectDomainTemplateList, setSelectDomainTemplateList] = React.useState([]);

  const [selectedDomainIdForAddPageDataToAnotherDomain, setSelectedDomainIdForAddPageDataToAnotherDomain] = useState('');
  const [dialogTitleHeader, setDialogTitleHeader] = React.useState('');

  const domainSelecterForAddPageDataToAnotherDomain = (event) => {
    setSelectedDomainIdForAddPageDataToAnotherDomain(event.target.value);
    allLoginPages.map((data) => {
      if (data.id === event.target.value) {

        var sendUrl = "";
    if (subType === 'loginpage'){
      sendUrl = '/loginPageSubPage/filter?parentId=' + event.target.value;
    }else{
      if (subType === 'aboutus'){
        sendUrl = '/aboutUsPageSubPage/filter?parentId=' + event.target.value;
      }else{
        sendUrl = '/ourServicesPageSubPage/filter?parentId=' + event.target.value;
      }
    }

    axios(CONFIG.MAIN_URL + sendUrl, {
      method: "get",
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then(function (response) {
        console.log("All Collections Returns");
        console.log(response.data);
        setSelectDomainTemplateList(response.data[0].pages);



      })
      .catch(function (error) {
        console.log(error);
      });

    }})

  }

  const [selectKeyCodeArr, setSelectKeyCodeArr] = React.useState([]);
  function getCheckStatus(regId) {
    var obj = {}
    var arr = [] 

    obj["keyCode"] = regId;
    arr.push(obj)


    setSelectKeyCodeArr(arr)

  }


  function openNewDialogClick() {
    console.log(allLoginPages)
    allLoginPages.map((option, index) => {
      if (option.domainName ===  selectedDomainRealName) {
        if (option.id != selectedDomainName) {
          console.log("+++++++++++++++++++++++XXXoption+++++++++++++++++++++++++");
          console.log(option);
          var avail = false;
          allSubPagesData.map((data) => {
            if (data.parentId === option.id){
              option["subpages"] = " - (Sub Pages Ok)"
              avail = true;
            }
          })
          if (!avail){
            option["subpages"] = ""
          }

          selectDomainForTemplateList.push(option)
        }
        
      }

    })
    setNewDialogOpen(true)
}

  const newDialogHandleClickClose = () => {
      setNewDialogOpen(false);
      setSelectDomainForTemplateList([])
      setSelectDomainTemplateList([])
      setSelectedDomainIdForAddPageDataToAnotherDomain('')
  };

  function updatePagesWithNewTemplates(){

        var selectedChkArr = []
        selectDomainTemplateList.forEach((row) => {
          if (document.getElementById("chkMenu" + row.keyCode).checked) {
            var obj1 = {};
            obj1["keyCode"] = row.keyCode;
            selectedChkArr.push(obj1);
          }
        });

        if (selectedChkArr.length === 0) {
          alert("you have not selected pages. Please select atleast one page");
          return;
        }

        console.log(selectedChkArr)



        var found = false;
        var keyCodeStr = "";
        pagesTemplates.map((data) => {
          var keyCode = data.keyCode;
          selectedChkArr.map((item) => {
            if (item.keyCode === keyCode){
              found = true;
              keyCodeStr = keyCodeStr + keyCode + "," 
            }
          })
        })
        if (found){
          alert("Cannot update. The following key codes exist in the selected domain \n\n" + keyCodeStr)
          return;
        }else{
          var obj = {}
          obj["id"] = documentId;
          obj["parentId"] = parentIdFromDb;
          obj["parentDomainName"] = selectedDomainRealName;
          obj["parentVersionNumber"] = selectedDomainVersionNo;
  
          var selectTemArr = []
          selectDomainTemplateList.map((data)=> {
              selectedChkArr.map((item)=> {
                if (data.keyCode === item.keyCode) {
                  pagesTemplates.push(data)
                  
                }

              })

          })


        obj["pages"] = pagesTemplates;

        console.log(obj);
        var sendJson = JSON.stringify(obj);
        console.log(sendJson);

        var sendUrl = "";
    if (subType === 'loginpage'){
      sendUrl = '/loginPageSubPage/save';
    }else{
      if (subType === 'aboutus'){
        sendUrl = '/aboutUsPageSubPage/save';
      }else{
        sendUrl = '/ourServicesPageSubPage/save';
      }
    }

        axios(CONFIG.MAIN_URL + sendUrl, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: sendJson,
        })
          .then((res) => {
            getHtmlSubData();
            getHtmlData()
            getAllSubPages()
            //setSelectedDomainName('')
            alert("updated Successfully");
            clearFuctionKeyCode();
            setSelectedDomainName('')
            setShowKeyCodeSection('none')
            setNewDialogOpen(false)
            setSelectDomainForTemplateList([])
            setSelectDomainTemplateList([])
            setSelectedDomainIdForAddPageDataToAnotherDomain('')


          })
          .catch(function (error) {
            alert("Error Updating");
            console.log(error);
          });

          
        }


  }


  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  function imagePreviw(url) {
        var str = "<img src=\"" + url + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      
  } 

  return (
    <>
      <Card className={classes.cardRiskManage}>
        <CardContent>
          <Card>
            <CardHeader
              avatar={
                <Avatar
                  sx={{ bgcolor: "#69676e", width: "25px", height: "25px" }}
                >
                  <Info />
                </Avatar>
              }
              className={classe.commonMainTableHead}
              title={"create / edit sub pages"}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography
                    style={{ marginRight: "8px", fontWeight: 400 }}
                  >
                    Domain Name:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" alignItems="center" style={{ gap: '5px' }}>

                    <FormControl fullWidth>
                      <InputLabel id="select-label">Select a Domain-name</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        value={selectedDomainName}
                        label="package-name"
                        onChange={domainSelecter} 

                      >
                        {allLoginPages.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {option.domainName} {"-"} <span style={{fontWeight:'bold'}}>{"( version"} {option.versionNumber} {")"}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {/** 
                <Grid item xs={1}>

                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ height: "36px" }}
                    onClick={() => {
                      selectDomainFunction();
                    }}
                  >
                    select
                  </Button>
                </Grid>
                  **/}
                <Grid item xs={6}>&nbsp;</Grid>

                <Grid item xs={12} style={{ display: `${showKeyCodeSection}` }}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Typography style={{ marginRight: "8px", fontWeight: 400 }}>
                        Page Template Code:
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: "5px" }}
                      >
                        <Autocomplete
                          id="package-name"
                          options={pagesTemplates.map((option) => option.keyCode)}
                          //title={pagesTemplates.map((option) => option.keyCode)}
                          value={selectedKeyCode}
                          onChange={(event, newValue) => {
                            keyCodeSelecter(event, newValue);
                          }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Type a new Template Code (Ex: page_1) OR select"
                              variant="outlined"
                              size="small"
                              style={{ width: "400px" }}
                              onChange={(event) => keyCodeSelecter1(event)}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <div>{option}</div>
                              <span style={{ fontSize: '9px' }}><b>{' - (Name: '}</b>{pagesTemplates.find((item) => item.keyCode === option)?.pageName}{''}
                                <b>{' - Is Hide: '}</b>{pagesTemplates.find((item) => item.keyCode === option)?.isHide}{')'}</span>
                            </li>
                          )}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ height: "36px", display: `${showSaveUpdateBtn}` }}
                          onClick={() => {
                            addeditpagetem();
                          }}
                        >
                          {addUpdateButtonSubPages}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ height: '36px', display: `${showAddNewBtn}` }}
                          onClick={() => {
                            addNewKeyCode()
                          }}
                        >
                          {'Add New'}
                        </Button>
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ height: "36px", display: `${showEditBtn}` }}
                            onClick={() => {
                              keyCodeEdit();
                            }}
                          >
                            EDIT
                          </Button>
                        </>
                        {/* : ''
                                            } */}

                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ height: "36px" }}
                          onClick={() => {
                            clearFuctionKeyCode();
                          }}
                        >
                          CLEAR
                        </Button>

                        {foundOtherDomain ? 
                        <>

                        <span>&nbsp;&nbsp;&nbsp;OR&nbsp;&nbsp;&nbsp;</span>

                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ height: "36px" }}
                            onClick={() => {
                              openNewDialogClick();
                            }}
                          >
                            select templates from other versions
                          </Button>
                        </>
                        </>
                        :''
                      }

                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} style={{ display: `${showLoginConent}` }}>
                  <Grid container spacing={2}>

                    <Grid item xs={2}>
                      <Typography
                        style={{ marginRight: "8px", fontWeight: 400 }}
                      >
                        Page Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box component="form" sx={{ '& .MuiTextField-root': { width: '87%' }, }} validate autoComplete="off">
                        <div>
                          <TextField
                            id="scaleDescription"
                            //label={"Type Answer Scale Example for " + selectedScaleName}
                            size="small"
                            //maxRows={2}
                            value={pageNameForSave}
                            onChange={onTextFieldChangepageNameForSave}
                          />
                        </div>
                      </Box>
                    </Grid>


                    <Grid item xs={1}>
                      <Typography
                        style={{ marginRight: "8px", fontWeight: 400 }}
                      >
                        Page Order:
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Box component="form" sx={{ '& .MuiTextField-root': { width: '50%' }, }} validate autoComplete="off">
                        <div>
                          <TextField
                            id="scaleDescription"
                            //label={"Type Answer Scale Example for " + selectedScaleName}
                            size="small"
                            //maxRows={2}
                            value={pageOrderForSave}
                            onChange={onTextFieldChangepageOrderForSave}

                          />
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        style={{ marginRight: "8px", fontWeight: 400 }}
                      >
                        Is Hide:
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Box display="flex" alignItems="center" style={{ gap: '5px' }}>

                        <FormControl fullWidth>
                          <InputLabel id="select-label">Select</InputLabel>
                          <Select
                            labelId="select-label"
                            id="select"
                            value={isHide}
                            label="package-name"
                            onChange={isHideSelecter}

                          >
                            <MenuItem value={'yes'}>
                              {'Yes'}
                            </MenuItem>
                            <MenuItem value={'no'}>
                              {'No'}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>


                    <Grid item xs={4} style={{display : `${hideDisplay}`}}>
                      <TableContainer>
                        <Table
                          aria-label="spanning table"
                          className={classes.tableStyle}
                          style={{ marginTop: "-7px" }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                rowSpan={2}
                                className={classes.thStyleImage}
                              >
                                <Box
                                  sx={{ minWidth: 120 }}
                                  className={classes.riskImageSize}
                                >
                                  <Card className={classes.root}>
                                    <div
                                      className={classes.posRelativeAndDivBorder}
                                    >
                                      {previewSubPages != null &&
                                        previewSubPages != "" && (
                                          <img
                                            src={previewSubPages}
                                            className={classes.riskImageSize}
                                          />
                                        )}
                                    </div>
                                  </Card>
                                </Box>
                              </TableCell>
                              <TableCell align="right" className={classes.thStyle}>
                                <Stack direction="row" spacing={2}>
                                  <label htmlFor="contained-button-file-1">
                                    <Input
                                      accept="image/*"
                                      id="contained-button-file-1"
                                      type="file"
                                      name="file"
                                      onChange={(event) => {
                                        console.log(event.target.files[0]);
                                        setSelectedFileSubPages(
                                          event.target.files[0]
                                        );
                                      }}
                                    />
                                    <Button
                                      variant="contained"
                                      component="span"
                                      className={classes.btnColorPurple}
                                      style={{ marginTop: "-55px" }}
                                    >
                                      Upload background Image
                                    </Button>
                                  </label>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={2} style={{display : `${hideDisplay}`}}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          clearImageFuctionSubPages();
                        }}
                        component="span"
                        className={classes.btnColorPurple}
                        style={{ marginTop: "9px" }}
                      >
                        Clear Image
                      </Button>
                    </Grid>

                    <Grid item xs={4} style={{display : `${hideDisplay}`}}>
                      <label>Pick background Color : </label>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="color"
                        style={{ width: "50%" }}
                        id="headerColor"
                        name="headerColor"
                        value={backgroundColorSubPages}
                        onChange={handleColorSubPages}
                      />
                    </Grid>
                    <Grid item xs={2} style={{display : `${hideDisplay}`}}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          clearColorFuctionSubPages();
                        }}
                        component="span"
                        className={classes.btnColorPurple}
                        style={{ marginTop: "0px", height: "32px" }}
                      >
                        Clear Color
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Editor
                        apiKey="ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj"
                        tinymceScriptSrc={
                          "https://cdn.tiny.cloud/1/ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj/tinymce/6/tinymce.min.js"
                        }
                        onInit={(evt, editor) => (editorRef1.current = editor)}
                        initialValue={subPageDesign}
                        init={{
                          referrer_policy: "origin",
                          height: 300,
                          menubar: true,
                          placeholder: "sub Page Design",
                          plugins:
                            "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media mediaembed template codesample table advtable charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help charmap quickbars emoticons casechange formatpainter pageembed hr fullpage advcode export permanentpen powerpaste",
                          imagetools_cors_hosts: ["picsum.photos"],
                          //font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
                          menubar: "file edit view insert format tools table help",
                          toolbar: "undo redo | bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | casechange forecolor backcolor formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview print | pageembed insertfile image media template tokens link anchor codesample | ltr rtl hr fullpage export permanentpen configurepermanentpen",
                          //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          //content_css: '/TinyStyle.css',
                          content_css: [
                            'Bootstrap/bootstrap.css',
                            'Bootstrap/bootstrap.min.css',
                            'Bootstrap/bootstrap-theme.css',
                            'Bootstrap/bootstrap-theme.min.css',
                            '/TinyStyle.css',
                            '/stylesTiny.css',
                            '/Hero10Style.css'
                          ],
                          allow_script_urls: true,
                          export_image_proxy: "proxy.php",
                          toolbar_sticky: true,
                          autosave_ask_before_unload: true,
                          autosave_interval: "30s",
                          autosave_prefix: "{path}{query}-{id}-",
                          autosave_restore_when_empty: false,
                          autosave_retention: "2m",
                          setup: (editor) => {
                            editor.ui.registry.addMenuButton("tokens", {
                              text: "Token",
                              tooltip: "Insert token",
                              fetch: (callback) => {
                                  var items = tokens.map((token) => {
                                      return {
                                          type: "menuitem",
                                          text: token.text,
                                          onAction: () => {
                                              editor.insertContent(token.value);
                                          }
                                      }
                                  });
                                  callback(items);
                              }
                          });
                            editor.on("init", function (e) {
                              var content = unescape(subPageDesign);
                              editor.setContent(content);
                              editor.dom.bind(editor.getBody(), 'click', function (e) {
                                var target = e.target;
                        
                                // Check if the clicked element is an image
                                if (target.nodeName.toLowerCase() === 'img') {
                                  // Your onclick function logic here
                                  imagePreviw(target.src);  // Replace this with your actual onclick function
                                }
                              });
                            });
                          },

                          image_title: true,
                          /* enable automatic uploads of images represented by blob or data URIs*/
                          automatic_uploads: true,
                          /*Here we add custom filepicker only to Image dialog*/
                          file_picker_types: "image",

                          /* and here's our custom image picker*/
                          file_picker_callback: function (cb, value, meta) {
                            var input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", "image/*");

                            input.onchange = function () {
                              var file = this.files[0];
                              var reader = new FileReader();

                              reader.onload = function () {

                                var id = "blobid" + new Date().getTime();

                                var blobCache =
                                  editorRef1.current.editorUpload.blobCache;
                                //var blobCache = editorRef.current.activeEditor.editorUpload.blobCache;
                                var base64 = reader.result.split(",")[1];

                                var blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              };

                              reader.readAsDataURL(file);
                            };

                            input.click();
                          },

                          //images_upload_handler: function (
                          //  blobInfo,
                          // success,
                          //  failure
                          //) {

                          //},

                          templates: tinyMCETemplateListSubPages,

                          referrer_policy: "origin",

                          template_cdate_format:
                            "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
                          template_mdate_format:
                            "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
                          image_caption: true,
                          quickbars_selection_toolbar:
                            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                          noneditable_noneditable_class: "mceNonEditable",
                          toolbar_mode: "sliding",
                          contextmenu: "link image imagetools table",
                          //content_css: useDarkMode ? 'dark' : 'default',
                          //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>


              </Grid>
            </CardContent>
          </Card>
        </CardContent>
      </Card>

      <Dialog

                open={newDialogOpen}
                onClose={newDialogHandleClickClose}
                scroll={userDerailsScroll}
                fullWidth={true}
                maxWidth={'md'}           
                aria-labelledby="userDerails-scroll-dialog-title"
                aria-describedby="userDerails-scroll-dialog-description"
            >
                <DialogTitle id="draggable-dialog-title">
                Select templates from other versions for {dialogTitleHeader}
                </DialogTitle>
                <DialogContent dividers={userDerailsScroll === 'paper'}>
                    <DialogContentText
                        id="userDerails-scroll-dialog-description"
                        tabIndex={-1}
                    >
                <Grid container spacing={2}>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" style={{ gap: '5px' }}>

                    <FormControl fullWidth>
                      <InputLabel id="select-label">Select a version</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        value={selectedDomainIdForAddPageDataToAnotherDomain}
                        label="package-name"
                        onChange={domainSelecterForAddPageDataToAnotherDomain} 

                      >
                        {selectDomainForTemplateList.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {option.domainName} {" - "} <span style={{fontWeight:'bold'}}>{"( version"} {option.versionNumber} {")"}</span>
                            <span style={{fontWeight:'bold'}}>{option.subpages}</span> 

                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                      <Grid item xs={12}>
                            <Card
                              className="stdCardInside"
                              style={{ height: "300px", overflowY: "auto" }}
                            >
                              <CardContent>

                                <Grid container spacing={1}>
                                <Grid item xs={12}>
                                        <div className={classes.scrollableDivToStandard}>
                                            <TableContainer>
                                                <Table aria-label="customized table">
                                                    <TableHead className={classe.commonSecondaryTableHead}>
                                                        <TableRow>
                                                            <StyledTableCell className={classe.commonTableThStyle} style={{ width: '20%' }}>Select</StyledTableCell>
                                                            <StyledTableCell align="left" className={classe.commonTableThStyle} style={{ width: '20%' }}>Key Code</StyledTableCell>
                                                            <StyledTableCell align="left" className={classe.commonTableThStyle} style={{ width: '40%' }}>Page Name</StyledTableCell>
                                                            <StyledTableCell align="left" className={classe.commonTableThStyle} style={{ width: '20%' }}>Order Number</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {selectDomainTemplateList.map((data) => (
                                                      <>
                                                    <StyledTableRow key={data.id} className={classes.tableTrStyle}>
                                                       <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                                                          
                                                          <input
                                                                type="checkbox"
                                                                onClick={() => {
                                                                  getCheckStatus(
                                                                    data.keyCode
                                                                  );
                                                                }}
                                                                id={
                                                                  "chkMenu" +
                                                                  data.keyCode
                                                                }
                                                                name={
                                                                  "chkMenu" +
                                                                  data.keyCode
                                                                }
                                                              />
                                                          
                                                       </StyledTableCell>
                                                       <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                                                                        <Typography
                                                                sx={{
                                                                  fontSize:
                                                                    "1.00rem !important",
                                                                  marginLeft: "0px",
                                                                }}
                                                              >
                                                                {data.keyCode} 
                                                              </Typography>
                                                       </StyledTableCell>
                                                       <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                                                                        <Typography
                                                                sx={{
                                                                  fontSize:
                                                                    "1.00rem !important",
                                                                  marginLeft: "0px",
                                                                }}
                                                              >
                                                                {data.pageName} 
                                                              </Typography>
                                                       </StyledTableCell>
                                                       <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                                                                        <Typography
                                                                sx={{
                                                                  fontSize:
                                                                    "1.00rem !important",
                                                                  marginLeft: "0px",
                                                                }}
                                                              >
                                                                {data.orderNumber} 
                                                              </Typography>
                                                       </StyledTableCell>
                                                    </StyledTableRow>
                                                    </>
                                                    ))}
                                                    </TableBody>
                                                    
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </Grid>
                                  



                                </Grid>
                              </CardContent>
                            </Card>
                            <Box style={{gap:'5px', float:'right', marginTop:'10px'}}>
                            <>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{ height: "36px" }}
                              onClick={() => {
                                updatePagesWithNewTemplates();
                              }}
                            >
                              Update
                            </Button>
                          </>
                          &nbsp;&nbsp;&nbsp;
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{ height: "36px" }}
                              onClick={() => {
                                newDialogHandleClickClose();
                              }}
                            >
                              Close
                            </Button>
                          </>
                          </Box>
                          </Grid>
                          </Grid>

                    </DialogContentText>
                </DialogContent>
            </Dialog>


            <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>
    </>
  );
};

export default AddSubContentComponent;
