import React, { useEffect, useState, useRef } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import axios from "axios";
import CardContent from "@mui/material/CardContent";
import { CONFIG } from "../config";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { Info, KeyboardReturnOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import "../Css/Slider.css";
import Draggable from "react-draggable";
import useStyle from "../Css/MainCss";
import Delete from "@mui/icons-material/Delete";
import { Editor } from "@tinymce/tinymce-react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import parseHtml from 'html-react-parser';

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  root: {
    position: "relative",
  },
  cardRiskManage: {
    width: "100%",
    border: "1px solid black",
  },
  tableTrStyle: {
    height: "19px !important",
  },
  mousePointer: {
    cursor: "pointer",
  },
  stdMainCardHeader: {
    backgroundColor: "#4C7EE0 !important",
    fontSize: "13px !important",
    height: "50px",
    padding: "0px",
    marginTop: "-7px",
    color: "white",
  },
  addrisktypebtn: {
    marginLeft: "0px !important",
    border: "1px solid #4C7EE0 !important",
    color: "#4C7EE0 !important",
    padding: "5px 10px !important",
    height: "40px !important",
  },

  btnBorderpurple: {
    border: "1px solid #9155FD !important",
    color: "#9155FD !important",
    padding: "5px 10px !important",
    height: "40px !important",
  },
  stdCard: {
    height: "100%",
    border: "1px solid black",
  },
  scrollableDivToStandard: {
    height: "350px",
    overflow: "auto",
    width: "100%",
  },
}));

const SuperAdminLoginLandingPageDesign = () => {
  const classe = useStyle();
  const classes = useStyles();
  const Input = styled("input")({
    display: "none",
  });

  const editorRef = useRef(null);
  const [golobalCentreId, setGolobalCentreId] = React.useState("");

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    getData();
    getTemplates();
    getAllCentresFromServiceSystem();
  }, []);

  const [tinyMCETemplateList, setTinyMCETemplateList] = React.useState();

  var templateArr = [];
  function getTemplateHtml(fileName) {
    axios("https://www.daytaem.co.uk/readFile.php?q=" + fileName, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        var tmpA = fileName.split(".");
        var templateObj = {};
        templateObj["title"] = tmpA[0].replace("RST_", "");
        templateObj["description"] = "Risk System - Template ";
        templateObj["content"] = res.data;
        templateArr.push(templateObj);
        setTinyMCETemplateList(templateArr);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getTemplates() {
    var filesArr = "";
    axios("https://www.daytaem.co.uk/getFile.php?q=aaaaaaa", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        var resData = response.data;
        var arrTmp = resData.split("@@");
        var filesStr = arrTmp[1];

        filesArr = filesStr.split("]");
        var k = 1;

        var templateHtmlArr = [];
        for (var i = 1; i < filesArr.length; i++) {
          if (filesArr[i] != "") {
            if (filesArr[i].slice(0, 3) === "RST") {
              var fileName = filesArr[i];
              getTemplateHtml(fileName);
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getData() {
    let data = sessionStorage.getItem("sessionstorage");
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId);
    getAllLandingPagesData();
  }

  const [centreDesignData, setCentreDesignData] = useState([]);

  function getAllLandingPagesData() {
    axios(CONFIG.MAIN_URL + `/homePageContent/all`, {
      method: "get",
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then(function (response) {
        console.log("All Collections Returns");
        console.log(response.data);
        setCentreDesignData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // console.log(selectedFile)
  const [saveBtnName, setSaveBtnName] = useState("Save");
  const [createheaderInfoMessage, setCreateHeaderInfoMessage] = useState(
    "Create A Design For Landing Page"
  );
  const [centreName, setCentreName] = useState("");
  const [collectionShortDescription, setCollectionShortDescription] =
    useState("");
  const [collectionDescription, setCollectionDescription] = useState("");
  const [editCentreId, setEditCentreId] = useState("");
  const [showCollectionTable, setShowCollectionTable] = React.useState(true);

  // const centreNameChange = (event) => {
  //    setCentreName(event.target.value);
  // }

  const [allCentres, setAllCentres] = React.useState([]);
  function getAllCentresFromServiceSystem() {
    var data = "{getAllCentreList{centreId,centreName}}";
    axios(CONFIG.MAIN_URL + "/graphql", {
      method: "post",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    })
      .then(function (response) {
        var centreList = response.data.data.getAllCentreList;
        console.log("All Centre List");
        console.log(centreList);
        setAllCentres(centreList);
      })
      .catch(function (error) {
        console.log("Entity Loding error" + error);
      });
  }

  const handleChangeCentre = (event) => {
    setVersionNumberArr([]);
    setSelectVersion("");
    clearFuction();
    setCentreName(event.target.value);
    var arr = [];
    centreDesignData.forEach((data) => {
      if (data.centreId === event.target.value) {
        console.log(data);
        arr.push(data);
      }
      setVersionNumberArr(arr);
    });
    console.log(versionNumberArr);
  };

  function editCentreData(id) {
    setShowCollectionTable(false);
    setEditCentreId(id);
    centreDesignData.forEach((data) => {
      var verArr = [];
      if (data.id === id) {
        console.log(data);
        setEditCentreId(data.id);
        setCentreName(data.centreId);
        editorRef.current.setContent(data.pageContent);
        setSaveBtnName("Update");
        setCreateHeaderInfoMessage("Edit Landing Page");
      }
    });
  }

  function deleteCollectionData(id) {
    centreDesignData.forEach((item) => {
      if (item.id === editCentreId) {
        var sendJson = JSON.stringify(item);
        console.log(
          "+++++++++++++++++++sendJson To Delete++++++++++++++++++++"
        );
        console.log(sendJson);
        var endPoint = "/homePageContent/delete";
        axios(CONFIG.MAIN_URL + endPoint, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: sendJson,
        })
          .then((res) => {
            alert("Deleted Successfully");
            clearFuction();
            getAllLandingPagesData();
          })
          .catch(function (error) {
            alert("Error Deleting ");
            console.log(error);
          });
      }
    });
  }

  const [alreadyCreatedDesign, setAlreadyCreatedDesign] = useState(false);

  const [showTinymce, setShowTinymce] = useState('none');

  function createnewversionButton() {
    setShowTinymce('block');
  }
 

  function clearFuction() {
    setShowCollectionTable(true);
    setEditCentreId("");
    setCentreName("");
    editorRef.current.setContent("");
    setSaveBtnName("Save");
    setCreateHeaderInfoMessage("Create A Landing Page Design");
    setSelectVersion("");
    setBaseVersion("");
    setBaseVersionId("");
    setVersionNumber("");
    setVersionNumberArr([])
    setShowTinymce('none');
  }

  const previousCollectionTableRowData = (
    <TableBody>
      {centreDesignData.map((item) => {
        return (
          <StyledTableRow key={item.id} className={classes.tableTrStyle}>
            <StyledTableCell
              component="th"
              scope="row"
              className={classes.tableTrStyle}
            >
              <div style={{ whiteSpace: "normal" }}>{item.centreId}</div>
            </StyledTableCell>
            <StyledTableCell align="right" className={classes.tableTrStyle}>
              <EditIcon
                className={classes.mousePointer}
                onClick={() => {
                  editCentreData(item.id);
                }}
              />
            </StyledTableCell>
            <StyledTableCell align="right" className={classes.tableTrStyle}>
              <Delete
                className={classes.mousePointer}
                onClick={() => {
                  deleteCollectionData(item.id);
                }}
              />
            </StyledTableCell>
          </StyledTableRow>
        );
      })}
    </TableBody>
  );

  const [versionNumberArr, setVersionNumberArr] = useState([]);
  const [selectVersion, setSelectVersion] = useState("");

  const [baseVersion, setBaseVersion] = useState("");
  const [baseVersionId, setBaseVersionId] = useState("");
  const [versionNumber, setVersionNumber] = useState("");

  const handleChangeVersion = (event) => {
    setEditCentreId("");
    editorRef.current.setContent("");
    setSelectVersion("");
    setBaseVersion("");
    setBaseVersionId("");
    setVersionNumber("");
    setShowTinymce('none');
    setSelectVersion(event.target.value);

  };

  function editPreviousVersion() {
    setShowTinymce("block");

    versionNumberArr.map((data) => {
      if (data.id === selectVersion) {
        console.log(data);
        setEditCentreId(data.id);
        setCentreName(data.centreId);
        if (
          data.pageContent != null &&
          data.pageContent != undefined &&
          data.pageContent != ""
        ) {
          editorRef.current.setContent(data.pageContent);
        }
        setBaseVersion(data.baseVersion);
        setBaseVersionId(data.baseVersionId);
        setVersionNumber(data.versionNumber);
        setSaveBtnName("Update");
        setCreateHeaderInfoMessage("Edit Landing Page");
      }
    });
  }



  const [frameSize, setFrameSize] = useState(12);

  function saveCollectionFunction() {
    var desc = "";
    if (editorRef.current) {
      desc = editorRef.current.getContent();
    }
    if (desc === "") {
      alert("Please enter Design");
      return;
    }
    var obj = {};
    obj["centreId"] = centreName;
    if (editCentreId != "") {
      obj["id"] = editCentreId;
      obj["baseVersion"] = baseVersion;
      if (baseVersionId != null) {
        obj["baseVersionId"] = baseVersionId;
      } else {
        obj["baseVersionId"] = "";
      }
      obj["versionNumber"] = versionNumber;
    } else {
      obj["baseVersion"] = "";
      obj["baseVersionId"] = "";

      if (versionNumberArr.length === 0) {
        obj["versionNumber"] = 1;
      } else {
        obj["versionNumber"] = parseInt(versionNumberArr.length);
      }
    }

    obj["pageContent"] = desc;

    var sendJson = JSON.stringify(obj);
    console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
    console.log(sendJson);
    var endPoint = '';
    if (editCentreId != "") {
      endPoint = "/homePageContent/update";
    }else{
      endPoint = "/homePageContent/save"
    }

    axios(CONFIG.MAIN_URL + endPoint, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: sendJson,
    })
      .then((res) => {
        if (editCentreId != "") {
          alert("Successfully Updated ");
          clearFuction();
          getAllLandingPagesData();
        } 

        if (editCentreId === "") {
          var rtnData = res.data;
          var tVersionNumber = rtnData.versionNumber;
          var tid = rtnData.id;
          var obj = {};
          obj["centreId"] = centreName;
          obj["baseVersion"] = tVersionNumber;
          obj["baseVersionId"] = tid;  
          obj["versionNumber"] = "current";

          obj["pageContent"] = editorRef.current.getContent();

          var sendJson = JSON.stringify(obj);
          console.log("+++++++++++++++++++sendJson To 2nd Save++++++++++++++++++++");
          console.log(sendJson);
          var endPoint = '';

            endPoint = "/homePageContent/save"
          
      
          axios(CONFIG.MAIN_URL + endPoint, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            data: sendJson,
          })
            .then((res) => {
              clearFuction();
              getAllLandingPagesData();
              alert(" Saved Successfully")
              
            })
            .catch(function (error) {
              alert('Error Updating');
              console.log(error);
          });
        }
      })
      .catch(function (error) {
        alert("Error Updating the collection");
        console.log(error);
      });
  }

  function saveFunctionNewVersion() {
    var desc = "";
    if (editorRef.current) {
      desc = editorRef.current.getContent();
    }
    if (desc === "") {
      alert("Please enter Design");
      return;
    }

    var obj = {};
    obj["id"] = editCentreId;
    obj["centreId"] = centreName;
    obj["baseVersion"] = baseVersion;
    if (baseVersionId != null) {
      obj["baseVersionId"] = baseVersionId;
    } else {
      obj["baseVersionId"] = "";
    }

    obj["versionNumber"] = "new";
    obj["pageContent"] = desc;

    var sendJson = JSON.stringify(obj);
    console.log(sendJson);
    var sendURL = "/homePageContent/save";
    if (editCentreId != "") {
      sendURL = "/homePageContent/update";
    }
    axios(CONFIG.MAIN_URL + sendURL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: sendJson,
    })
      .then((res) => {
        clearFuction();
        getAllLandingPagesData();
        alert("Saved Successfully in a new version ");
      })
      .catch(function (error) {
        alert("Error Updating");
        console.log(error);
      });
  }

  function saveFunctionCurrentVersion() {

    var docId = ""
    versionNumberArr.map((data) => {
        if (data.versionNumber === 'current') {
            docId = data.id;
        }
    })
    if (versionNumber === "current") {
      alert("this version is already current version");
      return;
      
    }
    
    var obj = {};
    obj["id"] = docId;
    obj["centreId"] = centreName;
    obj["baseVersion"] = versionNumber;
    obj["baseVersionId"] = baseVersionId;
    obj["versionNumber"] = "current";
    obj["pageContent"] = editorRef.current.getContent();

    var sendJson = JSON.stringify(obj);
    console.log(sendJson);
    var sendURL = "/homePageContent/save";

    axios(CONFIG.MAIN_URL + sendURL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: sendJson,
    })
      .then((res) => {
        clearFuction();
        getAllLandingPagesData();
        alert("Saved Successfully as the current version ");
      })
      .catch(function (error) {
        alert("Error Updating");
        console.log(error);
      });
  }

  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  function imagePreviw(url) {
        var str = "<img src=\"" + url + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      
  } 

  return (
    <>
      <div>
        <Grid>
          <Grid container spacing={2}>
            <>
              <Grid item xs={frameSize}>
                <Card className={classes.cardRiskManage}>
                  <CardContent>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "#69676e",
                              width: "25px",
                              height: "25px",
                            }}
                          >
                            <Info />
                          </Avatar>
                        }
                        className={classe.commonMainTableHead}
                        title={createheaderInfoMessage}
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Box
                              component="form"
                              sx={{
                                "& .MuiTextField-root": {
                                  mt: 1,
                                  width: "100%",
                                },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              {" "}
                              <FormControl fullWidth>
                                <TextField
                                  label="Select a Centre"
                                  sx={{ width: "100%" }}
                                  select
                                  value={centreName}
                                  onChange={handleChangeCentre}
                                >
                                  {allCentres.map((item) => (
                                    <MenuItem value={item.centreId}>
                                      {item.centreName}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={3}>
                            <Box
                              component="form"
                              sx={{
                                "& .MuiTextField-root": {
                                  mt: 1,
                                  width: "100%",
                                },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              {" "}
                              <FormControl fullWidth>
                                <TextField
                                  label="Select a version"
                                  sx={{ width: "100%" }}
                                  select
                                  value={selectVersion}
                                  onChange={handleChangeVersion}
                                >
                                  {versionNumberArr.map((item) => (
                                    <MenuItem value={item.id}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {"Version: "}&nbsp;
                                      </span>
                                      {item.versionNumber}{" "}
                                      <span style={{ fontWeight: "bold" }}></span>
                                      {
                                        item.versionNumber === "current" ?
                                        <>
                                      &nbsp;<span style={{ fontWeight: "bold" }}>{" - Base Version: "}</span>&nbsp;
                                      {item.baseVersion}
                                        </>
                                        :''
                                      }

                                    </MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={4}>
                            <Box>
                              {selectVersion != "" ? (
                                <>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{
                                      marginTop: "10px",
                                      height: "40px",
                                    }}
                                    onClick={() => {
                                      editPreviousVersion();
                                    }}
                                  >
                                    Edit
                                  </Button>
                                  {/* &nbsp;&nbsp;
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{
                                      marginTop: "10px",
                                      height: "40px",
                                    }}
                                    onClick={() => {
                                      deleteCollectionData();
                                    }}
                                  >
                                    delete
                                  </Button> */}
                                </>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  style={{ marginTop: "10px", height: "40px" }}
                                  onClick={() => {
                                    createnewversionButton();
                                  }}
                                >
                                  Create new version
                                </Button>
                              )}
                              &nbsp;&nbsp;
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginTop: "10px", height: "40px" }}
                                onClick={() => {
                                  clearFuction();
                                }}
                              >
                                Clear
                              </Button>
                            </Box>
                          </Grid>

                          {/* {showTinymce  ? */}
                          <>
                            <Grid item xs={12} style={{display: showTinymce}}>
                              <Editor
                                apiKey="ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj"
                                tinymceScriptSrc={
                                  "https://cdn.tiny.cloud/1/ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj/tinymce/6/tinymce.min.js"
                                }
                                onInit={(evt, editor) =>
                                  (editorRef.current = editor)
                                }
                                initialValue={collectionDescription}
                                init={{
                                  referrer_policy: "origin",
                                  height: 300,
                                  menubar: true,
                                  placeholder: "Landing Page Design",
                                  plugins:
                                    "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media mediaembed template codesample table advtable charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help charmap quickbars emoticons casechange formatpainter pageembed hr fullpage advcode export permanentpen powerpaste",
                                  imagetools_cors_hosts: ["picsum.photos"],
                                  menubar:
                                    "file edit view insert format tools table help",
                                  toolbar:
                                    "undo redo | bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | casechange forecolor backcolor formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview print | pageembed insertfile image media template tokens link anchor codesample | ltr rtl hr fullpage export permanentpen configurepermanentpen",
                                  //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                                  export_image_proxy: "proxy.php",
                                  toolbar_sticky: true,
                                  autosave_ask_before_unload: true,
                                  autosave_interval: "30s",
                                  autosave_prefix: "{path}{query}-{id}-",
                                  autosave_restore_when_empty: false,
                                  autosave_retention: "2m",
                                  setup: (editor) => {
                                    editor.on("init", function (e) {
                                      var content = unescape(
                                        collectionDescription
                                      );
                                      editor.setContent(content);
                                      editor.dom.bind(editor.getBody(), 'click', function (e) {
                                        var target = e.target;
                                
                                        // Check if the clicked element is an image
                                        if (target.nodeName.toLowerCase() === 'img') {
                                          // Your onclick function logic here
                                          imagePreviw(target.src);  // Replace this with your actual onclick function
                                        }
                                      });
                                    });
                                  },

                                  image_title: true,
                                  /* enable automatic uploads of images represented by blob or data URIs*/
                                  automatic_uploads: true,
                                  /*Here we add custom filepicker only to Image dialog*/
                                  file_picker_types: "image",

                                  /* and here's our custom image picker*/
                                  file_picker_callback: function (
                                    cb,
                                    value,
                                    meta
                                  ) {
                                    var input = document.createElement("input");
                                    input.setAttribute("type", "file");
                                    input.setAttribute("accept", "image/*");

                                    input.onchange = function () {
                                      var file = this.files[0];
                                      var reader = new FileReader();

                                      reader.onload = function () {
                                        /*
                                                              Note: Now we need to register the blob in TinyMCEs image blob
                                                              registry. In the next release this part hopefully won't be
                                                              necessary, as we are looking to handle it internally.
                                                              */
                                        var id =
                                          "blobid" + new Date().getTime();

                                        var blobCache =
                                          editorRef.current.editorUpload
                                            .blobCache;
                                        //var blobCache = editorRef.current.activeEditor.editorUpload.blobCache;
                                        var base64 =
                                          reader.result.split(",")[1];

                                        var blobInfo = blobCache.create(
                                          id,
                                          file,
                                          base64
                                        );
                                        blobCache.add(blobInfo);

                                        /* call the callback and populate the Title field with the file name */
                                        cb(blobInfo.blobUri(), {
                                          title: file.name,
                                        });
                                      };

                                      reader.readAsDataURL(file);
                                    };

                                    input.click();
                                  },

                                  //images_upload_handler: function (blobInfo, success, failure) {

                                  //},

                                  templates: tinyMCETemplateList,

                                  referrer_policy: "origin",

                                  template_cdate_format:
                                    "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
                                  template_mdate_format:
                                    "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
                                  image_caption: true,
                                  quickbars_selection_toolbar:
                                    "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                                  noneditable_noneditable_class:
                                    "mceNonEditable",
                                  toolbar_mode: "sliding",
                                  contextmenu: "link image imagetools table",
                                  //content_css: useDarkMode ? 'dark' : 'default',
                                  content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}></Grid>
                            
                            <Grid item xs={6} style={{ textAlign: "right" }}>
                            <div style={{display: showTinymce}}>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginTop: "10px", height: "46px" }}
                                onClick={() => {
                                  saveCollectionFunction();
                                }}
                              >
                                {saveBtnName}
                              </Button>
                              &nbsp;&nbsp;
                              {versionNumber != '' ? 
                                                              <>
                                                              <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{
                                                                  marginTop: "10px",
                                                                  height: "46px",
                                                                }}
                                                                onClick={() => {
                                                                  saveFunctionNewVersion();
                                                                }}
                                                              >
                                                                Save As A New Version
                                                              </Button>
                                                              &nbsp;&nbsp;
                                                              <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{
                                                                  marginTop: "10px",
                                                                  height: "46px",
                                                                }}
                                                                onClick={() => {
                                                                  saveFunctionCurrentVersion();
                                                                }}
                                                              >
                                                                Make this as the Current Version
                                                              </Button>
                                                              &nbsp;&nbsp;
                                                            </>
                            :''
                            }

                             
                             
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginTop: "10px", height: "46px" }}
                                onClick={() => {
                                  clearFuction();
                                }}
                              >
                                Clear
                              </Button>
                              </div>
                            </Grid>
                           
                          </>
                          {/* :''
                                      } */}
                        </Grid>
                      </CardContent>
                    </Card>
                  </CardContent>
                </Card>
              </Grid>
            
                    {/* <Grid item xs={4}>
                        <Card className={classes.stdCard}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                    <Info />
                                    </Avatar>
                                }
                                className={classes.stdMainCardHeader}
                                title="Design Created centries"
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className={classes.scrollableDivToStandard}>
                                            <TableContainer>
                                                <Table aria-label="customized table">
                                                    <TableHead className={classe.commonSecondaryTableHead}>
                                                        <TableRow>
                                                            <StyledTableCell className={classe.commonTableThStyle} style={{ width: '70%' }}>Centre Name</StyledTableCell>
                                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '15%' }}>Edit</StyledTableCell>
                                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{ width: '15%' }}>Delete</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {previousCollectionTableRowData}
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid> */}

                    
                
            </>
          </Grid>
        </Grid>
      </div>


      <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>
    </>



  );
};

export default SuperAdminLoginLandingPageDesign;
