import React, { useEffect, useState, useRef } from "react";
import { Divider, Grid, makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import axios from "axios";
import CardContent from "@mui/material/CardContent";
import { CONFIG } from "../config";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { Info, KeyboardReturnOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import "../Css/Slider.css";
import Draggable from "react-draggable";
import useStyle from "../Css/MainCss";
import Delete from "@mui/icons-material/Delete";
import { Editor } from "@tinymce/tinymce-react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import { Alert } from "bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Checkbox } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Close } from "@mui/icons-material";
import Divider1 from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import parseHtml from "html-react-parser";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  root: {
    position: "relative",
  },
  cardRiskManage: {
    width: "100%",
    border: "1px solid black",
  },
  tableTrStyle: {
    height: "19px !important",
  },
  mousePointer: {
    cursor: "pointer",
  },
  stdMainCardHeader: {
    backgroundColor: "#4C7EE0 !important",
    fontSize: "13px !important",
    height: "50px",
    padding: "0px",
    marginTop: "-7px",
    color: "white",
  },
  addrisktypebtn: {
    marginLeft: "0px !important",
    border: "1px solid #4C7EE0 !important",
    color: "#4C7EE0 !important",
    padding: "5px 10px !important",
    height: "40px !important",
  },

  btnBorderpurple: {
    border: "1px solid #9155FD !important",
    color: "#9155FD !important",
    padding: "5px 10px !important",
    height: "40px !important",
  },
  stdCard: {
    height: "100%",
    border: "1px solid black",
  },
  scrollableDivToStandard: {
    height: "350px",
    overflow: "auto",
    width: "100%",
  },
  btnColorPurple: {
    fontSize: "12px !important",
    backgroundColor: "#BACDF2 !important",
    height: "40px",
    textAlign: "center",
    color: "#4C7EE0 !important",
    border: "1px solid #4C7EE0",
  },
  btnBorderGreen: {
    border: "1px solid #4C7EE0 !important",
    color: "#4C7EE0 !important",
    height: "40px",
  },
  superAdmintabBtnDBClickedBlog: {
    width: "153px !important",
    color: "#F5F5F5 !important",
    backgroundColor: "#0D4CC8 !important",
    border: "1px solid #69676e !important",
    height: "50px",
    textDecoration: "none",
  },
}));

const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 2.75rem;
  }
`;

const Pricing2 = ({ tinyMCETemplateList }) => {
  const classe = useStyle();
  const classes = useStyles();
  const Input = styled("input")({
    display: "none",
  });

  const editorRef = useRef(null);
  const editorRef2 = useRef(null);
  const [golobalCentreId, setGolobalCentreId] = React.useState("");

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    getData();
  }, []);

  function getData() {
    let data = sessionStorage.getItem("sessionstorage");
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId);
    getAllPricingData();
    getAllCentresFromServiceSystem();
  }

  const [centreName, setCentreName] = useState("");
  const [allCentres, setAllCentres] = React.useState([]);
  function getAllCentresFromServiceSystem() {
    var data = "{getAllCentreList{centreId,centreName}}";
    axios(CONFIG.MAIN_URL + "/graphql", {
      method: "post",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    })
      .then(function (response) {
        var centreList = response.data.data.getAllCentreList;
        console.log("All Centre List");
        console.log(centreList);
        setAllCentres(centreList);
      })
      .catch(function (error) {
        console.log("Entity Loding error" + error);
      });
  }

  const [pricingPageAllData, setPricingPageAllData] = useState({});

  const [pricingData, setPricingData] = useState([]);
  const [allPricingData, setAllPricingData] = useState([]);

  const [pricingPageHeader, setPricingPageHeader] = useState("");
  // console.log(selectedFile)
  const [saveBtnName, setSaveBtnName] = useState("Save");
  const [createheaderInfoMessage, setCreateHeaderInfoMessage] = useState(
    "Create / Edit Pricing packages"
  );
  const [packageName, setPackageName] = useState("");
  const [annualFee, setAnnualFee] = useState("");
  const [monthlyFee, setMonthlyFee] = useState("");
  const [pricingDescription, setPricingDescription] = useState("");
  const [headerContent, setHeaderContent] = useState("");
  const [editPricingDataId, setEditPricingDataId] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("$");

  const [selectedPackageName, setSelectedPackageName] = useState("");

  const [mainArrayId, setMainArrayId] = useState("");

  const [addUpdateButton, setAddUpdateButton] = useState("SAVE NEW");

  const [showEditButton, setShowEditButton] = useState(true);

  const [unChangedpricingData, setUnChangedpricingData] = useState([]);

  const [commonPricingFeaturesUnChange, setCommonPricingFeaturesUnChange] =
    useState([]);

  const [commonPricingFeaturesChange, setCommonPricingFeaturesChange] =
    useState([]);

  function getAllPricingData() {
    axios(CONFIG.MAIN_URL + `/pricingMethods/all`, {
      method: "get",
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then(function (response) {
        console.log(
          "+++++++++++++++++++++All package Returns+++++++++++++++++++"
        );
        console.log(response.data);
        console.log(response.data[0]);
        console.log(response.data[0].pricingMethods);
        setAllPricingData(response.data);

        /*
        setPricingData(response.data[0].pricingMethods);
        setUnChangedpricingData(response.data[0].pricingMethods)
        setCommonPricingFeaturesChange(response.data[0].commonPricingFeatures)
        setCommonPricingFeaturesUnChange(response.data[0].commonPricingFeatures)
        setPricingPageAllData(response.data[0])
        setMainArrayId(response.data[0].id)
        setHeaderContent(response.data[0].pricingPageHeader)
        editorRef.current.setContent(response.data[0].pricingPageHeader);
        */
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [showFnPButton, setShowFnPButton] = useState(false);
  const [showHideTiny, setShowHideTiny] = useState("none");
  const [allPricingDataSuperadmin, setAllPricingDataSuperadmin] = useState([]);

  const handleChangeCentre = (event) => {
    clearFuction();
    setCentreName(event.target.value);
    setShowFnPButton(false);
    allPricingData.forEach((data) => {
      if (data.centreId === event.target.value) {
        if (data.centreId === "superadmin") {
          var tArr = [];
          allPricingData.forEach((item) => {
            if (item.centreId === "superadmin") {
              tArr.push(item);
            }
          });
          console.log(tArr);
          setAllPricingDataSuperadmin(tArr);
          setDomainDiv("block");
          setShowHideTiny("none");
        } else {
          setShowHideTiny("block");
          setDomainDiv("none");
          setPricingData(data.pricingMethods);
          setUnChangedpricingData(data.pricingMethods);
          if (
            data.commonPricingFeatures != undefined &&
            data.commonPricingFeatures != null &&
            data.commonPricingFeatures.length > 0
          ) {
            var arr = data.commonPricingFeatures.sort(compareOrderNumber);
            setCommonPricingFeaturesUnChange(arr);
            setCommonPricingFeaturesChange(arr);
          }
          //setCommonPricingFeaturesChange(data.commonPricingFeatures)
          //setCommonPricingFeaturesUnChange(data.commonPricingFeatures)
          setPricingPageAllData(data);
          setMainArrayId(data.id);
          setHeaderContent(data.pricingPageHeader);
          editorRef.current.setContent(data.pricingPageHeader);
          setSaveBtnName("Update");
          setShowFnPButton(true);
        }
      }
    });
  };

  const [selectedDomainName, setSelectedDomainName] = useState("");
  const [domainDiv, setDomainDiv] = useState("none");

  const domainSelecter = (event, newValue) => {
    setSelectedDomainName(newValue);
    console.log(allPricingDataSuperadmin);
    allPricingDataSuperadmin.map((data) => {
      if (data.domainName === newValue) {
        setShowHideTiny("block");
        setDomainDiv("block");
        setPricingData(data.pricingMethods);
        setUnChangedpricingData(data.pricingMethods);
        if (
          data.commonPricingFeatures != undefined &&
          data.commonPricingFeatures != null &&
          data.commonPricingFeatures.length > 0
        ) {
          var arr = data.commonPricingFeatures.sort(compareOrderNumber);
          setCommonPricingFeaturesUnChange(arr);
          setCommonPricingFeaturesChange(arr);
        }
        setPricingPageAllData(data);
        setMainArrayId(data.id);
        setHeaderContent(data.pricingPageHeader);
        editorRef.current.setContent(data.pricingPageHeader);
        setSaveBtnName("Update");
        setShowFnPButton(true);
      }
    });
  };
  const domainSelecter1 = (event) => {
    setSelectedDomainName(event.target.value);
    var found = false;
    allPricingDataSuperadmin.map((data) => {
      if (data.domainName === event.target.value) {
        found = true;
        setShowHideTiny("block");
        setDomainDiv("block");
        setPricingData(data.pricingMethods);
        setUnChangedpricingData(data.pricingMethods);
        if (
          data.commonPricingFeatures != undefined &&
          data.commonPricingFeatures != null &&
          data.commonPricingFeatures.length > 0
        ) {
          var arr = data.commonPricingFeatures.sort(compareOrderNumber);
          setCommonPricingFeaturesUnChange(arr);
          setCommonPricingFeaturesChange(arr);
        }
        setPricingPageAllData(data);
        setMainArrayId(data.id);
        setHeaderContent(data.pricingPageHeader);
        editorRef.current.setContent(data.pricingPageHeader);
        setSaveBtnName("Update");
        setShowFnPButton(true);
      }
    });
    if (!found) {
      if (event.target.value != "") {
        var cName = centreName;
        clearFuction();
        setCentreName(cName);
        setSelectedDomainName(event.target.value);
        setShowHideTiny("block");
        setDomainDiv("block");
        setShowFnPButton(false);
      } else {
        setShowHideTiny("none");
        setDomainDiv("block");
      }
    }
  };

  const [createNewBtnP, setCreateNewBtnP] = useState("none");
  const [saveUpdateBtnP, setSaveUpdateBtnP] = useState("none");
  const [editBtnP, setEditBtnP] = useState("none");
  const [deleteBtnP, setDeleteBtnP] = useState("none");
  const [displayHidePackageUI, setDisplayHidePackageUI] = useState("none");

  function clearFuctionPackagesShowHide() {
    setCreateNewBtnP("none");
    setSaveUpdateBtnP("none");
    setEditBtnP("none");
    setDeleteBtnP("none");
    setDisplayHidePackageUI("none");
  }

  const packageSelecter = (event) => {
    clearFuctionPackages();
    setSelectedPackageName(event.target.value);
    var found = false;
    if (pricingData != null && pricingData.length > 0) {
      pricingData.map((data) => {
        if (data.method === event.target.value) {
          found = true;
          setSaveUpdateBtnP("none");
          setEditBtnP("block");
          setDeleteBtnP("block");
          setCreateNewBtnP("none");
        }
      });
    }
    if (!found) {
      setSaveUpdateBtnP("none");
      setEditBtnP("none");
      setDeleteBtnP("none");

      if (event.target.value != "") {
        setCreateNewBtnP("block");
      } else {
        setCreateNewBtnP("none");
      }
    }
    setSaveUpdateBtnP("none");
  };
  const packageSelecter1 = (event, newValue) => {
    clearFuctionPackages();
    if (newValue != null) {
      setSelectedPackageName(newValue);
      setSaveUpdateBtnP("none");
      setEditBtnP("block");
      setDeleteBtnP("block");
    }
    setCreateNewBtnP("none");
  };

  function createNewPackage() {
    setSaveUpdateBtnP("block");
    setDisplayHidePackageUI("block");
    setCreateNewBtnP("none");
  }

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const packageNameChange = (event) => {
    setPackageName(event.target.value);
  };

  const annualFeeChange = (event) => {
    if (event.target.value.indexOf(".") > 0) {
      setAnnualFee(event.target.value);
    } else {
      const value = event.target.value.replace(/\D/g, "");
      setAnnualFee(value);
    }
  };

  const monthlyFeeChange = (event) => {
    if (event.target.value.indexOf(".") > 0) {
      setMonthlyFee(event.target.value);
    } else {
      const value = event.target.value.replace(/\D/g, "");
      setMonthlyFee(value);
    }
  };

  function deletepricingData1() {
    const packageNameToSave = selectedPackageName;
    const updatedData = pricingData.filter(
      (item) => item.method !== packageNameToSave
    );
    console.log(updatedData);

    var obj = {};

    obj["id"] = mainArrayId;
    obj["centreId"] = centreName;
    if (selectedDomainName != "") {
      obj["domainName"] = selectedDomainName;
    }

    obj["pricingPageHeader"] = headerContent;

    obj["commonPricingFeatures"] = commonPricingFeaturesUnChange;

    obj["pricingMethods"] = updatedData;

    var sendJson = JSON.stringify(obj);
    console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
    console.log(sendJson);

    const userConfirmed = window.confirm(
      "Do you want to delete ",
      packageNameToSave
    );

    if (userConfirmed) {
      axios(CONFIG.MAIN_URL + `/pricingMethods/update`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: sendJson,
      })
        .then((res) => {
          alert("Successfully deleted");
          //clearFuction();
          getAllPricingData();
          clearFuctionPackages();
        })
        .catch(function (error) {
          alert("Error Deleting");
          console.log(error);
        });
    } else {
    }
    setPricingData(updatedData);
    clearFuctionPackages();
  }

  function savePricingFunction() {
    console.log(mainArrayId);
    if (centreName === "") {
      alert("Please Select a Centre");
      return;
    }
    var desc = "";
    if (editorRef.current) {
      desc = editorRef.current.getContent();
    }
    if (desc === "") {
      alert("Please Header Content");
      return;
    }

    var obj = {};
    if (mainArrayId != "") {
      obj["id"] = mainArrayId;
    }
    obj["centreId"] = centreName;
    if (selectedDomainName != "") {
      obj["domainName"] = selectedDomainName;
    }
    obj["pricingPageHeader"] = desc;

    obj["pricingMethods"] = unChangedpricingData;

    obj["commonPricingFeatures"] = commonPricingFeaturesUnChange;

    var sendJson = JSON.stringify(obj);
    console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
    console.log(sendJson);
    var endPoint = "";
    if (mainArrayId != "") {
      endPoint = "/pricingMethods/update";
    } else {
      endPoint = "/pricingMethods/save";
    }

    axios(CONFIG.MAIN_URL + endPoint, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: sendJson,
    })
      .then((res) => {
        if (mainArrayId != "") {
          alert("Successfully Updated Header Content");
        } else {
          alert("Successfully Saved Header Content");
        }
        clearFuction();
        getAllPricingData();
      })
      .catch(function (error) {
        alert("Error Updating the Pricing");
        console.log(error);
      });
  }

  function clearFuction() {
    setSaveBtnName("Save");
    setSelectedDomainName("");
    setDomainDiv("none");
    setPricingData([]);
    setUnChangedpricingData([]);
    setCommonPricingFeaturesChange([]);
    setCommonPricingFeaturesUnChange([]);
    setPricingPageAllData({});
    setMainArrayId("");
    setHeaderContent("");
    editorRef.current.setContent("");
    setCentreName("");
  }

  const [pricingFeatures, setPricingFeatures] = useState([]);

  function packageEdit() {
    pricingData.map((data) => {
      if (data.method === selectedPackageName) {
        console.log(data);
        setAddUpdateButton("update");
        setEditPricingDataId(data.id);
        setSelectedPackageName(data.method);
        setPackageName(data.method);
        setAnnualFee(data.anualFee);
        setMonthlyFee(data.monthlyFee);
        setPricingDescription(data.pricingDetails);
        setSelectedCurrency(data.currency);

        editorRef2.current.setContent(data.pricingDetails);


        setSaveBtnName("Update");
        setPricingFeatures(data.pricingFeatures);
        setOrderNumberPackage(data.orderNumber);

        setCreateHeaderInfoMessage("Edit/Create Pricing Packages ");

        setCreateNewBtnP("none");
        setSaveUpdateBtnP("block");
        setEditBtnP("none");
        setDeleteBtnP("block");
        setDisplayHidePackageUI("block");
      }
    });
  }

  function clearFuctionPackages() {
    clearFuctionPackagesShowHide();
    setAnnualFee("");
    setMonthlyFee("");
    setPricingDescription("");
    //setHeaderContent('')
    setSelectedCurrency("$");
    if (editorRef2 != null && editorRef2.current != null) {
      editorRef2.current.setContent("");
    }
    setSelectedPackageName("");
    setAddUpdateButton("SAVE NEW");
    setInputText("");
    setPricingFeatures([]);
    setOrderNumberPackage("");

    // commonPricingFeaturesUnChange.forEach((data)=> {

    //   if (data.type === 'textField') {
    //     document.getElementById('txt'+data.id).value = '';
    //     alert(document.getElementById('txt'+data.id).value)
    //   }
    //   if (data.type === 'checkBox') {
    //     document.getElementById('chk'+data.id).checked = false;
    //     alert(document.getElementById('chk'+data.id).checked)

    //   }
    // })
  }

  function addpackages() {
    var prev = true;

    const packageNameToSave = selectedPackageName;
    pricingData.map((data) => {
      if (data.method === packageNameToSave) {
        //alert('Package already exists:');
        prev = false;
        return;
      }
    });

    if (prev) {
      if (mainArrayId === "") {
        alert(
          "please save the centre header and select the center for updating pricing packages"
        );
        return;
      }
      if (monthlyFee === "") {
        alert(" Monthly fee can not be empty");
        return;
      }
      if (annualFee === "") {
        alert("Annualy fee can not be empty");
        return;
      }
      if (orderNumberPackage === "") {
        alert("Package Display preference can not be empty");
        return;
      }

      var desc = "";
      if (editorRef2.current) {
        desc = editorRef2.current.getContent();
      }

      var obj = {};
      obj["method"] = selectedPackageName;
      obj["currency"] = selectedCurrency;
      obj["monthlyFee"] = monthlyFee;
      obj["anualFee"] = annualFee;
      obj["orderNumber"] = parseInt(orderNumberPackage);

      obj["pricingDetails"] = desc;

      var updatedData = [];
      commonPricingFeaturesUnChange.forEach((data) => {
        var tObj = {};
        if (data.type === "textField") {
          if (document.getElementById("txt" + data.id).value != "") {
            tObj["id"] = data.id;
            tObj["name"] = data.name;
            tObj["type"] = data.type;
            tObj["value"] = document.getElementById("txt" + data.id).value;
            tObj["orderNumber"] = data.orderNumber;
            updatedData.push(tObj);
          }
        }
        if (data.type === "checkBox") {
          if (document.getElementById("chk" + data.id).checked) {
            tObj["id"] = data.id;
            tObj["name"] = data.name;
            tObj["type"] = data.type;
            tObj["value"] = "yes";
            tObj["orderNumber"] = data.orderNumber;
            updatedData.push(tObj);
          }
        }
      });

      console.log(updatedData);

      obj["pricingFeatures"] = updatedData;

      pricingData.push(obj);

      var obj = {};

      obj["id"] = mainArrayId;
      obj["centreId"] = centreName;
      if (selectedDomainName != "") {
        obj["domainName"] = selectedDomainName;
      }

      obj["pricingPageHeader"] = headerContent;

      obj["commonPricingFeatures"] = commonPricingFeaturesUnChange;

      obj["pricingMethods"] = pricingData;

      var sendJson = JSON.stringify(obj);
      console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
      console.log(sendJson);
      var endPoint = "/pricingMethods/save";
      if (editPricingDataId != "") {
        endPoint = "/pricingMethods/update";
      }

      axios(CONFIG.MAIN_URL + `/pricingMethods/update`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: sendJson,
      })
        .then((res) => {
          alert("Successfully Saved Package Details");
          //clearFuction();
          getAllPricingData();
          clearFuctionPackages();
          //clearFuction();
        })
        .catch(function (error) {
          alert("Error Updating the Pricing");
          console.log(error);
        });

      console.log(pricingData);
    } else {
      if (monthlyFee === "") {
        alert(" Monthly fee can not be empty");
        return;
      }
      if (annualFee === "") {
        alert("Annualy fee can not be empty");
        return;
      }
      if (orderNumberPackage === "") {
        alert("Package Display preference can not be empty");
        return;
      }

      var updatedData = pricingData.filter(
        (item) => item.method !== packageNameToSave
      );
      pricingData.map((data) => {
        if (data.method === packageNameToSave) {
          var desc = "";
          if (editorRef2.current) {
            desc = editorRef2.current.getContent();
          }

          var obj = {};
          obj["id"] = editPricingDataId;
          obj["method"] = packageNameToSave;
          obj["currency"] = selectedCurrency;
          obj["monthlyFee"] = monthlyFee;
          obj["anualFee"] = annualFee;
          obj["orderNumber"] = parseInt(orderNumberPackage);
          obj["pricingDetails"] = desc;

          var updatedDataaa = [];
          commonPricingFeaturesUnChange.forEach((data) => {
            var tObj = {};
            if (data.type === "textField") {
              if (document.getElementById("txt" + data.id).value != "") {
                tObj["id"] = data.id;
                tObj["name"] = data.name;
                tObj["type"] = data.type;
                tObj["value"] = document.getElementById("txt" + data.id).value;
                tObj["orderNumber"] = data.orderNumber;
                updatedDataaa.push(tObj);
              }
            }
            if (data.type === "checkBox") {
              if (document.getElementById("chk" + data.id).checked) {
                tObj["id"] = data.id;
                tObj["name"] = data.name;
                tObj["type"] = data.type;
                tObj["value"] = "yes";
                tObj["orderNumber"] = data.orderNumber;
                updatedDataaa.push(tObj);
              }
            }
          });

          obj["pricingFeatures"] = updatedDataaa;

          updatedData.push(obj);
        }
      });
      console.log(updatedData);
      setPricingData(updatedData);

      var obj = {};

      obj["id"] = mainArrayId;
      obj["centreId"] = centreName;
      if (selectedDomainName != "") {
        obj["domainName"] = selectedDomainName;
      }

      obj["pricingPageHeader"] = headerContent;

      obj["commonPricingFeatures"] = commonPricingFeaturesUnChange;

      obj["pricingMethods"] = updatedData;

      var sendJson = JSON.stringify(obj);
      console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
      console.log(sendJson);

      axios(CONFIG.MAIN_URL + `/pricingMethods/update`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: sendJson,
      })
        .then((res) => {
          alert("Successfully Updated Pricing Details");
          //clearFuction();
          getAllPricingData();
          clearFuctionPackages();
        })
        .catch(function (error) {
          alert("Error Updating the Pricing");
          console.log(error);
        });
    }
  }

  const [inputText, setInputText] = useState("");
  const [items, setItems] = useState([]);
  const [editIndex, setEditIndex] = useState("");
  const [inputType, setInputType] = useState("");
  const [orderNumberFeature, setOrderNumberFeature] = useState("");
  const [orderNumberPackage, setOrderNumberPackage] = useState("");

  const [inputTextForSave, setInputTextForSave] = useState([]);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleChangeOrderNumberFeature = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setOrderNumberFeature(value);
  };

  const handleChangeOrderNumberPackage = (e) => {
    setOrderNumberPackage(e.target.value);
  };

  const handleInputChangeForSave = (item, newValue) => {
    // Update the value of the corresponding item in the state
    // setCommonPricingFeaturesUnChange((prevItems) => {
    //   return prevItems.map((prevItem) =>
    //     prevItem.id === item.id ? { ...prevItem, value: newValue } : prevItem
    //   );
    // });
  };

  function compareOrderNumber(a, b) {
    var nameA = a.orderNumber; // ignore upper and lowercase
    var nameB = b.orderNumber; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const handleAddClick = () => {
    if (mainArrayId === "") {
      alert(
        "please save the centre header and select the center for updating centre common featuers"
      );
      return;
    }
    if (inputText === "") {
      alert("please enter common feature name");
      return;
    }

    if (inputType === "") {
      alert("please enter common feature type");
      return;
    }
    if (orderNumberFeature === "") {
      alert("please enter Oreder Number");
      return;
    }

    if (editIndex === "") {
      var obj = {};

      var arr = [];

      obj["id"] = mainArrayId;
      obj["centreId"] = centreName;
      if (selectedDomainName != "") {
        obj["domainName"] = selectedDomainName;
      }

      obj["pricingPageHeader"] = headerContent;

      var obj1 = {};
      if (editIndex != "") {
        obj1["id"] = editIndex;
      }
      obj1["name"] = inputText;
      obj1["type"] = inputType;
      obj1["value"] = "";
      obj1["orderNumber"] = parseInt(orderNumberFeature);

      commonPricingFeaturesChange.push(obj1);

      obj["commonPricingFeatures"] = commonPricingFeaturesChange;

      obj["pricingMethods"] = pricingData;

      console.log(obj);
      var sendJson = JSON.stringify(obj);
      console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
      console.log(sendJson);

      axios(CONFIG.MAIN_URL + `/pricingMethods/update`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: sendJson,
      })
        .then((res) => {
          alert("Successfully saved Common Features");
          var arr = [];
          commonPricingFeaturesChange.map((dataX) => {
            arr.push(dataX);
          });
          arr = arr.sort(compareOrderNumber);
          setCommonPricingFeaturesUnChange(arr);
          setCommonPricingFeaturesChange(arr);

          getAllPricingData();
          setInputText("");
          setInputType("");
          setEditIndex("");
          setOrderNumberFeature("");
        })
        .catch(function (error) {
          alert("Error Updating the feature");
          console.log(error);
        });
    } else {
      var obj = {};

      var arr = [];

      obj["id"] = mainArrayId;
      obj["centreId"] = centreName;
      if (selectedDomainName != "") {
        obj["domainName"] = selectedDomainName;
      }

      obj["pricingPageHeader"] = headerContent;

      const newArray = commonPricingFeaturesChange.filter(
        (item) => item.id !== editIndex
      );

      var obj1 = {};
      obj1["id"] = editIndex;

      obj1["name"] = inputText;
      obj1["type"] = inputType;
      obj1["value"] = "";
      obj1["orderNumber"] = parseInt(orderNumberFeature);

      newArray.push(obj1);

      obj["commonPricingFeatures"] = newArray;

      obj["pricingMethods"] = pricingData;

      console.log(obj);
      var sendJson = JSON.stringify(obj);
      console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
      console.log(sendJson);

      axios(CONFIG.MAIN_URL + `/pricingMethods/update`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: sendJson,
      })
        .then((res) => {
          alert("Successfully updated Common Features");
          var arr = [];
          newArray.map((dataX) => {
            arr.push(dataX);
          });
          arr = arr.sort(compareOrderNumber);
          setCommonPricingFeaturesUnChange(arr);
          setCommonPricingFeaturesChange(arr);
          getAllPricingData();
          setInputText("");
          setInputType("");
          setEditIndex("");
          setOrderNumberFeature("");
        })
        .catch(function (error) {
          alert("Error Updating features");
          console.log(error);
        });
    }
  };

  const handleEditClick = (id) => {
    setEditIndex(id);
    commonPricingFeaturesChange.map((comm) => {
      if (comm.id === id) {
        setInputText(comm.name);
        setInputType(comm.type);
        setOrderNumberFeature(comm.orderNumber);
      }
    });
  };

  const handleDeleteClick = (id) => {
    const updatedData = commonPricingFeaturesUnChange.filter(
      (item) => item.id !== id
    );
    console.log(updatedData);
    if (mainArrayId === "") {
      alert("please select a center before deleting");
    }

    var obj = {};

    obj["id"] = mainArrayId;
    obj["centreId"] = centreName;
    if (selectedDomainName != "") {
      obj["domainName"] = selectedDomainName;
    }

    obj["pricingPageHeader"] = headerContent;

    obj["commonPricingFeatures"] = updatedData;

    obj["pricingMethods"] = unChangedpricingData;

    var sendJson = JSON.stringify(obj);
    console.log("+++++++++++++++++++sendJson To Save++++++++++++++++++++");
    console.log(sendJson);

    const userConfirmed = window.confirm("Do you want to delete ");

    if (userConfirmed) {
      axios(CONFIG.MAIN_URL + `/pricingMethods/update`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: sendJson,
      })
        .then((res) => {
          alert("Successfully deleted");
          //clearFuction();
          getAllPricingData();
          var arr = [];
          updatedData.map((dataX) => {
            arr.push(dataX);
          });
          arr = arr.sort(compareOrderNumber);
          setCommonPricingFeaturesUnChange(arr);
          setCommonPricingFeaturesChange(arr);
          clearFuctionPackages();
        })
        .catch(function (error) {
          alert("Error Deleting");
          console.log(error);
        });
    } else {
    }
    setPricingData(updatedData);
    clearFuctionPackages();
  };

  const handleCheckboxChange = (item, checkBoxCheck) => {
    if (checkBoxCheck) {
      document.getElementById("chk" + item.id).checked = true;
    } else {
      document.getElementById("chk" + item.id).checked = false;
    }

    // setCommonPricingFeaturesUnChange((prevItems) => {
    //   return prevItems.map((prevItem) =>
    //     prevItem.id === item.id ? { ...prevItem, checked: !prevItem.checked } : prevItem
    //   );
    // });
  };

  function PackageFeatues({ item }) {
    var textfieldVal = "";
    var checkBoxCheck = false;
    if (pricingFeatures != null && pricingFeatures.length > 0) {
      if (item.type === "textField") {
        pricingFeatures.forEach((fea) => {
          if (fea.id === item.id) {
            textfieldVal = fea.value;
          }
        });
      }
      if (item.type === "checkBox") {
        pricingFeatures.forEach((fea) => {
          if (fea.id === item.id && fea.value === "yes") {
            checkBoxCheck = true;
          }
        });
      }
    }

    return (
      <StyledTableCell
        component="th"
        scope="row"
        className={classes.tableTrStyle}
      >
        {item.type === "textField" ? (
          <>
            <TextField
              type="text"
              id={"txt" + item.id}
              defaultValue={textfieldVal}
              onChange={(e) => handleInputChangeForSave(item, e.target.value)}
              label="Text or numbers allowed"
              variant="outlined"
              fullWidth
            />
          </>
        ) : (
          <>
            {checkBoxCheck ? (
              <Checkbox
                id={"chk" + item.id}
                defaultChecked
                onChange={() => handleCheckboxChange(item, !checkBoxCheck)}
              />
            ) : (
              <Checkbox
                id={"chk" + item.id}
                onChange={() => handleCheckboxChange(item, !checkBoxCheck)}
              />
            )}
          </>
        )}
      </StyledTableCell>
    );
  }

  const previousTableRowData = (
    <TableBody>
      {commonPricingFeaturesUnChange.map((item) => {
        return (
          <StyledTableRow key={item.id} className={classes.tableTrStyle}>
            <StyledTableCell
              component="th"
              scope="row"
              className={classes.tableTrStyle}
            >
              <div style={{ whiteSpace: "normal" }}>{item.name}</div>
            </StyledTableCell>

            <PackageFeatues item={item} />
            {/* 
              <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                {item.type === "textField" ? 
                <>
                  <TextField
                    type="text"
                    id={item.id}
                   // value={item.value || ''}
                    onChange={(e) => handleInputChangeForSave(item, e.target.value)}
                    label="Type something..."
                    variant="outlined"
                    fullWidth
                  />
                </>:
                <>
                  <Checkbox
                    checked={item.checked}
                    onChange={() => handleCheckboxChange(item)}
                  />
                </>
                
                }
              
              </StyledTableCell> */}
          </StyledTableRow>
        );
      })}
    </TableBody>
  );

  const [openFeatureDialog, setOpenFeatureDialog] = React.useState(false);
  const [openFeatureDialogScroll, setOpenFeatureDialogScroll] =
    React.useState("paper");
  const [maxWidthState, setMaxWidthState] = React.useState("lg");

  const [openPackagesDialog, setOpenPackagesDialog] = React.useState(false);
  const [openPackageseDialogScroll, setOpenPackagesDialogScroll] =
    React.useState("paper");

  function openFeatureDialogClose() {
    setOpenFeatureDialog(false);
  }

  function openFeatureDialogOpen() {
    setOpenFeatureDialog(true);
  }

  function openPackagesDialogClose() {
    setOpenPackagesDialog(false);
  }

  function openPackagesDialogOpen() {
    setOpenPackagesDialog(true);
  }

  const [openButton1, setOpenButton1] = React.useState(true);
  const [openButton2, setOpenButton2] = React.useState(false);
  const [openButton3, setOpenButton3] = React.useState(false);

  function setOpenButton1Function(flag) {
    setOpenButton1(true);
    setOpenButton2(false);
    setOpenButton3(false);
  }
  function setOpenButton2Function(flag) {
    setOpenButton2(true);
    setOpenButton1(false);
    setOpenButton3(false);
  }

  function setOpenButton3Function(flag) {
    setOpenButton2(false);
    setOpenButton1(false);
    setOpenButton3(true);
  }

  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState("");

  function handleCloseClick() {
    setOpenImage(false);
  }

  function imagePreviw(url) {
    var str = '<img src="' + url + '" />';
    setImageHtmlData(str);
    setOpenImage(true);
  }

  const [openButtonPacFeature, setOpenButtonPacFeature] = React.useState("block");
  const [openButtonPacContent, setOpenButtonPacContent] = React.useState("none");

  function setOpenButtonPacFeatureFunction(flag) {
    setOpenButtonPacFeature("block");
    setOpenButtonPacContent("none");
  }
  function setOpenButtonPacContentFunction(flag) {
    setOpenButtonPacContent("block");
    setOpenButtonPacFeature("none");
  }

  return (
    <>
      <div>
        <Grid>
          <Grid container spacing={2}>
            <>
              <Grid item xs={12}>
                <Card className={classes.cardRiskManage}>
                  <CardContent>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "#69676e",
                              width: "25px",
                              height: "25px",
                            }}
                          >
                            <Info />
                          </Avatar>
                        }
                        className={classe.commonMainTableHead}
                        title={"Page Content"}
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Box
                              component="form"
                              sx={{
                                "& .MuiTextField-root": {
                                  mt: 1,
                                  width: "100%",
                                },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              {" "}
                              <FormControl fullWidth>
                                <TextField
                                  label="Select a Centre"
                                  sx={{ width: "100%" }}
                                  select
                                  value={centreName}
                                  onChange={handleChangeCentre}
                                >
                                  <MenuItem value={"superadmin"}>
                                    {"For Login Page Pricing"}
                                  </MenuItem>
                                  {allCentres.map((item) => (
                                    <MenuItem value={item.centreId}>
                                      {item.centreName}
                                      {" ("}
                                      {item.centreId}
                                      {")"}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            style={{
                              display: `${domainDiv}`,
                              marginTop: "8px",
                            }}
                          >
                            <AutoComplete
                              id="package-name"
                              options={allPricingDataSuperadmin.map(
                                (option) => option.domainName
                              )}
                              value={selectedDomainName}
                              style={{ height: "50px" }}
                              onChange={(event, newValue) => {
                                domainSelecter(event, newValue);
                              }}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Type a new domain name OR select"
                                  variant="outlined"
                                  size="small"
                                  style={{ width: "100%", height: "37px" }}
                                  onChange={(event) => domainSelecter1(event)}
                                />
                              )}
                            />
                          </Grid>

                          <Card
                            className={classes.cardRiskManage}
                            style={{
                              display: `${showHideTiny}`,
                              marginTop: "20px",
                            }}
                          >
                            <CardContent>
                              <div style={{ display: "flex", gap: "10px" }}>
                                <Button
                                  id="btnOpenRiskCollection"
                                  variant="outlined"
                                  size="medium"
                                  className={
                                    openButton1
                                      ? classes.superAdmintabBtnDBClickedBlog
                                      : classe.tabBtnDB
                                  }
                                  onClick={() => setOpenButton1Function(true)}
                                >
                                  Page Main Content
                                </Button>
                                {showFnPButton && (
                                  <>
                                    <Button
                                      id="btnOpenRiskCollection"
                                      variant="outlined"
                                      size="medium"
                                      className={
                                        openButton2
                                          ? classes.superAdmintabBtnDBClickedBlog
                                          : classe.tabBtnDB
                                      }
                                      onClick={() =>
                                        setOpenButton2Function(true)
                                      }
                                    >
                                      Create / Edit Pricing Features
                                    </Button>

                                    <Button
                                      id="btnOpenRiskCollection"
                                      variant="outlined"
                                      size="medium"
                                      className={
                                        openButton3
                                          ? classes.superAdmintabBtnDBClickedBlog
                                          : classe.tabBtnDB
                                      }
                                      onClick={() =>
                                        setOpenButton3Function(true)
                                      }
                                    >
                                      Create / Edit Pricing packages
                                    </Button>
                                  </>
                                )}
                              </div>
                              <br></br>

                              {openButton1 ? (
                                <>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ display: `${showHideTiny}` }}
                                    >
                                      <Editor
                                        apiKey="ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj"
                                        tinymceScriptSrc={
                                          "https://cdn.tiny.cloud/1/ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj/tinymce/6/tinymce.min.js"
                                        }
                                        onInit={(evt, editor) =>
                                          (editorRef.current = editor)
                                        }
                                        initialValue={headerContent}
                                        init={{
                                          referrer_policy: "origin",
                                          height: 300,
                                          menubar: true,
                                          placeholder: "Page Header Content",
                                          plugins:
                                            "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media mediaembed template codesample table advtable charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help charmap quickbars emoticons casechange formatpainter pageembed hr fullpage advcode export permanentpen powerpaste",
                                          imagetools_cors_hosts: [
                                            "picsum.photos",
                                          ],
                                          menubar:
                                            "file edit view insert format tools table help",
                                          toolbar:
                                            "undo redo | bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | casechange forecolor backcolor formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview print | pageembed insertfile image media template tokens link anchor codesample | ltr rtl hr fullpage export permanentpen configurepermanentpen",
                                          //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                                          export_image_proxy: "proxy.php",
                                          toolbar_sticky: true,
                                          autosave_ask_before_unload: true,
                                          autosave_interval: "30s",
                                          autosave_prefix:
                                            "{path}{query}-{id}-",
                                          autosave_restore_when_empty: false,
                                          autosave_retention: "2m",
                                          setup: (editor) => {
                                            editor.on("init", function (e) {
                                              var content =
                                                unescape(headerContent);
                                              editor.setContent(content);
                                              editor.dom.bind(
                                                editor.getBody(),
                                                "click",
                                                function (e) {
                                                  var target = e.target;

                                                  // Check if the clicked element is an image
                                                  if (
                                                    target.nodeName.toLowerCase() ===
                                                    "img"
                                                  ) {
                                                    // Your onclick function logic here
                                                    imagePreviw(target.src); // Replace this with your actual onclick function
                                                  }
                                                }
                                              );
                                            });
                                          },

                                          image_title: true,
                                          /* enable automatic uploads of images represented by blob or data URIs*/
                                          automatic_uploads: true,
                                          /*Here we add custom filepicker only to Image dialog*/
                                          file_picker_types: "image",

                                          /* and here's our custom image picker*/
                                          file_picker_callback: function (
                                            cb,
                                            value,
                                            meta
                                          ) {
                                            var input =
                                              document.createElement("input");
                                            input.setAttribute("type", "file");
                                            input.setAttribute(
                                              "accept",
                                              "image/*"
                                            );

                                            input.onchange = function () {
                                              var file = this.files[0];
                                              var reader = new FileReader();

                                              reader.onload = function () {
                                                /*
                                      Note: Now we need to register the blob in TinyMCEs image blob
                                      registry. In the next release this part hopefully won't be
                                      necessary, as we are looking to handle it internally.
                                      */
                                                var id =
                                                  "blobid" +
                                                  new Date().getTime();

                                                var blobCache =
                                                  editorRef.current.editorUpload
                                                    .blobCache;
                                                //var blobCache = editorRef.current.activeEditor.editorUpload.blobCache;
                                                var base64 =
                                                  reader.result.split(",")[1];

                                                var blobInfo = blobCache.create(
                                                  id,
                                                  file,
                                                  base64
                                                );
                                                blobCache.add(blobInfo);

                                                /* call the callback and populate the Title field with the file name */
                                                cb(blobInfo.blobUri(), {
                                                  title: file.name,
                                                });
                                              };

                                              reader.readAsDataURL(file);
                                            };

                                            input.click();
                                          },

                                          images_upload_handler: function (
                                            blobInfo,
                                            success,
                                            failure
                                          ) {},

                                          templates: tinyMCETemplateList,

                                          referrer_policy: "origin",

                                          template_cdate_format:
                                            "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
                                          template_mdate_format:
                                            "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
                                          image_caption: true,
                                          quickbars_selection_toolbar:
                                            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                                          noneditable_noneditable_class:
                                            "mceNonEditable",
                                          toolbar_mode: "sliding",
                                          contextmenu:
                                            "link image imagetools table",
                                          //content_css: useDarkMode ? 'dark' : 'default',
                                          content_style:
                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        style={{
                                          marginTop: "10px",
                                          height: "35px",
                                          float: "right",
                                          width: "70px",
                                        }}
                                        onClick={() => {
                                          savePricingFunction();
                                        }}
                                      >
                                        {saveBtnName}
                                      </Button>
                                      &nbsp;&nbsp;
                                    </Grid>
                                  </Grid>
                                </>
                              ) : (
                                ""
                              )}

                              {openButton2 ? (
                                <>
                                  <Card>
                                    <CardHeader
                                      avatar={
                                        <Avatar
                                          sx={{
                                            bgcolor: "#69676e",
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        >
                                          <Info />
                                        </Avatar>
                                      }
                                      title="Pricing Features"
                                      className={classe.commonMainTableHead}
                                    />
                                    <CardContent>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                          <div>
                                            <Box
                                              style={{
                                                display: "flex",
                                                gap: "10px",
                                              }}
                                            >
                                              <Grid item xs={3}>
                                                <TextField
                                                  type="text"
                                                  value={inputText}
                                                  onChange={handleInputChange}
                                                  label="Feature..."
                                                  variant="outlined"
                                                  fullWidth
                                                />
                                              </Grid>
                                              <Grid item xs={3}>
                                                <TextField
                                                  label="Select Feature Type"
                                                  sx={{ width: "100%" }}
                                                  select
                                                  value={inputType}
                                                  onChange={(e) =>
                                                    setInputType(e.target.value)
                                                  }
                                                >
                                                  <MenuItem value="textField">
                                                    TextField
                                                  </MenuItem>
                                                  <MenuItem value="checkBox">
                                                    checkBox
                                                  </MenuItem>
                                                </TextField>
                                              </Grid>
                                              <Grid item xs={2}>
                                                <TextField
                                                  type="text"
                                                  value={orderNumberFeature}
                                                  onChange={
                                                    handleChangeOrderNumberFeature
                                                  }
                                                  label="Display preference"
                                                  variant="outlined"
                                                  fullWidth
                                                  style={{ width: "150px" }}
                                                />
                                              </Grid>
                                              <Grid item xs={1}>
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={handleAddClick}
                                                  sx={{ marginTop: 1 }}
                                                >
                                                  {editIndex !== ""
                                                    ? "Update"
                                                    : "save"}
                                                </Button>
                                              </Grid>
                                            </Box>
                                            <div>
                                              <br></br>
                                              <Divider1 textAlign="left">
                                                <Chip label="Features Created" />
                                              </Divider1>
                                              <br></br>
                                              <Box
                                                border={1}
                                                borderColor="divider"
                                              >
                                                <TableContainer
                                                  component={Paper}
                                                >
                                                  <Table aria-label="customized table">
                                                    <TableHead
                                                      className={
                                                        classe.commonSecondaryTableHead
                                                      }
                                                    >
                                                      <TableRow>
                                                        <StyledTableCell
                                                          className={
                                                            classe.commonTableThStyle
                                                          }
                                                          style={{
                                                            width: "55%",
                                                          }}
                                                        >
                                                          Feature
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                          align="left"
                                                          className={
                                                            classe.commonTableThStyle
                                                          }
                                                          style={{
                                                            width: "15%",
                                                          }}
                                                        >
                                                          Type
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                          align="right"
                                                          className={
                                                            classe.commonTableThStyle
                                                          }
                                                          style={{
                                                            width: "15%",
                                                          }}
                                                        >
                                                          Display preference
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                          align="right"
                                                          className={
                                                            classe.commonTableThStyle
                                                          }
                                                          style={{
                                                            width: "15%",
                                                          }}
                                                        >
                                                          Edit
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                          align="right"
                                                          className={
                                                            classe.commonTableThStyle
                                                          }
                                                          style={{
                                                            width: "15%",
                                                          }}
                                                        >
                                                          Delete
                                                        </StyledTableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {commonPricingFeaturesUnChange.map(
                                                        (item, index) => (
                                                          <StyledTableRow
                                                            key={item.id}
                                                            className={
                                                              classes.tableTrStyle
                                                            }
                                                          >
                                                            <StyledTableCell
                                                              component="th"
                                                              scope="row"
                                                              className={
                                                                classes.tableTrStyle
                                                              }
                                                            >
                                                              <div
                                                                style={{
                                                                  whiteSpace:
                                                                    "normal",
                                                                }}
                                                              >
                                                                {item.name}
                                                              </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                              component="th"
                                                              scope="row"
                                                              className={
                                                                classes.tableTrStyle
                                                              }
                                                            >
                                                              <div
                                                                style={{
                                                                  whiteSpace:
                                                                    "normal",
                                                                }}
                                                              >
                                                                {item.type}
                                                              </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                              component="th"
                                                              scope="row"
                                                              align="right"
                                                              className={
                                                                classes.tableTrStyle
                                                              }
                                                            >
                                                              <div
                                                                style={{
                                                                  whiteSpace:
                                                                    "normal",
                                                                }}
                                                              >
                                                                {
                                                                  item.orderNumber
                                                                }
                                                              </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                              align="right"
                                                              className={
                                                                classes.tableTrStyle
                                                              }
                                                            >
                                                              <EditIcon
                                                                className={
                                                                  classes.mousePointer
                                                                }
                                                                onClick={() => {
                                                                  handleEditClick(
                                                                    item.id
                                                                  );
                                                                }}
                                                              />
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                              align="right"
                                                              className={
                                                                classes.tableTrStyle
                                                              }
                                                            >
                                                              <DeleteIcon
                                                                className={
                                                                  classes.mousePointer
                                                                }
                                                                onClick={() => {
                                                                  handleDeleteClick(
                                                                    item.id
                                                                  );
                                                                }}
                                                              />
                                                            </StyledTableCell>
                                                          </StyledTableRow>
                                                        )
                                                      )}
                                                    </TableBody>
                                                  </Table>
                                                </TableContainer>
                                              </Box>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </>
                              ) : (
                                ""
                              )}

                              {openButton3 ? (
                                <>
                                  <Card>
                                    <CardHeader
                                      avatar={
                                        <Avatar
                                          sx={{
                                            bgcolor: "#69676e",
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        >
                                          <Info />
                                        </Avatar>
                                      }
                                      className={classe.commonMainTableHead}
                                      title={createheaderInfoMessage}
                                    />
                                    <CardContent>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Grid item xs={3}>
                                              <Typography
                                                style={{
                                                  marginRight: "8px",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Package Name:
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                                style={{ gap: "5px" }}
                                              >
                                                {/* <TextField
                                                    id="trailDays"
                                                    label="Ex: Premium"
                                                    variant="outlined"
                                                    size="small"
                                                    value={packageName}
                                                    onChange={packageNameChange}
                                                    style={{width:'400px'}}

                                                    /> */}

                                                <Autocomplete
                                                  id="package-name"
                                                  options={pricingData.map(
                                                    (option) => option.method
                                                  )}
                                                  value={selectedPackageName}
                                                  onChange={(
                                                    event,
                                                    newValue
                                                  ) => {
                                                    packageSelecter1(
                                                      event,
                                                      newValue
                                                    );
                                                  }}
                                                  freeSolo
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      label="Select Or Type a new Package name (Ex: Premium)"
                                                      variant="outlined"
                                                      size="small"
                                                      style={{ width: "400px" }}
                                                      onChange={(event) =>
                                                        packageSelecter(event)
                                                      }
                                                    />
                                                  )}
                                                />

                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  style={{
                                                    height: "36px",
                                                    display: `${createNewBtnP}`,
                                                  }}
                                                  onClick={() => {
                                                    createNewPackage();
                                                  }}
                                                >
                                                  Create New Package
                                                </Button>
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  style={{
                                                    height: "36px",
                                                    display: `${saveUpdateBtnP}`,
                                                  }}
                                                  onClick={() => {
                                                    addpackages();
                                                  }}
                                                >
                                                  {addUpdateButton}
                                                </Button>
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  style={{
                                                    height: "36px",
                                                    display: `${editBtnP}`,
                                                  }}
                                                  onClick={() => {
                                                    packageEdit();
                                                  }}
                                                >
                                                  EDIT
                                                </Button>

                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  style={{
                                                    height: "36px",
                                                    display: `${deleteBtnP}`,
                                                  }}
                                                  onClick={() => {
                                                    deletepricingData1();
                                                  }}
                                                >
                                                  DELETE
                                                </Button>

                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  style={{ height: "36px" }}
                                                  onClick={() => {
                                                    clearFuctionPackages();
                                                  }}
                                                >
                                                  CLEAR
                                                </Button>
                                              </Box>
                                            </Grid>
                                          </Box>

                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            style={{ marginTop: "20px" }}
                                          >
                                            <Grid
                                              item
                                              xs={3}
                                              style={{
                                                display: `${displayHidePackageUI}`,
                                              }}
                                            >
                                              <Typography
                                                style={{
                                                  marginRight: "8px",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Pricing Annual/Monthly:
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              style={{
                                                display: `${displayHidePackageUI}`,
                                              }}
                                            >
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                              >
                                                <Typography
                                                  style={{
                                                    marginRight: "8px",
                                                    fontWeight: 400,
                                                  }}
                                                >
                                                  Currency:
                                                </Typography>
                                                <FormControl>
                                                  <Select
                                                    //label="Currency"
                                                    value={selectedCurrency}
                                                    onChange={
                                                      handleCurrencyChange
                                                    }
                                                    style={{ width: "75px" }}
                                                    variant="outlined"
                                                    size="small"
                                                  >
                                                    <MenuItem value="$">
                                                      {" "}
                                                      USD
                                                    </MenuItem>
                                                    <MenuItem value="€">
                                                      {" "}
                                                      EUR
                                                    </MenuItem>
                                                    <MenuItem value="¥">
                                                      JPY
                                                    </MenuItem>
                                                    {/* Add more currencies as needed */}
                                                  </Select>
                                                </FormControl>
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              style={{
                                                display: `${displayHidePackageUI}`,
                                              }}
                                            >
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                              >
                                                <Typography
                                                  style={{
                                                    marginRight: "8px",
                                                    fontWeight: 400,
                                                  }}
                                                >
                                                  Annual:
                                                </Typography>
                                                <TextField
                                                  id="trailDays"
                                                  label=""
                                                  variant="outlined"
                                                  size="small"
                                                  value={annualFee}
                                                  onChange={annualFeeChange}
                                                  style={{ width: "100px" }}
                                                />
                                              </Box>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={2}
                                              style={{
                                                display: `${displayHidePackageUI}`,
                                              }}
                                            >
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                              >
                                                <Typography
                                                  style={{
                                                    fontWeight: 400,
                                                    marginLeft:
                                                      "30px !important",
                                                  }}
                                                >
                                                  Monthly:&nbsp;
                                                </Typography>
                                                <TextField
                                                  id="trailDays"
                                                  label=""
                                                  variant="outlined"
                                                  size="small"
                                                  value={monthlyFee}
                                                  onChange={monthlyFeeChange}
                                                  style={{ width: "100px" }}
                                                />
                                              </Box>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={3}
                                              style={{
                                                display: `${displayHidePackageUI}`,
                                              }}
                                            >
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                              >
                                                <Typography
                                                  style={{
                                                    marginRight: "8px",
                                                    fontWeight: 400,
                                                  }}
                                                >
                                                  Display preference
                                                </Typography>
                                                <TextField
                                                  type="text"
                                                  value={orderNumberPackage}
                                                  onChange={
                                                    handleChangeOrderNumberPackage
                                                  }
                                                  label="Display preference"
                                                  variant="outlined"
                                                  fullWidth
                                                  size="small"
                                                  style={{ width: "150px" }}
                                                />
                                              </Box>
                                            </Grid>
                                          </Box>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={12}
                                          spacing={2}
                                          style={{
                                            display: `${displayHidePackageUI}`,
                                          }}
                                        >
                                          <Card style={{border:'1px solid black'}}>
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "10px",
                                                padding: "20px",
                                              }}
                                            >
                                              <Button
                                                id="btnOpenRiskCollection"
                                                variant="outlined"
                                                size="medium"
                                                className={
                                                  openButtonPacFeature
                                                    ? classes.superAdmintabBtnDBClickedBlog
                                                    : classe.tabBtnDB
                                                }
                                                onClick={() =>
                                                  setOpenButtonPacFeatureFunction(
                                                    true
                                                  )
                                                }
                                              >
                                                Package Features
                                              </Button>

                                              <>
                                                <Button
                                                  id="btnOpenRiskCollection"
                                                  variant="outlined"
                                                  size="medium"
                                                  className={
                                                    openButtonPacContent
                                                      ? classes.superAdmintabBtnDBClickedBlog
                                                      : classe.tabBtnDB
                                                  }
                                                  onClick={() =>
                                                    setOpenButtonPacContentFunction()
                                                  }
                                                >
                                                  Package Content
                                                </Button>
                                              </>
                                            </div>
                                            <CardContent>
                                             
                                               
                                                  <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{display: `${openButtonPacFeature}`}}>
                                                      <div
                                                        className={
                                                          classes.scrollableDivToStandard
                                                        }
                                                      >
                                                        <TableContainer
                                                          component={Paper}
                                                          //sx={{ maxHeight: 'auto' }}
                                                        >
                                                          <Table aria-label="customized table">
                                                            <TableHead
                                                              className={
                                                                classe.commonSecondaryTableHead
                                                              }
                                                            >
                                                              <TableRow>
                                                                <StyledTableCell
                                                                  className={
                                                                    classe.commonTableThStyle
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "65%",
                                                                  }}
                                                                >
                                                                  Package
                                                                  Feature
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                  align="right"
                                                                  className={
                                                                    classe.commonTableThStyle
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "35%",
                                                                  }}
                                                                >
                                                                  Result
                                                                </StyledTableCell>
                                                              </TableRow>
                                                            </TableHead>
                                                            {
                                                              previousTableRowData
                                                            }
                                                          </Table>
                                                        </TableContainer>
                                                      </div>
                                                    </Grid>
                                                 
                                             

                                    
                                               
                                                <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: `${openButtonPacContent}`,
                                        }}
                                      >
                                        <Editor
                                          apiKey="ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj"
                                          tinymceScriptSrc={
                                            "https://cdn.tiny.cloud/1/ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj/tinymce/6/tinymce.min.js"
                                          }
                                          onInit={(evt, editor) =>
                                            (editorRef2.current = editor)
                                          }
                                          initialValue={pricingDescription}
                                          init={{
                                            referrer_policy: "origin",
                                            height: 300,
                                            menubar: true,
                                            placeholder: "Pricing Description",
                                            plugins:
                                              "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media mediaembed template codesample table advtable charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help charmap quickbars emoticons casechange formatpainter pageembed hr fullpage advcode export permanentpen powerpaste",
                                            imagetools_cors_hosts: [
                                              "picsum.photos",
                                            ],
                                            menubar:
                                              "file edit view insert format tools table help",
                                            toolbar:
                                              "undo redo | bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | casechange forecolor backcolor formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview print | pageembed insertfile image media template tokens link anchor codesample | ltr rtl hr fullpage export permanentpen configurepermanentpen",
                                            //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                                            export_image_proxy: "proxy.php",
                                            toolbar_sticky: true,
                                            autosave_ask_before_unload: true,
                                            autosave_interval: "30s",
                                            autosave_prefix:
                                              "{path}{query}-{id}-",
                                            autosave_restore_when_empty: false,
                                            autosave_retention: "2m",
                                            setup: (editor) => {
                                              editor.on("init", function (e) {
                                                var content =
                                                  unescape(pricingDescription);
                                                editor.setContent(content);
                                                editor.dom.bind(editor.getBody(), 'click', function (e) {
                                                  var target = e.target;
                                          
                                                  // Check if the clicked element is an image
                                                  if (target.nodeName.toLowerCase() === 'img') {
                                                    // Your onclick function logic here
                                                    imagePreviw(target.src);  // Replace this with your actual onclick function
                                                  }
                                                });
                                              });
                                            },

                                            image_title: true,
                                            /* enable automatic uploads of images represented by blob or data URIs*/
                                            automatic_uploads: true,
                                            /*Here we add custom filepicker only to Image dialog*/
                                            file_picker_types: "image",

                                            /* and here's our custom image picker*/
                                            file_picker_callback: function (
                                              cb,
                                              value,
                                              meta
                                            ) {
                                              var input =
                                                document.createElement("input");
                                              input.setAttribute(
                                                "type",
                                                "file"
                                              );
                                              input.setAttribute(
                                                "accept",
                                                "image/*"
                                              );

                                              input.onchange = function () {
                                                var file = this.files[0];
                                                var reader = new FileReader();

                                                reader.onload = function () {
                                                  /*
                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                  registry. In the next release this part hopefully won't be
                                                  necessary, as we are looking to handle it internally.
                                                  */
                                                  var id =
                                                    "blobid" +
                                                    new Date().getTime();

                                                  var blobCache =
                                                    editorRef2.current
                                                      .editorUpload.blobCache;
                                                  //var blobCache = editorRef.current.activeEditor.editorUpload.blobCache;
                                                  var base64 =
                                                    reader.result.split(",")[1];

                                                  var blobInfo =
                                                    blobCache.create(
                                                      id,
                                                      file,
                                                      base64
                                                    );
                                                  blobCache.add(blobInfo);

                                                  /* call the callback and populate the Title field with the file name */
                                                  cb(blobInfo.blobUri(), {
                                                    title: file.name,
                                                  });
                                                };

                                                reader.readAsDataURL(file);
                                              };

                                              input.click();
                                            },

                                            images_upload_handler: function (
                                              blobInfo,
                                              success,
                                              failure
                                            ) {
                                            
                                            },

                                            templates: tinyMCETemplateList,
                                          
                                            referrer_policy: "origin",

                                            template_cdate_format:
                                              "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
                                            template_mdate_format:
                                              "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
                                            image_caption: true,
                                            quickbars_selection_toolbar:
                                              "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                                            noneditable_noneditable_class:
                                              "mceNonEditable",
                                            toolbar_mode: "sliding",
                                            contextmenu:
                                              "link image imagetools table",
                                            //content_css: useDarkMode ? 'dark' : 'default',
                                            content_style:
                                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                          }}
                                        />
                                      </Grid>
                                      </Grid>
                                                

                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </>
                              ) : (
                                ""
                              )}
                            </CardContent>
                          </Card>

                          {/* {showFnPButton && (
                            <Grid item xs={5}>
                              <Box style={{ display: "flex", gap: "5px" }}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  style={{
                                    marginTop: "10px",
                                    height: "46px",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    openFeatureDialogOpen();
                                  }}
                                >
                                  Create / Edit Pricing Features
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  style={{
                                    marginTop: "10px",
                                    height: "46px",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    openPackagesDialogOpen();
                                  }}
                                >
                                  Create / Edit Pricing packages
                                </Button>
                              </Box>
                            </Grid>
                          )} */}

                          <br></br>
                        </Grid>
                      </CardContent>
                    </Card>
                  </CardContent>
                </Card>
              </Grid>
            </>
          </Grid>
        </Grid>
      </div>

      <Dialog
        open={openImage}
        //onClose={setOpenImage(false)}
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        ></DialogTitle>
        <DialogContent>{parseHtml(imageHtmlData)}</DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseClick()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Pricing2;
