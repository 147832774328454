import React from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import '../Css/Slider.css'
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import RiskLevelSlider from './RiskLevelSlider';

const QuestionerPageOpenEnded = ({handleOpenEndedChange, openEndedvalue, openEndedScoreValue, handleOpenEndedScoreChange, openEndedAnswerValue, handleOpenEndedAnswerChange,
     questionSliderMethods,sliderType,handleChange,riskClearFunction,showSelectBar,sliderValue,setSliderValue,
     nameChange,sliderValueName,questionLableNameOpenEnded,setSliderValueStyle, sliderValueStyle,setRiskLvlEditFunction}) => {
    console.log(questionSliderMethods)


  return (
    <>
    <Grid item xs={7}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
            >
            <div>
                <TextField
                    id="openEndedQuest"
                    label={questionLableNameOpenEnded}
                    multiline
                    maxRows={5}
                    value={openEndedvalue}
                    onChange={handleOpenEndedChange}
                    autoFocus={true}
                />
            </div>
        </Box>
    </Grid>
    <Grid item xs={5}>
 
    <Box component="form" sx={{ '& .MuiTextField-root': {width: '100%' },}} validate autoComplete="off">
                                                                <div>

                                                                <RiskLevelSlider
                                                                    MethodDropdown={'Select Score method'}
                                                                    SliderName={'Question Score'}
                                                                    showSelectBar={showSelectBar}
                                                                    handleChange={handleChange}
                                                                    sliderType={sliderType}
                                                                    riskCategoryMethodData={questionSliderMethods}
                                                                    nameChange={nameChange}
                                                                    sliderValue={sliderValue}
                                                                    sliderValueStyle={sliderValueStyle}
                                                                    sliderValueName={sliderValueName}
                                                                    riskClearFunction={riskClearFunction}
                                                                    setSliderValueFunction={setRiskLvlEditFunction}
                                          
                                          />
                                                                {/* <>
    
                                                  {
                                                      showSelectBar ? 
                                                      
                                                      <div>

                                                      <Box >
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={10}>
                                                      <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Select Question Score Method</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          value={sliderType}
                                                          label="Age"
                                                          onChange={handleChange}
                                                        >

                                                            {questionSliderMethods.map((data,index)=> (
                                                            
                                                            <MenuItem value={data.id}>{data.methodName}</MenuItem>

                                                           
                                                          ))}
                                                  
                                                        </Select>
                                                      </FormControl>
                                                      </Grid>
                                                      </Grid>
                                                    </Box>
                                                
                                                    </div>
                                                      
                                                      
                                                      :null
                                                  }
                                                    
                                                  {
                                                    questionSliderMethods.map((data)=>(
                                                     
                                                      <>
                                                      {
                                                        sliderType === data.id ? 
                                                        <>
                                                        
                                                         <p style={{color:'black'}}>Question Score</p>
                                                              <Grid container spacing={2}>
                                                              
                                                                <Grid item xs={7}>
                                                                  <div>
                                                                 
                                                                    <input
                                                                      id='slider'
                                                                      type="range"
                                                                      min={data.minimumWeightingNumber}
                                                                      
                                                                      max={data.maximumWeightingNumber}
                                                                      value={sliderValue}
                                                                      onChange={({ target: { value } }) => {setRiskLvlEditFunction(value,data.minimumWeightingNumber,data.maximumWeightingNumber);nameChange(value)}}
                                                                      className={`slider `}
                                                                      // className={btnEntityClass()}
                                                                      style={{background: `${sliderValueStyle}`}}
                                                                      //onClick={nameChange} 
                                                                      />
                                                                  </div>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                  <div >
                                                                  <div >
                                                                    <p style={{fontSize:'10px'}}>{sliderValueName}</p>
                                                                  </div>
                                                                 
                                                                  </div>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                <div>
                                                                  <Button variant="outlined"  onClick={riskClearFunction}>clear</Button>
                                                                  </div>
                                                                </Grid>
                                                                
                                                                
                                                              </Grid>
                                                            <p>
                                                                 <b>{sliderValue}&nbsp;-&nbsp;{sliderValueName}</b>
                                                              </p>
                                                        </> 
                                                        
                                                        
                                                        
                                                        :null
                                                      }
                                                      </>
                                                    ))
                                                  }

                                                      
                                                    
                                                        </> */}
                                                                </div>
                                                            </Box> 





    </Grid>
    <Grid item xs={12}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { mt: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
            >
            <div>
                <TextField
                    id="openEndedAnswer"
                    label="Answer (about 30 charactors)"
                    multiline
                    maxRows={3}
                    value={openEndedAnswerValue}
                    onChange={handleOpenEndedAnswerChange}
                />
            </div>
        </Box>
    </Grid>


    </>
  )
}

export default QuestionerPageOpenEnded