import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import { Add, AddCircleTwoTone, ArrowDropDownSharp, CheckBoxOutlineBlankSharp, CheckBoxSharp, CleaningServicesRounded, LinearScale, QuestionAnswerRounded, RadioButtonChecked, SelectAllRounded, SelectAllSharp } from '@mui/icons-material';
import Draggable from 'react-draggable';
import { color, fontSize } from '@mui/system';
import {CONFIG} from '../config'
import axios from 'axios';
import surveryQuestionScale from '../ServeyQuestionScaleJson';
import ListItemText from '@mui/material/ListItemText';
import { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Edit from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import useStyle from '../Css/MainCss';
import RiskLevelSlider from './RiskLevelSlider';


const useStyles = makeStyles((theme) => ({ 
    right: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    tableHeadStyle: {
      backgroundColor: '#ece9fa',
      color: '#ffffff',
    },
    tableAnsConfirmHeadStyle: {
        backgroundColor: '#6666FF',
        color: '#ffffff',
        height: '100px !important',
        fontSize: '14px !important',
        fontWeight: '600'
    },
    tableAnsConfrnThStyle: {
        lineHeight: '0.50rem !important',
        fontSize: '22px !important',
        fontWeight: '600',
        color: '#ffffff !important'
    },
    tableAnsConfirmTrStyle: {
        lineHeight: '0.50rem !important',
        backgroundColor: '#FFFFFF',
        borderBottom: '0px !important'
    },
    tableAnsConfirmTrStyle1: {
        lineHeight: '0.25rem !important',
        backgroundColor: '#FFFFFF',
        borderBottom: '0px !important',
        paddingBottom: '0px !important',
        paddingTop: '0px !important',
    },  
    mousePointer: {
        cursor: 'pointer'
    },
    tableThStyle: {
       lineHeight: '0.25rem !important'
    },
     tableTrStyle: {
       lineHeight: '0.25rem !important',
       paddingTop: '0px !important',
       fontSize: '10px !important',
       paddingBottom: '0px !important'
    },
    closeEndedCardHeader:{
        backgroundColor: '#ece9fa !important',
        fontSize:'13px',
        height:'44px',
        padding:'0px',
        marginTop: '-7px',
    },
    avStyle: {
        width: '25px',
        height: '25px',
    },
    paddingStyle: {
        padding:'-3px 14px'
    },
    btnBorderRed:{
        border: '1px solid red !important',
        color: 'red !important',
        padding:'5px 10px !important',
        height: '40px !important',
        fontSize: '11px !important',
      },
      btnBorderGreen:{
        border: '1px solid 4C7EE0 !important',
        color: '4C7EE0 !important',
        padding:'5px 10px !important',
        height: '40px !important',
        fontSize: '11px !important',
      },
      focusBorder:{
        border: '2px solid red !important',
      },
      timeOutBorder:{
        border: '0px solid red !important',
      },


}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));  
  
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: '15px'
    },
  }));

 


const AddQustionCloseEnded = ({closeEndedvalue, handleCloseEndedChange, ansArray, setAnsArray, ansMethod,setAnsMethod,minNumber,setMinNumber,maxNumber,setMaxNumber,scalingMethod,setScalingMethod,scallingMgs,setScallingMsg, closeEndedTypeName, setCloseEndedTypeName, closeEndedTypeStatus, setCETypeStatus, ansArrayTmp, setAnsArrayTmp, closeEndedScoreValue, handleCloseEndedScoreChange, answerOrderNumber, setAnswerOrderNumber, selectedRiskName, selectedSuggestedValue, setSelectedSuggestedValue, optArray, setOptArray, closeEndedTypeMgs, setCloseEndedTypeMsg,
    questionSliderMethods,sliderTypeCloseEnded,handleChangeCloseEnded,riskClearFunctionCloseEnded,showSelectBarCloseEnded,sliderValueCloseEnded,setSliderValueCloseEnded,
    nameChangeCloseEnded,sliderValueNameCloseEnded,questionLableNameCloseEnded,setRiskLvlEditFunctionCloseEnded,sliderValueCloseEndedStyle,databClr,colorPatterns,
    scalingMethodExamples,setScalingMethodExamples,scalingMethodExampleId,setScalingMethodExampleId,scalingMethodExampleName,setScalingMethodExampleName,scaleData,databClrAns}) => {

      console.log(databClr)

      const classe = useStyle();
   
    const classes = useStyles();

    /** Sort abject array */
    function compare( a, b ) {
        if ( a.orderNumber < b.orderNumber ){
            return -1;
        }
        if ( a.orderNumber > b.orderNumber ){
            return 1;
        }
        return 0;
    }

    /** Find Duplicates values in a array */
    function toFindDuplicates(arry) {
        let toMap = {};
        let resultToReturn = false;
        for (let i = 0; i < arry.length; i++) {
            if (toMap[arry[i]]) {
                resultToReturn = true;
                break;
            }
            toMap[arry[i]] = true;
        }
        if (resultToReturn) {
        return true;
        }else{
        return false;
        }  
    }

    if (ansMethod != ""){
        setAnsMethod(ansMethod);
        console.log("ansMethod : " + ansMethod);
    }
    if (minNumber != 0){
        setMinNumber(minNumber);
    }
    if (maxNumber != 0){
        setMaxNumber(maxNumber);
    }
    if (scalingMethod != ""){
        setScalingMethod(scalingMethod);
    }
    
    
    // useEffect(()=> {
    //     getColors()
    // })
    
    
    // const [databClr, setDatabClr] = useState([])

    // function getColors(){
    //     axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
    //       method: 'get',
    //       headers: { 
    //           'Content-Type': 'text/plain', 
    //         },     
    //       })
    //       .then(function (response) {
    //         console.log("All colors");
    //         console.log(response.data);
    
    //         setDatabClr(response.data)
    
           
    //       })
    //       .catch(function (error) {
    //       console.log(error);
    //       });
    //   }

    const [firstElementFocus, setFirstElementFocus] = React.useState(false);
    
    const questionTypeHandler = (status) => {
        if (status === 1){
            setCETypeStatus(1);
            setCloseEndedTypeName("Nominal");
            setCloseEndedTypeMsg("Nominal scale is a naming scale, where Answers are simply \“named\” or labeled, with no specific order");
            setSelectDropDownOpen(true)
        }
        if (status === 2){
            setCETypeStatus(2);
            setCloseEndedTypeName("Ordinal");
            setCloseEndedTypeMsg("Ordinal scale has all its Answers in a specific order, beyond just naming them");
            setSelectDropDownOpen(true)
        }
        if (status === 3){
            setCETypeStatus(3);
            setCloseEndedTypeName("Interval");
            setCloseEndedTypeMsg("Interval scale offers labels, order, as well as, a specific interval between each of its variable options");
            setSelectDropDownOpen(true)
        }
        if (status === 4){
            setCETypeStatus(4);
            setCloseEndedTypeName("Ratio");
            setCloseEndedTypeMsg("Ratio scale bears all the characteristics of an interval scale, in addition to that, it can also accommodate the value of “zero” on any of its variables");
            setSelectDropDownOpen(true)
        }
        if (status != 0){
            setFocusStyle(setFirstElementFocus);
        }


    };
    const ansMethodArr = [
        {
            name: "Multiple Answers",
            value: "checkBoxType"
        },
        {
            name: "One Answer only",
            value: "radioButtonType"
        },        
        {
            name: "Answers with Preferential Order ",
            value: "orderNumberType"
        },
        {
            name: "User Input",
            value: "answerTypeType"
        },
    ]
    
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const [selectDropDownOpen, setSelectDropDownOpen] = useState(false)

    const [secondElementFocus, setSecondElementFocus] = React.useState(false);

    const handleChangeAnsMethod = (e) => {
        setSelectDropDownOpen(false)
        //orderNumberType, checkBoxType, radioButtonType
        setAnsMethod(e.target.value);
        if (e.target.value != "orderNumberType"){
            setMinNumber(1); 
            setMaxNumber(5);
        }

        setFocusStyle(setSecondElementFocus);

    };

    const selectRef = React.useRef();
    

    const clickAnsMethod = (e) => {
        if (closeEndedTypeStatus === 0) {
            alert('Please Select Measurement Scale First');
            return;
        }
    }

   
    const handleChangeMinNumber = (e) => {
        setMinNumber(e.target.value);
        if (maxNumber > 0){
            if (e.target.value > 0) {
                let a1 = parseInt(e.target.value);   
                let a2 = parseInt(maxNumber);    
                setOptArray(range(a1,a2)) ;
            }
        }
    };
    const handleChangeMaxNumber = (e) => {
        setMaxNumber(e.target.value);
        if (minNumber > 0){
            if (e.target.value > 0) {
                let a1 = parseInt(minNumber);   
                let a2 = parseInt(e.target.value);    
                setOptArray(range(a1,a2)) ;
            }
        }
    };

    function range(start, end) {
      return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }


    const ansScallingMethodArr = [
        {
            name: "Likert",
            value: "likert"
        },
        {
            name: "Semantic Differential",
            value: "semanticdifferential"
        },        
        {
            name: "Dichotomous",
            value: "dichotomous"
        },
    ]

    const [thiredElementFocus, setThiredElementFocus] = React.useState(false);

    const handleChangeScalingMethod = (e) => {
        //likert, semanticdifferential, dichotomous
        setFocusStyle(setThiredElementFocus);
        
        var tmpMethod = e.target.value;
        setScalingMethod(tmpMethod);


        scaleData.forEach((data)=>{
            if(e.target.value === data.id){
                setScallingMsg(data.description);
                setScalingMethodExamples(data.examples)
            }
        })

        // var arr = [];
        // if (tmpMethod === "likert"){
        //     setScallingMsg("It is a psychometric scale questions. Typically a five, seven, or nine-point agreement scale are used");
        //     var arr = surveryQuestionScale.scales;
        //     arr.forEach((data)=> {
        //         if (data.name === "likert") {
        //             console.log("+++++++++++++++++data.examples+++++++++++++++++");
        //             console.log(data.examples);
        //             setScalingMethodExamples(data.examples);
        //         }
        //     })
        // }
        // if (tmpMethod === "semanticdifferential"){
        //     setScallingMsg("Answering options are grammatically on opposite adjectives at each end like love-hate, satisfied-unsatisfied, and likely to return-unlikely to return with intermediate options in between");
        //     var arr = surveryQuestionScale.scales;
        //     arr.forEach((data)=> {
        //         if (data.name === "semanticdifferential") {
        //             setScalingMethodExamples(data.examples);
        //         }
        //     })
        // }
        // if (tmpMethod === "dichotomous"){
        //     setScallingMsg("It can have two possible answers like Yes/No, True/False, Fair/Unfair or Agree/Disagree answers");
        //     var arr = surveryQuestionScale.scales;
        //     arr.forEach((data)=> {
        //         if (data.name === "dichotomous") {
        //             setScalingMethodExamples(data.examples);
        //         }
        //     })
        // }

    };

    const [scalingMethodExampleData, setScalingMethodExampleData] = React.useState([]);
    const [scalingMethodExampleDataName, setScalingMethodExampleDataName] = React.useState([]);

    const [forthElementFocus, setForthElementFocus] = React.useState(false);

    const handleChangeScalingMethodExample = (e) => {
        setFocusStyle(setForthElementFocus);
        
        var tmpMethodId = e.target.value;
        setScalingMethodExampleId(tmpMethodId);
        scalingMethodExamples.forEach((data)=> {
            if (tmpMethodId === data.exampleId){
                setScalingMethodExampleName(data.name);
                setScalingMethodExampleData(data.types);
            }
        })

    };

    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
          },
        },
      };
    const [scalingMethodExampleNames, setScalingMethodExampleNames] = React.useState([]);
    const [exampleNamesArray, setExampleNamesArray] = React.useState([]);
    const [waitingOpen, setWaitingOpen] = React.useState(false);

    const handleChangeScalingMethodExampleData = (event) => {
        const {
          target: { value },
        } = event;
        setScalingMethodExampleNames(
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const [tempTableFocus, setTempTableFocus] = React.useState(false);

    function addAnswerToTempTable(){
        if (scalingMethodExampleNames.length === 0){
            alert("Please select at least one example to add to table ")
        }else{
            if (exampleNamesArray.length === 0){
                var arr = [];
                scalingMethodExampleNames.forEach((name)=> {
                    var obj = {}
                    obj["answer"] = name;
                    obj["riskLevel"] = '';
                    obj["riskCategoryId"] = '';
                    obj["riskMethodId"] = '';
                    arr.push(obj);
                })
                setFocusStyle(setTempTableFocus);
                setExampleNamesArray(arr);      
            }else{
                let text = "Update new selection";
                text += "\n\nPress 'OK' to confirm new selection.";
                text += "\nOr 'Cancel'.";
                if (window.confirm(text) == true) {
                    setExampleNamesArray([]);
                    //console.log(scalingMethodExampleNames);
                    setWaitingOpen(true);
                    setTimeout(()=> {
                        setWaitingOpen(false);
                        var arr = [];
                        scalingMethodExampleNames.forEach((name)=> {
                            var obj = {}
                            obj["answer"] = name;
                            obj["riskLevel"] = '';
                            obj["riskCategoryId"] = '';
                            obj["riskMethodId"] = '';
                            arr.push(obj);
                        })
                        setFocusStyle(setTempTableFocus);
                        setExampleNamesArray(arr);
                    }, 500); 
                }else{
                    var arr = [];
                    scalingMethodExampleNames.forEach((name)=> {
                        var canTake = false;
                        exampleNamesArray.forEach((data)=> {
                            if (data.answer === name){
                                canTake = true;
                            }
                        })
                        if (canTake){
                            arr.push(name);
                        }
                    })
                    setWaitingOpen(true);
                    setTimeout(()=> {
                        setWaitingOpen(false);
                        setScalingMethodExampleNames(arr);
                        setFocusStyle(setTempTableFocus);
                    }, 500); 
                }
            }
        }
    }

    function clearAnswerToTempTable(){
        setScalingMethodExampleNames([])
    }
    function addANewAnswerToTempTable(){
        var arr = [];
        exampleNamesArray.forEach((data)=> {
            arr.push(data);
        })
        var obj = {}
        obj["answer"] = '';
        obj["riskLevel"] = '';
        obj["riskCategoryId"] = '';
        obj["riskMethodId"] = '';
        arr.push(obj);
        setWaitingOpen(true);
        setTimeout(()=> {
            setWaitingOpen(false);
            setExampleNamesArray(arr);
        }, 100);
    }

    //id={"newAnsName" + index}

    const [confirmTableFocus, setConfirmTableFocus] = React.useState(false);

    function addToConfirmTable(){
        var txtEmpty = false;
        var riskEmpty = false;
        var isEmpty = true;
        exampleNamesArray.forEach((data,index)=> {
            var txtValue = document.getElementById("newAnsName" + index).value;
            isEmpty = false;
            if (txtValue === null || txtValue === ''){
                txtEmpty = true;
            }
            if (data.riskLevel === ''){
                riskEmpty = true;
            }
        })
        if (txtEmpty && riskEmpty){
            alert("Please complte the Answers in the table and also make sure you have properly scalled them")
        }else{
            if (isEmpty){
                alert("Please fill the Answer table before adding to the confirm table")
            }else{    
                if (txtEmpty){
                    alert("Please complte the Answers in the table")
                }else{
                    if (riskEmpty){
                        alert("Please make sure you have properly scalled answers in the table")
                    }else{
                        var alreadyList = '';
                        var alreadyCount = '';
                        ansArrayTmp.forEach((data)=>{
                            exampleNamesArray.forEach((item,index)=> {
                                var txtValue = document.getElementById("newAnsName" + index).value;
                                if (data.answer === txtValue) {    
                                    alreadyList = alreadyList + alreadyCount + ") " + data.answer + "\n";
                                    alreadyCount ++;
                                }
                            })    
                        })
                        if (alreadyList != ''){
                            alert("The following Answers are already in the confirm list. \n" + alreadyList + "Please change or delete duplicate names before confirming.")
                        }else{

                            var count = answerOrderNumber;
                            exampleNamesArray.forEach((data,index)=> {
                                var arrayData = {};
                                var txtValue = document.getElementById("newAnsName" + index).value;
                                arrayData["answer"] = txtValue;
                                arrayData["risk"] = data.riskLevel;
                                var tmpArray1 = {};
                                tmpArray1["riskLevel"] = data.riskLevel
                                tmpArray1["riskCategoryId"] = data.riskCategoryId
                                tmpArray1["riskMethodId"] = data.riskMethodId
                                arrayData["riskLevelData"] = tmpArray1;
                                arrayData["orderNumber"] = count;
                                count ++;
                                ansArrayTmp.push(arrayData);
                            })
                            setWaitingOpen(true);
                            setTimeout(()=> {
                                setWaitingOpen(false);
                                ansArrayTmp.sort(compare);
                                setAnsArrayTmp(ansArrayTmp);
                                setAnswerOrderNumber(count);
                                setExampleNamesArray([])
                                setFocusStyle(setConfirmTableFocus);
                            }, 500); 
                        }

            






                    }
                }
            }    
        }

    }




    //add scale dialog

    const [openAddScale, setOpenAddScale] = React.useState(false);
    const [addScaleIndex, setAddScaleIndex] = React.useState(-1);
    function handleClickAddScale(indexNo){
        var isEditItem = false;
        exampleNamesArray.forEach((data, index)=>{
            if (index === indexNo) { 
                setAddScaleIndex(indexNo); 
                
                if (data.riskLevel != ''){
                    setRiskValue(data.riskLevel);
                    setShowSelectBarScalingAnswer(false);
                    isEditItem = true;
                }
                if (data.riskCategoryId != ''){
                    setAnswerCategoryId(data.riskCategoryId);
                }
                if (data.riskMethodId != ''){
                    setSliderTypeScalingAnswer(data.riskMethodId);
                }

                

                if (isEditItem){
                    var min = 0;
                    var max = 0
                    
                    console.log("+++++++++++++++++scalingScoreMethods++++++++++++++");
                    console.log(scalingScoreMethods);
                    //setDatabClr([])
                    var colors = [];
                    scalingScoreMethods.forEach((item)=> {
                        //alert(data.riskMethodId);
                        //alert(item.id);
                        if(data.riskMethodId === item.id){
                            console.log("++++++++++++++++item+++++++++++++++++++")
                            console.log(item)
                            var colorPatId = item.colorPatternId
                            colorPatterns.forEach((colorItem)=>{
                                if (colorItem.id === colorPatId){
                                  setDatabClrAns1(colorItem.colors);
                                  colors = colorItem.colors;
                                }
                            })
                            
                            item.riskTypes.forEach((data1)=> {
                                if (data1.startingNumber <= data.riskLevel && data.riskLevel <= data1.endingNumber) {
                                setSliderValueNameScalingAnswer(data1.riskTypesName)
                                min=item.minimumWeightingNumber;
                                max=item.maximumWeightingNumber;
                                }
                            })
                        }
                    })

                    var posNumber = data.riskLevel - min;
                    var totalNumber = max - min;
                    var percentageVal = (posNumber/totalNumber) * 100
                    var cCode = getSubColorCodeRiskValueNew(data.riskLevel,colors);
                    var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
                    setRiskValueStyle(cssStr);

                }
            }
        })
        if (!isEditItem){
            setRiskValueStyle();
        }
        setOpenAddScale(true);
    }

    function handleClickAddScaleConfirm(){
        var arr = [];
        exampleNamesArray.forEach((data, index)=>{
            if (index === addScaleIndex) { 
                data["riskLevel"] = riskValue;
                data["riskCategoryId"] = answerCategoryId;
                data["riskMethodId"] = sliderTypeScalingAnswer;
                arr.push(data); 
            }else{
                arr.push(data); 
            }
        })
        setWaitingOpen(true);
        setTimeout(()=> {
             setWaitingOpen(false);
             setExampleNamesArray(arr);
             setOpenAddScale(false);
             setAddScaleIndex(-1);
             riskClearFunctionScalingAnswer();
        }, 500);

    }
    const handleCloseAddScale = () => {
        setOpenAddScale(false);
    };




    function deleteTableRow(indexNo){
        exampleNamesArray.forEach((data, index)=>{
            if (index === indexNo) {  
                exampleNamesArray.splice(index, 1);
                setExampleNamesArray(exampleNamesArray);
            }
        })
    }

    const tableTempRowData = (
        <TableBody>
        {exampleNamesArray.map((row,index) => (
            <StyledTableRow key={index} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <TextField
                        id={"newAnsName" + index}
                        defaultValue={row.answer}
                        size="small"
                        style={{width: '40px !important'}}
                    />
                </StyledTableCell>
                <StyledTableCell 
                    align="left"
                    className={classes.tableTrStyle}
                    >
                    {row.riskLevel != ''?    
                        <Button 
                            variant="outlined" 
                            style={{fontSize:'0.7rem', height: '50px'}}
                            startIcon={<Edit />} 
                            onClick ={ ()=>{handleClickAddScale(index)}}
                            >
                            Edit Scaling
                        </Button>
                    : 
                        <Button 
                            variant="outlined" 
                            style={{fontSize:'0.7rem', height: '50px'}}
                            startIcon={<Add />} 
                            onClick ={ ()=>{handleClickAddScale(index)}}
                            >
                            Add Scaling
                        </Button>   
                    }    
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    {row.riskLevel}
                </StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <DeleteIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{deleteTableRow(index)}}
                    />
                </StyledTableCell>
            </StyledTableRow>
        ))}



    </TableBody>
    )


    /*
    const handleChangeScalingMethodExampleData = (e) => {
        var tmpExampleName = e.target.value;
        setScalingMethodExampleDataName(tmpExampleName);
        setAnsValue(tmpExampleName);

    };
    */

    
    
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [ansOpen, setAnsOpen] = React.useState(false);
    const [ansScroll, setAnsScroll] = React.useState('paper');

    const [radioGroupFocus, setRadioGroupFocus] = React.useState(false);
  
    const ansHandleClickOpen = (scrollType) => () => {
        if (closeEndedTypeStatus > 0 && closeEndedvalue != "" && sliderValueCloseEnded != "") {
            setAnsOpen(true);
            setAnsScroll(scrollType);
            setAnsValue('');
            setRiskValue();
            if (ansMethod === ''){
                setFocusStyle(setFirstElementFocus);
            }else{
                if (scalingMethod === ''){
                    setFocusStyle(setSecondElementFocus);
                }else{
                    if (scalingMethodExampleId === ''){
                        setFocusStyle(setThiredElementFocus);
                    }else{
                        if (scalingMethodExampleNames.length === 0){
                            setFocusStyle(setForthElementFocus);
                        }
                    }
                }
            }
        } else{
            if (closeEndedvalue === ""){
                alert("Please Type the close ended question"); 
            }else{
                /*
                if (sliderValueCloseEnded === ""){
                    // alert("Please Enter Question Score");
                }else{
                    alert("Please select one of the measurement scale type above");
                }
                */
                setAnsOpen(true);
                setAnsScroll(scrollType);
                setAnsValue('');
                setRiskValue();
                setFocusStyle(setRadioGroupFocus);


            }
            
        }   
    };
    const ansHandleClickClose = () => {
      setAnsOpen(false);
      setRiskValueStyle()
    };
    const ansElementRef = React.useRef(null);
    React.useEffect(() => {
      if (ansOpen) {
        const { current: ansElement } = ansElementRef;
        if (ansElement !== null) {
          ansElement.focus();
        }
      }
      getAllQuestionMethods()
      
    }, [ansOpen]);



    const [ansValue, setAnsValue] = React.useState('');
    const [ansValueOrg, setAnsValueOrg] = React.useState('');
    const handleChangeAns = (event) => {
        setAnsValue(event.target.value);
    };



    //malindu answer slider section

    const [scalingScoreMethods, setScalingScoreMethods] = useState([])

    const [answerCategoryId, setAnswerCategoryId] = useState()

    function getAllQuestionMethods(){
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_ANSWER+"/all",{
          method: 'get',
          headers: { 
              'Content-Type': 'text/plain', 
            },     
          })
          .then(function (response) {
           
              console.log("All Risk Returns");
              console.log(response.data[0]);
              setScalingScoreMethods(response.data[0].methods)
              setAnswerCategoryId(response.data[0].id)
              console.log(response.data[0].methods);
      
          })
          .catch(function (error) {
          console.log(error);
          });
      }

  //  const [questionSliderMethods, setQuestionSliderMethods] = useState([])
        
  //  const [questionCategoryId, setQuestionCategoryId] = useState()


    
    const [sliderTypeScalingAnswer, setSliderTypeScalingAnswer] = React.useState();
    const [sliderTypeScalingAnswerOrg, setSliderTypeScalingAnswerOrg] = React.useState();
    const [sliderTypeScalingAnswerCategoryOrg, setSliderTypeScalingAnswerCategoryOrg] = React.useState();

    const [showSelectBarScalingAnswer, setShowSelectBarScalingAnswer] = React.useState(true);

    const [sliderValueScalingAnswer, setSliderValueScalingAnswer] = React.useState();

    const [sliderValueNameScalingAnswer, setSliderValueNameScalingAnswer] = React.useState('');
    const [sliderValueNameScalingAnswerOrg, setSliderValueNameScalingAnswerOrg] = React.useState('');

    const [riskValue, setRiskValue] = React.useState();
    const [riskValueOrg, setRiskValueOrg] = React.useState();

    const [riskValueStyle, setRiskValueStyle] = React.useState();
    const [riskValueStyleOrg, setRiskValueStyleOrg] = React.useState();

    const [databClrAns1, setDatabClrAns1] = useState(databClrAns);  
    
    const handleChangeScalingAnswer = (event) => {  
        setSliderTypeScalingAnswer(event.target.value);
        setShowSelectBarScalingAnswer(false);
        setDatabClrAns1([]);

        scalingScoreMethods.forEach((data)=> {
            if (data.id === event.target.value){
              var colorPatId = data.colorPatternId;
              if (colorPatId != undefined && colorPatId != null){
                colorPatterns.forEach((item)=> {
                  if (item.id === colorPatId){
                    setDatabClrAns1(item.colors);
                  }
                })
              }
            }
        })


        
      };
    
    
      function riskClearFunctionScalingAnswer(){
        setShowSelectBarScalingAnswer(true);
        setSliderTypeScalingAnswer('');
        setRiskValue();
        setSliderValueNameScalingAnswer('');

      }

      function nameChangeScalingAnswer(){

        console.log("+++++++++++++++++++scalingScoreMethods++++++++++++++++++");

        console.log(scalingScoreMethods);
        console.log(sliderTypeScalingAnswer);
        console.log(riskValue);



        scalingScoreMethods.forEach((data)=> {
          if(sliderTypeScalingAnswer === data.id){
            data.riskTypes.forEach((data1)=> {
              if (data1.startingNumber <= riskValue && riskValue <= data1.endingNumber) {
                setSliderValueNameScalingAnswer(data1.riskTypesName)
                
              }
            })
          }
        })
        
      
      }

  
    const handleChangeRisk = (event) => {
        const value = event.target.value.replace(/\D/g, "");
        setRiskValue(value);
    };


    // end of the answer scaling section 
   
    const [editAnswer, setEditAnswer] = React.useState(false);
    const [editAnswerOrderNumber, setEditAnswerOrderNumber] = React.useState(0);
    
    function setAnswerAndRisk(){
        if (ansMethod === ""){
            alert("Please select an Answer Method");
            return
        }
        if ( ansValue === ""){
            // alert("Please enter Answer type");
            // return
        }
        if ( riskValue === ""){
            // alert("Please enter Risk Level");
            // return
        }
        
        var canTake = true;
        ansArrayTmp.forEach((data)=>{
            if (data.answer === ansValue) {    
                alert(data.answer + " is already in the Answer list");
                canTake = false
                return;
            }
        })
        if (scalingMethod === "dichotomous"){
            if (ansArrayTmp.length === 2){
                alert("Do not allow more that 2 answers for Dichotomous question scale");
                canTake = false
                return;
            }
        }
        if (canTake){
            var arrayData = {}
            arrayData["answer"] = ansValue;
            arrayData["risk"] = riskValue;
            var tmpArray1 = {}

            tmpArray1["riskLevel"] = riskValue
            tmpArray1["riskCategoryId"] = answerCategoryId
            tmpArray1["riskMethodId"] = sliderTypeScalingAnswer
            arrayData["riskLevelData"] = tmpArray1;

            if (editAnswer){
                arrayData["orderNumber"] = editAnswerOrderNumber;
                setEditAnswer(false);
            }else{
                arrayData["orderNumber"] = answerOrderNumber;
                setAnswerOrderNumber(answerOrderNumber + 1);
            }
            ansArrayTmp.push(arrayData);
            ansArrayTmp.sort(compare);
            setAnsArrayTmp(ansArrayTmp);
            console.log(ansArrayTmp)
            setAnsValue("");
            setRiskValue();
            riskClearFunctionScalingAnswer()

            setOpenAddNewAnswer(false);

        }
        setRiskValueStyle()
    }


    function setAnswerAndRiskCancel(){
        var canTake = true;
        if (canTake){
            var arrayData = {}
            arrayData["answer"] = ansValueOrg;
            arrayData["risk"] = riskValueOrg;
            var tmpArray1 = {}

            tmpArray1["riskLevel"] = riskValueOrg
            tmpArray1["riskCategoryId"] = sliderTypeScalingAnswerCategoryOrg
            tmpArray1["riskMethodId"] = sliderTypeScalingAnswerOrg
            arrayData["riskLevelData"] = tmpArray1;

            if (editAnswer){
                arrayData["orderNumber"] = editAnswerOrderNumber;
                setEditAnswer(false);
            }
            ansArrayTmp.push(arrayData);
            ansArrayTmp.sort(compare);
            setAnsArrayTmp(ansArrayTmp);
            console.log(ansArrayTmp)
            setAnsValue("");
            setAnsValueOrg("");
            setRiskValue();
            setRiskValueOrg();
            setSliderTypeScalingAnswerCategoryOrg();
            riskClearFunctionScalingAnswer()

            setOpenAddNewAnswer(false);

        }
        setRiskValueStyle()
        setRiskValueStyleOrg()
    }


    console.log(sliderTypeCloseEnded)
    /** Edit Answer*/


    function editItemArrary(answer){
        if (editAnswer) {
            alert("You have not edited the previous edited Answer. Please edit the previous one and add to list before edit an another Entity");
            return;
        }
        ansArrayTmp.forEach((data, index)=>{
            if (data.answer === answer) {  
                console.log(data)
                setAnsValue(data.answer);
                setAnsValueOrg(data.answer);
                setRiskValue(data.risk);
                setRiskValueOrg(data.risk);
                setShowSelectBarScalingAnswer(false)
                setSliderTypeScalingAnswer(data.riskLevelData.riskMethodId)
                setSliderTypeScalingAnswerOrg(data.riskLevelData.riskMethodId);
                setSliderTypeScalingAnswerCategoryOrg(data.riskLevelData.riskCategoryId);

                if (data.riskLevelData != undefined && data.riskLevelData != null && data.riskLevelData.riskMethodId != undefined && data.riskLevelData.riskMethodId != null && data.riskLevelData.riskMethodId != '' ){
                var min = 0;
                var max = 0
                var colors = [];
                scalingScoreMethods.forEach((item)=> {
                    if(data.riskLevelData.riskMethodId === item.id){
                        var tmpColorPatId = item.colorPatternId;
                        colorPatterns.forEach((colorItem)=>{
                          if (colorItem.id === tmpColorPatId){
                            setDatabClrAns1(colorItem.colors);
                            colors = colorItem.colors;
                          }
                        })
                        
                      item.riskTypes.forEach((data1)=> {
                        if (data1.startingNumber <= data.risk && data.risk <= data1.endingNumber) {
                          setSliderValueNameScalingAnswer(data1.riskTypesName)
                          setSliderValueNameScalingAnswerOrg(data1.riskTypesName)
                          min=item.minimumWeightingNumber;
                          max=item.maximumWeightingNumber;
                        }
                      })
                    }
                })

                var posNumber = data.risk - min;
                var totalNumber = max - min;
                var percentageVal = (posNumber/totalNumber) * 100
                var cCode = getSubColorCodeRiskValueNew(data.risk,colors);
                var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
                setRiskValueStyle(cssStr);
                setRiskValueStyleOrg(cssStr);
                }else{
                    setShowSelectBarScalingAnswer(true)
                }

                setEditAnswer(true);
                setEditAnswerOrderNumber(data.orderNumber);
                ansArrayTmp.splice(index, 1);  
                ansArrayTmp.sort( compare );  
                setAnsArrayTmp(ansArrayTmp);
                
                setOpenAddNewAnswer(true);
            }
        })
    }

    /** Delete Answer*/
    function deleteItemArrary(answer){
        ansArrayTmp.forEach((data, index)=>{
            if (data.answer === answer) {  
                ansArrayTmp.splice(index, 1);
                setAnsValue(answer);
                alert("The answer '" + answer + "' Was deleted")
                ansArrayTmp.sort( compare );
                setAnsArrayTmp(ansArrayTmp);
                setRiskValue();
                setAnsValue("");
            }
        })
        /*
        var tmpArr = [];
        var num = 1;
        ansArrayTmp.forEach((data2, index2)=>{
            var arrayData = {}
            arrayData["answer"] = data2.answer;
            arrayData["risk"] = data2.risk;
            arrayData["orderNumber"] = num;
            tmpArr.push(arrayData);
            num ++;
        })
        setAnsArrayTmp([]);
        setAnswerOrderNumber(num);
        tmpArr.sort( compare );
        setAnsArrayTmp(tmpArr);
        setRiskValue("");
        setAnsValue("");
        */


    }

    function confirmChangePositionFunction() {
        var tmpObjList = [];
        var newArr = [];
        var canTake = true;
        ansArrayTmp.forEach((data, index)=>{
            var newVal = parseInt(document.getElementById("newAnsPos" + index).value);
            if (newVal != "" && !isNaN(newVal)) {
                newArr.push(newVal);
                let objObject = {};
                objObject["answer"] = data.answer;
                objObject["risk"] = data.risk;
                objObject["orderNumber"] = newVal;
                tmpObjList.push(objObject);
            }else{
                alert("Please Check the input position again. There may be empty value or non numeric value entered");
                canTake = false;
            }
        })
        var duplicateElements = toFindDuplicates(newArr);
        if (duplicateElements != undefined && duplicateElements != null && duplicateElements){
            alert("Please Check the input position again. There may be duplicated values entered");
            canTake = false;
         }
        if (tmpObjList != null && tmpObjList.length > 0 && canTake){
            tmpObjList.sort( compare );
            setAnsArrayTmp(tmpObjList);
            alert("Order Numbers are set Correctly");
        }
    }

    function confirmFunction(){
        riskClearFunctionScalingAnswer()
        if (ansArrayTmp.length > 0){
            var totalVal = 0.00;
            ansArrayTmp.forEach((data, index)=>{
                totalVal = totalVal + parseFloat(data.risk);
            })
            var canConfirm = true;
            var tmpCloseEndedScoreValue = 0.00;
            if (sliderValueCloseEnded === ""){
                canConfirm = false;
                // alert("Please enter Question Score before entering answers");
                // return;  
            }else{
                tmpCloseEndedScoreValue = parseFloat(sliderValueCloseEnded);
            }
           
            /*
            if (totalVal > tmpCloseEndedScoreValue){
                canConfirm = false;
                alert("Total Answers Scalling values should not exceed Question Score");
                return;    
            }
            if (totalVal < tmpCloseEndedScoreValue){
                canConfirm = false;
                alert("Total Answers Scalling values should be equeals to Question Score");
                return;    
            }
            */
            
            if (canConfirm){
                setAnsArray(ansArrayTmp);
                setAnsOpen(false);
            }
            
        }else{
            // alert("Please fill the Answer Scalling Table");
        } 
        setRiskValueStyle()   
    } 

    //add color to slider

    function getSubColorCodeRiskValue(number) {
        var cCode = "#ffffff";
        databClrAns1.forEach((data)=> {
          if (parseInt(number) === parseInt(data.scoreNumber)){
            cCode = data.color;
          }
        })
        return cCode;
      }

      function getSubColorCodeRiskValueNew(number, colors) {
        var cCode = "#ffffff";
        colors.forEach((data)=> {
          if (parseInt(number) === parseInt(data.scoreNumber)){
            cCode = data.color;
          }
        })
        return cCode;
      }
      
      function setRiskLvlEditFunctionRiskValue(val, min, max, type){
        //alert("XXXXXX")
        var posNumber = val - min;
        var totalNumber = max - min;
        var percentageVal = (posNumber/totalNumber) * 100
        var cCode = getSubColorCodeRiskValue(val);
        var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
        
        setRiskValue(val);
        setRiskValueStyle(cssStr);
        
      }  





    const orderNumberSelect = (
        <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel id="select-simple-select-label">Select</InputLabel>
            <Select
                labelId="select-simple-select-label"
                id="select-simple-select"
                label="Select"
                >
                {optArray.map((item)=>(
                    <MenuItem 
                        value={item}
                    >
                    {item}
                    </MenuItem>
                ))}    
            </Select>
        </FormControl>
    )    

    const [selectedValueTmp, setSelectedValueTmp] = React.useState(0);
    const handleChangeTmp = (event) => {
      setSelectedValueTmp(event.target.value);
    };
    const tableRowData = (
        <TableBody>
        {ansMethod === "radioButtonType" && ansArrayTmp.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <TextField
                        id={"newAnsPos" + index}
                        //label="position"
                        defaultValue={row.orderNumber}
                        size="small"
                        style={{width: '32px !important'}}
                    />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <Radio
                        checked={selectedValueTmp === `${index}`}
                        onChange={handleChangeTmp}
                        value={index}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle}>{row.risk}</StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <EditIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{editItemArrary(row.answer)}}
                    />
                </StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <DeleteIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{deleteItemArrary(row.answer)}}
                    />
                </StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "checkBoxType" && ansArrayTmp.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <TextField
                        id={"newAnsPos" + index}
                        //label="position"
                        defaultValue={row.orderNumber}
                        size="small"
                        style={{width: '32px !important'}}
                    />
                </StyledTableCell>

                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <Checkbox {...label} />
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle}>{row.risk}</StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <EditIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{editItemArrary(row.answer)}}
                    />
                </StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <DeleteIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{deleteItemArrary(row.answer)}}
                    />
                </StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "orderNumberType" && optArray.length > 0 && ansArrayTmp.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <TextField
                        id={"newAnsPos" + index}
                        //label="position"
                        defaultValue={row.orderNumber}
                        size="small"
                        style={{width: '32px !important'}}
                    />
                </StyledTableCell>

                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    {orderNumberSelect}
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle}>{row.risk}</StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <EditIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{editItemArrary(row.answer)}}
                    />
                </StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <DeleteIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{deleteItemArrary(row.answer)}}
                    />
                </StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "answerTypeType" && ansArrayTmp.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <TextField
                        id={"newAnsPos" + index}
                        //label="position"
                        defaultValue={row.orderNumber}
                        size="small"
                        style={{width: '32px !important'}}
                    />
                </StyledTableCell>

                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                <Box
                    component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '10ch' },
                        }}
                        
                        autoComplete="off"
                    >
                    <div>
                        <TextField
                            id="ansTypeType"
                            label="Answer"
                            size="small"
                        />
                    </div>
                </Box> 
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle}>{row.risk}</StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <EditIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{editItemArrary(row.answer)}}
                    />
                </StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <DeleteIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{deleteItemArrary(row.answer)}}
                    />
                </StyledTableCell>
            </StyledTableRow>
        ))}



    </TableBody>
    )

    

    const tableSetRowData = (
        <TableBody>
        {ansMethod === "radioButtonType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    <Radio
                        checked={selectedValueTmp === `${index}`}
                        onChange={handleChangeTmp}
                        value={index}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>{row.risk}</StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "checkBoxType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    <Checkbox {...label} />
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>{row.risk}</StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "orderNumberType" && optArray.length > 0 && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    {orderNumberSelect}
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>{row.risk}</StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "answerTypeType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                <Box
                    component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '10ch' },
                        }}
                        
                        autoComplete="off"
                    >
                    <div>
                        <TextField
                            id="ansTypeType"
                            label="Answer"
                            size="small"
                        />
                    </div>
                </Box> 
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>{row.risk}</StyledTableCell>
            </StyledTableRow>
        ))}
    </TableBody>
    )

    
    const handleChangeSuggestedValue = (event) => {
        console.log(event.target.value);
        setSelectedSuggestedValue(event.target.value);
    };
    const suggestedAnsTable = (
    <>
        {ansMethod === "radioButtonType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} >
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                    &nbsp;
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                    <Radio
                       
                        checked={selectedSuggestedValue === `${index}`}
                        onChange={handleChangeSuggestedValue}
                        value={index}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    {row.answer}              
                </StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "checkBoxType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} >
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                    &nbsp;
                </StyledTableCell>
                <StyledTableCell copSpan={2} component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    <FormControlLabel 
                        control={<Checkbox id={"chk"+index} checked={row.expectedAnswerName}/>} label={row.answer} />
                </StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "orderNumberType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} >
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                    &nbsp;
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'40%' }}>
                    {row.answer}              
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                    <FormControl sx={{ m: 0.5, minWidth: 100 }} size="small">
                        <InputLabel id="select-select-label">Select</InputLabel>
                        <Select
                            labelId="select-select-label"
                            id={"select" + index}
                            label="Select"
                            defaultValue={row.expectedAnswerName}
                            >
                            {optArray.map((item)=>(
                                <MenuItem 
                                    value={item}
                                >
                                {item}
                            </MenuItem>
                            ))}    
                        </Select>
                    </FormControl>
                </StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "answerTypeType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} >
            <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                &nbsp;
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'40%' }}>
                {row.answer}              
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                <Box
                    component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '10ch' },
                        }}
                        autoComplete="off"
                    >
                    <div>
                        <TextField
                            id={"txt" + index}
                            defaultValue={row.expectedAnswerName}
                            size="small"
                        />
                    </div>
                </Box> 
            </StyledTableCell>
        </StyledTableRow>
        ))}
    </>  
    )


    function PaperComponent(props) {
        return (
          <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper {...props} />
          </Draggable>
        );
    }

    /** Answer Confirm */
    const [ansConfirmOpen, setAnsConfirmOpen] = React.useState(false);
    const [ansConfirmScroll, setAnsConfirmScroll] = React.useState('paper');
    
    const ansConfirmHandleClickOpen = (scrollType) => () => {
        setAnsConfirmOpen(true);
        setAnsConfirmScroll(scrollType);
    };

    const ansConfirmHandleClickClose = () => {
        setAnsConfirmOpen(false);
    };

    function confirmExpertAnswer(){
        var tmpArry = [];
        if (ansMethod === "radioButtonType") {
            ansArray.forEach((data,index)=>{
                console.log("+++++++++++++++++++Expert Answer Data+++++++++++++++++++++++++");
                console.log(data);
                if (index === parseInt(selectedSuggestedValue)) {   
                    var arrayData = {}
                    arrayData["answer"] = data.answer;
                    arrayData["risk"] = data.risk;
                    arrayData["orderNumber"] = data.orderNumber;
                    arrayData["expectedAnswerName"] = "Yes";
                    arrayData["riskLevelData"] = data.riskLevelData;
                    tmpArry.push(arrayData);
                }else{
                    var arrayData = {}
                    arrayData["answer"] = data.answer;
                    arrayData["risk"] = data.risk;
                    arrayData["orderNumber"] = data.orderNumber;
                    arrayData["expectedAnswerName"] = null;
                    arrayData["riskLevelData"] = data.riskLevelData;
                    tmpArry.push(arrayData);
                }
            })
            setAnsArray(tmpArry);
            setAnsConfirmOpen(false);
        }
        if (ansMethod === "checkBoxType") {
            ansArray.forEach((data,index)=>{
                var arrayData = {}
                arrayData["answer"] = data.answer;
                arrayData["risk"] = data.risk;
                arrayData["orderNumber"] = data.orderNumber;
                if (document.getElementById('chk' + index).checked){
                    arrayData["expectedAnswerName"] = "checked";
                }else{
                    arrayData["expectedAnswerName"] = "";
                }
                arrayData["riskLevelData"] = data.riskLevelData;
                tmpArry.push(arrayData);
            })
            setAnsArray(tmpArry);
            setAnsConfirmOpen(false);
        }
        if (ansMethod === "orderNumberType") {
            ansArray.forEach((data,index)=>{
                var select = document.getElementById('select' + index);
                var value = select.innerText;
                if (value === undefined || value === null){
                    value = "";
                }
                var arrayData = {}
                arrayData["answer"] = data.answer;
                arrayData["risk"] = data.risk;
                arrayData["orderNumber"] = data.orderNumber;
                arrayData["expectedAnswerName"] = value;
                arrayData["riskLevelData"] = data.riskLevelData;
                tmpArry.push(arrayData);
            })
            setAnsArray(tmpArry);
            setAnsConfirmOpen(false);
        }
        if (ansMethod === "answerTypeType") {
            ansArray.forEach((data,index)=>{
                var value = document.getElementById("txt" + index).value;
                if (value === undefined || value === null){
                    value = "";
                }
                var arrayData = {}
                arrayData["answer"] = data.answer;
                arrayData["risk"] = data.risk;
                arrayData["orderNumber"] = data.orderNumber;
                arrayData["expectedAnswerName"] = value;
                arrayData["riskLevelData"] = data.riskLevelData;
                tmpArry.push(arrayData);
            })
            setAnsArray(tmpArry);
            setAnsConfirmOpen(false);
        }
    }

    //Add A new Answer dialog

    const [openAddNewAnswer, setOpenAddNewAnswer] = React.useState(false);

    const handleClickOpenAddNewAnswer = () => {
        setOpenAddNewAnswer(true);
    };
  
    const handleCloseAddNewAnswer = () => {
        setOpenAddNewAnswer(false);
    };

    function setFocusStyle(varName){
        varName(true);
        setTimeout(()=> {
            varName(false);
            setTimeout(()=> {
                varName(true);
                setTimeout(()=> {
                    varName(false);
                    setTimeout(()=> {
                        varName(true);
                        setTimeout(()=> {
                            varName(false);
                        }, 500);
                    }, 500);
                }, 500);
            }, 500);
        }, 1000);
    
    }



return (
    <>
    <Grid item xs={7}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
            >
            <div>
                <TextField
                    id="closeEndedQuest"
                    label={questionLableNameCloseEnded}
                    multiline
                    maxRows={3}
                    value={closeEndedvalue}
                    onChange={handleCloseEndedChange}
                    autoFocus={true}
                />
            </div>
        </Box>
    </Grid>
    <Grid item xs={5}>
        <Box component="form" sx={{ '& .MuiTextField-root': {width: '100%' },}} validate autoComplete="off">
            <div>

                                                            <RiskLevelSlider
                                                                    MethodDropdown={'Select Score method'}
                                                                    SliderName={'Question Score'}
                                                                    showSelectBar={showSelectBarCloseEnded}
                                                                    handleChange={handleChangeCloseEnded}
                                                                    sliderType={sliderTypeCloseEnded}
                                                                    riskCategoryMethodData={questionSliderMethods}
                                                                    nameChange={nameChangeCloseEnded}
                                                                    sliderValue={sliderValueCloseEnded}
                                                                    sliderValueStyle={sliderValueCloseEndedStyle}
                                                                    sliderValueName={sliderValueNameCloseEnded}
                                                                    riskClearFunction={riskClearFunctionCloseEnded}
                                                                    setSliderValueFunction={setRiskLvlEditFunctionCloseEnded}
                                          
                                          />
                {/* <>
                    {showSelectBarCloseEnded ? 
                        <div>
                            <Box >
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Select Score method</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={sliderTypeCloseEnded}
                                                label="Age"
                                                onChange={handleChangeCloseEnded}
                                                >
                                                {questionSliderMethods.map((data,index)=> (
                                                    <MenuItem value={data.id}>{data.methodName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    :null
                    }
                    {questionSliderMethods.map((data)=>(
                        <>
                            {sliderTypeCloseEnded === data.id ? 
                                <>
                                    <p style={{color:'black'}}>Question Score</p>
                                    <Grid container spacing={2}>
                                        <Grid item xs={7}>
                                            <div>
                                                <input
                                                    id='slider'
                                                    type="range"
                                                    min={data.minimumWeightingNumber}
                                                    max={data.maximumWeightingNumber}
                                                    value={sliderValueCloseEnded}
                                                    onChange={({ target: { value } }) => setRiskLvlEditFunctionCloseEnded(value,data.minimumWeightingNumber,data.maximumWeightingNumber)}
                                                    className={`slider`}
                                                    style={{background: `${sliderValueCloseEndedStyle}`}}
                                                    onClick={nameChangeCloseEnded} 
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <div>
                                                <div >
                                                    <p style={{fontSize:'10px'}}>{sliderValueNameCloseEnded}</p>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <div>
                                                <Button variant="outlined"  onClick={riskClearFunctionCloseEnded}>clear</Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <p>
                                        <b>{sliderValueCloseEnded}&nbsp;-&nbsp;{sliderValueNameCloseEnded}</b>
                                    </p>
                                </> 
                            :null
                            }
                        </>
                    ))}
                </> */}
            </div>
        </Box> 
    </Grid>
    
    <Grid item xs={3}>
        <Button variant="outlined" 
            startIcon={<AddIcon />} 
            onClick={ansHandleClickOpen('paper')}
            >
            Expected Answers
        </Button>
    </Grid>
    <Grid item xs={9}>
        {ansArray.length > 0 && (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 695 }} aria-label="customized table">
                    <TableHead className={classe.commonSecondaryTableHead}>
                        <TableRow>
                            <StyledTableCell className={classe.commonTableThStyle}>Answer</StyledTableCell>
                            <StyledTableCell className={classe.commonTableThStyle}>Answer Method</StyledTableCell>
                            <StyledTableCell className={classe.commonTableThStyle}>Scaling</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {tableSetRowData}
                </Table>    
            </TableContainer>    
        )}
    </Grid>   
    {ansArray.length > 0 && (
        <Grid item xs={12}>
            <Button 
                variant="outlined" 
                size='small'
                className={classes.btnBorderGreen}
                onClick={ansConfirmHandleClickOpen('paper')} 
                style={{float:'right'}}>
                    Expert's Example Answer/s
            </Button>
        
        </Grid>   

    )}

    {/** Close Ended Answers Dialog Box */}
    <Dialog
        maxWidth='xl'
        open={ansOpen}
        onClose={false}
        scroll={ansScroll}
        aria-labelledby="ans-scroll-dialog-title"
        aria-describedby="ans-scroll-dialog-description"
        >
        <DialogTitle id="draggable-dialog-title">
            Add A New Answer Type and Risk Level {closeEndedTypeName != null && closeEndedTypeName != ''? 'for \"' + closeEndedTypeName + '\" Measurement Scale':''}  
            <Grid container spacing={1}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <span style={{fontWeight:'bold',fontSize:'16px'}}>Question : </span><span style={{fontWeight:'normal',fontSize:'16px'}}>{closeEndedvalue}</span> - <span style={{fontSize: '16px', fontWeight:'bold'}}>Score : </span><span style={{fontSize: '16px !important', fontWeight:'normal'}}>{sliderValueCloseEnded}</span>
                </Grid>  
            </Grid>
        </DialogTitle>
        <DialogContent dividers={ansScroll === 'paper'}>
            <DialogContentText id="ans-scroll-dialog-description" ref={ansElementRef} tabIndex={-1}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h5>Answers</h5>
                    </Grid>
                    <Grid item xs={2}>
                        <FormLabel id="radio-buttons-group-close-ended-label">Measurement scale: </FormLabel>
                    </Grid>
                    <Grid item xs={4} className={radioGroupFocus? classes.focusBorder:classes.timeOutBorder} style={{height: '40px'}}>    
                        <RadioGroup
                            row
                            aria-labelledby="radio-buttons-group-close-ended-label"
                            name="radio-buttons-close-ended-group"
                            style={{marginTop: '-6px'}}
                            
                            >
                            <BootstrapTooltip title="Many named responses but user is allowed only one response. When you ask someone to select a meal from a menu, you're using a nominal scale. Yes/no questions, thumbs up/down, or multiple-choice questions are also nominal scale. Nominal-minded questions are also sometimes open-ended (allowing the person to write in a response)."> 	
                                <FormControlLabel 
                                    value="closeEndedNominal" 
                                    control={<Radio />} 
                                    label="Nominal" 
                                    checked={closeEndedTypeStatus === 1}
                                    onClick={(e) => questionTypeHandler(1)}  
                                />
                            </BootstrapTooltip>
                            <BootstrapTooltip title='User allowed many responses in a specific order. The answers can include: “very satisfied,” “satisfied,” “dissatisfied,” and “very dissatisfied”. Ranking of high school students – 1st, 3rd, 4th, 10th… Nth. A student scoring 99/100 would be the 1st rank, another student scoring 92/100 would be 3rd and so on and so forth. '>
                                <FormControlLabel 
                                    value="closeEndedOrdinal" 
                                    control={<Radio />} 
                                    label="Ordinal" 
                                    checked={closeEndedTypeStatus === 2}
                                    onClick={(e) => questionTypeHandler(2)}    
                                />
                            </BootstrapTooltip>
                            <BootstrapTooltip title="User allowed many responses in a specific order as well as a specific interval between each of it's options. Celsius and Fahrenheit are examples of interval scales. When you ask someone to rate their meal on a scale of one to ten, you're using an interval scale.">
                                <FormControlLabel 
                                    value="closeEndedInterval" 
                                    control={<Radio />} 
                                    label="Interval" 
                                    checked={closeEndedTypeStatus === 3}
                                    onClick={(e) => questionTypeHandler(3)}  
                                />
                            </BootstrapTooltip>
                            <BootstrapTooltip title="Same as Interval but with a 'Zero' as the difference. A ratio scale is a quantitative scale where there is a true zero and equal intervals between neighboring points. Unlike on an interval scale, a zero on a ratio scale means there is a total absence of the variable you are measuring. Length, area, and population are examples of ratio scales.">
                                <FormControlLabel 
                                    value="closeEndedRatio"  
                                    control={<Radio />} 
                                    label="Ratio" 
                                    checked={closeEndedTypeStatus === 4}
                                    onClick={(e) => questionTypeHandler(4)}    
                                />
                            </BootstrapTooltip>
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={5}>&nbsp;</Grid>
                    <Grid item xs={5} className={firstElementFocus? classes.focusBorder:classes.timeOutBorder} style={{height: '60px'}}>
                        <Box sx={{ minWidth: 120 }}>    
                            <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select a Answer Method for All Answers</InputLabel>
                                <Select
                                    label="Select a Answer Method for All Answers"
                                    sx={{ width: '100%' }}
                                    openMenuOnFocus={true}
                                    className={classes.paddingStyle}
                                    value={ansMethod}
                                    onChange={handleChangeAnsMethod}
                                    onClick={clickAnsMethod}
                                    //open={(selectDropDownOpen ? true : false  )  }
                                    ref={selectRef}
                                    
                                    >
                                    { closeEndedTypeStatus != 1 && closeEndedTypeStatus != 0 && scalingMethod != "dichotomous" && 
                                        ansMethodArr.map((item)=>(
                                            <MenuItem 
                                                value={item.value}
                                            >
                                            { (item.value === "checkBoxType") && 
                                                <>
                                                    {item.name} (<CheckBoxSharp/>)
                                                </>
                                            }
                                            { (item.value === "radioButtonType") && 
                                                <>
                                                    {item.name} (<RadioButtonChecked/>)
                                                </>
                                            }
                                            { (item.value === "orderNumberType") && 
                                                <>
                                                    {item.name} (<ArrowDropDownSharp/>)
                                                </>
                                            }
                                            { (item.value === "answerTypeType") && 
                                                <>
                                                    {item.name} (<QuestionAnswerRounded/>)
                                                </>
                                            }
                                            
                                            </MenuItem>
                                        ))
                                    }    
                                    { closeEndedTypeStatus === 1 && (
                                        <MenuItem 
                                            value="radioButtonType"
                                            >
                                            One Answer only  (<RadioButtonChecked/>)
                                        </MenuItem>
                                    )}
                                    { scalingMethod === "dichotomous" && (
                                        <MenuItem 
                                            value="radioButtonType"
                                            >
                                            One Answer only (<RadioButtonChecked/>)
                                        </MenuItem>
                                    )}


                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={7}>&nbsp;</Grid>




                    {/** 
                    <Grid item xs={12}>
                        <Alert severity="info">{closeEndedTypeMgs}</Alert>
                    </Grid>
                    */}    
                    <Grid item xs={3} className={secondElementFocus? classes.focusBorder:classes.timeOutBorder} style={{height: '60px'}}>
                        <Box sx={{ minWidth: 120 }}>    
                            <FormControl fullWidth>
                                <InputLabel id="ans-method-select-label">Select a survey question scale</InputLabel>
                                <Select 
                                    labelId="scale-method-select-label" 
                                    id="scale-method-select"
                                    label="Select a survey question scale"
                                    value={scalingMethod}
                                    className={classes.paddingStyle}
                                    onChange={handleChangeScalingMethod}
                                    //onMouseOver={scallingMgs}
                                    >
                                    {scaleData.map((item)=>(
                                        <MenuItem 
                                            value={item.id}
                                            title={item.description}
                                            >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={3} className={thiredElementFocus? classes.focusBorder:classes.timeOutBorder} style={{height: '60px'}}>
                        <Box sx={{ minWidth: 120 }}>    
                            <FormControl fullWidth>
                                <InputLabel id="example-select-label">Select an example for question scale if needed</InputLabel>
                                <Select 
                                    labelId="example-select-label" 
                                    id="example-select"
                                    label="Select an example for selected question scale if needed"
                                    value={scalingMethodExampleId}
                                    className={classes.paddingStyle}
                                    onChange={handleChangeScalingMethodExample}
                                    >

                                    {scalingMethodExamples.map((item)=>{
                                        var examples = '';
                                        item.types.forEach((data)=>{         
                                            examples = examples + data.name + '\n';
                                        })
                                        return (
                                            <MenuItem 
                                                value={item.exampleId}
                                                title={examples}
                                                >
                                                {item.name}
                                            </MenuItem>
                                        )
                                    })}

                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={4} className={forthElementFocus? classes.focusBorder:classes.timeOutBorder} style={{height: '60px'}}>
                        <Box sx={{ minWidth: 120 }}>    
                            <FormControl fullWidth>
                                <InputLabel id="example-data-select-label">Select examples</InputLabel>
                                <Select
          labelId="example-data-select-label"
          id="example-data-select"
          multiple
          value={scalingMethodExampleNames}
          onChange={handleChangeScalingMethodExampleData}
          input={<OutlinedInput label="Select examples" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {scalingMethodExampleData.map((name) => (
            <MenuItem key={name.name} value={name.name}>
              <Checkbox checked={scalingMethodExampleNames.indexOf(name.name) > -1} />
              <ListItemText primary={name.name} />
            </MenuItem>
          ))}
        </Select>

{/**

                                <Select 
                                    labelId="example-data-select-label" 
                                    id="example-data-select"
                                    label="Select an example"
                                    value={scalingMethodExampleDataName}
                                    className={classes.paddingStyle}
                                    onChange={handleChangeScalingMethodExampleData}
                                    >
                                    {scalingMethodExampleData.map((item)=>(
                                        
                                        
                                            
                                            <MenuItem 
                                            value={item.name}
                                            >
                                            {item.name}
                                        </MenuItem>
                                        
                                    
                                        
                                        
                                    ))}
                                </Select>
     */}
                                </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Button 
                            variant="outlined" 
                            style={{fontSize:'0.7rem', height: '50px'}}
                            startIcon={<Add />} 
                            onClick={() => addAnswerToTempTable()}
                            >
                            Add To Table
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button 
                            variant="outlined" 
                            style={{fontSize:'0.7rem', height: '50px'}}
                            startIcon={<CleaningServicesRounded />} 
                            onClick={() => clearAnswerToTempTable()}
                            >
                            Clear
                        </Button>
                    </Grid>


                    {/*scalingMethod != "" && (
                        <Grid item xs={7}>
                            <Alert severity="info"><span style={{fontSize:'9px'}}>{scallingMgs}</span>
                            </Alert>
                        </Grid>
                    )*/}

                        <Grid item xs={12} spacing={2}>
                            <Card className={classes.qusCard}>
                                <CardHeader
                                    avatar={
                                    <Avatar sx={{ bgcolor: '', width:'25px', height:'25px' }}>
                                        +
                                    </Avatar>
                                    } 
                                    className={classe.commonMainTableHead} 
                                    title="Expected Answers and Scale them"
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8} className={tempTableFocus? classes.focusBorder:classes.timeOutBorder}>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 695 }} aria-label="customized table">
                                                    <TableHead className={classe.commonSecondaryTableHead}>
                                                        <TableRow>
                                                            <StyledTableCell className={classe.commonTableThStyle} style={{width: '50%'}}>Answer</StyledTableCell>
                                                            <StyledTableCell className={classe.commonTableThStyle} style={{width: '20%'}}>Add/Edit Scaling</StyledTableCell>
                                                            <StyledTableCell className={classe.commonTableThStyle}style={{width: '10%'}}>Scaling</StyledTableCell>
                                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Delete</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {tableTempRowData}

                                                   
                                                </Table>
                                            </TableContainer>    
                                        </Grid>
                                        <Grid item xs={2}>
                                        <Button 
                                            variant="outlined" 
                                            style={{fontSize:'0.7rem', height: '37px'}}
                                            startIcon={<Add />} 
                                            //onClick={() => clearAnswerToTempTable()}
                                            onClick={() => addANewAnswerToTempTable()}
                                            >
                                            Add A new Answer
                                        </Button>
                                        </Grid>
                                        <Grid item xs={2}>
                                        <Button 
                                            variant="outlined" 
                                            style={{fontSize:'0.7rem', height: '37px'}}
                                            startIcon={<Add />} 
                                            onClick={() => addToConfirmTable()}
                                            >
                                            Add To Confirm List
                                        </Button>
                                        </Grid>

                                        </Grid>
                                    <Grid container spacing={2}>
                                        {/* <Grid item xs={5} >
                                            <Box
                                                component="form"
                                                    sx={{
                                                        '& .MuiTextField-root': { width: '100%' },
                                                    }}
                                                    validate
                                                    autoComplete="off"
                                                >
                                                <div>
                                                    <TextField
                                                        id="ansType"
                                                        label="Answer"
                                                        multiline
                                                        maxRows={1}
                                                        value={ansValue}
                                                        onChange={handleChangeAns}
                                                    />
                                                </div>
                                            </Box> 
                                        </Grid>
                                        <Grid item xs={5}>


                                        <Box component="form" sx={{ '& .MuiTextField-root': {width: '100%' },}} validate autoComplete="off">
                                                                <div>
                                                                <>
    
                                                  {
                                                      showSelectBarScalingAnswer ? 
                                                      
                                                      <div>

                                                      <Box >
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={10}>
                                                      <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label" style={{marginTop:'!important -30px'}}>Input Score</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          value={sliderTypeScalingAnswer}
                                                          label="Age"
                                                          onChange={handleChangeScalingAnswer}
                                                        >

                                                            {scalingScoreMethods.map((data,index)=> (
                                                            
                                                            <MenuItem value={data.id}>{data.methodName}</MenuItem>

                                                           
                                                          ))}
                                                  
                                                        </Select>
                                                      </FormControl>
                                                      </Grid>
                                                      </Grid>
                                                    </Box>
                                                
                                                    </div>
                                                      
                                                      
                                                      :null
                                                  }
                                                    
                                                  {
                                                    scalingScoreMethods.map((data)=>(
                                                     
                                                      <>
                                                      {
                                                        sliderTypeScalingAnswer === data.id ? 
                                                        <>
                                                        
                                                         <p style={{color:'black', marginTop:'-30px'}}>Scaling</p>
                                                              <Grid container spacing={2}>
                                                              
                                                                <Grid item xs={7}>
                                                                  <div>
                                                                 
                                                                    <input
                                                                      id='slider'
                                                                      type="range"
                                                                      min={data.minimumWeightingNumber}
                                                                      
                                                                      max={data.maximumWeightingNumber}
                                                                      value={riskValue}
                                                                      onChange={({ target: { value } }) => setRiskLvlEditFunctionRiskValue(value,data.minimumWeightingNumber,data.maximumWeightingNumber)}
                                                                      className={`slider`}
                                                                      // className={btnEntityClass()}
                                                                      style={{background: `${riskValueStyle}`}}
                                                                      onClick={nameChangeScalingAnswer} />
                                                                  </div>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                  <div >
                                                                  <div >
                                                                    <p style={{fontSize:'10px'}}>{sliderValueNameScalingAnswer}</p>
                                                                  </div>
                                                                 
                                                                  </div>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                <div>
                                                                  <Button variant="outlined"  onClick={riskClearFunctionScalingAnswer}>clear</Button>
                                                                  </div>
                                                                </Grid>
                                                                
                                                                
                                                              </Grid>
                                                            <p>
                                                                 <b>{riskValue}&nbsp;-&nbsp;{sliderValueNameScalingAnswer}</b>
                                                              </p>
                                                        </> 
                                                        
                                                        
                                                        
                                                        :null
                                                      }
                                                      </>
                                                    ))
                                                  }

                                                      
                                                    
                                                        </>
                                                                </div>
                                                            </Box> 




                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button 
                                                variant="outlined" 
                                                style={{fontSize:'0.7rem', height: '50px'}}
                                                startIcon={<AddIcon />} 
                                                onClick={() => setAnswerAndRisk()}
                                                >
                                                Add To List
                                            </Button>
                                        </Grid> */}

                                        <Grid item xs={12} className={confirmTableFocus? classes.focusBorder:classes.timeOutBorder}>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 695 }} aria-label="customized table">
                                                    <TableHead className={classe.commonSecondaryTableHead}>
                                                        <TableRow>
                                                            <StyledTableCell className={classe.commonTableThStyle} style={{width: '10%'}}>Position</StyledTableCell>
                                                            <StyledTableCell className={classe.commonTableThStyle} style={{width: '40%'}}>Answer</StyledTableCell>
                                                            <StyledTableCell className={classe.commonTableThStyle} style={{width: '20%'}}>Answer Method</StyledTableCell>
                                                            <StyledTableCell className={classe.commonTableThStyle}style={{width: '10%'}}>Scaling</StyledTableCell>
                                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Edit</StyledTableCell>
                                                            <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Delete</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {tableRowData}

                                                   
                                                </Table>
                                            </TableContainer>    
                                        </Grid>     
                                        <Grid item xs={12}>
                                            { ansArrayTmp.length > 0 && (
                                                <>
                                                    &nbsp;&nbsp;<Button 
                                                    variant="outlined" 
                                                    size='small' 
                                                    className={classes.btnBorderRed}
                                                    //onClick={entPosHandleClickOpen('paper')}
                                                    onClick={() => confirmChangePositionFunction()} 
                                                    style={{float:'right'}}
                                                    > 
                                                    Change the Position and Press Here to Change
                                                    </Button>&nbsp;&nbsp;
                                                    </>
                                            )}
                                            &nbsp;&nbsp;<Button 
                                                variant="outlined" 
                                                size='small'
                                                className={classes.btnBorderGreen}
                                                onClick={() => confirmFunction(1)} 
                                                style={{float:'right'}}>
                                                    CONFIRM
                                            </Button>&nbsp;&nbsp;
                                        </Grid>



                                    </Grid>
                            
                                </CardContent>
                            </Card>
                        </Grid>
                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={ansHandleClickClose}>Cancel</Button>
        </DialogActions>
    </Dialog>


    {/** End of Close Ended Answers Dialog Box */}
                          

    {/** Answer Confirm Dialog Box */}
        <Dialog

            open={ansConfirmOpen}
            onClose={ansConfirmHandleClickClose}
            scroll={ansConfirmScroll}
            aria-labelledby="ansConfirm-scroll-dialog-title"
            aria-describedby="ansConfirm-scroll-dialog-description"
            fullWidth={true}
            maxWidth={'md'}
          >
          <DialogTitle id="ansConfirm-scroll-dialog-title"></DialogTitle>
          <DialogContent dividers={ansConfirmScroll === 'paper'}>
            <DialogContentText
              id="ansConfirm-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 695 }} aria-label="customized table">
                            <TableHead className={classes.tableAnsConfirmHeadStyle}>
                                <TableRow>
                                    <StyledTableCell colSpan={3} align="center" className={classes.tableAnsConfrnThStyle}>{selectedRiskName}</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow key={1}>
                                    <StyledTableCell colSpan={3} component="th" scope="row" className={classes.tableAnsConfirmTrStyle}>
                                        &nbsp;
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key={2}>
                                    <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle} style={{width:'5%'}}>
                                        #)
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} component="th" scope="row" className={classes.tableAnsConfirmTrStyle}>
                                        {closeEndedvalue} 
                                    </StyledTableCell>
                                </StyledTableRow>
                                {suggestedAnsTable}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => confirmExpertAnswer()}>Confirm</Button>&nbsp;&nbsp;<Button onClick={ansConfirmHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of entity possition Dialog Box */}



    {/**Add Scale Dialog Box */} 

    <div>
     
      <Dialog
        open={openAddScale}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Scale Value"}
        </DialogTitle>
        <DialogContent>
                <Grid container spacing={2}>
                <Grid item xs={8}>
                <Box component="form" sx={{ '& .MuiTextField-root': {width: '100%' },}} validate autoComplete="off">
                                                                <div>

                                                                <RiskLevelSlider
                                                                    MethodDropdown={'Add Scale Value'}
                                                                    SliderName={'Input Score'}
                                                                    showSelectBar={showSelectBarScalingAnswer}
                                                                    handleChange={handleChangeScalingAnswer}
                                                                    sliderType={sliderTypeScalingAnswer}
                                                                    riskCategoryMethodData={scalingScoreMethods}
                                                                    nameChange={nameChangeScalingAnswer}
                                                                    sliderValue={riskValue}
                                                                    sliderValueStyle={riskValueStyle}
                                                                    sliderValueName={sliderValueNameScalingAnswer}
                                                                    riskClearFunction={riskClearFunctionScalingAnswer}
                                                                    setSliderValueFunction={setRiskLvlEditFunctionRiskValue}
                                          
                                          />

                                                                {/* <>
    
                                                  {
                                                      showSelectBarScalingAnswer ? 
                                                      
                                                      <div>

                                                      <Box >
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={10}>
                                                      <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label" style={{marginTop:'!important -30px'}}>Input Score</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          value={sliderTypeScalingAnswer}
                                                          label="Age"
                                                          onChange={handleChangeScalingAnswer}
                                                        >

                                                            {scalingScoreMethods.map((data,index)=> (
                                                            
                                                            <MenuItem value={data.id}>{data.methodName}</MenuItem>

                                                           
                                                          ))}
                                                  
                                                        </Select>
                                                      </FormControl>
                                                      </Grid>
                                                      </Grid>
                                                    </Box>
                                                
                                                    </div>
                                                      
                                                      
                                                      :null
                                                  }
                                                    
                                                  {
                                                    scalingScoreMethods.map((data)=>(
                                                     
                                                      <>
                                                      {
                                                        sliderTypeScalingAnswer === data.id ? 
                                                        <>
                                                        
                                                         <p style={{color:'black', marginTop:'-30px'}}>Scaling</p>
                                                              <Grid container spacing={2}>
                                                              
                                                                <Grid item xs={7}>
                                                                  <div>
                                                                 
                                                                    <input
                                                                      id='slider'
                                                                      type="range"
                                                                      min={data.minimumWeightingNumber}
                                                                      
                                                                      max={data.maximumWeightingNumber}
                                                                      value={riskValue}
                                                                      onChange={({ target: { value } }) => setRiskLvlEditFunctionRiskValue(value,data.minimumWeightingNumber,data.maximumWeightingNumber)}
                                                                      className={`slider`}
                                                                      // className={btnEntityClass()}
                                                                      style={{background: `${riskValueStyle}`}}
                                                                      onClick={nameChangeScalingAnswer} />
                                                                  </div>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                  <div >
                                                                  <div >
                                                                    <p style={{fontSize:'10px'}}>{sliderValueNameScalingAnswer}</p>
                                                                  </div>
                                                                 
                                                                  </div>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                <div>
                                                                  <Button variant="outlined"  onClick={riskClearFunctionScalingAnswer}>clear</Button>
                                                                  </div>
                                                                </Grid>
                                                                
                                                                
                                                              </Grid>
                                                            <p>
                                                                 <b>{riskValue}&nbsp;-&nbsp;{sliderValueNameScalingAnswer}</b>
                                                              </p>
                                                        </> 
                                                        
                                                        
                                                        
                                                        :null
                                                      }
                                                      </>
                                                    ))
                                                  }

                                                      
                                                    
                                                        </> */}
                                                                </div>
                                                            </Box> 
                </Grid>
              
                </Grid>
        </DialogContent>
        <DialogActions>
        <Button onClick={ ()=>{handleClickAddScaleConfirm()}}>Confirm</Button> 
        <Button onClick={handleCloseAddScale}>cancel</Button>


        </DialogActions>
      </Dialog>
    </div>
       
    {/**End Scale Dialog Box */}

    {/**add a new answer Dialog Box */}


      <div>
     
      <Dialog
        maxWidth='xl'
        onClose={false}
        open={openAddNewAnswer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        >
        <DialogTitle id="alert-dialog-title">{"Edit Answer"}</DialogTitle>
        <DialogContent dividers={ansConfirmScroll === 'paper'}>
            <Grid container spacing={2}>
                <Grid item xs={5} >
                    <Box
                        component="form"
                            sx={{
                                '& .MuiTextField-root': { width: '100%' },
                            }}
                            validate
                            autoComplete="off"
                        >
                        <div>
                            <TextField
                                id="ansType"
                                label="Answer"
                                multiline
                                maxRows={1}
                                value={ansValue}
                                onChange={handleChangeAns}
                            />
                        </div>
                    </Box> 
                </Grid>
                <Grid item xs={5}>
                    <Box component="form" sx={{ '& .MuiTextField-root': {width: '100%' },}} validate autoComplete="off">
                        <div>
                            <>
                                {showSelectBarScalingAnswer ? 
                                    <div>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label" style={{marginTop:'!important -30px'}}>Input Score</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={sliderTypeScalingAnswer}
                                                            label="Age"
                                                            onChange={handleChangeScalingAnswer}
                                                            >
                                                            {scalingScoreMethods.map((data,index)=> (
                                                                <MenuItem value={data.id}>{data.methodName}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </div>
                                :null
                                }
                                {scalingScoreMethods.map((data)=>(
                                    <>
                                        {sliderTypeScalingAnswer === data.id ? 
                                            <>
                                                <p style={{color:'black', marginTop:'-18px'}}>Scaling</p>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={7}>
                                                        <div>
                                                            <input
                                                                id='slider'
                                                                type="range"
                                                                min={data.minimumWeightingNumber}
                                                                max={data.maximumWeightingNumber}
                                                                value={riskValue}
                                                                onChange={({ target: { value } }) => setRiskLvlEditFunctionRiskValue(value,data.minimumWeightingNumber,data.maximumWeightingNumber)}
                                                                className={`slider`}
                                                                style={{background: `${riskValueStyle}`}}
                                                                onClick={nameChangeScalingAnswer} 
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <div>
                                                            <div>
                                                                <p style={{fontSize:'10px'}}>{sliderValueNameScalingAnswer}</p>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <div>
                                                            <Button variant="outlined"  onClick={riskClearFunctionScalingAnswer}>clear</Button>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <p>
                                                    <b>{riskValue}&nbsp;-&nbsp;{sliderValueNameScalingAnswer}</b>
                                                </p>
                                            </> 
                                        :null
                                        }
                                    </>
                                ))}
                            </>
                        </div>
                    </Box> 
                </Grid>
                {ansMethod === "orderNumberType" && (
                    <>                        
                        <Grid item xs={4} spacing={2}>
                            <Box
                                component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%' },
                                    }}
                                    validate
                                    autoComplete="off"
                                >
                                <div>
                                    <TextField
                                        id="minNumber"
                                        label="Preferential Order Min. Number"
                                        value={minNumber}
                                        select
                                        className={classes.paddingStyle}
                                        onChange={handleChangeMinNumber}
                                        >
                                        <MenuItem value={0}>0</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                    </TextField>    
                                </div>
                            </Box> 
                        </Grid>
                        <Grid item xs={4} spacing={2}>
                            <Box
                                component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%' },
                                    }}
                                    validate
                                    autoComplete="off"
                                >
                                <div>
                                    <TextField
                                        id="maxNumber"
                                        label="Preferential Order Max. Number"
                                        value={maxNumber}
                                        select
                                        className={classes.paddingStyle}
                                        onChange={handleChangeMaxNumber}
                                        >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={6}>6</MenuItem>
                                        <MenuItem value={7}>7</MenuItem>
                                        <MenuItem value={8}>8</MenuItem>
                                        <MenuItem value={9}>9</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                    </TextField>
                                </div>
                            </Box> 
                        </Grid>
                    </>
                )}
                                        
                <Grid item xs={2}>
                    <Button 
                        variant="outlined" 
                        style={{fontSize:'0.7rem', height: '50px'}}
                        startIcon={<AddIcon />} 
                        onClick={() => setAnswerAndRisk()}
                        >
                        Add To List
                    </Button>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setAnswerAndRiskCancel()}>cancel</Button>
        </DialogActions>
      </Dialog>


      {/** Dialog Waiting */}
<Dialog
    maxWidth={'md'}
    open={waitingOpen}
    PaperProps={{style:{backgroundColor: 'transparent', boxShadow: 'none'}}} 
    >
    <CircularProgress disableShrink />
  </Dialog>

    </div>
                       

    </>   
  )
}

export default AddQustionCloseEnded