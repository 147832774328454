import React, { useEffect, useState } from 'react'
import { FormGroup, Grid, makeStyles, TableFooter } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { CONFIG} from '../config'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Draggable from 'react-draggable'; 
import { Close, SendOutlined } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import userEmailManagementPageStyle from '../Css/UserEmailManagementPageCss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircularProgress from '@mui/material/CircularProgress';
import RightBarDataForUserPages from '../Components/RightBarDataForUserPages';
import parseHtml from 'html-react-parser';


function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const UserEmailManagementPage = () => {
  
  const [waitingOpen, setWaitingOpen] = React.useState(false);
  
    
  const classes = userEmailManagementPageStyle();
    const Input = styled('input')({
      display: 'none',
    });

/** Sort abject sort array */
function compare( a, b ) {
  var nameA = a.entity.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.entity.name.toUpperCase(); // ignore upper and lowercase

  if ( nameA < nameB ){
      return -1;
  }
  if ( nameA > nameB ){
      return 1;
  }
  // names must be equal
  return 0;
}

function compareQuestion( a, b ) {
  var nameA = a.question.questionId; // ignore upper and lowercase
  var nameB = b.question.questionId; // ignore upper and lowercase

  if ( nameA < nameB ){
      return -1;
  }
  if ( nameA > nameB ){
      return 1;
  }
  // names must be equal
  return 0;
}

function compareRiskNo( a, b ) {
  var nameA = a.no; // ignore upper and lowercase
  var nameB = b.no; // ignore upper and lowercase

  if ( nameA < nameB ){
      return -1;
  }
  if ( nameA > nameB ){
      return 1;
  }
  // names must be equal
  return 0;
}


function compareQd( a, b ) {
    var nameA = a.createdDate; // ignore upper and lowercase
    var nameB = b.createdDate; // ignore upper and lowercase
    if ( nameA > nameB ){ return -1;}
    if ( nameA < nameB ){ return 1;}
    return 0;
}


  React.useEffect(() => {
    getData();
  }, []);

  const [golobalCentreId, setGolobalCentreId] = React.useState();
  function getData () {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId); 
    getAdminUsers(data.centreId);
    getEntityData(data.centreId);
    getCentreData(data.centreId);
    console.log(data)
  }
  const [centreName, setCentreName] = React.useState('');
    function getCentreData(centreId){
      var data = '{getCentreData(centreId:\"' + centreId + '\"){centreId,centreName}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          console.log("++++++++++++++++Return centreData++++++++++++++++");
          var centreData = response.data.data.getCentreData;
          console.log(centreData);
          setCentreName(centreData.centreName);
  
      })
      .catch(function (error) {
          console.log("Centre Data Loding error" + error);
      });
    }


  const location = useLocation();
  const collectionUniqueName = location.pathname.split("/")[2];

  const [collectionData, setCollectionData] = useState();
  const [collectionId, setCollectionId] = useState('');
  const [collectionTitle, setCollectionTttle] = useState("");
  const [collectionDesc, setCollectionDesc] = useState("");
  const [documentCreator, setDocumentCreator] = useState('');

  useEffect(() => {
    getMailComposeData(collectionUniqueName);
  },[])

function getMailComposeData(collectionUniqueName){
  axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
    method: 'get',
  })
  .then(function (response) {
    console.log("Return Collection Data from collectionUniqueName");
    console.log(response.data);
    setCollectionData(response.data[0]);
    var tmpCollectionId = response.data[0].id; 
    setCollectionId(tmpCollectionId);
    setDocumentCreator(response.data[0].documentCreator);
    var tmpCollectionTitle = response.data[0].title; 
    setCollectionTttle(tmpCollectionTitle);
    var tmpCollectionDesc = response.data[0].description; 
    setCollectionDesc(tmpCollectionDesc);

    axios(CONFIG.MAIN_URL+'/mail-send/all',{
      method: 'get',
    })
    .then(function (resp) {
      console.log("Return Mail Compose of Mail sending");
      console.log(resp.data);
      var sendComposeArr = resp.data;
      axios(CONFIG.MAIN_URL+'/mail-compose/filter?collectionUniqueName='+ collectionUniqueName,{
        method: 'get',
      })
      .then(function (res) {
        console.log("Return Mail Compose data from collectionUniqueName");
        console.log(res.data);
        var arr = res.data;
        arr = arr.sort (compareQd);
        rowSettingsStr(arr, tmpCollectionTitle,sendComposeArr);
        rowSettingsToEntityStr(arr);
      })
      .catch(function (error) {
        console.log(error);
      });
    })
    .catch(function (error) {
      console.log(error);
    });

  })
  .catch(function (error) {
    console.log(error);
  });

}

const [allAdminUsers, setAllAdminUsers] = useState([]);
function getAdminUsers(centreId){
  //http://localhost:8081/userdata/getadminusers?centreId=CN1
  axios(CONFIG.MAIN_URL+'/userdata/getadminusers?centreId='+ centreId,{
    method: 'get',

  })
  .then(function (res) {
    console.log("++++++++++++++++Return All Admin Users++++++++++++++++++++++");
    console.log(res.data);
    setAllAdminUsers(res.data)

  })
  .catch(function (error) {
    console.log(error);
  });

}


/** Get All Entity Data and set Centre Entity data from it*/
const [centreEntityData, setCentreEntityData] = React.useState([]);  
function getEntityData(centerId){
    var data = '{allEntityList{ entityDataList{entityName,entityId,isActive,createdBy,lastModifiedBy,centreId,basicEntity{basicEntityId}}}}';
    axios(CONFIG.MAIN_URL+'/graphql',{
    method: 'post',
    headers: { 
        'Content-Type': 'text/plain',
      },
    data : data,  
    })
    .then(function (response) {
        var entityList = response.data.data.allEntityList.entityDataList;
        console.log("All entity List");
        console.log(entityList);
        var tmpEntityData = [];
        entityList.forEach((item, index)=>{
            if (item.isActive && item.centreId === centerId) {  
                var be = item.basicEntity
                var arrayData = {}
                arrayData["entityId"] = item.entityId;
                arrayData["entityName"] = item.entityName;
                tmpEntityData.push(arrayData);
            }
        })
        console.log(tmpEntityData);
        setCentreEntityData(tmpEntityData);
    })
    .catch(function (error) {
        console.log("Entity Loding error" + error);
    });
}



const [rows, setRows] = useState([]);
function rowSettingsStr(arraData, collectionTitle, sendComposeArr){
    var count = 1;
    var tmpArrayStr = "";
    setRows([]);
    tmpArrayStr += "[";
    arraData.forEach((item)=>{
        var tmpObj = {};
        tmpObj["id"] = count;
        tmpObj["composeId"] = item.id;
        var foundComposeId = false;
        sendComposeArr.forEach((data)=>{
          if (data.composeId === item.id){
            foundComposeId = true;
          }
        })
        tmpObj["isSendItem"] = foundComposeId;
        tmpObj["collectionUniqueName"] = item.collectionUniqueName;
        tmpObj["title"] = collectionTitle;
        tmpObj["createdDate"] = item.createdDate;
        //userDetail["transactionContentValueWithOutHTML"] = val.transactionContentValue.replace(/(<([^>]+)>)/ig, "").substring(0, 30) + "... More";
        tmpObj["mailBodyHtml"] = item.mail.mailBody;
        var tmpMailBody = item.mail.mailBody.replace(/(<([^>]+)>)/ig, "");
        tmpMailBody = tmpMailBody.replaceAll('&nbsp;', " ");
        tmpObj["mailBody"] = tmpMailBody;
        tmpObj["subject"] = item.mail.subject;
        var entityArr = item.toEntity;
        var count1 = 1;
        var tmpArrayStr1 = "[";
        entityArr.forEach((data)=>{
          var tmpObj1 = {};
          tmpObj1["id"] = count1;
          tmpObj1["entityId"] = data.entityId;
          tmpObj1["entityName"] = data.entityName;
          tmpObj1["composeId"] = item.id;
          var sendJson1 = JSON.stringify(tmpObj1);
          if (count1 === 1){
            tmpArrayStr1 += sendJson1;
          }else{
            tmpArrayStr1 += "," + sendJson1;
          }
          count1 ++;
        })
        tmpArrayStr1 += "]";
        var obj = JSON.parse(tmpArrayStr1)
        tmpObj["entities"] = obj;

        var sendJson = JSON.stringify(tmpObj);
        if (count === 1){
          tmpArrayStr += sendJson;
        }else{
          tmpArrayStr += "," + sendJson;
        }
        count ++;
      
    })
    tmpArrayStr += "]";
    console.log("JSON.parse(tmpArrayStr)");
    console.log(JSON.parse(tmpArrayStr));
    setRows(JSON.parse(tmpArrayStr));
}

const [toEntityRows, setToEntityRows] = useState([]);
function rowSettingsToEntityStr(arraData) {
  var count = 1;
  var tmpArrayStr = "";
  setToEntityRows([]);
  tmpArrayStr += "[";
  arraData.forEach((item)=>{
    console.log("+++++++++++333333++++++++++++++item++++++++++++++++++++++++++++");
    console.log(item);

      var entityArr = item.toEntity;
      entityArr.forEach((data)=>{
        var entityUsersArr = data.users;
        var eName  = data.entityName;
        entityUsersArr.forEach((data1)=>{
          var tmpObj = {};
          tmpObj["id"] = count;
          tmpObj["composeId"] = item.id;
          tmpObj["userId"] = data1.userId;
          tmpObj["userName"] = data1.userName + ' ' + data1.lastName;
          tmpObj["entityName"] = eName;
          tmpObj["userEmail"] = data1.userEmail;
          var sendJson = JSON.stringify(tmpObj);
          if (count === 1){
            tmpArrayStr += sendJson;
          }else{
            tmpArrayStr += "," + sendJson;
          }
          count ++;
        })
      })
  })
  tmpArrayStr += "]";
  console.log("++++++++++++++++++++JSON.parse(tmpArrayStr++++++++++++++++++++)");
  console.log(JSON.parse(tmpArrayStr));
  setToEntityRows(JSON.parse(tmpArrayStr));
}

const renderTextId = (params) => {
    return (
        <Tooltip title={params.row.id}>
        <span className={classes.fontZise10}>{params.row.id}</span>        
        </Tooltip>
    )
  }  
const renderTextCollectionUniqueName = (params) => {
    return (
        <Tooltip title={params.row.collectionUniqueName}>
        <span className={classes.fontZise10}>{params.row.collectionUniqueName}</span>        
        </Tooltip>
    )
  }
  const renderTextCreatedDate = (params) => {
    return (
        <Tooltip title={params.row.createdDate}>
        <span className={classes.fontZise10}>{params.row.createdDate}</span>        
        </Tooltip>
    )
  }
  
  const renderTextTitle = (params) => {
    return (
      
        <Tooltip title={params.row.title}>
        <span className={classes.fontZise10}>{params.row.title}</span>        
        </Tooltip>
        
    )
  }
  
  
  const renderTextSubject = (params) => {
    return (
        <Tooltip title={params.row.subject}>
        <span className={classes.fontZise10}>{params.row.subject}</span>        
        </Tooltip>
    )
  }
  var myWin = null;
  function setShowMailBody(mailBody){
    if (myWin != null) {
      myWin.close();
    }
    var left = (900 - 250) / 2;
    var top = (800 - 350) / 4;
    myWin = window.open("", "", "width=450,height=350,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,top=" + top + ", left=" + left);
    myWin.document.write(mailBody);
  }
  
  const renderTextMailBody = (params) => {
    return (
        <Tooltip title={params.row.mailBody}>
        <span 
          className={classes.fontZise10MosusePonter}
          onClick={() => {
            setShowMailBody(params.row.mailBodyHtml)
          }}
          >
            {params.row.mailBody}
        </span>        
        </Tooltip>
    )
  }

  const renderEntityButton = (params) => {
    return (
        <>
         <strong>
            <Button
                id={'btn'+params.row.id}
                variant="contained"
                color="primary"
                size="small"
                className={classes.fontZise9AndBlue}
                onClick={() => {
                    setShowEntities(params.row.id, params.row.composeId)
                }}
            >
                Show Entities
            </Button>
        </strong>

        </>
    )
}

const renderEvalResponseButton = (params) => {
  return (
    <>
     {params.row.isSendItem?
     <strong>
        <Button
            id={'btn'+params.row.id}
            variant="contained"
            color="primary"
            size="small"
            className={classes.fontZise9AndRed}
            onClick={() => {
              setOpenCollectionDataFuction(params.row.id, params.row.composeId)
            }}            
        >
            Evaluate
        </Button>
    </strong>
    :
    ''
    }

    </>
)

}

const renderSendMailButton = (params) => {
  return (
      <>
       {params.row.isSendItem?
       <strong>
          <Button
              id={'btn'+params.row.id}
              variant="contained"
              color="primary"
              size="small"
              className={classes.fontZise9AndGreen}
              onClick={() => {
                setShowAlreadySendMails(params.row.id, params.row.composeId, params.row.collectionUniqueName)
              }}
          >
              Already Sent
          </Button>
      </strong>
      :
      <strong>
        <Button
            id={'btn'+params.row.id}
            variant="contained"
            color="primary"
            size="small"
            className={classes.fontZise9AndRed}
            onClick={() => {
                setShowEntityUsersForSendMails(params.row.id, params.row.composeId, params.row.collectionUniqueName)
            }}
        >
            Send Mails
        </Button>
      </strong>
       }

      </>
  )
}



const [openEntityData, setOpenEntityData] = useState(false);
const [openEntityDataTopPos, setOpenEntityDataTopPos] = useState(0);
const [toEntityData, setToEntityData] = useState([]);

const [entityRows, setEntityRows] = useState([]);
function setShowEntities(no, composeId){
    var btn = document.getElementById("btn"+no);
    var posBtn = btn.offsetTop;
    var tableEndId = document.getElementById("tableEndId");
    var pos = tableEndId.offsetTop;
    var entityDataPos = ((posBtn + 215) - pos); 
    setOpenEntityDataTopPos(entityDataPos);
    rows.forEach((item)=>{
      if (item.composeId === composeId){
        console.log(item.entities);
        setToEntityData(item.entities);
        setEntityRows(item.entities);
      }
    })
    setOpenEntityData(true);
}

  
    const columns = [
      { field: 'id', headerName: 'No', renderCell: renderTextId, resizable: true, width: 10 },
      { field: 'composeId', headerName: 'composeId', width: 15, hide: true },
      { field: 'isSendItem', headerName: 'isSendItem', width: 15, hide: true },
      { field: 'title', headerName: 'Collection Title', renderCell: renderTextTitle, resizable: true, cellClassName: 'super-app-theme--cell', width: 120 },
      { field: 'collectionUniqueName', headerName: 'User Collection', renderCell: renderTextCollectionUniqueName, width: 110, resizable: true, cellClassName: 'super-app-theme--cell', hide: true},
      { field: 'createdDate', headerName: 'Created Date', renderCell: renderTextCreatedDate, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
      { field: 'subject', headerName: 'Mail Subject', renderCell: renderTextSubject, resizable: true, width: 150 },
      { field: 'mailBodyHtml', headerName: 'No', width: 15, hide: true },
      { 
        field: 'mailBody', 
        headerName: 'Mail Body', 
        renderCell: renderTextMailBody, 
        disableClickEventBubbling: true,
        resizable: true, 
        width: 270 
      },
      {
        field: 'action',
        headerName: 'Entities',
        sortable: false,
        renderCell: renderEntityButton,
        disableClickEventBubbling: true,
        width: 150,
      },
      {
        field: 'action1',
        headerName: 'Mail Status',
        sortable: false,
        renderCell: renderSendMailButton,
        disableClickEventBubbling: true,
        width: 150,
      },
      {
        field: 'action2',
        headerName: 'Response Evaluation',
        sortable: false,
        renderCell: renderEvalResponseButton,
        disableClickEventBubbling: true,
        width: 150,
      }
    
    ];
  
    const renderTextEntityName = (params) => {
      return (
        
          <Tooltip title={params.row.entityName}>
          <span className={classes.fontZise10}>{params.row.entityName}</span>        
          </Tooltip>
          
      )
    }

    const [entityQuestionOpen, setEntityQuestionOpen] = React.useState(false);
    const [entityQuestionScroll, setEntityQuestionScroll] = React.useState('paper');
    

    const entityQuestionHandleClickClose = () => {
        setEntityQuestionOpen(false);
    };


    const [selectedEntityName, setSelectedEntityName] = React.useState('');
    const [allQuestionForSelectedEntity, setAllQuestionForSelectedEntity] = React.useState([]);
    

    function setShowEntityQuestions(no, composeId,entityId, entityName){
      setAllQuestionForSelectedEntity([]);
      var obj = {} 
      obj["id"] = composeId;
      var objArr = [];
      var obj1 = {}
      obj1["entityDataId"] = entityId;
      objArr.push(obj1);
      obj["toEntity"] = objArr;
      var sendJson = JSON.stringify(obj);

      console.log(sendJson);
      console.log(CONFIG.MAIN_URL+'/entity-question/get-question');

      axios(CONFIG.MAIN_URL+'/entity-question/get-question',{
          method: 'post',
          headers: { 
            'Content-Type': 'application/json',    
          },  
          data:sendJson
      })
      .then(res =>  {
          console.log("++++++++++++++Returns Questions for the Entity ++++++++++++++++++++");
          console.log(res.data);
          setAllQuestionForSelectedEntity(res.data);
          setSelectedEntityName("Questions for entity - " + entityName);
          setEntityQuestionOpen(true);
          setEntityQuestionScroll('paper');
      })
      .catch(function (error) {
          console.log(error);
      });
    }

    const renderEntityQuestionButton = (params) => {
      return (
          <>
           <strong>
              <Button
                  id={'btn'+params.row.id}
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.fontZise9AndGreen}
                  onClick={() => {
                      setShowEntityQuestions(params.row.id, params.row.composeId, params.row.entityId, params.row.entityName)
                  }}
              >
                  Show Questions
              </Button>
          </strong>
  
          </>
      )
  }  
    
    const entityColumns = [
      { field: 'id', headerName: 'No', renderCell: renderTextId, resizable: true, width: 10, hide: true },
      { field: 'composeId', headerName: 'composeId', width: 15, hide: true },
      { field: 'entityId', headerName: 'entityId', width: 15, hide: true },
      { field: 'entityName', headerName: 'Entity Name', renderCell: renderTextEntityName, resizable: true, cellClassName: 'super-app-theme--cell', width: 300 },
      {
        field: 'action',
        headerName: 'Entity Related Questions',
        sortable: false,
        renderCell: renderEntityQuestionButton,
        disableClickEventBubbling: true,
        width: 200,
      }
    
    ];

    /** Show Already Send Mail user list for a compose Id */
    
    const [mailReceivedUsersOpen, setMailReceivedUsersOpen] = React.useState(false);
    const [mailReceivedUsersScroll, setMailReceivedUsersScroll] = React.useState('paper');

    const mailReceivedUsersHandleClickClose = () => {
      setMailReceivedUsersOpen(false);
    };

    const [mailReceivedUsersRows, setMailReceivedUsersRows] = React.useState([]);

    function setShowAlreadySendMails(id, composeId, collectionUniqueName){
      axios(CONFIG.MAIN_URL+'/user-email-data/filter?composeId='+composeId,{
        method: 'get',
      })
      .then(function (response) {
        console.log("Get Emailed user Data in composeId");
        console.log((response.data));
        var count = 1;
        var tmpArray = [];
        setMailReceivedUsersRows([]);
        response.data.forEach((item)=>{
          var tmpObj = {};
          tmpObj["id"] = count;
          tmpObj["collectionUniqueName"] = item.collectionUniqueName;
          tmpObj["composeId"] = item.composeId;
          tmpObj["emailDataId"] = item.emailDataId;
          tmpObj["createdDate"] = item.createdDate;
          tmpObj["receivedByName"] = item.receivedBy.userName + ' ' + item.receivedBy.lastName;
          tmpObj["receivedByEmail"] = item.receivedBy.userEmail;
          tmpObj["mailStatus"] = item.status;
          tmpArray.push(tmpObj);
          setMailReceivedUsersRows(tmpArray);
          count ++;
        })
        setMailReceivedUsersOpen(true);
        setMailReceivedUsersScroll('paper');

      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const renderTextFieldIdMR = (params) => {
      return (
          <Tooltip title={params.row.id}>
          <span className={classes.fontZise10}>{params.row.id}</span>        
          </Tooltip>
      )
    }
    const renderTextReceivedByName = (params) => {
      return (
          <Tooltip title={params.row.receivedByName}>
          <span className={classes.fontZise10}>{params.row.receivedByName}</span>        
          </Tooltip>
      )
    }
    const renderTextReceivedByEmail = (params) => {
      return (
          <Tooltip title={params.row.receivedByEmail}>
          <span className={classes.fontZise10}>{params.row.receivedByEmail}</span>        
          </Tooltip>
      )
    }
    const renderTextMailStatus = (params) => {
      return (
          <Tooltip title={params.row.mailStatus}>
          <span className={classes.fontZise10}>{params.row.mailStatus}</span>        
          </Tooltip>
      )
    }
    const renderTextCreatedDateRU = (params) => {
      return (
          <Tooltip title={params.row.createdDate}>
          <span className={classes.fontZise10}>{params.row.createdDate}</span>        
          </Tooltip>
      )
    }


    const columnsMailReceivedUsers = [
      { field: 'id', headerName: 'User Number', renderCell: renderTextFieldIdMR, resizable: true, width: 100 },
      { field: 'receivedByName', headerName: 'Received User Name', renderCell: renderTextReceivedByName, resizable: true, cellClassName: 'super-app-theme--cell', width: 200 },
      { field: 'receivedByEmail', headerName: 'Received User Email', renderCell: renderTextReceivedByEmail, width: 210, resizable: true, cellClassName: 'super-app-theme--cell'},
      { field: 'mailStatus', headerName: 'Mail Status', renderCell: renderTextMailStatus, resizable: true, cellClassName: 'super-app-theme--cell', width: 120 },
      { field: 'createdDate', headerName: 'Created Date', renderCell: renderTextCreatedDateRU, resizable: true, cellClassName: 'super-app-theme--cell', width: 150 }
    ];




    /** End of Show Already Send Mail user list for a compose Id */



    
    
    const [entityUserOpen, setEntityUserOpen] = React.useState(false);
    const [entityUserScroll, setEntityUserScroll] = React.useState('paper');

    const entityUserHandleClickClose = () => {
      setEntityUserOpen(false);
    };


    
    const [selectedComposeId, setSelectedComposeId] = React.useState('');
    const [selectedCollectionUniqueName, setSelectedCollectionUniqueName] = React.useState('');
    const [selectedUserId, setSelectedUserId] = React.useState('');
    const [selectedToEntityRows, setSelectedToEntityRows] = useState([]);

    function setShowEntityUsersForSendMails(id, composeId, collectionUniqueName){
      setSelectedComposeId(composeId);
      setSelectedCollectionUniqueName(collectionUniqueName);
      setSelectedToEntityRows([]);
      var arr = [];
      console.log("++++++++++++++++++toEntityRows+++++++++++++++++");
      console.log(toEntityRows);

      toEntityRows.forEach((item)=>{
        if (item.composeId === composeId){
          arr.push(item);
        }
      })
      setSelectedToEntityRows(arr);


      setEntityUserOpen(true);
      setEntityUserScroll('paper');
    }
 
    
    const emailFromUserhandleChange = (event) => {
      var selectValue = event.target.value;
      //alert(selectValue);
      setSelectedUserId(selectValue);
    };

    function confirmSendingMailsFunction(){
      if (selectedUserId === ''){
        alert('Please select a From mail user');
      }else{
        
        var obj = {};
        obj["composeId"] = selectedComposeId;
        obj["collectionUniqueName"] = selectedCollectionUniqueName;
        
        allAdminUsers.forEach((item)=>{
          if (item.userId === selectedUserId ){
            var obj1 = {};
            obj1["userName"] = item.userName + ' ' + item.lastName;
            obj1["userId"] = item.userId;
            obj1["entityId"] = "ADMIN";
            obj1["userEmail"] = item.email;
            obj["createdBy"] = obj1;
          }
        });
        var sendJson = JSON.stringify(obj);
        console.log(sendJson);
        console.log(CONFIG.MAIN_URL+'/mail-send/create');
        setWaitingOpen(true);
        axios(CONFIG.MAIN_URL+'/mail-send/create',{
            method: 'post',
            headers: { 
              'Content-Type': 'application/json',    
            },  
            data:sendJson
        })
        .then(res =>  {
            console.log("++++++++++++++Return Mail sending from Srevre ++++++++++++++++++++");
            console.log(res.data);
            setWaitingOpen(false);
            alert('Successfully Sent emails to Users' );
            getMailComposeData(selectedCollectionUniqueName);
            setEntityUserOpen(false);
        })
        .catch(function (error) {
          setWaitingOpen(false);
          console.log(error);
        });
        
      }
    }

    /** ++++++++++++++++++Start User Response Evaluation++++++++++++++  */
    
    /** Open Risk hirachy for select evaluation  */
    const [riskEvalTypeOpen, setRiskEvalTypeOpen] = React.useState(false);
    const [riskEvalTypeScroll, setRiskEvalTypeScroll] = React.useState('paper');
    const [selectedRiskEvalArray, setSelectedRiskEvalArray] = React.useState([]);
    const [rsType, setRsTtpe] = useState([]);
    const [riskTypeData, setRiskTypeData] = useState([])
    const [selectedCollectionEvaluateId, setSelectedCollectionEvaluateId] = useState('');
    const [selectedCollectionEvaluateUniqueName, setSelectedCollectionEvaluateUniqueName] = useState('');
    const [selectedCollectionEvaluateTitle, setSelectedCollectionEvaluateTitle] = useState('');

    const [quesData, setQuesData] = useState([]);

    const [selectedEvalNo, setSelectedEvalNo] = React.useState(0);
    const [selectedEvalComposeId, setSelectedEvalComposeId] = React.useState('');

    function setOpenCollectionDataFuction(id, composeId){
      
      setSelectedEvalNo(id);
      setSelectedEvalComposeId(composeId);
      setRiskTypeData([]);
      var tmpRiskArr = [];
      if (collectionData.risks != undefined && collectionData.risks != null && collectionData.risks.length > 0){
        tmpRiskArr = collectionData.risks; 
      }
      setSelectedCollectionEvaluateUniqueName(collectionData.collectionUniqueName);
      setRiskTypeData(tmpRiskArr);
      getAllQuestionsForCollection(tmpRiskArr);

      setSelectedCollectionEvaluateId(collectionData.id);
      setSelectedCollectionEvaluateTitle(collectionData.title);
      
      setRiskEvalTypeOpen(true);
      setRiskEvalTypeScroll('paper');
      setSelectedRiskEvalArray([]);
    }
    const riskEvalTypeHandleClickClose = () => {
      setRiskEvalTypeOpen(false);
      setSelectedRiskEvalArray([]);
    };

    const [hierarchyId, setHierarchyId] = React.useState();
    const handleChangeHierarchy = (event) => {
      var hierarchyId = event.target.value;
      setHierarchyId(event.target.value)
      riskTypeData.forEach((item,index)=>{
          if (item.collectionRiskId === hierarchyId) {  
              setRsTtpe(item.riskTypes);
          }
      })
    };


    /** Get all question from the collection and put in to attay */
    var allQuesArray = [];
    function getAllQuestionsForCollection(riskArray){
      allQuesArray = [];
      riskArray.forEach((item)=>{
        if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
          item.riskTypes.forEach((item1)=>{
            if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
              item1.questions.forEach((item2)=>{
                allQuesArray.push(item2);
              })  
            }
            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
              getAllQuestionsInSubRisksForCollection(item1.subRisks);
            }
          });
        }
        });
        if (allQuesArray.length > 0){
          //var quesArray = allQuesArray.sort (compare);
          setQuesData(allQuesArray);
        }
    }

    function getAllQuestionsInSubRisksForCollection(riskArray){
      riskArray.forEach((item)=>{
        if (item.questions != undefined && item.questions != null && item.questions.length > 0){
          item.questions.forEach((item1)=>{
            allQuesArray.push(item1);
          })  
        }
        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
          getAllQuestionsInSubRisksForCollection(item.subRisks);
        }
      });  
    }
    /** End of Get all question from the collection and put in to attay */

    function checkSelected(id, name, closeIds,openIds){
      if ( document.getElementById("chkRiskType" + id).checked) {
          var tmpArray = {};
          tmpArray["collectionRiskTypeId"] = id;
          var ar = [];
          closeIds.forEach((data)=>{
              ar.push(data);
          });    
          openIds.forEach((data)=>{
              ar.push(data);
          }); 
          tmpArray["collectionQuestionIds"] = ar;
          tmpArray["name"] = name;
          selectedRiskEvalArray.push(tmpArray);
          setSelectedRiskEvalArray(selectedRiskEvalArray);
      }else{
          selectedRiskEvalArray.forEach((data, index)=>{
              if (data.collectionRiskTypeId === id) { 
                  selectedRiskEvalArray.splice(index, 1);  
                  setSelectedRiskEvalArray(selectedRiskEvalArray);
              }
          })
      }    
    }
    
    function getQuestionIds(comment,type){
      var count = 0;
      var ids = [];
      if (comment.questions != undefined && comment.questions!= null){
          if (type === 1){
              if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                  comment.questions.forEach((quesItem)=>{
                      if (quesItem.questionType === "Closeended"){
                          ids.push(quesItem.questionId);
                      }
                  })  
              }
          }
          if (type === 2){
              if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
                  comment.questions.forEach((quesItem)=>{
                      if (quesItem.questionType === "Openended"){
                          ids.push(quesItem.questionId);
                      }
                  })  
              }
          }
      }else{
          ids = [];
      }
      return ids;
    }

    function getQuestionIdsWithSubRisks(comment,type){
      var count = 0;
      var riskIds = "";
      var ids = [];
      if (type === 1){
          if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
              comment.questions.forEach((quesItem)=>{
                  if (quesItem.questionType === "Closeended"){
                      ids.push(quesItem.questionId);
                  }
              })  
          }
      }
      if (type === 2){
          if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0){
              comment.questions.forEach((quesItem)=>{
                  if (quesItem.questionType === "Openended"){
                      ids.push(quesItem.questionId);
                  }
              })  
          }
      }
      if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
          return getQuestionSubIdsWithSubRisks(comment.subRisks,type,ids);
      }else{
          return ids;
      }
    }
    function getQuestionSubIdsWithSubRisks(comment,type,ids ){
      comment.forEach((data,index)=>{
          if (type === 1){
              if (data.questions != undefined && data.questions!= null){
                  if (data.questions.closeEndedQuestionIds != undefined && data.questions.closeEndedQuestionIds != null && data.questions.closeEndedQuestionIds.length > 0){
                      data.questions.closeEndedQuestionIds.forEach((item,index)=>{
                          ids.push(item.questionId);
                      });
                  }
              }
          }
          if (type === 2){
              if (data.questions != undefined && data.questions!= null){
                  if (data.questions.openEdndedQuestionIds != undefined && data.questions.openEdndedQuestionIds != null && data.questions.openEdndedQuestionIds.length > 0){
                      data.questions.openEdndedQuestionIds.forEach((item,index)=>{
                          ids.push(item.questionId);
                      });
                  }
              }
          }
          if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0){
              getQuestionSubIdsWithSubRisks(data.subRisks,type,ids);
          }
      });
      return ids;
      
    }

    function RiskEval({ comment }) {
      var closeEndedQuestionIds = getQuestionIds(comment,1);
      var openEndedQuestionIds = getQuestionIds(comment,2);
      var closeEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,1);
      var openEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment,2);

      const [show, setShow] = React.useState(false)
      const nestedComments = (comment.subRisks || []).map(comment => {
          return <RiskEval key={comment.collectionRiskTypeId} comment={comment} type="child" />
      })

      return (
        <div style={{"marginLeft": "15px", "marginTop": "10px"}}>
           <div style={{display:'flex',textAlign:'center'}}> 
              <Button 
                  style={{width:'0px', height:'0px', marginTop:'14px'}} 
                  onClick={()=>setShow(!show)} 
                  startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  >
              </Button>
              
              <span style={{marginTop:'-7px !important'}} >

              <FormControlLabel control={<Checkbox onClick={()=>checkSelected(comment.collectionRiskTypeId,comment.name,show?closeEndedQuestionIds:closeEndedQuestionIdsWithSub,show?openEndedQuestionIds:openEndedQuestionIdsWithSub )} id={"chkRiskType" + comment.collectionRiskTypeId}/>} 
              label={show?comment.name+ (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' (' )   + (closeEndedQuestionIds.length > 0 ?' C : '
              + closeEndedQuestionIds.length:'')+ (openEndedQuestionIds.length > 0 
                 ?closeEndedQuestionIds.length > 0 ?', O : ' + openEndedQuestionIds.length:' O : ' + openEndedQuestionIds.length:'') + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ?'' : ' )' )
               :
               comment.name+ (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' (' )   + (closeEndedQuestionIdsWithSub.length > 0 ?' C : '
              + closeEndedQuestionIdsWithSub.length:'')+ (openEndedQuestionIdsWithSub.length > 0 
                 ?closeEndedQuestionIdsWithSub.length > 0 ?', O : ' + openEndedQuestionIdsWithSub.length:' O : ' + openEndedQuestionIdsWithSub.length:'') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ?'' : ' )' )   
              }
              
              />
               </span>    
           </div>
          {
              show?
                  <>
                      {nestedComments}
                  </>
              :null
          }
        </div>
      )
    }
    
    const [userResposeEvalOpen, setUserResposeEvalOpen] = React.useState(false);
    const [openUserResposeEvalTopPos, setOpenUserResposeEvalTopPos] = useState(0);
    const [evaluateBtnPos, setEvaluateBtnPos] = useState(0);

    const [userResposeEvalRows, setUserResposeEvalRows] = React.useState([]);
    const [selectedMainCollection, setSelectedMainCollection] = React.useState([]);
    



/***************************************XXXXXXXXXXXXXXXXXX************************************************** */

function RiskScrumb( riskName, collectionRiskId, riskTypeData ) {
  var rsTypeArr = [];
  riskTypeData.forEach((data)=>{
      if (data.collectionRiskId === collectionRiskId){
          rsTypeArr = data.riskTypes;
      }
  });  
  var riskScrumbPath  = "";
  rsTypeArr.forEach((item)=>{
      var pathStr = "";
      pathStr = getRiskScrumb(item,riskName);
      if (pathStr != undefined && pathStr != ""){
          riskScrumbPath = pathStr;
          return true;
      }
  });  
  return riskScrumbPath;
}

// *Risk Scrumb****
function getRiskScrumb(comment,riskName){
var riskScrumbPath = "";
if (riskName === comment.name){
  riskScrumbPath =  riskName;
}else{
  if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0){
      var riskPath = comment.name;
      var subRiskPath =  getSubRiskScrumb(comment.subRisks,riskName);
      if (subRiskPath != ""){
          riskScrumbPath = riskPath + subRiskPath;
      }
  }
}
return riskScrumbPath;
}
function getSubRiskScrumb(subData,riskName){
var tmpSubPath = "";
subData.forEach((item,index)=>{
  if (item.name === riskName){
      tmpSubPath += " --> " + item.name;
      return true; 
  }else{
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
          var tmpSubPath1 = " --> " + item.name;
          var tmpSubSubPath1 = getSubRiskScrumb(item.subRisks,riskName);
          if (tmpSubSubPath1 != ""){
              tmpSubPath += tmpSubPath1 + tmpSubSubPath1;
          }
      }    
  }
});
return tmpSubPath;
}    




    function setUserResponseEvaluation(){
      //id, composeId, collectionUniqueName

      setUserResposeEvalOpen(false);

      var takenFlag = true;
      var takenFlag = false;
      var tmpRiskData = [];
      var headerMsg = "";
      if (document.getElementById('chkRiskType0').checked){
          takenFlag = true;
          quesData.forEach((data)=>{
              tmpRiskData.push(data);
              takenFlag = false;
              headerMsg = "All";
          });
      }else{
          var k = 0;
          selectedRiskEvalArray.forEach((item)=>{
              if (document.getElementById('chkRiskType' + item.collectionRiskTypeId) != null && document.getElementById('chkRiskType' + item.collectionRiskTypeId).checked){
                  takenFlag = true;
                  item.collectionQuestionIds.forEach((quesId)=>{
                      quesData.forEach((data)=>{
                          if (data.questionId === quesId){
                              tmpRiskData.push(data);
                              takenFlag = false;
                          }
                      });
                  });
                  if (k === 0){
                      headerMsg =  item.name;
                  }else{
                      headerMsg += ", " + item.name;
                  }
                  k ++;
              }
          });
      }
      if (takenFlag){
          if (takenFlag){
              alert("Please note that you don't have any Questioners for the selected Risk Types")
          }else{
              alert("Please select atleast one Risk Type for evaluation")
          }    
      }else{

        setWaitingOpen(true);
        var btn = document.getElementById("btn"+selectedEvalNo);
        var posBtn = btn.offsetTop;
        var tableEndId = document.getElementById("tableEndId");
        var pos = tableEndId.offsetTop;
        var entityDataPos = ((posBtn + 215) - pos); 
        setOpenUserResposeEvalTopPos(entityDataPos);

        axios(CONFIG.MAIN_URL+'/user-question-response/filter?composeId='+ selectedEvalComposeId,{
          method: 'get',
        })
        .then(function (response) {
          console.log("Return user-question-response in composeId");
          console.log(response.data);
          var userQuestionResponseArr = response.data

          axios(CONFIG.MAIN_URL+'/user-answer-question/filter?composeId='+ selectedEvalComposeId,{
            method: 'get',
          })
          .then(function (res) {
            console.log("Return User Response in composeId");
            console.log(res.data);
            
            var arr = res.data
            arr = arr.sort (compareQuestion);
            console.log(arr);
            var mainCollection = [];
            var tmpQuestionId = '';
            var tmpQuestionName = '';
            var subCollection = [];
            arr.forEach((item, index)=>{
              if (index === 0){
                userQuestionResponseArr.forEach((data)=>{
                  if (data.id === item.userResponseId) {
                    item["createdBy"] = data.createdBy;
                  }
                })  
                subCollection.push(item);

                tmpQuestionId = item.question.questionId;
                tmpQuestionName = item.question.question;
                if (index === (arr.length-1)){
                  var obj = {};
                  obj["questionId"] = tmpQuestionId;
                  obj["question"] = tmpQuestionName;
                  obj["responseData"] = subCollection;
                  mainCollection.push(obj);
                }
              }else{
                if (tmpQuestionId === item.question.questionId){
                  userQuestionResponseArr.forEach((data)=>{
                    if (data.id === item.userResponseId) {
                      item["createdBy"] = data.createdBy;
                    }
                  })  
                  subCollection.push(item);
                  if (index === (arr.length-1)){
                    var obj = {};
                    obj["questionId"] = tmpQuestionId;
                    obj["question"] = tmpQuestionName;
                    obj["responseData"] = subCollection;
                    mainCollection.push(obj);
                  }
                }else{
                  var obj = {};
                  obj["questionId"] = tmpQuestionId;
                  obj["question"] = tmpQuestionName;
                  obj["responseData"] = subCollection;
                  mainCollection.push(obj);
                  subCollection = [];
                  tmpQuestionId = item.question.questionId;
                  tmpQuestionName = item.question.question;
                  userQuestionResponseArr.forEach((data)=>{
                    if (data.id === item.userResponseId) {
                      item["createdBy"] = data.createdBy;
                    }
                  })  
                  subCollection.push(item);
                  if (index === (arr.length-1)){
                    var obj = {};
                    obj["questionId"] = tmpQuestionId;
                    obj["question"] = tmpQuestionName;
                    obj["responseData"] = subCollection;
                    mainCollection.push(obj);
                  }
                }
              }
            });
            console.log(mainCollection);
            setSelectedMainCollection(mainCollection)

            console.log("+++++++++++++++++++++++++mainCollection++++++++++++++++++++++++++");
            console.log(mainCollection);
    
            var count = 1;
            var tmpArray = [];
            setUserResposeEvalRows([]);
            mainCollection.forEach((item)=>{
              var tmpObj = {};
              var canTake = false;
              tmpRiskData.forEach((data)=>{
                if (item.questionId === data.questionId) {
                  canTake = true;
                } 
              })  
              if (canTake){
                tmpObj["id"] = count;
                tmpObj["questionId"] = item.questionId;
                tmpObj["question"] = item.question;
                tmpObj["userResponseId"] = item.userResponseId;
                
                //tmpObj["collectionId"] = selectedCollectionEvaluateId;
                //tmpObj["collectionUniqueName"] = selectedCollectionEvaluateUniqueName;
                
                var questionData = item.responseData[0].question;
                tmpObj["noOfUsers"] = item.responseData.length;
                var riskScrubStr = RiskScrumb( item.responseData[0].question.riskTypes.name, item.responseData[0].question.riskTypes.collectionRiskId, riskTypeData )
                tmpObj["riskName"] = riskScrubStr;
                //tmpObj["riskName"] = questionData.riskTypes.name;
      
                tmpObj["objectiveName"] = questionData.objective.name;
                tmpObj["componentName"] = questionData.component.name;
                tmpObj["principalName"] = questionData.principle.name;
                centreEntityData.forEach((data)=>{
                  if (questionData.entity.entityId.toString() === data.entityId) {
                    tmpObj["entityName"] = data.entityName;
                  }
                })
                tmpObj["responseData"] = item.responseData;
                tmpObj["questionType"] = questionData.questionType;
                //tmpObj["questionScore"] = item.questionScore;
                tmpArray.push(tmpObj);
                console.log("++++++++++++setUserResposeEvalRows++++++++++++");
                console.log(tmpArray);
                setUserResposeEvalRows(tmpArray);
                count ++;
              }
            })

            setWaitingOpen(false);
            if (tmpArray.length > 0){
              setRiskEvalTypeOpen(false);
              setSelectedRiskEvalArray([]);
              setUserResposeEvalOpen(true);
            }else{
              if (mainCollection.length > 0){
                alert("Please note that you don't have any Questioners for the selected Risk Types for the user responded Questioners")
              }else{
                alert("No users have responded yet")
              }  
            }
    
    
          })
          .catch(function (error) {
            console.log(error);
            setWaitingOpen(false);
          });

        })
        .catch(function (error) {
          console.log(error);
          setWaitingOpen(false);
        });
      }
    }

    const renderTextURId = (params) => {
      return (
          <span className={classes.fontZise10}>{params.row.id}</span>        
      )
    }
    const renderTextURObjectiveName = (params) => {
      return (
          <Tooltip title={params.row.objectiveName}>
          <span className={classes.fontZise10}>{params.row.objectiveName}</span>        
          </Tooltip>
      )
    }
    const renderTextURComponentName = (params) => {
      return (
          <Tooltip title={params.row.componentName}>
          <span className={classes.fontZise10}>{params.row.componentName}</span>        
          </Tooltip>
      )
    }
    const renderTextURPrincipalName = (params) => {
      return (
          <Tooltip title={params.row.principalName}>
          <span className={classes.fontZise10}>{params.row.principalName}</span>        
          </Tooltip>
      )
    }
    const renderTextURQuestion = (params) => {
      return (
          <Tooltip title={params.row.question}>
          <span className={classes.fontZise10}>{params.row.question}</span>        
          </Tooltip>
      )
    }
    const renderTextUREntity = (params) => {
      return (
          <Tooltip title={params.row.entityName}>
          <span className={classes.fontZise10}>{params.row.entityName}</span>        
          </Tooltip>
      )
    }
    
    const renderTextURQuestionType = (params) => {
      return (
          <span className={classes.fontZise10}>{params.row.questionType}</span>        
      )
    }
    const renderTextQuestionScore = (params) => {
      var no = params.row.id;
      var ques = params.row.question;
      var maxVal = 0;
      var maxName = ""
      var scoreStr = "";
      var highScoreStr = "";
      var tableScoreStr = "";

      var tmpArray = [];

      userResposeEvalRows.forEach((data, index)=>{
        if (index === (no-1)) {
          tmpArray = [];
          data.responseData.forEach((item)=>{
            var quesObj = item.question;
            quesObj["createdBy"] = item.createdBy;
            tmpArray.push(quesObj);
          })

          var count = 1;
          var tmpRowArray = [];
          var tmpColArray = [];
          var tmpDataArray = [];
          tmpArray.forEach((item,index)=>{
            if (item.questionType === "Closeended") {
              if (item.answers.answerMethod.name != "" && item.answers.answerList.length > 0 ) {
                var tmpAnsName = '';
                var tmpRiskLevel = '';
                var tmpTotalResValue = 0;
                var count1 = 1;
                item.answers.answerList.forEach((ansData,index2)=>{
                  if (index === 0){
                    /** To get answernames, resk level and taltal of user responsed */
                    tmpAnsName = ansData.answerName;
                    tmpRiskLevel = ansData.riskLevel;
                    tmpTotalResValue = 0;
                    if (ansData.expectedAnswerName === "Yes" || ansData.expectedAnswerName === "checked"){
                      tmpTotalResValue = parseInt(ansData.riskLevel);
                    }else{
                      if (ansData.expectedAnswerName != ''){
                        tmpTotalResValue = parseInt(ansData.expectedAnswerName);
                      }
                    }
                    tmpArray.forEach((itemData,indexData)=>{
                      if (indexData >0 ){
                        itemData.answers.answerList.forEach((ansDataData)=>{
                          if (tmpAnsName === ansDataData.answerName){
                            if (ansDataData.expectedAnswerName === "Yes" || ansDataData.expectedAnswerName === "checked"){
                              tmpTotalResValue = tmpTotalResValue + parseInt(ansDataData.riskLevel);
                            }else{
                              if (ansDataData.expectedAnswerName != ''){
                                tmpTotalResValue = tmpTotalResValue + parseInt(ansDataData.expectedAnswerName);
                              }
                            }
                          }  
                        })  

                      }
                    })
                    var objData = {};
                    objData["answerName"] =  tmpAnsName;
                    objData["riskLevel"] =  tmpRiskLevel;
                    objData["expectedAnswerValue"] =  tmpTotalResValue;
                    tmpDataArray.push(objData);
                  }
                  count1 ++;
                })  
              } 
              count ++;  
            }

          })  


          tableScoreStr +="<table ><tr>";
          tableScoreStr +="<td>";
          tableScoreStr +="<table><tr><td>Answers</td><tr><td>Weight</td><tr><td>Result Score</td></table>";
          tableScoreStr +="</td>";
          tmpDataArray.forEach((item,indexT)=>{
            tableScoreStr +="<td>";
            tableScoreStr +="<table><tr><td style='text-align:right'>"+item.answerName+"</td><tr><td style='text-align:right'>"+item.riskLevel+"</td><tr><td style='text-align:right'>"+item.expectedAnswerValue+"</td></table>";
            tableScoreStr +="</td>";
            if (indexT ===0){
              maxVal = item.expectedAnswerValue;
              maxName = item.answerName;
              scoreStr += 1 + "=" + item.expectedAnswerValue;
              highScoreStr = item.answerName + "=" + item.expectedAnswerValue;
            }else{
              if (parseInt(maxVal) < parseInt(item.expectedAnswerValue)){
                maxVal = item.expectedAnswerValue;
                maxName = item.answerName;
                highScoreStr = item.answerName + "=" + item.expectedAnswerValue;
              }
              scoreStr += ", " + item.answerName + "=" + item.expectedAnswerValue;
            }
          })
          tableScoreStr +="</tr></table>";
          
        }
      });
            

      return (
        <Tooltip title={parseHtml(tableScoreStr)}>
          <span className={classes.fontZise10}>{highScoreStr}</span>        
        </Tooltip>  
      )
    }

    const renderTextURRiskName = (params) => {
      return (
          <Tooltip title={params.row.riskName}>
          <span className={classes.fontZise10}>{params.row.riskName}</span>        
          </Tooltip>
      )
    }

    function PaperComponent(props) {
      return (
        <Draggable
          handle="#draggable-dialog-title"
          cancel={'[class*="MuiDialogContent-root"]'}
        >
          <Paper {...props} />
        </Draggable>
      );
    }
    const [selectedQuesData, setSelectedQuesData] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [quesDataCount, setQuesDataCount] = useState(0);
    const [userCount, setUserCount] = useState(0);

    const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
    const [questionDetailScroll, setQuestionDetailScroll] = React.useState('paper');

    const [moreInfoQuestionScore, setMoreInfoQuestionScore] = React.useState(0);
    const [userResposeEvalDataRows, setUserResposeEvalDataRows] = React.useState([]);
    const [userResposeEvalDataColumns, setUserResposeEvalDataColumns] = React.useState([]);
    const [userResposeEvalTotalDataArray, setUserResposeEvalTotalDataArray] = React.useState([]);

    const renderTextdata1 = (params) => {
      return (
          <Tooltip title={params.row.data1}>
          <span className={classes.fontZise10}>{params.row.data1}</span>        
          </Tooltip>
      )
    }
    const renderTextdata0 = (params) => {
      return (
          <Tooltip title={params.row.data0}>
          <span className={classes.fontZise10}>{params.row.data0}</span>        
          </Tooltip>
      )
    }

    function moreInfo(no,question){
      var tmpArray = [];
      setQuesDataCount(no);
      setSelectedQuestion(question);
      userResposeEvalRows.forEach((data, index)=>{
        if (index === (no-1)) {
          tmpArray = [];
          console.log(data.responseData);
          setUserCount(data.responseData.length);
          data.responseData.forEach((item)=>{
            var quesObj = item.question;
            quesObj["createdBy"] = item.createdBy;
            tmpArray.push(quesObj);
            setSelectedQuesData(tmpArray);
          })

          var count = 1;
          var tmpRowArray = [];
          var tmpColArray = [];
          var tmpDataArray = [];
          setUserResposeEvalDataRows([]);
          setUserResposeEvalDataColumns([]);
          tmpArray.forEach((item,index)=>{
            if (item.questionType === "Closeended") {
              setMoreInfoQuestionScore(item.questionScore)
              var obj = {};
              var objCol = {};
              obj["id"] = count;
              if (index === 0){
                objCol = {};
                objCol["field"] = 'id';
                objCol["headerName"] = 'No';
                objCol["resizable"] = true;
                objCol["cellClassName"] =  'super-app-theme--cell';
                objCol["width"] =  15;
                tmpColArray.push(objCol);
                setUserResposeEvalDataColumns(tmpColArray);
              }  
              obj["data0"] = item.createdBy.userName
              if (index === 0){
                objCol = {};
                objCol["field"] = 'data0';
                objCol["headerName"] = 'User Name';
                objCol["resizable"]= true;
                objCol["cellClassName"] =  'super-app-theme--cell';
                objCol["width"] =  200;
                objCol["renderCell"] = renderTextdata0;
                tmpColArray.push(objCol);
                setUserResposeEvalDataColumns(tmpColArray);
              }
              if (item.answers.answerMethod.name != "" && item.answers.answerList.length > 0 ) {
                
                var tmpAnsName = '';
                var tmpRiskLevel = '';
                var tmpTotalResValue = 0;

                var count1 = 1;
                item.answers.answerList.forEach((ansData,index2)=>{
                  if (index === 0){
                    objCol = {};
                    objCol["field"] = "data" + count1;
                    objCol["headerName"] = ansData.answerName + "(" + ansData.riskLevel + ")";
                    objCol["resizable"]= true;
                    objCol["cellClassName"] =  'super-app-theme--cell';
                    tmpColArray.push(objCol);
                    setUserResposeEvalDataColumns(tmpColArray);
                    /** To get answernames, resk level and taltal of user responsed */
                    tmpAnsName = ansData.answerName;
                    tmpRiskLevel = ansData.riskLevel;
                    tmpTotalResValue = 0;
                    if (ansData.expectedAnswerName === "Yes" || ansData.expectedAnswerName === "checked"){
                      tmpTotalResValue = parseInt(ansData.riskLevel);
                    }else{
                      if (ansData.expectedAnswerName != ''){
                        tmpTotalResValue = parseInt(ansData.expectedAnswerName);
                      }
                    }
                    tmpArray.forEach((itemData,indexData)=>{
                      if (indexData >0 ){
                        itemData.answers.answerList.forEach((ansDataData)=>{
                          if (tmpAnsName === ansDataData.answerName){
                            if (ansDataData.expectedAnswerName === "Yes" || ansDataData.expectedAnswerName === "checked"){
                              tmpTotalResValue = tmpTotalResValue + parseInt(ansDataData.riskLevel);
                            }else{
                              if (ansDataData.expectedAnswerName != ''){
                                tmpTotalResValue = tmpTotalResValue + parseInt(ansDataData.expectedAnswerName);
                              }
                            }
                          }  
                        })  

                      }
                    })
                    var objData = {};
                    objData["answerName"] =  tmpAnsName;
                    objData["riskLevel"] =  tmpRiskLevel;
                    objData["expectedAnswerValue"] =  tmpTotalResValue;
                    tmpDataArray.push(objData);
                    setUserResposeEvalTotalDataArray(tmpDataArray);

                  }
                  if (ansData.expectedAnswerName === "Yes" || ansData.expectedAnswerName === "checked"){
                    obj["data" + count1] = ansData.riskLevel;
                  }else{
                    if (ansData.expectedAnswerName != ''){
                      obj["data" + count1] = ansData.expectedAnswerName;
                    }else{
                      obj["data" + count1] = '';
                    }
                  }  
                  count1 ++;
                })  
              } 
              tmpRowArray.push(obj);
              setUserResposeEvalDataRows(tmpRowArray);
              count ++;  
            }else{
              if (item.questionType === "Openended") {
                setMoreInfoQuestionScore(item.questionScore)
                var obj = {};
                var objCol = {};
                obj["id"] = count;
                if (index === 0){
                  objCol = {};
                  objCol["field"] = 'id';
                  objCol["headerName"] = 'No';
                  objCol["resizable"] = true;
                  objCol["cellClassName"] =  'super-app-theme--cell';
                  objCol["width"] =  15;
                  tmpColArray.push(objCol);
                  setUserResposeEvalDataColumns(tmpColArray);
                }  
                obj["data0"] = item.createdBy.userName
                if (index === 0){
                  objCol = {};
                  objCol["field"] = 'data0';
                  objCol["headerName"] = 'User Name';
                  objCol["resizable"]= true;
                  objCol["cellClassName"] =  'super-app-theme--cell';
                  objCol["width"] =  200;
                  objCol["renderCell"] = renderTextdata0;
                  tmpColArray.push(objCol);
                  setUserResposeEvalDataColumns(tmpColArray);
                }
               
                if (index === 0){
                  objCol = {};
                  objCol["field"] = "data1";
                  objCol["headerName"] = 'User Answer';
                  objCol["resizable"]= true;
                  objCol["cellClassName"] =  'super-app-theme--cell';
                  objCol["width"] =  600;
                  objCol["renderCell"] = renderTextdata1;
                  tmpColArray.push(objCol);
                  setUserResposeEvalDataColumns(tmpColArray);
                }
                obj["data1"] = item.answers.answer
                    
                tmpRowArray.push(obj);
                setUserResposeEvalDataRows(tmpRowArray);
                count ++;  
              } 

            }

          })  
          console.log("++++++++++++++++++tmpRowArray++++++++++++++++");
          console.log(tmpRowArray);
          console.log("++++++++++++++++++tmpColArray++++++++++++++++");
          console.log(tmpColArray);
          console.log("++++++++++++++++++tmpDataArray++++++++++++++++");
          console.log(tmpDataArray);

          
        }
      });
  
      setQuestionDetailOpen(true);
      setQuestionDetailScroll('paper');
  
    }

    const questionDetailHandleClickClose = () => {
      setQuestionDetailOpen(false);
    };
  
    const [selectedUserData, setSelectedUserData] = useState([]);
    const [userDetailOpen, setUserDetailOpen] = React.useState(false);
    const [userDetailScroll, setUserDetailScroll] = React.useState('paper');

    function userInfo(no,noOfUsers,question){
      setQuesDataCount(no);
      setSelectedQuestion(question);
      setUserCount(noOfUsers);

      userResposeEvalRows.forEach((data, index)=>{
        if (index === (no-1)) {
          var tmpArray = [];
          data.responseData.forEach((item)=>{
            tmpArray.push(item.createdBy);
            setSelectedUserData(tmpArray);
          })
        }
      });
      setUserDetailOpen(true);
      setUserDetailScroll('paper');
  
    }

    const userDetailHandleClickClose = () => {
      setUserDetailOpen(false);
    };


    const renderRespondedUsersButton = (params) => {
      return (
          <strong>
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                      userInfo(params.row.id,params.row.noOfUsers,params.row.question)
                  }}
              >
                  {params.row.noOfUsers}
              </Button>
          </strong>
      )
    }


    const renderEvalButton = (params) => {
      return (
          <strong>
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                      moreInfo(params.row.id,params.row.question)
                  }}
              >
                  More Info
              </Button>
          </strong>
      )
  }
  


    const columnsUsersResponse = [
      
      { field: 'id', headerName: 'No', renderCell: renderTextURId, resizable: true, width: 15 },
      { field: 'questionId', headerName: 'No', width: 15, hide: true },
      { field: 'userResponseId', headerName: 'userResponseId', width: 15, hide: true },
      { field: 'noOfUsers', headerName: 'noOfUsers', width: 15, hide: true },
      
      { field: 'riskName', headerName: 'Risk', renderCell: renderTextURRiskName, resizable: true, cellClassName: 'super-app-theme--cell', width: 105 },
      { field: 'objectiveName', headerName: 'Objective', renderCell: renderTextURObjectiveName, resizable: true, cellClassName: 'super-app-theme--cell', width: 105 },
      { field: 'componentName', headerName: 'Component', renderCell: renderTextURComponentName, resizable: true, cellClassName: 'super-app-theme--cell', width: 105 },
      { field: 'principalName', headerName: 'Principal', renderCell: renderTextURPrincipalName, resizable: true, cellClassName: 'super-app-theme--cell', width: 105 },
      { field: 'entityName', headerName: 'Entity', renderCell: renderTextUREntity, cellClassName: 'super-app-theme--cell', resizable: true, width: 100 },
      { field: 'question', headerName: 'Question', renderCell: renderTextURQuestion, cellClassName: 'super-app-theme--cell', resizable: true, width: 175 },
      { field: 'questionType', headerName: 'Type', renderCell: renderTextURQuestionType, resizable: true, width: 80 },
      { field: 'questionScore', headerName: 'Score', renderCell: renderTextQuestionScore, resizable: true, width: 80 },
      {
        field: 'action',
        headerName: 'Eval Info',
        sortable: false,
        renderCell: renderEvalButton,
        disableClickEventBubbling: true,
        width: 110
      },
      {
        field: 'edit',
        headerName: 'Responded Users',
        sortable: false,
        renderCell: renderRespondedUsersButton,
        disableClickEventBubbling: true,
        width: 110
      }
      
    
    ];
  

















    const renderTextFieldId = (params) => {
      return (
          <Tooltip title={params.row.id}>
          <span className={classes.fontZise10}>{params.row.id}</span>        
          </Tooltip>
      )
    }
    const renderTextUserId = (params) => {
      return (
          <Tooltip title={params.row.userId}>
          <span className={classes.fontZise10}>{params.row.userId}</span>        
          </Tooltip>
      )
    }
    const renderTextUserName = (params) => {
      return (
          <Tooltip title={params.row.userName}>
          <span className={classes.fontZise10}>{params.row.userName}</span>        
          </Tooltip>
      )
    }
    const renderTextUserEmail = (params) => {
      return (
          <Tooltip title={params.row.userEmail}>
          <span className={classes.fontZise10}>{params.row.userEmail}</span>        
          </Tooltip>
      )
    }
    const renderTextEntityName1 = (params) => {
      return (
          <Tooltip title={params.row.entityName}>
          <span className={classes.fontZise10}>{params.row.entityName}</span>        
          </Tooltip>
      )
    }

    const columnsUsers = [
      { field: 'id', headerName: 'No', renderCell: renderTextFieldId, resizable: true, width: 10, hide: true },
      { field: 'entityName', headerName: 'Entity Name', renderCell: renderTextEntityName1, resizable: true, cellClassName: 'super-app-theme--cell', width: 160 },
      { field: 'userId', headerName: 'User Code', renderCell: renderTextUserId, resizable: true, cellClassName: 'super-app-theme--cell', width: 110 },
      { field: 'userName', headerName: 'User Name', renderCell: renderTextUserName, width: 200, resizable: true, cellClassName: 'super-app-theme--cell'},
      { field: 'userEmail', headerName: 'Email', renderCell: renderTextUserEmail, resizable: true, cellClassName: 'super-app-theme--cell', width: 140 }
    ];

/** Evaluate All Questions */

const [evaluateAllOpen, setEvaluateAllOpen] = React.useState(false);
const [evaluateAllScroll, setEvaluateAllScroll] = React.useState('paper');
const [closeEndedData, setCloseEndedData] = React.useState([]);
const [openEndedData, setOpenEndedData] = React.useState([]);
const [closeEndedTotal, setCloseEndedTotal] = React.useState({});

const evaluateAllHandleClickClose = () => {
  setEvaluateAllOpen(false);
};


function evaluateAll(){
  console.log(userResposeEvalRows);
  var countCloseEnded = 1;
  var countOpenEnded = 1;
  var closeEndedArray = [];
  var openEndedArray = [];
  var tmpCOTotalQuestionWeight = 0;
  var tmpCOTotalUsers = 0;
  var tmpCOTotalAnswer1 = 0;
  var tmpCOTotalAnswer2 = 0;
  var tmpCOTotalAnswer3 = 0;
  var tmpCOTotalAnswer4 = 0;
  var tmpCOTotalAnswer5 = 0;
  userResposeEvalRows.forEach((data, index1)=>{
    var tmpArray = [];
    var objAllCe = {};  
    var objAllOe = {};  
    data.responseData.forEach((item)=>{
      var quesObj = item.question;
      quesObj["createdBy"] = item.createdBy;
      tmpArray.push(quesObj);
    })
    var tmpDataArray = [];
    tmpArray.forEach((item,index)=>{
      if (item.questionType === "Closeended") {
        if (index === 0 ) {
          objAllCe["id"] = countCloseEnded;
          objAllCe["questionId"] = item.questionId;
          objAllCe["question"] = item.question;
          objAllCe["questionType"] = item.questionType;
          objAllCe["questionWeigth"] = item.questionScore;
          objAllCe["noOfRespondedUsers"] = tmpArray.length; 
          tmpCOTotalUsers += tmpArray.length;
          objAllCe["totalQuestionWeigth"] = (tmpArray.length * parseInt(item.questionScore));
          tmpCOTotalQuestionWeight += (tmpArray.length * parseInt(item.questionScore));
          objAllCe["questionData"] = item;
          objAllCe["responseUserData"] = tmpArray; 
          countCloseEnded ++;
        }
        if (item.answers.answerMethod.name != "" && item.answers.answerList.length > 0 ) {
            var tmpAnsName = '';
            var tmpRiskLevel = '';
            var tmpTotalUserValue = 0;
            var count1 = 1;
            item.answers.answerList.forEach((ansData,index2)=>{
              if (index === 0){
                var tmpRiskLevel = ansData.riskLevel;
                tmpAnsName = ansData.answerName;
                tmpTotalUserValue = 0;
                if (ansData.expectedAnswerName === "Yes" || ansData.expectedAnswerName === "checked"){
                  tmpTotalUserValue = 1;
                }else{
                  if (ansData.expectedAnswerName != ''){
                    tmpTotalUserValue =1;
                  }
                }
                tmpArray.forEach((itemData,indexData)=>{
                  if (indexData >0 ){
                    itemData.answers.answerList.forEach((ansDataData)=>{
                      if (tmpAnsName === ansDataData.answerName){
                        if (ansDataData.expectedAnswerName === "Yes" || ansDataData.expectedAnswerName === "checked"){
                          tmpTotalUserValue ++;
                        }else{
                          if (ansDataData.expectedAnswerName != ''){
                            tmpTotalUserValue ++;
                          }
                        }
                      }  
                    })  
                  }
                })
                var objData = {};
                objData["answerName"] =  tmpAnsName;
                objData["riskLevel"] =  tmpRiskLevel;
                objData["userResponseCount"] =  tmpTotalUserValue;
                tmpDataArray.push(objData);

              }
            })  
          }
            
        }else{
          if (index === 0 ) {
            objAllOe["id"] = countOpenEnded;
            objAllOe["questionId"] = item.questionId;
            objAllOe["question"] = item.question;
            objAllOe["questionType"] = item.questionType;
            objAllOe["questionWeigth"] = item.questionScore;
            objAllOe["noOfRespondedUsers"] = tmpArray.length; 
            objAllOe["totalQuestionWeigth"] = (tmpArray.length * parseInt(item.questionScore));
            objAllOe["questionData"] = item;
            objAllOe["responseUserData"] = tmpArray; 
            countOpenEnded ++;
          }
          var objData = {};
          objData["answer"] =  item.answers.answer;
          tmpDataArray.push(objData);

        }
        

      })
      if (Object.keys(objAllCe).length != 0){
        var answerLevelArr = [];
        var level1 = false;
        var level2 = false;
        var level3 = false;
        var level4 = false;
        var level5 = false;
        tmpDataArray.forEach((itemDataData)=>{
          var riskLevelVal= parseInt(itemDataData.riskLevel);
          if (riskLevelVal === -5 || riskLevelVal === -3 || (riskLevelVal > -5 && riskLevelVal < -3)){
            level1 = true;
            var obj = {}
            obj["no"] = 1;
            obj["riskName"] = 'Heighly Negative';
            obj["riskLevel"] = '-5';
            obj["userResponseCount"] = itemDataData.userResponseCount;
            obj["totalRiskLevel"] = -5 * parseInt(itemDataData.userResponseCount);
            tmpCOTotalAnswer1 += -5 * parseInt(itemDataData.userResponseCount);
            answerLevelArr.push(obj);
          }
          if (riskLevelVal === -2 || riskLevelVal === 0 || (riskLevelVal > -2 && riskLevelVal < 0)){
            level2 = true;
            var obj = {}
            obj["no"] = 2;
            obj["riskName"] = 'Negative';
            obj["riskLevel"] = '-2';
            obj["userResponseCount"] = itemDataData.userResponseCount;
            obj["totalRiskLevel"] = -2 * parseInt(itemDataData.userResponseCount);
            tmpCOTotalAnswer2 += -2 * parseInt(itemDataData.userResponseCount);
            answerLevelArr.push(obj);
          }
          if (riskLevelVal === 1 || riskLevelVal === 3 || (riskLevelVal > 1 && riskLevelVal < 3)){
            level3 = true;
            var obj = {}
            obj["no"] = 3;
            obj["riskName"] = 'Somewhat Positive';
            obj["riskLevel"] = '1';
            obj["userResponseCount"] = itemDataData.userResponseCount;
            obj["totalRiskLevel"] = 1 * parseInt(itemDataData.userResponseCount);
            tmpCOTotalAnswer3 += 1 * parseInt(itemDataData.userResponseCount);
            answerLevelArr.push(obj);
          }
          if (riskLevelVal === 4 || riskLevelVal === 7 || (riskLevelVal > 4 && riskLevelVal < 7)){
            level4 = true;
            var obj = {}
            obj["no"] = 4;
            obj["riskName"] = 'Positive';
            obj["riskLevel"] = '4';
            obj["userResponseCount"] = itemDataData.userResponseCount;
            obj["totalRiskLevel"] = 4 * parseInt(itemDataData.userResponseCount);
            tmpCOTotalAnswer4 += 4 * parseInt(itemDataData.userResponseCount);
            answerLevelArr.push(obj);          }
          if (riskLevelVal === 8 || riskLevelVal === 10 || (riskLevelVal > 8 && riskLevelVal < 10)){
            level5 = true;
            var obj = {}
            obj["no"] = 5;
            obj["riskName"] = 'Heightly Positive';
            obj["riskLevel"] = '8';
            obj["userResponseCount"] = itemDataData.userResponseCount;
            obj["totalRiskLevel"] = 8 * parseInt(itemDataData.userResponseCount);
            tmpCOTotalAnswer5 += 8 * parseInt(itemDataData.userResponseCount);
            answerLevelArr.push(obj);          
          }
        })
        if (!level1){
          var obj = {}
          obj["no"] = 1;
          obj["riskName"] = 'Heighly Negative';
          obj["riskLevel"] = '-5';
          obj["userResponseCount"] = 0;
          obj["totalRiskLevel"] = 0;
          answerLevelArr.push(obj);
        }
        if (!level2){
          var obj = {}
          obj["no"] = 2;
          obj["riskName"] = 'Negative';
          obj["riskLevel"] = '-2';
          obj["userResponseCount"] = 0;
          obj["totalRiskLevel"] = 0;
          answerLevelArr.push(obj);
        }
        if (!level3){
          var obj = {}
          obj["no"] = 3;
          obj["riskName"] = 'Somewhat Positive';
          obj["riskLevel"] = '1';
          obj["userResponseCount"] = 0;
          obj["totalRiskLevel"] = 0;
          answerLevelArr.push(obj);
        }
        if (!level4){
          var obj = {}
          obj["no"] = 4;
          obj["riskName"] = 'Positive';
          obj["riskLevel"] = '4';
          obj["userResponseCount"] = 0;
          obj["totalRiskLevel"] = 0;
          answerLevelArr.push(obj);          }
        if (!level5){
          var obj = {}
          obj["no"] = 5;
          obj["riskName"] = 'Heightly Positive';
          obj["riskLevel"] = '8';
          obj["userResponseCount"] = 0;
          obj["totalRiskLevel"] = 0;
          answerLevelArr.push(obj);          
        }
        answerLevelArr = answerLevelArr.sort (compareRiskNo);
        var no = 0;
        var totalRiskLevel = 0;
        var riskName = '';
        var riskLevel = '';
        var userResponseCount =  0;
        var tmpAnswerLevelArr = [];
        //console.log(answerLevelArr);
        answerLevelArr.forEach((itemDataData, index2)=>{
          if (index2 === 0){
            no = itemDataData.no;
            totalRiskLevel = itemDataData.totalRiskLevel;
            riskName = itemDataData.riskName;
            riskLevel = itemDataData.riskLevel;
            userResponseCount = itemDataData.userResponseCount;
            if ((answerLevelArr.length -1) ===  index2){
              var obj = {};
              obj["no"] = no;
              obj["riskName"] = riskName;
              obj["riskLevel"] = riskLevel;
              obj["userResponseCount"] = userResponseCount;
              obj["totalRiskLevel"] = totalRiskLevel;
              tmpAnswerLevelArr.push(obj);
            }
          }else{
            if (no === itemDataData.no){
              totalRiskLevel += itemDataData.totalRiskLevel;
              riskName = itemDataData.riskName;
              riskLevel = itemDataData.riskLevel;
              userResponseCount += itemDataData.userResponseCount;
              if ((answerLevelArr.length -1) ===  index2){
                var obj = {};
                obj["no"] = no;
                obj["riskName"] = riskName;
                obj["riskLevel"] = riskLevel;
                obj["userResponseCount"] = userResponseCount;
                obj["totalRiskLevel"] = totalRiskLevel;
                tmpAnswerLevelArr.push(obj);
              }
            }else{
              var obj = {};
              obj["no"] = no;
              obj["riskName"] = riskName;
              obj["riskLevel"] = riskLevel;
              obj["userResponseCount"] = userResponseCount;
              obj["totalRiskLevel"] = totalRiskLevel;
              tmpAnswerLevelArr.push(obj);
              no = itemDataData.no;
              totalRiskLevel = itemDataData.totalRiskLevel;
              riskName = itemDataData.riskName;
              riskLevel = itemDataData.riskLevel;
              userResponseCount = itemDataData.userResponseCount;
              if ((answerLevelArr.length -1) ===  index2){
                var obj = {};
                obj["no"] = no;
                obj["riskName"] = riskName;
                obj["riskLevel"] = riskLevel;
                obj["userResponseCount"] = userResponseCount;
                obj["totalRiskLevel"] = totalRiskLevel;
                tmpAnswerLevelArr.push(obj);
              }
            }
          }
        })

        //console.log(tmpAnswerLevelArr);
        tmpAnswerLevelArr = tmpAnswerLevelArr.sort (compareRiskNo);
        objAllCe["answerRiskLevelList"] = tmpAnswerLevelArr;
      }else{
        objAllOe["answers"] = tmpDataArray;
      }
      if (Object.keys(objAllCe).length === 0){
        openEndedArray.push(objAllOe);  
      }
      if (Object.keys(objAllOe).length === 0){
        closeEndedArray.push(objAllCe);  
      }
  });
  var obj = {}
  obj["totalQuestionWeight"] = tmpCOTotalQuestionWeight;
  obj["totalUsers"] = tmpCOTotalUsers;
  obj["totalAnswer1"] = tmpCOTotalAnswer1;
  obj["totalAnswer2"] = tmpCOTotalAnswer2;
  obj["totalAnswer3"] = tmpCOTotalAnswer3;
  obj["totalAnswer4"] = tmpCOTotalAnswer4;
  obj["totalAnswer5"] = tmpCOTotalAnswer5;

 
  console.log(closeEndedArray);
  console.log(obj);
  setCloseEndedData(closeEndedArray);
  setOpenEndedData(openEndedArray);
  setCloseEndedTotal(obj);

  setEvaluateAllOpen(true);
  setEvaluateAllScroll('paper');

}



const [PageName, setPageName] = useState('Mail Preparation And User Response Evaluation');
  return (
    <>
        {CONFIG.EXPERT_LOGIN?
          <RightBarDataForOtherPages PageName={PageName}/> 
        :
          <RightBarDataForUserPages PageName={PageName} CentreName={centreName}/> 
        }
        <div className={classes.pageTop}>
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.pageBodyStyle}>

                    <Grid item xs={12}>
                    <div role="presentation" onClick={handleClick}>
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/login">
                          Home
                        </Link>
                        <Typography color="text.primary">Mail preparation</Typography>
                      </Breadcrumbs>
                    </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Box    
                                sx={{
                                    height: 300,
                                    width: '100%',
                                    '& .super-app-theme--cell': {
                                    whiteSpace: 'normal !important',
                                    lineHeight: '1',
                                    },
                                    '& .super-app-theme--Open': {
                                        fontSize: '10px !important',
                                        backgroundColor: 'red !important'
                                    }, 
                                }}
                                >
                                <div style={{ height: 400, width: '100%', fontSize: '9px !important' }}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    //getRowHeight={() => '75px'}
                                    getRowClassName={`super-app-theme--Open`}
                                    disableSelectionOnClick
                                />
                                </div>

                            </Box>
                        </Grid>
                    </Grid> 
                    <Grid item xs={12} id="tableEndId">
                    </Grid>

                </Grid>
            </Grid>  
        </div>

        {
            openEntityData?
                <Draggable>
                    <Grid item xs={12} style={{marginTop: + `${openEntityDataTopPos}`+ 'px'}}>
                        <Card className={classes.cardDiv2DB}>
                            <Typography variant="h6" style={{textAlign:'right', marginTop:'-5px', marginRight: '25px'}}><Tooltip title="Close"><Close onClick={()=>setOpenEntityData(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" className={classes.cardTitleDB}></Typography>
                                    </Grid>  
                                    <Grid item xs={12}>
                                    <Grid container spacing={2}>
                            <Box    
                                sx={{
                                    height: 300,
                                    width: '100%',
                                    '& .super-app-theme--cell': {
                                    whiteSpace: 'normal !important',
                                    lineHeight: '1',
                                    },
                                    '& .super-app-theme--Open': {
                                        fontSize: '10px !important',
                                        backgroundColor: 'red !important'
                                    }, 
                                }}
                                >
                                <div style={{ height: 325, width: '100%', fontSize: '9px !important' }}>
                                
                                <DataGrid
                                    rows={entityRows}
                                    columns={entityColumns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    //getRowHeight={() => '75px'}
                                    getRowClassName={`super-app-theme--Open`}
                                    disableSelectionOnClick
                                />
                                
                                
                                
                                
                                </div>

                            </Box>
                        </Grid>






                
          </Grid>

                                </Grid> 
                            </CardContent>
                        </Card>
                    </Grid>
                </Draggable>
            :null
        }


        {
            userResposeEvalOpen?
                            <Draggable>
                                <Grid item xs={12} style={{marginTop: + `${openUserResposeEvalTopPos}`+ 'px'}}>
                                    <Card className={classes.cardDiv2UR}>
                                    <Typography variant="h6" style={{textAlign:'right', marginTop:'-5px', marginRight: '25px'}}><Tooltip title="Close"><Close onClick={()=>setUserResposeEvalOpen(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={8}>
                                              <Typography variant="h6" className={classes.cardTitleDB}>User Response Questionnaire Evaluation</Typography>
                                            </Grid>  
                                            <Grid item xs={4}>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{marginTop: '-10px'}}
                                                onClick={() => {
                                                    evaluateAll()
                                                }}
                                                >
                                                Evaluate All Questions
                                              </Button>

                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>

                                                <Box    
                                                sx={{
        height: 300,
        width: '100%',
        '& .super-app-theme--cell': {
          whiteSpace: 'normal !important',
          lineHeight: '1',
        },
        '& .super-app-theme--Open': {
            fontSize: '10px !important',
          }, 
  
    }}
    >





                                                    <div style={{ height: 325, width: '100%',backgroundColor: '#ffffff', fontSize: '9px !important' }}>
                                                    <DataGrid
                                                        rows={userResposeEvalRows}
                                                        columns={columnsUsersResponse}
                                                        pageSize={5}
                                                        rowsPerPageOptions={[5]}
                                                        getRowClassName={`super-app-theme--Open`}
                                                        disableSelectionOnClick
                                                    />
                                                    </div>

                                                    </Box>
                                                </Grid>
                                            </Grid>  
                                          </Grid> 
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Draggable>
                        :null
                    }














        {/** Entity Question Display Dialog Box */}
            <Dialog
              open={entityQuestionOpen}
              onClose={entityQuestionHandleClickClose}
              scroll={entityQuestionScroll}
              aria-labelledby="entityQuestion-scroll-dialog-title"
              aria-describedby="entityQuestion-scroll-dialog-description"
              fullWidth={true}
              maxWidth={'md'}
              >
              <DialogTitle id="entityQuestion-scroll-dialog-title">


              <Typography variant="h6" style={{textAlign:'right', marginTop:'-20px', marginRight: '-20px'}}><Tooltip title="Close"><Close onClick={()=>setEntityQuestionOpen(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>



              </DialogTitle>
              <DialogContent dividers={entityQuestionScroll === 'paper'}>
                <DialogContentText
                  id="entityQuestion-scroll-dialog-description"
                  tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                      <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 695 }} aria-label="customized table">
                              <TableHead className={classes.tableAnsConfirmHeadStyle}>
                                  <TableRow>
                                      <StyledTableCell colSpan={3} align="center" className={classes.tableAnsConfrnThStyle}>{selectedEntityName}</StyledTableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                              {
                                allQuestionForSelectedEntity.map((quesmainn,index) => {
                                  return (
                                    <>
                                        <StyledTableRow key={1}>
                                            <StyledTableCell colSpan={3} component="th" scope="row" className={classes.tableAnsConfirmTrStyleEmpty}>
                                                &nbsp;
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow key={2}>
                                            <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle} style={{width:'5%'}}>
                                                {(index+1) + ')'}
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={2} component="th" scope="row" className={classes.tableAnsConfirmTrStyle}>
                                                {quesmainn.question} 
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        {quesmainn.answers.answerMethod.name === "freeTextType"?
                                            <StyledTableRow key={index} >
                                                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                                                    &nbsp;
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={2} component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                                                    <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} autoComplete="off">
                                                        <div>
                                                            <TextField
                                                                id={"txt"+ quesmainn.questionId} 
                                                                label="Type Your Answer"
                                                                multiline
                                                                maxRows={3}
                                                            />
                                                        </div>
                                                    </Box> 
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        :
                                          <>
                                          {quesmainn.answers.answerMethod.name === "radioButtonType" ?
                                            <StyledTableRow>
                                              <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                                                  &nbsp;
                                              </StyledTableCell>
                                              <StyledTableCell colSpan={2} component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                                                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                                {quesmainn.answers.answerList.map((row,index1) => (
                                                    <FormControlLabel value={"radio"+ index1 + quesmainn.questionId} control={<Radio />} label={<Typography sx={{ fontSize: '1.1rem !important' }}>{row.answerName}</Typography>} className={classes.formControlLabelStyle}/>                          
                                                ))}
                                                </RadioGroup>
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          :
                                          <>
                                          {quesmainn.answers.answerMethod.name === "checkBoxType" ?
                                            <StyledTableRow >
                                                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                                                    &nbsp;
                                                </StyledTableCell>
                                                <StyledTableCell copSpan={2} component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                                                <FormGroup>
                                                {quesmainn.answers.answerList.map((row,index1) => (
                                                    <FormControlLabel 
                                                        control={<Checkbox id={"chk"+index1}/>} label={<Typography sx={{ fontSize: '1.1rem !important' }}>{row.answerName}</Typography>} className={classes.formControlLabelStyle}/>
                                                ))} 
                                                </FormGroup>       
                                                </StyledTableCell>
                                            </StyledTableRow>
                                          :
                                           '' 
                                          }
                                          </>
                                        }
                                        {quesmainn.answers.answerMethod.name === "orderNumberType" && quesmainn.answers.answerList.map((row,index1) => (
                                          <StyledTableRow key={index1} >
                                              <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                                                  &nbsp;
                                              </StyledTableCell>
                                              <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'40%' }}>
                                                  {row.answerName}              
                                              </StyledTableCell>
                                              <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                                                  <FormControl sx={{ m: 0.5, minWidth: 100 }} size="small">
                                                      <InputLabel id="select-select-label">Select</InputLabel>
                                                      <Select
                                                          labelId="select-select-label"
                                                          id={"select" + index1}
                                                          label="Select"
                                                          //defaultValue={row.expectedAnswerName}
                                                          >
                                                              <MenuItem value={1}>{1}</MenuItem>
                                                              <MenuItem value={2}>{2}</MenuItem>
                                                              <MenuItem value={3}>{3}</MenuItem>
                                                              <MenuItem value={4}>{4}</MenuItem>
                                                              <MenuItem value={5}>{5}</MenuItem>
                                                      </Select>
                                                  </FormControl>
                                              </StyledTableCell>
                                          </StyledTableRow>
                                        ))}
                                        </>

                                    }








                                
                                </>
                              )
                                })
                              }      
                              </TableBody>
                              
                          </Table>
                      </TableContainer>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
          <DialogActions>
            <Button onClick={entityQuestionHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of entity Question Dialog Box */}  


  {/** Dialog for entity Mapping */}

  <Dialog
    maxWidth={'md'}
    open={entityUserOpen}
    onClose={entityUserHandleClickClose}
    scroll={entityUserScroll}
    aria-labelledby="entityUser-scroll-dialog-title"
    aria-describedby="entityUser-scroll-dialog-description"
    >
    <DialogTitle id="draggable-dialog-title">
        Please Select a From email User to send emails to the Users listed below to evaluate the Questionnaire Collection
    </DialogTitle>
    <DialogContent dividers={entityUserScroll === 'paper'}>
      <DialogContentText
        id="entityUser-scroll-dialog-description"
        tabIndex={-1}
        >
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <FormControl sx={{ m: 0.5, minWidth: '100%' }} size="small">
                        <InputLabel id="select-select-label">Select From User</InputLabel>
                        <Select
                            labelId="select-select-label"
                            onChange={emailFromUserhandleChange}
                            label="Select From User"
                            >
                            {allAdminUsers.map((item)=>(
                                <MenuItem 
                                    value={item.userId}
                                >
                                {item.userName + ' ' + item.lastName}
                            </MenuItem>
                            ))}    
                        </Select>
                    </FormControl>
            
          </Grid>  
          <Grid item xs={2}>
          <Typography variant="h6" style={{textAlign:'center', marginTop:'-10px', fontSize: '13px'}}>To Users</Typography>
          </Grid>
          <Grid item xs={10}>
          
            <Grid container spacing={2}>
                            <Box    
                                sx={{
                                    //height: 250,
                                    width: '100%',
                                    '& .super-app-theme--cell': {
                                    whiteSpace: 'normal !important',
                                    lineHeight: '1',
                                    },
                                    '& .super-app-theme--Open': {
                                        fontSize: '10px !important',
                                        backgroundColor: 'red !important'
                                    }, 
                                }}
                                >
                                <div style={{ height: 200, width: '100%', fontSize: '9px !important' }}>
                                <DataGrid
                                    rows={selectedToEntityRows}
                                    columns={columnsUsers}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    getRowClassName={`super-app-theme--Open`}
                                    disableSelectionOnClick
                                />
                                </div>

                            </Box>
                        </Grid>

          </Grid>   
          <Grid item xs={12}>
            <Button 
                variant="outlined" 
                size='small'
                className={classes.btnBorderGreenEntityMap}
                onClick={() => confirmSendingMailsFunction()} 
                style={{float:'right'}}>
                    CONFIRM
            </Button>
          </Grid>
       </Grid>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={entityUserHandleClickClose}>Cancel</Button>
    </DialogActions>
  </Dialog>

{/************************ */}

<Dialog
          maxWidth='md'
          fullWidth={true}
          open={questionDetailOpen}
          onClose={questionDetailHandleClickClose}
          scroll={questionDetailScroll}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Typography variant="h6" style={{textAlign:'right', marginTop:'-22px', marginRight: '-24px'}}><Tooltip title="Close"><Close onClick={()=>setQuestionDetailOpen(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
            <Grid container spacing={1}>                    
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom component="div">
                    Evaluation Results
                </Typography>
              </Grid>                  
              <Grid item xs={4}>                  
                <Typography variant="h6" gutterBottom component="div">
                    Question No - <span style={{fontWeight: '200'}}>{quesDataCount}</span>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h6" gutterBottom component="div">
                    Responded Users - <span style={{fontWeight: '200'}}>{userCount}</span>
                </Typography>
              </Grid>

            </Grid>    
          </DialogTitle>
          <DialogContent dividers={questionDetailScroll === 'paper'}>
            <DialogContentText
              id="risk-eval-type-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={1}>
                {selectedQuesData.map((data,index) => {
                  return (
                    <Grid item xs={12}>
                      <Box >
                        {index === 0?
                        <>
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                              <TableRow>
                                  <TableCell component="th" scope="row" style={{width: '15%'}}>
                                    <span style={{width: '100%', fontSize: '10px !important', fontWeight: '550'}}>Question : </span>
                                  </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '85%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                      {selectedQuestion}
                                    </TableCell>
                                </TableRow>    
                            </TableBody>
                        </Table>
                        
                        <Table size="small" aria-label="purchases">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '15%'}}>
                                        <span style={{width: '100%', fontSize: '10px !important', fontWeight: '550'}}>Weight : </span>
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{width: '85%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                      {data.questionScore}
                                    </TableCell>
                                </TableRow>    
                            </TableBody>
                        </Table>
                        {data.questionType === "Closeended" && (
                          <Table size="small" aria-label="purchases">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '15%', borderBottomColor: 'white'}}>
                                        <span style={{width: '100%', fontSize: '10px !important', fontWeight: '550'}}>Answers : </span>
                                    </TableCell>
                                    {userResposeEvalTotalDataArray.map((item) => {
                                      return (
                                        <TableCell component="th" scope="row" style={{width: '10%', borderBottomColor: 'white', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                          {item.answerName}
                                        </TableCell>
                                      )
                                    })}
                                    {userResposeEvalTotalDataArray.length === 2 ?
                                      <>
                                        <TableCell component="th" scope="row" style={{width: '10%', borderBottomColor: 'white', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                          &nbsp;
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{width: '10%', borderBottomColor: 'white', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                          &nbsp;
                                        </TableCell>
                                      </>
                                    :
                                      ''
                                    }
                                    {userResposeEvalTotalDataArray.length === 2 ?
                                      <>
                                        <TableCell component="th" scope="row" style={{width: '10%', borderBottomColor: 'white', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                          &nbsp;
                                        </TableCell>
                                      </>
                                    :
                                      ''
                                    }
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '15%', borderBottomColor: 'white', borderTopColor: 'white'}}>
                                        <span style={{width: '100%', fontSize: '10px !important', fontWeight: '550'}}>Weight : </span>
                                    </TableCell>
                                    {userResposeEvalTotalDataArray.map((item) => {
                                      return (
                                        <TableCell component="th" scope="row" style={{width: '10%', borderBottomColor: 'white', borderTopColor: 'white', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                          {item.riskLevel}
                                        </TableCell>
                                      )
                                    })}
                                                                        {userResposeEvalTotalDataArray.length === 2 ?
                                      <>
                                        <TableCell component="th" scope="row" style={{width: '10%', borderBottomColor: 'white', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                          &nbsp;
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{width: '10%', borderBottomColor: 'white', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                          &nbsp;
                                        </TableCell>
                                      </>
                                    :
                                      ''
                                    }
                                    {userResposeEvalTotalDataArray.length === 2 ?
                                      <>
                                        <TableCell component="th" scope="row" style={{width: '10%', borderBottomColor: 'white', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                          &nbsp;
                                        </TableCell>
                                      </>
                                    :
                                      ''
                                    }
                                </TableRow>   
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{width: '15%',borderTopColor: 'white'}}>
                                        <span style={{width: '100%', fontSize: '10px !important', fontWeight: '550'}}>Result Score Totals : </span>
                                    </TableCell>
                                    {userResposeEvalTotalDataArray.map((item) => {
                                      return (
                                        <TableCell component="th" scope="row" style={{width: '10%', borderTopColor: 'white', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                          {item.expectedAnswerValue === 0?'':item.expectedAnswerValue}
                                        </TableCell>
                                      )
                                    })}
                                                                        {userResposeEvalTotalDataArray.length === 2 ?
                                      <>
                                        <TableCell component="th" scope="row" style={{width: '10%', borderBottomColor: 'white', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                          &nbsp;
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{width: '10%', borderBottomColor: 'white', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                          &nbsp;
                                        </TableCell>
                                      </>
                                    :
                                      ''
                                    }
                                    {userResposeEvalTotalDataArray.length === 2 ?
                                      <>
                                        <TableCell component="th" scope="row" style={{width: '10%', borderBottomColor: 'white', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                                          &nbsp;
                                        </TableCell>
                                      </>
                                    :
                                      ''
                                    }
                                </TableRow> 
                            </TableBody>
                          </Table>
                        )}

                        <Table size="small" aria-label="purchases">
                          <TableBody>        
                            <TableRow>
                              <TableCell component="th" scope="row" style={{width: '100%',borderBottomColor: 'white'}}>
                                  <span style={{width: '100%', fontSize: '10px !important', fontWeight: '550'}}>Details </span>
                              </TableCell>
                            </TableRow>  
                          </TableBody>
                        </Table>

                        </>
                        :
                        ''
                        }
                      </Box>
                    </Grid>     
                  )
                })} 
                <Grid item xs={12} style={{marginTop: '-25px !important'}}>
                                                <Grid container style={{marginTop: '-25px !important'}} >

                                                <Box    
                                                sx={{
        height: 250,
        //marginTop: '-25px !important',
        width: '100%',
        '& .super-app-theme--cell': {
          whiteSpace: 'normal !important',
          lineHeight: '1',
        },
        '& .super-app-theme--Open': {
            fontSize: '10px !important',
          }, 
  
    }}
    >





                                                    <div style={{ height: 300, width: '100%',backgroundColor: '#ffffff', fontSize: '9px !important', marginTop: '-30px !important' }}>
                                                    <DataGrid
                                                        rows={userResposeEvalDataRows}
                                                        columns={userResposeEvalDataColumns}
                                                        pageSize={5}
                                                        rowsPerPageOptions={[5]}
                                                        getRowClassName={`super-app-theme--Open`}
                                                        disableSelectionOnClick
                                                    />
                                                    </div>

                                                    </Box>
                                                </Grid>
                                            </Grid>



              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={questionDetailHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Question Details Show Dialog*/}


{/************************ */}

<Dialog
          maxWidth='xs'
          open={userDetailOpen}
          onClose={userDetailHandleClickClose}
          scroll={userDetailScroll}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Typography variant="h6" gutterBottom component="div">
                    Question No - {quesDataCount} - {'(' + userCount + ' User(s) responded)'}
                </Typography>
                <Typography component="th" scope="row" style={{width: '100%', fontSize: '10px !important'}}>
                    <span style={{fontWeight: '550'}}>Question - </span>{selectedQuestion}
                </Typography>
          </DialogTitle>
          <DialogContent dividers={questionDetailScroll === 'paper'}>
            <DialogContentText
              id="risk-eval-type-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                {selectedUserData.map((data,index) => {
                  return (
                    <>
                    {index === 0?
                            <>
                            <Grid item xs={12}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>        
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{width: '80%'}}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                Responded Users
                                            </Typography>
                                        </TableCell>
                                    </TableRow>  
                                </TableBody>
                            </Table>
                            </Grid>
                            </>
                     :
                     ''
                     }       
                    <Grid item xs={2}>
                      {(index + 1)}
                    </Grid>  
                    <Grid item xs={10}>
                      {data.userName}
                    </Grid>  
                    </>
                  )
                })} 
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={userDetailHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Question Details Show Dialog*/}




    {/** Risk Evaluate Options */}
    <Dialog
          maxWidth='md'
          open={riskEvalTypeOpen}
          onClose={riskEvalTypeHandleClickClose}
          scroll={riskEvalTypeScroll}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Select "All" or other Risk Type to Evaluate users Responses in<br></br>{selectedCollectionEvaluateTitle}</DialogTitle>
          <DialogContent dividers={riskEvalTypeScroll === 'paper'}>
            <DialogContentText
              id="risk-eval-type-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel id="radio-buttons-group-label">Pick your Risk Type</FormLabel>
                </Grid>
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                    <FormGroup>
                        <FormControlLabel className={classes.marginleftEval} control={<Checkbox id={"chkRiskType0"} />} label="All" />
                        <Box sx={{ minWidth: 120}}>    
                            <FormControl fullWidth>
                                <TextField
                                    label="Select a Risk Hierarchy"
                                    sx={{ width: '100%' }}
                                    select
                                    value={hierarchyId}
                                    onChange={handleChangeHierarchy}
                                    >
                                    {riskTypeData.map((item)=>(
                                        <MenuItem 
                                            value={item.collectionRiskId}
                                            >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Box>
                        {
                            rsType.map((risktypp) => {
                                return (
                                    <RiskEval 
                                        key={risktypp.collectionRiskTypeId} 
                                        comment={risktypp} 
                                    />
                                )
                            })
                        }
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    &nbsp;
                </Grid>
                <Grid item xs={12}>
                    <Button 
                        variant="outlined"
                        id="riskEvaluateBtn" 
                        className={classes.btnBorderRedEval}
                        style={{float: 'right'}}
                        onClick={()=>setUserResponseEvaluation()}
                        >
                        Confirm
                    </Button>     
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={riskEvalTypeHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Risk Evaluation Options*/}


  {/** Dialog for entity Mapping */}

  <Dialog
    maxWidth={'md'}
    fullWidth={true}
    open={mailReceivedUsersOpen}
    onClose={mailReceivedUsersHandleClickClose}
    scroll={mailReceivedUsersScroll}
    aria-labelledby="entityUser-scroll-dialog-title"
    aria-describedby="entityUser-scroll-dialog-description"
    >
    <DialogTitle id="draggable-dialog-title">
        Mail Status
    </DialogTitle>
    <DialogContent dividers={mailReceivedUsersScroll === 'paper'}>
      <DialogContentText
        id="entityUser-scroll-dialog-description"
        tabIndex={-1}
        >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
                            <Box    
                                sx={{
                                    //height: 250,
                                    width: '100%',
                                    '& .super-app-theme--cell': {
                                    whiteSpace: 'normal !important',
                                    lineHeight: '1',
                                    },
                                    '& .super-app-theme--Open': {
                                        fontSize: '10px !important',
                                        backgroundColor: 'red !important'
                                    }, 
                                }}
                                >
                                <div style={{ height: 200, width: '100%', fontSize: '9px !important' }}>
                                <DataGrid
                                    rows={mailReceivedUsersRows}
                                    columns={columnsMailReceivedUsers}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    getRowClassName={`super-app-theme--Open`}
                                    disableSelectionOnClick
                                />
                                </div>

                            </Box>
                        </Grid>

          </Grid>   
       </Grid>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={mailReceivedUsersHandleClickClose}>Cancel</Button>
    </DialogActions>
  </Dialog>


{/************************ */}

<Dialog
  maxWidth='xl'
  fullWidth={true}
  open={evaluateAllOpen}
  onClose={evaluateAllHandleClickClose}
  scroll={evaluateAllScroll}
  PaperComponent={PaperComponent}
  aria-labelledby="evaluateAll-dialog-title"
  >
  <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
    <Typography variant="h6" style={{textAlign:'right', marginTop:'-22px', marginRight: '-24px'}}><Tooltip title="Close"><Close onClick={()=>setEvaluateAllOpen(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
    <Grid container spacing={1}>                    
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom component="div">
            Evaluation Results
        </Typography>
      </Grid>                  
    </Grid>    
  </DialogTitle>
  <DialogContent dividers={questionDetailScroll === 'paper'}>
    <DialogContentText
      id="risk-eval-type-scroll-dialog-description"
      tabIndex={-1}
      >
      <Grid container spacing={1}>
        <Grid item xs={12}>
        <Paper sx={{ overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 370 }}>
            <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={5} style={{textAlign: 'center', fontSize: '14px', borderBottomColor: 'transparent'}}>Questions</StyledTableCell>
                  <StyledTableCell colSpan={5} style={{textAlign: 'center', fontSize: '14px'}}>Answers</StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell style={{verticalAlign: 'top', width: '4%'}}>No</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '40%'}}>Question</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Weight</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>No of Responses</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Total Weight</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Heighly Negative <br></br>(-5)</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Negative <br></br><br></br>(-2)</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Somewhat Positive <br></br>(1)</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Positive <br></br><br></br>(4)</StyledTableCell>
                  <StyledTableCell style={{verticalAlign: 'top', width: '7%'}}>Heighly Positive <br></br>(8)</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {closeEndedData.map((data,index) => {
                  return (
                    <>
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row" style={{fontSize: '12px'}}>
                        {data.id}
                      </StyledTableCell>
                      <StyledTableCell style={{fontSize: '12px'}}>{data.question}</StyledTableCell>
                      <StyledTableCell style={{fontSize: '12px'}}>{data.questionWeigth}</StyledTableCell>
                      <StyledTableCell style={{fontSize: '12px'}}>{data.noOfRespondedUsers}</StyledTableCell>
                      <StyledTableCell style={{fontSize: '12px'}}>{data.totalQuestionWeigth}</StyledTableCell>
                      {data.answerRiskLevelList.map((item,index1) => {
                        return (
                          <>
                            {index1 === 0?
                              <StyledTableCell style={{fontSize: '12px'}}>{item.totalRiskLevel === 0?'--':item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel)/(-5 * data.noOfRespondedUsers)) + '%)' }</StyledTableCell>
                            :
                              <>
                              {index1 === 1?
                              <StyledTableCell style={{fontSize: '12px'}}>{item.totalRiskLevel === 0?'--':item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel)/(-2 * data.noOfRespondedUsers)) + '%)' }</StyledTableCell>
                              :
                              <>
                                  {index1 === 2?
                                  <StyledTableCell style={{fontSize: '12px'}}>{item.totalRiskLevel === 0?'--':item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel)/(1 * data.noOfRespondedUsers)) + '%)' }</StyledTableCell>
                                  :
                                  <>
                                    {index1 === 3?
                                      <StyledTableCell style={{fontSize: '12px'}}>{item.totalRiskLevel === 0?'--':item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel)/(4 * data.noOfRespondedUsers)) + '%)' }</StyledTableCell>
                                    :
                                    <>
                                      {index1 === 4?
                                        <StyledTableCell style={{fontSize: '12px'}}>{item.totalRiskLevel === 0?'--':item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel)/(8 * data.noOfRespondedUsers)) + '%)' }</StyledTableCell>
                                      :
                                      ''
                                      }  
                                    </>
                                    }  
                                  </>
                                  }  
                              </>
                              
                              }
                              </>
                            }

                          </>
                        )  
                      })}  


                    </StyledTableRow>

                    
                    </>
                  )
              })}      
              </TableBody>
            </Table>  
          </TableContainer>
          </Paper>
          <TableContainer>
            <Table>
              <TableBody>
                  <StyledTableRow key={1} style={{backgroundColor:'black', color: 'white'}}>
                    <StyledTableCell align='right' colSpan={3} component="th" scope="row" style={{color: 'white', fontWeight: '550', width: '51%'}}>
                      {'TOTAL'}
                    </StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalUsers}</StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalQuestionWeight}</StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalAnswer1 === 0? '--':closeEndedTotal.totalAnswer1 + '(' + Math.round((100 * closeEndedTotal.totalAnswer1)/(-5 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalAnswer2 === 0? '--':closeEndedTotal.totalAnswer2 + '(' + Math.round((100 * closeEndedTotal.totalAnswer2)/(-2 * closeEndedTotal.totalUsers)) + '%)' }</StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalAnswer3 === 0? '--':closeEndedTotal.totalAnswer3 + '(' + Math.round((100 * closeEndedTotal.totalAnswer3)/(1 * closeEndedTotal.totalUsers)) + '%)' }</StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalAnswer4 === 0? '--':closeEndedTotal.totalAnswer4 + '(' + Math.round((100 * closeEndedTotal.totalAnswer4)/(4 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                    <StyledTableCell style={{color: 'white', fontWeight: '550', width: '7%'}}>{closeEndedTotal.totalAnswer5 === 0? '--':closeEndedTotal.totalAnswer5 + '(' + Math.round((100 * closeEndedTotal.totalAnswer5)/(8 * closeEndedTotal.totalUsers)) + '%)' }</StyledTableCell>
                  </StyledTableRow>
              </TableBody>
            </Table>  
          </TableContainer>





        </Grid>
      </Grid>                   
            
    </DialogContentText>
  </DialogContent>
  <DialogActions>
      <Button onClick={evaluateAllHandleClickClose}>Close</Button>
  </DialogActions>
</Dialog>
      {/** End of Question Details Show Dialog*/}


{/************************ */}







  {/** Dialog Waiting */}
  <Dialog
    maxWidth={'md'}
    open={waitingOpen}
    PaperProps={{style:{backgroundColor: 'transparent', boxShadow: 'none'}}} 
    >
    <CircularProgress disableShrink />
  </Dialog>










    </>
  )
}

export default UserEmailManagementPage