import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import CardHeader from '@mui/material/CardHeader';
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import axios from 'axios'
import { CONFIG } from '../config';
import parseHtml from 'html-react-parser';
import { More } from '@mui/icons-material';

import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import "../Css/blog.css";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const BlogComponentClientMoreInfor = ({ articleId, articleType }) => {

    const classes = useStyle();

    useEffect(() => {
        getArticle();
        getAllArticle();

    }, []);

    const [articleData, setArticleData] = React.useState({});
    const [noOfComments, setNoOfComments] = React.useState(0);
    const [commentsArray, setCommentsArray] = React.useState([]);

    function dateString(dayStr){
        var dateRtn = "";
        var tdArr = dayStr.split(".")
        dateRtn = tdArr[0].replaceAll("T", " " ) 
        return dateRtn;
      }
    const [articleDate, setArticleDate] = React.useState('');
    
    function getArticle() {
        var sendUrl = `/blogMainArticle/filter?id=` + articleId
        if (articleType != 'main'){
            sendUrl = `/blogArticle/filter?id=` + articleId
        }
        axios(CONFIG.EXPERT_SERVER_URL + sendUrl, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("++++Main Ariticle Details +++++++++++++++++");
                console.log(response.data);
                response.data.forEach((item, index) => {
                    if (index === 0) {
                        setArticleData(item);
                        if (item.articleComments != null && item.articleComments.length > 0) {
                            setNoOfComments(item.articleComments.length)
                            setCommentsArray(item.articleComments)
                            setArticleDate(dateString(item.updatedDate))
                        }
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [allArticleData, setAllArticleData] = React.useState([]);

    function getAllArticle() {
        
        var sendUrl = `/blogArticle/allCurrent`
        axios(CONFIG.EXPERT_SERVER_URL + sendUrl, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("++++Get All Current Ariticle Details +++++++++++++++++");
                console.log(response.data);
                var arr = [];
                response.data.forEach((item, index) => {
                    if (item.domainName === window.location.hostname || ('www.' + item.domainName) === window.location.hostname) {
                        arr.push(item);
                    }
                  })
                setAllArticleData(arr);

            })
            .catch(function (error) {
                console.log(error);
            });
    }



    const [yourName, setYourName] = useState('');
    const yourNameChange = (event) => {
        setYourName(event.target.value);
    };

    const [yourEmail, setYourEmail] = useState('');
    const yourEmailChange = (event) => {
        setYourEmail(event.target.value);
    };

    const [yourSubject, setYourSubject] = useState('');
    const yourSubjectChange = (event) => {
        setYourSubject(event.target.value);
    };

    const [yourComment, setYourComment] = useState('');
    const yourCommentChange = (event) => {
        setYourComment(event.target.value);
    };

    function clearComment() {
        setYourName('');
        setYourEmail('');
        setYourComment('');
        setYourSubject('')
    }

    function saveComment() {
        if (yourName === '') {
            alert("Please enter your Name");
            return;
        }
        if (yourEmail === '') {
            alert("Please enter your Email Address");
            return;
        }
        if (yourComment === '') {
            alert("Please enter your Comment");
            return;
        }

        var obj = articleData;
        var arr = [];
        if (commentsArray.length > 0) {
            commentsArray.forEach((data) => {
                var obj1 = {};
                obj1["id"] = data.id;
                obj1["createdUserName"] = data.createdUserName;
                obj1["comment"] = data.comment;
                obj1["isActive"] = data.isActive;
                arr.push(obj1)
            })
            var obj1 = {};
            obj1["createdUserName"] = yourName;
            obj1["comment"] = yourComment;
            obj1["isActive"] = 'yes';
            arr.push(obj1)

        } else {
            var obj1 = {};
            obj1["createdUserName"] = yourName;
            obj1["comment"] = yourComment;
            obj1["isActive"] = 'yes';
            arr.push(obj1)
        }
        obj["articleComments"] = arr
        console.log(obj);
        var sendJson = JSON.stringify(obj);
        console.log(sendJson)
        var sendURL = '';
        if (articleType === "main") {
            sendURL = '/blogMainArticle/update';
        } else {
            sendURL = '/blogArticle/update';
        }
        axios(CONFIG.MAIN_URL + sendURL, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: sendJson
        })
            .then(res => {
                getArticle();
                alert("Thank you for Your comment ");
                clearComment();
            })
            .catch(function (error) {
                alert('Error in Updating');
                console.log(error);
            });




    }

    const [openImage, setOpenImage] = React.useState(false);
    const [imageHtmlData, setImageHtmlData] = React.useState('');
  
    function handleCloseClick() {
      setOpenImage(false)
    }
  
    setTimeout(() => {
  
      var imgTags = document.getElementsByTagName('img');
      for (var i = 0; i < imgTags.length; i++) {
        imgTags[i].onclick = function () {
          var str = "<img src=\"" + this.src + "\" />";
          setImageHtmlData(str)
          setOpenImage(true);
        };
        imgTags[i].onmouseover = function () {
          this.style.cursor = 'pointer';
        };
      }
    }, 1000);


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <span style={{ fontFamily: 'times', color: '#466fa5', fontSize: '20px' }}>{articleData.title}</span>
                    <br></br>
                    <span style={{ fontFamily: 'times', color: '#333', fontSize: '12px' }}>By {articleData.createdUserName} On {articleDate}</span>
                </Grid>
                <Grid item xs={12}>
                    <img src={articleData.imageUrl} style={{ width: '100%', height: '380px' }} />
                </Grid>
                <Grid item class='detailGrid3000'>
                    <div style={{ display: 'flex', gap: '7px' }}>
                        <span style={{ fontSize: '16px', fontWeight: '700', color: '#333', marginRight: '20px', borderRight: '1px solid #ddd', paddingRight: '20px' }}>blog content</span>
                        <span style={{ fontSize: '13px', color: '#466fa5' }}>{noOfComments} comments</span>
                    </div>
                    <div style={{ textAlign: 'justify', width: '98%', marginTop: '20px', color: '#fff', fontFamily: 'Sans-serif' }}>
                        <span style={{ fontSize: '14px', fontWeight: '400', color: '#324f74', lineHeight: '23px', marginTop: '30px' }}>
                            {parseHtml(" " + articleData.content)}
                            {/** 
                            A Records Management (RM) system is a digital platform that organizes and controls an
                            organization's documents and information throughout their lifecycle. It ensures compliance with
                            regulations, facilitates efficient retrieval, and enhances data security. By automating
                            record-keeping processes, it improves operational efficiency, reduces the risk of errors, and
                            supports better decision-making. The RM system is crucial for maintaining a well-organized and
                            compliant information management framework within an organization.
                        */}
                        </span>
                    </div>
                    <br></br>
                    <div>
                        <div>
                            <span style={{ marginTop: '30px', fontSize: '16px', color: '#666', fontWeight: '700', textTransform: 'uppercase' }} >{noOfComments} comments</span>
                        </div>
                    </div>

                    <div style={{ marginTop: '40px', marginBottom: '40px' }}>
                        <div>
                            {commentsArray.map((item) => {
                                return (
                                    <>
                                        <div style={{ marginTop: '30px' }}>
                                            <div style={{ float: 'left', marginRight: '30px', width: '100%' }}>

                                                <div style={{ backgroundColor: '#f3f3f3', padding: '20px', overflow: 'hidden', position: 'relative' }}>

                                                    <span style={{ fontSize: '16px', fontWeight: '700', color: '#333', textTransform: 'uppercase', marginBottom: '5px', marginTop: '0px' }}>{item.createdUserName}</span>
                                                    <br></br>
                                                    <span style={{ fontSize: '14px', color: '#466fa5' }}>{item.createdDate}</span>
                                                    <br></br>
                                                    <span style={{ marginTop: '20px', fontFamily: 'Open Sans, sans-serif', fontSize: '13px', color: '#666' }}>{item.comment}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: '30px', backgroundColor: '#ffffff' }}>
                                            &nbsp;
                                        </div>
                                    </>
                                )
                            })}

                        </div>

                    </div>

                    <div style={{ marginTop: '30px', backgroundColor: '#ffffff' }}>
                        &nbsp;
                    </div>
                    <div >
                        <div style={{ marginTop: '30px' }}>
                            <span style={{ marginTop: '30px', fontSize: '16px', color: '#666', fontWeight: '700', textTransform: 'uppercase' }} >Leave a comment</span>
                        </div>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        id="title"
                                        label="Your Name"
                                        name='title'
                                        value={yourName}
                                        onChange={yourNameChange}
                                        sx={{ minWidth: '100%' }}
                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="title"
                                        label="Your email address"
                                        name='title'
                                        value={yourEmail}
                                        onChange={yourEmailChange}
                                        sx={{ minWidth: '100%' }}
                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="title"
                                        label="Subject"
                                        name='title'
                                        value={yourSubject}
                                        onChange={yourSubjectChange}
                                        sx={{ minWidth: '100%' }}
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="title"
                                        label="Your Comment"
                                        multiline
                                        maxRows={4}
                                        name='title'
                                        rows={4}
                                        value={yourComment}
                                        onChange={yourCommentChange}
                                        sx={{ minWidth: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        endIcon={<SendIcon />}
                                        onClick={() => { saveComment() }}
                                    >
                                        Send
                                    </Button>

                                </Grid>
                                <Grid item xs={10}></Grid>
                                <Grid item xs={12}>
                                    &nbsp;
                                </Grid>


                            </Grid>
                        </div>
                    </div>


                </Grid>

                <Grid item class='detailGrid3001'>
                    <div>
                        <span style={{ fontSize: '18px', marginTop: '10px', marginBottom: '10px', fontFamily: 'inherit', fontWeight: '500', lineHeight: '1.1', color: 'inherit' }}>Recent Posts</span>
                        <div>
                        {allArticleData.map((item) => {
                            return (
                            <>
                            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <span style={{ fontSize: '12px', marginTop: '10px', marginBottom: '10px', fontFamily: 'inherit', fontWeight: '500', color: 'inherit' }}><a href={`/blogmoreinfor?id=${item.id}&type=sub`}>{item.title}</a></span>
                                <br></br>
                                <span>{`${dateString(item.updatedDate)}`}</span>
                            </div>
                            </>
                            )
                        })}   

                        </div>

                    </div>

                </Grid>

            </Grid>

            <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>

        </>
    )
}

export default BlogComponentClientMoreInfor