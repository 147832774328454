import React, {useEffect,useState} from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import CardHeader from '@mui/material/CardHeader';
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Links from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import PersonAdd from '@mui/icons-material/PersonAdd';
import ListItemIcon from '@mui/material/ListItemIcon';
import { CONFIG } from '../config'
import Tooltip from '@mui/material/Tooltip'
import CollectionImg from '../assets/collection.jpg'
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';




function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));  


const Tranfertomarketingcenter = () => {

    //dialog
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    const [maxWidth, setMaxWidth] = React.useState('md');
    const [entityMapOpen, setEntityMapOpen] = React.useState(false);
    const [entityMapScroll, setEntityMapScroll] = React.useState('paper');

    const [entityMapArr, setEntityMapArr] = React.useState([]);
    const [entityMapCount, setEntityMapCount] = React.useState(0);
  
    
    const entityMapHandleClickOpen = (scrollType) => () => {
      setEntityMapOpen(true);
    };
    const entityMapHandleClickClose = () => {
      setEntityMapOpen(false);
      setEntityMapCount(0);
      setEntityMapArr([]);
    };


  
  const classes = useStyle();

  const [collection, setCollection] = useState([]); // Get All Collections
  const [useName, setUseName] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [golobalCentreId, setGolobalCentreId] = React.useState();
  const [collecId, setcollecId] = React.useState();
  const [quesData, setQuesData] = useState([]); // All Questions In a collectionId 
  const [quesTitle, setQuesTttle]= useState()
  const [abc, setAbc] = useState()
  const [centerCollection, setCenterCollection] = useState([]) // Get All Collections in a Center
  const [collectionFunc, setCollectionFunc] = useState([])

  const [canBuycollection, setCanBuyCollection] = useState([]); // Get All Collections  

  console.log(centerCollection)

  useEffect(() => {
    getData();
  }, [])

  function getData () {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId);
    getEntityData(data.centreId);
    getAllCollection(data.centreId);
    setUseName(data.name);
    console.log(data.name);
  }

  /** Get All Available Collection */
  function getAllCollection(centerId){
    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+`/all`,{
      method: 'get',
        headers: { 
          'Content-Type': 'text/plain', 
        },
    })
    .then(function (response) {
      console.log("Get All Available Collection");
      console.log((response.data));
      setCollection(response.data)
      
      getPurchasedCollection(response.data,centerId );

    })
    .catch(function (error) {
      console.log(error);
    });

  }

  /** Get Center Collection */
  function getPurchasedCollection(allCollectionArr, centreId){
    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+`/centre/${centreId}`,{
      method: 'get',
        headers: { 
          'Content-Type': 'text/plain', 
        },
    })
    .then(function (response) {
      console.log("Get Center Collection");
      console.log((response.data.collections));
      setCenterCollection(response.data.collections)

     
      /*
      allCollectionArr.forEach((item)=>{
        if (item.centreId != centreId){
          var canTake = true;
          response.data.collections.forEach((data)=>{
              if (data.parentCollectionId != undefined && data.parentCollectionId != null && data.parentCollectionId > 0){
                if(item.collectionId === data.parentCollectionId){
                  canTake = false;
                }
              }
          });
          if (canTake){
            tmpArr.push(item);
          }
        }
      })
      */
      var notMyArr = [];
      allCollectionArr.forEach((item)=>{
        /** Can buy only item.centreId != centreId AND  item.parentCollectionId === null */
        if (item.centreId != centreId && item.parentCollectionId === null ){
          notMyArr.push((item));
        }
      })
      var tmpArr = [];
      notMyArr.forEach((item)=>{
        var canTake = true;
        response.data.collections.forEach((data)=>{
          if (data.parentCollectionId != undefined && data.parentCollectionId != null && data.parentCollectionId > 0){
            if(data.parentCollectionId === item.collectionId){
              canTake = false;
            }
          }
        });
        if (canTake){
          tmpArr.push(item);
        }
      })

      setCanBuyCollection(tmpArr);

    })
    .catch(function (error) {
        console.log(error);
    });
  }



  /** Get Questions In a Collection Id*/
  const [buyQuestionShow, setBuyQuestionShow] = useState(true) // Get All Collections in a Center

  function getQuestionsInCollection(collId, no){
    if (no === 1){
      setBuyQuestionShow(false);
    }else{
      setBuyQuestionShow(true);
    }
    console.log(collId)
    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+`/questionResponse?collectionId=${collId}`,{
      method: 'get',
      headers: { 
        'Content-Type': 'text/plain', 
      },
    })
    .then(function (response) {
      console.log("Get All Questions In a Collection Id")
      console.log(response.data.questions)
      setQuesData(response.data.questions)
      setQuesTttle(response.data.title)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  /** Get All Entity Data and set Centre Entity data from it*/
  const [centreEntityData, setCentreEntityData] = React.useState([]);  
  function getEntityData(centerId){
      var data = '{allEntityList{ entityDataList{entityName,entityId,isActive,createdBy,lastModifiedBy,centreId,basicEntity{basicEntityId}}}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          var entityList = response.data.data.allEntityList.entityDataList;
          console.log("All entity List");
          console.log(entityList);
          var tmpEntityData = [];
          entityList.forEach((item, index)=>{
              if (item.isActive && item.centreId === centerId) {  
                  var be = item.basicEntity
                  var arrayData = {}
                  arrayData["entityId"] = item.entityId;
                  arrayData["entityName"] = item.entityName;
                  tmpEntityData.push(arrayData);
              }
          })
          console.log(tmpEntityData);
          setCentreEntityData(tmpEntityData);
      })
      .catch(function (error) {
          console.log("Entity Loding error" + error);
      });
  }


  const menuHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuOpen = Boolean(anchorEl);

  const menuHandleClose = () => {
    setAnchorEl(null);
  };

  /** Get Unique Array From Array */
  function getUnique(arr, index) {
    const unique = arr
         .map(e => e[index])
         // store the keys of the unique objects
         .map((e, i, final) => final.indexOf(e) === i && i)
         // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);      
     return unique;
  }


  /** buy collection */

/**
 * TRANSTER CHECK BOX
 */





 const [checked, setChecked] = React.useState(true);
 const [checkedSecond, setCheckedSecond] = React.useState(true);

 const [chaCenter, seChaCenter] = useState([])
 const [chaBuy, setChaBuy] = useState([])
 const [transBtn, setTransBtn] = useState(false)

 var empArray = [];

 const handleChangeCenter = (data) => {

  centerCollection.forEach((da1,index)=> {
    if(da1.collectionId === data){
      if(checked === true){
        console.log(data  + "our value") ;
        chaCenter.push(data)
        // arrayCenter.push(data)
    

      }
      setChecked(!checked)
      
    }
  })

   
};



const handleChangeBuy = (data1) => {


  canBuycollection.forEach((da2,index)=> {
    if(da2.collectionId === data1){
      if(checkedSecond === true){
        console.log(data1 + "our value") 
        
        chaBuy.push(data1)
        
        // arrayBuy.push(data1) 
        
          
      }
      setCheckedSecond(!checkedSecond)
      
    }
    
  })
    
};

console.log(chaBuy)
empArray.push(chaCenter,chaBuy)
console.log(empArray)


 function transfercentre(e){
        if(empArray[0].length === 0 || empArray[1].length === 0){
          alert('Please Select Atleast one Collection to Tranfer To Marketing centre ')
        }
        
    }



  return (
    <>
        <div className={classes.pageTop}>
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.pageBodyStyle}>
                    <Grid item xs={12}>
                        <Box sx={{ minWidth: 120 }}>
                          <Card className={classes.root}>
                              <div className={classes.posRelative}>      
                                  <CardMedia component="img" image={titleImage} title="Risk Group" alt="Ris Group"/> 
                                      <div className={classes.topImagePos}> 
                                          <p className={classes.topHeaderText1}>
                                          Tranfer To Marketing Center  
                                          </p>
                                          {/* <p className={classes.firstpha}> Buy Required questionnaire Collections And Evaluate your Risk </p> */}
                                      </div>  
                              </div>
                          </Card>        
                        </Box>
                    </Grid> 
                    <Grid item xs={12}>
                        <div role="presentation" onClick={handleClick}>
                          <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" to="/login" id='focusHomeLink'>
                              Home
                            </Link>
                            <Typography color="text.primary">Tranfer To Marketing centre</Typography>
                          </Breadcrumbs>
                        </div>
                    </Grid>
                    {/* 

                    <Grid item xs={12}>
                      <Link to={`/questioPage/1`}>
                        <Button variant="outlined" size='small' className={classes.btnBorderpurple}> 
                          Send Mail to Users For Risk Assessment
                        </Button>
                      </Link>
                    </Grid>
                    */}
                    <Grid item xs={12}>
                      <Button variant="outlined"  className={classes.btnwidthLargeExtra}
                      onClick={() =>{ transfercentre()}}
                      >Tranfer to marketing centre</Button>
                    </Grid>

                    {
                      centerCollection.map((quesmain) => {
                        console.log(quesmain)
                        return(
                        <>
                        <Grid item xs={6} >
                          
                      {/* <input type='hidden' value={quesmain.title} onChange={handleChangeCustomObjective}></input> */}
                          <Card variant="outlined" className={classes.cardoutcenter}>
                            <CardHeader className={classes.qusCardHeadermapdata} 
                              avatar={
                                <Avatar sx={{ bgcolor: '#9155FD' }} aria-label="recipe">
                                    {quesmain.title.charAt(0)}
                                </Avatar>
                              }
                              action={
                                <>
                           
                              {/* <input type='hidden' value={e => setClId(quesmain.title)}></input> */}
                                  <p className={classes.pMarginBottomZero}> 
                                  <Checkbox
                                      
                                      onChange={() =>{ handleChangeCenter(quesmain.collectionId)}}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      value={checked}
    />      
    
                                  </p> 
                                </>
                              }
                              title={quesmain.title}                             
                            />
                            <div>
                              <CardContent>
                                <div>
                                  <Grid container spacing={2}>
                                    <Grid item xs={3} sx={{m:1}}>
                                      <div>
                                        {/* 
                                        <img src={quesmain.imageUrl} alt={quesmain.title} height={100} width={100} ></img>
                                        */}
                                        <img src={CollectionImg} alt={quesmain.title} height={80} width={80} ></img>
                                      </div>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <div className={classes.descriptionStyle}>
                                        <p>{quesmain.description}</p>
                                      </div> 
                                    </Grid>
                                    {quesmain.parentCollectionId === null && (
                                      <Grid item xs={12}>
                                        <Stack direction="row" spacing={1} className={classes.rowbtn}>
                                          <Button variant="outlined"  className={classes.btnwidth} 
                                            onClick={() =>{ getQuestionsInCollection(quesmain.collectionId,1);handleClickOpen()}}
                                            >
                                            see questions
                                          </Button>
                                          <Link id={quesmain.collectionId} to={`/editcollection/${quesmain.collectionId}`}
                                            >
                                          <Button variant="outlined"  className={classes.btnwidthLarge} 
                                            >
                                            Edit This Collection
                                          </Button>
                                          </Link>
                                        </Stack>
                                      </Grid>
                                    )}
                                    {quesmain.parentCollectionId != null && (
                                      <>
                                      <Grid item xs={12}>
                                        <Stack direction="row" spacing={1} className={classes.rowbtnWithoutTop}>
                                          <Button variant="outlined"  className={classes.btnwidth} 
                                            onClick={() =>{ getQuestionsInCollection(quesmain.collectionId,1);handleClickOpen()}}
                                            >
                                            see questions
                                          </Button>
                                         
                                        </Stack>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Stack direction="row" spacing={1} className={classes.rowbtnMinusTop}>
                                          <Link id={quesmain.collectionId} to={`/editcollection/${quesmain.collectionId}`}
                                            >
                                          <Button variant="outlined"  className={classes.btnwidth} 
                                            
                                            >
                                          Edit Your Section
                                          </Button>
                                          </Link>
                                        </Stack>
                                      </Grid>
                                      </>
                                    )}
                                  </Grid>
                                  </div>  
                                </CardContent> 
                              </div>
                          </Card>
                        </Grid>
                        </>
                        )
                      })
                    }

                    {
                      canBuycollection.map((quesmainn) => {
                        //if (quesmainn.centreId != golobalCentreId && !centerCollection.includes(n => n.collectionId)) {
                          return(
                            <Grid item xs={6} >
                              <Card variant="outlined" className={classes.cardoutcenter}>
                                <CardHeader className={classes.qusCardHeadermapdata} 
                                  avatar={
                                    <Avatar sx={{ bgcolor: '#9155FD' }} aria-label="recipe">
                                        {quesmainn.title.charAt(0)}
                                    </Avatar>
                                  }
                                  action={
                                    <>
                                      <p quesmainn={classes.pMarginBottomZero}> 

                                      <Checkbox
                                           onChange={() =>{ handleChangeBuy(quesmainn.collectionId)}}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                          value={checkedSecond}
                                        />
                                         </p> 
                                    </>
                                  }
                                  title={quesmainn.title}
                                />
                                <div>
                                <CardContent>
                                  <div className={classes.quesimggg}>
                                    <Grid container spacing={1}>
                                      <Grid item xs={3} sx={{m:1}}>
                                        <div>
                                          {/* 
                                          <img src={quesmain.imageUrl} alt={quesmain.title} height={100} width={100} ></img>
                                          */}
                                          <img src={CollectionImg} alt={quesmainn.title} height={80} width={80} ></img>
                                        </div>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <div className={classes.descriptionStyle}>
                                          <p>{quesmainn.description}</p>
                                        </div> 
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Stack direction="row" spacing={1} className={classes.rowbtn}>
                                          <Button variant="outlined"  className={classes.btnwidth} 
                                            onClick={() =>{ getQuestionsInCollection(quesmainn.collectionId,2);handleClickOpen()}}
                                            >
                                            see some questions
                                          </Button>
                                         
                                        </Stack>
                                      </Grid>  
                                    </Grid>
                                  </div>  
                                </CardContent> 
                              </div>
                          </Card>
                        </Grid>
                          )
                        //}
                        
                      })
                    }
  
                </Grid>
            </Grid>  
        </div>

{/** Start Dialog Boxes */}   
{/** Question display for each Collection */}                
<div>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'sm'}
      >
      <DialogTitle id="alert-dialog-title" style={{textAlign:'center'}}>
        {quesTitle}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          id="alert-dialog-description">
            {buyQuestionShow && 
              quesData.map((quData,index)=>{
                if (index < 5) {
                    return(
                      <Grid container spacing={1}>
                        <Grid item xs={1}>
                          {index+1} )
                        </Grid>
                        <Grid item xs={11}>
                          {quData.question}
                        </Grid>
                      </Grid>
                    )
                }
              })
            }
            {!buyQuestionShow && 
              quesData.map((quData,index)=>{
                    return(
                      <Grid container spacing={1}>
                        <Grid item xs={1}>
                          {index+1} )
                        </Grid>
                        <Grid item xs={11}>
                          {quData.question}
                        </Grid>
                      </Grid>
                    )
              })
            }
        </DialogContentText>
      </DialogContent>
        <DialogActions dividers>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
    </Dialog>
  </div>

 

 

    </>







  )
}

export default Tranfertomarketingcenter