

import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';
import { height } from '@mui/system';
import "../Css/EvaluateComponent.css";

const TransferToExcelHierarchy = ({ arrayForTransferExcel, riskName }) => {
  const handleDownload = () => {
    var arrX = [
      [riskName],
      [''],
      ["No" ,"Risk Name", "Objective Name", "Component Name", "Principal Name", "Question" ,"Question Type"],
    ];

    arrayForTransferExcel.forEach((data, index) => {
      arrX.push([
        (index + 1) ,
        data.riskName,
        data.objectiveName,
        data.componentName,
        data.principalName,
        data.question,
        data.questionType,
      ]);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(arrX);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var fileName = "data.xlsx";
    if (riskName != undefined && riskName != null) {
      fileName = riskName;
    }
    fileName = fileName + ".xlsx";

    saveAs(excelData, fileName);
  };

  return (
    <div>
      <Button className='btnNormal' variant='outlined' onClick={handleDownload}>
        Transfer to Excel File
      </Button>
    </div>
  );
};

export default TransferToExcelHierarchy;


