import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import titleImage from '../assets/risktitle.jpg';
import CardHeader from '@mui/material/CardHeader';
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import axios from 'axios'
import { CONFIG } from '../config';
import parseHtml from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import {
  Container,
  CssBaseline,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

const BlogPageForSuperAdminEndPoint = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const keyCode = queryParams.get('keyCode');
    const domainName = queryParams.get('domainName');


    const [loggedDomainName, setLoggedDomainName] = useState('');
    const [pageTopHtml, setPageTopHtml] = React.useState('');
    const [createdBy, setCreatedBy] = React.useState('');
    const [createdDate, setCreatedDate] = React.useState('');

    useEffect(() => {
      /*  
      var domainName = window.location.hostname;
        var hostNameArr = domainName.split('www.')
        if (hostNameArr.length > 1) {
          domainName = hostNameArr[1]  
        }else{
          domainName = hostNameArr[0] 
        }
        */
        setLoggedDomainName(domainName);
        getAllSubPages();
    }, []);

    function getAllSubPages() {

        axios(CONFIG.EXPERT_SERVER_URL + `/blogArticlePage/filter?domainName=` + domainName, {
          method: 'get',
          headers: {
            'Content-Type': 'text/plain',
          },
        })
        .then(function (response) {
            console.log("++++Login Page Sub Pages Details +++++++++++++++++");
            console.log(response.data);
            
            response.data.forEach((data) => {
                        if (data.keyCode === keyCode){
                            if (data.content != undefined && data.content != null) {
                                var tArr = data.content.split("<body>");
                                if (tArr.length > 1) {
                                  var tArr1 = tArr[1].split("</body>");
                                  setPageTopHtml(tArr1[0].replaceAll("\\n", ''));
                                } else {
                                  if (data.content != "") {
                                    setPageTopHtml(data.content.replaceAll("\\n", ''));
                                  }
                                }
                            }
                            if (data.createdUserName != undefined && data.createdUserName != null) {
                                setCreatedBy(data.createdUserName);
                            }
                            if (data.updatedDate != undefined && data.updatedDate != null) {
                                setCreatedDate(data.updatedDate);
                            }
                        }
                    })
        })
        .catch(function (error) {
            console.log(error);
        });
    }
  
    const classes = useStyle();


    const [openImage, setOpenImage] = React.useState(false);
    const [imageHtmlData, setImageHtmlData] = React.useState('');
  
    function handleCloseClick() {
      setOpenImage(false)
    }
  
    setTimeout(() => {
  
      var imgTags = document.getElementsByTagName('img');
      for (var i = 0; i < imgTags.length; i++) {
        imgTags[i].onclick = function () {
          var str = "<img src=\"" + this.src + "\" />";
          setImageHtmlData(str)
          setOpenImage(true);
        };
        imgTags[i].onmouseover = function () {
          this.style.cursor = 'pointer';
        };
      }
    }, 1000);
  return (
<>
  <Header />
  <Container>
            <CssBaseline />
            <div className='pageTopRC'>
                <Grid container spacing={2}>
                    <Grid container spacing={2} className='pageBodyStyleRC'>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                <Grid item xs={12}>
          {'Created By '}{createdBy} {' On '} {createdDate}
        </Grid>
        <Grid item xs={12}>
          <div>{parseHtml(pageTopHtml)}</div> 
        </Grid>

                </Grid>
            </div>

        </Container>
  
  
  
        <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>
  
  
  
  
</>
  )
}

export default BlogPageForSuperAdminEndPoint