export const standardDataJs = [
		{
			standardId:"1", 
			standardName:"Coso2013",
            description:"2013coso standerd",
            centreId:"globle",
			objectives:[
				{
					objectiveId:"1",
					name:"Operations",
				},
				{
					objectiveId:"2",
					name:"Reporting ",
				},
				{
					objectiveId:"3",
					name:"Complaince",
				}
			],
			components:[
				{
					componentId:"1", 
					name:"Control environment",
				},
				{
					componentId:"2",
					name:"Risk assessment",
				},
				{
					componentId:"3",
					name:"Control activities",
				},
				{
					componentId:"4",
					name:"Information and communication",
				},
				{
					componentId:"5",
					name:"Evaluations",
				}
			],
			principles:[
				{
					principalId:"1",
					name:"Demonstrates commitment to integrity and ethical values"
				},
				{
					principalId:"2",
					name:" Exercises oversight responsibility"
				},
				{
					principalId:"3",
					name:" Establishes structure, authority, and responsibility"
				},
				{
					principalId:"4",
					name:" Demonstrates commitment to competence"
				},
				{
					principalId:"5",
					name:" Enforces accountability"
				},
				{
					principalId:"6",
					name:"Specifies suitable objectives"
				},
				{
					principalId:"7",
					name:"Identifies and analyzes risk"
				},
				{
					principalId:"8",
					name:"Assesses fraud risk"
				},
				{
					principalId:"9",
					name:"Identifies and analyzes significant change"
				},
				{
					principalId:"10",
					name:"Selects and develops control activities"
				},
				{
					principalId:"11",
					name:"Selects and develops general controls over technology"
				},
				{
					principalId:"12",
					name:"Deploys control activities through policies and procedures"
				},
				{
					principalId:"13",
					name:"Uses relevant information"
				},
				{
					principalId:"14",
					name:"Communicates internally"
				},
				{
					principalId:"15",
					name:" Communicates externally"
				},
				{
					principalId:"16",
					name:"Conducts ongoing and/or separate evaluations"
				},
				{
					principalId:"17",
					name:"Evaluates and communicates deficiencies"
				}
				
			],
            entities:[
                {
					standardEntityId:"1",
					entityId:"16",
					name:"admin WTS"
				}
            ]    
		},
		{
			standardId:"2", 
			standardName:"Coso2017",
            description:"2017coso standerd",
            centreId:"globle",
			objectives:[
				{
					objectiveId:"1",
					name:"Mission, vision and core values",
				},
				{
					objectiveId:"2",
					name:"Strategy Developments",
				},
				{
					objectiveId:"3",
					name:"Business Objective Formulation",
				},
				{
					objectiveId:"4",
					name:"Implementation and Performance",
				},
				{
					id:"5",
					name:"Enhanced value",
				}
			],
			components:[
				{
					componentId:"1",
					name:"Governance and Culture",
				},
				{
					componentId:"2", 
					name:"Strategy and Objective-Setting",
				},
				{
					componentId:"3", 
					name:"Performance",
				},
				{
					componentId:"4", 
					name:"Review and Revision",
				},
				{
					componentId:"5", 
					name:"Information,Communication and Reporting",
				}
			],
			principles:[
				{
					principalId:"1",
					name:"Exercises Board Risk Oversight"
				},
				{
					principalId:"2",
					name:"Establishes Operating Structures"
				},
				{
					principalId:"3",
					name:"Defines Desired Culture"
				},
				{
					principalId:"4",
					name:"Demonstrates Commitment to Core Values"
				},
				{
					principalId:"5",
					name:"Attracts, Develops, and Retains Capable Individual"
				},
				{
					principalId:"6",
					name:"Analyzes Business Context"
				},
				{
					principalId:"7",
					name:"Defines Risk Appetite"
				},
				{
					principalId:"8",
					name:"Evaluates Alternative Strategies"
				},
				{
					principalId:"9",
					name:"Formulates Business Objectives"
				},
				{
					principalId:"10",
					name:"Identifies Risk"
				},
				{
					principalId:"11",
					name:"Assesses Severity of Risk"
				},
				{
					principalId:"12",
					name:"Prioritizes Risks"
				},
				{
					principalId:"13",
					name:"Implements Risk Responses"
				},
				{
					principalId:"14",
					name:"Develops PortfolioVie"
				},
				{
					principalId:"15",
					name:"Assesses Substantial Change"
				},
				{
					principalId:"16",
					name:"Reviews Risk and Performance"
				},
				{
					principalId:"17",
					name:"Pursues Improvement in Enterprise Risk Management"
				},
				{
					principalId:"18",
					name:"Leverages Information and Technology"
				},
				{
					principalId:"19",
					name:"Communicates Risk Information"
				},
				{
					principalId:"20",
					name:"Reports on Risk, Culture, and Performance"
				}
			],
            entities:[
                {
					standardEntityId:"1",
					entityId:"16",
					name:"admin WTS"
				}
            ]    
		},
		{
			standardId:"3", 
			standardName:"Iso31000",
            description:"ISO31000 standerd",
            centreId:"globle",
            objectives:[],
            components:[],
			principles:[
				{
					principalId:"1",
					name:"Integrated",
				},
				{
					iprincipalIdd:"2",
					name:"Structured and comprehensive",
				},
				{
					principalId:"3",
					name:"Customized",
				},
				{
					principalId:"4",
					name:"Inclusive",
				},
				{
					id:"5",
					name:"Dynamic",
				},
				{
					principalId:"6",
					name:"Best available information",
				},
				{
					principalId:"7",
					name:"Human and cultural factors",
				},
				{
					principalId:"8",
					name:"Continual improvement",
				}
			],
            entities:[
                {
					standardEntityId:"1",
					entityId:"16",
					name:"admin WTS"
				},
				{
					standardEntityId:"2",
					entityId:"20",
					name:"Volunteer"
				}
            ]    
		}
	]
