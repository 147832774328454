import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Paper,
    Grid,
    Container,
    CssBaseline,
    FormControlLabel,
    Switch as MuiSwitch,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowBack, StarBorderPurple500Rounded } from '@mui/icons-material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import axios from 'axios';
import parseHtml from 'html-react-parser';
import Checkbox from '@mui/material/Checkbox';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import { CONFIG } from '../config';
import { Box } from '@material-ui/core';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DoneIcon from '@mui/icons-material/Done';
import Header from '../Components/Header';
import BlogComponentClient from '../Components/BlogComponentClient';

const packageBorderStyle = {
    border: '1px solid #ccc', 
    borderRadius: '5px',      
    padding: '10px', 
};

const BlogPageForSuperAdmin = () => {
    const [PageName, setPageName] = useState('Blog');
    const [unChangedpricingData, setUnChangedpricingData] = useState([]);
    const [headerContent, setHeaderContent] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    useEffect(() => {
        getData();
    }, []);
    const [globalCentreId, setGlobalCentreId] = React.useState('');
    function getData() {
        getAllPricingData('superadmin');
    }

    const [pricingData, setPricingData] = useState([]);
    const [commonPricingFeatures, setCommonPricingFeatures] = useState([]);
    const [minimumWidth, setMinimumWidth] = useState(800);

    function getAllPricingData(centreId) {
        axios(CONFIG.MAIN_URL + `/pricingMethods/all`, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("All package Returns");
                console.log(response.data);
                response.data.forEach((item) => {
                    if (item.centreId === centreId) {
                        setPricingData(item.pricingMethods.slice().sort((a, b) => a.orderNumber - b.orderNumber));
                        if (item.commonPricingFeatures !== undefined && item.commonPricingFeatures !== null) {
                            setCommonPricingFeatures(item.commonPricingFeatures);
                        }
                        if (item.pricingMethods !== undefined && item.pricingMethods !== null) {
                            setUnChangedpricingData(item.pricingMethods.slice().sort((a, b) => a.orderNumber - b.orderNumber));
                            if (item.pricingMethods.length > 0) {
                                if (item.pricingMethods.length === 4) {
                                    setMinimumWidth(1100);
                                }
                                if (item.pricingMethods.length === 5) {
                                    setMinimumWidth(1300);
                                }
                                if (item.pricingMethods.length === 6) {
                                    setMinimumWidth(1500);
                                }
                                if (item.pricingMethods.length > 6) {
                                    setMinimumWidth(1600);
                                }
                            }
                        }
                        setHeaderContent(item.pricingPageHeader)
        
              
                    }
                  })  


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const navigate = useNavigate();

    return (
        <>
        <Header />
        <Container>
            <CssBaseline />
            <div className='pageTopRC'>
                <Grid container spacing={2}>
                    <Grid container spacing={2} className='pageBodyStyleRC'>
                        <Grid item xs={12}>
                            <div role="presentation" onClick={handleClick}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" to="/">
                                        <span className='breadcrumbFonts'>Home</span>
                                    </Link>
                                    <Typography color="text.primary"><span className='breadcrumbFonts'>{PageName}</span></Typography>
                                </Breadcrumbs>
                            </div>
                            
                        </Grid>
                        <Grid item xs={12}>
                            <a className='goBack' onClick={() => navigate(-1)}><ArrowBack /> Go Back</a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div>
                            <BlogComponentClient/>
                        </div>
                    </Grid>
                </Grid>
            </div>

        </Container>
        </>
    );
}

export default BlogPageForSuperAdmin;
