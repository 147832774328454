import React, {useEffect,useState} from 'react'
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from "@mui/material/Typography";
import { CONFIG } from '../config'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { ArrowBack } from '@mui/icons-material';



function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}



const TransferCollection = () => {

  
  const navigate = useNavigate();
  const classes = useStyle();

  const [waitingOpen, setWaitingOpen] = React.useState(false);

  const [golobalCentreId, setGolobalCentreId] = React.useState();


/** ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  useEffect(() => {
    getData();
  }, [])

  function compareQa( a, b ) {
    var nameA = a.createdDate; // ignore upper and lowercase
    var nameB = b.createdDate; // ignore upper and lowercase
    if ( nameA < nameB ){ return -1;}
    if ( nameA > nameB ){ return 1;}
    return 0;
}
function compareQd( a, b ) {
    var nameA = a.createdDate; // ignore upper and lowercase
    var nameB = b.createdDate; // ignore upper and lowercase
    if ( nameA > nameB ){ return -1;}
    if ( nameA < nameB ){ return 1;}
    return 0;
}


  function getData () {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId);
    getAllCollectionToTransfer(data.centreId);
    console.log(data.name);
    getAllCollection();
    getAllUpdatedClientCentres();
  }

  /** Get All Updated Centers */
  const [allUpdatedClientCentres, setAllUpdatedClientCentres] = React.useState([]);
  function getAllUpdatedClientCentres(){
    axios(CONFIG.MAIN_URL+'/clientCentreDetails/all',{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get All Updated Client Centers");
      console.log((response.data));
      setAllUpdatedClientCentres(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  /** Get All Available Collection */
  const [allCollection, setAllCollection] = useState([]); // Get All Collections  
  function getAllCollection(){
    axios(CONFIG.MAIN_URL+'/questionnaire/all',{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get All Available Collection");
      console.log((response.data));
      
      setAllCollection(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  /** Get All Transaction Collection */
  const [transactionCollection, setTransactionCollection] = useState([]); // Get All Collections  
  const [allowMaxTransferCollection, setAllowMaxTransferCollection] = React.useState(0);
  const [currentTransferCount, setCurrentTransferCount] = React.useState(0);
  const [rows, setRows] = useState([]);
  var tmpArray = [];
  function getAllCollectionToTransfer(centreId){
    axios(CONFIG.MAIN_URL+'/transactiondata/all',{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get All Transaction Collection");
      console.log((response.data));

      setTransactionCollection(response.data);
      //rowSettings(response.data);
      var arr = response.data;
      arr = arr.sort (compareQd);

      rowSettingsStr(arr,centreId);

      axios(CONFIG.MAIN_URL+'/centreLimitation/all',{
        method: 'get',
      })
      .then(function (res) {
        console.log("Get All Maximum Collection Centers");
        console.log((res.data));
        res.data.forEach((item)=>{
          if (item.centreId === centreId ){
            setAllowMaxTransferCollection(item.maximumCollectionTransfered);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    })
    .catch(function (error) {
      console.log(error);
    });
  }

 

  function rowSettingsStr(arraData, centreId){
    var count = 1;
    var transferCount = 0;
    var tmpArrayStr = "";
    setRows([]);
    tmpArrayStr += "[";
    arraData.forEach((item)=>{
      if (item.expertCentreId === centreId) {
        var tmpObj = {};
        tmpObj["id"] = count;
        tmpObj["transactionId"] = item.transactionId;
        tmpObj["title"] = item.title;
        tmpObj["description"] = item.description;
        tmpObj["collectionUniqueName"] = item.collectionUniqueName;
        tmpObj["parentCollectionUniqueName"] = item.parentCollectionUniqueName;
        tmpObj["createdDate"] = item.createdDate;
        if (item.transferStatus === "Transfered"){
          transferCount ++;
        }
        tmpObj["transferStatus"] = item.transferStatus;
        tmpObj["updatedDate"] = item.updatedDate;
        tmpObj["userCentreId"] = item.userCentreId;
        tmpObj["expertCentreId"] = item.expertCentreId;
        tmpObj["userCentreName"] = item.userCentreName;
        tmpObj["userId"] = item.userId;
        tmpObj["userServerUrl"] = item.userServerUrl;
        var sendJson = JSON.stringify(tmpObj);
        if (count === 1){
          tmpArrayStr += sendJson;
        }else{
          tmpArrayStr += "," + sendJson;
        }
        count ++;
      }  
    })
    tmpArrayStr += "]";
    setRows(JSON.parse(tmpArrayStr));
    setCurrentTransferCount(transferCount);
}
  

  const [maxWidth, setMaxWidth] = React.useState('md');
  const [userDerailsOpen, setUserDerailsOpen] = React.useState(false);
  const [userDerailsScroll, setUserDerailsScroll] = React.useState('paper');

  const userDetailsHandleClickClose = () => {
    setUserDerailsOpen(false);
  };


  const [userCentreCode, setUserCentreCode] = React.useState('');
  const handleChangeUserCentreCode = (event) => {
    var id = event.target.value;
    setUserCentreCode(id);
    allUpdatedClientCentres.forEach((item)=>{
      if (item.centreId === id ){
        setUserCentreName(item.centreName);
        setServerUrl(item.serverUrl);
      }
    });

  };

  const [userCentreName, setUserCentreName] = React.useState('');
  const handleChangeUserCentreName = (event) => {
    setUserCentreName(event.target.value);
  };

  const [userServerUrl, setServerUrl] = React.useState('');
  const handleChangeUserServerUrl = (event) => {
    setServerUrl(event.target.value);
  };


  
  const [boughtCollectionObject, setBoughtCollectionObject] = React.useState(null);
  const [userId, setUserId] = React.useState('');
  const [selectedTransactionId, setSelectedTransactionId] = React.useState(0);
  
  function confirmUserDetailsFunction() {
    var canBuy = true;
    if (userCentreCode === ''){
      alert("Please enter Your Centre Code");
      canBuy = false;
    }else{
      if (userCentreName === ''){
        alert("Please enter Your Centre Name");
        canBuy = false;
      }else{
        if (userServerUrl === ''){
          alert("Please enter Your Domain URL");
          canBuy = false;
        }
      }
    }  

    if (canBuy){

      var buyCollectionTitle1 = boughtCollectionObject.title;
      var buyCollectionStatus1 = boughtCollectionObject.status;

      setWaitingOpen(true);
      
      var sendStr = "mutation{createCollectionData(collectionName:\"" + buyCollectionTitle1 + "\",status:\"" + buyCollectionStatus1 + "\",centreId:\"" + golobalCentreId + "\"){collectionId,collectionName,status,centreId }}"; 
      axios(CONFIG.MAIN_URL+'/graphql',{
        method: 'post',
        headers: { 
          'Content-Type': 'text/plain'
          },
        data : sendStr  
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        let rtnData = response.data;
        console.log("++++++++++ Collection Data save in PG");
        console.log(rtnData);
        console.log("++++++++++ Collection Data save in PG");

        var collectionUniqueName = boughtCollectionObject.collectionUniqueName;
        var tmpArr = collectionUniqueName.split("_");
        var newCollectionUniqueName = userCentreCode + "_" + tmpArr[0] + "_questionnaire_"+ rtnData.data.createCollectionData.collectionId;

        let mainObj = {};
        let obj = {};
        obj["userCentreName"]= userCentreName;
        obj["userCentreId"]= userCentreCode;
        obj["userId"]= userId;
        obj["transactionId"]= selectedTransactionId;
        obj["transferStatus"]= "Pending";
        obj["userServerUrl"]= userServerUrl;
        obj["collectionUniqueName"]= newCollectionUniqueName;
        obj["parentCollectionUniqueName"]= boughtCollectionObject.collectionUniqueName;
        obj["parentCollectionId"]= boughtCollectionObject.id;
        obj["centreId"]= golobalCentreId;
        mainObj["transferDetails"]= obj;
        boughtCollectionObject["documentCreator"] = "user";
        mainObj["questionnaireDetails"]= boughtCollectionObject;
        console.log(JSON.stringify(mainObj));
        var sendJson = JSON.stringify(mainObj);

        console.log(CONFIG.MAIN_URL+'/questionnaire-transactions/buy');
        //http://localhost:8089/questionnaire-transaction/buy
        axios(CONFIG.MAIN_URL+'/questionnaire-transactions/buy',{
          method: 'post',
          headers: { 
            'Content-Type': 'application/json',        
          },  
          data:sendJson
        })
        .then(res =>  {
          console.log("++++++++++++++Purchased Return Data+++++++++++++++++");
          console.log(res.data);
          alert('Prepared to Transfer the Questionnaire Collection. This is now ready to Transfer.')
          setUserCentreCode('');
          setUserCentreName('');
          setServerUrl('');

          setWaitingOpen(false);
      
          setUserDerailsOpen(false);
          getAllCollectionToTransfer(golobalCentreId);
        })
        .catch(function (error) {
          console.log(error);
          setWaitingOpen(false);
        });
      })
      .catch(function (error) {
        console.log(error);
        setWaitingOpen(false);
        alert("Error saving Collection Data to Database Server -:" + error);
      });
    }
  }

  
  function setPrepare(no,transactionId,collectionUniqueName,userCentreId,userServerUrl, userCentreName, userId, parentCollectionUniqueName, expertCentreId){
    
    //setUserDerailsOpen(true);
    console.log(currentTransferCount);
    console.log(allowMaxTransferCollection);

    if (currentTransferCount === allowMaxTransferCollection || currentTransferCount > allowMaxTransferCollection ){
      alert("Sorry!. You have exceeded maximum number of transfer collection granted by the System Administrator. Please contact System Adminstrator to increase the transfer collections.");
    }else{
      var boughtCollectionObject = {};
      allCollection.forEach((item)=>{
        if (item.collectionUniqueName === parentCollectionUniqueName){
          boughtCollectionObject = item;
        }
      });
      var userCentreCode = '';
      var userCentreName = '';
      var userServerUrl = '';
      allUpdatedClientCentres.forEach((item)=>{
        if (item.buyUserId === userId){
          userCentreCode = item.centreId;
          userCentreName = item.centreName;
          userServerUrl = item.serverUrl;
        }
      });

      var canBuy = true;
      if (userCentreCode === ''){
        canBuy = false;
      }else{
        if (userCentreName === ''){
          canBuy = false;
        }else{
          if (userServerUrl === ''){
            canBuy = false;
          }
        }
      }  
      if (canBuy){
        var buyCollectionTitle1 = boughtCollectionObject.title;
        var buyCollectionStatus1 = boughtCollectionObject.status;
        var sendStr = "mutation{createCollectionData(collectionName:\"" + buyCollectionTitle1 + "\",status:\"" + buyCollectionStatus1 + "\",centreId:\"" + golobalCentreId + "\"){collectionId,collectionName,status,centreId }}"; 
        console.log("+++++++++++++++++++++++sendStr+++++++++++++++++++++");
        console.log(sendStr);
        setWaitingOpen(true);
        axios(CONFIG.MAIN_URL+'/graphql',{
          method: 'post',
          headers: { 
            'Content-Type': 'text/plain'
            },
          data : sendStr  
        })
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          let rtnData = response.data;
          console.log("++++++++++ Collection Data save in PG");
          console.log(rtnData);
          console.log("++++++++++ Collection Data save in PG");
  
          var collectionUniqueName = boughtCollectionObject.collectionUniqueName;
          var tmpArr = collectionUniqueName.split("_");
          var newCollectionUniqueName = userCentreCode + "_" + tmpArr[0] + "_questionnaire_"+ rtnData.data.createCollectionData.collectionId;
  
          let mainObj = {};
          let obj = {};
          obj["userCentreName"]= userCentreName;
          obj["expertCentreId"]= expertCentreId;
          obj["title"]= boughtCollectionObject.title;
          obj["userCentreId"]= userCentreCode;
          obj["userId"]= userId;
          obj["transactionId"]= transactionId;
          obj["transferStatus"]= "Pending";
          obj["userServerUrl"]= userServerUrl;
          obj["collectionUniqueName"]= newCollectionUniqueName;
          obj["parentCollectionUniqueName"]= parentCollectionUniqueName;
          obj["parentCollectionId"]= boughtCollectionObject.id;
          obj["centreId"]= golobalCentreId;
          mainObj["transferDetails"]= obj;
          boughtCollectionObject["documentCreator"] = "user";
          mainObj["questionnaireDetails"]= boughtCollectionObject;
          console.log(JSON.stringify(mainObj));
          var sendJson = JSON.stringify(mainObj);
  
          console.log(CONFIG.MAIN_URL+'/questionnaire-transactions/buy');
          //http://localhost:8089/questionnaire-transaction/buy
          axios(CONFIG.MAIN_URL+'/questionnaire-transactions/buy',{
            method: 'post',
            headers: { 
              'Content-Type': 'application/json',        
            },  
            data:sendJson
          })
          .then(res =>  {
            console.log("++++++++++++++Purchased Return Data+++++++++++++++++");
            console.log(res.data);
            //alert('Prepared to Transfer the Questionnaire Collection. This is now ready to Transfer.')
            //let text = "Prepared to Transfer the Questionnaire Collection.\n\nPress 'OK' to confirm.";
            let text = "Ready to prepare the transfer '"+boughtCollectionObject.title+"' questionnaire collection ";
            text += "to the customer '"+userId+"' at the allocated centre '"+userCentreName+"("+userCentreCode+")',\n\nPress 'OK' to confirm.";
            if (window.confirm(text) == true) {
              setUserCentreCode('');
              setUserCentreName('');
              setServerUrl('');
              setWaitingOpen(false);
              setUserDerailsOpen(false);
              getAllCollectionToTransfer(golobalCentreId);
            } else {
              var collectionId = res.data.id;
              var uniqueName = res.data.collectionUniqueName; 
              console.log(CONFIG.MAIN_URL+'/questionnaire-transactions/delete/' + uniqueName + '/' + collectionId);
              //http://localhost:8089/questionnaire-transactions/delete/S01_R01_questionnaire_11/63202c05e4d86c10462273b3
              axios(CONFIG.MAIN_URL+'/questionnaire-transactions/delete/' + uniqueName + '/' + collectionId,{
                method: 'get'
              })
              .then(res =>  {
                console.log("++++++++++++++Delete Rturn+++++++++++++++++");
                console.log(res.data);
                let obj = {};
                obj["userCentreName"]= "";
                obj["expertCentreId"]= expertCentreId;
                obj["title"]= boughtCollectionObject.title;
                obj["userCentreId"]= "";
                obj["userId"]= userId;
                obj["transactionId"]= transactionId;
                obj["transferStatus"]= "Preparing";
                obj["userServerUrl"]= "";
                obj["collectionUniqueName"]= "";
                obj["parentCollectionUniqueName"]= parentCollectionUniqueName;
                obj["parentCollectionId"]= "";
                obj["centreId"]= golobalCentreId;

                var sendJson = JSON.stringify(obj);
                console.log("+++++++++++++++++++++++++++sendJson+++++++++++++++++++++");
                console.log(sendJson);
                axios(CONFIG.MAIN_URL+'/transactiondata/save',{
                  method: 'post',
                  headers: { 
                    'Content-Type': 'application/json',        
                  },  
                  data:sendJson
                })
                .then(res =>  {
                  setWaitingOpen(false);
                  console.log("++++++++++++++Update Transaction Data after Deleting the Collection+++++++++++++++++");
                  console.log(res.data);
                })  
                .catch(function (error) {
                  setWaitingOpen(false);
                  console.log(error);
                });





                
              })
              .catch(function (error) {
                setWaitingOpen(false);
                console.log(error);
                alert("Error Deleting Questionnaire Collection Data to Database Server -:" + error);
              });
    




            }
            
          })
          .catch(function (error) {
            setWaitingOpen(false);
            console.log(error);
            alert("Error saving Questionnaire Collection Data to Database Server -:" + error);
          });
        })
        .catch(function (error) {
          setWaitingOpen(false);
          console.log(error);
          alert("Error saving Collection Data to Database Server -:" + error);
        });
      }else{
        setWaitingOpen(false);
        alert("User Centre code, User Centre Name or User server URL missing. Please contact Administrator to fill those variables");
      }
      //setUserId(userId);
      //setSelectedTransactionId(transactionId);

    }

  }  



  function setTransfer(no,transactionId,collectionUniqueName,userCentreId,userServerUrl, userCentreName, userId, parentCollectionUniqueName, expertCentreId, title){
    
    var userCentreCode = '';
      var userCentreName = '';
      var userServerUrl = '';
      var centerAdminCode = '';
      var centerAdminPassword = '';
      var centerAdminFirstName = '';
      var centerAdminLastName = '';
      var centerAdminEmail = '';
      allUpdatedClientCentres.forEach((item)=>{
        if (item.buyUserId === userId){
          userCentreCode = item.centreId;
          userCentreName = item.centreName;
          userServerUrl = item.serverUrl;
          centerAdminCode = item.centerAdminCode;
          centerAdminPassword = item.centerAdminPassword;
          centerAdminFirstName = item.centreAdminFirstName;
          centerAdminLastName = item.centreAdminLastName;
          centerAdminEmail = item.centreAdminEmail;
        }
      });

      //alert(userServerUrl);
      
    
    
    let text = "Ready to carry out the transfer '"+title+"' questionnaire collection ";
    text += "to the customer '"+userId+"' at the allocated centre '"+userCentreName+"("+userCentreCode+")',\n\nPress 'OK' to confirm.";
    //let text = "You going to Transfer the Questionnaire Collection to the User Centre.\n\nPress 'OK' to confirm.";
    if (window.confirm(text) == true) {
    
      setWaitingOpen(true);
      axios(CONFIG.MAIN_URL+'/questionnaire-transactions/all?collectionUniqueName='+ collectionUniqueName,{
        method: 'get',
      })
      .then(function (response) {
        console.log("Get the Collection from BUY section and Return Collection Data ");
        console.log(response.data);
        
        var collectionData = response.data[0];
        var buyCollectionTitle1 = response.data[0].title;
        var buyCollectionStatus1 = response.data[0].status;

        var sendStr = "mutation{createCollectionData(collectionName:\"" + buyCollectionTitle1 + "\",status:\"" + buyCollectionStatus1 + "\",centreId:\"" + userCentreId + "\"){collectionId,collectionName,status,centreId }}"; 
        axios(userServerUrl+'/graphql',{
          method: 'post',
          headers: { 
            'Content-Type': 'text/plain'
            },
          data : sendStr  
        })
        .then(function (response) {
          console.log("++++++++++ Collection Data save in PG in the User server");
          console.log(JSON.stringify(response.data));
          let rtnData = response.data;
          
          var tmpArr = collectionUniqueName.split("_");
          var newCollectionUniqueName = tmpArr[0] + "_" + tmpArr[1] + "_questionnaire_"+ rtnData.data.createCollectionData.collectionId;
    
          collectionData["collectionUniqueName"]=newCollectionUniqueName;
          console.log("++++++++++ CollectionData");
          console.log(collectionData);
          var sendJson = JSON.stringify(collectionData);
    
          axios(userServerUrl+'/questionnaire-receiver/save',{
            method: 'post',
            headers: { 
              'Content-Type': 'application/json',        
            },  
            data:sendJson
          })
          .then(res =>  {
            console.log("++++++++++++++Transfered the collection to User server+++++++++++++++++");
            console.log(res.data);
            //alert('Successfully transfered Questionnaire Collection to, \n\n  * User Centre  : ' + userCentreName + '(' + userCentreId + ')\n  * User Centre URL :' + userServerUrl + '\n\nThe User will receive an email showing their usercode and password to access the "'+title+'" Questionnaire Collection purchased.' );
      

            let obj = {};
            obj["transactionId"]= transactionId;
            obj["title"]= title;
            obj["userCentreName"]= userCentreName;
            obj["userCentreId"]= userCentreId;
            obj["centreAdminCode"]= centerAdminCode;
            obj["centreAdminPassword"]= centerAdminPassword;
            obj["centreAdminFirstName"]= centerAdminFirstName;
            obj["centreAdminLastName"]= centerAdminLastName;
            obj["centreAdminEmail"]= centerAdminEmail;

            obj["expertCentreId"]= expertCentreId;
            obj["userId"]= userId;
            obj["transferStatus"]= "Transfered";
            obj["userServerUrl"]= userServerUrl;
            obj["collectionUniqueName"]= collectionUniqueName;
            obj["parentCollectionUniqueName"]= parentCollectionUniqueName;
            var sendJson = JSON.stringify(obj);

            console.log(sendJson);
            axios(CONFIG.MAIN_URL+'/transactiondata/save',{
              method: 'post',
              headers: { 
                'Content-Type': 'application/json',        
              },  
              data:sendJson
            })
            .then(res =>  {
              
              console.log("++++++++++++++Update Transaction Data+++++++++++++++++");
              console.log(res.data);
              getAllCollectionToTransfer(golobalCentreId);

              //To transfer Slider data
              axios(CONFIG.MAIN_URL+'/slider-data/all',{
                method: 'get',
              })
              .then(function (resslider) {
                console.log("+++++++++++++++Get All Slider Related data+++++++++++++");
                console.log(resslider.data);
                var sliderData = resslider.data;
                var sendSliderJson = JSON.stringify(sliderData);
                //Transfer Slider Data
                axios(userServerUrl+'/slider-data-receiver/create',{
                  method: 'post',
                  headers: { 
                    'Content-Type': 'application/json',        
                  },  
                  data:sendSliderJson
                })
                .then(res =>  {
                  setWaitingOpen(false);
                  alert('Successfully transfered Questionnaire Collection to, \n\n  * User Centre  : ' + userCentreName + '(' + userCentreId + ')\n  * User Centre URL :' + userServerUrl + '\n\nThe User will receive an email showing their usercode and password to access the "'+title+'" Questionnaire Collection purchased.' );

                })
                .catch(function (error) {
                  setWaitingOpen(false);
                  console.log(error);
                  alert('Successfuly transfered Questionnaire Collection to, \n\n  * User Centre  : ' + userCentreName + '(' + userCentreId + ')\n  * User Centre URL :' + userServerUrl + '\n\nThe User will receive an email showing their usercode and password to access the "'+title+'" Questionnaire Collection purchased.' );
                });
                
              })  
              .catch(function (error) {
                
                setWaitingOpen(false);
                console.log(error);
              });




            })  
            .catch(function (error) {
              setWaitingOpen(false);
              console.log(error);
            });

          })
          .catch(function (error) {
            setWaitingOpen(false);
            console.log(error);
          });
        })
        .catch(function (error) {
          setWaitingOpen(false);
          alert("Error saving Collection Data to Database Server -:" + error);
        });
      })
      .catch(function (error) {
        setWaitingOpen(false);
        console.log(error);
      });
    }
  }

  function setReverse(transactionId,collectionUniqueName,userId,parentCollectionUniqueName,expertCentreId){
    
    setWaitingOpen(true);
    axios(CONFIG.MAIN_URL+'/questionnaire-transactions/all?collectionUniqueName='+ collectionUniqueName,{
      method: 'get',
    })
    .then(function (response) {
      console.log("Get the Collection from BUY section and Return Collection Data ");
      console.log(response.data);
      
      var collectionId = response.data[0].id;
      var title = response.data[0].title;
      var uniqueName = collectionUniqueName; 
      console.log(CONFIG.MAIN_URL+'/questionnaire-transactions/delete/' + uniqueName + '/' + collectionId);
      //http://localhost:8089/questionnaire-transactions/delete/S01_R01_questionnaire_11/63202c05e4d86c10462273b3
      axios(CONFIG.MAIN_URL+'/questionnaire-transactions/delete/' + uniqueName + '/' + collectionId,{
        method: 'get'
      })
      .then(res =>  {
        console.log("++++++++++++++Delete Rturn+++++++++++++++++");
        console.log(res.data);
        let obj = {};
        obj["userCentreName"]= "";
        obj["expertCentreId"]= expertCentreId;
        obj["title"]= title;
        obj["userCentreId"]= "";
        obj["userId"]= userId;
        obj["transactionId"]= transactionId;
        obj["transferStatus"]= "Preparing";
        obj["userServerUrl"]= "";
        obj["collectionUniqueName"]= "";
        obj["parentCollectionUniqueName"]= parentCollectionUniqueName;
        obj["parentCollectionId"]= "";
        obj["centreId"]= golobalCentreId;

        var sendJson = JSON.stringify(obj);
        console.log("+++++++++++++++++++++++++++sendJson+++++++++++++++++++++");
        console.log(sendJson);
        axios(CONFIG.MAIN_URL+'/transactiondata/save',{
          method: 'post',
          headers: { 
            'Content-Type': 'application/json',        
          },  
          data:sendJson
        })
        .then(res =>  {
          console.log("++++++++++++++Update Transaction Data after Deleting the Collection+++++++++++++++++");
          console.log(res.data);
          setWaitingOpen(false);
          alert('Successfully Reversed Questionnaire Collection to previous status.' );
          getAllCollectionToTransfer(golobalCentreId);
        })  
        .catch(function (error) {
          setWaitingOpen(false);
          console.log(error);
        });
      })
      .catch(function (error) {
        setWaitingOpen(false);
        console.log(error);
        alert("Error Deleting Questionnaire Collection Data to Database Server -:" + error);
      });











    })
    .catch(function (error) {
      setWaitingOpen(false);
      console.log(error);
    });
  }






  const renderReverseButton = (params) => {
    return (
      <>
      {params.row.transferStatus === "Pending"?
        <strong>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.fontZise9AndRedSmall}
                onClick={() => {
                    setReverse(params.row.transactionId,params.row.collectionUniqueName,params.row.userId, params.row.parentCollectionUniqueName, params.row.expertCentreId)
                }}
            >
                Reverse
            </Button>
        </strong>
        :''
      }
      </>
    )
}



  const renderDetailsButton = (params) => {
    return (
      <>
      {params.row.transferStatus === "Pending"?
        <strong>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.fontZise9AndGreen}
                onClick={() => {
                    setTransfer(params.row.id,params.row.transactionId,params.row.collectionUniqueName,params.row.userCentreId, params.row.userServerUrl, params.row.userCentreName, params.row.userId, params.row.parentCollectionUniqueName, params.row.expertCentreId, params.row.title)
                }}
            >
                Ready  To  Transfer
            </Button>
        </strong>
        :params.row.transferStatus === "Preparing"?
        <strong>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.fontZise9AndRed}
                onClick={() => {
                    setPrepare(params.row.id,params.row.transactionId,params.row.collectionUniqueName,params.row.userCentreId, params.row.userServerUrl, params.row.userCentreName, params.row.userId, params.row.parentCollectionUniqueName, params.row.expertCentreId)
                }}
            >
                Prepare To Transfer
            </Button>
        </strong>
        :params.row.transferStatus === "Transfered"?
        <strong>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.fontZise9AndBlue}
            >
                Transfered
            </Button>
        </strong>
        :''
      }
      </>
    )
}

const renderTextRiskName = (params) => {
  return (
      <Tooltip title={params.row.riskName}>
      <span className={classes.fontZise10}>{params.row.riskName}</span>        
      </Tooltip>
  )
}
const renderTextId = (params) => {
  return (
      <span className={classes.fontZise10}>{params.row.id}</span>        
  )
}
const renderTextParentCollectionUniqueName = (params) => {
  return (
      <Tooltip title={params.row.parentCollectionUniqueName}>
      <span className={classes.fontZise10}>{params.row.parentCollectionUniqueName}</span>        
      </Tooltip>
  )
}


const renderTextCollectionUniqueName = (params) => {
  return (
      <Tooltip title={params.row.collectionUniqueName}>
      <span className={classes.fontZise10}>{params.row.collectionUniqueName}</span>        
      </Tooltip>
  )
}
const renderTextcreatedDate = (params) => {
  return (
      <Tooltip title={params.row.createdDate}>
      <span className={classes.fontZise10}>{params.row.createdDate}</span>        
      </Tooltip>
  )
}

const renderTextTitle = (params) => {
  return (
    
      <Tooltip title={params.row.title}>
      <span className={classes.fontZise10}>{params.row.title}</span>        
      </Tooltip>
      
  )
}


const renderTextupdatedDate = (params) => {
  return (
      
      <Tooltip title={params.row.updatedDate}>
      <span className={classes.fontZise10}>{params.row.updatedDate}</span>        
      </Tooltip>
     
  )
}


const renderTextuserCentreId = (params) => {
  return (
      <Tooltip title={params.row.userCentreId}>
      <span className={classes.fontZise10}>{params.row.userCentreId}</span>        
      </Tooltip>
  )
}



const renderTextuserCentreName = (params) => {
  return (
      <Tooltip title={params.row.userCentreName}>
      <span className={classes.fontZise10}>{params.row.userCentreName}</span>        
      </Tooltip>
  )
}
const renderTextuserServerUrl = (params) => {
  return (
      <Tooltip title={params.row.userServerUrl}>
      <span className={classes.fontZise10}>{params.row.userServerUrl}</span>        
      </Tooltip>
  )
}

const renderTextUserId = (params) => {
  return (
      <Tooltip title={params.row.userId}>
      <span className={classes.fontZise10}>{params.row.userId}</span>        
      </Tooltip>
  )
}



  const columns = [
    { field: 'id', headerName: 'No', renderCell: renderTextId, resizable: true, width: 10 },
    { field: 'transactionId', headerName: 'No', width: 15, hide: true },
    { field: 'title', headerName: 'Collection Title', renderCell: renderTextTitle, resizable: true, cellClassName: 'super-app-theme--cell', width: 105 },
    { field: 'description', headerName: 'description', width: 15, hide: true },
    { field: 'expertCentreId', headerName: 'expertCentreId', width: 15, hide: true },
    { field: 'parentCollectionUniqueName', headerName: 'Parent Collection', renderCell: renderTextParentCollectionUniqueName, width: 110, resizable: true, cellClassName: 'super-app-theme--cell'},      
    { field: 'collectionUniqueName', headerName: 'User Collection', renderCell: renderTextCollectionUniqueName, width: 110, resizable: true, cellClassName: 'super-app-theme--cell'},
    { field: 'createdDate', headerName: 'Created Date', renderCell: renderTextcreatedDate, resizable: true, cellClassName: 'super-app-theme--cell', width: 95 },
    { field: 'updatedDate', headerName: 'updatedDate', renderCell: renderTextupdatedDate, resizable: true, cellClassName: 'super-app-theme--cell', width: 95 },
    { field: 'userCentreId', headerName: 'Centre Id', renderCell: renderTextuserCentreId, resizable: true, cellClassName: 'super-app-theme--cell', width: 85 },
    { field: 'userCentreName', headerName: 'Centre Name', renderCell: renderTextuserCentreName, cellClassName: 'super-app-theme--cell', resizable: true, width: 90 },
    { field: 'userId', headerName: 'userId', renderCell: renderTextUserId, cellClassName: 'super-app-theme--cell', resizable: true, width: 12},
    { field: 'userServerUrl', headerName: 'Endpoint URL', renderCell: renderTextuserServerUrl, resizable: true, width: 110 },
    {
      field: 'action',
      headerName: 'Transfer Status',
      sortable: false,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: true,
      width: 150,
    },
    {
      field: 'action1',
      headerName: 'Reverse Status',
      sortable: false,
      renderCell: renderReverseButton,
      disableClickEventBubbling: true,
      width: 150,
    }
  
  ];




  /** +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */




  

  const [PageName, setPageName] = useState('Transfer Questionnaire Collection')
  return (
    <>
        <RightBarDataForOtherPages PageName={PageName}/>
        <div className={classes.pageTop}>
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.pageBodyStyle}>
                    <Grid item xs={12}>
                        <div role="presentation" onClick={handleClick}>
                          <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" to="/login" id='focusHomeLink'>
                            <span className='breadcrumbsStyle'>Home</span>
                            </Link>
                            <Typography color="text.primary"><span className='breadcrumbsStyle'>Main menu - Transfer Questionnaire Collections</span></Typography>
                          </Breadcrumbs>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                      <a style={{float: 'right',marginTop: '-36px',color: '#0056b3', cursor:'pointer'}} onClick={() => navigate(-1)}><ArrowBack/>  <span className='breadcrumbsStyle'>Go Back</span></a>
                    </Grid>
                    <Grid item xs={12}>
                         
                        <Grid container spacing={2}>
                            <Box    
                                sx={{
                                    height: 300,
                                    width: '100%',
                                    '& .super-app-theme--cell': {
                                    whiteSpace: 'normal !important',
                                    lineHeight: '1',
                                    },
                                    '& .super-app-theme--Open': {
                                        fontSize: '10px !important',
                                        backgroundColor: 'red !important'
                                    }, 
                                }}
                                >
                                <div style={{ height: 400, width: '100%', fontSize: '9px !important' }}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    //getRowHeight={() => '75px'}
                                    getRowClassName={`super-app-theme--Open`}
                                    disableSelectionOnClick
                                />
                                </div>

                            </Box>
                        </Grid>
                        
                    </Grid>  
  
                </Grid>
            </Grid>  
        </div>




{/** Dialog for User Details */}

<Dialog
    maxWidth={maxWidth}
    open={userDerailsOpen}
    onClose={userDetailsHandleClickClose}
    scroll={userDerailsScroll}
    aria-labelledby="userDerails-scroll-dialog-title"
    aria-describedby="userDerails-scroll-dialog-description"
    >
    <DialogTitle id="draggable-dialog-title">
        Please Select the User server details to Transfer the selected Questionnaire Collection 
    </DialogTitle>
    <DialogContent dividers={userDerailsScroll === 'paper'}>
      <DialogContentText
        id="userDerails-scroll-dialog-description"
        tabIndex={-1}
        >
        <Grid container spacing={2}>
          <Grid item xs={4}>

            <Box sx={{ minWidth: 120}}>    
              <FormControl fullWidth>
                  <TextField
                      label="Select a User Centre to Transfer"
                      sx={{ width: '100%' }}
                      select
                      value={userCentreCode}
                      onChange={handleChangeUserCentreCode}
                      >
                      {allUpdatedClientCentres.map((item)=>(
                          <MenuItem 
                              value={item.centreId}
                              >
                              {item.centreName}
                          </MenuItem>
                      ))}

                  </TextField>
              </FormControl>
            </Box>








          </Grid>     
 
          <Grid item xs={8}>
            <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                <div>
                    <TextField
                        label="User Centre Name"
                        multiline
                        maxRows={2}
                        disabled
                        value={userCentreName}
                        onChange={handleChangeUserCentreName}
                    />
                </div>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                <div>
                    <TextField
                        label="User Server Endpoint URL"
                        multiline
                        maxRows={2}
                        disabled
                        value={userServerUrl}
                        onChange={handleChangeUserServerUrl}
                    />
                </div>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button 
                variant="outlined" 
                size='small'
                className={classes.btnBorderGreenEntityMap}
                onClick={() => confirmUserDetailsFunction()} 
                style={{float:'right'}}>
                    CONFIRM
            </Button>
          </Grid>
       </Grid>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={userDetailsHandleClickClose}>Cancel</Button>
    </DialogActions>
  </Dialog>

{/** Dialog Waiting */}
<Dialog
    maxWidth={'md'}
    open={waitingOpen}
    PaperProps={{style:{backgroundColor: 'transparent', boxShadow: 'none'}}} 
    >
    <CircularProgress disableShrink />
  </Dialog>



    </>







  )
}

export default TransferCollection