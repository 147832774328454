import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import titleImage from '../assets/risktitle.jpg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { red, green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import { collectionData } from '../dataA';
import QuestionerMgtPageItemOthers from '../Components/QuestionerMgtPageItemOthers';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Links from '@mui/material/Link';
import { CONFIG} from '../config'
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete'
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import { Info, KeyboardReturnOutlined } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { standardDataJs } from '../dataB'; 
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import CircularProgress from '@mui/material/CircularProgress';
import { fontSize } from '@mui/system';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Tooltip from '@mui/material/Tooltip';
import { Close } from '@mui/icons-material';
import LogoCube from '../Pages/cube';
import useStyle from '../Css/MainCss';
import { ArrowBack } from '@mui/icons-material';
import '../Css/Standard.css'


function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));  
  
const useStyles = makeStyles((theme) => ({ 
    right: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    pageTop:{
      marginTop: '115px',
    },
    pageBodyStyle: {
     
      paddingTop: '12px',

    },
    root: {
      position: 'relative',
    },
    posRelative: {
      position: 'relative',
    },
    topImagePos: {
      position: 'absolute', 
      top: '33%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    topHeaderText: {
      color: '#d5d5e1',
      fontSize:'25px', 
      paddingBottom:'10px',
    },
    topImageSize: {
      height: '82px'
    },
    stdCard:{
      height:'100%',
      border:'1px solid black'

    },
    tableCard:{
      height:'330px',
      backgroundColor:'#d5d5e1',
      marginTop:'10px'
    },
    tableCardNew:{
      height:'330px',
      backgroundColor:'#d5d5e1',
      marginTop:'10px',
      height: '430px'
    },
    tableCardOptionButtons:{
      padding:'5px',
      border:'1px solid #897979',
      height: '500px'

    },
    borderPadding:{
      padding:'8px'
    },
    stdMainCardHeader:{
      backgroundColor:'#acacbe !important',
      fontSize:'13px !important',
      height:'50px',
      padding:'0px',
      marginTop: '-7px',
    },
    stdCardHeader:{
      backgroundColor:'#d5d5e1 !important',
      fontSize:'13px !important',
      height:'44px',
      padding:'0px',
      marginTop: '-7px',
    },
    stdCardHeadermapdata:{
      backgroundColor: '#ece9fa !important',
      fontSize:'13px',
      height:'70px',
      padding:'0px'
    },
    pStdMarginBottomZero: {
      marginBottom: '0rem !important'
    },
    tableHeadStyle: {
      backgroundColor: '#d5d5e1',
      color: '#ffffff',
    },
    tableThStyle: {
      lineHeight: '0.25rem !important'
    },
    tableThStylePri: {
      lineHeight: '0.25rem !important',
      fontSize: '10px !important'
    },
    tableTrStyle: {
      //lineHeight: '0.20rem !important',
      height: '19px !important'
    },
    tableTrStylePri: {
      //lineHeight: '0.20rem !important',
      height: '19px !important',
      fontSize: '9px !important',
      verticalAlign: 'top important'
    },
    mousePointer: {
      cursor: 'pointer'
    },
    scrollableDiv: {
      height: '245px',
      overflow: 'auto',
      width: '100%',
    },
    scrollablePriDiv: {
      height: '180px',
      overflow: 'auto',
      width: '100%',
    },
    scrollableDivToStandard: {
      height: '550px',
      overflow: 'auto',
      width: '100%',
    },
    mainbtn:{
      justifyContent:'center'
    },
    globalBtn:{
      height: '80px',
      width: '180px',
      backgroundColor: '#f9f9f9 !important',
      color: '#9481df !important',
      borderRadius: '12px !important',
    },
    globalBtnAfter:{
      height: '80px',
      width: '180px',
      backgroundColor: '#d6c3fc !important',
      color: 'black !important',
      borderRadius: '12px !important',
    },
    pageBtn:{
      height: '80px',
      width: '180px',
      backgroundColor: '#f9f9f9 !important',
      color: '#9481df !important',
      borderRadius: '12px !important',
    },
    btnclear:{
      height:'40px'
    },
    btnBorderGreen: {
      border: '1px solid #4C7EE0 !important',
      color: '#4C7EE0 !important',
      backgroundColor:'#BACDF2 !important',
      padding:'5px 10px !important',
      height:'40px'
    },
    btnBorderpurple:{
      border: '1px solid #4C7EE0 !important',
      color: '#4C7EE0 !important',
      padding:'5px 10px !important',
      height: '40px !important',
    },
    btnBorderRed:{
      border: '1px solid red !important',
      color: 'red!important',
      padding:'5px 10px !important',
      height: '40px !important',
      fontSize: '11px !important',
    },
    stdCardShowAll:{
      height:'485px !important',
      border:'1px solid black'
    },
    scrollableDivToStandardShowAll: {
      height: '375px',
      overflow: 'auto',
      width: '100%',
    },
  
  
}));

const CreateCustomStandard = () => {

  const navigate = useNavigate();
  const classe = useStyle();

  const [leftBarGridCount, setLeftBarGridCount] = React.useState(8);   
  const [rightBarGridCount, setRightBarGridCount] = React.useState(4);  
  const [screenSize, setScreenSize] = React.useState();  
  const [visibilityFlag, setVisibilityFlag] = React.useState('visible'); 

  useEffect (() => {
    console.log("++++++++++++window. screen. width+++++++++++++");
    if (window. screen. width <= 480){
      setRightBarGridCount(12)
      setLeftBarGridCount(12)

    }else{
      if (window. screen. width <= 810 && window. screen. width >= 481){
        setRightBarGridCount(12)
        setLeftBarGridCount(12)
      }
    }

    setScreenSize(window. screen. width)
    console.log(window. screen. width);
  },[])

    const classes = useStyles();
    const Input = styled('input')({
      display: 'none',
    });
  
    React.useEffect(() => {
      getSessionData();
    }, []);
      
    const [globalCentreId, setGlobalCentreId] = React.useState('');   
    const [waitingOpen, setWaitingOpen] = React.useState(false);
    function getSessionData () {
      let data = sessionStorage.getItem('sessionstorage');
      data = JSON.parse(data);
      setGlobalCentreId(data.centreId);
      getStandards();
      getEntityData(data.centreId);
      console.log(data)
    }
  
    const [standardData, setStandardData] = React.useState([]);
    function getStandards() {
      setWaitingOpen(true);
      axios(CONFIG.MAIN_URL+'/standard/all',{
        method: 'get',
      })
      .then(function (response) {
        setWaitingOpen(false);
        console.log(response.data);
        console.log(JSON.stringify(response.data));
        //console.log(JSON.stringify(standardDataJs));
        setStandardData(response.data)
        //setStandardData(standardDataJs)
      })
      .catch(function (error) {
        console.log(error);
      });
    };

    const [entityData, setEntityData] = React.useState([]);
    function getEntityData(centreId){
      var data = '{allEntityList{ entityDataList{entityName,entityId,isActive,createdBy,lastModifiedBy,centreId,basicEntity{basicEntityId,entityName,iamRole,forceIamRole}}}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          var entityList = response.data.data.allEntityList.entityDataList;
          var tmpEntityData = [];
          entityList.forEach((item, index)=>{
              if (item.isActive && item.centreId === centreId) {  
                  var be = item.basicEntity
                  var forceIamRole = item.basicEntity.forceIamRole;
                  var iamRole = item.basicEntity.iamRole;  
                  var canTake = true;
                  if ((iamRole === "parent") || (iamRole === "ADMIN" && forceIamRole === "ADMIN") || (iamRole === "supplier" && forceIamRole === "supplier") || (iamRole === "serviceprovider" && forceIamRole === "serviceprovider") || (iamRole === "volunteer" && forceIamRole === "volunteer")){
                    canTake = false;
                  }
                  if (canTake){
                    var arrayData = {}
                    arrayData["entityId"] = item.entityId;
                    arrayData["entityName"] = item.entityName;
                    tmpEntityData.push(arrayData);
                  }  
              }
          })
          console.log(tmpEntityData);
          setEntityData(tmpEntityData);
      
      })
      .catch(function (error) {
          console.log("Entity Loding error" + error);
      });
    }
    /** Sort abject array */
    function compare( a, b ) {
      if ( a.orderNumber < b.orderNumber ){
        return -1;
      }
      if ( a.orderNumber > b.orderNumber ){
        return 1;
      }
      return 0;
    }


    const [headerInfoMessage, setheaderInfoMessage] = useState("Create Custom Standard");
    const [saveBtnName, setSaveBtnName] = useState("Save");
    const [hiddenNamevalue, setHiddenNamevalue] = React.useState('');
    const handleHiddenName = (event) => {
      setHiddenNamevalue(event.target.value);
    };

    const [standardIdvalue, setStandardIdvalue] = React.useState(0);
    const [standardNamevalue, setStandardNamevalue] = React.useState('');
    const handleStandardName = (event) => {
      setStandardNamevalue(event.target.value);
    };
    const [standardDescriptionvalue, setStandardDescriptionvalue] = React.useState('');
    const handleStandardDescription = (event) => {
      setStandardDescriptionvalue(event.target.value);
    };

    const [maxWidth, setMaxWidth] = React.useState('md');
    /** Global Objective Dialog */
    const [globalObjOpen, setGlobalObjOpen] = React.useState(false);
    const [globalObjScroll, setGlobalObjScroll] = React.useState('paper');
    
    const globalObjHandleClickOpen = (scrollType) => () => {
      setGlobalObjOpen(true);
      setGlobalObjScroll(scrollType);
      setGlobalObjectivedData([]);
      setGlobalComponentsData([]);
      setGlobalPrincipalsData([]);
    };
    const globalObjHandleClickClose = () => {
      setGlobalObjOpen(false);
    };
    /** End of Global Objective Dialog */
    
    const [objectiveOrderNumber, setObjectiveOrderNumber] = React.useState(1);
    const [objectiveNameValue, setObjectiveNameValue] = React.useState('');
    const [selectedObjName, setSelectedObjName] = React.useState('');
    const [objectiveList, setobjectiveList] = React.useState([]);
    const [editObjective, setEditObjective] = React.useState(false);
    const [editObjectiveId, setEditObjectiveId] = React.useState(0);
    const [editObjectiveOrderNumber, setEditObjectiveOrderNumber] = React.useState(0);
  
    const handleobjectiveName = (event) => {
      setObjectiveNameValue(event.target.value);
    };

    /** Add to Objective List Table */
    function addToObjListFunc() {
      var canTake = true;
      if (objectiveList != null) {
        objectiveList.forEach((data)=>{
          if (data.name === objectiveNameValue) {  
            alert("Same objective Name. Please use the different name");
            canTake = false;
            return;
          }
        })
      }
      if (objectiveNameValue === "" || objectiveNameValue === " "){
        alert("Please enter Objective Name");
        canTake = false;
        return;
      }
      if (canTake) {
        let objObject = {};
        objObject["name"] = objectiveNameValue;
        if (editObjective){
          if (editObjectiveId > 0 ){
            objObject["objectiveId"] = editObjectiveId;
          }
          objObject["orderNumber"] = editObjectiveOrderNumber;
          setEditObjective(false);
        }else{
          objObject["orderNumber"] = objectiveOrderNumber;
          setObjectiveOrderNumber(objectiveOrderNumber + 1);
        }
        objectiveList.push(objObject);
        objectiveList.sort( compare );
        setobjectiveList(objectiveList);
        setObjectiveNameValue("");
      }

    }

  /** Edit Object*/
  function editObjectArrary(name){
    if (editObjective) {
      alert("You have not edited the previous edited objective. Please edit the previous one and add to list before edit an another objective");
      return;
    }
    objectiveList.forEach((data, index)=>{
      if (data.name === name) {  
        setObjectiveNameValue(data.name);
        setEditObjective(true);
        if (data.objectiveId != undefined && data.objectiveId != null && data.objectiveId > 0){
          setEditObjectiveId(data.objectiveId);
        }
        setEditObjectiveOrderNumber(data.orderNumber);
        objectiveList.splice(index, 1); 
        objectiveList.sort( compare ); 
        setobjectiveList(objectiveList);
      }
    })
  }

  /** Delete Oblect*/
  function deleteObjectArrary(name){
    objectiveList.forEach((data, index)=>{
      if (data.name === name) { 
        if (data.objectiveId != undefined && data.objectiveId != null && data.objectiveId > 0){
          var tmpId = data.objectiveId;
          let text = "Are you sure? '";
          text += "\n\nPress 'OK' to confirm.";
          if (window.confirm(text) == true) {
            let arr = objectiveList;
            setobjectiveList([]);
            var tempArr = [];
            arr.forEach((item)=>{
              if (item.objectiveId === tmpId){
                item["isDelete"] = true;
                tempArr.push(item);
              }else{
                tempArr.push(item);
              }
            })
            setobjectiveList(tempArr);
          }
          return
          
          //alert("The Objective '" + name + "' cannot be deleted");
          //return;
        }else{ 
          objectiveList.splice(index, 1);  
          setObjectiveNameValue(name);
          alert("The Objective '" + name + "' Was deleted")
          objectiveList.sort( compare );
          setobjectiveList(objectiveList);
          setObjectiveNameValue("");
          setHiddenNamevalue(name);
        }  
      }
    })
  }
  /** Objectve Tabke Rows */
  const objectiveTableRowData = (
    <TableBody>
    {objectiveList.map((row,index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
      <StyledTableRow key={index}  className={classes.tableTrStyle}>
        <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
          <div style= {{ whiteSpace: 'normal' }}>{row.name}</div>
        </StyledTableCell>
        <StyledTableCell 
          align="right"
          className={classes.tableTrStyle}
          >
          <EditIcon 
            className={classes.mousePointer}
            onClick ={ ()=>{editObjectArrary(row.name)}}
          />
        </StyledTableCell>
        <StyledTableCell 
          align="right"
          className={classes.tableTrStyle}
          >
          <DeleteIcon 
            className={classes.mousePointer}
            onClick ={ ()=>{deleteObjectArrary(row.name)}}
            />
        </StyledTableCell>
      </StyledTableRow>
     )
    }
  })}
  </TableBody>
)
const [selectedStandardName, setSelectedStandardName] = React.useState('');
const [globalObjectiveData, setGlobalObjectivedData] = React.useState([]);
const [globalComponentsData, setGlobalComponentsData] = React.useState([]);
const [globalPrincipalsData, setGlobalPrincipalsData] = React.useState([]);
const [globalObjectiveId, setGlobalObjectiveId] = React.useState('');
const [globalObjectiveName, setGlobalObjectiveName] = React.useState('');
const [globalComponentId, setGlobalComponentId] = React.useState('');
const [globalComponentName, setGlobalComponentName] = React.useState('');
const [globalPrincipleId, setGlobalPrincipleId] = React.useState('');
const [globalPrincipleName, setGlobalPrincipleName] = React.useState('');

function getObjectives(standerdItemObjective,standerdItemComponent,standerdItemPrincipal,stdName,indexNo){
    setGlobalObjectiveId('');
    setGlobalComponentId('');
    setGlobalPrincipleId('');
    //setGlobalObjectivedData([]);
    if (standerdItemObjective != null){
      standerdItemObjective.sort( compare );
      setGlobalObjectivedData(standerdItemObjective);
    }
    if (standerdItemComponent != null){
      standerdItemComponent.sort( compare );
      setGlobalComponentsData(standerdItemComponent);
    }
    if (standerdItemPrincipal != null){
      standerdItemPrincipal.sort( compare );
      setGlobalPrincipalsData(standerdItemPrincipal);
    }
    setSelectedStandardName(stdName);
    
    standardData.forEach((data1,index)=>{
      if (data1.centreId === "globle"){
        var idValue = document.getElementById('btn' + index);
        if (idValue != undefined) {
          if (indexNo === index) {  
            idValue.className = classes.globalBtnAfter; 
          }else{
            idValue.className = classes.globalBtn; 
          }
        }  
      }
    })

}
const handleChangeGlobalObjectives = (event) => {
      const oId = event.target.value;
      //setGlobalObjectiveId('');
      //setGlobalComponentId('');
      //setGlobalPrincipleId('');
      setGlobalObjectiveId(oId);
      //setGlobalComponentsData([]);
      //setGlobalPrincipalsData([]);
      globalObjectiveData.forEach((data,index)=>{
          if (data.objectiveId === oId) {   
              setGlobalObjectiveName(data.name); 
              //setGlobalComponentsData(data.component);
          }
      })
};

const handleChangeGlobalComponents = (event) => {
      const cId = event.target.value;
      //setGlobalComponentId('');
      //setGlobalPrincipleId('');
      setGlobalComponentId(cId);
      //setGlobalPrincipalsData([]);
      globalComponentsData.forEach((data,index)=>{
          if (data.componentId === cId) {  
            setGlobalComponentName(data.name);  
            //setGlobalPrincipalsData(data.principal);
          }
      })
};

const handleChangeGlobalPrinciples = (event) => {
  //setGlobalPrincipleId('');
  const pId = event.target.value;
  setGlobalPrincipleId(pId);
  globalPrincipalsData.forEach((data,index)=>{
    if (data.principalId === pId) {  
      setGlobalPrincipleName(data.name);  
    }
  })
};

function setObjectiveTextFunc() {
  if (globalObjectiveName === "") {
    alert("Please select a objective name");
  }else {  
    setObjectiveNameValue(globalObjectiveName);
    setSelectedStandardName("");
    setGlobalObjectivedData([]);
    setGlobalComponentsData([]);
    setGlobalPrincipalsData([]);
    setGlobalObjectiveId('');
    setGlobalObjectiveName("");
    setGlobalObjOpen(false);
  }
}

const [componentNameValue, setComponentNameValue] = React.useState('');
const handleComponentName = (event) => {
  setComponentNameValue(event.target.value);
};

const [globalCompOpen, setGlobalCompOpen] = React.useState(false);
const [globalCompScroll, setGlobalCompScroll] = React.useState('paper');

const globalCompHandleClickOpen = (scrollType) => () => {
  setGlobalCompOpen(true);
  setGlobalCompScroll(scrollType);
  setGlobalObjectivedData([]);
  setGlobalComponentsData([]);
  setGlobalPrincipalsData([]);
};
const globalCompHandleClickClose = () => {
  setGlobalCompOpen(false);
};

function setComponentTextFunc() {
  if (globalComponentName === "") {
    alert("Please select a Component name");
  }else {  
    setComponentNameValue(globalComponentName);
    setSelectedStandardName("");
    setGlobalObjectivedData([]);
    setGlobalComponentsData([]);
    setGlobalPrincipalsData([]);
    setGlobalObjectiveId('');
    setGlobalObjectiveName("");
    setGlobalComponentId('');
    setGlobalComponentName("");
    setGlobalCompOpen(false);
  }
}

const [editedPrincipalList, setEditedPrincipalList] = React.useState([]);
const [editComponent, setEditComponent] = React.useState(false);
const [editComponentId, setEditComponentId] = React.useState(0);  
const [componentList, setComponentList] = React.useState([]);
const [componentListForShortName, setComponentListForShortName] = React.useState([]);
const [editedComponentList, setEditedComponentList] = React.useState([]);
const [componentOrderNumber, setComponentOrderNumber] = React.useState(1);
const [editComponentOrderNumber, setEditComponentOrderNumber] = React.useState(0);

function addToCompListFunc() {
  var canTake = true;
  if (componentList != null) {
    componentList.forEach((data)=>{
      if (data.name === componentNameValue) {  
        alert("Same Component Name. Please use the different name");
        canTake = false;
        return;
      }
    })
  }
  if (componentNameValue === "" || componentNameValue === " "){
    alert("Please enter Component Name");
    canTake = false;
    return;
  }
  if (canTake) {
    let compObject = {};
    compObject["name"] = componentNameValue;
    if (editComponent){
      if (editComponentId > 0){
        compObject["componentId"] = editComponentId;
      }
      compObject["orderNumber"] = editComponentOrderNumber;
      setEditComponent(false);
    }else{
      compObject["orderNumber"] = componentOrderNumber;
      setComponentOrderNumber(componentOrderNumber + 1);
    }
    componentList.push(compObject);
    componentList.sort( compare );
    setComponentList(componentList);
 
    var arr = [];
    var tmpComponentNo = tmpComponentId;
    componentList.forEach((data)=>{
      var obj = {};
      if (data.componentId != undefined && data.componentId != null && data.componentId != ''){
        obj["componentName"] = data.name + " (Component)";
        obj["id"] = data.componentId;
      }else{
        tmpComponentNo ++;
        obj["componentName"] = data.name + " (Custom - For use this session only)";
        obj["id"] = tmpComponentNo;
      }
      arr.push(obj);
    })
    setTmpComponentId(tmpComponentNo);
    setComponentListForShortName(arr);
 
    setComponentNameValue("");
  }
}
/** Edit Component*/
function editComponentArrary(name){
  if (editComponent) {
    alert("You have not edited the previous edited Component. Please edit the previous one and add to list before edit an another component");
    return;
  }

  componentList.forEach((data, index)=>{
    if (data.name === name) {  
      setComponentNameValue(data.name);
      setEditComponent(true);
      if (data.componentId != undefined && data.componentId != null && data.componentId>0){
        setEditComponentId(data.componentId);
      }
      setEditComponentOrderNumber(data.orderNumber);
      componentList.splice(index, 1);
      componentList.sort( compare );   
      setComponentList(componentList);
    }
  })
}

/** Delete Component*/
function deleteComponentArrary(name){
  componentList.forEach((data, index)=>{
    if (data.name === name) { 
      if (data.componentId != undefined && data.componentId != null && data.componentId>0){
        var tmpId = data.componentId;
        let text = "Are you sure? '";
        text += "\n\nPress 'OK' to confirm.";
        if (window.confirm(text) == true) {
          let arr = componentList;
          setComponentList([]);
          var tempArr = [];
          arr.forEach((item)=>{
            if (item.componentId === tmpId){
              item["isDelete"] = true;
              tempArr.push(item);
            }else{
              tempArr.push(item);
            }
          })
          setComponentList(tempArr);
        }
        return
        
        //alert("The Component '" + name + "' cannot be deleted");
        //return;
      }else{ 
        componentList.splice(index, 1);  
        setComponentNameValue(name);
        alert("The Component '" + name + "' Was deleted")
        componentList.sort( compare );   
        setComponentList(componentList);

        componentListForShortName.splice(index, 1);
        setComponentListForShortName(componentListForShortName);

        setComponentNameValue("");
        setHiddenNamevalue(name);
      }
    }
  })
}

const componentTableRowData = (
  <TableBody>
  {componentList.map((row,index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
    <StyledTableRow key={index}  className={classes.tableTrStyle}>
      <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
        <div style= {{ whiteSpace: 'normal' }}>{row.name}</div>
      </StyledTableCell>
      <StyledTableCell 
        align="right"
        className={classes.tableTrStyle}
        >
        <EditIcon 
          className={classes.mousePointer}
          onClick ={ ()=>{editComponentArrary(row.name)}}
        />
      </StyledTableCell>
      <StyledTableCell 
        align="right"
        className={classes.tableTrStyle}
        >
        <DeleteIcon 
          className={classes.mousePointer}
          onClick ={ ()=>{deleteComponentArrary(row.name)}}
          />
      </StyledTableCell>
    </StyledTableRow>
  )
}
})}
</TableBody>

)

/** Principal Related Script */
const [principalList, setPrincipalList] = React.useState([]);

const [principalNameValue, setPrincipalNameValue] = React.useState('');
const handlePrincipalName = (event) => {
  setPrincipalNameValue(event.target.value);
};

const [globalPriOpen, setGlobalPriOpen] = React.useState(false);
const [globalPriScroll, setGlobalPriScroll] = React.useState('paper');

const globalPriHandleClickOpen = (scrollType) => () => {
  setGlobalPriOpen(true);
  setGlobalPriScroll(scrollType);
  setGlobalObjectivedData([]);
  setGlobalComponentsData([]);
  setGlobalPrincipalsData([]);
};
const globalPriHandleClickClose = () => {
  setGlobalPriOpen(false);
};

function setPrincipalTextFunc() {
  if (globalPrincipleName === "") {
    alert("Please select a Principle name");
  }else {  
    setPrincipalNameValue(globalPrincipleName);
    setSelectedStandardName("");
    setGlobalObjectivedData([]);
    setGlobalComponentsData([]);
    setGlobalPrincipalsData([]);
    setGlobalObjectiveId('');
    setGlobalObjectiveName("");
    setGlobalComponentId('');
    setGlobalComponentName("");
    setGlobalPrincipleId('');
    setGlobalPrincipleName("");
    setGlobalPriOpen(false);
  }
}

const [editPrincipal, setEditPrincipal] = React.useState(false);
const [editPrincipalId, setEditPrincipalId] = React.useState(0);
const [principalOrderNumber, setPrincipalOrderNumber] = React.useState(1);
const [editPrincipalOrderNumber, setEditPrincipalOrderNumber] = React.useState(0);

function addToPriListFunc() {
  var canTake = true;
  if (principalList != null) {
    principalList.forEach((data)=>{
      if (data.name === principalNameValue) {  
        alert("Same Principle Name. Please use the different name");
        canTake = false;
        return;
      }
    })
  }
  if (principalNameValue === "" || principalNameValue === " "){
    alert("Please enter Principle Name");
    canTake = false;
    return;
  }
  if (canTake) {
    let objObject = {};
    objObject["name"] = principalNameValue.replace("\t", "    ");
    if (editPrincipal){
      if (editPrincipalId > 0){
        objObject["principalId"] = editPrincipalId;
      }
      objObject["orderNumber"] = editPrincipalOrderNumber;
      setEditPrincipal(false);
    }else{
      objObject["orderNumber"] = principalOrderNumber;
      setPrincipalOrderNumber(principalOrderNumber + 1);
    }

    //alert(selectedShortName);
    //alert(selectedShortNameId);
    if (selectedShortName != ''){
      var tmpS = selectedShortName;
      var tmpA = tmpS.split(" (");
      var appendSName = '';
      if (tmpA.length > 0){
        //alert(selectedShortName)
        if (tmpA[1] === "Custom - For use this session only)"){
          appendSName = " (Custom)"
        }
      }
      objObject["shortName"] = tmpA[0] + appendSName;
      if (selectedShortNameId != ''){
        if (selectedShortNameId < 1000000){
          objObject["componentId"] = selectedShortNameId;
        }
      }
    }
    //console.log(objObject);


    principalList.push(objObject);
    principalList.sort( compare );
    setPrincipalList(principalList);
    setPrincipalNameValue("");
    setSelectedShortNameId('');
    setSelectedShortName('');
  }
}
/** Edit principal*/
function editPrincipalArrary(name){
  if (editPrincipal) {
    alert("You have not edited the previous edited Principle. Please edit the previous one and add to list before edit an another Principle");
    return;
  }

  principalList.forEach((data, index)=>{
    if (data.name === name) {  
      setPrincipalNameValue(data.name);
      setEditPrincipal(true);
      if (data.principalId != undefined && data.principalId != null && data.principalId>0){
        setEditPrincipalId(data.principalId);
      }
      setEditPrincipalOrderNumber(data.orderNumber);

      if (data.shortName != null){
        var foundShortName = false;
        var dataSName = '';
        var tArr = data.shortName.split(" (Custom)")
        dataSName = tArr[0];

        componentListForShortName.forEach((item)=>{
          var itemName1 = dataSName + " (Component)";
          var itemName2 = dataSName + " (Custom - For use this session only)"; 
          if (itemName1 === item.componentName || itemName2 === item.componentName){
            foundShortName = true;
            setSelectedShortName(item.componentName);
            setSelectedShortNameId(item.id);
          }
        })
        if (!foundShortName){
          var obj = {};
          var countNo = tmpComponentId + 1;
          obj["id"] = countNo;
          setTmpComponentId(countNo);
          obj["componentName"] = dataSName + " (Custom - For use this session only)";
          var arr = componentListForShortName;
          arr.push(obj);
          setComponentListForShortName(arr);
          setSelectedShortNameId(countNo);
          setSelectedShortName(dataSName + " (Custom - For use this session only)");
        }


      }

      console.log(data);


      principalList.splice(index, 1);  
      principalList.sort( compare ); 
      setPrincipalList(principalList);
    }
  })
}

/** Delete principal*/
function deletePrincipalArrary(name){
  principalList.forEach((data, index)=>{
    if (data.name === name) {  
      if (data.principalId != undefined && data.principalId != null && data.principalId>0){
        var tmpId = data.principalId;
        let text = "Are you sure? '";
        text += "\n\nPress 'OK' to confirm.";
        if (window.confirm(text) == true) {
          let arr = principalList;
          setPrincipalList([]);
          var tempArr = [];
          arr.forEach((item)=>{
            if (item.principalId === tmpId){
              item["isDelete"] = true;
              tempArr.push(item);
            }else{
              tempArr.push(item);
            }
          })
          setPrincipalList(tempArr);
        }
        return
        
        //alert("The Principle '" + name + "' cannot be deleted");
        //return
      }else{
        principalList.splice(index, 1);  
        setPrincipalNameValue(name);
        alert("The Principle '" + name + "' Was deleted");
        principalList.sort( compare );
        setPrincipalList(principalList);
        setPrincipalNameValue("");
        setHiddenNamevalue(name);
      }  
    }
  })
}


const principalTableRowData = (
  <TableBody>
  {principalList.map((row,index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
    <StyledTableRow key={index}  className={classes.tableTrStylePri}>
      <StyledTableCell component="th" scope="row" className={classes.tableTrStylePri} >
        <div style= {{ whiteSpace: 'normal' }}>{row.shortName === null?'':row.shortName}</div>
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" className={classes.tableTrStylePri} >
        <div style= {{ whiteSpace: 'normal' }}>{row.name}</div>
      </StyledTableCell>
      <StyledTableCell 
        align="right"
        className={classes.tableTrStylePri}
        >
        <EditIcon 
          className={classes.mousePointer}
          onClick ={ ()=>{editPrincipalArrary(row.name)}}
        />
      </StyledTableCell>
      <StyledTableCell 
        align="right"
        className={classes.tableTrStylePri}
        >
        <DeleteIcon 
          className={classes.mousePointer}
          onClick ={ ()=>{deletePrincipalArrary(row.name)}}
          />
      </StyledTableCell>
    </StyledTableRow>
  )
}
})}
</TableBody>
)

/** Entity Related Script */
const [entityList, setEntityList] = React.useState([]);

const [entityNameValue, setEntityNameValue] = React.useState('');
const [entityIdValue, setEntityIdValue] = React.useState('');

const [editEntity, setEditEntity] = React.useState(false);
const [editEntityId, setEditEntityId] = React.useState(0);
const [entityOrderNumber, setEntityOrderNumber] = React.useState(1);
const [editEntityOrderNumber, setEditEntityOrderNumber] = React.useState(0);


const [entityTextNameValue, setEntityTextNameValue] = React.useState('');
const handleEntityTextName = (event) => {
  setEntityTextNameValue(event.target.value);
};

const handleChangeEntity = (event) => {
  var eId = event.target.value;
  setEntityIdValue(eId);
  entityData.forEach((data,index)=>{
    if (data.entityId === eId) {  
      setEntityNameValue(data.entityName);  
      setEntityTextNameValue(data.entityName);
    }
  })
};


function addToEntityListFunc() {
  var canTake = true;
  if (entityList != null) {
    entityList.forEach((data)=>{
      if (data.entityId === entityIdValue) {  
        alert("Same Entity Added. Please use the different entity");
        canTake = false;
        return;
      }
    })
  }
  if (entityIdValue === "" || entityIdValue === " "){
    alert("Please select Entity");
    canTake = false;
    return;
  }
  if (entityTextNameValue === ""){
    alert("Please Type Entity name For Global entity");
    canTake = false;
    return;
  }
  if (canTake) {
    let objObject = {};
    if (editEntity){
      if (editEntityId > 0){
        objObject["entityDataId"] = editEntityId;
      }
      objObject["orderNumber"] = editEntityOrderNumber;
      setEditEntity(false);
    }else{
      objObject["orderNumber"] = entityOrderNumber;
      setEntityOrderNumber(entityOrderNumber + 1);
    }
    objObject["entityName"] = entityTextNameValue;
    objObject["entityId"] = entityIdValue;

    entityList.push(objObject);
    entityList.sort( compare );
    setEntityList(entityList);
    setEntityNameValue("");
    setEntityIdValue("");
    setEntityTextNameValue("");
  }
}

/** Edit entity*/
function editEntityArrary(id, name){
  if (editEntity) {
    alert("You have not edited the previous edited Entity. Please edit the previous one and add to list before edit an another Entity");
    return;
  }

  entityList.forEach((data, index)=>{
    if (data.entityId === id) {  
      setEntityTextNameValue(name);
      setEntityIdValue(data.entityId);
      setEditEntity(true);
      if (data.entityDataId != undefined && data.entityDataId != null && data.entityDataId>0){
        setEditEntityId(data.entityDataId);
      }
      setEditEntityOrderNumber(data.orderNumber);
      entityList.splice(index, 1);
      entityList.sort( compare );   
      setEntityList(entityList);
    }
  })
}    

/** Delete entity*/
function deleteEntityArrary(id){
  entityList.forEach((data, index)=>{
    if (data.entityId === id) {
      var nameVal = data.entityName;
      if (data.entityDataId != undefined && data.entityDataId != null && data.entityDataId>0){
        var tmpId = data.entityDataId;
        let text = "Are you sure? '";
        text += "\n\nPress 'OK' to confirm.";
        if (window.confirm(text) == true) {
          let arr = entityList;
          setEntityList([]);
          var tempArr = [];
          arr.forEach((item)=>{
            if (item.entityDataId === tmpId){
              item["isDelete"] = true;
              tempArr.push(item);
            }else{
              tempArr.push(item);
            }
          })
          setEntityList(tempArr);
        }
        return
        
        //alert("The Entity '" + nameVal + "' cannot be deleted");
        //return
      }else{
        var idVal = data.entityId;   
        entityList.splice(index, 1);  
        alert("The Entity '" + nameVal + "' Was deleted from the Standard")
        entityList.sort( compare );
        setEntityList(entityList);
        setHiddenNamevalue(nameVal);
      }
    }
  })
}


const entityTableRowData = (
  <TableBody>
  {entityList.map((row,index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
    <StyledTableRow key={index}  className={classes.tableTrStyle}>
      <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
        <div style= {{ whiteSpace: 'normal' }}>{row.entityName}</div>
      </StyledTableCell>
      <StyledTableCell 
        align="right"
        className={classes.tableTrStyle}
        >
        <EditIcon 
          className={classes.mousePointer}
          onClick ={ ()=>{editEntityArrary(row.entityId,row.entityName)}}
        />
      </StyledTableCell>
      <StyledTableCell 
        align="right"
        className={classes.tableTrStyle}
        >
        <DeleteIcon 
          className={classes.mousePointer}
          onClick ={ ()=>{deleteEntityArrary(row.entityId)}}
          />
      </StyledTableCell>
    </StyledTableRow>
   )
  }
})}
</TableBody>
)


const previousStandardTableRowData = (
  <TableBody>
  {standardData.map((item)=>{

    if (item.centreId != "globle" && item.centreId === globalCentreId){
      return (
    <StyledTableRow key={item.standardId}  className={classes.tableTrStyle}>
      <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
        <div style= {{ whiteSpace: 'normal' }}>{item.standardName}</div>
      </StyledTableCell>
      <StyledTableCell 
        align="right"
        className={classes.tableTrStyle}
        >
        <EditIcon 
          className={classes.mousePointer}
          onClick ={ ()=>{editCustomStandard(item.standardId)}}
        />
      </StyledTableCell>
    </StyledTableRow>
      )
    }
  })}
</TableBody>

)

function editCustomStandard(id) {
  standardData.forEach((data)=>{
    if (data.standardId === id) {
      setComponentsForShowAllStandardData(data.components)
      setObjectivesForShowAllStandardData(data.objectives)
      setPrinciplesForShowAllStandardData(data.principles)
      setEntityForShowAllStandardData(data.entities)
      clearFuction();
      setStandardNamevalue(data.standardName);
      setStandardDescriptionvalue(data.description);
      setStandardIdvalue(data.standardId);
      if (data.objectives != null && data.objectives.length>0){
        var tmpArr  = data.objectives;
        tmpArr.sort( compare );
        setobjectiveList(tmpArr);
        var lastNo = tmpArr[(tmpArr.length -1)].orderNumber;
        if (lastNo != null) {
          setObjectiveOrderNumber((lastNo + 1));
        }else{
          setObjectiveOrderNumber((tmpArr.length + 1));
        }
      }else{
        setobjectiveList([]);
      }
      if (data.components != null && data.components.length>0){
        var tmpArr  = data.components;
        tmpArr.sort( compare );
        setComponentList(tmpArr);

        var arr =[];
        tmpArr.forEach((item)=>{
          var obj = {};
          obj["id"] = item.componentId;
          obj["componentName"] = item.name + " (Component)";
          arr.push(obj);
        })
        setComponentListForShortName(arr);

        var lastNo = tmpArr[(tmpArr.length -1)].orderNumber;
        if (lastNo != null) {
          setComponentOrderNumber((lastNo + 1));
        }else{
          setComponentOrderNumber((tmpArr.length + 1));
        }
      }else{
        setComponentList([]);
        setComponentListForShortName([]);
      }
      if (data.principles != null && data.principles.length>0){
        var tmpArr  = data.principles;
        tmpArr.sort( compare );
        setPrincipalList(tmpArr);
        var lastNo = tmpArr[(tmpArr.length -1)].orderNumber;
        if (lastNo != null) {
          setPrincipalOrderNumber((lastNo + 1));
        }else{
          setPrincipalOrderNumber((tmpArr.length + 1));
        }
      }else{
        setPrincipalList([]);
      }
      if (data.entities != null && data.entities.length>0){
        var tmpArr  = data.entities;
        tmpArr.sort( compare );
        setEntityList(tmpArr);
        var lastNo = tmpArr[(tmpArr.length -1)].orderNumber;
        if (lastNo != null) {
          setEntityOrderNumber((lastNo + 1));
        }else{
          setEntityOrderNumber((tmpArr.length + 1));
        }
      }else{
        setEntityList([]);
      }
      setSaveBtnName("Update");
      setheaderInfoMessage("Edit Global Standard");
      objectiveBtnHandleClick();
      document.getElementById("focusCardId").scrollIntoView();
      setShowAllStandardBtn(true)

      setLeftBarGridCount(12);
      setRightBarGridCount(0);
      setVisibilityFlag('hidden');
      
    }  
  })
}

  
const [btnObjectiveClass, setBtnObjectiveClass] = useState(classes.btnBorderGreen);
const [btnComponentClass, setBtnComponentClass] = useState(classes.btnBorderpurple);
const [btnPrincipalClass, setBtnPrincipalClass] = useState(classes.btnBorderpurple);
const [btnEntityClass, setBtnEntityClass] = useState(classes.btnBorderpurple);

const [btnObjectiveClicked, setBtnObjectiveClicked] = useState(true);
const [btnComponentClicked, setBtnComponentClicked] = useState(false);
const [btnPrincipalClicked, setBtnPrincipalClicked] = useState(false);
const [btnEntityClicked, setBtnEntityClicked] = useState(false);

const objectiveBtnHandleClick = (event) => {
  setBtnObjectiveClass(classes.btnBorderGreen);
  setBtnComponentClass(classes.btnBorderpurple);
  setBtnPrincipalClass(classes.btnBorderpurple);
  setBtnEntityClass(classes.btnBorderpurple);
  
  setBtnObjectiveClicked(true);
  setBtnComponentClicked(false);
  setBtnPrincipalClicked(false);
  setBtnEntityClicked(false);

 
};

const componentBtnHandleClick = (event) => {
  setBtnObjectiveClass(classes.btnBorderpurple);
  setBtnComponentClass(classes.btnBorderGreen);
  setBtnPrincipalClass(classes.btnBorderpurple);
  setBtnEntityClass(classes.btnBorderpurple);

  setBtnObjectiveClicked(false);
  setBtnComponentClicked(true);
  setBtnPrincipalClicked(false);
  setBtnEntityClicked(false);

};
const principalBtnHandleClick = (event) => {
  setBtnObjectiveClass(classes.btnBorderpurple);
  setBtnComponentClass(classes.btnBorderpurple);
  setBtnPrincipalClass(classes.btnBorderGreen);
  setBtnEntityClass(classes.btnBorderpurple);

  setBtnObjectiveClicked(false);
  setBtnComponentClicked(false);
  setBtnPrincipalClicked(true);
  setBtnEntityClicked(false);
 
};
const entityBtnHandleClick = (event) => {
  setBtnObjectiveClass(classes.btnBorderpurple);
  setBtnComponentClass(classes.btnBorderpurple);
  setBtnPrincipalClass(classes.btnBorderpurple);
  setBtnEntityClass(classes.btnBorderGreen);

  setBtnObjectiveClicked(false);
  setBtnComponentClicked(false);
  setBtnPrincipalClicked(false);
  setBtnEntityClicked(true);
 
};

function onFormSubmit(e) {
  e.preventDefault();
  if (standardNamevalue === ""){
    alert("Please enter Standard Name");
    return;
  }
  var stdObj={};
  stdObj["standardName"]=standardNamevalue;
  if (standardIdvalue != 0){
    stdObj["standardId"]=standardIdvalue;
  }
  stdObj["centreId"]=globalCentreId;
  stdObj["description"]=standardDescriptionvalue;
  stdObj["isActive"]="true";
  stdObj["isDelete"]="false";
  if (objectiveList != null && objectiveList.length>0){
    stdObj["objectives"]=objectiveList;
  }
  if (componentList != null && componentList.length>0){
    stdObj["components"]=componentList;
  }
  if (principalList != null && principalList.length>0){
    
    var arr = [];
    principalList.forEach((item)=>{
      var name = item.name;
      item["name"] = name;
      arr.push(item);
    })
    stdObj["principles"]=arr;
  }
  if (entityList != null && entityList.length>0){
    stdObj["entities"]=entityList;
  }
  
  
  var sendJson = JSON.stringify(stdObj);

  console.log(sendJson);
 
  
  axios(CONFIG.MAIN_URL+'/standard/save',{
    method: 'post',
    headers: { 
        'Content-Type': 'application/json',
      },
    data : sendJson,  
    })
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      if (standardIdvalue != 0){
        alert("Custom Standard Updated successfully");
      }else{
        alert("Custom Standard saved successfully");
      }  
      clearFuction();
      getStandards();
      setStandardIdvalue(0);
    })
    .catch(function (error) {
        console.log(error);
        alert("Error saving Question -:" + error);
    });

}

function clearFuction() {
  if (screenSize < 811) {
    setLeftBarGridCount(12);
    setRightBarGridCount(12);
  }
  else{
    setLeftBarGridCount(8);
    setRightBarGridCount(4);
  }
  setVisibilityFlag('visible');

  setStandardNamevalue("");
  setStandardDescriptionvalue("");
  setobjectiveList([]);
  setComponentList([]);
  setComponentListForShortName([]);
  setPrincipalList([]);
  setEntityList([]);
  setSaveBtnName("Save");
  setStandardIdvalue(0);
  setheaderInfoMessage("Create Custom Standard");
  objectiveBtnHandleClick();
  setObjectiveOrderNumber(1);
  setEditObjectiveOrderNumber(0);
  setComponentOrderNumber(1);
  setEditComponentOrderNumber(0);
  setPrincipalOrderNumber(1);
  setEditPrincipalOrderNumber(0);
  setEntityOrderNumber(1);
  setEditEntityOrderNumber(0);
  setObjectiveNameValue('')
  setComponentNameValue('')
  setPrincipalNameValue('')
  setEntityIdValue('')
  setEditPrincipal(false)
  setEditObjective(false)
  setEditComponent(false)
  setEditEntity(false)
  setShowAllStandardBtn(false)
}
/** Find Duplicates values in a array */
function toFindDuplicates(arry) {
  let toMap = {};
  let resultToReturn = false;
  for (let i = 0; i < arry.length; i++) {
        if (toMap[arry[i]]) {
            resultToReturn = true;
            break;
        }
        toMap[arry[i]] = true;
  }
  if (resultToReturn) {
    return true;
  }else{
    return false;
  }  

}

/** set Objective New Position */
const [objPosOpen, setObjPosOpen] = React.useState(false);
const [objPosScroll, setObjPosScroll] = React.useState('paper');
const objPosHandleClickOpen = (scrollType) => () => {
  setObjPosOpen(true);
  setObjPosScroll(scrollType);
};
const objPosHandleClickClose = () => {
  setObjPosOpen(false);
};
function confirmObJNewPosFunc() {
  if(open === false){
    var tmpObjList = [];
    var newArr = [];
    var canTake = true;
    objectiveList.forEach((data, index)=>{
      var newVal = parseInt(document.getElementById("newObjPos" + index).value);
      if (newVal != "" && !isNaN(newVal)) {
        newArr.push(newVal);
        
        let objObject = {};
        objObject["name"] = data.name;
        if (data.objectiveId != undefined && data.objectiveId != null && data.objectiveId != ""){
          objObject["objectiveId"] = data.objectiveId;
        }
        objObject["orderNumber"] = newVal;
        tmpObjList.push(objObject);
      }else{
        alert("Please Check the input position again. There may be empty value or non numeric value entered");
        canTake = false;
      }
    })
    var duplicateElements = toFindDuplicates(newArr);
    if (duplicateElements != undefined && duplicateElements != null && duplicateElements){
      alert("Please Check the input position again. There may be duplicated values entered");
      canTake = false;
    }
    if (tmpObjList != null && tmpObjList.length > 0 && canTake){
      tmpObjList.sort( compare );
      setobjectiveList(tmpObjList);
      setObjPosOpen(false);
    }
  
  
  
  
  }else{
      
  var arr = []
    
  objectiveList.forEach((data, index)=> {
      data["orderNumber"] = index + 1;
      arr.push(data)
      
  })
    setobjectiveList(arr)
    alert("Successfully Rearranged")
  }

}


/** set Component New Position */
const [compPosOpen, setCompPosOpen] = React.useState(false);
const [compPosScroll, setCompPosScroll] = React.useState('paper');
const compPosHandleClickOpen = (scrollType) => () => {
  setCompPosOpen(true);
  setCompPosScroll(scrollType);
};
const compPosHandleClickClose = () => {
  setCompPosOpen(false);
};
function confirmCompNewPosFunc() {

   if(open === false){
  var tmpObjList = [];
  var newArr = [];
  var canTake = true;
  componentList.forEach((data, index)=>{
    var newVal = parseInt(document.getElementById("newCompPos" + index).value);
    if (newVal != "" && !isNaN(newVal)) {
      newArr.push(newVal);
      
      let objObject = {};
      objObject["name"] = data.name;
      if (data.componentId != undefined && data.componentId != null && data.componentId != ""){
        objObject["componentId"] = data.componentId;
      }
      objObject["orderNumber"] = newVal;
      tmpObjList.push(objObject);
    }else{
      alert("Please Check the input position again. There may be empty value or non numeric value entered");
      canTake = false;
    }
  })
  var duplicateElements = toFindDuplicates(newArr);
  if (duplicateElements != undefined && duplicateElements != null && duplicateElements){
    alert("Please Check the input position again. There may be duplicated values entered");
    canTake = false;
  }
  if (tmpObjList != null && tmpObjList.length > 0 && canTake){
    tmpObjList.sort( compare );
    setComponentList(tmpObjList);
    setCompPosOpen(false);
  }
}
else{
  var arr = []
  
componentList.forEach((data, index)=> {
    data["orderNumber"] = index + 1;
    arr.push(data)
    
})
  setComponentList(arr)
  alert("Successfully Rearranged")
}


}

/** set Principle New Position */
const [priPosOpen, setPriPosOpen] = React.useState(false);
const [priPosScroll, setPriPosScroll] = React.useState('paper');
const priPosHandleClickOpen = (scrollType) => () => {
  setPriPosOpen(true);
  setPriPosScroll(scrollType);
};
const priPosHandleClickClose = () => {
  setPriPosOpen(false);
};
function confirmPriNewPosFunc() {

  if(open === false){
  var tmpObjList = [];
  var newArr = [];
  var canTake = true;
  principalList.forEach((data, index)=>{
    var newVal = parseInt(document.getElementById("newPriPos" + index).value);
    if (newVal != "" && !isNaN(newVal)) {
      newArr.push(newVal);
      
      let objObject = {};
      objObject["name"] = data.name;
      if (data.principalId != undefined && data.principalId != null && data.principalId != ""){
        objObject["principalId"] = data.principalId;
      }
      objObject["orderNumber"] = newVal;
      if (data.shortName != undefined && data.shortName != null){
        objObject["shortName"] = data.shortName;
        if (data.componentId != undefined && data.componentId != null){ 
          objObject["componentId"] = data.componentId;
        }
      }
      tmpObjList.push(objObject);
    }else{
      alert("Please Check the input position again. There may be empty value or non numeric value entered");
      canTake = false;
    }
  })
  var duplicateElements = toFindDuplicates(newArr);
  if (duplicateElements != undefined && duplicateElements != null && duplicateElements){
    alert("Please Check the input position again. There may be duplicated values entered");
    canTake = false;
  }
  if (tmpObjList != null && tmpObjList.length > 0 && canTake){
    tmpObjList.sort( compare );
    setPrincipalList(tmpObjList);
    setPriPosOpen(false);
  }
}else{
  var arr = []
      
  principalList.forEach((data, index)=> {
      data["orderNumber"] = index + 1;
      arr.push(data)
      
  })
    setPrincipalList(arr)
    alert("Successfully Rearranged")
}

}

/** set Entity New Position */
const [entPosOpen, setEntPosOpen] = React.useState(false);
const [entPosScroll, setEntPosScroll] = React.useState('paper');
const entPosHandleClickOpen = (scrollType) => () => {
  setEntPosOpen(true);
  setEntPosScroll(scrollType);
};
const entPosHandleClickClose = () => {
  setEntPosOpen(false);
};
function confirmEntNewPosFunc() {

   if(open === false){
  var tmpObjList = [];
  var newArr = [];
  var canTake = true;
  entityList.forEach((data, index)=>{
    var newVal = parseInt(document.getElementById("newEntPos" + index).value);
    if (newVal != "" && !isNaN(newVal)) {
      newArr.push(newVal);
      
      let objObject = {};
      objObject["entityName"] = data.entityName;
      objObject["entityId"] = data.entityId;
      if (data.entityDataId != undefined && data.entityDataId != null && data.entityDataId != ""){
        objObject["entityDataId"] = data.entityDataId;
      }
      objObject["orderNumber"] = newVal;
      tmpObjList.push(objObject);
    }else{
      alert("Please Check the input position again. There may be empty value or non numeric value entered");
      canTake = false;
    }
  })
  var duplicateElements = toFindDuplicates(newArr);
  if (duplicateElements != undefined && duplicateElements != null && duplicateElements){
    alert("Please Check the input position again. There may be duplicated values entered");
    canTake = false;
  }
  if (tmpObjList != null && tmpObjList.length > 0 && canTake){
    tmpObjList.sort( compare );
    setEntityList(tmpObjList);
    setEntPosOpen(false);
  }
}else{
  var arr = []
          
  entityList.forEach((data, index)=> {
      data["orderNumber"] = index + 1;
      arr.push(data)
      
  })
  setEntityList(arr)
  alert("Successfully Rearranged")
}


}

/** For Principle Short Name */

const [selectedShortNameId, setSelectedShortNameId] = React.useState('');
const [selectedShortName, setSelectedShortName] = React.useState('');

const handleChangeShortName = (event) => {
  setSelectedShortNameId(event.target.value);
  componentListForShortName.forEach((item)=>{
    if (item.id === event.target.value) {
      setSelectedShortName(item.componentName);
    }  
  })
};


const [openCreateNewShortName, setOpenCreateNewShortName] = React.useState(false);
const [openCreateNewShortNameScroll, setOpenCreateNewShortNameScroll] = React.useState('paper');

function openCreateNewShortNameFunction(){
  setOpenCreateNewShortName(true);
  setOpenCreateNewShortNameScroll('paper');
}
const openCreateNewShortNameHandleClickClose = () => {
  setOpenCreateNewShortName(false);
};

const [newShortName, setNewShortName] = React.useState('');
const handleChangeNewShortName = (event) => {
  setNewShortName(event.target.value)
};

const [tmpComponentId, setTmpComponentId] = React.useState(1000000);

function saveNewShortNameFuction(){
  if (newShortName != ''){
    var canTake = true;
    componentListForShortName.forEach((item)=>{
      if (item.shortName === newShortName) {  
        canTake = false;
      }
    })
    if (canTake){
      var obj = {};
      var countNo = tmpComponentId + 1;
      obj["id"] = countNo;
      setTmpComponentId(countNo);
      obj["componentName"] = newShortName + " (Custom - For use this session only)";
      var arr = componentListForShortName;
      arr.push(obj);
      setComponentListForShortName(arr);
      setSelectedShortNameId(countNo);
      setSelectedShortName(newShortName + " (Custom - For use this session only)");
      setOpenCreateNewShortName(false);
    }else{
      alert("The Short name is already in the List. Please use different Short name");  
    }
  }else{
    alert("Please type the Short Name");
  }
}


const [PageName, setPageName] = useState('Custom Standard')


/**
 * show all data part
 */

 const [showAllStandardBtn, setShowAllStandardBtn] = useState(false)

 const [idForShowAllStandardData, setIdForShowAllStandardData] = useState()
 
 const [componentsForShowAllStandardData, setComponentsForShowAllStandardData] = useState([])
 
 const [objectivesForShowAllStandardData, setObjectivesForShowAllStandardData] = useState([])
 
 const [principleForShowAllStandardData, setPrinciplesForShowAllStandardData] = useState([])
 
 const [entityForShowAllStandardData, setEntityForShowAllStandardData] = useState([])
 
 const [open, setOpen] = React.useState(false);
 
 const handleClickOpen = () => {
  // var idDiv = document.getElementById('divOpenCustomStandards');
  // var styleDiv = idDiv.currentStyle || window.getComputedStyle(idDiv);
  // const matrix = new DOMMatrixReadOnly(styleDiv.transform)
  // setMainDivXC(matrix.m41)
  // setMainDivYC(matrix.m42)
   setOpen(true);
 };
 
 const handleClose = () => {
   setOpen(false);
   //setFromCustomStandardPage(true);
 };

 /** Show Coso Cube */
const [openCube, setOpenCube] = React.useState(false);
const [openCubeScroll, setOpenCubeScroll] = React.useState('paper');

function showCosoCube(){
  setOpenCube(true);
  setOpenCubeScroll('paper');
}



 
 
/** drag for compo */

const onDragEnd = (result) => {
  if (!result.destination) {
    return;
  }

  const newRows = [...componentList];
  console.log(newRows)
  const [removed] = newRows.splice(result.source.index, 1);
  newRows.splice(result.destination.index, 0, removed);

  setComponentList(newRows);
};

/** drag for obj */

const onDragEndObj = (result) => {
  if (!result.destination) {
    return;
  }

  const newRows = [...objectiveList];
  console.log(newRows)
  const [removed] = newRows.splice(result.source.index, 1);
  newRows.splice(result.destination.index, 0, removed);

  setobjectiveList(newRows);
};

/** drag for prin */

const onDragEndPrin = (result) => {
  if (!result.destination) {
    return;
  }

  const newRows = [...principalList];
  console.log(newRows)
  const [removed] = newRows.splice(result.source.index, 1);
  newRows.splice(result.destination.index, 0, removed);

  setPrincipalList(newRows);
};

/** drag for enti */

const onDragEndEnti = (result) => {
  if (!result.destination) {
    return;
  }

  const newRows = [...entityList];
  console.log(newRows)
  const [removed] = newRows.splice(result.source.index, 1);
  newRows.splice(result.destination.index, 0, removed);

  setEntityList(newRows);
};

const componentsTableRowData = (


<DragDropContext onDragEnd={onDragEnd}>
<Droppable droppableId="table">
  {(provided) => (
    <table ref={provided.innerRef}>
      <thead>
        <tr>
          <th style={{width:'86%', fontSize:'10px'}}>Name </th>
          <th style={{width:'14%', fontSize:'10px'}}>Order No </th>
        </tr>
      </thead>
      <tbody {...provided.droppableProps}>
      {componentList.map((row, index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
        <Draggable key={row.orderNumber} draggableId={`${row.orderNumber}`} index={index}>
        {(provided) => (
          <tr
            className={index % 2 === 0?classes.trBackColor1:classes.trBackColor2}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            
            
          >
            <td>{row.name}</td>
            {/* <td style={{height:'40px'}}>{index}</td> */}
            <td style={{float: 'right'}} >{row.orderNumber}</td>
          </tr>
        )}
        </Draggable>
      )
    }
  })}
        {provided.placeholder}
      </tbody>
    </table>
  )}
</Droppable>
</DragDropContext>
                             

)

const objectiveAllTableRowData = (


<DragDropContext onDragEnd={onDragEndObj}>
<Droppable droppableId="table">
  {(provided) => (
    <table ref={provided.innerRef}>
      <thead>
        <tr>
          <th style={{width:'86%', fontSize:'10px'}}>Name </th>
          <th style={{width:'14%', fontSize:'10px'}}>Order No </th>
        </tr>
      </thead>
      <tbody {...provided.droppableProps}>
      {objectiveList.map((row, index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
<Draggable key={row.orderNumber} draggableId={`${row.orderNumber}`} index={index}>
{(provided) => (
  <tr
  className={index % 2 === 0?classes.trBackColor1:classes.trBackColor2} 
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    
    
  >
    <td>{row.name}</td>
    {/* <td style={{height:'40px'}}>{index}</td> */}
    <td style={{float: 'right'}} >{row.orderNumber}</td>
  </tr>
)}
</Draggable>
)
}
})}
        {provided.placeholder}
      </tbody>
    </table>
  )}
</Droppable>
</DragDropContext>

)

const principleTableRowData = (


<DragDropContext onDragEnd={onDragEndPrin}>
<Droppable droppableId="table">
  {(provided) => (
    <table ref={provided.innerRef}>
      <thead>
        <tr>
          <th style={{width:'86%', fontSize:'10px'}}>Name </th>
          <th style={{width:'14%', fontSize:'10px'}}>Order No </th>
        </tr>
      </thead>
      <tbody {...provided.droppableProps}>
      {principalList.map((row, index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
<Draggable key={row.orderNumber} draggableId={`${row.orderNumber}`} index={index}>
{(provided) => (
  <tr
  className={index % 2 === 0?classes.trBackColor1:classes.trBackColor2} 
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    
    
  >
    <td><span style={{fontSize:"12px", fontWeight: 'bold'}}>{(row.shortName != null && row.shortName != ''? row.shortName+ ' - ': '')}</span>{row.name}</td>
    {/* <td style={{height:'40px'}}>{index}</td> */}
    <td style={{float: 'right'}} >{row.orderNumber}</td>
  </tr>
)}
</Draggable>
)
}
})}
        {provided.placeholder}
      </tbody>
    </table>
  )}
</Droppable>
</DragDropContext>

)

const entityAllTableRowData = (


      <DragDropContext onDragEnd={onDragEndEnti}>
      <Droppable droppableId="table">
        {(provided) => (
          <table ref={provided.innerRef}>
            <thead>
              <tr>
                <th style={{width:'86%', fontSize:'10px'}}>Name </th>
                <th style={{width:'14%', fontSize:'10px'}}>Order No </th>
              </tr>
            </thead>
            <tbody {...provided.droppableProps}>
            {entityList != null && entityList.map((row, index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
      <Draggable key={row.orderNumber} draggableId={`${row.orderNumber}`} index={index}>
      {(provided) => (
        <tr
        className={index % 2 === 0?classes.trBackColor1:classes.trBackColor2} 
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          
          
        >
          <td>{row.entityName}</td>
          {/* <td style={{height:'40px'}}>{index}</td> */}
          <td style={{float: 'right'}} >{row.orderNumber}</td>
        </tr>
      )}
      </Draggable>
      )
    }
  })}
              {provided.placeholder}
            </tbody>
          </table>
        )}
      </Droppable>
      </DragDropContext>

)


    return (
        <>

          <RightBarDataForOtherPages PageName={PageName}/>

            <div className={classes.pageTop}>
                <Grid container spacing={2}>
                    <Grid container spacing={2} className={classes.pageBodyStyle}>
                        
                        <Grid item xs={12}>
                            <div role="presentation" onClick={handleClick}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" to="/login">
                                    <span className='breadcrumbsStyle'>Home</span>
                                    </Link>
                                    <Typography color="text.primary"><span className='breadcrumbsStyle'>Main menu - Custom Standard</span></Typography>
                                </Breadcrumbs>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                          <a style={{float: 'right',marginTop: '-36px',color: '#0056b3', cursor:'pointer'}} onClick={() => navigate(-1)}><ArrowBack/> <span className='breadcrumbsStyle'>Go Back</span></a>
                        </Grid>
                        
                        
                           
                        <Grid item xs={leftBarGridCount}>
                            <Card id='focusCardId' style={{"width": "100% !important" , border:"1px solid black"}}>
                                <CardHeader 
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                            <Info/>
                                        </Avatar>
                                    } 
                                    className={classe.commonMainTableHead} 
                                    title={headerInfoMessage}
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                      <Grid item xs={5}>
                                        <Box component="form" sx={{'& .MuiTextField-root': { m: 0, width: '100%' },}} noValidate autoComplete="off">
                                          <div>
                                              <TextField
                                                id="hiddenTextName"
                                                label="This is for Hodden Text Value for Table Row Delete"
                                                value={hiddenNamevalue}
                                                onChange={handleHiddenName}
                                                hidden
                                                />
                                              <TextField
                                                  id="standardName"
                                                  label="Standard Name"
                                                  multiline
                                                  maxRows={2}
                                                  value={standardNamevalue}
                                                  onChange={handleStandardName}
                                              />
                                          </div>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={7}>
                                        <Box component="form" sx={{'& .MuiTextField-root': { m: 0, width: '100%' },}} noValidate autoComplete="off">
                                          <div>
                                            <TextField
                                                id="standardDescription"
                                                label="Standard Description"
                                                multiline
                                                maxRows={2}
                                                value={standardDescriptionvalue}
                                                onChange={handleStandardDescription}
                                            />
                                          </div>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12}>
                                      <Card className={classes.tableCardOptionButtons}>
                                      <Grid item xs={12}>
                                        <Stack direction="row" spacing={2}>
                                          <Button 
                                            variant="outlined" 
                                            size='small' 
                                            className={btnObjectiveClass}
                                            onClick={objectiveBtnHandleClick}
                                            style={screenSize < 481 ? {fontSize: '8px'} : {fontSize: '10px'}}
                                            > 
                                            <AddIcon style={screenSize < 481 ? {fontSize: '12px'} : {fontSize: '16px'}}/> Add Objectives
                                          </Button>
                                          <Button 
                                            variant="outlined" 
                                            size='small' 
                                            className={btnComponentClass}
                                            onClick={componentBtnHandleClick}
                                            style={screenSize < 481 ? {fontSize: '8px'} : {fontSize: '10px'}}
                                            > 
                                            <AddIcon style={screenSize < 481 ? {fontSize: '12px'} : {fontSize: '16px'}}/> Add Components
                                          </Button>
                                          <Button 
                                            variant="outlined" 
                                            size='small' 
                                            className={btnPrincipalClass}
                                            onClick={principalBtnHandleClick}
                                            style={screenSize < 481 ? {fontSize: '8px'} : {fontSize: '10px'}}
                                            > 
                                            <AddIcon style={screenSize < 481 ? {fontSize: '12px'} : {fontSize: '16px'}}/> Add Principles
                                          </Button>
                                          <Button 
                                            variant="outlined" 
                                            size='small' 
                                            className={btnEntityClass}
                                            onClick={entityBtnHandleClick}
                                            style={screenSize < 481 ? {fontSize: '8px'} : {fontSize: '10px'}}
                                            > 
                                            <AddIcon style={screenSize < 481 ? {fontSize: '12px'} : {fontSize: '16px'}}/> Add Entities
                                          </Button> 

                                            {showAllStandardBtn ? 
                                            <Button 
                                            variant="outlined" 
                                            size='small' 
                                            //className={btnEntityClass}
                                            //onClick={entityBtnHandleClick}
                                            onClick={handleClickOpen}
                                            style={screenSize < 481 ? {fontSize: '8px'} : {fontSize: '10px'}}
                                            > 
                                             Show All Data
                                          </Button> 
                                          :''
                                          }                                        
                                        </Stack>
                                      </Grid>
                                      {btnObjectiveClicked && (
                                        <>
                                          <Grid item xs={12} spacing={2} className={classes.borderPadding}>
                                            <Card className={classes.tableCardNew}>
                                              <CardHeader 
                                                avatar={
                                                  <Avatar sx={{ bgcolor: '#4C7EE0', width:'25px', height:'25px' }}>
                                                    +
                                                  </Avatar>
                                                  } 
                                                className={classe.commonSecondaryTableHead} 
                                                title="Custom Standard Objectives"
                                                />
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Grid item className='customobjleft' >
                                                      <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                                                        <div>
                                                          <TextField
                                                            id="objectiveName"
                                                            label="Type Objective Name"
                                                            multiline
                                                            maxRows={5}
                                                            value={objectiveNameValue}
                                                            onChange={handleobjectiveName}
                                                          />
                                                        </div>
                                                      </Box> 
                                                    </Grid>
                                                    <Grid item className='customobjmiddle'>
                                                      <Button variant="outlined" onClick={globalObjHandleClickOpen('paper')} style={{fontSize:'0.7rem', height: '50px'}}>
                                                        You can Select an Objective From Global Standards
                                                      </Button>
                                                      
                                                    </Grid>
                                                    <Grid item className='customobjright'>
                                                      <Button 
                                                        variant="contained" 
                                                        style={{fontSize:'0.7rem', height: '50px'}}
                                                        startIcon={<AddIcon />} 
                                                        onClick={() => addToObjListFunc()}
                                                        >
                                                        Add To List
                                                      </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      <div className={classes.scrollableDiv}>
                                                        <TableContainer 
                                                          component={Paper}
                                                          sx={{ maxHeight: 440 }}
                                                          >
                                                          <Table sx={{ minWidth: 600 }} aria-label="customized table" style={{border: '1px solid #ddd'}}>
                                                            <TableHead className={classe.commonSecondaryTableHead}>
                                                              <TableRow>
                                                                <StyledTableCell className={classe.commonTableThStyle} style={{width: '80%'}}>Objective Name</StyledTableCell>
                                                                <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Edit</StyledTableCell>
                                                                <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Delete</StyledTableCell>
                                                              </TableRow>
                                                            </TableHead>
                                                            {objectiveTableRowData}
                                                          </Table>
                                                        </TableContainer>    
                                                      </div>
                                                    </Grid>  
                                                    { objectiveList.length > 0 && (
                                                      <Grid item xs={12}>
                                                          <Button 
                                                            variant="outlined" 
                                                            size='small' 
                                                            className={classes.btnBorderRed}
                                                            onClick={objPosHandleClickOpen('paper')}
                                                            style={{float:'right'}}
                                                            > 
                                                            Need To Change Objective Position
                                                          </Button>
                                                      </Grid>
                                                    )}
                                                    


                                                  </Grid>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                        </>
                                      )}
                                      {btnComponentClicked && (
                                        <>
                                        <Grid item xs={12} spacing={2}>
                <Card className={classes.tableCardNew}>
                  <CardHeader 
                    avatar={
                      <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                        +
                      </Avatar>
                      } 
                    className={classe.commonSecondaryTableHead} 
                    title="Custom Standard Components"
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item className='customobjleft' >
                          <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                            <div>
                              <TextField
                                id="componentName"
                                label="Type Component Name"
                                multiline
                                maxRows={5}
                                value={componentNameValue}
                                onChange={handleComponentName}
                              />
                            </div>
                          </Box> 
                        </Grid>
                        <Grid item className='customobjmiddle'>
                          <Button variant="outlined" onClick={globalCompHandleClickOpen('paper')} style={{fontSize:'0.7rem', height: '50px'}}>
                            You can Select a Component From Global Standards
                          </Button>
                        </Grid>
                        <Grid item className='customobjright'>
                          <Button 
                            variant="contained" 
                            style={{fontSize:'0.7rem', height: '50px'}}
                            startIcon={<AddIcon />} 
                            onClick={() => addToCompListFunc()}
                            >
                            Add To List
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.scrollableDiv}>
                            <TableContainer 
                              component={Paper}
                              sx={{ maxHeight: 440 }}
                              >
                              <Table sx={{ minWidth: 600 }} aria-label="customized table" style={{border: '1px solid #ddd'}}>
                                <TableHead className={classe.commonSecondaryTableHead}>
                                  <TableRow>
                                    <StyledTableCell className={classe.commonTableThStyle} style={{width: '80%'}}>Component Name</StyledTableCell>
                                    <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Edit</StyledTableCell>
                                    <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Delete</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                {componentTableRowData}
                              </Table>
                            </TableContainer>    
                          </div>
                        </Grid>  
                        { componentList.length > 0 && (
                            <Grid item xs={12}>
                                <Button 
                                  variant="outlined" 
                                  size='small' 
                                  className={classes.btnBorderRed}
                                  onClick={compPosHandleClickOpen('paper')}
                                  style={{float:'right'}}
                                  > 
                                  Need To Change Component Position
                                </Button>
                            </Grid>
                          )}


                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                                        
                                        
                                        </>

              )}
                {btnPrincipalClicked && (
                                         <>
                <Grid item xs={12} spacing={2}>
                <Card className={classes.tableCardNew}>
                  <CardHeader 
                    avatar={
                      <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                        +
                      </Avatar>
                      } 
                    className={classe.commonSecondaryTableHead} 
                    title="Custom Standard Principles"
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item className='principleflleft' >
                          <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                            <div>
                              <TextField 
                                id="componentName"
                                label="Type Principle Name"
                                multiline
                                maxRows={5}
                                value={principalNameValue}
                                onChange={handlePrincipalName}
                              />
                            </div>
                          </Box> 
                        </Grid>
                        <Grid item className='principleflright'>
                          <Button variant="outlined" onClick={globalPriHandleClickOpen('paper')} className='selectprinciplebutton'>
                          You can Select a Principle From Global Standards
                          </Button>
                        </Grid>

                        <Grid item className='principleslleft'>
                          <Box sx={{ minWidth: 120}}>    
                            <FormControl fullWidth>
                                <TextField
                                    label={'Select a Short Name from Component List'}
                                    sx={{ width: '100%' }}
                                    select
                                    value={selectedShortNameId}
                                    onChange={handleChangeShortName}
                                    >
                                    {componentListForShortName.map((item)=>(
                                        <MenuItem 
                                            value={item.id}
                                            >
                                        {item.componentName}
                                        </MenuItem>
                                    ))}

                                </TextField>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid className='principleslmiddle'>
                            <Button 
                              variant="outlined" 
                              onClick={e => openCreateNewShortNameFunction()}  
                              style={{fontSize:'0.7rem', height: '50px', marginTop:'8PX'}}
                              >Name a new Component
                            </Button>
                        </Grid>
                        <Grid item className='principleslright'>
                          <Button 
                            variant="contained" 
                            style={{fontSize:'0.7rem', height: '50px'}}
                            startIcon={<AddIcon />} 
                            onClick={() => addToPriListFunc()}
                            >
                            Add To List
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.scrollablePriDiv}>
                            <TableContainer 
                              component={Paper}
                              sx={{ maxHeight: 440 }}
                              >
                              <Table sx={{ minWidth: 600 }} aria-label="customized table" style={{border: '1px solid #ddd'}}>
                                <TableHead className={classe.commonSecondaryTableHead}>
                                  <TableRow>
                                  <StyledTableCell className={classe.commonTableThStyle} style={{width: '20%'}}>Component Name</StyledTableCell>
                                    <StyledTableCell className={classe.commonTableThStyle} style={{width: '60%'}}>Principle Name</StyledTableCell>
                                    <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Edit</StyledTableCell>
                                    <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Delete</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                {principalTableRowData}
                              </Table>
                            </TableContainer>    
                          </div>
                        </Grid>  
                        { principalList.length > 0 && (
                          <Grid item xs={12}>
                              <Button 
                                variant="outlined" 
                                size='small' 
                                className={classes.btnBorderRed}
                                onClick={priPosHandleClickOpen('paper')}
                                style={{float:'right'}}
                                > 
                                Need To Change Principle Position
                              </Button>
                          </Grid>
                        )}

                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                                         
                                         
                                         
                                         </> 


                                      )}
                                      {btnEntityClicked && (
                                        <>
                                         <Grid item xs={12} spacing={2}>
                                          <Card className={classes.tableCardNew}>
                                            <CardHeader 
                                              avatar={
                                                <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                                                  +
                                                </Avatar>
                                                } 
                                              className={classe.commonSecondaryTableHead} 
                                              title="Global Standard Entities"
                                              />
                                              <CardContent>
                                                <Grid container spacing={2}>
                                                  <Grid item className='cusentityselectglobal' >
                                                    <Box sx={{ minWidth: 120 }}>    
                                                      <FormControl fullWidth>
                                                        <InputLabel id="entity-select-label">Select a Global Entity</InputLabel>
                                                        <Select 
                                                          labelId="entity-select-label" 
                                                          id="entitied-select"
                                                          value={entityIdValue}
                                                          label="Select A Entity"
                                                          onChange={handleChangeEntity}
                                                          >
                                                          {entityData.map((item)=>{
      if (item.isDelete === null || !item.isDelete ) {
        return (
                                                              <MenuItem 
                                                                  value={item.entityId}
                                                                  >
                                                              {item.entityName}
                                                              </MenuItem>
                                                           )
                                                          }
                                                        })}
                                                        </Select>
                                                      </FormControl>
                                                    </Box>
                                                  </Grid>

                                                  <Grid item className='cusentitychangeglobal' >
                                                    <Box centityText="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                                                      <div>
                                                        <TextField
                                                          id="entityTextName"
                                                          label="Change the Global entity name for Standard If Required"
                                                          multiline
                                                          maxRows={1}
                                                          value={entityTextNameValue}
                                                          onChange={handleEntityTextName}
                                                        />
                                                      </div>
                                                    </Box> 
                                                  </Grid>



                                                  <Grid item className='cusentityaddtolistl'>
                                                    <Button 
                                                      variant="contained" 
                                                      style={{fontSize:'0.7rem', height: '50px'}}
                                                      startIcon={<AddIcon />} 
                                                      onClick={() => addToEntityListFunc()}
                                                      >
                                                      Add To List
                                                    </Button>
                                                  </Grid>
                                                  <Grid item xs={12}>
                                                    <div className={classes.scrollableDiv}>
                                                      <TableContainer 
                                                        component={Paper}
                                                        sx={{ maxHeight: 440 }}
                                                        >
                                                        <Table sx={{ minWidth: 600 }} aria-label="customized table" style={{border: '1px solid #ddd'}}>
                                                          <TableHead className={classe.commonSecondaryTableHead}>
                                                            <TableRow>
                                                              <StyledTableCell className={classe.commonTableThStyle} style={{width: '80%'}}>Entity Name</StyledTableCell>
                                                              <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Edit</StyledTableCell>
                                                              <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Delete</StyledTableCell>
                                                            </TableRow>
                                                          </TableHead>
                                                          {entityTableRowData}
                                                        </Table>
                                                      </TableContainer>    
                                                    </div>
                                                  </Grid>  

                                                  { entityList.length > 0 && (
                                                      <Grid item xs={12}>
                                                          <Button 
                                                            variant="outlined" 
                                                            size='small' 
                                                            className={classes.btnBorderRed}
                                                            onClick={entPosHandleClickOpen('paper')}
                                                            style={{float:'right'}}
                                                            > 
                                                            Need To Change Entity Position
                                                          </Button>
                                                      </Grid>
                                                    )}

                                                </Grid>
                                              </CardContent>
                                            </Card>
                                          </Grid>                 
                                        </> 
                                      )}
                                      </Card>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Stack direction="row" spacing={2} style={{float: 'right'}}>
                                          <Button variant="contained" type='submit' onClick={e => onFormSubmit(e)}>{saveBtnName}</Button>
                                          <Button variant="contained" onClick={clearFuction} >Clear</Button>
                                        </Stack>

                                      </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                      <Grid item xs={rightBarGridCount} spacing={2} style={{visibility: visibilityFlag}}>
                      <Card id='focusCardId' className='stdCard'>
                        <CardHeader 
                          avatar={
                            <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                              <Info/>
                            </Avatar>
                            } 
                          className={classe.commonMainTableHead} 
                          title="Custom Standards Created"
                          />
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div className={classes.scrollableDivToStandard}>
                                  <TableContainer 
                                    component={Paper}
                                    //sx={{ maxHeight: 'auto' }}
                                    >
                                    <Table aria-label="customized table">
                                      <TableHead className={classe.commonSecondaryTableHead}>
                                        <TableRow>
                                          <StyledTableCell className={classe.commonTableThStyle} style={{width: '65%'}}>Standard Name</StyledTableCell>
                                          <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '10%'}}>Edit</StyledTableCell>
                                        </TableRow>
                                      </TableHead>
                                      {previousStandardTableRowData}
                                    </Table>
                                  </TableContainer>    
                                </div>
                              </Grid>  
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>  
                    </Grid>
                </Grid>
            </div>    

        {/** Starting Dialog Boxes */}
        {/** Global Objective Dialog Box */}
        <Dialog
            maxWidth={maxWidth}
            open={globalObjOpen}
            onClose={globalObjHandleClickClose}
            scroll={globalObjScroll}
            aria-labelledby="global-obj-scroll-dialog-title"
            aria-describedby="global-obj-scroll-dialog-description"
            >
            <DialogTitle id="global-obj-scroll-dialog-title">Select an Objective Name from a Global Standard</DialogTitle>
            <DialogContent dividers={globalObjScroll === 'paper'}>
              <DialogContentText
                id="global-obj-scroll-dialog-description"
                tabIndex={-1}
                >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel id="radio-buttons-group-label">Pick your Standard</FormLabel>
                  </Grid>       
                  <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                    <Stack spacing={10} direction="row" className={classes.mainbtn}>
                      {standardData.map((item,index)=>{
                        if (item.centreId === "globle" && item.objectives != null && item.objectives.length > 0 ){
                          return(
                          <Button 
                              variant="contained" 
                              id={"btn" + index}
                              className={classes.globalBtn}
                              onClick={() => getObjectives(item.objectives,item.components,item.principles,item.standardName,index)}
                              sx={{ boxShadow: 6 }}
                              >
                              {item.standardName}
                          </Button>
                          )
                        }  
                      })}
                    </Stack>
                  </Grid>
                  <Grid item xs={8}>
                    <Box sx={{ minWidth: 120 }}>    
                      <FormControl fullWidth>
                        <InputLabel id="objectives-select-label">Select a Global Objective</InputLabel>
                        <Select 
                          labelId="objectives-select-label" 
                          id="objectives-select"
                          value={globalObjectiveId}
                          label="Select Global Objectives"
                          onChange={handleChangeGlobalObjectives}
                          >
                          {globalObjectiveData.map((item)=>{
      if (item.isDelete === null || !item.isDelete ) {
        return (
                              <MenuItem 
                                  value={item.objectiveId}
                                  >
                              {item.name}
                              </MenuItem>
                          )
                        }
                      })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Button 
                      variant="outlined" 
                      style={{fontSize:'0.7rem', height: '50px'}}
                      //startIcon={<AddIcon />} 
                      onClick={() => setObjectiveTextFunc()}
                      >
                      Select This
                    </Button>     
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={globalObjHandleClickClose}>Cancel</Button>
            </DialogActions>
          </Dialog>

        {/** End of Global Objective Dialog */}

{/** Global Component Dialog Box */}
<Dialog
          maxWidth={maxWidth}
          open={globalCompOpen}
          onClose={globalCompHandleClickClose}
          scroll={globalCompScroll}
          aria-labelledby="global-comp-scroll-dialog-title"
          aria-describedby="global-comp-scroll-dialog-description"
          >
          <DialogTitle id="global-comp-scroll-dialog-title">Select a Component Name from a Global Standard</DialogTitle>
          <DialogContent dividers={globalCompScroll === 'paper'}>
            <DialogContentText
              id="global-comp-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel id="radio-buttons-group-label">Pick your Standard</FormLabel>
                </Grid>       
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <Stack spacing={10} direction="row" className={classes.mainbtn}>
                  {standardData.map((item,index)=>{
                        if (item.centreId === "globle" && item.components != null && item.components.length > 0){
                          return(
                          <Button 
                              variant="contained" 
                              id={"btn" + index}
                              className={classes.globalBtn}
                              onClick={() => getObjectives(item.objectives,item.components,item.principles,item.standardName,index)}
                              sx={{ boxShadow: 6 }}
                              >
                              {item.standardName}
                          </Button>
                          )
                        }  
                      })}
                  </Stack>
                </Grid>
                

                <Grid item xs={8}>
                  <Box sx={{ minWidth: 120 }}>    
                      <FormControl fullWidth>
                          <InputLabel id="global-components-select-label">Select a Global Components</InputLabel>
                          <Select 
                              labelId="global-components-select-label" 
                              id="global-components-select"
                              value={globalComponentId}
                              label="Select a Global Component"
                              onChange={handleChangeGlobalComponents}
                          >

                              {globalComponentsData.map((item)=>{
      if (item.isDelete === null || !item.isDelete ) {
        return (
                                  <MenuItem 
                                      value={item.componentId}
                                      >
                                      {item.name}
                                  </MenuItem>
                                  )
                                }
                              })}
                              
                          </Select>
                      </FormControl>
                  </Box>   
                </Grid>
                <Grid item xs={4}>
                  <Button 
                    variant="outlined" 
                    style={{fontSize:'0.7rem', height: '50px'}}
                    //startIcon={<AddIcon />} 
                    onClick={() => setComponentTextFunc()}
                    >
                    Select This
                  </Button>     
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={globalCompHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Global Component Dialog Box */}

        {/** Global Principal Dialog Box */}
        <Dialog
          maxWidth={maxWidth}
          open={globalPriOpen}
          onClose={globalPriHandleClickClose}
          scroll={globalPriScroll}
          aria-labelledby="global-pri-scroll-dialog-title"
          aria-describedby="global-pri-scroll-dialog-description"
          >
          <DialogTitle id="global-pri-scroll-dialog-title">Select a Principle Name from a Global Standard</DialogTitle>
          <DialogContent dividers={globalPriScroll === 'paper'}>
            <DialogContentText
              id="global-pri-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel id="radio-buttons-group-label">Pick your Standard</FormLabel>
                </Grid>       
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <Stack spacing={10} direction="row" className={classes.mainbtn}>
                  {standardData.map((item,index)=>{
                        if (item.centreId === "globle" && item.principles != null && item.principles.length > 0){
                          return(
                          <Button 
                              variant="contained" 
                              id={"btn" + index}
                              className={classes.globalBtn}
                              onClick={() => getObjectives(item.objectives,item.components,item.principles,item.standardName,index)}
                              sx={{ boxShadow: 6 }}
                              >
                              {item.standardName}
                          </Button>
                          )
                        }  
                      })}
                  </Stack>
                </Grid>

                <Grid item xs={8}>
                  <Box sx={{ minWidth: 120 }}>    
                      <FormControl fullWidth>
                          <InputLabel id="global-principal-select-label">Select a Global Principle</InputLabel>
                          <Select 
                              labelId="global-principal-select-label" 
                              id="global-principal-select"
                              value={globalPrincipleId}
                              label="Select a Global Principle"
                              onChange={handleChangeGlobalPrinciples}
                          >

                              {globalPrincipalsData.map((item)=>{
      if (item.isDelete === null || !item.isDelete ) {
        return (
                                  <MenuItem 
                                      value={item.principalId}
                                      >
                                      {item.name}
                                  </MenuItem>
                              )
                            }
                          })}
                          </Select>
                      </FormControl>
                  </Box>   
                </Grid>


                <Grid item xs={4}>
                  <Button 
                    variant="outlined" 
                    style={{fontSize:'0.7rem', height: '50px'}}
                    //startIcon={<AddIcon />} 
                    onClick={() => setPrincipalTextFunc()}
                    >
                    Select This
                  </Button>     
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={globalPriHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Global Principal Dialog Box */}  
      {/** objective possition change Dialog Box */}
        <Dialog
          maxWidth={maxWidth}
          open={objPosOpen}
          onClose={objPosHandleClickClose}
          scroll={objPosScroll}
          aria-labelledby="objPos-scroll-dialog-title"
          aria-describedby="objPos-scroll-dialog-description"
          >
          <DialogTitle id="objPos-scroll-dialog-title">Change the Position if Required</DialogTitle>
          <DialogContent dividers={objPosScroll === 'paper'}>
            <DialogContentText
              id="global-pri-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <TableContainer 
                    component={Paper}
                    sx={{ maxHeight: 440 }}
                    >
                    <Table sx={{ minWidth: 695 }} aria-label="customized table">
                      <TableHead className={classe.commonSecondaryTableHead}>
                        <TableRow>
                          <StyledTableCell className={classe.commonTableThStyle} style={{width: '65%'}}>Objective Name</StyledTableCell>
                          <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '20%'}}>Previous position</StyledTableCell>
                          <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '15%'}}>New position</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {objectiveList.map((row,index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
                          <StyledTableRow key={index}  className={classes.tableTrStyle}>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                              <div style= {{ whiteSpace: 'normal' }}>{row.name}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} align="right">
                              <div style= {{ whiteSpace: 'normal' }}>{row.orderNumber}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} align="right">
                                <TextField
                                    id={"newObjPos" + index}
                                    label="position"
                                    defaultValue={row.orderNumber}
                                    size="small"
                                />
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      }
                    })}
                      </TableBody>
                    </Table>
                  </TableContainer>  
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => confirmObJNewPosFunc()}>Confirm</Button>&nbsp;&nbsp;<Button onClick={objPosHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of objective possition Dialog Box */} 

      {/** Component possition change Dialog Box */}
        <Dialog
          maxWidth={maxWidth}
          open={compPosOpen}
          onClose={compPosHandleClickClose}
          scroll={compPosScroll}
          aria-labelledby="compPos-scroll-dialog-title"
          aria-describedby="compPos-scroll-dialog-description"
          >
          <DialogTitle id="objPos-scroll-dialog-title">Change the Position of the Components if Required</DialogTitle>
          <DialogContent dividers={compPosScroll === 'paper'}>
            <DialogContentText
              id="comp-pri-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <TableContainer 
                    component={Paper}
                    sx={{ maxHeight: 440 }}
                    >
                    <Table sx={{ minWidth: 695 }} aria-label="customized table">
                      <TableHead className={classe.commonSecondaryTableHead}>
                        <TableRow>
                          <StyledTableCell className={classe.commonTableThStyle} style={{width: '65%'}}>Component Name</StyledTableCell>
                          <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '20%'}}>Previous position</StyledTableCell>
                          <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '15%'}}>New position</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {componentList.map((row,index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
                          <StyledTableRow key={index}  className={classes.tableTrStyle}>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                              <div style= {{ whiteSpace: 'normal' }}>{row.name}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} align="right">
                              <div style= {{ whiteSpace: 'normal' }}>{row.orderNumber}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} align="right">
                                <TextField
                                    id={"newCompPos" + index}
                                    label="position"
                                    defaultValue={row.orderNumber}
                                    size="small"
                                />
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      }
                    })}
                      </TableBody>
                    </Table>
                  </TableContainer>  
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => confirmCompNewPosFunc()}>Confirm</Button>&nbsp;&nbsp;<Button onClick={compPosHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Component possition Dialog Box */} 

      {/** Principle possition change Dialog Box */}
        <Dialog
          maxWidth={maxWidth}
          open={priPosOpen}
          onClose={priPosHandleClickClose}
          scroll={priPosScroll}
          aria-labelledby="priPos-scroll-dialog-title"
          aria-describedby="priPos-scroll-dialog-description"
          >
          <DialogTitle id="priPos-scroll-dialog-title">Change the Position of the Principles if Required</DialogTitle>
          <DialogContent dividers={priPosScroll === 'paper'}>
            <DialogContentText
              id="pri-pri-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <TableContainer 
                    component={Paper}
                    sx={{ maxHeight: 440 }}
                    >
                    <Table sx={{ minWidth: 695 }} aria-label="customized table">
                      <TableHead className={classe.commonSecondaryTableHead}>
                        <TableRow>
                          <StyledTableCell className={classe.commonTableThStyle} style={{width: '45%'}}>Principle Name</StyledTableCell>
                          <StyledTableCell className={classe.commonTableThStyle} style={{width: '20%'}}>Component Name</StyledTableCell>
                          <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '20%'}}>Previous position</StyledTableCell>
                          <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '15%'}}>New position</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {principalList.map((row,index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
                          <StyledTableRow key={index}  className={classes.tableTrStyle}>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                              <div style= {{ whiteSpace: 'normal' }}>{row.name}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                              <div style= {{ whiteSpace: 'normal' }}>{row.shortName === null? '':row.shortName}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} align="right">
                              <div style= {{ whiteSpace: 'normal' }}>{row.orderNumber}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} align="right">
                                <TextField
                                    id={"newPriPos" + index}
                                    label="position"
                                    defaultValue={row.orderNumber}
                                    size="small"
                                />
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      }
                    })}
                      </TableBody>
                    </Table>
                  </TableContainer>  
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => confirmPriNewPosFunc()}>Confirm</Button>&nbsp;&nbsp;<Button onClick={priPosHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of Principal possition Dialog Box */} 

      {/** Entity possition change Dialog Box */}
        <Dialog
          maxWidth={maxWidth}
          open={entPosOpen}
          onClose={entPosHandleClickClose}
          scroll={entPosScroll}
          aria-labelledby="entPos-scroll-dialog-title"
          aria-describedby="entPos-scroll-dialog-description"
          >
          <DialogTitle id="entPos-scroll-dialog-title">Change the Position of the Entities if Required</DialogTitle>
          <DialogContent dividers={entPosScroll === 'paper'}>
            <DialogContentText
              id="ent-pri-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                  <TableContainer 
                    component={Paper}
                    sx={{ maxHeight: 440 }}
                    >
                    <Table sx={{ minWidth: 695 }} aria-label="customized table">
                      <TableHead className={classe.commonSecondaryTableHead}>
                        <TableRow>
                          <StyledTableCell className={classe.commonTableThStyle} style={{width: '65%'}}>Entity Name</StyledTableCell>
                          <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '20%'}}>Previous position</StyledTableCell>
                          <StyledTableCell align="right" className={classe.commonTableThStyle} style={{width: '15%'}}>New position</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {entityList.map((row,index) => {
      if (row.isDelete === null || !row.isDelete ) {
        return (
                          <StyledTableRow key={index}  className={classes.tableTrStyle}>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
                              <div style= {{ whiteSpace: 'normal' }}>{row.entityName}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} align="right">
                              <div style= {{ whiteSpace: 'normal' }}>{row.orderNumber}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} align="right">
                                <TextField
                                    id={"newEntPos" + index}
                                    label="position"
                                    defaultValue={row.orderNumber}
                                    size="small"
                                />
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      }
                    })}
                      </TableBody>
                    </Table>
                  </TableContainer>  
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => confirmEntNewPosFunc()}>Confirm</Button>&nbsp;&nbsp;<Button onClick={entPosHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of entity possition Dialog Box */}  

      {/** Create New Principle Short Name */}
      <Dialog
        maxWidth='md'
        open={openCreateNewShortName}
        onClose={false}
        scroll={openCreateNewShortNameScroll}
        aria-labelledby="create-new-scale-draggable-dialog-title"
        >
        <DialogTitle style={{ cursor: 'move' }} id="create-new-scale-draggable-dialog-title">Create a New Short Name</DialogTitle>
        <DialogContent dividers={openCreateNewShortNameScroll === 'paper'}>
          <DialogContentText id="create-new-scale-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} validate autoComplete="off">
                  <div>
                    <TextField
                        id="newShortName"
                        label="Type the New Short Name"
                        multiline
                        maxRows={2}
                        value={newShortName}
                        onChange={handleChangeNewShortName}
                    />
                  </div>
                </Box> 
              </Grid>
              <Grid item xs={12}>
                <Button 
                    variant="outlined"
                    id="newShortNameBtn" 
                    style={{float: 'right'}}
                    onClick={()=>saveNewShortNameFuction()}
                    >
                    Add
                </Button>     
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={openCreateNewShortNameHandleClickClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/** End of Create New Scale*/}

      {/** start of show all data*/}
      
      <Dialog
        open={open}
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'xl'}
       // style={{width:'500px'}}
        //fullScreen="true"
      >
        <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" style={{fontSize:'25px !important'}}>
            Custom Standard Name - {standardNamevalue}
        	</Typography>
	        <Typography variant="h6" style={{marginRight: '-5px', padding: '7px'}}>
          <Button
              variant="contained"
              color="primary"
              size="small"
              style={{marginTop: '-30px', float: 'right'}}
              onClick={() => {
                  showCosoCube()
              }}
              >
              Show Graphical View
            </Button>
          </Typography>
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>

        <Grid item xs={3} >
                      <Card className={classes.stdCardShowAll}>
                        <CardHeader 
                          avatar={
                            <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                              <Info/>
                            </Avatar>
                            } 
                          className={classe.commonMainTableHead} 
                          title="Objectives"
                          />
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div className={classes.scrollableDivToStandardShowAll}>
                                  
                                    
                                      {objectiveAllTableRowData}
                                    
                                    
                                </div>
                              </Grid>  

                            </Grid>
                          </CardContent>
                          <Button onClick={()=> confirmObJNewPosFunc()} style={{float:'right'}} variant="outlined">confirm changes</Button>
                        </Card>
                      </Grid> 
        <Grid item xs={3} >
                      <Card className={classes.stdCardShowAll}>
                        <CardHeader 
                          avatar={
                            <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                              <Info/>
                            </Avatar>
                            } 
                          className={classe.commonMainTableHead} 
                          title="Components"
                          />
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div className={classes.scrollableDivToStandardShowAll}>
                                 
                                    
                                      {componentsTableRowData}
                                  
                                </div>
                              </Grid>  

                            </Grid>
                          </CardContent>
                          <Button onClick={()=> confirmCompNewPosFunc()} style={{float:'right'}} variant="outlined">confirm changes</Button>
                        </Card>
                      </Grid> 


                    


                      <Grid item xs={3} >
                      <Card className={classes.stdCardShowAll}>
                        <CardHeader 
                          avatar={
                            <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                              <Info/>
                            </Avatar>
                            } 
                          className={classe.commonMainTableHead} 
                          title="Principles"
                          />
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div className={classes.scrollableDivToStandardShowAll}>
                                 
                                    
                                      {principleTableRowData}
                                   
                                  
                                </div>
                              </Grid>  

                            </Grid>
                          </CardContent>

                          <Button onClick={()=> confirmPriNewPosFunc()} style={{float:'right'}} variant="outlined">confirm changes</Button>
                        </Card>
                      </Grid> 

                      <Grid item xs={3} >
                      <Card className={classes.stdCardShowAll}>
                        <CardHeader 
                          avatar={
                            <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                              <Info/>
                            </Avatar>
                            } 
                          className={classe.commonMainTableHead} 
                          title="Entities"
                          />
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div className={classes.scrollableDivToStandardShowAll}>
                                 
                                    
                                      {entityAllTableRowData}
                                    
                                </div>
                              </Grid>  

                            </Grid>
                          </CardContent>
                          <Button onClick={()=> confirmEntNewPosFunc()} style={{float:'right'}} variant="outlined">confirm changes</Button>  
                        </Card>
                      </Grid> 
                      </Grid>
        </DialogContent>
        <DialogActions>
       
          <Button onClick={handleClose} >
            Back
          </Button>
        </DialogActions>
      </Dialog>
  
   {/** end of show all data*/}

   {/** start of show Open Cube*/}
      
   <Dialog
        open={openCube}
        onClose={false}
        scroll={openCubeScroll}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'sm'}
        //maxWidth={'xl'}
        >
        <DialogTitle id="alert-dialog-title">
	        <Typography variant="h6" style={{textAlign:'right', marginTop:'', marginRight: '-25px', padding: '7px', marginTop: '-25px'}}>
            <Tooltip title="Close"><Close onClick={()=>setOpenCube(false)} style={{cursor: 'pointer'}}/></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card className={classes.cubeCardHeight}>
                <CardHeader 
                  avatar={
                    <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px'}}>
                      <Info/>
                    </Avatar>
                  } 
                  className={classe.commonMainTableHead} 
                  titleTypographyProps={{variant:'h5' }}
                  title={"Standard Name - " + standardNamevalue}
                />
                <CardContent>
                  <LogoCube
                  objectiveList={objectiveList}
                  componentList={componentList}
                  principalList={principalList}
                  entityList={entityList}
                  />
                </CardContent>
              </Card>  
            </Grid>  
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenCube(false)} >
            Close
          </Button>
        </DialogActions>
      </Dialog>
  
   {/** end of show all data*/}



{/** Dialog Waiting */}
<Dialog
    maxWidth={'md'}
    open={waitingOpen}
    PaperProps={{style:{backgroundColor: 'transparent', boxShadow: 'none'}}} 
    >
    <CircularProgress disableShrink />
  </Dialog>


        </>
    )
    
}

export default CreateCustomStandard