import React, { useEffect, useState, useRef } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import CardContent from '@mui/material/CardContent';
import { CONFIG } from '../config'
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info, KeyboardReturnOutlined } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import '../Css/Slider.css';
import Draggable from 'react-draggable';
import useStyle from '../Css/MainCss';
import Delete from '@mui/icons-material/Delete';
import { Editor } from '@tinymce/tinymce-react';
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { Select, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddSubContentComponent from './AddSubContentComponent';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import parseHtml from 'html-react-parser';



const useStyles = makeStyles((theme) => ({

    cardRiskManage: {
        width: '100%',
        border: '1px solid black'
    },


}));
const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 2.75rem;
  }
`;


const SuperAdminLoginAboutUsPageDesign = ({ tokens }) => {

    const classe = useStyle();
    const classes = useStyles();
    const editorRef = useRef(null);

    const [aboutUsDesign, setAboutUsDesign] = useState('')

    const [aboutUsDesignId, setAboutUsDesignId] = useState('')

    React.useEffect(() => {
        getTemplates()
        getHtmlData()
    }, [])



    const [tinyMCETemplateList, setTinyMCETemplateList] = React.useState()

    var templateArr = [];
    function getTemplateHtml(fileName) {
        axios('https://www.daytaem.co.uk/readFile.php?q=' + fileName, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(function (res) {
                var tmpA = fileName.split(".")
                var templateObj = {};
                templateObj["title"] = tmpA[0].replace("RST_", "");
                templateObj["description"] = "Risk System - Template ";
                templateObj["content"] = res.data;
                templateArr.push(templateObj);
                setTinyMCETemplateList(templateArr);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    function getTemplates() {
        var filesArr = "";
        axios('https://www.daytaem.co.uk/getFile.php?q=aaaaaaa', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(function (response) {
                console.log(response);
                var resData = response.data;
                var arrTmp = resData.split("@@");
                var filesStr = arrTmp[1];

                filesArr = filesStr.split("]");
                var k = 1;

                var templateHtmlArr = [];
                for (var i = 1; i < filesArr.length; i++) {
                    if (filesArr[i] != "") {
                        if (filesArr[i].slice(0, 3) === "RST") {
                            var fileName = filesArr[i];
                            getTemplateHtml(fileName)
                        }
                    }

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [allAboutUsData, setAllAboutUsData] = React.useState([]);
    const [allDomainData, setAllDomainData] = React.useState([]);
    function getHtmlData() {
        axios(CONFIG.MAIN_URL + `/aboutUsPageContent/all`, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("All Collections Returns");
                console.log(response.data);
                setAllAboutUsData(response.data);
                var rtnData = response.data;
                var domainArr = [];
                rtnData.forEach((data, index) => {
                    if (index === 0) {
                        var obj = {};
                        obj["name"] = data.domainName;
                        domainArr.push(obj);
                    } else {
                        var dName = data.domainName;
                        var found = false;
                        domainArr.forEach((item, index) => {
                            if (item.name === dName) {
                                found = true;
                            }
                        })
                        if (!found) {
                            var obj = {};
                            obj["name"] = dName;
                            domainArr.push(obj);
                        }

                    }
                })
                setAllDomainData(domainArr)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [selectedDomainName, setSelectedDomainName] = useState("");
    const [selectedVersions, setSelectedVersions] = useState([]);

    const domainSelecter = (event, newValue) => {
        clearFunction();
        setSelectedDomainName(newValue);
        var tArr = [];
        allAboutUsData.map((data) => {
            if (data.domainName === newValue) {
                tArr.push(data);
            }
        });
        setSelectedVersions(tArr);
        setDisplayAddNewBtn(true);

    };
    const domainSelecter1 = (event) => {
        clearFunction();
        setSelectedDomainName(event.target.value);
        var tArr = [];
        allAboutUsData.map((data) => {
            if (data.domainName === event.target.value) {
                tArr.push(data);
            }
        });
        setSelectedVersions(tArr);
        if (event.target.value != '') {
            setDisplayAddNewBtn(true);
        } else {
            setDisplayAddNewBtn(false);
        }

    };




    const [displayEditedPage, setDisplayEditedPage] = useState('none')
    const [selectedVersionNo, setSelectedVersionNo] = useState('');
    const [selectedId, setSelectedId] = useState('');

    const [baseVersionNo, setBaseVersionNo] = useState('')
    const [displayEditBtn, setDisplayEditBtn] = useState(false)
    const [displayAddNewBtn, setDisplayAddNewBtn] = useState(false);
    const [selectedCurrentVersion, setSelectedCurrentVersion] = useState(false);

    const versionSelecter = (event) => {
        clearFunctionForVersion();
        setSelectedId(event.target.value);
        setDisplayEditBtn(true);
        setDisplayAddNewBtn(false);
    };

    function clearFunction() {
        setDisplayEditBtn(false);
        setDisplayAddNewBtn(false);
        setSelectedVersionNo('')
        setSelectedId('')
        setBaseVersionNo('')
        setDisplayEditedPage('none')
        setAboutUsDesignId('')
        setAboutUsDesign('');
        editorRef.current.setContent('');
        setSelectedCurrentVersion(false)
        setSelectedDomainName('')
        setSelectedVersions([])
    }

    function clearFunctionForVersion() {
        setDisplayEditBtn(false);
        setDisplayAddNewBtn(false);
        setSelectedVersionNo('')
        setSelectedId('')
        setBaseVersionNo('')
        setDisplayEditedPage('none')
        setAboutUsDesignId('')
        setAboutUsDesign('');
        editorRef.current.setContent('');
        setSelectedCurrentVersion(false)
    }




    function editFunction() {
        allAboutUsData.forEach((data) => {
            if (data.id === selectedId) {
                console.log(data)
                setDisplayEditedPage('block')
                setAboutUsDesignId(data.id);
                setSelectedVersionNo(data.versionNumber);
                if (data.versionNumber === 'current') {
                    setSelectedCurrentVersion(true)
                }
                setBaseVersionNo(data.baseVersion)
                setAboutUsDesign(data.pageContent);
                editorRef.current.setContent(data.pageContent);
                setDisplayEditBtn(false);

            }
        })
    }

    function createNewFunction() {
        setDisplayEditedPage('block')
        setAboutUsDesignId('');
        setSelectedId('');
        setAboutUsDesign('');
        editorRef.current.setContent('');
        setDisplayAddNewBtn(false);

    }


    function saveFunction() {
        var pContent = editorRef.current.getContent();
        if (pContent === '') {
            alert("Please enter page content");
            return;
        }
        var obj = {}
        var isNewDomain = false;
        if (aboutUsDesignId === '') {
            var newVerionNumber = 0;
            var foundItem = false;
            allAboutUsData.forEach((data, index) => {
                if (data.domainName === selectedDomainName && data.versionNumber != 'current') {
                    foundItem = true;
                    if (parseInt(data.versionNumber) > newVerionNumber) {
                        newVerionNumber = parseInt(data.versionNumber);
                    }
                }
            })
            if (!foundItem) {
                isNewDomain = true;
            }
            obj["versionNumber"] = (newVerionNumber + 1) + "";
            obj["baseVersion"] = "new";

        } else {
            obj["id"] = aboutUsDesignId;
            obj["versionNumber"] = selectedVersionNo;
            obj["baseVersion"] = baseVersionNo;
        }
        obj["domainName"] = selectedDomainName;
        obj["pageContent"] = editorRef.current.getContent();
        var sendJson = JSON.stringify(obj);
        console.log(sendJson)
        var sendUrl = '/aboutUsPageContent/save';
        if (aboutUsDesignId != '') {
            sendUrl = '/aboutUsPageContent/update';
        }

        axios(CONFIG.MAIN_URL + sendUrl, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: sendJson
        })
            .then(res => {
                if (isNewDomain) {
                    var obj = {}
                    obj["versionNumber"] = 'current';
                    obj["baseVersion"] = res.data.versionNumber;
                    obj["baseVersionId"] = res.data.id;
                    obj["domainName"] = selectedDomainName;
                    obj["pageContent"] = editorRef.current.getContent();
                    var sendJson = JSON.stringify(obj);
                    var sendUrl = '/aboutUsPageContent/save';
                    axios(CONFIG.MAIN_URL + sendUrl, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        data: sendJson
                    })
                        .then(res1 => {
                            getHtmlData()
                            alert(" Saved Successfully")
                            clearFunction()
                        })
                        .catch(function (error) {
                            alert('Error Updating');
                            console.log(error);
                        });

                } else {
                    getHtmlData()
                    if (aboutUsDesignId === '') {
                        alert(" Saved Successfully")
                    } else {
                        alert("Updated Successfully")
                    }
                    clearFunction()
                }

            })
            .catch(function (error) {
                alert('Error Updating');
                console.log(error);
            });

    }

    function saveNewVersionFunction() {
        var pContent = editorRef.current.getContent();
        if (pContent === '') {
            alert("Please enter page content");
            return;
        }
        var obj = {}

        obj["id"] = aboutUsDesignId;
        obj["versionNumber"] = 'new';
        obj["baseVersion"] = baseVersionNo;
        obj["domainName"] = selectedDomainName;
        obj["pageContent"] = editorRef.current.getContent();
        var sendJson = JSON.stringify(obj);
        console.log(sendJson)
        var sendUrl = '/aboutUsPageContent/save';
        if (aboutUsDesignId != '') {
            sendUrl = '/aboutUsPageContent/update';
        }

        axios(CONFIG.MAIN_URL + sendUrl, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: sendJson
        })
            .then(res => {
                getHtmlData()
                alert("Saved Successfully in a new version")
                clearFunction()
            })
            .catch(function (error) {
                alert('Error Updating');
                console.log(error);
            });

    }

    function saveAsCurrentFunction() {
        var pContent = editorRef.current.getContent();
        if (pContent === '') {
            alert("Please enter page content");
            return;
        }

        var currentId = '';
        allAboutUsData.forEach((data, index) => {
            if (data.domainName === selectedDomainName && data.versionNumber === 'current') {
                currentId = data.id;
            }
        })


        let text = "The 'current' version will Update from the selected version " + selectedVersionNo;
        text += "\n\nDo you want to create a new version from the current version and create a new 'current' version?."
        text += "\n\n'OK' to confirm.";
        text += "\n\nOr 'Cancel' to create a new 'current' version without creating a new version in existing 'current' version.";
        if (window.confirm(text) == true) {
            var obj = {}
            allAboutUsData.map((data) => {
                if (data.domainName === selectedDomainName && data.versionNumber === 'current') {
                    obj = data;
                    obj["versionNumber"] = 'new';
                }
            })

            var sendJson = JSON.stringify(obj);
            console.log(sendJson)
            var sendUrl = '/aboutUsPageContent/update';
            axios(CONFIG.MAIN_URL + sendUrl, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: sendJson
            })
                .then(res => {
                    var obj = {}

                    if (currentId != '') {
                        obj["id"] = currentId;
                    }
                    obj["versionNumber"] = 'current';
                    obj["baseVersion"] = selectedVersionNo;
                    obj["baseVersionId"] = aboutUsDesignId;
                    obj["domainName"] = selectedDomainName;
                    obj["pageContent"] = editorRef.current.getContent();
                    var sendJson = JSON.stringify(obj);
                    console.log(sendJson)
                    var sendUrl = '/aboutUsPageContent/save';
                    if (currentId != '') {
                        sendUrl = '/aboutUsPageContent/update';
                    }

                    axios(CONFIG.MAIN_URL + sendUrl, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        data: sendJson
                    })
                        .then(res => {
                            getHtmlData()
                            alert("Updated Successfully as the Current version")
                            clearFunction()
                        })
                        .catch(function (error) {
                            alert('Error Updating');
                            console.log(error);
                        });


                })
                .catch(function (error) {
                    alert('Error Updating');
                    console.log(error);
                });

        } else {


            var obj = {}

            if (currentId != '') {
                obj["id"] = currentId;
            }
            obj["versionNumber"] = 'current';
            obj["baseVersion"] = selectedVersionNo;
            obj["baseVersionId"] = aboutUsDesignId;
            obj["domainName"] = selectedDomainName;
            obj["pageContent"] = editorRef.current.getContent();
            var sendJson = JSON.stringify(obj);
            console.log(sendJson)
            var sendUrl = '/aboutUsPageContent/save';
            if (currentId != '') {
                sendUrl = '/aboutUsPageContent/update';
            }

            axios(CONFIG.MAIN_URL + sendUrl, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: sendJson
            })
                .then(res => {
                    getHtmlData()
                    if (currentId === '') {
                        alert(" Saved Successfully as the Current version")
                    } else {
                        alert("Updated Successfully as the Current version")
                    }
                    clearFunction()

                })
                .catch(function (error) {
                    alert('Error Updating');
                    console.log(error);
                });
        }

    }

    function deleteFunction() {
        var errorMsg = "";
        allAboutUsData.forEach((data, index) => {
            if (data.domainName === selectedDomainName && data.versionNumber === selectedVersionNo) {
                if (data.versionNumber === 'current') {
                    errorMsg = "Sorry!. Cannot delete the \"CURRENT\" version";
                }
            }
        })
        if (errorMsg != "") {
            alert(errorMsg);
            return;
        }

        var tId = "";
        var tContent = "";
        allAboutUsData.forEach((data, index) => {
            if (data.domainName === selectedDomainName && data.versionNumber === selectedVersionNo) {
                tId = data.id;
                tContent = data.pageContent
            }
        })

        var obj = {}
        obj["id"] = tId
        obj["pageContent"] = tContent;

        var sendJson = JSON.stringify(obj);

        console.log(sendJson)

        axios(CONFIG.MAIN_URL + '/aboutUsPageContent/delete', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            data: sendJson
        })
            .then(res => {
                getHtmlData()
                alert("Deleted Successfully")
                console.log(res.data);
                clearFunction();
            })
            .catch(function (error) {
                alert('Error Updating the collection');
                console.log(error);
            });
    }

    const [openButton1, setOpenButton1] = React.useState(true);

    const [openButton2, setOpenButton2] = React.useState(false);

    function setOpenButton1Function(flag) {
        setOpenButton1(true)
        setOpenButton2(false)
    }
    function setOpenButton2Function(flag) {
        setOpenButton2(true)
        setOpenButton1(false)

    }

    const [openImage, setOpenImage] = React.useState(false);
    const [imageHtmlData, setImageHtmlData] = React.useState('');
  
    function handleCloseClick() {
      setOpenImage(false)
    }
  
    function imagePreviw(url) {
          var str = "<img src=\"" + url + "\" />";
          setImageHtmlData(str)
          setOpenImage(true);
        
    }   


    return (
        <>
            <Card className={classes.cardRiskManage} >
                <CardContent>

                <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                            id='btnOpenRiskCollection'
                            variant="outlined"
                            size="medium"
                            className={(openButton1 ? classe.superAdmintabBtnDBClicked : classe.tabBtnDB)}
                            onClick={() => setOpenButton1Function(true)}
                        >
                            log in page design
                        </Button>
                        <Button
                            id='btnOpenRiskCollection'
                            variant="outlined"
                            size="medium"
                            className={(openButton2 ? classe.superAdmintabBtnDBClicked : classe.tabBtnDB)}
                            onClick={() => setOpenButton2Function(true)}
                        >
                            create edit sub pages
                        </Button>

                    </div>
                    <br></br>

                    {
                        openButton1 ?
                     <>   
                    <Card >
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                                    <Info />
                                </Avatar>
                            }
                            className={classe.commonMainTableHead}
                            title={'About Us Page Design'}
                        />
                        <CardContent>

                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <AutoComplete
                                        id="package-name"
                                        options={allDomainData.map((option) => option.name)}
                                        value={selectedDomainName}
                                        style={{ height: '50px' }}
                                        onChange={(event, newValue) => {
                                            domainSelecter(event, newValue);
                                        }}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Type a new domain name OR select"
                                                variant="outlined"
                                                size="small"
                                                style={{ width: '100%', height: '37px' }}
                                                onChange={(event) => domainSelecter1(event)}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label={'Select a Version OR Press Add New to Create a New Version'}
                                            sx={{ width: '100%' }}
                                            select
                                            value={selectedId}
                                            onChange={versionSelecter}
                                        >
                                            {selectedVersions.map((data, index) => (
                                                <MenuItem value={data.id}>
                                                    {data.versionNumber === 'current' ?
                                                        <>
                                                            <span style={{ fontWeight: 'bold' }}>{"Current Version "} {""}</span>

                                                            <span style={{ fontWeight: 'bold' }}>{" - Base Version: "} {data.baseVersion} {""}</span>
                                                        </>
                                                        :
                                                        <span style={{ fontWeight: 'bold' }}>{"Version "} {data.versionNumber} {""}</span>
                                                    }
                                                </MenuItem>
                                            ))}

                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <div style={{ display: 'flex', gap: '5px' }}>
                                        {displayEditBtn ?
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    style={{ height: "49px", width: '30%' }}
                                                    onClick={() => {
                                                        editFunction();
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    style={{ height: "49px", width: '30%' }}
                                                    onClick={() => deleteFunction()}>
                                                    delete</Button>
                                            </>
                                            :
                                            ''
                                        }
                                        {displayAddNewBtn ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ height: "49px", width: '30%' }}
                                                onClick={() => {
                                                    createNewFunction();
                                                }}
                                            >
                                                Create a New Version
                                            </Button>
                                            :
                                            ''
                                        }
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            style={{ height: "49px", width: '30%' }}
                                            onClick={() => {
                                                clearFunction();
                                            }}
                                        >
                                            Clear
                                        </Button>




                                    </div>
                                </Grid>

                                <Grid item xs={12} style={{ display: displayEditedPage }}>
                                    <Editor
                                        apiKey='ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj'
                                        tinymceScriptSrc={'https://cdn.tiny.cloud/1/ex3nb8i80k7nq5i5j954uoyn3zzyeqsx61qmsi92mt5pc1uj/tinymce/6/tinymce.min.js'}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue={aboutUsDesign}
                                        init={{
                                            referrer_policy: 'origin',
                                            height: 300,
                                            menubar: true,
                                            placeholder: 'About Us Page Design',
                                            plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media mediaembed template codesample table advtable charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help charmap quickbars emoticons casechange formatpainter pageembed hr fullpage advcode export permanentpen powerpaste',
                                            imagetools_cors_hosts: ['picsum.photos'],
                                            menubar: 'file edit view insert format tools table help',
                                            toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize styles | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | casechange forecolor backcolor formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview print | pageembed insertfile image media template tokens link anchor codesample | ltr rtl hr fullpage export permanentpen configurepermanentpen',
                                            //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                                            export_image_proxy: 'proxy.php',
                                            toolbar_sticky: true,
                                            autosave_ask_before_unload: true,
                                            autosave_interval: '30s',
                                            autosave_prefix: '{path}{query}-{id}-',
                                            autosave_restore_when_empty: false,
                                            autosave_retention: '2m',
                                            setup: (editor) => {
                                                editor.ui.registry.addMenuButton("tokens", {
                                                    text: "Token",
                                                    tooltip: "Insert token",
                                                    fetch: (callback) => {
                                                        var items = tokens.map((token) => {
                                                            return {
                                                                type: "menuitem",
                                                                text: token.text,
                                                                onAction: () => {
                                                                    editor.insertContent(token.value);
                                                                }
                                                            }
                                                        });
                                                        callback(items);
                                                    }
                                                });
                                                editor.on('init', function (e) {
                                                    var content = unescape(aboutUsDesign);
                                                    editor.setContent(content);
                                                    editor.dom.bind(editor.getBody(), 'click', function (e) {
                                                        var target = e.target;
                                                
                                                        // Check if the clicked element is an image
                                                        if (target.nodeName.toLowerCase() === 'img') {
                                                          // Your onclick function logic here
                                                          imagePreviw(target.src);  // Replace this with your actual onclick function
                                                        }
                                                      });
                                                });
                                            },


                                            image_title: true,
                                            /* enable automatic uploads of images represented by blob or data URIs*/
                                            automatic_uploads: true,
                                            /*Here we add custom filepicker only to Image dialog*/
                                            file_picker_types: 'image',

                                            /* and here's our custom image picker*/
                                            file_picker_callback: function (cb, value, meta) {
                                                var input = document.createElement('input');
                                                input.setAttribute('type', 'file');
                                                input.setAttribute('accept', 'image/*');

                                                input.onchange = function () {
                                                    var file = this.files[0];
                                                    var reader = new FileReader();

                                                    reader.onload = function () {
                                                        /*
                                                        Note: Now we need to register the blob in TinyMCEs image blob
                                                        registry. In the next release this part hopefully won't be
                                                        necessary, as we are looking to handle it internally.
                                                        */
                                                        var id = 'blobid' + (new Date()).getTime();

                                                        var blobCache = editorRef.current.editorUpload.blobCache;
                                                        //var blobCache = editorRef.current.activeEditor.editorUpload.blobCache;
                                                        var base64 = reader.result.split(',')[1];

                                                        var blobInfo = blobCache.create(id, file, base64);
                                                        blobCache.add(blobInfo);

                                                        /* call the callback and populate the Title field with the file name */
                                                        cb(blobInfo.blobUri(), { title: file.name });
                                                    };

                                                    reader.readAsDataURL(file);
                                                };

                                                input.click();
                                            },

                                            //images_upload_handler: function (blobInfo, success, failure) {
                                            //},

                                            templates: tinyMCETemplateList,
                                            /*
                                            templates : [
                                            {
                                                title: 'Date modified example',
                                                description: 'Adds a timestamp indicating the last time the document modified.',
                                                content: '<p>Last Modified: This will be replaced with the date modified.</time></p>'
                                            },
                                            {
                                                title: 'Replace values example',
                                                description: 'These values will be replaced when the template is inserted into the editor content.',
                                                content: '<p>Name: StaffID: </p>'
                                            }
                                            ],
                                            */
                                            referrer_policy: 'origin',

                                            template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                                            template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                                            image_caption: true,
                                            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                            noneditable_noneditable_class: 'mceNonEditable',
                                            toolbar_mode: 'sliding',
                                            contextmenu: 'link image imagetools table',
                                            //content_css: useDarkMode ? 'dark' : 'default',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'





                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ display: displayEditedPage }}>
                                    <div style={{ display: 'flex', float: 'right', gap: '5px' }}>
                                        {
                                            aboutUsDesignId === '' ?
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        style={{ height: "49px" }}
                                                        onClick={() => saveFunction()}>
                                                        save</Button>

                                                </>

                                                :
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        style={{ height: "49px" }}
                                                        onClick={() => saveFunction()}>
                                                        update
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        style={{ height: "49px" }}
                                                        onClick={() => saveNewVersionFunction()}>
                                                        Save as a New Version
                                                    </Button>
                                                    {!selectedCurrentVersion ?
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            style={{ height: "49px" }}
                                                            onClick={() => saveAsCurrentFunction()}>
                                                            Make this as the Current Version</Button>
                                                        :
                                                        ''
                                                    }
                                                    &nbsp;&nbsp;

                                                </>

                                        }

                                    </div>

                                </Grid>



                            </Grid>
                        </CardContent>
                    </Card>
                    </>
                    :
                    <>
                                <AddSubContentComponent
                                    tokens={tokens}
                                    subType={'aboutus'}
                                />
                            </>
                    }


                </CardContent>
            </Card>


            <Dialog
          open={openImage}
          //onClose={setOpenImage(false)}
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          aria-labelledby="draggable-dialog-title"

        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          </DialogTitle>
          <DialogContent>
            {parseHtml(imageHtmlData)}
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={() => handleCloseClick()}
            >
          Close
          </Button>
          </DialogActions>
        </Dialog>

        </>
    )
}

export default SuperAdminLoginAboutUsPageDesign