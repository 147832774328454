import React, { useState } from 'react';
import {
    Typography,
    Button,
    Paper,
    Grid,
    Container,
    CssBaseline,
    FormControlLabel,
    Switch as MuiSwitch,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import axios from 'axios'
import CardContent from '@mui/material/CardContent';
import { CONFIG } from '../config'
import parseHtml from 'html-react-parser';
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';

const PurchaseSuccess = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        getData()
    }, []);
    const [globalCentreId, setGlobalCentreId] = React.useState();
    const [loginUserId, setLoginUserId] = React.useState();
    const [loginUserName, setLoginUserName] = React.useState();

    function getData() {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        console.log(data);
        setGlobalCentreId(data.centreId);
        setLoginUserId(data.userId);
        setLoginUserName(data.name);
    }

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderID = queryParams.get('orderID');
    const price = queryParams.get('amount');
    const payId = queryParams.get('PAYID');
    React.useEffect(() => {
        if (orderID != undefined && orderID != null && price != undefined && price != null && payId != undefined && payId != null){
            /*
            axios(CONFIG.MAIN_URL + `/packageRequest/all`, {
                method: 'get',
                headers: {
                    'Content-Type': 'text/plain',
                },
            })
                .then(function (response) {
                    console.log("All package Returns");
                    console.log(response.data);
                    setPricingData(response.data[0].pricingMethods);
                    if (response.data[0].commonPricingFeatures != undefined && response.data[0].commonPricingFeatures != null){
                        setCommonPricingFeatuers(response.data[0].commonPricingFeatures);
                    }
                    if (response.data[0].pricingMethods != undefined && response.data[0].pricingMethods != null){
                        setUnChangedpricingData(response.data[0].pricingMethods)
                        if (response.data[0].pricingMethods.length > 0){
                            if (response.data[0].pricingMethods.length === 4){
                                setMinimumWidth(1100);
                                setCellWidth1('5%');
                                setCellWidth2('25%');
                                setCellWidth3('30%')
                            }
                            if (response.data[0].pricingMethods.length === 5){
                                setMinimumWidth(1300);
                                setCellWidth1('5%');
                                setCellWidth2('25%');
                                setCellWidth3('30%')
                            }
                            if (response.data[0].pricingMethods.length === 6){
                                setMinimumWidth(1500);
                                setCellWidth1('5%');
                                setCellWidth2('25%');
                                setCellWidth3('30%')
                            }
                            if (response.data[0].pricingMethods.length > 6){
                                setMinimumWidth(1600);
                                setCellWidth1('5%');
                                setCellWidth2('25%');
                                setCellWidth3('30%')
                            }
                        }
                    }
                    setHeaderContent(response.data[0].pricingPageHeader)
    
                })
                .catch(function (error) {
                    console.log(error);
                });
                */
        }
    }, []);




    const [PageName, setPageName] = useState('Purchase Details')

    return (
        <>
            <RightBarDataForOtherPages PageName={PageName} />
            <div className='pageTopRC'>
                <Grid container spacing={2}>
                    <Grid container spacing={2} className='pageBodyStyleRC'>

                        <Grid item xs={12}>
                            <div role="presentation" onClick={handleClick}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" to="/login">
                                        <span className='breadcrumbFonts'>Home</span>
                                    </Link>

                                    <Typography color="text.primary"><span className='breadcrumbFonts'>Main menu - {PageName}</span></Typography>
                                </Breadcrumbs>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <a className='goBack' onClick={() => navigate(-1)}><ArrowBack /> Go Back</a>
                        </Grid>
                    </Grid>
                </Grid>
                <br></br>
                <h2 style={{textAlign: 'left'}}>Successfully purchased the Risk assessment package. You will be contacted soon.</h2>
                <br></br>
                <h2>Your selected package details are:</h2>
                <p>Your Reference: {orderID}</p>
                <p>Price: ${price}</p>
                <p>Payment Reference: {payId}</p>

            </div>
        </>
    )
}

export default PurchaseSuccess
