import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './Components/Header';
import SideBar from './Components/SideBar';
import HomeComponent from "./Home";

import { Route, Routes,BrowserRouter} from 'react-router-dom';
import { Suspense } from 'react';
import { Grid, makeStyles } from "@material-ui/core";
import MainPage from './Pages/MainPage'
import QuestionnnaireMainPage from './Pages/QuestionnnaireMainPage';
import Container from '@mui/material/Container';
import Home from './Pages/Home'
import CollectionManagementPage from './Pages/CollectionManagementPage';
import QuestionerManagementPage from './Pages/QuestionerManagementPage';
import QuestionerPage from './Pages/QuestionerPage';
import User from './Pages/User';
import DataTable from './Pages/DataTable';
import CenterCollectionManagementPage from './Pages/CenterCollectionManagementPage';
import Dashboard from './Pages/Dashboard';
import SideBarUpload from './Components/SideBarUpload';
import CreateCustomStandard from './Pages/CreateCustomStandard';
import CreateGlobalStandard from './Pages/CreateGlobalStandard'
import RiskTypeOption from './Pages/RiskTypeOption';
import Tranfertomarketingcenter from './Pages/Tranfertomarketingcenter';
import AboutUs from './Pages/AboutUs';
import OurServices from './Pages/OurServices';
import RightBarDataForOtherPages from './Components/RightBarDataForOtherPages'
import TransferCollection from './Pages/TransferCollection';
import EntityChange from './Pages/EntityChange';
import ShopItems from './Pages/ShopItems';
import QuestionerManagementPageExpert from './Pages/QuestionerManagementPageExpert';
import MyCollections from './Pages/MyCollections';
import DashboardMultiUser from './Pages/DashboardMultiUser';
import CentreMaintenancePage from './Pages/CentreMaintenancePage';
import UserEmailManagementPage from './Pages/UserEmailManagementPage';
import UserQuestionManagementPage from './Pages/UserQuestionManagementPage';
import AddingColorToSliderPage from './Pages/AddingColorToSliderPage';
import CreateSliderMthodPage from './Pages/CreateSliderMthodPage';
import UserCentreEntitiesPage from './Pages/UserCentreEntitiesPage';
import UserBulkUpload from './Pages/UserBulkUpload';
import UserDetailsPage from './Pages/UserDetailsPage';
import MyCollectionsPublish from './Pages/MyCollectionsPublish';
import AddScales from './Pages/AddScales';
import AddQuestionPage from './Pages/AddQuestionPage'
import LogoCube from './Pages/cube'
import RiskCollectionPage from './Pages/RiskCollectionPage';
import EvaluateRisk from './Pages/EvaluateRisk';
import TreeT from './Pages/TreeT';
import RiskTypeOptionNew from './Pages/RiskTypeOptionNew';
import UploadJson from './Pages/UploadJson';
import CreateEditRiskRegister from './Pages/CreateEditRiskRegister';
import SendMailToAdmin from './Pages/SendMailToAdmin';
import QuestionsForGeneratingRiskRegister from './Pages/QuestionsForGeneratingRiskRegister';
import CreateJsonSchemas from './Pages/CreateJsonSchemas';
import DmgRiskGpt from './Pages/DmgRiskGptd2';
import UserRights from './Pages/UserRights';
import TrialUserList from './Pages/TrialUserList';
import PricingPage from './Pages/PricingPage3';
import Purchase from './Pages/Purchase';
import PurchaseSuccess from './Pages/PurchaseSuccess';
import DomainEndpoint from './Pages/DomainEndpoint';
import SystemSettings from './Pages/SystemSettings';
import PricingPage3ForSuperAdmin from './Pages/PricingPage3ForSuperAdmin';
import BlogPageForSuperAdmin from './Pages/BlogPageForSuperAdmin';
import BlogPageForSuperAdminMoreInfor from './Pages/BlogPageForSuperAdminMoreInfor';
import BlogPageForSuperAdminEndPoint from './Pages/BlogPageForSuperAdminEndPoint';
import DmgRiskGPTTest from './Pages/DmgRiskGPTTest';
import DmgRiskGpt3 from './Pages/DmgRiskGptd3';

function App() {

  const [cenId, setCenId] = useState()

   
  return (
    <div>
      <Suspense fallback={null}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<HomeComponent/>}>    
            </Route>
            <Route path="/*" element={
            <>    
               
            <Grid container>
            <Grid item xs={12}>
            <Container style={{maxWidth: '100%'}}>
                <Routes>
               
                <Route exact path="/home" element={<Home/>}>  </Route>
                <Route exact path="/newcollection" element={<CollectionManagementPage/>}>  </Route>
                <Route exact path="/questionerManagement/:id" element={<QuestionerManagementPage/>}>  </Route>
                <Route exact path="/questionManagementExpert/:id" element={<QuestionerManagementPageExpert/>}>  </Route>
                <Route exact path="/questioPage/:id" element={<QuestionerPage/>}>  </Route>
                <Route exact path="/user" element={<User/>}>  </Route>
                <Route exact path="/table" element={<DataTable/>}>  </Route>
                <Route exact path="/editcollection/:id" element={<CollectionManagementPage/>}>  </Route>
                <Route exact path="/centerCollection" element={<CenterCollectionManagementPage/>}>  </Route>
                <Route exact path="/transferCollection" element={<TransferCollection/>}>  </Route>
                <Route exact path="/entityChange" element={<EntityChange/>}>  </Route>
                <Route exact path="/dashboard" element={<Dashboard/>}>  </Route>
                <Route exact path="/dashboardmultiuser/:id" element={<DashboardMultiUser/>}>  </Route>

                <Route exact path="/quesmainpage" element={<QuestionnnaireMainPage/>}>  </Route>
                <Route exact path="/mycollections" element={<MyCollections/>}>  </Route>
                <Route exact path="/publishcollections" element={<MyCollectionsPublish/>}>  </Route>
                <Route exact path="/customStandard" element={<CreateCustomStandard/>}>  </Route>
                <Route exact path="/globalStandard" element={<CreateGlobalStandard/>}>  </Route>
                <Route exact path="/createRisk" element={<RiskTypeOption/>}>  </Route>
                <Route exact path="/tranfertomarketingcenter" element={<Tranfertomarketingcenter/>}>  </Route>
                <Route exact path="/centreMaintenance" element={<CentreMaintenancePage/>}>  </Route>
                <Route exact path="/userEmailManagement/:id" element={<UserEmailManagementPage/>}>  </Route>
                <Route exact path="/centreEntities" element={<UserCentreEntitiesPage/>}>  </Route>
                <Route exact path="/bulkUpload" element={<UserBulkUpload/>}>  </Route>
                
                <Route exact path="/addColor" element={<AddingColorToSliderPage/>}>  </Route>
                <Route exact path="/sliderMethods" element={<CreateSliderMthodPage/>}>  </Route>
                <Route exact path="/allUsers" element={<UserDetailsPage/>}>  </Route>
                <Route exact path="/answerScale" element={<AddScales/>}>  </Route>
                <Route exact path="/createQuestion" element={<AddQuestionPage/>}>  </Route>
                <Route exact path="/riskCollection" element={<RiskCollectionPage/>}>  </Route>
                <Route exact path="/evaluateRisk" element={<EvaluateRisk/>}>  </Route>
                <Route exact path="/createRiskNew" element={<RiskTypeOptionNew/>}>  </Route>
                <Route exact path="/jsonUpload" element={<UploadJson/>}>  </Route>
                <Route exact path="/riskRegister" element={<CreateEditRiskRegister/>}>  </Route>
                <Route exact path="/registerProposal" element={<SendMailToAdmin/>}>  </Route>
                {/*<Route exact path="/questionsforgeneratingriskregister" element={<QuestionsForGeneratingRiskRegister/>}>  </Route>*/}
                <Route exact path="/createjsonschemas" element={<CreateJsonSchemas/>}>  </Route>
                <Route exact path="/questionsforgeneratingriskregister" element={<DmgRiskGpt/>}>  </Route>
                <Route exact path="/researchforgeneratingriskregister" element={<DmgRiskGpt3/>}>  </Route>
                <Route exact path="/userRights" element={<UserRights/>}>  </Route>
                <Route exact path="/trialUsers" element={<TrialUserList/>}>  </Route>
                <Route exact path="/pricing" element={<PricingPage/>}>  </Route>
                <Route exact path="/purchase/" element={<Purchase/>}></Route>
                <Route exact path="/paymentReturn/" element={<PurchaseSuccess/>}></Route>
                <Route exact path="/systemSettings/" element={<SystemSettings/>}></Route>

                <Route exact path="/riskgpttest" element={<DmgRiskGPTTest/>}>  </Route>

     
                
                </Routes>
              </Container>
            </Grid>
            
          </Grid>
            </> 
            }
          ></Route>

          <Route>
          <Route exact path="/login" element={<HomeComponent/>}> </Route>
          <Route exact path="/aboutus" element={<AboutUs/>}> </Route>
          <Route exact path="/domainendpoint" element={<DomainEndpoint/>}> </Route>
          <Route exact path="/ourservices" element={<OurServices/>}> </Route>
          
          <Route exact path="/pricingsp" element={<PricingPage3ForSuperAdmin/>}>  </Route>
          <Route exact path="/shopitems" element={<ShopItems/>}> </Route>
          <Route exact path="/questionmanagement/:id" element={<UserQuestionManagementPage/>}> </Route>
          <Route exact path="/cube" element={<LogoCube/>}> </Route>
          <Route exact path="/voicetest" element={<TreeT/>}> </Route>
          <Route exact path="/blog" element={<BlogPageForSuperAdmin/>}> </Route>
          <Route exact path="/blogmoreinfor" element={<BlogPageForSuperAdminMoreInfor/>}> </Route>
          <Route exact path="/blogpageendpoint" element={<BlogPageForSuperAdminEndPoint/>}> </Route>

          
          </Route>

          
          </Routes>
        </BrowserRouter>
              
      </Suspense>
    </div>
  );
}

export default App;
