import React, { useEffect, useState } from 'react'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";

import useStyle from '../Css/MainCss';
import { ArrowBack, Delete, Details, DetailsSharp, DisplaySettings, Search, TurnedInOutlined } from '@mui/icons-material';
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info } from '@mui/icons-material';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { CONFIG } from '../config'

import { Close } from '@mui/icons-material';
import RiskHierarchyNew from './RiskHierarchyNew';
import Draggable1 from 'react-draggable';
import { event } from 'jquery';
import { CircularProgress } from "@material-ui/core";
import EvaluateRisk from '../Pages/EvaluateRisk';
import Tooltip1 from '@mui/material/Tooltip';
import { FullscreenRounded } from '@mui/icons-material';
import { BarChart, Bar, XAxis, YAxis, Cell, Tooltip, LabelList, CartesianGrid, Legend } from 'recharts';
import TransferToExcel from '../Components/TransferToExcel';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EvaluateComponent from './EvaluateComponent';
import EvaluateComponent1 from './EvaluateComponent1';
import FinaliseRegisterComponent from './FinaliseRegisterComponent';
import  "../Css/RiskRegisterComponent.css";
import SavePageAs from './SavePageAs'


function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const RiskRegisterComponent = ({selectedRegisteredId}) => {
  const classes = useStyle();


  const [PageName, setPageName] = React.useState('Risk Register');

  const [allRegister, setAllRegister] = useState([])

  const navigate = useNavigate();

  const [globalCentreId, setGlobalCentreId] = useState('')
  const [globalUserId, setGlobalUserId] = React.useState('');
  const [globalUserRoleId, setGlobalUserRoleId] = React.useState('');

  const [waitingOpen, setWaitingOpen] = React.useState(false);

  /** Sort abject array */
  function compare( a, b ) {
    if ( a.orderNo < b.orderNo ){
      return -1;
    }
    if ( a.orderNo > b.orderNo ){
      return 1;
    }
    return 0;
  }
  

  const [fromPageSelectedRegisteredId, setFromPageSelectedRegisteredId] = useState('')

  useEffect(() => {
    getData();
    if (selectedRegisteredId != undefined && selectedRegisteredId != null && selectedRegisteredId != ''){
      setFromPageSelectedRegisteredId(selectedRegisteredId);
    }

    getColors()

  }, [])

  function getData() {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    getAllRegister(data.centreId)
    setGlobalCentreId(data.centreId)
    setGlobalUserId(data.userId);
    setGlobalUserRoleId(data.userRoleId);
    getEntityData(data.centreId)
    getGlobalRiskForCenter(data.centreId, 1, '')

  }

  const [registerId, setRegisterId] = useState('')
  const [registerUniqName, setRegisterUniqName] = useState('')
  const [registerCreatedUser, setRegisterCreatedUser] = useState('')
  const [registerName, setRegisterName] = useState('')
  const [registerDescription, setRegisterDescription] = useState('')


  const registerNameOnChange = (event) => {
    setRegisterName(event.target.value)
  }

  const registerDescriptionOnChange = (event) => {
    setRegisterDescription(event.target.value)
  }


  function getAllRegister(centrId) {
    axios(CONFIG.MAIN_URL + `/register/all`, {
      method: 'get',
    })
      .then(function (response) {
        var arr = []
        console.log("all register");
        console.log(response.data);
        response.data.forEach((item) => {
          if (item.centreId === centrId)
            arr.push(item)
          console.log(arr)

        })
        setAllRegister(arr);

        if (selectedRegisteredId != undefined && selectedRegisteredId != null && selectedRegisteredId != ''){
          arr.forEach((data) => {
            if (data.id === selectedRegisteredId) {
              var countSection = 1;
              var arrSection = [];
              data.riskRegister.forEach((item) => {
                item["sectionTmpId"] = countSection;
                countSection ++;
                var arrRisk = [];
                item.risks.forEach((item1) => {
                  item1["hierarchyTmpId"] = countSection;
                  countSection ++;
                  arrRisk.push(item1);
                })
                item["risks"]=arrRisk
                arrSection.push(item);   
              })
              setSectionTmpId(countSection);
              setCategoryData(arrSection);
              console.log(data);
              console.log(arrSection);
              console.log(data.riskRegister);
      
      
              //data.riskRegister.forEach((data1) => {
              //  setHierarchyData(data1.risks)
              //})
      
              // setHierarchyData([])
              setRegisterDescription(data.description)
              setRegisterName(data.name)
              setRegisterId(data.id)
              setRegisterUniqName(data.registerUniqueName);
              setRegisterCreatedUser(data.createdUserId);
              setRegisterSaveBtn("UPDATE REGISTER")
            }
      
          })

       
        }


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [globalRiskArray, setGlobalRiskArray] = useState([]);

  function getGlobalRiskForCenter(centerId,type,updatedRiskHierarchyId) {
    setWaitingOpen(true);
    axios(CONFIG.MAIN_URL + `/risk/all?centreId=` + centerId, {
      method: 'get',
    })
      .then(function (response) {
        console.log("Risk All Return Response");
        console.log(response.data);
        setGlobalRiskArray(response.data);
        setWaitingOpen(false);
        if (type === 2 && updatedRiskHierarchyId != undefined && updatedRiskHierarchyId != null && updatedRiskHierarchyId != ''){
          response.data.forEach((item) => {
            if (item.riskId === updatedRiskHierarchyId){
              console.log(item)
              var obj = {}
              obj["riskId"] = item.riskId;
              if (item.key != undefined && item.key != null){
                obj["key"] = item.key;
              }else{
                obj["key"] = '';
              }
              
              obj["name"] = item.name;
              obj["likelihood"] = item.riskLikelihood;
              obj["impact"] = item.riskImpact;
              obj["riskAppetite"] = item.riskAppetite;
              setSelectedOption(item);
              setHierarchyValue(obj)

              setRiskId(item.riskId)
              if (item.key != undefined && item.key != null){
                setRiskKey(item.key)
              }
              setSelectedRiskHierarchy(item.name)
              setIntLikelihoodValueDialog(item.riskLikelihood)
              setIntImpactValesDialog(item.riskImpact)
              setIntRiskAppetiteValueDialog(item.riskAppetite)

            }

          })    
        }
      })
      .catch(function (error) {
        console.log(error);
        setWaitingOpen(false);
      });
  }

  const registerTableRowData = (
    <TableBody>
      {allRegister.map((row, index) => {
        // if (row.isDelete === null || !row.isDelete ) {
        return (
          <StyledTableRow key={index} className={classes.tableTrStyle}>
            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
              <div style={{ whiteSpace: 'normal' }}>{row.name}</div>
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={classes.tableTrStyle}
            >
              {row.status.search("Finalise") > -1?
              <DetailsSharp
              className={classes.mousePointer}
              onClick={() => { showRegisterArrary(row.id) }}
            />
            :
            <EditIcon
                className={classes.mousePointer}
                onClick={() => { editRegisterArrary(row.id) }}
              />
              }
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={classes.tableTrStyle}
            >
              {row.status.search("Finalise") > -1?
              <Delete
              className={classes.mousePointer}
              onClick={() => { deleteRegister(row.id, row.registerUniqueName,row.name) }}
            />
            :
            ''
            }
            </StyledTableCell>
          </StyledTableRow>
        )
        //  }
      })}
    </TableBody>
  )

  const [sectionTmpId, setSectionTmpId] = useState(0)
  //const [hierarchyTmpId, setHierarchyTmpId] = useState(0)

  function editRegisterArrary(id) {
    console.log(id)
    console.log(allRegister)
    allRegister.forEach((data) => {
      if (data.id === id) {
        var countSection = 1;
        var arrSection = [];
        if (data.riskRegister != undefined && data.riskRegister != null){
          data.riskRegister.forEach((item) => {
            item["sectionTmpId"] = countSection;
            countSection ++;
            var arrRisk = [];
            item.risks.forEach((item1) => {
              item1["hierarchyTmpId"] = countSection;
              countSection ++;
              arrRisk.push(item1);
            })
            item["risks"]=arrRisk
            arrSection.push(item);   
          })
        }
        setSectionTmpId(countSection);
        setCategoryData(arrSection);
        console.log(arrSection);
        console.log(data.riskRegister);


        //data.riskRegister.forEach((data1) => {
        //  setHierarchyData(data1.risks)
        //})

        // setHierarchyData([])
        setRegisterDescription(data.description)
        setRegisterName(data.name)
        setRegisterId(data.id)
        setRegisterUniqName(data.registerUniqueName);
        setRegisterCreatedUser(data.createdUserId);
        setRegisterSaveBtn("UPDATE REGISTER")
        setShowRegister(false);
      }
    })
  }

  function deleteRegister(id, uniqueName,deletedRegisterName){
    var obj = {};
    obj["id"] = id;
    obj["registerUniqueName"] = uniqueName;


    var answer = window.confirm("You are going to delete the finalised register \"" + deletedRegisterName + "\". \n\n Press 'OK' to confirm");
    if (answer) {
      let sendJson = JSON.stringify(obj);
      setWaitingOpen(true);  
      axios(CONFIG.MAIN_URL+'/register/delete',{
        method: 'post',
        headers: { 
            'Content-Type': 'application/json',
          },
        data : sendJson,  
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        let rtnData = response.data;
        setWaitingOpen(false);
        alert("Register Deleted successfully.");
        getAllRegister(globalCentreId);
      })
      .catch(function (error) {
        setWaitingOpen(false);
        console.log(error);
        alert("Error Deleting Register-:" + error);
      });
    }

  }

  const [showRegister, setShowRegister] = useState(false);
  const [registerStatus, setRegisterStatus] = useState('');
  const [registerDetailId, setRegisterDetailId] = useState('');

  function showRegisterArrary(id){
    setRegisterDetailId(id)
    allRegister.forEach((data) => {
      if (data.id === id) {
        var countSection = 1;
        var arrSection = [];
        data.riskRegister.forEach((item) => {
          item["sectionTmpId"] = countSection;
          countSection ++;
          var arrRisk = [];
          item.risks.forEach((item1) => {
            item1["hierarchyTmpId"] = countSection;
            countSection ++;
            arrRisk.push(item1);
          })
          item["risks"]=arrRisk
          arrSection.push(item);   
        })
        setSectionTmpId(countSection);
        setCategoryData(arrSection);
        setRegisterDescription(data.description)
        setRegisterName(data.name)
        setRegisterId(data.id)
        setRegisterStatus(data.status);
        setRegisterUniqName(data.registerUniqueName);
        setRegisterCreatedUser(data.createdUserId);
        setRegisterSaveBtn("UPDATE REGISTER")
        setShowRegister(true);
      }
    })

  }

  const [categoryId, setCategoryId] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [categoryDescription, setCategoryDescription] = useState('')
  const [categoryData, setCategoryData] = useState([])
  const [hierarchyData, setHierarchyData] = useState([])

  const [entityName, setEntityName] = useState('')

  const categoryNameOnChange = (event) => {
    setCategoryName(event.target.value)
  }

  const categoryDescriptionOnChange = (event) => {
    setCategoryDescription(event.target.value)
  }

  const entityNameOnChange = (event) => {
    setEntityName(event.target.value)
    setCategoryDescription(event.target.value)
  }

  

  function addToCategoryTable() {
    if (categoryName === '') {
      alert("Please Enter Section Name")
      return;
    }
    var catData = {}
    if (editSectionTmpId === 0) {

      var countId = sectionTmpId;
      catData["sectionTmpId"] = (countId + 1);  
      setSectionTmpId(countId + 1);

      catData["section"] = categoryName;
      catData["orderNo"] = 0;
      catData["role"] = categoryDescription;
      catData["likelihood"] = parseInt(intLikelihoodValue);
      catData["impact"] = parseInt(intImpactVales);
      catData["riskLevel"] = parseInt(intLikelihoodValue) * parseInt(intImpactVales);
      catData["riskAppetite"] = parseInt(intRiskAppetiteValue);
      catData["risks"] = [];
      categoryData.push(catData)
      console.log(categoryData)
      
      setCategoryName('')
      
      setEditSectionTmpId(0);

      setCategoryDescription('')
      setEntityName('')
      setIntLikelihoodValue('')
      setIntImpactVales('')
      setIntRiskAppetiteValue('')
      setCategoryId('')
    }else{

      var pos = 0;
      categoryData.forEach((data)=>{
        if(data.orderNo > pos){
          pos = data.orderNo;
        }
      })
      pos ++;
      //alert("2222 : " + pos);

      categoryData.forEach((data)=>{
        if (data.sectionTmpId === editSectionTmpId) {
          data["id"] = categoryId;
          data["section"] = categoryName;
          data["role"] = categoryDescription;
          data["likelihood"] = parseInt(intLikelihoodValue);
          data["impact"] = parseInt(intImpactVales);
          data["riskLevel"] = parseInt(intLikelihoodValue) * parseInt(intImpactVales);
          data["riskAppetite"] = parseInt(intRiskAppetiteValue);
        }
      })
      setCategoryName('')
      setEditSectionTmpId(0);
      setCategoryDescription('')
      setEntityName('')
      setIntLikelihoodValue('')
      setIntImpactVales('')
      setIntRiskAppetiteValue('')
      setCategoryId('')
      setCategoryButton('add')
    }

    var pos = 0;
    categoryData.forEach((data)=>{
      if(data.orderNo > pos){
        pos = data.orderNo;
      }
    })
    pos ++;

    categoryData.forEach((data)=>{
      if(data.orderNo === 0){
        data["orderNo"]= pos;
        pos ++;
      }
    })



    categoryData.sort( compare );

    if (registerId != ''){
      let obj = {};
      obj["id"]= registerId;
      obj["name"]= registerName;
      obj["description"]= registerDescription;
      obj["centreId"]= globalCentreId;
      obj["status"]= 'Draft';
      obj["registerUniqueName"]= registerUniqName;
      obj["documentCreator"]= "expert";
      obj["riskRegister"] = categoryData;
      obj["createdUserId"] = registerCreatedUser;

      console.log(obj);
      console.log(JSON.stringify(obj));
      let sendJson = JSON.stringify(obj);
      setWaitingOpen(true);  
      axios(CONFIG.MAIN_URL+'/register/save',{
        method: 'post',
        headers: { 
            'Content-Type': 'application/json',
          },
        data : sendJson,  
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        let rtnData = response.data;
        setWaitingOpen(false);
        alert("Register Update successfully.");
        getAllRegister(globalCentreId);
      })
      .catch(function (error) {
        setWaitingOpen(false);
        console.log(error);
        alert("Error saving Register-:" + error);
      });
    }


  }

  function calculatedRiskLevel() {
    var x = '';
    if (!isNaN(parseFloat(intImpactVales)) && !isNaN(parseFloat(intLikelihoodValue))) {
      x = parseInt(intImpactVales) * parseInt(intLikelihoodValue)
      //setIntRiskTypeValue(x);
    }
    return x;
  }

  const options = ['1', '2', '3', '4', '5'];
  const [intImpactVales, setIntImpactVales] = useState('');
  const options1 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  const [intRiskAppetiteValue, setIntRiskAppetiteValue] = useState('');
  const [intLikelihoodValue, setIntLikelihoodValue] = useState('');
  const [intImpactValesForEachRisk, setIntImpactValesForEachRisk] = useState('');
  const [intLikelihoodValueForEachRisk, setIntLikelihoodValueForEachRisk] = useState('');



  function PaperComponentCreateRisk(props) {
    return (
      <Draggable1
        handle="#create-risk-data-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable1>
    );
  }
  const [openCreateRisk, setOpenCreateRisk] = React.useState(false);
  const [openCreateRiskScroll, setOpenCreateRiskScroll] = React.useState('paper');
  const [editSectionTmpId, setEditSectionTmpId] = React.useState(0);
  
  
  function handleClickOpenRisk(data){
    clearHierarcyEditStates()
    
    setHierarchyData(data.risks);
    setSelectedRiskEvaluateTitle(data.section)
    setEditSectionTmpId(data.sectionTmpId);
    setOpenCreateRisk(true);
    setOpenCreateRiskScroll('paper');
  }

  const handleCloseRisk = () => {
    setOpenCreateRisk(false);
  };

  const categoryTableRowData = (

    <TableBody>
      {categoryData.map((row, index) => {
        //  if (row.isDelete === null || !row.isDelete ) {
        return (
          <StyledTableRow key={index} className='tableTrStyle'>
            <StyledTableCell component="th" scope="row" className='tableTrStyle' >
              <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{row.section}</div>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className='tableTrStyle' >
              <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{row.role}</div>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className='tableTrStyle' >
              <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{row.riskLevel}</div>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className='tableTrStyle' >
              <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{row.riskAppetite}</div>
            </StyledTableCell>
            
            <StyledTableCell component="th" scope="row" className='tableTrStyle' >
              <Button 
                variant='outlined' 
                className='hierarchBtn'
                onClick={() => { handleClickOpenRisk(row) }}
                >
                Risk Hierarchy</Button>
            </StyledTableCell>
            {!showRegister?
            <>
            <StyledTableCell
              align="right"
              className='tableTrStyle'
            >
              <EditIcon
                className={classes.mousePointer}
                onClick={() => { editSectionArrary(row) }}
              />
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className='tableTrStyle'
            >
              <DeleteIcon
                className={classes.mousePointer}
                onClick ={ ()=>{deleteSectionArrary(row.sectionTmpId)}}
              />
            </StyledTableCell>
            </>
            :
            ''
            }
          </StyledTableRow>
        )
        //   }
      })}
    </TableBody>
  )

  /** Change the Section Postion */
  function PaperComponentSectionDialog(props) {
    return (
        <Draggable1
            handle="#section-data-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable1>
    );
  }
  
  const [openSectionPosition, setOpenSectionPosition] = React.useState(false);
  const [openSectionPositionScroll, setOpenSectionPositionScroll] = React.useState('paper');
  

  const [categoryDataTmp, setCategoryDataTmp] = React.useState([]);
  function changeSectionPosition(){
    var pos = 0
    categoryData.forEach((data) => {
      if (data.orderNo > pos){
        pos = (data.orderNo + 1)
      }
    })

    categoryData.forEach((data) => {
      if (data.orderNo === 0){
        if (pos === 0){
          pos = pos + 1;  
        }
        data["orderNo"] = pos;
        pos = pos + 1;
      }
    })

    
    var arr = [];
    categoryData.forEach((data) => {
      arr.push(data);
    })
    setCategoryDataTmp(arr);
   
    setOpenSectionPosition(true);
    setOpenSectionPositionScroll('paper');
  }

  function confirmSectionNewPosAndEvaluateFunc(){

    var arr = [];
    var pos = 1
    categoryDataTmp.forEach((data) => {
      data["orderNo"] = pos;
      arr.push(data);
      pos ++;
    })

    if (registerId != ''){
      setCategoryData(arr);
      let obj = {};
      obj["id"]= registerId;
      obj["name"]= registerName;
      obj["description"]= registerDescription;
      obj["centreId"]= globalCentreId;
      obj["status"]= 'Draft';
      obj["registerUniqueName"]= registerUniqName;
      obj["documentCreator"]= "expert";
      obj["riskRegister"] = arr;
      obj["createdUserId"] = registerCreatedUser;

      console.log(obj);
      console.log(JSON.stringify(obj));
      let sendJson = JSON.stringify(obj);
      setWaitingOpen(true);  
      axios(CONFIG.MAIN_URL+'/register/save',{
        method: 'post',
        headers: { 
            'Content-Type': 'application/json',
          },
        data : sendJson,  
      })
      .then(function (response) {
        console.log(response.data);
        let rtnData = response.data;

        setWaitingOpen(false);
        //alert("Update Section Position successfully.");
        getAllRegister(globalCentreId);
        setOpenSectionPosition(false);
        var tmpIdCount = 1;
        var tmpIdCount1 = 1;
        var jsonObj = createJsonObject(rtnData);
        setSelectedRegisterEvaluateTitle(rtnData.name)
        jsonObj.riskTypes.forEach((item1, index) => {
            item1["riskTypeTmpId"] = tmpIdCount;
            tmpIdCount++
            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                tmpIdCount = assignExtraAttributes(item1.subRisks, tmpIdCount);
            }
            tmpIdCount++;
        })
        setRegisterTypeDataForEval(jsonObj);
        setOpenRegisterDataForEval(true)
        setOpenRegisterDataForEvalScroll('paper');

        

      })
      .catch(function (error) {
        setWaitingOpen(false);
        console.log(error);
        alert("Error saving Register-:" + error);
      });
    }else{
      alert("Please save the changes before evaluate.");
      setCategoryData(arr);
      setOpenSectionPosition(false);
    }

    




  }

  function confirmSectionNewPosFunc(){
    var arr = [];
    var pos = 1
    categoryDataTmp.forEach((data) => {
      data["orderNo"] = pos;
      arr.push(data);
      pos ++;
    })

    if (registerId != ''){
      setCategoryData(arr);
      let obj = {};
      obj["id"]= registerId;
      obj["name"]= registerName;
      obj["description"]= registerDescription;
      obj["centreId"]= globalCentreId;
      obj["status"]= 'Draft';
      obj["registerUniqueName"]= registerUniqName;
      obj["documentCreator"]= "expert";
      obj["riskRegister"] = arr;
      obj["createdUserId"] = registerCreatedUser;

      console.log(obj);
      console.log(JSON.stringify(obj));
      let sendJson = JSON.stringify(obj);
      setWaitingOpen(true);  
      axios(CONFIG.MAIN_URL+'/register/save',{
        method: 'post',
        headers: { 
            'Content-Type': 'application/json',
          },
        data : sendJson,  
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        let rtnData = response.data;
        setWaitingOpen(false);
        alert("Update Section Position successfully.");
        getAllRegister(globalCentreId);
        setOpenSectionPosition(false);

      })
      .catch(function (error) {
        setWaitingOpen(false);
        console.log(error);
        alert("Error saving Register-:" + error);
      });
    }else{
      setCategoryData(arr);
      setOpenSectionPosition(false);
    }








  }

  const onDragEndSection = (result) => {
    if (!result.destination) {
      return;
    }
    const newRows = [...categoryDataTmp];
    const [removed] = newRows.splice(result.source.index, 1);
    newRows.splice(result.destination.index, 0, removed);
    setCategoryDataTmp(newRows);
  };
  


  const sectionTableRowData = (
    <DragDropContext onDragEnd={onDragEndSection}>
      <Droppable droppableId="table">
        {(provided) => (
          <table style={{width: '100%'}}  ref={provided.innerRef}>
            <thead>
              <tr>
                <th style={{width:'50%', fontSize:'11px'}}>Section Name</th>
                <th style={{width:'15%', fontSize:'11px',textAlign: 'right'}}>Risk Level</th>
                <th style={{width:'15%', fontSize:'11px',textAlign: 'right'}}>Appetite</th>
                <th style={{width:'20%', fontSize:'11px',textAlign: 'right'}}>Order No </th>
              </tr>
            </thead>
            <tbody {...provided.droppableProps}>
              {categoryDataTmp.map((row, index) => {
                //if (row.isDelete === null || !row.isDelete ) {
                return (
                  <Draggable key={row.orderNo} draggableId={`${row.orderNo}`} index={index}>
                    {(provided) => (
                      <tr
                        className={index % 2 === 0?classes.trBackColor1:classes.trBackColor2}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        //{...provided.dragHandleProps}
                        //{...provided.dragHandleProps}
                        >
                        <td style={{fontSize:'10px'}}>{row.section}</td>
                        <td style={{textAlign: 'right', fontSize:'10px'}}>{row.riskLevel}</td>
                        <td style={{textAlign: 'right', fontSize:'10px'}}>{row.riskAppetite}</td>
                        {/* <td style={{height:'40px'}}>{index}</td> */}
                        <td style={{textAlign: 'right', fontSize:'10px'}} >{row.orderNo}</td>
                      </tr>
                    )}
                  </Draggable>
                )
                //}
              })}
              {provided.placeholder}
            </tbody>
          </table>
        )}
      </Droppable>
    </DragDropContext>
    
    )
    

/** Change the Risk Postion */
function PaperComponentRiskDialog(props) {
  return (
      <Draggable1
          handle="#risk-data-dialog-title"
          cancel={'[class*="MuiDialogContent-root"]'}
      >
          <Paper {...props} />
      </Draggable1>
  );
}

const [openRiskPosition, setOpenRiskPosition] = React.useState(false);
const [openRiskPositionScroll, setOpenRiskPositionScroll] = React.useState('paper');


const [hierarchyDataTmp, setHierarchyDataTmp] = React.useState([]);

function changeRiskPosition(){
  var pos = 0
  hierarchyData.forEach((data) => {
    if (data.orderNo > pos){
      pos = (data.orderNo + 1)
    }
  })

  hierarchyData.forEach((data) => {
    if (data.orderNo === 0){
      if (pos === 0){
        pos = pos + 1;  
      }
      data["orderNo"] = pos;
      pos = pos + 1;
    }
  })

  
  var arr = [];
  hierarchyData.forEach((data) => {
    arr.push(data);
  })
  setHierarchyDataTmp(arr);
 
  setOpenRiskPosition(true);
  setOpenRiskPositionScroll('paper');
}

function confirmRiskNewPosAndEvaluateFunc(){
  var arr = [];
  var pos = 1
  hierarchyDataTmp.forEach((data) => {
    data["orderNo"] = pos;
    arr.push(data);
    pos ++;
  })
  setHierarchyData(arr);
  setTimeout(() => {
    if (registerId != ''){
      categoryData.forEach((data) => {
        if (data.sectionTmpId === editSectionTmpId){
          data["risks"] = arr;
        }
      })

      let obj = {};
      obj["id"]= registerId;
      obj["name"]= registerName;
      obj["description"]= registerDescription;
      obj["centreId"]= globalCentreId;
      obj["status"]= 'Draft';
      obj["registerUniqueName"]= registerUniqName;
      obj["documentCreator"]= "expert";
      obj["riskRegister"] = categoryData;
      obj["createdUserId"] = registerCreatedUser;
  
      console.log(obj);
      console.log(JSON.stringify(obj));
      let sendJson = JSON.stringify(obj);
      setWaitingOpen(true);  
      axios(CONFIG.MAIN_URL+'/register/save',{
        method: 'post',
        headers: { 
            'Content-Type': 'application/json',
          },
        data : sendJson,  
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        let rtnData = response.data;
        setWaitingOpen(false);
        //alert("Update Risk Hierarchies Position successfully.");
        getAllRegister(globalCentreId);
        setOpenRiskPosition(false);
        var tmpIdCount = 1;
        var tmpIdCount1 = 1;
        var jsonObj = createJsonObject(rtnData);
        setSelectedRegisterEvaluateTitle(rtnData.name)
        jsonObj.riskTypes.forEach((item1, index) => {
            item1["riskTypeTmpId"] = tmpIdCount;
            tmpIdCount++
            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                tmpIdCount = assignExtraAttributes(item1.subRisks, tmpIdCount);
            }
            tmpIdCount++;
        })
        setRegisterTypeDataForEval(jsonObj);
        setOpenRegisterDataForEval(true)
        setOpenRegisterDataForEvalScroll('paper');
  
      })
      .catch(function (error) {
        setWaitingOpen(false);
        console.log(error);
        alert("Error saving Register-:" + error);
      });
    }else{
      alert("Please save the changes before evaluate.");
      setOpenRiskPosition(false);
    }
  }, 500);
}

function confirmRiskNewPosFunc(){
  var arr = [];
  var pos = 1
  hierarchyDataTmp.forEach((data) => {
    data["orderNo"] = pos;
    arr.push(data);
    pos ++;
  })
  setHierarchyData(arr);
  setTimeout(() => {
    if (registerId != ''){
      categoryData.forEach((data) => {
        if (data.sectionTmpId === editSectionTmpId){
          data["risks"] = arr;
        }
      })

      let obj = {};
      obj["id"]= registerId;
      obj["name"]= registerName;
      obj["description"]= registerDescription;
      obj["centreId"]= globalCentreId;
      obj["status"]= 'Draft';
      obj["registerUniqueName"]= registerUniqName;
      obj["documentCreator"]= "expert";
      obj["riskRegister"] = categoryData;
      obj["createdUserId"] = registerCreatedUser;
  
      console.log(obj);
      console.log(JSON.stringify(obj));
      let sendJson = JSON.stringify(obj);
      setWaitingOpen(true);  
      axios(CONFIG.MAIN_URL+'/register/save',{
        method: 'post',
        headers: { 
            'Content-Type': 'application/json',
          },
        data : sendJson,  
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        let rtnData = response.data;
        setWaitingOpen(false);
        alert("Update Risk Hierarchies Position successfully.");
        getAllRegister(globalCentreId);
        setOpenRiskPosition(false);
  
      })
      .catch(function (error) {
        setWaitingOpen(false);
        console.log(error);
        alert("Error saving Register-:" + error);
      });
    }else{
      setOpenRiskPosition(false);
    }
  }, 500);
  
}

const onDragEndRisk = (result) => {
  if (!result.destination) {
    return;
  }
  const newRows = [...hierarchyDataTmp];
  const [removed] = newRows.splice(result.source.index, 1);
  newRows.splice(result.destination.index, 0, removed);
  setHierarchyDataTmp(newRows);
};



const riskTableRowData = (
  <DragDropContext onDragEnd={onDragEndRisk}>
    <Droppable droppableId="table">
      {(provided) => (
        <table style={{width: '100%'}}  ref={provided.innerRef}>
          <thead>
            <tr>
            <th style={{width:'15%', fontSize:'10px'}}>User Key</th>
              <th style={{width:'45%', fontSize:'10px'}}>Risk Heirarchy Name</th>
              <th style={{width:'13%', fontSize:'10px',textAlign: 'right'}}>Risk Level</th>
              <th style={{width:'12%', fontSize:'10px',textAlign: 'right'}}>Appetite</th>
              <th style={{width:'15%', fontSize:'10px',textAlign: 'right'}}>Order No </th>
            </tr>
          </thead>
          <tbody {...provided.droppableProps}>
            {hierarchyDataTmp.map((row, index) => {
              //if (row.isDelete === null || !row.isDelete ) {
              return (
                <Draggable key={row.orderNo} draggableId={`${row.orderNo}`} index={index}>
                  {(provided) => (
                    <tr
                      className={index % 2 === 0?classes.trBackColor1:classes.trBackColor2}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      //{...provided.dragHandleProps}
                      //{...provided.dragHandleProps}
                      >
                      <td style={{fontSize:'10px'}}>{row.riskHierarchy.key}</td>
                      <td style={{fontSize:'10px'}}>{row.name}</td>
                      <td style={{textAlign: 'right',fontSize:'10px'}}>{row.riskLevel}</td>
                      <td style={{textAlign: 'right',fontSize:'10px'}}>{row.riskAppetite}</td>
                      {/* <td style={{height:'40px'}}>{index}</td> */}
                      <td style={{textAlign: 'right',fontSize:'10px'}} >{row.orderNo}</td>
                    </tr>
                  )}
                </Draggable>
              )
              //}
            })}
            {provided.placeholder}
          </tbody>
        </table>
      )}
    </Droppable>
  </DragDropContext>
  
  )
  












  function editSectionArrary(item) {
    var tmpId = item.sectionTmpId
    categoryData.forEach((data) => {
      if (data.sectionTmpId === tmpId) {
        setEditSectionTmpId(tmpId)
        setCategoryId(data.id)
        setCategoryName(data.section)
        setCategoryDescription(data.role)
        setIntLikelihoodValue(data.likelihood)
        setIntImpactVales(data.impact)
        setIntRiskAppetiteValue(data.riskAppetite)
        setCategoryButton('update')
      }
    })
  }

  function deleteSectionArrary(tmpId){
    var arr = []
    categoryData.forEach((data, index) => {
      if (data.sectionTmpId === tmpId) {
        var name = data.section
        alert("Deleted Section - " + name)
      }else{
        arr.push(data);
      }
    })
    setCategoryData(arr)
    if (registerId != ''){
      let obj = {};
      obj["id"]= registerId;
      obj["name"]= registerName;
      obj["description"]= registerDescription;
      obj["centreId"]= globalCentreId;
      obj["status"]= 'Draft';
      obj["registerUniqueName"]= registerUniqName;
      obj["documentCreator"]= "expert";
      obj["riskRegister"] = arr;
      obj["createdUserId"] = registerCreatedUser;
      let sendJson = JSON.stringify(obj);
      setWaitingOpen(true);
      axios(CONFIG.MAIN_URL+'/register/save',{
        method: 'post',
        headers: { 
            'Content-Type': 'application/json',
          },
        data : sendJson,  
      })
      .then(function (response) {
        setWaitingOpen(false);
        getAllRegister(globalCentreId)
      })
      .catch(function (error) {
        setWaitingOpen(false);
        console.log(error);
        alert("Error Deleting Section-:" + error);
      });
    }


    

   }

  const [showHierarchyId, setShowHierarchyId] = React.useState('');
  const [showHierarchyName, setShowHierarchyName] = React.useState('');
  
  const [openShowHierarchy, setOpenShowHierarchy] = React.useState(false);
  const [openShowHierarchyScroll, setOpenShowHierarchyScroll] = React.useState('paper');

  function closeShowHierarchyFunction() {
    setOpenShowHierarchy(false);
  }

  function showHierarchy(data){
    console.log(data)
    var canShow = false;
    if (data.riskHierarchy != null ){
      if (data.riskHierarchy.riskId != null && data.riskHierarchy.riskId != ''){
        canShow = true;
      }
    }
    if (canShow){
      var riskId = parseInt(data.riskHierarchy.riskId);
      setShowHierarchyId(riskId);
      setShowHierarchyName(data.riskHierarchy.name);
      setOpenShowHierarchy(true);
      setOpenShowHierarchyScroll('paper');
    }else{
      alert("You haven't set the Risk Hierarchy for \"" + data.name + "\". Please edit to set the Risk Hierarchy")
    }
  }

  
  /** Start Show only Hierarchy */

  function PaperComponentCollectionData(props) {
    return (
        <Draggable1
            handle="#collection-data-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable1>
    );
  }

  const [openRiskDataForEval, setOpenRiskDataForEval] = React.useState(false);
  const [openRiskDataForEvalScroll, setOpenRiskDataForEvalScroll] = React.useState('paper');
  const [selectedRiskEvaluateTitle, setSelectedRiskEvaluateTitle] = React.useState('');
  const [riskTypeDataForEval, setRiskTypeDataForEval] = React.useState({});

  function setOpenRiskDataForEvalfunction() {
      setOpenRiskDataForEval(false)
  }

  function onlyShowHierarchy(data){
    console.log(data)
    var canShow = false;
    if (data.riskHierarchy != null ){
      if (data.riskHierarchy.riskId != null && data.riskHierarchy.riskId != ''){
        if (data.riskHierarchy.riskHierarchyData != undefined && data.riskHierarchy.riskHierarchyData != null){
          canShow = true;
        }
      }
    }
    if (canShow){
      setRiskTypeDataForEval(data.riskHierarchy.riskHierarchyData)
      setSelectedRiskEvaluateTitle(data.riskHierarchy.name);
      setOpenRiskDataForEval(true);
      setOpenRiskDataForEvalScroll('paper');
    }else{
      alert("You haven't set the Risk Hierarchy for \"" + data.name + "\".");
    }
  }

  function getSubNo(comment, riskId) {
    var subNo = "0";
    riskTypeDataForEval.riskTypes.forEach((data) => {
        if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
            data.subRisks.forEach((item, index) => {
                if (item.riskTypeId === riskId) {
                    subNo = (index + 1) + "";
                }

            })
        }
    })
    if (subNo === "0") {
        riskTypeDataForEval.riskTypes.forEach((data) => {
            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                data.subRisks.forEach((item, index) => {
                    if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                        var tmpN0 = getSubRiskSubNo(item.subRisks, riskId, '');
                        if (tmpN0 != "0") {
                            subNo = (index + 1) + '.' + tmpN0;
                        } else {
                            riskTypeDataForEval.riskTypes.forEach((data1) => {
                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                    data1.subRisks.forEach((item1, index1) => {
                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                            item1.subRisks.forEach((item2, index2) => {
                                                item2.subRisks.forEach((item3, index3) => {
                                                    if (item3.riskTypeId === riskId) {
                                                        subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1);
                                                    }
                                                })
                                            })
                                        }
                                    })
                                }
                            })
                            if (tmpN0 === "0") {
                                riskTypeDataForEval.riskTypes.forEach((data1) => {
                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                        data1.subRisks.forEach((item1, index1) => {
                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                item1.subRisks.forEach((item2, index2) => {
                                                    item2.subRisks.forEach((item3, index3) => {
                                                        item3.subRisks.forEach((item4, index4) => {
                                                            if (item4.riskTypeId === riskId) {
                                                                subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1);
                                                            }
                                                        })
                                                    })
                                                })
                                            }
                                        })
                                    }
                                })
                                if (tmpN0 === "0") {
                                    riskTypeDataForEval.riskTypes.forEach((data1) => {
                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                            data1.subRisks.forEach((item1, index1) => {
                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                    item1.subRisks.forEach((item2, index2) => {
                                                        item2.subRisks.forEach((item3, index3) => {
                                                            item3.subRisks.forEach((item4, index4) => {
                                                                item4.subRisks.forEach((item5, index5) => {
                                                                    if (item5.riskTypeId === riskId) {
                                                                        subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1);
                                                                    }
                                                                })
                                                            })
                                                        })
                                                    })
                                                }
                                            })
                                        }
                                    })
                                    if (tmpN0 === "0") {
                                        riskTypeDataForEval.riskTypes.forEach((data1) => {
                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                data1.subRisks.forEach((item1, index1) => {
                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                        item1.subRisks.forEach((item2, index2) => {
                                                            item2.subRisks.forEach((item3, index3) => {
                                                                item3.subRisks.forEach((item4, index4) => {
                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                        item5.subRisks.forEach((item6, index6) => {
                                                                            if (item6.riskTypeId === riskId) {
                                                                                subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1);
                                                                            }
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                        if (tmpN0 === "0") {
                                            riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                    data1.subRisks.forEach((item1, index1) => {
                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                            item1.subRisks.forEach((item2, index2) => {
                                                                item2.subRisks.forEach((item3, index3) => {
                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                item6.subRisks.forEach((item7, index7) => {
                                                                                    if (item7.riskTypeId === riskId) {
                                                                                        subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1);
                                                                                    }
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                            if (tmpN0 === "0") {
                                                riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                        data1.subRisks.forEach((item1, index1) => {
                                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                item1.subRisks.forEach((item2, index2) => {
                                                                    item2.subRisks.forEach((item3, index3) => {
                                                                        item3.subRisks.forEach((item4, index4) => {
                                                                            item4.subRisks.forEach((item5, index5) => {
                                                                                item5.subRisks.forEach((item6, index6) => {
                                                                                    item6.subRisks.forEach((item7, index7) => {
                                                                                        item7.subRisks.forEach((item8, index8) => {
                                                                                            if (item8.riskTypeId === riskId) {
                                                                                                subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1);
                                                                                            }
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            }
                                                        })
                                                    }
                                                })
                                                if (tmpN0 === "0") {
                                                    riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                            data1.subRisks.forEach((item1, index1) => {
                                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                    item1.subRisks.forEach((item2, index2) => {
                                                                        item2.subRisks.forEach((item3, index3) => {
                                                                            item3.subRisks.forEach((item4, index4) => {
                                                                                item4.subRisks.forEach((item5, index5) => {
                                                                                    item5.subRisks.forEach((item6, index6) => {
                                                                                        item6.subRisks.forEach((item7, index7) => {
                                                                                            item7.subRisks.forEach((item8, index8) => {
                                                                                                item8.subRisks.forEach((item9, index9) => {
                                                                                                    if (item9.riskTypeId === riskId) {
                                                                                                        subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1);
                                                                                                    }
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                    if (tmpN0 === "0") {
                                                        riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                data1.subRisks.forEach((item1, index1) => {
                                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                        item1.subRisks.forEach((item2, index2) => {
                                                                            item2.subRisks.forEach((item3, index3) => {
                                                                                item3.subRisks.forEach((item4, index4) => {
                                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                                        item5.subRisks.forEach((item6, index6) => {
                                                                                            item6.subRisks.forEach((item7, index7) => {
                                                                                                item7.subRisks.forEach((item8, index8) => {
                                                                                                    item8.subRisks.forEach((item9, index9) => {
                                                                                                        item9.subRisks.forEach((item10, index10) => {
                                                                                                            if (item10.riskTypeId === riskId) {
                                                                                                                subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1) + "." + (index10 + 1);
                                                                                                            }
                                                                                                        })    
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        })

                                                    }

                                                }
                                            }
                                        }
                                    }
                                }
                            }


                        }

                    }

                })
            }
        })

    }
    return subNo;
  }

  function getSubRiskSubNo(comment, riskId, subNo) {
    var no = "0"
    comment.forEach((data, index) => {
        if (data.riskTypeId === riskId) {
            no = subNo + (index + 1) + '';
        }
    });
    if (no === "0") {
        comment.forEach((item, index) => {
            if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                getSubRiskSubNo(item.subRisks, riskId, (index + 1));
            }

        })
    }
    return no;
}

const [lastRiskTmpId, setLastRiskTmpId] = React.useState(0);


  function Comment({ comment, lastRiskTmpId, riskNo, fromMainMenu, fromSubMenu }) {

    console.log(comment);

    const [subLastRiskTmpId, setSubLastRiskTmpId] = React.useState(lastRiskTmpId);
    const [subRiskNo, setSubRiskNo] = React.useState(riskNo);
    const [show, setShow] = React.useState(true)
    var noDisplay = '';
    var noDisplaySpacesCount = 0;

    var subAvarageRisk = 0;
    var subAvarageAppetite = 0;
    if (fromMainMenu) {
        fromMainMenu = false;
        noDisplay = riskNo;
        //subAvarageRisk = getSubAvarageRisk(comment);
        //subAvarageAppetite = getSubAvarageAppetite(comment);
    }
    if (fromSubMenu) {
        fromSubMenu = false;
        var subNo = getSubNo(comment, comment.riskTypeId)
        noDisplay = riskNo + '.' + subNo;
        var tmpArr = noDisplay.split(".");
        if (tmpArr.length === 2) {
            noDisplaySpacesCount = 1;
        }
        if (tmpArr.length === 3) {
            noDisplaySpacesCount = 2;
        }
        if (tmpArr.length === 4) {
            noDisplaySpacesCount = 3;
        }
        if (tmpArr.length === 5) {
            noDisplaySpacesCount = 4;
        }
        if (tmpArr.length === 6) {
            noDisplaySpacesCount = 5;
        }
        if (tmpArr.length === 7) {
            noDisplaySpacesCount = 6;
        }
        if (tmpArr.length === 8) {
            noDisplaySpacesCount = 7;
        }
        if (tmpArr.length === 9) {
            noDisplaySpacesCount = 8;
        }
        if (tmpArr.length === 10) {
            noDisplaySpacesCount = 9;
        }
        if (tmpArr.length === 11) {
            noDisplaySpacesCount = 10;
        }
        //subAvarageRisk = getSubAvarageRisk(comment);
        //subAvarageAppetite = getSubAvarageAppetite(comment);
    }
    const nestedComments = (comment.subRisks || []).map(comment => {
        return <Comment key={comment.riskTypeTmpId} comment={comment} lastRiskTmpId={subLastRiskTmpId} riskNo={subRiskNo} fromSubMenu={true} type="child" />
    })

    return (
        <>
            <Grid item xs={8}>
                {noDisplaySpacesCount === 0 ?
                    <></>
                    : noDisplaySpacesCount === 1 ?
                        <>&nbsp;&nbsp;&nbsp;&nbsp;</>
                        : noDisplaySpacesCount === 2 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                            : noDisplaySpacesCount === 3 ?
                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                : noDisplaySpacesCount === 4 ?
                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                    : noDisplaySpacesCount === 5 ?
                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                        : noDisplaySpacesCount === 6 ?
                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                            : noDisplaySpacesCount === 7 ?
                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                : noDisplaySpacesCount === 8 ?
                                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                    : noDisplaySpacesCount === 9 ?
                                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                        : noDisplaySpacesCount === 10 ?
                                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                            : ''
                }
                <Button
                    style={{ width: '0px', height: '0px' }}
                    onClick={() => setShow(!show)}
                    startIcon={show ? <ExpandMoreIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} /> : <ChevronRightIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} />}
                >
                </Button>
                {noDisplay}{') '}{comment.name}
            </Grid>
            <Grid item xs={1}>
                <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLikelihood}</p></div></span>
            </Grid>
            <Grid item xs={1}>
                <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskImpact}</p></div></span>
            </Grid>
            <Grid item xs={1}>
                <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLevel}</p></div></span>
            </Grid>
            <Grid item xs={1}>
                <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskAppetite}</p></div></span>
            </Grid>


            {
                show ?
                    <>
                        {nestedComments}
                    </>
                    : null
            }
        </>

    )
}






  /** End of Show Only Hierachy */


  const RiskHieTableRowData = (

    <TableBody>
      {hierarchyData.map((row, index) => {
        //  if (row.isDelete === null || !row.isDelete ) {
        return (
          <StyledTableRow key={index} className='tableTrStyle'>
            <StyledTableCell component="th" scope="row" className='tableTrStyle' >
              <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{row.riskHierarchy.key!= null? row.riskHierarchy.key: ''}</div>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className='tableTrStyle' >
              <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{row.name}</div>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className='tableTrStyle' >
              <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{row.description}</div>
            </StyledTableCell>
            <StyledTableCell
              align="center"
              className='tableTrStyle'
            >
              {!showRegister?
              <>
              <Details
                className='mousePointer'
                onClick ={ ()=>{showHierarchy(row)}}
              />
              </>
              :
              <>
              <Details
                className={classes.mousePointer}
                onClick ={ ()=>{onlyShowHierarchy(row)}}
              />
              </>
                }

            </StyledTableCell>


            <StyledTableCell align="right" component="th" scope="row" className='tableTrStyle' >
              <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{row.likelihood}</div>
            </StyledTableCell>
            <StyledTableCell align="right" component="th" scope="row" className='tableTrStyle' >
              <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{row.impact}</div>
            </StyledTableCell>
            <StyledTableCell align="right" component="th" scope="row" className='tableTrStyle' >
              <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{row.riskLevel}</div>
            </StyledTableCell>
            <StyledTableCell align="right" component="th" scope="row" className='tableTrStyle' >
              <div className='tableTrStyle' style={{ whiteSpace: 'normal' }}>{row.riskAppetite}</div>
            </StyledTableCell>
            {!showRegister?
            <>
            <StyledTableCell
              align="right"
              className='tableTrStyle'
            >
              <EditIcon
                className={classes.mousePointer}
               onClick ={ ()=>{editHierarchyArrary(row.hierarchyTmpId)}}
              />
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className='tableTrStyle'
            >
              <DeleteIcon
                className={classes.mousePointer}
                onClick ={ ()=>{deleteHierarchyArrary(row.hierarchyTmpId)}}
              />
            </StyledTableCell>
            </>
            :
            ''
            }
          </StyledTableRow>
        )
        //   }
      })}
    </TableBody>
  )

  const [hierarchyValue, setHierarchyValue] = useState({});

  function hierarchyOnchange(newValue){
    console.log(newValue);
    setSelectedOption(newValue);
    if (newValue != undefined && newValue != null){
      
      
      setRiskId(newValue.riskId)
      setSelectedRiskHierarchy(newValue.name)
      setIntLikelihoodValueDialog(newValue.riskLikelihood)
      setIntImpactValesDialog(newValue.riskImpact)
      setIntRiskAppetiteValueDialog(newValue.riskAppetite)
    }
  }
  
  const [hierarchiesWithSameKey, setHierarchiesWithSameKey] = useState([])
  const [openKeyList, setOpenKeyList] = React.useState(false);  
  const [userKey, setUserKey] = React.useState('');
  const [registerKey, setRegisterKey] = React.useState('');  

  const [selectedRiskId, setSelectedRiskId] = React.useState('');  

  const handleChangeSelectedRisk = (event) => {
    setSelectedRiskId(event.target.value);
  }





  function editHierarchyArrary(id){
    
    hierarchyData.forEach((data)=> {
      if(data.hierarchyTmpId === id){
        console.log("++++++++++++++++++++data++++++++++++++++++")
        console.log(data)
        clearHierarcyEditStates();
        var riskDataExist = false;
        if (data.riskHierarchy != null){
          if (data.riskHierarchy.riskId != null && data.riskHierarchy.riskId != ''){
            riskDataExist = true;
          }
        }
        if (riskDataExist) {
          setWaitingOpen(true);
          axios(CONFIG.MAIN_URL + `/risk/all?centreId=` + globalCentreId, {
              method: 'get',
          })
          .then(function (response) {
            console.log("Risk All Return Response");
            console.log(response.data);
            setGlobalRiskArray(response.data);
            setWaitingOpen(false);
            let shouldContinue = true;
            for (let i = 0; i < response.data.length && shouldContinue; i++) {
              var riskD = response.data[i];
              if (riskD.riskId === parseInt(data.riskHierarchy.riskId)) {
                shouldContinue = false;
                var obj = {}
                obj["riskId"] = riskD.riskId;
                obj["key"] = riskD.key;
                obj["name"] = riskD.name;
                obj["likelihood"] = riskD.riskLikelihood;
                obj["impact"] = riskD.riskImpact;
                obj["riskAppetite"] = riskD.riskAppetite;
                setHierarchyValue(obj)
                setRiskId(riskD.riskId)
                setSelectedRiskHierarchy(riskD.name)
                setRiskKey(riskD.key);
                setHierarchyDataId(data.id);
                setEditHierarchyTmpId(data.hierarchyTmpId);
                setRegisterKey(riskD.key)
                setSelectedRiskHierarchy(data.name)
                setHierarchyDescription(data.description)
                if (data.riskDescription != undefined && data.riskDescription != null){
                  setHierarchyRiskDescription(data.riskDescription);
                }
                if (data.impactDescription != undefined && data.impactDescription != null){
                  setHierarchyImpactDescription(data.impactDescription);
                }
                if (data.mitigation != undefined && data.mitigation != null){
                  setHierarchyMitigation(data.mitigation);
                }

                setIntImpactValesDialog(data.impact)
                setIntLikelihoodValueDialog(data.likelihood)
                setIntRiskAppetiteValueDialog(data.riskAppetite)
                setAddHierarchyButton("update")
          
                setSelectedRiskId(riskD.riskId)


              
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            setWaitingOpen(false);
          });

        }else{
          var obj = {}
          console.log(data);

          obj["riskId"] = data.id;
          if (data.key != undefined && data.key != null){
            obj["key"] = data.key;
          }else{
            obj["key"] = '';
          }
          //obj["name"] = data.name;
          obj["likelihood"] = data.likelihood;
          obj["impact"] = data.impact;
          obj["riskAppetite"] = data.riskAppetite;
          //obj["riskLevel"] = data.riskAppetite;
          //setHierarchyValue(obj)

          setHierarchyDataId(data.id);

          setEditHierarchyTmpId(data.hierarchyTmpId);
          setRegisterKey(data.riskHierarchy.key)
          //setRiskKey(data.riskHierarchy.key)
          console.log(data.riskHierarchy.key)
          setSelectedRiskHierarchy(data.name)
          setHierarchyDescription(data.description)
          if (data.riskDescription != undefined && data.riskDescription != null){
            setHierarchyRiskDescription(data.riskDescription);
          }
          if (data.impactDescription != undefined && data.impactDescription != null){
            setHierarchyImpactDescription(data.impactDescription);
          }
          if (data.mitigation != undefined && data.mitigation != null){
            setHierarchyMitigation(data.mitigation);
          }
          setIntImpactValesDialog(data.impact)
          setIntLikelihoodValueDialog(data.likelihood)
          setIntRiskAppetiteValueDialog(data.riskAppetite)
          setAddHierarchyButton("update")
          
          setSelectedRiskId('')

          let shouldContinue = true;
          console.log("++++++++++++++++globalRiskArray++++++++++++++");
          console.log(globalRiskArray);
          console.log(data.riskHierarchy.key);

          var hierarchiesWithSameUserKey = []
          let keyExist = false;
          for (let i = 0; i < globalRiskArray.length && shouldContinue; i++) {
            const risk = globalRiskArray[i];
            
            if (risk.key === data.riskHierarchy.key) {
              keyExist = true;
              obj["name"] = data.name;
              //shouldContinue = false;
              var objTemp = {};
              objTemp["riskId"] = risk.riskId;
              objTemp["name"] = risk.name;
              hierarchiesWithSameUserKey.push(objTemp);
            }
          }
          if (keyExist){
            setHierarchiesWithSameKey(hierarchiesWithSameUserKey);
            //setUserKey(data.riskHierarchy.key);
            setRegisterKey(data.riskHierarchy.key);
            setOpenKeyList(true);
          }else{
              var answer = window.confirm("The user Key " + data.riskHierarchy.key + " is not in the created Risk hierarchy list, Do you want to create a new risk hierarchy for the user Key " + data.riskHierarchy.key +   " ? \n\n Press 'OK' to confirm");
              if (answer) {
                  setOpenCreateHierarchy(true);
                  setRegisterKey(data.riskHierarchy.key);
                  shouldContinue = false;
              } else {
                //some code
                shouldContinue = false;
              }
          }
        }

      }

    })
    

  }


  function continueUpdating(){
    if (selectedRiskId === ''){
      alert("Please select a Risk Hierarchy")
      return;
    }

    let shouldContinue = true;
    for (let i = 0; i < globalRiskArray.length && shouldContinue; i++) {
      const riskD = globalRiskArray[i];
      if (riskD.riskId === selectedRiskId) {
        console.log(riskD);
        shouldContinue = false;
        var obj = {}
        obj["riskId"] = selectedRiskId;
        obj["key"] = registerKey;
        obj["name"] = riskD.name;
        obj["likelihood"] = riskD.riskLikelihood;
        obj["impact"] = riskD.riskImpact;
        obj["riskAppetite"] = riskD.riskAppetite;
        setHierarchyValue(obj)
        setRiskId(riskD.riskId)
        setSelectedRiskHierarchy(riskD.name)
        setRiskKey(registerKey);
        //hierarchyOnchange(riskD)
        setOpenKeyList(false);

    
      }
    }
  }

  function createANewHierarchy(){
    setOpenCreateHierarchy(true);
    setOpenKeyList(false);

  }

  function deleteHierarchyArrary(tmpId){
    hierarchyData.forEach((item,index)=> {
      if (item.hierarchyTmpId === tmpId){
        hierarchyData.splice(index, 1);
        setTimeout(() => {
          setHierarchyData(hierarchyData);
          if (registerId != ''){
            let obj = {};
            obj["id"]= registerId;
            obj["name"]= registerName;
            obj["description"]= registerDescription;
            obj["centreId"]= globalCentreId;
            obj["status"]= 'Draft';
            obj["registerUniqueName"]= registerUniqName;
            obj["documentCreator"]= "expert";
            obj["riskRegister"] = categoryData;
            obj["createdUserId"] = registerCreatedUser;

            console.log(obj);
            console.log(JSON.stringify(obj));
            let sendJson = JSON.stringify(obj);
            setWaitingOpen(true);
            axios(CONFIG.MAIN_URL+'/register/save',{
              method: 'post',
              headers: { 
                  'Content-Type': 'application/json',
                },
              data : sendJson,  
            })
            .then(function (response) {
              setWaitingOpen(false);
              setOpenCreateRisk(false)
              alert("Delete Risk Data - " + item.name)
              setOpenCreateRisk(true)
              getAllRegister(globalCentreId)
            })
            .catch(function (error) {
              setWaitingOpen(false);
              console.log(error);
              alert("Error Deleting Risk Hierarchy-:" + error);
            });
          }else{
            setOpenCreateRisk(false)
            alert("Delete Risk Data - " + item.name)
            setOpenCreateRisk(true)
          }

        }, 500);
       
      }

    })
    
  }

  const [allEntityData, setAllEntityData] = React.useState([]);

  function getEntityData(centreId) {
    var data = '{allEntityList{ entityDataList{entityName,entityId,isActive,createdBy,lastModifiedBy,centreId,basicEntity{basicEntityId,entityName,iamRole,forceIamRole}}}}';
    axios(CONFIG.MAIN_URL + '/graphql', {
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
      },
      data: data,
    })
      .then(function (response) {
        //console.log("++++++++++++++++++++++++entityList+++++++++++++++++++++");
        var entityList = response.data.data.allEntityList.entityDataList;
        //console.log(entityList);

        var tmpEntityData = [];
        entityList.forEach((item, index) => {
          if (item.isActive && item.centreId === centreId) {
            var be = item.basicEntity
            var forceIamRole = item.basicEntity.forceIamRole;
            var iamRole = item.basicEntity.iamRole;
            var canTake = true;
            if ((iamRole === "ADMIN" && forceIamRole === "ADMIN") || (iamRole === "supplier" && forceIamRole === "supplier") || (iamRole === "serviceprovider" && forceIamRole === "serviceprovider") || (iamRole === "volunteer" && forceIamRole === "volunteer")) {
              canTake = false;
            }
            if (canTake) {
              var arrayData = {};
              arrayData["entityId"] = item.entityId;
              arrayData["entityName"] = item.entityName;
              tmpEntityData.push(arrayData);
            }
          }
        })
        //console.log("++++++++++++++++++++++++tmpEntityData+++++++++++++++++++++");
        //console.log(tmpEntityData);
        setAllEntityData(tmpEntityData);

      })
      .catch(function (error) {
        //console.log("Entity Loding error" + error);
      });
  }


  const [registerSaveBtn, setRegisterSaveBtn] = useState("SAVE REGISTER")
  
  function saveRegister(){

    if (registerName === '') {
      alert("Please Enter Register Name")
      return;
    }

    var draft = 'draft'
    if (registerId === ''){
      var sendingData = "mutation{createRegisterData(registerName:\"" + registerName +"\",status:\"" + draft + "\",centreId:\"" + globalCentreId + "\"){registerId,registerName,status,centreId }}"
      axios(CONFIG.MAIN_URL+'/graphql',{
        method: 'post',
        headers: { 
          'Content-Type': 'text/plain'
          },
        data : sendingData  
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        let rtnData = response.data;
        console.log("++++++++++ register Data save in PG");
        console.log(rtnData);
        console.log("++++++++++ register Data save in PG");

        let obj = {};

        obj["name"]= registerName;
        obj["description"]= registerDescription;
        obj["centreId"]= globalCentreId;
        obj["status"]= 'Draft';
        obj["registerUniqueName"]= globalCentreId + "_register_"+ rtnData.data.createRegisterData.registerId;
        obj["documentCreator"]= "expert";
        obj["riskRegister"] = categoryData;
        obj["createdUserId"] = globalUserId;

        console.log(obj);
        console.log(JSON.stringify(obj));
        let sendJson = JSON.stringify(obj);
          
        axios(CONFIG.MAIN_URL+'/register/save',{
          method: 'post',
          headers: { 
              'Content-Type': 'application/json',
            },
          data : sendJson,  
        })
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          let rtnData = response.data;
          alert("Register saved successfully.");
          getAllRegister(globalCentreId)
          setCategoryData([])
          setHierarchyData([])
          setRegisterSaveBtn("SAVE REGISTER")
          setRegisterName('')
          setRegisterDescription('')


        })
        .catch(function (error) {
          console.log(error);
          alert("Error saving Register-:" + error);
        });
      })
    
      .catch(function (error) {
        console.log(error);
        alert("Error saving register Data to Database Server -:" + error);
      });
  }else{

        let obj = {};

        obj["id"]= registerId;
        obj["name"]= registerName;
        obj["description"]= registerDescription;
        obj["centreId"]= globalCentreId;
        obj["status"]= 'Draft';
        obj["registerUniqueName"]= registerUniqName;
        obj["documentCreator"]= "expert";
        obj["riskRegister"] = categoryData;
        obj["createdUserId"] = registerCreatedUser;

        console.log(obj);
        console.log(JSON.stringify(obj));
        let sendJson = JSON.stringify(obj);


          
        axios(CONFIG.MAIN_URL+'/register/save',{
          method: 'post',
          headers: { 
              'Content-Type': 'application/json',
            },
          data : sendJson,  
        })
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          let rtnData = response.data;
          alert("Register Update successfully.");
          getAllRegister(globalCentreId)
          setCategoryData([])
          setHierarchyData([])
          setRegisterUniqName('')
          setRegisterCreatedUser('');
          setRegisterSaveBtn("SAVE REGISTER")
          setRegisterName('')
          setRegisterDescription('')
        })
        .catch(function (error) {
          console.log(error);
          alert("Error saving Register-:" + error);
        });

    }

  }


  const [selectedRegisterFinaliseTitle, setSelectedRegisterFinaliseTitle] = useState("")
  const [selectedRegisterFinaliseObj, setSelectedRegisterFinaliseObj] = useState({})
  const [selectedRegisterFinaliseObjOrg, setSelectedRegisterFinaliseObjOrg] = useState({})
  const [openRegisterFinalise, setOpenRegisterFinalise] = useState(false)
  const [openRegisterFinaliseScroll, setOpenRegisterFinaliseScroll] = useState('paper');
  
  function registerFinaliseClose() {
    getAllRegister(globalCentreId);
    setOpenRegisterFinalise(false)
  } 
 
  

  function finaliseRegisterFunction(){
    var arr = [];
    categoryData.forEach((item) => {
      item.risks.forEach((data) => {
        if (data.riskHierarchy != null){
          if (data.riskHierarchy.riskId != null && data.riskHierarchy.riskId != ''){
          }else{
            var obj = {}
            obj["key"] = data.riskHierarchy.key;
            obj["name"] = data.riskHierarchy.name;
            arr.push(obj);
          }
        }
      })
    })
    var canFinalise = false;
    if (arr.length > 0){
      //Should give a confirm message that some risk hierarchies are not set properly
      canFinalise = true;
    }else{
      canFinalise = true;
    }
    if (canFinalise){
      setWaitingOpen(true);
      axios(CONFIG.MAIN_URL + `/risk/all?centreId=` + globalCentreId, {
          method: 'get',
      })
      .then(function (response) {
        console.log("Risk All Return Response");
        console.log(response.data);
        setGlobalRiskArray(response.data);

        categoryData.forEach((item) => {
          item.risks.forEach((item1) => {
            if (item1.riskHierarchy != null){
              if (item1.riskHierarchy.riskId != null && item1.riskHierarchy.riskId != ''){
                let shouldContinue = true;
                for (let i = 0; i < response.data.length && shouldContinue; i++) {
                  var riskD = response.data[i];
                  if (riskD.riskId === parseInt(item1.riskHierarchy.riskId)) {
                    shouldContinue = false;
                    item1.riskHierarchy["riskHierarchyData"] = riskD;
                  }
                }  
              }
            }
          })
        })
        var regCount = 0;
        allRegister.forEach((item) => {
          if (item.registerUniqueName === registerUniqName){
            regCount ++;
          }
        })


        var obj = {};

        //obj["id"]= registerId;
        const d = new Date();
        var dateStr = d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear() + "  "  + d.getHours() + ":"  + d.getMinutes() + ":"  + d.getSeconds()
        obj["id"]= registerDetailId;
        obj["name"]= registerName;
        obj["description"]= registerDescription;
        obj["centreId"]= globalCentreId;
        obj["status"]= 'Finalise-' + regCount;
        obj["registerUniqueName"]= registerUniqName;
        obj["documentCreator"]= "expert";
        obj["riskRegister"] = categoryData;

        console.log(obj);
        setWaitingOpen(false);


        var tmpIdCount = 1;
        var tmpIdCount1 = 1;
        var jsonObj = createJsonObject(obj);
        setSelectedRegisterFinaliseTitle(registerName)
        jsonObj.riskTypes.forEach((item1, index) => {
          item1["riskTypeTmpId"] = tmpIdCount;
          tmpIdCount++
          if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
            tmpIdCount = assignExtraAttributes(item1.subRisks, tmpIdCount);
          }
          tmpIdCount++;
        })
        setSelectedRegisterFinaliseObj(jsonObj);
        setSelectedRegisterFinaliseObjOrg(obj);

        setOpenRegisterFinalise(true);
        setOpenRegisterFinaliseScroll('paper')




        //console.log(JSON.stringify(obj));
        
          
        
        /*
        let sendJson = JSON.stringify(obj);
        axios(CONFIG.MAIN_URL+'/register/save',{
          method: 'post',
          headers: { 
              'Content-Type': 'application/json',
            },
          data : sendJson,  
        })
        .then(function (response) {
          setWaitingOpen(false);
          console.log(JSON.stringify(response.data));
          let rtnData = response.data;
          alert("Register Finalised successfully.");
          getAllRegister(globalCentreId)
          setCategoryData([])
          setHierarchyData([])
          setRegisterUniqName('')
          setRegisterSaveBtn("SAVE REGISTER")
          setRegisterName('')
          setRegisterDescription('');
          cancelFunction();
        })
        .catch(function (error) {
          console.log(error);
          setWaitingOpen(false);
          alert("Error saving Register-:" + error);
        });

        */

      })
      .catch(function (error) {
        console.log(error);

        setWaitingOpen(false);
      });
    }else{
      alert("")
    }












  }

  function cancelFunction() {
    setShowRegister(false);
    setRegisterId('')
    setCategoryData([])
    setHierarchyData([])
    setRegisterDescription('')
    setRegisterName('')
    setHierarchyDataId('')
    setEditHierarchyTmpId(0);
    setSelectedRiskHierarchy('')
    setHierarchyDescription('')

    setHierarchyRiskDescription('');
    setHierarchyImpactDescription('');
    setHierarchyMitigation('');

    setIntImpactValesDialog('')
    setIntLikelihoodValueDialog('')
    setIntRiskAppetiteValueDialog('')
    setAddHierarchyButton("add")
    setCategoryId('')
    setCategoryName('')
    setCategoryDescription('')
    setEntityName('')
    setIntLikelihoodValue('')
    setIntImpactVales('')
    setIntRiskAppetiteValue('')
    setSectionTmpId(0)
    setEditSectionTmpId(0);
    //setHierarchyTmpId(0)
    setEditHierarchyTmpId(0)
    setCategoryButton('add')
    setRegisterSaveBtn("SAVE REGISTER")

  }


  const [categoryButton, setCategoryButton] = useState('add')

  //dialog

  const [maxWidth, setMaxWidth] = React.useState('lg');

  const [riskId, setRiskId] = React.useState('');
  const [riskKey, setRiskKey] = React.useState('');
  const [hierarchyDataId, setHierarchyDataId] = React.useState('');
  const [editHierarchyTmpId, setEditHierarchyTmpId] = React.useState(0);
  const [selectedRiskHierarchy, setSelectedRiskHierarchy] = React.useState('');


  const optionsDialog = ['1', '2', '3', '4', '5'];

  const [intImpactValesDialog, setIntImpactValesDialog] = useState('');
  const options1Dialog = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  const [intRiskAppetiteValueDialog, setIntRiskAppetiteValueDialog] = useState('');
  const [intLikelihoodValueDialog, setIntLikelihoodValueDialog] = useState('');
  const [intImpactValesForEachRiskDialog, setIntImpactValesForEachRiskDialog] = useState('');
  const [intLikelihoodValueForEachRiskDialog, setIntLikelihoodValueForEachRiskDialog] = useState('');
  const [hierarchyDescription, setHierarchyDescription] = useState('')

  const [hierarchyRiskDescription, setHierarchyRiskDescription] = useState('')
  const [hierarchyImpactDescription, setHierarchyImpactDescription] = useState('')
  const [hierarchyMitigation, setHierarchyMitigation] = useState('')

  const [riskDataFieldShow, setRiskDataFieldShow] = useState(false)


  function calculatedRiskLevelDialog() {
    var x = '';
    if (!isNaN(parseFloat(intImpactValesDialog)) && !isNaN(parseFloat(intLikelihoodValueDialog))) {
      x = parseInt(intImpactValesDialog) * parseInt(intLikelihoodValueDialog)
      //setIntRiskTypeValue(x);
    }
    return x;
  }

  const handleChange = (event) => {
    //alert(event.target.value)
    setSelectedRiskHierarchy(event.target.value);
  };

  const hierarchyDescriptionOnchange = (event) => {
    setHierarchyDescription(event.target.value)
  }

  const hierarchyRiskDescriptionOnchange = (event) => {
    setHierarchyRiskDescription(event.target.value)
  }
  const hierarchyImpactDescriptionOnchange = (event) => {
    setHierarchyImpactDescription(event.target.value)
  }
  const hierarchyMitigationOnchange = (event) => {
    setHierarchyMitigation(event.target.value)
  }


  function HierarchyDataToTable(){
    if (selectedRiskHierarchy === '') {
      alert('Please Select Hierarchy')
      return;
    }
    var riskData = {};
    if (editHierarchyTmpId === 0) {
      //alert("jbhjhjj")
      var HieData = {}
      var RiskObj = {}
      var idCount = sectionTmpId;
      HieData["name"] = selectedRiskHierarchy;
      HieData["orderNo"] = 0;
      HieData["hierarchyTmpId"] = (idCount + 1);
      setSectionTmpId(idCount + 1);
      HieData["description"] = hierarchyDescription;

      HieData["riskDescription"] = hierarchyRiskDescription;
      HieData["impactDescription"] = hierarchyImpactDescription;
      HieData["mitigation"] = hierarchyMitigation;


      HieData["likelihood"] = parseInt(intLikelihoodValueDialog);
      HieData["impact"] = parseInt(intImpactValesDialog);
      HieData["riskLevel"] = parseInt(intLikelihoodValueDialog) * parseInt(intImpactValesDialog);
      HieData["riskAppetite"] = parseInt(intRiskAppetiteValueDialog);
  
      RiskObj["riskId"] = riskId;
      RiskObj["key"] = riskKey;
      RiskObj["name"] = selectedRiskHierarchy;
      
      let shouldContinue = true;
      for (let i = 0; i < globalRiskArray.length && shouldContinue; i++) {
        const riskD = globalRiskArray[i];
        if (riskD.riskId === riskId) {
          shouldContinue = false;
          //RiskObj["riskHierarchyData"] = riskD;
          riskData = riskD;
        }
      }

      HieData["riskHierarchy"] = RiskObj;
    
      hierarchyData.push(HieData);

      var pos = 0;
      hierarchyData.forEach((data)=>{
        if(data.orderNo > pos){
          pos = data.orderNo;
        }
      })
      pos ++;
      hierarchyData.forEach((data)=>{
        if(data.orderNo === 0){
          data["orderNo"]= pos;
          pos ++;
        }
      })
      hierarchyData.sort( compare );
      
      categoryData.forEach((item)=>{
        if (item.sectionTmpId === editSectionTmpId){
          item["risks"] = hierarchyData;
        }
      })
      clearHierarcyEditStates();


    }else{
      var pos = 0;
      hierarchyData.forEach((data)=>{
        if(data.orderNo > pos){
          pos = data.orderNo;
        }
      })
      pos ++;
      hierarchyData.forEach((data)=>{
        if(data.orderNo === 0){
          data["orderNo"]= pos;
          pos ++;
        }
      })
      hierarchyData.sort( compare );
      
      hierarchyData.forEach((data)=>{
        if (data.hierarchyTmpId === editHierarchyTmpId) {
          data["id"] = hierarchyDataId;
          data["name"] = selectedRiskHierarchy;
          data["description"] = hierarchyDescription;
          data["riskDescription"] = hierarchyRiskDescription;
          data["impactDescription"] = hierarchyImpactDescription;
          data["mitigation"] = hierarchyMitigation;

          data["likelihood"] = parseInt(intLikelihoodValueDialog);
          data["impact"] = parseInt(intImpactValesDialog);
          data["riskLevel"] = parseInt(intLikelihoodValueDialog) * parseInt(intImpactValesDialog);
          data["riskAppetite"] = parseInt(intRiskAppetiteValueDialog);
      
          data.riskHierarchy["riskId"] = riskId;
          data.riskHierarchy["key"] = riskKey;
          data.riskHierarchy["name"] = selectedRiskHierarchy;
          
          let shouldContinue = true;

          for (let i = 0; i < globalRiskArray.length && shouldContinue; i++) {
            const riskD = globalRiskArray[i];
            if (riskD.riskId === riskId) {
              shouldContinue = false;
              //data.riskHierarchy["riskHierarchyData"] = riskD;
              riskData = riskD;
            }
          }
          data["riskHierarchy"] = data.riskHierarchy;
        }
      })
      categoryData.forEach((item)=>{
        if (item.sectionTmpId === editSectionTmpId){
          item["risks"] = hierarchyData;
        }
      })
      clearHierarcyEditStates()
    }

    /** Save the Register and Risk Hireachy */

    if (registerId != ''){
      let obj = {};
      obj["id"]= registerId;
      obj["name"]= registerName;
      obj["description"]= registerDescription;
      obj["centreId"]= globalCentreId;
      obj["status"]= 'Draft';
      obj["registerUniqueName"]= registerUniqName;
      obj["documentCreator"]= "expert";
      obj["riskRegister"] = categoryData;
      obj["createdUserId"] = registerCreatedUser;

      console.log(obj);
      console.log(JSON.stringify(obj));
      let sendJson = JSON.stringify(obj);
      setWaitingOpen(true);  
      axios(CONFIG.MAIN_URL+'/register/save',{
        method: 'post',
        headers: { 
            'Content-Type': 'application/json',
          },
        data : sendJson,  
      })
      .then(function (response) {
        console.log("++++++++++++++++++Register Update Response+++++++++++++++++++");
        console.log(response.data);
        console.log(riskData)
        let rtnData = response.data;
        setWaitingOpen(false);
        alert("Register Update successfully.");
        getAllRegister(globalCentreId);

        /** Should save the Risk Hirachy with Register Id */
        var rtnRegisterId = rtnData.id;
        var rtnRegisterName = rtnData.name;

        var arr = []
        if (riskData.registers != undefined && riskData.registers != null && riskData.registers.length > 0){
          riskData.registers.forEach((dataItem)=>{
            if (dataItem.id === rtnRegisterId){
              var obj = {}
              obj["id"] = rtnRegisterId
              obj["name"] = rtnRegisterName
              arr.push(obj);
            }else{
              arr.push(dataItem)
            }
          })
        }else{
          var obj = {}
          obj["id"] = rtnRegisterId
          obj["name"] = rtnRegisterName
          arr.push(obj)

        }
        riskData["registers"]=arr;
        var dataStr = JSON.stringify(riskData);
        console.log("++++++++++++++++Saving data Str++++++++++++++++++++++");
        console.log(dataStr);    
        
        axios(CONFIG.MAIN_URL + '/risk/save', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          data: dataStr,
        })
          .then(function (res) {
            console.log("++++++++++++++Updatd Risk Return+++++++++++");
            console.log(res.data);
            setWaitingOpen(false);
          })
          .catch(function (error) {
            setWaitingOpen(false);
            console.log(error);
            alert("Error saving Risk Type -:" + error);
          });
        

        




      })
      .catch(function (error) {
        setWaitingOpen(false);
        console.log(error);
        alert("Error saving Register-:" + error);
      });
    }

  }

  function clearHierarcyEditStates(){
    setHierarchyDataId('')
    setEditHierarchyTmpId(0)
    setHierarchyDescription('')
    setHierarchyRiskDescription('')
    setHierarchyImpactDescription('')
    setHierarchyMitigation('')
    setSelectedRiskHierarchy('')
    setIntImpactValesDialog('')
    setIntLikelihoodValueDialog('')
    setIntRiskAppetiteValueDialog('')
    setAddHierarchyButton("add")
    setSelectedOption(null)
    setHierarchyValue({});
    setSelectedRiskId('')
    setRiskKey('');
    //setEditHierarchyTmpId(0);
  }

  const [acValue, setACValue] = useState(null)

  
  const [addHierarchyButton, setAddHierarchyButton] = useState('ADD');

  const [selectedOption, setSelectedOption] = useState(null);


  /** Athula */

  function PaperComponentCreateHierarchy(props) {
    return (
      <Draggable1
        handle="#create-hierarchy-data-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable1>
    );
  }
  function PaperComponentShowHierarchy(props) {
    return (
      <Draggable1
        handle="#show-hierarchy-data-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable1>
    );
  }
  const [updatedRiskHierarchyId, setUpdatedRiskHierarchyId] = React.useState('');
  const [openCreateHierarchy, setOpenCreateHierarchy] = React.useState(false);
  const [openCreateHierarchyScroll, setOpenCreateHierarchyScroll] = React.useState('paper');

  function createNewHierarchyFunction() {
    setOpenCreateHierarchy(true);
    setOpenCreateHierarchyScroll('paper');
  }
  function closeCreateHierarchyFunction() {
    getGlobalRiskForCenter(globalCentreId, 2, updatedRiskHierarchyId);
    setRiskId(updatedRiskHierarchyId)
    setOpenCreateHierarchy(false);
    //getAllRisks(globalCentreId);
  }

  /** End Athula */

  /** For Evaluation Page */

  const [openEvaluation, setOpenEvaluation] = React.useState(false);
  const [openEvaluationScroll, setOpenEvaluationScroll] = React.useState('paper');
  const [fromMultiTaskin, setFromMultiTaskin] = useState(true)
  
  function openEvaluationfunction() {
    setOpenEvaluation(true);
    setOpenEvaluationScroll('paper');
  }
  
  function PaperComponentEvaluation(props) {
    return (
        <Draggable1
            handle="#evaluation-data-dialog-titlee"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable1>
    );
  }
  
  function setOpenEvaluationfunction() {
      setOpenEvaluation(false)
  }


  /** evaluate malindu */

  const [colorPatterns, setColorPatterns] = React.useState([])
  const [colorPatternId, setColorPatternId] = React.useState('')
  const [databClr, setDatabClr] = React.useState([]);
  const [tdPresentage, setTdPresentage] = React.useState()
  const [colorPatternName, setColorPatternName] = React.useState('')
  const [colorPatternPrimaryColorName, setColorPatternPrimaryColorName] = React.useState('')
  const [colorPatternSecondaryColorName, setColorPatternSecondaryColorName] = React.useState('')
 
  const [colorSelectDropDownRiskLevel, setColorSelectDropDownRiskLevel] = React.useState();
  const [colorSelectDropDownRiskAppetite, setColorSelectDropDownRiskAppetite] = React.useState();
  const [colorSelectDropDownRiskLevelTmp, setColorSelectDropDownRiskLevelTmp] = React.useState();
  const [colorSelectDropDownRiskAppetiteTmp, setColorSelectDropDownRiskAppetiteTmp] = React.useState();
  const [btnHirachivalClass, setBtnHirachivalClass] = React.useState(classes.btnNormal);
  const [btnGradientClass, setBtnGradientClass] = React.useState(classes.btnNormal);
  const [btnBarChartClass, setBtnBarChartClass] = React.useState(classes.btnNormal);
  const [riskRegisterValidate, setRiskRegisterValidate] = React.useState(false);
  const [editRiskHirachy, setEditRiskHirachy] = React.useState(false);
  const [selectedRegisterEvaluateTitle, setSelectedRegisterEvaluateTitle] = React.useState('');
  const [registerTypeDataForEval, setRegisterTypeDataForEval] = React.useState({});
  const [originalRegisterTypeDataForEval, setOriginalRegisterTypeDataForEval] = React.useState({});
  const [openRegisterDataForEval, setOpenRegisterDataForEval] = React.useState(false);
  const [openRegisterDataForEvalScroll, setOpenRegisterDataForEvalScroll] = React.useState('paper');
  const [createdRiskId, setCreatedRiskId] = React.useState('');
  const [maxWidthState, setMaxWidthState] = React.useState('lg');
  const [fullScreenState, setFullScreenState] = React.useState(false);
  const [fullScreenStateRegister, setFullScreenStateRegister] = React.useState(false);
  const [fullScreenStateMsg, setFullScreenStateMsg] = React.useState('Full Screen');
 
  const [applyFlag, setApplyFlag] = React.useState(false)
  const [showEvalData, setShowEvalData] = React.useState(false)
  const [showTableType, setShowTableType] = React.useState()
  const [commitBarData, setCommitBarData] = React.useState([]);
  const [barChartHeight, setBarChartHeight] = React.useState(600);
  const [barData, setBarData] = React.useState([]);
  const [arrRiskColorState, setArrRiskColorState] = React.useState([]);
  const [arrColorAppState, setArrColorAppState] = React.useState([]);
  const [riskCategoryMethodData, setRiskCategoryMethodData] = React.useState([])
  const [riskCategoryMethodDataAppetite, setRiskCategoryMethodDataAppetite] = React.useState([])
  const [showTranferExlButton, setShowTranferExlButton] = React.useState(false)
 
 
  const renderCustomizedLabel = (props) => {
   const {
     x, y, width, height, value,
   } = props;
 
 const fireOffset = value.toString().length < 5;
 const offset = fireOffset ? -40 : 80;
 const orgWidth = width > 500 ? 500 : width;
   return (
       <text x={x + orgWidth + offset} y={y + height - 5} fill={fireOffset ? "#285A64" :"#7e96ac"} textAnchor="end">
         {value}
       </text>
   );
 };
 
 const renderCustomizedLabel1 = (props) => {
   const {
     x, y, width, height, value,
   } = props;
 
 const fireOffset = value.toString().length < 5;
 const offset = fireOffset ? -40 : 100;
 const orgWidth = width > 500 ? 500 : width;
   return (
       <text x={x + orgWidth + offset} y={y + height - 5} fill={fireOffset ? "#285A64" :"#7e96ac"} textAnchor="end">
         {value}
       </text>
   );
 };
 
  function setMinMaxWindowFunction() {
   if (!fullScreenState) {
       setFullScreenState(true);
       setFullScreenStateMsg('Full Screen Out');
   } else {
       setFullScreenState(false);
       setFullScreenStateMsg('Full Screen');
   }
 
 }
 
 function hierarchicalFunction() {
   setBtnHirachivalClass(classes.btnActive)
   setBtnGradientClass(classes.btnNormal)
   setBtnBarChartClass(classes.btnNormal)
 }
 
 const [arrayForDisplayDataInTable, setArrayForDisplayDataInTable] = React.useState([])
 const [commitArrayData, setCommitArrayData] = React.useState([])
 
 var commitArray = []
 function pushToArray(item) {
     commitArray.push(item);
     console.log("+++++++++++++++++++++commitArray+++++++++++++++");
     console.log(commitArray);
 }
 
 
 const handleChangeColorPattern = (event) => {
   setColorPatternId(event.target.value);
   colorPatterns.forEach((data, index) => {
       if (data.id === event.target.value) {
           console.log(data)
           setDatabClr(data.colors)
           setTdPresentage(100 / (data.colors.length + 1))
 
           setColorPatternName(data.name);
           setColorPatternPrimaryColorName(data.primaryColorName);
           setColorPatternSecondaryColorName(data.secondaryColorName);
       }
   })
 };
 
 const handleChangeDropDown = (event) => {
   setColorSelectDropDownRiskLevelTmp(event.target.value);
 };
 
 const handleChangeDropDownAppetite = (event) => {
   setColorSelectDropDownRiskAppetiteTmp(event.target.value);
 };
 
 function applyFunction() {
   setColorSelectDropDownRiskLevel(colorSelectDropDownRiskLevelTmp);
   setColorSelectDropDownRiskAppetite(colorSelectDropDownRiskAppetiteTmp)
 
   setShowEvalData(true)
   setApplyFlag(true)
   setShowTableType(1)
   setBtnHirachivalClass(classes.btnActive)
   setBtnGradientClass(classes.btnNormal)
   setBtnBarChartClass(classes.btnNormal)
 }
 
  function getColors() {
   axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR + "/all", {
       method: 'get',
       headers: {
           'Content-Type': 'text/plain',
       },
   })
       .then(function (response) {
           console.log("+++++++++++++++++All colors++++++++++++++++++");
           console.log(response.data);
           setColorPatterns(response.data);
 
       })
       .catch(function (error) {
           console.log(error);
       });
 }
 
 const CustomTooltip = ({ active, payload, label }) => {
   if (active && payload && payload.length) {
       return (
           <div className="custom-tooltip" style={{ backgroundColor: '#E2E4EC', padding: '3px 15px', border: '1px solid black', borderRadius: '5PX' }}>
               <p className="label" style={{ fontWeight: 'bold' }}>{`${label} `}</p>
               <p className="label">{`Risk Level : ${payload[0].value}`}</p>
               <p className="label">{`Risk Appetite : ${payload[1].value}`}</p>
 
           </div>
       );
   }
 
   return null;
 };
 
 function createJsonObject(obj) {
   //console.log("+++++++++++++++++++obj+++++++++++++++++++++");
   //console.log(obj);
   var regTmpId = 100000;
   obj.riskRegister.forEach((data) => {
       data["riskTypeId"] =  regTmpId
       regTmpId ++;
       data.risks.forEach((risk) => {
           risk["riskTypeId"] =  regTmpId
           regTmpId ++;
           if (risk.riskHierarchy != undefined && risk.riskHierarchy != null) {
               if (risk.riskHierarchy.riskHierarchyData != undefined && risk.riskHierarchy.riskHierarchyData != null) {
                   var riskHierarchyObj = risk.riskHierarchy.riskHierarchyData;
                   if (riskHierarchyObj.riskTypes != undefined && riskHierarchyObj.riskTypes != null && riskHierarchyObj.riskTypes.length > 0) {
                       risk["subRisks"] = riskHierarchyObj.riskTypes;
                   }
               }
               
           }

       })
   })

   var dataStr = JSON.stringify(obj);
   dataStr = dataStr.replaceAll("\"riskRegister\"", "\"riskTypes\"");
   dataStr = dataStr.replaceAll("\"section\"", "\"name\"");
   dataStr = dataStr.replaceAll("\"risks\"", "\"subRisks\"");
   dataStr = dataStr.replaceAll("\"likelihood\"", "\"riskLikelihood\"");
   dataStr = dataStr.replaceAll("\"impact\"", "\"riskImpact\"");
   var jsonObj = JSON.parse(dataStr)

   return jsonObj;

 }
 
 function assignExtraAttributes(data, tmpIdCount) {
   data.forEach((item) => {
       item["riskTypeTmpId"] = tmpIdCount
       tmpIdCount++;
       if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
           assignExtraAttributes(item.subRisks, tmpIdCount);
       }
   })
   return tmpIdCount;
 }

 const [multipleRoles, setMultipleRoles] = useState(false)
 
 function getAllRegisterEval(centrId, regId) {
   axios(CONFIG.MAIN_URL + `/register/all`, {
       method: 'get',
   })
       .then(function (response) {
           //var arr = []
           console.log("all register");
           console.log(response.data);
           console.log(centrId);
           console.log(regId);
           response.data.forEach((item) => {
               if (item.centreId === centrId && item.id ===regId){
                   var tmpIdCount = 1;
                   var tmpIdCount1 = 1;
                   var jsonObj = createJsonObject(item);
                   setSelectedRegisterEvaluateTitle(item.name)
                   jsonObj.riskTypes.forEach((item1, index) => {
                       item1["riskTypeTmpId"] = tmpIdCount;
                       tmpIdCount++
                       if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                           tmpIdCount = assignExtraAttributes(item1.subRisks, tmpIdCount);
                       }
                       tmpIdCount++;
                   })

                  //  const groupedRoles = item.reduce((acc, role) => {
                  //   const existingRole = acc.find(group => group.role === role.role);
                  //   if (existingRole) {
                  //     existingRole.data.push(role);
                  //   } else {
                  //     acc.push({ role: role.role, data: [role] });
                  //   }
                  //   return acc;
                  // }, []);

                   console.log("+++++++++++++++jsonObj+++++++++++++++")
                   console.log(jsonObj)

 
                   setRegisterTypeDataForEval(jsonObj);

                   setMultipleRoles(false)

                   var mainArr = []
                   jsonObj.riskTypes.forEach((data) => {
                     var secName = data.role;
                     var obj = {};
                     var arr = [];
                     var canTake = true;
               
                     mainArr.forEach((arrData) => {
                         if (arrData.role === secName){
                             canTake = false;
                         }
                       })
               
                       if (canTake) {
                        jsonObj.riskTypes.forEach((item) => {
                             if (secName === item.role) {
                                 arr.push(item);
                             } 
                         })
               
                         obj["role"] = data.role
               
                         mainArr.push(obj);
                     }	
                   })
               
                   if(mainArr.length > 1){
                     setMultipleRoles(true)
                   }
                
 
                   var jsonObj1 = createJsonObject(item);
                   jsonObj1.riskTypes.forEach((item1, index) => {
                       item1["riskTypeTmpId"] = tmpIdCount1;
                       tmpIdCount1++
                       if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                           tmpIdCount1 = assignExtraAttributes(item1.subRisks, tmpIdCount1);
                       }
                       tmpIdCount1++;
                   })
                   setOriginalRegisterTypeDataForEval(jsonObj1)
                   //console.log(jsonObj1)


               }
 
           })
 
       })
       .catch(function (error) {
           console.log(error);
       });


 }
 



   function evaluateRiskFuction() {

 
         //getAllRegister(globalCentreId);
         setEditRiskHirachy(false)
         getAllRegisterEval(globalCentreId, registerDetailId);
         
         
         setOpenRegisterDataForEval(true)
         setOpenRegisterDataForEvalScroll('paper');
 
         //setOpenRiskDataForEval(true);
         //setOpenRiskDataForEvalScroll('paper');



         
 }
 
 function CommentRegister({ comment, lastRiskTmpId, riskNo, fromMainMenu, fromSubMenu, editRiskHirachyData }) {
 
   console.log("++++++++++++++++++++comment++++++++++++++++++++++");
   console.log(editRiskHirachyData);
 
   const [subLastRiskTmpId, setSubLastRiskTmpId] = React.useState(lastRiskTmpId);
   const [subEditRiskHirachyData, setSubEditRiskHirachyData] = React.useState(editRiskHirachyData);
   const [subRiskNo, setSubRiskNo] = React.useState(riskNo);
   const [show, setShow] = React.useState(true)
 
   var noDisplay = '';
   var noDisplaySpacesCount = 0;
 
 
   function onTxtNameChageEvent(event) {
       comment["name"] = event.target.value
   }
   function onBlurChageEvent(event) {
       comment["name"] = event.target.value
   }
 
 
   const onTextFieldChangeLikelihood = (event) => {
       if (event != null && event.target.value != null) {
           var riskLevelVal = 1;
           var riskImpactVal = 1;
           if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
               event.target.value = '';
               if (comment.riskImpact != undefined && comment.riskImpact != null && comment.riskImpact != '') {
                   riskImpactVal = parseInt(comment.riskImpact);
               }
           } else {
               if (comment.riskImpact != undefined && comment.riskImpact != null && comment.riskImpact != '') {
                   riskImpactVal = parseInt(comment.riskImpact);
               }
               riskLevelVal = parseInt(event.target.value)
           }
           comment["riskLikelihood"] = event.target.value;
           var rLevel = riskImpactVal * riskLevelVal;
           comment["riskLevel"] = rLevel.toString();
         //  comment.riskLevelData["riskLikelihood"] = event.target.value;
         //  comment.riskLevelData["riskLevel"] = rLevel.toString();
           var btn = document.getElementById("btn" + comment.riskTypeTmpId);
           btn.click();
           setTimeout(() => {
               btn.click();
           }, 10);
 
       }
   }
 
   const onTextFieldChangeImpact = (event) => {
       if (event != null && event.target.value != null) {
           var riskLevelVal = 1;
           var riskImpactVal = 1;
           if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
               event.target.value = ''
               if (comment.riskLikelihood != undefined && comment.riskLikelihood != null && comment.riskLikelihood != '') {
                   riskLevelVal = parseInt(comment.riskLikelihood);
               }
           } else {
               if (comment.riskLikelihood != undefined && comment.riskLikelihood != null && comment.riskLikelihood != '') {
                   riskLevelVal = parseInt(comment.riskLikelihood);
               }
               riskImpactVal = parseInt(event.target.value)
           }
           comment["riskImpact"] = event.target.value;
           //comment.riskLevelData["riskImpact"] = event.target.value;
           var rLevel = riskImpactVal * riskLevelVal;
           comment["riskLevel"] = rLevel.toString();
         //  comment.riskLevelData["riskLevel"] = rLevel.toString();
           var btn = document.getElementById("btn" + comment.riskTypeTmpId);
           btn.click();
           setTimeout(() => {
               btn.click();
           }, 10);
       }
   }
 
   const onTextFieldChangeAppetite = (event) => {
       if (event != null && event.target.value != null) {
           if (event.target.value <= 0 || event.target.value > 10 || !Number.isInteger(parseInt(event.target.value))) {
               event.target.value = ''
           }
           comment["riskAppetite"] = event.target.value;
          // comment.riskAppetiteData["riskAppetite"] = event.target.value;
           var btn = document.getElementById("btn" + comment.riskTypeTmpId);
           btn.click();
           setTimeout(() => {
               btn.click();
           }, 10);
       }
   }
 
 
 
   var subAvarageRisk = 0;
   var subAvarageAppetite = 0;
   if (fromMainMenu) {
       fromMainMenu = false;
       noDisplay = riskNo;
       subAvarageRisk = getSubAvarageRisk(comment);
       //subAvarageRisk = getSubAvarageRisk1(comment);
       subAvarageAppetite = getSubAvarageAppetite(comment);
   }
   if (fromSubMenu) {
       fromSubMenu = false;
       var subNo = getSubNoReg(comment, comment.riskTypeId)
       noDisplay = riskNo + '.' + subNo;
       var tmpArr = noDisplay.split(".");
       if (tmpArr.length === 2) {
           noDisplaySpacesCount = 1;
       }
       if (tmpArr.length === 3) {
           noDisplaySpacesCount = 2;
       }
       if (tmpArr.length === 4) {
           noDisplaySpacesCount = 3;
       }
       if (tmpArr.length === 5) {
           noDisplaySpacesCount = 4;
       }
       if (tmpArr.length === 6) {
           noDisplaySpacesCount = 5;
       }
       if (tmpArr.length === 7) {
           noDisplaySpacesCount = 6;
       }
       if (tmpArr.length === 8) {
           noDisplaySpacesCount = 7;
       }
       if (tmpArr.length === 9) {
           noDisplaySpacesCount = 8;
       }
       if (tmpArr.length === 10) {
           noDisplaySpacesCount = 9;
       }
       if (tmpArr.length === 11) {
           noDisplaySpacesCount = 10;
       }
 
 
       subAvarageRisk = getSubAvarageRisk(comment);
       //subAvarageRisk = getSubAvarageRisk1(comment);
       subAvarageAppetite = getSubAvarageAppetite(comment);
   }
   const nestedComments = (comment.subRisks || []).map(comment => {
       return <CommentRegister key={comment.riskTypeTmpId} comment={comment} lastRiskTmpId={subLastRiskTmpId} riskNo={subRiskNo} fromSubMenu={true} editRiskHirachyData={subEditRiskHirachyData} type="child" />
   })
 
 
 
 
 
 
   /** Need */
   function getRiskRiskTypeName(Comment) {
       var rName = ''
       riskCategoryMethodData.forEach((data) => {
           if (data.id === Comment.riskLevelData.riskMethodId) {
               data.riskTypes.forEach((data1) => {
                   if (data1.startingNumber <= Comment.riskLevel && Comment.riskLevel <= data1.endingNumber) {
                       rName = data1.riskTypesName
                   }
               })
           }
 
       })
       return rName;
   }
 
   /** Need */
   function getAppetiteRiskTypeName(Comment) {
       var aName = ''
       riskCategoryMethodDataAppetite.forEach((data) => {
           if (data.id === Comment.riskAppetiteData.riskMethodId) {
               data.riskTypes.forEach((data1) => {
                   if (data1.startingNumber <= Comment.riskAppetite && Comment.riskAppetite <= data1.endingNumber) {
                       aName = data1.riskTypesName
                   }
               })
           }
 
       })
       return aName;
   }
 
 
   /** Need */
   function getRiskRiskColor(Comment) {
       var rColor = ''
       var lastNumber = ''
       databClr.forEach((data2) => {
           lastNumber = data2.scoreNumber;
           if (applyFlag) {
               if (colorSelectDropDownRiskLevel === 1) {
                   if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                       rColor = data2.color
                   }
               } else if (colorSelectDropDownRiskLevel === 2) {
                   if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                       rColor = data2.secondaryColor
                   }
               }
           }
       })
       if (rColor === '') {
           databClr.forEach((data2) => {
               if (applyFlag) {
                   if (colorSelectDropDownRiskLevel === 1) {
                       if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                           rColor = data2.color
                       }
                   } else if (colorSelectDropDownRiskLevel === 2) {
                       if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                           rColor = data2.secondaryColor
                       }
                   }
               }
           })
       }
       return rColor;
   }
 
 
   function getRiskRiskAction(Comment) {
       var rAction = ''
       var lastNumber = ''
       databClr.forEach((data2) => {
           lastNumber = data2.scoreNumber;
           if (applyFlag) {
               if (colorSelectDropDownRiskLevel === 1) {
                   if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                       rAction = data2.primaryColorName
                   }
               } else if (colorSelectDropDownRiskLevel === 2) {
                   if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                       rAction = data2.secondaryColorName
                   }
               }
           }
       })
       if (rAction === '') {
           databClr.forEach((data2) => {
               if (applyFlag) {
                   if (colorSelectDropDownRiskLevel === 1) {
                       if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                           rAction = data2.primaryColorName
                       }
                   } else if (colorSelectDropDownRiskLevel === 2) {
                       if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                           rAction = data2.secondaryColorName
                       }
                   }
               }
           })
       }
       return rAction;
   }
 
 
   function getRiskRiskColorAve(avarageRisk) {
       var rColor = ''
       var lastNumber = '';
       databClr.forEach((data2) => {
           lastNumber = data2.scoreNumber;
           if (applyFlag) {
               if (colorSelectDropDownRiskLevel === 2) {
                   if (parseInt(data2.scoreNumber) === parseInt(avarageRisk)) {
                       rColor = data2.secondaryColor;
                   }
               } else if (colorSelectDropDownRiskLevel === 1) {
                   if (parseInt(data2.scoreNumber) === parseInt(avarageRisk)) {
                       rColor = data2.color;
                   }
               }
           }
       })
       if (rColor === '') {
           databClr.forEach((data2) => {
               if (applyFlag) {
                   if (colorSelectDropDownRiskLevel === 2) {
                       if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                           rColor = data2.secondaryColor;
                       }
                   } else if (colorSelectDropDownRiskLevel === 1) {
                       if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                           rColor = data2.color;
                       }
                   }
               }
           })
       }
       return rColor;
   }
 
 
 
 
 
   /** Need */
   function getAppetiteColor(Comment) {
       var aColor = ''
       var colorFg1 = ''
 
       databClr.forEach((data2) => {
           if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
               //console.log("++++++++++++++data2+++++++++++++++")
               //console.log(data2)
               //console.log(applyFlag)
               //console.log(Comment.riskAppetiteData)
               //console.log(colorSelectDropDownRiskAppetite)
 
               if (Comment.riskAppetite != null && Comment.riskAppetite != undefined) {
                   /*
                   if (applyFlag) {
                       if (colorSelectDropDownRiskAppetite === 1) {
                           if (Comment.riskAppetiteData.color != null && Comment.riskAppetiteData.color != undefined && Comment.riskAppetiteData.color != '') {
                               aColor = Comment.riskAppetiteData.color;
                           }
                       }
                       else if (colorSelectDropDownRiskAppetite === 2) {
                           if (Comment.riskAppetiteData.secondaryColor != null && Comment.riskAppetiteData.secondaryColor != undefined && Comment.riskAppetiteData.secondaryColor != '') {
                               aColor = Comment.riskAppetiteData.secondaryColor;
 
                           }
                       }
                   }
                   */
 
                   if (applyFlag) {
                       if (colorSelectDropDownRiskAppetite === 1) {
                           if (aColor === '') {
                               aColor = data2.color
                           }
                       }
                       if (colorSelectDropDownRiskAppetite === 2) {
                           if (aColor === '') {
                               aColor = data2.secondaryColor
                           }
                       }
                   }
               }
           }
       })
       return aColor;
   }
 
 
   function getRiskAppetiteAction(Comment) {
       var aColor = ''
       var colorFg1 = ''
 
       databClr.forEach((data2) => {
           if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
               //console.log("++++++++++++++data2+++++++++++++++")
               //console.log(data2)
               //console.log(applyFlag)
               //console.log(Comment.riskAppetiteData)
               //console.log(colorSelectDropDownRiskAppetite)
 
               if (Comment.riskAppetite != null && Comment.riskAppetite != undefined) {
                   if (applyFlag) {
                       if (colorSelectDropDownRiskAppetite === 1) {
                           if (aColor === '') {
                               aColor = data2.primaryColorName
                           }
                       }
                       if (colorSelectDropDownRiskAppetite === 2) {
                           if (aColor === '') {
                               aColor = data2.secondaryColorName
                           }
                       }
                   }
               }
           }
       })
       return aColor;
   }
 
   function getAppetiteColorAve(avarageAppetite) {
       var aColor = ''
       databClr.forEach((data2) => {
           if (applyFlag) {
               if (colorSelectDropDownRiskAppetite === 2) {
                   if (parseInt(data2.scoreNumber) === parseInt(avarageAppetite)) {
                       aColor = data2.secondaryColor;
                   }
               } else if (colorSelectDropDownRiskAppetite === 1) {
                   if (parseInt(data2.scoreNumber) === parseInt(avarageAppetite)) {
                       aColor = data2.color;
                   }
               }
           }
 
 
       })
       return aColor;
 
 
 
   }
 
 
   /** Need */
   function getRiskLikeliColor(Comment) {
 
       var rColor = ''
       databClr.forEach((data2) => {
           if (applyFlag) {
               if (colorSelectDropDownRiskLevel === 1) {
                   if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLikelihood)) {
                       rColor = data2.color
                       //setApplyFlag(false)
                   }
               } else if (colorSelectDropDownRiskLevel === 2) {
                   if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLikelihood)) {
                       rColor = data2.secondaryColor
                       //setApplyFlag(false)
                   }
               }
           }
 
       })
       return rColor;
   }
 
   /** Need */
   function getRiskImpactColor(Comment) {
 
       var rColor = ''
       databClr.forEach((data2) => {
           if (applyFlag) {
               if (colorSelectDropDownRiskLevel === 1) {
                   if (parseInt(data2.scoreNumber) === parseInt(Comment.riskImpact)) {
                       rColor = data2.color
                       //setApplyFlag(false)
                   }
               } else if (colorSelectDropDownRiskLevel === 2) {
                   if (parseInt(data2.scoreNumber) === parseInt(Comment.riskImpact)) {
                       rColor = data2.secondaryColor
                       //setApplyFlag(false)
                   }
               }
           }
 
       })
       return rColor;
   }
 
   var riskLikelihood = getRiskLikeliColor(comment)
   var appetiteColor = getAppetiteColor(comment);
   var appetiteAction = getRiskAppetiteAction(comment);
   var riskColor = getRiskRiskColor(comment);
   var riskAction = getRiskRiskAction(comment);
   var riskImpact = getRiskImpactColor(comment)
   var riskColorAverage = getRiskRiskColorAve(subAvarageRisk);
   var appetiteColorAverage = getAppetiteColorAve(subAvarageAppetite)
 
   /** Need */
   function DisplayStandared({ comment }) {
       console.log("+++++++++++++Athula+++++++comment++++++++++++++++++++++")
       console.log(comment)
       var objectiveId = '';
       var objectiveName = '';
       var componentId = '';
       var componentName = '';
       var principleId = '';
       var principleName = '';
       var standardId = '';
       var standardName = '';
       if (comment.riskStandardData != undefined && comment.riskStandardData != null) {
           if (comment.riskStandardData.objectiveData != undefined && comment.riskStandardData.objectiveData != null) {
               objectiveId = comment.riskStandardData.objectiveData.objectiveId;
               objectiveName = comment.riskStandardData.objectiveData.objectiveName;
               standardId = comment.riskStandardData.objectiveData.standardId;
               standardName = comment.riskStandardData.objectiveData.standardName;
           }
           if (comment.riskStandardData.componentData != undefined && comment.riskStandardData.componentData != null) {
               componentId = comment.riskStandardData.componentData.componentId;
               componentName = comment.riskStandardData.componentData.componentName;
               if (standardId === '') {
                   standardId = comment.riskStandardData.componentData.standardId;
               }
               if (standardName === '') {
                   standardName = comment.riskStandardData.componentData.standardName;
               }
           }
           if (comment.riskStandardData.principleData != undefined && comment.riskStandardData.principleData != null) {
               principleId = comment.riskStandardData.principleData.principleId;
               principleName = comment.riskStandardData.principleData.principleName;
               if (standardId === '') {
                   standardId = comment.riskStandardData.principleData.standardId;
               }
               if (standardName === '') {
                   standardName = comment.riskStandardData.principleData.standardName;
               }
           }
       }
       var altString = 'Standard Name : ' + standardName;
       if (objectiveName != '') {
           altString += '\nObjective Name : ' + objectiveName;
       }
       if (componentName != '') {
           altString += '\nComponent Name : ' + componentName;
       }
       if (principleName != '') {
           altString += '\nPrinciple Name : ' + principleName;
       }
       if (standardName.length > 10) {
           standardName = standardName.substring(0, 10) + '...';
       }
 
       console.log("++++++++++++++++++++++++++++altString+++++++++++++++++++++++");
       console.log(altString);
       console.log(standardName);
 
       return (
           <>
 
               {
                   <span title={`${altString}`} style={{ cursor: 'pointer' }}>&nbsp;{standardName}</span>
               }
 
           </>
       )
   }
   comment["riskColor"] = riskColor;
   comment["appetiteColor"] = appetiteColor;
 
   var createArr = pushToArray(comment);
 
 
 
 
 
   return (
       <>
           <Grid item xs={6}>
               {noDisplaySpacesCount === 0 ?
                   <></>
                   : noDisplaySpacesCount === 1 ?
                       <>&nbsp;&nbsp;&nbsp;&nbsp;</>
                       : noDisplaySpacesCount === 2 ?
                           <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                           : noDisplaySpacesCount === 3 ?
                               <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                               : noDisplaySpacesCount === 4 ?
                                   <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                   : noDisplaySpacesCount === 5 ?
                                       <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                       : noDisplaySpacesCount === 6 ?
                                           <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                           : noDisplaySpacesCount === 7 ?
                                               <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                               : noDisplaySpacesCount === 8 ?
                                                   <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                   : noDisplaySpacesCount === 9 ?
                                                       <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                       : noDisplaySpacesCount === 10 ?
                                                           <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                           : ''
               }
               <Button
                   style={{ width: '0px', height: '0px' }}
                   id={`btn` + comment.riskTypeTmpId}
                   onClick={() => setShow(!show)}
                   startIcon={show ? <ExpandMoreIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} /> : <ChevronRightIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} />}
               >
               </Button>
               {noDisplay}{') '}
 
               {comment.name}
 
               {/* {!editRiskHirachyData ?
                   comment.name
                   :
                   <TextField
                       size="small"
                       id={`txt` + comment.riskTypeTmpId}
                       label="Type/Edit Risk Name"
                       defaultValue={comment.name}
                       title={comment.name}
                       onChange={onTxtNameChageEvent}
                       style={{ height: '10px !important' }}
                   />
               } */}
           </Grid>
           <Grid item xs={1}>
               {!editRiskHirachyData ?
                   <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLikelihood}</p></div></span>
                   :
                   <TextField
                       size="small"
                       id={`txtRiskLikelihood` + comment.riskTypeTmpId}
                       label="Likelihood"
                       defaultValue={comment.riskLikelihood}
                       title={comment.riskLikelihood}
                       onChange={onTextFieldChangeLikelihood}
                       //onBlur={onTextFieldChangeLikelihood}
                       style={{ height: '10px !important' }}
                   />
               }
 
           </Grid>
           <Grid item xs={1}>
               {!editRiskHirachyData ?
                   <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskImpact}</p></div></span>
                   :
                   <TextField
                       size="small"
                       id={`txtRiskImpact` + comment.riskTypeTmpId}
                       label="Impact"
                       defaultValue={comment.riskImpact}
                       title={comment.riskImpact}
                       onChange={onTextFieldChangeImpact}
                       style={{ height: '10px !important' }}
                   />
               }
           </Grid>
           <Grid item xs={1}>
               <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLevel}</p></div></span>
           </Grid>
           <Grid item xs={1}>
               <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px' }}><p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '9px' }}>{riskAction}</p></div></span>
           </Grid>
           <Grid item xs={1}>
               {!editRiskHirachyData ?
                   <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${appetiteColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskAppetite}</p></div></span>
                   :
                   <TextField
                       size="small"
                       id={`txtRiskAppetite` + comment.riskTypeTmpId}
                       label="Appetite"
                       defaultValue={comment.riskAppetite}
                       title={comment.riskAppetite}
                       onChange={onTextFieldChangeAppetite}
                       style={{ height: '10px !important', backgroundColor: `${appetiteColor}`, width: '66px' }}
                   />
               }
           </Grid>
           <Grid item xs={1}>
               <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px' }}><p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '9px' }}>{appetiteAction}</p></div></span>
           </Grid>
 
           {/*riskDisplayStr*/}
 
           {
               show ?
                   <>
                       {nestedComments}
                   </>
                   : null
           }
       </>
 
   )
 }
 
 function getSubAvarageRisk(comment) {
   var subTotal = 0
   var avarage = 0;
   if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
       var len = 0;
       var subTotal = 0;
       comment.subRisks.forEach((item) => {
           subTotal = subTotal + parseInt(item.riskLevel);
           len = len + 1;
           if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
               item.subRisks.forEach((item1) => {
                   subTotal = subTotal + parseInt(item1.riskLevel);
                   len = len + 1;
                   if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                       item1.subRisks.forEach((item2) => {
                           subTotal = subTotal + parseInt(item2.riskLevel);
                           len = len + 1;
                           if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                               item2.subRisks.forEach((item3) => {
                                   subTotal = subTotal + parseInt(item3.riskLevel);
                                   len = len + 1;
                                   if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                       item3.subRisks.forEach((item4) => {
                                           subTotal = subTotal + parseInt(item4.riskLevel);
                                           len = len + 1;
                                           if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                               item4.subRisks.forEach((item5) => {
                                                   subTotal = subTotal + parseInt(item5.riskLevel);
                                                   len = len + 1;
                                                   if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                       item5.subRisks.forEach((item6) => {
                                                           subTotal = subTotal + parseInt(item6.riskLevel);
                                                           len = len + 1;
                                                           if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {
 
                                                           }
                                                       })
                                                   }
                                               })
                                           }
                                       })
                                   }
                               })
                           }
                       })
                   }
               })
           }
 
       })
       avarage = (subTotal / (len));
       //avarage = Math.round(avarage * 100)/100;
       avarage = Math.round(avarage);
   }
   return avarage;
 
 }
 
 function getSubAvarageAppetite(comment) {
   var subTotal = 0
   var avarage = 0;
   if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
       var len = 0;
       var subTotal = 0;
       comment.subRisks.forEach((item) => {
           subTotal = subTotal + parseInt(item.riskAppetite);
           len = len + 1;
           if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
               item.subRisks.forEach((item1) => {
                   subTotal = subTotal + parseInt(item1.riskAppetite);
                   len = len + 1;
                   if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                       item1.subRisks.forEach((item2) => {
                           subTotal = subTotal + parseInt(item2.riskAppetite);
                           len = len + 1;
                           if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                               item2.subRisks.forEach((item3) => {
                                   subTotal = subTotal + parseInt(item3.riskAppetite);
                                   len = len + 1;
                                   if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                       item3.subRisks.forEach((item4) => {
                                           subTotal = subTotal + parseInt(item4.riskAppetite);
                                           len = len + 1;
                                           if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                               item4.subRisks.forEach((item5) => {
                                                   subTotal = subTotal + parseInt(item5.riskAppetite);
                                                   len = len + 1;
                                                   if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                       item5.subRisks.forEach((item6) => {
                                                           subTotal = subTotal + parseInt(item6.riskAppetite);
                                                           len = len + 1;
                                                           if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {
 
                                                           }
                                                       })
                                                   }
                                               })
                                           }
                                       })
                                   }
                               })
                           }
                       })
                   }
               })
           }
 
       })
       avarage = (subTotal / (len));
       //avarage = Math.round(avarage * 100)/100;
       avarage = Math.round(avarage);
   }
   return avarage;
 
 }
 
 function getSubNoReg(comment, riskId) {
   var subNo = "0";
   registerTypeDataForEval.riskTypes.forEach((data) => {
       if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
           data.subRisks.forEach((item, index) => {
               if (item.riskTypeId === riskId) {
                   subNo = (index + 1) + "";
               }
 
           })
       }
   })
   if (subNo === "0") {
       registerTypeDataForEval.riskTypes.forEach((data) => {
           if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
               data.subRisks.forEach((item, index) => {
                   if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                       var tmpN0 = getSubRiskSubNo(item.subRisks, riskId, '');
                       if (tmpN0 != "0") {
                           subNo = (index + 1) + '.' + tmpN0;
                       } else {
                           registerTypeDataForEval.riskTypes.forEach((data1) => {
                               if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                   data1.subRisks.forEach((item1, index1) => {
                                       if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                           item1.subRisks.forEach((item2, index2) => {
                                               item2.subRisks.forEach((item3, index3) => {
                                                   if (item3.riskTypeId === riskId) {
                                                       subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1);
                                                   }
                                               })
                                           })
                                       }
                                   })
                               }
                           })
                           if (tmpN0 === "0") {
                               registerTypeDataForEval.riskTypes.forEach((data1) => {
                                   if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                       data1.subRisks.forEach((item1, index1) => {
                                           if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                               item1.subRisks.forEach((item2, index2) => {
                                                   item2.subRisks.forEach((item3, index3) => {
                                                       item3.subRisks.forEach((item4, index4) => {
                                                           if (item4.riskTypeId === riskId) {
                                                               subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1);
                                                           }
                                                       })
                                                   })
                                               })
                                           }
                                       })
                                   }
                               })
                               if (tmpN0 === "0") {
                                   registerTypeDataForEval.riskTypes.forEach((data1) => {
                                       if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                           data1.subRisks.forEach((item1, index1) => {
                                               if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                   item1.subRisks.forEach((item2, index2) => {
                                                       item2.subRisks.forEach((item3, index3) => {
                                                           item3.subRisks.forEach((item4, index4) => {
                                                               item4.subRisks.forEach((item5, index5) => {
                                                                   if (item5.riskTypeId === riskId) {
                                                                       subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1);
                                                                   }
                                                               })
                                                           })
                                                       })
                                                   })
                                               }
                                           })
                                       }
                                   })
                                   if (tmpN0 === "0") {
                                       registerTypeDataForEval.riskTypes.forEach((data1) => {
                                           if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                               data1.subRisks.forEach((item1, index1) => {
                                                   if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                       item1.subRisks.forEach((item2, index2) => {
                                                           item2.subRisks.forEach((item3, index3) => {
                                                               item3.subRisks.forEach((item4, index4) => {
                                                                   item4.subRisks.forEach((item5, index5) => {
                                                                       item5.subRisks.forEach((item6, index6) => {
                                                                           if (item6.riskTypeId === riskId) {
                                                                               subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1);
                                                                           }
                                                                       })
                                                                   })
                                                               })
                                                           })
                                                       })
                                                   }
                                               })
                                           }
                                       })
                                       if (tmpN0 === "0") {
                                           registerTypeDataForEval.riskTypes.forEach((data1) => {
                                               if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                   data1.subRisks.forEach((item1, index1) => {
                                                       if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                           item1.subRisks.forEach((item2, index2) => {
                                                               item2.subRisks.forEach((item3, index3) => {
                                                                   item3.subRisks.forEach((item4, index4) => {
                                                                       item4.subRisks.forEach((item5, index5) => {
                                                                           item5.subRisks.forEach((item6, index6) => {
                                                                               item6.subRisks.forEach((item7, index7) => {
                                                                                   if (item7.riskTypeId === riskId) {
                                                                                       subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1);
                                                                                   }
                                                                               })
                                                                           })
                                                                       })
                                                                   })
                                                               })
                                                           })
                                                       }
                                                   })
                                               }
                                           })
                                           if (tmpN0 === "0") {
                                               registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                   if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                       data1.subRisks.forEach((item1, index1) => {
                                                           if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                               item1.subRisks.forEach((item2, index2) => {
                                                                   item2.subRisks.forEach((item3, index3) => {
                                                                       item3.subRisks.forEach((item4, index4) => {
                                                                           item4.subRisks.forEach((item5, index5) => {
                                                                               item5.subRisks.forEach((item6, index6) => {
                                                                                   item6.subRisks.forEach((item7, index7) => {
                                                                                       item7.subRisks.forEach((item8, index8) => {
                                                                                           if (item8.riskTypeId === riskId) {
                                                                                               subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1);
                                                                                           }
                                                                                       })
                                                                                   })
                                                                               })
                                                                           })
                                                                       })
                                                                   })
                                                               })
                                                           }
                                                       })
                                                   }
                                               })
                                               if (tmpN0 === "0") {
                                                   registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                       if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                           data1.subRisks.forEach((item1, index1) => {
                                                               if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                   item1.subRisks.forEach((item2, index2) => {
                                                                       item2.subRisks.forEach((item3, index3) => {
                                                                           item3.subRisks.forEach((item4, index4) => {
                                                                               item4.subRisks.forEach((item5, index5) => {
                                                                                   item5.subRisks.forEach((item6, index6) => {
                                                                                       item6.subRisks.forEach((item7, index7) => {
                                                                                           item7.subRisks.forEach((item8, index8) => {
                                                                                               item8.subRisks.forEach((item9, index9) => {
                                                                                                   if (item9.riskTypeId === riskId) {
                                                                                                       subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1);
                                                                                                   }
                                                                                               })
                                                                                           })
                                                                                       })
                                                                                   })
                                                                               })
                                                                           })
                                                                       })
                                                                   })
                                                               }
                                                           })
                                                       }
                                                   })
                                                   if (tmpN0 === "0") {
                                                       registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                           if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                               data1.subRisks.forEach((item1, index1) => {
                                                                   if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                       item1.subRisks.forEach((item2, index2) => {
                                                                           item2.subRisks.forEach((item3, index3) => {
                                                                               item3.subRisks.forEach((item4, index4) => {
                                                                                   item4.subRisks.forEach((item5, index5) => {
                                                                                       item5.subRisks.forEach((item6, index6) => {
                                                                                           item6.subRisks.forEach((item7, index7) => {
                                                                                               item7.subRisks.forEach((item8, index8) => {
                                                                                                   item8.subRisks.forEach((item9, index9) => {
                                                                                                       item9.subRisks.forEach((item10, index10) => {
                                                                                                           if (item10.riskTypeId === riskId) {
                                                                                                               subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1) + "." + (index10 + 1);
                                                                                                           }
                                                                                                       })    
                                                                                                   })
                                                                                               })
                                                                                           })
                                                                                       })
                                                                                   })
                                                                               })
                                                                           })
                                                                       })
                                                                   }
                                                               })
                                                           }
                                                       })
 
                                                   }
 
                                               }
                                           }
                                       }
                                   }
                               }
                           }
 
 
                       }
 
                   }
 
               })
           }
       })
 
   }
   return subNo;
 }
 
 
 function PaperComponentCollectionDataRegister(props) {
   return (
       <Draggable1
           handle="#register-data-dialog-title"
           cancel={'[class*="MuiDialogContent-root"]'}
       >
           <Paper {...props} />
       </Draggable1>
   );
 }
 function setMinMaxWindowFunctionRegister() {
   if (!fullScreenState) {
       setFullScreenState(true);
       setFullScreenStateMsg('Full Screen Out');
   } else {
       setFullScreenState(false);
       setFullScreenStateMsg('Full Screen');
   }
 
 }
 
 function RegisterDataForEvalfunctionClose() {
   setOpenRegisterDataForEval(false)
   setDatabClr([]);
   setBarData([]);
   setCommitBarData([])
   setColorPatternPrimaryColorName('');
   setColorPatternSecondaryColorName('');
   setColorSelectDropDownRiskLevel()
   setColorSelectDropDownRiskAppetite()
   setApplyFlag(false)
   setShowEvalData(false)
   setShowTranferExlButton(false)
 }
 
 function applyFunctionRegister() {
   setColorSelectDropDownRiskLevel(colorSelectDropDownRiskLevelTmp);
   setColorSelectDropDownRiskAppetite(colorSelectDropDownRiskAppetiteTmp)
 
   setShowEvalData(true)
   setApplyFlag(true)
   setShowTableType(1)
   setShowTranferExlButton(true)
 
   setBtnHirachivalClass(classes.btnActive)
   setBtnGradientClass(classes.btnNormal)
   setBtnBarChartClass(classes.btnNormal)
 
 }
 
 function hierarchicalFunctionRegister() {
   setBtnHirachivalClass(classes.btnActive)
   setBtnGradientClass(classes.btnNormal)
   setBtnBarChartClass(classes.btnNormal)
 }
 
 
 function displayRiskAsendingRegister() {
   setShowEvalData(true)
 
   var arr = [];
   if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
       //arr = commitArray.sort(compareRiskTypeOrder);
       arr = commitArray
   } else {
       arr = commitArrayData;
   }
   setArrayForDisplayDataInTable(arr);
   setCommitArrayData(arr);
   setShowTableType(2)
 
   /*
   var arr = []
   allRegister.forEach((data) => {
       if (data.id === registerId) {
           data.riskRegister.forEach((data1) => {
               arr.push(data1)
               data1.risks.forEach((risk) => {
                   var obj = {}
                   obj["section"] = risk.name
                   obj["riskLevel"] = risk.riskLevel
                   obj["riskAppetite"] = risk.riskAppetite
 
                   arr.push(obj)
               })
           })
       }
   })
 
   allRegisterArr()
   setArrayForDisplayDataInTable(arr);
   setCommitArrayData(arr);
   setShowTableType(2)
   */
 
   setBtnHirachivalClass(classes.btnNormal)
   setBtnGradientClass(classes.btnActive)
   setBtnBarChartClass(classes.btnNormal)
 
 }
 
 function createBarFunctionRegister() {
 
   var arr = [];
   if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
       //arr = commitArray.sort(compareRiskTypeOrder);
       arr = commitArray
   } else {
       if (commitBarData != undefined && commitBarData != null && commitBarData.length > 0) {
           arr = commitBarData;
       } else {
           arr = commitArrayData;
       }
   }
 
   setShowEvalData(true)
   setShowTableType(3)
 
 
   if (arr.length <= 10) {
       setBarChartHeight(600)
   } else {
       if (arr.length > 10 && arr.length <= 17) {
           setBarChartHeight(800)
       } else {
           if (arr.length > 17 && arr.length <= 30) {
               setBarChartHeight(1000)
           } else {
               if (arr.length > 30 && arr.length <= 40) {
                   setBarChartHeight(1500)
               } else {
                   if (arr.length > 40 && arr.length <= 60) {
                       setBarChartHeight(2200)
                   } else {
                       setBarChartHeight(3500)
                   }
               }
           }
       }
   }
 
 
   var arr1 = [];
   var arrRiskColor = []
   var arrColorApp = []
 
   arr.forEach((data) => {
       var obj = {}
       var name = data.name;
       obj["argument"] = name;
       obj["riskLevel"] = parseInt(data.riskLevel);
       arrRiskColor.push(data.riskColor)
       obj["riskAppetite"] = parseInt(data.riskAppetite);
       obj["riskLevelStr"] = "Risk Level: " + data.riskLevel;      
       obj["riskAppetiteStr"] = "Risk Appetite: " + data.riskAppetite;
       arrColorApp.push(data.appetiteColor)
       arr1.push(obj)
   })
   setCommitBarData(arr);
   console.log(arr1)
   setBarData(arr1);
   setArrRiskColorState(arrRiskColor)
   setArrColorAppState(arrColorApp)
 
 
   setBtnHirachivalClass(classes.btnNormal)
   setBtnGradientClass(classes.btnNormal)
   setBtnBarChartClass(classes.btnActive)
 
 }
 
 
 function showDataInAViewFunction(no){
        
   setEditRiskHirachy(true);
   setWaitingOpen(true);
   setTimeout(() => {
       setWaitingOpen(false);
   }, 500)
   
 }




  return (
    <>
      <div>
        <Grid container spacing={2}>
          <Grid container spacing={2} className={classes.pageBodyStyleRiskReg}>
            {/** <Grid item    xs={(fromPageSelectedRegisteredId != ''?12:9)}> */}
            <Grid item className={fromPageSelectedRegisteredId != ''?'mainGridFromGpt':'mainGrid'}>
              <Card className='mainCard'>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                      <Info />
                    </Avatar>
                  }
                  className={classes.commonMainTableHeadEval}
                  title={!showRegister?'Create And Edit Risk Register':'Risk Register - ' + registerStatus }
                  sx={{ fontSize: '25px !important' }}
                />
                <CardContent>
                  <Grid container spacing={2}>
                    {/*
                    <Grid item xs={12}>
                        <span className='fontSize30'>This is a test</span>

                    </Grid>
                    */}

                    <Grid item xs={6}>
                      <Box component="form" sx={{ '& .MuiTextField-root': { m: 0, width: '100%' }, }} noValidate autoComplete="off">
                        <TextField
                          id="standardName"
                          label="Register Name"
                          multiline
                          maxRows={3}
                          //size='small'
                          value={registerName}
                          onChange={registerNameOnChange}
                        />

                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box component="form" sx={{ '& .MuiTextField-root': { m: 0, width: '100%' }, }} noValidate autoComplete="off">
                        <TextField
                          id="standardName"
                          label="Register Description"
                          multiline
                          maxRows={3}
                          // size='small'
                          value={registerDescription}
                          onChange={registerDescriptionOnChange}
                        />

                      </Box>
                    </Grid>

                    <Grid item xs={12}>

                      <Card className='subCard'>
                        <CardHeader
                          avatar={
                            <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                              <Info />
                            </Avatar>
                          }
                          className={classes.commonMainTableHeadEval}
                          title={!showRegister?'Create And Edit Section': 'Section'}
                          sx={{ fontSize: '25px !important' }}
                        />
                        <CardContent>
                          {!showRegister?
                          <>
                          <Grid container spacing={2}>
                            <Grid item className='sectionGrid1'>
                              <Box component="form" sx={{ '& .MuiTextField-root': { m: 0, width: '100%' }, }} noValidate autoComplete="off">
                                <div>
                                  <TextField
                                    id="CategoryName"
                                    label="Register's Section Name"
                                    value={categoryName}
                                    onChange={categoryNameOnChange}
                                  />
                                </div>
                              </Box>
                            </Grid>
                            <Grid item className='sectionGrid2'>
                              <Box component="form" sx={{ '& .MuiTextField-root': { m: 0, width: '100%' }, }} noValidate autoComplete="off">
                                <div>
                                  <TextField
                                    id="CategoryName"
                                    label="Ownership Role"
                                    value={categoryDescription}
                                    onChange={categoryDescriptionOnChange}
                                  />
                                </div>
                              </Box>
                            </Grid>

                            <Grid item className='sectionGrid3'>
                              <Box component="form" sx={{ '& .MuiTextField-root': { m: 0, width: '100%' }, }} noValidate autoComplete="off">

                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Select Ownership Role From Entity</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={entityName}
                                    label="Age"
                                    onChange={entityNameOnChange}
                                  >
                                    {
                                      allEntityData.map((data) => (
                                        <MenuItem value={data.entityName} >{data.entityName}</MenuItem>
                                      ))
                                    }
                                  </Select>
                                </FormControl>

                              </Box>
                            </Grid>

                            <Grid item className='sectionGrid4'>
                              <Autocomplete
                                value={intLikelihoodValue}
                                onChange={(event, intLikelihoodValue) => {
                                  if (intLikelihoodValue != null) {
                                    setIntLikelihoodValue(intLikelihoodValue);
                                  }
                                }}
                                inputValue={intLikelihoodValue}
                                onInputChange={(event) => {
                                  if (event != null && event.target.value != null) {
                                    if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                                      setIntLikelihoodValue('')
                                    } else {
                                      setIntLikelihoodValue(event.target.value);
                                    }
                                  }
                                }}
                                options={options}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Likelihood"
                                    variant="outlined"
                                  //onChange={handleChangeIntLikelihood}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item className='sectionGrid5'>
                              <Autocomplete
                                value={intImpactVales}
                                onChange={(event, newValue) => {
                                  if (newValue != null) {
                                    setIntImpactVales(newValue);
                                  }
                                }}
                                inputValue={intImpactVales}
                                onInputChange={(event, newInputValue) => {
                                  if (event != null && event.target.value != null) {
                                    if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                                      setIntImpactVales('')
                                    } else {
                                      setIntImpactVales(event.target.value);
                                    }
                                  }
                                }}
                                options={options}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id="txtImpact"
                                    label="Impact "
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item className='sectionGrid6'>
                              <TextField
                                id="outlined-basic"
                                label="Risk Level"
                                variant="outlined"
                                value={calculatedRiskLevel()}
                                inputProps={{
                                  style: {
                                    height: "22px",
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item className='sectionGrid7'>
                              <Autocomplete
                                value={intRiskAppetiteValue}
                                onChange={(event, newValue) => {
                                  if (newValue != null) {
                                    setIntRiskAppetiteValue(newValue);
                                  }
                                }}
                                inputValue={intRiskAppetiteValue}
                                onInputChange={(event, newInputValue) => {
                                  if (event != null && event.target.value != null) {
                                    if (event.target.value <= 0 || event.target.value > 10 || !Number.isInteger(parseInt(event.target.value))) {
                                      setIntRiskAppetiteValue('')
                                    } else {
                                      setIntRiskAppetiteValue(event.target.value);
                                    }
                                  }
                                }}
                                options={options1}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Risk Appetite "
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item className='sectionGrid8'>
                              <Button variant='outlined' style={{ float: 'right', height: '55px', minWidth: '55px' }} onClick={() => addToCategoryTable()}>{categoryButton}</Button>
                            </Grid>
                           </Grid>
                           </>
                           :
                           ''
                          }
                          <Grid container spacing={2}>



                            <Grid item xs={12}>
                              <div className='scrollableDiv'>
                                <TableContainer component={Paper} sx={{ maxHeight: 220 }}>
                                  <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 600 }} style={{ border: '1px solid #ddd' }}>
                                    <TableHead className='commonSecondaryTableHead'>
                                      <TableRow>
                                        <StyledTableCell className='commonTableThStikyStyle' style={{ width: '19%' }}>Section Name</StyledTableCell>
                                        <StyledTableCell className='commonTableThStikyStyle' style={{ width: '19%' }}>Role</StyledTableCell>
                                        <StyledTableCell className='commonTableThStikyStyle' style={{ width: '16%' }}>Risk Level</StyledTableCell>
                                        <StyledTableCell className='commonTableThStikyStyle' style={{ width: '15%' }}>Appetite</StyledTableCell>
                                        <StyledTableCell className='commonTableThStikyStyle' style={{ width: '18%' }}> </StyledTableCell>
                                        {!showRegister?
                                        <>
                                        <StyledTableCell align="right" className='commonTableThStikyStyle' style={{ width: '6%' }}>Edit</StyledTableCell>
                                        <StyledTableCell align="right" className='commonTableThStikyStyle' style={{ width: '6%' }}>Delete</StyledTableCell>
                                        </>
                                        :
                                        ''
                                        }
                                      </TableRow>
                                    </TableHead>
                                    {categoryTableRowData}
                                  </Table>
                                </TableContainer>
                              </div>
                            </Grid>
                            {!showRegister?
                            <Grid item xs={12}>
                              <Button 
                                onClick={()=> changeSectionPosition()} 
                                className='changePosBtn'
                                variant="outlined"
                                >
                                Change Register's Section Position 
                              </Button>
                            </Grid>
                            :
                            ''
                            }


                          </Grid>




                        </CardContent>

                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <div style={{ display: 'flex', gap: '10px', float: 'right', marginTop: '-7px' }}>
                        {registerId != '' && !showRegister?
                          <div>
                            <Button variant='contained' className='confBtns' onClick={() => finaliseRegisterFunction()}>Finalise Register</Button>
                          </div>
                        :
                          ''
                        }
                        {!showRegister?
                        <div>
                        <Button variant='contained' className='confBtns' onClick={() => saveRegister()}> {registerSaveBtn}</Button>
                        </div>
                        :
                        ''
                        }
                        {/*showRegister?
                        <div>
                          <Button variant='contained' style={{ float: 'right' }} onClick={() => openEvaluationfunction()}> {'Evaluate'}</Button>
                        </div>
                        :
                        ''
                      */}


                        {fromPageSelectedRegisteredId != ''?
                        ''
                        :            
                        <>
                        {showRegister ?
                          <div>
                            <Button variant='contained' className='confBtns' onClick={() => evaluateRiskFuction()}> Evaluate</Button>
                          </div>
                          :
                          ''
                        }

                        <div>
                          <Button variant='contained' className='confBtns' onClick={() => cancelFunction()}> Clear</Button>
                        </div>
                      </>
                        }
                        

                      </div>

                    </Grid>


                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {fromPageSelectedRegisteredId != ''?                      
            ''
            :
            <Grid item className='subGrid'>
              <Card style={{ "width": "100% !important", border: "1px solid black", height: "450px" }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: '#69676e', width: '25px', height: '25px' }}>
                      <Info />
                    </Avatar>
                  }
                  className={classes.commonMainTableHeadEval}
                  title={'Risk Registers Created'}
                  sx={{ fontSize: '25px !important' }}
                />
                <CardContent>

                  <Grid item xs={12}>
                    <div className={classes.scrollableDiv}>
                      <TableContainer
                        component={Paper}
                        sx={{ maxHeight: 375 }}
                      >
                        <Table aria-label="customized table" style={{ border: '1px solid #ddd' }}>
                          <TableHead className='commonSecondaryTableHead'>
                            <TableRow>
                              <StyledTableCell className={classes.commonTableThStyle} style={{ width: '70%' }}>Register</StyledTableCell>
                              <StyledTableCell align="right" className={classes.commonTableThStyle} style={{ width: '15%' }}>Edit/Show</StyledTableCell>
                              <StyledTableCell align="right" className={classes.commonTableThStyle} style={{ width: '15%' }}>Delete</StyledTableCell>
                              {/**<StyledTableCell align="right" className={classes.commonTableThStyle} style={{ width: '5%' }}>Delete</StyledTableCell>*/}
                            </TableRow>
                          </TableHead>
                          {registerTableRowData}
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>

                </CardContent>
              </Card>
            </Grid>
            }

            <Grid>

            </Grid>
          </Grid>
        </Grid>
      </div>


      <Dialog

        open={openCreateRisk}
        onClose={false}
        scroll={openCreateRiskScroll}
        aria-labelledby="create-risk-data-dialog-title"
        aria-describedby="create-risk-data-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
        //PaperComponent={PaperComponentCreateRisk}
      >
        <DialogTitle style={{ cursor: 'move' }} id="create-risk-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>{!showRegister?'Add or Create risk hierarchies': 'Risk hierarchies'} for "{selectedRiskEvaluateTitle}"</Typography>
          <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
            <Tooltip title="Close"><Close onClick={handleCloseRisk} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={openCreateRiskScroll === 'paper'}>
          <DialogContentText id="create-risk-data-text" tabIndex={-1}>
            {!showRegister?
            <>
            <Grid container spacing={2}>
              <Grid item className='riskGrid1'>
                {
                  riskDataFieldShow ?

                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Risk Hierarchy Here:</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedRiskHierarchy}
                          label="Age"
                          onChange={handleChange}

                        //style={{width:'300px'}}
                        >
                          <MenuItem value={10}>hierarchy 1</MenuItem>
                          <MenuItem value={20}>hierarchy 2</MenuItem>
                          <MenuItem value={30}>hierarchy 3</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    :

                    <Autocomplete
                      value={hierarchyValue}
                      freeSolo
                      options={globalRiskArray}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => { hierarchyOnchange(newValue)
                      }}
                      renderInput={(params) => (
                        <TextField {...params} 
                          label="Select a Hierarchy" 
                          variant="outlined" 
                          />
                      )}
                    />




                }
              </Grid>

              <Grid item className='riskGrid2'>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <div style={{marginTop: '16px'}}>
                    <p>OR</p>
                  </div>

                  <div>
                    <Button variant='outlined' style={{ height: '56px' }} onClick={() => createNewHierarchyFunction()}>Create New Risk Hierarchy</Button>
                  </div>

                </div>

              </Grid>



              <Grid item className='riskGrid3'>
                <TextField
                  id="RiskRegisterDescription"
                  label="Risk Hierarchy Description"
                  type="text"
                  multiline
                  maxRows={2}
                  style={{ width: '100%' }}
                  value={hierarchyDescription}
                  onChange={hierarchyDescriptionOnchange}
                />
              </Grid>

              <Grid item className='riskGrid5'>
                <Autocomplete
                  value={intLikelihoodValueDialog}
                  onChange={(event, intLikelihoodValue) => {
                    if (intLikelihoodValue != null) {
                      setIntLikelihoodValueDialog(intLikelihoodValue);
                    }
                  }}
                  inputValue={intLikelihoodValueDialog}
                  onInputChange={(event) => {
                    if (event != null && event.target.value != null) {
                      if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                        setIntLikelihoodValueDialog('')
                      } else {
                        setIntLikelihoodValueDialog(event.target.value);
                      }
                    }
                  }}
                  options={optionsDialog}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Likelihood"
                      variant="outlined"
                    //onChange={handleChangeIntLikelihood}
                    />
                  )}
                />
              </Grid>
              <Grid item className='riskGrid5'>
                <Autocomplete
                  value={intImpactValesDialog}
                  onChange={(event, newValue) => {
                    if (newValue != null) {
                      setIntImpactValesDialog(newValue);
                    }
                  }}
                  inputValue={intImpactValesDialog}
                  onInputChange={(event, newInputValueDialog) => {
                    if (event != null && event.target.value != null) {
                      if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                        setIntImpactValesDialog('')
                      } else {
                        setIntImpactValesDialog(event.target.value);
                      }
                    }
                  }}
                  options={options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="txtImpact"
                      label="Impact "
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item className='riskGrid5'>
                <TextField
                  id="outlined-basic"
                  label="Risk Level"
                  variant="outlined"
                  value={calculatedRiskLevelDialog()}
                  style={{width: '100%'}}
                  inputProps={{
                    style: {
                      height: "22px",
                    },
                  }}
                />
              </Grid>
              <Grid item className='riskGrid5'>
                <Autocomplete
                  value={intRiskAppetiteValueDialog}
                  onChange={(event, newValueDialog) => {
                    if (newValueDialog != null) {
                      setIntRiskAppetiteValueDialog(newValueDialog);
                    }
                  }}
                  inputValue={intRiskAppetiteValueDialog}
                  onInputChange={(event, newInputValueDialog) => {
                    if (event != null && event.target.value != null) {
                      if (event.target.value <= 0 || event.target.value > 10 || !Number.isInteger(parseInt(event.target.value))) {
                        setIntRiskAppetiteValueDialog('')
                      } else {
                        setIntRiskAppetiteValueDialog(event.target.value);
                      }
                    }
                  }}
                  options={options1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Risk Appetite "
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item className='riskGrid5'>
                <TextField
                  id="RiskRegisterDescriptionx"
                  label="Risk Hierarchy Risk Description"
                  type="text"
                  multiline
                  maxRows={2}
                  style={{ width: '100%' }}
                  value={hierarchyRiskDescription}
                  onChange={hierarchyRiskDescriptionOnchange}
                />
              </Grid>
              <Grid item className='riskGrid5'>
                <TextField
                  id="RiskRegisterDescriptiony"
                  label="Risk Hierarchy Impact Description"
                  type="text"
                  multiline
                  maxRows={2}
                  style={{ width: '100%' }}
                  value={hierarchyImpactDescription}
                  onChange={hierarchyImpactDescriptionOnchange}
                />
              </Grid>
              <Grid item className='riskGrid5'>
                <TextField
                  id="RiskRegisterDescription"
                  label="Risk Hierarchy Mitigation"
                  type="text"
                  multiline
                  maxRows={2}
                  style={{ width: '100%' }}
                  value={hierarchyMitigation}
                  onChange={hierarchyMitigationOnchange}
                />
              </Grid>

              <Grid item className='riskGrid5'>
                <Button variant='outlined' style={{ float: 'right', height: '56px' }} onClick={() => HierarchyDataToTable()}>{addHierarchyButton}</Button>
              </Grid>
             </Grid>
             </>
             :
             ''
            }

             <Grid container spacing={2}> 

              <Grid item xs={12}>
                <div className={classes.scrollableDiv}>
                  <TableContainer
                    component={Paper}
                    sx={{ maxHeight: 340 }}
                  >
                    <Table sx={{ minWidth: 400 }} stickyHeader aria-label="sticky table" style={{ border: '1px solid #ddd' }}>
                      <TableHead className='commonSecondaryTableHead'>
                        <TableRow>
                        <StyledTableCell className='commonTableThStikyStyle' style={{ width: '10%' }}>User Key</StyledTableCell>
                          <StyledTableCell className='commonTableThStikyStyle' style={{ width: '16%' }}>Risk Hierarchy </StyledTableCell>
                          <StyledTableCell className='commonTableThStikyStyle' style={{ width: '16%' }}>Risk Description</StyledTableCell>
                          <StyledTableCell align="center" className='commonTableThStikyStyle' style={{ width: '15%' }}>{!showRegister?'Show/Edit Heirarchy':'Show Heirarchy'}</StyledTableCell>
                          <StyledTableCell align="right" className='commonTableThStikyStyle' style={{ width: '8%' }}>Likelihood</StyledTableCell>
                          <StyledTableCell align="right" className='commonTableThStikyStyle' style={{ width: '8%' }}>Impact</StyledTableCell>
                          <StyledTableCell align="right" className='commonTableThStikyStyle' style={{ width: '8%' }}>Risk Level</StyledTableCell>
                          <StyledTableCell align="right" className='commonTableThStikyStyle' style={{ width: '7%' }}>Appetite</StyledTableCell>
                          {!showRegister?
                          <>
                          <StyledTableCell align="right" className='commonTableThStikyStyle' style={{ width: '6%' }}>Edit</StyledTableCell>
                          <StyledTableCell align="right" className='commonTableThStikyStyle' style={{ width: '6%' }}>Delete</StyledTableCell>
                          </>
                          :
                          ''
                          }
                        </TableRow>
                      </TableHead>
                      {RiskHieTableRowData}
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
              {!showRegister?
                <Grid item xs={12}>
                  <Button 
                    onClick={()=> changeRiskPosition()} 
                    style={{marginTop: '-10px',float:'right'}} 
                    variant="outlined"
                    >
                    Change Section Risk Hierarchies Position 
                  </Button>
                </Grid>
                :
                ''
              }


            </Grid>


          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRisk}>
            Close
          </Button>
        </DialogActions>
      </Dialog>



      {/** Athula Create New Risk Hierarchy */}
      <Dialog
        open={openCreateHierarchy}
        onClose={false}
        scroll={openCreateHierarchyScroll}
        aria-labelledby="create-hierarchy-data-dialog-title"
        aria-describedby="create-hierarchy-data-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
        PaperComponent={PaperComponentCreateHierarchy}
      >
        <DialogTitle style={{ cursor: 'move' }} id="create-hierarchy-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>Create New Risk Hierarchy</Typography>
          <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
            <Tooltip title="Close"><Close onClick={closeCreateHierarchyFunction} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={openCreateHierarchyScroll === 'paper'}>
          <DialogContentText id="create-hierarchy-data-text" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RiskHierarchyNew
                  updatedRiskHierarchyId={updatedRiskHierarchyId}
                  setUpdatedRiskHierarchyId={setUpdatedRiskHierarchyId}
                  intImpactValesfromRegister = {intImpactValesDialog}
                  intLikelihoodValuefromRegister = {intLikelihoodValueDialog}
                  intRiskAppetiteValuefromRegister = {intRiskAppetiteValueDialog}
                  selectedRiskHierarchyfromRegister = {selectedRiskHierarchy}
                  keyFromRegister={riskKey}
                  registerKey={registerKey}
                 
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreateHierarchyFunction}>Back To Risk Register</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openShowHierarchy}
        onClose={false}
        scroll={openShowHierarchyScroll}
        aria-labelledby="show-hierarchy-data-dialog-title"
        aria-describedby="show-hierarchy-data-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
        //PaperComponent={PaperComponentShowHierarchy}
      >
        <DialogTitle style={{ cursor: 'move' }} id="show-hierarchy-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>Risk Hierarchy - {showHierarchyName}</Typography>
          <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
            <Tooltip title="Close"><Close onClick={closeShowHierarchyFunction} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={openShowHierarchyScroll === 'paper'}>
          <DialogContentText id="show-hierarchy-data-text" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RiskHierarchyNew
                  riskHierarchyId={showHierarchyId}
                  //displayOnly={'OK'}
                  intImpactValesfromRegister={intImpactValesDialog}
                  intLikelihoodValuefromRegister={intLikelihoodValueDialog}
                  intRiskAppetiteValuefromRegister={intRiskAppetiteValueDialog}
                  selectedRiskHierarchyfromRegister={selectedRiskHierarchy}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeShowHierarchyFunction}>Back To Risk Register</Button>
        </DialogActions>
      </Dialog>

      
      <Dialog
        open={openKeyList}
        onClose={() => setOpenKeyList(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        <span style={{fontSize: '12px', fontWeight: 'bold'}}>
          This allocated user key ({registerKey}) is having several Risk Hierarchies. Do you want to select one of them and update OR create a new Risk Hierarchy for the {registerKey}?. <br></br>
          Press "Select an Existing" to choose one the Risk Hierarchies created earlier.<br></br>
          Press "Create A New Hierarchy" to create a new risk hierachy with the user key ({registerKey}). <br></br>
          Press "Cancel" to exit this window.
        </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
            <Grid item xs={12} >
                        <TextField
                            label='Select a Risk Hierarchy'
                            required
                            sx={{
                                width: '100%',
                                "& .MuiInputBase-root": {
                                    height: 45
                                },
                                "& .MuiInputLabel-root": {
                                    marginTop: -0.5
                                }
                            }}
                            select
                            value={selectedRiskId}
                            onChange={handleChangeSelectedRisk}
                        >
                            {hierarchiesWithSameKey.map((item) => (
                                <MenuItem
                                    value={item.riskId}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>

                    </Grid>
            </Grid>  
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={() => createANewHierarchy()} autoFocus>
            Create A New Hierarchy 
          </Button>
          <Button onClick={() => continueUpdating()} autoFocus>
            Select an Existing
          </Button>
          <Button onClick={() => setOpenKeyList(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/** Risk Hirarchy Show Only */}

      <Dialog
        open={openRiskDataForEval}
        onClose={false}
        scroll={openRiskDataForEvalScroll}
        aria-labelledby="collection-data-dialog-title"
        aria-describedby="collection-data-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
        fullScreen={false}
        PaperComponent={PaperComponentCollectionData}
        >
        <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
            <Typography variant="h6" style={{ fontSize: '25px !important' }}>Risk Hierarchy - "{selectedRiskEvaluateTitle}"
            </Typography>
            <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                <Tooltip title="Close"><Close onClick={setOpenRiskDataForEvalfunction} style={{ cursor: 'pointer' }} /></Tooltip>
            </Typography>
        </DialogTitle>
        <DialogContent dividers={openRiskDataForEvalScroll === 'paper'}>
          <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {Object.keys(riskTypeDataForEval).length > 0 &&
                  <>
                    <Grid item xs={12}>
                      <div>

                              <Grid container spacing={1}>
                                  <Grid item xs={8}>&nbsp;</Grid>
                                  <Grid item xs={1}><b><u>Likelihood</u></b></Grid>
                                  <Grid item xs={1}><b><u>Impact</u></b></Grid>
                                  <Grid item xs={1}><b><u>Risk Level</u></b></Grid>
                                  <Grid item xs={1}><b><u>Appetite</u></b></Grid>
                                  {
                                      riskTypeDataForEval.riskTypes.map((riskType, index) => {
                                          return (
                                              <Comment
                                                  key={riskType.riskTypeTmpId}
                                                  comment={riskType}
                                                  lastRiskTmpId={lastRiskTmpId}
                                                  riskNo={index + 1}
                                                  fromMainMenu={TurnedInOutlined}
                                              />

                                          )

                                      })


                                  }
                              </Grid>


                      </div>
                    </Grid>
                  </>
                }
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      <DialogActions>
          <Button onClick={setOpenRiskDataForEvalfunction}>Close</Button>
      </DialogActions>
    </Dialog>


    <Dialog
        open={openEvaluation}
        onClose={false}
        scroll={openEvaluationScroll}
        aria-labelledby="evaluation-data-dialog-title"
        aria-describedby="evaluation-data-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
        fullScreen={false}
        PaperComponent={PaperComponentEvaluation}
        >
        <DialogTitle style={{ cursor: 'move' }} id="evaluation-data-dialog-title">
            <Typography variant="h6" style={{ fontSize: '25px !important' }}>Evaluate
            </Typography>
            <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                <Tooltip title="Close"><Close onClick={setOpenEvaluationfunction} style={{ cursor: 'pointer' }} /></Tooltip>
            </Typography>
        </DialogTitle>
        <DialogContent dividers={openEvaluationScroll === 'paper'}>
          <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <EvaluateRisk 
                  fromMultiTaskin={fromMultiTaskin}
                  onlyRiskRegsiters={true}
                  />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      <DialogActions>
          <Button onClick={setOpenEvaluationfunction}>Close</Button>
      </DialogActions>
    </Dialog>

    <Dialog
        open={openRiskDataForEval}
        onClose={false}
        scroll={openRiskDataForEvalScroll}
        aria-labelledby="collection-data-dialog-title"
        aria-describedby="collection-data-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
        fullScreen={false}
        PaperComponent={PaperComponentCollectionData}
      >
        <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>Risk Hierarchy - "{selectedRiskEvaluateTitle}"
          </Typography>
          <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
            <Tooltip title="Close"><Close onClick={setOpenRiskDataForEvalfunction} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={openRiskDataForEvalScroll === 'paper'}>
          <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {Object.keys(riskTypeDataForEval).length > 0 &&
                  <>
                    <Grid item xs={12}>
                      <div>

                        <Grid container spacing={1}>
                          <Grid item xs={8}>&nbsp;</Grid>
                          <Grid item xs={1}><b><u>Likelihood</u></b></Grid>
                          <Grid item xs={1}><b><u>Impact</u></b></Grid>
                          <Grid item xs={1}><b><u>Risk Level</u></b></Grid>
                          <Grid item xs={1}><b><u>Appetite</u></b></Grid>
                          {
                            riskTypeDataForEval.riskTypes.map((riskType, index) => {
                              return (
                                <Comment
                                  key={riskType.riskTypeTmpId}
                                  comment={riskType}
                                  lastRiskTmpId={lastRiskTmpId}
                                  riskNo={index + 1}
                                  fromMainMenu={TurnedInOutlined}
                                />

                              )

                            })


                          }
                        </Grid>


                      </div>
                    </Grid>
                  </>
                }
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={setOpenRiskDataForEvalfunction}>Close</Button>
        </DialogActions>
      </Dialog>

      {/** Evaluate Register */}          
        <Dialog
          open={openRegisterDataForEval}
          onClose={false}
          scroll={openRegisterDataForEvalScroll}
          aria-labelledby="register-data-dialog-title"
          aria-describedby="register-data-dialog-description"
          fullWidth={true}
          maxWidth={maxWidthState}
          fullScreen={fullScreenState}
          PaperComponent={PaperComponentCollectionDataRegister}
          >
          <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
            <Typography variant="h6" style={{ fontSize: '25px !important' }}>Evaluate "{selectedRegisterEvaluateTitle}"</Typography>
            <Typography variant="h6" style={{ marginRight: '-22px', padding: '7px', marginTop: '-50px', float: 'right' }}>
              <Tooltip1 title="Close"><Close onClick={RegisterDataForEvalfunctionClose} style={{ cursor: 'pointer' }} /></Tooltip1>
            </Typography>
          </DialogTitle>
          <DialogContent dividers={openRegisterDataForEvalScroll === 'paper'}>
            <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>

                {
                  multipleRoles ? 

                  <EvaluateComponent1
                      colorPatterns={colorPatterns}
                      registerTypeDataForEval={registerTypeDataForEval}
                      openRegisterDataForEvalScroll={openRegisterDataForEvalScroll}
                      registerEvaluate={true}
                    /> 
                    :
                    <EvaluateComponent
                      colorPatterns={colorPatterns}
                      registerTypeDataForEval={registerTypeDataForEval}
                      openRegisterDataForEvalScroll={openRegisterDataForEvalScroll}
                      registerEvaluate={true}
                    /> 
                }
                     
                  </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>        
          <DialogActions>
            <Button onClick={RegisterDataForEvalfunctionClose}>Cancel</Button>
          </DialogActions>
        </Dialog>

   
   
   
   
            {/** Change Section Order */}                        
            <Dialog
              open={openSectionPosition}
              onClose={false}
              scroll={openSectionPositionScroll}
              aria-labelledby="section-data-dialog-title"
              aria-describedby="section-data-dialog-description"
              maxWidth={'xl'}
              //PaperComponent={PaperComponentSectionDialog}
              >
              <DialogTitle style={{ cursor: 'move' }} id="section-data-dialog-title">
                <Typography variant="h6" style={{fontSize:'25px !important'}}>
                  Change the Register's Sections Position for "{registerName}"
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card className={classes.stdCardShowAll}>
                      <CardHeader 
                        avatar={
                          <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                            <Info/>
                          </Avatar>
                        } 
                        className={classes.commonMainTableHeadEval}
                        title="Register's Section"
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <div className={classes.scrollableDivPosChange}>
                              {sectionTableRowData}
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div style={{float: 'right'}}>
                              <Button 
                                onClick={()=> confirmSectionNewPosAndEvaluateFunc()} 
                                variant="outlined"
                                >
                                save changes and Evaluate
                              </Button>&nbsp;&nbsp;
                              <Button 
                                onClick={()=> confirmSectionNewPosFunc()} 
                                variant="outlined"
                                >
                                confirm and save changes
                              </Button>
                            </div>
                          </Grid>  
                        </Grid>
                      </CardContent>
                      
                    </Card>
                  </Grid> 
                </Grid>
              </DialogContent>
              <DialogActions>
                 <Button 
                  onClick={()=> setOpenSectionPosition(false)} 
                  >
                  Back
                </Button>
            </DialogActions>
          </Dialog>


            {/** Change Section Risk  Order */}                        
            <Dialog
              open={openRiskPosition}
              onClose={false}
              scroll={openRiskPositionScroll}
              aria-labelledby="risk-data-dialog-title"
              aria-describedby="risk-data-dialog-description"
              maxWidth={'xl'}
              //PaperComponent={PaperComponentRiskDialog}
              >
              <DialogTitle style={{ cursor: 'move' }} id="risk-data-dialog-title">
                <Typography variant="h6" style={{fontSize:'25px !important'}}>
                  Change the Risk Hierarchies Position for "{selectedRiskEvaluateTitle}"
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card className={classes.stdCardShowAll}>
                      <CardHeader 
                        avatar={
                          <Avatar sx={{ bgcolor: '#69676e', width:'25px', height:'25px' }}>
                            <Info/>
                          </Avatar>
                        } 
                        className={classes.commonMainTableHeadEval}
                        title="Risk Hierarchies"
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <div className={classes.scrollableDivPosChange}>
                              {riskTableRowData}
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div style={{float: 'right'}}>
                              <Button 
                                onClick={()=> confirmRiskNewPosAndEvaluateFunc()} 
                                variant="outlined"
                                >
                                save changes and Evaluate
                              </Button>&nbsp;&nbsp;
                              <Button 
                                onClick={()=> confirmRiskNewPosFunc()} 
                                variant="outlined"
                                >
                                confirm and save changes
                              </Button>
                            </div>
                            
                          </Grid>  
                        </Grid>
                      </CardContent>
                      
                    </Card>
                  </Grid> 
                </Grid>
              </DialogContent>
              <DialogActions>
                 <Button 
                  onClick={()=> setOpenRiskPosition(false)} 
                  >
                  Back
                </Button>
            </DialogActions>
          </Dialog>


        {/** Finalise Register */}          
        <Dialog
          open={openRegisterFinalise}
          onClose={false}
          scroll={openRegisterFinaliseScroll}
          aria-labelledby="register-data-dialog-title"
          aria-describedby="register-data-dialog-description"
          fullWidth={true}
          maxWidth={maxWidthState}
          fullScreen={fullScreenState}
          //PaperComponent={PaperComponentCollectionDataRegister}
          >
          <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
            <Typography variant="h6" style={{ fontSize: '25px !important' }}>Finalise "{selectedRegisterFinaliseTitle}" - <SavePageAs/></Typography>
            <Typography variant="h6" style={{ marginRight: '-22px', padding: '7px', marginTop: '-50px', float: 'right' }}>
              <Tooltip1 title="Close"><Close onClick={registerFinaliseClose} style={{ cursor: 'pointer' }} /></Tooltip1>
            </Typography>
          </DialogTitle>
          <DialogContent dividers={openRegisterFinaliseScroll === 'paper'}>
            <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FinaliseRegisterComponent
                      colorPatterns={colorPatterns}
                      registerTypeData={selectedRegisterFinaliseObj}
                      registerTypeDataOrg={selectedRegisterFinaliseObj}
                      openRegisterDataForEvalScroll={openRegisterFinaliseScroll}
                      registerEvaluate={true}
                      registerTypeDataForEvalOrg={selectedRegisterFinaliseObjOrg}
                      createdUserId={globalUserId}
                    />  
                  </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>        
          <DialogActions>
            <Button onClick={registerFinaliseClose}>Close</Button>
          </DialogActions>
        </Dialog>









   
   {/** Dialog Waiting */}
        <Dialog
                maxWidth={'md'}
                open={waitingOpen}
                PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
            >
                <CircularProgress disableShrink />
        </Dialog>

    </>
  )
}

export default RiskRegisterComponent
