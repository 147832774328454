import React, { useEffect, ReactDOM, useState } from 'react'
import { Grid, makeStyles, Tooltip } from "@material-ui/core";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import { entityData, standards } from '../data';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Links from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import { useSearchParams } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import { CONFIG } from '../config'
import { standardDataJs } from '../dataB';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { AssessmentOutlined, AssessmentRounded, Close, Info } from '@mui/icons-material';
import { Paper } from '@mui/material';
import Draggable from 'react-draggable';
import { width } from '@mui/system';
import Divider from '@mui/material/Divider';
import { CardActionArea, CardActions } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import RiskTypeQustionDisplay from '../Components/RiskTypeQustionDisplay';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import CircularProgress from '@mui/material/CircularProgress';
import RightBarDataForUserPages from '../Components/RightBarDataForUserPages';
import useStyle from '../Css/MainCss';
import AddQustionCloseEnded from '../Components/AddQustionCloseEnded';
import AddQustionOpenEnded from '../Components/AddQustionOpenEnded';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

import '../Css/EvaluateRisk.css'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageBtn: {
    height: '80px',
    width: '180px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '12px !important',
  },
  globalBtn: {
    height: '80px',
    width: '180px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '12px !important',
  },
  globalBtnAfter: {
    height: '80px',
    width: '180px',
    backgroundColor: '#d6c3fc !important',
    color: 'black !important',
    borderRadius: '12px !important',
  },
  pageBtnRisk: {
    height: '30px',
    width: '210px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '5px !important',
  },
  cardBodyH6: {
    fontSize: '14px !important',
  },
  headerLable: {
    color: '#827d7d',
    marginLeft: '25px',
  },
  pageTop: {
    marginTop: '115px',
  },
  pageBodyStyle: {
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '7px',
    backgroundColor: '#f7f7f7',
    marginLeft: '-11px',
  },
  mainbtn: {
    justifyContent: 'center'
  },
  qusMainCardHeader: {
    backgroundColor: '#d6c3fc !important',
    fontSize: '13px !important',
    height: '44px',
    padding: '0px',
    marginTop: '-7px',
  },
  qusCardHeader: {
    backgroundColor: '#7B9DE0 !important',
    fontSize: '13px !important',
    height: '44px',
    padding: '0px',
    marginTop: '-7px',
  },
  popupdiv: {
    marginLeft: '15%',
    //marginTop:'-50%',
    border: '2px solid black',
    width: '700px',
    backgroundColor: '#f6f5fc !important',
    boxShadowhadow: '5px 10px #888888',
    resize: 'horizontal',
    overflow: 'auto',
    height: '100%',


  },
  cardcencel: {
    // alignItems:'right !important',
    //  marginLeft:'100px !important'
    float: 'right !important'
  },
  stdButtonsStyle: {
    fontSize: '0.70rem !important',
    height: '50px !important',
    //paddingRight: '7px !important',
    //paddingLeft: '5px !important',
  },
  cardClose: {
    float: 'right !important'
  },
  topHeaderText1Move: {
    color: '#ffffff',
    fontSize: '20px',
    paddingBottom: '10px',
    textAlign: 'center'
  },
  topHeaderText2Move: {
    color: '#ffffff',
    fontSize: '12px',
    paddingBottom: '10px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  cardDivMove: {
    border: '2px solid #7a769b',
    backgroundColor: '#696873 !important',
    boxShadowhadow: '5px 10px #69676e',
    borderRadius: '5px !important',
    BorderStyle: 'rigid'
  },
  cardTitleMove: {
    fontSize: '15px !important',
    textAlign: 'center',
    fontWeight: '500',
    paddingBottom: '10px',
    color: 'white'
  },
  leftPageNumberStyle: {
    marginTop: '-91px !important',
    marginLeft: '-17px !important',
    padding: '0px !important',
    backgroundColor: 'black !important',
    fontSize: '15px !important',
    maxWidth: '26px !important',
    maxHeight: '26px !important',
    minWidth: '26px !important',
    minHeight: '26px !important'
  },
  rightPageNumberStyle: {
    float: 'right !important',
    marginTop: '-49px !important',
    marginRight: '-16px !important',
    padding: '0px !important',
    backgroundColor: 'black !important',
    fontSize: '15px !important',
    maxWidth: '26px !important',
    maxHeight: '26px !important',
    minWidth: '26px !important',
    minHeight: '26px !important'
  },
  mutimenuPageHeadingStyle: {
    fontSize: '14px !important',
    fontWeight: 'normal !important',
    marginTop: '-68px !important',
    color: '#b60707',
    marginLeft: '12px !important'
  },
  stdCard: {
    height: '100%',
    border: '1px solid black'
  },
  stdMainCardHeader: {
    backgroundColor: '#acacbe !important',
    fontSize: '13px !important',
    height: '50px',
    padding: '0px',
    marginTop: '-7px',
  },
  scrollableDivToStandard: {
    height: '400px',
    overflow: 'auto',
    width: '100%',
  },
  tableHeadStyle: {
    backgroundColor: '#d5d5e1',
    color: '#ffffff',
  },
  tableThStyle: {
    lineHeight: '0.25rem !important'
  },
  tableTrStyle: {
    //lineHeight: '0.20rem !important',
    height: '19px !important'
  },
  mousePointer: {
    cursor: 'pointer'
  },


}));


function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const EvaluateRiskTemplateQuestionComponent = ({ centreId, prevAddedQuestions }) => {

  const [maxWidthTable, setMaxWidthTable] = React.useState(400);


  useEffect (() => {
    console.log("++++++++++++window. screen. width+++++++++++++");
    if (window. screen. width <= 480){
          setMaxWidthTable(150)
    }else{
      if (window. screen. width <= 810 && window. screen. width >= 481){
          setMaxWidthTable(200)

      }
    }
    console.log(window. screen. width);
  },[])


  const classes = useStyles();
  const classess = useStyle();

  const [waitingOpen, setWaitingOpen] = React.useState(false);

  const previousRiskTableRowData = (
    <TableBody>
      {prevAddedQuestions.map((item) => {

        // if (item.centreId != "globle" && item.centreId === globalCentreId){
        return (
          <StyledTableRow key={item.riskId} className={classes.tableTrStyle}>
            <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} >
              <div style={{ whiteSpace: 'normal' }} className='celltxt'>{item.question}</div>
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={classes.tableTrStyle}
            >
              <AssessmentOutlinedIcon
                
              />
            </StyledTableCell>
          </StyledTableRow>
        )
        //  }
      })}
    </TableBody>

  )



  return (
    <>
      <div>
        <Grid container spacing={2}>


          <Grid item xs={12}>

            <TableContainer
              component={Paper}
              style={{maxHeight:maxWidthTable}}

            >
              <TableHead className={classess.commonSecondaryTableHead}>
                <TableRow>
                  <StyledTableCell className='celltxthead' style={{ width: '90%' }}>Questionnaire</StyledTableCell>
                  <StyledTableCell align="right" className='celltxthead' style={{ width: '10%' }}>Evaluate</StyledTableCell>

                </TableRow>
              </TableHead>
              <Table aria-label="customized table" style={{ border: '1px solid #ddd' }}>
                {previousRiskTableRowData}
              </Table>
            </TableContainer>

          </Grid>

        </Grid>
      </div>


      {/** Dialog Waiting */}
      <Dialog
        maxWidth={'md'}
        open={waitingOpen}
        PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
      >
        <CircularProgress disableShrink />
      </Dialog>

    </>




  )
}

export default EvaluateRiskTemplateQuestionComponent