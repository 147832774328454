import React, { useEffect, useState } from 'react'
import { FormGroup, Grid, makeStyles, TableFooter } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { CONFIG} from '../config'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Draggable from 'react-draggable'; 
import { Close, SendOutlined } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import userCentreEntitiesPageStyle from '../Css/UserUploadUsersStyle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircularProgress from '@mui/material/CircularProgress';
import RightBarDataForUserPages from '../Components/RightBarDataForUserPages';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Info } from '@mui/icons-material';
import Stack from '@mui/material/Stack';



function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const UserBulkUpload = () => {
  
  const [waitingOpen, setWaitingOpen] = React.useState(false);
  
    
  const classes = userCentreEntitiesPageStyle();
    const Input = styled('input')({
      display: 'none',
    });



  React.useEffect(() => {
    getData();
  }, []);

  const [globalCentreId, setGlobalCentreId] = React.useState('');
  const [globalUserId, setGlobalUserId] = React.useState('');
  const [bulkUploadPath, setBulkUploadPath] = React.useState(CONFIG.BULK_UPLOAD_PATH);
  function getData () {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGlobalCentreId(data.centreId); 
    setGlobalUserId(data.userId);
    getCentreData(data.centreId);
    console.log(data)
  }

  const [centreName, setCentreName] = React.useState('');
  function getCentreData(centreId){
    var data = '{getCentreData(centreId:\"' + centreId + '\"){centreId,centreName}}';
    axios(CONFIG.MAIN_URL+'/graphql',{
    method: 'post',
    headers: { 
        'Content-Type': 'text/plain',
      },
    data : data,  
    })
    .then(function (response) {
        console.log("++++++++++++++++Return centreData++++++++++++++++");
        var centreData = response.data.data.getCentreData;
        console.log(centreData);
        setCentreName(centreData.centreName);

    })
    .catch(function (error) {
        console.log("Centre Data Loding error" + error);
    });
  }

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }
  const [uploadErrorOpen, setUploadErrorOpen] = React.useState(false);
  const [uploadErrorList, setUploadErrorList] = React.useState([]);
  const [uploadErrorScroll, setUploadErrorScroll] = React.useState('paper');

  const uploadErrorHandleClickClose = () => {
    setUploadErrorOpen(false);
  };










  const [selectedFile, setSelectedFile] = useState(null);

  function saveUsers(e){
    if (selectedFile === null) {
      alert('Please select Excel file to Upload users');
      return;
    }
    var fileformat=selectedFile.name.split('.').pop().toLowerCase();	
	  if(fileformat != "xlsx"){
      alert('Invalid File Format. Please do not change .xlsx file format');
      return;
	  }	
	  if(selectedFile.name != "UploadFile.xlsx"){
      alert('Invalid File Name. Please do not change file name');
      return;
	  }
    
    e.preventDefault();
    let formdata = new FormData();
    formdata.append('file',selectedFile);
    formdata.append('selectedSchoolId',globalCentreId);

    var ans = document.getElementById("passAskTrue").checked;
    if (ans){
      formdata.append('passAsk',true);
    }else{
      formdata.append('passAsk',false);
    }

    console.log(formdata);
    console.log(selectedFile);
    setWaitingOpen(true);

    
    axios(CONFIG.SERVICES_SYSTEM_URL+'/user/userBulkImport',{
      method: 'post',
      contentType: false,
      data: formdata,
    })
    .then(function (response) {
      setWaitingOpen(false);
      console.log("Return Updating Bulk Data");
      console.log(response);
      if (response.data === "Success") {
				alert("Upload Success");
				return;
			}
			var allObj = response.data;		
      console.log(allObj);	
			if (allObj.Error != null) {
					var errorObj = allObj.Error;					
						alert(errorObj.title);	
						return;
				} else {
          var mainArr = [];
          var alreadyUserObj = JSON.parse(allObj.alreadyUserList);
          var duplicateUserObj = JSON.parse(allObj.duplicateUserList);
          var duplicateMobiNumObj = JSON.parse(allObj.duplicateMobiNumList);
          var alreadyMobiListObj = JSON.parse(allObj.alreadyMobiList);
          if (alreadyUserObj != null && alreadyUserObj.length > 0) {
            var obj = {};
            obj["tableName"] = "Username Exists";
            var objFieldHeaders = {};
            objFieldHeaders["header1"] = "First Name";
            objFieldHeaders["header2"] = "UserName";
            obj["tableFieldHeaders"] = objFieldHeaders;
            var arrFieldData = [];
            alreadyUserObj.forEach((data)=>{
              var objFieldData = {};
              objFieldData["value1"] = data.name;
              objFieldData["value2"] = data.userId;
              arrFieldData.push(objFieldData);
            });
            obj["tableFieldData"] = arrFieldData;
            mainArr.push(obj);
				}else{
          var obj = {};
          obj["tableName"] = "Username Exists";
          var objFieldHeaders = {};
          objFieldHeaders["header1"] = "First Name";
          objFieldHeaders["header2"] = "UserName";
          obj["tableFieldHeaders"] = objFieldHeaders;
          var arrFieldData = [];
          obj["tableFieldData"] = arrFieldData;
          mainArr.push(obj);
        }

				if (duplicateUserObj != null && duplicateUserObj.length > 0) {
					var obj = {};
          obj["tableName"] = "Duplicate UserName";
          var objFieldHeaders = {};
          objFieldHeaders["header1"] = "First Name";
          objFieldHeaders["header2"] = "UserName";
          obj["tableFieldHeaders"] = objFieldHeaders;
          var arrFieldData = [];
          duplicateUserObj.forEach((data)=>{
            var objFieldData = {};
            objFieldData["value1"] = data.name;
            objFieldData["value2"] = data.userId;
            arrFieldData.push(objFieldData);
          });
          obj["tableFieldData"] = arrFieldData;
          mainArr.push(obj);
				}else{
          var obj = {};
          obj["tableName"] = "Duplicate UserName";
          var objFieldHeaders = {};
          objFieldHeaders["header1"] = "First Name";
          objFieldHeaders["header2"] = "UserName";
          obj["tableFieldHeaders"] = objFieldHeaders;
          var arrFieldData = [];
          obj["tableFieldData"] = arrFieldData;
          mainArr.push(obj);
        }

				if (duplicateMobiNumObj != null && duplicateMobiNumObj.length > 0) {
          var obj = {};
          obj["tableName"] = "Duplicate Mobile";
          var objFieldHeaders = {};
          objFieldHeaders["header1"] = "First Name";
          objFieldHeaders["header2"] = "Mobile Number";
          obj["tableFieldHeaders"] = objFieldHeaders;
          var arrFieldData = [];
          duplicateUserObj.forEach((data)=>{
            var objFieldData = {};
            objFieldData["value1"] = data.name;
            objFieldData["value2"] = data.mobileNumber;
            arrFieldData.push(objFieldData);
          });
          obj["tableFieldData"] = arrFieldData;
          mainArr.push(obj);
				}else{
          var obj = {};
          obj["tableName"] = "Duplicate Mobile";
          var objFieldHeaders = {};
          objFieldHeaders["header1"] = "First Name";
          objFieldHeaders["header2"] = "Mobile Number";
          obj["tableFieldHeaders"] = objFieldHeaders;
          var arrFieldData = [];
          obj["tableFieldData"] = arrFieldData;
          mainArr.push(obj);
        }
				if (alreadyMobiListObj != null && alreadyMobiListObj.length > 0) {
					var obj = {};
          obj["tableName"] = "Mobile Already Used";
          var objFieldHeaders = {};
          objFieldHeaders["header1"] = "First Name";
          objFieldHeaders["header2"] = "Mobile Number";
          obj["tableFieldHeaders"] = objFieldHeaders;
          var arrFieldData = [];
          duplicateUserObj.forEach((data)=>{
            var objFieldData = {};
            objFieldData["value1"] = data.name;
            objFieldData["value2"] = data.mobileNumber;
            arrFieldData.push(objFieldData);
          });
          obj["tableFieldData"] = arrFieldData;
          mainArr.push(obj);
				}else{
          var obj = {};
          obj["tableName"] = "Mobile Already Used";
          var objFieldHeaders = {};
          objFieldHeaders["header1"] = "First Name";
          objFieldHeaders["header2"] = "Mobile Number";
          obj["tableFieldHeaders"] = objFieldHeaders;
          var arrFieldData = [];
          obj["tableFieldData"] = arrFieldData;
          mainArr.push(obj);
        }
        setUploadErrorList(mainArr)
        setUploadErrorOpen(true);
        setUploadErrorScroll('paper');
			}
    })
    .catch(function (error) {
      setWaitingOpen(false);
      alert(error);
      console.log(error);
    })
    






    



  }




  /** End of For Centre Entities */






  

const [headerInfoMessage, setheaderInfoMessage] = useState("Assign your Centre Entities based on Basic Entities provided by the System Administrator");
const [headerInfoMessage1, setheaderInfoMessage1] = useState("Previous Centre Entities");
const [PageName, setPageName] = useState('Bulk Upload');
  return (
    <>
        <RightBarDataForUserPages PageName={PageName} CentreName={centreName}/>
        <div className={classes.pageTop}>
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.pageBodyStyle}>
                    <Grid item xs={12}>
                        <div role="presentation" onClick={handleClick}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" to="/login">
                            Home
                            </Link>
                            <Typography color="text.primary">Bulk Upload</Typography>
                        </Breadcrumbs>
                        </div>
                    </Grid>
                    
                    <Grid item xs={12}>
                      <Card id='focusCardId' style={{"width": "100% !important", border:"1px solid black"}}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6" gutterBottom component="div" style={{color: '#2f3038', fontWeight: '300'}}>
                                  Download the Sample Excel.&nbsp;<a href={bulkUploadPath} style={{fontSize: '18px'}}>here</a>
                              </Typography>
                              <Typography variant="h6" gutterBottom component="div" style={{color: '#2f3038', fontWeight: '300'}}>
                                  Fill the details of the registration form fields in the respective columns.Make sure the UserName And MobileNumber mandatory fields are filled.
                              </Typography>
                              <Typography variant="h6" gutterBottom component="div" style={{color: '#2f3038', fontWeight: '300'}}>
                                  Upload the edited excel back in .xlsx format and do not change file name.
                              </Typography>
                              <Typography variant="h6" gutterBottom component="div" style={{color: '#2f3038', fontWeight: '300'}}>
                                  Please do not use any special characters or spaces in your username.
                              </Typography>
                              <Typography variant="h6" gutterBottom component="div" style={{color: '#2f3038', fontWeight: '300'}}>
                                  Your Password must be minimum length of 6 characters, consisting of three of the followings,
                                  <br></br>
                                  - Include both lowercase(a-z) and uppercase(A-Z) characters.
                                  <br></br>
                                  - Any numeric characters(0-9).
                                  <br></br>
                                  - Any special characters ('!"#$%'()*+,-./:;?@[]^_~). 
                                  <br></br><br></br>
                              </Typography>
                              <Typography variant="h6" gutterBottom component="div" style={{color: '#2f3038', fontWeight: '300'}}>
                                <input type="checkbox" id="passAskTrue" name="passAskTrue"
                                /> Ask Password By Email<br></br><br></br>
                              </Typography>  

                              <Typography variant="h6" gutterBottom component="div" style={{color: '#2f3038', fontWeight: '300'}}>
                                <label htmlFor="contained-button-file">
                                  <input id="file" type="file" name="file" accept=".xls,.xlsx" 
                                    onChange={(event) => {
                                      setSelectedFile(event.target.files[0]);
                                    }}
                                  />
                                  <input type="button" id="userBulkBtn" onClick={e => saveUsers(e)} value="Upload file" />
                                </label>
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card> 
                    </Grid>    
                </Grid>
            </Grid>  
        </div>



{/************************ */}

<Dialog
    maxWidth='xl'
    fullWidth={true}
    open={uploadErrorOpen}
          onClose={uploadErrorHandleClickClose}
          scroll={uploadErrorScroll}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Typography variant="h6" gutterBottom component="div">
                  Upload Not Complete
                </Typography>
          </DialogTitle>
          <DialogContent dividers={uploadErrorScroll === 'paper'}>
            <DialogContentText
              id="risk-eval-type-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  Please Fix below issues and Upload again
                </Grid>  
                {uploadErrorList.map((data) => {
                  return (
                    <>
                    <Grid item xs={3}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 200 }} >
                          <TableHead className={classes.tableAnsConfirmHeadStyle}>
                            <TableRow>
                              <StyledTableCell colSpan={2} align="center" className={classes.tableAnsConfrnThStyle} >{data.tableName}</StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell className={classes.tableAnsConfrnThStyle}>{data.tableFieldHeaders.header1}</StyledTableCell>
                              <StyledTableCell className={classes.tableAnsConfrnThStyle}>{data.tableFieldHeaders.header2}</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                           {data.tableFieldData.map((item, index) => {
                              return (
                                <>
                                  <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyleEmpty}>
                                      {item.value1}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyleEmpty}>
                                      {item.value2}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </>
                              )
                            })}
                          </TableBody>          
                        </Table>
                      </TableContainer>        

                    </Grid>
                    
                    </>
                  )
                })}    

              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={uploadErrorHandleClickClose}>Close</Button>
          </DialogActions>
        </Dialog>
      {/** End of Question Details Show Dialog*/}












  {/** Dialog Waiting */}
  <Dialog
    maxWidth={'md'}
    open={waitingOpen}
    PaperProps={{style:{backgroundColor: 'transparent', boxShadow: 'none'}}} 
    >
    <CircularProgress disableShrink />
  </Dialog>










    </>
  )
}

export default UserBulkUpload