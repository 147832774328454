import React, { useState } from 'react';
import { Button, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/DMGlogo.png';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { sendAuthorizationRequest } from '../actions/sign-in';
import '../Css/Header.css';
import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import '../Css/RightBarDataForOtherPages.css';
import {CONFIG} from '../config';
import axios from 'axios';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft:'25px'
  },
  logoImg: {
    width: '100px',
    height: '50px',
    
    
  },
  navList: {
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    gap:'20px',
    marginTop:'10px',
    color: '#9155FD',
    
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
      fontSize:'5px',
      gap:'1px',
      color: '#9155FD',
    },
  },
  navItem: {
    [theme.breakpoints.down('sm')]: {
      margin: '1px',
      color: '#9155FD',

    },
  },
  navLink: {
    textDecoration: 'none',
    color: '#9155FD',

  },

  '@media only screen and (min-width: 320px) and (max-width: 480px)': {
    navLink: {
      fontSize: '7px',
    },
    loginSpan: {
      fontSize: '8px',
    },
    loginBtn: {
      width:'5px  !important'
    }
  },

  '@media only screen and (min-width: 481px) and (max-width: 810px)': {
    navLink: {
      fontSize: '12px',
    },
  },
  loginBtn: {
      background:'linear-gradient(270deg, #9155FD 0%, #C6A7FE 100%)',
      color:'#fff',

  },
  loginSpan: {
    [theme.breakpoints.down('sm')]: {
      //display: 'none',
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [drawerWidth, setDrawerWidth] = React.useState(240);


  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));

  React.useEffect(() => {
    getHeaderData();
  }, [])



  const [headerLogoUrl, setHeaderLogoUrl] = useState('');
  const [headerSystemName, setHeaderSystemName] = useState('');
  const [headerBackGroundColor, setHeaderBackGroundColor] = useState('white');
  
  function getHeaderData(){
    axios(CONFIG.EXPERT_SERVER_URL + `/pageHeaderContent/all`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
    .then(function (response) {
      console.log("++++++++++++++++++++++++Header Details+++++++++++++++++++");
      console.log(response.data);
      response.data.forEach((item,index) => {
        if (item.centreId === "superadmin") {
          if (item.logoUrl != undefined && item.logoUrl != null) {
            setHeaderLogoUrl(item.logoUrl);
          }  
          if (item.systemName != undefined && item.systemName != null) {
            setHeaderSystemName(item.systemName);
          }  
          if (item.backgroundColor != undefined && item.backgroundColor != null) {
            setHeaderBackGroundColor(item.backgroundColor);
          }  
        }
      })
    })
    .catch(function (error) {
      console.log(error);
    });
  }


  function handlePricingBtnClick() {
    let a = document.createElement('a');
    a.target = '_self';
    a.href = '/pricingsp';
    a.click();
  }

  

  const handleLoginBtnClick = (volunterFlag) => {
    sendAuthorizationRequest(volunterFlag);
  };

  return (
    <div>
        
      <Box sx={{ display: 'flex'}}>
        <CssBaseline />
      <AppBar position="fixed" open={open} style={{backgroundColor:`${headerBackGroundColor}`, height:'100px' }}>
        <Toolbar style={{display:'flex',justifyContent:'space-between', color:'black'}}>
            <div>
              {headerLogoUrl != ''?
                <>
                  <img src={headerLogoUrl} style={{width: '100px', height: '80px', marginTop: '10px'}} />
                </>
              :
                <>
                  <img src={logo} alt="logo" style={{width: '100px', height: '80px', marginTop: '10px'}} />
                </>
              }
            </div>
            {/** 
            <div style={{textAlign:'center',marginTop:'10px'}}>
              <span className='dataManagementGroupText'  >
                &nbsp;
              </span>
              <br></br>
              <span className='pagenameText'   style={{fontFamily:'cursive'}} >
                &nbsp;
              </span>
              <br></br>
              

            </div>
            */}
            <div style={{textAlign:'center',marginTop:'10px'}}>
              <span className='aboutusLines'>
              <ul className={classes.navList}>
            <li className={classes.navItem}>
              <a href="/" className={classes.navLink}>
                HOME
              </a>
            </li>
            <li className={classes.navItem}>
              <nobr><a href="/aboutus" className={classes.navLink}>
                ABOUT US
              </a></nobr>
            </li>
            <li className={classes.navItem}>
              <nobr><a href="/ourservices" className={classes.navLink}>
                OUR SERVICES
              </a></nobr>
            </li>
            <li className={classes.navItem}>
              <a href="/pricingsp" className={classes.navLink}>
                PRICING
              </a>
            </li>
            <li className={classes.navItem}>
              <a href="/blog" className={classes.navLink}>
                BLOG
              </a>
            </li>
            <li>
              <Button
                variant="contained"
                size="medium"
                className={classes.loginBtn}
                onClick={()=>handleLoginBtnClick(true)}
              >
                <span className={classes.loginSpan}>Login</span>
              </Button>
            </li>
          </ul>
               {/*<span onClick={handleLogoutBtnlCickAbout} className='aboutusText' style={{cursor:'pointer'}}>About Us</span><span className='aboutusText'> | </span><span onClick={handleLogoutBtnlCickServices} className='aboutusText' style={{cursor:'pointer'}}>Our Services</span><span className='aboutusText'> | </span><span onClick={handleLogoutBtnClick} className='aboutusText' style={{cursor:'pointer'}}>Logout</span>*/}
              </span>
            </div>
        </Toolbar>
      </AppBar>

            </Box>
          </div>








  );
};

export default Header;
