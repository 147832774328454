

export const CONFIG = {
    "TOKEN_ENDPOINT": "https://riskarchi.com:9450/oauth2/token",
    "AUTHORIZE_ENDPOINT": "https://riskarchi.com:9450/oauth2/authorize",
    "RESPONSE_TYPE": "code",
    "SCOPE": "openid",
    "REDIRECT_URI": "https://riskarchi.com/login",
    "REDIRECT_URI_PORT": "/login",
    "CLIENT_ID": "Gubl_EXlzpPdQGHUwbnPNAE5bvUa",
    "CLIENT_SECRET": "_jlJkUnSHd41EcP0GVspKocDcAsa",
    "GRANT_TYPE": "authorization_code",
    "CLIENT_URL": "https://riskarchi.com",
    "LOGOUT_URL": "https://riskarchi.com:9450/oidc/logout",
    "COOKIE_PATH": "/",
    "GRAPH_URL":"https://riskarchi.com:9001/graphql",
    "MAIN_URL":"https://riskarchi.com:9001",
    "ADMIN_REGISTRATION_URL":"https://riskarchi.com:8080/loadRegistrationPage",
    "BULK_UPLOAD_PATH":"https://riskarchi.com:8080/file/UploadFile.xlsx",
    "SERVICES_SYSTEM_URL":"https://riskarchi.com:8080",
    "EXPERT_SERVER_URL":"https://riskarchi.com:9001",
    "EXPERT_LOGIN":true,
    "CONTROLLER_NAME":"/questionnaire",
    "CONTROLLER_NAME_FOR_QUESTION":"/question",
    "CONTROLLER_NAME_FOR_SLIDER_COLOR":"/color",
    "CONTROLLER_NAME_FOR_SLIDER_RISK":"/risk-category",
    "CONTROLLER_NAME_FOR_SLIDER_QUESTION":"/question-category",
    "CONTROLLER_NAME_FOR_SLIDER_ANSWER":"/answer-category",
    "TRIAL_CENTRE_ID": "CT1",
    "TRIAL_CENTRE_NAME": "Trial_Centre_For_Risk"

    //"EXPERT_LOGIN":false,
    //"CONTROLLER_NAME":"/questionnaire-client",
    //"CONTROLLER_NAME_FOR_QUESTION":"/question-client",
    //"CONTROLLER_NAME_FOR_SLIDER_COLOR":"/color-receiver",
    //"CONTROLLER_NAME_FOR_SLIDER_RISK":"/risk-category-receiver",
    //"CONTROLLER_NAME_FOR_SLIDER_QUESTION":"/question-category-receiver",
    //"CONTROLLER_NAME_FOR_SLIDER_ANSWER":"/answer-category-receiver"
};
