import React from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { ArrowBack } from '@mui/icons-material';
import RightBarSuperAdmin from '../Components/RightBarSuperAdmin';
import Button from '@mui/material/Button';
import useStyle from '../Css/MainCss';
import imgdash from '../assets/risk.jpg'
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import axios from 'axios';
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import jsonFormat1 from '../jsonFormat1';
import { CONFIG } from '../config'
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Paper1 from '@mui/material/Paper';
import {
    ArgumentAxis,
    ValueAxis,
    Chart,
    BarSeries,
    Title,
    SeriesTemplate, CommonSeriesSettings
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation } from '@devexpress/dx-react-chart';
import { Close } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Tooltip1 from '@mui/material/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Info, KeyboardReturnOutlined, TurnedInOutlined } from '@mui/icons-material';
import RiskHierarchyNew from '../Components/RiskHierarchyNew';
import { BarChart, Bar, XAxis, YAxis, Cell, Tooltip, LabelList, CartesianGrid, Legend } from 'recharts';
import { FullscreenRounded } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import AddQuestionComponent from '../Components/AddQuestionComponent';
import RiskRegisterComponent from '../Components/RiskRegisterComponent';
import TransferToExcelRegister from '../Components/TransferToExcelRegister';
import TransferToExcel from '../Components/TransferToExcel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EvaluateComponent from '../Components/EvaluateComponent';
import  "../Css/UploadJson.css";
import SavePageAs from '../Components/SavePageAs';



function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

const UploadJson = () => {

    const [PageName, setPageName] = React.useState('Upload Risk Data Through JSON');
    const navigate = useNavigate();
    const classes = useStyle();

    React.useEffect(() => {
        getData();
        getColors();
        getAllRiskSchemas();
    }, [])

    const [globalCentreId, setGlobalCentreId] = React.useState('');
    function getData() {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        setGlobalCentreId(data.centreId);
        getAllRisks(data.centreId);
        getAllRegister(data.centreId);
        getEntityData(data.centreId)
    }

    const [allEntityData, setAllEntityData] = React.useState([]);

    function getEntityData(centreId) {
        var data = '{allEntityList{ entityDataList{entityName,entityId,isActive,createdBy,lastModifiedBy,centreId,basicEntity{basicEntityId,entityName,iamRole,forceIamRole}}}}';
        axios(CONFIG.MAIN_URL + '/graphql', {
            method: 'post',
            headers: {
                'Content-Type': 'text/plain',
            },
            data: data,
        })
        .then(function (response) {
            var entityList = response.data.data.allEntityList.entityDataList;

            var tmpEntityData = [];
            entityList.forEach((item, index) => {
                if (item.isActive && item.centreId === centreId) {
                    var be = item.basicEntity
                    var forceIamRole = item.basicEntity.forceIamRole;
                    var iamRole = item.basicEntity.iamRole;
                    var canTake = true;
                    if ((iamRole === "ADMIN" && forceIamRole === "ADMIN") || (iamRole === "supplier" && forceIamRole === "supplier") || (iamRole === "serviceprovider" && forceIamRole === "serviceprovider") || (iamRole === "volunteer" && forceIamRole === "volunteer")) {
                    canTake = false;
                    }
                    if (canTake) {
                    var arrayData = {};
                    arrayData["entityId"] = item.entityId;
                    arrayData["entityName"] = item.entityName;
                    tmpEntityData.push(arrayData);
                    }
                }
            })
            setAllEntityData(tmpEntityData);
        })
        .catch(function (error) {
            //console.log("Entity Loding error" + error);
        });
    }






    const [allRegister, setAllRegister] = React.useState([]);

    function getAllRegister(centrId) {
        axios(CONFIG.MAIN_URL + `/register/all`, {
            method: 'get',
        })
            .then(function (response) {
                var arr = []
                console.log("all register");
                console.log(response.data);
                response.data.forEach((item) => {
                    if (item.centreId === centrId)
                        arr.push(item)
                    console.log(arr)

                })
                setAllRegister(arr);

            })
            .catch(function (error) {
                console.log(error);
            });
    }





    function createJsonObject(obj) {
        console.log("+++++++++++++++++++obj+++++++++++++++++++++");
        console.log(obj);
        var regTmpId = 100000;
        obj.riskRegister.forEach((data) => {
            data["riskTypeId"] =  regTmpId;
            regTmpId ++;
            data.risks.forEach((risk) => {
                risk["riskTypeId"] =  regTmpId;
                regTmpId ++;
                if (risk.riskHierarchy != undefined && risk.riskHierarchy != null) {
                    if (risk.riskHierarchy.riskHierarchyData != undefined && risk.riskHierarchy.riskHierarchyData != null) {
                        var riskHierarchyObj = risk.riskHierarchy.riskHierarchyData;
                        if (riskHierarchyObj.riskTypes != undefined && riskHierarchyObj.riskTypes != null) {
                            risk["subRisks"] = riskHierarchyObj.riskTypes;
                        }
                    }
                    
                }

            })
        })

        var dataStr = JSON.stringify(obj);
        dataStr = dataStr.replaceAll("\"riskRegister\"", "\"riskTypes\"");
        dataStr = dataStr.replaceAll("\"section\"", "\"name\"");
        dataStr = dataStr.replaceAll("\"risks\"", "\"subRisks\"");
        dataStr = dataStr.replaceAll("\"likelihood\"", "\"riskLikelihood\"");
        dataStr = dataStr.replaceAll("\"impact\"", "\"riskImpact\"");
        var jsonObj = JSON.parse(dataStr)

        return jsonObj;

    }

    function assignExtraAttributes(data, tmpIdCount) {
        data.forEach((item) => {
            item["riskTypeTmpId"] = tmpIdCount
            tmpIdCount++;
            if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                assignExtraAttributes(item.subRisks, tmpIdCount);
            }
        })
        return tmpIdCount;
    }

    const [selectedRegisterEvaluateTitle, setSelectedRegisterEvaluateTitle] = React.useState('');
    const [registerTypeDataForEval, setRegisterTypeDataForEval] = React.useState({});
    const [originalRegisterTypeDataForEval, setOriginalRegisterTypeDataForEval] = React.useState({});

    function getAllRegisterEval(centrId, regId) {
        axios(CONFIG.MAIN_URL + `/register/all`, {
            method: 'get',
        })
            .then(function (response) {
                //var arr = []
                console.log("all register");
                console.log(response.data);
                response.data.forEach((item) => {
                    if (item.centreId === centrId && item.id ===regId){
                        var tmpIdCount = 1;
                        var tmpIdCount1 = 1;
                        var jsonObj = createJsonObject(item);
                        setSelectedRegisterEvaluateTitle(item.name)
                        jsonObj.riskTypes.forEach((item1, index) => {
                            item1["riskTypeTmpId"] = tmpIdCount;
                            tmpIdCount++
                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                tmpIdCount = assignExtraAttributes(item1.subRisks, tmpIdCount);
                            }
                            tmpIdCount++;
                        })

                        setRegisterTypeDataForEval(jsonObj);

                        var jsonObj1 = createJsonObject(item);
                        jsonObj1.riskTypes.forEach((item1, index) => {
                            item1["riskTypeTmpId"] = tmpIdCount1;
                            tmpIdCount1++
                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                tmpIdCount1 = assignExtraAttributes(item1.subRisks, tmpIdCount1);
                            }
                            tmpIdCount1++;
                        })
                        setOriginalRegisterTypeDataForEval(jsonObj1)
                    }

                })

            })
            .catch(function (error) {
                console.log(error);
            });
    }




    const [allAvailableRisk, setAllAvailableRisk] = React.useState([]);
    function getAllRisks(centId) {
        axios(CONFIG.MAIN_URL + `/risk/all`, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("All Risk Returns");
                console.log(response.data);
                var arr = [];
                response.data.forEach((item) => {
                    if (centId === item.centreId) {
                        arr.push(item);
                    }
                })
                setAllAvailableRisk(arr);
                if (createdRiskId != undefined && createdRiskId != null && createdRiskId != '') {
                    arr.forEach((item) => {
                        if (createdRiskId === item.riskId) {
                            setRiskTypeDataForEval(item);
                            setSelectedRiskEvaluateTitle(item.name);
                        }
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const [allAvailableRiskSchemas, setAllAvailableRiskSchemas] = React.useState([]);
    function getAllRiskSchemas() {
        axios(CONFIG.MAIN_URL + `/schemaformat/all`, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("All Risk Schemas Returns");
                console.log(response.data);
                setAllAvailableRiskSchemas(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const [selectedSchemaId, setSelectedSchemaId] = React.useState('');
    const [selectedSchemaName, setSelectedSchemaName] = React.useState('');

    const handleChangeSelectedSchema = (event) => {
        setSelectedSchemaId(event.target.value);
        allAvailableRiskSchemas.forEach((data, index) => {
            if (data.id === event.target.value) {
                setSelectedSchemaName(data.name);
            }
        })
    }



    const [databClr, setDatabClr] = React.useState([]);
    const [colorPatterns, setColorPatterns] = React.useState([])

    function getColors() {
        axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR + "/all", {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        })
            .then(function (response) {
                console.log("+++++++++++++++++All colors++++++++++++++++++");
                console.log(response.data);
                setColorPatterns(response.data);

            })
            .catch(function (error) {
                console.log(error);
            });
    }




    const [openSchemaFormat1, setOpenSchemaFormat1] = React.useState(true);
    const [openSchemaFormat2, setOpenSchemaFormat2] = React.useState(false);
    const [openSchemaFormat3, setOpenSchemaFormat3] = React.useState(false);

    function setOpenSchemaFormat1Fuction(flag) {
        setOpenSchemaFormat1(true);
        setOpenSchemaFormat2(false);
        setOpenSchemaFormat3(false);
    }
    function setOpenSchemaFormat2Fuction(flag) {
        setOpenSchemaFormat1(false);
        setOpenSchemaFormat2(true);
        setOpenSchemaFormat3(false);
    }
    function setOpenSchemaFormat3Function(flag) {
        setOpenSchemaFormat1(false);
        setOpenSchemaFormat2(false);
        setOpenSchemaFormat3(true);
    }

    const [jsonStringFormat1, setJsonStringFormat1] = React.useState("");

    const handleChangeFormat1 = e => {
        var fileName = e.target.files[0].name;
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            try {
                var jString = JSON.parse(e.target.result);
                setJsonStringFormat1(e.target.result);
            } catch (error) {
                alert("You have selected an invalid Json")
                setJsonStringFormat1('');
            }
        };
    };

    function clearFormat1Fuction() {
        setJsonStringFormat1('');
        setCreatedRiskId('');
        setRiskTypeDataForEval({});
        setSelectedRiskEvaluateTitle('');
        setRiskName('');
        setRegisterDescription('');
        setRiskRegisterValidate(false);
        setSelectedSchemaId('')
        setSavedQuestionArray([]);
        setEditRiskOrRegister('Edit');
        setSelectedRegisteredArray([])
        setSelectedRegisteredId('');
        setSelectedRegisteredName('');
    }

    const handleChangeJsonStringFormat1 = (event) => {
        setJsonStringFormat1(event.target.value);

    }

    /*
    const schemaFormat1 = jsonFormat1; 
    const validatorFormat1 = new Ajv().compile(schemaFormat1);
    */

    const [waitingOpen, setWaitingOpen] = React.useState(false);
    const [createdRiskId, setCreatedRiskId] = React.useState('');
    const [riskTypeDataForEval, setRiskTypeDataForEval] = React.useState({});
    const [selectedRiskEvaluateTitle, setSelectedRiskEvaluateTitle] = React.useState('');
    //const [commitArray, setCommitArray] = React.useState([]);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [riskRegisterValidate, setRiskRegisterValidate] = React.useState(false);
    const [editRiskOrRegister, setEditRiskOrRegister] = React.useState('Edit');


    const [openKeyList, setOpenKeyList] = React.useState(false);
    const [duplicateKeyList, setDuplicateKeyList] = React.useState([]);

    function continueUpdating() {
        setOpenConfirm(true);
        setOpenKeyList(false)
    }

    const [openJsonSchema, setOpenJsonSchema] = React.useState(false);
    const [jsonSchemaString, setJsonSchemaString] = React.useState('');
    
    function viewSchemaFuction(){
        if (selectedSchemaId === '') {
            alert("No JSON schema selecetd. Please select a JSON Schema");
            return;
        }
        var schemaStr = '';
        allAvailableRiskSchemas.forEach((data, index) => {
            if (data.id === selectedSchemaId) {
                schemaStr = data.schemaFormat;
            }
        })
        schemaStr = schemaStr.replace(/'/g, '"');
        var schemaObj = JSON.parse(schemaStr);
        setJsonSchemaString(JSON.stringify(schemaObj, null, "\t"));
        setOpenJsonSchema(true);
    }

    function insertSampleFuction(){
        if (selectedSchemaId === '') {
            alert("No JSON schema selecetd. Please select a JSON Schema");
            return;
        }
        var schemaStr = '';
        allAvailableRiskSchemas.forEach((data, index) => {
            if (data.id === selectedSchemaId) {
                if (data.sampleJson != undefined && data.sampleJson != null && data.sampleJson != ''){
                    schemaStr = data.sampleJson;
                }
            }
        })
        if (schemaStr === ''){
            alert("No Sample Available. Please Contact Administrator");
            return;
        }else{
            schemaStr = schemaStr.replace(/'/g, '"');
            var schemaObj = JSON.parse(schemaStr);
            schemaStr = JSON.stringify(schemaObj, null, "\t")
            setJsonStringFormat1(schemaStr);
        }

    }
    
    /** Edit JSON section */
    function PaperComponentJsonEdit(props) {
        return (
          <Draggable
            handle="#json-edit-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper {...props} />
          </Draggable>
        );
    }
    const [openJsonEdit, setOpenJsonEdit] = React.useState(false);
    const [openJsonEditScroll, setOpenJsonEditScroll] = React.useState('paper');

    const [editedSectionName, setEditedSectionName] = React.useState('');
    const [editedJsonArray, setEditedJsonArray] = React.useState([]);

    const sectionNameOnBlur = (event) => {
        var fieldVal = document.getElementById("txtSectionName").value;
        if (fieldVal != ''){
            setEditedSectionName(fieldVal);
            clearSection();
            clearRiskData();
        }
      }
    
    
      const sectionNameOnChange = (event) => {
        setEditedSectionName(event.target.value);
        clearSection();
        clearRiskData();
      }
    



    function editJsonFuction(){
        if (jsonStringFormat1 === '') {
            alert("No JSON String in the Text Area. Please add a Json String to Edit");
            return;
        }
        try {
            var jsonObj = JSON.parse(jsonStringFormat1);
            if (jsonObj.riskRegister != undefined && jsonObj.riskRegister != null){ 
                var sectionArr = [];
                jsonObj.riskRegister.forEach((data, index) => {
                    sectionArr.push(data);
                })
                setEditedJsonArray(sectionArr)
            }else{
                alert("Please note that currently You can edit Register related Json only");
                return;
            }

        } catch (error) {
            alert("An invalid Json in the Text area. Please correct this")
            return;
        }
        setOpenJsonEdit(true);
        setOpenJsonEditScroll('paper');
    }

    function calculatedRiskLevel() {
        var x = '';
        if (!isNaN(parseFloat(intImpactVales)) && !isNaN(parseFloat(intLikelihoodValue))) {
          x = parseInt(intImpactVales) * parseInt(intLikelihoodValue)
          //setIntRiskTypeValue(x);
        }
        return x;
      }
    
      const options = ['1', '2', '3', '4', '5'];
      const [intImpactVales, setIntImpactVales] = React.useState('');
      const options1 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
      const [intRiskAppetiteValue, setIntRiskAppetiteValue] = React.useState('');
      const [intLikelihoodValue, setIntLikelihoodValue] = React.useState('');
    
      const [selectedEntityName, setSelectedEntityName] = React.useState('')
      const selectedEntityNameOnChange = (event) => {
        setSelectedEntityName(event.target.value)
      }

      const [entityName, setEntityName] = React.useState('')
      const entityNameOnChange = (event) => {
        setSelectedEntityName(event.target.value)
        setEntityName(event.target.value)
      }




    const [editedSectionRiskName, setEditedSectionRiskName] = React.useState('');
    const [editedSectionRiskArray, setEditedSectionRiskArray] = React.useState([]);

    const sectionRiskNameOnBlur = (event) => {
        var fieldVal = document.getElementById("txtSectionRiskName").value;
        if (fieldVal != ''){
            setEditedSectionRiskName(fieldVal);
        }
      }
    
    
      const sectionRiskNameOnChange = (event) => {
        setEditedSectionRiskName(event.target.value);
      }
    

      function addNewSectionFuction(){
        if (editedSectionName != ''){
            var previousOne = false;
            editedJsonArray.forEach((data, index) => {
                if (editedSectionName === data.section){
                    previousOne = true;
                }
            })
            if (previousOne){
                alert("The section \"" + editedSectionName + "\" is already in the Risk Register List. Please type a different Section name");
            }
        }else{
            alert("Please enter Section Name")
        }
      }

      
      function editSectionFuction(){
        if (editedSectionName != ''){
            var previousOne = false;
            editedJsonArray.forEach((data, index) => {
                if (editedSectionName === data.section){
                    setIntLikelihoodValue(data.likelihood)
                    setIntImpactVales(data.impact)
                    setIntRiskAppetiteValue(data.riskAppetite)
                    setSelectedEntityName(data.role)
                    setEditedSectionRiskArray(data.risks)
                    previousOne = true;
                }
            })
            if (!previousOne){
                clearSection();
                clearRiskData();
                alert("You are going to add a new section");
            }

        }else{
            alert("Please enter Section Name")
        }
      }

      function deleteJsonSectionFuction(){

        if (editedSectionName != ''){
            var previousOne = false;
            var arr = [];
            var sectionName = "";
            editedJsonArray.forEach((data, index) => {
                if (editedSectionName === data.section){
                    previousOne = true;
                    sectionName = data.section;
                }else{
                    arr.push(data); 
                }
            })
            if (!previousOne){
                alert("Please select a previous section to delete");
                return;
            }else{
                var answer = window.confirm("You are going to delete the section \"" + sectionName + "\" together with its Risks.\n\n Press 'OK' to confirm");
                if (answer) {
                    setEditedJsonArray(arr);
                    var regObj = {}
                    regObj["riskRegister"] = arr
                    var schemaStr = JSON.stringify(regObj, null, "\t")
                    setJsonStringFormat1(schemaStr);
                    clearSection();
                    setOpenJsonEdit(false);
                }
            }

        }else{
            alert("Please enter Section Name to delete")
        }

      }



      function addToSection(){
        if (editedSectionName != ''){
            if (intLikelihoodValue === '' || intImpactVales === '' || intRiskAppetiteValue === '' || selectedEntityName === ''){
              alert("Please fill the above data")   
              return;
            }
            var previousOne = false;
            editedJsonArray.forEach((data, index) => {
                if (editedSectionName === data.section){
                    data["likelihood"] = parseInt(intLikelihoodValue);
                    data["impact"] = parseInt(intImpactVales);
                    data["riskLevel"] = parseInt(intLikelihoodValue) * parseInt(intImpactVales);
                    data["riskAppetite"] = parseInt(intRiskAppetiteValue);
                    data["role"] = selectedEntityName;
                    previousOne = true;
                }
            })
            if (!previousOne){
                var obj = {};
                obj["section"] = editedSectionName;
                obj["likelihood"] = parseInt(intLikelihoodValue);
                obj["impact"] = parseInt(intImpactVales);
                obj["riskLevel"] = parseInt(intLikelihoodValue) * parseInt(intImpactVales);
                obj["riskAppetite"] = parseInt(intRiskAppetiteValue)
                obj["role"] = selectedEntityName;
                var arr = [];
                obj["risks"] = arr;
                editedJsonArray.push(obj);
            }
            var regObj = {}
            regObj["riskRegister"] = editedJsonArray
            var schemaStr = JSON.stringify(regObj, null, "\t")
            setJsonStringFormat1(schemaStr);
            alert("Updates section data");
            clearSection();
            setOpenJsonEdit(false);


        }else{
            alert("Please enter Section Name")
        }

      }

      function clearSection(){
        setIntLikelihoodValue('')
        setIntImpactVales('')
        setIntRiskAppetiteValue('')
        setSelectedEntityName('')
      }

      function clearRiskData(){
        setEditedSectionRiskArray([]);
        setEditedSectionRiskName('')
        setIntLikelihoodRiskValue('')
        setIntImpactRiskVales('')
        setIntRiskAppetiteRiskValue('')
        setSelectedUserKey('')
        setSelectedHierarchyName('')
      
      }

      const [selectedUserKey, setSelectedUserKey] = React.useState('');
      const handleChangeSelectedUserKey = (event) => {
        setSelectedUserKey(event.target.value);
      }

      const [selectedHierarchyName, setSelectedHierarchyName] = React.useState('');
      const handleChangeSelectedHierarchyName = (event) => {
        setSelectedHierarchyName(event.target.value);
      }

      function calculatedRiskLevelRisk() {
        var x = '';
        if (!isNaN(parseFloat(intImpactRiskVales)) && !isNaN(parseFloat(intLikelihoodRiskValue))) {
          x = parseInt(intImpactRiskVales) * parseInt(intLikelihoodRiskValue)
          //setIntRiskTypeValue(x);
        }
        return x;
      }
      
      const [intImpactRiskVales, setIntImpactRiskVales] = React.useState('');
      const [intRiskAppetiteRiskValue, setIntRiskAppetiteRiskValue] = React.useState('');
      const [intLikelihoodRiskValue, setIntLikelihoodRiskValue] = React.useState('');

      function addNewRiskFuction(){
        if (editedSectionRiskName != ''){
            var previousOne = false;
            editedSectionRiskArray.forEach((data, index) => {
                if (editedSectionRiskName === data.description){
                    previousOne = true;
                }
            })
            if (previousOne){
                alert("The Risk \"" + editedSectionRiskName + "\" is already in the Section \"" + editedSectionName + "\". Please type a different Risk Register's name");
            }
        }else{
            alert("Please enter Risk Register's Name")
        }
      }

      
      function editSectionRiskFuction(){
        if (editedSectionRiskName != ''){
            var previousOne = false;
            editedSectionRiskArray.forEach((data, index) => {
                if (editedSectionRiskName === data.description){
                    setIntLikelihoodRiskValue(data.likelihood)
                    setIntImpactRiskVales(data.impact)
                    setIntRiskAppetiteRiskValue(data.riskAppetite)
                    setSelectedUserKey(data.riskHierarchies.key)
                    setSelectedHierarchyName(data.riskHierarchies.description)
                    previousOne = true;
                }
            })
            if (!previousOne){
                //clearRiskData();
                setIntLikelihoodRiskValue('')
                setIntImpactRiskVales('')
                setIntRiskAppetiteRiskValue('')
                setSelectedUserKey('')
                setSelectedHierarchyName('')

                alert("You are going to add a new Risk Hierarchy");
            }

        }else{
            alert("Please enter Register's Risk Description")
        }
      }

      function deleteJsonSectionRiskFuction(){

        if (editedSectionRiskName != ''){
            var previousOne = false;
            var arr = [];
            var sectionName = "";
            editedSectionRiskArray.forEach((data, index) => {
                if (editedSectionRiskName === data.description){
                    previousOne = true;
                    sectionName = data.description;
                }else{
                    arr.push(data); 
                }
            })
            if (!previousOne){
                alert("Please select a previous Risk Hierarchy to delete");
                return;
            }else{
                var answer = window.confirm("You are going to delete the Risk \"" + sectionName + "\".\n\n Press 'OK' to confirm");
                if (answer) {
                    setEditedSectionRiskArray(arr);
                    editedJsonArray.forEach((data, index) => {
                        if (editedSectionName === data.section){
                            data["risks"] = arr;
                        }
                    })
                    var regObj = {}
                    regObj["riskRegister"] = editedJsonArray
                    var schemaStr = JSON.stringify(regObj, null, "\t")
                    setJsonStringFormat1(schemaStr);
                    clearSection();
                    setOpenJsonEdit(false);
                }
            }

        }else{
            alert("Please enter Risk Register's Name to delete")
        }

      }

      function addToSectionRisk(){
        if (editedSectionName != '' && editedSectionRiskName){
            if (intLikelihoodRiskValue === '' || intImpactRiskVales === '' || intRiskAppetiteRiskValue === '' || selectedUserKey === '' || selectedHierarchyName === ''){
              alert("Please fill the above data")   
              return;
            }
            var previousOne = false;
            editedSectionRiskArray.forEach((data, index) => {
                if (editedSectionRiskName === data.description){
                    data["likelihood"] = parseInt(intLikelihoodRiskValue);
                    data["impact"] = parseInt(intImpactRiskVales);
                    data["riskLevel"] = parseInt(intLikelihoodRiskValue) * parseInt(intImpactRiskVales);
                    data["riskAppetite"] = parseInt(intRiskAppetiteRiskValue);
                    var obj = {};
                    obj["key"] = selectedUserKey;
                    obj["description"] = selectedHierarchyName;
                    data["riskHierarchies"] = obj;
                    previousOne = true;
                }
            })
            if (!previousOne){
                var obj = {};
                obj["description"] = editedSectionRiskName;
                obj["likelihood"] = parseInt(intLikelihoodValue);
                obj["impact"] = parseInt(intImpactVales);
                obj["riskLevel"] = parseInt(intLikelihoodValue) * parseInt(intImpactVales);
                obj["riskAppetite"] = parseInt(intRiskAppetiteValue)
                var obj1 = {};
                obj1["key"] = selectedUserKey;
                obj1["description"] = selectedHierarchyName;
                obj["riskHierarchies"] = obj1;
                editedSectionRiskArray.push(obj);
            }

            editedJsonArray.forEach((data, index) => {
                if (editedSectionName === data.section){
                    data["risks"] = editedSectionRiskArray;
                }
            })
            var regObj = {}
            regObj["riskRegister"] = editedJsonArray
            var schemaStr = JSON.stringify(regObj, null, "\t")
            setJsonStringFormat1(schemaStr);
            alert("Updates Risk data");
            clearRiskData();
            clearSection();
            setOpenJsonEdit(false);

        }else{
            alert("Please enter Section Name or Risk Register's Name")
        }

      }



      function closOpenJsonEditFunction(){
        clearSection();
        clearRiskData();
        setOpenJsonEdit(false);
      }


    /** End of Edit JSON section */  



    function validateAndUpdateFormat1Fuction() {
        if (selectedSchemaId === '') {
            alert("No JSON schema selecetd for Validation. Please select a JSON Schema");
            return;
        }
        if (jsonStringFormat1 === '') {
            alert("Please enter JSON string in the Text Area");
            return;
        }

        var schemaStr = '';
        allAvailableRiskSchemas.forEach((data, index) => {
            if (data.id === selectedSchemaId) {
                schemaStr = data.schemaFormat;
            }
        })
        schemaStr = schemaStr.replace(/'/g, '"');
        var schemaObj = JSON.parse(schemaStr);
        /** Validate Json String is a correct JSON Format */

        const Ajv = require('ajv');
        const ajv = new Ajv({ allErrors: true });
        try {
            var jObj = JSON.parse(jsonStringFormat1);

            if (jObj.riskRegister != undefined && jObj.riskRegister != null && jObj.riskRegister.length > 0) {
                setRiskRegisterValidate(true);
            } else {
                setRiskRegisterValidate(false);
            }


            var validate = ajv.compile(schemaObj);
            const valid = validate(jObj);
            if (!valid) {
                var errorStr = "Error validating JSON data With JSON-Schema\n\n";
                var count = 1
                validate.errors.forEach((data) => {
                    errorStr = errorStr + count + ")\n";
                    errorStr = errorStr + "    Error : " + data.message + "\n";
                    errorStr = errorStr + "    Error Path : " + data.instancePath + "\n";
                    count++;
                })
                console.log(validate.errors);
                alert(errorStr)
                return;
            }
        } catch (error) {
            //console.log(error.message);
            //var err = error.message.replace("SyntaxError: JSON.parse: " , ""); 
            //console.log(err);
            alert("Invalid Json in the Text Area. Please correct it and Press validate button again.\n\nError:\n" + error)
            return;
        }

        var jObj = JSON.parse(jsonStringFormat1);
        if (jObj.riskRegister != undefined && jObj.riskRegister != null && jObj.riskRegister.length > 0) {
            var dataArr = [];
            jObj.riskRegister.forEach((data) => {
                if (data.risks != undefined && data.risks != null && data.risks.length > 0) {
                    data.risks.forEach((data1) => {
                        var riskKey = data1.riskHierarchies.key;
                        var rtnObj = getRegisterNames(riskKey);
                        if (rtnObj != null ){
                            dataArr.push(rtnObj)
                        }
                    })
                }
            })
            setDuplicateKeyList(dataArr);
            if (dataArr.length > 0){
                setOpenKeyList(true)
            }else{
                setOpenConfirm(true);    
            }
        }else{
            setOpenConfirm(true);
        }
        
    }

    function getRegisterNames(key){
        var arr = []
        var newRegi = false;
        var obj = {};
        obj["key"] = key
        allRegister.forEach((data) => {
            newRegi = true;
            if (data.riskRegister != undefined && data.riskRegister != null){
                data.riskRegister.forEach((data1) => {
                    data1.risks.forEach((risk) => {
                        var keyRegister = risk.riskHierarchy.key
                        if (key === keyRegister && newRegi){
                            newRegi = false;
                            var obj1 = {}
                            obj1["id"] = data.id
                            obj1["name"] = data.name
                            arr.push(obj1)         
                        }
                    })
                })
            }
        })
        if (arr.length > 0){
            obj["registers"] = arr
            return obj;
        }else{
            return null;
        }

    }

    const [riskName, setRiskName] = React.useState('');
    const [riskKey, setRiskKey] = React.useState('');
    const [registerDescription, setRegisterDescription] = React.useState('')

    const registerDescriptionOnChange = (event) => {
        setRegisterDescription(event.target.value)
    }

    const [savedQuestionArray, setSavedQuestionArray] = React.useState([]);
    const [selectedRegisteredArray, setSelectedRegisteredArray] = React.useState([]);
    const [selectedRegisteredId, setSelectedRegisteredId] = React.useState('');
    const [selectedRegisteredName, setSelectedRegisteredName] = React.useState('');

    function getRiskId(key){
        var riskId = '';
        /** Same key code is having Multiple ids. so this can not be update now  */
        /*
        allAvailableRisk.forEach((data) => {
            if (data.key != undefined && data.key != null){
                if (key === data.key){
                    riskId = data.riskId;
                }
            }
        })
        */
        return riskId;
    }

    function handleUpdate() {
        if (riskName === '') {
            if (riskRegisterValidate) {
                alert("Please assign a Register Name");
            } else {
                alert("Please assign a Risk Name");
            }
            return;
        }
        if (riskRegisterValidate) {
            if (registerDescription === '') {
                alert("Please type a Register Description");
                return
            }
        }else{
            if (riskKey === '') {
                alert("Please assign a Risk Key");
                return
            }
        }

        try {
            var jObj = JSON.parse(jsonStringFormat1);
            console.log(jObj);
            if (riskRegisterValidate) {
                setWaitingOpen(true);
                var riskObj = {};
                //riskObj["registerUniqueName"]= globalCentreId + "_register_"+ rtnData.data.createRegisterData.registerId;
                riskObj["documentCreator"] = "expert";
                riskObj["name"] = riskName;
                riskObj["description"] = registerDescription;
                riskObj["centreId"] = globalCentreId;
                riskObj["status"] = "Draft";

                if (jObj.riskRegister != undefined && jObj.riskRegister != null && jObj.riskRegister.length > 0) {
                    var mainArr = [];
                    jObj.riskRegister.forEach((data) => {
                        var tmpObj1 = {};
                        tmpObj1["section"] = data.section;
                        tmpObj1["role"] = data.role;
                        tmpObj1["likelihood"] = data.likelihood;
                        tmpObj1["impact"] = data.impact;
                        tmpObj1["riskLevel"] = data.riskLevel;
                        tmpObj1["riskAppetite"] = data.riskAppetite;
                        var subArr = [];
                        if (data.risks != undefined && data.risks != null && data.risks.length > 0) {
                            data.risks.forEach((data1) => {
                                var tmpObj2 = {};
                                tmpObj2["name"] = data1.riskHierarchies.description;
                                tmpObj2["description"] = data1.description;
                                tmpObj2["likelihood"] = data1.likelihood;
                                tmpObj2["impact"] = data1.impact;
                                tmpObj2["riskLevel"] = data1.riskLevel;
                                tmpObj2["riskAppetite"] = data1.riskAppetite;
                                var tmpObj3 = {};
                                var riskId = getRiskId(data1.riskHierarchies.key);
                                tmpObj3["riskId"] = riskId;

                                tmpObj3["key"] = data1.riskHierarchies.key;
                                tmpObj3["name"] = data1.riskHierarchies.description;
                                tmpObj2["riskHierarchy"] = tmpObj3;
                                subArr.push(tmpObj2);
                            })
                            tmpObj1["risks"] = subArr;
                        }
                        mainArr.push(tmpObj1);
                    })
                    riskObj["riskRegister"] = mainArr;
                }
                console.log(riskObj);
                var sendingData = "mutation{createRegisterData(registerName:\"" + riskName + "\",status:\"" + 'draft' + "\",centreId:\"" + globalCentreId + "\"){registerId,registerName,status,centreId }}"
                axios(CONFIG.MAIN_URL + '/graphql', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'text/plain'
                    },
                    data: sendingData
                })
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        let rtnData = response.data;
                        console.log("++++++++++ register Data save in PG");
                        console.log(rtnData);
                        console.log("++++++++++ register Data save in PG");
                        riskObj["registerUniqueName"] = globalCentreId + "_register_" + rtnData.data.createRegisterData.registerId;
                        console.log(riskObj);
                        console.log(JSON.stringify(riskObj));
                        let sendJson = JSON.stringify(riskObj);
                        axios(CONFIG.MAIN_URL + '/register/save', {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            data: sendJson,
                        })
                            .then(function (response) {
                                setWaitingOpen(false);
                                console.log(JSON.stringify(response.data));
                                console.log(response.data);
                                let rtnData = response.data;
                                alert("Register saved successfully.");

                                var registerId = response.data.id;
                                var name = response.data.name;

                                setSelectedRegisteredArray(response.data);
                                setSelectedRegisteredId(registerId);
                                setSelectedRegisteredName(name);

                                setOpenConfirm(false);
                                setEditRiskOrRegister('Edit Risk Register');
                                //getAllRegister(globalCentreId);
                            })
                            .catch(function (error) {
                                setWaitingOpen(false);
                                console.log(error);
                                alert("Error saving Register-:" + error);
                            });
                    })
                    .catch(function (error) {
                        setWaitingOpen(false);
                        console.log(error);
                        alert("Error saving register Data to Database Server -:" + error);
                    });



            } else {
                /** Update the Risk file */
                var tmpName = riskName;
                var riskLikelihood = "1";
                var riskImpact = "1";
                var riskLevel = 1;
                var riskAppetite = "1";
                var riskObj = {};
                var today = new Date();
                var curDateTime = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

                riskObj["name"] = tmpName;
                riskObj["key"] = riskKey;
                riskObj["centreId"] = globalCentreId;
                riskObj["riskLikelihood"] = riskLikelihood;
                riskObj["riskImpact"] = riskImpact;
                riskObj["riskLevel"] = riskLevel;
                riskObj["riskAppetite"] = riskAppetite;

                var riskCount = 1;
                var jsonType = 0;

                if (jObj.riskComponents != undefined && jObj.riskComponents != null && jObj.riskComponents.length > 0) {
                    jsonType = 1;
                    var mainArr = [];
                    jObj.riskComponents.forEach((data) => {
                        var tmpObj1 = {};
                        tmpObj1["riskTypeTmpId"] = riskCount;
                        riskCount++;
                        tmpObj1["name"] = data.componentName;
                        tmpObj1["riskLikelihood"] = riskLikelihood;
                        tmpObj1["riskImpact"] = riskImpact;
                        tmpObj1["riskLevel"] = riskLevel;
                        tmpObj1["riskAppetite"] = riskAppetite;
                        var tmpObj2 = {};
                        tmpObj2["riskLikelihood"] = riskLikelihood;
                        tmpObj2["riskImpact"] = riskImpact;
                        tmpObj2["riskLevel"] = riskLevel;
                        tmpObj1["riskLevelData"] = tmpObj2;
                        tmpObj1["riskAppetite"] = riskAppetite;
                        var tmpObj3 = {};
                        tmpObj3["riskAppetite"] = riskAppetite;
                        tmpObj3["secondaryColor"] = "#ffffff";
                        tmpObj1["riskAppetiteData"] = tmpObj3;

                        //setCommitArray.push(tmpObj1);
                        var subArr = [];
                        if (data.subRiskComponents != undefined && data.subRiskComponents != null && data.subRiskComponents.length > 0) {
                            data.subRiskComponents.forEach((data1) => {
                                var tmpObj4 = {};
                                tmpObj4["riskTypeTmpId"] = riskCount;
                                riskCount++;
                                tmpObj4["name"] = data1.objectiveName;
                                tmpObj4["riskLikelihood"] = riskLikelihood;
                                tmpObj4["riskImpact"] = riskImpact;
                                tmpObj4["riskLevel"] = riskLevel;
                                tmpObj4["riskAppetite"] = riskAppetite;
                                var tmpObj5 = {};
                                tmpObj5["riskLikelihood"] = riskLikelihood;
                                tmpObj5["riskImpact"] = riskImpact;
                                tmpObj5["riskLevel"] = riskLevel;
                                tmpObj4["riskLevelData"] = tmpObj5;
                                tmpObj4["riskAppetite"] = riskAppetite;
                                var tmpObj6 = {};
                                tmpObj6["riskAppetite"] = riskAppetite;
                                tmpObj6["secondaryColor"] = "#ffffff";
                                tmpObj4["riskAppetiteData"] = tmpObj6;

                                //setCommitArray.push(tmpObj4);

                                if (data1.subSubRiskComponents != undefined && data1.subSubRiskComponents != null && data1.subSubRiskComponents.length > 0) {
                                    var subSubArr = [];
                                    data1.subSubRiskComponents.forEach((data2) => {
                                        var tmpObj7 = {};
                                        tmpObj7["riskTypeTmpId"] = riskCount;
                                        riskCount++;
                                        tmpObj7["name"] = data2.principleName;
                                        var riskLikelihoodStr = ""
                                        var riskLevelAmt = 1;
                                        if (data2.likelihood != undefined && data2.likelihood != null) {
                                            riskLikelihoodStr = data2.likelihood.toString();
                                            riskLevelAmt = parseInt(data2.likelihood);
                                        } else {
                                            riskLikelihoodStr = riskLikelihood;
                                        }
                                        var riskImpactStr = ""
                                        if (data2.impact != undefined && data2.impact != null) {
                                            riskImpactStr = data2.impact.toString();
                                            riskLevelAmt = riskLevelAmt * (parseInt(data2.impact));
                                        } else {
                                            riskImpactStr = riskImpact;
                                        }

                                        tmpObj7["riskLikelihood"] = riskLikelihoodStr;
                                        tmpObj7["riskImpact"] = riskImpactStr;
                                        tmpObj7["riskLevel"] = riskLevelAmt;
                                        tmpObj7["riskAppetite"] = riskAppetite;
                                        var tmpObj8 = {};
                                        tmpObj8["riskLikelihood"] = riskLikelihoodStr;
                                        tmpObj8["riskImpact"] = riskImpactStr;
                                        tmpObj8["riskLevel"] = riskLevelAmt;
                                        tmpObj7["riskLevelData"] = tmpObj8;
                                        tmpObj7["riskAppetite"] = riskAppetite;
                                        var tmpObj9 = {};
                                        tmpObj9["riskAppetite"] = riskAppetite;
                                        tmpObj9["secondaryColor"] = "#ffffff";
                                        tmpObj7["riskAppetiteData"] = tmpObj9;

                                        //setCommitArray.push(tmpObj7);

                                        subSubArr.push(tmpObj7);
                                    })
                                    tmpObj4["subRisks"] = subSubArr;
                                }
                                subArr.push(tmpObj4);
                            })
                            tmpObj1["subRisks"] = subArr;
                        }
                        mainArr.push(tmpObj1);
                    })
                    riskObj["riskTypes"] = mainArr;
                } else {
                    if (jObj.objectives != undefined && jObj.objectives != null && jObj.objectives.length > 0) {
                        jsonType = 2;
                        var mainArr = [];
                        jObj.objectives.forEach((data) => {
                            var tmpObj1 = {};
                            tmpObj1["riskTypeTmpId"] = riskCount;
                            riskCount++;
                            tmpObj1["name"] = data.mainRisk;
                            tmpObj1["riskLikelihood"] = riskLikelihood;
                            tmpObj1["riskImpact"] = riskImpact;
                            tmpObj1["riskLevel"] = riskLevel;
                            tmpObj1["riskAppetite"] = riskAppetite;
                            var tmpObj2 = {};
                            tmpObj2["riskLikelihood"] = riskLikelihood;
                            tmpObj2["riskImpact"] = riskImpact;
                            tmpObj2["riskLevel"] = riskLevel;
                            tmpObj1["riskLevelData"] = tmpObj2;
                            tmpObj1["riskAppetite"] = riskAppetite;
                            var tmpObj3 = {};
                            tmpObj3["riskAppetite"] = riskAppetite;
                            tmpObj3["secondaryColor"] = "#ffffff";
                            tmpObj1["riskAppetiteData"] = tmpObj3;

                            var tmpObjObjectiveData = {};
                            tmpObjObjectiveData["objectiveName"] = data.objectiveName;
                            var tmpObjObjectiveDataObj = {};
                            tmpObjObjectiveDataObj["objectiveData"] = tmpObjObjectiveData;
                            tmpObj1["riskStandardData"] = tmpObjObjectiveDataObj;

                            var subArr = [];
                            if (data.components != undefined && data.components != null && data.components.length > 0) {
                                data.components.forEach((data1) => {
                                    var tmpObj4 = {};
                                    tmpObj4["riskTypeTmpId"] = riskCount;
                                    riskCount++;
                                    tmpObj4["name"] = data1.subRisk;
                                    tmpObj4["riskLikelihood"] = riskLikelihood;
                                    tmpObj4["riskImpact"] = riskImpact;
                                    tmpObj4["riskLevel"] = riskLevel;
                                    tmpObj4["riskAppetite"] = riskAppetite;
                                    var tmpObj5 = {};
                                    tmpObj5["riskLikelihood"] = riskLikelihood;
                                    tmpObj5["riskImpact"] = riskImpact;
                                    tmpObj5["riskLevel"] = riskLevel;
                                    tmpObj4["riskLevelData"] = tmpObj5;
                                    tmpObj4["riskAppetite"] = riskAppetite;
                                    var tmpObj6 = {};
                                    tmpObj6["riskAppetite"] = riskAppetite;
                                    tmpObj6["secondaryColor"] = "#ffffff";
                                    tmpObj4["riskAppetiteData"] = tmpObj6;
                                    var tmpComponentData = {};
                                    tmpComponentData["componentName"] = data1.componentName;
                                    var tmpComponentDataObj = {};
                                    tmpComponentDataObj["componentData"] = tmpComponentData;
                                    tmpObj4["riskStandardData"] = tmpComponentDataObj;

                                    if (data1.subSubRisks != undefined && data1.subSubRisks != null && data1.subSubRisks.length > 0) {
                                        var subSubArr = [];
                                        data1.subSubRisks.forEach((data2) => {
                                            var tmpObj7 = {};
                                            tmpObj7["riskTypeTmpId"] = riskCount;
                                            riskCount++;
                                            tmpObj7["name"] = data2.subSubRiskName;
                                            var riskLikelihoodStr = ""
                                            var riskLevelAmt = 1;
                                            if (data2.likelihood != undefined && data2.likelihood != null) {
                                                riskLikelihoodStr = data2.likelihood.toString();
                                                riskLevelAmt = parseInt(data2.likelihood);
                                            } else {
                                                riskLikelihoodStr = riskLikelihood;
                                            }
                                            var riskImpactStr = ""
                                            if (data2.impact != undefined && data2.impact != null) {
                                                riskImpactStr = data2.impact.toString();
                                                riskLevelAmt = riskLevelAmt * (parseInt(data2.impact));
                                            } else {
                                                riskImpactStr = riskImpact;
                                            }

                                            tmpObj7["riskLikelihood"] = riskLikelihoodStr;
                                            tmpObj7["riskImpact"] = riskImpactStr;
                                            tmpObj7["riskLevel"] = riskLevelAmt;
                                            tmpObj7["riskAppetite"] = riskAppetite;
                                            var tmpObj8 = {};
                                            tmpObj8["riskLikelihood"] = riskLikelihoodStr;
                                            tmpObj8["riskImpact"] = riskImpactStr;
                                            tmpObj8["riskLevel"] = riskLevelAmt;
                                            tmpObj7["riskLevelData"] = tmpObj8;
                                            tmpObj7["riskAppetite"] = riskAppetite;
                                            var tmpObj9 = {};
                                            tmpObj9["riskAppetite"] = riskAppetite;
                                            tmpObj9["secondaryColor"] = "#ffffff";
                                            tmpObj7["riskAppetiteData"] = tmpObj9;

                                            var tmpPrincipleData = {};
                                            tmpPrincipleData["principleName"] = data2.principleName;
                                            var tmpPrincipleDataObj = {};
                                            tmpPrincipleDataObj["principleData"] = tmpPrincipleData;
                                            tmpObj7["riskStandardData"] = tmpPrincipleDataObj;



                                            //setCommitArray.push(tmpObj7);

                                            subSubArr.push(tmpObj7);
                                        })
                                        tmpObj4["subRisks"] = subSubArr;
                                    }
                                    subArr.push(tmpObj4);
                                })
                                tmpObj1["subRisks"] = subArr;
                            }
                            mainArr.push(tmpObj1);
                        })
                        riskObj["riskTypes"] = mainArr;

                    }

                }
                console.log(riskObj);
                var data = JSON.stringify(riskObj);
                console.log(data);
                setWaitingOpen(true);
                axios(CONFIG.MAIN_URL + '/risk/save', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data,
                })
                    .then(function (response) {

                        alert("Risk saved successfully");
                        console.log(response.data);
                        setRiskTypeDataForEval(response.data);
                        setCreatedRiskId(response.data.riskId);
                        getAllRisks(globalCentreId);
                        setSelectedRiskEvaluateTitle(response.data.name);
                        setOpenConfirm(false);
                        setEditRiskOrRegister('Edit Risk');
                        if (jsonType === 2) {
                            updateQuestionTemplate(jObj, response.data, response.data.riskId)
                            console.log(questionArray);
                            if (questionArray != undefined && questionArray != null && questionArray.length > 0) {
                                var sendArr = [];
                                questionArray.forEach((arrayData) => {
                                    sendArr.push(arrayData);
                                })
                                var myJSON = JSON.stringify(sendArr);
                                console.log(myJSON);
                                var saveUrl = CONFIG.MAIN_URL + '/question/savequestionlist';
                                axios(saveUrl, {
                                    method: 'post',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    data: myJSON,
                                })
                                    .then(function (resp) {
                                        console.log("++++++++++++++Question Save Return++++++++++");
                                        console.log(resp.data);
                                        console.log(JSON.stringify(resp.data));
                                        setSavedQuestionArray(resp.data)
                                        setWaitingOpen(false);
                                    })
                                    .catch(function (error) {
                                        setWaitingOpen(false);
                                        console.log(error);
                                    });

                            } else {
                                setWaitingOpen(false);
                            }

                        } else {
                            setWaitingOpen(false);
                        }
                    })
                    .catch(function (error) {
                        setWaitingOpen(false);
                        console.log(error);
                        alert("Error saving Risk Type -:" + error);
                        setOpenConfirm(false);
                    });
            }

        } catch (error) {
            alert("Invalid Json in the Text Area. Please correct it and Press validate button again")
            setOpenConfirm(false);
        }
    }
    var questionArray = [];
    function updateQuestionTemplate(sndJson, resJson, riskId) {
        if (sndJson.objectives != undefined && sndJson.objectives != null && sndJson.objectives.length > 0) {
            sndJson.objectives.forEach((data) => {
                if (data.components != undefined && data.components != null && data.components.length > 0) {
                    data.components.forEach((data1) => {
                        if (data1.subSubRisks != undefined && data1.subSubRisks != null && data1.subSubRisks.length > 0) {
                            data1.subSubRisks.forEach((data2) => {
                                var riskName = data2.subSubRiskName;
                                var principleName = data2.principleName;
                                var question = data2.question;
                                var yesScore = data2.yesScore;
                                var noScore = data2.noScore;
                                resJson.riskTypes.forEach((item) => {
                                    if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                                        item.subRisks.forEach((item1) => {
                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                item1.subRisks.forEach((item2) => {
                                                    if (item2.name === riskName && item2.riskStandardData.principleData.principleName === principleName) {
                                                        //console.log(item2);
                                                        var obj = {};

                                                        /** Create Question Object */
                                                        obj["collectionUniqueName"] = "";
                                                        obj["centreId"] = globalCentreId;

                                                        obj["question"] = question;
                                                        obj["questionType"] = "Closeended";
                                                        obj["questionTypeName"] = "Nominal";
                                                        var objSub = {};
                                                        objSub["status"] = "draft";
                                                        obj["metaInfo"] = objSub;
                                                        objSub = {};
                                                        objSub["collectionRiskTypeId"] = item2.riskTypeId + "";
                                                        objSub["collectionRiskId"] = riskId + "";
                                                        objSub["name"] = item2.name;
                                                        objSub["riskLikelihood"] = item2.riskLikelihood;
                                                        objSub["riskImpact"] = item2.riskImpact;
                                                        objSub["riskLevel"] = item2.riskLevel;
                                                        objSub["riskAppetite"] = item2.riskAppetite;
                                                        obj["riskTypes"] = objSub;
                                                        objSub = {};
                                                        objSub["id"] = "";
                                                        objSub["name"] = principleName;
                                                        objSub["principleScore"] = 0;
                                                        obj["principle"] = objSub;

                                                        objSub = {};
                                                        var objSubSub = {};
                                                        objSubSub["name"] = "radioButtonType";
                                                        objSubSub["minValue"] = "0";
                                                        objSubSub["maxValue"] = "0";
                                                        objSub["answerMethod"] = objSubSub;

                                                        var arryTmp = [];

                                                        var tmpObjX1 = {};
                                                        tmpObjX1["riskLevel"] = yesScore;
                                                        tmpObjX1["riskCategoryId"] = "";
                                                        tmpObjX1["riskMethodId"] = "";

                                                        var tmpObjX0 = {};
                                                        tmpObjX0["answerName"] = "Yes";
                                                        tmpObjX0["answerType"] = "radioButtonType";
                                                        tmpObjX0["riskLevel"] = yesScore;
                                                        tmpObjX0["expectedAnswerName"] = "";
                                                        tmpObjX0["riskLevelData"] = tmpObjX1;

                                                        arryTmp.push(tmpObjX0);

                                                        tmpObjX1 = {};
                                                        tmpObjX1["riskLevel"] = noScore;
                                                        tmpObjX1["riskCategoryId"] = "";
                                                        tmpObjX1["riskMethodId"] = "";

                                                        tmpObjX0 = {};
                                                        tmpObjX0["answerName"] = "No";
                                                        tmpObjX0["answerType"] = "radioButtonType";
                                                        tmpObjX0["riskLevel"] = noScore;
                                                        tmpObjX0["expectedAnswerName"] = "";
                                                        tmpObjX0["riskLevelData"] = tmpObjX1;

                                                        arryTmp.push(tmpObjX0);

                                                        objSub["answerList"] = arryTmp;
                                                        obj["answers"] = objSub;

                                                        questionArray.push(obj);
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            })
                        }
                    })
                }
            })
        }
    }


    const handleChangeRiskName = (event) => {
        setRiskName(event.target.value);
    };
    const handleChangeRiskKey = (event) => {
        setRiskKey(event.target.value);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    /** Start Evaluation Section */

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#E2E4EC', padding: '3px 15px', border: '1px solid black', borderRadius: '5PX' }}>
                    <p className="label" style={{ fontWeight: 'bold' }}>{`${label} `}</p>
                    <p className="label">{`Risk Level : ${payload[0].value}`}</p>
                    <p className="label">{`Risk Appetite : ${payload[1].value}`}</p>

                </div>
            );
        }

        return null;
    };

    function PaperComponentCollectionData(props) {
        return (
            <Draggable
                handle="#collection-data-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }
    const [arrayForDisplayDataInTable, setArrayForDisplayDataInTable] = React.useState([])
    const [commitArrayData, setCommitArrayData] = React.useState([])

    function displayRiskAsending() {
        setShowEvalData(true)
        var arr = [];
        if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
            //arr = commitArray.sort(compareRiskTypeOrder);
            arr = commitArray;
        } else {
            arr = commitArrayData;
        }
        setArrayForDisplayDataInTable(arr);
        setCommitArrayData(arr);
        setShowTableType(2)

        setBtnHirachivalClass(classes.btnNormal)
        setBtnGradientClass(classes.btnActive)
        setBtnBarChartClass(classes.btnNormal)
    }



    const [btnHirachivalClass, setBtnHirachivalClass] = React.useState(classes.btnNormal);
    const [btnGradientClass, setBtnGradientClass] = React.useState(classes.btnNormal);
    const [btnBarChartClass, setBtnBarChartClass] = React.useState(classes.btnNormal);

    const [openRiskDataForEval, setOpenRiskDataForEval] = React.useState(false);
    const [openRiskDataForEvalScroll, setOpenRiskDataForEvalScroll] = React.useState('paper');

    const [fullScreenState, setFullScreenState] = React.useState(false);
    const [fullScreenStateRegister, setFullScreenStateRegister] = React.useState(false);
    const [fullScreenStateMsg, setFullScreenStateMsg] = React.useState('Full Screen');
    const [maxWidthState, setMaxWidthState] = React.useState('lg');


    function setMinMaxWindowFunction() {
        if (!fullScreenState) {
            setFullScreenState(true);
            setFullScreenStateMsg('Full Screen Out');
        } else {
            setFullScreenState(false);
            setFullScreenStateMsg('Full Screen');
        }

    }


    const [registerEvaluate, setRegisterEvaluate] = React.useState(false);
    
    
    function evaluateRiskFuction() {
        setRegisterTypeDataForEval({});
        /*
        colorPatterns.forEach((data, index) => {
            if (index === 0) {
                setColorPatternId(data.id);
                setDatabClr(data.colors)
                setTdPresentage(100 / (data.colors.length + 1))
                setColorPatternName(data.name);
                setColorPatternPrimaryColorName(data.primaryColorName);
                setColorPatternSecondaryColorName(data.secondaryColorName);
            }
        })
        setColorSelectDropDownRiskLevelTmp(1)
        setColorSelectDropDownRiskAppetiteTmp(2)
        
        setBtnHirachivalClass(classes.btnNormal)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnNormal)
        
        if (riskRegisterValidate) {
            //getAllRegister(globalCentreId);
            setEditRiskHirachy(false)
            getAllRegisterEval(globalCentreId, selectedRegisteredId);
            setOpenRegisterDataForEval(true)
            setOpenRegisterDataForEvalScroll('paper');
        } else {
            if (createdRiskId === '') {
                alert("No created Risk for evaluation")
                return;
            }
            setOpenRiskDataForEval(true);
            setOpenRiskDataForEvalScroll('paper');
        }
        */
        
        
        if (riskRegisterValidate) {
            setEditRiskHirachy(false)
            getAllRegisterEval(globalCentreId, selectedRegisteredId);
            setRegisterEvaluate(true)
            setOpenRegisterDataForEval(true)
            setOpenRegisterDataForEvalScroll('paper');
        } else {
            if (createdRiskId === '') {
                alert("No created Risk for evaluation")
                return;
            }
            setRegisterEvaluate(false)
            setRegisterTypeDataForEval(riskTypeDataForEval);
            setSelectedRegisterEvaluateTitle(selectedRiskEvaluateTitle);
            setOpenRegisterDataForEval(true)
            setOpenRegisterDataForEvalScroll('paper');
        }


    }


    const [applyFlag, setApplyFlag] = React.useState(false)
    const [showEvalData, setShowEvalData] = React.useState(false)
    const [showTableType, setShowTableType] = React.useState()
    const [allData, setAllData] = React.useState([])

    function applyFunction() {
        setColorSelectDropDownRiskLevel(colorSelectDropDownRiskLevelTmp);
        setColorSelectDropDownRiskAppetite(colorSelectDropDownRiskAppetiteTmp)

        setShowEvalData(true)
        setApplyFlag(true)
        setShowTableType(1)
        setBtnHirachivalClass(classes.btnActive)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnNormal)
    }

    function hierarchicalFunction() {
        setBtnHirachivalClass(classes.btnActive)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnNormal)
    }

    const [colorSelectDropDownRiskLevel, setColorSelectDropDownRiskLevel] = React.useState();
    const [colorSelectDropDownRiskAppetite, setColorSelectDropDownRiskAppetite] = React.useState();
    const [colorSelectDropDownRiskLevelTmp, setColorSelectDropDownRiskLevelTmp] = React.useState();
    const [colorSelectDropDownRiskAppetiteTmp, setColorSelectDropDownRiskAppetiteTmp] = React.useState();


    const handleChangeDropDown = (event) => {
        setColorSelectDropDownRiskLevelTmp(event.target.value);
    };

    const handleChangeDropDownAppetite = (event) => {
        setColorSelectDropDownRiskAppetiteTmp(event.target.value);
    };

    function setOpenRiskDataForEvalfunction() {
        setOpenRiskDataForEval(false)
        setColorSelectDropDownRiskLevel()
        setColorSelectDropDownRiskAppetite()
        setApplyFlag(false)
        setShowEvalData(false)
        setColorPatternId('');
        setColorPatternPrimaryColorName('');
        setColorPatternSecondaryColorName('');
        setDatabClr([]);
        getAllRisks(globalCentreId);
        setFullScreenState(false);
        setFullScreenStateRegister(false);
        setFullScreenStateMsg("Full Screen");
    }

    const [colorPatternId, setColorPatternId] = React.useState('')
    const [colorPatternName, setColorPatternName] = React.useState('')
    const [colorPatternPrimaryColorName, setColorPatternPrimaryColorName] = React.useState('')
    const [colorPatternSecondaryColorName, setColorPatternSecondaryColorName] = React.useState('')
    const [tdPresentage, setTdPresentage] = React.useState()

    const handleChangeColorPattern = (event) => {
        setColorPatternId(event.target.value);
        colorPatterns.forEach((data, index) => {
            if (data.id === event.target.value) {
                console.log(data)
                setDatabClr(data.colors)
                setTdPresentage(100 / (data.colors.length + 1))

                setColorPatternName(data.name);
                setColorPatternPrimaryColorName(data.primaryColorName);
                setColorPatternSecondaryColorName(data.secondaryColorName);
            }
        })
    };

    const [barData, setBarData] = React.useState([]);
    const [commitBarData, setCommitBarData] = React.useState([]);

    function compareRiskTypeOrder(a, b) {
        var nameA = parseInt(a.riskLevel); // ignore upper and lowercase
        var nameB = parseInt(b.riskLevel); // ignore upper and lowercase

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // names must be equal
        return 0;
    }

    const [arrRiskColorState, setArrRiskColorState] = React.useState([]);
    const [arrColorAppState, setArrColorAppState] = React.useState([]);

    const [barChartHeight, setBarChartHeight] = React.useState(600);

    function createBarFunction() {


        var arr = [];
        if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
            //arr = commitArray.sort(compareRiskTypeOrder);
            arr = commitArray;
        } else {
            if (commitBarData != undefined && commitBarData != null && commitBarData.length > 0) {
                arr = commitBarData;
            } else {
                arr = commitArrayData;
            }
        }
        setShowEvalData(true)
        setShowTableType(3)

        if (arr.length <= 10) {
            setBarChartHeight(600)
        } else {
            if (arr.length > 10 && arr.length <= 17) {
                setBarChartHeight(800)
            } else {
                if (arr.length > 17 && arr.length <= 30) {
                    setBarChartHeight(1000)
                } else {
                    setBarChartHeight(1200)
                }
            }
        }

        var arr1 = [];
        var arrRiskColor = []
        var arrColorApp = []

        arr.forEach((data) => {
            var obj = {}
            var name = data.name;
            obj["argument"] = name;
            obj["riskLevel"] = parseInt(data.riskLevel);
            arrRiskColor.push(data.riskColor)
            obj["riskAppetite"] = parseInt(data.riskAppetite);
            obj["riskLevelStr"] = "Risk Level: " + data.riskLevel;      
            obj["riskAppetiteStr"] = "Risk Appetite: " + data.riskAppetite;
            arrColorApp.push(data.appetiteColor)
            arr1.push(obj)
        })
        setCommitBarData(arr);
        console.log(arr1)
        setBarData(arr1);
        setArrRiskColorState(arrRiskColor)
        setArrColorAppState(arrColorApp)

        setBtnHirachivalClass(classes.btnNormal)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnActive)
    }


    const renderCustomizedLabel = (props) => {
        const {
          x, y, width, height, value,
        } = props;
      
      const fireOffset = value.toString().length < 5;
      const offset = fireOffset ? -40 : 80;
      const orgWidth = width > 500 ? 500 : width;
        return (
            <text x={x + orgWidth + offset} y={y + height - 5} fill={fireOffset ? "#285A64" :"#7e96ac"} textAnchor="end">
              {value}
            </text>
        );
      };

      const renderCustomizedLabel1 = (props) => {
        const {
          x, y, width, height, value,
        } = props;
      
      const fireOffset = value.toString().length < 5;
      const offset = fireOffset ? -40 : 100;
      const orgWidth = width > 500 ? 500 : width;
        return (
            <text x={x + orgWidth + offset} y={y + height - 5} fill={fireOffset ? "#285A64" :"#7e96ac"} textAnchor="end">
              {value}
            </text>
        );
      };

    const Root = props => (
        <Legend.Root {...props} sx={{ display: 'flex', margin: 'auto', flexDirection: 'row' }} />
    );
    const Label = props => (
        <Legend.Label {...props} sx={{ whiteSpace: 'nowrap' }} />
    );



    function getSubAvarageRisk(comment) {
        var subTotal = 0
        var avarage = 0;
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            var len = 0;
            var subTotal = 0;
            comment.subRisks.forEach((item) => {
                subTotal = subTotal + parseInt(item.riskLevel);
                len = len + 1;
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    item.subRisks.forEach((item1) => {
                        subTotal = subTotal + parseInt(item1.riskLevel);
                        len = len + 1;
                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                            item1.subRisks.forEach((item2) => {
                                subTotal = subTotal + parseInt(item2.riskLevel);
                                len = len + 1;
                                if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                    item2.subRisks.forEach((item3) => {
                                        subTotal = subTotal + parseInt(item3.riskLevel);
                                        len = len + 1;
                                        if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                            item3.subRisks.forEach((item4) => {
                                                subTotal = subTotal + parseInt(item4.riskLevel);
                                                len = len + 1;
                                                if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                                    item4.subRisks.forEach((item5) => {
                                                        subTotal = subTotal + parseInt(item5.riskLevel);
                                                        len = len + 1;
                                                        if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                            item5.subRisks.forEach((item6) => {
                                                                subTotal = subTotal + parseInt(item6.riskLevel);
                                                                len = len + 1;
                                                                if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {

                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }

            })
            avarage = (subTotal / (len));
            //avarage = Math.round(avarage * 100)/100;
            avarage = Math.round(avarage);
        }
        return avarage;

    }



    function getSubAvarageAppetite(comment) {
        var subTotal = 0
        var avarage = 0;
        if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
            var len = 0;
            var subTotal = 0;
            comment.subRisks.forEach((item) => {
                subTotal = subTotal + parseInt(item.riskAppetite);
                len = len + 1;
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    item.subRisks.forEach((item1) => {
                        subTotal = subTotal + parseInt(item1.riskAppetite);
                        len = len + 1;
                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                            item1.subRisks.forEach((item2) => {
                                subTotal = subTotal + parseInt(item2.riskAppetite);
                                len = len + 1;
                                if (item2.subRisks != undefined && item2.subRisks != null && item2.subRisks.length > 0) {
                                    item2.subRisks.forEach((item3) => {
                                        subTotal = subTotal + parseInt(item3.riskAppetite);
                                        len = len + 1;
                                        if (item3.subRisks != undefined && item3.subRisks != null && item3.subRisks.length > 0) {
                                            item3.subRisks.forEach((item4) => {
                                                subTotal = subTotal + parseInt(item4.riskAppetite);
                                                len = len + 1;
                                                if (item4.subRisks != undefined && item4.subRisks != null && item4.subRisks.length > 0) {
                                                    item4.subRisks.forEach((item5) => {
                                                        subTotal = subTotal + parseInt(item5.riskAppetite);
                                                        len = len + 1;
                                                        if (item5.subRisks != undefined && item5.subRisks != null && item5.subRisks.length > 0) {
                                                            item5.subRisks.forEach((item6) => {
                                                                subTotal = subTotal + parseInt(item6.riskAppetite);
                                                                len = len + 1;
                                                                if (item6.subRisks != undefined && item6.subRisks != null && item6.subRisks.length > 0) {

                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }

            })
            avarage = (subTotal / (len));
            //avarage = Math.round(avarage * 100)/100;
            avarage = Math.round(avarage);
        }
        return avarage;

    }

    function getSubNo(comment, riskId) {
        var subNo = "0";
        riskTypeDataForEval.riskTypes.forEach((data) => {
            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                data.subRisks.forEach((item, index) => {
                    if (item.riskTypeId === riskId) {
                        subNo = (index + 1) + "";
                    }

                })
            }
        })
        if (subNo === "0") {
            riskTypeDataForEval.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((item, index) => {
                        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                            var tmpN0 = getSubRiskSubNo(item.subRisks, riskId, '');
                            if (tmpN0 != "0") {
                                subNo = (index + 1) + '.' + tmpN0;
                            } else {
                                riskTypeDataForEval.riskTypes.forEach((data1) => {
                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                        data1.subRisks.forEach((item1, index1) => {
                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                item1.subRisks.forEach((item2, index2) => {
                                                    item2.subRisks.forEach((item3, index3) => {
                                                        if (item3.riskTypeId === riskId) {
                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1);
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    }
                                })
                                if (tmpN0 === "0") {
                                    riskTypeDataForEval.riskTypes.forEach((data1) => {
                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                            data1.subRisks.forEach((item1, index1) => {
                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                    item1.subRisks.forEach((item2, index2) => {
                                                        item2.subRisks.forEach((item3, index3) => {
                                                            item3.subRisks.forEach((item4, index4) => {
                                                                if (item4.riskTypeId === riskId) {
                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1);
                                                                }
                                                            })
                                                        })
                                                    })
                                                }
                                            })
                                        }
                                    })
                                    if (tmpN0 === "0") {
                                        riskTypeDataForEval.riskTypes.forEach((data1) => {
                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                data1.subRisks.forEach((item1, index1) => {
                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                        item1.subRisks.forEach((item2, index2) => {
                                                            item2.subRisks.forEach((item3, index3) => {
                                                                item3.subRisks.forEach((item4, index4) => {
                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                        if (item5.riskTypeId === riskId) {
                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1);
                                                                        }
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                        if (tmpN0 === "0") {
                                            riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                    data1.subRisks.forEach((item1, index1) => {
                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                            item1.subRisks.forEach((item2, index2) => {
                                                                item2.subRisks.forEach((item3, index3) => {
                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                if (item6.riskTypeId === riskId) {
                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1);
                                                                                }
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                            if (tmpN0 === "0") {
                                                riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                        data1.subRisks.forEach((item1, index1) => {
                                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                item1.subRisks.forEach((item2, index2) => {
                                                                    item2.subRisks.forEach((item3, index3) => {
                                                                        item3.subRisks.forEach((item4, index4) => {
                                                                            item4.subRisks.forEach((item5, index5) => {
                                                                                item5.subRisks.forEach((item6, index6) => {
                                                                                    item6.subRisks.forEach((item7, index7) => {
                                                                                        if (item7.riskTypeId === riskId) {
                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1);
                                                                                        }
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            }
                                                        })
                                                    }
                                                })
                                                if (tmpN0 === "0") {
                                                    riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                            data1.subRisks.forEach((item1, index1) => {
                                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                    item1.subRisks.forEach((item2, index2) => {
                                                                        item2.subRisks.forEach((item3, index3) => {
                                                                            item3.subRisks.forEach((item4, index4) => {
                                                                                item4.subRisks.forEach((item5, index5) => {
                                                                                    item5.subRisks.forEach((item6, index6) => {
                                                                                        item6.subRisks.forEach((item7, index7) => {
                                                                                            item7.subRisks.forEach((item8, index8) => {
                                                                                                if (item8.riskTypeId === riskId) {
                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1);
                                                                                                }
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                    if (tmpN0 === "0") {
                                                        riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                data1.subRisks.forEach((item1, index1) => {
                                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                        item1.subRisks.forEach((item2, index2) => {
                                                                            item2.subRisks.forEach((item3, index3) => {
                                                                                item3.subRisks.forEach((item4, index4) => {
                                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                                        item5.subRisks.forEach((item6, index6) => {
                                                                                            item6.subRisks.forEach((item7, index7) => {
                                                                                                item7.subRisks.forEach((item8, index8) => {
                                                                                                    item8.subRisks.forEach((item9, index9) => {
                                                                                                        if (item9.riskTypeId === riskId) {
                                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1);
                                                                                                        }
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        })
                                                        if (tmpN0 === "0") {
                                                            riskTypeDataForEval.riskTypes.forEach((data1) => {
                                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                    data1.subRisks.forEach((item1, index1) => {
                                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                            item1.subRisks.forEach((item2, index2) => {
                                                                                item2.subRisks.forEach((item3, index3) => {
                                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                                item6.subRisks.forEach((item7, index7) => {
                                                                                                    item7.subRisks.forEach((item8, index8) => {
                                                                                                        item8.subRisks.forEach((item9, index9) => {
                                                                                                            item9.subRisks.forEach((item10, index10) => {
                                                                                                                if (item10.riskTypeId === riskId) {
                                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1) + "." + (index10 + 1);
                                                                                                                }
                                                                                                            })    
                                                                                                        })
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            })

                                                        }

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }


                            }

                        }

                    })
                }
            })

        }
        return subNo;
    }

    function getSubNoReg(comment, riskId) {
        var subNo = "0";
        registerTypeDataForEval.riskTypes.forEach((data) => {
            if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                data.subRisks.forEach((item, index) => {
                    if (item.riskTypeId === riskId) {
                        subNo = (index + 1) + "";
                    }

                })
            }
        })
        if (subNo === "0") {
            registerTypeDataForEval.riskTypes.forEach((data) => {
                if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
                    data.subRisks.forEach((item, index) => {
                        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                            var tmpN0 = getSubRiskSubNo(item.subRisks, riskId, '');
                            if (tmpN0 != "0") {
                                subNo = (index + 1) + '.' + tmpN0;
                            } else {
                                registerTypeDataForEval.riskTypes.forEach((data1) => {
                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                        data1.subRisks.forEach((item1, index1) => {
                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                item1.subRisks.forEach((item2, index2) => {
                                                    item2.subRisks.forEach((item3, index3) => {
                                                        if (item3.riskTypeId === riskId) {
                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1);
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    }
                                })
                                if (tmpN0 === "0") {
                                    registerTypeDataForEval.riskTypes.forEach((data1) => {
                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                            data1.subRisks.forEach((item1, index1) => {
                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                    item1.subRisks.forEach((item2, index2) => {
                                                        item2.subRisks.forEach((item3, index3) => {
                                                            item3.subRisks.forEach((item4, index4) => {
                                                                if (item4.riskTypeId === riskId) {
                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1);
                                                                }
                                                            })
                                                        })
                                                    })
                                                }
                                            })
                                        }
                                    })
                                    if (tmpN0 === "0") {
                                        registerTypeDataForEval.riskTypes.forEach((data1) => {
                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                data1.subRisks.forEach((item1, index1) => {
                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                        item1.subRisks.forEach((item2, index2) => {
                                                            item2.subRisks.forEach((item3, index3) => {
                                                                item3.subRisks.forEach((item4, index4) => {
                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                        if (item5.riskTypeId === riskId) {
                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1);
                                                                        }
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                        if (tmpN0 === "0") {
                                            registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                    data1.subRisks.forEach((item1, index1) => {
                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                            item1.subRisks.forEach((item2, index2) => {
                                                                item2.subRisks.forEach((item3, index3) => {
                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                if (item6.riskTypeId === riskId) {
                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1);
                                                                                }
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                            if (tmpN0 === "0") {
                                                registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                    if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                        data1.subRisks.forEach((item1, index1) => {
                                                            if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                item1.subRisks.forEach((item2, index2) => {
                                                                    item2.subRisks.forEach((item3, index3) => {
                                                                        item3.subRisks.forEach((item4, index4) => {
                                                                            item4.subRisks.forEach((item5, index5) => {
                                                                                item5.subRisks.forEach((item6, index6) => {
                                                                                    item6.subRisks.forEach((item7, index7) => {
                                                                                        if (item7.riskTypeId === riskId) {
                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1);
                                                                                        }
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            }
                                                        })
                                                    }
                                                })
                                                if (tmpN0 === "0") {
                                                    registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                        if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                            data1.subRisks.forEach((item1, index1) => {
                                                                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                    item1.subRisks.forEach((item2, index2) => {
                                                                        item2.subRisks.forEach((item3, index3) => {
                                                                            item3.subRisks.forEach((item4, index4) => {
                                                                                item4.subRisks.forEach((item5, index5) => {
                                                                                    item5.subRisks.forEach((item6, index6) => {
                                                                                        item6.subRisks.forEach((item7, index7) => {
                                                                                            item7.subRisks.forEach((item8, index8) => {
                                                                                                if (item8.riskTypeId === riskId) {
                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1);
                                                                                                }
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                    if (tmpN0 === "0") {
                                                        registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                            if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                data1.subRisks.forEach((item1, index1) => {
                                                                    if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                        item1.subRisks.forEach((item2, index2) => {
                                                                            item2.subRisks.forEach((item3, index3) => {
                                                                                item3.subRisks.forEach((item4, index4) => {
                                                                                    item4.subRisks.forEach((item5, index5) => {
                                                                                        item5.subRisks.forEach((item6, index6) => {
                                                                                            item6.subRisks.forEach((item7, index7) => {
                                                                                                item7.subRisks.forEach((item8, index8) => {
                                                                                                    item8.subRisks.forEach((item9, index9) => {
                                                                                                        if (item9.riskTypeId === riskId) {
                                                                                                            subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1);
                                                                                                        }
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        })
                                                        if (tmpN0 === "0") {
                                                            registerTypeDataForEval.riskTypes.forEach((data1) => {
                                                                if (data1.subRisks != undefined && data1.subRisks != null && data1.subRisks.length > 0) {
                                                                    data1.subRisks.forEach((item1, index1) => {
                                                                        if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                                                                            item1.subRisks.forEach((item2, index2) => {
                                                                                item2.subRisks.forEach((item3, index3) => {
                                                                                    item3.subRisks.forEach((item4, index4) => {
                                                                                        item4.subRisks.forEach((item5, index5) => {
                                                                                            item5.subRisks.forEach((item6, index6) => {
                                                                                                item6.subRisks.forEach((item7, index7) => {
                                                                                                    item7.subRisks.forEach((item8, index8) => {
                                                                                                        item8.subRisks.forEach((item9, index9) => {
                                                                                                            item9.subRisks.forEach((item10, index10) => {
                                                                                                                if (item10.riskTypeId === riskId) {
                                                                                                                    subNo = (index1 + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1) + "." + (index5 + 1) + "." + (index6 + 1) + "." + (index7 + 1) + "." + (index8 + 1) + "." + (index9 + 1) + "." + (index10 + 1);
                                                                                                                }
                                                                                                            })    
                                                                                                        })
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        }
                                                                    })
                                                                }
                                                            })

                                                        }

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }


                            }

                        }

                    })
                }
            })

        }
        return subNo;
    }



    function getSubRiskSubNo(comment, riskId, subNo) {
        var no = "0"
        comment.forEach((data, index) => {
            if (data.riskTypeId === riskId) {
                no = subNo + (index + 1) + '';
            }
        });
        if (no === "0") {
            comment.forEach((item, index) => {
                if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
                    getSubRiskSubNo(item.subRisks, riskId, (index + 1));
                }

            })
        }
        return no;
    }

    const [lastRiskTmpId, setLastRiskTmpId] = React.useState(0);
    const [riskCategoryMethodData, setRiskCategoryMethodData] = React.useState([])
    const [riskCategoryId, setRiskCategoryId] = React.useState('')
    const [riskCategoryMethodDataAppetite, setRiskCategoryMethodDataAppetite] = React.useState([])
    const [riskCategoryIdAppetite, setRiskCategoryIdAppetite] = React.useState('')



    var commitArray = []
    function pushToArray(item) {
        commitArray.push(item);
        console.log("+++++++++++++++++++++commitArray+++++++++++++++");
        console.log(commitArray);
    }


    function Comment({ comment, lastRiskTmpId, riskNo, fromMainMenu, fromSubMenu }) {

        console.log(comment);

        const [subLastRiskTmpId, setSubLastRiskTmpId] = React.useState(lastRiskTmpId);
        const [subRiskNo, setSubRiskNo] = React.useState(riskNo);
        const [show, setShow] = React.useState(true)
        var noDisplay = '';
        var noDisplaySpacesCount = 0;

        var subAvarageRisk = 0;
        var subAvarageAppetite = 0;
        if (fromMainMenu) {
            fromMainMenu = false;
            noDisplay = riskNo;
            subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageRisk = getSubAvarageRisk1(comment);
            subAvarageAppetite = getSubAvarageAppetite(comment);
        }
        if (fromSubMenu) {
            fromSubMenu = false;
            var subNo = getSubNo(comment, comment.riskTypeId)
            noDisplay = riskNo + '.' + subNo;
            var tmpArr = noDisplay.split(".");
            if (tmpArr.length === 2) {
                noDisplaySpacesCount = 1;
            }
            if (tmpArr.length === 3) {
                noDisplaySpacesCount = 2;
            }
            if (tmpArr.length === 4) {
                noDisplaySpacesCount = 3;
            }
            if (tmpArr.length === 5) {
                noDisplaySpacesCount = 4;
            }
            if (tmpArr.length === 6) {
                noDisplaySpacesCount = 5;
            }
            if (tmpArr.length === 7) {
                noDisplaySpacesCount = 6;
            }
            if (tmpArr.length === 8) {
                noDisplaySpacesCount = 7;
            }
            if (tmpArr.length === 9) {
                noDisplaySpacesCount = 8;
            }
            if (tmpArr.length === 10) {
                noDisplaySpacesCount = 9;
            }
            if (tmpArr.length === 11) {
                noDisplaySpacesCount = 10;
            }


            subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageRisk = getSubAvarageRisk1(comment);
            subAvarageAppetite = getSubAvarageAppetite(comment);
        }
        const nestedComments = (comment.subRisks || []).map(comment => {
            return <Comment key={comment.riskTypeTmpId} comment={comment} lastRiskTmpId={subLastRiskTmpId} riskNo={subRiskNo} fromSubMenu={true} type="child" />
        })



        /** Need */
        function getRiskRiskTypeName(Comment) {
            var rName = ''
            riskCategoryMethodData.forEach((data) => {
                if (data.id === Comment.riskLevelData.riskMethodId) {
                    data.riskTypes.forEach((data1) => {
                        if (data1.startingNumber <= Comment.riskLevel && Comment.riskLevel <= data1.endingNumber) {
                            rName = data1.riskTypesName
                        }
                    })
                }

            })
            return rName;
        }

        /** Need */
        function getAppetiteRiskTypeName(Comment) {
            var aName = ''
            riskCategoryMethodDataAppetite.forEach((data) => {
                if (data.id === Comment.riskAppetiteData.riskMethodId) {
                    data.riskTypes.forEach((data1) => {
                        if (data1.startingNumber <= Comment.riskAppetite && Comment.riskAppetite <= data1.endingNumber) {
                            aName = data1.riskTypesName
                        }
                    })
                }

            })
            return aName;
        }


        /** Need */
        function getRiskRiskColor(Comment) {
            var rColor = ''
            var lastNumber = ''
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rColor = data2.color
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rColor = data2.secondaryColor
                        }
                    }
                }
            })
            if (rColor === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.color
                            }
                        } else if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.secondaryColor
                            }
                        }
                    }
                })
            }
            return rColor;
        }


        function getRiskRiskAction(Comment) {
            var rAction = ''
            var lastNumber = ''
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rAction = data2.primaryColorName
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rAction = data2.secondaryColorName
                        }
                    }
                }
            })
            if (rAction === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rAction = data2.primaryColorName
                            }
                        } else if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rAction = data2.secondaryColorName
                            }
                        }
                    }
                })
            }
            return rAction;
        }


        function getRiskRiskColorAve(avarageRisk) {
            var rColor = ''
            var lastNumber = '';
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageRisk)) {
                            rColor = data2.secondaryColor;
                        }
                    } else if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageRisk)) {
                            rColor = data2.color;
                        }
                    }
                }
            })
            if (rColor === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.secondaryColor;
                            }
                        } else if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.color;
                            }
                        }
                    }
                })
            }
            return rColor;
        }





        /** Need */
        function getAppetiteColor(Comment) {
            var aColor = ''
            var colorFg1 = ''

            databClr.forEach((data2) => {
                if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
                    //console.log("++++++++++++++data2+++++++++++++++")
                    //console.log(data2)
                    //console.log(applyFlag)
                    //console.log(Comment.riskAppetiteData)
                    //console.log(colorSelectDropDownRiskAppetite)

                    if (Comment.riskAppetiteData != null && Comment.riskAppetiteData != undefined) {
                        /*
                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (Comment.riskAppetiteData.color != null && Comment.riskAppetiteData.color != undefined && Comment.riskAppetiteData.color != '') {
                                    aColor = Comment.riskAppetiteData.color;
                                }
                            }
                            else if (colorSelectDropDownRiskAppetite === 2) {
                                if (Comment.riskAppetiteData.secondaryColor != null && Comment.riskAppetiteData.secondaryColor != undefined && Comment.riskAppetiteData.secondaryColor != '') {
                                    aColor = Comment.riskAppetiteData.secondaryColor;

                                }
                            }
                        }
                        */

                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (aColor === '') {
                                    aColor = data2.color
                                }
                            }
                            if (colorSelectDropDownRiskAppetite === 2) {
                                if (aColor === '') {
                                    aColor = data2.secondaryColor
                                }
                            }
                        }
                    }
                }
            })
            return aColor;
        }


        function getRiskAppetiteAction(Comment) {
            var aColor = ''
            var colorFg1 = ''

            databClr.forEach((data2) => {
                if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
                    //console.log("++++++++++++++data2+++++++++++++++")
                    //console.log(data2)
                    //console.log(applyFlag)
                    //console.log(Comment.riskAppetiteData)
                    //console.log(colorSelectDropDownRiskAppetite)

                    if (Comment.riskAppetiteData != null && Comment.riskAppetiteData != undefined) {
                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (aColor === '') {
                                    aColor = data2.primaryColorName
                                }
                            }
                            if (colorSelectDropDownRiskAppetite === 2) {
                                if (aColor === '') {
                                    aColor = data2.secondaryColorName
                                }
                            }
                        }
                    }
                }
            })
            return aColor;
        }

        function getAppetiteColorAve(avarageAppetite) {
            var aColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskAppetite === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageAppetite)) {
                            aColor = data2.secondaryColor;
                        }
                    } else if (colorSelectDropDownRiskAppetite === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageAppetite)) {
                            aColor = data2.color;
                        }
                    }
                }


            })
            return aColor;



        }


        /** Need */
        function getRiskLikeliColor(Comment) {

            var rColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLikelihood)) {
                            rColor = data2.color
                            //setApplyFlag(false)
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLikelihood)) {
                            rColor = data2.secondaryColor
                            //setApplyFlag(false)
                        }
                    }
                }

            })
            return rColor;
        }

        /** Need */
        function getRiskImpactColor(Comment) {

            var rColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskImpact)) {
                            rColor = data2.color
                            //setApplyFlag(false)
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskImpact)) {
                            rColor = data2.secondaryColor
                            //setApplyFlag(false)
                        }
                    }
                }

            })
            return rColor;
        }

        var riskLikelihood = getRiskLikeliColor(comment)
        var appetiteColor = getAppetiteColor(comment);
        var appetiteAction = getRiskAppetiteAction(comment);
        var riskColor = getRiskRiskColor(comment);
        var riskAction = getRiskRiskAction(comment);
        var riskImpact = getRiskImpactColor(comment)
        var riskColorAverage = getRiskRiskColorAve(subAvarageRisk);
        var appetiteColorAverage = getAppetiteColorAve(subAvarageAppetite)

        /** Need */
        function OnlyRiskLvl({ comment }) {
            var riskName = getRiskRiskTypeName(comment)
            var riskColor = getRiskRiskColor(comment)
            return (
                <>

                    {comment.name} {" - (Risk : "}{' '} {comment.riskLevel}{" - "}
                    {
                        <span style={{ color: `${riskColor}` }}><b>{riskName}</b>  </span>
                    }

                    {" ) "}




                </>
            )
        }
        /** Need */
        function OnlyAppetiteLvl({ comment }) {
            var appetiteName = getAppetiteRiskTypeName(comment)
            var appetiteColor = getAppetiteColor(comment)
            return (
                <>

                    {comment.name} {" - ( Appetite : "} {appetitesSliderValue} {" - "}
                    {
                        <span style={{ color: `${appetiteColor}` }}> <b>{appetiteName}</b> </span>
                    }
                    {" ) "}




                </>
            )
        }
        /** Need */
        function DisplayStandared({ comment }) {
            console.log("+++++++++++++Athula+++++++comment++++++++++++++++++++++")
            console.log(comment)
            var objectiveId = '';
            var objectiveName = '';
            var componentId = '';
            var componentName = '';
            var principleId = '';
            var principleName = '';
            var standardId = '';
            var standardName = '';
            if (comment.riskStandardData != undefined && comment.riskStandardData != null) {
                if (comment.riskStandardData.objectiveData != undefined && comment.riskStandardData.objectiveData != null) {
                    objectiveId = comment.riskStandardData.objectiveData.objectiveId;
                    objectiveName = comment.riskStandardData.objectiveData.objectiveName;
                    standardId = comment.riskStandardData.objectiveData.standardId;
                    standardName = comment.riskStandardData.objectiveData.standardName;
                }
                if (comment.riskStandardData.componentData != undefined && comment.riskStandardData.componentData != null) {
                    componentId = comment.riskStandardData.componentData.componentId;
                    componentName = comment.riskStandardData.componentData.componentName;
                    if (standardId === '') {
                        standardId = comment.riskStandardData.componentData.standardId;
                    }
                    if (standardName === '') {
                        standardName = comment.riskStandardData.componentData.standardName;
                    }
                }
                if (comment.riskStandardData.principleData != undefined && comment.riskStandardData.principleData != null) {
                    principleId = comment.riskStandardData.principleData.principleId;
                    principleName = comment.riskStandardData.principleData.principleName;
                    if (standardId === '') {
                        standardId = comment.riskStandardData.principleData.standardId;
                    }
                    if (standardName === '') {
                        standardName = comment.riskStandardData.principleData.standardName;
                    }
                }
            }
            var altString = 'Standard Name : ' + standardName;
            if (objectiveName != '') {
                altString += '\nObjective Name : ' + objectiveName;
            }
            if (componentName != '') {
                altString += '\nComponent Name : ' + componentName;
            }
            if (principleName != '') {
                altString += '\nPrinciple Name : ' + principleName;
            }
            if (standardName.length > 10) {
                standardName = standardName.substring(0, 10) + '...';
            }

            console.log("++++++++++++++++++++++++++++altString+++++++++++++++++++++++");
            console.log(altString);
            console.log(standardName);

            return (
                <>

                    {
                        <span title={`${altString}`} style={{ cursor: 'pointer' }}>&nbsp;{standardName}</span>
                    }

                </>
            )
        }
        comment["riskColor"] = riskColor;
        comment["appetiteColor"] = appetiteColor;
        var createArr = pushToArray(comment);





        return (
            <>
                <Grid item xs={4}>
                    {noDisplaySpacesCount === 0 ?
                        <></>
                        : noDisplaySpacesCount === 1 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;</>
                            : noDisplaySpacesCount === 2 ?
                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                : noDisplaySpacesCount === 3 ?
                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                    : noDisplaySpacesCount === 4 ?
                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                        : noDisplaySpacesCount === 5 ?
                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                            : noDisplaySpacesCount === 6 ?
                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                : noDisplaySpacesCount === 7 ?
                                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                    : noDisplaySpacesCount === 8 ?
                                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                        : noDisplaySpacesCount === 9 ?
                                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                            : noDisplaySpacesCount === 10 ?
                                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                                : ''
                    }
                    <Button
                        style={{ width: '0px', height: '0px' }}
                        onClick={() => setShow(!show)}
                        startIcon={show ? <ExpandMoreIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} /> : <ChevronRightIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} />}
                    >
                    </Button>
                    {noDisplay}{') '}{comment.name}
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLikelihood}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskImpact}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLevel}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskColorAverage}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{subAvarageRisk}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px' }}><p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '9px' }}>{riskAction}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${appetiteColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskAppetite}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${appetiteColorAverage}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{subAvarageAppetite}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px' }}><p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '9px' }}>{appetiteAction}</p></div></span>
                </Grid>

                {/*riskDisplayStr*/}

                {
                    show ?
                        <>
                            {nestedComments}
                        </>
                        : null
                }
            </>

        )
    }
    const [appetitesSliderValue, setAppetiteSliderValue] = React.useState();

    /** End of Evaluation Section */

    /** Start Edit Section Section */

    const [openRiskDataEdit, setOpenRiskDataEdit] = React.useState(false);
    const [openRiskDataEditScroll, setOpenRiskDataEditScroll] = React.useState('paper');

    const [openRegisterEdit, setOpenRegisterEdit] = React.useState(false);
    const [openRegisterEditScroll, setOpenRegisterEditScroll] = React.useState('paper');

    function editRiskFuction() {
        if (riskRegisterValidate) {
            setOpenRegisterEdit(true)
            setOpenRegisterEditScroll('paper');
        } else {
            if (createdRiskId === '') {
                alert("No created Risk for Edit")
                return;
            }
            setOpenRiskDataEdit(true);
            setOpenRiskDataEditScroll('paper');
        }
    }

    function setOpenRiskDataEditfunction() {
        setOpenRiskDataEdit(false)
        getAllRisks(globalCentreId);
    }

    /** End of Edit Section */

    function PaperComponentAllQuestionData(props) {
        return (
            <Draggable
                handle="#collection-data-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    const [rows, setRows] = React.useState([]);
    const [openShowQuestions, setOpenShowQuestions] = React.useState(false);
    const [openShowQuestionsScroll, setOpenShowQuestionsScroll] = React.useState('paper');

    function showQuestionFuction() {
        var count = 1;
        var tmpArray = [];
        setRows([]);
        savedQuestionArray.forEach((item) => {
            console.log(item);
            var tmpObj = {};
            tmpObj["questionId"] = item.questionId;
            tmpObj["id"] = count;
            tmpObj["riskName"] = item.riskTypes.name;
            tmpObj["principalName"] = item.principle.name;
            tmpObj["question"] = item.question;
            tmpObj["questionType"] = item.questionType;
            tmpObj["questionData"] = item;
            tmpArray.push(tmpObj);
            setRows(tmpArray);
            count++;
        })
        setOpenShowQuestions(true);
        setOpenShowQuestionsScroll('paper');
    }


    const [selectedQuesData, setSelectedQuesData] = React.useState([]);
    const [selectedQuestion, setSelectedQuestion] = React.useState('');
    const [quesDataCount, setQuesDataCount] = React.useState(0);
    const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
    const [questionDetailScroll, setQuestionDetailScroll] = React.useState('paper');


    function PaperComponent(props) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    const questionDetailHandleClickClose = () => {
        setQuestionDetailOpen(false);
    };


    function parseId(no, questionId, question) {
        var tmpArray = [];
        setQuesDataCount(no);
        setSelectedQuestion(question);
        savedQuestionArray.forEach((item) => {
            if (item.questionId === questionId) {
                tmpArray.push(item);
                setSelectedQuesData(tmpArray);
            }
        });
        setQuestionDetailOpen(true);
        setQuestionDetailScroll('paper');
    }

    const renderDetailsButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                        parseId(params.row.id, params.row.questionId, params.row.question)
                    }}
                >
                    More Info
                </Button>
            </strong>
        )
    }

    const renderTextRiskName = (params) => {
        return (
            <Tooltip1 title={params.row.riskName}>
                <span style={{ fontSize: '10px' }}>{params.row.riskName}</span>
            </Tooltip1>
        )
    }
    const renderTextId2 = (params) => {
        return (
            <span >{params.row.id}</span>
        )
    }
    const renderTextPrincipalName = (params) => {
        return (
            <Tooltip1 title={params.row.principalName}>
                <span style={{ fontSize: '10px' }}>{params.row.principalName}</span>
            </Tooltip1>
        )
    }
    const renderTextQuestion2 = (params) => {
        return (
            <Tooltip1 title={params.row.question}>
                <span style={{ fontSize: '10px' }}>{params.row.question}</span>
            </Tooltip1>
        )
    }

    const renderTextQuestionType2 = (params) => {
        return (
            <span style={{ fontSize: '10px' }}>{params.row.questionType}</span>
        )
    }

    const [openQuestionEdit, setOpenQuestionEdit] = React.useState(false);
    const [openQuestionEditScroll, setOpenQuestionEditScroll] = React.useState('paper');
    const [selectedQuestionName, setSelectedQuestionName] = React.useState('');
    const [selectedQuestionId, setSelectedQuestionId] = React.useState('');


    function editQuestion(questionId, question) {
        setSelectedQuestionName(question);
        setSelectedQuestionId(questionId);
        setOpenQuestionEdit(true);
        setOpenQuestionEditScroll('paper');
    }


    const renderEditButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                        editQuestion(params.row.questionId, params.row.question)
                    }}
                >
                    Edit
                </Button>
            </strong>
        )
    }



    const columns = [
        { field: 'id', headerName: 'No', renderCell: renderTextId2, resizable: true, width: 15 },
        { field: 'questionId', headerName: 'No', width: 15, hide: true },
        { field: 'riskName', headerName: 'Risk', renderCell: renderTextRiskName, resizable: true, cellClassName: 'super-app-theme--cell', width: 240 },
        { field: 'principalName', headerName: 'Principal', renderCell: renderTextPrincipalName, resizable: true, cellClassName: 'super-app-theme--cell', width: 200 },
        { field: 'question', headerName: 'Question', renderCell: renderTextQuestion2, cellClassName: 'super-app-theme--cell', resizable: true, width: 400 },
        { field: 'questionType', headerName: 'Type', renderCell: renderTextQuestionType2, resizable: true, width: 80 },
        {
            field: 'action',
            headerName: 'More Info',
            sortable: false,
            renderCell: renderDetailsButton,
            disableClickEventBubbling: true,

        },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            renderCell: renderEditButton,
            disableClickEventBubbling: true,

        }

    ];



    /** For Evaluate Regsister */
    const [openRegisterDataForEval, setOpenRegisterDataForEval] = React.useState(false);
    const [openRegisterDataForEvalScroll, setOpenRegisterDataForEvalScroll] = React.useState('paper');
    const [showTranferExlButton, setShowTranferExlButton] = React.useState(false)

    function PaperComponentCollectionDataRegister(props) {
        return (
            <Draggable
                handle="#register-data-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }
    function setMinMaxWindowFunctionRegister() {
        if (!fullScreenState) {
            setFullScreenState(true);
            setFullScreenStateMsg('Full Screen Out');
        } else {
            setFullScreenState(false);
            setFullScreenStateMsg('Full Screen');
        }

    }

    function RegisterDataForEvalfunctionClose() {
        setOpenRegisterDataForEval(false)
        setDatabClr([]);
        setBarData([]);
        setCommitBarData([])
        setColorPatternPrimaryColorName('');
        setColorPatternSecondaryColorName('');
        setColorSelectDropDownRiskLevel()
        setColorSelectDropDownRiskAppetite()
        setApplyFlag(false)
        setShowEvalData(false)
        setShowTranferExlButton(false)
    }

    function applyFunctionRegister() {
        setColorSelectDropDownRiskLevel(colorSelectDropDownRiskLevelTmp);
        setColorSelectDropDownRiskAppetite(colorSelectDropDownRiskAppetiteTmp)

        setShowEvalData(true)
        setApplyFlag(true)
        setShowTableType(1)
        setShowTranferExlButton(true)

        setBtnHirachivalClass(classes.btnActive)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnNormal)

    }

    function hierarchicalFunctionRegister() {
        setBtnHirachivalClass(classes.btnActive)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnNormal)
    }

    function allRegisterArr() {
        var arr = []
        allRegister.forEach((data) => {
            if (data.id === selectedRegisteredId) {
                data.riskRegister.forEach((data1) => {
                    arr.push(data1)
                    data1.risks.forEach((risk) => {
                        var obj = {}
                        obj["section"] = risk.name
                        obj["riskLevel"] = risk.riskLevel
                        obj["riskAppetite"] = risk.riskAppetite

                        arr.push(obj)
                    })
                })
            }
        })
        console.log(arr)

    }


    function displayRiskAsendingRegister() {
        setShowEvalData(true)

        var arr = [];
        if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
            //arr = commitArray.sort(compareRiskTypeOrder);
            arr = commitArray
        } else {
            arr = commitArrayData;
        }
        setArrayForDisplayDataInTable(arr);
        setCommitArrayData(arr);
        setShowTableType(2)

        /*
        var arr = []
        allRegister.forEach((data) => {
            if (data.id === registerId) {
                data.riskRegister.forEach((data1) => {
                    arr.push(data1)
                    data1.risks.forEach((risk) => {
                        var obj = {}
                        obj["section"] = risk.name
                        obj["riskLevel"] = risk.riskLevel
                        obj["riskAppetite"] = risk.riskAppetite

                        arr.push(obj)
                    })
                })
            }
        })

        allRegisterArr()
        setArrayForDisplayDataInTable(arr);
        setCommitArrayData(arr);
        setShowTableType(2)
        */

        setBtnHirachivalClass(classes.btnNormal)
        setBtnGradientClass(classes.btnActive)
        setBtnBarChartClass(classes.btnNormal)

    }

    function createBarFunctionRegister() {

        var arr = [];
        if (commitArray != undefined && commitArray != null && commitArray.length > 0) {
            //arr = commitArray.sort(compareRiskTypeOrder);
            arr = commitArray
        } else {
            if (commitBarData != undefined && commitBarData != null && commitBarData.length > 0) {
                arr = commitBarData;
            } else {
                arr = commitArrayData;
            }
        }

        setShowEvalData(true)
        setShowTableType(3)


        if (arr.length <= 10) {
            setBarChartHeight(600)
        } else {
            if (arr.length > 10 && arr.length <= 17) {
                setBarChartHeight(800)
            } else {
                if (arr.length > 17 && arr.length <= 30) {
                    setBarChartHeight(1000)
                } else {
                    if (arr.length > 30 && arr.length <= 40) {
                        setBarChartHeight(1500)
                    } else {
                        if (arr.length > 40 && arr.length <= 60) {
                            setBarChartHeight(2200)
                        } else {
                            setBarChartHeight(3500)
                        }
                    }
                }
            }
        }


        var arr1 = [];
        var arrRiskColor = []
        var arrColorApp = []

        arr.forEach((data) => {
            var obj = {}
            var name = data.name;
            obj["argument"] = name;
            obj["riskLevel"] = parseInt(data.riskLevel);
            arrRiskColor.push(data.riskColor)
            obj["riskAppetite"] = parseInt(data.riskAppetite);
            obj["riskLevelStr"] = "Risk Level: " + data.riskLevel;      
            obj["riskAppetiteStr"] = "Risk Appetite: " + data.riskAppetite;
            arrColorApp.push(data.appetiteColor)
            arr1.push(obj)
        })
        setCommitBarData(arr);
        console.log(arr1)
        setBarData(arr1);
        setArrRiskColorState(arrRiskColor)
        setArrColorAppState(arrColorApp)


        setBtnHirachivalClass(classes.btnNormal)
        setBtnGradientClass(classes.btnNormal)
        setBtnBarChartClass(classes.btnActive)

    }


    function riskLevelcolorFunction(risklevel){
        var riskColor = ''
        var lastScoreColor = ''
        var lastScoreColorSecondary = ''
        colorPatterns.map((pattern) => {
            if (pattern.id === colorPatternId) {
                pattern.colors.map((clr) => {
                    lastScoreColor = clr.color
                    lastScoreColorSecondary = clr.secondaryColor
                    if (colorSelectDropDownRiskLevelTmp === 1) {
                        if (clr.scoreNumber === parseInt(risklevel)) {
                            riskColor = clr.color
                        }
                    }else if(colorSelectDropDownRiskLevelTmp === 2){
                        if (clr.scoreNumber === parseInt(risklevel)) {
                            riskColor = clr.secondaryColor
                        }
                    }


                })
            }
        })
         if (riskColor === '') {
            if (colorSelectDropDownRiskLevelTmp === 1) {
                riskColor = lastScoreColor
            }else{
                riskColor = lastScoreColorSecondary
            }
         }
        return riskColor
    }

    function riskApopetitecolorFunction(riskApp){
        var riskColor = ''
        var lastScoreColor = ''
        var lastScoreColorSecondary = ''
        colorPatterns.map((pattern) => {
            if (pattern.id === colorPatternId) {
                pattern.colors.map((clr) => {
                    lastScoreColor = clr.color
                    lastScoreColorSecondary = clr.secondaryColor
                    if (colorSelectDropDownRiskAppetiteTmp === 1) {
                        if (clr.scoreNumber === parseInt(riskApp)) {
                            riskColor = clr.color
                        }
                        if (riskColor === '') {
                            riskColor = lastScoreColor
                        }
                    }else if(colorSelectDropDownRiskAppetiteTmp === 2){
                        if (clr.scoreNumber === parseInt(riskApp)) {
                            riskColor = clr.secondaryColor
                        }
                        if (riskColor === '') {
                            riskColor = lastScoreColorSecondary
                        }
                        
                    }


                })
            }
        })
        // if (riskColor === '') {
        //     riskColor = lastScoreColor
        // }
        return riskColor
    }

    function riskLevelActionFunction(level){
        var riskAction = ''
        var lastScoreAction = ''
        var lastScoreActionSecondary = ''
        colorPatterns.map((pattern) => {
            if (pattern.id === colorPatternId) {
                pattern.colors.map((clr) => {
                    lastScoreAction = clr.primaryColorName
                    lastScoreActionSecondary = clr.secondaryColorName
                    if (colorSelectDropDownRiskLevelTmp === 1) {
                        if (clr.scoreNumber === parseInt(level)) {
                            riskAction = clr.primaryColorName
                        }
                        if (riskAction === '') {
                            riskAction = lastScoreAction
                        }
                    }else if(colorSelectDropDownRiskLevelTmp === 2){
                        if (clr.scoreNumber === parseInt(level)) {
                            riskAction = clr.secondaryColorName
                        }
                        if (riskAction === '') {
                            riskAction = lastScoreActionSecondary
                        }
                        
                    }


                })
            }
        })
        // if (riskColor === '') {
        //     riskColor = lastScoreColor
        // }
        return riskAction
    }

    function riskApopetiteActionFunction(appetite){
        var riskAction = ''
        var lastScoreAction = ''
        var lastScoreActionSecondary = ''
        colorPatterns.map((pattern) => {
            if (pattern.id === colorPatternId) {
                pattern.colors.map((clr) => {
                    lastScoreAction = clr.primaryColorName
                    lastScoreActionSecondary = clr.secondaryColorName
                    if (colorSelectDropDownRiskLevelTmp === 1) {
                        if (clr.scoreNumber === parseInt(appetite)) {
                            riskAction = clr.primaryColorName
                        }
                        if (riskAction === '') {
                            riskAction = lastScoreAction
                        }
                    }else if(colorSelectDropDownRiskLevelTmp === 2){
                        if (clr.scoreNumber === parseInt(appetite)) {
                            riskAction = clr.secondaryColorName
                        }
                        if (riskAction === '') {
                            riskAction = lastScoreActionSecondary
                        }
                        
                    }


                })
            }
        })

        return riskAction
    }

    const [editRiskHirachy, setEditRiskHirachy] = React.useState(false);
    function showDataInAViewFunction(no){
       
        setEditRiskHirachy(true);
        setWaitingOpen(true);
        setTimeout(() => {
            setWaitingOpen(false);
        }, 500)
        
    }



    function CommentRegister({ comment, lastRiskTmpId, riskNo, fromMainMenu, fromSubMenu, editRiskHirachyData }) {

        console.log("++++++++++++++++++++comment++++++++++++++++++++++");
        console.log(editRiskHirachyData);

        const [subLastRiskTmpId, setSubLastRiskTmpId] = React.useState(lastRiskTmpId);
        const [subEditRiskHirachyData, setSubEditRiskHirachyData] = React.useState(editRiskHirachyData);
        const [subRiskNo, setSubRiskNo] = React.useState(riskNo);
        const [show, setShow] = React.useState(true)

        var noDisplay = '';
        var noDisplaySpacesCount = 0;


        function onTxtNameChageEvent(event) {
            comment["name"] = event.target.value
        }
        function onBlurChageEvent(event) {
            comment["name"] = event.target.value
        }


        const onTextFieldChangeLikelihood = (event) => {
            if (event != null && event.target.value != null) {
                var riskLevelVal = 1;
                var riskImpactVal = 1;
                if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                    event.target.value = '';
                    if (comment.riskImpact != undefined && comment.riskImpact != null && comment.riskImpact != '') {
                        riskImpactVal = parseInt(comment.riskImpact);
                    }
                } else {
                    if (comment.riskImpact != undefined && comment.riskImpact != null && comment.riskImpact != '') {
                        riskImpactVal = parseInt(comment.riskImpact);
                    }
                    riskLevelVal = parseInt(event.target.value)
                }
                comment["riskLikelihood"] = event.target.value;
                var rLevel = riskImpactVal * riskLevelVal;
                comment["riskLevel"] = rLevel.toString();
              //  comment.riskLevelData["riskLikelihood"] = event.target.value;
              //  comment.riskLevelData["riskLevel"] = rLevel.toString();
                var btn = document.getElementById("btn" + comment.riskTypeTmpId);
                btn.click();
                setTimeout(() => {
                    btn.click();
                }, 10);

            }
        }

        const onTextFieldChangeImpact = (event) => {
            if (event != null && event.target.value != null) {
                var riskLevelVal = 1;
                var riskImpactVal = 1;
                if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                    event.target.value = ''
                    if (comment.riskLikelihood != undefined && comment.riskLikelihood != null && comment.riskLikelihood != '') {
                        riskLevelVal = parseInt(comment.riskLikelihood);
                    }
                } else {
                    if (comment.riskLikelihood != undefined && comment.riskLikelihood != null && comment.riskLikelihood != '') {
                        riskLevelVal = parseInt(comment.riskLikelihood);
                    }
                    riskImpactVal = parseInt(event.target.value)
                }
                comment["riskImpact"] = event.target.value;
                //comment.riskLevelData["riskImpact"] = event.target.value;
                var rLevel = riskImpactVal * riskLevelVal;
                comment["riskLevel"] = rLevel.toString();
              //  comment.riskLevelData["riskLevel"] = rLevel.toString();
                var btn = document.getElementById("btn" + comment.riskTypeTmpId);
                btn.click();
                setTimeout(() => {
                    btn.click();
                }, 10);
            }
        }

        const onTextFieldChangeAppetite = (event) => {
            if (event != null && event.target.value != null) {
                if (event.target.value <= 0 || event.target.value > 10 || !Number.isInteger(parseInt(event.target.value))) {
                    event.target.value = ''
                }
                comment["riskAppetite"] = event.target.value;
               // comment.riskAppetiteData["riskAppetite"] = event.target.value;
                var btn = document.getElementById("btn" + comment.riskTypeTmpId);
                btn.click();
                setTimeout(() => {
                    btn.click();
                }, 10);
            }
        }



        var subAvarageRisk = 0;
        var subAvarageAppetite = 0;
        if (fromMainMenu) {
            fromMainMenu = false;
            noDisplay = riskNo;
            subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageRisk = getSubAvarageRisk1(comment);
            subAvarageAppetite = getSubAvarageAppetite(comment);
        }
        if (fromSubMenu) {
            fromSubMenu = false;
            var subNo = getSubNoReg(comment, comment.riskTypeId)
            noDisplay = riskNo + '.' + subNo;
            var tmpArr = noDisplay.split(".");
            if (tmpArr.length === 2) {
                noDisplaySpacesCount = 1;
            }
            if (tmpArr.length === 3) {
                noDisplaySpacesCount = 2;
            }
            if (tmpArr.length === 4) {
                noDisplaySpacesCount = 3;
            }
            if (tmpArr.length === 5) {
                noDisplaySpacesCount = 4;
            }
            if (tmpArr.length === 6) {
                noDisplaySpacesCount = 5;
            }
            if (tmpArr.length === 7) {
                noDisplaySpacesCount = 6;
            }
            if (tmpArr.length === 8) {
                noDisplaySpacesCount = 7;
            }
            if (tmpArr.length === 9) {
                noDisplaySpacesCount = 8;
            }
            if (tmpArr.length === 10) {
                noDisplaySpacesCount = 9;
            }
            if (tmpArr.length === 11) {
                noDisplaySpacesCount = 10;
            }


            subAvarageRisk = getSubAvarageRisk(comment);
            //subAvarageRisk = getSubAvarageRisk1(comment);
            subAvarageAppetite = getSubAvarageAppetite(comment);
        }
        const nestedComments = (comment.subRisks || []).map(comment => {
            return <CommentRegister key={comment.riskTypeTmpId} comment={comment} lastRiskTmpId={subLastRiskTmpId} riskNo={subRiskNo} fromSubMenu={true} editRiskHirachyData={subEditRiskHirachyData} type="child" />
        })






        /** Need */
        function getRiskRiskTypeName(Comment) {
            var rName = ''
            riskCategoryMethodData.forEach((data) => {
                if (data.id === Comment.riskLevelData.riskMethodId) {
                    data.riskTypes.forEach((data1) => {
                        if (data1.startingNumber <= Comment.riskLevel && Comment.riskLevel <= data1.endingNumber) {
                            rName = data1.riskTypesName
                        }
                    })
                }

            })
            return rName;
        }

        /** Need */
        function getAppetiteRiskTypeName(Comment) {
            var aName = ''
            riskCategoryMethodDataAppetite.forEach((data) => {
                if (data.id === Comment.riskAppetiteData.riskMethodId) {
                    data.riskTypes.forEach((data1) => {
                        if (data1.startingNumber <= Comment.riskAppetite && Comment.riskAppetite <= data1.endingNumber) {
                            aName = data1.riskTypesName
                        }
                    })
                }

            })
            return aName;
        }


        /** Need */
        function getRiskRiskColor(Comment) {
            var rColor = ''
            var lastNumber = ''
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rColor = data2.color
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rColor = data2.secondaryColor
                        }
                    }
                }
            })
            if (rColor === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.color
                            }
                        } else if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.secondaryColor
                            }
                        }
                    }
                })
            }
            return rColor;
        }


        function getRiskRiskAction(Comment) {
            var rAction = ''
            var lastNumber = ''
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rAction = data2.primaryColorName
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLevel)) {
                            rAction = data2.secondaryColorName
                        }
                    }
                }
            })
            if (rAction === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rAction = data2.primaryColorName
                            }
                        } else if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rAction = data2.secondaryColorName
                            }
                        }
                    }
                })
            }
            return rAction;
        }


        function getRiskRiskColorAve(avarageRisk) {
            var rColor = ''
            var lastNumber = '';
            databClr.forEach((data2) => {
                lastNumber = data2.scoreNumber;
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageRisk)) {
                            rColor = data2.secondaryColor;
                        }
                    } else if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageRisk)) {
                            rColor = data2.color;
                        }
                    }
                }
            })
            if (rColor === '') {
                databClr.forEach((data2) => {
                    if (applyFlag) {
                        if (colorSelectDropDownRiskLevel === 2) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.secondaryColor;
                            }
                        } else if (colorSelectDropDownRiskLevel === 1) {
                            if (parseInt(data2.scoreNumber) === parseInt(lastNumber)) {
                                rColor = data2.color;
                            }
                        }
                    }
                })
            }
            return rColor;
        }





        /** Need */
        function getAppetiteColor(Comment) {
            var aColor = ''
            var colorFg1 = ''

            databClr.forEach((data2) => {
                if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
                    //console.log("++++++++++++++data2+++++++++++++++")
                    //console.log(data2)
                    //console.log(applyFlag)
                    //console.log(Comment.riskAppetiteData)
                    //console.log(colorSelectDropDownRiskAppetite)

                    if (Comment.riskAppetite != null && Comment.riskAppetite != undefined) {
                        /*
                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (Comment.riskAppetiteData.color != null && Comment.riskAppetiteData.color != undefined && Comment.riskAppetiteData.color != '') {
                                    aColor = Comment.riskAppetiteData.color;
                                }
                            }
                            else if (colorSelectDropDownRiskAppetite === 2) {
                                if (Comment.riskAppetiteData.secondaryColor != null && Comment.riskAppetiteData.secondaryColor != undefined && Comment.riskAppetiteData.secondaryColor != '') {
                                    aColor = Comment.riskAppetiteData.secondaryColor;

                                }
                            }
                        }
                        */

                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (aColor === '') {
                                    aColor = data2.color
                                }
                            }
                            if (colorSelectDropDownRiskAppetite === 2) {
                                if (aColor === '') {
                                    aColor = data2.secondaryColor
                                }
                            }
                        }
                    }
                }
            })
            return aColor;
        }


        function getRiskAppetiteAction(Comment) {
            var aColor = ''
            var colorFg1 = ''

            databClr.forEach((data2) => {
                if (parseInt(data2.scoreNumber) === parseInt(Comment.riskAppetite)) {
                    //console.log("++++++++++++++data2+++++++++++++++")
                    //console.log(data2)
                    //console.log(applyFlag)
                    //console.log(Comment.riskAppetiteData)
                    //console.log(colorSelectDropDownRiskAppetite)

                    if (Comment.riskAppetite != null && Comment.riskAppetite != undefined) {
                        if (applyFlag) {
                            if (colorSelectDropDownRiskAppetite === 1) {
                                if (aColor === '') {
                                    aColor = data2.primaryColorName
                                }
                            }
                            if (colorSelectDropDownRiskAppetite === 2) {
                                if (aColor === '') {
                                    aColor = data2.secondaryColorName
                                }
                            }
                        }
                    }
                }
            })
            return aColor;
        }

        function getAppetiteColorAve(avarageAppetite) {
            var aColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskAppetite === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageAppetite)) {
                            aColor = data2.secondaryColor;
                        }
                    } else if (colorSelectDropDownRiskAppetite === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(avarageAppetite)) {
                            aColor = data2.color;
                        }
                    }
                }


            })
            return aColor;



        }


        /** Need */
        function getRiskLikeliColor(Comment) {

            var rColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLikelihood)) {
                            rColor = data2.color
                            //setApplyFlag(false)
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskLikelihood)) {
                            rColor = data2.secondaryColor
                            //setApplyFlag(false)
                        }
                    }
                }

            })
            return rColor;
        }

        /** Need */
        function getRiskImpactColor(Comment) {

            var rColor = ''
            databClr.forEach((data2) => {
                if (applyFlag) {
                    if (colorSelectDropDownRiskLevel === 1) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskImpact)) {
                            rColor = data2.color
                            //setApplyFlag(false)
                        }
                    } else if (colorSelectDropDownRiskLevel === 2) {
                        if (parseInt(data2.scoreNumber) === parseInt(Comment.riskImpact)) {
                            rColor = data2.secondaryColor
                            //setApplyFlag(false)
                        }
                    }
                }

            })
            return rColor;
        }

        var riskLikelihood = getRiskLikeliColor(comment)
        var appetiteColor = getAppetiteColor(comment);
        var appetiteAction = getRiskAppetiteAction(comment);
        var riskColor = getRiskRiskColor(comment);
        var riskAction = getRiskRiskAction(comment);
        var riskImpact = getRiskImpactColor(comment)
        var riskColorAverage = getRiskRiskColorAve(subAvarageRisk);
        var appetiteColorAverage = getAppetiteColorAve(subAvarageAppetite)

        /** Need */
        function DisplayStandared({ comment }) {
            console.log("+++++++++++++Athula+++++++comment++++++++++++++++++++++")
            console.log(comment)
            var objectiveId = '';
            var objectiveName = '';
            var componentId = '';
            var componentName = '';
            var principleId = '';
            var principleName = '';
            var standardId = '';
            var standardName = '';
            if (comment.riskStandardData != undefined && comment.riskStandardData != null) {
                if (comment.riskStandardData.objectiveData != undefined && comment.riskStandardData.objectiveData != null) {
                    objectiveId = comment.riskStandardData.objectiveData.objectiveId;
                    objectiveName = comment.riskStandardData.objectiveData.objectiveName;
                    standardId = comment.riskStandardData.objectiveData.standardId;
                    standardName = comment.riskStandardData.objectiveData.standardName;
                }
                if (comment.riskStandardData.componentData != undefined && comment.riskStandardData.componentData != null) {
                    componentId = comment.riskStandardData.componentData.componentId;
                    componentName = comment.riskStandardData.componentData.componentName;
                    if (standardId === '') {
                        standardId = comment.riskStandardData.componentData.standardId;
                    }
                    if (standardName === '') {
                        standardName = comment.riskStandardData.componentData.standardName;
                    }
                }
                if (comment.riskStandardData.principleData != undefined && comment.riskStandardData.principleData != null) {
                    principleId = comment.riskStandardData.principleData.principleId;
                    principleName = comment.riskStandardData.principleData.principleName;
                    if (standardId === '') {
                        standardId = comment.riskStandardData.principleData.standardId;
                    }
                    if (standardName === '') {
                        standardName = comment.riskStandardData.principleData.standardName;
                    }
                }
            }
            var altString = 'Standard Name : ' + standardName;
            if (objectiveName != '') {
                altString += '\nObjective Name : ' + objectiveName;
            }
            if (componentName != '') {
                altString += '\nComponent Name : ' + componentName;
            }
            if (principleName != '') {
                altString += '\nPrinciple Name : ' + principleName;
            }
            if (standardName.length > 10) {
                standardName = standardName.substring(0, 10) + '...';
            }

            console.log("++++++++++++++++++++++++++++altString+++++++++++++++++++++++");
            console.log(altString);
            console.log(standardName);

            return (
                <>

                    {
                        <span title={`${altString}`} style={{ cursor: 'pointer' }}>&nbsp;{standardName}</span>
                    }

                </>
            )
        }
        comment["riskColor"] = riskColor;
        comment["appetiteColor"] = appetiteColor;

        var createArr = pushToArray(comment);





        return (
            <>
                <Grid item xs={6}>
                    {noDisplaySpacesCount === 0 ?
                        <></>
                        : noDisplaySpacesCount === 1 ?
                            <>&nbsp;&nbsp;&nbsp;&nbsp;</>
                            : noDisplaySpacesCount === 2 ?
                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                : noDisplaySpacesCount === 3 ?
                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                    : noDisplaySpacesCount === 4 ?
                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                        : noDisplaySpacesCount === 5 ?
                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                            : noDisplaySpacesCount === 6 ?
                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                : noDisplaySpacesCount === 7 ?
                                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                    : noDisplaySpacesCount === 8 ?
                                                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                        : noDisplaySpacesCount === 9 ?
                                                            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                            : noDisplaySpacesCount === 10 ?
                                                                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                                                : ''
                    }
                    <Button
                        style={{ width: '0px', height: '0px' }}
                        id={`btn` + comment.riskTypeTmpId}
                        onClick={() => setShow(!show)}
                        startIcon={show ? <ExpandMoreIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} /> : <ChevronRightIcon style={{ height: '30px', fontSize: '38px', marginTop: '1px' }} />}
                    >
                    </Button>
                    {noDisplay}{') '}

                    {comment.name}

                    {/* {!editRiskHirachyData ?
                        comment.name
                        :
                        <TextField
                            size="small"
                            id={`txt` + comment.riskTypeTmpId}
                            label="Type/Edit Risk Name"
                            defaultValue={comment.name}
                            title={comment.name}
                            onChange={onTxtNameChageEvent}
                            style={{ height: '10px !important' }}
                        />
                    } */}
                </Grid>
                <Grid item xs={1}>
                    {!editRiskHirachyData ?
                        <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLikelihood}</p></div></span>
                        :
                        <TextField
                            size="small"
                            id={`txtRiskLikelihood` + comment.riskTypeTmpId}
                            label="Likelihood"
                            defaultValue={comment.riskLikelihood}
                            title={comment.riskLikelihood}
                            onChange={onTextFieldChangeLikelihood}
                            //onBlur={onTextFieldChangeLikelihood}
                            style={{ height: '10px !important' }}
                        />
                    }

                </Grid>
                <Grid item xs={1}>
                    {!editRiskHirachyData ?
                        <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: '#ffffff', borderRadius: '50%', border: '1px solid #10020247' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskImpact}</p></div></span>
                        :
                        <TextField
                            size="small"
                            id={`txtRiskImpact` + comment.riskTypeTmpId}
                            label="Impact"
                            defaultValue={comment.riskImpact}
                            title={comment.riskImpact}
                            onChange={onTextFieldChangeImpact}
                            style={{ height: '10px !important' }}
                        />
                    }
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${riskColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskLevel}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px' }}><p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '9px' }}>{riskAction}</p></div></span>
                </Grid>
                <Grid item xs={1}>
                    {!editRiskHirachyData ?
                        <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px', backgroundColor: `${appetiteColor}`, borderRadius: '50%' }}><p style={{ margin: 'auto', fontWeight: 'bold' }}>{comment.riskAppetite}</p></div></span>
                        :
                        <TextField
                            size="small"
                            id={`txtRiskAppetite` + comment.riskTypeTmpId}
                            label="Appetite"
                            defaultValue={comment.riskAppetite}
                            title={comment.riskAppetite}
                            onChange={onTextFieldChangeAppetite}
                            style={{ height: '10px !important', backgroundColor: `${appetiteColor}`, width: '66px' }}
                        />
                    }
                </Grid>
                <Grid item xs={1}>
                    <span style={{ width: '100px', textAlign: 'left' }}><div style={{ display: 'flex', width: '30px', height: '30px' }}><p style={{ margin: 'auto', fontWeight: 'bold', fontSize: '9px' }}>{appetiteAction}</p></div></span>
                </Grid>

                {/*riskDisplayStr*/}

                {
                    show ?
                        <>
                            {nestedComments}
                        </>
                        : null
                }
            </>

        )
    }



    return (
        <>
            <RightBarDataForOtherPages PageName={PageName} />

            <div className={classes.pageTop}>
                <Grid container spacing={2}>
                    <Grid container spacing={2} className={classes.pageBodyStyle} style={{ marginLeft: '-1px' }}>
                        <Grid item xs={12}>
                            <div role="presentation" onClick={handleClick}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" to="/login">
                                        <span className='breadcrumbFonts'>Home</span>
                                    </Link>
                                    <Typography color="text.primary"><span className='breadcrumbFonts'>Main menu - Upload Risk Data Through JSON </span></Typography>
                                </Breadcrumbs>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <a className='goBack' onClick={() => navigate(-1)}><ArrowBack /> Go Back</a>
                        </Grid>
                    </Grid>

                    <Grid item className='grid1'>
                        <TextField
                            label='Select a JSON schema for Validate'
                            required
                            sx={{
                                width: '100%',
                                fontSize: '9px',
                                "& .MuiInputBase-root": {
                                    height: 45
                                },
                                "& .MuiInputLabel-root": {
                                    marginTop: -0.5
                                }
                            }}
                            title={selectedSchemaName}
                            select
                            value={selectedSchemaId}
                            onChange={handleChangeSelectedSchema}
                        >
                            {allAvailableRiskSchemas.map((item) => (
                                <MenuItem
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>

                    </Grid>
                    <Grid item className='grid2' >
                        <Button
                            variant="contained"
                            component="label"
                            onClick={() => viewSchemaFuction()}
                            className='btnFonts'
                            >
                            View Schema
                        </Button>&nbsp;&nbsp;&nbsp;
                        <Button
                            variant="contained"
                            component="label"
                            onClick={() => insertSampleFuction()}
                            className='btnFonts'
                            >
                            Insert A Sample
                        </Button>
                        

                    </Grid>
                    {/** 
                    <Grid item xs={12}>            




                        <div style={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <Button
                                    id='btnCentreLimitation'
                                    variant="outlined"
                                    size="medium"
                                    className={(openSchemaFormat1 ? classes.tabBtnDBClicked : classes.tabBtnDB)}
                                    onClick={() => setOpenSchemaFormat1Fuction(true)}
                                >
                                    Update Data Foemat 1
                                </Button>&nbsp;&nbsp;
                                <Button
                                    id='btnCentreMaintenance'
                                    variant="outlined"
                                    size="medium"
                                    className={(openSchemaFormat2 ? classes.tabBtnDBClicked : classes.tabBtnDB)}
                                    onClick={() => setOpenSchemaFormat2Fuction(true)}
                                >
                                    Update Data Foemat 2
                                </Button>&nbsp;&nbsp;
                                <Button
                                    id='btnOpenRiskCollection'
                                    variant="outlined"
                                    size="medium"
                                    className={(openSchemaFormat3 ? classes.tabBtnDBClicked : classes.tabBtnDB)}
                                    onClick={() => setOpenSchemaFormat3Function(true)}
                                >
                                    Update Data Foemat 3
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    */}
                    <Grid item xs={12}>
                        <Card
                            className='cardDivMove'
                            style={{ marginRadius: '10px'}}
                        >
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        Upload your JSON file from &nbsp;&nbsp;
                                        <Button
                                            variant="contained"
                                            component="label"
                                            className='btnFonts'
                                        >
                                            Here
                                            <input
                                                type="file"
                                                accept='application/json'
                                                onChange={handleChangeFormat1}
                                                hidden
                                            />
                                        </Button>&nbsp;&nbsp;Or 
                                    </Grid>
                                    <Grid item xs={12}>
                                        Copy and Paste your JSON string here
                                    </Grid>
                                    <Grid item className='grid3'>
                                        <textarea
                                            className="lined textArea" 
                                            name="postContent"
                                            rows={12}
                                            //cols={135}
                                            value={jsonStringFormat1}
                                            onChange={handleChangeJsonStringFormat1}
                                            
                                        />
                                    </Grid>
                                    <Grid item className='grid4'>
                                        <Button
                                            variant="contained"
                                            component="label"
                                            onClick={() => editJsonFuction()}
                                            className='btnFonts textAreaBtn'
                                            >
                                            Edit Json
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        
                                        <Button
                                            variant="contained"
                                            component="label"
                                            className='btnFonts'
                                            onClick={() => validateAndUpdateFormat1Fuction()}
                                        >
                                            Validate JSON and Update the Database
                                        </Button>&nbsp;&nbsp;
                                     </Grid>
                                     <Grid item xs={12}>   
                                        <Button
                                            variant="contained"
                                            component="label"
                                            className='btnFonts'
                                            onClick={() => evaluateRiskFuction()}
                                        >
                                            Evaluate
                                        </Button>&nbsp;&nbsp;
                                        <Button
                                            variant="contained"
                                            component="label"
                                            className='btnFonts'
                                            onClick={() => editRiskFuction()}
                                        >
                                            {editRiskOrRegister}
                                        </Button>&nbsp;&nbsp;
                                        {savedQuestionArray.length > 0 ?
                                            <>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    className='btnFonts'
                                                    onClick={() => showQuestionFuction()}
                                                >
                                                    Show Questions
                                                </Button>&nbsp;&nbsp;
                                            </>
                                            :
                                            ''
                                        }
                                        <Button
                                            variant="contained"
                                            component="label"
                                            className='btnFonts'
                                            onClick={() => clearFormat1Fuction()}
                                        >
                                            Clear
                                        </Button>

                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    {openSchemaFormat2 ?
                        <Grid item xs={12}>
                            <Card
                                className={classes.cardDivMove}
                                style={{ marginRadius: '10px', width: '100%', height: '350px', overflowY: 'scroll' }}
                            >
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        : ''
                    }
                    {openSchemaFormat3 ?
                        <Grid item xs={12}>
                            <Card
                                className={classes.cardDivMove}
                                style={{ marginRadius: '10px', width: '100%', height: '350px', overflowY: 'scroll' }}
                            >
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        : ''
                    }
                </Grid>
            </div>

            {/** Evaluation Risk */}
            <Dialog
                open={openRiskDataForEval}
                onClose={false}
                scroll={openRiskDataForEvalScroll}
                aria-labelledby="collection-data-dialog-title"
                aria-describedby="collection-data-dialog-description"
                fullWidth={true}
                maxWidth={maxWidthState}
                fullScreen={fullScreenState}
                PaperComponent={PaperComponentCollectionData}
            >
                <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
                    <Typography variant="h6" style={{ fontSize: '25px !important' }}>Evaluate "{selectedRiskEvaluateTitle}" - <SavePageAs/>
                    </Typography>
                    <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                        <Tooltip1 title={fullScreenStateMsg}><FullscreenRounded onClick={() => { setMinMaxWindowFunction() }} style={{ cursor: 'pointer' }} /></Tooltip1>
                        <Tooltip1 title="Close"><Close onClick={setOpenRiskDataForEvalfunction} style={{ cursor: 'pointer' }} /></Tooltip1>
                    </Typography>
                </DialogTitle>
                {!fullScreenState ?
                    <Grid container spacing={2} style={{ marginLeft: '10px', marginBottom: '20px' }}>
                        <Grid item xs={2.5}>
                            select your color patterns here :
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label='Select Color Pattern'
                                required
                                sx={{
                                    width: '100%',
                                    "& .MuiInputBase-root": {
                                        height: 37
                                    },
                                    "& .MuiInputLabel-root": {
                                        marginTop: -0.5
                                    }
                                }}
                                select
                                value={colorPatternId}
                                onChange={handleChangeColorPattern}
                            >
                                {colorPatterns.map((item) => (
                                    <MenuItem
                                        value={item.id}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>



                        <Grid item xs={2}>

                            <TextField
                                label='Risk Level'
                                required
                                sx={{
                                    width: '100%',
                                    "& .MuiInputBase-root": {
                                        height: 37
                                    },
                                    "& .MuiInputLabel-root": {
                                        marginTop: -0.5
                                    }
                                }}
                                select
                                value={colorSelectDropDownRiskLevelTmp}
                                onChange={handleChangeDropDown}
                            >
                                <MenuItem value={1}>{colorPatternPrimaryColorName}</MenuItem>
                                <MenuItem value={2}>{colorPatternSecondaryColorName}</MenuItem>
                            </TextField>



                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                label='Risk Appetite'
                                required
                                sx={{
                                    width: '100%',
                                    "& .MuiInputBase-root": {
                                        height: 37
                                    },
                                    "& .MuiInputLabel-root": {
                                        marginTop: -0.5
                                    }
                                }}
                                select
                                value={colorSelectDropDownRiskAppetiteTmp}
                                onChange={handleChangeDropDownAppetite}
                            >
                                <MenuItem value={1}>{colorPatternPrimaryColorName}</MenuItem>
                                <MenuItem value={2}>{colorPatternSecondaryColorName}</MenuItem>
                            </TextField>


                        </Grid>
                        <Grid item xs={2}>
                            <Button variant='outlined' style={{ padding: "7px 5px" }} onClick={applyFunction}>Apply</Button>
                        </Grid>


                        <Grid container spacing={2}>


                            <Grid item xs={2}>
                                &nbsp; &nbsp;Views
                            </Grid>  &nbsp;
                            <Grid item xs={1.5}>
                                <Button
                                    variant='outlined'
                                    className={btnHirachivalClass}
                                    onClick={() => { setShowEvalData(true); setShowTableType(1); hierarchicalFunction() }}
                                >
                                    Hierarchical View
                                </Button>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Button
                                    variant='outlined'
                                    className={btnGradientClass}
                                    onClick={() => displayRiskAsending()}
                                >
                                    Gradient View
                                </Button>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Button
                                    variant='outlined'
                                    className={btnBarChartClass}
                                    onClick={() => createBarFunction()}
                                >
                                    BarChart View
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>
                    : ''
                }

                <DialogContent dividers={openRiskDataForEvalScroll === 'paper'}>
                    <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {Object.keys(riskTypeDataForEval).length > 0 &&
                                    <>
                                        <Grid item xs={12}>
                                            <div>

                                                {
                                                    showEvalData && showTableType === 1 ?
                                                        <>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={4}>&nbsp;</Grid>
                                                                <Grid item xs={1}><b><u>Likelihood</u></b></Grid>
                                                                <Grid item xs={1}><b><u>Impact</u></b></Grid>
                                                                <Grid item xs={1}><b><u>Risk Level</u></b></Grid>
                                                                <Grid item xs={1}><b><u>Average</u></b></Grid>
                                                                <Grid item xs={1}><b><u>Action</u></b></Grid>
                                                                <Grid item xs={1}><b><u>Appetite</u></b></Grid>
                                                                <Grid item xs={1}><b><u>Average</u></b></Grid>
                                                                <Grid item xs={1}><b><u>Action</u></b></Grid>


                                                                {
                                                                    riskTypeDataForEval.riskTypes.map((riskType, index) => {

                                                                        return (

                                                                            <Comment
                                                                                key={riskType.riskTypeTmpId}
                                                                                comment={riskType}
                                                                                lastRiskTmpId={lastRiskTmpId}
                                                                                riskNo={index + 1}
                                                                                fromMainMenu={TurnedInOutlined}
                                                                            //lastRiskTmpId={lastRiskTmpId}
                                                                            />

                                                                        )

                                                                    })


                                                                }
                                                            </Grid>
                                                        </>

                                                        : showEvalData && showTableType === 2 ?

                                                            <>
                                                                <Grid container>
                                                                <Grid item xs={12} style={{ textAlign:'center', fontWeight:'bold', fontSize:'19px'}}>
                                                                        Risk Level
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ textAlign:'left', fontWeight:'bold', fontSize:'15px'}}>
                                                                        Low
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ textAlign:'right', fontWeight:'bold', fontSize:'15px'}}>
                                                                        High
                                                                    </Grid>

                                                                <Grid item xs={12}>
                                                                    <table style={{ backgroundImage: colorSelectDropDownRiskLevel === 1 ? 'linear-gradient(to right, green , red)' : colorSelectDropDownRiskLevel === 2 ? 'linear-gradient(to right, red , green)' : '', width: '100%', color: 'white', minHeight: '200px' }} border='0'>
                                                                        <tr>
                                                                            {
                                                                                databClr.map((item, index1) => (

                                                                                    <td style={{ verticalAlign: 'top', width: `${tdPresentage}%` }}>
                                                                                        <table style={{ verticalAlign: 'top' }}>
                                                                                            {
                                                                                                arrayForDisplayDataInTable.map((data, index) => (
                                                                                                    parseInt(data.riskLevel) === item.scoreNumber || (databClr.length === (index1 + 1) && parseInt(data.riskLevel) >= item.scoreNumber) ?

                                                                                                        <tr>
                                                                                                            <td style={{ fontSize: '10px', width: '5%', verticalAlign: 'top' }}><span style={{ height: '6px', width: '6px', display: 'inline-block', backgroundColor: 'white', borderRadius: '50%' }}></span></td>
                                                                                                            <td style={{ fontSize: '10px' }}>{data.name}</td>
                                                                                                        </tr>
                                                                                                        :
                                                                                                        ''

                                                                                                ))
                                                                                            }
                                                                                        </table>

                                                                                    </td>
                                                                                ))
                                                                            }




                                                                        </tr>


                                                                    </table>
                                                                </Grid>

                                                                <Grid item xs={12} style={{ textAlign:'center', fontWeight:'bold', fontSize:'19px'}}>
                                                                        Risk Appetite
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ textAlign:'left', fontWeight:'bold', fontSize:'15px'}}>
                                                                        Low
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ textAlign:'right', fontWeight:'bold', fontSize:'15px'}}>
                                                                        High
                                                                    </Grid>

                                                                <Grid item xs={12}>
                                                                    <table style={{ backgroundImage: colorSelectDropDownRiskLevel === 1 ? 'linear-gradient(to right, green , red)' : colorSelectDropDownRiskLevel === 2 ? 'linear-gradient(to right, red , green)' : '', width: '100%', color: 'white', minHeight: '200px' }} border='0'>
                                                                        {/** 
                                            <tr style={{width:'100%'}}>

                                            {databClr.map((data, index) => (
                                                <td style={{ width: `${tdPresentage}%` }} key={index} value={index}>
                                                    
                                                        &nbsp;
                                                </td>
                                                ))}
                                                
                                            
                                            </tr>
                                            */}

                                                                        <tr>
                                                                            {
                                                                                databClr.map((item, index1) => (

                                                                                    <td style={{ verticalAlign: 'top', width: `${tdPresentage}%` }}>
                                                                                        <table style={{ verticalAlign: 'top' }}>
                                                                                            {
                                                                                                arrayForDisplayDataInTable.map((data, index) => (
                                                                                                    parseInt(data.riskAppetite) === item.scoreNumber || (databClr.length === (index1 + 1) && parseInt(data.riskAppetite) >= item.scoreNumber) ?

                                                                                                        <tr>
                                                                                                            <td style={{ fontSize: '10px', width: '5%', verticalAlign: 'top' }}><span style={{ height: '6px', width: '6px', display: 'inline-block', backgroundColor: 'white', borderRadius: '50%' }}></span></td>
                                                                                                            <td style={{ fontSize: '10px' }}>{data.name}</td>
                                                                                                        </tr>
                                                                                                        :
                                                                                                        ''

                                                                                                ))
                                                                                            }
                                                                                        </table>

                                                                                    </td>
                                                                                ))
                                                                            }




                                                                        </tr>


                                                                    </table>
                                                                </Grid>
                                                                </Grid>

                                                            </>
                                                            :
                                                            showEvalData && showTableType === 3 ?
                                                                <>
                                                                    <BarChart width={1000} height={barChartHeight} data={barData} margin={{ bottom: 50 }} layout="vertical">
                                                                        <XAxis
                                                                            type="number"
                                                                            axisLine={false}

                                                                            margin={{ top: 0, right: 40, left: 40, bottom: 20 }}
                                                                        />
                                                                        <YAxis dataKey="argument" type="category" width={400} />
                                                                        <CartesianGrid strokeDasharray="2 2" />
                                                                        <Tooltip content={<CustomTooltip />} />



                                                                        <Bar dataKey="riskLevel" fill={arrRiskColorState}>
                                                                            {barData.map((entry, index) => (
                                                                                <>
                                                                                <Cell key={`cell-${index}`} fill={arrRiskColorState[index % arrRiskColorState.length]} />
                                                                                <LabelList dataKey="riskLevelStr" content={renderCustomizedLabel}/>
                                                                                </>
                                                                            ))}
                                                                            
                                                                        </Bar>


                                                                        <Bar dataKey="riskAppetite" fill={arrColorAppState}>
                                                                            {barData.map((entry, index) => (
                                                                                <>
                                                                                <Cell key={`cell-${index}`} fill={arrColorAppState[index % arrColorAppState.length]} />
                                                                                <LabelList dataKey="riskAppetiteStr" content={renderCustomizedLabel1} />
                                                                                </>
                                                                            ))}
                                                                            
                                                                        </Bar>

                                                                    </BarChart>
                                                                </>

                                                                :

                                                                ''
                                                }


                                            </div>
                                        </Grid>




                                    </>
                                }
                            </Grid>




                        </Grid>





                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={setOpenRiskDataForEvalfunction}>Cancel</Button>
                </DialogActions>
            </Dialog>


            {/** Edit Risk */}
            <Dialog
                open={openRiskDataEdit}
                onClose={false}
                scroll={openRiskDataEditScroll}
                aria-labelledby="collection-data-dialog-title"
                aria-describedby="collection-data-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
                    <Typography variant="h6" style={{ fontSize: '25px !important' }}>Edit "{selectedRiskEvaluateTitle}" - <SavePageAs/>
                    </Typography>
                    <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                        <Tooltip title="Close"><Close onClick={setOpenRiskDataEditfunction} style={{ cursor: 'pointer' }} /></Tooltip>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={openRiskDataEditScroll === 'paper'}>
                    <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RiskHierarchyNew
                                    riskHierarchyId={createdRiskId}
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={setOpenRiskDataEditfunction}>Cancel</Button>
                </DialogActions>
            </Dialog>


            {/** Validate confirm and request hirachy name for update */}

            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}>
                <DialogTitle>
                    {riskRegisterValidate ?
                        <>
                            Update the Database with Risk Register name and Description
                        </>
                        :
                        <>
                            Update the Database with Risk Hierarchy name
                        </>
                    }
                </DialogTitle>
                <DialogContent>
                    {riskRegisterValidate ?
                        <>
                            <DialogContentText>
                                JSON data in the above text area has been validated with the selected JSON schema for correctness. To update the database, please enter the Risk Register Name and Description here.
                            </DialogContentText>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Register Name"
                                        type="text"
                                        value={riskName}
                                        onChange={handleChangeRiskName}
                                        fullWidth
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="dense"
                                        id="description"
                                        label="Register Description"
                                        type="text"
                                        value={registerDescription}
                                        onChange={registerDescriptionOnChange}
                                        fullWidth
                                        variant="standard"
                                    />

                                </Grid>
                            </Grid>
                        </>
                        :
                        <>
                            <DialogContentText>
                                JSON data in the above text area has been validated with the selected JSON schema for correctness. To update the database, please enter the Risk Hierarchy Key Code and Name here.
                            </DialogContentText>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="key"
                                        label="Risk Hierarchy Key"
                                        type="text"
                                        value={riskKey}
                                        onChange={handleChangeRiskKey}
                                        fullWidth
                                        variant="standard"
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="dense"
                                        id="name"
                                        label="Risk Hierarchy Name"
                                        type="text"
                                        value={riskName}
                                        onChange={handleChangeRiskName}
                                        fullWidth
                                        variant="standard"
                                    />
                                    
                                </Grid>
                            </Grid>    
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>Cancel</Button>
                    <Button onClick={handleUpdate}>Update Database</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={openShowQuestions}
                onClose={false}
                scroll={openShowQuestionsScroll}
                aria-labelledby="collection-data-dialog-title"
                aria-describedby="collection-data-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
                PaperComponent={PaperComponentAllQuestionData}
            >
                <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
                    <Typography variant="h6" style={{ fontSize: '25px !important' }}>Questions related to "{selectedRiskEvaluateTitle}" - <SavePageAs/>
                    </Typography>
                    <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '-25px', padding: '7px', marginTop: '-50px' }}>
                        <Tooltip1 title="Close"><Close onClick={() => setOpenShowQuestions(false)} style={{ cursor: 'pointer' }} /></Tooltip1>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={openShowQuestionsScroll === 'paper'}>
                    <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>

                                    <Box
                                        sx={{
                                            height: 400,
                                            width: '100%',
                                            '& .super-app-theme--cell': {
                                                whiteSpace: 'normal !important',
                                                lineHeight: '1',
                                            },
                                            '& .super-app-theme--Open': {
                                                fontSize: '10px !important',
                                            },

                                        }}
                                    >





                                        <div style={{ height: 400, width: '100%', backgroundColor: '#ffffff', fontSize: '9px !important' }}>
                                            <DataGrid
                                                rows={rows}
                                                columns={columns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                getRowClassName={`super-app-theme--Open`}
                                                disableSelectionOnClick
                                            />
                                        </div>

                                    </Box>
                                </Grid>
                            </Grid>


                        </Grid>





                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenShowQuestions(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>

            {/** Question Details Show Dialog */}
            <Dialog
                maxWidth='xs'
                open={questionDetailOpen}
                onClose={questionDetailHandleClickClose}
                scroll={questionDetailScroll}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <Typography variant="h6" gutterBottom component="div">
                        Question No - {quesDataCount}
                    </Typography>
                    <Typography component="th" scope="row" style={{ width: '100%', fontSize: '10px !important' }}>
                        <span style={{ fontWeight: '550' }}>Question - </span>{selectedQuestion}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={questionDetailScroll === 'paper'}>
                    <DialogContentText
                        id="risk-eval-type-scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <Grid container spacing={2}>
                            {selectedQuesData.map((data) => {
                                return (
                                    <Grid item xs={12}>
                                        <Box >
                                            <Table size="small" aria-label="purchases">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                                            <Typography variant="h6" gutterBottom component="div">
                                                                Detail Weightings
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                                            <Typography variant="h6" component="div">
                                                                Score
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <Table size="small" aria-label="purchases">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                                            Questionnaire weighting
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                                            <span >{data.questionScore}</span>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            {data.questionType === "Closeended" && (
                                                <>
                                                    <Table size="small" aria-label="purchases">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                                                    <Typography variant="h6" gutterBottom component="div">
                                                                        Answers
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                                                    &nbsp;
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    <Table size="small" aria-label="purchases">
                                                        <TableBody>
                                                            {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                                                //var riskLvlColor = getRiskLvlColor(item.riskLevel)
                                                                return (
                                                                    <>
                                                                        <TableRow>
                                                                            <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                                                                <span >{item.answerName}</span>
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                                                                <span>{item.riskLevel}</span>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </>
                                            )}
                                            {data.questionType === "Closeended" && (
                                                <Table size="small" aria-label="purchases">
                                                    <TableBody>
                                                        {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                                            //var riskLvlColor = getRiskLvlColor(item.riskLevel)
                                                            if (item.expectedAnswerName != "") {
                                                                return (
                                                                    <>
                                                                        <TableRow>
                                                                            <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                                                                <span>
                                                                                    {item.answerName}
                                                                                </span>
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                                                                <span>{item.expectedAnswerName === "Yes" || item.expectedAnswerName === "checked" ? item.riskLevel : item.expectedAnswerName}</span>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>
                                                                )
                                                            }
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            )}
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={questionDetailHandleClickClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
            {/** End of Question Details Show Dialog*/}



            {/** Edit Question */}
            <Dialog
                open={openQuestionEdit}
                onClose={false}
                scroll={openQuestionEditScroll}
                aria-labelledby="collection-data-dialog-title"
                aria-describedby="collection-data-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
                    <Typography variant="h6" style={{ fontSize: '25px !important' }}>Edit Question - "{selectedQuestionName}"
                    </Typography>
                    <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                        <Tooltip1 title="Close"><Close onClick={() => setOpenQuestionEdit(false)} style={{ cursor: 'pointer' }} /></Tooltip1>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={openQuestionEditScroll === 'paper'}>
                    <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AddQuestionComponent
                                    fromUploadPage={'OK'}
                                    questionIdFromUploadPage={selectedQuestionId}
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenQuestionEdit(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>

            {/** Edit Register */}
            <Dialog
                open={openRegisterEdit}
                onClose={false}
                scroll={openRegisterEditScroll}
                aria-labelledby="collection-data-dialog-title"
                aria-describedby="collection-data-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
                    <Typography variant="h6" style={{ fontSize: '25px !important' }}>Edit Register - "{selectedRegisteredName}" - <SavePageAs/>
                    </Typography>
                    <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                        <Tooltip1 title="Close"><Close onClick={() => setOpenRegisterEdit(false)} style={{ cursor: 'pointer' }} /></Tooltip1>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={openRegisterEditScroll === 'paper'}>
                    <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RiskRegisterComponent
                                    selectedRegisteredId={selectedRegisteredId}
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRegisterEdit(false)}>Close</Button>
                </DialogActions>
            </Dialog>



            {/** For Register Eval */}

            <Dialog
                open={openRegisterDataForEval}
                onClose={false}
                scroll={openRegisterDataForEvalScroll}
                aria-labelledby="register-data-dialog-title"
                aria-describedby="register-data-dialog-description"
                fullWidth={true}
                maxWidth={maxWidthState}
                fullScreen={fullScreenState}
                PaperComponent={PaperComponentCollectionDataRegister}
            >
                <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
                    <Typography variant="h6" style={{ fontSize: '25px !important' }}>Evaluate "{selectedRegisterEvaluateTitle}" - <SavePageAs/></Typography>
                    <Typography variant="h6" style={{ marginRight: '-22px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                        <Tooltip title="Close"><Close onClick={RegisterDataForEvalfunctionClose} style={{ cursor: 'pointer' }} /></Tooltip>
                    </Typography>
                </DialogTitle>

                <DialogContent dividers={openRegisterDataForEvalScroll === 'paper'}>
            <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <EvaluateComponent
                      colorPatterns={colorPatterns}
                      registerTypeDataForEval={registerTypeDataForEval}
                      openRegisterDataForEvalScroll={openRegisterDataForEvalScroll}
                      registerEvaluate={registerEvaluate}
                    />  
                  </Grid>
              </Grid>
{/*
                {!fullScreenState ?
                    <Grid container spacing={2} style={{ marginLeft: '10px', marginBottom: '20px' }}>
                        <Grid item xs={2.5}>
                            select your color patterns here :
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label='Select Color Pattern'
                                required
                                sx={{
                                    width: '100%',
                                    "& .MuiInputBase-root": {
                                        height: 37
                                    },
                                    "& .MuiInputLabel-root": {
                                        marginTop: -0.5
                                    }
                                }}
                                select
                                value={colorPatternId}
                                onChange={handleChangeColorPattern}
                            >
                                {colorPatterns.map((item) => (
                                    <MenuItem
                                        value={item.id}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>



                        <Grid item xs={2}>

                            <TextField
                                label='Risk Level'
                                required
                                sx={{
                                    width: '100%',
                                    "& .MuiInputBase-root": {
                                        height: 37
                                    },
                                    "& .MuiInputLabel-root": {
                                        marginTop: -0.5
                                    }
                                }}
                                select
                                value={colorSelectDropDownRiskLevelTmp}
                                onChange={handleChangeDropDown}
                            >
                                <MenuItem value={1}>{colorPatternPrimaryColorName}</MenuItem>
                                <MenuItem value={2}>{colorPatternSecondaryColorName}</MenuItem>
                            </TextField>



                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                label='Risk Appetite'
                                required
                                sx={{
                                    width: '100%',
                                    "& .MuiInputBase-root": {
                                        height: 37
                                    },
                                    "& .MuiInputLabel-root": {
                                        marginTop: -0.5
                                    }
                                }}
                                select
                                value={colorSelectDropDownRiskAppetiteTmp}
                                onChange={handleChangeDropDownAppetite}
                            >
                                <MenuItem value={1}>{colorPatternPrimaryColorName}</MenuItem>
                                <MenuItem value={2}>{colorPatternSecondaryColorName}</MenuItem>
                            </TextField>


                        </Grid>
                        <Grid item xs={2}>
                            <Button variant='outlined' style={{ padding: "7px 5px" }} onClick={applyFunctionRegister}>Apply</Button>
                        </Grid>


                        <Grid container spacing={2}>


                            <Grid item xs={2}>
                                &nbsp; &nbsp;Views
                            </Grid>  &nbsp;
                            <Grid item xs={1.5}>
                                <Button
                                    variant='outlined'
                                    className={btnHirachivalClass}
                                    onClick={() => { setShowEvalData(true); setShowTableType(1); hierarchicalFunctionRegister() }}
                                >
                                    Hierarchical View
                                </Button>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Button
                                    variant='outlined'
                                    className={btnGradientClass}
                                    onClick={() => displayRiskAsendingRegister()}
                                >
                                    Gradient View
                                </Button>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Button
                                    variant='outlined'
                                    className={btnBarChartClass}
                                    onClick={() => createBarFunctionRegister()}
                                >
                                    BarChart View
                                </Button>
                            </Grid>

                            
                            {showTranferExlButton ?
                                <>
                                    <Grid item xs={2}>
                                            <TransferToExcel
                                                commitData={commitArray}
                                                commitArrayData={commitArrayData}
                                                commitBarData={commitBarData}
                                                riskTypeDataForEval={registerTypeDataForEval}
                                            />

                                        </Grid>
                                </>
                                : ''}


                        </Grid>
                    </Grid>

                    : ''
                }

                <DialogContent dividers={openRegisterDataForEvalScroll === 'paper'}>
                    <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                                    {Object.keys(registerTypeDataForEval).length > 0 &&
                                        <>
                                            <Grid item xs={12}>
                                                <div>

                                                    {
                                                        showEvalData && showTableType === 1 ?
                                                            <>

                                                                 
                                                                <div style={{display:'flex', gap:"15px",float:'right'}}>
                                                                <div>
                                                                    <Button
                                                                        variant='outlined'
                                                                        className={classes.btnSepateWindow}
                                                                        onClick={() => { showDataInAViewFunction(1) }}
                                                                    >
                                                                        Edit Register to Tranfer Excel Spread Sheet
                                                                    </Button>

                                                                    </div>
                                                                    
                                                                </div>
                                                                


                                                                <Grid container spacing={1} id='divHierarchicalViewId'>
                                                                    <Grid item xs={6}>&nbsp;</Grid>
                                                                    <Grid item xs={1}><b><u>Likelihood</u></b></Grid>
                                                                    <Grid item xs={1}><b><u>Impact</u></b></Grid>
                                                                    <Grid item xs={1}><b><u>Risk Level</u></b></Grid>
                                                                    <Grid item xs={1}><b><u>Action</u></b></Grid>
                                                                    <Grid item xs={1}><b><u>Appetite</u></b></Grid>
                                                                    <Grid item xs={1}><b><u>Action</u></b></Grid>
                                                                    {
                                                                    
                                                                        registerTypeDataForEval.riskTypes.map((riskType, index) => {

                                                                            return (

                                                                                <CommentRegister
                                                                                    key={riskType.riskTypeTmpId}
                                                                                    comment={riskType}
                                                                                    lastRiskTmpId={lastRiskTmpId}
                                                                                    riskNo={index + 1}
                                                                                    fromMainMenu={TurnedInOutlined}
                                                                                    editRiskHirachyData={editRiskHirachy}
                                                                                //lastRiskTmpId={lastRiskTmpId}
                                                                                />

                                                                            )

                                                                        })


                                                                    }


                                                                </Grid>
                                                            </>

                                                            : showEvalData && showTableType === 2 ?

                                                                <>
                                                                    <Grid container>
                                                                    <Grid item xs={12} style={{ textAlign:'center', fontWeight:'bold', fontSize:'19px'}}>
                                                                        Risk Level
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ textAlign:'left', fontWeight:'bold', fontSize:'15px'}}>
                                                                        Low
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ textAlign:'right', fontWeight:'bold', fontSize:'15px'}}>
                                                                        High
                                                                    </Grid>
                                                                    <Grid item xs={12}>

                                                                        <table style={{ backgroundImage: colorSelectDropDownRiskLevel === 1 ? 'linear-gradient(to right, green , red)' : colorSelectDropDownRiskLevel === 2 ? 'linear-gradient(to right, red , green)' : '', width: '100%', color: 'white', minHeight: '200px' }} border='0'>
                                                                            <tr>
                                                                                {
                                                                                    databClr.map((item, index1) => (

                                                                                        <td style={{ verticalAlign: 'top', width: `${tdPresentage}%` }}>
                                                                                            <table style={{ verticalAlign: 'top' }}>
                                                                                                {
                                                                                                    arrayForDisplayDataInTable.map((data, index) => (
                                                                                                        parseInt(data.riskLevel) === item.scoreNumber || (databClr.length === (index1 + 1) && parseInt(data.riskLevel) >= item.scoreNumber) ?

                                                                                                            <tr>
                                                                                                                <td style={{ fontSize: '10px', width: '5%', verticalAlign: 'top' }}><span style={{ height: '6px', width: '6px', display: 'inline-block', backgroundColor: 'white', borderRadius: '50%' }}></span></td>
                                                                                                                <td style={{ fontSize: '10px' }}>{data.name}</td>
                                                                                                            </tr>
                                                                                                            :
                                                                                                            ''

                                                                                                    ))
                                                                                                }
                                                                                            </table>

                                                                                        </td>
                                                                                    ))
                                                                                }




                                                                            </tr>


                                                                        </table>
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{ textAlign:'center', fontWeight:'bold', fontSize:'19px'}}>
                                                                        Risk Appetite
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ textAlign:'left', fontWeight:'bold', fontSize:'15px'}}>
                                                                        Low
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ textAlign:'right', fontWeight:'bold', fontSize:'15px'}}>
                                                                        High
                                                                    </Grid>

                                                                    <Grid item xs={12}>

                                                                        <table style={{ backgroundImage: colorSelectDropDownRiskAppetiteTmp === 1 ? 'linear-gradient(to right, green , red)' : colorSelectDropDownRiskAppetiteTmp === 2 ? 'linear-gradient(to right, red , green)' : '', width: '100%', color: 'white', minHeight: '200px' }} border='0'>

                                                                            <tr>
                                                                                {
                                                                                    databClr.map((item, index1) => (

                                                                                        <td style={{ verticalAlign: 'top', width: `${tdPresentage}%` }}>
                                                                                            <table style={{ verticalAlign: 'top' }}>
                                                                                                {
                                                                                                    arrayForDisplayDataInTable.map((data, index) => (
                                                                                                        parseInt(data.riskAppetite) === item.scoreNumber || (databClr.length === (index1 + 1) && parseInt(data.riskAppetite) >= item.scoreNumber) ?

                                                                                                            <tr>
                                                                                                                <td style={{ fontSize: '10px', width: '5%', verticalAlign: 'top' }}><span style={{ height: '6px', width: '6px', display: 'inline-block', backgroundColor: 'white', borderRadius: '50%' }}></span></td>
                                                                                                                <td style={{ fontSize: '10px' }}>{data.name}</td>
                                                                                                            </tr>
                                                                                                            :
                                                                                                            ''

                                                                                                    ))
                                                                                                }
                                                                                            </table>

                                                                                        </td>
                                                                                    ))
                                                                                }




                                                                            </tr>


                                                                        </table>
                                                                    </Grid>
                                                                    </Grid>

                                                                </>
                                                                :
                                                                showEvalData && showTableType === 3 ?
                                                                    <>


                                                                        <BarChart width={1000} height={barChartHeight} data={barData} margin={{ bottom: 50 }} layout="vertical">
                                                                            <XAxis
                                                                                type="number"
                                                                                axisLine={false}

                                                                                margin={{ top: 0, right: 40, left: 40, bottom: 20 }}
                                                                            />
                                                                            <YAxis dataKey="argument" type="category" width={400} />
                                                                            <CartesianGrid strokeDasharray="2 2" />
                                                                            <Tooltip content={<CustomTooltip />} />



                                                                            <Bar dataKey="riskLevel" fill={arrRiskColorState}>
                                                                                {barData.map((entry, index) => (
                                                                                    <>
                                                                                    <Cell key={`cell-${index}`} fill={arrRiskColorState[index % arrRiskColorState.length]} />
                                                                                    <LabelList dataKey="riskLevelStr" content={renderCustomizedLabel}/>
                                                                                    </>
                                                                                ))}
                                                                            </Bar>


                                                                            <Bar dataKey="riskAppetite" fill={arrColorAppState}>
                                                                                {barData.map((entry, index) => (
                                                                                    <>
                                                                                    <Cell key={`cell-${index}`} fill={arrColorAppState[index % arrColorAppState.length]} />
                                                                                    <LabelList dataKey="riskAppetiteStr" content={renderCustomizedLabel1} />
                                                                                    </>
                                                                                ))}
                                                                                
                                                                            </Bar>

                                                                        </BarChart>
                                                                    </>

                                                                    :

                                                                    ''
                                                    }


                                                </div>
                                            </Grid>




                                        </>
                                    }
                                </Grid>



                        </Grid>
*/}




                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={RegisterDataForEvalfunctionClose}>Cancel</Button>
                </DialogActions>
            </Dialog>


            <Dialog
        open={openKeyList}
        onClose={() => setOpenKeyList(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        <span style={{fontSize: '13px', fontWeight: 'bold'}}>{"The following Key codes in the JSON string above are already included in the Registers created before. Do you want to upload the New Register? Press 'Yes' to continue."}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={1}>
                <Grid item xs={3}><span style={{fontSize: '13px', fontWeight: 'bold'}}><u>Key Code</u></span></Grid>
                <Grid item xs={9}><span style={{fontSize: '13px', fontWeight: 'bold'}}><u>Register Names Created Before</u></span></Grid>
                    {duplicateKeyList.map((item) => (
                        <>
                        <Grid item xs={3}><span style={{fontSize: '11px'}}>{item.key}</span></Grid>
                            {item.registers.map((data,index) => {
                                if (index === 0){
                                    return (
                                    <>
                                    <Grid item xs={9}><span style={{fontSize: '11px'}}>{data.name}</span></Grid>
                                    </>
                                    )
                                }else{
                                    return (
                                    <>
                                    <Grid item xs={3}><span style={{fontSize: '11px'}}>&nbsp;</span></Grid>
                                    <Grid item xs={9}><span style={{fontSize: '11px'}}>{data.name}</span></Grid>
                                    </>
                                    )
                                }
                                

                            })}        
                        </>        
                    ))}
            </Grid>                
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenKeyList(false)}>No</Button>
          <Button onClick={() => continueUpdating()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openJsonSchema}
        onClose={() => setOpenJsonSchema(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        <span style={{fontSize: '13px', fontWeight: 'bold'}}>{"JSON schema"}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <textarea
                        name="postContent"
                        rows={20}
                        cols={50}
                        value={jsonSchemaString}
                        //style={{ marginTop: '-13px' }}
                    />
                     {}       
                </Grid>    
            </Grid>                
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenJsonSchema(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      
      <Dialog
        open={openJsonEdit}
        onClose={false}
        scroll={openJsonEditScroll}
        aria-labelledby="json-edit-dialog-title"
        aria-describedby="json-edit-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
        >
        <DialogTitle style={{ cursor: 'move' }} id="json-edit-dialog-title">
            <Typography variant="h6" style={{ fontSize: '25px !important' }}>Edit Json
            </Typography>
            <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
                <Tooltip1 title="Close"><Close onClick={() => setOpenJsonEdit(false)} style={{ cursor: 'pointer' }} /></Tooltip1>
            </Typography>
        </DialogTitle>
        <DialogContent dividers={openJsonEditScroll === 'paper'}>
            <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
                <Grid container spacing={2}>
                    <Grid item className='editModelGrid1'>
                        <Autocomplete
                            id="txtSectionName"
                            freeSolo
                            options={editedJsonArray.map((option) => option.section)}
                            inputValue={editedSectionName}
                            onInputChange={(event, newInputValue) => {
                                setEditedSectionName(newInputValue);
                            }}
                            renderInput={(params) => <TextField {...params} value={editedSectionName} onChange={sectionNameOnChange} onBlur={sectionNameOnBlur} label="Type Register's Section Name" />}
                        />
                    </Grid>    
                    <Grid item className='editModelGrid2'>
                            <div style={{textAlign: 'right' }}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    onClick={() => editSectionFuction()}
                                    className='editModelBtn'
                                    style={{ textAlign: 'center', height: '52px' }}
                                    >
                                    Edit
                                </Button>&nbsp;&nbsp;
                                
                                
                                <Button
                                    variant="contained"
                                    component="label"
                                    onClick={() => addNewSectionFuction()}
                                    className='editModelBtn'
                                    style={{ textAlign: 'center', height: '52px' }}
                                    >
                                    Add New
                                </Button>&nbsp;&nbsp;
                                
                                <Button
                                    variant="contained"
                                    component="label"
                                    onClick={() => deleteJsonSectionFuction()}
                                    className='editModelBtn'
                                    style={{ textAlign: 'center', height: '52px' }}
                                    >
                                    Delete
                                </Button>
                            
                            </div>

                    </Grid>
                    <Grid item xs={6}>
                              <Box component="form" sx={{ '& .MuiTextField-root': { m: 0, width: '100%' }, }} noValidate autoComplete="off">
                                <div>
                                  <TextField
                                    id="CategoryName"
                                    label="Ownership Role"
                                    value={selectedEntityName}
                                    onChange={selectedEntityNameOnChange}
                                  />
                                </div>
                              </Box>
                    </Grid>

                    
                    
                    <Grid item xs={6}>
                        <Box component="form" sx={{ '& .MuiTextField-root': { m: 0, width: '100%' }, }} noValidate autoComplete="off">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Ownership Role</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={entityName}
                                    label="Age"
                                    onChange={entityNameOnChange}
                                    >
                                    {
                                        allEntityData.map((data) => (
                                            <MenuItem value={data.entityName} >{data.entityName}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item className='editModelGrid3'>
                        <Autocomplete
                            value={intLikelihoodValue}
                            onChange={(event, intLikelihoodValue) => {
                                if (intLikelihoodValue != null) {
                                    setIntLikelihoodValue(intLikelihoodValue);
                                }
                            }}
                            inputValue={intLikelihoodValue}
                            onInputChange={(event) => {
                                if (event != null && event.target.value != null) {
                                    if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                                        setIntLikelihoodValue('')
                                    } else {
                                        setIntLikelihoodValue(event.target.value);
                                    }
                                }
                            }}
                            options={options}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Likelihood"
                                variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item className='editModelGrid4'>
                        <Autocomplete
                            value={intImpactVales}
                            onChange={(event, newValue) => {
                                if (newValue != null) {
                                setIntImpactVales(newValue);
                                }
                            }}
                            inputValue={intImpactVales}
                            onInputChange={(event, newInputValue) => {
                                if (event != null && event.target.value != null) {
                                if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                                    setIntImpactVales('')
                                } else {
                                    setIntImpactVales(event.target.value);
                                }
                                }
                            }}
                            options={options}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                id="txtImpact"
                                label="Impact "
                                variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item className='editModelGrid5'>
                        <TextField
                        id="outlined-basic"
                        label="Risk Level"
                        variant="outlined"
                        value={calculatedRiskLevel()}
                        inputProps={{
                            style: {
                            height: "22px",
                            },
                        }}
                        />
                    </Grid>
                        <Grid item className='editModelGrid6'>
                            <Autocomplete
                            value={intRiskAppetiteValue}
                            onChange={(event, newValue) => {
                                if (newValue != null) {
                                setIntRiskAppetiteValue(newValue);
                                }
                            }}
                            inputValue={intRiskAppetiteValue}
                            onInputChange={(event, newInputValue) => {
                                if (event != null && event.target.value != null) {
                                if (event.target.value <= 0 || event.target.value > 10 || !Number.isInteger(parseInt(event.target.value))) {
                                    setIntRiskAppetiteValue('')
                                } else {
                                    setIntRiskAppetiteValue(event.target.value);
                                }
                                }
                            }}
                            options={options1}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Risk Appetite "
                                variant="outlined"
                                />
                            )}
                            />
                        </Grid>
                        <Grid item className='editModelGrid7'>
                            <Button variant='outlined' style={{ float: 'right', height: '50px' }} 
                                onClick={() => addToSection()}
                                >
                                {'Update Section'}
                            </Button>
                        </Grid>
                        <Grid item className='editModelGrid1'>
                            <Autocomplete
                                id="txtSectionRiskName"
                                freeSolo
                                options={editedSectionRiskArray.map((option) => option.description)}
                                inputValue={editedSectionRiskName}
                                onInputChange={(event, newInputValue) => {
                                    setEditedSectionRiskName(newInputValue);
                                }}
                                renderInput={(params) => <TextField {...params} value={editedSectionName} onChange={sectionRiskNameOnChange} onBlur={sectionRiskNameOnBlur} label="Type Register's Risk Description" />}
                            />
                        </Grid>
                        <Grid item className='editModelGrid2'>
                            <div style={{textAlign: 'right' }}>
                            <Button
                                variant="contained"
                                component="label"
                                onClick={() => editSectionRiskFuction()}
                                className='editModelBtn'
                                style={{ textAlign: 'center', height: '52px' }}
                                >
                                Edit
                            </Button>&nbsp;&nbsp;
                            <Button
                                variant="contained"
                                component="label"
                                onClick={() => addNewRiskFuction()}
                                className='editModelBtn'
                                style={{ textAlign: 'center', height: '52px' }}
                                >
                                Add New
                            </Button>&nbsp;&nbsp;
                            <Button
                                variant="contained"
                                component="label"
                                onClick={() => deleteJsonSectionRiskFuction()}
                                className='editModelBtn'
                                style={{ textAlign: 'center', height: '52px' }}
                                >
                                Delete
                            </Button>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                            id="outlined-basic"
                            label="User Key"
                            variant="outlined"
                            value={selectedUserKey}
                            onChange={handleChangeSelectedUserKey}
                            inputProps={{
                                style: {
                                height: "22px",
                                },
                            }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                        <Box component="form" sx={{ '& .MuiTextField-root': { m: 0, width: '100%' }, }} noValidate autoComplete="off">
                            <FormControl fullWidth>
                            <TextField
                            id="outlined-basic"
                            label="Risk Hierarchy Name"
                            variant="outlined"
                            value={selectedHierarchyName}
                            onChange={handleChangeSelectedHierarchyName}
                            inputProps={{
                                style: {
                                height: "22px",
                                },
                            }}
                            />
                            </FormControl>
                            </Box>
                        </Grid>
                        <Grid item className='editModelGrid3'>
                        <Autocomplete
                            value={intLikelihoodRiskValue}
                            onChange={(event, intLikelihoodRiskValue) => {
                                if (intLikelihoodRiskValue != null) {
                                    setIntLikelihoodRiskValue(intLikelihoodRiskValue);
                                }
                            }}
                            inputValue={intLikelihoodRiskValue}
                            onInputChange={(event) => {
                                if (event != null && event.target.value != null) {
                                    if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                                        setIntLikelihoodRiskValue('')
                                    } else {
                                        setIntLikelihoodRiskValue(event.target.value);
                                    }
                                }
                            }}
                            options={options}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Likelihood"
                                variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item className='editModelGrid4'>
                        <Autocomplete
                            value={intImpactRiskVales}
                            onChange={(event, newValue) => {
                                if (newValue != null) {
                                setIntImpactRiskVales(newValue);
                                }
                            }}
                            inputValue={intImpactRiskVales}
                            onInputChange={(event, newInputValue) => {
                                if (event != null && event.target.value != null) {
                                if (event.target.value <= 0 || event.target.value > 5 || !Number.isInteger(parseInt(event.target.value))) {
                                    setIntImpactRiskVales('')
                                } else {
                                    setIntImpactRiskVales(event.target.value);
                                }
                                }
                            }}
                            options={options}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                id="txtImpact"
                                label="Impact "
                                variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item className='editModelGrid5'>
                        <TextField
                        id="outlined-basic"
                        label="Risk Level"
                        variant="outlined"
                        value={calculatedRiskLevelRisk()}
                        inputProps={{
                            style: {
                            height: "22px",
                            },
                        }}
                        />
                    </Grid>
                        <Grid item className='editModelGrid6'>
                            <Autocomplete
                            value={intRiskAppetiteRiskValue}
                            onChange={(event, newValue) => {
                                if (newValue != null) {
                                setIntRiskAppetiteRiskValue(newValue);
                                }
                            }}
                            inputValue={intRiskAppetiteRiskValue}
                            onInputChange={(event, newInputValue) => {
                                if (event != null && event.target.value != null) {
                                if (event.target.value <= 0 || event.target.value > 10 || !Number.isInteger(parseInt(event.target.value))) {
                                    setIntRiskAppetiteRiskValue('')
                                } else {
                                    setIntRiskAppetiteRiskValue(event.target.value);
                                }
                                }
                            }}
                            options={options1}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Risk Appetite "
                                variant="outlined"
                                />
                            )}
                            />
                        </Grid>
                        <Grid item className='editModelGrid7'>
                            <Button variant='outlined' style={{ float: 'right', height: '50px' }} 
                                onClick={() => addToSectionRisk()}
                                >
                                {'Update Risk'}
                            </Button>
                        </Grid>



                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => closOpenJsonEditFunction()}>Close</Button>
        </DialogActions>
    </Dialog>




            {/** Dialog Waiting */}
            <Dialog
                maxWidth={'md'}
                open={waitingOpen}
                PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
            >
                <CircularProgress disableShrink />
            </Dialog>

        </>

    )
}

export default UploadJson
