import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import titleImage from '../assets/risktitle.jpg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { red, green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import { collectionData } from '../dataA';
//import finacialImage from '../assets/risktitle.jpg';
import QuestionerMgtPageItemOthers from '../Components/QuestionerMgtPageItemOthers';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Links from '@mui/material/Link';
import { CONFIG} from '../config'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import RightBarDataForUserPages from '../Components/RightBarDataForUserPages';
import { ArrowBack } from '@mui/icons-material';
import "../Css/QuestionerManagementPage.css";

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
const useStyles = makeStyles((theme) => ({ 
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageBtn:{
    height: '60px',
    width: '170px',
    backgroundColor: '#f9f9f9 !important',
    color: '#9481df !important',
    borderRadius: '12px !important',
  },
  headerLable:{
    color: '#827d7d',
  },
  pageTop:{
    marginTop: '105px',
  },
  pageBodyStyle: {
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '12px',
    /*backgroundColor: '#f7f7f7',*/
    marginLeft: '25px',
  },
  root: {
    position: 'relative',
  },
  posRelative: {
    position: 'relative',
  },
  topImagePos: {
    position: 'absolute', 
    top: '33%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  topHeaderText1: {
    color: '#9155FD',
    fontSize:'25px', 
    paddingBottom:'10px',
  },
  topHeaderText2: {
    color: '#3A3541',
    fontSize:'12px', 
    textAlign:'center',
    fontFamily: 'Inter',
  },
  thStyle: {
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important'
  },
  thStyleSort: {
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important',
    paddingLeft: '9px !important',
    paddingRight: '9px !important',
  },
  btnColorPurple:{
    backgroundColor: '#9155FD !important',
    height:'40px'
  },
  riskImageSize: {
    width: '120px',
    height: '100px !important',
  },
  tableStyle: {
    width: '85% !important',
  },
  btnBorderGreen: {
    border: '1px solid #0e640e !important',
    color: '#0e640e !important',
    padding:'12px 10px !important',
    height: '40px !important',

  },
  btnBorderpurple:{
    border: '1px solid #9155FD !important',
    color: '#9155FD !important',
    padding:'5px 10px !important',
    height: '40px !important',
  },
  marginleft: {
    marginLeft: '78px !important'
  },
  marginleftEval: {
    marginLeft: '68px !important'
  },
  btnBorderAsh: {
    border: '1px solid #8a8181 !important',
    color: '#8a8181 !important',
    height: '40px !important',
  },
  btnBorderAshSort: {
    border: '1px solid #8a8181 !important',
    color: '#8a8181 !important',
    height: '50px !important',
    width: '100% !important'
  },
  btnBorderRed: {
    border: '1px solid #ff0000 !important',
    color: '#ff0000 !important',
    height: '40px !important',
  },
  btnBorderRedEval: {
    border: '1px solid #ff0000 !important',
    color: '#ff0000 !important',
    height: '40px !important',
    marginTop: '-20px !important',
  },

  qusCard: {
    width: '100% !important',
  },
  qusCardHeader: {
    backgroundColor: '#ece9fa !important',
  },
  subText: {
    fontSize: '12px !important',
  },
  btnPadding: {
    paddingLeft:'2px !important',
    paddingRight:'2px !important',
  },
  cardBodyH6: {
    fontSize: '11px !important',
  },
  cardBodyH6MarginLeftMinus: {
    fontSize: '13px !important',
    marginLeft: '-12px !important'
  },
  cardBodyH6MarginLeftPlus: {
    marginLeft: '10px !important'
  },
  cardBodyH6Filter: {
    fontSize: '11px !important',
    marginLeft: '10px !important',
    marginTop: '10px !important',
  },
  cardBodyH6Ans: {
    fontSize: '13px',
    paddingLeft: '12px',
  },
  addBodyPlus: {
    fontSize: '146px !important',
    fontWeight: '1000 !important',
    textAlign: 'center !important',
    color: '#3A3541 !important',
  },
  subQuestionBtn: {
    float: 'right',
    marginTop: '10px !important',
  },
  thStyleImage: {
    lineHeight: '0.5rem !important',
    borderBottom: '0px !important',
    width: '130px !important',
  },
  posRelativeAndDivBorder: {
    position: 'relative',
    border: '1px solid #9155FD', 
    width: '120px',
    height: '100px !important',
  },
  riskImageSize: {
    width: '120px',
    height: '100px !important',
  },
  tableThStyle: {
    backgroundColor: '#d4d0ea',
    color: '#ffffff',
    lineHeight: '0.25rem !important'
  },
  tableThStyleEval: {
    backgroundColor: '#d4d0ea',
    color: '#ffffff',
    lineHeight: '0.25rem !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },
  tableTrStyle: {
      lineHeight: '0.25rem !important',
      fontSize: '10px !important',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
  },
  tableRadioTrStyle: {
    lineHeight: '0.25rem !important',
    fontSize: '10px !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },
  tableTrStyleNormalBottom: {
    borderBottom: '0px !important',
    paddingTop: '2px !important',
    paddingBottom: '2px !important'
  },
  tableTrStyleNormalAll: {
    borderBottom: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingTop: '2px !important',
    paddingBottom: '2px !important'
  },
  tableFixHead: {
    overflow: 'auto',
    height: '400px',
  },
  tableHeadStyleRiskEvak: {
    position: 'sticky',
    top: 0,
  },
  tableRadioTrStyleRiskEval: {
    lineHeight: '1.25rem !important',
    fontSize: '10px !important',
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
  },
  pMarginBottom: {
    marginBottom: '0rem !important'
  },
  popupdiv:{
    marginLeft:'15%',
    border:'2px solid black',
    width:'350px',
    backgroundColor:'#f6f5fc !important',
    boxShadowhadow: '5px 10px #888888',
    resize: 'horizontal',
    overflow: 'auto',
    height: '100%',
  },
  popupdivSort:{
    marginLeft:'15%',
    border:'2px solid black',
    width:'450px !important',
    backgroundColor:'#f6f5fc !important',
    boxShadowhadow: '5px 10px #888888',
    resize: 'horizontal',
    overflow: 'auto',
    height: '100%',
  },
  popupDivRiskEval:{
    marginLeft:'15%',
    border:'1px solid black',
    width:'775px',
    backgroundColor:'#f6f5fc !important',
    boxShadowhadow: '5px 10px #888888',
    resize: 'horizontal',
    overflow: 'auto',
    height: '100%',
  },
  fontZise10:{
    fontSize: '10px !important',
  },



}));

const QuestionerManagementPage = () => {
  const navigate = useNavigate();

  const [questionsData, setQuestionsData] = React.useState(collectionData);
  
  const [golobalCentreId, setGolobalCentreId] = React.useState('');
  const classes = useStyles();
    const Input = styled('input')({
      display: 'none',
    });
  const [subOpen, setSubOpen] = React.useState(false);
  const [subScroll, setSubScroll] = React.useState('paper');

/** Sort abject sort array */
function compare( a, b ) {
  var nameA = a.entity.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.entity.name.toUpperCase(); // ignore upper and lowercase

  if ( nameA < nameB ){
      return -1;
  }
  if ( nameA > nameB ){
      return 1;
  }
  // names must be equal
  return 0;
}

  const subHandleClickOpen = (scrollType) => () => {
    setSubOpen(true);
    setSubScroll(scrollType);
  };

  const subHandleClose = () => {
    setSubOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    getData();
    if (subOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [subOpen]);

  function getData () {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    setGolobalCentreId(data.centreId); 
    getCentreData(data.centreId);
    getColors();
    console.log(data)
  }



  const [databClr, setDatabClr] = useState([])
    function getColors(){
      axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
        method: 'get',
        headers: { 
            'Content-Type': 'text/plain', 
          },     
        })
        .then(function (response) {
          console.log("All colors");
          console.log(response.data);  
          setDatabClr(response.data);         
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    function getRiskLvlColor(lvlData){
      var colorCode = ''
      databClr.forEach((data2)=>{         
        if (parseInt(data2.scoreNumber) === parseInt(lvlData)) {
          colorCode = data2.color;
        }
      })
      return colorCode;
    }

  const [centreName, setCentreName] = React.useState('');
  function getCentreData(centreId){
      var data = '{getCentreData(centreId:\"' + centreId + '\"){centreId,centreName}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          console.log("++++++++++++++++Return centreData++++++++++++++++");
          var centreData = response.data.data.getCentreData;
          console.log(centreData);
          setCentreName(centreData.centreName);
  
      })
      .catch(function (error) {
          console.log("Centre Data Loding error" + error);
      });
  }

  function notYetCompletedFun() {
    alert("This is under Construction");
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const menuHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuHandleClose = () => {
    setAnchorEl(null);
  };

  const [selectedFile, setSelectedFile] = React.useState();
  const [preview, setPreview] = React.useState();

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
      if (!selectedFile) {
          setPreview(undefined)
          return
      }
      const objectUrl = URL.createObjectURL(selectedFile)
      setPreview(objectUrl)
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const location = useLocation();
  const collectionUniqueName = location.pathname.split("/")[2];

  const [collectnId, setCollectnId] = useState('');
  const [rsType, setRsTtpe] = useState([]);
  const [riskTypeData, setRiskTypeData] = useState([])

  const [quesData, setQuesData] = useState([]);
  const [quesTitle, setQuesTttle] = useState("");
  const [quesDesc, setQuesDesc] = useState("");
  const [documentCreator, setDocumentCreator] = useState('');

  useEffect(() => {
    axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/all?collectionUniqueName='+ collectionUniqueName,{
      method: 'get',
    })
    .then(function (response) {
      console.log("Return Collection Data fromcollectionUniqueName");
      console.log(response.data);

      setCollectnId(response.data[0].id);
      setDocumentCreator(response.data[0].documentCreator);
      setQuesTttle(response.data[0].title);
      setQuesDesc(response.data[0].description);
      setPreview(response.data[0].imageUrl);
      var tmpRiskArr = [];
      if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0){
        tmpRiskArr = response.data[0].risks; 
      }
      setRiskTypeData(tmpRiskArr);
      getAllQuestions(tmpRiskArr);
    })
    .catch(function (error) {
      console.log(error);
    });
  },[])

  /** Get all question from the collection and put in to attay */
  var allQuesArray = [];
  function getAllQuestions(riskArray){
    allQuesArray = [];
    riskArray.forEach((item)=>{
      if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
        item.riskTypes.forEach((item1)=>{
          if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0){
            item1.questions.forEach((item2)=>{
              allQuesArray.push(item2);
            })  
          }
          if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0){
            getAllQuestionsInSubRisks(item1.subRisks);
          }
        });
      }
    });
    if (allQuesArray.length > 0){
      var quesArray = allQuesArray.sort (compare);
      setQuesData(allQuesArray);
    }
  }

  function getAllQuestionsInSubRisks(riskArray){
    riskArray.forEach((item)=>{
      if (item.questions != undefined && item.questions != null && item.questions.length > 0){
        item.questions.forEach((item1)=>{
          allQuesArray.push(item1);
        })  
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0){
        getAllQuestionsInSubRisks(item.subRisks);
      }
    });  
  }


/** Save Image and Collections */
function onFormSubmit(e) {
  e.preventDefault()
  let formdata = new FormData()
  formdata.append('file',selectedFile)
  formdata.append('centreId',golobalCentreId)
  if (selectedFile === undefined || selectedFile === null) {
    saveData(preview);
  }else{
      axios(CONFIG.MAIN_URL+'/image/upload',{
        method: 'post',
         data: formdata,
      })
      .then(function (response) {
        console.log(response.data.location);
        if (response.data.location === null || response.data.location === ""){
          alert(response.data.message + " and please use a different image name");
          return;
        }else {
          saveData(response.data.location);
        }  
      })
      .catch(function (error) {
        alert(error);
        console.log(error);
      })
  }
}

/** Save Collection */
function saveData(imageUrl) {
  let obj = {}
  if (collectnId != ''){
    obj["id"]= collectnId;  
  }
  obj["title"]= quesTitle;
  obj["description"]= quesDesc;
  obj["imageUrl"]= imageUrl;
  obj["centreId"]= golobalCentreId;
  obj["status"]= "Published";
  obj["collectionUniqueName"]= collectionUniqueName;
  obj["documentCreator"]= documentCreator;
  obj["risks"]= riskTypeData;
  console.log(obj);
  console.log(JSON.stringify(obj));
  let sendJson = JSON.stringify(obj);
    
  axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME+'/save',{
    method: 'post',
    headers: { 
        'Content-Type': 'application/json',
      },
    data : sendJson,  
  })
  .then(function (response) {
    console.log(JSON.stringify(response.data));
    let rtnData = response.data;
    alert("Questionnaire Group Published successfully");
    console.log(rtnData.collectionId);
  })
  .catch(function (error) {
    console.log(error);
    alert("Error Publishing Questionnaire Group -:" + error);
  });

}

const [PageName, setPageName] = useState('Questionnaires and Collection Management')
  return (
    <>
        {CONFIG.EXPERT_LOGIN?
        <RightBarDataForOtherPages PageName={PageName}/> 
        :
        <RightBarDataForUserPages PageName={PageName} CentreName={centreName}/> 
        }
        <div className={classes.pageTop}>
            <Grid container spacing={2}>
                <Grid container spacing={2} className='pageBodyStyleQMP'>

                    <Grid item xs={12}>
                    <div role="presentation" onClick={handleClick}>
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/login">
                          <span className='breadcrumbFonts'>Home</span>
                        </Link>
                        <Link underline="hover" color="inherit" to={`/editcollection/${collectionUniqueName}`}>
                        <span className='breadcrumbFonts'>Edit Collection</span>
                        </Link>
                        
                        <Typography color="text.primary"><span className='breadcrumbFonts'>Main menu - Questionnaires and Collection Management</span></Typography>
                      </Breadcrumbs>
                    </div>
                    </Grid>
                    <Grid item xs={12}>
                          <a className='goBack' onClick={() => navigate(-1)}><ArrowBack/> Go Back</a>
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table sx={{ minWidth: 700 }} aria-label="spanning table" className={classes.tableStyle}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" rowSpan={2} className={classes.thStyle}>
                                <Box sx={{ minWidth: 120 }} className={classes.riskImageSize}>
                                  <Card className={classes.root}>
                                    <div className={classes.posRelativeAndDivBorder}>   
                                      {preview != null && preview != "" &&  <img src={preview} className={classes.riskImageSize}/> }   
                                     </div>
                                  </Card>        
                                </Box>
                              </TableCell>
                              <TableCell align="right" className={classes.thStyle}>
                                <Stack direction="row" spacing={2}>
                                  <label htmlFor="contained-button-file">
                                    <Input accept="image/*" id="contained-button-file" type="file" name="file"
                                     onChange={(event) => {
                                      console.log(event.target.files[0]);
                                      setSelectedFile(event.target.files[0]);
                                    }}/>
                                    <Button variant="contained" component="span" className={classes.btnColorPurple} >
                                       Upload new photo
                                    </Button>
                                  </label>
                                    <Button 
                                      variant="outlined" 
                                      className={classes.btnBorderGreen}
                                      onClick={e => onFormSubmit(e)} 
                                      > 
                                      Published
                                    </Button>
                                    <Link to={`/questioPage/${collectionUniqueName}`}>
                                    <Button variant="outlined" size='small' className={classes.btnBorderpurple}> 
                                      Add A New Question
                                    </Button>
                                    </Link>
                                    <Button variant="outlined" className={classes.btnBorderAsh}
                                      onClick={e => notYetCompletedFun()} 
                                    >
                                      Un-Published
                                    </Button>
                                    <Button variant="outlined" className={classes.btnBorderRed}
                                      onClick={e => notYetCompletedFun()}
                                    >
                                      View Stats
                                    </Button>
                                </Stack>  

                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.thStyle}><span className={classes.topHeaderText2}>Allowed JPG, PNG or GIF. Max size of 800k</span></TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </Grid>  
                    
                    {quesData.length > 0 && 
                      <>
                         <QuestionerMgtPageItemOthers 
                            conSize={6}
                            rsType={rsType}
                            setRsTtpe={setRsTtpe}
                            riskTypeData={riskTypeData}
                            quesData={quesData}
                            classes={classes} 
                            menuHandleClick={menuHandleClick}
                            anchorEl={anchorEl}
                            menuOpen={menuOpen}
                            menuHandleClose={menuHandleClose}
                            subHandleClickOpen={subHandleClickOpen}
                            subOpen={subOpen}
                            subHandleClose={subHandleClose}
                            subScroll={subScroll}
                            descriptionElementRef={descriptionElementRef}
                            collectnId={collectnId}
                            collectionUniqueName={collectionUniqueName}
                            databClr={databClr}
                          /> 
                      </>          

                    }
                </Grid>
            </Grid>  
        </div>
    </>
  )
}

export default QuestionerManagementPage